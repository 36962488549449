import React, { useEffect, useState, useRef } from "react";
import "../../../incidents/cases/addCaseList.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import UserFilterList from "../../shared/UserFilterList ";
import { TableLoader } from "../../../../library/common/Loader";
import {
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import NORow from "../../../../library/custom/noRow/noRow";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import {
  USER_CONFIGURATION_VIEW,
  USER_ONBOARD_EDIT,
} from "../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import commonImages from "../../../../assets";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function UserConfigurationList({
  userConfigurationListData,
  getuserConfigurationLists,
  loading,
  updateUserConfiguration,
  recreateUsersList,
  openGrandAccess,
  setOpenGrandAccess,
  openGrandResetAccess,
  setOpenGrandResetAccess,
  onboardUsersStatus,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [search, setSearch] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [userConfigurationApiData, setuserConfigurationApiData] = useState({
    page: 1,
    pageSize: pageSize,
    search: "",
    Status: "",
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pwdpopMsg, setPwdpopMsg] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealOriginbalPwd, setIsRevealOriginbalPwd] = useState(false);
  const isFormValid = useRef(false);
  const [errorList, setErrorList] = useState({});
  const [checks, setChecks] = useState({
    capsLettercheck: false,
    numberCheck: false,
    pwdlength: false,
    smalllettercheck: false,
    specchar: false,
  });

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openAdd = Boolean(anchorElAdd);
  const idAdd = openAdd ? "simple-popover" : undefined;

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: pageSize,
      search: searchText ? searchText : "",
      orgId: ORG_ID(),
      Status: "",
    };
    getuserConfigurationLists(data);
  };
  const style = {
    border: "2px rgb(238, 238, 238)",
    padding: "7px",
    marginTop: "-101px",
    marginRight: "-302px",
    position: "absolute",
    marginLeft: "234px",
    backgroundColor: "#fff",
    zIndex: 9,
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      ...userConfigurationApiData,
      page: newPage + 1,
      pageSize: pageSize,
    };
    setuserConfigurationApiData({
      ...userConfigurationApiData,
      page: newPage + 1,
      pageSize: pageSize,
    });
    getuserConfigurationLists(data);
  };

  const handleOnclick = (rows) => {
    if (
      rows.row.status == 1 ||
      rows.row.status == 4 ||
      rows.row.status == 6 ||
      rows.row.status == 7
    ) {
      navigate(USER_CONFIGURATION_VIEW, {
        state: { onboardUserId: rows.row.onboardUserId, editMode: true },
      });
    } else if (rows.row.status == 2 || rows.row.status == 5) {
      navigate(USER_ONBOARD_EDIT, {
        state: { onboardUserId: rows.row.onboardUserId, editMode: true },
      });
    }
  };

  const handleResendOnClick = (rows) => {
    const data = {
      userid: rows.row.onboardUserId,
    };
    recreateUsersList(data);
  };
  function getColorCode(status) {
    let colors = "black";
    if (status === 2 || status === 5) {
      colors = "Crimson";
    }
    return colors;
  }

  const columns = [
    { field: "onboardUserId", headerName: "User ID", width: 100 },
    { field: "firstName", headerName: "First Name", flex: 3 },
    { field: "lastName", headerName: "Last Name", flex: 3 },
    {
      field: "statusText",
      headerName: "Status",
      flex: 4,
      renderCell: (rows) => {
        return (
          <div style={{ color: getColorCode(rows?.row?.status) }}>
            {rows?.row?.statusText}
          </div>
        );
      },
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={userConfigurationListData?.itemsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={userConfigurationListData?.userdata ?? []}
          fileNameXL={"UsersList"}
        />
      ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            editOnClick={() => handleOnclick(rows)}
            edit={checkPrivileges([64, 65, 12])}
            editLabel="Edit User"
            resendEmail={rows.row.status == 4}
            resendEmailLable={"Resend welcome email"}
            resendEmailOnClick={() => handleResendOnClick(rows)}
          />
        );
      },
    },
  ];

  const handleSearch = (data) => {
    setAnchorEl(null);
    getuserConfigurationLists(data);
  };

  const userStatusOnChanges = (e) => {
    setUserStatus(e.target.value);
  };

  const onRowClick = (e) => {};

  const handleClear = () => {
    setUserStatus("");
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      Status: "",
    };
    handleSearch(data);
  };

  const handleApply = (state) => {
    setuserConfigurationApiData({
      ...userConfigurationApiData,
      Status: userStatus,
    });
    setAnchorEl(null);
    const data = {
      ...userConfigurationApiData,
      page: 1,
      pageSize: pageSize,
      search: "",
      Status: userStatus,
    };
    getuserConfigurationLists(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      ...userConfigurationApiData,
      page: 1,
      pageSize: newPazeSize,
      search: search ? search : "",
      Status: userStatus ? userStatus : "",
    };
    getuserConfigurationLists(data);
  };

  const [accessUserId, setAccessUserId] = useState([]);

  const handleClickGrantAccess = () => {
    setOpenGrandAccess(true);
  };
  const handleClickGrantAccessSystemGenerated = () => {
    setOpenGrandAccess(true);

    const result = accessUserId?.map((val) => ({
      userId: val,
      tmpPassword: "",
    }));

    const accessData = {
      userIds: result,
    };
    updateUserConfiguration(accessData);
  };
  const handleClickGrantResetAccess = () => {
    setOpenGrandResetAccess(true);
    setOpenGrandAccess(false);
  };

  const handleModelClose = () => {
    setOpenGrandAccess(false);
  };
  const handleModelResetClose = () => {
    setOpenGrandResetAccess(false);
    setPassword("");
    setConfirmPassword("");
    setPwdpopMsg(false);
    setIsRevealPwd(false);
    setIsRevealOriginbalPwd(false);
  };
  useEffect(() => {
    if (password.trim() === confirmPassword.trim()) {
      setErrorList((prevErrorList) => ({
        ...prevErrorList,
        confirmPassword: true,
      }));
    }
  }, [password, confirmPassword]);
  useEffect(() => {
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);
    } else {
      setPwdpopMsg(true);
    }
  }, [
    checks.capsLettercheck,
    checks.numberCheck,
    checks.pwdlength,
    checks.smalllettercheck,
    checks.specchar,
  ]);

  const handleOnKeyUp = (e) => {
    const value = e.target.value.trim();
    const capsLettercheck = /[A-Z]/.test(value);
    const smalllettercheck = /[a-z]/.test(value);
    // const numberCheck = /[0-9]/.test(value);
    const numberCheck = /\d/.test(value);
    const pwdlength = value.length >= 8;
    const specchar = /[!@#$`~%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
    const whitespace = /\s/g.test(value);
    setChecks({
      capsLettercheck,
      numberCheck,
      pwdlength,
      smalllettercheck,
      specchar,
    });
    if (whitespace === true) {
      setPassword("");
    }
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);

      errorList.password = true;
    } else {
      setPwdpopMsg(true);
    }
  };
  var capsClass = checks.capsLettercheck ? "valid" : "invalid";
  var numcheckClass = checks.numberCheck ? "valid" : "invalid";
  var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
  var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
  var specClass = checks.specchar ? "valid" : "invalid";

  /* Create Custome password submit button*/

  const validateForm = () => {
    var capsClass = checks.capsLettercheck ? "valid" : "invalid";
    var numcheckClass = checks.numberCheck ? "valid" : "invalid";
    var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
    var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
    var specClass = checks.specchar ? "valid" : "invalid";
    var passwodval = false;
    if (
      capsClass === "valid" &&
      numcheckClass === "valid" &&
      pwdlenClass === "valid" &&
      sletterClass === "valid" &&
      specClass === "valid" &&
      password.trim().length !== 0
    ) {
      passwodval = true;
    }
    let newErrorList = {
      ...errorList,
      password: passwodval,
      confirmPassword: confirmPassword.trim === password.trim,
    };

    setErrorList({ ...newErrorList });

    /* This validation is not based on state */
    let temporaryValidation = true;
    for (const key in newErrorList) {
      temporaryValidation = temporaryValidation && newErrorList[key];
    }
    /*
Saving it in this ref as well for
when we don't want to revalidate the
whole thing
*/
    isFormValid.current = temporaryValidation;
    return temporaryValidation;
  };

  const submitButton = async (event) => {
    event.preventDefault();
    setErrorList((prevErrorList) => ({
      ...prevErrorList,
      confirmPassword: true,
    }));
    if (validateForm()) {
      if (
        password?.trim() === confirmPassword?.trim() &&
        password &&
        confirmPassword
      ) {
        const result = accessUserId?.map((val) => ({
          userId: val,
          tmpPassword: password,
        }));
        const accessData = {
          userIds: result,
        };

        updateUserConfiguration(accessData);
      } else {
        setErrorList((prevErrorList) => ({
          ...prevErrorList,
          confirmPassword: false,
        }));
      }
    }
    // }
  };

  return (
    <>
      <Modal
        open={openGrandAccess}
        onClose={handleModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container className="modalUsrContainer">
          <Grid md={6} xs={12}>
            <Box className="modalCustomUserBox">
              <div onClick={handleModelClose} className="ClosegrandAccesstyle">
                X
              </div>
              <div className="modalTitle">Grant Access</div>
              <div className="modeldesc">
                How do you want to assign the password?
              </div>
              <Grid container className="grandaccess">
                <Grid item lg={5} xs={12} sm={3} md={3}>
                  <Buttons
                    label="System generated"
                    onClick={handleClickGrantAccessSystemGenerated}
                    varientContainedGrey={true}
                  />
                </Grid>

                <Grid item lg={5.5} xs={12} sm={3} md={3}>
                  <Buttons
                    varientContained={true}
                    label={"Create Custom Password"}
                    onClick={handleClickGrantResetAccess}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openGrandResetAccess}
        onClose={handleModelResetClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container className="modalUsrContainer">
          <Grid md={4} xs={12}>
            <Box className="modalCustomUserBox">
              <div
                onClick={handleModelResetClose}
                className="ClosegrandAccesstyle"
              >
                X
              </div>
              <div className="modalTitle">Grant Access</div>
              <div className="modeldescreset">Create your custom password</div>
              <Grid container className="modalFooterBoxReset">
                <Grid md={12} item className="resetpwd">
                  <form>
                    <div
                      className={[
                        "form-wrapper",
                        errorList.password ? null : "has-error",
                      ].join(" ")}
                    >
                      <label>
                        Password (min 8 characters){" "}
                        <span className="error">*</span>
                      </label>
                      <div className="password-container">
                        <input
                          className={[
                            errorList.confirmPassword
                              ? "input-control"
                              : "input-control border-red",
                          ]}
                          type={isRevealOriginbalPwd ? "text" : "password"}
                          placeholder="*******"
                          value={password}
                          maxLength="20"
                          onChange={(e) => setPassword(e.target.value.trim())}
                          onKeyUp={handleOnKeyUp}
                        />
                        <img
                          className="showHidePassword"
                          title={
                            isRevealOriginbalPwd
                              ? "Hide password"
                              : "show password"
                          }
                          alt=""
                          src={
                            isRevealOriginbalPwd
                              ? commonImages.visibilityoff
                              : commonImages.visibility
                          }
                          onClick={() =>
                            setIsRevealOriginbalPwd((prevState) => !prevState)
                          }
                        />
                        {password && (
                          <div
                            style={{
                              display: pwdpopMsg === false ? "none" : "block",
                            }}
                          >
                            <div style={style}>
                              <form>
                                <p style={{ fontWeight: "bold" }}>
                                  All checkmarks must turn green.
                                </p>
                                <p className={pwdlenClass}>
                                  <CheckCircleIcon className="checkbox" />
                                  At least 8 characters
                                </p>
                                <p className={capsClass}>
                                  <CheckCircleIcon className="checkbox" />
                                  At least 1 uppercase letter
                                </p>
                                <p className={sletterClass}>
                                  <CheckCircleIcon className="checkbox" />
                                  At least 1 lowercase letter
                                </p>
                                <p className={specClass}>
                                  <CheckCircleIcon className="checkbox" />
                                  At least 1 special character
                                </p>
                                <p className={numcheckClass}>
                                  <CheckCircleIcon className="checkbox" />
                                  At least 1 number{" "}
                                </p>
                              </form>
                            </div>
                          </div>
                        )}

                        {errorList.password === false && (
                          <div className="show-error">
                            Please enter your password
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={[
                        "form-wrapper",
                        errorList.confirmPassword ? null : "has-error",
                      ].join(" ")}
                    >
                      <label>
                        Confirm Password <span className="error"> *</span>
                      </label>
                      <div className="password-container">
                        <input
                          className={[
                            errorList.confirmPassword
                              ? "input-control"
                              : "input-control border-red",
                          ]}
                          type={isRevealPwd ? "text" : "password"}
                          placeholder="Enter Your Password"
                          value={confirmPassword}
                          maxLength="20"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <img
                          className="showHidePassword"
                          title={
                            isRevealPwd ? "Hide password" : "show password"
                          }
                          alt=""
                          src={
                            isRevealPwd
                              ? commonImages.visibilityoff
                              : commonImages.visibility
                          }
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />

                        {errorList.confirmPassword === false && (
                          <div className="show-error">
                            Password do not match
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </Grid>
                <Grid container className="restActions" mt={2}>
                  <Grid item lg={3} xs={12} sm={3} md={3}>
                    <Buttons
                      varientText={true}
                      label="Cancel"
                      onClick={handleModelResetClose}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12} sm={3} md={3}>
                    <Buttons
                      varientContained={true}
                      label={"Save"}
                      onClick={submitButton}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">User Configuration</div>
          <div>
            <div className="filter_btn">
              {checkPrivileges([12, 64, 65]) && (
                <Buttons
                  id="btnAddUsers"
                  aria-describedby={idAdd}
                  varientContained={true}
                  label={"Grant Access"}
                  onClick={(e) => handleClickGrantAccess()}
                />
              )}
            </div>
          </div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              <UserFilterList
                secondLabel="Status"
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                secondLebelValue={userStatus}
                handleSearch={handleSearch}
                handleApply={handleApply}
                handleClear={handleClear}
                secondLebelFilter={onboardUsersStatus}
                resetBtn={true}
                secondLebelOnChanges={userStatusOnChanges}
              />
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.onboardUserId}
                rows={userConfigurationListData?.userdata ?? []}
                rowCount={userConfigurationListData?.itemsCount ?? 0}
                onRowClick={onRowClick}
                columns={columns}
                loading={loading}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                checkboxSelection
                page={page}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                onSelectionModelChange={setAccessUserId}
                isRowSelectable={(rows) =>
                  rows.row.status == 1 || rows.row.status == 5
                }
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
