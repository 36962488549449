import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SupportLandingPage from "../component/supportLandingPage";
import * as supportAction from "../../../redux/actions/supportAction";
import "./supportContainerStyle.scss";

function SupportContainer(props) {
  const articles = useSelector((state) => state.article?.data?.articles);
  const [searchbarInput, setSearchbarInput] = useState("");
  function handleSearch(searchbarInput) {
    setSearchbarInput(searchbarInput);
    dispatch(supportAction.getArticle(searchbarInput));
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(supportAction.getArticle(""));
  }, []);

  return (
    <div className="overrideSupportContainer">
      <div className="supportMainContainer">
        <div className="rightBarr">
          <div className="container_wrapperr">
            <SupportLandingPage
              searchbarInput={searchbarInput}
              handleSearch={handleSearch}
              articles={articles}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportContainer;
