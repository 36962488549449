import { Grid } from "@mui/material";
import React, { useRef } from "react";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import AddIcon from "@mui/icons-material/Add";
import Buttons from "../../../../library/custom/button/Button";
import FormDropZone from "../../../../library/dropZone/FormDropZone";
import Checkbox from "../../../../library/checkbox/checkbox";

const AddInspectionProof = (props) => {
  const hiddenSignatureInput = useRef(null);

  return (
    <>
      <Grid container>
        <Grid item md={10}>
          <div className="addProof">
            <Grid container mt={3}>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className="addProofTitle">Certify Completion</div>
                <FormDropZone
                  id="uploadMedia"
                  paraText1={"Drop the files here ..."}
                  paraText2={"Drop Files here, "}
                  paraText3={"or Browse"}
                  deleteImageURL={props?.deleteImageURL}
                  handleFileChange={(photo, fileIds) =>
                    props?.handleFileChange(photo, fileIds)
                  }
                  files={props?.filess}
                  imageUrl={props?.filess}
                  fileType={10}
                  isDoc={true}
                  isImage={false}
                  allFiles={true}
                  isDynamics={true}
                  isMuliple={true}
                />
                <p>
                  Supported file types: doc, docx, ppt, pptx, xls, xlsx,
                  zip,images, or pdf
                </p>
                {props?.isFiles && (
                  <div className="errorMsg"> Inspection Proof is Required</div>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Checkbox
                  checked={props.checkBox}
                  onChange={() => props.setcheckBox(!props.checkBox)}
                  label={`I certify under penalty of law that this report and all
                  attachments were prepared under my direction or supervision in
                  accordance with a system designed to assure that certified
                  personnel properly gather and evaluate the information
                  submitted. Based on my inquiry of the person or persons who
                  manage the system, or those persons directly responsible for
                  gathering the information, the information submitted is, to
                  the best of my knowledge and belief, true, accurate, and
                  complete. I am aware that there are significant penalties for
                  submitting false information, including the possibility of
                  fine and imprisonment for knowing violations.`}
                />
              </Grid>
              {!props?.checkBox && props?.checkBoxError && (
                <Grid item lg={12} xs={12} mt={2}>
                  <p className="error">This field is required</p>
                </Grid>
              )}
            </Grid>

            <Grid container mt={4} className="buttonEnd">
              <Grid item lg={6} xs={12} sm={6} md={6}></Grid>
              <Grid item lg={6} xs={12} sm={6} md={6}>
                <Buttons
                  varientContained={true}
                  label={"Save"}
                  onClick={props?.submitData}
                  // disabled={isSubmit ? true : false}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AddInspectionProof;
