import { getApiUrl } from "../config";
import { post, get, deletes } from "./axiosRequest";
import { ORG_ID } from "../constant/constant";
export const createLesson = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Lesson/AddLesson`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const getLessonsList = async ({ data }) => {
  const { pageNumber, pageSize, search, catIds, status, organisationId } = data;

  let url = `${
    getApiUrl().lmsApiUrl
  }/Lesson/GetLessonList?pageSize=${pageSize}`;

  if (pageNumber) {
    url += `&page=${pageNumber}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  if (catIds) {
    url += `&catIds=${catIds}`;
  }
  if (status) {
    if (status === 2) {
      url += `&status=0`;
    } else {
      url += `&status=${status}`;
    }
  }

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getLessonDetails = async ({ payload }) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Lesson/GetLessonDetails?lessonId=${payload}&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateLessonList = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Lesson/UpdateLesson`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const deleteLesson = async ({ payload }) => {
  const url = `${getApiUrl().lmsApiUrl}/Lesson/DeleteLessonRequest/${
    payload?.lessonId
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const publishLessons = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Lesson/PublishLesson`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const assignLessons = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Lesson/AssignLessonToUser`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getLessonAssignedUserList = async (params) => {
  const { pageNumber, pageSize, lessonId, isCompleted } = params?.data;

  const url = `${
    getApiUrl().lmsApiUrl
  }/Lesson/GetAssignedUserList?page=${pageNumber}&pageSize=${pageSize}&lessonId=${lessonId}&isCompleted=${isCompleted}&orgId=${ORG_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const completeLesson = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Lesson/CompletedLesson`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const startLessons = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Lesson/StartLesson`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getUserLessonsList = async ({ data }) => {
  const { pageNumber, pageSize, search, catIds, statusId, userId, orgId } =
    data;
  const url = `${
    getApiUrl().lmsApiUrl
  }/Lesson/GetLessonListViaUser?page=${pageNumber}&pageSize=${pageSize}&search=${search}&catIds=${catIds}&statusId=${statusId}&userId=${userId}&orgId=${orgId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const sendcourseAndLessonAlertMail = async ({ data }) => {
  //const dataResult = data?.data;

  const url = `${getApiUrl().lmsApiUrl}/Lms/SendCourseAndLessonAlertEmail`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const getCompletedlessons = async (params) => {
  const { lessonId, userOrGroupId, userType, OrgId } = params?.data;

  const url = `${
    getApiUrl().lmsApiUrl
  }/Lesson/GetCompletedLessons?lessonId=${lessonId}&userOrGroupId=${userOrGroupId}&userType=${userType}&OrgId=${ORG_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// UnAssign Lesson
export const unAssignLesson = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Lesson/UnAssignLessonToUser`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
// enEnroll Lesson
export const unEnrollLesson = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Lesson/UnEnrollLessonByUser`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
