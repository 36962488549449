import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./landingpage.scss";
import { DASHBOARD, SWDASHBOARD } from "../../constant/routeContant";
import { LANDING_CARD, vapidKey } from "../../constant/constant";
import { Grid } from "@mui/material";
import Card from "../../library/custom/cardList/card";
import { messaging } from "../../../src/firebase";
import { getToken } from "firebase/messaging";
import * as loginActions from "../../redux/actions/loginActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Landingpage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setserviceType, setServiceType] = useState("");
  const [fcmTokens, setFcmToken] = useState("");
  const userId = localStorage.getItem("userId");

  const loginData = useSelector((state) => state.login);

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: vapidKey,
      });
      setFcmToken(token);
    } else if (permission === "denied") {
      console.log("You denied for the notification");
    }
  }

  const postNotifiactionLoginData = (data) => {
    dispatch(loginActions.postNotifiactionLogin(data));
  };

  useEffect(() => {
    if (loginData.status === "SUCCESS") {
      requestPermission();
    }
  }, []);

  useEffect(() => {
    if (loginData.status === "SUCCESS" && fcmTokens !== "") {
      const defaultData = {
        mapList: [
          {
            userId: userId,
            fcmToken: fcmTokens,
          },
        ],
      };
      postNotifiactionLoginData(defaultData);
    }
  }, [fcmTokens]);

  const onClickHandler = (id) => {
    localStorage.setItem("serviceType", id);
    if (id === 1) {
      navigate(DASHBOARD);
    } else if (id === 2) {
      navigate(SWDASHBOARD);
    }
  };
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userInfoProduct = userInfo?.product;
  const data = userInfoProduct?.map((val) => val?.key);
  const check_Storm_Water = data?.includes(2);
  useEffect(() => {
    if (check_Storm_Water && data?.length === 1) {
      navigate(SWDASHBOARD);
    }
    if (check_Storm_Water === false && data?.length === 1) {
      navigate(DASHBOARD);
    }
  }, []);

  return (
    <>
      <Grid container className="landingtitle" mt={6}>
        <h2>Select Service</h2>
      </Grid>
      <Grid container>
        <Grid md={2} xs={12}></Grid>
        <Grid
          container
          md={8}
          className="CardListContanerLanding cardsBoxLanding"
          mt={6}
        >
          <Grid md={2} xs={12}></Grid>
          <Card
            iconShow={false}
            onClick={onClickHandler}
            imgShow={true}
            cardDetail={LANDING_CARD[0]}
          />

          <Card
            iconShow={false}
            onClick={onClickHandler}
            imgShow={true}
            cardDetail={LANDING_CARD[1]}
          />

          <Grid md={2} xs={12}></Grid>
        </Grid>
        <Grid md={2} xs={12}></Grid>
      </Grid>
    </>
  );
};

export default Landingpage;
