import React, { useState, useEffect } from "react";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import "./configureInspections.scss";
import { Grid } from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import Button from "../../../../library/custom/button/Button";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import "./configureInspections.scss";
import { USER_ID } from "../../../../constant/constant";

const EditInspectionsType = ({ state, detail,updateInspectionTypeData }) => {
  const dataBack = {
    title: "Back to Inspections",
    route: -1,
  };

  const intialState = {
    value: "",
    stateId: "",
  };
  const [formValue, setFormValue] = useState(intialState);
  const [isState, setIsState] = useState(false);
  const [isInspectionType, setIsInspectionType] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  function mappedData() {
    let errors = false;
    if (formValue?.value.trim() === "") {
      setFormValue({
        ...formValue,
        value: "",
      });
      setIsInspectionType(true);
      errors = true;
    }
    if (!formValue?.stateId) {
      setIsState(true);
      errors = true;
    }
    if (errors) {
      return false;
    }

    return {
      value: formValue?.value,
      stateId: formValue?.stateId,
      isActive: detail?.isActive?.toLowerCase()==='active'?true: false,
      key: detail?.key,
      userId: parseInt(USER_ID()),
    };
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      updateInspectionTypeData(data);
    }
  };
  useEffect(() => {
    if (detail) {
      setFormValue({
        ...formValue,
        value: detail?.value,
        stateId: detail?.stateId,
      });
    }
  }, [detail]);

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Edit Inspection Type</div>
        </div>
        <Grid container mt={3}>
          <Grid item lg={4} md={12} xs={12}>
            <InputField
              type="text"
              isRequired={true}
              value={formValue?.value}
              onChange={(e) =>
                setFormValue({
                  ...formValue,
                  value: e.target.value,
                })
              }
              label="Inspection Type"
              placeholder="Inspection Type Here..."
              isError={isInspectionType}
              errorMsg={isInspectionType ? "Inspection Type is Required" : ""}
            />
          </Grid>
        </Grid>
        <Grid container mt={3}>
          <Grid item lg={4} md={12} xs={12}>
            <FormControl fullWidth>
              <FormLabels label="State" isRequired={true} />
              <SelectMenu
                placeholder="Please Select"
                listData={state}
                value={formValue?.stateId}
                onchangehandler={(e) =>
                  setFormValue({
                    ...formValue,
                    stateId: e.target.value,
                  })
                }
                isError={isState}
                errorMsg={isState ? "State is Required" : ""}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mt={15}>
          <Grid item lg={6} md={12} xs={12}>
            <div className="insBackBtn">
              <BackBtn dataBack={dataBack} />
            </div>
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <Grid container item lg={12} xs={12} className="divisionBtnBox">
              <Grid lg={6} xs={12} sm={6}>
                <Buttons
                  // onClick={() => navigate(dataBack?.route)}
                  varientText={true}
                  label="Cancel"
                />
              </Grid>
              <Grid lg={6} xs={12} sm={6}>
                <Button
                  varientContained={true}
                  label={"Save"}
                  disabled={isSubmit ? true : false}
                  onClick={submitData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default EditInspectionsType;
