import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import "./contractor.scss";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { NumberField } from "../../../../library/custom/numberField/numberField";

import TextArea from "../../../../library/custom/textArea/TextArea";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  COUNTRY_CODE,
  TEXT_FIELD_ERROR_MSG,
  DROP_DOWN_FIELD_ERROR_MSG,
  ORG_ID,
  USERID,
  CONTRACTOR_TEMPLATE_URL,
  USER_ID,
} from "../../../../constant/constant";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const ContractorForm = ({
  divisions,
  editMode,
  contractorGrades,
  contractorTypes,
  stateData,
  addContractor,
  contractorId,
  viewContractors,
  ismultipleUpload,
}) => {
  const userIdss = viewContractors?.data?.result?.userId;

  const [divisionIds, setDivisionIds] = useState([]);

  const divisionsData = findValueMultipleSelect(divisions, divisionIds);

  // code start
  const [serviceType, setServiceType] = useState([1]);
  const [serviceTypeError, setServiceTypeError] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const serviceTypes = userInfo?.product;

  useEffect(() => {
    if (serviceTypes?.length === 1) {
      setServiceType(serviceTypes[0].key);
    }
  }, [serviceTypes]);
  const userId = localStorage.getItem("userId");
  function initiateState(editModeObject) {
    const keyDynamic = editMode ? "modifiedBy" : "createdBy";
    const divisionId = editMode ? "divisionIds" : "divisionId";

    const intialState = {
      contractorTypeId: editModeObject ? editModeObject?.contractorTypeId : "",
      stateId: editModeObject ? editModeObject?.stateId : "",
      contractorName: editModeObject ? editModeObject?.contractorName : "",
      gradeId: editModeObject ? editModeObject?.gradeId : "",
      pocFirstName: editModeObject ? editModeObject?.pocFirstName : "",
      pocLastName: editModeObject ? editModeObject?.pocLastName : "",
      pocEmail: editModeObject ? editModeObject?.pocEmail : "",
      pocPhone: editModeObject ? editModeObject?.pocPhone : "",
      pocCity: editModeObject ? editModeObject?.pocCity : "",
      pocZipCode: editModeObject ? editModeObject?.pocZipCode : "",
      pocNotes: editModeObject ? editModeObject?.pocNotes : "",
      productIds: editModeObject ? editModeObject?.product : [1],
      isdCode: COUNTRY_CODE,
      [keyDynamic]: parseInt(userId),
      contractorId: editMode ? contractorId : 0,
      userId: userIdss,
    };
    return intialState;
  }

  useEffect(() => {
    const divisionId = editMode ? "divisionIds" : "divisionId";
    setFormValue({
      ...formValue,
      [divisionId]: divisionsData,
    });
  }, [divisionIds]);

  const [formValue, setFormValue] = useState(initiateState());

  useEffect(() => {
    if (viewContractors?.data?.result) {
      setFormValue(initiateState(viewContractors?.data?.result));
      setServiceType(
        viewContractors?.data?.result?.productIds?.map((item) => item.value) ||
          viewContractors?.data?.result?.product?.map((item) => item.value)
      );
    }
  }, [viewContractors]);

  useEffect(() => {
    let valueArray = [];
    divisions?.forEach((data) => {
      let newarr = viewContractors?.data?.result?.divisionIds?.forEach((i) => {
        if (i === data.key) valueArray.push(data.value);
      });
      console.log(valueArray);
      if (divisionIds.indexOf(data) >= 0) {
        valueArray.push(data.value);
      }
    });
    setDivisionIds(valueArray);
  }, [viewContractors, divisions]);

  const history = useNavigate();
  const [contractoNameError, setContractoNameError] = useState(false);
  const [contractoTypeError, setContractoTypeError] = useState(false);
  const [contractoGradeError, setContractoGradeError] = useState(false);
  const [contractoFnameError, setContractoFnameError] = useState(false);
  const [contractoLnameError, setContractoLnameError] = useState(false);
  const [contractoEmailError, setContractoEmailError] = useState(false);
  const [contractoPhoneError, setContractoPhoneError] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [divisionsError, setDivisionsError] = useState(false);
  const [stateIdError, setStateIdError] = useState(false);
  const [pocCityError, setPocCityError] = useState(false);
  const [pocZipCodeError, setPocZipCodeError] = useState(false);
  const [emailverify, setEmailverify] = useState(false);
  const [phoneverify, setPhoneverify] = useState(false);

  const onchangeBulkupload = () => {
    setIsBulkUpload(!isBulkUpload);
    ismultipleUpload(!isBulkUpload);
  };
  const onChangeEmail = (e) => {
    setContractoEmailError(false);
    setFormValue({ ...formValue, pocEmail: e.target.value });
    const isValidEmail = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailverify(false);
    } else {
      setEmailverify(true);
    }
    setFormValue({ ...formValue, pocEmail: e.target.value });
    if (e.target.value == "") {
      setEmailverify(false);
    }
  };

  const onChangeMultipleDivision = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);

    setDivisionIds(typeof value === "string" ? value.split(",") : value);
  };
  function findValueMultipleSelect(arrrayofObject, selectedArray) {
    let valueArray = [];
    arrrayofObject?.forEach((data) => {
      if (selectedArray.indexOf(data.value) >= 0) {
        valueArray.push(data.key);
      }
    });
    return valueArray;
  }
  const findValueMultipleSelectServiveType = (
    arrrayofObject,
    selectedArray
  ) => {
    if (serviceTypes?.length > 1) {
      let valueArray = [];
      arrrayofObject.forEach((data) => {
        if (selectedArray?.indexOf(data.value) >= 0) {
          valueArray.push(data.key);
        }
      });
      return valueArray;
    }
  };

  const onClickAddUser = (e) => {
    const selectedServiceType = findValueMultipleSelectServiveType(
      serviceTypes,
      serviceType
    );
    const trimmedFormValue = {
      ...formValue,
      pocFirstName: formValue.pocFirstName?.trim(),
      pocLastName: formValue.pocLastName?.trim(),
      contractorName: formValue.contractorName?.trim(),
    };
    let isError = false;

    if (!formValue.contractorName) {
      setContractoNameError(true);
      isError = true;
    }
    if (!formValue.contractorTypeId) {
      setContractoTypeError(true);
      isError = true;
    }
    if (!formValue.gradeId) {
      setContractoGradeError(true);
      isError = true;
    }
    if (!formValue.pocFirstName) {
      setContractoFnameError(true);
      isError = true;
    }
    if (!formValue.pocLastName) {
      setContractoLnameError(true);
      isError = true;
    }
    if (!formValue.pocEmail) {
      setContractoEmailError(true);
      isError = true;
    }
    if (emailverify == true) {
      isError = true;
    }
    if (!formValue.pocPhone) {
      setContractoPhoneError(true);
      isError = true;
    }
    if (formValue?.pocPhone?.length != 10) {
      setPhoneverify(true);
      isError = true;
      if (formValue?.pocPhone === "") {
        setPhoneverify(false);
      }
    }
    if (formValue?.pocPhone?.length == 0) {
      setPhoneverify(false);
      isError = true;
    }
    if (editMode) {
      if (formValue.divisionIds.length == 0) {
        setDivisionsError(true);
        isError = true;
      }
    } else {
      if (formValue.divisionId.length == 0) {
        setDivisionsError(true);
        isError = true;
      }
    }

    if (!formValue.stateId) {
      setStateIdError(true);
      isError = true;
    }
    if (!formValue.pocCity) {
      setPocCityError(true);
      isError = true;
    }
    if (!formValue.pocZipCode) {
      setPocZipCodeError(true);
      isError = true;
    }
    if (!serviceType || serviceType?.length === 0) {
      setServiceTypeError(true);
      isError = true;
    }

    formValue["productIds"] = selectedServiceType
      ? selectedServiceType
      : [serviceType];

    if (!isError) {
      const sendData = {
        contractors: [trimmedFormValue],
        createdBy: parseInt(USER_ID()),
        organisationId: ORG_ID(),
      };

      addContractor(editMode ? formValue : sendData);
    }
  };

  const handleFileChanges = (data) => {
    const selectedServiceType = findValueMultipleSelectServiveType(
      serviceTypes,
      serviceType
    );
    const users = data.map((x, index) => {
      delete x.id;
      x.contractorName = x.contractorName?.trim();
      x.pocFirstName = x.pocFirstName?.trim();
      x.pocLastName = x.pocLastName?.trim();
      x.contractorTypeId = parseInt(x.contractorTypeId);
      x.stateId = parseInt(x.stateId);
      x.gradeId = parseInt(x.gradeId);
      x.divisionId = x.divisionId.split(",").map((item) => +item);
      x.createdBy = parseInt(USER_ID());
      x.isdCode = COUNTRY_CODE;
      x.contractorId = 0;
      x.productIds = selectedServiceType ? selectedServiceType : [serviceType];
      return x;
    });
    const sendDatas = {
      contractors: users,
      createdBy: parseInt(USER_ID()),
      organisationId: ORG_ID(),
    };

    addContractor(sendDatas);
  };
  const onlyContainsNumbers = (str) => /^\d+$/.test(str);

  const mapBulkError = (data) => {
    let contractorsWithError = [];
    data.forEach((x) => {
      if (
        x.contractorName?.trim() === undefined ||
        null ||
        "" ||
        x.contractorTypeId?.trim() === undefined ||
        null ||
        "" ||
        x.gradeId?.trim() === undefined ||
        null ||
        "" ||
        x.divisionId?.trim() === undefined ||
        null ||
        "" ||
        x.pocFirstName?.trim() === undefined ||
        null ||
        "" ||
        x.pocLastName?.trim() === undefined ||
        null ||
        "" ||
        x.pocEmail?.trim() === undefined ||
        null ||
        "" ||
        x.pocPhone?.trim() === undefined ||
        null ||
        "" ||
        x.pocPhone?.trim().length !== 10 ||
        x.stateId?.trim() === undefined ||
        null ||
        "" ||
        x.pocCity?.trim() === undefined ||
        null ||
        "" ||
        x.pocZipCode?.trim() === undefined ||
        null ||
        "" ||
        x.pocZipCode?.trim().length >= 6 ||
        onlyContainsNumbers(x.pocZipCode) === false
      ) {
        contractorsWithError.push(x);
        return;
      }
    });
    return contractorsWithError;
  };
  // code start

  const onChangeServiceType = (event) => {
    setServiceTypeError(false);
    const {
      target: { value },
    } = event;
    setServiceType(typeof value === "string" ? value.split(",") : value);
  };

  const selectedServiceTypeData = localStorage?.getItem("serviceType");
  useEffect(() => {
    if (selectedServiceTypeData) {
      const newListData = serviceTypes?.find(
        (x) => x.key == selectedServiceTypeData
      );
      console.log("newListData", newListData);

      setServiceType(
        typeof newListData?.value === "string"
          ? newListData?.value.split(",")
          : newListData?.value
      );
    }
  }, [selectedServiceTypeData]);

  useEffect(() => {
    if (!formValue?.pocPhone) {
      setPhoneverify(false);
    }
  }, [formValue?.pocPhone]);

  return (
    <Grid container mt={4}>
      <Grid item lg={12} xs={12} sm={12} md={4}>
        <Grid container item lg={9} xs={12} sm={12} md={9}>
          <Grid item lg={6} xs={12} sm={12} md={6} textAlign="left">
            <div className="heading_style">Add Sub-Contractor</div>
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={6} textAlign="right">
            {checkPrivileges([12, 43]) && !editMode && (
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={
                  isBulkUpload ? "Single Contractor" : "Multiple Contractors"
                }
                varientContained={true}
                onClick={onchangeBulkupload}
              ></Buttons>
            )}
          </Grid>
        </Grid>
        <div>
          {isBulkUpload ? (
            <Grid container>
              <Grid item lg={9} xs={9} sm={9} md={9} mt={4}>
                <BulkUpload
                  handleFileChanges={handleFileChanges}
                  mapBulkError={mapBulkError}
                  paraText1={"Drop the files here ..."}
                  paraText2={"Drop the files here, "}
                  paraText3={"or Browse"}
                  templateUrl={CONTRACTOR_TEMPLATE_URL}
                  templateText="Download Template"
                  pocFirstNameKey="pocFirstName"
                  pocLastNameKey="pocLastName"
                  pocPhoneKey="pocPhone"
                  pocFirstNamelabel="FIRST NAME"
                  pocLastNamelabel="LAST NAME"
                  pocPhonelabel="PHONE"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container item md={9} xs={9} className="addShadowBox">
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={false}
                      isRequired={true}
                      type="text"
                      label={"Sub-Contractor Name"}
                      value={formValue.contractorName}
                      placeholder="Sub-Contractor Name"
                      onChange={(e) => {
                        setContractoNameError(false);
                        setFormValue({
                          ...formValue,
                          contractorName: e.target.value,
                        });
                      }}
                      isError={contractoNameError}
                      errorMsg={
                        contractoNameError && !formValue.contractorName
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <FormLabels label="Sub-Contractor Type" isRequired={true} />
                    <SelectMenu
                      placeholder={"Please Select"}
                      listData={contractorTypes}
                      value={formValue.contractorTypeId}
                      onchangehandler={(e) => {
                        setContractoTypeError(false);
                        setFormValue({
                          ...formValue,
                          contractorTypeId: e.target.value,
                        });
                      }}
                      isError={contractoTypeError}
                      errorMsg={
                        contractoTypeError && !formValue.contractorTypeId
                          ? DROP_DOWN_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <FormLabels label="Grade" isRequired={true} />
                    <SelectMenu
                      placeholder={"Please Select"}
                      listData={contractorGrades}
                      value={formValue.gradeId}
                      onchangehandler={(e) => {
                        setContractoGradeError(false);
                        setFormValue({
                          ...formValue,
                          gradeId: e.target.value,
                        });
                      }}
                      isError={contractoGradeError}
                      errorMsg={
                        contractoGradeError && !formValue.gradeId
                          ? DROP_DOWN_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Division" isRequired={true} />
                    <MultiSelectBox
                      placeholder={"Please Select"}
                      listData={divisions}
                      onChange={(e) => onChangeMultipleDivision(e)}
                      value={divisionIds ?? []}
                      isError={divisionsError}
                      errorMessage={
                        divisionsError &&
                        (formValue.divisionIds?.length == 0 ||
                          formValue.divisionId?.length == 0)
                          ? DROP_DOWN_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <h1 className="pocHeading">Point of Contact Details</h1>

              <Grid container mt={1} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={false}
                      isRequired={true}
                      type="text"
                      label={"First Name"}
                      value={formValue.pocFirstName}
                      placeholder="Enter first name"
                      onChange={(e) => {
                        setContractoFnameError(false);
                        setFormValue({
                          ...formValue,
                          pocFirstName: e.target.value,
                        });
                      }}
                      isError={contractoFnameError}
                      errorMsg={
                        contractoFnameError && !formValue.pocFirstName
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={false}
                      isRequired={true}
                      type="text"
                      label={"Last Name"}
                      value={formValue.pocLastName}
                      placeholder="Enter last name"
                      onChange={(e) => {
                        setContractoLnameError(false);
                        setFormValue({
                          ...formValue,
                          pocLastName: e.target.value,
                        });
                      }}
                      isError={contractoLnameError}
                      errorMsg={
                        contractoLnameError && !formValue.pocLastName
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container mt={2} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={false}
                      isRequired={true}
                      type="text"
                      label={"Email"}
                      value={formValue.pocEmail}
                      placeholder="Enter Email"
                      onChange={(e) => {
                        onChangeEmail(e);
                      }}
                      isError={contractoEmailError}
                      errorMsg={
                        contractoEmailError && !formValue.pocEmail
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                  {emailverify && (
                    <div className="errorMsg">
                      Please enter a valid Email address
                    </div>
                  )}
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <NumberField
                      type="text"
                      disabled={editMode ? true : false}
                      isRequired={true}
                      label="Phone Number"
                      maxLength="10"
                      value={formValue.pocPhone}
                      placeholder={`Enter Phone number without ${COUNTRY_CODE}`}
                      onChange={(value) =>
                        value.length <= 10
                          ? setFormValue({
                              ...formValue,
                              pocPhone: value,
                            })
                          : formValue.pocPhone
                      }
                      isError={contractoPhoneError}
                      errorMsg={
                        contractoPhoneError && !formValue.pocEmail
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                  {phoneverify && formValue?.pocPhone?.length != 10 && (
                    <div className="errorMsg">
                      Please enter 10 digit valid Phone Number
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid item container mt={0} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="State" isRequired={true} />
                    <SelectMenu
                      placeholder={"Please Select"}
                      listData={stateData}
                      value={formValue.stateId}
                      onchangehandler={(e) => {
                        setFormValue({
                          ...formValue,
                          stateId: e.target.value,
                        });
                      }}
                      isError={stateIdError}
                      errorMsg={
                        stateIdError && !formValue.stateId
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={false}
                      isRequired={true}
                      type="text"
                      label={"City"}
                      maxLength="10"
                      value={formValue.pocCity}
                      placeholder={`Add City Here...`}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          pocCity: e.target.value,
                        })
                      }
                      isError={pocCityError}
                      errorMsg={
                        pocCityError && !formValue.pocCity
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container mt={0} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <NumberField
                      type="text"
                      isRequired={true}
                      label="Zip Code"
                      placeholder="Add Zip Code Here..."
                      maxLength="5"
                      value={formValue.pocZipCode}
                      onChange={(e) =>
                        e.length <= 5
                          ? setFormValue({
                              ...formValue,
                              pocZipCode: e,
                            })
                          : formValue.pocZipCode
                      }
                      isError={pocZipCodeError}
                      errorMsg={
                        pocZipCodeError && !formValue.pocZipCode
                          ? TEXT_FIELD_ERROR_MSG
                          : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container mt={0} spacing={3}>
                <Grid item lg={12} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Add Notes" />
                    <TextArea
                      placeholder={"Enter Here"}
                      value={formValue.pocNotes}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          pocNotes: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>

                {serviceTypes.length > 1 && (
                  <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                    <FormControl fullWidth>
                      <FormLabels label="Service Type" isRequired={true} />

                      <MultiSelectBox
                        placeholder={"Please Select"}
                        listData={serviceTypes}
                        onChange={(e) => onChangeServiceType(e)}
                        value={serviceType || []}
                        isError={serviceTypeError}
                        errorMessage="Selection is required for this field"
                        disableSelect={true}
                      />
                    </FormControl>
                  </Grid>
                )}
                {serviceTypes.length === 1 && (
                  <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                    <FormControl fullWidth>
                      <FormLabels label="Service Type" isRequired={true} />
                      {serviceTypes?.map((value, index) => {
                        return (
                          <div className="serviveTypeStyle" key={value?.key}>
                            {" "}
                            {value?.value}
                          </div>
                        );
                      })}
                    </FormControl>
                  </Grid>
                )}
              </Grid>

              <Grid container mb={10} mt={1} spacing={3}>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  sm={12}
                  md={4}
                  textAlign="left"
                ></Grid>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <div className="adduser_btn">
                    <div
                      className="cancle_heading_style"
                      onClick={() => history(-1)}
                    >
                      Cancel
                    </div>
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={editMode ? "Update Contractor" : "Add Contractor"}
                      varientContained={true}
                      onClick={(e) => onClickAddUser(e)}
                    ></Buttons>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default ContractorForm;
