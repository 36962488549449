import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as contractorAction from "../../../redux/actions/contractorAction";
import ViewContractorDetails from "../component/contractor/viewContractorDetails";
import { ADMIN_PERSONAL_CONTRACTOR } from "../../../constant/routeContant";
import { API_STATUS } from "../../../constant/constant";

const ContractorContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const rows = location?.state?.rowsss;
  const addSubContractor = () => {
    navigate(ADMIN_PERSONAL_CONTRACTOR, {
      state: { contractorId: rows?.id, companyName: rows?.contractorName },
    });
  };

  const dispatch = useDispatch();

  const USERID = localStorage.getItem("userId");

  const getContractorListData = (data) => {
    dispatch(contractorAction.getPersonalContractorList(data));
  };

  const personalContractorListData = useSelector(
    (state) => state.getPersonalContractorListData
  );
  const defaultData = {
    page: 1,
    pageSize: 10,
    search: "",
    contractorId: rows.id,
  };
  useEffect(() => {
    getContractorListData(defaultData);
  }, []);

  const deletePersonalContractorDatas = useSelector(
    (state) => state.deletePersonalContractorData
  );
  const deleteList = (id) => {
    dispatch(contractorAction.deletePersonalContractor(id));
  };

  useEffect(() => {
    if (deletePersonalContractorDatas.status === API_STATUS.SUCCESS) {
      getContractorListData(defaultData);
    }
  }, [deletePersonalContractorDatas]);

  return (
    <>
      <ViewContractorDetails
        addSubContractor={addSubContractor}
        loading={
          personalContractorListData?.isLoading ||
          deletePersonalContractorDatas.isLoading
        }
        rows={rows}
        personalContractorListData={personalContractorListData?.data?.result}
        deleteList={deleteList}
        compnayName={rows?.contractorName}
        constractorId={rows?.id}
      />
    </>
  );
};

export default ContractorContainer;
