import { perosnalInjuryType } from "../constants/perosnalInjuryType";

export const perosnalInjury = (data) => ({
  type: perosnalInjuryType.POST_PERSONAL_INJURY_PENDING,
  data,
});

export const perosnalInjurySuccess = (data) => ({
  type: perosnalInjuryType.POST_PERSONAL_INJURY_SUCCESS,
  data,
});

export const perosnalInjuryFailure = (error) => ({
  type: perosnalInjuryType.POST_PERSONAL_INJURY_FAILURE,
  error,
});

export const updatePerosnalInjury = (data) => ({
  type: perosnalInjuryType.PUT_PERSONAL_INJURY_PENDING,
  data,
});

export const updatePerosnalInjurySuccess = (data) => ({
  type: perosnalInjuryType.PUT_PERSONAL_INJURY_SUCCESS,
  data,
});

export const updatePerosnalInjuryFailure = (error) => ({
  type: perosnalInjuryType.PUT_PERSONAL_INJURY_FAILURE,
  error,
});

export const clearPerosnalInjury = () => ({
  type: perosnalInjuryType.CLEAR_PERSONAL_INJURY,
  data: {},
});

export const editPerosnalInjury = (data) => ({
  type: perosnalInjuryType.EDIT_PERSONAL_INJURY,
  data,
});

export const getPersonalReportDetails = (caseid, incidentid) => ({
  type: perosnalInjuryType.GET_PERSONAL_REPORT,
  caseid,
  incidentid,
});

export const getPersonalReportDetailsSuccess = (data) => ({
  type: perosnalInjuryType.GET_PERSONAL_REPORT_SUCCESS,
  data,
});

export const getPersonalReportDetailsFailure = (error) => ({
  type: perosnalInjuryType.GET_PERSONAL_REPORT_FAILURE,
  error,
});
