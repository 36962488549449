import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as lmsAction from "../../../redux/actions/lmsAction";
import AddLesson from "../component/addLesson/addLessons";
import { useLocation, useNavigate } from "react-router-dom";
import { LESSON, DASHBOARD } from "../../../constant/routeContant";
import SuccessScreencommon from "../../../library/custom/success/successScreen";
import { API_STATUS } from "../../../constant/constant";
import Loader from "../../../library/common/Loader";
import { checkPrivileges } from "../../../utils/rolesHelper";

const AddLessonsContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const edit = pathname.split("/")[2] ?? null;
  const editid = pathname.split("/")[3] ?? null;
  const [successScreens, setSuccessScreens] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10]);
    if (isAllowedDash === false) {
      navigate(DASHBOARD);
    }
    // dispatch(lmsAction.getCategoryList({ pageNumber: 1, isGlobal: false })); // Todo
  }, []);
  const categoryListDataIsloading = useSelector(
    (state) => state.getCategoryList?.isLoading
  );
  const categoryListData = useSelector((state) => state.getCategoryList?.data);
  const createLesson = useSelector((state) => state.createLesson);
  const statuss = createLesson?.status;
  const cid = createLesson?.data?.lessonId;
  const lessdetail = useSelector((state) => state.updateLesson);
  const statussss = lessdetail?.status;
  const lessonDetailData = lessdetail?.editLessonData;
  const isLoading = useSelector((state) => state.updateLesson?.isLoading);
  const isLoadingAdd = useSelector((state) => state.createLesson?.isLoading);

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10]);

    if (isAllowedDash === false) {
      navigate(DASHBOARD);
    }
    if (!!edit && editid) {
      dispatch(lmsAction.getLessonDetails(editid));
    }
  }, [edit, editid, dispatch]);

  const addAndUpdateLesson = async (data) => {
    setSuccessScreens(true);
    if (editid) {
      dispatch(lmsAction.updateLessonsData(data));
    } else {
      dispatch(lmsAction.createLesson(data));
    }
  };

  const successScree = (id, message, labelmsg) => {
    return (
      <SuccessScreencommon
        isUpdate={message}
        route={LESSON}
        id={id}
        label2={labelmsg}
        label1="Lesson ID"
        label4="Lesson in"
      ></SuccessScreencommon>
    );
  };

  return (
    <>
      {isLoading || (isLoadingAdd && <Loader />)}
      {isLoading && <Loader />}
      {categoryListDataIsloading && <Loader />}
      {statuss === API_STATUS.SUCCESS && !editid && successScreens ? (
        successScree(cid, "Lesson Created Successfully", "")
      ) : statussss === API_STATUS.SUCCESS && editid && successScreens ? (
        successScree(editid, "Lesson Updated Successfully", "has been updated")
      ) : (
        <AddLesson
          categoryListData={categoryListData}
          onSubmit={addAndUpdateLesson}
          lessonDetailData={lessonDetailData}
        />
      )}
    </>
  );
};
export default AddLessonsContainer;
