import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { InputField } from "../../../library/custom/textBox/InputField";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Buttons from "../../../library/custom/button/Button";
import intl from "react-intl-universal";
import useDebounce from "../../../utils/useDebounce";
import FormDropZone from "../../../library/dropZone/FormDropZoneIncident";
export default function Vehicle(props) {
  const {
    vehicleOnChange,
    nameOfDriveValue,
    vehicleRegNum,
    unitNumber,
    makeAndModel,
    numberOfOccu,
    vehicleTowed,
    drugsAlcohol,
    ticketIssue,
    numberOfOccupants,
    isErrorNameOfDrive = false,
    errorNameOfDriveMsg = "",
    regNumberDatas,
    cancelVehicleList,
    addPicturesVehical,
    deleteImageURL,
    id,
    keyNo,
    vehicleUpload,
    searchVehicle,
    isLoading,
    isErrorVehicleRegNum = false,
    errorVehicleRegNumMsg = "",
    editModeDisable,
    filess,
    getAssetDetails
  } = props;

  const [searchtext, setSeachText] = useState("");
  const debounceAs = useDebounce(searchtext, 500);
  useEffect(() => {
    if (debounceAs) {
      searchVehicle(searchtext);
    }
  }, [debounceAs]);
  return (
    <>
      <div
        style={{
          border: "1px solid #DFDFDF",
          maxWidth: "750px",
          padding: "20px",
          borderRadius: "10px",
          marginBottom: "30px",
        }}
      >
        <Grid item container>
          <Grid item lg={12} textAlign="left" mb={2}>
            <h1>Vehicle {keyNo}</h1>
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item lg={6} xs={12} sm={12} md={4}>
            <InputField
              id="nameOfDrive"
              fullWidth={true}
              disabled={false}
              type="text"
              label={intl.get("incident.vehicleAccident.nameOfDriver")}
              isRequired={true}
              value={nameOfDriveValue}
              placeholder="Add Name of Driver Text Here..."
              onChange={(e) => vehicleOnChange(e)}
              isError={isErrorNameOfDrive}
              errorMsg={errorNameOfDriveMsg}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={4}>
            <>
              <FormLabels
                label={intl.get(
                  "incident.vehicleAccident.selectRegistrationNumb"
                )}
                isRequired={true}
              />
              <SearchAutoComplete
                name="vehicleRegNum"
                optionsData={isLoading ? [] : regNumberDatas ?? []}
                typeOnchange={(event) => setSeachText(event.target.value)}
                isUserDetails={false}
                value={vehicleRegNum}
                isRegNum={true}
                onChange={(e, value) =>{ vehicleOnChange(e, value)
                  getAssetDetails(value)
                }}
                isLoading={isLoading}
                noOptionsTextMsg="No vehicle"
                isError={isErrorVehicleRegNum}
                errorMsg={errorVehicleRegNumMsg}
                disabled={editModeDisable ? true : false}
              />
            </>
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={1}>
          <Grid item lg={6} xs={12} sm={12} md={4}>
            <InputField
              id="unitNumber"
              fullWidth={true}
              disabled={true}
              type="text"
              label={intl.get("incident.vehicleAccident.unitNumber")}
              value={unitNumber}
              onChange={(e) => vehicleOnChange(e)}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={4}>
            <InputField
              id="makeAndModel"
              fullWidth={true}
              disabled={true}
              type="text"
              label={intl.get("incident.vehicleAccident.makeAndModel")}
              value={makeAndModel}
              onChange={(e) => vehicleOnChange(e)}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={1}>
          <Grid item lg={6} xs={12} sm={12} md={4}>
            <FormLabels
              label={intl.get("incident.vehicleAccident.numberOfOccupants")}
            />
            <SelectMenu
              name="nameOfDriveData"
              listData={numberOfOccupants}
              onchangehandler={(e) => vehicleOnChange(e)}
              value={numberOfOccu}
              placeholder="Please select"
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={1}>
          <Grid item lg={6} xs={12} sm={12} md={4}>
            <FormLabels
              label={intl.get("incident.vehicleAccident.wasVehicletowed")}
              isRequired={false}
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={vehicleTowed === true}
                value={true}
                label="Yes"
                id="vehicleTowedYes"
                onChange={(e) => vehicleOnChange(e)}
              ></RadioButton>
              <RadioButton
                id="vehicleTowedNo"
                checked={vehicleTowed === false}
                value={false}
                label="No"
                onChange={(e) => vehicleOnChange(e)}
              ></RadioButton>
            </RadioGroup>
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={4}>
            <FormLabels
              label={intl.get(
                "incident.vehicleAccident.wasTicketIssuedToDriver"
              )}
              isRequired={false}
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={ticketIssue === true}
                id="ticketIssueYes"
                value={true}
                label="Yes"
                onChange={(e) => vehicleOnChange(e)}
              ></RadioButton>
              <RadioButton
                checked={ticketIssue === false}
                value={false}
                id="ticketIssueNo"
                label="No"
                onChange={(e) => vehicleOnChange(e)}
              ></RadioButton>
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid item container mt={3}>
          <Grid item lg={12} xs={12} sm={12} md={12} mb={4}>
            <FormDropZone
              label={intl.get("incident.vehicleAccident.addPhotos")}
              paraText1={"Drop the files here ..."}
              paraText2={"Drop Files here, "}
              paraText3={"or Browse"}
              handleFileChange={(photo, fileIds) =>
                vehicleUpload(photo, fileIds)
              }
              isDoc={true}
              isImage={false}
              allFiles={true}
              isDynamics={true}
              fileType={10}
              imageUrl={addPicturesVehical}
              deleteImageURL={deleteImageURL}
              filess={filess}
              id="addPhoto"
              key="addPhoto"
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item lg={8} xs={12} sm={12} md={4}>
            <FormLabels
              label={intl.get("incident.vehicleAccident.influenceOfDrugs")}
              isRequired={false}
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                id="drugsAlcoholYes"
                checked={drugsAlcohol === true}
                value={true}
                label="Yes"
                onChange={(e) => vehicleOnChange(e)}
              ></RadioButton>
              <RadioButton
                id="drugsAlcoholNo"
                checked={drugsAlcohol === false}
                value={false}
                label="No"
                onChange={(e) => vehicleOnChange(e)}
              ></RadioButton>
            </RadioGroup>
          </Grid>
        </Grid>
      </div>
      <Grid item container mt={3}>
        <Grid lg={8} xs={12} sm={12} md={12}>
          <Box mb={2} textAlign="right">
            <Buttons
              variantOutline={true}
              onClick={() => cancelVehicleList(id)}
              label={intl.get("incident.vehicleAccident.cancel")}
            ></Buttons>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
