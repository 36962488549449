export const safetyLogType = {
  GET_KUDOS_LOG_PENDING: "[GET_KUDOS_LOG][REQUEST] Get Kudos Log requested",
  GET_KUDOS_LOG_SUCCESS: "[GET_KUDOS_LOG][RESPONSE] Get Kudos Log Successfull",
  GET_KUDOS_LOG_FAILURE: "[GET_KUDOS_LOG][RESPONSE] Get Kudos Log Failed",

  GET_REPRIMAND_LOG_PENDING:
    "[GET_REPRIMAND_LOG][REQUEST] Get Reprimand Log requested",
  GET_REPRIMAND_LOG_SUCCESS:
    "[GET_REPRIMAND_LOG][RESPONSE] Get Reprimand Log Successfull",
  GET_REPRIMAND_LOG_FAILURE:
    "[GET_REPRIMAND_LOG][RESPONSE] Get Reprimand Log Failed",

  GET_VIOLATION_LOG_PENDING:
    "[GET_VIOLATION_LOG][REQUEST] Get Violation Log requested",
  GET_VIOLATION_LOG_SUCCESS:
    "[GET_VIOLATION_LOG][RESPONSE] Get Violation Log Successfull",
  GET_VIOLATION_LOG_FAILURE:
    "[GET_VIOLATION_LOG][RESPONSE] Get Violation Log Failed",
};
