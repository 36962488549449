import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { get, post, deletes } from "./axiosRequest";
import { PRODUCT_ID } from "../constant/storm-water/constant";

export const getDivisionsList = async ({ data }) => {
  const { pageSize, page, search } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Division/GetDivisionList?orgId=${ORG_ID()}&page=${page}&pageSize=${pageSize}&search=${search}&productId=${PRODUCT_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const createDivisions = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Division/AddDivision`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateDivisions = async ({ payload: { data } }) => {
  const url = `${getApiUrl().adminApiUrl}/Division/UpdateDivision`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteDivisions = async (payload) => {
  const url = `${getApiUrl().adminApiUrl}/Division/DeleteDivision/${
    payload?.payload
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getDivisionsReport = async (divisionId) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Division/GetDivisionDetails?divisionId=${divisionId}&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
