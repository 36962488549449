import { call, put, takeLatest } from "redux-saga/effects";
import {
  addVehicale,
  getVehicleAccidentReport,
  updateVehicleAccident,
  getRegNumber,
} from "../../services/incidentService";
import { apiError } from "../../utils/helper";
import * as vehicalAccidentAction from "../actions/vehicalAccidentAction";
import { vehicalAccidentType } from "../constants/vehicalAccidentType";

export function* vehicalAccidentSaga(params) {
  console.debug("vehicalAccidentSaga");
  try {
    const { data, status, error } = yield call(addVehicale, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = vehicalAccidentType.VEHICAL_ACCIDENT_PAGE;
    yield put(
      vehicalAccidentAction.vehicalAccidentSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Vehical Accident Failed" };
    yield put(vehicalAccidentAction.vehicalAccidentFailure(data));
  }
}

export function* getvehicleAccidentSaga({ caseid, incidentid }) {
  console.debug("getVehicleAccidentReport");
  try {
    const { data, status, error } = yield call(
      getVehicleAccidentReport,
      caseid,
      incidentid
    );
    if (!data && status !== 200) throw apiError(error);
    const vehicleAccidentReportPage = vehicalAccidentType.VEHICAL_ACCIDENT_PAGE;
    yield put(
      vehicalAccidentAction.getVehicalAccidentSuccess({
        vehicleAccidentReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Vehicle Accident Report Failed" };
    yield put(vehicalAccidentAction.getVehicalAccidentFailure(data));
  }
}

export function* updateVehicalAccidentSaga(params) {
  console.debug("updateVehicalAccidentSaga");
  try {
    const { data, status, error } = yield call(updateVehicleAccident, params);
    if (!data && status !== 200) throw apiError(error);
    const vehicleAccidentPage = vehicalAccidentType.VEHICAL_ACCIDENT_PAGE;
    yield put(
      vehicalAccidentAction.updateVehicalAccidentSuccess({
        vehicleAccidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Vehicle accident updation Failed" };
    yield put(vehicalAccidentAction.updateVehicalAccidentFailure(data));
  }
}

export function* getRegNumberData({ searchText }) {
  try {
    const {
      data: result,
      status,
      error,
    } = yield call(getRegNumber, searchText);
    if (!result && status !== 200) throw apiError(error);
    const data = typeof result === "string" ? JSON.parse(result) : result;
    yield put(
      vehicalAccidentAction.getRegNumberSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Get reg number" };
    yield put(vehicalAccidentAction.getRegNumberFailure(data));
  }
}

const myVehicleIAccidentSaga = [
  takeLatest(
    vehicalAccidentType.POST_VEHICAL_ACCIDENT_PENDING,
    vehicalAccidentSaga
  ),
  takeLatest(vehicalAccidentType.GET_VEHICAL_ACCIDENT, getvehicleAccidentSaga),
  takeLatest(vehicalAccidentType.GET_REG_NUMBER, getRegNumberData),
  takeLatest(
    vehicalAccidentType.PUT_VEHICAL_ACCIDENT_PENDING,
    updateVehicalAccidentSaga
  ),
];

export default myVehicleIAccidentSaga;
