import React from "react";
import "./Cards.scss";

export default function Card({
  cardData,
  cardClickHandler,
  staticCardData = false,
  selectedVehicleAccident,
}) {
  return (
    <div className="newCards">
      <div className="blueCardBody">
        {cardData.map((data, index) => {
          return staticCardData ? (
            <div
              key={data.key}
              onClick={() => cardClickHandler(data)}
              className={
                selectedVehicleAccident?.value === data?.value
                  ? "staticDataCard borderGreen"
                  : "staticDataCard"
              }
            >
              {/* <div className="staticCardIcon">
              <img src={data.img} alt="icons" />
            </div> */}
              <div className="staticCardContent">
                <h5>{data.value}</h5>
              </div>
            </div>
          ) : (
            <div className="newCards" key={index}>
              <div
                key={data.name}
                className={data.isSelected ? "selectedCard" : "defaultCard"}
                onClick={() => cardClickHandler(data)}
              >
                {data.value}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
