import * as coursesUserAction from "../actions/lmsUserAction";
import { lmsUserType } from "../constants/lmsUserType";
import {
  startCoursess,
  getUserCoursesList
} from "../../services/coursesService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* getUserCoursesListSaga(params) {
  try {
    const { data, status, error } = yield call(getUserCoursesList, params);
    if (!data && status !== 200) throw apiError(error);
    const coursesUserPage = lmsUserType.COURSES_USER__PAGE;
    yield put(
        coursesUserAction.getUserCoursesListSuccess({
        coursesUserPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Lesson List Failed" };
    yield put(coursesUserAction.getUserCoursesListFailure(data));
  }
}

export function* startCourseSaga(param) {
  const payload = param.data;
  try {
    const { data, status, error } = yield call(startCoursess, payload);
    if (!data && status !== 200) throw apiError(error);
    const startCourse = coursesUserAction.startCourse;
    yield put(
      coursesUserAction.startCourseSuccess({
        startCourse,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Start Course Failed" };
    yield put(coursesUserAction.startCourseFailure(data));
  }
}

const myUserCoursesSaga = [
  takeLatest(lmsUserType.GET_USER_COURSES_LIST_PENDING, getUserCoursesListSaga),
  takeLatest(lmsUserType.POST_START_COURSES_PENDING, startCourseSaga),

];

export default myUserCoursesSaga;
