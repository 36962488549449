export const incidentType = {
  GET_INCIDENT: "[REQUEST] Get Incident Data",
  GET_INCIDENT_SUCCESS: "[RESPONSE] Get Incident Data Successfull",
  GET_INCIDENT_FAILURE: "[RESPONSE] Get Incident Data Failed",
  INCIDENT_PAGE: "InicidentPage",
  CASES_PAGE: "CasesPage",
  CASEVIEW_PAGE: "case view page",

  // list of incidents
  DELETE_INCIDENT: "DELETE_INCIDENT",
  DELETE_INCIDENT_SUCCESS: "DELETE_INCIDENT_SUCCESS",
  DELETE_INCIDENT_FAILED: "DELETE_INCIDENT_FAILED",

  //GetIncidentList

  GET_INCIDENT_LIST: "[GET_INCIDENT_LIST] Get Incident List Data",
  GET_INCIDENT_LIST_SUCCESS:
    "[GET_INCIDENT_LIST_SUCCESS] Get Incident List Data Successfull",
  GET_INCIDENT_LIST_FAILURE:
    "[GET_INCIDENT_LIST_FAILURE] Get Incident List Data Failed",

  GET_CASES_LIST: "[GET_CASES_LIST] Get Cases List Data",
  GET_CASES_LIST_SUCCESS:
    "[GET_CASES_LIST_SUCCESS] Get Cases List Data Successfull",
  GET_CASES_LIST_FAILURE: "[GET_CASES_LIST_FAILURE] Get Cases List Data Failed",

  GET_CASE_DETAILS_PENDING: "[GET_CASE_DETAILS_PENDING] Get Cases Detail Data",
  GET_CASE_DETAILS_SUCCESS:
    "[GET_CASES_LIST_SUCCESS] Get Cases Detail Data Successfull",
  GET_CASE_DETAILS_FAILED:
    "[GET_CASES_LIST_FAILURE] Get Cases Detail Data Failed",

  GET_ACTION_LIST: "[GET_ACTION_LIST] Get Action List Data",
  GET_ACTION_LIST_SUCCESS:
    "[GET_ACTION_LIST_SUCCESS] Get Action List Data Successfull",
  GET_ACTION_LIST_FAILURE:
    "[GET_ACTION_LIST_FAILURE] Get Action List Data Failed",

  CLOSE_ACTION_ITEM: "[UPDATE_ACTION_LIST] Get Action List Data",
  CLOSE_ACTION_ITEM_SUCCESS:
    "[UPDATE_ACTION_LIST_SUCCESS] Get Action List Data Successfull",
  CLOSE_ACTION_ITEM_FAILURE:
    "[UPDATE_ACTION_LIST_FAILURE] Get Action List Data Failed",

  CLOSE_INCIDENT: "[CLOSE_INCIDENT] CLOSE_INCIDENT Data",
  CLOSE_INCIDENT_SUCCESS:
    "[CLOSE_INCIDENT_SUCCESS] CLOSE_INCIDENT Data Successfull",
  CLOSE_INCIDENT_FAILURE: "[CLOSE_INCIDENT_FAILURE] CLOSE_INCIDENT Data Failed",

  DELETE_CASE: "DELETE_CASE",
  DELETE_CASE_SUCCESS: "DELETE_CASE_SUCCESS",
  DELETE_CASE_FAILED: "DELETE_CASE_FAILED",
};
