import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./GoogleMap.scss";

const GoogleMaps = (props) => {
  const [markerPosition, setMarkerPosition] = useState({
    lat: 40.73061,
    lng: -73.935242,
  });
  const [address, setAddress] = useState("");
  const [map, setMap] = useState(null);
  const markerRef = useRef(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  let marker;

  const loadMap = (map, maps) => {
    setMap(map);
    map.addListener("click", (event) => {
      const { lat, lng } = event.latLng;
      setMarkerPosition({ lat: lat(), lng: lng() });
      markerRef.current.setPosition(event.latLng);
      setShowInfoWindow(false);
    });

    markerRef.current = new maps.Marker({
      position: markerPosition,
      map,
      draggable: true,
    });

    markerRef.current.addListener("dragend", (event) => {
      const { lat, lng } = event.latLng;
      setMarkerPosition({ lat: lat(), lng: lng() });
      setShowInfoWindow(false);
    });

    markerRef.current.addListener("click", () => {
      setShowInfoWindow(true);
    });
  };

  useEffect(() => {
    props.onChageLocation(
      `${markerPosition.lat.toFixed(6)},${markerPosition.lng.toFixed(6)}`
    );
    if (map) {
      map.panTo(markerPosition);
    }
  }, [markerPosition, map]);

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setAddress(selectedAddress);
      setMarkerPosition(latLng);
      map.panTo(results[0].geometry.location);
      markerRef.current.setPosition(results[0].geometry.location);
      setShowInfoWindow(false);
    } catch (error) {
      console.error("Error while geocoding address:", error);
    }
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMarkerPosition({ lat: latitude, lng: longitude });
          setAddress("");
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const InfoWindow = () => {
    return (
      <div className="infoWindow">
        <div className="infoWindowCard">
          <div className="infoWindowContent">
            <h1>Latitude: {markerPosition.lat.toFixed(6)}</h1>
            <h1>Longitude: {markerPosition.lng.toFixed(6)}</h1>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <div className="mapContainer">
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="autocompleteStyle">
              <input {...getInputProps({ placeholder: "Search address" })} />
              <div>
                {loading ? <div>Loading...</div> : null}
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "#eaeaea" : "#ffffff",
                  };
                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC36KSCJWZ63CmMCrS4labvOSwu-SGxKTw" }}
        defaultCenter={markerPosition}
        defaultZoom={7}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
      >
        {showInfoWindow && (
          <InfoWindow lat={markerPosition.lat} lng={markerPosition.lng} />
        )}
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMaps;
