import React from "react";
import { INSPECTIONCARD } from "../../../../constant/constant";
import Card from "../../../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const InspectionList = ({ onClick }) => {
  return (
    <>
      <Grid container className="CardListContaner">
        <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={INSPECTIONCARD[0]}
        />
        <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={INSPECTIONCARD[1]}
        />
        <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={INSPECTIONCARD[2]}
        />
        <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={INSPECTIONCARD[3]}
        />
        {checkPrivileges([12, 120, 121, 4]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={INSPECTIONCARD[4]}
          />
        )}
      </Grid>
    </>
  );
};

export default InspectionList;
