import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { get, post, deletes } from "./axiosRequest";

export const getVehicleList = async ({ data }) => {
  const {
    divisionId = 0,
    pageSize,
    page,
    search,
    userId,
    organisationId,
  } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Vehicle/GetVehicleList?organisationId=${organisationId}&divisionId=${divisionId}&page=${page}&pageSize=${pageSize}&search=${search}&userId=${userId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: organisationId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const postAddVehicle = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Vehicle/AddVehicle`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteVehicle = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/Vehicle/DeleteVehicle/${
    payload ? payload : ""
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getVehicleDetails = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Vehicle/GetVehicleDetails?vehicleId=${data}&orgId=${ORG_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateVehicle = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/Vehicle/UpdateVehicle`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
