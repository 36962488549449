import { newsType } from "../constants/newsType";

export const postcreateNews = (data) => ({
  type: newsType.POST_NEWS_PENDING,
  data,
});
export const postcreateNewsSuccess = (data) => ({
  type: newsType.POST_NEWS_SUCCESS,
  data,
});
export const postcreateNewsFailure = (error) => ({
  type: newsType.POST_NEWS_FAILURE,
  error,
});

export const getNewsList = (data) => ({
  type: newsType.GET_LIST_NEWS_PENDING,
  data,
});

export const getNewsListSuccess = (data) => ({
  type: newsType.GET_LIST_NEWS_SUCCESS,
  data,
});

export const getNewsListFailure = (error) => ({
  type: newsType.GET_LIST_NEWS_FAILURE,
  error,
});
export const clearNewsList = () => ({
  type: newsType.CLEAR_GETNEWS_NEWS,
  data: "",
});
//CLEAR_GETNEWS_NEWS
export const getNewsDetails = (data) => ({
  type: newsType.GET_NEWS_DETAILS_PENDING,
  data,
});

export const getNewsDetailsSuccess = (data) => ({
  type: newsType.GET_NEWS_DETAILS_SUCCESS,
  data,
});

export const getNewsDetailsFailure = (error) => ({
  type: newsType.GET_NEWS_DETAILS_FAILURE,
  error,
});

export const updateNews = (data) => ({
  type: newsType.PUT_NEWS_PENDING,
  data,
});

export const updateNewsSuccess = (data) => ({
  type: newsType.PUT_NEWS_SUCCESS,
  data,
});

export const updateNewsFailure = (error) => ({
  type: newsType.PUT_NEWS_FAILURE,
  error,
});

export const deleteNews = (payload) => ({
  type: newsType.DELETE_NEWS_PENDING,
  payload: payload,
});

export const deleteNewsSuccess = (payload) => ({
  type: newsType.DELETE_NEWS_SUCCESS,
  payload,
});

export const deleteNewsFailure = (error) => ({
  type: newsType.DELETE_NEWS_FAILURE,
  error,
});
