import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import commonImages from "../../../../assets";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { ADMIN_LIST_NEWS } from "../../../../constant/routeContant";
const ViewNews = ({ NewsData }) => {
  const NewsDataResult = NewsData?.data?.news;
  const dataBack = {
    title: "Back to News",
    route: ADMIN_LIST_NEWS,
    //route: access?.isReadAccess ? USERLESSON : LESSON,
  };
  return (
    <>
      <BackBtn dataBack={dataBack} />
      <Grid container className="lessonView">
        <Grid item xs={12}>
          <Grid className="lessonImage">
            <img
              key={NewsData?.file?.id}
              src={
                NewsDataResult?.file?.path
                  ? NewsDataResult?.file?.path
                  : commonImages?.noimagePublish
              }
              alt=""
            />
          </Grid>

          <Grid className="otherDescration">
            <Grid className="subTitle">Description</Grid>
            <Grid className="desc">
              {" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: NewsDataResult?.description,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewNews;
