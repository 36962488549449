export const lmsReportType = {
  GET_LMS_REPORT_PENDING: "[GET_LMS_REPORT][REQUEST] GET LMS REPORT Requested",
  GET_LMS_REPORT_FAILURE: "[GET_LMS_REPORT][RESPONSE] GET LMS REPORT Failed",
  GET_LMS_REPORT_SUCCESS:
    "[GET_LMS_REPORT][RESPONSE] GET LMS REPORT  Successfull",
  LMS_REPORT_PAGE: "LMSREPORTPAGE",

  GET_LMS_REPORT_COURSE_PENDING:
    "[GET_REPORT_COURSES][REQUEST] GET LMS REPORT COURSE Requested",
  GET_LMS_REPORT_COURSE_FAILURE:
    "[GET_REPORT_COURSES][RESPONSE] GET LMS REPORT COURSE Failed",
  GET_LMS_REPORT_COURSE_SUCCESS:
    "[GET_REPORT_COURSES][RESPONSE] GET LMS REPORT COURSE  Successfull",
  LMS_REPORT_COURSE_PAGE: "LMSREPORTCOURSEPAGE",

  GET_LMS_REPORT_LESSON_PENDING:
    "[GET_REPORT_LESSON][REQUEST] GET LMS REPORT LESSON Requested",
  GET_LMS_REPORT_LESSON_FAILURE:
    "[GET_REPORT_LESSON][RESPONSE] GET LMS REPORT LESSON Failed",
  GET_LMS_REPORT_LESSON_SUCCESS:
    "[GET_REPORT_LESSON][RESPONSE] GET LMS REPORT LESSON  Successfull",
  LMS_REPORT_LESSON_PAGE: "LMSREPORTLESSONPAGE",
};
