import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import "./selectMenu.scss";
import selectIcon from "./selectmenuIcon";
export default function SelectMenu({
  listData,
  onchangehandler,
  name,
  value,
  placeholder,
  disabled,
  isError = false,
  errorMsg = "Selection is required for this field",
  isKeyValue = true,
  isId = false,
  disabledDefault,
  isgroupplaceholder,
  customClass = false,
}) {
  return (
    <div className="overideSelectMenu">
      <Select
        IconComponent={selectIcon}
        className="selectBox"
        size="small"
        labelId="demo-simple-select-label"
        value={value}
        name={name}
        displayEmpty
        disabled={disabled}
        id="demo-simple-select"
        onChange={(e) => onchangehandler(e)}
        renderValue={
          !(value == "null" || value == "" || value == null)
            ? undefined
            : () => <div className="placeholder">Please Select</div>
        }
      >
        {disabledDefault ||
          (isgroupplaceholder && (
            <MenuItem disabled={disabledDefault} value="0">
              <em>{placeholder}</em>
            </MenuItem>
          ))}

        {listData &&
          listData.map((data) => {
            return (
              <MenuItem
                key={isKeyValue ? data.key : data.articleCatSubcatId}
                value={isKeyValue ? data.key : data.articleCatSubcatId}
                className={
                  customClass
                    ? `${customClass} selectutilityitems`
                    : "selectutilityitems"
                }
              >
                {isKeyValue
                  ? isId
                    ? `${data.value} (${data.key})`
                    : data.value
                  : data.title}
              </MenuItem>
            );
          })}
      </Select>
      {(value?.length === 0 || !value) && isError && (
        <FormHelperText className="errorText">{errorMsg}</FormHelperText>
      )}
    </div>
  );
}
