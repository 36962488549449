import React from "react";
import { Grid } from "@mui/material";
import "./reportHeader.scss";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";

const ReportHeader = ({ headerData, handlePrint }) => {
  const conducted_On = new Date(headerData?.conducted_On);

  const dateToTime = (date) =>
    date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

  const dateString = headerData?.conducted_On;
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const localDate = new Date(dateString);
  const utcDate = new Date(localDate.getTime() + userOffset);

  return (
    <>
      <div className="overReportHeader">
        <Grid container className="reportHeader">
          <Grid md={6} xs={12} className="reportHeaderLeft">
            <div className="reportTitle">
              Report ID: {headerData?.report_Id}
            </div>
          </Grid>
          <Grid md={6} xs={12} className="reportHeaderRight">
            <div className="reportDesc">
              Reported on:
              <span>
                {`${
                  conducted_On.getMonth() + 1
                }/${conducted_On.getDate()}/${conducted_On.getFullYear()}`}
              </span>
              <span>{`${dateToTime(utcDate)}`}</span>
            </div>
            <Button
              onClick={handlePrint}
              variant="contained"
              startIcon={<DownloadIcon className="downloadIcon" />}
              className="downloadpdf"
            >
              Download as PDF
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ReportHeader;
