import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "../../../../library/button/button";
import "./stolenStatus.scss";

const OtherStolenStatus = ({ stolenStatusData }) => {
  return (
    <>
      {stolenStatusData && (
        <div className="otherStolenOver">
          <Box>
            <Typography variant="h1" className="stolentitle">
              Property Status
            </Typography>
            <Box className="stolenbtnlist">
              <Button varientContained={true} label={stolenStatusData} />
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default OtherStolenStatus;
