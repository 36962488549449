export const questionTypeData = [
  {
    id: 0,
    value: "Acceptable",
    label: "Acceptable",
  },
  {
    id: 1,
    value: "Not Acceptable",
    label: "Not Acceptable",
  },
  {
    id: 2,
    value: "Not Applicable",
    label: "Not Applicable",
  },
];

// Action Taken data
export const actionTakenOptionData = [
  {
    key: 0,
    value: "Yes",
    label: "Yes",
  },
  {
    key: 1,
    value: "No",
    label: "No",
  },
];

// Involved Party Type data
export const InvolvedPartyOptionData = [
  {
    key: 0,
    value: "Employee",
    label: "Employee",
  },
  {
    key: 1,
    value: "Sub-Contractor",
    label: "Sub-Contractor",
  },
];

// Involved Party Type data for Inspection
export const InspectionInvolvedPartyOptionData = [
  {
    key: 0,
    value: "Employee",
    label: "Employee",
  },
  {
    key: 1,
    value: "Sub-Contractor",
    label: "Sub-Contractor",
  },
  {
    key: 2,
    value: "N/A",
    label: "N/A",
  },
];

// Escalate to Reprimand
export const escalatetoReprimand = [
  {
    key: 0,
    value: "Yes",
    label: "Yes",
  },
  {
    key: 1,
    value: "No",
    label: "No",
  },
];

export const closeItem = [
  {
    key: 0,
    value: "Open",
    label: "Open",
  },
  {
    key: 1,
    value: "Closed",
    label: "Closed",
  },
];

export const columnsViewReport = [
  { field: "reportId", headerName: "Report ID", flex: 1 },
  { field: "dateOfEntry", headerName: "Date of Entry", flex: 1.5 },
  { field: "inspectionType", headerName: "Inspection Type", flex: 2 },
  {
    field: "assetType",
    headerName: "Asset Type",
    renderCell: (rows) => {
      return (
        <>
          {rows?.row?.assetType === 1
            ? "Project"
            : rows?.row?.assetType === 3
            ? "Vehicle"
            : rows?.row?.assetType === 2
            ? "Equipment"
            : "Custom asset"}
        </>
      );
    },
    type: "text",
    flex: 1.5,
  },
  {
    field: "asset",
    headerName: "Asset Name",
    type: "text",
    flex: 1.5,
  },
  {
    field: "inspectionBy",
    headerName: "Inspected by",
    type: "text",
    flex: 1.5,
  },
  {
    field: "status",
    headerName: "Status",
    type: "text",
    flex: 1,
  },
];

export const COLUMNS_REPRIMINDS_VIEW = [
  { field: "reprimandDtlId", headerName: "Reprimand ID", flex: 1 },
  { field: "dateofEntry", headerName: "Date of Entry", flex: 2 },
  { field: "jobSite", headerName: "Jobsite", flex: 2 },
  {
    field: "involvedParty",
    headerName: "Involved Party",
    type: "text",
    flex: 1.5,
  },

  {
    field: "inspectionBy",
    headerName: "Inspected by",
    type: "text",
    flex: 1.5,
  },
  {
    field: "status",
    headerName: "Status",
    type: "text",
    flex: 1,
  },
];

//
export const statusOfList = [
  {
    key: 2,
    value: "Open",
    label: "Open",
  },
  {
    key: 3,
    value: "Closed",
    label: "Closed",
  },
];

export const ASSETTYPE = [
  { key: 1, value: "Projects" },
  { key: 3, value: "Vehicles" },
  { key: 2, value: "Equipment" },
  { key: 4, value: "Custom assets" },
];
export const ASSETTYPE_SW = [{ key: 5, value: "Projects" }];
export const ASSET_TYPE_JHA = [
  { key: 1, value: "Projects" },
  { key: 4, value: "Custom assets" },
];