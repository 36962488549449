import React from "react";
import { useNavigate } from "react-router-dom";
import SwInspectionList from "../components/SwInspectionList";
import {
  INSPECTION_TYPE,
  INSPECTION_FORM_LIST,
  ITEM_FOUND_RESPONSES,
  LINK_QUESTION_INSPECTION,
  SETUP_INSPECTION_LIST,
  SW_CATEGORIES,
  SW_SUB_CATEGORIES,
  INSPECTION_GENERATOR,
  INSPECTION_AUTHORITY,
} from "../../../../constant/routeContant";

const SwInspectionManagmentContainer = () => {
  const navigate = useNavigate();
  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(INSPECTION_FORM_LIST);
    } else if (id === 2) {
      navigate(SW_CATEGORIES);
    } else if (id === 3) {
      navigate(SW_SUB_CATEGORIES);
    } else if (id === 4) {
      navigate(LINK_QUESTION_INSPECTION);
    } else if (id === 5) {
      navigate(ITEM_FOUND_RESPONSES);
    } else if (id === 6) {
      navigate(SETUP_INSPECTION_LIST);
    } else if (id === 7) {
      navigate(INSPECTION_TYPE);
    } else if (id === 8) {
      navigate(INSPECTION_GENERATOR);
    } else if (id === 9) {
      navigate(INSPECTION_AUTHORITY);
    }
  };

  return (
    <>
      <div className="dashTitle">Inspection Management</div>
      <SwInspectionList onClick={onClickHandler} />
    </>
  );
};

export default SwInspectionManagmentContainer;
