import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React from "react";
import { useDispatch } from "react-redux";
import * as categoryAction from "../../../../redux/actions/utilityCategoryAction";
import {
  ISwitch,
  ORG_ID,
  LNG_ID,
  USER_ID,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import NORow from "../../../../library/custom/noRow/noRow";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function BasicTable(props) {
  const dispatch = useDispatch();
  const data = props?.data;
  const catselectid = props?.catselectid;
  const categoryPath = props?.categoryPath;
  const handleChange = (event) => {
    const categoryId = event.target.value;
    const statuscategory = {
      flag: catselectid,
      lngId: 1,
      categoryId: categoryId,
      isActive: event.target.checked,
    };

    const statusIncidentcategory = {
      incRefDataId: parseInt(categoryId),
      isActive: event.target.checked,
    };

    if (categoryPath === "support" && catselectid === 1) {
      dispatch(categoryAction.utilitySupportActiveCategory(statuscategory));
    } else if (categoryPath === "incident") {
      dispatch(
        categoryAction.utilityIncidentActiveCategory(statusIncidentcategory)
      );
    } else {
      dispatch(categoryAction.utilityActiveCategory(statuscategory));
    }
  };
  const handleUpdateChange = (row) => {
    const updatecatConfigution = {
      flag: parseInt(catselectid),
      categoryName: row?.value,
      isGlobal: row?.isGlobal == true ? false : true,
      categoryId: row?.key,
      modifiedBy: USER_ID(),
      lngId: parseInt(LNG_ID),
      serviceType: parseInt(PRODUCT_ID()),
    };

    const upateinsdata = {
      type: row?.value,
      orgId: ORG_ID(),
      createdBy: USER_ID(),
      isGlobal: row?.isGlobal == true ? false : true,
      incRefDataId: row?.key,
    };

    if (categoryPath === "support" && catselectid === 1) {
      delete updatecatConfigution.serviceType;
      dispatch(
        categoryAction.updateSupportCategoryConfiguration(updatecatConfigution)
      );
    } else if (categoryPath === "incident") {
      dispatch(
        categoryAction.updateIncidentCategoryConfiguration(upateinsdata)
      );
    } else {
      dispatch(
        categoryAction.updateCategoryConfiguration(updatecatConfigution)
      );
    }
  };
  return (
    <>
      <TableContainer component={Paper} className="tableHead">
        <Table sx={{ minWidth: 330 }} aria-label="simple table">
          <TableHead className="tableHeader">
            <TableRow>
              <TableCell sx={{ borderRadius: 1 }} className="tableHeadercell">
                Category
              </TableCell>
              <TableCell
                align="right"
                sx={{ borderRadius: 1 }}
                className="tableHeadercell"
              >
                Status
              </TableCell>
              {checkPrivileges([12]) &&
                parseInt(ORG_ID()) === HBNEXT_ORG_ID && (
                  <TableCell
                    align="right"
                    sx={{ borderRadius: 1 }}
                    className="tableHeadercell"
                  >
                    Is Global
                  </TableCell>
                )}
            </TableRow>
          </TableHead>
          <TableBody className="tableHeaderbody">
            {data.length === 0 && (
              <>
                <NORow />
              </>
            )}

            {data.length > 0 &&
              data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  children
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className="tableHeaderval"
                  >
                    {row.value}
                  </TableCell>
                  <TableCell align="right">
                    {
                      <div>
                        <ISwitch
                          {...label}
                          defaultChecked={
                            row.statusId === 1 || row.isActive ? true : false
                          }
                          checked={
                            row.statusId === 1 || row.isActive ? true : false
                          }
                          value={row.key}
                          onChange={handleChange}
                          classes="testswitch"
                        />
                      </div>
                    }
                  </TableCell>
                  {checkPrivileges([12]) &&
                    parseInt(ORG_ID()) === HBNEXT_ORG_ID && (
                      <TableCell align="right">
                        {
                          <div>
                            <ISwitch
                              {...label}
                              defaultChecked={
                                row.isGlobal === 1 || row.isGlobal
                                  ? true
                                  : false
                              }
                              checked={
                                row.isGlobal === 1 || row.isGlobal
                                  ? true
                                  : false
                              }
                              value={row.key}
                              onChange={() => handleUpdateChange(row)}
                              classes="testswitch"
                            />
                          </div>
                        }
                      </TableCell>
                    )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
