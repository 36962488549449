import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import commonImages from "../../../assets";
import {
  Card,
  CardContent,
  Box,
  CardActions,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Buttons from "../../../library/custom/button/Button";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import "./FilterCommonStatusSelect.scss";
import DatePicker from "../../../library/custom/datePicker/DatePicker";

const FilterCommonStatusSelect = ({
  id,
  open,
  anchorEl,
  handleClose,
  statusType,
  categoryType,
  category,
  handleApply,
  handleClear,
  resetBtn = false,
  filter,
  categoryOnChanges,
  onChangeType,
  filterSelect = false,
  filterStatus = false,
  label = "Status",
  selectLable,
  filterDate = false,
  toDate,
  fromDate,
  onchangeToDatePicker,
  onchangeFromDatePicker,
  minDate = false,
  scalculatedDate = "",
  statusId = "",
}) => {
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases">
          <CardContent>
            {filterDate && (
              <>
                <Box className="" sx={{ pb: 2 }}>
                <Typography className="filterLabel" gutterBottom variant="h5">
                  Time Period
                </Typography>
                  <Box className="datePicker_row">
                    <div>
                      <Typography variant="subtitle1">From</Typography>
                      <DatePicker
                        value={fromDate}
                        onChangeHandler={(e) => onchangeFromDatePicker(e)}
                        maxDateEnable
                      />
                    </div>
                    <div>
                      <Typography variant="subtitle1">To</Typography>
                      <DatePicker
                        value={toDate}
                        onChangeHandler={(e) => onchangeToDatePicker(e)}
                        maxDateEnable={
                          scalculatedDate && statusId === 9 ? false : true
                        }
                        minDate={fromDate}
                        maxDate={scalculatedDate ?? ""}
                      />
                    </div>
                  </Box>
                </Box>
              </>
            )}

            {filterStatus && (
              <Box className="custom_filter_box">
                <FormGroup>
                  <FormLabels label={label} isRequired={false} />

                  {statusType &&
                    statusType.map((item) => (
                      <FormControlLabel
                        key={item.key}
                        control={
                          <img
                            className="filterCheckbox"
                            title={
                              filter.searchType[0]?.key === item.key
                                ? "tickCheckbox"
                                : "untickCheckbox"
                            }
                            alt=""
                            src={
                              filter.searchType[0]?.key === item.key
                                ? commonImages.check
                                : commonImages.noCheck
                            }
                            onClick={() => onChangeType(item)}
                          />
                        }
                        label={item?.value}
                      />
                    ))}
                </FormGroup>
              </Box>
            )}
            {filterSelect && (
              <Box className="" sx={{ pb: 2, pt: 2 }}>
                <FormLabels
                  label={selectLable ? selectLable : "Category"}
                  isRequired={false}
                />
                <SelectMenu
                  name={"Category"}
                  listData={categoryType}
                  onchangehandler={(e) => categoryOnChanges(e)}
                  value={category}
                  placeholder="Please select"
                />
              </Box>
            )}
          </CardContent>
          <CardActions>
            {resetBtn && (
              <Buttons
                primary={true}
                label={"Reset"}
                onClick={(e) => handleClear(e)}
                size="small"
              />
            )}

            <Buttons
              primary={true}
              label={"Apply"}
              onClick={(e) => handleApply(e)}
              size="small"
            />
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default FilterCommonStatusSelect;
