import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import { sideNavImg } from "../../assets";
import { useNavigate } from "react-router-dom";
import {
  ADD_INSPECTION,
  DASHBOARD,
  SUPPORT,
  OBSERVATION,
  REPRIMAND,
  INCIDENT_LIST,
  VIEW_INSPECTION_REPORT,
  REPRIMAND_LIST,
  CREATE_LESSON,
  CREATE_COURSE,
  LESSON,
  OPEN_ITEMS_LIST,
  VIEW_OBSERVATION_LISTS,
  ADMIN_SAFETY_LOG,
  USERCOURSES,
  INSPECTION_ADMIN,
  ADMIN_CLIENT_LIST,
  ADMIN_UTILITY,
  ADMIN_COMPANY_MANAGEMENT,
  ADMIN_USER_MANAGEMENT,
  ADMIN_ASSET_MANAGEMENT,
  ADMIN_CREDENTIAL_MANAGEMENT,
  ADMIN_RESOURCE,
  REPORTS_ANALYTICS,
  ADDCASE,
  CASES,
  TOOL_BOX_LIST,
  ADMIN_CONTRACTOR_MANAGEMENT,
  ADMIN_CONTRACTOR_DETAILS,
  TOOL_BOX_USER,
  SW_INSPECTION_CONFIGURATION,
  ADMIN_JHA_CONFIGURATION,
  SW_INSPECTION_LIST,
  CONDUCT_JHA,
  CONDUCT_JHA_LIST,
  SW_OPEN_ITEMS,
  SWDASHBOARD,
  ADMIN_PROJECTS_LIST,
  ADMIN_SITE_PROJECTS_LIST,
  SW_INSPECTION_ADD,
  SW_INSPECTION_DAILY_VIEW,
  SW_REPORTS_ANALYTICS,
} from "../../constant/routeContant";
import "./sidebar.scss";
import { logos } from "../../assets";
import { checkPrivileges, showRighSideMenu } from "../../utils/rolesHelper";
import { LOGIN_ORG_ID, HBNEXT_ORG_ID, ORG_ID } from "../../constant/constant";

const AppMenu = ({ handleRoute }) => {
  const navigate = useNavigate();
  const [inspection, setInspection] = React.useState(false);
  const [swInspection, setSwInspection] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);
  const [incident, setIncident] = React.useState(false);
  const [openItem, setOpenItem] = React.useState(false);
  const [training, setTraining] = React.useState(false);
  const [jhaReports, setJHAReports] = React.useState(false);
  const [activeClass, setActiveClass] = useState();
  const showMenu = showRighSideMenu();
  const siteURL =
    checkPrivileges([12, 111, 112, 113]) &&
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)
      ? ADMIN_PROJECTS_LIST
      : parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) &&
        checkPrivileges([108, 109, 110])
      ? ADMIN_SITE_PROJECTS_LIST
      : ADMIN_PROJECTS_LIST;
  const onRouteChange = (url) => {
    handleRoute(url);
    setActiveClass({ active: url });
  };
  const userInfoDetails = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <>
      <div className="logo">
        <img
          src={logos?.logo}
          width="110px"
          className="light-logo"
          alt="no logo"
        />
        <img
          src={logos?.logoWhite}
          width="110px"
          className="dark-logo"
          alt="no logo"
        />
      </div>
      <List component="nav" className="appMenu" disablePadding>
        {/* Dashboard swdahsboard*/}
        <ListItem
          button
          className={
            activeClass?.active === DASHBOARD
              ? "menuItem activeClass"
              : "menuItem"
          }
          onClick={() =>
            onRouteChange(showMenu?.isSW ? SWDASHBOARD : DASHBOARD)
          }
        >
          <ListItemIcon className={"menuItemIcon"}>
            <img src={sideNavImg?.homeIcon} alt="Home" />
          </ListItemIcon>
          <ListItemText className="menuText" primary="Dashboard" />
        </ListItem>
        {/* Admin */}
        {checkPrivileges([
          11, 12, 13, 37, 38, 39, 40, 41, 42, 43, 44, 45, 47, 48, 49, 50, 51,
          52, 53, 54, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
          72, 73, 74, 78, 79, 80, 93, 94, 95, 99, 100, 101, 108, 109, 110, 111,
          112, 113, 120, 121, 122, 123,
        ]) && (
          <ListItem
            button
            onClick={() => setAdmin(!admin)}
            className={"menuItem"}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.adminIcon} alt="Inspection" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Admin" />
            {admin ? (
              <IconExpandLess className="menuIcon" />
            ) : (
              <IconExpandMore className="menuIcon" />
            )}
          </ListItem>
        )}
        <Collapse in={admin} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {checkPrivileges([12, 67, 68, 69]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_CLIENT_LIST
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_CLIENT_LIST)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Client Management"
                />
              </ListItem>
            )}
            {showMenu?.isSW &&
              checkPrivileges([111, 112, 113, 108, 109, 110, 12]) && (
                <ListItem
                  button
                  className={
                    activeClass?.active === siteURL
                      ? "menuItem activeClass"
                      : "menuItem"
                  }
                  onClick={() => onRouteChange(siteURL)}
                >
                  <ListItemText
                    className="menuSubText"
                    inset
                    primary="Site Management"
                  />
                </ListItem>
              )}

            {checkPrivileges([40, 41, 42, 49, 50, 51, 73, 12]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_COMPANY_MANAGEMENT
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_COMPANY_MANAGEMENT)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Company Management"
                />
              </ListItem>
            )}

            {checkPrivileges([12, 55, 56, 57, 64, 65, 66, 77, 76]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_USER_MANAGEMENT
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_USER_MANAGEMENT)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="User Management"
                />
              </ListItem>
            )}

            {showMenu?.isSafety &&
              checkPrivileges([
                12, 37, 38, 39, 52, 53, 54, 70, 71, 72, 78, 79, 80,
              ]) && (
                <ListItem
                  button
                  className={
                    activeClass?.active === ADMIN_ASSET_MANAGEMENT
                      ? "menuItem activeClass"
                      : "menuItem"
                  }
                  onClick={() => onRouteChange(ADMIN_ASSET_MANAGEMENT)}
                >
                  <ListItemText
                    className="menuSubText"
                    inset
                    primary="Asset Management"
                  />
                </ListItem>
              )}

            {checkPrivileges([12, 13, 47, 48]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_CREDENTIAL_MANAGEMENT
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_CREDENTIAL_MANAGEMENT)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Credential Management"
                />
              </ListItem>
            )}

            {checkPrivileges([
              12, 1, 4, 11, 90, 7, 64, 78, 52, 43, 37, 13, 44,
            ]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_UTILITY
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_UTILITY)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Configuration Management"
                />
              </ListItem>
            )}

            {showMenu?.isSafety && checkPrivileges([12, 120, 121, 122]) && (
              <ListItem
                button
                className={
                  activeClass?.active === INSPECTION_ADMIN
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(INSPECTION_ADMIN)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Inspection Management"
                />
              </ListItem>
            )}

            {showMenu?.isSW &&
              checkPrivileges([12, 93, 94, 95, 96, 97, 98, 99, 100, 101]) && (
                <ListItem
                  button
                  className={
                    activeClass?.active === SW_INSPECTION_CONFIGURATION
                      ? "menuItem activeClass"
                      : "menuItem"
                  }
                  onClick={() => onRouteChange(SW_INSPECTION_CONFIGURATION)}
                >
                  <ListItemText
                    className="menuSubText"
                    inset
                    primary="Inspection Management"
                  />
                </ListItem>
              )}

            {showMenu?.isSafety && checkPrivileges([12, 43, 44, 45]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADMIN_CONTRACTOR_MANAGEMENT
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADMIN_CONTRACTOR_MANAGEMENT)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Contractor Management"
                />
              </ListItem>
            )}

            {showMenu?.isSafety &&
              checkPrivileges([12, 58, 59, 60, 61, 62, 63]) && (
                <ListItem
                  button
                  className={
                    activeClass?.active === ADMIN_JHA_CONFIGURATION
                      ? "menuItem activeClass"
                      : "menuItem"
                  }
                  onClick={() => onRouteChange(ADMIN_JHA_CONFIGURATION)}
                >
                  <ListItemText
                    className="menuSubText"
                    inset
                    primary="Hazard Analysis Configuration"
                  />
                </ListItem>
              )}
          </List>
        </Collapse>

        {/* inspection */}
        {showMenu?.isSafety && checkPrivileges([12, 16, 4, 5, 6]) && (
          <ListItem
            button
            onClick={() => setInspection(!inspection)}
            className={"menuItem"}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.inspections} alt="Inspection" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Inspections" />
            {inspection ? (
              <IconExpandLess className="menuIcon" />
            ) : (
              <IconExpandMore className="menuIcon" />
            )}
          </ListItem>
        )}
        <Collapse in={inspection} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {checkPrivileges([12, 16, 4, 5]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADD_INSPECTION
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADD_INSPECTION)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Add Report"
                />
              </ListItem>
            )}
            {checkPrivileges([12, 16, 4, 5, 6]) && (
              <ListItem
                button
                className={
                  activeClass?.active === VIEW_INSPECTION_REPORT
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(VIEW_INSPECTION_REPORT)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="View Report"
                />
              </ListItem>
            )}
          </List>
        </Collapse>

        {/* sw inspection */}
        {showMenu?.isSW && checkPrivileges([12, 102, 103, 104]) && (
          <>
            <ListItem
              button
              onClick={() => setSwInspection(!swInspection)}
              className={"menuItem"}
            >
              <ListItemIcon className={"menuItemIcon"}>
                <img src={sideNavImg?.inspections} alt="swInspection" />
              </ListItemIcon>
              <ListItemText className="menuText" primary="Inspections" />
              {inspection ? (
                <IconExpandLess className="menuIcon" />
              ) : (
                <IconExpandMore className="menuIcon" />
              )}
            </ListItem>
            <Collapse in={swInspection} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" disablePadding>
                {checkPrivileges([12, 102, 103]) && (
                  <ListItem
                    button
                    className={
                      activeClass?.active === SW_INSPECTION_ADD
                        ? "menuItem activeClass"
                        : "menuItem"
                    }
                    onClick={() => onRouteChange(SW_INSPECTION_ADD)}
                  >
                    <ListItemText
                      className="menuSubText"
                      inset
                      primary="Add Report"
                    />
                  </ListItem>
                )}
                {checkPrivileges([12, 102, 103, 104]) && (
                  <>
                    <ListItem
                      button
                      className={
                        activeClass?.active === SW_INSPECTION_LIST
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(SW_INSPECTION_LIST)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="View Report"
                      />
                    </ListItem>
                    <ListItem
                      button
                      className={
                        activeClass?.active === SW_INSPECTION_DAILY_VIEW
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(SW_INSPECTION_DAILY_VIEW)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="View Daily Report"
                      />
                    </ListItem>
                  </>
                )}
              </List>
            </Collapse>
          </>
        )}

        {/* JHA inspection */}
        {showMenu?.isSafety && checkPrivileges([12, 123, 124, 125]) && (
          <ListItem
            button
            onClick={() => setJHAReports(!jhaReports)}
            className={"menuItem"}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.analytics} alt="JHA Inspection" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Hazard Analysis" />
            {jhaReports ? (
              <IconExpandLess className="menuIcon" />
            ) : (
              <IconExpandMore className="menuIcon" />
            )}
          </ListItem>
        )}
        <Collapse in={jhaReports} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {showMenu?.isSafety && checkPrivileges([12, 123, 124]) && (
              <ListItem
                button
                className={
                  activeClass?.active === CONDUCT_JHA
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(CONDUCT_JHA)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Add Hazard Analysis Report"
                />
              </ListItem>
            )}
            {checkPrivileges([12, 123, 124, 125]) && (
              <ListItem
                button
                className={
                  activeClass?.active === CONDUCT_JHA_LIST
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(CONDUCT_JHA_LIST)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="View Hazard Analysis Report"
                />
              </ListItem>
            )}
          </List>
        </Collapse>

        {/*incident */}
        {showMenu?.isSafety && checkPrivileges([1, 2, 3, 12]) && (
          <ListItem
            button
            onClick={() => setIncident(!incident)}
            className={"menuItem"}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.incidentmanagement} alt="Incidents" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Incident Management" />
            {incident ? (
              <IconExpandLess className="menuIcon" />
            ) : (
              <IconExpandMore className="menuIcon" />
            )}
          </ListItem>
        )}
        <Collapse in={incident} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {checkPrivileges([1, 2, 3, 12]) && (
              <ListItem
                button
                className={
                  activeClass?.active === CASES
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(CASES)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="View Cases"
                />
              </ListItem>
            )}
            {checkPrivileges([1, 2, 12]) && (
              <ListItem
                button
                className={
                  activeClass?.active === ADDCASE
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(ADDCASE)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Add Case"
                />
              </ListItem>
            )}

            {checkPrivileges([12, 1, 2, 3]) && (
              <ListItem
                button
                className={
                  activeClass?.active === INCIDENT_LIST
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(INCIDENT_LIST)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="View Incidents"
                />
              </ListItem>
            )}
          </List>
        </Collapse>

        {/*SW Item Management */}
        {showMenu?.isSW && checkPrivileges([12, 105, 106, 107]) && (
          <ListItem
            button
            className={
              activeClass?.active === SW_OPEN_ITEMS
                ? "menuItem activeClass"
                : "menuItem"
            }
            onClick={() => onRouteChange(SW_OPEN_ITEMS)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.observations} alt="Item Management" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Item Management" />
          </ListItem>
        )}

        {/* Item management */}
        {showMenu?.isSafety &&
          checkPrivileges([12, 81, 82, 83, 84, 85, 86, 117, 118, 119]) && (
            <>
              <ListItem
                button
                onClick={() => setOpenItem(!openItem)}
                className={"menuItem"}
              >
                <ListItemIcon className={"menuItemIcon"}>
                  <img src={sideNavImg?.observations} alt="Reprimand" />
                </ListItemIcon>
                <ListItemText className="menuText" primary="Item Management" />
                {openItem ? (
                  <IconExpandLess className="menuIcon" />
                ) : (
                  <IconExpandMore className="menuIcon" />
                )}
              </ListItem>

              <Collapse in={openItem} timeout="auto" unmountOnExit>
                <Divider />
                <List component="div" disablePadding>
                  {checkPrivileges([12, 117, 118, 119]) && (
                    <ListItem
                      button
                      className={
                        activeClass?.active === OPEN_ITEMS_LIST
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(OPEN_ITEMS_LIST)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="View Open Items"
                      />
                    </ListItem>
                  )}
                  {checkPrivileges([12, 84, 85]) && (
                    <ListItem
                      button
                      className={
                        activeClass?.active === OBSERVATION
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(OBSERVATION)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="Add Observations"
                      />
                    </ListItem>
                  )}
                  {checkPrivileges([12, 84, 85, 86]) && (
                    <ListItem
                      button
                      className={
                        activeClass?.active === VIEW_OBSERVATION_LISTS
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(VIEW_OBSERVATION_LISTS)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="View Observations"
                      />
                    </ListItem>
                  )}
                  {checkPrivileges([12, 81, 82]) && (
                    <ListItem
                      button
                      className={
                        activeClass?.active === REPRIMAND
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(REPRIMAND)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="Add Reprimand"
                      />
                    </ListItem>
                  )}
                  {checkPrivileges([12, 81, 82, 83]) && (
                    <ListItem
                      button
                      className={
                        activeClass?.active === REPRIMAND_LIST
                          ? "menuItem activeClass"
                          : "menuItem"
                      }
                      onClick={() => onRouteChange(REPRIMAND_LIST)}
                    >
                      <ListItemText
                        className="menuSubText"
                        inset
                        primary="View Reprimand"
                      />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

        {/* Resource Library */}
        {checkPrivileges([73, 74, 75, 12]) && (
          <ListItem
            button
            className={
              activeClass?.active === ADMIN_RESOURCE
                ? "menuItem activeClass"
                : "menuItem"
            }
            onClick={() => onRouteChange(ADMIN_RESOURCE)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.resourcelibrary} alt="Resource" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Resource Library" />
          </ListItem>
        )}

        {/* Learning Management */}
        {checkPrivileges([12, 9, 10, 11, 90, 91, 92]) && (
          <ListItem
            button
            className={"menuItem"}
            onClick={() => setTraining(!training)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.learningmanagement} alt="Training" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Learning Management" />
            {training ? (
              <IconExpandLess className="menuIcon" />
            ) : (
              <IconExpandMore className="menuIcon" />
            )}
          </ListItem>
        )}
        <Collapse in={training} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {checkPrivileges([12, 11]) && (
              <ListItem
                button
                className={
                  activeClass?.active === CREATE_LESSON
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(CREATE_LESSON)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Create Lesson"
                />
              </ListItem>
            )}
            {checkPrivileges([12, 11]) && (
              <ListItem
                button
                className={
                  activeClass?.active === CREATE_COURSE
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(CREATE_COURSE)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="Create Course"
                />
              </ListItem>
            )}
            {checkPrivileges([12, 10, 11]) && (
              <ListItem
                button
                className={
                  activeClass?.active === LESSON
                    ? "menuItem activeClass"
                    : "menuItem"
                }
              >
                <ListItemText
                  inset
                  className="menuSubText"
                  primary="Training Management"
                  onClick={() => onRouteChange(LESSON)}
                />
              </ListItem>
            )}
            {checkPrivileges([12, 9, 10, 11]) && (
              <ListItem
                button
                className={
                  activeClass?.active === USERCOURSES
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(USERCOURSES)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="My Training"
                />
              </ListItem>
            )}
            {showMenu?.isSafety && checkPrivileges([12, 90, 91]) && (
              <ListItem
                button
                className={
                  activeClass?.active === TOOL_BOX_LIST
                    ? "menuItem activeClass"
                    : "menuItem"
                }
              >
                <ListItemText
                  inset
                  className="menuSubText"
                  primary="Safety Meeting Management"
                  onClick={() => onRouteChange(TOOL_BOX_LIST)}
                />
              </ListItem>
            )}
            {showMenu?.isSafety && checkPrivileges([12, 90, 91, 92]) && (
              <ListItem
                button
                className={
                  activeClass?.active === TOOL_BOX_USER
                    ? "menuItem activeClass"
                    : "menuItem"
                }
                onClick={() => onRouteChange(TOOL_BOX_USER)}
              >
                <ListItemText
                  className="menuSubText"
                  inset
                  primary="My Safety Meetings"
                />
              </ListItem>
            )}
          </List>
        </Collapse>

        {/* Safetylog */}
        {showMenu?.isSafety && checkPrivileges([12, 135]) && (
          <ListItem
            button
            className={
              activeClass?.active === ADMIN_SAFETY_LOG
                ? "menuItem activeClass"
                : "menuItem"
            }
            onClick={() => onRouteChange(ADMIN_SAFETY_LOG)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.analytics} alt="Analytics" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Safety Log" />
          </ListItem>
        )}

        {/* Reports & Analytics */}
        {showMenu?.isSafety && checkPrivileges([12, 114, 115, 116]) && (
          <ListItem
            button
            className={
              activeClass?.active === REPORTS_ANALYTICS
                ? "menuItem activeClass"
                : "menuItem"
            }
            onClick={() => onRouteChange(REPORTS_ANALYTICS)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.analytics} alt="Analytics" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Reports & Analytics" />
          </ListItem>
        )}

        {showMenu?.isSW && checkPrivileges([12, 142]) && (
          <ListItem
            button
            className={
              activeClass?.active === SW_REPORTS_ANALYTICS
                ? "menuItem activeClass"
                : "menuItem"
            }
            onClick={() => onRouteChange(SW_REPORTS_ANALYTICS)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img src={sideNavImg?.analytics} alt="Analytics" />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Reports & Analytics" />
          </ListItem>
        )}

        {/* Support */}
        <ListItem
          button
          className={
            activeClass?.active === SUPPORT
              ? "menuItem activeClass"
              : "menuItem"
          }
          onClick={() => onRouteChange(SUPPORT)}
        >
          <ListItemIcon className={"menuItemIcon"}>
            <img src={sideNavImg?.supportresources} alt="Support" />
          </ListItemIcon>
          <ListItemText className="menuText" primary="Support" />
        </ListItem>

        {/* Contactor */}
        {userInfoDetails?.isContractor && (
          <ListItem
            button
            className={"menuItem"}
            onClick={() => onRouteChange(ADMIN_CONTRACTOR_DETAILS)}
          >
            <ListItemIcon className={"menuItemIcon"}>
              <img
                width="30px"
                height="30px"
                src={sideNavImg?.personnelcontractor}
                alt="PersonnelContractor"
              />
            </ListItemIcon>
            <ListItemText className="menuText" primary="Personnel Management" />
          </ListItem>
        )}
      </List>
    </>
  );
};

export default AppMenu;
