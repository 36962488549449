import React, { useEffect } from "react";
import NewsList from "../component/news/listsNews";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE, API_STATUS } from "../../../constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../library/common/Loader";
import * as newsAction from "../../../redux/actions/newsAction";

const NewsListsContainer = () => {
  const location = useLocation();
  const fieldid = location?.state?.fieldId;
  const dispatch = useDispatch();
  const getNewsList = useSelector((state) => state.getNewsList);
  const NewsData = getNewsList?.data;
  const isLoading = getNewsList?.isLoading;
  const NewsdeleteStatus = useSelector((state) => state.deleteNews.status);

  const getAssignLessonUserListData = useSelector(
    (state) => state?.getAssignLessonUserList
  );

  useEffect(() => {
    if (NewsdeleteStatus == API_STATUS.SUCCESS) {
      const defaultData = {
        page: 1,
        pageSize: PAGE_SIZE,
      };
      dispatch(newsAction.getNewsList(defaultData));
    }
  }, [NewsdeleteStatus]);

  let navigate = useNavigate();

  const getNewsListData = async (data) => {
    await dispatch(newsAction.getNewsList(data));
  };

  useEffect(() => {
    const defaultData = {
      page: 1,
      pageSize: PAGE_SIZE,
    };
    dispatch(newsAction.getNewsList(defaultData));
  }, []);
  const deleteNews = (data) => {
    dispatch(newsAction.deleteNews(data));
  };
  return (
    <>
      {isLoading && <Loader />}

      <NewsList
        getNewsListData={getNewsListData}
        newsListData={NewsData}
        isLoading={getAssignLessonUserListData?.isLoading}
        fieldid={fieldid}
        deleteNews={deleteNews}
      />
    </>
  );
};

export default NewsListsContainer;
