export const quizType = {
  POST_QUIZ_PENDING: "[QUIZ][REQUEST] Create Quiz",
  POST_QUIZ_FAILURE: "[QUIZ][RESPONSE] Quiz Creation Failed",
  POST_QUIZ_SUCCESS: "[QUIZ][RESPONSE] Quiz Created Successfull",
  CREATE_PAGE: "CreatePage",


  GET_QUIZ_LIST_PENDING: "[GET_QUIZ_LIST][REQUEST] Get Quiz list requested",
  GET_QUIZ_LIST_FAILURE: "[GET_QUIZ_LIST][RESPONSE] Get Quiz list Failed",
  GET_QUIZ_LIST_SUCCESS: "[GET_QUIZ_LIST][RESPONSE] CGet Quiz list Successfull",
  QUIZ_PAGE: "QuizPage",


  POST_SUBMIT_QUIZ_PENDING: "[QUIZ][REQUEST] Submit Quiz",
  POST_SUBMIT_QUIZ_FAILURE: "[QUIZ][RESPONSE] Submit Quiz Failed",
  POST_SUBMIT_QUIZ_SUCCESS: "[QUIZ][RESPONSE] Submit Quiz Successfull",
  SUBMIT_QUIZ: "SubmitQuizPage",


  GET_QUIZ_DETAILS: "[GET_QUIZ_DETAILST] Get Quiz DETAILS Data",
  GET_QUIZ_DETAILS_SUCCESS:
    "[GET_QUIZ_DETAILS_SUCCESS] Get quiz DETAILS Data Successfull",
  GET_QUIZ_DETAILS_FAILURE: "[GET_QUIZ_DETAILS_FAILURE] Get quiz DETAILS Data Failed",
  QUIZ_PAGE_DETAIL: "Quiz Details",

  POST_UPDATE_QUIZ_PENDING: "[QUIZ][REQUEST] Update Quiz",
  POST_UPDATE_QUIZ_FAILURE: "[QUIZ][RESPONSE] Update Quiz Failed",
  POST_UPDATE_QUIZ_SUCCESS: "[QUIZ][RESPONSE] Update Quiz Successfull",
  UPDATE_QUIZ: "UpdateQuizPage",

}