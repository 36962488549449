import {
  Edit,
  FileDownload,
  MoreVert,
  RemoveRedEye,
} from "@mui/icons-material";
import { IconButton, Menu } from "@mui/material";
import React from "react";
import DownloadCsv from "../../../feature/download_file/DownloadCsv";
import commonImages from "../../../assets";

export default function TableRowAction(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dowloadCsv = React.useRef();
  const {
    rows: { row },
    viewOnClick,
    editOnClick,
    publish,
    publishText,
    publishOnClick,
    edit,
    download,
    view,
    deleted,
    deleteOnClick,
    viewLabel = "View Incident",
    deleteLabel = "Delete Incident",
    editLabel = "Edit Incident",
    downloadLabel = "Download Incident",
    viewOnAttendeeClick,
    VerifyAttendees,
    viewAttendeeLabel,
    CloseIncident = false,
    CloseIncidentLabel,
    closeOnClick,
    AssignAttendees,
    AssignAttendeeOnClick,
    ScheduleAttendeeLabel,
    scheduleMeetingOnclick,
    assignLabel,
    ScheduleMeetings,
    selfAssignClick,
    SelfAssign,
    selfAssignLabel,
  } = props;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="setting-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="setting_button_ui">
          <div className="dropdownList_ui">
            <ul>
              {view && (
                <li onClick={() => viewOnClick(row)}>
                  <RemoveRedEye /> <span>{viewLabel}</span>
                </li>
              )}

              {edit && (
                <li
                  // className={statusClosed ? "disabledBtnLi" : null}
                  onClick={() => editOnClick(row)}
                >
                  <Edit /> <span>{editLabel}</span>
                </li>
              )}
              {download && (
                <li onClick={() => dowloadCsv.current.link.click()}>
                  <FileDownload /> <span>{downloadLabel}</span>
                </li>
              )}
              {AssignAttendees && (
                <li onClick={() => AssignAttendeeOnClick(row)}>
                  <img
                    src={commonImages.assignAteendee}
                    className="deleteImg"
                    alt=""
                  />{" "}
                  <span>{assignLabel}</span>
                </li>
              )}
              {ScheduleMeetings && (
                <li onClick={() => scheduleMeetingOnclick(row)}>
                  <img
                    src={commonImages.schedulemeeting}
                    className="deleteImg"
                    alt=""
                  />
                  <span> {ScheduleAttendeeLabel}</span>
                </li>
              )}
              {VerifyAttendees && (
                <li onClick={() => viewOnAttendeeClick(row)}>
                  <img
                    src={commonImages.check_circleAttendee}
                    className="deleteImg"
                    alt=""
                  />
                  <span> {viewAttendeeLabel}</span>
                </li>
              )}
              {deleted && (
                <li
                  // className={statusClosed ? "disabledBtnLi" : null}
                  onClick={() => deleteOnClick(row)}
                >
                  <img
                    src={commonImages.deleteblueIcon}
                    className="deleteImg"
                    alt=""
                  />
                  <span> {deleteLabel}</span>
                </li>
              )}
              {CloseIncident && (
                <li onClick={() => closeOnClick(row)}>
                  <img
                    src={commonImages.closeIncident}
                    width="20px"
                    height="25px"
                    className="closeIncident"
                    alt=""
                  />
                  <span> {CloseIncidentLabel}</span>
                </li>
              )}
              {publish && (
                <li onClick={() => publishOnClick(row)}>
                  {/* <RemoveRedEye /> */}
                  {publishText === "Publish Lesson" ||
                  publishText === "Publish Course" ? (
                    <img
                      src={commonImages.publishIcon}
                      className="deleteImg"
                      alt=""
                    />
                  ) : (
                    <img
                      src={commonImages.unpublishIcon}
                      className="deleteImg"
                      alt=""
                    />
                  )}

                  <span>{publishText}</span>
                </li>
              )}
              {SelfAssign && (
                <li onClick={() => selfAssignClick(row)}>
                  <img
                    src={commonImages.selfassign}
                    className="deleteImg"
                    alt=""
                  />
                  <span> {selfAssignLabel}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Menu>
      <DownloadCsv
        ref={dowloadCsv}
        data={[row]}
        filename={"list-of-incident.csv"}
      />
    </>
  );
}
