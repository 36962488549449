export const vehicalAccidentType = {
  POST_VEHICAL_ACCIDENT_PENDING:
    "[POST_VEHICAL_ACCIDENT_PENDING][REQUEST] Try To create Vehical Accident",
  POST_VEHICAL_ACCIDENT_FAILURE:
    "[POST_VEHICAL_ACCIDENT_FAILURE][RESPONSE] Vehical Accident Failed",
  POST_VEHICAL_ACCIDENT_SUCCESS:
    "[POST_VEHICAL_ACCIDENT_SUCCESS][RESPONSE] Vehical Accident Successfull",
  PUT_VEHICAL_ACCIDENT_PENDING:
    "[PUT_VEHICAL_ACCIDENT_PENDING][REQUEST] Try To Update Vehical Accident",
  PUT_VEHICAL_ACCIDENT_FAILURE:
    "[PUT_VEHICAL_ACCIDENT_FAILURE][RESPONSE] Update Vehical Accident Failed",
  PUT_VEHICAL_ACCIDENT_SUCCESS:
    "[PUT_VEHICAL_ACCIDENT_SUCCESS][RESPONSE] Update Vehical Accident Successfull",

  VEHICAL_ACCIDENT_PAGE: "VehicalAccidentPage",

  GET_VEHICAL_ACCIDENT: "[REQUEST] Get Vehicle Report Data",
  GET_VEHICAL_ACCIDENT_SUCCESS:
    "[RESPONSE] Get Vehicle Report Data Successfull",
  GET_VEHICAL_ACCIDENT_FAILURE: "[RESPONSE] Get Vehicle Report Data Failed",

  GET_REG_NUMBER: "[REQUEST] Get Reg Number",
  GET_REG_NUMBER_SUCCESS: "[RESPONSE] Get Reg Number Successfull",
  GET_REG_NUMBER_FAILURE: "[RESPONSE] Get Reg Number Failed",
  CLEAR_VEHICAL_ACCIDENT:
    "[CLEAR_VEHICAL_ACCIDENT][REQUEST] Clear Vehicle Accident",
};
