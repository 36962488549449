import React, { useState } from "react";
import { Grid } from "@mui/material";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import {
  HBNEXT_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
} from "../../../../constant/constant";
import "./viewSite.scss";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import ToolBar from "../../../../library/storm-water/toolBar";
import { useNavigate } from "react-router-dom";
import { ADMIN_ADD_SITE } from "../../../../constant/routeContant";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const ViewSite = ({
  getSiteDetsilsData,
  dataBack,
  toggleProjectDetails,
  swprojectListData,
  isLoading,
  siteId,
  ids,
  swProjectList,
  isGlobal,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [searchdata, setSearchdata] = useState({});
  const [selectClient, setSelectClient] = useState("");
  const [showGrid, setShowGrid] = useState(false);

  const isHBNext =
    (parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) || !isGlobal) &&
    checkPrivileges([12, 108, 109]);

  const swProjectcolumns = [
    { field: "projectNumber", headerName: "Project No.", flex: 1.5 },
    { field: "customer", headerName: "Client", flex: 2 },
    {
      field: "site",
      headerName: "Site",
      flex: 1,
      renderCell: (rows) => {
        return <div>{rows?.row?.site ? rows?.row?.site : "N/A"}</div>;
      },
    },
    { field: "name", headerName: "Project Name", flex: 1.5 },
    { field: "city", headerName: "City", flex: 1.5 },

    { field: "state", headerName: "State", flex: 1.6 },
    { field: "startDate", headerName: "Start Date", flex: 1.8 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (rows) => {
        return <div>{rows?.row?.isActive ? "Active" : "InActive"}</div>;
      },
      flex: 1.5,
    },
  ];

  const [swProjectListApiData, setSwProjectListApiData] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    startDate: "",
    endDate: "",
    customerId: ids ?? "",
    status: "",
    divisionId: "",
    siteId: siteId,
  });
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    setSwProjectListApiData({
      ...swProjectListApiData,
      page: newPage + 1,
      siteId: siteId,
    });
    const data = {
      ...swProjectListApiData,
      customerId: ids,
      page: newPage + 1,
      startDate: searchdata?.startDate || "",
      endDate: searchdata?.endDate || "",
      status: searchdata?.status || "",
      search: search ? search : "",
    };

    swProjectList(data);
  };
  const toolBarDataEditMode = {
    title: "Site Tools",
    list: [
      {
        id: 1,
        title: "Edit Site",
        link: "",
        isshow: true,
      },
      {
        id: 2,
        title: "Add Site",
        link: "",
        isshow: true,
      },
    ],
  };
  return (
    <div className="overideViewSite">
      <Grid container>
        <Grid lg={8} md={12} xs={12} pr={2}>
          <div className="topDetails">
            <div className="title">Site Details</div>
            <div className="detailWrapper">
              <div className="siteDetailTitle">Site Details</div>
              <div className="siteName">{getSiteDetsilsData?.siteName}</div>
              <div className="siteAddress description">
                {getSiteDetsilsData?.address}
              </div>
              {isHBNext && (
                <>
                  <div className="description">
                    No. of Projects:{" "}
                    <strong>{getSiteDetsilsData?.noOfproject}</strong>
                  </div>
                  <div className="description">
                    No. of Clients:{" "}
                    <strong>{getSiteDetsilsData?.noOfCustomer}</strong>
                  </div>
                </>
              )}
            </div>
          </div>
          {isHBNext && (
            <div className="customerListWrapper">
              <div className="topDetails">
                <div
                  className="title"
                  style={{
                    fontWeight: 700,
                    marginBottom: "7px",
                    fontSize: "16px",
                  }}
                >
                  Select Client
                </div>
              </div>
              <div>
                <SelectMenu
                  placeholder={"Please Select"}
                  listData={getSiteDetsilsData?.customer ?? []}
                  value={selectClient}
                  onchangehandler={(e) => {
                    setSelectClient(e.target.value);
                    toggleProjectDetails(e.target.value);
                    setShowGrid(true);
                  }}
                />
              </div>
              {showGrid && (
                <>
                  <div className="topDetails">
                    <div
                      className="title"
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",

                        marginTop: "25px",
                      }}
                    >
                      {" "}
                      Projects List
                    </div>
                  </div>
                  <div className="customerList">
                    <GridTable
                      getRowId={(r) => r.projectId}
                      rows={swprojectListData?.data?.projects ?? []}
                      rowCount={swprojectListData?.data?.itemsCount ?? 0}
                      columns={swProjectcolumns}
                      loading={isLoading}
                      checkboxSelection={false}
                      page={1}
                      pagination
                      pageSize={100}
                      rowsPerPageOptions={[100]}
                      onPageChange={handlePagignation}
                      backIconButtonProps={{ disabled: false }}
                      disableSelectionOnClick
                      components={{
                        LoadingOverlay: TableLoader,
                        NoRowsOverlay: NORow,
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <Grid container mt={3} spacing={1}>
            <Grid lg={2} xs={12} sm={4} md={4}>
              <BackBtn dataBack={dataBack} />
            </Grid>
          </Grid>
        </Grid>
        <Grid lg={4} md={12} xs={12} pl={1}>
          <div className="toolBarBox">
            <div className="otherDetail">
              <h3>Site Details</h3>
              <h2>{getSiteDetsilsData?.siteName}</h2>
            </div>

            <div className="otherDetail">
              <h3>Client Name</h3>
              {getSiteDetsilsData?.customer?.map((item, index) => {
                return <h2 key={index}>{item?.value}</h2>;
              })}
            </div>

            <ToolBar
              toolBarData={toolBarDataEditMode}
              handleClick={(e) => {
                if (e.id === 2) {
                  navigate(ADMIN_ADD_SITE);
                }
                if (e.id === 1) {
                  navigate(ADMIN_ADD_SITE, {
                    state: {
                      editMode: true,
                      siteId: siteId,
                    },
                  });
                }
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewSite;
