export const getArticleCatSubcategorySagaType = {
  GET_ARTICLE_CAT_SUBCATEGORY_PENDING: "[REQUEST] Get FAQ",
  GET_ARTICLE_CAT_SUBCATEGORY_SUCCESS: "[RESPONSE] Get FAQ Successfull",
  GET_ARTICLE_CAT_SUBCATEGORY_FAILURE: "[RESPONSE] Get FAQ DatFailed",
};

export const getTicketCategoryType = {
  GET_TICKET_CATEGORY_PENDING: "[REQUEST] Get TICKET CAT",
  GET_TICKET_CATEGORY_SUCCESS: "[RESPONSE] Get TICKET CAT Successfull",
  GET_TICKET_CATEGORY_FAILURE: "[RESPONSE] Get TICKET CAT DatFailed",
};

export const getArticleType = {
  GET_ARTICLE_PENDING: "[REQUEST] Get ARTICLE",
  GET_ARTICLE_SUCCESS: "[RESPONSE] Get ARTICLE Successfull",
  GET_ARTICLE_FAILURE: "[RESPONSE] Get ARTICLE DatFailed",
};

export const getArticlesBasedCatSubcategoryType = {
  GET_ARTICLE_BASED_CAT_SUBCATEGORY_PENDING:
    "[REQUEST] Get ARTICLE_BASED_CAT_SUBCATEGORY",
  GET_ARTICLE_BASED_CAT_SUBCATEGORY_SUCCESS:
    "[RESPONSE] Get ARTICLE_BASED_CAT_SUBCATEGORY Successfull",
  GET_ARTICLE_BASED_CAT_SUBCATEGORY_FAILURE:
    "[RESPONSE] Get ARTICLE_BASED_CAT_SUBCATEGORY DatFailed",
};

export const getArticleDetailsType = {
  GET_ARTICLE_DETAILS_PENDING: "[REQUEST] Get ARTICLE_DETAILS",
  GET_ARTICLE_DETAILS_SUCCESS: "[RESPONSE] Get ARTICLE_DETAILS Successfull",
  GET_ARTICLE_DETAILS_FAILURE: "[RESPONSE] Get ARTICLE_DETAILS DatFailed",
};

export const addLikeDislikeArticleType = {
  POST_ADD_LIKE_DISLIKE_ARTICLE_PENDING:
    "[OBSERVATION][REQUEST] Create ADD_LIKE_DISLIKE_ARTICLE",
  POST_ADD_LIKE_DISLIKE_ARTICLE_FAILURE:
    "[OBSERVATION][RESPONSE] observation ADD_LIKE_DISLIKE_ARTICLE Failed",
  POST_ADD_LIKE_DISLIKE_ARTICLE_SUCCESS:
    "[OBSERVATION][RESPONSE] observation ADD_LIKE_DISLIKE_ARTICLE Successfull",
};

export const getTicketsType = {
  GET_TICKETS_PENDING: "[REQUEST] Get TICKETS",
  GET_TICKETS_SUCCESS: "[RESPONSE] Get TICKETS Successfull",
  GET_TICKETS_FAILURE: "[RESPONSE] Get TICKETS DatFailed",
};

export const getTicketDetailsType = {
  GET_TICKET_DETAILS_PENDING: "[REQUEST] Get TICKET DETAILS",
  GET_TICKET_DETAILS_SUCCESS: "[RESPONSE] Get TICKET DETAILS Successfull",
  GET_TICKETS_DETAILS_FAILURE: "[RESPONSE] Get TICKET DETAILS DatFailed",
};

export const createTicketType = {
  POST_CREATE_TICKET_PENDING: "[OBSERVATION][REQUEST] CREATE_TICKET",
  POST_CREATE_TICKET_FAILURE: "[OBSERVATION][RESPONSE] CREATE_TICKET Failed",
  POST_CREATE_TICKET_SUCCESS:
    "[OBSERVATION][RESPONSE] CREATE_TICKET Successfull",
};

export const replyTicketType = {
  POST_REPLY_TICKET_PENDING: "[OBSERVATION][REQUEST] REPLY_TICKET",
  POST_REPLY_TICKET_FAILURE: "[OBSERVATION][RESPONSE] REPLY_TICKET Failed",
  POST_REPLY_TICKET_SUCCESS: "[OBSERVATION][RESPONSE] REPLY_TICKET Successfull",
};

export const postUserFeedbackOnTicketType = {
  POST_USER_FEEDBACK_ON_TICKET_PENDING:
    "[OBSERVATION][REQUEST] USER_FEEDBACK_ON_TICKET",
  POST_USER_FEEDBACK_ON_TICKET_FAILURE:
    "[OBSERVATION][RESPONSE] USER_FEEDBACK_ON_TICKET Failed",
  POST_USER_FEEDBACK_ON_TICKET_SUCCESS:
    "[OBSERVATION][RESPONSE] USER_FEEDBACK_ON_TICKET Successfull",
};

export const getDashboardTicketsType = {
  GET_DASHBOARD_TICKETS_PENDING: "[REQUEST] Get Dashboard TICKETS",
  GET_DASHBOARD_TICKETS_SUCCESS: "[RESPONSE] Get Dashboard TICKETS Successfull",
  GET_DASHBOARD_TICKETS_FAILURE: "[RESPONSE] Get Dashboard TICKETS Failed",
};

export const getTicketsAdminDeskType = {
  GET_TICKETS_ADMIN_DESK_PENDING: "[REQUEST] Get TICKETS ADMIN DESK",
  GET_TICKETS_ADMIN_DESK_SUCCESS:
    "[RESPONSE] Get TICKETS ADMIN DESK Successfull",
  GET_TICKETS_ADMIN_DESK_FAILURE: "[RESPONSE] Get TICKETS ADMIN DESK Failed",
};

export const getTicketsFeedbackDeskType = {
  GET_TICKETS_FEEDBACK_DESK_PENDING: "[REQUEST] Get TICKETS FEEDBACK DESK",
  GET_TICKETS_FEEDBACK_DESK_SUCCESS:
    "[RESPONSE] Get TICKETS FEEDBACK DESK Successfull",
  GET_TICKETS_FEEDBACK_DESK_FAILURE:
    "[RESPONSE] Get TICKETS FEEDBACK DESK Failed",
};

export const postClosedTicketType = {
  POST_CLOSED_TICKET_PENDING: "[TICKET][REQUEST] CLOSED_TICKET",
  POST_CLOSED_TICKET_FAILURE: "[TICKET][RESPONSE] CLOSED_TICKET Failed",
  POST_CLOSED_TICKET_SUCCESS: "[TICKET][RESPONSE] CLOSED_TICKET Successfull",
};

export const postUserAssignTicketType = {
  POST_USER_ASSIGN_TICKET_PENDING: "[TICKET][REQUEST] USER_ASSIGN_TICKET",
  POST_USER_ASSIGN_TICKET_FAILURE:
    "[TICKET][RESPONSE] USER_ASSIGN_TICKET Failed",
  POST_USER_ASSIGN_TICKET_SUCCESS:
    "[TICKET][RESPONSE] USER_ASSIGN_TICKET Successfull",
};

export const postUpdateSeverityTicketType = {
  POST_UPDATE_SEVERITY_TICKET_PENDING:
    "[SEVERITY][REQUEST] UPDATE_SEVERITY_TICKET__TICKET",
  POST_UPDATE_SEVERITY_TICKET_FAILURE:
    "[SEVERITY][RESPONSE] _UPDATE_SEVERITY_TICKET_TICKET Failed",
  POST_UPDATE_SEVERITY_TICKET_SUCCESS:
    "[SEVERITY][RESPONSE] _UPDATE_SEVERITY_TICKET_TICKET Successfull",
};

export const postAddFAQType = {
  POST_ADD_FAQ_PENDING: "[Add FAQ][REQUEST] POST_ADD_FAQ",
  POST_ADD_FAQ_FAILURE: "[Add FAQ][RESPONSE] POST_ADD_FAQ Failed",
  POST_ADD_FAQ_SUCCESS: "[Add FAQ][RESPONSE] POST_ADD_FAQ Successfull",
};


export const deleteFAQType = {
  DELETE_FAQ_PENDING: "[DELETE FAQ][REQUEST] DELETE_FAQ",
  DELETE_FAQ_FAILURE: "[DELETE FAQ][RESPONSE] DELETE_FAQ Failed",
  DELETE_FAQ_SUCCESS: "[DELETE FAQ][RESPONSE] POST_ADD_FAQ Successfull",
  CLEAR_FAQ: "[CLEAR FAQ][RESPONSE] FAQ Data cleared Successfull",
};



export const updateFAQType ={
  UPDATE_FAQ_PENDING: 'UPDATE_FAQ_PENDING',
  UPDATE_FAQ_SUCCESS: 'UPDATE_FAQ_SUCCESS',
  UPDATE_FAQ_FAILED: 'UPDATE_FAQ_FAILED',
}