import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./lmstableData.scss";
import { useNavigate } from "react-router-dom";
import { TableLoader } from "../../../library/common/Loader";
import {
  USER_ASSIGNED,
  USER_ASSIGNED_LESSON,
} from "../../../constant/routeContant";
import { PAGE_SIZE } from "../../../constant/constant";
import NORow from "../../../library/custom/noRow/noRow";

const LmstableData = (props) => {
  const navigate = useNavigate();
  const LessonList = props?.lessionListDataAll;
  const CourseList = props?.courseListDataAll;
  const onPageChange = props.onPageChange;
  const isLoading = props.isLoading;
  const page = props.page ? props.page - 1 : 0;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const columns = props.columns;

  const handleOnCellClick = (params) => {
    let courseid = params?.id;
    let fieldId = params?.field;
    let name = params?.row?.course;
    let finalid;
    if (fieldId === "totalCompletedByUser") {
      finalid = "true";
    } else if (fieldId === "totaleAssignedToUser") {
      finalid = "";
    } else {
      finalid = "false";
    }
    if (
      fieldId === "totalCompletedByUser" ||
      fieldId === "totaleAssignedToUser" ||
      fieldId === "totalNotCompletedByUser"
    ) {
      navigate(USER_ASSIGNED, {
        state: { courseId: courseid, fieldId: finalid, coursename: name },
      });
    }
  };
  const handleOnCellLessonClick = (params) => {
    let lessonid = params?.id;
    let fieldId = params?.field;
    let name = params?.row?.lesson;
    let finalid;

    if (fieldId === "totalCompletedByUser") {
      finalid = "true";
    } else if (fieldId === "totaleAssignedToUser") {
      finalid = "";
    } else {
      finalid = "false";
    }
    if (
      fieldId === "totalCompletedByUser" ||
      fieldId === "totaleAssignedToUser" ||
      fieldId === "totalNotCompletedByUser"
    ) {
      navigate(USER_ASSIGNED_LESSON, {
        state: { lessonId: lessonid, fieldId: finalid, lessonname: name },
      });
    }
  };
  return (
    <>
      <div className="tableData">
        {CourseList ? (
          <DataGrid
            getRowId={(r) => r.id}
            rows={CourseList?.data?.courses ?? []}
            columns={columns}
            page={page}
            pageSize={PAGE_SIZE}
            onPageChange={onPageChange}
            paginationMode="server"
            rowCount={CourseList?.data?.noOfRecords ?? 0}
            IsReadOnly="True"
            backIconButtonProps={{ disabled: false }}
            loading={isLoading}
            getRowClassName={(params) => "customrowTable"}
            getColumnClassName={(params) => "customheaderTable"}
            headerClassName={"hideRightSeparator"}
            getEstimatedRowHeight={() => 100}
            getRowHeight={() => "auto"}
            onCellClick={handleOnCellClick}
            components={{
              LoadingOverlay: TableLoader,
              NoRowsOverlay: NORow,
            }}
            disableSelectionOnClick={true}
            disableColumnSelector={false}
            disableColumnFilter={false}
            disableColumnMenu={false}
          />
        ) : (
          <DataGrid
            getRowId={(r) => r.lessonId}
            rows={LessonList?.data?.lessons ?? []}
            columns={columns}
            pageSize={PAGE_SIZE}
            onPageChange={onPageChange}
            paginationMode="server"
            rowCount={LessonList?.data?.noOfRecords ?? 0}
            IsReadOnly="True"
            backIconButtonProps={{ disabled: false }}
            loading={isLoading}
            getRowClassName={(params) => "customrowTable"}
            getColumnClassName={(params) => "customheaderTable"}
            headerClassName={"hideRightSeparator"}
            getEstimatedRowHeight={() => 100}
            getRowHeight={() => "auto"}
            onCellClick={handleOnCellLessonClick}
            components={{
              LoadingOverlay: TableLoader,
              NoRowsOverlay: NORow,
            }}
            disableSelectionOnClick={true}
            disableColumnSelector={false}
            disableColumnFilter={false}
            disableColumnMenu={false}
            //disableColumnSelector={true}
          />
        )}
      </div>
    </>
  );
};

export default LmstableData;
