import React from "react";
import ReportTrainingSessions from "../component/reportTrainingSessions";
import { useDispatch, useSelector } from "react-redux";
import * as lmsReportAction from "../../../redux/actions/lmsReportAction";
import Loader from "../../../library/common/Loader";
function ReportTrainingSessionsContainer() {
  const dispatch = useDispatch();

  const lmsReport = useSelector((state) => state.getLmsReport);
  const lmsReportLesson = useSelector((state) => state.getLmsReportLesson);
  const lmsReportCourse = useSelector((state) => state.getLmsReportCourse);

  const showLoader = useSelector(
    (state) =>
      state.getLmsReport.isLoading ||
      state.getLmsReportLesson.isLoading ||
      state.getLmsReportCourse.isLoading
  );

  const getLmsReportData = (data) => {
    dispatch(lmsReportAction.getLmsReport(data));
  };
  const getLmsReportLessonData = (data) => {
    dispatch(lmsReportAction.getLmsReportLesson(data));
  };
  const getLmsReportCourseData = (data) => {
    dispatch(lmsReportAction.getLmsReportCourse(data));
  };

  return (
    <>
      {showLoader && <Loader />}

      <ReportTrainingSessions
        getLmsReportData={getLmsReportData}
        getLmsReportLessonData={getLmsReportLessonData}
        getLmsReportCourseData={getLmsReportCourseData}
        lmsReportData={showLoader ? [] : lmsReport?.data}
        lmsReportLesson={showLoader ? [] : lmsReportLesson?.data}
        lmsReportCourse={showLoader ? [] : lmsReportCourse?.data}
      />
    </>
  );
}

export default ReportTrainingSessionsContainer;
