import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import "./selectMenu.scss";
import FormHelperText from "@mui/material/FormHelperText";
import commonImages from "../../../assets";
import selectIcon from "./selectmenuIcon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectMenu({
  listData,
  onChange,
  value,
  name,
  isIdShow = false,
  isError = false,
  errorMessage,
}) {
  return (
    <div className="overideSelectMenu">
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        onChange={onChange}
        size="small"
        renderValue={(selected) => selected.map((x) => x.value).join(",")}
        MenuProps={MenuProps}
        className="selectBox"
        name={name}
        IconComponent={selectIcon}
      >
        {listData &&
          listData?.map((variant) => (
            <MenuItem key={variant.key} value={variant}>
              <img
                className="multiCheck"
                title={
                  value.findIndex((item) => item.key === variant.key) >= 0
                    ? "tickCheckbox"
                    : "untickCheckbox"
                }
                alt=""
                src={
                  value.findIndex((item) => item.key === variant.key) >= 0
                    ? commonImages.check
                    : commonImages.noCheck
                }
              />
              <ListItemText>
                {variant.value} {isIdShow && `(${variant.key})`}
              </ListItemText>
            </MenuItem>
          ))}
      </Select>
      {isError && value.length === 0 && (
        <FormHelperText className="errorText">
          {errorMessage ? errorMessage : "Required"}
        </FormHelperText>
      )}
    </div>
  );
}
