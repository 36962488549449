import { nearMissesType } from "../constants/nearMissesType";

export const nearMisses = (data) => ({
  type: nearMissesType.POST_NEAR_MISSES_PENDING,
  data,
});

export const nearMissesSuccess = (data) => ({
  type: nearMissesType.POST_NEAR_MISSES_SUCCESS,
  data,
});

export const nearMissesFailure = (error) => ({
  type: nearMissesType.POST_NEAR_MISSES_FAILURE,
  error,
});

export const getNearMisses = (caseid, incidentid) => ({
  type: nearMissesType.GET_NEAR_MISSES_PENDING,
  caseid,
  incidentid,
});

export const getNearMissesSuccess = (data) => ({
  type: nearMissesType.GET_NEAR_MISSES_SUCCESS,
  data,
});

export const getNearMissesFailure = (error) => ({
  type: nearMissesType.GET_NEAR_MISSES_FAILURE,
  error,
});
export const updateNearMisses = (data) => ({
  type: nearMissesType.PUT_NEAR_MISSES_PENDING,
  data,
});

export const updateNearMissesSuccess = (data) => ({
  type: nearMissesType.PUT_NEAR_MISSES_SUCCESS,
  data,
});

export const updateNearMissesFailure = (error) => ({
  type: nearMissesType.PUT_NEAR_MISSES_FAILURE,
  error,
});
export const clearNearMisses = () => ({
  type: nearMissesType.CLEAR_NEAR_MISSES,
  data: {},
});
