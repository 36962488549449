import React, { useState } from "react";
import { TableLoader } from "../../library/common/Loader";
import { PAGE_SIZE } from "../../constant/constant";
import { useDispatch } from "react-redux";
import NORow from "../../library/custom/noRow/noRow";
import * as jhaAction from "../../redux/actions/jhaAction";
import GridTable from "../../library/custom/gridTable/GridTable";
import SettingTable from "../incidents/incidentList/SettingTable";
import { useNavigate } from "react-router-dom";
import { CONDUCT_JHA } from "../../constant/routeContant";
import { Grid } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
const JHADashboardTable = ({ itemsCount, itemList, isLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      field: "jhaTemplateName",
      headerName: "Hazard Analysis Template Name",
      flex: 3,
    },
    { field: "assetName", headerName: "Asset Name", flex: 3 },
    // { field: "assetType", headerName: "Asset Type", flex: 2 },

    {
      flex: 2,
      field: "assetType",
      headerName: "Asset Type",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <div>{rows?.row?.assetType === 1 ? "Project" : "Custom Asset"}</div>
          </Grid>
        </Grid>
      ),
    },
    { field: "frequency", headerName: "Frequency", flex: 2 },
    {
      flex: 1,
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={[]}
          fileNameXL={"Activities List"}
        />
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          
          <Grid container pr={0.6} justifyContent={"right"}>
            <RemoveRedEye style={{color:'#1b436e',cursor:"pointer"}} />
          </Grid>
        
          // <ReportRowManipulte
          //   rows={rows}
          //   viewOnClick={() => {
          //     //   setOpen(true);
          //     //   seteditFormValue({
          //     //     ...editFormValue,
          //     //     activityId: rows?.row?.id,
          //     //     activityName: rows?.row?.activityName,
          //     //     isActive: rows?.row?.isActive,
          //     //   });
          //   }}
          //   view
          //   editLabel="Conduct JHA"
          // />
        );
      },
    },
  ];
  const [page, setPage] = useState(1);
  // pagination

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: PAGE_SIZE,
    };
    dispatch(jhaAction.getJHASetupDashboard(data));
  };
  return (
    <div className="desc_box row_uniq">
      <div className="table_style_ui">
        <GridTable
          getRowId={(r) => r.jhaId}
          rows={itemList ?? []}
          rowCount={itemsCount}
          columns={columns}
          loading={isLoading}
          page={page}
          pagination
          pageSize={PAGE_SIZE}
          rowsPerPageOptions={[PAGE_SIZE]}
          onPageChange={handlePagignation}
          backIconButtonProps={{ disabled: false }}
          disableSelectionOnClick
          components={{
            LoadingOverlay: TableLoader,
            NoRowsOverlay: NORow,
          }}
          onClick={(r) =>
            navigate(CONDUCT_JHA, {
              state: {
                data: r.row,
              },
            })
          }
        />
      </div>
    </div>
  );
};

export default JHADashboardTable;
