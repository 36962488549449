import React, { useState } from "react";
import { Grid } from "@mui/material";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import SwOrganisationSummary from "./SwOrganisationSummary";
import {
  INSPECTION_REPORT_TYPE,
  SUPER_ADMIN_INSPECTION_REPORT_TYPE,
} from "./SwReportConstant";
import {
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  REPORT_PAGE_SIZE,
} from "../../../../constant/constant";
import SwInspectorSummary from "./SwInspectorSummary";
import SwDateWiseSummary from "./SwDateWiseSummary";
import SwPerDayInspectorSummary from "./SwPerDayInspectorSummary";
import SwDateWiseInspectorSummary from "./SwDateWiseInspectorSummary";
import { getCurrentDate, getFormatedDate } from "../../../../utils/helper";

function SwSummaryreport({
  checkReportType,
  getOrganisationSummaryListData,
  getOrganisationSummaryList,
  isLoading,
  getInspectorSummaryListData,
  getInspectorSummaryList,
  getDateWiseSummaryListData,
  getDateWiseSummaryList,
  getPerDayInspectorSummaryListData,
  getPerDayInspectorSummaryList,
  getDateWiseInspectorSummaryListData,
  getDateWiseInspectorSummaryList,
}) {
  const [reportType, setReportType] = useState("");
  const orgId = localStorage.getItem("orgId");
  const substract30Day = new Date();
  substract30Day.setDate(substract30Day.getDate() - 30);
  const areAllEqual =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) &&
    parseInt(LOGIN_ORG_ID()) === parseInt(HBNEXT_ORG_ID);

  const onChangeReportType = (event) => {
    setReportType(event.target.value);
    checkReportType(event.target.value);
    if (event.target.value === 1) {
      const defaultData = {
        FromDate: getFormatedDate(substract30Day),
        ToDate: getCurrentDate(new Date()),
        Top: 30000,
        Page: 1,
        PageSize: REPORT_PAGE_SIZE,
      };
      getOrganisationSummaryListData(defaultData);
    }
    if (event.target.value === 2) {
      const defaultData = {
        CustomerId: areAllEqual ? 0 : orgId,
        FromDate: getFormatedDate(substract30Day),
        ToDate: getCurrentDate(new Date()),
        Top: 30000,
        Page: 1,
        PageSize: REPORT_PAGE_SIZE,
      };
      getInspectorSummaryListData(defaultData);
    }
    if (event.target.value === 3) {
      const defaultData = {
        CustomerId: areAllEqual ? 0 : orgId,
        FromDate: getFormatedDate(substract30Day),
        ToDate: getCurrentDate(new Date()),
        Page: 1,
        PageSize: REPORT_PAGE_SIZE,
      };
      getDateWiseSummaryListData(defaultData);
    }
    if (event.target.value === 4) {
      const defaultData = {
        CustomerId: areAllEqual ? 0 : orgId,
        FromDate: getFormatedDate(substract30Day),
        ToDate: getCurrentDate(new Date()),
        Page: 1,
        PageSize: REPORT_PAGE_SIZE,
      };
      getPerDayInspectorSummaryListData(defaultData);
    }

    if (event.target.value === 5) {
      const defaultData = {
        CustomerId: areAllEqual ? 0 : orgId,
        FromDate: getFormatedDate(substract30Day),
        ToDate: getCurrentDate(new Date()),
        Page: 1,
        PageSize: REPORT_PAGE_SIZE,
      };
      getDateWiseInspectorSummaryListData(defaultData);
    }
  };
  return (
    <>
      <Grid container>
        <Grid item lg={6} xs={12} sm={12} md={4} pl={1} textAlign="left">
          <div className="heading_style">Inspection Report Summary</div>
        </Grid>
      </Grid>
      <Grid
        container
        item
        lg={11}
        md={11}
        sm={9}
        xs={9}
        className="addShadowBox"
      >
        <Grid item container mt={0}>
          <Grid item lg={6} xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <FormLabels label="Select Report Type" isRequired={false} />
              <SelectMenu
                listData={
                  areAllEqual
                    ? SUPER_ADMIN_INSPECTION_REPORT_TYPE
                    : INSPECTION_REPORT_TYPE
                }
                value={reportType}
                onchangehandler={onChangeReportType}
                placeholder="Please Select"
              />
            </FormControl>
          </Grid>
        </Grid>
        {reportType === 1 && (
          <>
            <SwOrganisationSummary
              getOrganisationSummaryList={getOrganisationSummaryList}
              getOrganisationSummaryListData={getOrganisationSummaryListData}
              isLoading={isLoading}
              conName={"orgSummaryReport"}
              substract30Day={substract30Day}
            />
          </>
        )}
        {reportType === 2 && (
          <>
            <SwInspectorSummary
              getInspectorSummaryList={getInspectorSummaryList}
              getInspectorSummaryListData={getInspectorSummaryListData}
              isLoading={isLoading}
              conName={"inspectorSummaryReport"}
              areAllEqual={areAllEqual}
              substract30Day={substract30Day}
            />
          </>
        )}

        {reportType === 3 && (
          <>
            <SwDateWiseSummary
              getDateWiseSummaryListData={getDateWiseSummaryListData}
              getDateWiseSummaryList={getDateWiseSummaryList}
              isLoading={isLoading}
              areAllEqual={areAllEqual}
              substract30Day={substract30Day}
            />
          </>
        )}
        {reportType === 4 && (
          <>
            <SwPerDayInspectorSummary
              getPerDayInspectorSummaryListData={
                getPerDayInspectorSummaryListData
              }
              getPerDayInspectorSummaryList={getPerDayInspectorSummaryList}
              isLoading={isLoading}
              areAllEqual={areAllEqual}
              substract30Day={substract30Day}
            />
          </>
        )}

        {reportType === 5 && (
          <>
            <SwDateWiseInspectorSummary
              getDateWiseInspectorSummaryListData={
                getDateWiseInspectorSummaryListData
              }
              getDateWiseInspectorSummaryList={getDateWiseInspectorSummaryList}
              isLoading={isLoading}
              areAllEqual={areAllEqual}
              substract30Day={substract30Day}
            />
          </>
        )}
      </Grid>
    </>
  );
}

export default SwSummaryreport;
