import React, { useEffect, useState } from "react";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import UserInfo from "../component/userInfo/userInfo";
import Loader from "../../../library/common/Loader";

const UserInfoContainer = () => {
  const dispatch = useDispatch();
  const checkUserInfo = useSelector((state) => state.checkUserInfo);
  const showLoader = useSelector((state) => state.checkUserInfo?.isLoading);

  const onSubmit = (data) => {
    dispatch(userAction.checkUserInfo(data));
  };

  useEffect(() => {
    const data = {
      phonenumber: "",
      email: "",
    };
    dispatch(userAction.checkUserInfo(data));
  }, []);
  
  return (
    <>
      {showLoader && <Loader />}
      <div className="dashTitle">User Information Check</div>
      <UserInfo userInfo={checkUserInfo?.data} onSubmit={onSubmit} />
    </>
  );
};

export default UserInfoContainer;
