import React from "react";
import { Grid, Box } from "@mui/material";
import { InputField } from "../../../library/custom/textBox/InputField";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import Buttons from "../../../library/custom/button/Button";
import MultiSelectBox from "../../../library/custom/selectMenu/MultiSelectBox";
import DatePicker from "../../../library/custom/datePicker/DatePicker";
import intl from "react-intl-universal";
export default function Utility(props) {
  const {
    utilityPropertyDamaged,
    typeOfUtilityData,
    typeOfUtilityValue,
    typeOfUtilityLine,
    location,
    utilityOnChanges,
    locationDetail,
    damageTicket,
    locateTicket,
    validFrom,
    validTo,
    wereUtilityLines,
    utilityListLength,
    utilityPropertyDamage,
    typeOfUtilityPropertyDamage,
    wereUtilityDamage,
    isErrorUtilityPropertyDamaged,
    isErrorTypeOfUtility,
    isErrorTypeOfUtilityLine,
    cancelUtilityList,
    typeOfMachine,
    keyNo,
  } = props;
  return (
    <>
      <div
        style={{
          border: "1px solid #DFDFDF",
          maxWidth: "750px",
          padding: "20px",
          borderRadius: "10px",
          marginBottom: "30px",
          marginTop: "30px",
        }}
      >
        <Grid item container>
          <Grid item lg={12} xs={12} sm={12} md={12} textAlign="left" mb={2}>
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              Utility Damaged {keyNo}
            </h1>
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get("incident.utilityDamage.utilityPropertyDamage")}
              isRequired={true}
            />
            <SelectMenu
              name="utilityPropertyDamaged"
              listData={utilityPropertyDamage?.filter((list) => list.isActive)}
              onchangehandler={(e) => utilityOnChanges(e)}
              value={utilityPropertyDamaged}
              placeholder="Please select"
              isError={isErrorUtilityPropertyDamaged}
              errorMsg={
                isErrorUtilityPropertyDamaged &&
                "Selection is required for this field "
              }
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get("incident.utilityDamage.typeOfUtility")}
              isRequired={true}
            />
            <MultiSelectBox
              placeholder={"Please Select"}
              listData={typeOfUtilityData?.filter((list) => list.isActive)}
              onChange={(e) => utilityOnChanges(e)}
              value={typeOfUtilityValue}
              name="typeOfUtility"
              isError={isErrorTypeOfUtility}
              errorMessage="Selection is required for this field "
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={3}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get("incident.utilityDamage.typeOfUtilityLineDamage")}
              isRequired={true}
            />
            <MultiSelectBox
              listData={typeOfUtilityPropertyDamage?.filter(
                (list) => list.isActive
              )}
              onChange={(e) => utilityOnChanges(e)}
              value={typeOfUtilityLine}
              name="typeOfUtilityLine"
              isError={isErrorTypeOfUtilityLine}
              errorMessage="Selection is required for this field"
            />
          </Grid>

          <Grid item lg={6} xs={12} sm={12} md={5}>
            <InputField
              id="typeOfMachine"
              fullWidth={true}
              disabled={false}
              type="text"
              label={intl.get("incident.utilityDamage.typeOfMachinery")}
              value={typeOfMachine}
              placeholder="Add Type of Machinery Text Here..."
              onChange={(e) => utilityOnChanges(e)}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={1}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <InputField
              id="locationDetail"
              fullWidth={true}
              disabled={false}
              type="text"
              label={intl.get("incident.utilityDamage.locationDetails")}
              value={locationDetail}
              placeholder="Add Location Detail Text Here..."
              onChange={(e) => utilityOnChanges(e)}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <InputField
              id="location"
              fullWidth={true}
              disabled={false}
              type="text"
              label={intl.get("incident.utilityDamage.addLocation")}
              value={location}
              placeholder="Add Location Text Here..."
              onChange={(e) => utilityOnChanges(e)}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={1}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <InputField
              id="locateTicket"
              fullWidth={true}
              disabled={false}
              type="text"
              label={intl.get("incident.utilityDamage.locateTicket")}
              value={locateTicket}
              placeholder="Add Locate Ticket Text Here..."
              onChange={(e) => utilityOnChanges(e)}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <InputField
              id="damageTicket"
              fullWidth={true}
              disabled={false}
              type="text"
              label={intl.get("incident.utilityDamage.damageTicket")}
              value={damageTicket}
              placeholder="Add Damage Ticket Text Here..."
              onChange={(e) => utilityOnChanges(e)}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} mt={3}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get("incident.utilityDamage.validFrom")}
              isRequired={false}
            />
            <DatePicker
              id="datePickerFrom"
              value={validFrom}
              onChangeHandler={(e) => utilityOnChanges(e, "dataFrom")}
            />
          </Grid>

          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get("incident.utilityDamage.validTo")}
              isRequired={false}
            />
            <DatePicker
              id="datePickerTO"
              maxDateEnable={true}
              minDate={validFrom}
              value={validTo}
              onChangeHandler={(e) => utilityOnChanges(e, "dateTo")}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={3}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get("incident.utilityDamage.wereUtilityLines")}
              required={false}
            />
            <MultiSelectBox
              placeholder={"Please Select"}
              listData={wereUtilityDamage?.filter((list) => list.isActive)}
              onChange={(e) => utilityOnChanges(e)}
              value={wereUtilityLines}
              name="wereUtilityLines"
            />
          </Grid>
        </Grid>
      </div>
      <Grid item container mt={3}>
        <Grid lg={8} xs={12} sm={12} md={12}>
          <Box mb={2} textAlign="right">
            <Buttons
              variantOutline={true}
              onClick={() => cancelUtilityList(utilityListLength)}
              label={intl.get("incident.utilityDamage.cancel")}
            ></Buttons>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
