import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import UserQuizDetail from "../component/userQuizDetail/userQuizDetail";
import { useDispatch, useSelector } from "react-redux";
import * as quizAction from "../../../redux/actions/quizAction";
import { useLocation } from "react-router-dom";
import Loader from "../../../library/common/Loader";
import { API_STATUS, LNG_ID } from "../../../constant/constant";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { quizImg } from "../../../assets";

const UserQuizContainer = () => {
  const dataBack = {
    title: "Back to Lesson",
    route: -1,
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const quizId = location?.state?.quizId;
  const lessonId = location?.state?.lessonId;
  const courseId = location?.state?.courseId;
  const isAdminLms = location?.state?.isAdminLms;
  const [errCompltemsg, setErrCompltemsg] = useState("");
  const [isclickbtn, setIsclickbtn] = useState(false);
  const quizListData = useSelector((state) => state.getQuizList);

  const defaultData = {
    lngId: LNG_ID,
    quizId: quizId,
    lessonId: lessonId,
    courseId: courseId,
  };

  const submitQuiz = useSelector((state) => state.submitQuiz);
  const submitQuizCode = useSelector(
    (state) => state.submitQuiz.data?.responseMessages?.responseCode
  );
  const submitQuizMsg = useSelector(
    (state) => state.submitQuiz.data?.responseMessages?.responseMessage
  );

  const statuss = submitQuiz?.status;
  const SubisLoading = submitQuiz?.isLoading;

  const qShowStatus = submitQuiz?.data?.result;
  const route = -1;

  const [successScreens, setSuccessScreens] = useState(false);

  const addSubmitQuiz = async (data) => {
    setSuccessScreens(true);
    dispatch(quizAction.submitQuiz(data));
  };

  const getQuizListData = (data) => {
    dispatch(quizAction.getQuizList(data));
  };

  useEffect(() => {
    getQuizListData(defaultData);
    setErrCompltemsg("");
  }, []);

  const isLoading = useSelector((state) => state.getQuizList?.isLoading);

  const successScree = (qShowStatus) => {
    return (
      <SuccessScreen
        route={route}
        isClass={qShowStatus === "Passed!" ? "passed" : "failed"}
        isImg={qShowStatus === "Passed!" ? quizImg?.passed : quizImg?.failed}
        label1={qShowStatus}
        label2={
          qShowStatus === "Passed!"
            ? "Congrats! You have successfully completed the Lesson"
            : "Cheer up! Redo the lesson and take the quiz again !"
        }
      ></SuccessScreen>
    );
  };
  useEffect(() => {
    if (statuss == "SUCCESS" && submitQuizCode == "HBNG002" && isclickbtn) {
      setErrCompltemsg(submitQuizMsg);
      setIsclickbtn(false);
    }
  }, [SubisLoading, submitQuiz]);

  return (
    <>
      {statuss == API_STATUS.SUCCESS &&
      successScreens &&
      submitQuizCode == "HBNG001" ? (
        successScree(qShowStatus)
      ) : (
        <div className="createQuizContainer">
          <Grid container>
            <BackBtn dataBack={dataBack} />
          </Grid>
          <Grid container>
            {isLoading || SubisLoading ? (
              <Loader />
            ) : (
              <UserQuizDetail
                quizListData={quizListData.data}
                getQuizListData={getQuizListData}
                onSubmit={addSubmitQuiz}
                lessonId={lessonId}
                courseId={courseId}
                errCompltemsg={errCompltemsg}
                setIsclickbtn={setIsclickbtn}
                isAdminLms={isAdminLms}
              />
            )}
          </Grid>
        </div>
      )}
    </>
  );
};

export default UserQuizContainer;
