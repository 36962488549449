import React, { useEffect, useRef, useState } from "react";
import { Grid, Button, Box } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import Person from "@mui/icons-material/Person";
import useDebounce from "../../../utils/useDebounce";
import SearchAutoComplete from "../searchAutoComplete/SearchAutoComplete";
import "./CreateAction.scss";
import intl from "react-intl-universal";
import { GET_USER_TYPE } from "../../../constant/constant";
import TextEditor from "../textEditor/TextEditor";

const CreateAction = ({
  singleAction,
  handleChangeActionDescription,
  handleAddAssigneeCta,
  handleUploadFile,
  handleCancelCta,
  optionsData,
  searchUsers,
  incidentId,
  lable = intl.get("incident.createAction.createAction"),
  showAssign = true,
  showCancel = true,
  isLoading = false,
  placeholderText = "Add Create Action Text Here...",
  isError,
  removeActionImage,
  fileError
}) => {
  const [defaultOpenValue, setDefaultOpenValue] = useState(false);

  const [searchtext, setSeachText] = useState("");
  const debounceAs = useDebounce(searchtext, 500);
  useEffect(() => {
    if (debounceAs) {
      searchUsers(GET_USER_TYPE.EMPLOYEE, debounceAs);
    }
  }, [debounceAs]);

  const hiddenFileInput = useRef(null);
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const fileName = fileUploaded.name;
    handleUploadFile(fileUploaded, fileName, singleAction);
  };

  let editFileName = "";

  if (
    incidentId &&
    singleAction &&
    singleAction?.files &&
    singleAction?.files.length > 0
  ) {
    const editFileNameVal = singleAction?.files[0]?.filePath?.split("/");
    editFileName = editFileNameVal[editFileNameVal.length - 1];
  }

  const handleOnChangeSelectBox = (event, value) => {
    setDefaultOpenValue(false);
    handleAddAssigneeCta(event, value, singleAction);
  };


  return (
    <>
      <div className="overideAction">
        <Grid container>
          <Grid lg={8} xs={12} sm={12} md={8}>
            <div className="formLabelStyle">{lable}</div>

            <div className="actionContainer">
              <div style={{ position: "relative" }}>
                <TextEditor
                  key={singleAction.id}
                  style={{ border: "none", width: "100%", resize: "none" }}
                  className="crateAction"
                  placeholder={placeholderText}
                  value={singleAction.description}
                  onChange={(e) =>
                    handleChangeActionDescription(e, singleAction)
                  }
                />

                <input
                  key={`file_${singleAction.id}`}
                  type="file"
                  ref={hiddenFileInput}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  style={{ display: "none" }}
                  accept="image/*"
                />
                <div className="btnBody">
                  <div className="addAssigSearch">
                    {defaultOpenValue && (
                      <SearchAutoComplete
                        optionsData={isLoading ? [] : optionsData}
                        isKeyValuePair={true}
                        isLoading={isLoading}
                        typeOnchange={(event) => {
                          setSeachText(event.target.value);
                        }}
                        onChange={(event, value) =>
                          handleOnChangeSelectBox(event, value)
                        }
                      />
                    )}
                  </div>

                  {!defaultOpenValue && showAssign ? (
                    <Button
                      className="btnColor"
                      variant="text"
                      startIcon={<Person className="btnIconColor" />}
                      onClick={() => {
                        setDefaultOpenValue(true);
                      }}
                    >
                      {intl.get("incident.createAction.addAssignee")}
                    </Button>
                  ) : (
                    ""
                  )}

                  <Button
                    className="btnColor"
                    variant="text"
                    startIcon={<DescriptionIcon className="btnIconColor" />}
                    onClick={() => {
                      hiddenFileInput.current.click();
                    }}
                  >
                    {intl.get("incident.createAction.addMedia")}
                  </Button>
                </div>
              </div>

              {singleAction?.assignedPartyName && (
                <div className="nameBody">
                  <div className="nameTxt">
                    Assign to:{" "}
                    <span className="name">
                      {singleAction.assignedPartyName}
                    </span>
                  </div>
                </div>
              )}

              {singleAction?.file && singleAction?.file?.name && (
                <div className="imageNameBody">
                  <div className="nameTxt">
                    <DescriptionIcon className="btnColor" /> Image:{" "}
                    <span className="name">{singleAction.file.name}</span>
                  </div>
                  <div
                    className="removeAction"
                    onClick={() => removeActionImage(singleAction)}
                  >
                    X
                  </div>
                </div>
              )}

              {incidentId && editFileName && !singleAction?.file?.name && (
                <div className="imageNameBody">
                  <div className="nameTxt">
                    <DescriptionIcon className="btnColor" /> Image:{" "}
                    <span className="name">{editFileName}</span>
                  </div>
                </div>
              )}
            </div>

              {singleAction?.isFileError && <p className="error">{fileError}</p>}
            {isError ? (
              <p className="actionError">Please Select Assignee </p>
            ) : null}
            {showCancel && (
              <Box mt={1} textAlign="right">
                <Button
                  variant="outlined"
                  className="cancelBtn"
                  onClick={() => handleCancelCta(singleAction)}
                >
                  {intl.get("incident.createAction.cancel")}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CreateAction;
