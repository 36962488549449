import * as permitAction from "../../storm-water/actions/permitAction";
import { permitType } from "../../storm-water/types/permitType";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../../../src/utils/helper";
import {
  addPermit,
  getPermitDetails,
  getPermitList,
  updatePermit,
  addPermitType,
  getPermitTypeList,
  updatePermitType,
  getGeneralPermitList,
  updateGeneralPermitType,
  getPermitType,
} from "../../../services/storm-water/permitServices";

export function* addPermitSaga(params) {
  try {
    const { data, status, error } = yield call(addPermit, params);
    if (!data && status !== 200) throw apiError(error);
    const permitPage = permitType.PERMIT_PAGE;
    yield put(
      permitAction.addPermitSuccess({
        permitPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Permit  Creation Failed" };
    yield put(permitAction.addPermitFailure(data));
  }
}

export function* permitDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(getPermitDetails, params);
    if (!data && status !== 200) throw apiError(error);
    const page = permitType.PERMIT_PAGE;
    yield put(
      permitAction.permitDetailSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Getpermit details Failed" };
    yield put(permitAction.permitDetailFailure(data));
  }
}

export function* permitListSaga(params) {
  try {
    const { data, status, error } = yield call(getPermitList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = permitType.PERMIT_PAGE;
    yield put(
      permitAction.permitListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Getpermit list Failed" };
    yield put(permitAction.permitListFailure(data));
  }
}

export function* updatePermitSaga(params) {
  try {
    const { data, status, error } = yield call(updatePermit, params);
    if (!data && status !== 200) throw apiError(error);
    const permitPage = permitType.PERMIT_PAGE;
    yield put(
      permitAction.PostUpdatePermitSuccess({
        permitPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Permit Failed" };
    yield put(permitAction.PostUpdatePermitFailure(data));
  }
}

export function* addPermitTypeSaga(params) {
  try {
    const { data, status, error } = yield call(addPermitType, params);
    if (!data && status !== 200) throw apiError(error);
    const permitPage = permitType.PERMIT_PAGE;
    yield put(
      permitAction.addPermitTypeSuccess({
        permitPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Permit Type  Creation Failed" };
    yield put(permitAction.addPermitTypeFailure(data));
  }
}

export function* getPermitTypeListSaga(params) {
  try {
    const { data, status, error } = yield call(getPermitTypeList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = permitType.PERMIT_PAGE;
    yield put(
      permitAction.getPermitTypeListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Getpermit type list Failed" };
    yield put(permitAction.getPermitTypeListFailure(data));
  }
}

export function* updatePermitTypeSaga(params) {
  try {
    const { data, status, error } = yield call(updatePermitType, params);
    if (!data && status !== 200) throw apiError(error);

    yield put(
      permitAction.updatePermitTypeSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Permit Type Failed" };
    yield put(permitAction.updatePermitTypeFailure(data));
  }
}

export function* getGeneralPermitListSaga(params) {
  try {
    const { data, status, error } = yield call(getGeneralPermitList, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      permitAction.generalPermitListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get general permit list Failed" };
    yield put(permitAction.generalPermitListFailure(data));
  }
}

export function* updateGeneralPermitSaga(params) {
  try {
    const { data, status, error } = yield call(updateGeneralPermitType, params);
    if (!data && status !== 200) throw apiError(error);

    yield put(
      permitAction.updateGeneralPermitSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update General Permit Failed" };
    yield put(permitAction.updateGeneralPermitFailure(data));
  }
}

export function* getPermitTypeSaga(params) {
  try {
    const { data, status, error } = yield call(getPermitType, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      permitAction.getPermitTypeSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Permit Type Failed" };
    yield put(permitAction.getPermitTypeFailure(data));
  }
}

const myPermitSaga = [
  takeLatest(permitType.POST_ADD_PERMIT_PENDING, addPermitSaga),
  takeLatest(permitType.GET_PERMIT_DETAILS_PENDING, permitDetailsSaga),
  takeLatest(permitType.GET_PERMIT_LIST_PENDING, permitListSaga),
  takeLatest(permitType.POST_UPDATE_PERMIT_PENDING, updatePermitSaga),
  takeLatest(permitType.POST_ADD_PERMIT_TYPE_PENDING, addPermitTypeSaga),
  takeLatest(permitType.GET_PERMIT_TYPE_LIST_PENDING, getPermitTypeListSaga),
  takeLatest(permitType.POST_UPDATE_PERMIT_TYPE_PENDING, updatePermitTypeSaga),
  takeLatest(
    permitType.GET_GENERAL_PERMIT_LIST_PENDING,
    getGeneralPermitListSaga
  ),
  takeLatest(
    permitType.POST_UPDATE_GENERAL_PERMIT_PENDING,
    updateGeneralPermitSaga
  ),
  takeLatest(permitType.GET_PERMIT_TYPE_PENDING, getPermitTypeSaga),
];

export default myPermitSaga;
