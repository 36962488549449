import React from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import DescriptionIcon from "@mui/icons-material/Description";
import "./TextArea.scss";
import FormHelperText from "@mui/material/FormHelperText";

export default function TextArea({
  props,
  placeholder,
  value,
  onChange,
  name,
  children,
  id,
  addMedia,
  isError = false,
  errorMsg = "",
  uploadImg,
  loadingImg = false,
  disabled = false,
}) {
  return (
    <div className="overideTextArea">
      <div className="textAreaBody">
        <TextareaAutosize
          aria-label="minimum height"
          minRows={4}
          placeholder={placeholder}
          id={id}
          value={value}
          onChange={(e) => onChange(e)}
          className="textArea"
          name={name}
          disabled={disabled}
        >
          {children}
        </TextareaAutosize>

        {isError && value.length === 0 && (
          <FormHelperText className="errorText">{errorMsg}</FormHelperText>
        )}
      </div>
      {addMedia && (
        <div className="btnBody" style={{ textAlign: "left" }}>
          <Button
            style={{
              color: "#111111",
            }}
            variant="text"
            startIcon={
              <DescriptionIcon
                style={{
                  color: "#1B436E",
                }}
              />
            }
            component="label"
          >
            Add Media
            <input hidden accept="image/*" type="file" onChange={uploadImg} />
          </Button>
          {loadingImg && "please wait..."}
        </div>
      )}
    </div>
  );
}
