import { projectType } from "../../storm-water/types/projectType";
import { API_STATUS } from "../../../../src/constant/constant";

const initialState = {
  flow: "project",
  isError: false,
  isLoading: false,
};

export const createSwProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.POST_PROJECT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.POST_PROJECT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.POST_PROJECT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case projectType.CLEAR_SWPROJECT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateSwProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.PUT_PROJECT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.PUT_PROJECT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.PUT_PROJECT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case projectType.CLEAR_SWPROJECTEDIT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getSwProjectDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.GET_PROJECT_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.GET_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const swprojectListReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.GET_SW_PROJECT_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.GET_SW_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.GET_SW_PROJECT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const postAddContactInProjectReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case projectType.POST_ADD_CONTACT_IN_PROJECT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.POST_ADD_CONTACT_IN_PROJECT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.POST_ADD_CONTACT_IN_PROJECT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getContactInProjectListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case projectType.GET_CONTACT_IN_PROJECT_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.GET_CONTACT_IN_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.GET_CONTACT_IN_PROJECT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const updateProjectContactStatusReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case projectType.UPDATE_PROJECT_CONTACT_STATUS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.UPDATE_PROJECT_CONTACT_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.UPDATE_PROJECT_CONTACT_STATUS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const editOneContactInProjectReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case projectType.EDIT_ONE_CONTACT_IN_PROJECT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.EDIT_ONE_CONTACT_IN_PROJECT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.EDIT_ONE_CONTACT_IN_PROJECT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const deleteContactInProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.DELETE_CONTACT_IN_PROJECT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.DELETE_CONTACT_IN_PROJECT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.DELETE_CONTACT_IN_PROJECT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const swrainFallDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.SW_GET_RAINFALL_DATA_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.SW_GET_RAINFALL_DATA_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.SW_GET_RAINFALL_DATA_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const deleteSWProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.SW_DELETE_PROJECT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.SW_DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.SW_DELETE_PROJECT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
