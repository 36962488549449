import React, { useEffect, useState } from "react";
import Buttons from "../../../library/custom/button/Button";
import "./ticketContainer.scss";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Ticket from "../component/ticket/ticket";
import FilterList from "../common/filterList";
import * as supportAction from "../../../redux/actions/supportAction";
import Loader from "../../../library/common/Loader";
import NORow from "../../../library/custom/noRow/noRow";
import FilterButton from "../../../library/custom/filterButton/filterButton";
import { useNavigate } from "react-router-dom";
import { CREATE_TICKET, USER_TICKET_DETAILS } from "../../../constant/routeContant";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TicketContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const initialState = {
    userID: localStorage.getItem("userId"),
    priorityID: "",
    searchbarInput: "",
    ticketType: "",
  };
  const [data, setData] = useState(initialState);

  // getTickets
  const tickets = useSelector((state) => state.tickets?.data?.ticketList);
  const [ticketsState, setTicketsState] = useState(null);
  useEffect(() => {
    setTicketsState(tickets);
  }, [tickets]);
  useEffect(() => {
    dispatch(supportAction.getTickets(data));
  }, [data, dispatch]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const ticketFrom = localStorage.getItem("userId");

  const [priorityState, setpriorityState] = useState({
    low: false,
    medium: false,
    high: false,
  });
  // handle filter change
  const handleChange = (event) => {
    const newPriorityState = {
      ...priorityState,
      [event.target.name]: event.target.checked,
    };
    setpriorityState(newPriorityState);
    const arr = [];
    if (newPriorityState.high) {
      arr.push(1);
    }
    if (newPriorityState.medium) {
      arr.push(2);
    }
    if (newPriorityState.low) {
      arr.push(3);
    }
    const priorityIds = arr.join(",");
    setData({
      ...data,
      priorityID: priorityIds,
      userID: ticketFrom,
    });
  };

  function handleUserFeedback(data) {
    dispatch(supportAction.postUserFeedback(data));
  }
  const [tabValue, setTabValue] = React.useState(0);
  // tabs change
  function handleTabsChange(e, newTabValue) {
    setTicketsState(null);
    setTabValue(newTabValue);

    if (newTabValue === 0) {
      setData({
        ...data,
        ticketType: "",
      });
    }
    if (newTabValue === 1) {
      setData({
        ...data,
        ticketType: 0,
      });
    }
    if (newTabValue === 2) {
      setData({
        ...data,
        ticketType: 1,
      });
    }
  }

  // ticket reply
  const replyTicketSatus = useSelector((state) => state.replyTicket.status);

  useEffect(() => {
    if (replyTicketSatus === "SUCCESS")
      dispatch(supportAction.getTicketDetails(ticketDetails.ticketId));
  }, [replyTicketSatus, dispatch]);


  const ticketDetails = useSelector(
    (state) => state.getTicketDetails?.data?.ticketDetails
  );
  const [ticketDetailsState, setTicketDetailsState] = useState(null);
  useEffect(() => {
    setTicketDetailsState(ticketDetails);
  }, [ticketDetails]);

  const isLoading = useSelector((state) => state.createTicket);

  return (
    <div className="overrideTicketContainer">
      {/* {createTicketStatus === "SUCCESS" &&
      createTicketData &&
      successScreens ? (
        successScree(createTicketData?.result?.ticketId)
      ) : ( */}
        <div className="rightDiv">
          {isLoading.isLoading && <Loader />}
            <div className="containerTkt">
              <div className="mainContainer">
                  <div className="headerDiv">
                    <h1>Support Tickets</h1>
                    <Buttons
                      startIcon={<AddIcon />}
                      primary={true}
                      label={"New Ticket"}
                      onClick={() => {
                        navigate(CREATE_TICKET)
                      }}
                    />
                  </div>

                  <Grid mt={2} className="menuDiv">
                    <Grid className="leftMenu">
                      <Box>
                        <Tabs
                          value={tabValue}
                          onChange={handleTabsChange}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            className="tabcustom"
                            icon={
                              ticketsState &&
                              data.ticketType === "" && (
                                <div className="totalV">{tickets.length}</div>
                              )
                            }
                            iconPosition="end"
                            label="All Tickets"
                            {...a11yProps(0)}
                          />
                          <Tab
                            className="tabcustom"
                            icon={
                              ticketsState &&
                              data.ticketType === 0 && (
                                <div className="totalV">{tickets.length}</div>
                              )
                            }
                            iconPosition="end"
                            label="Open Tickets"
                            {...a11yProps(1)}
                          />
                          <Tab
                            className="tabcustom"
                            icon={
                              ticketsState &&
                              data.ticketType === 1 && (
                                <div className="totalV">{tickets.length}</div>
                              )
                            }
                            iconPosition="end"
                            label="Closed Tickets"
                            {...a11yProps(2)}
                          />
                        </Tabs>
                      </Box>
                    </Grid>
                    <Grid className="rightMenu">
                      <div className="searchBar">
                        <SearchBar
                          searchbarInput={data.searchbarInput}
                          handleSearch={(e) => {
                            setData({
                              ...data,
                              searchbarInput: e,
                            });
                            dispatch(supportAction.getTickets(data));
                          }}
                          placeholder="Search Text"
                        />
                      </div>
                      <div className="filter_btn">
                        <FilterButton handleClick={handleClick} />
                        <FilterList
                          id={id}
                          open={open}
                          handleClose={handleClose}
                          handleChange={handleChange}
                          anchorEl={anchorEl}
                          priorityState={priorityState}
                          isUser={true}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {ticketsState ? (
                    <div style={{ width: "100%" }}>
                      {ticketsState.length === 0 ? (
                        <NORow />
                      ) : (
                        tickets.map((ticket, index) => {
                          return (
                            <Grid
                              mt={2}
                              onClick={() => {
                                navigate(USER_TICKET_DETAILS, {
                                  state: {
                                    ticketId: ticket.ticketId,
                                    status: ticket.status,
                                  },
                                });
                              }}
                              className="ticketsContainer"
                              key={ticket.ticketId}
                            >
                              <Ticket
                                ticketQuestion={ticket.title}
                                ticketAnswer={ticket.description}
                                priority={ticket.priority}
                                ticketId={ticket.ticketId}
                                category={ticket.category}
                                replyCount={ticket.replyCount}
                                createdDateTime={ticket.createdDateTime}
                                status={ticket.status}
                              />
                            </Grid>
                          );
                        })
                      )}
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
            </div>
        </div>
    </div>
  );
};

export default TicketContainer;
