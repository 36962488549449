import React from "react";
import SupportCard from "../../../library/custom/supportCard/supportCard";
import "./connectStyle.scss";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import { Grid } from "@mui/material";

const Connect = () => {
  return (
    <div className="overrideConnect">
      <div className="connectMainContainer">
        <div className="rightContainer">
          <Grid container gap={3} className="cardsContainer">
            <SupportCard
              className="card"
              icon={<PlagiarismIcon className="icon" />}
              category="Phone"
              details="770-619-1669"
              details2="(M-F: 8:00am - 5:00pm)"
            />
            <SupportCard
              className="card"
              icon={<PlagiarismIcon className="icon" />}
              category="Address"
              details="1255 Lakes Pkwy Suite 385,"
              details2=" Lawrenceville, GA 30043"
            />
            <SupportCard
              className="card"
              icon={<PlagiarismIcon className="icon" />}
              category="Email"
              details="support@nextsequence.io"
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Connect;
