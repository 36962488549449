import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewcCasetable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { useNavigate } from "react-router-dom";
import UserFilterList from "../../shared/UserFilterList ";
import { TableLoader } from "../../../../library/common/Loader";
import {
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import NORow from "../../../../library/custom/noRow/noRow";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import {
  ADD_ADMIN_USER,
  ADMIN_VIEW_USER,
} from "../../../../constant/routeContant";

export default function UserList({
  userListData,
  loading,
  getuserLists,
  jobTitles,
  isDivision = true,
  divisionId = 0,
  getSyncProcoreUserLists,
  setIsSuccess,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [search, setSearch] = useState("");
  const [jobTitle, setjobTitle] = useState("");
  const [searchlocation, setSearchlocation] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isProcoreClient = userInfo?.isProcoreConnected;
  const ProcoreClientId = localStorage.getItem("IsProcoreConnected");
  const isClientSwitch = parseInt(ORG_ID()) !== parseInt(LOGIN_ORG_ID());

  let ShowButton = false;

  if (isClientSwitch) {
    if (ProcoreClientId === "true") {
      ShowButton = true;
    }
  } else {
    if (isProcoreClient === true) {
      ShowButton = true;
    }
  }

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openAdd = Boolean(anchorElAdd);
  const idAdd = openAdd ? "simple-popover" : undefined;

  const handleClickAdd = () => {
    const data = {
      orgId: ORG_ID(),
    };
    getSyncProcoreUserLists(data);
    setIsSuccess(true);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: pageSize,
      search: searchText ? searchText : "",
      orgId: ORG_ID(),
      designation: jobTitle,
      divisionId: divisionId ?? 0,
      IsProcoreUsers: true,
    };
    getuserLists(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getuserLists({
      page: newPage + 1,
      pageSize: pageSize,
      search: search ? search : "",
      orgId: ORG_ID(),
      designation: jobTitle ? jobTitle : "",
      divisionId: divisionId ?? 0,
      IsProcoreUsers: true,
    });
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    getuserLists({
      page: page,
      pageSize: newPazeSize,
      search: search,
      orgId: ORG_ID(),
      designation: jobTitle,
      divisionId: divisionId ?? 0,
      IsProcoreUsers: true,
    });
  };

  const handleOnViewclick = (rows) => {
    navigate(ADMIN_VIEW_USER, {
      state: { userDetails: rows.row, editMode: true },
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(ADMIN_VIEW_USER, {
        state: {
          userDetails: rows.row,
          editMode: true,
        },
      });
    }
  };

  const columns = [
    { field: "userid", headerName: "User ID", flex: 1.5 },
    { field: "userName", headerName: "Name", flex: 2 },
    { field: "jobtitle", headerName: "Job Title", flex: 2.3 },
    isDivision && {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={userListData?.itemsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={userListData?.userData ?? []}
          fileNameXL="list-of-user.csv"
        />
      ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          isDivision &&
          rows?.row?.isOrganiztion === false && (
            <ReportRowManipulte
              rows={rows}
              viewOnClick={() => handleOnViewclick(rows)}
              view={true}
              viewLabel="View User"
            />
          )
        );
      },
    },
  ];

  const handleSearch = (event) => {
    getuserLists({
      page: page,
      pageSize: pageSize,
      search: search,
      designation: jobTitle,
      IsProcoreUsers: true,
    });
  };
  const [designation, setDesignation] = useState("");
  const designationOnChanges = (e) => {
    let title = jobTitles?.find((x) => x.key === e.target.value)?.value;
    setjobTitle(title);
    setDesignation(e.target.value);
  };

  const handleClear = () => {
    setDesignation("");
    setAnchorEl(null);
    window.close();
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      designation: "",
      IsProcoreUsers: true,
    };
    getuserLists(data);
  };
  const handleApply = () => {
    setAnchorEl(null);
    window.close();
    const data = {
      page: 1,
      pageSize: pageSize,
      search: search ? search : "",
      designation: jobTitle,
      IsProcoreUsers: true,
    };
    getuserLists(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        {isDivision && (
          <div className="grid_main_header two_grid">
            <div className="grid_heading_style">Procore Users List</div>
            {ShowButton && (
              <div>
                <div className="filter_btn">
                  <Buttons
                    id="btnAddUsers"
                    aria-describedby={idAdd}
                    varientContained={true}
                    label={"Sync Users"}
                    onClick={(e) => handleClickAdd(e)}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>

            {isDivision && (
              <div className="filter_btn">
                <button
                  aria-describedby={id}
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClick(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>
                <UserFilterList
                  firstLabel="COMPANY"
                  secondLabel="Job Title"
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  searchlocation={searchlocation}
                  setSearchlocation={setSearchlocation}
                  handleSearch={handleSearch}
                  handleApply={handleApply}
                  secondLebelFilter={jobTitles}
                  resetBtn={true}
                  handleClear={handleClear}
                  secondLebelOnChanges={designationOnChanges}
                  secondLebelValue={designation}
                />
              </div>
            )}
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <ViewcCasetable
                getRowId={(r) => r.userid}
                rows={userListData?.userData || []}
                rowCount={userListData?.itemsCount}
                onClick={(rows) => onRowClick(rows)}
                columns={columns}
                loading={loading}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
