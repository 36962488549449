import { permitType } from "../../storm-water/types/permitType";
import { API_STATUS } from "../../../../src/constant/constant";

const initialState = {
  flow: "permit",
  isError: false,
  isLoading: false,
};

export const addPermitReducer = (state = initialState, action) => {
  switch (action.type) {
    case permitType.POST_ADD_PERMIT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case permitType.POST_ADD_PERMIT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case permitType.POST_ADD_PERMIT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const permitDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case permitType.GET_PERMIT_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case permitType.GET_PERMIT_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case permitType.GET_PERMIT_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getpermitListReducer = (state = initialState, action) => {
  switch (action.type) {
    case permitType.GET_PERMIT_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case permitType.GET_PERMIT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case permitType.GET_PERMIT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updatePermitReducer = (state = initialState, action) => {
  switch (action.type) {
    case permitType.POST_UPDATE_PERMIT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case permitType.POST_UPDATE_PERMIT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case permitType.POST_UPDATE_PERMIT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const addPermitTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case permitType.POST_ADD_PERMIT_TYPE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case permitType.POST_ADD_PERMIT_TYPE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case permitType.POST_ADD_PERMIT_TYPE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getPermitTypeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case permitType.GET_PERMIT_TYPE_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case permitType.GET_PERMIT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case permitType.GET_PERMIT_TYPE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updatePermitTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case permitType.POST_UPDATE_PERMIT_TYPE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case permitType.POST_UPDATE_PERMIT_TYPE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case permitType.POST_UPDATE_PERMIT_TYPE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getGeneralPermitTypeListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case permitType.GET_GENERAL_PERMIT_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case permitType.GET_GENERAL_PERMIT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case permitType.GET_GENERAL_PERMIT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateGeneralPermitReducer = (state = initialState, action) => {
  switch (action.type) {
    case permitType.POST_UPDATE_GENERAL_PERMIT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case permitType.POST_UPDATE_GENERAL_PERMIT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case permitType.POST_UPDATE_GENERAL_PERMIT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getPermitTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case permitType.GET_PERMIT_TYPE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case permitType.GET_PERMIT_TYPE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case permitType.GET_PERMIT_TYPE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
