import React, { useState, useEffect } from "react";
import InspectionAcknowledgment from "../component/inspection/inspectionAcknowledgment";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { USER_ID } from "../../../constant/constant";

const InspectionAcknowledgmentContainer = () => {
  const dispatch = useDispatch();

  const [acknowledgmentMessage, setAcknowledgmentMessage] = useState();
  const [acknowledgmentId, setAcknowledgmentId] = useState();
  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const defaultSubData = {
    type: 5,
  };

  const getInspectionAcknowledgement = useSelector(
    (state) => state.getInspectionAcknowledgement
  );
  const getInspectionAcknowledgementData = (data) => {
    dispatch(incpectionAction.getInspectionAcknowledgement(data));
  };

  const updateInspectionAcknowledgement = useSelector(
    (state) => state.updateInspectionAcknowledgement
  );

  const [clearInspectionAcknowledgement, setClearInspectionAcknowledgement] =
    useState(false);

  const showLoader = useSelector(
    (state) =>
      state.getInspectionAcknowledgement?.isLoading ||
      state.updateInspectionAcknowledgement?.isLoading
  );

  const onSubmit = (data) => {
    setClearInspectionAcknowledgement(true);
    dispatch(incpectionAction.updateInspectionAcknowledgement(data));
  };

  useEffect(() => {
    getInspectionAcknowledgementData(defaultSubData);
  }, []);

  useEffect(() => {
    if (
      updateInspectionAcknowledgement?.status === "SUCCESS" &&
      updateInspectionAcknowledgement?.isLoading === false &&
      clearInspectionAcknowledgement
    ) {
      toast("Inspection Acknowledgement Updated successfully");
      getInspectionAcknowledgementData(defaultSubData);
    }
  }, [updateInspectionAcknowledgement]);

  useEffect(() => {
    if (
      getInspectionAcknowledgement?.status === "SUCCESS" &&
      getInspectionAcknowledgement?.isLoading === false
    ) {
      setAcknowledgmentMessage(
        getInspectionAcknowledgement?.data?.result[0]?.value
      );
      setChecked(getInspectionAcknowledgement?.data?.result[0]?.status);
      setAcknowledgmentId(getInspectionAcknowledgement?.data?.result[0]?.key);
    }
  }, [getInspectionAcknowledgement]);

  const onSubmitData = () => {
    const data = {
      userId: parseInt(USER_ID()),
      updateDetails: [
        {
          id: acknowledgmentId,
          value: acknowledgmentMessage,
          status: checked,
        },
      ],
    };
    onSubmit(data);
  };

  return (
    <>
      {showLoader && <Loader />}

      <InspectionAcknowledgment
        checked={checked}
        setChecked={setChecked}
        handleChange={handleChange}
        getInspectionAcknowledgement={getInspectionAcknowledgement}
        acknowledgmentMessage={acknowledgmentMessage}
        setAcknowledgmentMessage={setAcknowledgmentMessage}
        onSubmitData={onSubmitData}
      />
      <ToastContainer />
    </>
  );
};

export default InspectionAcknowledgmentContainer;
