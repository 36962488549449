import React from "react";
import { INSPECTIONCARD } from "../../../../constant/storm-water/constant";
import Card from "../../../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const SwInspectionList = ({ onClick }) => {
  return (
    <>
      <Grid container className="CardListContaner">
        {checkPrivileges([12, 99, 100, 101]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={INSPECTIONCARD[5]}
          />
        )}
        {checkPrivileges([12, 96, 97, 98]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={INSPECTIONCARD[8]}
          />
        )}
        {checkPrivileges([12, 93, 94, 95]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={INSPECTIONCARD[0]}
          />
        )}
        {checkPrivileges([12, 93, 94, 95]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={INSPECTIONCARD[7]}
          />
        )}
        {/* <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={INSPECTIONCARD[6]}
        /> */}

        {checkPrivileges([12, 93, 94, 95]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={INSPECTIONCARD[1]}
          />
        )}
        {checkPrivileges([12, 93, 94, 95]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={INSPECTIONCARD[2]}
          />
        )}
        {checkPrivileges([12, 93, 94, 95]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={INSPECTIONCARD[3]}
          />
        )}
        {checkPrivileges([12, 93, 94, 95]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={INSPECTIONCARD[4]}
          />
        )}
      </Grid>
    </>
  );
};

export default SwInspectionList;
