import React from "react";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { Grid } from "@mui/material";
import AllAssets from "../../shared/allAssets";
import "./viewDivision.scss";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";

const ViewDivision = ({
  viewDivisionsData,
  projectsListData,
  getProjectListData,
  isLoading,
  equipmentList,
  getEquipmentList,
  isEquipmentLoading,
  vehicleList,
  getVehicleList,
  isVehicleLoading,
  assetList,
  isAssetLoading,
  getAssetListData,
  tabClick,
  divisionId,
  loading,
  userListData,
  getuserLists,
  GroupListData,
  noOfRecords,
  getuserGroupLists,
  loadingGroup,
  ContractorListData,
  loadingContractor,
  getContractorListData,
}) => {
  return (
    <>
      <div className="overideDivision">
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">Divisions</div>
        </Grid>
        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <InputField
              type="text"
              label="Division Name"
              disabled={true}
              value={viewDivisionsData?.divisionName}
            />
          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputField
                type="text"
                label="State"
                disabled={true}
                value={viewDivisionsData?.stateName}
              />
            </FormControl>
          </Grid>
        </Grid>

        {PRODUCT_ID() == 2 && (
          <Grid item container mt={3} spacing={2}>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <InputField
                type="text"
                label="Client Id"
                disabled={true}
                value={viewDivisionsData?.clientId}
              />
            </Grid>
          </Grid>
        )}
        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels label={"Division Admin"} />
              <SearchAutoComplete disabled={true} />
            </FormControl>
          </Grid>
          <Grid sm={8} mt={5} pl={2}>
            <div className="UserChiplistBox">
              {viewDivisionsData?.adminData?.map((list) => {
                return (
                  <div key={list?.key} className="UserChiplistItem">
                    {list?.value}
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>

        <Grid item container mt={3}>
          <AllAssets
            projectsListData={projectsListData}
            getProjectListData={getProjectListData}
            isLoading={isLoading}
            equipmentList={equipmentList}
            getEquipmentList={getEquipmentList}
            isEquipmentLoading={isEquipmentLoading}
            vehicles={vehicleList}
            getVehicleList={getVehicleList}
            isVehicleLoading={isVehicleLoading}
            assetList={assetList}
            isAssetLoading={isAssetLoading}
            getAssetListData={getAssetListData}
            tabClick={tabClick}
            divisionId={divisionId}
            loading={loading}
            userListData={userListData}
            getuserLists={getuserLists}
            GroupListData={GroupListData}
            noOfRecords={noOfRecords}
            getuserGroupLists={getuserGroupLists}
            loadingGroup={loadingGroup}
            isDivisionDropdown={false}
            ContractorListData={ContractorListData}
            loadingContractor={loadingContractor}
            getContractorListData={getContractorListData}
          />
        </Grid>
      </div>
    </>
  );
};

export default ViewDivision;
