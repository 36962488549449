import { siteType } from "../types/siteType";

export const site = (data) => ({
  type: siteType.POST_SITE_PENDING,
  data,
});

export const siteSuccess = (data) => ({
  type: siteType.POST_SITE_SUCCESS,
  data,
});

export const siteFailure = (error) => ({
  type: siteType.POST_SITE_FAILURE,
  error,
});

export const updateSite = (data) => ({
  type: siteType.PUT_SITE_UPDATE_PENDING,
  data,
});

export const updateSiteSuccess = (data) => ({
  type: siteType.PUT_SITE_UPDATE_SUCCESS,
  data,
});

export const updateSiteFailure = (error) => ({
  type: siteType.PUT_SITE_UPDATE_FAILURE,
  error,
});

export const getSiteDetsils = (data) => ({
  type: siteType.GET_SITE_DETAILS_PENDING,
  data,
});

export const getSiteDetsilsSuccess = (data) => ({
  type: siteType.GET_SITE_DETAILS_SUCCESS,
  data,
});

export const getSiteDetailsFailure = (error) => ({
  type: siteType.GET_SITE_DETAILS_FAILURE,
  error,
});
export const getSiteClear = () => ({
  type: siteType.CLEAR_SWSITE,
  data: "",
});

export const siteList = (data) => ({
  type: siteType.GET_SITE_LIST_PENDING,
  data,
});

export const siteListSuccess = (data) => ({
  type: siteType.GET_SITE_LIST_SUCCESS,
  data,
});

export const siteListFailure = (error) => ({
  type: siteType.GET_SITE_LIST_FAILURE,
  error,
});
export const getSiteAutoSearchList = (data) => ({
  type: siteType.GET_SW_SITE_AUTO_SEARCH_PENDING,
  data,
});

export const getSiteAutoSearchListSuccess = (data) => ({
  type: siteType.GET_SW_SITE_AUTO_SEARCH_SUCCESS,
  data,
});

export const getSiteAutoSearchListFailure = (error) => ({
  type: siteType.GET_SW_SITE_AUTO_SEARCH_FAILURE,
  error,
});

export const getSite = (data) => ({
  type: siteType.GET_SITE_PENDING,
  data,
});

export const getSiteSuccess = (data) => ({
  type: siteType.GET_SITE_SUCCESS,
  data,
});

export const getSiteFailure = (error) => ({
  type: siteType.GET_SITE_FAILURE,
  error,
});
