import { getApiUrl } from "../config";
import { ORG_ID, PAGE_SIZE, USER_ID } from "../constant/constant";
import { get } from "./axiosRequest";

// get Kudos Log
export const getKudosLogService = async ({ data }) => {
  const { page, search, userId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/SafetyLog/GetKudosLogs?organisationId=${ORG_ID()}&userId=${userId}&search=${search}&page=${page}&pageSize=${PAGE_SIZE}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getReprimandLogService = async ({ data }) => {
  const { page, search, userId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/SafetyLog/GetReprimandLogs?organisationId=${ORG_ID()}&userId=${userId}&search=${search}&page=${page}&pageSize=${PAGE_SIZE}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getViolationLogService = async ({ data }) => {
  const { page, search, userId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/SafetyLog/GetViolationLogs?organisationId=${ORG_ID()}&userId=${userId}&search=${search}&page=${page}&pageSize=${PAGE_SIZE}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

