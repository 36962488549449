import React, { useState, useEffect } from "react";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import Buttons from "../../../../library/custom/button/Button";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { Grid } from "@mui/material";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import FilterCommonStatusSelect from "../../../admin/shared/FilterCommonStatusSelect";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { ISwitch, PAGE_SIZE, USER_ID } from "../../../../constant/constant";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import "./configureInspections.scss";
import { STATUS } from "../../../../constant/storm-water/constant";

const ConfigureInspections = ({
  handleEditOnClick,
  onSubmit,
  updateInspectionTypeData,
  createInspectionsType,
  updateInspectionType,
  inspectionTypeList,
  getInspectionTypeListData,
  state,
  count,
  isLoading,
}) => {
  const intialState = {
    value: "",
    stateId: 0,
    isActive: true,
  };
  const [formValue, setFormValue] = useState(intialState);
  const [isState, setIsState] = useState(false);
  const [isInspectionType, setIsInspectionType] = useState(false);
  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [stateF, setStateF] = useState(0);
  const [statusIds, setStatusIds] = useState();
  const [filter, setFilter] = useState({
    searchType: [],
  });

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      search: search ? search : "",
      stateId: stateF ? stateF : 0,
      userId: parseInt(USER_ID()),
      isActive: statusIds === 1 ? true : statusIds === 2 ? false : "",
      pageSize: PAGE_SIZE,
    };
    getInspectionTypeListData(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      search: event.target.value,
      stateId: stateF ? stateF : 0,
      userId: parseInt(USER_ID()),
      isActive: statusIds === 1 ? true : statusIds === 2 ? false : "",
      page: page,
      pageSize: PAGE_SIZE,
    };
    getInspectionTypeListData(data);
  };

  function mappedData() {
    let errors = false;
    if (formValue?.value.trim() === "") {
      setFormValue({
        ...formValue,
        value: "",
      });
      seterrorsMsgShowActive(false);
      setIsInspectionType(true);
      errors = true;
    }
    if (!formValue?.stateId) {
      setIsState(true);
      errors = true;
    }
    if (errors) {
      return false;
    }

    const index = inspectionTypeList?.findIndex(
      (obect) => obect.value?.toUpperCase() === formValue?.value?.toUpperCase()
    );
    if (index === -1) {
      seterrorsMsgShowActive(false);
      const listInspectionType = [
        {
          value: formValue?.value,
          stateId: formValue?.stateId,
          isActive: formValue?.isActive,
        },
      ];
      return { listInspectionType, userId: parseInt(USER_ID()) };
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Inspection Type already added");
    }
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  useEffect(() => {
    if (
      createInspectionsType?.status === "SUCCESS" &&
      createInspectionsType?.isLoading === false
    ) {
      setIsSubmit(false);
      setFormValue({
        ...formValue,
        value: "",
        stateId: "",
      });
      const data = {
        search: "",
        stateId: 0,
        userId: parseInt(USER_ID()),
        isActive: "",
        page: 1,
        pageSize: PAGE_SIZE,
      };
      getInspectionTypeListData(data);
    }
  }, [createInspectionsType]);

  const handleChange = (item) => {
    const newTypeList = [...inspectionTypeList];
    newTypeList.forEach((lists) => {
      if (lists.key == item.key) {
        lists.isActive = !lists.isActive;
        lists.checked = !lists.checked;
        updateInspectionTypeData({
          value: item?.value,
          stateId: item?.stateId,
          isActive: item?.isActive,
          key: item?.key,
          userId: parseInt(USER_ID()),
        });
      }
    });
  };

  useEffect(() => {
    if (
      updateInspectionType?.status === "SUCCESS" &&
      updateInspectionType?.isLoading === false
    ) {
      setIsSubmit(false);
      setFormValue({
        ...formValue,
        value: "",
        stateId: "",
      });
      const data = {
        search: "",
        stateId: 0,
        userId: parseInt(USER_ID()),
        isActive: "",
        page: 1,
        pageSize: PAGE_SIZE,
      };
      getInspectionTypeListData(data);
    }
  }, [updateInspectionType]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dataBack = {
    title: "Back to Inspections",
    route: -1,
  };
  const handleApply = () => {
    const data = {
      search: search ? search : "",
      stateId: stateF ? stateF : 0,
      userId: parseInt(USER_ID()),
      isActive: statusIds === 1 ? true : statusIds === 2 ? false : "",
      page: page,
      pageSize: PAGE_SIZE,
    };
    getInspectionTypeListData(data);
    handleClose();
  };

  const handleClear = () => {
    setStateF("");
    setStatusIds("");
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      prevStates.searchType = [];
      return prevStates;
    });
    const dataClear = {
      search: "",
      stateId: 0,
      userId: parseInt(USER_ID()),
      isActive: "",
      page: 1,
      pageSize: PAGE_SIZE,
    };
    getInspectionTypeListData(dataClear);
    handleClose();
  };

  const stateOnChanges = (e) => {
    setStateF(e.target.value);
  };

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
        setStatusIds(item?.key);
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };

  const columns = [
    {
      field: "value",
      headerName: "INSPECTION TYPE",
      flex: 2,
    },
    {
      field: "state",
      headerName: "STATE",
      flex: 1.5,
    },
    {
      flex: 1.5,
      field: "isActive",
      headerName: "STATUS",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox swins">
                <FormControlLabel
                  control={
                    <ISwitch
                      checked={rows?.row.isActive ? true : false}
                      onChange={() => handleChange(rows?.row)}
                      name="status"
                    />
                  }
                />

                <div>{rows?.row?.isActive ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={count ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={inspectionTypeList ?? []}
          fileNameXL={"inspections Type"}
        />
      ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            edit
            editOnClick={(row) => handleEditOnClick(row)}
            editLabel="Edit Inspection Type"
          />
        );
      },
    },
  ];

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Configure Inspection Type</div>
        </div>
        <Grid container mb={3} mt={4}>
          <Grid lg={4} xs={12} sm={12} md={4} item>
            <InputField
              type="text"
              isRequired={true}
              value={formValue?.value}
              onChange={(e) =>
                setFormValue({
                  ...formValue,
                  value: e.target.value,
                })
              }
              label="Inspection Type"
              placeholder="Inspection Type Here..."
              isError={isInspectionType}
              errorMsg={isInspectionType ? "Inspection Type is Required" : ""}
            />
            {errorsMsgShowActive && (
              <div className="errorMsg">{errorsMsgShow}</div>
            )}
          </Grid>
          <Grid lg={4} xs={12} sm={12} md={4} item ml={2}>
            <FormControl fullWidth>
              <FormLabels label="State" isRequired={true} />
              <SelectMenu
                placeholder="Please Select"
                listData={state}
                value={formValue?.stateId}
                onchangehandler={(e) =>
                  setFormValue({
                    ...formValue,
                    stateId: e.target.value,
                  })
                }
                isError={isState}
                errorMsg={isState ? "State is Required" : ""}
              />
            </FormControl>
          </Grid>
          <Grid lg={3} xs={12} sm={12} md={3} item>
            <Grid
              className="smallAddBtn"
              item
              lg={1.5}
              md={3}
              xs={3}
              ml={3}
              mt={4}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Create"}
                id="btnAddEquipment"
                varientAddIconBlue={true}
                disabled={isSubmit ? true : false}
                onClick={submitData}
              ></Buttons>
            </Grid>
          </Grid>
        </Grid>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>

              <FilterCommonStatusSelect
                // id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                statusType={STATUS}
                categoryType={state}
                category={stateF}
                handleApply={handleApply}
                handleClear={handleClear}
                filter={filter}
                categoryOnChanges={stateOnChanges}
                onChangeType={onChangeType}
                statusIds={statusIds}
                filterSelect={true}
                filterStatus={true}
                resetBtn={true}
                selectLable="State"
              />
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.key}
                rows={inspectionTypeList ?? []}
                rowCount={count ?? 0}
                columns={columns}
                checkboxSelection={false}
                loading={isLoading}
                page={1}
                pagination
                pageSize={PAGE_SIZE}
                rowsPerPageOptions={[10]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
            <div className="insBackBtn">
              <BackBtn dataBack={dataBack} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConfigureInspections;
