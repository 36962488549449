export const getInspectionsType = {
  GET_INSPECTION_PENDING: "[GET_INSPECTION_PENDING] Get Inspection Data",
  GET_INSPECTION_SUCCESS:
    "[GET_INSPECTION_PENDINGSUCCESS] Get Inspection Successfull",
  GET_INSPECTION_FAILURE:
    "[GET_INSPECTION_PENDINGFAILURE] Get Inspection Failed",
};

export const getDropdownDataKeyValueType = {
  GET_DROPDOWN_DATA_KEY_VALUE_PENDING:
    "[GET_DROPDOWN_DATA_KEY_VALUE_PENDING] Get Dropdown Value  Data",
  GET_DROPDOWN_DATA_KEY_VALUE_SUCCESS:
    "[GET_DROPDOWN_DATA_KEY_VALUE_SUCCESS] Get Dropdown Value  Successfull",
  GET_DROPDOWN_DATA_KEY_VALUE_FAILURE:
    "[GET_DROPDOWN_DATA_KEY_VALUE_FAILURE] Get Dropdown Value  Failed",
};

export const getSupervisorByProjectType = {
  GET_SUPERVISOR_BY_PROJECT_PENDING:
    "[GET_SUPERVISOR_BY_PROJECT] GET SUPERVISOR BY PROJECT Data",
  GET_SUPERVISOR_BY_PROJECT_SUCCESS:
    "[GET_SUPERVISOR_BY_PROJECT_SUCCESS] GET SUPERVISOR BY PROJECT Successfull",
  GET_SUPERVISOR_BY_PROJECT_FAILURE:
    "[GET_SUPERVISOR_BY_PROJECT_FAILURE] GET SUPERVISOR BY PROJECT Failed",
  SUPERVISOR_BY_PROJECT_PAGE: "SUPERVISOR_BY_PROJECT_Page",
};
