import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import PersonalContractorList from "../component/contractor/personalContractorList";
import { useNavigate } from "react-router-dom";
import {
  ADD_ADMIN_GROUP,
  ADMIN_PERSONAL_CONTRACTOR,
} from "../../../constant/routeContant";
import * as contractorAction from "../../../redux/actions/contractorAction";
import { API_STATUS } from "../../../constant/constant";

const PersonalContractorContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const NavigateSingleUserMethode = () => {
    navigate(ADMIN_PERSONAL_CONTRACTOR);
  };
  const NavigateUserGroupMethode = () => {
    navigate(ADD_ADMIN_GROUP);
  };

  const getuserLists = () => {
    dispatch(userAction.getUserList());
  };

  const deletePersonalContractorDatas = useSelector(
    (state) => state.deletePersonalContractorData
  );
  const deleteList = (id) => {
    dispatch(contractorAction.deletePersonalContractor(id));
  };

  useEffect(() => {
    getuserLists();
  }, []);

  const defaultData = {
    page: 1,
    pageSize: 10,
    search: "",
    contractorId: "",
  };

  const personalContractorListData = useSelector(
    (state) => state.getPersonalContractorListData
  );

  const getContractorListData = (data) => {
    dispatch(contractorAction.getPersonalContractorList(data));
  };
  useEffect(() => {
    if (deletePersonalContractorDatas.status === API_STATUS.SUCCESS) {
      getContractorListData(defaultData);
    }
  }, [deletePersonalContractorDatas]);

  useEffect(() => {
    getContractorListData(defaultData);
  }, []);
  return (
    <>
      <PersonalContractorList
        loading={
          personalContractorListData?.isLoading ||
          deletePersonalContractorDatas.isLoading
        }
        contractorListData={personalContractorListData?.data?.result}
        getContractorListData={getContractorListData}
        NavigateSingleUser={NavigateSingleUserMethode}
        NavigateUserGroup={NavigateUserGroupMethode}
        deleteList={deleteList}
      />
    </>
  );
};

export default PersonalContractorContainer;
