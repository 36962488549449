import React, { useEffect, useState } from "react";
import LessonDetail from "../component/lessonDetail/lessonDetail";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as lmsUserAction from "../../../redux/actions/lmsUserAction";
import Loader from "../../../library/common/Loader";
import { USER_QUIZ } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import * as userAction from "../../../redux/actions/userAction";
import { API_STATUS } from "../../../constant/constant";
import { usersAccess } from "../../../utils/rolesHelper";
import { MODULES_NAME } from "../../../constant/constant";
import * as lmsAction from "../../../redux/actions/lmsAction";
import SuccessScreen from "../../../library/custom/success/successScreen";

const LessonDetailContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const lessonId = location?.state?.lessonId;
  const [assignmsg, setAssignmsg] = useState(false);
  const [errCompltemsg, setErrCompltemsg] = useState("");
  const [comsucces, setComsucces] = useState(false);

  const navigate = useNavigate();
  const access = usersAccess(MODULES_NAME.lms);
  const userLessonViewData = useSelector(
    (state) => state?.getUserLessonView.data
  );
  const allUsers = useSelector((state) => state.allUsers?.data?.users);
  const userdata = useSelector((state) => state?.users);
  const quizStatus = userLessonViewData?.lessons?.isQuizCompeted;
  const quizId = userLessonViewData?.lessons?.quizId;
  const assignLessonResult = useSelector((state) => state.assignLesson);
  const assignLessonStatus = assignLessonResult?.status;
  const completeLessonResult = useSelector((state) => state.completeLesson);
  const completeLessonIsloading = useSelector(
    (state) => state.completeLesson?.isLoading
  );
  const completeLessonIsStatus = useSelector(
    (state) => state.completeLesson?.status
  );
  const completeLessonCode =
    completeLessonResult?.data?.responseMessages?.responseCode;
  const completeLessonresponseMessages =
    completeLessonResult?.data?.responseMessages?.responseMessage;
  // reassign code =======================================
  const [checklessonstatus, setchecklessonstatus] = useState(false);
  const CompleteLessonStatusStatus = useSelector(
    (state) => state.completedLessonStatuscheckData?.status
  );
  const CompleteLessonStatusResult = useSelector(
    (state) =>
      state.completedLessonStatuscheckData?.dataresult
        ?.getLessonCompletedListResponse
  );
  const [isSubmitAssign, setisSubmitAssign] = useState(false);
  useEffect(() => {
    if (CompleteLessonStatusResult?.length > 0) {
      setchecklessonstatus(true);
    } else {
      setchecklessonstatus(false);
    }
  }, [CompleteLessonStatusResult]);

  useEffect(() => {
    dispatch(lmsUserAction.getUserLessonView(lessonId));
    dispatch(userAction.searchUsers("", ""));
  }, [dispatch]);
  //isAdminLesson
  const isAdminLesson = location?.state?.isAdminLesson;
  const handleViewOnClick = (quizId, lessonId) => {
    navigate(USER_QUIZ, {
      state: { quizId: quizId, lessonId: lessonId,isAdminLms:isAdminLesson },
    });
  };


  function searchUsers(searchText, type, divisionId) {
    dispatch(userAction.searchAllUsers(searchText, type, divisionId));
  }

  useEffect(() => {
    if (assignLessonStatus === API_STATUS.SUCCESS) {
      setAssignmsg(true);
      const timeout = setTimeout(() => {
        setAssignmsg(false);
      }, 2000);

      return () => clearTimeout(timeout);
    } else {
      setAssignmsg(false);
    }
  }, [assignLessonStatus]);

  const completelesson = (searchText) => {
    dispatch(lmsAction.completeLesson(searchText));
  };
  useEffect(() => {
    if (
      completeLessonIsStatus === API_STATUS.SUCCESS &&
      completeLessonCode === "HBNG002"
    ) {
      setErrCompltemsg(completeLessonresponseMessages);
    }

    if (
      completeLessonIsStatus === API_STATUS.SUCCESS &&
      completeLessonCode === "HBNG001"
    ) {
      setComsucces(true);
    }
  }, [completeLessonIsloading, completeLessonCode]);

  const successScree = (id, message) => {
    return (
      <SuccessScreen
        isUpdate={message}
        route="/user-lesson"
        id={id}
        label2="Congrats! You have successfully completed the Lesson"
        label1="Lesson ID"
        label4="Lesson in "
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    dispatch(lmsAction.clearAssignlessonStatus());
    setisSubmitAssign(false);
    setErrCompltemsg("");
    setComsucces(false);
    setTimeout(() => {
      setErrCompltemsg("");
    }, 1000);
  }, []);
  function handleAllGroupSearch(serachText, divisionId, alldivision) {
    dispatch(userAction.searchAllGroup(serachText, divisionId, alldivision));
  }
  const allGroups = useSelector((state) => state.searchAllGroup);
  const isLoading = useSelector((state) => state.searchAllGroup?.isLoading);
  const AlluserisLoading = useSelector((state) => state.allUsers?.isLoading);

  const getStatusCheckLessoncompleted = (data) => {
    dispatch(lmsAction.getCompletedLessonStatuscheck(data));
  };
  const showLoader = useSelector(
    (state) =>
      state.assignLesson?.isLoading ||
      state.getUserLessonView?.isLoading ||
      state.completeLesson?.isLoading
  );

  return (
    <>
      {showLoader && <Loader />}
      {comsucces && completeLessonCode == "HBNG001" ? (
        successScree(lessonId, "Lesson Completed Successfully")
      ) : (
        <LessonDetail
          viewOnClick={() => handleViewOnClick(quizId, lessonId)}
          viewData={userLessonViewData}
          userdatafinal={userdata}
          onSearch={searchUsers}
          allUsers={allUsers}
          assignmsg={assignmsg}
          access={access}
          quizStatus={quizStatus}
          completelesson={completelesson}
          completeLessonIsStatus={completeLessonIsStatus}
          errCompltemsg={errCompltemsg}
          handleAllGroupSearch={handleAllGroupSearch}
          userGroups={allGroups?.data?.groupList}
          isLoading={isLoading}
          AlluserisLoading={AlluserisLoading}
          assignLessonStatus={assignLessonStatus}
          isAdminLesson={isAdminLesson}
          getStatusCheckLessoncompleted={getStatusCheckLessoncompleted}
          checklessonstatus={checklessonstatus}
          setchecklessonstatus={setchecklessonstatus}
          CompleteLessonStatusResult={CompleteLessonStatusResult}
          CompleteLessonStatusStatus={CompleteLessonStatusStatus}
          setisSubmitAssign={setisSubmitAssign}
          isSubmitAssign={isSubmitAssign}
        />
      )}
    </>
  );
};

export default LessonDetailContainer;
