import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import "./userForm.scss";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { dateValiadtor, getFormatedDate } from "../../../../utils/helper";
import {
  COUNTRY_CODE,
  ORG_ID,
  STROM_SAFETY_USERS_TEMPLATE_URL,
  STROM_USERS_TEMPLATE_URL,
  USERS_TEMPLATE_URL,
  USER_ID,
} from "../../../../constant/constant";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import Checkbox from "../../../../library/checkbox/checkbox";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";

const UserForm = ({
  adduserLists,
  divisions,
  jobTitles,
  roles,
  userData,
  editMode,
  userId,
  roleStormWater,
  divisionStormWater,
  getDropDowndata,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitleName] = useState(null);
  const [date, setDate] = useState(null);
  const [division, setDivision] = useState("");
  const [divisionIds, setDivisionIds] = useState([]);
  const [stromWaterdivision, setStromWaterDivision] = useState("");
  const [stromWaterdivisionIds, setStromWaterDivisionIds] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectUserRole, setSelectUserRole] = useState("");
  const [stromWaterRole, setStromWaterRole] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [companyNameErro, setCompanyNameError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [dateErrMsg, setDateErrMsg] = useState("");
  const [divisionError, setDivisionError] = useState(false);
  const [stromWaterdivisionError, setStromWaterDivisionError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [selectUserRoleError, setSelectUserRoleError] = useState(false);
  const [stormWaterRoleError, setStormWaterRoleError] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [checkBox, setcheckBox] = useState(true);
  const [emailverify, setEmailverify] = useState(false);
  const [serviceType, setServiceType] = useState([]);
  const [serviceTypeError, setServiceTypeError] = useState(false);

  const [phoneverify, setPhoneverify] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const serviceTypes = userInfo?.product;

  useEffect(() => {
    if (serviceTypes?.length === 1) {
      setServiceType(serviceTypes[0].value);
    }
  }, [serviceTypes]);

  useEffect(() => {
    if (editMode && serviceType?.length === 2) {
      getDropDowndata(1);
      setTimeout(() => {
        getDropDowndata(2);
      }, 2000);
    } else {
      if (serviceType?.length === 2 && serviceType[1] === "Safety") {
        getDropDowndata(1);
      } else if (serviceType?.length === 2 && serviceType[1] === "Stormwater") {
        getDropDowndata(2);
      } else if (serviceType?.length === 1 && serviceType[0] === "Safety") {
        getDropDowndata(1);
      } else if (serviceType?.length === 1 && serviceType[0] === "Stormwater") {
        getDropDowndata(2);
      } else if (serviceType?.length === 6) {
        getDropDowndata(1);
      } else if (serviceType?.length === 10) {
        getDropDowndata(2);
      }
    }
  }, [serviceType]);

  const selectedServiceTypeData = localStorage?.getItem("serviceType");

  useEffect(() => {
    const companyNames = localStorage.getItem("companyName");
    const orgNames = localStorage.getItem("orgName");
    if (orgNames) {
      setCompanyName(orgNames);
    } else {
      setCompanyName(companyNames);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setFirstName(userData?.firstname);
      setLastName(userData?.lastname);
      setDivision(userData?.divisionId);
      setEmail(userData?.email);
      // setCompanyName(userData?.companyname);
      const phone = userData?.phonenumber;
      setPhone(
        phone && phone > 10
          ? phone.substring(phone.length - 10, phone.length)
          : phone
      );
      setSelectUserRole(userData?.safetyRoles);
      setStromWaterRole(userData?.stormRoles);
      setDate(userData?.startDate);
      setJobTitleName(userData?.jobTitleId);
      setcheckBox(!userData?.startDate);
      setServiceType(
        userData?.productIds?.map((item) => item.value) ||
          userData?.product?.map((item) => item.value)
      );
    }
  }, [userData]);

  useEffect(() => {
    let valueArray = [];
    divisions?.forEach((data) => {
      let newarr = userData?.safetyDivisionIds?.forEach((i) => {
        if (i === data.key) valueArray.push(data.value);
      });
      if (divisionIds.indexOf(data) >= 0) {
        valueArray.push(data.value);
      }
    });
    setDivisionIds(valueArray);
  }, [userData, divisions]);

  useEffect(() => {
    let valueArray = [];
    divisionStormWater?.forEach((data) => {
      let newarr = userData?.stormDivisionIds?.forEach((i) => {
        if (i === data.key) valueArray.push(data.value);
      });
      if (stromWaterdivisionIds.indexOf(data) >= 0) {
        valueArray.push(data.value);
      }
    });
    setStromWaterDivisionIds(valueArray);
  }, [userData, divisionStormWater]);

  const history = useNavigate();

  const firstNameHandler = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameHandler = (e) => {
    setLastName(e.target.value);
  };

  const onChangeJobTitle = (e) => {
    setJobTitleName(e.target.value);
  };

  const onchangedatePicker = (e) => {
    setDate(e);
    setDateErrMsg(false);
  };

  const onChangeDivision = (e) => {
    setDivision(e.target.value);
  };

  const onChangeStromWaterDivision = (e) => {
    setStromWaterDivision(e.target.value);
  };

  const onChangeMultipleStromWaterDivision = (event) => {
    const {
      target: { value },
    } = event;

    setStromWaterDivisionIds(
      typeof value === "string" ? value.split(",") : value
    );
  };
  function findValueMultipleSelectStromWater(arrrayofObject, selectedArray) {
    let valueArray = [];
    arrrayofObject?.forEach((data) => {
      if (selectedArray.indexOf(data.value) >= 0) {
        valueArray.push(data.key);
      }
    });
    return valueArray;
  }

  const stormWaterdivisionsData = findValueMultipleSelectStromWater(
    divisionStormWater,
    stromWaterdivisionIds
  );

  const onChangeMultipleDivision = (event) => {
    const {
      target: { value },
    } = event;

    setDivisionIds(typeof value === "string" ? value.split(",") : value);
  };
  function findValueMultipleSelect(arrrayofObject, selectedArray) {
    let valueArray = [];
    arrrayofObject?.forEach((data) => {
      if (selectedArray.indexOf(data.value) >= 0) {
        valueArray.push(data.key);
      }
    });
    return valueArray;
  }
  const divisionsData = findValueMultipleSelect(divisions, divisionIds);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    const isValidEmail = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailverify(false);
    } else {
      setEmailverify(true);
    }

    setEmail(e.target.value);
    if (e.target.value == "") {
      setEmailverify(false);
    }
  };

  const onChangeSelectUserRole = (e) => {
    setSelectUserRole(e.target.value);
  };

  const onChangeStormWaterUserRole = (e) => {
    setStromWaterRole(e.target.value);
  };

  const onChangeServiceType = (event) => {
    setServiceTypeError(false);
    const {
      target: { value },
    } = event;
    setServiceType(typeof value === "string" ? value.split(",") : value);
  };

  const findValueMultipleSelectServiveType = (
    arrrayofObject,
    selectedArray
  ) => {
    if (serviceTypes?.length > 1) {
      let valueArray = [];
      arrrayofObject.forEach((data) => {
        if (selectedArray?.indexOf(data.value) >= 0) {
          valueArray.push(data.key);
        }
      });
      return valueArray;
    }
  };

  const userIds = localStorage.getItem("userId");
  const onClickAddUser = (e) => {
    const selectedServiceType = findValueMultipleSelectServiveType(
      serviceTypes,
      serviceType
    );
    let isFieldEmpty = false;
    if (firstName.trim() === "") {
      setFirstName("");
      setFirstNameError(true);
      isFieldEmpty = true;
    }
    if (lastName.trim() === "") {
      setLastName("");
      setLastNameError(true);
      isFieldEmpty = true;
    }
    if (!email) {
      setEmailError(true);
      isFieldEmpty = true;
    }
    if (!serviceType || serviceType?.length === 0) {
      setServiceTypeError(true);
      isFieldEmpty = true;
    }
    if (!phone) {
      setPhoneError(true);
      isFieldEmpty = true;
    }

    if (!date && checkBox === false) {
      setDateError(true);
      isFieldEmpty = true;
    }
    if (date) {
      const validDate = dateValiadtor(getFormatedDate(date));
      if (validDate === false) {
        setDateErrMsg(true);
        isFieldEmpty = true;
      } else if (validDate === "Valid date") {
        setDateErrMsg(false);
      }
    }
    if (serviceType?.includes("Safety")) {
      if (!editMode && !division) {
        setDivisionError(true);
        isFieldEmpty = true;
      }
    }
    if (serviceType?.includes("Safety")) {
      if (editMode && divisionIds?.length == 0) {
        setDivisionError(true);
        isFieldEmpty = true;
      }
    }

    if (serviceType?.includes("Safety")) {
      if (selectUserRole.length === 0) {
        if (editMode && userData?.safetyRole?.length > 0) {
          setSelectUserRoleError(false);
          isFieldEmpty = false;
        } else {
          setSelectUserRoleError(true);
          isFieldEmpty = true;
        }
      }
    }

    if (serviceType?.includes("Stormwater")) {
      if (!editMode && !stromWaterdivision) {
        setStromWaterDivisionError(true);
        isFieldEmpty = true;
      }
    }

    if (serviceType?.includes("Stormwater")) {
      if (editMode && stromWaterdivisionIds?.length == 0) {
        setStromWaterDivisionError(true);
        isFieldEmpty = true;
      }
    }

    if (serviceType?.includes("Stormwater")) {
      if (stromWaterRole.length === 0) {
        if (editMode && userData?.stormRole?.length > 0) {
          setStormWaterRoleError(false);
          isFieldEmpty = false;
        } else {
          setStormWaterRoleError(true);
          isFieldEmpty = true;
        }
      }
    }

    if (emailverify == true) {
      isFieldEmpty = true;
    }
    if (phone?.length != 10) {
      setPhoneverify(true);
      isFieldEmpty = true;
      if (phone === "") {
        setPhoneverify(false);
      }
    }
    if (phone?.length == 0) {
      setPhoneverify(false);
      isFieldEmpty = true;
    }
    if (!isFieldEmpty) {
      let findData = {};
      if (!editMode) {
        findData = roles?.find((role) => role.key === selectUserRole);
      }
      let findDataStromWater = {};
      if (!editMode) {
        findDataStromWater = roleStormWater?.find(
          (role) => role.key === stromWaterRole
        );
      }
      let sendData = {
        users: [
          {
            firstname: firstName?.trim(),
            lastname: lastName?.trim(),
            email: email,
            isemailverified: false,
            companyname: companyName,
            phonenumber: phone,
            isphonenumberverified: true,
            jobTitleId: jobTitle,
            startDate: checkBox ? null : getFormatedDate(date),
            // isProvided: !checkBox,
            safetyDivisionId: division ? division : null,
            safetyRole: findData?.key,
            stormDivisionId: stromWaterdivision ? stromWaterdivision : null,
            stormRole: findDataStromWater?.key,
            status: true,
            organisationId: ORG_ID(),
            isdCode: COUNTRY_CODE,
            productIds: selectedServiceType
              ? selectedServiceType
              : [serviceType == "Safety" ? 1 : 2],
          },
        ],
        createdbyuser: userIds,
        createdbyapp: "0",
      };
      const stormRole = serviceType?.includes("Stormwater")
        ? editMode && userData?.stormRoles?.length > 0
          ? userData?.stormRoles.map((item) => item.key)
          : [stromWaterRole]
        : null;

      const safetyRole = serviceType?.includes("Safety")
        ? editMode && userData?.safetyRoles?.length > 0
          ? userData?.safetyRoles.map((item) => item.key)
          : [selectUserRole]
        : null;

      const safetyDivisionsData = serviceType?.includes("Safety")
        ? divisionsData
        : null;

      const stromDivisionsData = serviceType?.includes("Stormwater")
        ? stormWaterdivisionsData
        : null;
      let sendDataEdit = {
        firstname: firstName?.trim(),
        companyname: companyName,
        lastname: lastName?.trim(),
        email: email,
        isemailverified: false,
        phonenumber: phone,
        isphonenumberverified: true,
        jobTitleId: jobTitle,
        startDate: checkBox ? null : getFormatedDate(date),
        safetyDivisionIds: safetyDivisionsData,
        safetyRole: safetyRole,
        stormDivisionIds: stromDivisionsData,
        stormRole: stormRole,
        status: true,
        organisationId: ORG_ID(),
        userid: +userId,
        modifiedbyuser: userIds,
        modifiedbyapp: "0",
        isdCode: COUNTRY_CODE,
        productIds: selectedServiceType
          ? selectedServiceType
          : [serviceType == "Safety" ? 1 : 2],
      };
      adduserLists(editMode ? sendDataEdit : sendData);
    }
  };

  const handleFileChanges = (data) => {
    const selectedServiceType = findValueMultipleSelectServiveType(
      serviceTypes,
      serviceType
    );
    const users = data.map((x, index) => {
      x.firstname = x.firstname?.trim();
      x.lastname = x.lastname?.trim();
      x.phonenumber = `${x?.phonenumber?.replace(/-/g, "")}`;
      x.isdCode = COUNTRY_CODE;
      x.status = true;
      x.isphonenumberverified = true;
      x.isemailverified = true;
      x.jobTitleId = parseInt(x.jobTitleId);
      x.startDate =
        x.startDate === undefined ? null : x?.startDate?.replace(/\//g, "-");
      x.productIds = x.productIds?.split(",");
      x.safetyRole = x.safetyRole ? parseInt(x.safetyRole) : null;
      x.safetyDivisionId = x.safetyDivisionId
        ? parseInt(x.safetyDivisionId)
        : null;
      x.stormDivisionId = x.stormDivisionId
        ? parseInt(x.stormDivisionId)
        : null;
      x.stormRole = x.stormRole ? parseInt(x.stormRole) : null;
      delete x.id;
      return x;
    });
    const usersData = {
      users: users,
      createdbyuser: USER_ID(),
      createdbyapp: "0",
    };
    adduserLists(usersData);
  };
  const mapBulkError = (data) => {
    let usersWithError = [];
    data.forEach((x) => {
      const validDate = dateValiadtor(
        getFormatedDate(x.startDate?.replace(/\//g, "-"))
      );
      if (
        x.firstname?.trim() === undefined ||
        null ||
        "" ||
        x.lastname?.trim() === undefined ||
        null ||
        "" ||
        x.email?.trim() === undefined ||
        null ||
        "" ||
        x.phonenumber?.trim() === undefined ||
        null ||
        "" ||
        x.phonenumber?.trim().length !== 10 ||
        x.productIds?.trim() === undefined ||
        null ||
        "" ||
        (x.productIds?.split(",").includes("1") &&
          x.safetyRole?.trim() === undefined) ||
        null ||
        "" ||
        (x.productIds?.split(",").includes("1") &&
          x.safetyDivisionId?.trim() === undefined) ||
        null ||
        "" ||
        (x.productIds?.split(",").includes("2") &&
          x.stormRole?.trim() === undefined) ||
        null ||
        "" ||
        (x.productIds?.split(",").includes("2") &&
          x.stormDivisionId?.trim() === undefined) ||
        null ||
        ""
      ) {
        usersWithError.push(x);
        return;
      }
    });
    return usersWithError;
  };

  const checkBoxHandler = () => {
    setDate(null);
    setcheckBox(!checkBox);
    if (!checkBox) {
      setDateError(false);
    } else {
      setDateError(true);
    }
  };

  useEffect(() => {
    if (selectedServiceTypeData) {
      const newListData = serviceTypes?.find(
        (x) => x.key == selectedServiceTypeData
      );
      setServiceType(
        typeof newListData?.value === "string"
          ? newListData?.value.split(",")
          : newListData?.value
      );
    }
  }, [selectedServiceTypeData]);

  useEffect(() => {
    if (!phone) {
      setPhoneverify(false);
    }
  }, [phone]);
  return (
    <Grid container mt={4}>
      <Grid item lg={9} xs={12} sm={12} md={9}>
        <Grid container>
          <Grid item lg={6} xs={12} sm={12} md={6} textAlign="left">
            <div className="heading_style">
              {editMode
                ? "Edit Individual User"
                : isBulkUpload
                ? "Add Multi User"
                : " Add Individual User"}
            </div>
          </Grid>
          {!editMode ? (
            <Grid item lg={6} xs={12} sm={12} md={6} textAlign="right">
              {checkPrivileges([12, 64]) && (
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={isBulkUpload ? "Single Users" : "Bulk Upload"}
                  varientContained={true}
                  onClick={() => setIsBulkUpload(!isBulkUpload)}
                ></Buttons>
              )}
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <div>
          {isBulkUpload ? (
            <Grid item lg={9} xs={9} sm={9} md={9} mt={4}>
              <BulkUpload
                handleFileChanges={handleFileChanges}
                mapBulkError={mapBulkError}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                templateUrl={STROM_SAFETY_USERS_TEMPLATE_URL}
                templateText="Download Template"
                pocFirstNameKey="firstname"
                pocLastNameKey="lastname"
                pocPhoneKey="phonenumber"
                pocFirstNamelabel="First Name"
                pocLastNamelabel="Last Name"
                pocPhonelabel="Phone"
              />
            </Grid>
          ) : (
            <Grid container item md={9} xs={9} className="addShadowBox">
              <Grid container mt={2} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={false}
                      isRequired={true}
                      type="text"
                      label={"First Name"}
                      value={firstName}
                      placeholder="Enter first name"
                      onChange={(e) => firstNameHandler(e)}
                    />
                  </FormControl>
                  {firstNameError && !firstName && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={false}
                      isRequired={true}
                      type="text"
                      label={"Last Name"}
                      value={lastName}
                      placeholder="Enter last name"
                      onChange={(e) => lastNameHandler(e)}
                    />
                  </FormControl>
                  {lastNameError && !lastName && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid container mt={1} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={false}
                      isRequired={true}
                      type="text"
                      label={"Email"}
                      value={email}
                      placeholder="Enter Email"
                      onChange={(e) => onChangeEmail(e)}
                    />
                  </FormControl>
                  {emailError && !email && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                  {emailverify && (
                    <div className="errorMsg">
                      Please enter a valid Email address
                    </div>
                  )}
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true}>
                    <NumberField
                      disabled={editMode ? true : false}
                      isRequired={true}
                      type="text"
                      label={"Phone"}
                      maxLength="10"
                      value={phone}
                      placeholder={`Enter Phone number without ${COUNTRY_CODE}`}
                      onChange={(value) =>
                        value.length <= 10 ? setPhone(value) : phone
                      }
                    />
                  </FormControl>
                  {phoneError && !phone && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                  {phoneverify && phone?.length != 10 && (
                    <div className="errorMsg">
                      Please enter 10 digit valid Phone Number
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid item container mt={0} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="Company" />
                    <InputField
                      disabled={true}
                      isRequired={true}
                      type="text"
                      value={companyName}
                    />
                  </FormControl>
                  {companyNameErro && !companyName && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="Job Title" isRequired={false} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={jobTitles}
                      value={jobTitle}
                      onchangehandler={(e) => onChangeJobTitle(e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container mt={0} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label={"Hire Date"} isRequired={true} />
                    <DatePicker
                      value={date}
                      disabled={checkBox}
                      onChangeHandler={(e) => onchangedatePicker(e)}
                    />
                  </FormControl>
                  {dateError && !date && (
                    <div className="errorMsg">Please select required field</div>
                  )}
                  {dateErrMsg && date != null && (
                    <div className="errorMsg">Invalid Date</div>
                  )}
                </Grid>

                {serviceTypes.length > 1 && (
                  <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                    <FormControl fullWidth>
                      <FormLabels label="Service Type" isRequired={true} />
                      <MultiSelectBox
                        placeholder={"Please Select"}
                        listData={serviceTypes}
                        onChange={(e) => onChangeServiceType(e)}
                        value={serviceType || []}
                        isError={serviceTypeError}
                        errorMessage="Selection is required for this field"
                      />
                    </FormControl>
                  </Grid>
                )}
                {serviceTypes.length === 1 && (
                  <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                    <FormControl fullWidth>
                      <FormLabels label="Service Type" isRequired={true} />
                      {serviceTypes?.map((value, index) => {
                        return (
                          <div className="serviveTypeStyle" key={value?.key}>
                            {" "}
                            {value?.value}
                          </div>
                        );
                      })}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid item container mt={0} spacing={1}>
                <Grid item lg={5} xs={12} sm={12} md={6}>
                  <div className="typeTxt">
                    <Checkbox
                      checked={checkBox}
                      onChange={() => checkBoxHandler()}
                      label={"Did Not Provide"}
                    />
                  </div>
                </Grid>
              </Grid>
              {serviceType == 1 ||
                (serviceType?.includes("Safety") && (
                  <>
                    <Grid container>
                      <Grid
                        item
                        lg={6}
                        xs={12}
                        sm={12}
                        md={6}
                        mt={4}
                        textAlign="left"
                      >
                        <div className="heading_style">Safety</div>
                      </Grid>
                    </Grid>

                    <Grid item container mt={0} spacing={3}>
                      {editMode ? (
                        <Grid item lg={6} xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <FormLabels label="Division" isRequired={true} />
                            <MultiSelectBox
                              placeholder={"Please Select"}
                              listData={divisions}
                              onChange={(e) => onChangeMultipleDivision(e)}
                              value={divisionIds || []}
                            />
                          </FormControl>
                          {divisionError && divisionIds?.length === 0 && (
                            <div className="errorMsg">
                              Please enter this required field
                            </div>
                          )}
                        </Grid>
                      ) : (
                        <Grid item lg={6} xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <FormLabels label="Division" isRequired={true} />
                            <SelectMenu
                              placeholder="Please Select"
                              listData={divisions}
                              value={division}
                              onchangehandler={(e) => onChangeDivision(e)}
                            />
                          </FormControl>
                          {divisionError && !division && (
                            <div className="errorMsg">
                              Please enter this required field
                            </div>
                          )}
                        </Grid>
                      )}
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <FormLabels
                            label={
                              editMode && userData?.safetyRoles?.length > 0
                                ? "Assigned User Role"
                                : "Select User Role"
                            }
                            isRequired={true}
                          />
                          {editMode && userData?.safetyRoles?.length > 0 ? (
                            <>
                              {userData?.safetyRoles?.map((value, index) => {
                                return (
                                  <div key={value?.key}>
                                    {value?.value}{" "}
                                    {index + 1 == userData?.safetyRoles.length
                                      ? ""
                                      : ","}
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <SelectMenu
                                placeholder="Please Select"
                                listData={roles}
                                value={selectUserRole}
                                onchangehandler={(e) =>
                                  onChangeSelectUserRole(e)
                                }
                              />
                              {selectUserRoleError &&
                                selectUserRole?.length === 0 && (
                                  <div className="errorMsg">
                                    Please enter this required field
                                  </div>
                                )}
                            </>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                ))}

              {serviceType?.includes("Stormwater") && (
                <>
                  <Grid container>
                    <Grid
                      item
                      lg={6}
                      xs={12}
                      sm={12}
                      md={6}
                      mt={4}
                      textAlign="left"
                    >
                      <div className="heading_style">Stormwater</div>
                    </Grid>
                  </Grid>

                  <Grid item container mt={0} spacing={3}>
                    {editMode ? (
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <FormLabels label="Division" isRequired={true} />
                          <MultiSelectBox
                            placeholder={"Please Select"}
                            listData={divisionStormWater}
                            onChange={(e) =>
                              onChangeMultipleStromWaterDivision(e)
                            }
                            value={stromWaterdivisionIds || []}
                          />
                        </FormControl>
                        {stromWaterdivisionError &&
                          stromWaterdivisionIds?.length === 0 && (
                            <div className="errorMsg">
                              Please enter this required field
                            </div>
                          )}
                      </Grid>
                    ) : (
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <FormLabels label="Division" isRequired={true} />
                          <SelectMenu
                            placeholder="Please Select"
                            listData={divisionStormWater}
                            value={stromWaterdivision}
                            onchangehandler={(e) =>
                              onChangeStromWaterDivision(e)
                            }
                          />
                        </FormControl>
                        {stromWaterdivisionError && !stromWaterdivision && (
                          <div className="errorMsg">
                            Please enter this required field
                          </div>
                        )}
                      </Grid>
                    )}
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels
                          label={
                            editMode && userData?.stormRoles?.length > 0
                              ? "Assigned User Role"
                              : "Select User Role"
                          }
                          isRequired={true}
                        />
                        {editMode && userData?.stormRoles?.length > 0 ? (
                          <>
                            {" "}
                            {userData?.stormRoles?.map((value, index) => {
                              return (
                                <div key={value?.key}>
                                  {value?.value}{" "}
                                  {index + 1 == userData?.stormRoles.length
                                    ? ""
                                    : ","}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <SelectMenu
                              placeholder="Please Select"
                              listData={roleStormWater}
                              value={stromWaterRole}
                              onchangehandler={(e) =>
                                onChangeStormWaterUserRole(e)
                              }
                            />

                            {stormWaterRoleError &&
                              stromWaterRole?.length === 0 && (
                                <div className="errorMsg">
                                  Please enter this required field
                                </div>
                              )}
                          </>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid
                container
                mb={10}
                mt={1}
                spacing={3}
                // className="addnewrolecls"
              >
                <Grid
                  item
                  lg={6}
                  xs={12}
                  sm={12}
                  md={6}
                  textAlign="left"
                ></Grid>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <div className="adduser_btn">
                    <div
                      className="cancle_heading_style"
                      onClick={() => history(-1)}
                    >
                      Cancel
                    </div>
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={editMode ? "Update User" : "Add User"}
                      varientContained={true}
                      onClick={(e) => onClickAddUser(e)}
                    ></Buttons>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default UserForm;
