import React from "react";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { FormControl, Grid } from "@mui/material";
import "./dailyReport.scss";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import Buttons from "../../../../library/custom/button/Button";
import commonImages from "../../../../assets";
import { QUESTION_OPTION } from "../../../../constant/storm-water/constant";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";

const DailyReport = ({
  locationData,
  userInfo,
  fuelStorage,
  constructionEntrance,
  fuelStorageHandler,
  constructionEntranceHandler,
  inspectionDailyReportDetails,
  submitData,
  isSubmit,
  fuelStorageError,
  constructionEntranceError,
  handleEditClick,
  handleCancelClick,
  handleSaveClick,
  editingLogId,
  onChangConstructionEntrance,
  onChangFuelStorage,
  selectFuelStorage,
  selectConstructionEntrance,
}) => {
  const currentDate = new Date();
  const currentMonthName = currentDate.toLocaleString("default", {
    month: "long",
  });

  console.log("inspectionDailyReportDetails", inspectionDailyReportDetails);
  return (
    <>
      <Grid container item lg={9} xs={12} sm={12} md={9} mb={4}>
        <Grid item lg={12} xs={12} sm={12} md={12} textAlign="left">
          <div className="heading_style">Inspection Report Daily Log</div>
        </Grid>
      </Grid>
      <Grid
        container
        item
        md={12}
        xs={12}
        spacing={2}
        className="addShadowBox dailyReport"
      >
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          textAlign="left"
          mt={0}
          mb={2}
        >
          <div className="heading_style">
            NPDES & EROSION CONTROL INSPECTION REPORT DAILY LOG
          </div>
        </Grid>
        <Grid container item md={10} xs={12} spacing={2}>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label={"Name of Inspector"}
                value={`${userInfo?.firstName} ${userInfo?.lastName}`}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label={"Project Name"}
                value={locationData?.projectName}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label={"Project Location"}
                value={locationData?.projectLocation}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label={"Month of Inspection"}
                value={currentMonthName}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label={"Date of Inspection Log"}
                value={locationData?.dateSchedule}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label={"Rainfall Amount (inches)"}
                value={locationData?.rainfall}
              />
            </FormControl>
          </Grid>

          <Grid item md={12} xs={12} mt={2}>
            <FormControl fullWidth>
              <FormLabels label="Fuel Storage" isRequired={true} />
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radioGroup"
              >
                <RadioButton
                  checked={fuelStorage === "1"}
                  value="1"
                  label="Acceptable"
                  onChange={(e) => fuelStorageHandler(e.target.value)}
                ></RadioButton>
                <RadioButton
                  checked={fuelStorage === "2"}
                  value="2"
                  label="Not Acceptable"
                  onChange={(e) => fuelStorageHandler(e.target.value)}
                ></RadioButton>
                <RadioButton
                  checked={fuelStorage === "3"}
                  value="3"
                  label="Not Applicable"
                  onChange={(e) => fuelStorageHandler(e.target.value)}
                ></RadioButton>
              </RadioGroup>
            </FormControl>
            {fuelStorageError && !fuelStorage && (
              <p className="error">Please select fuel storage </p>
            )}
          </Grid>

          <Grid item md={12} xs={12}>
            <FormControl fullWidth>
              <FormLabels label="Construction Entrance" isRequired={true} />
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radioGroup"
              >
                <RadioButton
                  checked={constructionEntrance === "1"}
                  value="1"
                  label="Acceptable"
                  onChange={(e) => constructionEntranceHandler(e.target.value)}
                ></RadioButton>
                <RadioButton
                  checked={constructionEntrance === "2"}
                  value="2"
                  label="Not Acceptable"
                  onChange={(e) => constructionEntranceHandler(e.target.value)}
                ></RadioButton>
                <RadioButton
                  checked={constructionEntrance === "3"}
                  value="3"
                  label="Not Applicable"
                  onChange={(e) => constructionEntranceHandler(e.target.value)}
                ></RadioButton>
              </RadioGroup>
            </FormControl>
            {constructionEntranceError && !constructionEntrance && (
              <p className="error">Please select construction entrance </p>
            )}
          </Grid>

          <Grid container mt={3} className="buttonEnd">
            <Grid item lg={4} xs={12} sm={6} md={6}>
              <Buttons
                varientContained={true}
                label={"Save"}
                onClick={submitData}
                disabled={isSubmit ? true : false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <div className="viewReportIns">
              <div className="title">Previous Entries</div>
              <table>
                <tr className="tableHead">
                  <th>Date</th>
                  <th>Inspector</th>
                  <th>Rain (Inches)</th>
                  <th>Fuel Storage</th>
                  <th>Construction Entrance</th>
                  <th></th>
                </tr>

                {inspectionDailyReportDetails?.map((item) => {
                  const isEditing = editingLogId === item?.logId;
                  return (
                    <tr key={item?.logId}>
                      <td>{item?.date}</td>
                      <td>{item?.inspectorName}</td>
                      <td>{item?.rainfall}</td>
                      <td>
                        {isEditing ? (
                          <>
                            <SelectMenu
                              placeholder="Please Select"
                              listData={QUESTION_OPTION ?? []}
                              value={selectFuelStorage}
                              onchangehandler={(e) =>
                                onChangFuelStorage(e.target.value)
                              }
                            />
                          </>
                        ) : (
                          <>
                            {parseInt(item?.fuelStorage) === 1
                              ? "Acceptable"
                              : parseInt(item?.fuelStorage) === 2
                              ? "Not Acceptable"
                              : parseInt(item?.fuelStorage) === 3
                              ? "Not Applicable"
                              : ""}
                          </>
                        )}
                      </td>
                      <td>
                        {isEditing ? (
                          <>
                            <SelectMenu
                              placeholder="Please Select"
                              listData={QUESTION_OPTION ?? []}
                              value={selectConstructionEntrance}
                              onchangehandler={(e) =>
                                onChangConstructionEntrance(e.target.value)
                              }
                            />
                          </>
                        ) : (
                          <>
                            {parseInt(item?.constructionEntrance) === 1
                              ? "Acceptable"
                              : parseInt(item?.constructionEntrance) === 2
                              ? "Not Acceptable"
                              : parseInt(item?.constructionEntrance) === 3
                              ? "Not Applicable"
                              : ""}
                          </>
                        )}
                      </td>
                      <td>
                        {isEditing ? (
                          <div className="tableSubmit">
                            <div
                              className="submit"
                              onClick={() => handleSaveClick(item)}
                            >
                              Save
                            </div>
                            <div className="cancel" onClick={handleCancelClick}>
                              Cancel
                            </div>
                          </div>
                        ) : (
                          <div
                            className="editIcon"
                            onClick={() => handleEditClick(item)}
                          >
                            <img src={commonImages?.editicon} alt="" />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DailyReport;
