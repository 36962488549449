import React, { useRef } from "react";
import { Grid, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../textBox/InputField";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import "./Section.scss";
import Buttons from "../button/Button";
import JoditEditor from "jodit-react";
import FormDropZone from "../../../library/dropZone/FormDropZone";

export default function Section(props) {
  const {
    singleSection,
    keyNo,
    handleOnChangeSectionForm,
    sectionImageUpload,
    isErrorSectionName = false,
    id,
    cancelSectionList,
    deleteImageURL,
    sectionFileUploads,
    deleteUrlURL,
  } = props;

  const editor = useRef(null);

  return (
    <>
      <div className="overideSection" key={keyNo}>
        <div className="sectionBox">
          <h1 className="title">{`Section${keyNo}`}</h1>

          <Grid item container mt={3}>
            <Grid lg={12} xs={12} sm={12} md={12}>
              <FormDropZone
                key={singleSection.id}
                label={"Add Section Photo"}
                id="uploadPhoto"
                paraText1={"Drop the file here ..."}
                paraText2={"Drop Image here, "}
                paraText3={"or Browse"}
                deleteImageURL={(url) => deleteImageURL(url)}
                handleFileChange={(photo, id) => sectionImageUpload(photo, id)}
                imageUrl={
                  singleSection.imgFilesUrl ? singleSection.imgFilesUrl : []
                }
                fileType={10}
                isDynamics={false}
                isMuliple={false}
                isDoc={false}
                isImage={true}
                allFiles={false}
              />
            </Grid>
          </Grid>

          <Grid item container mt={1} spacing={2}>
            <Grid item lg={12} xs={12} sm={12} md={8}>
              <FormControl fullWidth>
                <FormLabels label="Add  Text" />
                <JoditEditor
                  ref={editor}
                  hidePoweredByJodit="false"
                  value={singleSection.Text}
                  tabIndex={1}
                  onBlur={(e) =>
                    handleOnChangeSectionForm(e, singleSection, "Text")
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container mt={1} spacing={2}>
            <Grid item lg={12} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  type="text"
                  id="embededVideoLink"
                  label="Embed Video Link"
                  placeholder="Embed Video Link"
                  value={singleSection.embededVideoLink}
                  isError={isErrorSectionName}
                  onChange={(e) =>
                    handleOnChangeSectionForm(
                      e,
                      singleSection,
                      "embededVideoLink"
                    )
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container mt={4}>
            <Grid lg={12} xs={12} sm={12} md={12}>
              <FormDropZone
                key={singleSection.id}
                label={"Add Document"}
                id="uploadDocs"
                paraText1={"Drop the file here ..."}
                paraText2={"Drop Document here, "}
                paraText3={"or Browse"}
                handleFileChange={(photo, id) => sectionFileUploads(photo, id)}
                imageUrl={
                  singleSection.docFilesUrl ? singleSection.docFilesUrl : []
                }
                deleteImageURL={(url) => deleteUrlURL(url)}
                fileType={10}
                isDynamics={false}
                isMuliple={false}
                isDoc={true}
                isImage={false}
                allFiles={false}
              />
            </Grid>
          </Grid>
        </div>
        <div className="sectionCancel">
          <Grid item container mt={3}>
            <Grid lg={12} xs={12} sm={12} md={12}>
              <Box mb={2} textAlign="right">
                <Buttons
                  variantOutline={true}
                  onClick={() => cancelSectionList(id)}
                  label="Cancel"
                ></Buttons>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
