import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Thumbs from "../custom/media/Image";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import "./FormDropZone.scss";
import { fileUpload } from "../../services/fileUploadService";
import { DOCUMENT_UPLOAD_MAX_NUM, DOCUMENT_UPLOAD_MAX_SIZE } from "../../constant/constant";
import File from "../custom/media/File";
import commonImages from "../../assets";
import ImageTable from "../custom/media/TableView";

const FormDropZone = ({
  key,
  id,
  label,
  paraText1 = "Drop the files here ...",
  paraText2 = "Drop Files here, ",
  paraText3 = "or Browse",
  handleFileChange,
  isImage = true,
  allFiles = false,
  allImages = false,
  imageUrl = [],
  deleteImageURL,
  isMuliple = true,
  acceptedFileType = { "image/*": [".png", ".jpeg", ".jpg"] },
  isDoc = false,
  isMultipleIcon = false,
  filess,
  iscancel = false,
  isTableView = false,
}) => {
  let acceptedFileTypes = acceptedFileType;
  let maxFiles = isMuliple ? DOCUMENT_UPLOAD_MAX_NUM : 1;
  if (allImages) {
    acceptedFileTypes = ["image/jpeg", "image/png", "image/gif"];
  }
  if (isDoc) {
    acceptedFileTypes = {
      "image/*": [".pdf", ".doc", "docx", ".ppt", ".pptx"],
    };
  }
  if (allFiles) {
    acceptedFileTypes = {
      "image/*": [
        ".zip",
        ".rar",
        ".ppt",
        ".pptx",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/docx",
        "application/pdf",
        "text/plain",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    };
  }
  const [files, setFiles] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [isMaxFile, setIsMaxFile] = useState(false);
  const [isMaxFiless, setIsMaxFiless] = useState([]);
  const [formDataState, setFormDataState] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [largeFileError, setLargeFileError] = useState(null);
  const handeleUpload = async (filesData) => {
    setFormDataState(filesData);
  };

  useEffect(() => {
    if (formDataState) {
      setIsMaxFile(false);
      const fileSizes = isMaxFiless?.length + formDataState?.length;
      if (fileSizes > maxFiles) {
        setIsMaxFile(true);
        return;
      }
      formDataState.forEach((singleFile) => {
        setIsLoader(true);
        fileUpload(singleFile)
          .then((res) => {
            setIsLoader(false);
            const uploadedfiles = [...files, ...res];
            setFiles(uploadedfiles);
          })
          .catch((error) => {
            setFileError("Error while uploading files.");
          });
      });
    }
  }, [formDataState]);

  useEffect(() => {
    if (iscancel) {
      setFiles([]);
      setIsMaxFiless([]);
      setFormDataState(null);
    }
  }, [iscancel]);

  useEffect(() => {
    if (files) {
      const ddd = [...isMaxFiless, ...files];
      setIsMaxFiless(ddd);
    }
  }, [files]);

  useEffect(() => {
    if (filess?.length > 0) {
      setIsMaxFiless(filess);
    }
  }, [filess]);

  useEffect(() => {
    if (isMaxFiless.length > 0) {
      let filesPath = "";
      const fileData = isMaxFiless && isMaxFiless.map((x) => x.fileUrl);
      const fileDataId = isMaxFiless && isMaxFiless.map((x) => x.id);
      filesPath = fileData && fileData.join("|");
      handleFileChange(filesPath, fileDataId, setFileError(null));
    }
  }, [isMaxFiless]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setIsMaxFile(false);
    setIsFileTooLarge(false);
    setFileError(null);

    const isImage = acceptedFiles.every((file) =>
      file.type.startsWith("image/")
    );

    if (!isImage && allImages) {
      setFileError("Supported image files only.");
      return;
    }

    if (rejectedFiles.length > 0) {
      const rejectedFile = rejectedFiles[0];
      const maxSizeBytes = DOCUMENT_UPLOAD_MAX_SIZE;
      const fileSizeBytes = rejectedFile.file.size;

      if (fileSizeBytes > maxSizeBytes) {
        setIsFileTooLarge(true);
        setLargeFileError("The uploaded files are larger than 50 MB in size.");
        return;
      }
    }

    if (rejectedFiles?.length > maxFiles) {
      setIsMaxFile(true);
      return;
    }
    if (isMaxFiless && isMaxFiless.length <= 2) {
      let FileSize = 0;
      let totalFileSize = acceptedFiles.reduce(
        (accumVariable, curValue) => accumVariable + curValue.size,
        FileSize
      );
      if (totalFileSize >= DOCUMENT_UPLOAD_MAX_SIZE) {
        setIsFileTooLarge(true);
        setLargeFileError("The uploaded files are larger than 50 MB in size.");
        return;
      } else {
        handeleUpload(acceptedFiles);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    maxFiles: maxFiles,
    maxSize: DOCUMENT_UPLOAD_MAX_SIZE,
    multiple: isMuliple,
  });

  const handleCloseFile = (img) => {
    deleteImageURL();
    const newFiles = files.filter((file) => file.id !== img.id);
    setFiles(newFiles);
  };
  const deleteImageURLs = (url, index) => {
    const newFiles = files.filter((file) => file.fileUrl !== url);
    setFiles(newFiles);

    setIsMaxFiless(isMaxFiless.filter((ites, index) => ites.fileUrl !== url));
    deleteImageURL(url, index);
  };

  return (
    <div className="overideFormDropBox" key={key} id={id}>
      <div className="dropBoxTitle">{label}</div>
      <div className="dropbox" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>
            <p className="dropText">{paraText1}</p>
          </div>
        ) : (
          <>
            {isMultipleIcon ? (
              <div className="multipleIconContainer">
                <div className="multipleIconImg">
                  <img src={commonImages?.multipleMedia} alt="icon" />
                </div>
                <div>Add Media</div>
              </div>
            ) : (
              <div>
                <CloudUploadOutlinedIcon className="clousIcon" />
                <p className="browseText">
                  {paraText2} <span>{paraText3}</span>
                </p>
                <p className="browse-file-message">
                  You can upload maximum 50 MB files.
                </p>
              </div>
            )}
          </>
        )}
      </div>
      {isFileTooLarge && largeFileError && (
        <div className="dropBoxError">{largeFileError}</div>
      )}
      {isMaxFile && (
        <div className="dropBoxError">
          {isMuliple
            ? "You can upload maximum 50 MB files."
            : "You can upload only 1 file."}
        </div>
      )}
      {fileError && !isFileTooLarge && (
        <div className="newErrorMsg">{fileError}</div>
      )}
      <div className="imageBody">
        {isImage ? (
          <>
            {isTableView ? (
              <ImageTable
                handleCloseFile={handleCloseFile}
                files={files}
                imageUrl={imageUrl}
                deleteImageURL={(url, index) => deleteImageURLs(url, index)}
                isLoader={isLoader}
              />
            ) : (
              <Thumbs
                handleCloseFile={handleCloseFile}
                files={files}
                imageUrl={imageUrl}
                deleteImageURL={(url, index) => deleteImageURLs(url, index)}
                isLoader={isLoader}
              />
            )}
          </>
        ) : (
          <File
            handleCloseFile={handleCloseFile}
            files={files}
            imageUrl={imageUrl}
            deleteImageURL={(url, index) => deleteImageURLs(url, index)}
            isLoader={isLoader}
          />
        )}
      </div>
    </div>
  );
};

export default FormDropZone;
