import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import parse from "html-react-parser";

const MultipleEmployee = ({ additionalData }) => {
  return (
    <div className="commonOveride">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">Employee details:</Typography>
            <div className="additionalListBox">
              {additionalData?.map((item, i) => {
                return (
                  <>
                    <div className="additionalList" key={i}>
                      <div className="subtitle">Employee {i + 1}</div>
                      <Box className="columns">
                        <Typography className="listView">
                          Employee's Name:
                          <span className="font-w400">
                            {item?.employeeName}
                          </span>
                        </Typography>
                        <Typography className="listView">
                          Supervisor:
                          <span className="font-w400">
                            {item?.supervisorName}
                          </span>
                        </Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView">
                          Job Title:
                          <span className="font-w400">{item?.jobTitle}</span>
                        </Typography>
                        <Typography className="listView">
                          Start Date:
                          <span className="font-w400">{item?.startDate}</span>
                        </Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView">
                          Jobsite:
                          <span className="font-w400">{item?.jobSite}</span>
                        </Typography>
                        <Typography className="listView">
                          Contributing Factor(s):
                          <span className="font-w400">
                            {item?.contributingFactorsKeyValue
                              .map((i) => i.value)
                              .join(", ")}
                          </span>
                        </Typography>
                      </Box>

                      <Typography className="listView">
                        Description of Incident:
                        <span className="font-w400 blockView overRideTextEditor">
                          {parse(unescape(item?.description))}
                        </span>
                      </Typography>

                      <Typography className="listView columns">
                        <div className="columnsf">
                          Was the Equipment or Tool Inspected Before Use
                        </div>

                        {item?.isToolInspected === true ? (
                          <div className="columnsl yesbtn">Yes</div>
                        ) : item?.isToolInspected === false ? (
                          <div className="columnsl nobtn">No</div>
                        ) : (
                          ""
                        )}
                      </Typography>
                      <Typography className="listView columns">
                        <div className="columnsf">
                          Was a JHA Completed Prior to the Commencement of Work
                          Activities?
                        </div>
                        {item.isJhaCompleted === true ? (
                          <div className="columnsl yesbtn">Yes</div>
                        ) : item.isJhaCompleted === false ? (
                          <div className="columnsl nobtn">No</div>
                        ) : (
                          ""
                        )}
                      </Typography>
                      <Typography className="listView columns">
                        <div className="columnsf">
                          Were all Associated Hazards Identified in the JHA?
                        </div>

                        {item?.isHazardsIdentified === true ? (
                          <div className="columnsl yesbtn"> Yes</div>
                        ) : item?.isHazardsIdentified === false ? (
                          <div className="columnsl nobtn">No</div>
                        ) : (
                          ""
                        )}
                      </Typography>
                      <Typography className="listView columns">
                        <div className="columnsf">
                          Were Employees Trained to Recognize the Unsafe
                          Conditions?
                        </div>
                        {item?.isEmployeesTrained === true ? (
                          <div className="columnsl yesbtn">Yes</div>
                        ) : item?.isEmployeesTrained === false ? (
                          <div className="columnsl nobtn">No</div>
                        ) : (
                          ""
                        )}
                      </Typography>
                      <Typography className="listView desNone">
                        How can Similar Cases be Avoided in the Future?
                        <span className="font-w400 blockView overRideTextEditor">
                          {parse(unescape(item?.howSimilarCaseAvoided))}
                        </span>
                      </Typography>
                    </div>
                  </>
                );
              })}
            </div>
          </Box>
          <Box className="commoninfoRight"></Box>
        </Box>
      </Box>
    </div>
  );
};

export default MultipleEmployee;
