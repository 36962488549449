import React from "react";
import KudosObservationReport from "../observation/view-observation-report/kudosObservationReport";
import ReprimandReportView from "../observation/view-observation-report/reprimandReportView";
import ViolationObservationReport from "../observation/view-observation-report/violationObservationReport";
import NearMissesActionReport from "./actionReports/nearMissesActionReport";
import PersonalInjuryActionReport from "./actionReports/personalInjuryActionReport";
import StolenPropertyActionReport from "./actionReports/stolenPropertyActionReport";
import UtilityDamageActionReport from "./actionReports/utilityDamageActionReport";
import VehicleAccidentActionReport from "./actionReports/vehicleAccidentActionReport";

const ViewOpenItemReport = ({
  actionItemTypeId,
  stolenPropertyData,
  nearMissData,
  personalReportData,
  vehicleAccidentData,
  utilityDamageData,
  reprimandId,
  reprimandDetails,
  observationReportData,
  InspectionOpenItemDetail,
  observationType,
  actionReportId,
  ReprimandViolationCount,
  pageTitle,
  closeAction,
  actionStatus,
  observationStatusLog,
  closeViolation,
  observationID,
  origin,
  closeInspectionCorrective,
  closeInspectionAction,
  closeInspectionReprimand,
  inspectionViolationCounts,
  addedById,
  closeReprimandReprimand,
  inspectionId,
  getCount,
  observationInvolvedPartyID,
  actionIncidentId,
  actionCaseId,
  involvedPartyName,
  involvedPartyId,
  backRouteText,
  backRoute,
  addedBy
}) => {
  return (
    <>
      {origin === "Inspection" ||
      origin === "Inspections" ||
      origin === "Observations" ||
      origin === "Reprimand" ? (
        <>
          {observationType === "Kudos" && (
            <KudosObservationReport
              observationReportData={observationReportData}
              InspectionOpenItemDetail={InspectionOpenItemDetail}
              pageTitle={pageTitle}
              origin={origin}
              observationStatusLog={observationStatusLog}
              involvedPartyName={involvedPartyName}
              involvedPartyId={involvedPartyId}
              backRoute={backRoute}
              backRouteText={backRouteText}
              addedBy={addedBy}
            />
          )}
          {observationType === "Corrective" && (
            <ViolationObservationReport
              addedById={addedById}
              observationReportData={observationReportData}
              ReprimandViolationCount={ReprimandViolationCount}
              pageTitle={pageTitle}
              observationStatusLog={observationStatusLog}
              closeViolation={closeViolation}
              observationID={observationID}
              origin={origin}
              InspectionOpenItemDetail={InspectionOpenItemDetail}
              closeInspectionCorrective={closeInspectionCorrective}
              inspectionViolationCounts={inspectionViolationCounts}
              getCount={getCount}
              observationInvolvedPartyID={observationInvolvedPartyID}
              involvedPartyName={involvedPartyName}
              involvedPartyId={involvedPartyId}
              backRoute={backRoute}
              backRouteText={backRouteText}
              addedBy={addedBy}
            />
          )}
          {observationType === "Reprimand" && (
            <ReprimandReportView
              addedById={addedById}
              reprimandDetails={reprimandDetails}
              ReprimandViolationCount={ReprimandViolationCount}
              InspectionOpenItemDetail={InspectionOpenItemDetail}
              origin={origin}
              observationStatusLog={observationStatusLog}
              pageTitle={pageTitle}
              closeInspectionReprimand={closeInspectionReprimand}
              reprimandId={reprimandId}
              closeReprimandReprimand={closeReprimandReprimand}
              inspectionId={inspectionId}
              getCount={getCount}
              involvedPartyName={involvedPartyName}
              involvedPartyId={involvedPartyId}
            />
          )}
          {observationType === "Action" && (
            <PersonalInjuryActionReport
              addedById={addedById}
              personalReportData={personalReportData}
              actionReportId={actionReportId}
              closeAction={closeAction}
              actionStatus={actionStatus}
              InspectionOpenItemDetail={InspectionOpenItemDetail}
              origin={origin}
              closeInspectionAction={closeInspectionAction}
              actionIncidentId={actionIncidentId}
              actionCaseId={actionCaseId}
              actionItemTypeId={actionItemTypeId}
              involvedPartyName={involvedPartyName}
              involvedPartyId={involvedPartyId}
            />
          )}
        </>
      ) : (
        <>
          {actionItemTypeId === 1 && observationType === "Action" && (
            <PersonalInjuryActionReport
              addedById={addedById}
              personalReportData={personalReportData}
              actionReportId={actionReportId}
              closeAction={closeAction}
              actionStatus={actionStatus}
              InspectionOpenItemDetail={InspectionOpenItemDetail}
              origin={origin}
              closeInspectionAction={closeInspectionAction}
              actionIncidentId={actionIncidentId}
              actionCaseId={actionCaseId}
              actionItemTypeId={actionItemTypeId}
              involvedPartyName={involvedPartyName}
              involvedPartyId={involvedPartyId}
            />
          )}
          {actionItemTypeId === 2 && observationType === "Action" && (
            <VehicleAccidentActionReport
              vehicleAccidentData={vehicleAccidentData}
              actionReportId={actionReportId}
              closeAction={closeAction}
              actionStatus={actionStatus}
              InspectionOpenItemDetail={InspectionOpenItemDetail}
              origin={origin}
              actionIncidentId={actionIncidentId}
              actionCaseId={actionCaseId}
              actionItemTypeId={actionItemTypeId}
              involvedPartyName={involvedPartyName}
              involvedPartyId={involvedPartyId}
            />
          )}
          {actionItemTypeId === 3 && observationType === "Action" && (
            <StolenPropertyActionReport
              stolenPropertyData={stolenPropertyData}
              actionReportId={actionReportId}
              closeAction={closeAction}
              actionStatus={actionStatus}
              InspectionOpenItemDetail={InspectionOpenItemDetail}
              actionIncidentId={actionIncidentId}
              actionCaseId={actionCaseId}
              actionItemTypeId={actionItemTypeId}
              involvedPartyName={involvedPartyName}
              involvedPartyId={involvedPartyId}
            />
          )}
          {actionItemTypeId === 5 && observationType === "Action" && (
            <NearMissesActionReport
              nearMissData={nearMissData}
              actionReportId={actionReportId}
              closeAction={closeAction}
              actionStatus={actionStatus}
              InspectionOpenItemDetail={InspectionOpenItemDetail}
              origin={origin}
              actionIncidentId={actionIncidentId}
              actionCaseId={actionCaseId}
              actionItemTypeId={actionItemTypeId}
              involvedPartyName={involvedPartyName}
              involvedPartyId={involvedPartyId}
            />
          )}
          {actionItemTypeId === 4 && observationType === "Action" && (
            <UtilityDamageActionReport
              utilityDamageData={utilityDamageData}
              actionReportId={actionReportId}
              closeAction={closeAction}
              actionStatus={actionStatus}
              InspectionOpenItemDetail={InspectionOpenItemDetail}
              origin={origin}
              actionIncidentId={actionIncidentId}
              actionCaseId={actionCaseId}
              actionItemTypeId={actionItemTypeId}
              involvedPartyName={involvedPartyName}
              involvedPartyId={involvedPartyId}
            />
          )}
        </>
      )}
    </>
  );
};

export default ViewOpenItemReport;
