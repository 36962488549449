import { getApiUrl } from "../config";
import { get, post } from "./axiosRequest";
import { ORG_ID, LNG_ID, PAGE_SIZE } from "../constant/constant";

export const postReprimandService = async ({ payload }) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/AddReprimands`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const updateReprimandService = async ({ payload }) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/updatereprimands`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getReprimandListService = async ({ payload }) => {
  const { page } = payload;

  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetReprimandsList?organisationId=${ORG_ID()}&page=${page}&pageSize=${PAGE_SIZE}&lngId=${LNG_ID}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getReprimandDetailsService = async ({ payload }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetReprimandDetail?reprimandsId=${payload}&lngId=${LNG_ID}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
