import React, { useEffect, useState } from "react";
import "./addCourses.scss";
import { FormControl, Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../../library/custom/textArea/TextArea";
import Button from "../../../../library/custom/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { formValidation } from "../../../../utils/incpectionHelper";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiKeySelectBox";
import {
  LNG_ID,
  ORG_ID,
  USER_ID,
  DROP_DOWN_FIELD_ERROR_MSG,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import Checkbox from "../../../../library/checkbox/checkbox";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import FormDropZone from "../../../../library/dropZone/FormDropZone";

const AddCourses = ({
  categoryListData,
  lessionListData,
  onSubmit,
  editCourseData,
  isSuccess,
}) => {
  // const { isSuccess, editCourseData } = useSelector(
  //   (state) => state.updateCourse
  // );
  const location = useLocation();
  const { pathname } = location;
  const edit = pathname.split("/")[2] ?? null;
  const [addPhoto, setAddPhoto] = useState("");
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [checkBox, setcheckBox] = useState(
    edit && editCourseData ? null : false
  );
  const [formValue, setFormValue] = useState({
    organisationId: ORG_ID(),
    lngId: LNG_ID,
    categoryId: "",
    completionTimeHr: "0",
    completionTimeMin: "",
    title: "",
    description: "",
    lessonIds: [],
    imageUrl: "",
    fileIds: "",
  });
  const isShowClient =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && checkPrivileges([12]);
  const dispatch = useDispatch();
  const [lessonIdError, setLessonIdError] = useState(false);
  function findIdMultipleSelect(arrrayofObject, selectedArray) {
    let valueArray = [];
    arrrayofObject.forEach((data) => {
      if (selectedArray.indexOf(data.key) >= 0) {
        valueArray.push(data.key);
      }
    });
    return valueArray;
  }
  const formHandler = (event, action) => {
    const iscategryAvilable = categoryListData?.filter((val) => {
      return val?.key === formValue?.categoryId;
    });

    if (iscategryAvilable?.length === 0) {
      setFormValue({
        ...formValue,
        categoryId: "",
      });
    }

    let resArrayKey = [];
    if (formValue?.lessonIds) {
      formValue?.lessonIds.forEach((data) => {
        resArrayKey.push(data?.key);
      });
    }

    let lessonids = findIdMultipleSelect(lessionListData, resArrayKey);

    const formData = {
      ...(edit && editCourseData && { courseId: editCourseData?.id ?? "" }),
      organisationId: ORG_ID(),
      lngId: LNG_ID,
      categoryId: iscategryAvilable?.length > 0 ? formValue?.categoryId : null,
      //categoryId: formValue?.categoryId ?? null,
      completionTimeHr: formValue?.completionTimeHr ?? null,
      completionTimeMin: formValue?.completionTimeMin ?? null,
      title: formValue?.title ?? null,
      description: formValue?.description ?? null,
      lessonIds: lessonids ?? null,
      fileIds: images ?? [],
      createdBy: USER_ID(),
      isGlobal: checkBox,
      // isGlobal: false,
    };

    const { errorKey } = formValidation(
      {
        title: formValue.title,
        categoryId: formValue.categoryId,
        completionTimeMin: formValue.completionTimeMin,
      },
      action
    );
    setErrors(errorKey);
    let isError = false;
    if (!formValue.title) {
      isError = true;
    }
    if (!formValue.categoryId) {
      isError = true;
    }
    if (!formValue.completionTimeMin) {
      isError = true;
    }
    if (lessonids?.length == 0) {
      isError = true;
      setLessonIdError(true);
    }
    if (!isError) {
      setIsSubmit(true);

      onSubmit(formData);
    } else {
      setIsSubmit(false);
    }
  };

  const addlesns = (event) => {
    setLessonIdError(false);
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.key === item.key) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.key !== item.key);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setFormValue({
      ...formValue,
      lessonIds: duplicateRemoved,
    });
  };

  const handleMinsonChange = (e, value) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      const minval = e.target.value.replace(/^0+/, "");
      setFormValue({
        ...formValue,

        completionTimeMin: minval,
      });
    }
  };

  const handleTitleChange = (event, value) => {
    let fName = event.target.value;
    let fNameWhiteSpace = fName.replace(/\s+/g, " ");
    setFormValue({
      ...formValue,

      title: fNameWhiteSpace,
    });
  };

  const handleDescChange = (event, value) => {
    let Desc = event.target.value;
    setFormValue({
      ...formValue,

      description: Desc,
    });
  };

  const clearFormValue = React.useCallback(
    (editForm) => {
      console.log("editForm?.categoryId", editForm);
      let lessonKeValueArr = [];
      if (editForm?.lessons) {
        editForm.lessons.forEach((item) => {
          let listObj = {};
          listObj.key = item.lessonId;
          listObj.value = item.lesson;
          lessonKeValueArr.push(listObj);
        });
      }
      let coverKeId = [];
      let coverImagePath;
      if (editForm?.file) {
        let coverImageId = editForm?.file?.id;
        coverImagePath = editForm?.file?.path;

        setAddPhoto(coverImagePath);
        coverKeId = [coverImageId];
      }
      setcheckBox(edit && editForm && editForm?.isGlobal);
      setFormValue({
        categoryId: (edit && editForm?.categoryId) ?? "",
        completionTimeHr: (edit && editForm?.completionTimeHr) ?? "0",
        completionTimeMin: (edit && editForm?.completionTimeMin) ?? "",
        description: (edit && editForm?.description) ?? "",
        title: (edit && editForm?.course) ?? "",
        organisationId: (edit && editForm?.organisationId) ?? "",
        lngId: (edit && editForm?.lngId) ?? "",
        lessonIds: lessonKeValueArr.length > 0 ? lessonKeValueArr : [],
        fileIds: (edit && coverKeId) ?? "",
        //isGlobal: (edit && editForm?.isGlobal) ?? "",
      });
      setImages(coverKeId);
    },
    [edit, editCourseData]
  );
  useEffect(() => {
    setFormValue({
      ...formValue,
      fileIds: images,
    });
  }, [images]);

  useEffect(() => {
    if (isSuccess) {
      clearFormValue();
    }
  }, [isSuccess, clearFormValue]);

  useEffect(() => {
    if (edit) {
      let result =
        editCourseData && editCourseData?.lessons?.map(({ lesson }) => lesson);
      editCourseData["lessonsIds"] = result;

      clearFormValue(editCourseData);
    }
  }, [edit, clearFormValue, editCourseData]);

  useEffect(() => {
    if (checkBox) {
      dispatch(lmsAction.getCategoryList({ isGlobal: true }));

      dispatch(
        lmsAction.getLessonDropDownList({
          pageNumber: 1,
          lngId: LNG_ID,
          organisationId: ORG_ID(),
          isGlobal: true,
        })
      );
    } else if (checkBox === false) {
      dispatch(lmsAction.getCategoryList({ isGlobal: false }));

      dispatch(
        lmsAction.getLessonDropDownList({
          pageNumber: 1,
          lngId: LNG_ID,
          organisationId: ORG_ID(),
          isGlobal: false,
        })
      );
    }
  }, [checkBox, dispatch]);

  const handleDeleteImageURL = (urls) => {
    setImages([]);
    setAddPhoto("");
  };

  useEffect(() => {
    setAddPhoto("");
  }, [edit]);

  return (
    <>
      <div className="overideCourses">
        <div className="formContainer">
          {edit ? (
            <div className="heading_style">
              <h1>Update Course</h1>
            </div>
          ) : (
            <div className="heading_style">
              <h1>Create Course</h1>
            </div>
          )}

          <div className="addcourseBoxContainer">
            <div className="addcourseBox">
              <Grid>
                {isShowClient && (
                  <Grid container>
                    <Grid item lg={6} xs={12} sm={12} md={4} mt={2}>
                      <div className="typeTxt">
                        <Checkbox
                          checked={checkBox}
                          onChange={() => {
                            setcheckBox(!checkBox);
                            setFormValue({
                              ...formValue,
                              categoryId: "",
                              lessonIds: [],
                            });
                          }}
                          label={"Is Global"}
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
                <Grid item container mt={4} lg={12} spacing={1}>
                  <Grid item lg={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabels label="Category" isRequired={true} />
                      <SelectMenu
                        listData={categoryListData}
                        value={formValue?.categoryId}
                        isRequired={true}
                        placeholder="Select Category"
                        onchangehandler={(e) =>
                          setFormValue({
                            ...formValue,
                            categoryId: e.target.value,
                          })
                        }
                        isError={!!errors?.categoryId ?? false}
                        errorMsg={errors?.categoryId ?? ""}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabels
                        label={"Completion Time Minute"}
                        isRequired={true}
                      />

                      <InputField
                        fullWidth={true}
                        disabled={false}
                        type="text"
                        placeholder="Enter in Minutes"
                        value={formValue.completionTimeMin}
                        onChange={(event, value) =>
                          handleMinsonChange(event, value)
                        }
                        class="noscroll"
                        isError={!!errors?.completionTimeMin ?? false}
                        errorMsg={errors?.completionTimeMin ?? ""}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container mt={1.5} lg={12} spacing={2}></Grid>
              <Grid container>
                <Grid mt={3} spacing={2} lg={12} xs={12}>
                  <FormDropZone
                    label={"Add Cover Photo"}
                    paraText1={"Drop the file here ..."}
                    paraText2={"Drop Image here, "}
                    paraText3={"or Browse"}
                    handleFileChange={(files, uploadfile) => {
                      setAddPhoto(files);
                      setImages(uploadfile);
                    }}
                    deleteImageURL={(url) => handleDeleteImageURL(url)}
                    isDynamics={true}
                    isRequired={true}
                    fileType={10}
                    isMuliple={false}
                    imageUrl={addPhoto}
                    id="addPhoto"
                    key="addPhoto"
                    isDoc={false}
                    isImage={true}
                    allFiles={false}
                  />
                </Grid>
              </Grid>

              <Grid mt={1.5} spacing={2} lg={12}>
                <FormControl fullWidth>
                  <InputField
                    isRequired={true}
                    fullWidth={true}
                    disabled={false}
                    placeholder="Enter title text here..."
                    type="text"
                    label="Title"
                    value={formValue.title}
                    onChange={(event, value) => handleTitleChange(event, value)}
                    isError={!!errors?.title ?? false}
                    errorMsg={errors?.title ?? ""}
                  />
                </FormControl>
              </Grid>

              <Grid mt={3} spacing={2} lg={12}>
                <FormLabels label={"Description"} />

                <TextArea
                  name={"issuefound"}
                  placeholder="Add course description text here..."
                  value={formValue.description}
                  onChange={(event, value) => handleDescChange(event, value)}
                />
              </Grid>

              <Grid item lg={4} xs={12} sm={12} md={4} mt={1}>
                <FormControl fullWidth>
                  <FormLabels label="Add Lessons" isRequired={true} />

                  <MultiSelectBox
                    listData={lessionListData}
                    onChange={addlesns}
                    value={formValue.lessonIds || []}
                    isError={lessonIdError}
                    errorMessage={
                      lessonIdError && formValue.lessonIds?.length == 0
                        ? DROP_DOWN_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item lg={4} xs={12} sm={12} md={4}></Grid>

              <Grid item mb={3} container mt={7} className="courseBtn">
                <Grid container textAlign="right" className="addcourseButton">
                  <Grid item lg={3} textAlign="center">
                    <Button
                      varientContained={true}
                      disabled={isSubmit ? true : false}
                      label={
                        edit === "edit" ? "Update Course" : "Create Course"
                      }
                      onClick={(e) =>
                        formHandler(e, edit === "edit" ? "update" : "submit")
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddCourses;
