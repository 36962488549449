import { MODULES_NAME } from "../constant/constant";
import { PRODUCT_ID } from "../constant/storm-water/constant";

export const isUserAllowed = (privilegeId) => {
  const allPrivileges = JSON.parse(localStorage.getItem("privileges"));
  const isAllow = allPrivileges?.includes(privilegeId);
  return isAllow;
};

export const usersAccess = (moduleName) => {
  if (moduleName === MODULES_NAME.incident) {
    return {
      isFullAccess: isUserAllowed(1),
      isWriteAccess: isUserAllowed(2),
      isReadAccess: isUserAllowed(3),
    };
  } else if (moduleName === MODULES_NAME.inspection) {
    return {
      isFullAccess: isUserAllowed(4),
      isWriteAccess: isUserAllowed(5),
      isReadAccess: isUserAllowed(6),
    };
  } else if (moduleName === MODULES_NAME.support) {
    return {
      isAdmin: isUserAllowed(7),
      isResolution: isUserAllowed(8),
    };
  } else if (moduleName === MODULES_NAME.lms) {
    return {
      isFullAccess: isUserAllowed(11),
      isWriteAccess: isUserAllowed(10),
      isReadAccess: isUserAllowed(9),
    };
  } else if (moduleName === MODULES_NAME.admin) {
    return {
      isFullAppAccess: isUserAllowed(12),
      isLimitedInspectorAccess: isUserAllowed(16),
    };
  }
};

export const checkPrivileges = (privilegeIds) => {
  const allPrivileges = JSON.parse(localStorage.getItem("privileges"));
  let isAllow = false;
  privilegeIds?.every((id) => {
    if (allPrivileges?.includes(id)) {
      isAllow = true;
      return false;
    }
    return true;
  });
  return isAllow;
};

export const showRighSideMenu = () => {
  const serviceType = PRODUCT_ID();
  return {
    isSW: parseInt(serviceType) === 2,
    isSafety: parseInt(serviceType) === 1,
  };
};
