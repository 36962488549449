import React, { useEffect, useState, useRef } from "react";
import ViewInspectionProof from "../components/viewInspectionProof";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../../../library/common/Loader";
import { Grid } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { LOGIN_ORG_ID, ORG_ID } from "../../../../constant/constant";

const ViewInspectionProofContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const inspectionDetail = location?.state?.inspectionDetails;
  const getSwInspectionDetail = useSelector(
    (state) => state.getExternalInspection
  );
  const getSwInspectionDetailData = (data) => {
    dispatch(swInspectionAction.getExternalInspection(data));
  };
  const showLoader = useSelector(
    (state) => state.getExternalInspection?.isLoading
  );

  useEffect(() => {
    if (inspectionDetail?.reportId) {
      const data = {
        inspectionId: inspectionDetail?.reportId,
      };
      getSwInspectionDetailData(data);
    }
  }, [inspectionDetail]);

  const [pdfloader, setPdfLoader] = useState(false);
  const dateOfInspection = getSwInspectionDetail?.data?.dateOfInspection;
  const inspectedProjectName = getSwInspectionDetail?.data?.projectName;

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${dateOfInspection} ${inspectedProjectName}`,
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
    onBeforeGetContent: () => setPdfLoader(true),
    onAfterPrint: () => setPdfLoader(false),
    onPrintError: () => setPdfLoader(false),
  });
  const isClient = ORG_ID() !== LOGIN_ORG_ID();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clientLogo = localStorage.getItem("orgLogo");
  const clientName = localStorage.getItem("orgName");
  const logoCheck = isClient ? clientLogo : userInfo?.logo;

  return (
    <>
      {showLoader && <Loader />}
      {pdfloader && <Loader />}

      <Grid ref={componentRef} className="InspectionContainer">
        <Grid container>
          <Grid item md={12}>
            <div className="title repotPdfHeading">View Submission Proof</div>
          </Grid>
          <Grid item md={12} className="clientPdfDetail">
            <Grid className="clientName">
              {isClient ? clientName : userInfo?.clientName}
            </Grid>
            <Grid className="clientLogo">
              {logoCheck && <img src={logoCheck} alt="client Log" />}
            </Grid>
          </Grid>
        </Grid>

        <ViewInspectionProof
          handlePrint={handlePrint}
          inspectionDetail={showLoader ? [] : getSwInspectionDetail?.data}
          inspectionRowDetail={inspectionDetail}
        />
      </Grid>
    </>
  );
};

export default ViewInspectionProofContainer;
