import React, { useEffect } from "react";
import ConductJha from "../component/conductJha";
import { useDispatch } from "react-redux";
import * as uploadFilesAction from "../../../redux/actions/uploadFilesAction";
import { useSelector } from "react-redux";
import { useState } from "react";
import { CONDUCT_JHA_LIST } from "../../../constant/routeContant";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { API_STATUS, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import * as assetsAction from "../../../redux/actions/assetsAction";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../library/common/Loader";
import SuccessScreen from "../../../library/custom/success/successScreen";
import * as userAction from "../../../redux/actions/userAction";

function ConductJhaContainer() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const inpectorsignature = userInfo?.signatureUrl;

  const dispatch = useDispatch();
  const location = useLocation();
  const dueJHAData = location?.state?.data;
  const [successScreens, setSuccessScreens] = useState(false);

  const [isProfileSignature, setIsProfileSignature] = useState(false);
  const [isAttendeeSignature, setIsAttendeeSignature] = useState(false);
  const fileUploadData = useSelector((state) => state.fileUpload.data);
  const conductJhadata = useSelector((state) => state.addJhaConductData);
  const templateListData = useSelector(
    (state) => state.getJhaActiveTemplateList
  );
  const [assetType, setAssetType] = useState("");
  const [projectName, SetProjectName] = useState("");
  const [projectValue, setprojectValue] = useState("");
  const [asset, SetAsset] = useState("");
  const [assetValue, setAssetValue] = useState("");
  const [locations, setLocations] = useState("");
  const [adduser, setAdduser] = useState("");
  const [templete, setTemplete] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [selectedSignature, setSelectedSignature] = useState("");
  const [assetTypeError, setAssetTypeError] = useState(false);
  const [projectNameError, SetProjectNameError] = useState(false);
  const [assetError, SetAssetError] = useState(false);
  const [locationsError, setLocationsError] = useState(false);
  const [templeteError, setTempleteError] = useState(false);
  const [catogerysError, setCatogeriyesError] = useState(false);
  const [customAssetNameError, setCustomAssetNameError] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [userType, setUserType] = useState("0");
  const [attendeeSignature, setAttendeeSignature] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [photos, setPhotos] = useState("");
  const hiddenSignatureInput = useRef(null);
  const hiddenAttendeeSignatureInput = useRef([]);
  const autoComp = useRef(null);

  const getJhaTempleteDetsilsData = useSelector(
    (state) => state.getJhaTempleteDetsilsData
  );

  const assetOption = useSelector((state) => state.getAssetDynomoList?.data);
  const allGroups = useSelector((state) => state.searchAllGroup);
  const getUserGroupDetsilsData = useSelector(
    (state) => state.getUserGroupDetsilsData
  );

  function handleAllGroupSearch(serachText, divisionId, alldivision) {
    dispatch(userAction.searchAllGroup(serachText, divisionId, alldivision));
  }

  const [imageCheck, setImageCheck] = useState(false);

  const isLoading = useSelector(
    (state) =>
      state.getAssetList.isLoading || state.searchAllUserProjectWise.isLoading
  );
  const allUsers = useSelector((state) => state.allUsers);
  const allUsersProjectWise = useSelector(
    (state) => state.searchAllUserProjectWise
  );
  const isAutoSearchDataLoading = allUsers?.isLoading;
  const searchUsers = (
    searchText,
    searchType,
    divisionId,
    assetType,
    assetId,
    priviledgeId
  ) => {
    dispatch(
      userAction.searchAllUsers(
        searchText,
        searchType,
        divisionId,
        assetType,
        assetId,
        priviledgeId
      )
    );
  };
  const typeOnchange = (event, value, USER_TYPE) => {
    {
      searchUsers(
        event.target.value,
        USER_TYPE,
        0,
        assetType === 1 ? projectName : asset,
        assetType,
        0
      );
    }
  };

  const isProfileSignatureLoading = useSelector(
    (state) => state.fileUpload.isLoading
  );

  const showLoader = useSelector(
    (state) =>
      state.addJhaConductData.isLoading ||
      state.getJhaTempleteDetsilsData.isLoading ||
      state.getJhaTemplateList.isLoading ||
      state.getUserGroupDetsilsData.isLoading
  );

  const searchAsset = (searchText, type) => {
    dispatch(assetsAction.getAssetDynomoList({ searchText, type }));
  };

  const uploadSignatureFile = (data) => {
    setIsProfileSignature(true);
    dispatch(uploadFilesAction.createFileUpload(data));
  };
  const uploadAttendeeSignatureFile = (data) => {
    setIsAttendeeSignature(true);
    dispatch(uploadFilesAction.createFileUpload(data));
  };

  const conductJha = (data) => {
    setSuccessScreens(true);
    dispatch(jhaAction.addJhaConduct(data));
  };

  //get templete list

  const getjhaTempleteDetsils = (data) => {
    dispatch(jhaAction.getJhaTempeleteDetsils(data));
  };

  useEffect(() => {
    if (templete) {
      getjhaTempleteDetsils(templete);
    }
  }, [templete]);

  useEffect(() => {
    if (
      templateListData.status === API_STATUS.SUCCESS &&
      templateListData?.isLoading === false
    ) {
      // mapTempleteKeyValue();
      setTempleteList(templateListData?.data?.result);
    }
  }, [templateListData]);
  const [catogerys, setCatogeriyes] = useState([]);
  const mappeData = (data) => {
    return data.map((cat) => {
      return {
        key: cat.key,
        value: cat.value,
        catSelected: "",
        showActivity: false,
        activitiesTemplate: cat.activitiesTemplate.map((activities) => {
          return {
            key: activities.key,
            value: activities.value,
            activitiesSelect: "",
            hazards: activities.hazards,
            showSubActivity: false,
          };
        }),
      };
    });
  };

  useEffect(() => {
    if (getJhaTempleteDetsilsData?.data?.categories) {
      setCatogeriyes(mappeData(getJhaTempleteDetsilsData?.data?.categories));
    }
  }, [getJhaTempleteDetsilsData?.data?.categories]);

  useEffect(() => {
    setCatogeriyes([]);
  }, []);

  const [templeteList, setTempleteList] = useState([]);
  // function mapTempleteKeyValue() {
  //   let arr = [];
  //   templateListData?.data?.result?.itemList.map((i) =>
  //     arr.push({
  //       key: i.id,
  //       value: i.templateName,
  //     })
  //   );
  //   setTempleteList(arr);
  // }

  const dataBack = {
    title: "Back to Hazard Analysis",
    route: CONDUCT_JHA_LIST,
  };

  const onChnageCat = (selectedKey) => {
    setCatogeriyesError(false);
    const cloneAllCat = [...catogerys];
    cloneAllCat.forEach((cat) => {
      if (cat.key == selectedKey.key) {
        cat.catSelected = selectedKey.key;
        cat.showActivity = !cat.showActivity;
      }
    });
    setCatogeriyes(cloneAllCat);
  };
  const onChnageSubCat = (selectedKey, selectedSubKey) => {
    const cloneAllCat = [...catogerys];
    cloneAllCat.forEach((cat) => {
      if (cat.key == selectedKey.key) {
        cat.activitiesTemplate.forEach((activitie) => {
          if (activitie.key === selectedSubKey.key) {
            activitie.activitiesSelect = selectedSubKey.key;
            activitie.showSubActivity = !activitie.showSubActivity;
          }
        });
      }
    });
    setCatogeriyes(cloneAllCat);
  };
  useEffect(() => {
    if (dueJHAData) {
      setAssetType(dueJHAData?.assetType);
      setTemplete(dueJHAData?.jhaTemplateId);
      if (dueJHAData?.assetType === 4) {
        SetAsset(dueJHAData?.assetId);
        setAssetValue(dueJHAData?.assetName);

        // SetProjectName(dueJHAData?.assetId);
        // setprojectValue(dueJHAData?.assetName);
      }
      if (dueJHAData?.assetType === 1) {
        SetProjectName(dueJHAData?.assetId);
        setprojectValue(dueJHAData?.assetName);
      }
    }
  }, [dueJHAData]);

  const getTemplateListData = (data) => {
    dispatch(jhaAction.getJhaActiveTemplateList(data));
  };

  useEffect(() => {
    if (
      dueJHAData &&
      getJhaTempleteDetsilsData?.status === API_STATUS.SUCCESS
    ) {
      const defaultData = {
        assetType: dueJHAData?.assetType,
      };
      getTemplateListData(defaultData);
    }
  }, [getJhaTempleteDetsilsData]);

  const handleFileChange = (photos) => {
    setPhotos(photos);
  };

  const handleDeleteImageURL = (urls) => {
    const previousImg = photos.split("|");
    setPhotos(previousImg.filter((url) => url !== urls).join("|"));
  };

  //group
  const [groupUsersIds, setGroupUsersIds] = useState([]);
  const [userGroupList, setUsersGroupList] = useState([]);
  const [usersGroup, setUsersGroup] = useState("");
  const [groupUsers, setGroupUsers] = useState([]);

  const addGroupUserData = () => {
    const index = userList?.findIndex(
      (obect) => obect.groupId == usersGroup.key
    );
    if (index === -1 && usersGroup) {
      setUsersGroup("");
      setUsersGroupList([...userGroupList, usersGroup]);
      setGroupUsersIds([...groupUsersIds, usersGroup.key]);
      const groupsDatas = getUserGroupDetsilsData?.data?.result?.userIds.map(
        (user) => {
          return {
            key: user.key,
            value: user.value,
            gpName: getUserGroupDetsilsData?.data?.result?.value,
            userType: +userType,
            groupId: getUserGroupDetsilsData?.data?.result?.groupId,
          };
        }
      );
      setUsersList([...userList, ...groupsDatas]);
      const ele = autoComp.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const getUserGroupDetail = (groupId) => {
    dispatch(userAction.getUserGroupDetails(groupId));
  };

  const onChangeUsersGroup = (value) => {
    setUsersGroup(value);
  };

  useEffect(() => {
    if (usersGroup) {
      getUserGroupDetail(usersGroup?.key);
    }
  }, [usersGroup]);

  const deleteUsersGroup = (row) => {
    const updateList = userGroupList.filter((list) => list.key !== row);
    setUsersGroupList(updateList);
    setGroupUsersIds(updateList?.map((ids) => ids.key));
  };

  //user
  const [users, setUsers] = useState(1);
  const [userList, setUsersList] = useState([]);
  const [individualIds, setindividualIds] = useState([]);
  const autoCompUser = useRef(null);

  const addUserData = () => {
    if (!users.key) return;
    const index = userList.findIndex((object) => object.key === users.key);
    if (index === -1) {
      setindividualIds([...individualIds, users.key]);
      const stateObj = {
        ...users,
        imgUrl: "",
        imgId: "",
        userType: +userType,
      };
      setUsersList([...userList, stateObj]);
    }
    const ele = autoCompUser.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const deleteData = (id) => {
    const updateList = userList.filter((list) => list.key !== id);
    const updateAssignIds = individualIds.filter((list) => list !== id);
    setUsersList(updateList);
    setindividualIds(updateAssignIds);
    setUsersGroupList(updateList);
    setGroupUsersIds(updateAssignIds);
  };

  function handleUserType(e) {
    setUserType(e);
    // setUsersList([]);
  }

  const OnClickAssignAttendee = () => {
    setShowAssign(true);
  };

  const OnClickVerifyAll = () => {
    setShowVerify(true);
  };

  const [userId, setUserId] = useState("");

  const signatureUpload = (event) => {
    const fileUploaded = event.target.files[0];
    handleSignatureUploadMethod(fileUploaded);
  };
  const handleSignatureUploadMethod = (data) => {
    const formData = new FormData();
    formData.append("fileName", data);
    uploadSignatureFile(formData);
  };

  useEffect(() => {
    if (isProfileSignature) {
      if (fileUploadData && imageCheck) {
        setSelectedSignature(fileUploadData?.files[0]?.filePath);
      }
    } else {
      if (fileUploadData && imageCheck) {
        setSelectedProfile(fileUploadData?.files[0]?.filePath);
      }
    }
  }, [fileUploadData, imageCheck]);

  const attendeesignatureUpload = (event, keyId) => {
    console.log(keyId);
    setUserId(keyId);
    const fileUploaded = event.target.files[0];

    handleAttendeeSignatureUploadMethod(fileUploaded);
  };
  const handleAttendeeSignatureUploadMethod = (data) => {
    const formData = new FormData();
    formData.append("fileName", data);
    uploadAttendeeSignatureFile(formData);
  };

  useEffect(() => {
    if (isAttendeeSignature) {
      const cloneUser = [...userList];
      if (fileUploadData) {
        cloneUser.forEach((user) => {
          if (user.key === userId) {
            console.log(user.key);
            user.imgUrl = fileUploadData?.files[0]?.filePath;
          }
        });
      }
      setUsersList(cloneUser);
    }
  }, [fileUploadData]);

  const onChangeAssetType = (event) => {
    setAssetType(event.target.value);
    setTemplete("");
    setUsersList([]);
    setProjectNameValue("");
    setCustomAssetValue("");
    setUsersGroup("");
    setAdduser("");
    setUsers("");
    setUsersValue("");
    const defaultData = {
      assetType: event.target.value,
    };
    getTemplateListData(defaultData);
  };
  const [projectNameValue, setProjectNameValue] = useState("");
  const onSelectSearchProject = (event, value) => {
    setProjectNameValue({ key: value?.key ?? "", value: value?.value ?? "" });
    setprojectValue("");
    SetProjectName(value?.key);
    setUsersList([]);
  };
  const [customAssetValue, setCustomAssetValue] = useState("");

  const onSelectSearchAsset = (event, value) => {
    setCustomAssetValue({ key: value?.key ?? "", value: value?.value ?? "" });
    setAssetValue("");
    SetAsset(value?.key);
    setUsersList([]);
  };

  const [usersValue, setUsersValue] = useState("");
  const onSelectUsers = (value) => {
    setUsersValue({
      key: value?.key ?? "",
      value: value?.value ?? "",
    });
    setUsers(value);
  };

  const onChangeLocation = (event) => {
    setLocations(event.target.value);
  };

  const onChangeAdduser = (event) => {
    setAdduser(event.target.value);
  };

  const onChangeTemplete = (event) => {
    setTemplete(event.target.value);
  };

  function mappedData() {
    let errors = false;

    if (!assetType) {
      setAssetTypeError(true);
      errors = true;
    }

    if (!asset && assetType === 4) {
      setCustomAssetNameError(true);
      errors = true;
    } else {
      setCustomAssetNameError(false);
    }
    if (!projectName && assetType === 1) {
      SetProjectNameError(true);
      errors = true;
    } else {
      SetProjectNameError(false);
    }
    // if (locations.trim() === "") {
    //   setLocations("");
    //   setLocationsError(true);
    //   errors = true;
    // }
    if (!templete) {
      setTempleteError(true);
      errors = true;
    }
    const notCat = catogerys?.some((items) => items.showActivity);
    if (!notCat) {
      setCatogeriyesError(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const data = {
      assetTypeId: assetType,
      assetId: projectName ? projectName : asset,
      templateId: templete,
      location: locations,
      signatureUrl: inpectorsignature ? inpectorsignature : selectedSignature,
      createdBy: parseInt(USER_ID()),
      categories: catogerys,
      attendeesUsers: userList,
      proofOfAttandanceUrl: photos,
      conductId: dueJHAData?.jhaId ?? 0,
    };
    return data;
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      const newCategory = JSON.parse(JSON.stringify(data.categories));
      const sendData = newCategory?.map((x) => {
        x.categoryId = x.catSelected ? parseInt(x.catSelected) : 0;
        x.activities = x.activitiesTemplate
          .filter((y) => y.activitiesSelect)
          .map((z) => z.activitiesSelect);
        delete x.key;
        delete x.showActivity;
        delete x.catSelected;
        delete x.value;
        delete x.activitiesTemplate;
        return x;
      });
      const newUser = JSON.parse(JSON.stringify(data.attendeesUsers));
      const sendUser = newUser?.map((y) => {
        y.signatureUrl = y.imgUrl;
        y.userId = parseInt(y.key) ? parseInt(y.key) : parseInt(0);
        y.userName = y.value;
        delete y.gpName;
        delete y.imgId;
        delete y.imgUrl;
        delete y.projectIds;
        delete y.value;
        delete y.key;
        return y;
      });
      data.categories = sendData;
      data.attendeesUsers = sendUser;
      conductJha(data);
    }
  };
  const addAdditionalUser = () => {
    if (adduser) {
      var id = "id" + Math.random().toString(16).slice(2);
      setUsersList([
        ...userList,
        { key: id, value: adduser, userType: +userType },
      ]);
    }
    setAdduser("");
  };
  const removeImage = (userKey) => {
    const cloneUser = [...userList];
    cloneUser.forEach((user) => {
      if (user.key === userKey) {
        user.imgUrl = "";
      }
    });
    setUsersList(cloneUser);
  };

  useEffect(() => {
    setSuccessScreens(false);
  }, []);
  const successScreen = (id, message, message1) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={message}
        route={CONDUCT_JHA_LIST}
        label1="Conducted Hazard Analysis ID"
        label4="Conduct Hazard Analysis List in "
        label2={message1}
      ></SuccessScreen>
    );
  };

  return (
    <>
      {showLoader && <Loader />}
      {conductJhadata.status === "SUCCESS" &&
      conductJhadata.data &&
      successScreens ? (
        successScreen(
          conductJhadata?.data?.id,
          "Hazard Analysis Conducted Successfully"
        )
      ) : (
        <ConductJha
          uploadSignatureFile={uploadSignatureFile}
          isProfileSignature={isProfileSignature}
          fileUploadData={fileUploadData}
          isProfileSignatureLoading={isProfileSignatureLoading}
          dataBack={dataBack}
          conductJha={conductJha}
          getjhaTempleteDetsils={getjhaTempleteDetsils}
          templateList={templeteList}
          assetOption={assetOption}
          searchAsset={searchAsset}
          getJhaTempleteDetsilsData={catogerys}
          onChnageCat={onChnageCat}
          onChnageSubCat={onChnageSubCat}
          assetType={assetType}
          projectName={projectName}
          asset={asset}
          locations={locations}
          templete={templete}
          selectedSignature={selectedSignature}
          hiddenSignatureInput={hiddenSignatureInput}
          hiddenAttendeeSignatureInput={hiddenAttendeeSignatureInput}
          onChangeAssetType={onChangeAssetType}
          onSelectSearchProject={onSelectSearchProject}
          onSelectSearchAsset={onSelectSearchAsset}
          onChangeLocation={onChangeLocation}
          onChangeTemplete={onChangeTemplete}
          signatureUpload={signatureUpload}
          attendeesignatureUpload={attendeesignatureUpload}
          submitData={submitData}
          isLoading={isLoading}
          assetTypeError={assetTypeError}
          projectNameError={projectNameError}
          assetError={assetError}
          locationsError={locationsError}
          templeteError={templeteError}
          catogerysError={catogerysError}
          catogerys={catogerys}
          customAssetNameError={customAssetNameError}
          showAssign={showAssign}
          userType={userType}
          handleUserType={handleUserType}
          OnClickAssignAttendee={OnClickAssignAttendee}
          typeOnchange={typeOnchange}
          allUsers={allUsers && allUsers?.data}
          isAutoSearchDataLoading={isAutoSearchDataLoading}
          users={users}
          setUsers={setUsers}
          addUserData={addUserData}
          autoCompUser={autoCompUser}
          userList={userList}
          deleteData={deleteData}
          attendeeSignature={attendeeSignature}
          OnClickVerifyAll={OnClickVerifyAll}
          showVerify={showVerify}
          handleFileChange={handleFileChange}
          handleDeleteImageURL={handleDeleteImageURL}
          photos={photos}
          removeImage={removeImage}
          setImageCheck={setImageCheck}
          addGroupUserData={addGroupUserData}
          deleteUsersGroup={deleteUsersGroup}
          handleAllGroupSearch={handleAllGroupSearch}
          autoComp={autoComp}
          userGroups={allGroups?.data}
          userGroupList={userGroupList}
          onChangeUsersGroup={onChangeUsersGroup}
          groupUsers={groupUsers}
          inpectorsignature={inpectorsignature}
          assetValue={assetValue}
          projectValue={projectValue}
          dueJHAData={dueJHAData}
          onChangeAdduser={onChangeAdduser}
          adduser={adduser}
          addAdditionalUser={addAdditionalUser}
          projectNameValue={projectNameValue}
          customAssetValue={customAssetValue}
          onSelectUsers={onSelectUsers}
          usersValue={usersValue}
        />
      )}
    </>
  );
}

export default ConductJhaContainer;
