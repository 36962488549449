import React from "react";
import JhaHazards from "../component/jha/jhaHazards";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { API_STATUS, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ADMIN_JHA_SET_CONTROLS } from "../../../constant/routeContant";
import JhaSetControlsConatiner from "./jhaSetControlsConatiner";

const JhaHazardsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shwoToast, setshwoToast] = useState(false);
  const [showControlPage, setshowControlPage] = useState(false);
  const defaultData = {
    search: "",
    isAll: 0,
    page: 1,
    pageSize: 1000,
  };
  const [catId, setCatId] = useState("");
  const [formValue, setFormValue] = useState({
    hazardsName: "",
    isGlobal: false,
    activityId: "",
    createdBy: parseInt(USER_ID()),
  });

  const categoriesListData = useSelector((state) => state.jhaCategoryList);
  const activitiesListData = useSelector((state) => state.getJhaActivityList);
  const hazardsListData = useSelector((state) => state.getJhaHazardsList);
  const [listState, setlistState] = useState([]);
  const [listCountState, setlistCountState] = useState(0);
  useEffect(() => {
    if (hazardsListData?.data?.result?.itemList) {
      setlistState(hazardsListData?.data?.result?.itemList);
      setlistCountState(hazardsListData?.data?.result?.itemsCount);
    }
  }, [hazardsListData]);

  const getCategoriesListData = (data) => {
    dispatch(jhaAction.getJhaCategoryList(data));
  };
  const getJhaActivityList = (data) => {
    dispatch(jhaAction.getJhaActivityList(data));
  };
  const getJhaHazardsList = (data) => {
    dispatch(jhaAction.getJhaHazardsList(data));
  };
  useEffect(() => {
    dispatch(jhaAction.clearJha());
    setlistState([]);
    setlistCountState([]);
    getCategoriesListData(defaultData);
  }, []);

  useEffect(() => {
    if (
      categoriesListData.status === API_STATUS.SUCCESS &&
      categoriesListData?.isLoading === false
      // clearalert
    ) {
      mapCategoriesKeyValue();
    }
  }, [categoriesListData]);
  useEffect(() => {
    if (catId !== "")
      getJhaActivityList({
        categoryId: catId,
        search: "",
        isAll: 0,
        page: 1,
        pageSize: 1000,
      });
  }, [catId]);
  useEffect(() => {
    if (formValue.activityId !== "")
      getJhaHazardsList({
        activityId: formValue.activityId,
        search: "",
        page: 1,
        pageSize: PAGE_SIZE,
      });
  }, [formValue.activityId]);

  useEffect(() => {
    if (
      activitiesListData.status === API_STATUS.SUCCESS &&
      activitiesListData?.isLoading === false
      // clearalert
    ) {
      mapActivityKeyValue();
    }
  }, [activitiesListData]);

  const [categoriesList, setcategoriesList] = useState([]);
  function mapCategoriesKeyValue() {
    let arr = [];
    categoriesListData?.data?.result?.itemList.map((i) =>
      arr.push({
        key: i.id,
        value: i.categoryName,
      })
    );
    setcategoriesList(arr);
  }

  const [activityList, setactivityList] = useState([]);
  function mapActivityKeyValue() {
    let arr = [];
    activitiesListData?.data?.result?.itemList.map((i) =>
      arr.push({
        key: i.id,
        value: i.activityName,
      })
    );
    setactivityList(arr);
  }

  // add
  const addJhaHazardData = useSelector((state) => state?.addJhaHazard);
  function addJhaHazard(data) {
    setshwoToast(true);
    dispatch(jhaAction.addJhaHazard(data));
  }
  useEffect(() => {
    if (
      addJhaHazardData.status === API_STATUS.SUCCESS &&
      addJhaHazardData?.isLoading === false &&
      shwoToast
      // clearalert
    ) {
      toast("Hazard Added successfully");
      setshwoToast(false);
      getJhaHazardsList({
        activityId: formValue.activityId,
        search: "",
        page: 1,
        pageSize: PAGE_SIZE,
      });
    }
  }, [addJhaHazardData]);

  // edit
  const updateJhaHazardData = useSelector((state) => state?.updateJhaHazard);
  function handleEditOnClick(data) {
    setshwoToast(true);

    dispatch(jhaAction.updateJhaHazard(data));
  }

  function handleStatusChange(data) {
    let newData = {
      hazardsName: data.hazardsName,
      isGlobal: false,
      hazardsId: data.id,
      isActive: data.isActive,

      modifiedBy: parseInt(USER_ID()),
    };
    setshwoToast(true);
    dispatch(jhaAction.updateJhaHazard(newData));
  }

  useEffect(() => {
    if (
      updateJhaHazardData.status === API_STATUS.SUCCESS &&
      updateJhaHazardData?.isLoading === false &&
      shwoToast
      // clearalert
    ) {
      toast("Hazard Updated successfully");
      setshwoToast(false);
      getJhaHazardsList({
        activityId: formValue.activityId,
        search: "",
        page: 1,
        pageSize: PAGE_SIZE,
      });
    }
  }, [updateJhaHazardData]);

  const isLoading = useSelector(
    (state) =>
      state.getJhaHazardsList?.isLoading ||
      state.addJhaHazard?.isLoading ||
      state.updateJhaHazards?.isLoading
  );

  const [selectedHazardData, setselectedHazardData] = useState({});
  function handleSetControl(data) {
    const activityName = findActivityName();
    // navigate(ADMIN_JHA_SET_CONTROLS, {
    //   state: {
    //     activityName: activityName[0].value,
    //     hazardId: data?.id,
    //     hazardName: data?.hazardsName,
    //   },
    // });
    setselectedHazardData({
      activityName: activityName[0].value,
      hazardId: data?.id,
      hazardName: data?.hazardsName,
    });
    setshowControlPage(true);
  }
  function findActivityName(params) {
    return activityList.filter((i) => i.key === formValue.activityId);
  }
  return (
    <div>
      <ToastContainer />
      {showControlPage ? (
        <JhaSetControlsConatiner
          activityName={selectedHazardData?.activityName}
          hazardId={selectedHazardData?.hazardId}
          hazardName={selectedHazardData?.hazardName}
          setshowControlPage={setshowControlPage}
        />
      ) : (
        <JhaHazards
          categoriesList={categoriesList ?? []}
          activityList={activityList ?? []}
          // hazardsList={hazardsListData?.data?.result?.itemList ?? []}
          hazardsList={listState ?? []}
          // itemsCount={hazardsListData?.data?.result?.itemsCount ?? []}
          itemsCount={listCountState ?? 0}
          formValue={formValue}
          setFormValue={setFormValue}
          catId={catId}
          setCatId={setCatId}
          addJhaHazard={addJhaHazard}
          isLoading={isLoading}
          handleEditOnClick={handleEditOnClick}
          handleStatusChange={handleStatusChange}
          handleSetControl={handleSetControl}
          getJhaHazardsList={getJhaHazardsList}
          setlistState={setlistState}
          setlistCountState={setlistCountState}
        />
      )}
    </div>
  );
};

export default JhaHazardsContainer;
