import React, { useState, useEffect } from "react";
import { FormControl, Grid } from "@mui/material";
import { InputField } from "../../../library/custom/textBox/InputField";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import DatePicker from "../../../library/custom/datePicker/DatePicker";
import TImePicker from "../../../library/custom/timePicker/TImePicker";
import commonImages from "../../../assets";
import Card from "../../../library/custom/card/Card";
import Button from "../../../library/custom/button/Button";
import {
  ORG_ID,
  USER_ID,
  associatedIncidentType,
} from "../../../constant/constant";
import { dateValiadtor, getFormatedDate } from "../../../utils/helper";
import "../../../../src/feature/case/styles/personalInjuryForm.scss";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";

const AddCase = ({
  addRoute,
  caseId,
  viewCaseData,
  editMode,
  onSubmit,
  locationOfRecordData,
}) => {
  const todatDate = new Date();
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const intialState = {
    caseId: 0,
    dateOfCase: new Date(),
    timeOfCase: `${hours < 10 ? `0${hours}` : hours}:${minutes}`,
    location: "",
    isAssociatedIncident: true,
    type: false,
    associatedIncident: [],
    description: "",
    createdBy: parseInt(USER_ID()),
    createdByName: "",
    createdDatetime: "",
    orgId: parseInt(ORG_ID()),
    reportTime: todatDate.getHours() + ":" + todatDate.getMinutes(),
  };
  const [formValue, setFormValue] = useState(intialState);
  const [isstartDate, setisstartDate] = useState(false);
  const [isTime, setIsTime] = useState(false);
  const [isstartDateErrMsg, setisstartDateErrMsg] = useState(false);
  const [isTimeErrMsg, setisTimeErrMsg] = useState(false);
  const [associatedIncidentTypes, setAssociatedIncidentTypes] = useState(
    associatedIncidentType
  );
  const preferedBy = localStorage.getItem("name");
  const [modifiedBy, setModifiedBy] = useState();
  const [locationOfRecord, setLocationOfRecord] = useState();

  const handleLocationOfRecordChange = (e) => {
    setLocationOfRecord(e.target.value);
  };

  const cardClickHandler = (val) => {
    let updatedvehicleList = [...associatedIncidentTypes];
    updatedvehicleList.forEach((vehicleLists) => {
      if (vehicleLists.value === val.value) {
        vehicleLists.isSelected = !vehicleLists.isSelected;
      }
    });
    setAssociatedIncidentTypes(updatedvehicleList);
  };

  function mappedData() {
    let errors = false;

    if (formValue?.timeOfCase === null) {
      setIsTime(true);
      errors = true;
      setisTimeErrMsg("Time is required");
    }
    if (formValue?.dateOfCase === null) {
      setisstartDate(true);
      errors = true;
      setisstartDateErrMsg("Date is required");
    }
    if (formValue.dateOfCase !== null) {
      const validDate = dateValiadtor(getFormatedDate(formValue.dateOfCase));
      if (validDate === false) {
        setisstartDate(true);
        errors = true;
        setisstartDateErrMsg("Invalid Date");
      }
    }
    if (errors) {
      return false;
    }
    const associatedIncidentTypeDatas = [];
    associatedIncidentTypes.forEach((data) => {
      if (data.isSelected === true) {
        associatedIncidentTypeDatas.push(data.key);
      }
    });
    const data = {
      caseId: caseId ? caseId : 0,
      dateOfCase: getFormatedDate(formValue.dateOfCase),
      timeOfCase: formValue.timeOfCase ? formValue.timeOfCase : "00:00:00",
      location: formValue?.location,
      isAssociatedIncident: formValue?.type,
      associatedIncident: formValue?.type ? associatedIncidentTypeDatas : [],
      description:
        Array.isArray(formValue?.description) &&
        formValue?.description?.length > 0
          ? formValue?.description[0].value
          : formValue?.description,
      createdBy: parseInt(USER_ID()),
      modifiedBy: parseInt(USER_ID()),
      orgId: parseInt(ORG_ID()),
      locationOfRecordId: locationOfRecord,
    };
    return data;
  }

  const [isSubmit, setIsSubmit] = useState(false);
  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };
  useEffect(() => {
    if (!addRoute && viewCaseData) {
      setFormValue({
        caseId: viewCaseData?.caseId,
        dateOfCase: viewCaseData?.dateOfCase,
        timeOfCase: viewCaseData?.timeOfCase,
        location: viewCaseData?.location,
        isAssociatedIncident: viewCaseData?.isAssociatedIncident,
        reportTime: viewCaseData?.createdDatetime,
        description: viewCaseData?.description,
        type: viewCaseData?.isAssociatedIncident,
      });
      setLocationOfRecord(viewCaseData?.locationOfRecordId);
      setModifiedBy(viewCaseData?.createdByName);
      let associatedIncidentList = [...associatedIncidentTypes];
      associatedIncidentList.forEach((associatedIncidentLists) => {
        associatedIncidentLists.isSelected = false;
        viewCaseData?.associatedIncident?.forEach((associatedInciden) => {
          if (associatedInciden.key === associatedIncidentLists.key) {
            associatedIncidentLists.isSelected = true;
          }
        });
      });
      setAssociatedIncidentTypes(associatedIncidentList);
    }
  }, [addRoute, viewCaseData]);
  useEffect(() => {
    if (addRoute) {
      setFormValue({
        caseId: 0,
        timeOfCase: `${hours < 10 ? `0${hours}` : hours}:${minutes}`,
        location: "",
        isAssociatedIncident: true,
        type: false,
        associatedIncident: [],
        description: "",
        dateOfCase: new Date(),
      });
      setLocationOfRecord();
      let associatedIncidentList = [...associatedIncidentTypes];
      associatedIncidentList.forEach((associatedIncidentLists) => {
        associatedIncidentLists.isSelected = false;
      });
      setAssociatedIncidentTypes(associatedIncidentList);
    }
  }, [addRoute]);

  return (
    <>
      <div className="overidePersonal">
        <Grid item lg={6} xs={12} sm={12} md={6} textAlign="left">
          <div className="heading_style">
            {editMode ? "Edit Case" : "Add Case"}
          </div>
        </Grid>
        <Grid container item md={12} xs={12} className="addShadowBox">
          <Grid item container spacing={2} mb={3}>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label={"Prepared By"}
                value={editMode ? modifiedBy : preferedBy}
                placeholder="Add Prepared By Text Here..."
              />
            </Grid>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  type="text"
                  label={"Location"}
                  value={formValue.location}
                  placeholder="Add Location Text Here..."
                  onChange={(e) =>
                    setFormValue({ ...formValue, location: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container spacing={2} mb={3}>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabels label={"Date of Case"} isRequired={true} />
                <DatePicker
                  value={formValue.dateOfCase}
                  disabled={editMode}
                  onChangeHandler={(e) => {
                    setFormValue({ ...formValue, dateOfCase: e });
                    setisstartDate(false);
                  }}
                  isError={isstartDate}
                  errorMsg={isstartDateErrMsg}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth className="editCaseDisbled">
                <FormLabels label={"Time of Case"} isRequired={true} />
                <TImePicker
                  disabled={editMode}
                  value={formValue.timeOfCase}
                  onChangeTimeHandler={(e) => {
                    setFormValue({ ...formValue, timeOfCase: e.target.value });
                    setIsTime(false);
                    setisTimeErrMsg("");
                  }}
                  isError={isTime}
                  errorMsg={isTimeErrMsg}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid lg={4} xs={12} sm={12} md={4} item mb={3}>
            <FormControl fullWidth>
              <FormLabels label="Establishment" isRequired={false} />
              <SelectMenu
                placeholder="Please Select"
                listData={locationOfRecordData ?? []}
                value={locationOfRecord ? locationOfRecord : null}
                onchangehandler={(e) => handleLocationOfRecordChange(e)}
              />
            </FormControl>
          </Grid>

          <Grid item container spacing={2} mb={3}>
            <Grid item lg={10} textAlign="left">
              <FormControl fullWidth>
                <div className="typeTxt">
                  <img
                    className="checkImage"
                    title={formValue.type ? "tickCheckbox" : "untickCheckbox"}
                    alt=""
                    src={
                      formValue.type ? commonImages.check : commonImages.noCheck
                    }
                    onClick={() =>
                      setFormValue({ ...formValue, type: !formValue.type })
                    }
                  />
                  <span className="associatedIncidentLabel">
                    Associated incident type, if applicable
                  </span>
                </div>
                {formValue.type && (
                  <Card
                    cardData={associatedIncidentTypes ?? []}
                    cardClickHandler={cardClickHandler}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container spacing={2} mb={3}>
            <Grid item lg={8} md={12} xs={12}>
              <FormControl fullWidth>
                <FormLabels label={"Summary of case"} />
                <TextEditor
                  value={
                    formValue.description.length > 0
                      ? formValue.description[0].value
                      : ""
                  }
                  placeholder={"Add Summary Of Case Here..."}
                  onChange={(content) => {
                    setFormValue({
                      ...formValue,
                      description: content,
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container spacing={2} mb={3}>
            <Grid lg={4} xs={12} sm={6} md={6} mt={3} ml={2}>
              <Button
                varientContained={true}
                disabled={isSubmit ? true : false}
                label={editMode ? "Update Case" : "Create Case"}
                onClick={submitData}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AddCase;
