import React, { useEffect, useState } from "react";
import SupportCard from "../../../library/custom/supportCard/supportCard";
import "./faqContainerStyle.scss";
import { Grid } from "@mui/material";
import Header from "../common/header";
import * as supportAction from "../../../redux/actions/supportAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { useNavigate } from "react-router-dom";
import { FAQ_TOPIC } from "../../../constant/routeContant";
import Buttons from "../../../library/custom/button/Button";
import defaultFaqIcon from "../../../assets/defaultFaqIcon.svg";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import { SUPPORT } from "../../../constant/routeContant";

import commonImages from "../../../assets";
const Faq = () => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [searchbarInput, setSearchbarInput] = useState("");
  const [page, setPage] = useState(1);

  // articleCatSubcategory DATA
  const articleCatSubcategory = useSelector(
    (state) => state.articlesCatSub?.data?.articleCatSubcat
  );
  const numOfRecords = useSelector(
    (state) => state.articlesCatSub?.data?.noOfRecords
  );

  const showLoader = useSelector((state) => state.articlesCatSub?.isLoading);
  useEffect(() => {
    dispatch(
      supportAction.getArticleCatSubcategory({
        search: "",
        page: 1,
        pageSize: 6,
      })
    );
  }, []);
  function handleSearch(search) {
    setSearchbarInput(search);
    dispatch(
      supportAction.getArticleCatSubcategory({
        search: search,
        page: 1,
        pageSize: 6,
      })
    );
  }
  useEffect(() => {
    dispatch(
      supportAction.getArticleCatSubcategory({
        search: searchbarInput,
        page: page,
        pageSize: 6,
      })
    );
  }, [page]);

  const dataBack = {
    title: "Back to Support Dashboard",
    route: SUPPORT,
  };
  console.log(articleCatSubcategory?.length)
  return (
    <div className="overrideFaqPage">
      <div className="faqMainContainer">
        <div className="rightDiv">
          {showLoader && <Loader />}
          <div className="faqcontainer">
            <Header
              value={searchbarInput}
              handleSearch={(search) => {
                handleSearch(search);
              }}
            />
            {
              articleCatSubcategory?.length === 0 && 
              <img src={commonImages.NoRow} alt=""/>
            } 
            {articleCatSubcategory ? (
              <Grid className="cardsContainer" gap={3}>
                {articleCatSubcategory.map((details, index) => {
                  return (
                    <SupportCard
                      key={details.articleCatSubcatId}
                      varientFaq={true}
                      icon={
                        <img
                          src={
                            details.imageUrl === "" || "string"
                              ? defaultFaqIcon
                              : details.imageUrl
                          }
                          alt=""
                          className="icon"
                        />
                      }
                      category={details.title}
                      details={details.description}
                      onClick={() => {
                        naviagte(FAQ_TOPIC, {
                          state: {
                            articleCatSubcatId: details.articleCatSubcatId,
                            title: details.title,
                            description: details.description,
                          },
                        });
                      }}
                    />
                  );
                })}
{           articleCatSubcategory?.length !== 0 &&      <Grid
                  item
                  lg={12}
                  container
                  justifyContent={"right"}
                  alignItems={"center"}
                >
                  <Buttons
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                    varientText
                    label={"<"}
                    className="paginationButton"
                  />
                  <span className="paginationText">
                    {page}/{Math.round(numOfRecords / 6) }
                  </span>
                  <Buttons
                    disabled={articleCatSubcategory.length < 6}
                    onClick={() => setPage(page + 1)}
                    varientText
                    label={">"}
                    className="paginationButton"
                  />
                </Grid>
}
                <Grid item lg={12} container>
                  <BackBtn onClick={() => naviagte(-1)} dataBack={dataBack} />
                </Grid>
              </Grid>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
