import React, { useEffect } from "react";
import DivisionList from "../component/divisions/divisionList";
import * as divisionsAction from "../../../redux/actions/divisionsAction";
import { useDispatch, useSelector } from "react-redux";
import {
  ADMIN_ADD_DIVISION,
  ADMIN_EDIT_DIVISION,
  ADMIN_VIEW_DIVISION,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { API_STATUS } from "../../../constant/constant";

const DivisionContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultData = {
    page: 1,
    pageSize: 10,
    search: "",
  };

  const divisionsListData = useSelector((state) => state.divisionsList);

  const getDivisionsListData = (data) => {
    dispatch(divisionsAction.getDivisionsList(data));
  };
  const isLoading = useSelector(
    (state) =>
      state.divisionsList?.isLoading || state.deleteDivisions?.isLoading
  );

  const deleteDivisions = useSelector((state) => state.deleteDivisions);

  useEffect(() => {
    getDivisionsListData(defaultData);
  }, []);
  useEffect(() => {
    if (deleteDivisions?.status === API_STATUS.SUCCESS) {
      getDivisionsListData(defaultData);
    }
  }, [deleteDivisions]);

  const addDivision = () => {
    navigate(ADMIN_ADD_DIVISION);
  };

  const handleViewOnClick = (divisionId) => {
    navigate(ADMIN_VIEW_DIVISION, {
      state: { divisionId: divisionId },
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(ADMIN_VIEW_DIVISION, {
        state: { divisionId: rows.row.divisionId, editMode: true },
      });
    }
  };

  const handleEditOnClick = (divisionId) => {
    navigate(ADMIN_EDIT_DIVISION, {
      state: { divisionId: divisionId, editMode: true },
    });
  };
  const handleDeleteOnClick = (divisionId) => {
    dispatch(divisionsAction.deleteDivisions(divisionId));
  };

  return (
    <>
      <DivisionList
        divisionsListData={isLoading ? [] : divisionsListData?.data}
        getDivisionsListData={getDivisionsListData}
        isLoading={isLoading}
        addDivision={addDivision}
        handleDeleteOnClick={handleDeleteOnClick}
        handleEditOnClick={handleEditOnClick}
        handleViewOnClick={handleViewOnClick}
        onRowClick={onRowClick}
      />
    </>
  );
};

export default DivisionContainer;
