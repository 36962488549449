import { signupType } from "../constants/signupType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "signup",
  isError: false,
  isLoading: false,
  mobileVerified: false,
  otpstatus: false,
  error: {},
};

export const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case signupType.POST_SIGNUP_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
        error: {},
      };
    case signupType.POST_SIGNUP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
        mobileVerified: true,
      };
    case signupType.POST_SIGNUP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        mobileVerified: false,
        error: { ...action.error },
      };
    case signupType.CLEAR_ERRORS:
      return {
        ...state,
        error: { ...action.error },
        isLoading: false,
        isError: false,
        otpstatus: false,
        mobileVerified: false,
      };
    default:
      return state;
  }
};

export const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case signupType.POST_SIGNUPONBOARDING_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
        onstatus: false,
        error: {},
      };
    case signupType.POST_SIGNUPONBOARDING_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
        onstatus: true,
      };
    case signupType.POST_SIGNUPONBOARDING_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
        onstatus: false,
      };
    default:
      return state;
  }
};

export const otpSignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case signupType.POST_OTPSIGNUP_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
        otpstatus: false,
        error: {},
      };
    case signupType.POST_OTPSIGNUP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
        otpstatus: true,
      };
    case signupType.POST_OTPSIGNUP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
        otpstatus: false,
      };
    default:
      return state;
  }
};

export const verifyMobileReducer = (state = initialState, action) => {
  switch (action.type) {
    case signupType.POST_VERIFYMOBILE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
        error: {},
      };
    case signupType.POST_VERIFYMOBILE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
        mobileVerified: true,
      };
    case signupType.POST_VERIFYMOBILE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        mobileVerified: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateOnboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case signupType.PUT_ONBOARDING_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case signupType.PUT_ONBOARDING_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case signupType.PUT_ONBOARDING_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
