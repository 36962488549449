import React, { useState } from "react";
import Buttons from "../../../library/custom/button/Button";
import SearchIcon from "@mui/icons-material/Search";
import GridTable from "../../../library/custom/gridTable/GridTable";
import SettingTable from "../../incidents/incidentList/SettingTable";
import {
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
} from "../../../constant/constant";
import ReportRowManipulte from "../../incidents/incidentList/ReportRowManipulte";
import NORow from "../../../library/custom/noRow/noRow";
import { useNavigate } from "react-router-dom";
import { SETUP_ADD_CREDENTIAL } from "../../../constant/routeContant";
import { TableLoader } from "../../../library/common/Loader";
import FilterCommonStatusSelect from "../../admin/shared/FilterCommonStatusSelect";
import FilterListIcon from "@mui/icons-material/FilterList";
import { checkPrivileges } from "../../../utils/rolesHelper";

export default function SetupCredentialList({
  NavigateAddCredentials,
  credentialListData,
  isLoading,
  getCredentials,
  credentialTypes,
}) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [anchorEl, setAnchorEl] = useState(null);
  const [credentialListApiData, setCredentialListApiData] = useState({
    organisationId: ORG_ID(),
    page: 1,
    pageSize: pageSize,
    search: "",
    credentialTypeId: "",
    usedId: "",
  });

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnclick = (rows) => {
    navigate(SETUP_ADD_CREDENTIAL, {
      state: { credentialNameId: rows.row.id, editMode: true },
    });
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...credentialListApiData,
      search: searchText ? searchText : "",
    };
    setCredentialListApiData({
      ...credentialListApiData,
      search: searchText ? searchText : "",
    });
    getCredentials(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      ...credentialListApiData,
      page: newPage + 1,
    };
    setCredentialListApiData({
      ...credentialListApiData,
      page: newPage + 1,
    });
    getCredentials(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      ...credentialListApiData,
      page: 1,
      pageSize: newPazeSize,
    };
    setCredentialListApiData(data);
    getCredentials(data);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [credentialType, setCredentialType] = useState();

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
        setCredentialType(item?.key);
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const handleSearch = (data) => {
    setAnchorEl(null);
    getCredentials(data);
  };

  const handleApply = (state) => {
    setCredentialListApiData({
      ...credentialListApiData,
      credentialTypeId: credentialType ? credentialType : 0,
    });
    setAnchorEl(null);
    const data = {
      ...credentialListApiData,
      page: 1,
      pageSize: pageSize,
      search: "",
      credentialTypeId: credentialType ? credentialType : 0,
      organisationId: ORG_ID(),
    };
    getCredentials(data);
  };

  const handleClear = () => {
    setCredentialType("");
    setFilter({ searchType: [] });
    const data = {
      page: 1,
      pageSize: pageSize,
      credentialTypeId: "",
      organisationId: ORG_ID(),
      search: "",
    };
    handleSearch(data);
  };

  const [filter, setFilter] = useState({
    searchType: [],
  });

  const credcloumns = [
    { field: "id", headerName: "Credential ID", flex: 3 },
    { field: "credentialName", headerName: "Credential Name", flex: 4 },
    { field: "issuingBodies", headerName: "Issuing Body", flex: 5 },
    { field: "expirationPeriod", headerName: "Expiry Period", flex: 4 },
    { field: "credentialType", headerName: "Credential Type", flex: 4 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={credentialListData?.noOfRecords ?? 0}
          numberOfItemsPerPage={pageSize}
          incidentLists={credentialListData?.credentialData ?? []}
          fileNameXL={"Credential-list"}
        />
      ),
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          (parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) ||
            !rows?.row?.isGlobal) &&
          checkPrivileges([12, 13]) && (
            <ReportRowManipulte
              rows={rows}
              credentialLists={true}
              editOnClick={() => handleOnclick(rows)}
              edit={checkPrivileges([12, 13])}
              editLabel="Edit Credential"
              vie
              download={false}
              view={false}
              deleted={false}
              deleteLabel="Delete Credential"
            />
          )
        );
      },
    },
  ];

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Credential List</div>
          <div>
            <div className="filter_btn">
              <Buttons
                variant="contained"
                type="button"
                name="btn"
                label={"Add Credential"}
                varientAddIconBlue={true}
                onClick={NavigateAddCredentials}
              ></Buttons>
            </div>
          </div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>

            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              {/* <FilterButton
                label={"Filters"}
                handleClick={(e) => handleClick(e)}
              /> */}

              <FilterCommonStatusSelect
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleApply={handleApply}
                handleClear={handleClear}
                filter={filter}
                statusType={credentialTypes}
                onChangeType={onChangeType}
                statusIds={credentialType}
                filterStatus={true}
                resetBtn={true}
                label={"Credential Type"}
              />
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.id}
                rows={credentialListData?.credentialData ?? []}
                columns={credcloumns}
                rowCount={credentialListData?.noOfRecords ?? 0}
                loading={isLoading}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                page={page}
                pagination
                pageSize={pageSize}
                onPageChange={handlePagignation}
                checkboxSelection={false}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
