import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../tabPanel/tabPanel";
import LmstableData from "../../../../library/custom/lmstableData/lmstableData";
import "./coursesList.scss";
import { useSelector, useDispatch } from "react-redux";
import { COLUMNS_COURSES_VIEW } from "../../courseConstant";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import { useNavigate } from "react-router-dom";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import { COURSESDETAIL } from "../../../../constant/routeContant";
import SearchIcon from "@mui/icons-material/Search";
import Popper from "@mui/material/Popper";
import LmsFilterList from "../../../../library/custom/lmsFilterList/lmsFilterList";
import {
  PAGE_SIZE,
  LNG_ID,
  ORG_ID,
  USER_ID,
  API_STATUS,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import FilterButton from "../../../../library/custom/filterButton/filterButton";
import DialogBox from "../../../../library/common/DialogBox";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const CoursesList = (props) => {
  const [page, setPage] = useState(1);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getCoursesListData = props?.getCoursesListData;
  const { isLoading } = useSelector((state) => state.getCoursesList);
  const [status, setStatus] = useState(0);
  const CoursedeleteStatus = props?.CoursedeleteStatus;
  const publishCourseStatus = props?.publishCourseStatus;
  const courseListData = props?.courseListData;
  const [placement, setPlacement] = useState();
  const [open, setOpen] = useState(false);
  const categoryListData = props?.categoryListData;
  const deleteCourse = props?.deleteCourse;
  const isShowClient =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && checkPrivileges([12]);
  const setisdisable = props?.setisdisable;
  const isdisable = props?.isdisable;

  const handleChange = (event, newValue) => {
    setPage(1);
    setValue(newValue);
    setStatus(newValue);
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: search,
      catIds: filter ? filter?.searchType[0]?.key : "",
      statusId: "",
      userId: USER_ID(),
      organisationId: ORG_ID(),
      status: newValue,
    };
    getCoursesListData(data);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [search, setSearch] = useState("");

  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: searchText ? searchText : "",
      catIds: filter ? filter?.searchType[0]?.key : "",
      status: value,
      userId: USER_ID(),
      organisationId: ORG_ID(),
    };
    getCoursesListData(data);
  };

  const handleFilterClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const id = open ? "simple-popper" : undefined;

  useEffect(() => {
    if (courseListData.status === API_STATUS.SUCCESS) {
      setisdisable(false);
    }
  }, [courseListData.status]);

  useEffect(() => {
    const { searchType } = filter;

    if (
      publishCourseStatus === API_STATUS.SUCCESS ||
      CoursedeleteStatus === API_STATUS.SUCCESS
    ) {
      dispatch(lmsAction.getCategoryList({ pageNumber: 1, isGlobal: false }));
      dispatch(
        lmsAction.getCoursesList({
          search: "",
          pageSize: PAGE_SIZE,
          pageNumber: page ?? 1,
          catIds: searchType && searchType[0]?.key,
          status: status ? status : "",
          organisationId: ORG_ID(),
        })
      );

      handleClose();
    }
  }, [CoursedeleteStatus, publishCourseStatus]);

  const handleViewOnClick = (courseId) => {
    navigate(COURSESDETAIL, {
      state: {
        courseId: courseId,
        userId: USER_ID(),
        isAdminCourse: true,
      },
    });
  };

  const publishItem = (courseId) => {
    const courseData = {
      courseId: courseId,
      isPulish: true,
      orgId: parseInt(ORG_ID()),
    };
    props?.onSubmit(courseData);
    setAnchorEl(null);
  };

  const unPublishItem = (courseId) => {
    const courseData = {
      courseId: courseId,
      isPulish: false,
      orgId: parseInt(ORG_ID()),
    };
    props?.onSubmit(courseData);
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteItem = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };
  const deletehandle = () => {
    setOpenModal(false);
    const data = {
      courseId: selectedRow?.id,
      lngId: LNG_ID,
    };
    deleteCourse(data);
  };
  const finaldata = courseListData?.data?.courses.forEach(function (v) {
    delete v.file;
  });
  const columns = React.useMemo(() => {
    const headers = [...COLUMNS_COURSES_VIEW];
    headers.push({
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={noRecourdCount ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={courseListData?.data?.courses ?? []}
          fileNameXL={"CourseList"}
        />
      ),
      width: 75,
      sortable: false,
      disableClickEventBubbling: true,

      filterable: false,
      disableColumnMenu: true,

      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            usingComp={"Course"}
            deleted={
              (!rows.row.isPublished &&
                checkPrivileges([12, 11]) &&
                isShowClient) ||
              (!rows.row.isPublished &&
                checkPrivileges([12, 11]) &&
                rows?.row?.isGlobal === false)
                ? "deleted"
                : ""
            }
            edit={
              (!rows.row.isPublished &&
                checkPrivileges([12, 11, 10]) &&
                isShowClient) ||
              (!rows.row.isPublished &&
                checkPrivileges([12, 11, 10]) &&
                rows?.row?.isGlobal === false)
                ? "edit"
                : ""
            }
            view
            viewLabel={"View Course"}
            editLabel={"Edit Course"}
            deleteLabel={"Delete Course"}
            publishText={
              rows?.row?.isPublished ? (
                <span className={isdisable ? "disableclss" : ""}>
                  UnPublish Course{" "}
                </span>
              ) : (
                <span className={isdisable ? "disableclss" : ""}>
                  Publish Course
                </span>
              )
            }
            publish
            viewOnClick={(row) => handleViewOnClick(row?.id)}
            editOnClick={(row) =>
              !row?.isPublished ? navigate(`/course/edit/${row.id}`) : undefined
            }
            deleteOnClick={() =>
              checkPrivileges([12, 11]) ? deleteItem(rows?.row) : ""
            }
            publishOnClick={(row) =>
              !isdisable &&
              (row?.isPublished ? unPublishItem(row?.id) : publishItem(row?.id))
            }
          />
        );
      },
    });
    return headers;
  }, [courseListData, PAGE_SIZE, navigate, isdisable]);
  const noRecourdCount = courseListData?.data?.noOfRecords;

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getCoursesListData({
      pageNumber: newPage + 1,
      pageSize: PAGE_SIZE,
      search: search,
      catIds: filter ? filter?.searchType[0]?.key : "",
      status: value,
      userId: USER_ID(),
      organisationId: ORG_ID(),
    });
  };
  const handleSearch = (data) => {
    setAnchorEl(null);
    getCoursesListData(data);
    setOpen(false);
  };
  const [filter, setFilter] = useState({ searchType: [] });

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div className="couresList">
        <Grid container>
          <Grid lg={7} xs={12}>
            {openModal && (
              <DialogBox
                open={openModal}
                rows={[]}
                header={"Confirm Deletion"}
                deletehandle={deletehandle}
                handleCloseModal={() => setOpenModal(false)}
                content={"Are you sure you want to delete this Course?"}
                view
              />
            )}
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tabcustom"
                  icon={
                    value === 0 ? (
                      <div className="totalV">
                        {value === 0 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="All Courses"
                />
                <Tab
                  className="tabcustom"
                  icon={
                    value === 1 ? (
                      <div className="totalV">
                        {value === 1 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  label="Published"
                  iconPosition="end"
                />
                <Tab
                  className="tabcustom"
                  icon={
                    value === 2 ? (
                      <div className="totalV">
                        {value === 2 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  label="Unpublished"
                  iconPosition="end"
                />
              </Tabs>
            </Box>
          </Grid>
          <Grid lg={5} xs={12}>
            <div className="rightFilter">
              <div className="searchfilter">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div className="searchfilterInput">
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
              <div
                className="filter_btn"
                onClick={handleFilterClick("bottom-end")}
              >
                <FilterButton label={"Filters"} handleClick={handleClick} />
              </div>
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
              >
                <LmsFilterList
                  filterType={categoryListData?.categoryList ?? []}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  handleSearch={handleSearch}
                  tabvalue={value}
                  inputsearch={search}
                  onChangeType={onChangeType}
                  filter={filter}
                  PAGE_SIZE={PAGE_SIZE}
                />
              </Popper>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <TabPanel className="tabPanel" value={value} index={0}>
            <LmstableData
              courseListDataAll={courseListData}
              onPageChange={handlePagignation}
              page={page}
              isLoading={isLoading}
              columns={columns}
            />
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={1}>
            <LmstableData
              courseListDataAll={courseListData}
              onPageChange={handlePagignation}
              page={page}
              isLoading={isLoading}
              columns={columns}
            />
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={2}>
            <LmstableData
              courseListDataAll={courseListData}
              onPageChange={handlePagignation}
              page={page}
              isLoading={isLoading}
              columns={columns}
            />
          </TabPanel>
        </Grid>
      </div>
    </>
  );
};

export default CoursesList;
