import React from "react";
import "./table.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Table = ({ tableRef, columns, rows, className = "", onSort }) => {
  const containerClassName = `tableContainer ${className}`;

  const handleSort = (column) => {
    if (onSort) onSort(column);
  };

  const renderHeader = () => {
    if (onSort) {
      return (
        <thead>
          <tr className="column">
            {columns?.map((col) => (
              <th key={col.id} onClick={() => handleSort(col.id)}>
                {col.sortable ? (
                  <span>
                    {col.label}
                    {col.isSorted ? (
                      col.sortDirection === "asc" ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </span>
                ) : (
                  col.label
                )}
              </th>
            ))}
          </tr>
        </thead>
      );
    }
  };

  return (
    <div className={containerClassName} ref={tableRef}>
      <table>
        {onSort ? renderHeader() : <thead>{columns && columns()}</thead>}

        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

export default Table;
