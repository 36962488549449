import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as reportsAction from "../../../../redux/storm-water/actions/reportsAction";
import ReportMap from "../components/ReportMap";
import Loader from "../../../../library/common/Loader";

function SwReportMapConatiner() {
  const dispatch = useDispatch();
  const getOrganisationMapList = useSelector(
    (state) => state.organisationMapData
  );

  const showLoader = useSelector(
    (state) => state.organisationMapData.isLoading
  );
  const getOrganisationMapListData = (data) => {
    dispatch(reportsAction.getOrganisationMapList(data));
  };

  useEffect(() => {
    getOrganisationMapListData();
  }, []);

  return (
    <>
      {showLoader && <Loader />}
      <ReportMap getOrganisationMapList={getOrganisationMapList} />
    </>
  );
}

export default SwReportMapConatiner;
