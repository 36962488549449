import React from "react";
import { CREDENTIALMANAGEMENT } from "../../../../constant/constant";
import Card from "../../../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const CredentialManagementList = ({ onClick }) => {
  return (
    <>
      <Grid container className="CardListContaner">
        {checkPrivileges([12, 13]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CREDENTIALMANAGEMENT[0]}
          />
        )}
        {checkPrivileges([12, 13, 47]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CREDENTIALMANAGEMENT[1]}
          />
        )}
        {checkPrivileges([12, 13, 47, 48]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CREDENTIALMANAGEMENT[2]}
          />
        )}
        {checkPrivileges([12, 13, 47, 48]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={CREDENTIALMANAGEMENT[3]}
          />
        )}
      </Grid>
    </>
  );
};

export default CredentialManagementList;
