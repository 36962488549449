import React, { useEffect, useState } from "react";
import { PAGE_SIZE_100, USER_INVOLVEDPARTY } from "../../../../constant/constant";
import { Grid, Tab, Tabs } from "@mui/material";
import TabPanel from "../../../lms/component/tabPanel/tabPanel";
import SubContractorKudosAsInvolvedParty from "./subContractorKudosAsInvolvedParty";
import SubContractorCorrectiveAsInvolvedParty from "./subContractorCorrectiveAsInvolvedParty";

const SubContractorParty = (props) => {
  const [value, setValue] = useState(0);

  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }
  useEffect(() => {
    if (props?.drillDetail?.page === "subContractorCorrectiveAsInvolvedParty") {
      setValue(1);
    } else if (
      props?.drillDetail?.page === "subContractorKudosAsInvolvedParty"
    ) {
      setValue(0);
    }
  }, [props?.drillDetail]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const { apiData } = props.drillDetail;
    const data = {
      assetType: apiData.assetType,
      customAssetType: apiData.customAssetType,
      startDate: apiData.startDate,
      endDate: apiData.endDate,
      divisionId: apiData.divisionId,
      assetId: apiData.assetId,
      assetCatId: apiData?.assetCatId,
      reportDetails: newValue === 0 ? 8 : 7,
      page: 1,
      pageSize: PAGE_SIZE_100,
    };
    props.getInspectionReportAssetDetailsData(data);
  };

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Tabs value={value} onChange={handleChange} className="tabsWrapper">
            {USER_INVOLVEDPARTY?.map((tabItem, i) => {
              return (
                <Tab
                  className="tabList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        </Grid>

        <Grid item md={12} mt={3}>
          <TabPanel value={value} index={0}>
            <SubContractorKudosAsInvolvedParty
              kudosSubContractors={props?.kudosSubContractors}
              drillDetail={props?.drillDetail}
              getInspectionReportAssetDetailsData={
                props?.getInspectionReportAssetDetailsData
              }
              reportDetails={8}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SubContractorCorrectiveAsInvolvedParty
              correctiveSubContractors={props?.correctiveSubContractors}
              drillDetail={props?.drillDetail}
              getInspectionReportAssetDetailsData={
                props?.getInspectionReportAssetDetailsData
              }
              reportDetails={7}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default SubContractorParty;
