import React, { useState } from "react";
import { getCurrentMinuteSecond } from "../utils/helper";
import { checkPrivileges } from "../utils/rolesHelper";

const todatDate = new Date();
function commonFormHoc(OrignalComponent) {
  function EnhancedComponent() {
    const [hideprojectNameEdit, setHideprojectNameEdit] = useState(false);
    const [userClear, setUserClear] = useState(0);
    const [form, setForm] = useState({
      location: "",
      assetId: "",
      generalComment: " ",
      preparedBy: localStorage.getItem("name") ?? "",
      dateOfInspection: todatDate,
      timeOfInspection: getCurrentMinuteSecond(),
      selectedVehicleAccident: {
        key: 1,
        value: "Dry",
      },
    });
    const cardClicStatickHandler = (val) => {
      setForm((prevState) => ({
        ...prevState,
        selectedVehicleAccident: val,
      }));
    };
    const onChangeDatePicker = React.useCallback(
      (value) => {
        setForm((prevState) => ({ ...prevState, dateOfInspection: value }));
      },
      [setForm]
    );
    const onChange = React.useCallback(
      (e) => {
        // if (e.target.value.length <= 500) {
        setForm((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
        // }
      },
      [setForm]
    );

    const onChangeComment = React.useCallback(
      (e) => {
        setForm((prevState) => ({ ...prevState, generalComment: e }));
      },
      [setForm]
    );

    const onChangeAsset = React.useCallback(
      (e, value) => {
        setHideprojectNameEdit(true);
        setUserClear((prevClear) => prevClear + 1);
        setForm((prevState) => ({
          ...prevState,
          assetId: checkPrivileges([12, 139, 144])
            ? value ?? ""
            : e?.target?.value ?? "",
        }));
      },
      [setForm]
    );

    return (
      <OrignalComponent
        form={form}
        onChange={onChange}
        onChangeComment={onChangeComment}
        onChangeDatePicker={onChangeDatePicker}
        cardClicStatickHandler={cardClicStatickHandler}
        onChangeAsset={onChangeAsset}
        setForm={setForm}
        hideprojectNameEdit={hideprojectNameEdit}
        userClear={userClear}
      />
    );
  }
  return EnhancedComponent;
}

export default commonFormHoc;
