import React from "react";
import { Grid, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../textBox/InputField";
import TextArea from "../../../library/custom/textArea/TextArea";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import "./Witness.scss";
import Buttons from "../button/Button";
import intl from "react-intl-universal";
import FormDropZone from "../../dropZone/FormDropZoneIncident";

export default function Witness(props) {
  const {
    singleWitness,
    keyNo,
    handleOnChangeWitnessForm,
    witnessFileUpload,
    optionsData,
    isErrorWitnessName = false,
    errorWitnessNameMsg = "",
    isErrorStatement = false,
    errorStatementMsg = "",
    id,
    cancelWitnessList,
    deleteImageURL,
  } = props;

  return (
    <>
      <div className="overideWitness" key={keyNo}>
        <div className="witnessBox">
          <h1 className="title">{`Witness${keyNo}`}</h1>
          <Grid item container mt={3} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  type="text"
                  id="name"
                  label={intl.get("incident.vehicleAccident.name")}
                  isRequired={true}
                  placeholder="Add Name Text Here..."
                  value={singleWitness.witnessName}
                  isError={isErrorWitnessName}
                  errorMsg={errorWitnessNameMsg}
                  onChange={(e) =>
                    handleOnChangeWitnessForm(e, singleWitness, "witnessName")
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  type="text"
                  id="employerName"
                  label={intl.get("incident.vehicleAccident.employersName")}
                  isRequired={false}
                  placeholder="Add Employer's Name Text Here..."
                  value={singleWitness.employerName}
                  onChange={(event) =>
                    handleOnChangeWitnessForm(
                      event,
                      singleWitness,
                      "employerName"
                    )
                  }
                />
                {/* <FormLabels label="Employer's Name" />
                <SearchAutoComplete
                  id="partyId"
                  optionsData={optionsData}
                  isUserDetails={true}
                  // defaultValue={[optionsData[defaultValueIndex]]}
                  value={singleWitness.partyId ? singleWitness.partyId : ""}
                  onChange={(event) =>
                    handleOnChangeWitnessForm(event, singleWitness, "partyId")
                  }
                /> */}
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container mt={3} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  id="jobTitle"
                  type="text"
                  label={intl.get("incident.vehicleAccident.jobTitle")}
                  placeholder="Add Job Title Text Here..."
                  value={singleWitness.jobTitle}
                  onChange={(e) =>
                    handleOnChangeWitnessForm(e, singleWitness, "jobTitle")
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={4} mt={3}>
              <FormLabels label="" />
              {/* <Signature
                handleOnChangeEvent={handleOnChangeWitnessForm}
                singleWitness={singleWitness} */}
              {/* <Buttons
                variantOutline={true}
                disabled={witnessSignature}
                id="signature"
                label="+ Add Signature"
                onClick={(e) => {
                  setWitnessSignature(true);
                  handleOnChangeWitnessForm(true, singleWitness, "signature");
                }}
              /> */}
              {/* {witnessSignature && (
                <p className="colorGreen">Signature Authorized successfully</p>
              )} */}
            </Grid>
          </Grid>
          <Grid item container mt={3} spacing={2}>
            <Grid item lg={12} xs={12} sm={12} md={8}>
              <FormControl fullWidth>
                <FormLabels
                  label={intl.get("incident.vehicleAccident.statement")}
                  isRequired={true}
                />
                <TextArea
                  key={singleWitness.id}
                  id="statement"
                  placeholder={"Add Statement Text Here..."}
                  value={singleWitness.statement ?? ""}
                  onChange={(e) =>
                    handleOnChangeWitnessForm(e, singleWitness, "statement")
                  }
                  isError={isErrorStatement}
                  errorMsg={errorStatementMsg}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container mt={3}>
            <Grid lg={12} xs={12} sm={12} md={12}>
              <FormDropZone
                key={singleWitness.id}
                label={intl.get("incident.vehicleAccident.addPhotos")}
                id="uploadMedia"
                paraText1={"Drop the files here ..."}
                paraText2={"Drop Files here, "}
                paraText3={"or Browse"}
                deleteImageURL={deleteImageURL}
                // imageUrl={filePaths}
                handleFileChange={(photo) => witnessFileUpload(photo)}
                imageUrl={singleWitness.files}
                fileType={10}
                isDynamics={false}
                isImage={false}
                allFiles={true}
                isDoc={true}
              />
            </Grid>
          </Grid>
        </div>
        <div className="witnessCancel">
          <Grid item container mt={3}>
            <Grid lg={12} xs={12} sm={12} md={12}>
              {/* {showCancel && ( */}
              <Box mb={2} textAlign="right">
                <Buttons
                  variantOutline={true}
                  onClick={() => cancelWitnessList(id)}
                  label={intl.get("incident.vehicleAccident.cancel")}
                ></Buttons>
              </Box>
              {/* )} */}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
