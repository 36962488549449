import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Utility from "./utility";
import Button from "../../../library/custom/button/Button";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import CreateAction from "../../../library/custom/createAction/CreateAction";
import { getFormatedDate } from "../../../utils/helper";
import {
  API_STATUS,
  GET_USER_TYPE,
  INCIDENT_STATUS,
  ORG_ID,
} from "../../../constant/constant";
import intl from "react-intl-universal";
import useDebounce from "../../../utils/useDebounce";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
const UtilityDamageForm = ({
  incident,
  onSubmit,
  searchOption,
  utilityDamageData,
  incidentId,
  fileUploadData,
  uploadFile,
  caseId,
  editMode,
  isLoading,
  searchUsers,
  utilityDamageDataStatus,
  searchAllUsers,
  allUsers,
  getAssetOnUser,
  assetOnUserList,
  getSupervisorOnAsset,
  supervisorOnAssetList,
  fileError,
  countError,
}) => {
  const {
    associatedIncidentType,
    utilityPropertyDamaged,
    typeOfUtilityLineDamage,
    wereUtilityLines,
    typeOfUtility,
  } = incident;
  const userId = localStorage.getItem("userId");
  const todatDate = new Date();

  const intialState = {
    foremanSignature: false,
    supervisorSignature: false,
    userId: 0,
    employeeName: "",
    jobSite: 0,
    supervisor: 0,
    dateOfCase: todatDate,
    addDetails: "",
    timeOfCase: todatDate.getHours() + ":" + todatDate.getMinutes(),
    type: false,
    caseDescription: "",
    caseLocation: "",
    supervisorError: false,
    jobSiteProjectNameError: false,
  };

  const [formValue, setFormValue] = useState(intialState);
  const [disableEmpName, setDisableEmpName] = useState(false);

  const [searchtext, setSeachText] = useState("");
  const debounceAs = useDebounce(searchtext, 500);
  useEffect(() => {
    if (debounceAs) {
      searchUsers(GET_USER_TYPE.PREVNONPREVUSER, debounceAs);
    }
  }, [debounceAs]);

  useEffect(() => {
    if (utilityDamageData && incidentId) {
      const { actions, additionalDetails, utilityDamagedPrm } =
        utilityDamageData;
      if (utilityDamageDataStatus.status === API_STATUS.SUCCESS) {
        setDisableEmpName(true);
        setEmployeeNameValue({
          userId: utilityDamagedPrm?.userId,
          firstName: utilityDamagedPrm?.employee,
        });
        setFormValue({
          employeeName: utilityDamagedPrm?.employee,
          jobSite: utilityDamagedPrm?.projectId,
          supervisor: utilityDamagedPrm?.supervisorId,
          addDetails: utilityDamagedPrm?.additionalDetails || additionalDetails,
          userId: utilityDamagedPrm?.userId,
          type: true,
        });

        setJobSiteData([
          {
            key: utilityDamagedPrm?.projectId,
            value: utilityDamagedPrm?.jobSite,
          },
        ]);
        setSupervisorData([
          {
            key: utilityDamagedPrm?.supervisorId,
            value: utilityDamagedPrm?.supervisor,
          },
        ]);
        const newUtilities = [];
        utilityDamagedPrm?.utilityPrm?.forEach((items, index) => {
          newUtilities.push({
            utilityPropertyDamaged: items?.utilityPropertyId,
            utilityId: items.utilityId,
            typeOfUtility: items?.typeOfUtility?.map((item) => item.value),
            typeOfUtilityLine: items?.udUtilityDamage?.map(
              (item) => item.value
            ),
            typeOfMachine: items?.typeOfMachinery,
            locationDetail: items?.locationDetail,
            location: items?.addLocation,
            locateTicket: items?.locateTicket,
            damageTicket: items?.damageTicket,
            validFrom: items?.validFrom,
            validTo: items?.validTo,
            id: index,
            wereUtilityLines: items?.udUtilityLine?.map((item) => item.value),
          });
        });
        setUtilityList(newUtilities);

        if (actions.length) {
          const actionList = [];
          actions.forEach((items, index) => {
            actionList.push({
              isFileAdded: items.isFileAdded,
              files: items.files,
              description: items.description,
              assignedPartyName: items.assignedPartyName,
              assignedPartyId: items.assignedPartyId,
              id: items.actionId,
              fileId: items?.files?.map((files) => files.fileId),
            });
          });

          setActionlist(actionList);
        }
      }
      getSupervisorOnAsset(utilityDamagedPrm?.projectId);
    }
  }, [utilityDamageDataStatus]);
  const [associatedIncidentTypes] = useState(associatedIncidentType);

  const [jobSiteData, setJobSiteData] = useState([]);
  const [supervisorData, setSupervisorData] = useState([]);
  const [employeeNameValue, setEmployeeNameValue] = useState();

  const handleEmployeeName = (e, value) => {
    setSupervisorData(value?.supervisor);
    setJobSiteData(value?.jobSite);
    setEmployeeNameValue({
      userId: value?.userId ?? "",
      firstName: value?.firstName ?? "",
      lastName: value?.lastName ?? "",
    });
    setFormValue({
      ...formValue,
      employeeName: `${value?.firstName} ${value?.lastName}`,
      userId: value?.userId,
    });
  };

  const [isUtilityDisplay, setIsUtilityDisplay] = useState(true);
  const [utilityList, setUtilityList] = useState([
    {
      utilityPropertyDamaged: null,
      location: "",
      locationDetail: "",
      damageTicket: "",
      locateTicket: "",
      validFrom: getFormatedDate(new Date()),
      validTo: getFormatedDate(new Date()),
      typeOfUtility: [],
      typeOfUtilityLine: [],
      wereUtilityLines: [],
      id: Date.now() + Math.floor(Math.random() * 100),
      isErrorUtilityPropertyDamaged: false,
      isErrorTypeOfUtility: false,
      isErrorTypeOfUtilityLine: false,
      typeOfMachine: "",
      utilityId: 0,
    },
  ]);

  const handleOnclickUtility = () => {
    setIsUtilityDisplay(true);
    if (utilityList.length < 4) {
      const newutilityList = [...utilityList];
      newutilityList.push({
        utilityPropertyDamaged: null,
        location: "",
        locationDetail: "",
        damageTicket: "",
        locateTicket: "",
        validFrom: getFormatedDate(new Date()),
        validTo: getFormatedDate(new Date()),
        typeOfUtility: [],
        typeOfUtilityLine: [],
        wereUtilityLines: [],
        id: Date.now() + Math.floor(Math.random() * 100),
        isErrorUtilityPropertyDamaged: false,
        isErrorTypeOfUtility: false,
        isErrorTypeOfUtilityLine: false,
        typeOfMachine: "",
        utilityId: 0,
      });
      setUtilityList(newutilityList);
    }
  };

  const utilityOnChanges = (e, fieldUpdate, data) => {
    const name = e?.target?.name;
    const elementId = e?.target?.id;
    const value = e?.target?.value;
    let updatedutilityList = [...utilityList];
    updatedutilityList.forEach((utilityList) => {
      if (utilityList.id == data.id) {
        if (fieldUpdate === "dataFrom") {
          utilityList.validFrom = getFormatedDate(e);
        }
        if (fieldUpdate === "dateTo") {
          utilityList.validTo = getFormatedDate(e);
        }
        if (name === "utilityPropertyDamaged") {
          utilityList.utilityPropertyDamaged = value;
        }
        if (name === "typeOfUtility") {
          utilityList.typeOfUtility =
            typeof value === "string" ? value.split(",") : value;
        }
        if (name === "typeOfUtilityLine") {
          utilityList.typeOfUtilityLine =
            typeof value === "string" ? value.split(",") : value;
        }
        if (name === "wereUtilityLines") {
          utilityList.wereUtilityLines =
            typeof value === "string" ? value.split(",") : value;
        }
        if (elementId === "location") {
          utilityList.location = value;
        } else if (elementId === "locationDetail") {
          utilityList.locationDetail = value;
        } else if (elementId === "damageTicket") {
          utilityList.damageTicket = value;
        } else if (elementId === "locateTicket") {
          utilityList.locateTicket = value;
        } else if (elementId === "typeOfMachine") {
          utilityList.typeOfMachine = value;
        }
      }
    });
    setUtilityList(updatedutilityList);
  };

  const cancelUtilityList = (id) => {
    const removeUtilityList = utilityList.filter(
      (utility) => utility.id !== id
    );
    setUtilityList(removeUtilityList);
  };

  const handleChangeCreateActionDesc = (value, data) => {
    let updatedActionList = actionList;
    updatedActionList.forEach((singleValue) => {
      if (singleValue.id === data.id) {
        singleValue[`description`] = value;
      }
    });
    setActionlist(updatedActionList);
  };

  const handleOnclickAddAction = () => {
    if (actionList.length < 4) {
      const newActionList = [...actionList];
      newActionList.push({
        assignedPartyName: "",
        file: null,
        selectedFileName: "",
        description: "",
        id: Date.now() + Math.floor(Math.random() * 100),
        isCompleted: false,
        isError: false,
        isFileError: false,
      });
      setActionlist(newActionList);
    }
  };

  const handleCreateActionCancelCta = (data) => {
    const newActionList = actionList.filter(
      (singleAction) => data.id !== singleAction.id
    );
    setActionlist(newActionList);
  };

  const removeActionImage = (singleAction) => {
    const actionListClone = [...actionList];
    actionListClone.forEach((Actionlist) => {
      if (Actionlist?.id === singleAction?.id) {
        Actionlist.fileId = null;
        Actionlist.file = [];
        Actionlist.isFileAdded = false;
        Actionlist.selectedFileName = "";
        Actionlist.isFileError = false;
      }
    });
    setActionlist(actionListClone);
  };

  const handleAddAssigneeCta = (event, users, data) => {
    let updatedActionList = actionList;
    updatedActionList.forEach((singleValue) => {
      if (singleValue.id === data.id) {
        singleValue[`assignedPartyName`] = users?.value || {};
        singleValue[`assignedPartyId`] = users?.key || 0;
        singleValue.isError = false;
      }
    });
    setActionlist(updatedActionList);
  };
  const [selectedData, setSelectedData] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileUploaded, setSelectedFileUploaded] = useState([]);
  const handleUploadFileMethod = (fileUploaded, fileName, data) => {
    setSelectedData(data);
    setSelectedFileName(fileName);
    setSelectedFileUploaded(fileUploaded);
    uploadFile(fileUploaded);
  };

  useEffect(() => {
    if (fileUploadData) {
      let updatedActionList = [...actionList];
      updatedActionList.forEach((actionLists) => {
        if (actionLists.id === selectedData.id) {
          actionLists.file = selectedFileUploaded;
          actionLists.isFileAdded = true;
          actionLists.fileId = [fileUploadData?.files[0].id];
          actionLists.selectedFileName = selectedFileName;
          actionLists.isFileError = false;
        }
      });
      setActionlist(updatedActionList);
    }
  }, [fileUploadData]);

  useEffect(() => {
    if (countError) {
      let updatedActionList = [...actionList];
      updatedActionList.forEach((actionLists) => {
        if (actionLists.id === selectedData.id) {
          actionLists.isFileError = true;
        }
      });
      setActionlist(updatedActionList);
    }
  }, [countError]);

  const [actionList, setActionlist] = useState([
    {
      assignedPartyName: "",
      file: [],
      isFileAdded: false,
      selectedFileName: "",
      description: "",
      id: Date.now() + Math.floor(Math.random() * 100),
      isCompleted: false,
      fileId: null,
      isError: false,
      isFileError: false,
    },
  ]);

  function findValueMultipleSelect(arrrayofObject, selectedArray) {
    let valueArray = [];
    arrrayofObject.forEach((data) => {
      if (selectedArray.indexOf(data.value) >= 0) {
        valueArray.push(data.key);
      }
    });
    return valueArray;
  }

  const [isEmployeeName, setIsEmployeeName] = useState(false);

  function mappedData(isSaveAsDraft) {
    let errors = false;
    if (!isSaveAsDraft) {
      if (!formValue.employeeName) {
        setIsEmployeeName(true);
        errors = true;
      }
      if (!formValue.jobSite && !formValue.supervisor) {
        setFormValue({
          ...formValue,
          jobSiteProjectNameError: true,
          supervisorError: true,
        });
      } else {
        if (!formValue.jobSite) {
          setFormValue({ ...formValue, jobSiteProjectNameError: true });
          errors = true;
        }

        if (!formValue.supervisor) {
          setFormValue({ ...formValue, supervisorError: true });
          errors = true;
        }
      }
      let cloneActionList = [...actionList];
      cloneActionList.forEach((items) => {
        if (items.description || items.isFileAdded) {
          if (!items.assignedPartyId) {
            items.isError = true;
            errors = true;
          } else {
            items.isError = false;
          }
        }
      });
      let updatedutilityList = [...utilityList];
      updatedutilityList?.forEach((data) => {
        if (!data.utilityPropertyDamaged) {
          data.isErrorUtilityPropertyDamaged = true;
          errors = true;
        }
        if (data.typeOfUtility.length === 0) {
          data.isErrorTypeOfUtility = true;
          errors = true;
        }
        if (data.typeOfUtilityLine.length === 0) {
          data.isErrorTypeOfUtilityLine = true;
          errors = true;
        }
      });
      setUtilityList(updatedutilityList);

      if (errors) {
        window.scrollTo({
          top: 1050,
          left: 0,
          behavior: "smooth",
        });
        return false;
      }
    }
    const associatedIncidentTypeData = [];
    associatedIncidentTypes.forEach((data) => {
      if (data.isSelected === true) {
        associatedIncidentTypeData.push(data.key);
      }
    });

    const utilityListData = [];
    utilityList.forEach((data, index) => {
      utilityListData.push({
        componentId: editMode ? data.componentId : "0",
        utilityId: data.utilityId,
        utilityPropertyId: data?.utilityPropertyDamaged,
        typeOfMachinery: data?.typeOfMachine,
        locationDetail: data?.locationDetail,
        addLocation: data?.location,
        locateTicket: data?.locateTicket,
        damageTicket: data?.damageTicket,
        validFrom: data?.validFrom, //  "2023-03-06",
        validTo: data?.validTo, //"2023-03-20",
        typeOfUtility: findValueMultipleSelect(
          typeOfUtility,
          data?.typeOfUtility
        ),
        udUtilityDamage: findValueMultipleSelect(
          typeOfUtilityLineDamage,
          data?.typeOfUtilityLine
        ),
        udUtilityLine: findValueMultipleSelect(
          wereUtilityLines,
          data.wereUtilityLines
        ),
      });
    });
    const actionListData = [];
    actionList.forEach((data) => {
      actionListData.push({
        assignedPartyId: data.assignedPartyId,
        isFileAdded: data.isFileAdded,
        files: data.fileId,
        description: data.description,
      });
    });
    const filterActionData = actionListData?.filter(
      (item) => item?.assignedPartyId !== undefined
    );
    const data = {
      incidentId: incidentId,
      caseId: editMode ? caseId : caseId,
      status: INCIDENT_STATUS.SUBMITTED,
      createdBy: parseInt(userId),
      orgId: ORG_ID,
      actions: filterActionData?.length > 0 ? filterActionData : [],
      utilityDamagedPrm: {
        userId: formValue.userId,
        supervisorId: formValue.supervisor,
        projectId: formValue.jobSite,
        additionalDetails: formValue.addDetails,
        utilityPrm: utilityListData.length > 0 ? utilityListData : [],
      },
    };
    return data;
  }
  const [isSubmit, setIsSubmit] = useState(false);
  const submitData = () => {
    const data = mappedData(false);
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const saveData = () => {
    const data = mappedData(true);
    data.status = INCIDENT_STATUS.DRAFT;
    setIsSubmit(true);
    onSubmit(data);
  };

  useEffect(() => {
    if (assetOnUserList) {
      setJobSiteData(assetOnUserList);
      //setDisableEmpName(false);
    }
  }, [assetOnUserList]);

  const searchUsersByProject = (type, text) => {
    searchAllUsers(type, text);
  };

  useEffect(() => {
    if (
      employeeNameValue !== undefined &&
      employeeNameValue !== null &&
      employeeNameValue !== 0 &&
      employeeNameValue !== "" &&
      employeeNameValue.userId !== ""
    ) {
      getAssetOnUser(employeeNameValue);
    }
  }, [employeeNameValue]);

  useEffect(() => {
    setSupervisorData(supervisorOnAssetList);
  }, [supervisorOnAssetList]);

  return (
    <>
      <div className="overidePersonal">
        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <>
              <FormLabels label="Foreman Name" isRequired={true} />

              <SearchAutoComplete
                optionsData={isLoading ? [] : searchOption?.users ?? []}
                isUserDetails={true}
                value={employeeNameValue}
                isLoading={isLoading}
                typeOnchange={(event) => {
                  setSeachText(event.target.value);
                }}
                onChange={(event, value) => handleEmployeeName(event, value)}
                isError={isEmployeeName}
                errorMsg={
                  isEmployeeName && !formValue.employeeName
                    ? "Selection is required for this field ."
                    : ""
                }
              />
            </>
          </Grid>

          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormLabels
              label={intl.get("incident.utilityDamage.jobsiteName")}
              isRequired={true}
            />
            <SelectMenu
              isError={formValue.jobSiteProjectNameError}
              listData={jobSiteData}
              value={formValue.jobSite}
              disabled={disableEmpName}
              onchangehandler={(e) => {
                setFormValue({ ...formValue, jobSite: e.target.value });
                getSupervisorOnAsset(e.target.value);
              }}
              placeholder={"Please Select"}
            />
          </Grid>
        </Grid>
        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormLabels
              label={intl.get("incident.utilityDamage.supervisorName")}
              isRequired={true}
            />
            <SelectMenu
              placeholder={"Please Select"}
              listData={supervisorData}
              value={formValue.supervisor}
              disabled={disableEmpName}
              isError={formValue.supervisorError}
              onchangehandler={(e) =>
                setFormValue({ ...formValue, supervisor: e.target.value })
              }
            />
          </Grid>
        </Grid>

        {isUtilityDisplay &&
          utilityList.map((data, index) => (
            <Utility
              key={index}
              utilityPropertyDamaged={data.utilityPropertyDamaged}
              nameOfDriveValue={data.nameOfDerive}
              typeOfUtilityData={typeOfUtility}
              typeOfUtilityValue={data.typeOfUtility}
              typeOfUtilityLine={data.typeOfUtilityLine}
              location={data.location}
              locationDetail={data.locationDetail}
              utilityPropertyDamage={utilityPropertyDamaged}
              typeOfUtilityPropertyDamage={typeOfUtilityLineDamage}
              wereUtilityDamage={wereUtilityLines}
              locateTicket={data.locateTicket}
              damageTicket={data.damageTicket}
              validFrom={data.validFrom}
              keyNo={index + 1}
              validTo={data.validTo}
              typeOfMachine={data.typeOfMachine}
              wereUtilityLines={data.wereUtilityLines}
              utilityOnChanges={(e, fieldUpdate) =>
                utilityOnChanges(e, fieldUpdate, data)
              }
              utilityListLength={data.id}
              numberOfUtility={index}
              isErrorUtilityPropertyDamaged={data.isErrorUtilityPropertyDamaged}
              isErrorTypeOfUtility={data.isErrorTypeOfUtility}
              isErrorTypeOfUtilityLine={data.isErrorTypeOfUtilityLine}
              cancelUtilityList={(id) => cancelUtilityList(id)}
            />
          ))}
        {isUtilityDisplay && (
          <Grid container>
            <Grid
              item
              lg={5}
              xs={12}
              sm={12}
              md={5}
              textAlign="left"
              mb={3}
              mt={2}
            >
              <div className="varientText">
                <Button
                  varientTextGreen={true}
                  disabled={utilityList.length === 4 ? true : false}
                  label={intl.get("incident.utilityDamage.addUtilityDamage")}
                  onClick={handleOnclickUtility}
                ></Button>
              </div>
            </Grid>
          </Grid>
        )}

        {actionList.map((singleAction, index) => (
          <CreateAction
            key={index}
            singleAction={singleAction}
            handleChangeActionDescription={handleChangeCreateActionDesc}
            handleAddAssigneeCta={handleAddAssigneeCta}
            handleUploadFile={handleUploadFileMethod}
            handleCancelCta={handleCreateActionCancelCta}
            incidentId={utilityDamageData?.incidentId}
            optionsData={allUsers?.users ?? []}
            searchUsers={searchUsersByProject}
            isLoading={isLoading}
            isError={singleAction.isError}
            removeActionImage={removeActionImage}
            fileError={fileError}
          />
        ))}
        <div className="addBox">
          <Button
            varientTextGreen={true}
            disabled={actionList.length === 4 ? true : false}
            label={intl.get("incident.case.addAction")}
            onClick={handleOnclickAddAction}
          />
        </div>
        <Grid container mt={3}>
          <Grid item lg={8} xs={12} sm={12} md={8}>
            <FormLabels label={intl.get("incident.case.addAdditinalDetails")} />
            <TextEditor
              placeholder={"Add Additional Details Text Here..."}
              value={formValue.addDetails}
              onChange={(value) =>
                setFormValue({ ...formValue, addDetails: value })
              }
            />
          </Grid>
        </Grid>

        <Grid container mt={3} spacing={10} alignItems="center">
          <Grid lg={4} xs={12} sm={6} md={4} textAlign="center">
            <Button
              varientText={true}
              label={intl.get("incident.case.saveAsDraft")}
              disabled={isSubmit ? true : false}
              onClick={saveData}
            />
          </Grid>
          <Grid lg={4} xs={12} sm={6} md={4} textAlign="right">
            <Button
              varientContained={true}
              label={intl.get("incident.case.submit")}
              disabled={isSubmit ? true : false}
              onClick={submitData}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default UtilityDamageForm;
