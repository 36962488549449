import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import commonImages from "../../../../assets";
import Typography from "@mui/material/Typography";
import "./courseDetail.scss";
import Button from "../../../../library/custom/button/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { USERCOURSES, COURSES } from "../../../../constant/routeContant";
import TabPanel from "../tabPanel/tabPanel";
import Loader from "../../../../library/common/Loader";
import Popover from "@mui/material/Popover";
import RadioGroup from "@mui/material/RadioGroup";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import {
  ORG_ID,
  API_STATUS,
  USER_ID,
  USER_TYPE,
} from "../../../../constant/constant";
import { useDispatch } from "react-redux";
import Buttons from "../../../../library/custom/button/Button";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Checkbox from "../../../../library/checkbox/checkbox";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const CourseDetail = (props) => {
  const dispatch = useDispatch();
  const courseData = props?.viewData?.result;
  const courseid = courseData?.courseDetails?.id;
  const lessonData = props?.userLessonViewData?.lessons;
  const quizStatus = props?.quizStatus;
  const { viewOnClick } = props;
  const isLoading = props?.isLoading;
  const handleAllGroupSearch = props?.handleAllGroupSearch;
  const isAdminCourse = props?.isAdminCourse;
  const dataBack = {
    title: "Back to Course",
    route: isAdminCourse ? COURSES : USERCOURSES,
    iscourseNavigatePage: false,
  };
  const onSearch = props?.onSearch;
  const allUsers = props?.allUsers;
  const userGroups = props?.userGroups;
  const assigncourseStatus = props?.assigncourseStatus;
  const errCompltemsg = props?.errCompltemsg;
  // reassign course
  const getStatusCheckCoursecompleted = props?.getStatusCheckCoursecompleted;
  const setcheckcoursestatus = props?.setcheckcoursestatus;
  const checkcoursestatus = props?.checkcoursestatus;
  const completeCourseStatusResult = props?.completeCourseStatusResult;
  const setisSubmitAssign = props?.setisSubmitAssign;
  const isSubmitAssign = props?.isSubmitAssign;
  const [usersValue, setUsersValue] = useState();
  const [usersGroupValue, setUsersGroupValue] = useState();
  const [Reassignbtndisable, setReassignbtndisable] = useState(false);
  //============================================

  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }

  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAssign, setAnchorElAssign] = useState(null);
  const [userOrGroupId, setUserOrGroupId] = useState("");
  const [statuccheck, setStatuccheck] = useState("1");
  const [isassignName, setIsassignName] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [checked, setChecked] = useState(false);

  const autoCompGroup = useRef(null);
  const autoCompUser = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.getUserLessonViews(event?.target?.id);
  };
  const handlecheckbtn = (e, value) => {
    setStatuccheck(e.target.value);
    setUserOrGroupId("");
    setIsassignName(false);
    setUsersValue({ key: "", value: "" });
    setUsersGroupValue({ key: "", value: "" });
  };

  const openAssign = Boolean(anchorElAssign);
  const open = Boolean(anchorEl);
  const idAssign = open ? "simple-popover" : undefined;
  const handleCloseAssign = () => {
    setAnchorElAssign(null);
  };
  const handleClickAssign = (event) => {
    setcheckcoursestatus(false);
    setUsersValue({ key: "", value: "" });
    setUserOrGroupId("");
    setAnchorElAssign(event.currentTarget);
    setIsSubmit(true);
    setReassignbtndisable(false);
  };

  const onChangeUsers = (e) => {
    setUsersValue({ key: e?.key ?? "", value: e?.value ?? "" });
    setUsersGroupValue({ key: e?.key ?? "", value: e?.value ?? "" });
    if (e == null) {
      setUserOrGroupId("");
    } else {
      // setUsersValue({ key: "", value: "" });
      // setUsersGroupValue({ key: "", value: "" });
      setUserOrGroupId(e.key);
      setIsassignName(false);
    }
  };

  function mappedData() {
    let errors = false;
    if (userOrGroupId?.length === 0) {
      setIsassignName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    let data;
    data = {
      userType: statuccheck,
      courseId: courseid,
      userOrGroupId: userOrGroupId,
      createdBy: parseInt(USER_ID()),
      orgId: parseInt(ORG_ID()),
    };
    return data;
  }
  useEffect(() => {
    if (assigncourseStatus === API_STATUS.SUCCESS) {
      setIsSubmit(true);
      setTimeout(() => {
        setAnchorElAssign(null);
      }, 2000);
    }
  }, [assigncourseStatus]);

  const handleClickAdd = (e) => {
    setisSubmitAssign(true);
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(false);
      if (statuccheck === "0") {
        getStatusCheckCoursecompleted(data);
      } else if (statuccheck === "1") {
        dispatch(lmsAction.assignCourse(data));
      }

      // if (statuccheck === "1") {
      //   const ele = autoCompGroup.current.getElementsByClassName(
      //     "MuiAutocomplete-clearIndicator"
      //   )[0];
      //   if (ele) ele.click();
      // } else {
      //   const ele = autoCompUser.current.getElementsByClassName(
      //     "MuiAutocomplete-clearIndicator"
      //   )[0];
      //   if (ele) ele.click();
      // }
    }
  };
  const handleClickAddReassign = (e) => {
    setReassignbtndisable(true);
    let data;
    data = {
      userType: parseInt(statuccheck),
      courseId: courseid,
      userOrGroupId: userOrGroupId,
      createdBy: USER_ID(),
      orgId: ORG_ID(),
      reAssign: true,
    };
    dispatch(lmsAction.assignCourse(data));
  };
  const completeLesson = () => {
    const data = {
      userId: USER_ID(),
      lessonId: lessonData?.id,
      courseId: courseid,
      orgId: ORG_ID(),
    };
    dispatch(lmsAction.completeLesson(data));
  };
  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    if (
      checkcoursestatus === false &&
      props?.completeCourseStatusStatus === API_STATUS.SUCCESS &&
      props?.completeCourseStatusResult?.length === 0 &&
      isSubmitAssign
    ) {
      const data = mappedData();
      if (data) {
        data.reAssign = false;
      }

      dispatch(lmsAction.assignCourse(data));
    }
  }, [props?.completeCourseStatusResult, props?.completeCourseStatusStatus]);
  const cancelreassign = (e) => {
    setAnchorElAssign(null);
    setcheckcoursestatus(false);
  };
  return (
    <>
      <BackBtn dataBack={dataBack} />
      <Grid container className="courseView">
        <Grid item xs={12}>
          <Grid className="courseImage">
            <img
              key={courseData?.courseDetails.file?.id}
              src={
                courseData?.courseDetails.file?.path
                  ? courseData?.courseDetails.file?.path
                  : commonImages?.noimagePublish
              }
              alt=""
            />
          </Grid>
          <Grid container>
            <Grid className="imageDetail" md={8}>
              <Typography className="imageDetailContent">
                <span>
                  <img src={commonImages?.category} alt="category" />
                </span>
                {courseData?.courseDetails?.category}
              </Typography>
              {checkPrivileges([12, 11, 10]) ? (
                <>
                  <Typography className="imageDetailContent">
                    <span>
                      <img src={commonImages?.group} alt="category" />
                    </span>
                    Assigned{" "}
                    <span className="count">
                      ({courseData?.courseDetails?.totaleAssignedToUser})
                    </span>
                  </Typography>
                  <Typography className="imageDetailContent">
                    <span>
                      <img
                        src={commonImages?.assignmentturnedin}
                        alt="category"
                      />
                    </span>
                    Completed{" "}
                    <span className="count">
                      ({courseData?.courseDetails?.totalCompletedByUser})
                    </span>
                  </Typography>
                </>
              ) : (
                ""
              )}
              <Typography className="imageDetailContent">
                <span>
                  <img src={commonImages?.alarm} alt="alarm" />
                </span>
                {`${courseData?.courseDetails?.completionTimeHr}hr ${courseData?.courseDetails?.completionTimeMin}min`}
              </Typography>
            </Grid>
            <Grid md={4} xs={12}>
              {checkPrivileges([12, 11, 10]) &&
                courseData?.courseDetails?.statusId === 1 &&
                isAdminCourse && (
                  <div className="assignBox">
                    <>
                      <Buttons
                        type="button"
                        name="btn"
                        label={"Assign"}
                        primaryassign={true}
                        onClick={handleClickAssign}
                      ></Buttons>

                      <Popover
                        id={idAssign}
                        open={openAssign}
                        anchorEl={anchorElAssign}
                        onClose={handleCloseAssign}
                        className="assignPopBox"
                        anchorOrigin={{
                          vertical: "right",
                          horizontal: "bottom",
                        }}
                        transformOrigin={{
                          vertical: "right",
                          horizontal: "right",
                        }}
                      >
                        <div className="assignPopDetail">
                          <div className="title">User Type</div>
                          <div className="innerauto">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              gap={5}
                              className="radioGroup"
                            >
                              <RadioButton
                                checked={statuccheck === "1" ? true : false}
                                value="1"
                                label="Group"
                                disabled={false}
                                onChange={(e, value) =>
                                  handlecheckbtn(e, value)
                                }
                              ></RadioButton>

                              <RadioButton
                                checked={statuccheck === "0" ? true : false}
                                value="0"
                                label="Individual"
                                disabled={false}
                                onChange={(e, value) =>
                                  handlecheckbtn(e, value)
                                }
                              ></RadioButton>
                            </RadioGroup>

                            {statuccheck === "1" ? (
                              <>
                                <div className="searchauto">
                                  <SearchAutoComplete
                                    isKeyValuePair={true}
                                    optionsData={
                                      isLoading ? [] : userGroups ?? []
                                    }
                                    typeOnchange={(event, value) =>
                                      handleAllGroupSearch(
                                        event.target.value,
                                        0,
                                        true
                                      )
                                    }
                                    isLoading={isLoading}
                                    onChange={(e, value) =>
                                      onChangeUsers(value)
                                    }
                                    autoCompClear={autoCompGroup}
                                  />
                                </div>
                                {isassignName ? (
                                  <div className="show-error">
                                    Please enter this required field
                                  </div>
                                ) : (
                                  ""
                                )}
                                <Grid
                                  container
                                  lg={12}
                                  mt={2}
                                  className="individualbtn"
                                >
                                  <Buttons
                                    variant="contained"
                                    type="button"
                                    name="btn"
                                    label={"Assign"}
                                    varientContained={true}
                                    onClick={(e) => handleClickAdd(e)}
                                    disabled={isSubmit ? false : true}
                                  ></Buttons>
                                </Grid>
                                {props.assignmsg ? (
                                  <div className="success">
                                    Course Assign successfully
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <>
                                <SearchAutoComplete
                                  optionsData={
                                    props?.AlluserisLoading
                                      ? []
                                      : allUsers ?? []
                                  }
                                  isKeyValuePair={true}
                                  typeOnchange={(event, value) =>
                                    onSearch(
                                      event.target.value,
                                      USER_TYPE.NONPRIVILEGED
                                    )
                                  }
                                  isLoading={props?.AlluserisLoading}
                                  onChange={(e, value) => onChangeUsers(value)}
                                  autoCompClear={autoCompUser}
                                  value={usersValue ? usersValue : ""}
                                />
                                {isassignName ? (
                                  <div className="show-error">
                                    Please enter this required field
                                  </div>
                                ) : (
                                  ""
                                )}
                                {checkcoursestatus === true &&
                                completeCourseStatusResult?.length > 0 ? (
                                  <div className="errorMsg">
                                    This course has been completed by the
                                    assigned user. Do you want to re-assign this
                                    course ?
                                  </div>
                                ) : (
                                  ""
                                )}

                                <Grid
                                  container
                                  lg={12}
                                  mt={2}
                                  className="individualbtn"
                                >
                                  {checkcoursestatus === false && (
                                    <Buttons
                                      variant="contained"
                                      type="button"
                                      name="btn"
                                      label={"Assign"}
                                      varientContained={true}
                                      onClick={(e) => handleClickAdd(e)}
                                      disabled={isSubmit ? false : true}
                                    ></Buttons>
                                  )}
                                  {checkcoursestatus && (
                                    <>
                                      {" "}
                                      <Buttons
                                        varientText={true}
                                        label="Cancel"
                                        onClick={(e) => cancelreassign(e)}
                                      />
                                      <Buttons
                                        variant="contained"
                                        type="button"
                                        name="btn"
                                        label={"Apply"}
                                        varientContained={true}
                                        onClick={(e) =>
                                          handleClickAddReassign(e)
                                        }
                                        disabled={
                                          Reassignbtndisable ? true : false
                                        }
                                      ></Buttons>
                                    </>
                                  )}
                                </Grid>

                                {props.assignmsg ? (
                                  <div className="success">
                                    Course Assign successfully
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                      </Popover>
                    </>
                  </div>
                )}
            </Grid>
          </Grid>
          <Grid>
            <Grid className="title">{courseData?.courseDetails?.course}</Grid>
            <Grid className="otherDescration">
              <Grid className="subTitle">Description</Grid>
              <Grid className="desc">
                {courseData?.courseDetails?.description}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="lessonwithDetail">
          <Grid item xs={12} md={3} sm={12} className="lessonListBox">
            <Tabs orientation="vertical" value={value} onChange={handleChange}>
              {courseData?.lessonList?.map((tabItem, i) => {
                return (
                  <Tab
                    className="lessonList"
                    label={`${"Lesson"} ${i + 1}`}
                    icon={
                      tabItem?.isLessonCompleted ? (
                        <div className="totalV">
                          <img src={commonImages?.checkcircle} alt="icon" />
                        </div>
                      ) : (
                        ""
                      )
                    }
                    iconPosition="end"
                    key={i}
                    {...a11yProps(i, tabItem?.lessonId)}
                  />
                );
              })}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={9} className="lessonwithDetailBox">
            {courseData?.lessonList?.map((tabPanel, i) => {
              return (
                <TabPanel value={value} key={tabPanel?.lessonId} index={i}>
                  {props.showLoader && <Loader />}
                  {props.isSuccess && (
                    <>
                      <Grid container className="lessonView">
                        <Grid key={i} xs={12}>
                          <Grid item xs={12}>
                            <Grid className="lessonImage">
                              <img
                                key={lessonData?.file?.id}
                                src={
                                  lessonData?.file?.path
                                    ? lessonData?.file?.path
                                    : commonImages?.noimagePublish
                                }
                                alt=""
                              />
                            </Grid>
                            <Grid className="imageDetail">
                              <Typography className="imageDetailContent">
                                <span>
                                  <img
                                    src={commonImages?.category}
                                    alt="category"
                                  />
                                </span>
                                {lessonData?.category}
                              </Typography>
                              {checkPrivileges([12, 11, 10]) ? (
                                <>
                                  <Typography className="imageDetailContent">
                                    <span>
                                      <img
                                        src={commonImages?.group}
                                        alt="category"
                                      />
                                    </span>
                                    Assigned{" "}
                                    <span className="count">
                                      ({lessonData?.totaleAssignedToUser})
                                    </span>
                                  </Typography>
                                  <Typography className="imageDetailContent">
                                    <span>
                                      <img
                                        src={commonImages?.assignmentturnedin}
                                        alt="category"
                                      />
                                    </span>
                                    Completed{" "}
                                    <span className="count">
                                      ({lessonData?.totalCompletedByUser})
                                    </span>
                                  </Typography>{" "}
                                </>
                              ) : (
                                ""
                              )}
                              <Typography className="imageDetailContent">
                                <span>
                                  <img src={commonImages?.alarm} alt="alarm" />
                                </span>
                                {`${lessonData?.completionTimeHr}${"hr"} ${
                                  lessonData?.completionTimeMin
                                }${"min"}`}
                              </Typography>
                            </Grid>

                            <Grid>
                              <Grid className="title">
                                {lessonData?.lesson}
                              </Grid>
                              <Grid className="otherDescration">
                                <Grid className="subTitle">Description</Grid>
                                <Grid className="desc">{lessonData?.text}</Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container className="lessonwithDetail">
                            {lessonData?.sections?.map((lessonSection, i) => {
                              const document =
                                lessonSection?.document?.split("/");
                              const documentName =
                                document?.length > 0 &&
                                document.slice(-1).pop();

                              return (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className="lessonwithDetailBox"
                                  key={i}
                                >
                                  {lessonSection?.filesImg?.length > 0 && (
                                    <Grid className="lessonImage">
                                      {lessonSection?.filesImg?.map(
                                        (lessonImg) => {
                                          return (
                                            <img
                                              key={lessonImg?.id}
                                              src={lessonImg?.path}
                                              alt=""
                                            />
                                          );
                                        }
                                      )}
                                    </Grid>
                                  )}

                                  <Grid className="otherDescration">
                                    <Grid className="desc">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: lessonSection?.text,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {lessonSection?.videoLink?.length > 0 && (
                                    <Grid className="otherDescration">
                                      <Grid className="subTitle">
                                        {/* Safety Report Guidelines 101.mp4 */}
                                      </Grid>

                                      <Grid className="lessonImage">
                                        <iframe
                                          title={lessonSection?.videoLink}
                                          className="videoLink"
                                          src={lessonSection?.videoLink}
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        ></iframe>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {lessonSection?.filesDoc?.length > 0 && (
                                    <Grid className="otherDescration">
                                      <Grid className="subTitle">
                                        {/* Safety Report Guidelines 101 */}
                                      </Grid>
                                      <Grid className="pdfAttached">
                                        <span>
                                          {lessonSection?.filesDoc.map(
                                            (item, index) => {
                                              return (
                                                <a
                                                  href={item?.path}
                                                  target="_blank"
                                                  key={index}
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    src={
                                                      commonImages?.attachment
                                                    }
                                                    alt=""
                                                  />
                                                  Download PDF
                                                </a>
                                              );
                                            }
                                          )}
                                        </span>
                                        <span className="pdfAttachedName">
                                          {documentName}
                                        </span>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              );
                            })}

                            <Grid
                              item
                              xs={12}
                              md={12}
                              className="lessonwithDetailBox"
                            >
                              <Grid container className="takeQuiz">
                                {lessonData?.quizId > 0 && (
                                  <Button
                                    varientContained={true}
                                    label="Take Quiz"
                                    disabled={
                                      quizStatus || lessonData?.quizId <= 0
                                        ? true
                                        : false
                                    }
                                    onClick={() =>
                                      viewOnClick(
                                        lessonData?.quizId,
                                        lessonData?.id,
                                        courseid
                                      )
                                    }
                                  />
                                )}
                                {lessonData?.quizId > 0 && !isAdminCourse ? (
                                  <div className="quizstartDiv">
                                    {quizStatus ? (
                                      <span className="quizstatuspass">
                                        Passed
                                      </span>
                                    ) : quizStatus === false ? (
                                      <span className="quizstatusfail">
                                        Failed
                                      </span>
                                    ) : (
                                      false
                                    )}
                                  </div>
                                ) : null}
                              </Grid>
                              {lessonData?.quizId === 0 &&
                              lessonData?.isLessonCompeted === true ? (
                                ""
                              ) : lessonData?.quizId === 0 && !isAdminCourse ? (
                                <>
                                  <Grid className="checkingBox">
                                    <FormControlLabel
                                      className="checkboxs"
                                      control={
                                        <Checkbox
                                          onChange={handleCheckedChange}
                                          checked={checked}
                                          disabled={
                                            quizStatus == null ||
                                            quizStatus === false
                                              ? false
                                              : true
                                          }
                                          label="I confirm that I have successfully completed the lesson and taken the quiz."
                                        />
                                      }
                                    />
                                  </Grid>
                                  <Grid className="Btncomplete">
                                    <Button
                                      varientContained={true}
                                      onClick={completeLesson}
                                      label="Complete"
                                      disabled={checked ? false : true}
                                    />
                                  </Grid>
                                </>
                              ) : (
                                ""
                              )}

                              {errCompltemsg ? (
                                <div className="show-error">
                                  {errCompltemsg}
                                </div>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </TabPanel>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CourseDetail;
