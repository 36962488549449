import React, { useEffect, useState } from "react";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { InputField } from "../../../../library/custom/textBox/InputField";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import TImePicker from "../../../../library/custom/timePicker/TImePicker";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import TextArea from "../../../../library/custom/textArea/TextArea";
import Button from "../../../../library/custom/button/Button";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import "./reprimandForm.scss";
import {
  postReprimand,
  updateReprimand,
} from "../../../../redux/actions/reprimandAction";
import {
  InvolvedPartyOptionData,
  actionTakenOptionData,
} from "../../../../constant/inspectionConstant";
import { formValidation } from "../../../../utils/incpectionHelper";
import {
  getFormatedDate,
  getCurrentMinuteSecond,
  convertTime,
} from "../../../../utils/helper";
import { useSelector, useDispatch } from "react-redux";
import SuccessScreen from "../../../../library/custom/success/successScreen";
import { useLocation } from "react-router-dom";
import { REPRIMAND_LIST } from "../../../../constant/routeContant";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { GET_USER_TYPE, ORG_ID } from "../../../../constant/constant";
import Checkbox from "../../../../library/checkbox/checkbox";

const ReprimandForm = ({
  searchOption,
  searchUsers,
  isAutoSearchDataLoading,
  getAssetOnUser,
  assetOnUserList,
  getSupervisorOnAsset,
  supervisorOnAssetList,
}) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.data);
  const location = useLocation();
  const { pathname } = location;
  const edit = pathname.split("/")[2] ?? null;
  const { isSuccess, editReprimandData, successData } = useSelector(
    (state) => state.reprimand
  );

  const employeesData = React.useMemo(() => {
    return (
      users &&
      users?.users?.map((item) => {
        const items = { ...item };
        items.label = `${item.firstName} ${item.lastName}`;
        return items;
      })
    );
  }, [users]);

  const [formValue, setFormValue] = useState({
    dateOfInspection: new Date(),
    timeOfInspection: getCurrentMinuteSecond(),
    location: "",
    jobSiteProjectName: "",
    involvedPartyType: "",
    addInvolvedParty: "",
    addCorrectingParty: "",
    addCorrectingPartyId: "",
    actionTaken: "",
    addActionTaken: "",
    closeItem: false,
    addAbadementNotes: "",
    issuefound: "",
  });
  const [errors, setErrors] = useState({});
  const [supervisorData, setSupervisorData] = useState([]);
  const [jobSiteOptions, setJobSiteOptions] = useState(null);
  const [errorEnvPartyType, setErrorEnvPartyType] = useState(false);
  const [errorActionTaken, setErrorActionTaken] = useState(false);

  const handleEmployeeName = (e, value) => {
    setJobSiteOptions([]);

    setSupervisorData([]);
    setFormValue({
      ...formValue,
      addInvolvedParty: value,
      addCorrectingParty: "",
      jobSiteProjectName: "",
    });
  };

  useEffect(() => {
    if (
      formValue.addInvolvedParty !== undefined &&
      formValue.addInvolvedParty !== null &&
      formValue.addInvolvedParty !== 0 &&
      formValue.addInvolvedParty !== "" &&
      formValue.addInvolvedParty.userId !== ""
    ) {
      getAssetOnUser(formValue.addInvolvedParty);
    }
  }, [formValue.addInvolvedParty]);
  const formHandler = (event, action) => {
    const userId = localStorage.getItem("userId");
    const formData = {
      status: 0,
      preparedBy: userId,
      inspectionDate: getFormatedDate(formValue.dateOfInspection) ?? null,
      inspectionTime: convertTime(formValue.timeOfInspection) ?? null,
      location: formValue?.location ?? null,
      assetId: formValue?.jobSiteProjectName ?? null,
      comment: null,
      involvedPartyType:
        (formValue?.involvedPartyType !== null &&
          formValue?.involvedPartyType) ??
        null,
      issue: formValue?.issuefound ?? null,
      isActionTaken: formValue?.actionTaken === 0 ? true : false,
      actionTaken: formValue?.addActionTaken ?? null,
      abatementNotes: formValue?.addAbadementNotes ?? "",
      involvedParty: `${formValue?.addInvolvedParty.userId}`,
      correctingParty: formValue?.addCorrectingParty ?? null,
      // ...(edit && { ReprimandsDtlId: editReprimandData?.reprimandDtlId ?? "" }),
      // status: action === "draft" || action === "update_draft" ? 1 : 2,
      // PreparedBy: (userId && parseInt(userId)) ?? null,
      // inspectionDate: getFormatedDate(formValue.dateOfInspection) ?? null,
      // inspectionTime: convertTime(formValue.timeOfInspection) ?? null,
      // location: formValue?.location ?? null,
      // jobSite: formValue?.jobSiteProjectName ?? null,
      // weather: null,
      // comment: null,
      // createdBy: (userId && parseInt(userId)) ?? null,
      // organisationId: ORG_ID(),
      // reprimands: {
      //   ...(edit && {
      //     ReprimandsDtlId: editReprimandData?.reprimandDtlId ?? "",
      //   }),
      //   kudosEmpNameId: null,
      //   comment: null,
      //   correctiveIssueDtl: formValue?.issuefound ?? null,
      //   actionTaken: formValue?.actionTaken === 0 ? true : false,
      //   abatementNotes: formValue?.addAbadementNotes ?? "",
      //   escalateReprimand: true,
      //   actionTakenDtl: formValue?.addActionTaken ?? null,
      //   closeItem: formValue?.closeItem ?? false,
      //   createdBy: (userId && parseInt(userId)) ?? null,
      //   reprimandsinvemp: [
      //     {
      //       employeeType:
      //         (formValue?.involvedPartyType !== null &&
      //           formValue?.involvedPartyType + 1) ??
      //         null,
      //       involvedEmp: `${formValue?.addInvolvedParty.userId}`,
      //       correctingEmp:
      //         (formValue?.addCorrectingParty &&
      //           formValue?.addCorrectingParty) ??
      //         null,
      //       createdBy: (userId && parseInt(userId)) ?? null,
      //     },
      //   ],
      // },
    };

    const { commonFormValid, errorKey } = formValidation(
      {
        dateOfInspection: formValue.dateOfInspection,
        timeOfInspection: formValue.timeOfInspection,
        location: formValue.location,
        jobSiteProjectName: formValue.jobSiteProjectName,
        issuefound: formValue.issuefound,
      },
      action
    );
    setErrors(errorKey);
    if (formValue.involvedPartyType === "") {
      setErrorEnvPartyType(true);
    }
    if (formValue.actionTaken === "") {
      setErrorActionTaken(true);
    }
    if (
      commonFormValid &&
      !errorEnvPartyType &&
      !errorActionTaken &&
      (action === "submit" || action === "draft")
    ) {
      dispatch(postReprimand(formData));
    } else if (
      commonFormValid &&
      !errorEnvPartyType &&
      !errorActionTaken &&
      (action === "update" || action === "update_draft")
    ) {
      dispatch(updateReprimand(formData));
    }
  };
  const clearFormValue = React.useCallback(
    (editForm) => {
      const jobSite = (edit && users && users?.users[0]?.jobSite) ?? [];
      const employeesD =
        edit &&
        editForm &&
        employeesData &&
        employeesData.filter(
          (em) => em.userId === (editForm?.involvedParty ?? "")
        );
      const supervisor =
        edit &&
        editForm &&
        employeesD &&
        employeesD &&
        employeesD[0]?.supervisor;
      setSupervisorData(supervisor ?? []);
      const corrParty =
        edit &&
        editForm &&
        supervisor &&
        supervisor.filter((su) => su.key === editForm?.correctingParty ?? "");
      setFormValue({
        dateOfInspection:
          (edit && new Date(editForm?.inspectionDate)) ?? new Date(),
        timeOfInspection: getCurrentMinuteSecond(
          editForm && (editForm?.inspectionTime ?? "")
        ),
        location: (edit && editForm?.location) ?? "",
        jobSiteProjectName:
          (edit &&
            editForm?.jobSite &&
            jobSite.filter((j) => j.key === editForm?.jobSite)[0]?.key) ??
          "",
        involvedPartyType:
          (edit && editForm && editForm?.employeeType - 1) ?? null,
        addInvolvedParty:
          (edit && editForm && employeesD && employeesD[0]) ?? "",
        addCorrectingParty:
          (edit && editForm && supervisor && corrParty[0].key) ?? "",
        actionTaken: (edit && editForm?.actionTaken ? 0 : 1) ?? "",
        addActionTaken: (edit && editForm?.actionTakenDtl) ?? "",
        closeItem: (edit && editForm?.closeItem) ?? false,
        addAbadementNotes: (edit && editForm?.abatementNotes) ?? "",
        issuefound: (edit && editForm?.correctiveIssueDtl) ?? "",
      });
    },
    [users, edit, employeesData]
  );
  useEffect(() => {
    if (isSuccess) {
      clearFormValue();
    }
  }, [isSuccess, clearFormValue]);

  useEffect(() => {
    if (edit) {
      clearFormValue(editReprimandData);
    }
  }, [edit, clearFormValue, editReprimandData]);

  useEffect(() => {
    setJobSiteOptions(assetOnUserList);
  }, [assetOnUserList]);
  useEffect(() => {
    setSupervisorData(supervisorOnAssetList);
  }, [supervisorOnAssetList]);
  return (
    <>
      {isSuccess ? (
        <SuccessScreen
          id={successData?.data?.reprimandsId ?? null}
          // isUpdate={successData?.status ?? null}
          isUpdate={"Reprimand Added Successfully"}
          route={REPRIMAND_LIST}
          label1="Reprimand ID"
          label4="Reprimand List"
        />
      ) : (
        <div className="overideReprimand ">
          <div className="formContainer">
            <div className="title">
              <h1>Add Reprimand</h1>
            </div>
            <Grid container item md={9} xs={9} className="addShadowBox">
              <Grid item container lg={12} spacing={2}>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label={"Date of Reprimand"} isRequired={true} />
                    <DatePicker
                      value={formValue.dateOfInspection}
                      onChangeHandler={(e) =>
                        setFormValue({
                          ...formValue,
                          dateOfInspection: e,
                        })
                      }
                      isError={!!errors?.dateOfInspection ?? false}
                      errorMsg={errors?.dateOfInspection ?? ""}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels
                      label={"Time of Inspection"}
                      isRequired={true}
                    />
                    <TImePicker
                      disabled={false}
                      value={formValue.timeOfInspection}
                      onChangeTimeHandler={(e) =>
                        setFormValue({
                          ...formValue,
                          timeOfInspection: e.target.value,
                        })
                      }
                      isError={!!errors?.timeOfInspection ?? false}
                      errorMsg={errors?.timeOfInspection ?? ""}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container mt={1.5} lg={12} spacing={2}>
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <InputField
                      fullWidth={true}
                      type="text"
                      label="Location"
                      value={formValue.location}
                      placeholder="Location"
                      isRequired={true}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          location: e.target.value,
                        })
                      }
                      isError={!!errors?.location ?? false}
                      errorMsg={errors?.location ?? ""}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container xs={12} sm={12} md={12} lg={12} mt={3}>
                <div className="involvedPartyType">
                  <FormLabels
                    label="Involved Party Type"
                    labelPlacement="start"
                    isRequired={true}
                  />

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="radioGroup"
                  >
                    {InvolvedPartyOptionData &&
                      InvolvedPartyOptionData.map((item) => (
                        <RadioButton
                          key={item.key}
                          checked={formValue.involvedPartyType === item.key}
                          value={item.value}
                          label={item.label}
                          name={item.label}
                          onChange={(e) => {
                            setFormValue((prevState) => ({
                              ...prevState,
                              involvedPartyType: item.key,
                              addInvolvedParty: "",
                              addCorrectingParty: "",
                              jobSiteProjectName: "",
                            }));
                            setErrorEnvPartyType(false);
                            setJobSiteOptions([]);
                            setSupervisorData([]);
                          }}
                        />
                      ))}

                    {errorEnvPartyType && (
                      <p className="show-error">
                        Please Checked Involved Party Type{" "}
                      </p>
                    )}
                  </RadioGroup>
                </div>
              </Grid>

              {(formValue.involvedPartyType === 0 ||
                formValue.involvedPartyType === 1) && (
                <>
                  <Grid
                    className="inputGrid"
                    item
                    container
                    lg={12}
                    spacing={2}
                    mt={3}
                  >
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth className="selectableRadioOption">
                        <FormLabels label="Add Involved Party" />
                        <SearchAutoComplete
                          optionsData={
                            isAutoSearchDataLoading
                              ? []
                              : searchOption?.users ?? []
                          }
                          value={formValue?.addInvolvedParty ?? ""}
                          onChange={(event, value) =>
                            handleEmployeeName(event, value)
                          }
                          typeOnchange={(event) => {
                            searchUsers(
                              formValue.involvedPartyType === 1
                                ? 1
                                : GET_USER_TYPE.PREVNONPREVUSER,
                              event.target.value
                            );
                          }}
                          isUserDetails={true}
                          isLoading={isAutoSearchDataLoading}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels label="Jobsite/Project" isRequired={true} />
                        <SelectMenu
                          listData={jobSiteOptions ?? []}
                          isRequired={true}
                          value={formValue.jobSiteProjectName}
                          onchangehandler={(e) => {
                            setFormValue({
                              ...formValue,
                              jobSiteProjectName: e.target.value,
                            });
                            getSupervisorOnAsset(e.target.value);
                          }}
                          isError={!!errors?.jobSiteProjectName ?? false}
                          errorMsg={errors?.jobSiteProjectName ?? ""}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid className="inputGrid" container mt={2}>
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth className="selectableRadioOption">
                        <FormLabels label="Add Correcting Party" />
                        <SelectMenu
                          disabled={supervisorData.length === 0}
                          listData={supervisorData}
                          value={
                            supervisorData.length > 0
                              ? formValue.addCorrectingParty
                              : ""
                          }
                          onchangehandler={(e) =>
                            setFormValue({
                              ...formValue,
                              addCorrectingParty: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container mt={2} mb={3}>
                <Grid
                  item
                  textAlign="left"
                  lg={12}
                  mt={2}
                  sm={12}
                  xs={12}
                  md={12}
                >
                  <FormLabels label={"Issue Found"} isRequired={true} />
                  <TextArea
                    value={formValue.issuefound ?? ""}
                    onChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        issuefound: e.target.value,
                      }))
                    }
                    name={"issuefound"}
                    placeholder="Issue Found"
                    addMedia={false}
                    isError={!!errors?.issuefound ?? false}
                    errorMsg={errors?.issuefound ?? ""}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} md={12} lg={12}>
                <div className="Action Taken RadioButtonStyle">
                  <FormLabels
                    label="Action Taken"
                    labelPlacement="start"
                    isRequired={true}
                  />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="radioGroup  RadioBtnStyle"
                  >
                    {actionTakenOptionData &&
                      actionTakenOptionData.map((item) => (
                        <RadioButton
                          checked={item.key === formValue.actionTaken}
                          key={item.key}
                          value={item.value}
                          label={item.value}
                          onChange={(e) =>
                            setFormValue(
                              {
                                ...formValue,
                                actionTaken: item.key,
                              },
                              setErrorActionTaken(false)
                            )
                          }
                        />
                      ))}
                    {errorActionTaken && (
                      <p className="show-error">Please Checked Action Taken </p>
                    )}
                  </RadioGroup>
                </div>
              </Grid>
              {formValue.actionTaken === 0 && (
                <>
                  <Grid container mt={2} mb={3}>
                    <Grid item lg={12} xs={12} sm={12} md={12}>
                      <FormControl fullWidth className="selectableRadioOption">
                        <FormLabels label="Enter Action Taken" />
                        <TextArea
                          placeholder={"Enter Here"}
                          value={formValue.addActionTaken}
                          onChange={(e) =>
                            setFormValue({
                              ...formValue,
                              addActionTaken: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container mb={3}>
                <Grid item mt={2} xs={12} sm={12} md={12} lg={12}>
                  <FormControl fullWidth className="selectableRadioOption">
                    <FormLabels label="Add Abatement Notes" />
                    <TextArea
                      placeholder={"Enter Here"}
                      value={formValue.addAbadementNotes}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          addAbadementNotes: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {/* <Grid mt={4}>
                  <Checkbox
                    value={formValue.closeItem}
                    onChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        closeItem: !prevState.closeItem,
                      }))
                    }
                    label={"Close Item"}
                  />
                </Grid> */}

              <Grid container mb={3}>
                <Grid container textAlign="right" alignItems="center">
                  <Grid item lg={6} md={6} xs={12} sm={12}></Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                    sm={12}
                    textAlign="center"
                    className="reprimandButton"
                    mt={3}
                  >
                    <Button
                      varientContained={true}
                      label={edit === "edit" ? "Update" : "Submit"}
                      onClick={(e) =>
                        formHandler(e, edit === "edit" ? "update" : "submit")
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};
export default ReprimandForm;
