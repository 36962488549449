import { contactType } from "../constants/contactType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "contacts",
  isError: false,
  isLoading: false,
};

export const contactListReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactType.GET_CONTACTS_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contactType.GET_CONTACTS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contactType.GET_CONTACTS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactType.POST_CONTACTS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contactType.POST_CONTACTS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contactType.POST_CONTACTS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case contactType.CLEAR_CONTACT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactType.UPDATE_CONTACT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contactType.UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contactType.UPDATE_CONTACT_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case contactType.CLEAR_CONTACT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const deleteContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactType.DELETE_CONTACT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contactType.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contactType.DELETE_CONTACT_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const viewContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactType.GET_CONTACT_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contactType.GET_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contactType.GET_CONTACT_DETAILS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const convertNonPrivilegeContactListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contactType.CONVERT_NON_PRIVILEGE_CONTACT_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case contactType.CONVERT_NON_PRIVILEGE_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case contactType.CONVERT_NON_PRIVILEGE_CONTACT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
