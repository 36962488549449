import * as safetyLogAction from "../actions/safetyLogAction";
import { safetyLogType } from "../constants/safetyLogType";

import { getKudosLogService, getReprimandLogService, getViolationLogService, getItemLogService } from "../../services/safetyLogService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* getKudosLogSaga(params) {
  try {
    const { data, status, error } = yield call(getKudosLogService, params);
    if (!data && status !== 200) throw apiError(error);
    const kudosPage = safetyLogType.GET_KUDOS_LOG_PENDING;
    yield put(
      safetyLogAction.getKudosLogSuccess({
        kudosPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get kudos Log Failed" };
    yield put(safetyLogAction.getkudosLogFailure(data));
  }
}


export function* getReprimandLogSaga(params) {
  try {
    const { data, status, error } = yield call(getReprimandLogService, params);
    if (!data && status !== 200) throw apiError(error);
    const reprimandPage = safetyLogType.GET_REPRIMAND_LOG_PENDING;
    yield put(
      safetyLogAction.getReprimandLogSuccess({
        reprimandPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Reprimand Log Failed" };
    yield put(safetyLogAction.getReprimandLogFailure(data));
  }
}

export function* getViolationLogSaga(params) {
  try {
    const { data, status, error } = yield call(getViolationLogService, params);
    if (!data && status !== 200) throw apiError(error);
    const violationPage = safetyLogType.GET_VIOLATION_LOG_PENDING;
    yield put(
      safetyLogAction.getViolationLogSuccess({
        violationPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get violation Log Failed" };
    yield put(safetyLogAction.getViolationLogFailure(data));
  }
}

const mySafetyLogSaga = [
    takeLatest(safetyLogType.GET_KUDOS_LOG_PENDING, getKudosLogSaga),
    takeLatest(safetyLogType.GET_REPRIMAND_LOG_PENDING, getReprimandLogSaga),
    takeLatest(safetyLogType.GET_VIOLATION_LOG_PENDING, getViolationLogSaga),
  ];
  
  export default mySafetyLogSaga;
  