import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";
import "./chart.scss";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables);

const Doughnutchart = ({
  labels = [],
  datasets = [],
  options,
  height = "400px",
}) => {
  useEffect(() => {
    Chart.register(ChartDataLabels);
  }, []);
  const hasData =
    labels.length > 0 &&
    datasets.length > 0 &&
    datasets[0].data.some((value) => value > 0);

  return (
    <div style={{ width: "100%", height }}>
      {hasData ? (
        <Doughnut options={options} data={{ labels, datasets }} />
      ) : (
        <div className="noData">No Data Available</div>
      )}
    </div>
  );
};

export default Doughnutchart;
