import { Grid } from "@mui/material";
import React from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import { useState } from "react";
import PrimaryInspectorList from "./primaryInspectorList";
import SecondryInspectorList from "./secondryInspectorList";
import { PAGE_SIZE } from "../../../../constant/constant";

function InspectionAuthority({
  allUsers,
  onSearch,
  isLoading,
  dataBack,
  InspectorListData,
  SwInspectorList,
  getSwReference,
  assignSwInspectorDatas,
  SwSecondaryInspectorList,
  getSwSecondaryInspectorListData,
  deleteSecondaryInspectorList,
  setSecondaryInspector,
  secondaryInspector,
  selectedUsers,
  searchSecondaryInspector,
  errorMsgPrimaryInspectors,
  setErrorMsgPrimaryInspectors,
  setupData,
  setselectedUsers,
  assignsSwInspectorData,
  assignSwInspectorDataStatus,
  deleteSwSecondaryInspectionDataStatus,
  deleteSwSecondaryInspectionDatas,
  setIsSuccess,
}) {
  const [inspectorTypecheck, setinspectorTypecheck] = useState("0");

  function handleInspectorType(e) {
    setinspectorTypecheck(e);
    const defaultData = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      UserId: "",
      InspectionDayId: "",
      FrequencyId: "",
    };
    SwInspectorList(defaultData);
  }

  return (
    <div className="overidePersonal">
      <Grid container item lg={9} xs={12} sm={12} md={9}>
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">Inspection Authority</div>
        </Grid>
      </Grid>
      <Grid item container mt={3} spacing={2} className="swproject">
        <Grid item lg={6} xs={12} sm={12} md={4}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={inspectorTypecheck === "0"}
                value="0"
                label="Primary"
                onChange={(e) => handleInspectorType(e.target.value)}
              ></RadioButton>

              <RadioButton
                checked={inspectorTypecheck === "1"}
                value="1"
                label="Secondary"
                onChange={(e) => handleInspectorType(e.target.value)}
              ></RadioButton>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      {inspectorTypecheck === "0" && (
        <Grid>
          <PrimaryInspectorList
            allUsers={allUsers}
            onSearch={onSearch}
            isLoading={isLoading}
            dataBack={dataBack}
            InspectorListData={InspectorListData}
            SwInspectorList={SwInspectorList}
            getSwReference={getSwReference}
            assignSwInspectorDatas={assignSwInspectorDatas}
            errorMsgPrimaryInspectors={errorMsgPrimaryInspectors}
            setErrorMsgPrimaryInspectors={setErrorMsgPrimaryInspectors}
            setupData={setupData}
            assignsSwInspectorData={assignsSwInspectorData}
            assignSwInspectorDataStatus={assignSwInspectorDataStatus}
            setIsSuccess={setIsSuccess}
          />
        </Grid>
      )}
      {inspectorTypecheck === "1" && (
        <Grid>
          <SecondryInspectorList
            allUsers={allUsers}
            onSearch={onSearch}
            searchSecondaryInspector={searchSecondaryInspector}
            isLoading={isLoading}
            dataBack={dataBack}
            InspectorListData={InspectorListData}
            SwInspectorList={SwInspectorList}
            getSwReference={getSwReference}
            assignSwInspectorDatas={assignSwInspectorDatas}
            SwSecondaryInspectorList={SwSecondaryInspectorList}
            getSwSecondaryInspectorListData={getSwSecondaryInspectorListData}
            deleteSecondaryInspectorList={deleteSecondaryInspectorList}
            secondaryInspector={secondaryInspector}
            setSecondaryInspector={setSecondaryInspector}
            selectedUsers={selectedUsers}
            setupData={setupData}
            assignsSwInspectorData={assignsSwInspectorData}
            assignSwInspectorDataStatus={assignSwInspectorDataStatus}
            deleteSwSecondaryInspectionDataStatus={
              deleteSwSecondaryInspectionDataStatus
            }
            deleteSwSecondaryInspectionDatas={deleteSwSecondaryInspectionDatas}
            setIsSuccess={setIsSuccess}
          />
        </Grid>
      )}
    </div>
  );
}

export default InspectionAuthority;
