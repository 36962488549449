import { safetyLogType } from "../constants/safetyLogType";

export const getKudosLog = (data) => ({
  type: safetyLogType.GET_KUDOS_LOG_PENDING,
  data,
});

export const getKudosLogSuccess = (data) => ({
  type: safetyLogType.GET_KUDOS_LOG_SUCCESS,
  data,
});

export const getkudosLogFailure = (error) => ({
  type: safetyLogType.GET_KUDOS_LOG_FAILURE,
  error,
});

export const getReprimandLog = (data) => ({
  type: safetyLogType.GET_REPRIMAND_LOG_PENDING,
  data,
});

export const getReprimandLogSuccess = (data) => ({
  type: safetyLogType.GET_REPRIMAND_LOG_SUCCESS,
  data,
});

export const getReprimandLogFailure = (error) => ({
  type: safetyLogType.GET_REPRIMAND_LOG_FAILURE,
  error,
});

export const getViolationLog = (data) => ({
  type: safetyLogType.GET_VIOLATION_LOG_PENDING,
  data,
});

export const getViolationLogSuccess = (data) => ({
  type: safetyLogType.GET_VIOLATION_LOG_SUCCESS,
  data,
});

export const getViolationLogFailure = (error) => ({
  type: safetyLogType.GET_VIOLATION_LOG_FAILURE,
  error,
});

export const getItemLogUnmount = (error) => ({
  type: safetyLogType.GET_ITEM_LOG_FAILURE,
  error,
});
