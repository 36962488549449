import * as incidentAction from "../actions/incidentAction";
import { incidentType } from "../constants/incidentType";
import {
  getIncidentReferenceData,
  getIncidentList,
  getCasesList,
  deleteIncident,
  getActionsList,
  closeActionService,
  getCaseReport,
  closeIncident,
  deleteCase,
} from "../../services/incidentService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* incidentReferenceData() {
  console.debug("incidentSaga");
  try {
    const { data, status, error } = yield call(getIncidentReferenceData);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentDataSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Referencedata Failed" };
    yield put(incidentAction.getIncidentDataFailure(data));
  }
}

export function* incidentListSaga(params) {
  console.debug("incidentListSaga");
  try {
    const { data, status, error } = yield call(getIncidentList, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentListSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident List Failed" };
    yield put(incidentAction.getIncidentDataFailure(data));
  }
}

function* deleteIncidentsSaga(payload) {
  console.debug("deleteIncidentsSaga");
  try {
    const { data, status, error } = yield call(deleteIncident, payload);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.deleteIncidentsSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident List Failed" };
    yield put(incidentAction.deleteIncidentsFailure(data));
  }
}

export function* actionListSaga(params) {
  console.debug("actionListSaga");
  try {
    const { data, status, error } = yield call(getActionsList, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.getActionListSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Action List Failed" };
    yield put(incidentAction.getActionListFailure(data));
  }
}

export function* closeActionItemSaga(params) {
  console.debug("closeActionItemSaga");
  try {
    const { data, status, error } = yield call(closeActionService, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.closeActionItemSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Close Item failed" };
    yield put(incidentAction.closeActionItemFailure(data));
  }
}

export function* casesListSaga(params) {
  console.debug("casesListSaga");
  try {
    const { data, status, error } = yield call(getCasesList, params);
    if (!data && status !== 200) throw apiError(error);
    const casesPage = incidentType.CASES_PAGE;
    yield put(
      incidentAction.getCasesListSuccess({
        casesPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Case List Failed" };
    yield put(incidentAction.getCasesListFailure(data));
  }
}

export function* caseReport({ caseId }) {
  console.debug("caseReport");
  try {
    const { data, status, error } = yield call(getCaseReport, caseId);
    if (!data && status !== 200) throw apiError(error);
    const CaseReportPage = incidentType.CASEVIEW_PAGE;
    yield put(
      incidentAction.getCaseDetailsSuccess({
        CaseReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Case Report Failed" };
    yield put(incidentAction.getCaseDetailsFailure(data));
  }
}

export function* closeIncidentSaga(params) {
  console.debug("closeIncidentSaga");
  try {
    const { data, status, error } = yield call(closeIncident, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.closeIncidentSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Close Item failed" };
    yield put(incidentAction.closeIncidentFailure(data));
  }
}

function* deleteCaseSaga(payload) {
  console.debug("deleteCaseSaga");
  try {
    const { data, status, error } = yield call(deleteCase, payload);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.deleteCaseSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident List Failed" };
    yield put(incidentAction.deleteCaseFailure(data));
  }
}

const myIncidentSaga = [
  takeLatest(incidentType.GET_INCIDENT, incidentReferenceData),
  takeLatest(incidentType.GET_INCIDENT_LIST, incidentListSaga),
  takeLatest(incidentType.GET_CASES_LIST, casesListSaga),
  takeLatest(incidentType.DELETE_INCIDENT, deleteIncidentsSaga),
  takeLatest(incidentType.GET_ACTION_LIST, actionListSaga),
  takeLatest(incidentType.CLOSE_ACTION_ITEM, closeActionItemSaga),
  takeLatest(incidentType.GET_CASE_DETAILS_PENDING, caseReport),
  takeLatest(incidentType.CLOSE_INCIDENT, closeIncidentSaga),
  takeLatest(incidentType.DELETE_CASE, deleteCaseSaga),

];

export default myIncidentSaga;
