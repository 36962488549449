import React from "react";
import "./Carousel.scss";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "./Carousel.scss";
import { inspection } from "../../../assets";

const Carousel = (props) => {
  const onClickHandler = (value) => {
    const urlParams = new URLSearchParams(window.location.search);
    const editedCaseId = urlParams.get("caseid");
    if (!editedCaseId) {
      props.handleOnChange(value);
    }
  };
  return (
    <>
      <div className="overideCarousel">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={16}
          slidesPerView={5}
          navigation
          className="carouselContainer"
          breakpoints={{
            300: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
            1199: {
              slidesPerView: 5,
            },
          }}
        >
          {props.sliderItems.map((item, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <div
                className="carouselBox"
                ref={props?.clickTargetRef}
                onClick={() => (props?.edit ? "" : onClickHandler(item))}
              >
                <div
                  className={`${props?.edit ? "itemBox disbled" : "itemBox"} ${
                    props?.activeSlide?.hasOwnProperty("id") ||
                    props?.activeSlide?.hasOwnProperty("key")
                      ? props?.activeSlide?.id === item.id ||
                        props?.activeSlide?.id === item.questionSetId
                        ? `${"active"}`
                        : ""
                      : ""
                  }`}
                >
                  <img
                    src={item.img ? item.img : inspection?.custominspection}
                    alt={item.title}
                    className="img-fluid"
                  />
                  <h3 className="sliderTitle">
                    {item.questionSetName ? item.questionSetName : item.title}
                  </h3>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default React.memo(Carousel);
