import { Settings } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Typography from "@mui/material/Typography";
import "./TableView.scss";
import commonImages from "../../../assets";
import { CircularProgress, Stack } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { textcopied } from "../toastMessage/Toast";
import { getFileInfo } from "../../../utils/helper";

const ImageTable = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [copied, setCopied] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = (imageUrl) => {
    navigator.clipboard.writeText(imageUrl);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      textcopied();
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const imageUrls =
    props?.imageUrl && props?.imageUrl.length > 0
      ? props?.imageUrl?.split("|")
      : "";
  const uniqueImageUrls = [...new Set(imageUrls)];
  return (
    <div className="ImageTable">
      {uniqueImageUrls?.length !== 0 && (
        <>
          <div className="reportTableRes">
            <table className="reportsTable">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>File Preview</th>
                  <th>File URL</th>
                  <th>
                    <Settings
                      onClick={handleClick}
                      className="settingIconReport"
                    />
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography
                        sx={{ py: 1, px: 2 }}
                        className="downloadList"
                      >
                        <CSVLink
                          filename={"ImageUrlList"}
                          data={props?.files ?? []}
                        >
                          Download List
                        </CSVLink>
                      </Typography>
                    </Popover>
                  </th>
                </tr>
              </thead>
              <tbody>
                {uniqueImageUrls &&
                  uniqueImageUrls?.map((url, index) => {
                    const fileInfo = getFileInfo(url);
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {fileInfo?.isImage && (
                            <img
                              src={
                                fileInfo?.fileExtension === "tif"
                                  ? commonImages?.noimage
                                  : url
                              }
                              className="TableImageThumb"
                              alt={url}
                            />
                          )}
                          {fileInfo?.isDocument && (
                            <img
                              className="dropImg"
                              src={commonImages?.docIcon}
                              alt={url}
                              width={"40px"}
                            />
                          )}
                          {fileInfo?.isPdf && (
                            <img
                              className="dropImg"
                              src={commonImages?.pdfIcon}
                              alt={url}
                              width={"40px"}
                            />
                          )}
                        </td>
                        <td
                          className="copyurl"
                          onClick={(i) => handleCopy(url)}
                        >
                          {"COPY URL"}
                        </td>
                        <td className="CancelImage">
                          <span
                            onClick={(i) => props?.deleteImageURL(url, index)}
                          >
                            {"X"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}

      {props?.isLoader && (
        <Stack className="loaderDropBox" spacing={2} direction="row">
          <CircularProgress color="success" />
        </Stack>
      )}
      <ToastContainer />
    </div>
  );
};

export default ImageTable;
