import React from "react";
import "./attendeeList.scss";
import { Grid } from "@mui/material";
import commonImages from "../../../assets";

export default function AttendeeList({
  userList,
  handleDelete,
  hiddenAttendeeSignatureInput,
  attendeesignatureUpload,
  attendeeSignature,
  removeImage,
  setImageCheck
}) {
  return (
    <div className="attendeeList">
      <div className="container">
        <div className="heading">
          <div className="col">Attendees</div>
          <div className="col">Group</div>
          <div className="col">Signature</div>
          <div className="col"></div>
        </div>
        {userList?.map((user, index) => {
          return (
            <div className="table-row" key={index}>
              <div className="col attendee">{user?.value} </div>
               <div className="col group">{user?.gpName}</div>
              <div className="col proof">
                <Grid item container className="topHeaderProfile">
                  <Grid item lg={6} xs={12}>
                    <Grid className="attendeesignatureImg">
                      {user?.imgUrl ? (
                        <div className="attendeesignContainer">
                          <div className="attendeeSignImg">
                            <img
                              className="attendeeborderSignatureImg"
                              src={user?.imgUrl}
                              alt=""
                            />
                          </div>
                          <div className="signatureCnacel" onClick={()=>removeImage(user.key)}>
                            <img
                              className="cancelIcon"
                              src={commonImages.cancelIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      ) : (
                        <Grid item container className="topHeaderProfile">
                          <Grid item lg={1} xs={12}>
                            <input
                              type="file"
                              // ref={hiddenAttendeeSignatureInput}
                              ref={(el) => (hiddenAttendeeSignatureInput.current[index] = el)}
                              onChange={(event) => {
                                attendeesignatureUpload(event, user?.key);
                              }}
                              style={{ display: "none" }}
                              accept="image/*"
                            />
                            <label
                              className="signatureBtn"
                              onClick={() => {
                                hiddenAttendeeSignatureInput.current[index].click();
                                setImageCheck(false)
                              }}
                              htmlFor="actual-btn"
                            >
                              + Add Signature
                            </label>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div
                className="col cancel"
                onClick={() => handleDelete(user.key)}
              >
                X
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
