import { reportType } from "../constants/reportType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "report",
  isError: false,
  isLoading: false,
};

export const getCredentialReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportType.GET_CREDENTIAL_REPORT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case reportType.GET_CREDENTIAL_REPORT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case reportType.GET_CREDENTIAL_REPORT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getLookupCredentialReportReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case reportType.GET_LOOKUP_CREDENTIAL_REPORT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case reportType.GET_LOOKUP_CREDENTIAL_REPORT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case reportType.GET_LOOKUP_CREDENTIAL_REPORT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getConfiguredCredentialReportReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case reportType.GET_CONFIGURED_CREDENTIAL_REPORT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case reportType.GET_CONFIGURED_CREDENTIAL_REPORT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case reportType.GET_CONFIGURED_CREDENTIAL_REPORT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
