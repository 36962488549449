import React, { useEffect } from "react";
import SamplingLogsVersion from "../components/samplingLogsVersion";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as projectAction from "../../../../redux/storm-water/actions/projectAction";
import Loader from "../../../../library/common/Loader";

import { PAGE_SIZE } from "../../../../constant/constant";
function SamplingLogVersionContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const projectId = location?.state?.projectId;
  const stateId = location?.state?.stateId;
  const projectName = location?.state?.projectName;
  const projectNumber = location?.state?.projectNumber;
  const customerId = location?.state?.customerId ?? location?.state?.customerId;

  const ProjectSampleData = useSelector(
    (state) => state?.swprojectSampleList?.data
  );
  const ProjectSampleDataIsloading = useSelector(
    (state) => state?.swprojectSampleList?.isLoading
  );

  const data = {
    page: 1,
    pageSize: PAGE_SIZE,
    projectId: projectId,
  };
  const getProjectSampleListData = (data) => {
    dispatch(projectAction.swProjectSampleList(data));
  };

  useEffect(() => {
    getProjectSampleListData(data);
  }, []);
  return (
    <div>
      {ProjectSampleDataIsloading && <Loader />}
      <SamplingLogsVersion
        ProjectSampleData={ProjectSampleData}
        stateId={stateId}
        projectName={projectName}
        projectNumber={projectNumber}
        projectId={projectId}
        customerId={customerId}
      />
    </div>
  );
}

export default SamplingLogVersionContainer;
