import React from "react";
import ViewImages from "../viewImages/viewImages";
import "./viewAction.scss";
import Parser from "html-react-parser";
const ViewAction = ({ items }) => {
  const imageSize = {
    size: "small",
  };
  return (
    <div className="viewActionOver">
      <div className="viewActionBox">
        <div className="actiontitle">Actions:</div>
        <div className="actionListBox">
          {items?.map((list, i) => {
            const image = list?.files
              ? list?.files[0]?.filePath?.split("|")
              : "";
            return (
              <>
                <div className="actionList" key={i}>
                  <div className="actionListTop">
                    <div className="title">Action {i + 1}:</div>
                    <div className="desc overRideTextEditor">
                      {Parser(list?.description || "")}
                    </div>
                  </div>
                  <div className="actionListBottom">
                    <div className="acList assignName">
                      Assigned to: <span>{list?.assignedPartyName}</span>
                    </div>
                    <div className="acList assignMedia">
                      <div className="mediaTtitle">Media:</div>
                      <ViewImages image={image} imageSize={imageSize} />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ViewAction;
