import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "../../../observation/view-observation-report/commonObservationReport.scss";
import "./reprimandReport.scss";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { REPRIMAND_LIST } from "../../../../constant/routeContant";
import parse from "html-react-parser";
import TextArea from "../../../../library/custom/textArea/TextArea";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { USER_ID } from "../../../../constant/constant";
import ViewImages from "../../../incidents/view-incident-report/common/viewImages/viewImages";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import FormDropZone from "../../../../library/dropZone/FormDropZone";
const ReprimandReportView = ({
  data,
  inspectionViolationCounts,
  closeReprimandReprimand,
  involvedPartyUserId,
  reprimandId,
  status,
}) => {
  const reprimandBackBtn = {
    title: "Back to Reprimand",
    route: REPRIMAND_LIST,
  };
  // closing Reprimand
  const [closesFilesData, setCloseFilesData] = useState("");
  const [correctiveReprimand, setCorrectiveReprimand] = useState("");
  const [isCorrectiveReprimand, setIsCorrectiveReprimand] = useState(false);
  const handleFileChange = (files, single) => {
    setCloseFilesData({ fileId: single[0], filePath: files });
  };

  const handleDeleteImageURL = () => {
    setCloseFilesData([]);
  };

  function mappedData() {
    let errors = false;
    if (correctiveReprimand?.length === 0) {
      setIsCorrectiveReprimand(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const closeReprimandData = {
      id: reprimandId,
      involvedUserId: involvedPartyUserId,
      files: [closesFilesData?.fileId ? closesFilesData?.fileId : 0],
      note: correctiveReprimand,
      userId: USER_ID,
    };
    return closeReprimandData;
  }
  console.log(status, "data?.status");
  const closeReprimand = () => {
    const ReprimandSubmitData = mappedData();
    if (!ReprimandSubmitData) {
    } else {
      closeReprimandReprimand(ReprimandSubmitData);
    }
  };
  const reprimandCloseNote = data?.listInvolvedParty[0]?.note;
  const reprimandCloseMedia = data?.listInvolvedParty[0]?.closedFiles
    ? data?.listInvolvedParty[0]?.closedFiles[0]?.filePath?.split("|")
    : "";
  const imageSize = {
    size: "small",
  };
  return (
    <div className="observationRepoertOveride">
      <div className="commonOveride">
        <Box className="commoninfoWrap">
          <Box className="commoninfoBox">
            <Box className="commoninfoleft">
              <Typography className="titleViolation">Reprimand</Typography>
              <Typography className="reportid" mt={4}>
                Reprimand ID: <span>{data?.inspectionOrReprimandId}</span>
              </Typography>
              <Box className="columns" mt={4}>
                <Typography className="listView" style={{ display: "flex" }}>
                  Involved Party:{" "}
                  <span className="font-w400">
                    {data?.listInvolvedParty?.map((element, index) => {
                      return <div key={index}>{element?.userName}</div>;
                    })}
                  </span>
                </Typography>
                <Typography className="listView">
                  Corrective Party:{" "}
                  <span className="font-w400">
                    {" "}
                    {data?.itemRespOrCorrecingPartyName}
                  </span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  Date of Reprimand:{" "}
                  <span className="font-w400"> {data?.inspectionDate}</span>
                </Typography>
                <Typography className="listView">
                  Time of Reprimand:{" "}
                  <span className="font-w400"> {data?.inspectionTime}</span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  Location:
                  <span className="font-w400">{data?.location}</span>
                </Typography>
                {/* <Typography className="listView">
                Project:
                <span className="font-w400"></span>
              </Typography> */}
              </Box>
              <Typography className="listView  ">
                Issue:
                <span className="blockView">{data?.issue}</span>{" "}
              </Typography>
              <Typography className="listView ">
                Abadement Notes:
                <span className="blockView">
                  {parse(unescape(data?.abatementNotes))}
                </span>
              </Typography>
              <Typography className="listView">
                Action:
                <span className="blockView"> {data?.actionTaken}</span>
              </Typography>
              {status === "Open" ? (
                ""
              ) : (
                <>
                  <Typography className="listView">
                    Comment:
                    <span className="blockView">{reprimandCloseNote}</span>
                  </Typography>
                  <Typography className="listView desNone">
                    Media:
                    <span className="blockView">
                      <ViewImages
                        image={reprimandCloseMedia}
                        imageSize={imageSize}
                      />
                    </span>
                  </Typography>
                </>
              )}
            </Box>

            <Box className="commoninfoRight">
              <Grid mt={4}>
                <Typography className="incstatus">Reprimand Status</Typography>

                {status === "Open" ? (
                  <Button variant="contained" className="statusbtnOpen">
                    {data?.listInvolvedParty[0].status}
                  </Button>
                ) : (
                  <Button variant="contained" className="statusbtn ">
                    {data?.listInvolvedParty[0].status}
                  </Button>
                )}
              </Grid>
              <Grid mt={4}>
                <Typography className="incstatus">
                  Previous Reprimand
                </Typography>
                <Button variant="contained" className="reprimandstatusbtn">
                  {inspectionViolationCounts?.data?.result >= 1
                    ? inspectionViolationCounts?.data?.result
                    : 0}
                </Button>
              </Grid>
            </Box>
          </Box>
        </Box>
        {status === "Open" && checkPrivileges([12, 81]) ? (
          <>
            <Typography className="correctivemeasures desNone" mt={4}>
              <Grid item md={12} className="closedItemDropboxs">
                <FormLabels label="Comments" isRequired={true} />
                <TextArea
                  placeholder={"Enter Here"}
                  value={correctiveReprimand}
                  onChange={(e) => {
                    if (
                      e.target.value === " " &&
                      correctiveReprimand.trim() === ""
                    )
                      setCorrectiveReprimand("");
                    else setCorrectiveReprimand(e.target.value);
                    setIsCorrectiveReprimand(false);
                  }}
                />
                {isCorrectiveReprimand ? (
                  <div className="newErrorMsg">
                    Please Select this required field
                  </div>
                ) : (
                  ""
                )}
                <FormDropZone
                  label={""}
                  paraText1={"Drop the files here ..."}
                  paraText2={"Drop the files here, "}
                  paraText3={"or Browse"}
                  handleFileChange={handleFileChange}
                  fileType={10}
                  imageUrl={closesFilesData?.filePath}
                  deleteImageURL={(url) => handleDeleteImageURL()}
                  id="addClosed"
                  key="addClosed"
                  isMuliple={false}
                  isDoc={true}
                  isImage={false}
                  allFiles={true}
                  isMultipleIcon={true}
                />
              </Grid>
            </Typography>
          </>
        ) : (
          ""
        )}

        <Box className="escalatereprimand">
          <Grid mt={4}>
            <BackBtn dataBack={reprimandBackBtn} />
          </Grid>
          {status === "Open" && checkPrivileges([12, 81]) ? (
            <Grid mt={4} style={{ marginRight: "-240px" }}>
              <Button
                varientContained={true}
                className="closereprimandbtn"
                label=" Close Item"
                onClick={closeReprimand}
              >
                Close Item
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Box>
      </div>
    </div>
  );
};

export default ReprimandReportView;
