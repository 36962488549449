import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ViewImages from "../viewImages/viewImages";

const AdditionalVehicleDetails = ({ additionalData }) => {
  const imageSize = {
    size: "small",
  };
  return (
    <div className="commonOveride">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">
              Additional Vehicle Details:
            </Typography>
            <div className="additionalListBox">
              {additionalData?.vehicles?.map((item, i) => {
                const image = [];
                const totalImg = item?.files?.map((i) => image.push(i.value));
                return (
                  <>
                    <div className="additionalList" key={i}>
                      <div className="subtitle">
                        Additional Vehicle Details :{i + 1}
                      </div>
                      <Box className="columns">
                        <Typography className="listView">
                          Name of Driver:
                          <span className="font-w400">
                            {item?.nameOfDriver}
                          </span>
                        </Typography>
                        <Typography className="listView">
                          Vehicle Reg Number:
                          <span className="font-w400">{item?.regNumber}</span>
                        </Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView">
                          Unit Number:
                          <span className="font-w400">{item?.unitNumber}</span>
                        </Typography>
                        <Typography className="listView">
                          Make and Model:
                          <span className="font-w400">{item?.model}</span>
                        </Typography>
                      </Box>
                      <Typography className="listView">
                        Number of Occupants:
                        <span className="font-w400">
                          {item?.numberOfOccupants === 0
                            ? ""
                            : item?.numberOfOccupants}
                        </span>
                      </Typography>

                      <Typography className="listView columns">
                        <div className="columnsf">Was the Vehicle Towed?</div>
                        {item?.isVehicleTowed === true ? (
                          <>
                            <div className="columnsl yesbtn">Yes</div>
                          </>
                        ) : item?.isVehicleTowed === false ? (
                          <>
                            <div className="columnsl nobtn">No</div>
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                      <Typography className="listView columns">
                        <div className="columnsf">
                          Was a Ticket Issued to this Driver?
                        </div>
                        {item?.isTicketIssuedToDriver === true ? (
                          <>
                            <div className="columnsl yesbtn">Yes</div>
                          </>
                        ) : item?.isTicketIssuedToDriver === false ? (
                          <>
                            <div className="columnsl nobtn">No</div>
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                      <Typography className="listView columns">
                        <div className="columnsf">
                          Was the Driver Under Influence of Drugs/Alcohol?
                        </div>
                        {item?.isInfluenceOfDrugs === true ? (
                          <>
                            <div className="columnsl yesbtn">Yes</div>
                          </>
                        ) : item?.isInfluenceOfDrugs === false ? (
                          <>
                            <div className="columnsl nobtn">No</div>
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>

                      <Typography className="listView desNone">
                        Photos:
                        <ViewImages image={image} imageSize={imageSize} />
                      </Typography>
                    </div>
                  </>
                );
              })}
            </div>
          </Box>
          <Box className="commoninfoRight"></Box>
        </Box>
      </Box>
    </div>
  );
};

export default AdditionalVehicleDetails;
