import * as reprimandAction from "../actions/reprimandAction";
import { reprimandType } from "../constants/reprimandType";
import {
  getReprimandListService,
  postReprimandService,
  getReprimandDetailsService,
  updateReprimandService,
} from "../../services/reprimandService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* postReprimandSaga(payload) {
  try {
    const { data, status, error } = yield call(postReprimandService, payload);
    if (!data && status !== 200) throw apiError(error);
    const { responseMessage, identity } = data;
    yield put({
      type: reprimandType.POST_REPRIMAND_SUCCESS,
      msg: responseMessage ?? "",
      successData: {
        id: identity ?? null,
        data,
        status: (payload?.payload?.status === 1 ? "Draft" : "Submitted") ?? "",
      },
    });
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Reprimand List Failed" };
    yield put({
      type: reprimandType.POST_REPRIMAND_FAILED,
      error: data,
    });
  }
}
// update
export function* updateReprimandSaga(payload) {
  try {
    const { data, status, error } = yield call(updateReprimandService, payload);
    if (!data && status !== 200) throw apiError(error);
    const { responseMessage } = data;
    yield put({
      type: reprimandType.UPDATE_REPRIMAND_SUCCESS,
      msg: responseMessage ?? "",
      successData: {
        id: payload?.payload?.ReprimandsDtlId ?? null,
        status:
          (payload?.payload?.status === 1 ? "Updated Draft" : "Updated") ?? "",
      },
    });
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Reprimand List Failed" };
    yield put({
      type: reprimandType.UPDATE_REPRIMAND_FAILED,
      error: data,
    });
  }
}
export function* getReprimandListSaga(payload) {
  try {
    const { data, status, error } = yield call(
      getReprimandListService,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const { insReprimandDetails, recordsCount } = data;
    yield put({
      type: reprimandType.GET_REPRIMAND_LIST_SUCCESS,
      payload: {
        insReprimandDetails,
        recordsCount,
      },
    });
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Reprimand List Failed" };
    yield put({
      type: reprimandType.GET_REPRIMAND_LIST_FAILED,
      error: data,
    });
  }
}

// get details
export function* getReprimandDetailsSaga(payload) {
  try {
    const { data, status, error } = yield call(
      getReprimandDetailsService,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    // const { insReprimandsInspection } = data;
    console.log(data);
    yield put({
      type: reprimandType.GET_REPRIMAND_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Reprimand List Failed" };
    yield put({
      type: reprimandType.GET_REPRIMAND_DETAILS_FAILED,
      error: data,
    });
  }
}

const myReprimandSaga = [
  takeLatest(reprimandType.GET_REPRIMAND_LIST_PENDING, getReprimandListSaga),
  takeLatest(reprimandType.POST_REPRIMAND_PENDING, postReprimandSaga),
  takeLatest(reprimandType.UPDATE_REPRIMAND_PENDING, updateReprimandSaga),
  takeLatest(
    reprimandType.GET_REPRIMAND_DETAILS_PENDING,
    getReprimandDetailsSaga
  ),
];

export default myReprimandSaga;
