import { Grid } from "@mui/material";
import "./checkbox.scss";

const Checkbox = ({ value,disabled, onChange, checked, label = "Close Item",name }) => {
  return (
    <>
      <Grid className="check">
        <input
          type="checkbox"
          className="tickbox"
          value={value}
          onChange={(e) => onChange(e)}
          checked={checked}
          name={name}
          disabled={disabled}
        />
        <span className="closeItem">{label}</span>
      </Grid>
    </>
  );
};

export default Checkbox;
