import React from "react";
import Incidents from "../component/incidents";

const IncidentsContainer = () => {
  return (
    <>
      <Incidents />
    </>
  );
};

export default IncidentsContainer;
