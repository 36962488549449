import React from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../custom/textBox/InputField";
import Buttons from "../button/Button";
import Button from "../button/Button";
import "./addNewQuestion.scss";
import FormDropZone from "../../../library/dropZone/FormDropZone";

const AddNewQuestion = (props) => {
  const {
    singleQuestion,
    handleOnChangeQuestionForm,
    cancelQuestion,
    deleteImageURL,
    questionFileUpload,
    addAnswers,
    cancelAnswers,
    correctAnswer,
    isErrorQuestion = false,
    errorQuestionMsg = "",
    imageUrl,
    isquizans,
  } = props;
  return (
    <>
      <div className="newQuestionOver">
        <Grid container className="newQuestionContainer">
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputField
                id="question"
                isRequired={true}
                fullWidth={true}
                type="text"
                label="Enter Question"
                value={singleQuestion?.question}
                placeholder="Add Question Here.."
                onChange={(e) =>
                  handleOnChangeQuestionForm(
                    e,
                    singleQuestion,
                    null,
                    "question"
                  )
                }
                isError={isErrorQuestion}
                errorMsg={errorQuestionMsg}
              />
            </FormControl>
          </Grid>
          <Grid lg={12} xs={12} sm={12} md={12} mt={3} className="questionDrop">
            <FormDropZone
              key={singleQuestion.id}
              label={"Add Image"}
              paraText1={"Drop the files here ..."}
              paraText2={"Drop Images here, "}
              paraText3={"or Browse"}
              handleFileChange={(photo, fileDataId) =>
                questionFileUpload(photo, fileDataId, singleQuestion)
              }
              deleteImageURL={deleteImageURL}
              isDynamics={false}
              isRequired={true}
              fileType={10}
              isMuliple={false}
              imageUrl={imageUrl}
              id="uploadMedia"
              isDoc={false}
              isImage={true}
              allFiles={false}
            />
          </Grid>
          <Grid lg={12} xs={12} sm={12} mt={3} className="answers">
            <div className="answerBox">
              <ul>
                {singleQuestion?.answers?.map((singleAnswer, index) => (
                  <>
                    <li>
                      <div
                        className="answerList"
                        key={index}
                        id={singleAnswer?.id}
                      >
                        <div className="answerInput">
                          <FormControl fullWidth>
                            <InputField
                              id="answer"
                              fullWidth={true}
                              type="text"
                              value={singleAnswer?.answer}
                              placeholder="Add Answer Here..."
                              onChange={(e) =>
                                handleOnChangeQuestionForm(
                                  e,
                                  singleQuestion,
                                  singleAnswer,
                                  "question"
                                )
                              }
                            />
                          </FormControl>
                        </div>

                        <div className="correctBox">
                          <div
                            className={
                              singleAnswer?.isCorrected
                                ? "correctanswer selected"
                                : "correctanswer"
                            }
                            onClick={() =>
                              correctAnswer(singleQuestion, singleAnswer)
                            }
                          >
                            {" "}
                            Correct Answer
                          </div>
                        </div>
                        {singleAnswer?.isShowDefault && (
                          <div
                            className="clear"
                            onClick={() =>
                              cancelAnswers(singleQuestion, singleAnswer)
                            }
                          >
                            x
                          </div>
                        )}

                        <div></div>
                      </div>
                    </li>
                  </>
                ))}
              </ul>
              {singleQuestion.anasError || isquizans ? (
                <p className="correctError">
                  Please select the answer or fill the option
                </p>
              ) : null}
              <div className="">
                <Button
                  varientTextGreen={true}
                  label="Add Answer"
                  disabled={singleQuestion?.answers.length === 4 ? true : false}
                  onClick={() => addAnswers(singleQuestion)}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid className="cancelQuestion" item mb={3}>
          <Buttons
            variantOutline={true}
            onClick={() => cancelQuestion(singleQuestion)}
            label="Remove"
          ></Buttons>
        </Grid>
      </div>
    </>
  );
};

export default AddNewQuestion;
