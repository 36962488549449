import { getApiUrl } from "../config";
import { post, get } from "./axiosRequest";
import { ORG_ID } from "../constant/constant";

export const updateUserDashboardCart = async (data) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Dashboard/UpdateUserDashboardCart?orgId=${ORG_ID()}`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const userDashboardCartList = async ({ data }) => {
  const { userId } = data;

  const url = `${
    getApiUrl().adminApiUrl
  }/Dashboard/GetUserDashboardCartList?userId=${userId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const dashboardCartList = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Dashboard/GetDashboardCartList?orgId=${ORG_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
