import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import parse from "html-react-parser";

const UtilityDamageDetail = ({ addDetails }) => {
  return (
    <div className="commonOveride">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">Additional Details:</Typography>
            <Typography className="listView desNone">
              <span className="font-w400 overRideTextEditor">
                {parse(unescape(addDetails?.additionalDetails))}
              </span>
            </Typography>
          </Box>
          <Box className="commoninfoRight"></Box>
        </Box>
      </Box>
    </div>
  );
};

export default UtilityDamageDetail;
