import React, { useState, useEffect } from "react";
import { Grid, FormControl } from "@mui/material";
import Button from "../../../library/custom/button/Button";
import CommonForm from "../commonForm";
import { useDispatch, useSelector } from "react-redux";

import {
  getQuesDynamicField,
  revokeQuesDynamicField,
  postInpectionData,
  getInspectionDetails,
  updateInpectionData,
  getInspectionAcknowledgement,
} from "../../../redux/actions/incpectionAction";
import * as assetsAction from "../../../redux/actions/assetsAction";
import QuestionSet from "../questionSet";
import {
  questSetJson,
  skipQuestions,
  questionsAnswerValidation,
  formValidation,
  questionsAnswerDetails,
  questSetEditJson,
} from "../../../utils/incpectionHelper";
import commonFormHoc from "../../../hoc/commonFormHoc";
import {
  getFormatedDate,
  convertTime,
  getCurrentMinuteSecond,
} from "../../../utils/helper";
import { useLocation, useOutletContext } from "react-router-dom";
import vehicleAccident from "../../../constant/accidentConstant";
import {
  API_STATUS,
  LNG_ID,
  ORG_ID,
  USER_ID,
} from "../../../constant/constant";
import { FullPageLoader } from "../../../library/common/Loader";
import commonImages from "../../../assets";
import { checkPrivileges } from "../../../utils/rolesHelper";

const todatDate = new Date();

const BasicInspectionForm = ({
  cardClicStatickHandler,
  form,
  onChange,
  onChangeDatePicker,
  setForm,
  onChangeAsset,
  onChangeComment,
  hideprojectNameEdit,
  userClear,
  ...rest
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = useLocation();
  const pathN = pathname?.split("/");
  const InspectionId = pathN[4] ?? null;
  const questsetId = pathN[2] ?? null;
  const edit = !!pathN[3] ?? false;
  // const [edit, setEdit] = useState(!!pathN[3]);
  const [employeeSignature, setEmployeeSignature] = useState(false);
  const editassetTypeValue = location?.state?.assetTypeValue;
  const statusId = location?.state?.statusId;
  const [checked, setChecked] = useState(false);
  const [checkedMessage, setCheckedMessage] = useState();

  const {
    quesSetDetails,
    // weather,
    dynamicFeild,
    isSuccess,
    // inspEditDetails,
    isInUpdateError,
    updateMsgError,
    isInLoading,
  } = useSelector((state) => state.inspection);

  const assetdtaa = useSelector((state) => state.getUserAssetList);
  const defaultSubData = {
    type: 5,
  };

  const inspectionAcknowledgementData = useSelector(
    (state) => state.getInspectionAcknowledgement
  );
  const getInspectionAcknowledgementData = (data) => {
    dispatch(getInspectionAcknowledgement(data));
  };

  const inspEditDetails = useSelector(
    (state) => state?.inspectionDetailData.inspEditDetails
  );

  const users = useSelector((state) => state?.users?.data?.users ?? "");
  const assetOption = useSelector((state) => state.getAssetDynomoList.data);
  const isLoading = useSelector((state) => state.getAssetDynomoList.isLoading);
  const inspEditDetailsLoding = useSelector(
    (state) => state?.inspectionDetailData.isInLoading
  );

  const weather = vehicleAccident;
  const [qestSetData, setQestSetData] = useState([]);
  const [qestSetState, setQestSetState] = useState([]);
  const [commonFormError, setCommonFormError] = useState({});
  const quesSetId = useOutletContext();
  const searchAsset = (searchText, type) => {
    dispatch(assetsAction.getAssetDynomoList({ searchText, type }));
  };
  const blankCommonForm = React.useCallback(
    (editData) => {
      const weat = (editData && editData?.weatherTimeofIns) ?? null;
      const projectId = (editData && editData?.project) ?? null;
      const timeOfIns = (editData && editData?.timeOfIns) ?? null;
      const currentWeat = {
        key: 1,
        value: "Dry",
      };
      setForm({
        assetId: checkPrivileges([12, 139, 144])
          ? { key: editData?.assetId, value: editData?.asset ?? "" }
          : editData?.assetId ?? "",
        location: editData && editData?.location ? editData?.location : "",
        generalComment: editData?.generalComment
          ? editData?.generalComment
          : " ",
        preparedBy: localStorage.getItem("name") ?? "",
        dateOfInspection:
          editData && editData?.dateOfIns
            ? new Date(editData?.dateOfIns)
            : todatDate,
        timeOfInspection: getCurrentMinuteSecond(timeOfIns),
        selectedVehicleAccident:
          editData && weat && weather
            ? weather?.filter((we) => we.value === weat)[0]
            : currentWeat,
      });
    },
    [setForm, weather]
  );
  const usersStatus = useSelector((state) => state.users);
  useEffect(() => {
    if (!edit && questsetId) {
      const data = {
        questSetId: questsetId,
        organisationId: ORG_ID(),
        lngId: LNG_ID,
      };
      if (usersStatus.status === API_STATUS.SUCCESS) {
        dispatch(getQuesDynamicField({ data }));
      }
    }
  }, [questsetId, edit, usersStatus]);

  useEffect(() => {
    const data = {
      questSetId: questsetId,
      organisationId: ORG_ID(),
      lngId: LNG_ID,
    };
    dispatch(getQuesDynamicField({ data }));
  }, [questsetId]);
  useEffect(() => {
    if (parseInt(questsetId) === parseInt(quesSetId.current) && !edit) {
      blankCommonForm();
      setCommonFormError();
      dispatch(revokeQuesDynamicField());
    }
  }, [questsetId, blankCommonForm, quesSetId, edit]);

  useEffect(() => {
    setQestSetData(quesSetDetails);
    if (!edit) {
      dataBindUp(quesSetDetails);
    } else {
      dataEditBindUp(quesSetDetails, edit, inspEditDetails, users);
      blankCommonForm(inspEditDetails);
    }
  }, [quesSetDetails, edit, inspEditDetails, users, blankCommonForm]);

  const skipQuestion = React.useCallback(
    (e, value, key, parentElementData) => {
      e.stopPropagation();
      const skipData = skipQuestions(
        value,
        key,
        parentElementData,
        qestSetState,
        setQestSetData
      );
      setQestSetState(skipData);
    },
    [qestSetState]
  );

  const dataBindUp = (data) => {
    const modifyJson = questSetJson(data);
    setQestSetState(modifyJson);
  };
  const dataEditBindUp = (data, edit, editData, users) => {
    const editModify = questSetEditJson(
      data,
      edit,
      editData,
      users,
      setQestSetData
    );

    setQestSetState(editModify);
  };
  const [assetError, setAssetError] = useState(false);
  const [assetLocationError, setAssetLocationError] = useState(false);
  const [characterLimit, setCharaterLimit] = useState(false);

  const validationHandler = () => {
    let validation = false;

    if (!checked) {
      setCheckedMessage(true);
    } else {
      setCheckedMessage(false);
    }
    if (
      checkPrivileges([12, 139, 144]) ? !form?.assetId?.key : !form?.assetId
    ) {
      setAssetError(true);
      validation = true;
    } else {
      setAssetError(false);
      validation = false;
    }
    if (!form?.location) {
      setAssetLocationError(true);
      validation = true;
    } else {
      setAssetLocationError(false);
      validation = false;
    }
    if (form.generalComment.length > 2000) {
      setCharaterLimit(true);
      validation = true;
    } else {
      setCharaterLimit(false);
      validation = false;
    }

    const addQuesSet = JSON.parse(JSON.stringify(qestSetState));
    addQuesSet.forEach((qes) => {
      const isSubSkipped = qes?.subcategories?.some(
        (itede) => itede?.skip?.IsSubCategory
      );
      if (
        !qes?.skip?.IsCategory &&
        qes?.catogeryCompleted == 0 &&
        !isSubSkipped
      ) {
        qes.catError = true;
        validation = true;
      } else {
        qes.catError = false;
        if (!qes?.skip?.IsCategory) {
          qes?.subcategories?.forEach((subQue) => {
            if (!subQue?.skip?.IsSubCategory && subQue?.percentDone != 100) {
              subQue.subError = true;
              validation = true;
            } else {
              subQue.subError = false;
            }
          });
        }
      }
    });
    setQestSetState(addQuesSet);
    return validation;
  };

  const onSubmitForm = (event, action) => {
    let isSubmit = action === "submit" || action === "update";
    let validationStatus = validationHandler();
    if (isSubmit && validationStatus) {
      return false;
    }

    event.preventDefault();
    const userId = localStorage.getItem("userId");
    const key = pathname?.split("/")[2] ?? null;
    if (userId && key) {
      const commonForm = {
        inspectionId: 0,
        ...(edit && { inspectionId: parseInt(InspectionId ?? null) }),
        ...(edit && { modifiedBy: (userId && parseInt(userId)) ?? null }),
        questionSetId: (key && parseInt(key)) ?? null,
        status: action === "draft" || action === "update_draft" ? 1 : 5,
        preparedBy: (userId && parseInt(userId)) ?? null,
        inspectionDate: getFormatedDate(form.dateOfInspection) ?? null,
        inspectionTime: convertTime(form.timeOfInspection) ?? null,
        location: form.location ?? null,
        assetId: checkPrivileges([12, 139, 144])
          ? form?.assetId?.key ?? null
          : form?.assetId ?? null,
        assetType: edit ? editassetTypeValue : quesSetId?.assetTypeValue,
        weatherId: form?.selectedVehicleAccident?.key ?? null,
        comment: form.generalComment ?? " ",
      };

      const dynamicFields = [];
      const skippedCategorySubcategory = [];
      qestSetState &&
        qestSetState.forEach((item) => {
          if (item?.skip?.IsCategory === true) {
            const items = {
              questionSetId: parseInt(key),
              isCategory: true,
              questCategorySubcategoryId: parseInt(item.key),
              questCategoryId: parseInt(item?.key),
              createdBy: parseInt(USER_ID()),
            };
            skippedCategorySubcategory.push(items);
          } else {
            item &&
              item?.subcategories?.forEach((subItem) => {
                if (subItem?.skip?.IsSubCategory === true) {
                  const subItems = {
                    questionSetId: parseInt(key),
                    isCategory: false,
                    questCategorySubcategoryId: parseInt(subItem.key),
                    questCategoryId: parseInt(item?.key),
                    createdBy: parseInt(USER_ID()),
                  };
                  skippedCategorySubcategory.push(subItems);
                }
                subItem &&
                  subItem?.questions?.forEach((quest) => {
                    const acceptFile =
                      (quest &&
                        quest?.dataForm &&
                        (quest?.dataForm?.acceptable?.commentFeild?.file ??
                          null)) ??
                      undefined;
                    const notacceptFile =
                      (quest &&
                        quest?.dataForm &&
                        (quest?.dataForm?.notacceptable?.issuefound?.file ??
                          null)) ??
                      undefined;
                    if (Object.keys(acceptFile ?? {}).length > 0) {
                      delete acceptFile.fileTypeId;
                      dynamicFields.push({
                        fieldId: acceptFile?.fileId ?? null,
                        fieldValue: acceptFile?.filePath ?? null,
                      });
                    }
                    if (Object.keys(notacceptFile ?? {}).length > 0) {
                      delete notacceptFile.fileTypeId;
                      dynamicFields.push({
                        fieldId: notacceptFile?.fileId ?? null,
                        fieldValue: notacceptFile?.filePath ?? null,
                      });
                    }
                  });
              });
          }
        });

      const { commonFormValid, errorKey } = formValidation(form, action);
      const { valid, errors } = questionsAnswerValidation(qestSetState, action);
      setCommonFormError(errorKey);
      setQestSetState(errors);
      const listInspectionCategory = questionsAnswerDetails(
        qestSetState,
        filesId
      );
      const formData = {
        ...commonForm,
        listInspectionCategory,
      };

      if (action === "submit" && valid && commonFormValid) {
        if (
          inspectionAcknowledgementData?.data?.result[0]?.status &&
          !checked
        ) {
          setCheckedMessage(true);
        } else {
          dispatch(postInpectionData(formData));
          setCheckedMessage(false);
        }
      } else if (action === "draft") {
        dispatch(postInpectionData(formData));
      } else if (action === "update" && valid && commonFormValid) {
        if (
          inspectionAcknowledgementData?.data?.result[0]?.status &&
          !checked
        ) {
          setCheckedMessage(true);
        } else {
          dispatch(postInpectionData(formData));
          setCheckedMessage(false);
        }
      } else if (action === "update_draft") {
        dispatch(postInpectionData(formData));
      }
    }
    // }
  };
  const [filesId, setFilesId] = useState([]);
  const [filesUrl, setFilesUrl] = useState([]);

  const fileUploads = (files, single) => {
    setFilesUrl(files);
    setFilesId(single);
  };
  const loading = inspEditDetailsLoding;

  useEffect(() => {
    getInspectionAcknowledgementData(defaultSubData);
  }, []);

  return (
    <>
      {loading && <FullPageLoader />}
      {isInUpdateError && updateMsgError && (
        <Grid container mt={5}>
          <p>{updateMsgError?.message}</p>
        </Grid>
      )}
      {!isInUpdateError && (
        <CommonForm
          onChange={onChange}
          onChangeDatePicker={onChangeDatePicker}
          cardClicStatickHandler={cardClicStatickHandler}
          form={form}
          weather={weather}
          assetOption={assetOption}
          searchAsset={searchAsset}
          isLoading={isLoading}
          onChangeAsset={onChangeAsset}
          onChangeComment={onChangeComment}
          hideprojectNameEdit={hideprojectNameEdit}
          dynamicFeild={dynamicFeild}
          commonFormError={commonFormError}
          assetTypeValue={edit ? editassetTypeValue : quesSetId?.assetTypeValue}
          assetName={edit ? inspEditDetails?.asset : ""}
          assetNamekey={edit ? inspEditDetails?.assetId : ""}
          edit={edit}
          pathN={pathN[2]}
          assetError={assetError}
          assetLocationError={assetLocationError}
          characterLimit={characterLimit}
          assetdtaa={assetdtaa}
          statusId={statusId}
        />
      )}

      {!isInUpdateError && qestSetData && (
        <QuestionSet
          skipQuestion={skipQuestion}
          qestSetState={qestSetState}
          setQestSetState={setQestSetState}
          qestSetData={qestSetData}
          setImage={fileUploads}
          filesId={filesId}
          filesUrl={filesUrl}
          edit={edit}
          assetId={form?.assetId}
          assetTypeValue={edit ? editassetTypeValue : quesSetId?.assetTypeValue}
          userClear={userClear}
        />
      )}

      {inspectionAcknowledgementData?.data?.result[0]?.status && (
        <Grid item container spacing={2} mb={3} mt={3}>
          <Grid item lg={10} textAlign="left">
            <FormControl fullWidth>
              <div className="typeTxt">
                <img
                  className="checkImage"
                  title={checked ? "tickCheckbox" : "untickCheckbox"}
                  alt=""
                  src={checked ? commonImages.check : commonImages.noCheck}
                  onClick={() => setChecked(!checked)}
                />
                <span className="associatedIncidentLabel ml-1">
                  {inspectionAcknowledgementData?.data?.result[0]?.value}
                </span>
              </div>
            </FormControl>
          </Grid>

          <Grid item lg={12} textAlign="left">
            {checkedMessage && (
              <div className="error">Please select this required field.</div>
            )}
          </Grid>
        </Grid>
      )}

      {!isInUpdateError && (
        <>
          {/* <Grid container mt={2}>  // TODO
            <Grid item lg={3} textAlign="left">
              <div className="formLabelStyle">Add Inspector's Signature</div>
              <Button
                variantOutline={true}
                disabled={employeeSignature}
                label="Add Signature"
                onClick={() => setEmployeeSignature(!employeeSignature)}
              />
              {employeeSignature && (
                <p className="colorGreen">Signature Authorized successfully</p>
              )}
            </Grid>
          </Grid> */}
          <Grid container mt={3} className="insBtnAdd">
            <Grid container textAlign="right" alignItems="center">
              <Grid item lg={4}></Grid>
              <Grid item lg={3}>
                <Button
                  varientText={true}
                  label="Save as Draft"
                  onClick={(e) =>
                    onSubmitForm(e, edit ? "update_draft" : "draft")
                  }
                />
              </Grid>
              <Grid item lg={5} textAlign="center">
                <Button
                  varientContained={true}
                  label={edit ? "submit" : "Submit"}
                  onClick={(e) => onSubmitForm(e, edit ? "update" : "submit")}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default React.memo(commonFormHoc(BasicInspectionForm));
