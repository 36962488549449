import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EnterOtpImage from "../../assets/otp.svg";
import { maxLengthCheck } from "../../utils/helper";
import Button from "../button/button";

import "./otp-box.scss";
import { useDispatch, useSelector } from "react-redux";
import * as onOtpActions from "../../redux/actions/signupAction";
import { API_STATUS } from "../../constant/constant";
import { LOGIN } from "../../constant/routeContant";
import { resendOtp } from "../../services/signupService";

const OtpBox = ({ mobile }) => {
  const userOtp = useSelector((state) => state.otpSignup);
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const showModal = useRef(false);
  const dispatch = useDispatch();

  const setShowModal = (flag) => {
    showModal.current = flag;
    if (showModal.current) {
      modalRef.current.classList.add("visible");
    } else {
      modalRef.current.classList.remove("visible");
    }
  };

  const [otp, setOtp] = useState("");
  const INITIAL_COUNT = 30;
  const twoDigits = (num) => String(num).padStart(2, "0");
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;

  // otp timeout
  const handleStart = async () => {
    setStatus(STATUS.STARTED);
    setSecondsRemaining(INITIAL_COUNT);
  };

  const STATUS = {
    STARTED: "Started",
    STOPPED: (
      <b>
        <a type="button" onClick={handleStart} className="show-danger">
          Resend code
        </a>
      </b>
    ),
  };

  const [status, setStatus] = useState(STATUS.STOPPED);
  const [otpError, setOtpError] = useState("");

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
  );

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // set up the interval
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  /* Initially everything is invalid */
  let defaultErrorStates = {
    otp: true,
  };

  /*
    Dynamically update the
    form whenever user inputs stuff
    */
  const untouchedForm = useRef(true);
  const isFormValid = useRef(false);

  const [errorList, setErrorList] = useState(defaultErrorStates);

  /* Validate Form */
  const validateForm = () => {
    let newErrorList = {
      ...errorList,
      otp: otp.trim().length === 6,
    };

    setErrorList({ ...newErrorList });

    /* This validation is not based on state */
    let temporaryValidation = true;
    for (const key in newErrorList) {
      temporaryValidation = temporaryValidation && newErrorList[key];
    }
    /*
    Saving it in this ref as well for
      when we don't want to revalidate the
      whole thing
      */
    isFormValid.current = temporaryValidation;
    return temporaryValidation;
  };

  useEffect(() => {
    if (untouchedForm.current) {
      return;
    }
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);
  useEffect(() => {
    setOtpError("");
    if (
      userOtp &&
      userOtp.otpstatus === true &&
      userOtp.status === API_STATUS.SUCCESS
    ) {
      navigate(LOGIN);
    } else if (
      userOtp &&
      userOtp.error &&
      userOtp.status === API_STATUS.FAILURE
    ) {
      setOtpError(userOtp.error.message);
    }
  }, [userOtp]);

  /* Next Button */
  const submitButton = async (event) => {
    event.preventDefault();

    /*
      The form is no longer untouched,
      which means we can now do real-time verificaiton
      */
    untouchedForm.current = false;

    if (validateForm()) {
      untouchedForm.current = true;
      dispatch(onOtpActions.otpsignup(mobile, otp));
    }
  };

  const resendCode = async () => {
    try {
      const result = await resendOtp(mobile);
      if (result) {
        console.log("Otp Successfully Sent!");
      }
    } catch (err) {
      setOtpError("Otp Sent Failed!!");
    }
  };

  const otpFilter = (event, setter) => {
    setter(event.target.value.replace(/[^\d]+$/g, ""));
  };
  useEffect(() => {
    setShowModal(true);
  });

  return (
    <div className="common-hamburger">
      <div ref={modalRef} className="common-hamburger-menu">
        <div className="common-hamburger-menu-wrap">
          <div className="common-hamburger-page">
            <div className="common-hamburger-page-wrap">
              <div className="common-hamburger-page-wrap-header">
                <img
                  src={EnterOtpImage}
                  alt="bg"
                  style={{ height: "200px", width: "302px" }}
                />
                <h1>Mobile Verification</h1>
                <p>
                  Enter the 6 digit Verification code sent to your registered
                  Mobile
                </p>
                <form onSubmit={submitButton}>
                  <div className="form-wrapper">
                    <input
                      style={{ width: "302px" }}
                      className="verify-otp"
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]"
                      value={otp}
                      maxLength="6"
                      onInput={maxLengthCheck}
                      onChange={(e) => otpFilter(e, setOtp)}
                    />
                    {errorList.otp ? null : (
                      <div className="show-error">
                        Verification failed. Please check code and re-enter.
                      </div>
                    )}
                    {otpError && <div className="show-error">{otpError}</div>}
                  </div>

                  <div className="verify-mobile-button">
                    <Button
                      label="Verify Code"
                      onButtonClickHandler={submitButton}
                    />
                  </div>
                  {status === STATUS.STARTED ? (
                    <div className="forgot-password">
                      <b
                        style={{
                          color: "#666666",
                        }}
                      >
                        Resend code in
                      </b>

                      <b style={{ color: "#666666", marginLeft: "2px" }}>
                        {twoDigits(minutesToDisplay)}:
                        {twoDigits(secondsToDisplay)}
                      </b>
                    </div>
                  ) : (
                    <h2 onClick={resendCode} className="forgot-password">
                      {status}
                    </h2>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpBox;
