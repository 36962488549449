import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as divisionsAction from "../../../redux/actions/divisionsAction";
import { useLocation } from "react-router-dom";
import Loader from "../../../library/common/Loader";
import * as contractorAction from "../../../redux/actions/contractorAction";
import {
  API_STATUS,
  DIVISION_ID,
  ORG_ID,
  PAGE_SIZE,
  USERID,
} from "../../../constant/constant";
import ViewDivision from "../component/divisions/viewDivision";
import * as projectAction from "../../../redux/actions/projectAction";
import * as equipmentsAction from "../../../redux/actions/equipmentsAction";
import * as vehiclesAction from "../../../redux/actions/vehiclesAction";
import * as assetsAction from "../../../redux/actions/assetsAction";
import * as userAction from "../../../redux/actions/userAction";
import { showRighSideMenu } from "../../../utils/rolesHelper";

const DivisionsViewContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const divisionIdGet = location?.state?.divisionId;
  const divisionId = divisionIdGet;
  const showMenu = showRighSideMenu();

  const viewDivisionsData = useSelector((state) => state?.viewDivisions.data);
  const showLoader = useSelector((state) => state.viewDivisions.isLoading);
  const isSuccess = useSelector(
    (state) => state.viewDivisions.status === API_STATUS.SUCCESS
  );

  const defaultProjectData = {
    organisationId: ORG_ID(),
    divisionId: divisionId,
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: USERID,
  };

  const projectsListData = useSelector((state) => state.projectList);
  const isLoading = useSelector((state) => state.projectList?.isLoading);
  const getProjectListData = (data) => {
    dispatch(projectAction.getProjectList(data));
  };

  const defaultEquipmentData = {
    organisationId: ORG_ID(),
    divisionId: divisionId,
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    statusIds: "",
    userId: USERID,
  };
  const equipmentList = useSelector((state) => state.equipmentList);
  const isEquipmentLoading = useSelector(
    (state) => state.equipmentList?.isLoading
  );

  const getEquipmentList = (data) => {
    dispatch(equipmentsAction.getEquipmentList(data));
  };
  const defaultContractorData = {
    page: 1,
    pageSize: 10,
    search: "",
    gradeId: "",
    divisionId: divisionId,
  };

  const ContractorListData = useSelector((state) => state.getContractorList);
  const getContractorListData = (data) => {
    dispatch(contractorAction.getContractorList(data));
  };

  const defaultVehicleData = {
    organisationId: ORG_ID(),
    divisionId: divisionId,
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    userId: USERID,
  };

  const vehicleList = useSelector((state) => state.vehicleList);
  const isVehicleLoading = useSelector((state) => state.vehicleList?.isLoading);
  const getVehicleList = (data) => {
    dispatch(vehiclesAction.getVehicleList(data));
  };

  const defaultAssetData = {
    organisationId: ORG_ID(),
    divisionId: divisionId,
    page: 1,
    pageSize: PAGE_SIZE,
    statusIds: "",
    search: "",
    userId: USERID,
  };
  const assetList = useSelector((state) => state.assetList);
  const isAssetLoading = useSelector((state) => state.assetList?.isLoading);
  const getAssetListData = (data) => {
    dispatch(assetsAction.getAssetList(data));
  };

  const userListData = useSelector((state) => state.userlistData);
  const getuserLists = (data) => {
    dispatch(userAction.getUserList(data));
  };
  const defaultUserData = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    orgId: ORG_ID(),
    divisionId: divisionId,
    IsProcoreUsers: false,
    designation:"",
  };

  const GroupListData = useSelector((state) => state.GroupListData);

  const getuserGroupLists = (data) => {
    dispatch(userAction.getListGroupLists(data));
  };

  const defaultGroupData = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    divisionId: divisionId ? divisionId : 0,
    allGroups: localStorage.getItem("divisionId") === "null" ? true : false,
  };

  const tabClick = (id) => {
    if (id === "7") {
      getContractorListData(defaultContractorData);
    }
    if (id === "6") {
      getuserGroupLists(defaultGroupData);
    }
    if (id === "5") {
      getuserLists(defaultUserData);
    }
    if (id === "4") {
      getAssetListData(defaultAssetData);
    }
    if (id === "3") {
      getVehicleList(defaultVehicleData);
    }
    if (id === "2") {
      getEquipmentList(defaultEquipmentData);
    }
    if (id === "1" && showMenu?.isSafety) {
      getProjectListData(defaultProjectData);
    }
  };

  useEffect(() => {
    getProjectListData(defaultProjectData);
    dispatch(divisionsAction.getDivisionsDetails(divisionId));
  }, [dispatch]);

  return (
    <>
      {showLoader && <Loader />}
      {isSuccess && (
        <ViewDivision
          projectsListData={projectsListData?.data}
          getProjectListData={getProjectListData}
          isLoading={isLoading}
          viewDivisionsData={viewDivisionsData}
          equipmentList={equipmentList?.data}
          getEquipmentList={getEquipmentList}
          isEquipmentLoading={isEquipmentLoading}
          vehicleList={vehicleList?.data}
          getVehicleList={getVehicleList}
          isVehicleLoading={isVehicleLoading}
          assetList={assetList?.data}
          isAssetLoading={isAssetLoading}
          getAssetListData={getAssetListData}
          tabClick={tabClick}
          divisionId={divisionId}
          loading={userListData.isLoading}
          userListData={userListData?.data}
          getuserLists={getuserLists}
          GroupListData={GroupListData?.data?.result?.groupData}
          noOfRecords={GroupListData?.data?.result?.noOfRecords}
          getuserGroupLists={getuserGroupLists}
          loadingGroup={GroupListData?.isLoading}
          ContractorListData={ContractorListData?.data}
          loadingContractor={ContractorListData?.isLoading}
          getContractorListData={getContractorListData}
        />
      )}
    </>
  );
};

export default DivisionsViewContainer;
