import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { PRODUCT_ID } from "../constant/storm-water/constant";
import { post, get, deletes } from "./axiosRequest";

export const createRole = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Role/AddRole`;
  console.log("createRole" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateRoleList = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Role/UpdateRole`;
  console.log("updateRole" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getRoleList = async (data) => {
  const { search } = data.data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Role/GetPrivilegeList?search=${search}&productId=${PRODUCT_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const createAssignRole = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/User/UserRoleMapping`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getRoleListDetails = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Role/GetRoleDetails?roleId=${data}&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteRole = async (payload) => {
  const url = `${getApiUrl().adminApiUrl}/Role/DeleteRole`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
      data: {
        roleId: payload.roleId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUserInRoleList = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/Role/GetUserInRoleList?userId=${
    data.userId
  }&serviceType=${PRODUCT_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
