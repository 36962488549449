import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FromToDatePicker from "../../library/custom/datePicker/DatePicker";
import "./emailExportCard.scss";
import {
  ASSET_TYPE,
  ITEM_TYPE,
  ORIGIN,
  STATUS_TYPE,
  USER_ID,
} from "../../constant/constant";
import Checkbox from "../../library/checkbox/checkbox";
import {
  getFormatedDate,
  getCurrentDate,
} from "../../utils/helper";
import downloadIcon from "../../../src/assets/downloadIcon.svg";
import { checkPrivileges } from "../../utils/rolesHelper";

function EmailExportCard({
  id,
  open,
  anchorEl,
  onClose,
  getExportItemListData,
  OnClickCancelButton,
}) {
  const [originSelect, setOriginSelect] = useState([]);
  const [assetType, setAssetType] = useState([]);
  const [itemType, setItemType] = useState([]);
  const [satatus, setSatatus] = useState([]);
  const [selectAll, setSelectAll] = useState([]);
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isClient = userInfo?.isClient;

  const OnchangeStartDate = (e) => {
    setStartDate(e);
  };

  const OnchangeEndDate = (e) => {
    setEndDate(e);
  };

  const OnChangeOrigin = (e, filterItem) => {
    if (e.target.checked) {
      setOriginSelect([...originSelect, e.target.value]);
    } else {
      setOriginSelect(originSelect?.filter((item) => item !== e.target.value));
    }
  };
  const OnChangeAssetType = (e) => {
    if (e.target.checked) {
      setAssetType([...assetType, e.target.value]);
    } else {
      setAssetType(assetType?.filter((item) => item !== e.target.value));
    }
  };

  const OnChangeItemType = (e) => {
    if (e.target.checked) {
      setItemType([...itemType, e.target.value]);
    } else {
      setItemType(itemType?.filter((item) => item !== e.target.value));
    }
  };

  const OnChangeStatus = (e) => {
    if (e.target.checked) {
      setSatatus([...satatus, e.target.value]);
    } else {
      setSatatus(satatus?.filter((item) => item !== e.target.value));
    }
  };
  const [chekedAllCheckbox, setChekedAllCheckbox] = useState(false);
  const OnChangeSelectAll = (e) => {
    setChekedAllCheckbox(!chekedAllCheckbox);
    if (e.target.checked) {
      setOriginSelect(["1", "2", "3", "4"]);
      setAssetType(["1", "2", "3", "4"]);
      setItemType(["1", "2", "3", "4"]);
      setSatatus(["2", "3"]);
    } else {
      setOriginSelect([]);
      setAssetType([]);
      setItemType([]);
      setSatatus([]);
    }
  };

  const onClickExportToMail = () => {
    const data = {
      userId: checkPrivileges([12, 117]) ? 0 : parseInt(USER_ID()),
      isExportToMail: true,
      origin: originSelect ? originSelect.map((item) => +item) : "",
      itemType: itemType ? itemType.map((item) => +item) : "",
      assetType: assetType ? assetType.map((item) => +item) : "",
      status: satatus ? satatus.map((item) => +item) : "",
      startDate: startDate ? getFormatedDate(startDate) : null,
      endDate: endDate ? getFormatedDate(endDate) : null,
    };

    getExportItemListData(data);
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "Center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card className="emailExportOverride">
          <CardContent>
            <Grid container>
              <Grid item lg={12} md={7}>
                <Box className="" sx={{ pb: 2 }}>
                  <Typography gutterBottom className="headingOfSubtitle">
                    Time Period
                  </Typography>
                  <Box className="datePicker_row_emailExport">
                    <div>
                      <Typography variant="subtitle1">From</Typography>
                      <FromToDatePicker
                        maxDateEnable={true}
                        value={startDate}
                        onChangeHandler={(e) => OnchangeStartDate(e)}
                      />
                    </div>
                    <div>
                      <Typography variant="subtitle1">To</Typography>
                      <FromToDatePicker
                        minDate={startDate}
                        maxDateEnable={true}
                        value={endDate}
                        onChangeHandler={(e) => OnchangeEndDate(e)}
                      />
                    </div>
                  </Box>
                </Box>

                <Box className="" sx={{ pb: 2 }}>
                  <Typography gutterBottom className="headingOfSubtitle">
                    Origin
                  </Typography>
                  <Box
                    sx={{ pl: 1.3, pb: 1 }}
                    className="checkboxEmailExport exportCheckbox"
                  >
                    <FormGroup>
                      <Grid container>
                        {ORIGIN.map((filterItem, index) => (
                          <Grid item lg={3} xs={12} sm={3} md={3} mt={1}>
                            <div className="typeTxt">
                              <Checkbox
                                checked={
                                  originSelect?.findIndex(
                                    (item) => parseInt(item) === filterItem.key
                                  ) >= 0
                                }
                                value={filterItem?.key}
                                onChange={OnChangeOrigin}
                                label={filterItem.value}
                              />
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Box>
                </Box>

                <Box className="" sx={{ pb: 2 }}>
                  <Typography gutterBottom className="headingOfSubtitle">
                    Asset Type
                  </Typography>
                  <Box
                    sx={{ pl: 1.3, pb: 1 }}
                    className="checkboxEmailExport exportCheckbox"
                  >
                    <FormGroup>
                      <Grid container>
                        {ASSET_TYPE.map((filterItem, index) => (
                          <Grid item lg={3} xs={12} sm={3} md={3} mt={1}>
                            <div className="typeTxt">
                              <Checkbox
                                checked={
                                  assetType?.findIndex(
                                    (item) => parseInt(item) === filterItem.key
                                  ) >= 0
                                }
                                value={filterItem?.key}
                                onChange={OnChangeAssetType}
                                label={filterItem.value}
                              />
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Box>
                </Box>

                <Box className="" sx={{ pb: 2 }}>
                  <Typography gutterBottom className="headingOfSubtitle">
                    Item Type
                  </Typography>
                  <Box
                    sx={{ pl: 1.3, pb: 1 }}
                    className="checkboxEmailExport exportCheckbox"
                  >
                    <FormGroup>
                      <Grid container>
                        {ITEM_TYPE.map((filterItem, index) => (
                          <Grid item lg={3} xs={12} sm={3} md={3} mt={1}>
                            <div className="typeTxt">
                              <Checkbox
                                checked={
                                  itemType?.findIndex(
                                    (item) => parseInt(item) === filterItem.key
                                  ) >= 0
                                }
                                value={filterItem?.key}
                                onChange={OnChangeItemType}
                                label={filterItem.value}
                              />
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Box>
                </Box>

                <Box className="" sx={{ pb: 1 }}>
                  <Typography gutterBottom className="headingOfSubtitle">
                    Status
                  </Typography>
                  <Box
                    sx={{ pl: 1.3, pb: 3 }}
                    className="checkboxEmailExport exportCheckbox"
                  >
                    <FormGroup>
                      <Grid container>
                        {STATUS_TYPE.map((filterItem, index) => (
                          <Grid item lg={3} xs={12} sm={3} md={3} mt={1}>
                            <div className="typeTxt">
                              <Checkbox
                                checked={
                                  satatus?.findIndex(
                                    (item) => parseInt(item) === filterItem.key
                                  ) >= 0
                                }
                                value={filterItem?.key}
                                onChange={OnChangeStatus}
                                label={filterItem.value}
                              />
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <Box
            sx={{ pl: 3.5, pb: 2 }}
            className="checkboxEmailExport exportCheckbox"
          >
            <FormGroup>
              <div className="SelectAllConainerStyle">
                <div className="selectAllCheckBoxStyles">
                  <Grid item lg={4} xs={12} sm={3} md={3} mt={1}>
                    <div className="typeTxt">
                      <Checkbox
                        checked={chekedAllCheckbox}
                        onChange={OnChangeSelectAll}
                        label={"Select All"}
                      />
                    </div>
                  </Grid>
                </div>
                <div>
                  <div className="ButtonandExportMailContainer">
                    <div>
                      <Grid
                        item
                        lg={3}
                        xs={12}
                        sm={3}
                        md={3}
                        mt={1}
                        className="mailExportButtonStyle"
                      >
                        <Button
                          className="mailExportButtonStyles"
                          type="text"
                          onClick={OnClickCancelButton}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </div>
                    <div>
                      <Grid item lg={3} xs={12} sm={3} md={3} mt={1}>
                        <div
                          className="ExportTomailConainer"
                          onClick={onClickExportToMail}
                        >
                          <div className="downloadExportImage">
                            <img src={downloadIcon} alt="" />
                          </div>
                          <div className="ExportTomailText">Export To Mail</div>
                        </div>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </FormGroup>
          </Box>
        </Card>
      </Popover>
    </>
  );
}

export default EmailExportCard;
