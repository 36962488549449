import React, { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import "./TextEditor.scss";
import { FormHelperText } from "@mui/material";

export default function TextEditor({
  id = "",
  value,
  onChange,
  isError = false,
  errorMsg = "",
  placeholder,
  enableDragAndDropFileToEditor = false,
  insertImageAsBase64URI = false,
  showXPathInStatusbar = false,
  showCharsCounter = false,
  showWordsCounter = false,
  toolbarAdaptive = false,
  toolbarSticky = false,
  removeDefaultButtons = [
    "brush",
    "file",
    "image",
    "fullsize",
    "link",
    "table",
    "paint",
    "source",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "image",
    "table",
    "link",
    "hr",
    "eraser",
    "fullsize",
    "undo",
    "redo",
    "ol",
  ],
  defaultButtons = [
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "|",
    "left",
    "center",
    "right",
    "justify",
  ],
}) {
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      width: "100%",
      enableDragAndDropFileToEditor: enableDragAndDropFileToEditor,
      placeholder: placeholder || "Start typings...",
      buttons: defaultButtons,
      uploader: { insertImageAsBase64URI: insertImageAsBase64URI },
      removeButtons: removeDefaultButtons,
      showXPathInStatusbar: showXPathInStatusbar,
      showCharsCounter: showCharsCounter,
      showWordsCounter: showWordsCounter,
      toolbarAdaptive: toolbarAdaptive,
      toolbarSticky: toolbarSticky,
    }),
    []
  );

  return (
    <div className="overideTextArea">
      <div className="textAreaBody">
        {isError && value?.length === 0 && (
          <FormHelperText className="errorText">{errorMsg}</FormHelperText>
        )}

        <JoditEditor
          id={id}
          ref={editor}
          value={value}
          config={config}
          tabIndex={1}
          onBlur={(content) => onChange(content, editor)}
        />
      </div>
    </div>
  );
}
