import React, { useEffect, useState } from "react";
import ItemManagement from "../components/itemManagement";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import {
  API_STATUS,
  MODULES_NAME,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import { getFormatedDate } from "../../../../utils/helper";
import Loader from "../../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import * as assetsAction from "../../../../redux/actions/assetsAction";
import { useLocation } from "react-router-dom";
import * as swCommonAction from "../../../../redux/storm-water/actions/swCommonAction";
import * as clientAction from "../../../../redux/actions/clientAction";

const ItemManagementContainer = () => {
  const dispatch = useDispatch();
  const [timeRange, setTimeRange] = useState(1);
  const [calculatedDate, setCalculatedDate] = useState(new Date());
  const [cartType, setCartType] = useState(1);
  const [selectionModel, setSelectionModel] = useState();
  const [commentBox, setCommentBox] = useState(false);
  const [closeBox, setCloseBox] = useState(false);
  const [disbled, setDisbled] = useState(false);
  const [clearComment, setClearComment] = useState(false);
  const [clearClosed, setClearClosed] = useState(false);
  const todayDate = new Date();
  const [selectedRows, setSelectedRows] = useState([]);
  const adminView = checkPrivileges([12, 105]);

  const location = useLocation();
  const stateData = location?.state;
  const [clients, setClients] = useState();
  const refData = {
    flags: [9],
  };
  const getSwReference = useSelector((state) => state.getSwReference);
  const getSwReferenceData = (data) => {
    dispatch(swCommonAction.getSwReference(data));
  };
  const searchAllClientsReducerData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchDataLoading = searchAllClientsReducerData.isLoading;
  useEffect(() => {
    getSwReferenceData(refData);
  }, []);

  useEffect(() => {
    if (timeRange != 1) {
      const days = parseInt(timeRange);
      const today = new Date();
      const calculated = new Date(today);
      calculated.setDate(today.getDate() - (days - 1));
      setCalculatedDate(calculated);
    }
    if (timeRange == 1) {
      setCalculatedDate("");
    }
  }, [timeRange]);

  const defaultData = {
    organisationId: clients ? clients : parseInt(ORG_ID()),
    cartType: cartType,
    search: "",
    projectId: 0,
    userId: adminView ? "" : parseInt(USER_ID()),
    fromDate: calculatedDate !== "" ? getFormatedDate(calculatedDate) : "",
    toDate: getFormatedDate(todayDate),
    page: 1,
    pageSize: PAGE_SIZE,
  };
  const commentIntialState = {
    comment: "",
  };
  // uat deploy
  const closeIntialState = {
    dateClosed: todayDate,
    actionTaken: "",
    closedId: "",
    closedIdValue: "",
  };
  const [commentFormValue, setCommentFormValue] = useState(commentIntialState);
  const [closeFormValue, setCloseFormValue] = useState(closeIntialState);
  const [isClosedIdError, setIsClosedIdError] = useState(false);
  const openItemList = useSelector((state) => state.swOpenItemList);
  const getOpenItemList = (data) => {
    dispatch(swInspectionAction.getSwOpenItemList(data));
  };
  const createSwInspectionCloseItem = useSelector(
    (state) => state.createSwInspectionCloseItem
  );
  const createSwInspectionCloseItemData = (data) => {
    dispatch(swInspectionAction.createSwInspectionCloseItem(data));
  };

  const createSwInspectionItemComment = useSelector(
    (state) => state.createSwInspectionItemComment
  );
  const createSwInspectionItemCommentData = (data) => {
    dispatch(swInspectionAction.createSwInspectionItemComment(data));
  };

  const isLoading = useSelector((state) => state.swOpenItemList?.isLoading);

  const showLoader = useSelector(
    (state) =>
      state.createSwInspectionCloseItem.isLoading ||
      state.createSwInspectionItemComment.isLoading
  );

  function handleAllProjectSearch(event, value) {
    dispatch(
      assetsAction.getAssetDynomoList({
        searchText: event.target.value,
        type: 5,
        orgId: clients
          ? clients
          : stateData?.customerId
          ? stateData?.customerId
          : parseInt(ORG_ID()),
      })
    );
  }

  const allProject = useSelector((state) => state.getAssetDynomoList);
  const isProjectLoading = allProject?.isLoading;

  const handleTimeRangeSetChange = (e) => {
    setTimeRange(e.target.value);
  };

  const getItemHander = (id) => {
    setCartType(id);
  };

  const openItemHandler = (value) => {
    const closevalue = getSwReference?.data?.ItemClosedActionList?.find(
      (item) => item.key === value
    );
    setCloseFormValue({
      ...closeFormValue,
      closedId: closevalue?.key,
      closedIdValue: closevalue?.value,
    });
  };

  useEffect(() => {
    if ((cartType || calculatedDate) && !stateData) {
      getOpenItemList(defaultData);
    }
  }, [cartType, calculatedDate, stateData]);

  const [closesFilesData, setCloseFilesData] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isMedia = userInfo?.isMediaAllowed;
  const handleFileChange = (files, single) => {
    setCloseFilesData({ fileId: single[0], filePath: files });
  };
  const handleDeleteImageURL = () => {
    setCloseFilesData([]);
  };

  const [isComment, setIsComment] = useState(false);
  function mappedCommentData() {
    let errors = false;
    if (commentFormValue?.comment === "") {
      setIsComment(true);
      errors = true;
    }
    if (errors) {
      return false;
    }

    const data = {
      comment: commentFormValue?.comment,
      userId: parseInt(USER_ID()),
      locationId: selectionModel[0],
    };
    return data;
  }
  const submitCommentData = () => {
    const data = mappedCommentData();
    if (!data) {
    } else {
      setClearComment(true);
      createSwInspectionItemCommentData(data);
    }
  };
  const [isDateClosed, setIsDateClosed] = useState();
  const [isActionTaken, setIsActionTaken] = useState();

  function mappedCloseData() {
    let errors = false;
    if (!closeFormValue?.dateClosed) {
      setIsDateClosed(true);
      errors = true;
    }
    // if (!closeFormValue?.closedId) {
    //   setIsClosedIdError(true);
    //   errors = true;
    // }
    // if (closeFormValue?.actionTaken === "") {
    //   setIsActionTaken(true);
    //   errors = true;
    // }
    if (errors) {
      return false;
    }

    const dataClose = {
      listLocationId: selectionModel,
      dateClosed: getFormatedDate(closeFormValue?.dateClosed),
      actionTaken: closeFormValue?.actionTaken,
      closedId: closeFormValue?.closedId
        ? parseInt(closeFormValue?.closedId)
        : null,
      userId: parseInt(USER_ID()),
      files: [
        {
          fileId: closesFilesData?.fileId ?? 0,
          filePath: closesFilesData?.filePath ?? null,
          subrootId: 0,
        },
      ],
    };
    return dataClose;
  }

  const submitCloseData = () => {
    const dataClose = mappedCloseData();
    if (!dataClose) {
    } else {
      setClearClosed(true);
      createSwInspectionCloseItemData(dataClose);
    }
  };

  useEffect(() => {
    if (selectionModel?.length > 1) {
      setDisbled(true);
    } else {
      setDisbled(false);
    }
  }, [selectionModel]);
  const closeItemHandler = () => {
    setCloseBox(!closeBox);
    setCommentBox(false);
    setCommentFormValue({
      ...commentFormValue,
      comment: "",
    });
  };

  const commentHandler = () => {
    setCloseBox(false);
    setCommentBox(!commentBox);
  };
  const cancelComment = () => {
    setCommentBox(false);
    setCommentFormValue({
      ...commentFormValue,
      comment: "",
    });
  };
  const cancelClose = () => {
    setCloseBox(false);
  };

  useEffect(() => {
    if (
      createSwInspectionItemComment.status === API_STATUS.SUCCESS &&
      createSwInspectionItemComment?.isLoading === false &&
      clearComment
    ) {
      getOpenItemList(defaultData);
      toast("Comment Added successfully");
      setCommentBox(false);
      setCloseBox(false);
      setCommentFormValue({
        ...commentFormValue,
        comment: "",
      });
      setCloseFormValue({
        ...closeFormValue,
        dateClosed: "",
        actionTaken: "",
      });
      setSelectedRows([]);
      setSelectionModel([]);
    }
  }, [createSwInspectionItemComment]);

  useEffect(() => {
    if (
      createSwInspectionCloseItem.status === API_STATUS.SUCCESS &&
      createSwInspectionCloseItem?.isLoading === false &&
      clearClosed
    ) {
      getOpenItemList(defaultData);
      toast("Closed Item successfully");
      setCloseBox(false);
      setCommentBox(false);
      setCloseFormValue({
        ...closeFormValue,
        dateClosed: "",
        actionTaken: "",
      });
      setCommentFormValue({
        ...commentFormValue,
        comment: "",
      });
      setSelectedRows([]);
      setSelectionModel([]);
    }
  }, [createSwInspectionCloseItem]);

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(
      selectionModel.map((selectedId) =>
        openItemList?.data?.listDetails?.find(
          (row) => row.itemId === selectedId
        )
      )
    );
  };

  useEffect(() => {
    if (selectedRows) {
      const itemIds = selectedRows?.map((item) => item.itemId);
      setSelectionModel(itemIds);
    }
  }, [selectedRows]);
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };
  return (
    <>
      {showLoader && <Loader />}
      <ItemManagement
        handleTimeRangeSetChange={handleTimeRangeSetChange}
        timeRange={timeRange}
        openItemList={openItemList?.data ?? []}
        isLoading={isLoading}
        getOpenItemList={getOpenItemList}
        cartType={cartType}
        todayDate={todayDate}
        calculatedDate={calculatedDate}
        getItemHander={getItemHander}
        selectionModel={selectionModel}
        disbled={disbled}
        setSelectionModel={setSelectionModel}
        isComment={isComment}
        commentFormValue={commentFormValue}
        setCommentFormValue={setCommentFormValue}
        submitCommentData={submitCommentData}
        commentBox={commentBox}
        closeBox={closeBox}
        closeItemHandler={closeItemHandler}
        commentHandler={commentHandler}
        cancelComment={cancelComment}
        cancelClose={cancelClose}
        isDateClosed={isDateClosed}
        isActionTaken={isActionTaken}
        closeFormValue={closeFormValue}
        setCloseFormValue={setCloseFormValue}
        submitCloseData={submitCloseData}
        setCartType={setCartType}
        selectedRows={selectedRows}
        handleSelectionModelChange={handleSelectionModelChange}
        handleFileChange={handleFileChange}
        closesFilesData={closesFilesData}
        handleDeleteImageURL={handleDeleteImageURL}
        isMedia={isMedia}
        handleAllProjectSearch={handleAllProjectSearch}
        isProjectLoading={isProjectLoading}
        allProjects={allProject?.data}
        stateData={stateData}
        getSwReference={getSwReference?.data}
        openItemHandler={openItemHandler}
        isClosedIdError={isClosedIdError}
        searchClient={searchClient}
        searchAllClientsReducerData={searchAllClientsReducerData}
        autoSearchDataLoading={autoSearchDataLoading}
        setClients={setClients}
        clients={clients}
      />
      <ToastContainer />
    </>
  );
};

export default ItemManagementContainer;
