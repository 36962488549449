import React, { useEffect, useState } from "react";
import DailyReport from "../components/dailyReport";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../library/common/Loader";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { USER_ID } from "../../../../constant/constant";

const DailyReportContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const locationData = location?.state?.row;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [fuelStorage, setFuelStorage] = useState("");
  const [constructionEntrance, setConstructionEntrance] = useState("");
  const [fuelStorageError, setFuelStorageError] = useState(false);
  const [constructionEntranceError, setConstructionEntranceError] =
    useState(false);
  const [selectFuelStorage, setSelectFuelStorage] = useState();
  const [selectConstructionEntrance, setSelectConstructionEntrance] =
    useState();

  const [isSubmit, setIsSubmit] = useState(false);

  const onChangConstructionEntrance = (value) => {
    setSelectConstructionEntrance(value);
  };
  const onChangFuelStorage = (value) => {
    setSelectFuelStorage(value);
  };

  const [editingLogId, setEditingLogId] = useState(null);
  const handleEditClick = (item) => {
    setEditingLogId(item?.logId);
    setSelectConstructionEntrance(item?.constructionEntrance);
    setSelectFuelStorage(item?.fuelStorage);
  };

  const handleCancelClick = () => {
    setEditingLogId(null);
  };
  const handleSaveClick = (item) => {
    updateData();
  };

  const fuelStorageHandler = (value) => {
    setFuelStorage(value);
  };

  const constructionEntranceHandler = (value) => {
    setConstructionEntrance(value);
  };

  const updateSwInspection = useSelector((state) => state.updateSwInspection);
  const updateInspectionDailyReport = useSelector(
    (state) => state.updateInspectionDailyReport
  );

  const inspectionDailyReportDetails = useSelector(
    (state) => state.getInspectionDailyReportDetails
  );
  const getInspectionDailyReportDetailsData = (data) => {
    dispatch(swInspectionAction.getInspectionDailyReportDetails(data));
  };

  const showLoader = useSelector(
    (state) =>
      state.getInspectionDailyReportDetails?.isLoading ||
      state.updateSwInspection?.isLoading ||
      state.updateInspectionDailyReport?.isLoading
  );
  function mappedData() {
    let errors = false;
    if (!fuelStorage) {
      setFuelStorageError(true);
      errors = true;
    }
    if (!constructionEntrance) {
      setConstructionEntranceError(true);
      errors = true;
    }

    if (errors) {
      return false;
    }

    const data = {
      preparedBy: parseInt(USER_ID()),
      inspectionId: locationData?.reportId,
      setupId: locationData?.setupId,
      fuelStorage: parseInt(fuelStorage),
      constructionEntrance: parseInt(constructionEntrance),
      status: 8,
      listInspectionCategory: null,
    };
    return data;
  }
  const [clearUpdateSwInspection, setClearUpdateSwInspection] = useState(false);
  const [
    clearUpdateInspectionDailyReport,
    setClearUpdateInspectionDailyReport,
  ] = useState(false);

  const onSubmit = (data) => {
    setClearUpdateSwInspection(true);
    dispatch(swInspectionAction.updateSwInspection(data));
  };

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const onUpdate = (data) => {
    setClearUpdateInspectionDailyReport(true);
    dispatch(swInspectionAction.updateInspectionDailyReport(data));
  };

  const updateData = () => {
    const data = {
      inspectionId: locationData?.reportId,
      updateDailyReportLog: [
        {
          dailyLogId: editingLogId,
          fuelStorage: selectFuelStorage,
          constructionEntrance: selectConstructionEntrance,
        },
      ],
    };
    onUpdate(data);
  };

  useEffect(() => {
    if (locationData) {
      const data = {
        inspectionId: locationData?.reportId,
      };
      getInspectionDailyReportDetailsData(data);
    }
  }, [locationData]);

  useEffect(() => {
    if (
      updateSwInspection?.status === "SUCCESS" &&
      updateSwInspection?.isLoading === false &&
      clearUpdateSwInspection
    ) {
      toast("Daily report updated successfully");
      setFuelStorage("");
      setConstructionEntrance("");
      setIsSubmit(false);
      setFuelStorageError(false);
      setConstructionEntranceError(false);
      const data = {
        inspectionId: locationData?.reportId,
      };
      getInspectionDailyReportDetailsData(data);
    }
  }, [updateSwInspection]);

  useEffect(() => {
    if (
      updateInspectionDailyReport?.status === "SUCCESS" &&
      updateInspectionDailyReport?.isLoading === false &&
      clearUpdateInspectionDailyReport
    ) {
      toast("Daily report updated successfully");
      setEditingLogId(null);
      const data = {
        inspectionId: locationData?.reportId,
      };
      getInspectionDailyReportDetailsData(data);
    }
  }, [updateInspectionDailyReport]);

  return (
    <>
      {showLoader && <Loader />}

      <DailyReport
        locationData={locationData}
        userInfo={userInfo}
        fuelStorage={fuelStorage}
        constructionEntrance={constructionEntrance}
        fuelStorageHandler={fuelStorageHandler}
        constructionEntranceHandler={constructionEntranceHandler}
        inspectionDailyReportDetails={
          inspectionDailyReportDetails?.data?.dailyReportInspectionDetails
        }
        submitData={submitData}
        isSubmit={isSubmit}
        fuelStorageError={fuelStorageError}
        constructionEntranceError={constructionEntranceError}
        handleEditClick={handleEditClick}
        handleCancelClick={handleCancelClick}
        handleSaveClick={handleSaveClick}
        editingLogId={editingLogId}
        onChangConstructionEntrance={onChangConstructionEntrance}
        onChangFuelStorage={onChangFuelStorage}
        selectFuelStorage={selectFuelStorage}
        selectConstructionEntrance={selectConstructionEntrance}
      />
      <ToastContainer />
    </>
  );
};

export default DailyReportContainer;
