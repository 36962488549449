import React, { useState, useEffect } from "react";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import Buttons from "../../../../library/custom/button/Button";
import { useNavigate } from "react-router-dom";
import "./samplinglog.scss";
import ToolBar from "../../../../library/storm-water/toolBar";
import commonImages from "../../../../assets";
import { useDispatch, useSelector } from "react-redux";
import * as projectAction from "../../../../redux/storm-water/actions/projectAction";
import { Grid } from "@mui/material";

import {
  SETUP_INSPECTION,
  SETUP_INSPECTION_LIST,
  SW_CONTACT_PROJECT,
  SW_PERMIT_LIST,
  SW_OPEN_ITEMS,
  SW_RAIN_FALL_DATA,
  SW_INSPECTION_LIST,
  INSPECTION_AUTHORITY,
  ADMIN_ADD_STORM_WATERPROJECT,
  ADMIN_RESOURCE,
} from "../../../../constant/routeContant";
import { ORG_ID } from "../../../../constant/constant";

function SamplingLogsVersion({
  ProjectSampleData,
  stateId,
  projectName,
  projectNumber,
  projectId,
  customerId,
}) {
  const dispatch = useDispatch();

  const dataBack = {
    title: "Back to Projects",
    route: -1,
  };
  const history = useNavigate();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [noofreecord, setNoofreecord] = useState(0);

  const numOfRecords = useSelector(
    (state) => state.swprojectSampleList?.data?.itemsCount
  );

  const toolBarData = {
    title: "Project Tools",
    list: [
      {
        id: 10,
        title: "Edit Project",
        link: "",
      },
      {
        id: 1,
        title: "Project Contact",
        link: "",
      },
      {
        id: 2,
        title: "Inspection Service List",
        link: "",
      },
      {
        id: 9,
        title: "Setup Inspections",
        link: "",
      },
      {
        id: 3,
        title: "Permits",
        link: "",
      },
      {
        id: 11,
        title: "Sampling Log",
        link: "",
        activeClass: "active",
      },
      {
        id: 5,
        title: "Open Items",
        link: "",
      },
      {
        id: 6,
        title: "Rainfall Data",
        link: "",
      },
      {
        id: 7,
        title: "Inspection Records",
        link: "",
      },

      {
        id: 8,
        title: "Inspection Authority",
        link: "",
      },
      {
        id: 12,
        title: "Project Resources",
        link: "",
      },
    ],
  };
  const lastRowIndex = ProjectSampleData?.sampleLog?.length - 1;

  console.log("ProjectSampleData", ProjectSampleData);
  useEffect(() => {
    dispatch(
      projectAction.swProjectSampleList({
        projectId: projectId ? projectId : "",
        page: page,
        pageSize: 10,
      })
    );
    setNoofreecord(page - 1);
  }, [page]);

  const downloaddata = (val) => {
    window.open(val, "_blank");
  };
  const customerName = localStorage?.getItem("companyName");
  return (
    <>
      <section className="grid_main_sec sw">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Sample Log History</div>
        </div>
        <Grid container>
          <Grid lg={8} md={12} xs={12} pr={2} item>
            <Grid item container lg={12} xs={12} md={8} mt={6}></Grid>
            <Grid container mt={1}>
              <div className="otherDetail2">
                <h2 className="addedbybold">
                  <span className="viewversion">View Previous Version</span>
                </h2>
              </div>
            </Grid>
            <Grid container mt={2} xs={12} lg={12} className="versionhistory">
              {ProjectSampleData?.sampleLog?.map((val, index) => {
                return (
                  <>
                    {" "}
                    <div className="versionhistory_Root">
                      <div className="item version">
                        <p>
                          {"version" +
                            " " +
                            (ProjectSampleData?.itemsCount -
                              noofreecord * 10 -
                              index)}
                        </p>
                      </div>
                      <div className="item addedby">
                        <div className="version1">
                          <p>Added By : </p>
                        </div>
                        <p className="versionname"> {val.createdByName}</p>
                      </div>
                      <div className="item version_date">
                        <p>Date of Entry : {val?.sampleDate}</p>
                      </div>
                      <div className="item version_icon">
                        <img
                          src={commonImages?.download}
                          alt="download"
                          onClick={() => downloaddata(val?.report)}
                        />
                      </div>
                    </div>
                    {index == lastRowIndex ? (
                      ""
                    ) : (
                      <hr className="sepratorversion"></hr>
                    )}
                  </>
                );
              })}
            </Grid>

            <Grid
              item
              lg={12}
              container
              justifyContent={"right"}
              alignItems={"center"}
              mt={3}
            >
              <Buttons
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
                varientText
                label={"<"}
                className="paginationButton"
              />
              <span className="paginationText">
                {page}/{Math.round(numOfRecords / 10) + 1 || 1}
              </span>
              <Buttons
                disabled={ProjectSampleData?.sampleLog.length < 10}
                onClick={() => setPage(page + 1)}
                varientText
                label={">"}
                className="paginationButton"
              />
            </Grid>
          </Grid>

          <Grid lg={4} md={12} xs={12} pl={1} item>
            <div className="toolBarBox">
              <div className="otherDetail">
                <h3>Projects Details</h3>
                <h2>{projectName}</h2>
                <p>{projectNumber}</p>
              </div>

              <div className="otherDetail">
                <h3>Client Name</h3>
                <h2>{customerName}</h2>
              </div>

              <ToolBar
                toolBarData={toolBarData}
                handleClick={(e) => {
                  if (e.id === 1) {
                    navigate(SW_CONTACT_PROJECT, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                      },
                    });
                  }
                  if (e.id === 2) {
                    navigate(SETUP_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                      },
                    });
                  }
                  if (e.id === 9) {
                    navigate(SETUP_INSPECTION, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        rightSideMode: true,
                      },
                    });
                  }
                  if (e.id === 3) {
                    navigate(SW_PERMIT_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectid: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 5) {
                    navigate(SW_OPEN_ITEMS, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 6) {
                    navigate(SW_RAIN_FALL_DATA, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 7) {
                    navigate(SW_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 8) {
                    navigate(INSPECTION_AUTHORITY);
                  }
                  if (e.id === 10) {
                    navigate(ADMIN_ADD_STORM_WATERPROJECT, {
                      state: {
                        editMode: true,
                        projectid: projectId,
                      },
                    });
                  }
                  if (e.id === 12) {
                    navigate(ADMIN_RESOURCE, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  } else return;
                }}
              />
            </div>
          </Grid>
        </Grid>
      </section>
      <div className="insBackBtn1">
        <BackBtn dataBack={dataBack} />
      </div>
    </>
  );
}

export default SamplingLogsVersion;
