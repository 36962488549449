import { getApiUrl } from "../config";
import { get } from "./axiosRequest";
import {  ORG_ID } from "../constant/constant";

export const getUserCourseView = async (userId, courseId) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Course/GetCourseDetailsWithLessonList?userId=${userId}&courseId=${courseId}&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
