import React, { useState } from "react";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { getFormatedDate } from "../../../utils/helper";
import { Grid } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import Button from "../../../library/custom/button/Button";
import Employee from "../../incidents/add-incident/component/employee";
import Witness from "../../../library/custom/witness/Witness";
import CreateAction from "../../../library/custom/createAction/CreateAction";
import { useEffect } from "react";
import { GET_USER_TYPE, INCIDENT_STATUS } from "../../../constant/constant";
import intl from "react-intl-universal";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
const todatDate = new Date();
const NearMissesForm = ({
  incident,
  caseData,
  onSubmit,
  searchOption,
  nearMissesData,
  incidentId,
  caseId,
  fileUploadData,
  uploadFile,
  editMode,
  isLoading,
  searchUsers,
  searchAllUsers,
  allUsers,
  getAssetOnUser,
  assetOnUserList,
  getSupervisorOnAsset,
  supervisorOnAssetList,
  assetOnUserData,
  fileError,
  countError,
}) => {
  const userId = localStorage.getItem("userId");
  const { contributingFactors, associatedIncidentType } = incident;

  const intialState = {
    employeeSignature: false,
    supervisorSignature: false,
    location: "",
    addDetails: "",
    dateOfCase: getFormatedDate(new Date()),
    timeOfCase: todatDate.getHours() + ":" + todatDate.getMinutes(),
    caseDescription: "",
    type: false,
    employeeType: "No",
    witnessType: "No",
  };
  const [formValue, setFormValue] = useState(intialState);

  const [employeeList, setEmployeeList] = useState([
    {
      supervisor: 0,
      employeeName: "",
      jobTitle: "",
      startData: "",
      jobSiteProjectName: 0,
      contributingFactors: [],
      descriptionOfIncident: "",
      equipmentInspected: null,
      JHACompleted: null,
      associatedJHA: null,
      employeesTrained: null,
      similarCases: "",
      id: Date.now() + Math.floor(Math.random() * 100),
      isErrorEmployeeName: false,
      errorEmployeeNameMsg: "",
      isErrorJobSiteProjectName: false,
      errorJobSiteProjectNameMsg: "",
      isErrorDescriptionOfIncident: false,
      isavoidedinthefuture: false,
      isavoidedinthefutureMsg: "",
      errorDescriptionOfIncidentMsg: "",
      userId: "",
      supervisorList: [],
      jobSiteList: [],
      disableEmpName: false,
      nmEmployeeId: 0,
      jobSiteProjectNameError: false,
      supervisorError: false,
      selectEmployee: [],
    },
  ]);
  useEffect(() => {
    if (nearMissesData && incidentId) {
      const {
        employes,
        witness,
        actions,
        additionalDetails,
        isWitness,
      } = nearMissesData;
      setWitnessType(isWitness);
      if (witness.length > 0) {
        const witnessList = [];
        witness.forEach((items, index) => {
          witnessList.push({
            employerName: items.employerName,
            witnessName: items.witnessName,
            jobTitle: items.jobTitle,
            statement: items.statement,
            files: items.files,
            id: items.witnessId,
          });
        });
        setWitnessList(witnessList);
      }
      intialState.addDetails = additionalDetails;
      intialState.type = true;
      intialState.employeeType = "Yes";
      setFormValue(intialState);

      if (actions.length) {
        const actionList = [];
        actions.forEach((items, index) => {
          actionList.push({
            isFileAdded: items.isFileAdded,
            files: items.files,
            description: items.description,
            assignedPartyName: items.assignedPartyName,
            assignedPartyId: items.assignedPartyId,
            id: items.actionId,
            fileId: items?.files?.map((files) => files.fileId),
          });
        });

        setActionlist(actionList);
      }

      const newVehicleList = [];

      employes?.forEach((items, index) => {
        newVehicleList?.push({
          employeeName: items?.employeeName ?? "",
          selectEmployee: [
            { userId: items?.userId, firstName: items?.employeeName },
          ],
          userId: items?.userId,
          componentId: items?.componentId,
          supervisor: items?.supervisorId,
          nmEmployeeId: items?.nmEmployeeId,
          jobTitle: items?.jobTitle,
          startData: items?.startDate,
          disableEmpName: true,
          id: index,
          jobSiteProjectName: items?.projectId,
          contributingFactors: items?.contributingFactorsKeyValue?.map(
            (item) => item.value
          ),
          descriptionOfIncident: items?.description,
          equipmentInspected: items?.isToolInspected,
          JHACompleted: items?.isJhaCompleted,
          associatedJHA: items?.isHazardsIdentified,
          employeesTrained: items?.isEmployeesTrained,
          similarCases: items?.howSimilarCaseAvoided,
          vehiclePictuer: [],
          supervisorList: [
            { key: items?.supervisorId, value: items?.supervisorName },
          ],
          jobSiteList: [{ key: items?.projectId, value: items?.jobSite }],
        });
      });

      if (assetOnUserList?.length > 0) {
        newVehicleList?.forEach((items) => {
          items.disableEmpName = false;
          items.jobSiteList = assetOnUserList;
        });
      }
      if (supervisorOnAssetList?.length > 0) {
        newVehicleList?.forEach((items) => {
          items.disableEmpName = false;
          items.supervisorList = supervisorOnAssetList;
        });
      }

      if (assetOnUserData?.length > 0) {
        newVehicleList?.forEach((items) => {
          items.jobTitle = assetOnUserData?.jobTitle ?? "";
          items.startData = assetOnUserData?.startDate;
        });
      }

      setEmployeeList(newVehicleList);
    }
  }, [nearMissesData]);
  const [isEmployeeDisplay, setIsEmployeeDisplay] = useState(true);

  const employeeOnChanges = (e, ref, data) => {
    const name = e.target?.name;
    let elementId = e.target?.id;
    let value = e.target?.value;
    if (ref) {
      elementId = ref.current.id;
      value = e;
    } else {
      elementId = e.target?.id;
      value = e.target?.value;
    }

    let updatedvehicleList = [...employeeList];
    updatedvehicleList.forEach((vehicleLists) => {
      if (vehicleLists.id === data.id) {
        if (e instanceof Date) {
          vehicleLists.startData = getFormatedDate(e);
        }
        if (name === "contributingFactors") {
          vehicleLists.contributingFactors = value;
        }
        if (elementId === "makeAndModel") {
          vehicleLists.makeAndModel = value;
        }
        if (name === "projectName") {
          vehicleLists.jobSiteProjectName = value;
        }
        if (elementId === "descriptionOfIncident") {
          vehicleLists.descriptionOfIncident = value;
        }
        if (elementId === "similarCases") {
          vehicleLists.similarCases = value;
        }
        if (
          elementId === "equipmentInspectedYes" ||
          elementId === "equipmentInspectedNo"
        ) {
          vehicleLists.equipmentInspected = JSON.parse(value);
        }
        if (elementId === "JHACompletedYes" || elementId === "JHACompletedNo") {
          vehicleLists.JHACompleted = JSON.parse(value);
        }
        if (
          elementId === "associatedJHAYes" ||
          elementId === "associatedJHANo"
        ) {
          vehicleLists.associatedJHA = JSON.parse(value);
        }
        if (
          elementId === "employeesTrainedYes" ||
          elementId === "employeesTrainedNo"
        ) {
          vehicleLists.employeesTrained = JSON.parse(value);
        }
        if (name === "supervisor") {
          vehicleLists.supervisor = value;
        }
      }
    });
    setEmployeeList(updatedvehicleList);
  };

  const cancelEmployee = (id) => {
    const removeEmployee = employeeList.filter(
      (employee) => employee.id !== id
    );
    setEmployeeList(removeEmployee);
  };

  const [witnessType, setWitnessType] = useState(false);
  const [witnessList, setWitnessList] = useState([
    {
      id: Date.now() + Math.floor(Math.random() * 100),
      witnessName: "",
      employerName: "",
      jobTitle: "",
      statement: "",
      signature: "",
      files: [],
      isErrorWitnessName: false,
      isErrorStatement: false,
      errorWitnessNameMsg: "",
      errorStatementMsg: "",
    },
  ]);

  const handleOnclickWitness = () => {
    if (witnessList.length < 4) {
      const newWitnessList = [...witnessList];
      newWitnessList.push({
        id: Date.now() + Math.floor(Math.random() * 100),
        witnessName: "",
        employerName: "",
        jobTitle: "",
        statement: "",
        signature: "",
        files: [],
        isErrorWitnessName: false,
        isErrorStatement: false,
        errorWitnessNameMsg: "",
        errorStatementMsg: "",
      });
      setWitnessList(newWitnessList);
    }
  };

  const handleOnChangeWitnessForm = (event, data, type) => {
    const elementId = event.target.id;
    const value = event.target.value;
    let updatedWitnessList = [...witnessList];
    updatedWitnessList.forEach((witnessLists) => {
      if (witnessLists.id === data.id) {
        if (elementId === "name") {
          witnessLists.witnessName = value;
        } else if (elementId === "jobTitle") {
          witnessLists.jobTitle = value;
        } else if (elementId === "statement") {
          witnessLists.statement = value;
        } else if (elementId === "employerName") {
          witnessLists.employerName = value;
        } else if (elementId === "uploadMedia") {
          witnessLists.files = value;
        }
      }
    });
    setWitnessList(updatedWitnessList);
  };

  const [actionList, setActionlist] = useState([
    {
      assignedPartyName: "",
      file: [],
      isFileAdded: false,
      selectedFileName: "",
      description: "",
      id: Date.now() + Math.floor(Math.random() * 100),
      isCompleted: false,
      fileId: null,
      isError: false,
      isFileError: false,
    },
  ]);

  const cancelWitnessList = (id) => {
    const removeWitnessList = witnessList.filter(
      (witness) => witness.id !== id
    );
    setWitnessList(removeWitnessList);
  };

  const handleEmployeeName = (e, value, data) => {
    let updatedEmployeeList = [...employeeList];
    updatedEmployeeList.forEach((employeeLists) => {
      if (employeeLists.id === data.id) {
        employeeLists.employeeName = `${value?.firstName} ${value?.lastName}`;
        employeeLists.jobTitle = "";
        employeeLists.startData = "";
        employeeLists.userId = value?.userId;
        employeeLists.supervisorList = value?.supervisor;
        employeeLists.jobSiteList = value?.jobSite;
        employeeLists.jobSiteProjectName = null;
        employeeLists.supervisor = null;
        if (!employeeLists.selectEmployee) {
          employeeLists.selectEmployee = [];
        }
        employeeLists.selectEmployee.push({
          userId: value?.userId ?? "",
          firstName: value?.firstName ?? "",
          lastName: value?.lastName ?? "",
        });

        employeeLists.selectEmployee = employeeLists.selectEmployee.map(
          (emp) => {
            return {
              ...emp,
              userId: value?.userId ?? "",
              firstName: value?.firstName ?? "",
              lastName: value?.lastName ?? "",
            };
          }
        );
      }
    });
    setEmployeeList(updatedEmployeeList);
  };

  const handleChangeCreateActionDesc = (value, data) => {
    let updatedActionList = actionList;
    updatedActionList.forEach((singleValue) => {
      if (singleValue.id === data.id) {
        singleValue[`description`] = value;
      }
    });
    setActionlist(updatedActionList);
  };

  const handleAddAssigneeCta = (event, users, data) => {
    let updatedActionList = actionList;
    updatedActionList.forEach((singleValue) => {
      if (singleValue.id === data.id) {
        singleValue[`assignedPartyName`] = users?.value || {};
        singleValue[`assignedPartyId`] = users?.key || 0;
        singleValue.isError = false;
        singleValue.isFileError = false;
      }
    });
    setActionlist(updatedActionList);
  };

  const [selectedData, setSelectedData] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileUploaded, setSelectedFileUploaded] = useState([]);

  const handleUploadFileMethod = (fileUploaded, fileName, data) => {
    setSelectedData(data);
    setSelectedFileName(fileName);
    setSelectedFileUploaded(fileUploaded);
    uploadFile(fileUploaded);
  };

  useEffect(() => {
    if (fileUploadData) {
      let updatedActionList = [...actionList];
      updatedActionList.forEach((actionLists) => {
        if (actionLists.id === selectedData.id) {
          actionLists.file = selectedFileUploaded;
          actionLists.isFileAdded = true;
          actionLists.fileId = [fileUploadData?.files[0].id];
          actionLists.selectedFileName = selectedFileName;
          actionLists.isFileError = false;
        }
      });
      setActionlist(updatedActionList);
    }
  }, [fileUploadData]);

  useEffect(() => {
    if (countError) {
      let updatedActionList = [...actionList];
      updatedActionList.forEach((actionLists) => {
        if (actionLists.id === selectedData.id) {
          actionLists.isFileError = true;
        }
      });
      setActionlist(updatedActionList);
    }
  }, [countError]);

  const handleCreateActionCancelCta = (data) => {
    const newActionList = actionList.filter(
      (singleAction) => data.id !== singleAction.id
    );
    setActionlist(newActionList);
  };

  const removeActionImage = (singleAction) => {
    const actionListClone = [...actionList];
    actionListClone.forEach((Actionlist) => {
      if (Actionlist?.id === singleAction?.id) {
        Actionlist.fileId = null;
        Actionlist.file = [];
        Actionlist.isFileAdded = false;
        Actionlist.selectedFileName = "";
        Actionlist.isFileError = false;
      }
    });
    setActionlist(actionListClone);
  };

  const handleOnclickAddAction = () => {
    if (actionList.length < 4) {
      const newActionList = [...actionList];
      newActionList.push({
        assignedPartyName: "",
        file: null,
        selectedFileName: "",
        description: "",
        id: Date.now() + Math.floor(Math.random() * 100),
        isCompleted: false,
        isError: false,
        isFileError: false,
      });
      setActionlist(newActionList);
    }
  };

  const [filesData, setFileeswws] = useState("");
  const [witnessData, setFileeswwss] = useState("");

  const witnessFileUpload = (files, single) => {
    setFileeswws(files);
    setFileeswwss(single);
  };
  useEffect(() => {
    let updatedWitnessList = [...witnessList];
    updatedWitnessList.forEach((witnessLists) => {
      if (witnessLists.id === witnessData.id) {
        witnessLists.files = filesData;
      }
    });
    setWitnessList(updatedWitnessList);
  }, [filesData]);
  const handleDeleteWitnessImage = (urls, singleWitness) => {
    const cloneWitness = [...witnessList];
    cloneWitness.forEach((singleWitn) => {
      if (singleWitn.id === singleWitness.id) {
        singleWitn.files = singleWitn.files
          .split("|")
          .filter((url) => url !== urls)
          .join("|");
      }
    });
    setWitnessList(cloneWitness);
  };

  function findValueMultipleSelect(arrrayofObject, selectedArray) {
    let valueArray = [];
    arrrayofObject.forEach((data) => {
      if (selectedArray.indexOf(data.value) >= 0 && data.isActive) {
        valueArray.push(data.key);
      }
    });
    return valueArray;
  }

  function mappedData(isSaveAsDraft) {
    let errors = false;
    if (!isSaveAsDraft) {
      let updatedEmployeeList = [...employeeList];
      if (updatedEmployeeList.length > 0) {
        updatedEmployeeList?.forEach((data) => {
          if (data.employeeName.trim() === "") {
            data.employeeName = "";
            data.isErrorEmployeeName = true;
            data.errorEmployeeNameMsg = "Selection is required for this field";
            errors = true;
          }
          if (!data.supervisor) {
            data.supervisorError = true;
            errors = true;
          }
          if (!data.jobSiteProjectName) {
            data.jobSiteProjectNameError = true;
            errors = true;
          }
          if (data.descriptionOfIncident.trim() === "") {
            data.descriptionOfIncident = "";
            data.isErrorDescriptionOfIncident = true;
            data.errorDescriptionOfIncidentMsg =
              "Please enter this required field";
            errors = true;
          }
          if (data.similarCases.trim() === "") {
            data.similarCases = "";
            data.isavoidedinthefuture = true;
            data.isavoidedinthefutureMsg = "Please enter this required field";
            errors = true;
          }
        });
      }
      setEmployeeList(updatedEmployeeList);

      let updatedwitnessList = [...witnessList];
      if (updatedwitnessList.length > 0 && witnessType) {
        updatedwitnessList?.forEach((data) => {
          if (data.witnessName.trim() === "") {
            data.witnessName = "";
            data.isErrorWitnessName = true;
            data.errorWitnessNameMsg = "Please enter this required field";
            errors = true;
          }
          if (data.statement.trim() === "") {
            data.statement = "";
            data.isErrorStatement = true;
            data.errorStatementMsg = "Please enter this required field";
            errors = true;
          }
        });
      }
      setWitnessList(updatedwitnessList);

      let cloneActionList = [...actionList];
      cloneActionList.forEach((items) => {
        if (items.description || items.isFileAdded) {
          if (!items.assignedPartyId) {
            items.isError = true;
            errors = true;
          } else {
            items.isError = false;
          }
        }
      });

      if (!formValue.timeOfCase) {
        errors = true;
      }
      if (errors) {
        window.scrollTo({
          top: 1050,
          left: 0,
          behavior: "smooth",
        });
        return false;
      }
    }

    const actionListData = [];
    actionList.forEach((data) => {
      actionListData.push({
        assignedPartyId: data.assignedPartyId,
        isFileAdded: data.isFileAdded,
        files: data.fileId,
        description: data.description,
      });
    });
    const filterActionData = actionListData?.filter(
      (item) => item?.assignedPartyId !== undefined
    );
    const witnessListData = [];
    witnessList.forEach((data) => {
      witnessListData.push({
        employerName: data.employerName, // TODO Need to be map
        witnessName: data.witnessName,
        jobTitle: data.jobTitle,
        statement: data.statement,
        files: `${data.files}`,
      });
    });

    const employeeListData = [];
    employeeList.forEach((data, index) => {
      employeeListData.push({
        componentId: editMode ? data.componentId : "0",
        userId: data?.userId ? data?.userId : 0,
        nmEmployeeId: data.nmEmployeeId,
        supervisorId: data.supervisor,
        projectId: data?.jobSiteProjectName,
        description: data?.descriptionOfIncident,
        isToolInspected: data?.equipmentInspected,
        isJhaCompleted: data.JHACompleted,
        isHazardsIdentified: data.associatedJHA,
        isEmployeesTrained: data.employeesTrained,
        howSimilarCaseAvoided: data.similarCases,
        contributingFactors: findValueMultipleSelect(
          contributingFactors,
          data?.contributingFactors
        ),
      });
    });
    const data = {
      caseId: caseId === 0 ? caseData.caseId : caseId,
      status: INCIDENT_STATUS.SUBMITTED,
      createdBy: parseInt(userId),
      incidentId: incidentId,
      additionalDetails: formValue.addDetails,
      isWitness: witnessType,
      witness: witnessType ? witnessListData : [],
      actions: filterActionData?.length > 0 ? filterActionData : [],
      employes: employeeListData.length > 0 ? employeeListData : [],
    };
    return { nearMisses: data };
  }
  const submitData = () => {
    const data = mappedData(false);
    if (!data) {
    } else {
      onSubmit(data);
    }
  };

  const saveData = () => {
    const data = mappedData(true);
    data.nearMisses.status = INCIDENT_STATUS.DRAFT;
    onSubmit(data);
  };
  const editSerch = (data) => {
    searchUsers(
      GET_USER_TYPE.PREVNONPREVUSER,
      data?.employeeName.split(" ")[0]
    );
  };

  const searchUsersByProject = (type, text) => {
    searchAllUsers(type, text);
  };

  useEffect(() => {
    if (!editMode) {
      let updatedEmployeeList = [...employeeList];

      updatedEmployeeList?.forEach((items) => {
        items.disableEmpName = false;
        items.jobSiteList = assetOnUserList;
      });
      setEmployeeList(updatedEmployeeList);
    }
  }, [assetOnUserList]);
  useEffect(() => {
    if (employeeList[0].selectEmployee[0] !== undefined) {
      getAssetOnUser(employeeList[0].selectEmployee[0]);
    }
  }, [employeeList[0].selectEmployee[0]]);
  useEffect(() => {
    if (
      employeeList[0].jobSiteProjectName !== 0 &&
      employeeList[0].jobSiteProjectName !== undefined &&
      employeeList[0].jobSiteProjectName !== null
    ) {
      getSupervisorOnAsset(employeeList[0].jobSiteProjectName);
    }
  }, [employeeList[0].jobSiteProjectName]);

  useEffect(() => {
    if (!editMode) {
      let updatedEmployeeList = [...employeeList];
      updatedEmployeeList.forEach((items) => {
        items.disableEmpName = false;
        items.supervisorList = supervisorOnAssetList;
      });
      setEmployeeList(updatedEmployeeList);
    }
  }, [supervisorOnAssetList]);
  useEffect(() => {
    if (assetOnUserData && !editMode) {
      let updatedEmployeeList = [...employeeList];
      updatedEmployeeList.forEach((items) => {
        items.jobTitle = assetOnUserData?.jobTitle ?? "";
        items.startData = assetOnUserData?.startDate;
      });
      setEmployeeList(updatedEmployeeList);
    }
  }, [assetOnUserData]);

  return (
    <>
      <div className="overidePersonal">
        {isEmployeeDisplay &&
          employeeList?.map((data, index) => (
            <Employee
              key={index}
              keyNo={index + 1}
              employeeName={data?.selectEmployee[0]}
              supervisor={data?.supervisor}
              supervisorData={data?.supervisorList}
              jobSiteData={data?.jobSiteList}
              jobSiteProjectNameError={data?.jobSiteProjectNameError}
              supervisorError={data?.supervisorError}
              jobTitle={data?.jobTitle}
              startData={data?.startData}
              disableEmpName={data?.disableEmpName}
              jobSiteProjectName={data?.jobSiteProjectName}
              contributingFactors={data?.contributingFactors}
              descriptionOfIncident={data?.descriptionOfIncident}
              equipmentInspected={data?.equipmentInspected}
              JHACompleted={data?.JHACompleted}
              associatedJHA={data?.associatedJHA}
              employeesTrained={data?.employeesTrained}
              similarCases={data?.similarCases}
              vehicleListLength={data?.id}
              searchOption={searchOption}
              editSerch={() => editSerch(data)}
              handleEmployeeName={(event, value) =>
                handleEmployeeName(event, value, data)
              }
              employeeOnChange={(e, ref) => employeeOnChanges(e, ref, data)}
              nameOfDriveData={contributingFactors}
              isErrorEmployeeName={data.isErrorEmployeeName}
              errorEmployeeNameMsg={data.errorEmployeeNameMsg}
              isErrorJobSiteProjectName={data.isErrorJobSiteProjectName}
              errorJobSiteProjectNameMsg={data.errorJobSiteProjectNameMsg}
              isErrorDescriptionOfIncident={data.isErrorDescriptionOfIncident}
              errorDescriptionOfIncidentMsg={data.errorDescriptionOfIncidentMsg}
              cancelEmployee={(id) => cancelEmployee(id)}
              incidentId={incidentId}
              searchUsers={searchUsers}
              isLoading={isLoading}
              isavoidedinthefuture={data.isavoidedinthefuture}
              isavoidedinthefutureMsg={data.isavoidedinthefutureMsg}
              editMode={editMode}
            />
          ))}
        <Grid className="inputGrid" container mb={2}>
          <Grid lg={4} xs={12} sm={12} md={4}>
            <FormLabels label={intl.get("incident.nearMisses.witness")} />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={witnessType === true}
                value={true}
                label="Yes"
                onChange={(e) => setWitnessType(JSON.parse(e.target.value))}
                name="witness"
              ></RadioButton>
              <RadioButton
                checked={witnessType === false}
                value={false}
                label="No"
                onChange={(e) => setWitnessType(JSON.parse(e.target.value))}
                name="witness"
              ></RadioButton>
            </RadioGroup>
          </Grid>
        </Grid>

        {witnessType &&
          witnessList.map((singleWitness, index) => (
            <Witness
              key={index}
              keyNo={index + 1}
              singleWitness={singleWitness}
              handleOnChangeWitnessForm={handleOnChangeWitnessForm}
              optionsData={searchOption?.users}
              isErrorWitnessName={singleWitness.isErrorWitnessName}
              isErrorStatement={singleWitness.isErrorStatement}
              errorWitnessNameMsg={singleWitness.errorWitnessNameMsg}
              errorStatementMsg={singleWitness.errorStatementMsg}
              id={singleWitness.id}
              cancelWitnessList={(id) => cancelWitnessList(id)}
              deleteImageURL={(url) =>
                handleDeleteWitnessImage(url, singleWitness)
              }
              witnessFileUpload={(photo) =>
                witnessFileUpload(photo, singleWitness)
              }
            />
          ))}
        {witnessType && (
          <div className="addBox">
            <Button
              varientTextGreen={true}
              disabled={witnessList.length === 4 ? true : false}
              label={intl.get("incident.nearMisses.addWitness")}
              onClick={handleOnclickWitness}
            />
          </div>
        )}

        {actionList.map((singleAction, index) => (
          <CreateAction
            key={index}
            singleAction={singleAction}
            handleChangeActionDescription={handleChangeCreateActionDesc}
            handleAddAssigneeCta={handleAddAssigneeCta}
            handleUploadFile={handleUploadFileMethod}
            handleCancelCta={handleCreateActionCancelCta}
            incidentId={nearMissesData?.incidentId}
            isLoading={isLoading}
            optionsData={allUsers?.users ?? []}
            searchUsers={searchUsersByProject}
            isError={singleAction.isError}
            removeActionImage={removeActionImage}
            fileError={fileError}
          />
        ))}
        <div className="addBox">
          <Button
            varientTextGreen={true}
            disabled={actionList.length === 4 ? true : false}
            label={intl.get("incident.case.addAction")}
            onClick={handleOnclickAddAction}
          />
        </div>
        <Grid container mt={3}>
          <Grid item lg={8} xs={12} sm={12} md={8}>
            <FormLabels label={intl.get("incident.case.addAdditinalDetails")} />
            <TextEditor
              placeholder={"Add Additional Details Text Here..."}
              value={formValue.addDetails}
              onChange={(value) =>
                setFormValue({ ...formValue, addDetails: value })
              }
            />
          </Grid>
        </Grid>

        <Grid container mt={3} spacing={10} alignItems="center">
          <Grid lg={4} xs={12} sm={6} md={4} textAlign="center">
            <Button
              varientText={true}
              label={intl.get("incident.case.saveAsDraft")}
              onClick={saveData}
            />
          </Grid>
          <Grid lg={3} xs={12} sm={6} md={3} textAlign="right">
            <Button
              varientContained={true}
              label={intl.get("incident.case.submit")}
              onClick={submitData}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default NearMissesForm;
