import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import DownloadCsv from "../../download_file/DownloadCsv";

export default function AlertModalAction(props) {
  const { reportIdOfChekbox, incidentLists, filename } = props;
  const dowloadCsv = React.useRef();
  const data = React.useMemo(() => {
    return incidentLists.filter((item) => {
      return reportIdOfChekbox.find((reportId) => {
        return reportId === item.reportId;
      });
    });
  }, [reportIdOfChekbox, incidentLists]);

  const downloadIncidentReport = () => {
    dowloadCsv.current.link.click();
  };
  
  return (
    <>
      <div className="alertBox_bottom">
        <div className="row_alert_box">
          <div className="row_alert_column">
            <div className="report_slected_box">
              <span>
                <CancelIcon />
              </span>
              <span>{reportIdOfChekbox?.length ?? 0} Report Selected</span>
            </div>
          </div>
          <div className="row_alert_column">
            <div className="button_row_for_multiple">
              <Button
                className="btn_style_ui btn_bg_white_color_blue"
                size="small"
                variant="outlined"
                startIcon={<FileDownload />}
                onClick={downloadIncidentReport}
              >
                Download
              </Button>
              {/* <Button
                size="small"
                className="btn_style_ui btn_bg_white_color_red"
                variant="outlined"
                startIcon={<FileDownload />}
                onClick={deleteIncidents}
              >
                Request Deletion
              </Button> */}
            </div>
          </div>
          <DownloadCsv
            ref={dowloadCsv}
            data={incidentLists}
            filename={filename}
            // ref={dowloadCsv} data={data} filename={filename}
          />
        </div>
      </div>
    </>
  );
}
