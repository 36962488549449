import React from "react";
import "./toolBar.scss";

const ToolBar = (props) => {
  return (
    <div className="toolBarContainer">
      <div className="toolBarTitle">{props?.toolBarData?.title}</div>

      <div className="toolBarListContainer">
        {props?.toolBarData?.list?.map((list) => {
          return list.isshow ? (
            <div
              className={
                list?.activeClass ? "toolBarList active" : "toolBarList"
              }
              onClick={() => props?.handleClick(list)}
              key={list?.id}
            >
              {list?.title}
            </div>
          ) : (
            ""
          );
        })}
      </div>
    </div>
  );
};

export default ToolBar;
