import { FormControl, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { InputField } from "../../../../library/custom/textBox/InputField";
import TextArea from "../../../../library/custom/textArea/TextArea";
import Button from "../../../../library/custom/button/Button";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { ORG_ID, ticketPriority } from "../../../../constant/constant";
import "./createTicket.scss";
import Loader from "../../../../library/common/Loader";
import FormDropZone from "../../../../library/dropZone/FormDropZone";

const CreateTicket = (props) => {
  const [category, setCategory] = useState("");
  let categoryType = "";
  let categoryDescription = "";
  const ticketFrom = localStorage.getItem("name");

  const initialState = {
    createdBy: localStorage.getItem("userId"),
    priorityId: "",
    categoryId: "",
    title: "",
    description: "",
    fileIds: "",
    orgId: ORG_ID(),
  };
  const [images, setImages] = useState([]);
  useEffect(() => {
    setFormValue({
      ...formValue,
      fileIds: images,
    });
  }, [images]);

  const [formValue, setFormValue] = useState(initialState);
  const [error, seterror] = useState(false);
  const [isPriority, setIsPriority] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [isTitle, setIsTitle] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  function submitData() {
    if (formValue.priorityId.length < 1) {
      setIsPriority(true);
      seterror(true);
    }
    if (formValue.categoryId.length < 1) {
      setIsCategory(true);
      seterror(true);
    }
    if (formValue.title.length < 1) {
      setIsTitle(true);
      seterror(true);
    }
    if (formValue.description.length < 1) {
      setIsDescription(true);
      seterror(true);
    }
    if (
      formValue.priorityId.length < 1 ||
      formValue.categoryId.length < 1 ||
      formValue.title.length < 1 ||
      formValue.description.length < 1
    ) {
      seterror(true);
    } else {
      props.onClick(formValue);
      seterror(false);
    }
  }
  categoryType = props?.articleCatSubcategory?.find(
    (x) => x.key === formValue.categoryId
  );

  categoryDescription = props?.articleCatSubcategory?.find(
    (x) => x.articleCatSubcatId === formValue.categoryId
  )?.description;

  const [addPhoto, setAddPhoto] = useState("");

  const handleDeleteImageURL = (urls) => {
    setAddPhoto("");
    setImages([]);
  };
  return (
    <>
      <div className="overideCreateTicket">
        <div className="formContainer">
          <div className="title">
            <h1>Create Ticket</h1>
          </div>
          {!props?.articleCatSubcategory && <Loader />}
          <div className="ticketBoxContainer">
            <div className="ticketBox">
              <Grid>
                <Grid item container mt={1.5} lg={12} fullWidth spacing={2}>
                  <Grid item lg={6}>
                    <FormControl fullWidth>
                      <InputField
                        fullWidth={true}
                        disabled={true}
                        type="text"
                        label="From"
                        value={ticketFrom}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6}>
                    <FormControl fullWidth>
                      <FormLabels label="Priority" isRequired={true} />
                      <SelectMenu
                        placeholder={"Please Select"}
                        key={ticketPriority}
                        listData={ticketPriority}
                        value={formValue.priorityId}
                        onchangehandler={(e) => {
                          setFormValue({
                            ...formValue,
                            priorityId: e.target.value,
                          });
                        }}
                        isError={isPriority}
                        errorMsg={
                          isPriority
                            ? "Selection is required for this field"
                            : ""
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid mt={2} className="inputGrid" container fullWidth lg={12}>
                <Grid lg={5.8} fullWidth>
                  <FormControl fullWidth>
                    <FormLabels label="Category" isRequired={true} />

                    <SelectMenu
                      placeholder={"Please Select"}
                      listData={props?.articleCatSubcategory}
                      value={formValue.categoryId}
                      onchangehandler={(e) => {
                        setCategory(e.target.value);
                        setFormValue({
                          ...formValue,
                          categoryId: e.target.value,
                        });
                      }}
                      // isKeyValue={false}
                      isError={isCategory}
                      errorMsg={
                        isCategory ? "Selection is required for this field" : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid mt={2} item lg={12}>
                <FormControl fullWidth>
                  <InputField
                    isRequired={true}
                    fullWidth={true}
                    type="text"
                    label="Title"
                    value={formValue.title}
                    placeholder="Enter title here"
                    onChange={(e) => {
                      if (
                        e.target.value === " " &&
                        formValue.title.trim() === ""
                      )
                        setFormValue({
                          ...formValue,
                          title: "",
                        });
                      else
                        setFormValue({ ...formValue, title: e.target.value });
                    }}
                    isError={isTitle}
                    errorMsg={isTitle ? "Please enter this required field" : ""}
                  />
                </FormControl>
              </Grid>
              <Grid container mt={3} mb={3}>
                <Grid lg={12}>
                  <FormControl fullWidth>
                    <FormLabels label="Message" isRequired={true} />
                    <TextArea
                      placeholder={"Enter message here"}
                      value={formValue.description}
                      onChange={(e) => {
                        if (
                          e.target.value === " " &&
                          formValue.description.trim() === ""
                        )
                          setFormValue({
                            ...formValue,
                            description: "",
                          });
                        else
                          setFormValue({
                            ...formValue,
                            description: e.target.value,
                          });
                      }}
                      isError={isDescription}
                      errorMsg={
                        isDescription ? "Please enter this required field" : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid lg={12} mb={2} mt={2}>
                  <FormDropZone
                    paraText1={"Drop the files here ..."}
                    paraText2={"Drop Image here, "}
                    paraText3={"or Browse"}
                    handleFileChange={(files, uploadfiles) => {
                      setImages(...images, uploadfiles);
                      setAddPhoto(files);
                    }}
                    deleteImageURL={(url) => handleDeleteImageURL(url)}
                    isDynamics={true}
                    isRequired={true}
                    fileType={10}
                    isMuliple={false}
                    imageUrl={addPhoto}
                    id="addPhoto"
                    key="addPhoto"
                    isDoc={false}
                    isImage={true}
                    allFiles={false}
                  />
                </Grid>
              </Grid>
              <Grid className="submitBtnDiv" lg={12} mt={1.5}>
                <Grid
                  className="errorSubBtn"
                  container
                  lg={3}
                  textAlign="center"
                >
                  <Button
                    varientContained={true}
                    label="Submit Ticket"
                    onClick={submitData}
                    disabled={props.isLoading ? true : false}
                  />
                  {error && (
                    <span className="errorSpan">please fill all fields</span>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className="categoryDropdownContainer">
              {category && (
                <div className="tooltip">
                  <p className="tooltipHeader">Category:</p>
                  <h1 className="tooltipHeaderMain">{categoryType.value}</h1>
                  <p className="tooltipHeader">{categoryDescription}</p>
                </div>
              )}
              {/* todo
                <div className="tooltip">
                  Lorem ipsum dolor sit amet.lorem5 Lorem ipsum dolor sit amet.
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTicket;
