export const assignType = {
  POST_ASSIGN_PENDING: "[ASSIGN][REQUEST] Create Assign",
  POST_ASSIGN_FAILURE: "[ASSIGN][RESPONSE] Assign Creation Failed",
  POST_ASSIGN_SUCCESS: "[ASSIGN][RESPONSE] Assign Created Successfull",
  PUT_ASSIGN_PENDING: "[ASSIGN][REQUEST] Update Assign",
  PUT_ASSIGN_FAILURE: "[ASSIGN][RESPONSE] Assign Updation Failed",
  PUT_ASSIGN_SUCCESS: "[ASSIGN][RESPONSE] Assign Updation Successfull",
  GET_ASSIGN_LIST_PENDING:
    "[GET_ASSIGN_LIST][REQUEST] Get Assign list requested",
  GET_ASSIGN_LIST_FAILURE: "[GET_ASSIGN_LIST][RESPONSE] Get Assign list Failed",
  GET_ASSIGN_LIST_SUCCESS:
    "[GET_ASSIGN_LIST][RESPONSE] Get Assign list Successfull",
  ASSIGN_PAGE: "AssignPage",
};
