import React from "react";
import AllProject from "./allProject";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { REPORTS_ANALYTICS_DASHBOARD } from "../../../../constant/routeContant";
import CategoriesCorrective from "./categoriesCorrective";
import SubCategoriesCorrective from "./subCategoriesCorrective";
import InspectorCorrective from "./inspectorCorrective";
import UserInvolvedPart from "./userInvolvedParty";
import SubContractorParty from "./subContractorParty";
import TrendingCategories from "./trendingCategories";
import TreandingSubCategories from "./trendingSubCategories";
import InspectionCompleted from "./inspectionCompleted";
import InspectionCompletedByPerInspector from "./inspectionCompletedByPerInspector";
import InspectedPerDay from "./inspectedPerDay";

const DrillDown = (props) => {
  const dataBack = {
    title: "Back to Dashboard",
    route: REPORTS_ANALYTICS_DASHBOARD,
    state: {
      apiData: props?.drillDetail?.apiData,
      backpage: "backpage",
    },
  };

  return (
    <>
      <div>
        <BackBtn dataBack={dataBack} />
      </div>
      {props?.drillDetail?.page === "projects" ? (
        <AllProject
          drillDetail={props?.drillDetail}
          allAsset={props?.inspectionReportAssetDetails?.topAssetList}
          getInspectionReportAssetDetailsData={
            props?.getInspectionReportAssetDetailsData
          }
        />
      ) : props?.drillDetail?.page === "categoriescorrective" ? (
        <CategoriesCorrective
          drillDetail={props?.drillDetail}
          categorys={props?.inspectionReportAssetDetails?.topCategoryList}
          getInspectionReportAssetDetailsData={
            props?.getInspectionReportAssetDetailsData
          }
        />
      ) : props?.drillDetail?.page === "subCategoriescorrective" ? (
        <SubCategoriesCorrective
          drillDetail={props?.drillDetail}
          subCategorys={props?.inspectionReportAssetDetails?.topSubCategoryList}
          getInspectionReportAssetDetailsData={
            props?.getInspectionReportAssetDetailsData
          }
        />
      ) : props?.drillDetail?.page === "inspectorcorrective" ? (
        <InspectorCorrective
          drillDetail={props?.drillDetail}
          allInspector={props?.inspectionReportAssetDetails?.topInspectorList}
          getInspectionReportAssetDetailsData={
            props?.getInspectionReportAssetDetailsData
          }
        />
      ) : props?.drillDetail?.page === "userCorrectiveAsInvolvedParty" ||
        props?.drillDetail?.page === "userKudosAsInvolvedParty" ? (
        <UserInvolvedPart
          correctiveUsers={
            props?.inspectionReportAssetDetails?.topCorrectiveUserList
          }
          kudosUsers={props?.inspectionReportAssetDetails?.topKudosUserList}
          drillDetail={props?.drillDetail}
          getInspectionReportAssetDetailsData={
            props?.getInspectionReportAssetDetailsData
          }
        />
      ) : props?.drillDetail?.page ===
          "subContractorCorrectiveAsInvolvedParty" ||
        props?.drillDetail?.page === "subContractorKudosAsInvolvedParty" ? (
        <SubContractorParty
          correctiveSubContractors={
            props?.inspectionReportAssetDetails?.topCorrectiveSubContractorList
          }
          kudosSubContractors={
            props?.inspectionReportAssetDetails?.topKudosSubContractorList
          }
          drillDetail={props?.drillDetail}
          getInspectionReportAssetDetailsData={
            props?.getInspectionReportAssetDetailsData
          }
        />
      ) : props?.drillDetail?.page === "trendingcategories" ? (
        <TrendingCategories trendData={props?.drillDetail} />
      ) : props?.drillDetail?.page === "trendingsubcategories" ? (
        <TreandingSubCategories trendData={props?.drillDetail} />
      ) : props?.drillDetail?.page === "inspectioncompletedperassetdetails" ? (
        <InspectionCompleted
          drillDetail={props?.drillDetail}
          inspectionCompletedByAsset={
            props?.inspectionReportAssetDetails?.inspectionCompletedByAsset
          }
          getInspectionReportAssetDetailsData={
            props?.getInspectionReportAssetDetailsData
          }
        />
      ) : props?.drillDetail?.page === "inspectiondonebyuserdetails" ? (
        <InspectionCompletedByPerInspector
          drillDetail={props?.drillDetail}
          inspectionCompletedByPerInspector={
            props?.inspectionReportAssetDetails
              ?.inspectionCompletedByPerInspector
          }
          getInspectionReportAssetDetailsData={
            props?.getInspectionReportAssetDetailsData
          }
        />
      ) : props?.drillDetail?.page === "inspectedperday" ? (
        <InspectedPerDay trendData={props?.drillDetail} />
      ) : null}
    </>
  );
};

export default DrillDown;
