import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBSzamshK-ipn7tFoC9AWbTWaHKOokl35E",
  authDomain: "sequence-a1d2d.firebaseapp.com",
  projectId: "sequence-a1d2d",
  storageBucket: "sequence-a1d2d.appspot.com",
  messagingSenderId: "339792312074",
  appId: "1:339792312074:web:1a0dc4066260974689acf3",
  measurementId: "G-ES6P2YH1L2"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
