import React from "react";
import SwReportsManagementList from "../components/SwReportsManagementList";
import { useNavigate } from "react-router-dom";
import {
  STROM_WATER_ANALYTICS_REPORT_INSPECTION,
  SW_MAP_VIEW_REPORT,
  SW_RAINFALL_REPORT,
  SW_SUMMARY_REPORT,
} from "../../../../constant/routeContant";

function SwReportsContainer() {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(STROM_WATER_ANALYTICS_REPORT_INSPECTION);
    }
    if (id === 2) {
      navigate(SW_SUMMARY_REPORT);
    }
    if (id === 3) {
      navigate(SW_MAP_VIEW_REPORT);
    }
    if (id === 4) {
      navigate(SW_RAINFALL_REPORT);
    }
  };
  return (
    <>
      <div className="dashTitle">Report Management</div>
      <SwReportsManagementList onClick={onClickHandler} />
    </>
  );
}

export default SwReportsContainer;
