import React from "react";
import { Edit } from "@mui/icons-material";
import FormLabel from "@mui/material/FormLabel";
import "./FormLables.scss";

export default function FormLabels({
  label,
  isRequired,
  editEmpName = false,
  editNameHandler,
  boxNumner,
}) {
  return (
    <div className="overideLables">
      <FormLabel id="demo-row-radio-buttons-group-label">
        <div className="formLabel">
          {label} {isRequired && <span className="required">*</span>}
          {editEmpName && (
            <span title={`Edit Employee ${boxNumner}`} className="editBtn">
              <Edit onClick={() => editNameHandler()} />
            </span>
          )}
        </div>
      </FormLabel>
    </div>
  );
}
