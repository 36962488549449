import React, { useState } from "react";
import "./Accordion.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordions from "../../../library/custom/accordion/Accordion";
import { useEffect } from "react";

function ParentAccordions({
  summary,
  datas,
  skipQuestion,
  itemKey,
  qestSetState,
  setQestSetState,
  questionAnswerSelectable,
  skip,
  setImage,
  filesId,
  filesUrl,
  edit,
  catogeryCompleted,
  catError,
  assetId,
  assetTypeValue,
  userClear
}) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = React.useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, []);

  const accordionExpandOnError = React.useCallback(() => {
    const questset =
      qestSetState && qestSetState.filter((item) => item.key === itemKey)[0];
    const isError = questset.subcategories.filter((subc) => {
      const dats = subc.questions.filter((qs) => qs?.optionError !== undefined);
      return dats.length > 0;
    });
    const exp =
      isError.length > 0 ? (questset?.skip?.IsCategory ? false : true) : false;
    setExpanded((prevState) => (prevState ? prevState : exp));
  }, [qestSetState, itemKey]);

  useEffect(() => {
    if (skip?.IsCategory === true) {
      setExpanded(false);
    }
  }, [skip]);
  useEffect(() => {
    if (skip && edit === true) {
      if (skip?.IsCategory === true) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    }
  }, [skip, edit]);
  useEffect(() => {
    accordionExpandOnError();
  }, [accordionExpandOnError]);
  const completeds = datas.filter((items) => items.subComplete);
  return (
    <div>
      <Accordion
        className={[
          "accordion_body",
          skip?.IsCategory && "disable_accordian",
        ].join(" ")}
        expanded={expanded}
        onChange={() => (skip?.IsCategory ? "" : handleChange())}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="parentAccordion"
          style={{ flexDirection: "row-reverse", flexBasis: "90%" }}
        >
          <div className="accordian_header_content">
            <Typography>{summary}</Typography>
            <div className="accordian_header_content_child">
              <button onClick={(e) => skipQuestion(e, summary, itemKey)}>
                {skip?.IsCategory ? "Undo" : "Skip"}
              </button>
              <Typography>
                {completeds?.length}/{datas?.length ?? 0} Inspected{" "}
                {Math.trunc(catogeryCompleted)} (%)
              </Typography>
            </div>
          </div>
        </AccordionSummary>

        {datas &&
          datas.map((data) => {
            return (
              <AccordionDetails key={data.key} className="childAccordion">
                <Accordions
                  accordionDetails={data.questions}
                  accordionSummarys={data.value}
                  percentDone={data.percentDone}
                  varientGeneral={true}
                  subError={data.subError}
                  skipQuestion={skipQuestion}
                  itemKeySub={data.key}
                  qestSetState={qestSetState}
                  setImage={setImage}
                  filesId={filesId}
                  filesUrl={filesUrl}
                  setQestSetState={setQestSetState}
                  questionAnswerSelectable={questionAnswerSelectable}
                  parentElementData={{
                    summary,
                    itemKey,
                  }}
                  skip={data?.skip ?? {}}
                  edit={edit}
                  assetId={assetId}
                  assetTypeValue={assetTypeValue}
                  userClear={userClear}
                />
              </AccordionDetails>
            );
          })}
      </Accordion>
      {catError && (
        <p className="error mb-1">Please enter this required field.</p>
      )}
    </div>
  );
}

export default React.memo(ParentAccordions);
