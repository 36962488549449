import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./chart.scss";
Chart.register(...registerables);

const PieChart = ({
  labels = [],
  datasets = [],
  options,
  height = "400px",
}) => {
  useEffect(() => {
    Chart.register(ChartDataLabels);
  }, []);

  const hasData = labels.length > 0 && datasets.length > 0;

  return (
    <div style={{ width: "100%", height }}>
      {hasData ? (
        <Pie options={options} data={{ labels, datasets }} />
      ) : (
        <div className="noData">No Data Available</div>
      )}
    </div>
  );
};

export default PieChart;
