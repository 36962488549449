import { call, put, takeLatest } from "redux-saga/effects";
import {
  addNearMisses,
  getNearMisses,
  updateNearMisses,
} from "../../services/incidentService";
import { apiError } from "../../utils/helper";
import * as nearMissesAction from "../actions/nearMissesAction";
import { nearMissesType } from "../constants/nearMissesType";

export function* nearMissesSaga(params) {
  console.debug("nearMissesSaga");
  try {
    const { data, status, error } = yield call(addNearMisses, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      nearMissesAction.nearMissesSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Near Misses Failed" };
    yield put(nearMissesAction.nearMissesFailure(data));
  }
}

export function* getNearMissesSaga({ caseid, incidentid }) {
  console.debug("getnearMissesSaga");
  try {
    const { data, status, error } = yield call(
      getNearMisses,
      caseid,
      incidentid
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      nearMissesAction.getNearMissesSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Near Misses Failed" };
    yield put(nearMissesAction.getNearMissesFailure(data));
  }
}

export function* updateNearMissesSaga(params) {
  console.debug("updateNearMissesSaga");
  try {
    const { data, status, error } = yield call(updateNearMisses, params);
    if (!data && status !== 200) throw apiError(error);
    const nearMissesPage = nearMissesType.NEAR_MISSES_PAGE;
    yield put(
      nearMissesAction.updateNearMissesSuccess({
        nearMissesPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Near misses updation Failed" };
    yield put(nearMissesAction.updateNearMissesFailure(data));
  }
}

const myNearMissesSaga = [
  takeLatest(nearMissesType.POST_NEAR_MISSES_PENDING, nearMissesSaga),
  takeLatest(nearMissesType.GET_NEAR_MISSES_PENDING, getNearMissesSaga),
  takeLatest(nearMissesType.PUT_NEAR_MISSES_PENDING, updateNearMissesSaga),
];

export default myNearMissesSaga;
