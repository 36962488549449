import React, { useEffect } from "react";
import { REPORTS_CREDENTIAL_COUNT } from "../../../constant/routeContant";
import ReportCredentialList from "../component/reportCredentialList";
import { useDispatch, useSelector } from "react-redux";
import * as reportAction from "../../../redux/actions/reportAction";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { useLocation } from "react-router-dom";

function ReportCredentialListConatiner() {
  const dispatch = useDispatch();
  const location = useLocation();
  const credentialTabId = location?.state?.tabId;
  const names = location?.state?.name;
  const groupId = location?.state?.groupId;
  const PAGE_SIZES = 100;
  const getLookupCredentialReportData = useSelector(
    (state) => state.getLookupCredentialReportData
  );
  const getLookupCredentialReports = (data) => {
    dispatch(reportAction.getLookupCredentialReport(data));
  };

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZES,
    groupId: groupId ? groupId : 0,
    orgId: ORG_ID(),
    tabId: credentialTabId,
    startDate: "",
    endDate: "",
  };
  useEffect(() => {
    getLookupCredentialReports(defaultData);
  }, []);

  const dataBack = {
    title: "Back to Credential Reports",
    route: REPORTS_CREDENTIAL_COUNT,
  };

  return (
    <ReportCredentialList
      dataBack={dataBack}
      getLookupCredentialReportData={getLookupCredentialReportData}
      getLookupCredentialReports={getLookupCredentialReports}
      isLoading={getLookupCredentialReportData.isLoading}
      credentialTabId={credentialTabId}
      groupId={groupId}
      PAGE_SIZES={PAGE_SIZES}
      namess={names}
    />
  );
}

export default ReportCredentialListConatiner;
