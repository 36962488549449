export const reportType = {
  GET_CREDENTIAL_REPORT_PENDING:
    "[GET_CREDENTIAL_REPORT][REQUEST] Get Credential Report requested",
  GET_CREDENTIAL_REPORT_FAILURE:
    "[GET_CREDENTIAL_REPORT][RESPONSE] Get Credential Report Failed",
  GET_CREDENTIAL_REPORT_SUCCESS:
    "[GET_CREDENTIAL_REPORT][RESPONSE] Get Credential Report Successfull",

  GET_LOOKUP_CREDENTIAL_REPORT_PENDING:
    "[GET_LOOKUP_CREDENTIAL_REPORT][REQUEST] Get Lookup Credential Report requested",
  GET_LOOKUP_CREDENTIAL_REPORT_FAILURE:
    "[GET_LOOKUP_CREDENTIAL_REPORT][RESPONSE] Get Lookup Credential Report Failed",
  GET_LOOKUP_CREDENTIAL_REPORT_SUCCESS:
    "[GET_LOOKUP_CREDENTIAL_REPORT][RESPONSE] Get Lookup Credential Report Successfull",

  GET_CONFIGURED_CREDENTIAL_REPORT_PENDING:
    "[GET_CONFIGURED_CREDENTIAL_REPORT][REQUEST] Get CONFIGURED Credential Report requested",
  GET_CONFIGURED_CREDENTIAL_REPORT_FAILURE:
    "[GET_CONFIGURED_CREDENTIAL_REPORT][RESPONSE] Get CONFIGURED Credential Report Failed",
  GET_CONFIGURED_CREDENTIAL_REPORT_SUCCESS:
    "[GET_CONFIGURED_CREDENTIAL_REPORT][RESPONSE] Get CONFIGURED Credential Report Successfull",

  REPORT_PAGE: "REPORT_PAGE",
};
