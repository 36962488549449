import React, { useState, useEffect } from "react";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import "./configureInspections.scss";
import { Grid } from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import Button from "../../../../library/custom/button/Button";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { ISwitch, USER_ID } from "../../../../constant/constant";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import ToolBar from "../../../../library/storm-water/toolBar";
import {
  INSPECTION_GENERATOR,
  ITEM_FOUND_RESPONSES,
  SW_CATEGORIES,
  SW_SUB_CATEGORIES,
} from "../../../../constant/routeContant";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const QuestionConfiguration = ({
  getSubCategoriesList,
  onSubmit,
  onUpdateSubmit,
  getQuestionList,
  createSwQuestion,
  getQuestionListData,
}) => {
  const intialState = {
    value: "",
    subCategoriesValue: null,
  };

  const [questionBox, setQuestionBox] = useState(false);
  const [responseType, setResponseType] = useState("");

  const [formValue, setFormValue] = useState(intialState);
  const [isQuestionName, setIsQuestionName] = useState(false);
  const [errorResponseType, setErrorResponseType] = useState(false);
  const [subCategoriesName, setSubCategoriesName] = useState(false);
  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [getQuestionsLists, setGetQuestionsLists] = useState([]);
  const dataBack = {
    title: "Back to Inspection Configuration",
    route: -1,
  };
  const navigate = useNavigate();

  const handleSubCategoriesChange = (e) => {
    setFormValue({
      ...formValue,
      subCategoriesValue: e.target.value,
    });
  };

  const addQuestionBox = () => {
    setQuestionBox(!questionBox);
  };

  const responseTypeHandler = (item) => {
    setResponseType(item);
  };

  function mappedData() {
    let errors = false;
    if (formValue?.value.trim() === "") {
      setFormValue({
        ...formValue,
        value: "",
      });
      seterrorsMsgShowActive(false);
      setIsQuestionName(true);
      errors = true;
    }
    if (!formValue?.subCategoriesValue) {
      seterrorsMsgShowActive(false);
      setSubCategoriesName(true);
      errors = true;
    }
    if (responseType === "") {
      seterrorsMsgShowActive(false);
      setErrorResponseType(true);
      errors = true;
    } else {
      setErrorResponseType(false);
    }
    if (errors) {
      return false;
    }

    const index = getQuestionsLists?.findIndex(
      (object) => object.value?.trim()?.toUpperCase() === formValue?.value?.trim()?.toUpperCase()
    );
    if (index === -1) {
      seterrorsMsgShowActive(false);
      setErrorsMsgShow("");
      const questionList = [
        {
          value: formValue?.value,
          status: true,
          isTextAnswerType: responseType === 2 ? true : false,
          options: [],
        },
      ];
      return {
        questionList,
        subCatId: formValue?.subCategoriesValue,
        userId: parseInt(USER_ID()),
      };
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Question already added");
    }
  }

  const submitData = () => {
    const data = mappedData();

    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };
  const questionCancel = () => {
    setQuestionBox(false);
    setSubCategoriesName(false);
    setErrorResponseType(false);
    setIsQuestionName(false);
  };

  const handleChange = (item) => {
    const newQuestionList = [...getQuestionsLists];
    newQuestionList.forEach((lists) => {
      if (lists.key == item.key) {
        lists.status = !lists.status;
        lists.checked = !lists.checked;
        const questionList = [
          {
            key: item?.key,
            value: item?.value,
            status: item?.status,
            isTextAnswerType: item?.isTextAnswerType,
            options: [],
          },
        ];
        onUpdateSubmit({
          questionList,
          userId: parseInt(USER_ID()),
        });
      }
    });
  };

  useEffect(() => {
    if (getQuestionList) {
      setGetQuestionsLists(getQuestionList?.data?.questionList);
    }
  }, [getQuestionList]);

  useEffect(() => {
    if (
      createSwQuestion?.status === "SUCCESS" &&
      createSwQuestion?.isLoading === false
    ) {
      setIsSubmit(false);
      setQuestionBox(false);
      setFormValue({ ...formValue, value: "" });
      const data = {
        subcatId: [formValue?.subCategoriesValue],
      };
      getQuestionListData(data);
    }
  }, [createSwQuestion]);

  useEffect(() => {
    if (formValue?.subCategoriesValue) {
      const data = {
        subcatId: [formValue?.subCategoriesValue],
      };
      getQuestionListData(data);
    }
  }, [formValue?.subCategoriesValue]);

  useEffect(() => {
    setGetQuestionsLists([]);
  }, []);

  const toolBarData = {
    title: "Inspection Form Configuration",
    list: [
      {
        id: 1,
        title: "Inspection Header",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 2,
        title: "Category Configuration",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 3,
        title: "Sub Category Configuration",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 5,
        title: "Question Configuration",
        link: "",
        activeClass: "active",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 4,
        title: "Item Found Responses",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
    ],
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Question Configuration</div>
        </div>

        <div className="swQuestionBox swQuestionBoxQuestion">
          <Grid container>
            <Grid item md={8} xs={12} mt={3}>
              <Grid container>
                <Grid md={6} lg={6} xs={12} item>
                  <FormControl fullWidth>
                    <FormControl fullWidth>
                      <FormLabels
                        label="Select Sub Category"
                        isRequired={true}
                      />
                      <SelectMenu
                        placeholder="Please Select"
                        listData={getSubCategoriesList ?? []}
                        value={formValue.subCategoriesValue}
                        onchangehandler={(e) => handleSubCategoriesChange(e)}
                        isError={subCategoriesName}
                        errorMsg={
                          subCategoriesName ? "Sub Category is Required" : ""
                        }
                      />
                      {/* <MultiSelectBox
                    listData={getSubCategoriesList ?? []}
                    value={formValue.subCategoriesValue || []}
                    // onChange={handleSubCategoriesChange}
                    isError={subCategoriesName}
                    isIdShow={true}
                  /> */}
                    </FormControl>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container>
                {getQuestionsLists &&
                  getQuestionsLists?.map((questionItem) => {
                    return (
                      <Grid item md={12} xs={12} mb={1} key={questionItem?.key}>
                        <div className="questionContainerSW">
                          <div className="questionBoxListSw">
                            <div className="questionBoxSw withPadding">
                              {questionItem?.value}
                            </div>
                            <div className="questionCloseBoxSw">
                              <Grid className="catListBox">
                                <Grid className="closeIcon">
                                  <FormControl
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormGroup className="isActiveBox swins">
                                      {checkPrivileges([12, 93]) && (
                                        <FormControlLabel
                                          control={
                                            <ISwitch
                                              checked={
                                                questionItem?.status
                                                  ? questionItem?.status
                                                  : false
                                              }
                                              onChange={() =>
                                                handleChange(questionItem)
                                              }
                                              name="status"
                                            />
                                          }
                                        />
                                      )}
                                      <div>
                                        {questionItem?.status
                                          ? "Active"
                                          : "Inactive"}
                                      </div>
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                        <div className="responseTypeBox">
                          <div className="responseType">Response Type</div>
                          <div className="responseTypeRight">
                            <div
                              className={
                                questionItem?.isTextAnswerType === false
                                  ? "responseTypeBtn active"
                                  : "responseTypeBtn"
                              }
                            >
                              Acceptable/Not Acceptable/NA
                            </div>
                            <div
                              className={
                                questionItem?.isTextAnswerType === true
                                  ? "responseTypeBtn active"
                                  : "responseTypeBtn"
                              }
                            >
                              Text Box
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>

              <Grid container>
                {checkPrivileges([12, 93, 94]) && (
                  <Grid item md={12} xs={12} mt={4}>
                    <div
                      className="addQuestionSW"
                      onClick={() => addQuestionBox()}
                    >
                      {questionBox ? (
                        <>
                          <RemoveIcon className="addIconBtns" /> Remove Question
                        </>
                      ) : (
                        <>
                          <AddIcon className="addIconBtns" /> Add Question
                        </>
                      )}
                    </div>
                  </Grid>
                )}
                {questionBox && (
                  <Grid item md={12} xs={12} mb={1}>
                    <div className="questionContainerSW">
                      <div className="questionBoxListSw">
                        <div className="questionBoxSw">
                          <InputField
                            type="text"
                            isRequired={true}
                            value={formValue?.value}
                            label={false}
                            placeholder="Add Question Text Here..."
                            onChange={(e) =>
                              setFormValue({
                                ...formValue,
                                value: e.target.value,
                              })
                            }
                            isError={isQuestionName}
                            errorMsg={
                              isQuestionName ? "Question is Required" : ""
                            }
                          />
                        </div>
                        {/* <div className="questionCloseBoxSw">X</div> */}
                      </div>
                    </div>
                    <div className="responseTypeBox">
                      <div className="responseType">Response Type</div>
                      <div className="responseTypeRight">
                        <div
                          onClick={() => responseTypeHandler(1)}
                          className={
                            responseType === 1
                              ? "responseTypeBtn active"
                              : "responseTypeBtn"
                          }
                        >
                          Acceptable/Not Acceptable/NA
                        </div>
                        <div
                          onClick={() => responseTypeHandler(2)}
                          className={
                            responseType === 2
                              ? "responseTypeBtn active"
                              : "responseTypeBtn"
                          }
                        >
                          Text Box
                        </div>
                      </div>
                    </div>
                    <Grid md={12} item>
                      {errorResponseType && (
                        <p className="errorMsg">Please Select Response Type</p>
                      )}
                    </Grid>

                    <Grid md={12} item>
                      {errorsMsgShowActive && (
                        <p className="errorMsg">{errorsMsgShow}</p>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid container mt={15}>
                <Grid item lg={6} md={12} xs={12} className="sw">
                  <div className="insBackBtn">
                    <BackBtn dataBack={dataBack} />
                  </div>
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Grid
                    container
                    item
                    lg={12}
                    xs={12}
                    className="divisionBtnBox"
                  >
                    <Grid lg={6} xs={12} sm={6}>
                      <Buttons
                        onClick={questionCancel}
                        varientText={true}
                        label="Cancel"
                        disabled={
                          questionBox === false || isSubmit ? true : false
                        }
                      />
                    </Grid>
                    <Grid lg={6} xs={12} sm={6}>
                      <Button
                        varientContained={true}
                        label={"Save"}
                        disabled={
                          questionBox === false || isSubmit ? true : false
                        }
                        onClick={submitData}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid lg={4} md={12} xs={12} item className="inspectionTool">
              <div className="toolBarBox">
                <ToolBar
                  toolBarData={toolBarData}
                  handleClick={(e) => {
                    if (e.id === 1) {
                      navigate(INSPECTION_GENERATOR);
                    }
                    if (e.id === 2) {
                      navigate(SW_CATEGORIES);
                    }
                    if (e.id === 3) {
                      navigate(SW_SUB_CATEGORIES);
                    }
                    if (e.id === 4) {
                      navigate(ITEM_FOUND_RESPONSES);
                    }
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default QuestionConfiguration;
