import React, { useState, useEffect, useRef } from "react";
import { Grid, Box } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import Buttons from "../../../../library/custom/button/Button";
import commonImages from "../../../../assets";
import "./profile.scss";
import {
  API_STATUS,
  COUNTRY_CODE,
  LNG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  USERID,
  USER_ID,
} from "../../../../constant/constant";
import { ADMIN_PROFILE } from "../../../../constant/routeContant";
import Loader from "../../../../library/common/Loader";
import { getFormatedDate } from "../../../../utils/helper";
import { toast } from "react-toastify";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";

const ProfileForm = ({
  divisions,
  jobTitles,
  roles,
  getUserListDetsilsData,
  uploadProfileFile,
  uploadSignatureFile,
  isProfileSuccess,
  adduserLists,
  updateUserListData,
  isProfileSignature,
  isProfileSignatureLoading,
  editProfile,
  viewContractors,
  updateContractors,
  updateContractorData,
  uploadContractorProfileFile,
  uploadContractorSignatureFile,
  getClientDetsilsData,
  updateClientData,
  updateClient,
  uploadClientProfileFile,
  uploadClientSignatureFile,
  isClinetProfileSignature,
  isClientProfileSuccess,
  isClinetProfileSignatureLoading,
  isContractorProfileSuccess,
  isContractorProfileSignatureLoading,
  isContractorProfileSignature,
  fileUploadedData,
  fileErrorMessage,
  signatureImageError,
  profileImageError,
  clientProfileError,
  contractorProfileError,
  contractorSignatureError,
  clientSignatureError,
}) => {
  const orgName = localStorage.getItem("companyName");
  const loginOrganizationId = localStorage.getItem("loginOrganizationId");
  const orgId = localStorage.getItem("orgId");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isClient = userInfo?.isClient;
  const isContractor = userInfo?.isContractor;
  const clientRole = userInfo?.roles;
  const contractorUserId = userInfo?.userId;
  const contractorCompany = userInfo?.organization;
  const userDivision = userInfo?.divisions;

  const UserProfileShow = isClient === false && isContractor === false;
  const contractorProfileShow = isContractor === true && isClient === false;
  const clientProfileShow = isClient === true && isContractor === false;
  const serviceTypes = userInfo?.product;
  const resultArray = serviceTypes.map((item) => item.key);

  // for User
  const [userId, setUserid] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [jobTitle, setJobTitleName] = useState("");
  const [date, setDate] = useState(new Date());
  const [division, setDivision] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [isSubmit, setIsSubmit] = useState(false);
  const [divisionNames, setDivisionName] = useState([]);

  const [selectUserRole, setSelectUserRole] = useState("");
  const firstNameHandler = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameHandler = (e) => {
    setLastName(e.target.value);
  };

  const onChangeJobTitle = (e) => {
    setJobTitleName(e.target.value);
  };
  const companynameHandler = (e) => {
    setCompanyName(e.target.value);
  };

  const onchangedatePicker = (e) => {
    setDate(e);
  };

  const onChangeDivision = (e) => {
    setDivision(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangeSelectUserRole = (e) => {
    setSelectUserRole(e.target.value);
  };
  const [selectedSignature, setSelectedSignature] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");

  const hiddenSignatureInput = useRef(null);
  const hiddenProfileInput = useRef(null);

  const signatureUpload = (event) => {
    const fileUploaded = event.target.files[0];
    handleSignatureUploadMethod(fileUploaded);
  };
  const handleSignatureUploadMethod = (data) => {
    uploadSignatureFile(data);
  };

  const profileUpload = (event) => {
    const profileUploaded = event.target.files[0];
    handleProfileUploadMethod(profileUploaded);
  };

  const handleProfileUploadMethod = (data) => {
    uploadProfileFile(data);
  };

  useEffect(() => {
    if (isProfileSignature) {
      if (fileUploadedData) {
        setSelectedSignature(fileUploadedData?.files[0]?.fileUrl);
      }
    } else {
      if (fileUploadedData) {
        setSelectedProfile(fileUploadedData?.files[0]?.fileUrl);
      }
    }
  }, [fileUploadedData]);
  useEffect(() => {
    if (updateUserListData?.status === API_STATUS.SUCCESS) {
      toast("Profile Update Succesfully");
      localStorage.setItem("name", `${firstName} ${lastName}`);
      setTimeout(() => {
        window.location.href = ADMIN_PROFILE;
      }, 1000);
    }
  }, [updateUserListData]);

  function filterByServiceType(data, serviceType) {
    return data.filter((item) => item.serviceType === serviceType);
  }
  const filteredDataWithType1 = filterByServiceType(userDivision, 1);
  const filteredDataWithType2 = filterByServiceType(userDivision, 2);

  useEffect(() => {
    if (getUserListDetsilsData) {
      setUserid(getUserListDetsilsData?.userid);
      setFirstName(getUserListDetsilsData?.firstname);
      setLastName(getUserListDetsilsData?.lastname);
      setCompanyName(getUserListDetsilsData?.companyname || orgName);
      setDivision(getUserListDetsilsData?.divisionId);
      setEmail(getUserListDetsilsData?.email);
      setPhone(getUserListDetsilsData?.phonenumber);
      const phone = getUserListDetsilsData?.phonenumber;
      setPhone(
        phone && phone > 10
          ? phone.substring(phone.length - 10, phone.length)
          : phone
      );
      setSelectUserRole(getUserListDetsilsData?.roleId);
      setDate(getUserListDetsilsData?.startDate);
      setJobTitleName(getUserListDetsilsData?.jobTitleId);
      setSelectedProfile(getUserListDetsilsData?.profileImage);
      setSelectedSignature(getUserListDetsilsData?.signatureUrl);
    }
  }, [getUserListDetsilsData]);

  const onClickAddUser = (e) => {
    setIsSubmit(true);
    const userId = localStorage.getItem("userId");
    const sendData = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      companyname: orgName,
      isemailverified: false,
      phonenumber: phone,
      isphonenumberverified: true,
      jobTitleId: jobTitle,
      startDate: getUserListDetsilsData?.startDate,
      safetyDivisionIds: getUserListDetsilsData?.safetyDivisionIds,
      stormDivisionIds: getUserListDetsilsData?.stormDivisionIds,
      stormRole: getUserListDetsilsData?.stormRoles.map((item) => item.key),
      safetyRole: getUserListDetsilsData?.safetyRoles.map((item) => item.key),
      profileImage: selectedProfile,
      signatureUrl: selectedSignature,
      status: true,
      organisationId: LOGIN_ORG_ID(),
      userId: userId,
      modifiedbyuser: userId,
      createdbyapp: "0",
      productIds: resultArray,
    };
    localStorage.setItem("profileImage", selectedProfile);
    adduserLists(sendData);
  };
  useEffect(() => {
    let valueArray = [];
    divisions?.forEach((data) => {
      let newarr = getUserListDetsilsData?.divisionIds?.forEach((i) => {
        if (i === data.key) valueArray.push(data.value);
      });

      if (divisionNames.indexOf(data) >= 0) {
        valueArray.push(data.value);
      }
    });
    setDivisionName(valueArray);
  }, [getUserListDetsilsData, divisions]);

  // for client

  const [clinetuserId, setClientUserid] = useState("");
  const [ClientPocName, setClientPocName] = useState("");
  const [clientCompanyname, setClientCompanyName] = useState("");
  const [clinetEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");

  useEffect(() => {
    if (getClientDetsilsData) {
      setClientUserid(getClientDetsilsData?.data?.result?.userId);
      setClientPocName(getClientDetsilsData?.data?.result?.pointOfContact);
      setClientCompanyName(getClientDetsilsData?.data?.result?.fullName);
      setClientEmail(getClientDetsilsData?.data?.result?.email);
      setClientPhone(getClientDetsilsData?.data?.result?.phoneNumber);
      setSelectedClientSignature(
        getClientDetsilsData?.data?.result?.signatureUrl
      );
      setSelectedClientProfile(
        getClientDetsilsData?.data?.result?.profileImage
      );
    }
  }, [getClientDetsilsData]);

  useEffect(() => {
    localStorage.removeItem("profileImage");
  }, []);

  const clientPocNameHandler = (e) => {
    setClientPocName(e.target.value);
  };
  const onChangeClientEmail = (e) => {
    setClientEmail(e.target.value);
  };

  const clientcompanynameHandler = (e) => {
    setClientCompanyName(e.target.value);
  };

  const [selectedClientSignature, setSelectedClientSignature] = useState("");
  const [selectedClientProfile, setSelectedClientProfile] = useState("");

  const hiddenClientSignatureInput = useRef(null);
  const hiddenClientProfileInput = useRef(null);

  const clientsignatureUpload = (event) => {
    const fileUploaded = event.target.files[0];
    handleClientSignatureUploadMethod(fileUploaded);
  };
  const handleClientSignatureUploadMethod = (data) => {
    uploadClientSignatureFile(data);
  };

  const clientprofileUpload = (event) => {
    const profileUploaded = event.target.files[0];
    handleClientProfileUploadMethod(profileUploaded);
  };

  const handleClientProfileUploadMethod = (data) => {
    uploadClientProfileFile(data);
  };

  useEffect(() => {
    if (isClinetProfileSignature) {
      if (fileUploadedData) {
        setSelectedClientSignature(fileUploadedData?.files[0]?.fileUrl);
      }
    } else {
      if (fileUploadedData) {
        setSelectedClientProfile(fileUploadedData?.files[0]?.fileUrl);
      }
    }
  }, [fileUploadedData]);

  const onClickAddClientUser = (e) => {
    setIsSubmit(true);
    const orgId = localStorage.getItem("orgId");
    const sendData = {
      organisationId: orgId,
      fullName: getClientDetsilsData?.data?.result?.fullName,
      shortName: getClientDetsilsData?.data?.result?.shortName,
      organisationTypeId: LOGIN_ORG_ID(),
      businessTypeId: getClientDetsilsData?.data?.result?.industryTypeId,
      stateId: getClientDetsilsData?.data?.result?.stateId,
      cityId: getClientDetsilsData?.data?.result?.cityId,
      noOfEmployees: getClientDetsilsData?.data?.result?.noOfEmployees,
      preferredLanguageId:
        getClientDetsilsData?.data?.result?.preferredLanguageId,
      webSite: getClientDetsilsData?.data?.result?.webSite,
      createdBy: USER_ID(),
      phoneNumber: clientPhone,
      email: clinetEmail,
      address1: getClientDetsilsData?.data?.result?.address1,
      address2: getClientDetsilsData?.data?.result?.address2,
      pointOfContact: ClientPocName,
      zipCode: getClientDetsilsData?.data?.result?.zipCode,
      isActive:
        getClientDetsilsData?.data?.result?.statusId === 1 ? true : false,
      statusId: getClientDetsilsData?.data?.result?.statusId,
      isdCode: COUNTRY_CODE,
      logoUrl: getClientDetsilsData?.data?.result?.logoUrl,
      signatureUrl: selectedClientSignature,
      profileImage: selectedClientProfile,
      productIds: resultArray,
    };
    localStorage.setItem("profileImage", selectedClientProfile);
    updateClient(sendData);
  };

  useEffect(() => {
    if (updateClientData?.status === API_STATUS.SUCCESS) {
      toast("Profile Update Succesfully");
      localStorage.setItem("name", `${clientCompanyname}`);
      setTimeout(() => {
        window.location.href = ADMIN_PROFILE;
      }, 1000);
    }
  }, [updateClientData]);

  // for contractor

  const [contractorPocFirstName, setContractorPocFirstName] = useState("");
  const [contractorPocLasttName, setContractorPocLastName] = useState("");
  const [contractorCompanyname, setContractorCompanyName] = useState("");
  const [contractorEmail, setContractorEmail] = useState("");
  const [contractorPhone, setContractorPhone] = useState("");

  const contractorPocfisrstNameHandler = (e) => {
    setContractorPocFirstName(e.target.value);
  };

  const contractorPocLastNameHandler = (e) => {
    setContractorPocLastName(e.target.value);
  };

  const onChangeContractortEmail = (e) => {
    setContractorEmail(e.target.value);
  };

  useEffect(() => {
    if (viewContractors) {
      setContractorPocFirstName(viewContractors?.data?.result?.pocFirstName);
      setContractorPocLastName(viewContractors?.data?.result?.pocLastName);
      setContractorEmail(viewContractors?.data?.result?.pocEmail);
      setContractorPhone(viewContractors?.data?.result?.pocPhone);
      setContractorCompanyName(viewContractors?.data?.result?.contractorName);

      setSelectedContractorSignature(
        viewContractors?.data?.result?.signatureUrl
      );
      setSelectedContractorProfile(viewContractors?.data?.result?.profileImage);
    }
  }, [viewContractors]);

  const [selectedContractorSignature, setSelectedContractorSignature] =
    useState("");
  const [selectedContractorProfile, setSelectedContractorProfile] =
    useState("");

  const hiddenContractorSignatureInput = useRef(null);
  const hiddenContractorProfileInput = useRef(null);

  const contractorsignatureUpload = (event) => {
    const fileUploaded = event.target.files[0];
    handleContractorSignatureUploadMethod(fileUploaded);
  };
  const handleContractorSignatureUploadMethod = (data) => {
    uploadContractorSignatureFile(data);
  };

  const contractorprofileUpload = (event) => {
    const profileUploaded = event.target.files[0];
    handleContractorProfileUploadMethod(profileUploaded);
  };

  const handleContractorProfileUploadMethod = (data) => {
    uploadContractorProfileFile(data);
  };

  useEffect(() => {
    if (isContractorProfileSignature) {
      if (fileUploadedData) {
        setSelectedContractorSignature(fileUploadedData?.files[0]?.fileUrl);
      }
    } else {
      if (fileUploadedData) {
        setSelectedContractorProfile(fileUploadedData?.files[0]?.fileUrl);
      }
    }
  }, [fileUploadedData]);

  const onClickAddContractorUser = (e) => {
    setIsSubmit(true);
    const orgId = localStorage.getItem("orgId");
    const userId = localStorage.getItem("userId");
    // const keyDynamic = editMode ? "modifiedBy" : "createdBy";
    const sendData = {
      contractorTypeId: viewContractors?.data?.result?.contractorTypeId,
      stateId: viewContractors?.data?.result?.stateId,
      contractorName: viewContractors?.data?.result?.contractorName,
      gradeId: viewContractors?.data?.result?.gradeId,
      divisionId: viewContractors?.data?.result?.divisionId,
      pocFirstName: contractorPocFirstName,
      pocLastName: contractorPocLasttName,
      pocEmail: contractorEmail,
      pocPhone: viewContractors?.data?.result?.pocPhone,
      pocCity: viewContractors?.data?.result?.pocCity,
      pocZipCode: viewContractors?.data?.result?.pocZipCode,
      pocNotes: viewContractors?.data?.result?.pocNotes,
      isdCode: COUNTRY_CODE,
      modifiedBy: userId,
      organisationId: ORG_ID(),
      contractorId: viewContractors?.data?.result?.contractorId,
      signatureUrl: selectedContractorSignature,
      profileImage: selectedContractorProfile,
      productIds: resultArray,
    };
    localStorage.setItem("profileImage", selectedContractorProfile);
    updateContractors(sendData);
  };

  useEffect(() => {
    if (updateContractorData?.status === API_STATUS.SUCCESS) {
      toast("Profile Update Succesfully");
      localStorage.setItem("name", `${firstName} ${lastName}`);
      setTimeout(() => {
        window.location.href = ADMIN_PROFILE;
      }, 1000);
    }
  }, [updateContractorData]);

  return (
    <>
      {UserProfileShow && (
        <>
          {" "}
          <div className="overideProfile">
            {!isProfileSuccess && <Loader />}
            {isProfileSignatureLoading && <Loader />}
            <Box mt={3}>
              <Grid container className="topHeaderProfile">
                <Grid item md={6}>
                  <Grid className="profileImg">
                    <img
                      className="borderProfileImg"
                      src={
                        selectedProfile
                          ? selectedProfile
                          : commonImages?.DefaultProfilePic
                      }
                      alt=""
                    />

                    <input
                      type="file"
                      ref={hiddenProfileInput}
                      onChange={(event) => {
                        profileUpload(event);
                      }}
                      style={{ display: "none" }}
                      accept="image/jpeg, image/png, image/jpg"
                    />
                    <label
                      for="profile-btn"
                      onClick={() => {
                        hiddenProfileInput.current.click();
                      }}
                    >
                      <Grid className="editProfileicon">
                        <img src={commonImages?.edit} alt="" />
                      </Grid>
                    </label>
                  </Grid>
                  {profileImageError && (
                    <div className="error">{fileErrorMessage}</div>
                  )}
                </Grid>
                <Grid container md={6} className="btnProfleBox">
                  {orgId === loginOrganizationId && (
                    <Grid className="btnProfile">
                      <span>
                        <img src={commonImages?.whiteEditIcon} alt="" />
                      </span>
                      <span>Edit Profile</span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container mt={2} spacing={3}>
                <Grid item className="profileTitle" sm={12}>
                  Personal Details
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={true}
                      isRequired={false}
                      type="text"
                      label={"User ID"}
                      value={userId}
                      placeholder="Enter first name"
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      isRequired={true}
                      type="text"
                      label={"First Name"}
                      value={firstName}
                      placeholder="Enter first name"
                      onChange={(e) => firstNameHandler(e)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      isRequired={true}
                      type="text"
                      label={"Last Name"}
                      value={lastName}
                      placeholder="Enter last name"
                      onChange={(e) => lastNameHandler(e)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Company" />
                    <InputField
                      disabled={true}
                      isRequired={true}
                      type="text"
                      value={companyname}
                      onChange={(e) => companynameHandler(e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container mt={2} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <FormControl fullWidth={true}>
                      <InputField
                        isRequired={true}
                        type="text"
                        label={"Email"}
                        value={email}
                        placeholder="Enter Email"
                        onChange={(e) => onChangeEmail(e)}
                      />
                    </FormControl>
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <FormControl fullWidth={true}>
                      <InputField
                        disabled={true}
                        isRequired={true}
                        type="text"
                        label={"Phone"}
                        value={phone}
                        placeholder="Enter Phonr number 91+"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </FormControl>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container mt={0} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Job Title" isRequired={true} />
                    <SelectMenu
                      listData={jobTitles}
                      value={jobTitle}
                      onchangehandler={(e) => onChangeJobTitle(e)}
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label={"Hire Date"} isRequired={true} />
                    <DatePicker
                      value={date}
                      onChangeHandler={(e) => onchangedatePicker(e)}
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container mt={0} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4} mt={1}>
                  <FormControl fullWidth>
                    <FormLabels label="Division" />
                    {PRODUCT_ID() == 1 && (
                      <span className="rolesList">
                        {filteredDataWithType1?.map((i) => i.value).join(", ")}
                      </span>
                    )}
                    {PRODUCT_ID() == 2 && (
                      <span className="rolesList">
                        {filteredDataWithType2?.map((i) => i.value).join(", ")}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Assigned User Role" />
                    {PRODUCT_ID() == 1 && (
                      <span className="rolesList">
                        {getUserListDetsilsData?.safetyRoles
                          ?.map((i) => i.value)
                          .join(", ")}
                      </span>
                    )}
                    {PRODUCT_ID() == 2 && (
                      <span className="rolesList">
                        {getUserListDetsilsData?.stormRoles
                          ?.map((i) => i.value)
                          .join(", ")}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item mt={3} className="profileTitle" sm={12}>
                System Setting
              </Grid>
              <Grid
                item
                container
                mt={0}
                spacing={3}
                className="topHeaderProfile"
              >
                <Grid item lg={6} xs={12} mt={2}>
                  <Grid className="signatureImg">
                    {selectedSignature ? (
                      <img
                        className="borderSignatureImg"
                        src={selectedSignature}
                        alt=""
                      />
                    ) : (
                      <p className="signatureText">No Signature Added</p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                mt={0}
                spacing={3}
                className="topHeaderProfile"
              >
                <Grid item lg={3} xs={12} mt={2}>
                  <input
                    type="file"
                    ref={hiddenSignatureInput}
                    onChange={(event) => {
                      signatureUpload(event);
                    }}
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png, image/jpg"
                  />
                  <label
                    className="signatureBtn"
                    onClick={() => {
                      hiddenSignatureInput.current.click();
                    }}
                    htmlFor="actual-btn"
                  >
                    Update Signature
                  </label>
                </Grid>
                {signatureImageError && (
                  <div className="error">{fileErrorMessage}</div>
                )}
              </Grid>

              <Grid container mb={10} mt={3} spacing={3}>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  sm={12}
                  md={4}
                  textAlign="left"
                ></Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <div className="adduser_btn">
                    <div
                      className="cancle_heading_style"
                      onClick={() => editProfile(1)}
                    >
                      Cancel
                    </div>
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={"Update User"}
                      varientContained={true}
                      onClick={(e) => onClickAddUser(e)}
                      disabled={isSubmit ? true : false}
                    ></Buttons>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </>
      )}

      {clientProfileShow && (
        <>
          <div className="overideProfile">
            {!isClientProfileSuccess && <Loader />}
            {isClinetProfileSignatureLoading && <Loader />}
            <Box mt={3}>
              <Grid container className="topHeaderProfile">
                <Grid item md={6}>
                  <Grid className="profileImg">
                    <img
                      className="borderProfileImg"
                      src={
                        selectedClientProfile
                          ? selectedClientProfile
                          : commonImages?.DefaultProfilePic
                      }
                      alt=""
                    />

                    <input
                      type="file"
                      ref={hiddenClientProfileInput}
                      onChange={(event) => {
                        clientprofileUpload(event);
                      }}
                      style={{ display: "none" }}
                      accept="image/jpeg, image/png, image/jpg"
                    />
                    <label
                      for="profile-btn"
                      onClick={() => {
                        hiddenClientProfileInput.current.click();
                      }}
                    >
                      <Grid className="editProfileicon">
                        <img src={commonImages?.edit} alt="" />
                      </Grid>
                    </label>
                  </Grid>
                </Grid>
                {clientProfileError && (
                  <div className="error">{fileErrorMessage}</div>
                )}
                <Grid container md={6} className="btnProfleBox">
                  {orgId === loginOrganizationId && (
                    <Grid className="btnProfile">
                      <span>
                        <img src={commonImages?.whiteEditIcon} alt="" />
                      </span>
                      <span>Edit Profile</span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container mt={2} spacing={3}>
                <Grid item className="profileTitle" sm={12}>
                  Personal Details
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={true}
                      isRequired={false}
                      type="text"
                      label={"User ID"}
                      value={clinetuserId}
                      placeholder="Enter first name"
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      isRequired={true}
                      type="text"
                      label={"Point Of Contact Name"}
                      value={ClientPocName}
                      placeholder="Enter Point of Conatact name"
                      onChange={(e) => clientPocNameHandler(e)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <FormControl fullWidth={true}>
                      <InputField
                        isRequired={true}
                        type="text"
                        label={"Email"}
                        value={clinetEmail}
                        placeholder="Enter Email"
                        onChange={(e) => onChangeClientEmail(e)}
                      />
                    </FormControl>
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Company" />
                    <InputField
                      disabled={true}
                      isRequired={true}
                      type="text"
                      value={clientCompanyname}
                      onChange={(e) => clientcompanynameHandler(e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container mt={2} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <FormControl fullWidth={true}>
                      <InputField
                        disabled={true}
                        isRequired={true}
                        type="text"
                        label={"Phone"}
                        value={clientPhone}
                        placeholder="Enter Phonr number 91+"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </FormControl>
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4} mt={1}>
                  <FormControl fullWidth>
                    {/* <FormLabels label="Select User Role" isRequired={true} /> */}

                    <FormLabels label="Assigned User Role" />
                    {/* 
            todo
            <SelectMenu
              listData={roles}
              value={selectUserRole}
              onchangehandler={(e) => onChangeSelectUserRole(e)}
              disabled={true}
            /> */}
                    <span className="rolesList">
                      {clientRole?.map((i) => i.name).join(", ")}
                    </span>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item mt={3} className="profileTitle" sm={12}>
                System Setting
              </Grid>
              <Grid
                item
                container
                mt={0}
                spacing={3}
                className="topHeaderProfile"
              >
                <Grid item lg={6} xs={12} mt={2}>
                  <Grid className="signatureImg">
                    {selectedClientSignature ? (
                      <img
                        className="borderSignatureImg"
                        src={selectedClientSignature}
                        alt=""
                      />
                    ) : (
                      <p className="signatureText">No Signature Added</p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                mt={0}
                spacing={3}
                className="topHeaderProfile"
              >
                <Grid item lg={3} xs={12} mt={2}>
                  <input
                    type="file"
                    ref={hiddenClientSignatureInput}
                    onChange={(event) => {
                      clientsignatureUpload(event);
                    }}
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png, image/jpg"
                  />
                  <label
                    className="signatureBtn"
                    onClick={() => {
                      hiddenClientSignatureInput.current.click();
                    }}
                    htmlFor="actual-btn"
                  >
                    Upload Signature
                  </label>
                </Grid>
              </Grid>
              {clientSignatureError && (
                <div className="error">{fileErrorMessage}</div>
              )}

              <Grid container mb={10} mt={3} spacing={3}>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  sm={12}
                  md={4}
                  textAlign="left"
                ></Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <div className="adduser_btn">
                    <div
                      className="cancle_heading_style"
                      onClick={() => editProfile(1)}
                    >
                      Cancel
                    </div>
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={"Update User"}
                      varientContained={true}
                      onClick={(e) => onClickAddClientUser(e)}
                      disabled={isSubmit ? true : false}
                    ></Buttons>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </>
      )}
      {contractorProfileShow && (
        <>
          {" "}
          <div className="overideProfile">
            {!isContractorProfileSuccess && <Loader />}
            {isContractorProfileSignatureLoading && <Loader />}
            <Box mt={3}>
              <Grid container className="topHeaderProfile">
                <Grid item md={6}>
                  <Grid className="profileImg">
                    <img
                      className="borderProfileImg"
                      src={
                        selectedContractorProfile
                          ? selectedContractorProfile
                          : commonImages?.DefaultProfilePic
                      }
                      alt=""
                    />

                    <input
                      type="file"
                      ref={hiddenContractorProfileInput}
                      onChange={(event) => {
                        contractorprofileUpload(event);
                      }}
                      style={{ display: "none" }}
                      accept="image/jpeg, image/png, image/jpg"
                    />
                    <label
                      for="profile-btn"
                      onClick={() => {
                        hiddenContractorProfileInput.current.click();
                      }}
                    >
                      <Grid className="editProfileicon">
                        <img src={commonImages?.edit} alt="" />
                      </Grid>
                    </label>
                  </Grid>
                  {contractorProfileError && (
                    <div className="error">{fileErrorMessage}</div>
                  )}
                </Grid>

                <Grid container md={6} className="btnProfleBox">
                  {orgId === loginOrganizationId && (
                    <Grid className="btnProfile">
                      <span>
                        <img src={commonImages?.whiteEditIcon} alt="" />
                      </span>
                      <span>Edit Profile</span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container mt={2} spacing={3}>
                <Grid item className="profileTitle" sm={12}>
                  Personal Details
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={true}
                      isRequired={false}
                      type="text"
                      label={"User ID"}
                      value={contractorUserId}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      isRequired={true}
                      type="text"
                      label={"First Name"}
                      value={contractorPocFirstName}
                      placeholder="Enter first name"
                      onChange={(e) => contractorPocfisrstNameHandler(e)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      isRequired={true}
                      type="text"
                      label={"Last Name"}
                      value={contractorPocLasttName}
                      placeholder="Enter last name"
                      onChange={(e) => contractorPocLastNameHandler(e)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Company" />
                    <InputField
                      disabled={true}
                      isRequired={true}
                      type="text"
                      value={contractorCompanyname}
                      onChange={(e) => setContractorCompanyName(e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container mt={2} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <FormControl fullWidth={true}>
                      <InputField
                        isRequired={true}
                        type="text"
                        label={"Email"}
                        value={contractorEmail}
                        placeholder="Enter Email"
                        onChange={(e) => onChangeContractortEmail(e)}
                      />
                    </FormControl>
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <FormControl fullWidth={true}>
                      <InputField
                        disabled={true}
                        isRequired={true}
                        type="text"
                        label={"Phone"}
                        value={contractorPhone}
                        placeholder="Enter Phonr number 91+"
                        onChange={(e) => setContractorPhone(e.target.value)}
                      />
                    </FormControl>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container mt={0} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    {/* <FormLabels label="Division" isRequired={true} /> */}
                    <FormLabels label="Division" />
                    {/*
            todo
            <SelectMenu
              listData={divisions}
              value={division}
              onchangehandler={(e) => onChangeDivision(e)}
              disabled={true}
            /> */}

                    <span className="rolesList">
                      {divisionNames?.map((i) => i).join(", ")}
                    </span>
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    {/* <FormLabels label="Select User Role" isRequired={true} /> */}

                    <FormLabels label="Assigned User Role" />
                    {/* 
            todo
            <SelectMenu
              listData={roles}
              value={selectUserRole}
              onchangehandler={(e) => onChangeSelectUserRole(e)}
              disabled={true}
            /> */}
                    <span className="rolesList">
                      {getUserListDetsilsData?.roles
                        ?.map((i) => i.value)
                        .join(", ")}
                    </span>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item mt={3} className="profileTitle" sm={12}>
                System Setting
              </Grid>
              <Grid
                item
                container
                mt={0}
                spacing={3}
                className="topHeaderProfile"
              >
                <Grid item lg={6} xs={12} mt={2}>
                  <Grid className="signatureImg">
                    {selectedContractorSignature ? (
                      <img
                        className="borderSignatureImg"
                        src={selectedContractorSignature}
                        alt=""
                      />
                    ) : (
                      <p className="signatureText">No Signature Added</p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                mt={0}
                spacing={3}
                className="topHeaderProfile"
              >
                <Grid item lg={3} xs={12} mt={2}>
                  <input
                    type="file"
                    ref={hiddenContractorSignatureInput}
                    onChange={(event) => {
                      contractorsignatureUpload(event);
                    }}
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png, image/jpg"
                  />
                  <label
                    className="signatureBtn"
                    onClick={() => {
                      hiddenContractorSignatureInput.current.click();
                    }}
                    htmlFor="actual-btn"
                  >
                    Upload Signature
                  </label>
                </Grid>
                {contractorSignatureError && (
                  <div className="error">{fileErrorMessage}</div>
                )}
              </Grid>
              <Grid container mb={10} mt={3} spacing={3}>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  sm={12}
                  md={4}
                  textAlign="left"
                ></Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <div className="adduser_btn">
                    <div
                      className="cancle_heading_style"
                      onClick={() => editProfile(1)}
                    >
                      Cancel
                    </div>
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={"Update User"}
                      varientContained={true}
                      onClick={(e) => onClickAddContractorUser(e)}
                      disabled={isSubmit ? true : false}
                    ></Buttons>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default ProfileForm;
