import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Buttons from "../../../../library/custom/button/Button";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import {
  HBNEXT_ORG_ID,
  ISwitch,
  ORG_ID,
  ORG_NAME,
  SITE_GRID_COLOUMNS,
  SITE_TEMPLATE_URL,
  USER_ID,
} from "../../../../constant/constant";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import LocationField from "../../../../library/common/LocationField";
import ToolBar from "../../../../library/storm-water/toolBar";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { TIMEZONE_OPTION } from "../../../../constant/storm-water/constant";
import { onlyContainsNumbers } from "../../../../utils/helper";
function AddSite({
  editMode,
  state,
  city,
  getCity,
  addSite,
  getSiteDetsilsData,
  siteId,
  dataBack,
  customer,
  // setEditMode,
  divisions,
}) {
  const customerName = customer ?? ORG_NAME();
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [formType, setFormType] = useState(1);
  const [siteName, setSiteName] = useState("");
  const [siteArea, setSiteArea] = useState("");
  const [states, setStates] = useState("");
  const [cities, setCity] = useState(null);
  const [adress, setAdress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [location, setLocations] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [checked, setChecked] = useState(true);
  const [division, setDivision] = useState("");
  const [siteNameError, setSiteNameError] = useState(false);
  const [siteAreaError, setSiteAreaError] = useState(false);
  const [statesError, setStatesError] = useState(false);
  const [locationError, setLocationsError] = useState(false);
  const [divisionError, setDivisionError] = useState(false);
  const [timezone, setTimezone] = useState(null);
  //const [timeZoneError, setTimeZoneError] = useState(false);

  const history = useNavigate();
  useEffect(() => {
    if (getSiteDetsilsData?.data && editMode) {
      getCity(getSiteDetsilsData?.data?.result?.stateId);
      {
        setFormType(2);
        setSiteName(getSiteDetsilsData?.data?.result?.siteName);
        setSiteArea(getSiteDetsilsData?.data?.result?.disturbedArea);
        setStates(getSiteDetsilsData?.data?.result?.stateId);
        setCity(getSiteDetsilsData?.data?.result?.cityId);
        setAdress(getSiteDetsilsData?.data?.result?.address);
        getCity(getSiteDetsilsData?.data?.result?.stateId);
        const zipCode = getSiteDetsilsData?.data?.result?.zip;
        setZipCode(
          zipCode && zipCode > 10
            ? zipCode.substring(zipCode.length - 5, zipCode.length)
            : zipCode
        );
        setLocations(getSiteDetsilsData?.data?.result?.location);
        setOwnerName(getSiteDetsilsData?.data?.result?.primaryOwner);
        setChecked(getSiteDetsilsData?.data?.result?.status);
        setDivision(getSiteDetsilsData?.data?.result?.divisionId);
        setTimezone(getSiteDetsilsData?.data?.result?.timezone);
      }
    }
  }, [getSiteDetsilsData?.data]);

  const onChangeSiteName = (event) => {
    setSiteName(event.target.value);
  };
  const onChangeSiteArea = (event) => {
    setSiteArea(event.target.value);
  };
  const onChangeState = (event) => {
    const stateId = event.target.value;
    getCity(stateId);
    setStates(event.target.value);
  };

  const onChangeDivision = (e) => {
    setDivision(e.target.value);
  };

  const onChangeCity = (event) => {
    setCity(event.target.value);
  };
  const onChangeAdress = (event) => {
    setAdress(event.target.value);
  };
  const onChangeZipCode = (event) => {
    setZipCode(event.target.value);
  };
  const onChangeLocation = (event) => {
    setLocations(event.target.value);
  };

  const onChangeOwnerName = (event) => {
    setOwnerName(event.target.value);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const addSites = () => {
    let isFieldEmpty = false;
    if (siteName.trim() === "") {
      setSiteName("");
      setSiteNameError(true);
      isFieldEmpty = true;
    }
    // if (!siteArea) {
    //   setSiteAreaError(true);
    //   isFieldEmpty = true;
    // }
    if (!states) {
      setStatesError(true);
      isFieldEmpty = true;
    }

    // if (!timezone) {
    //   isFieldEmpty = true;
    //   setTimeZoneError(true);
    // }
    if (location.trim() === "") {
      setLocations("");
      setLocationsError(true);
      isFieldEmpty = true;
    }
    if (!division) {
      setDivisionError(true);
      isFieldEmpty = true;
    }

    if (!isFieldEmpty) {
      const sendData = {
        siteDetails: [
          {
            siteName: siteName,
            disturbedArea: parseFloat(siteArea),
            stateId: states,
            cityId: cities,
            address: adress,
            location: location,
            zip: zipCode,
            primaryOwner: ownerName,
            isActive: checked,
            divisionId: division,
            isGlobal: parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID),
            timezone: timezone,
          },
        ],
      };
      const sendEdit = {
        siteName: siteName,
        disturbedArea: parseFloat(siteArea),
        stateId: states,
        cityId: cities,
        address: adress,
        location: location,
        zip: zipCode,
        primaryOwner: ownerName,
        isActive: checked,
        siteId: siteId,
        modifiedBy: parseInt(USER_ID()),
        divisionId: division,
        isGlobal: getSiteDetsilsData?.data?.result?.isGlobal,
        timezone: timezone,
      };

      addSite(formType === 2 ? sendEdit : sendData, formType);
    }
  };

  const handleFileChanges = (data) => {
    const sites = data.map((x, index) => {
      x.disturbedArea = parseFloat(x.disturbedArea);
      x.stateId = parseInt(x.stateId);
      x.cityId = parseInt(x.cityId);
      x.divisionId = parseInt(x.divisionId);
      x.isActive = x.isActive == "FALSE" ? false : true;
      return x;
    });
    const siteData = {
      siteDetails: sites,
      createdBy: parseInt(USER_ID()),
    };
    addSite(siteData);
  };

  const toolBarData = {
    title: "Site Tools",
    list: [
      {
        id: 1,
        title: "Site List",
        link: "",
      },
    ],
  };

  const toolBarDataEditMode = {
    title: "Site Tools",
    list: [
      {
        id: 1,
        title: "Site List",
        link: "",
      },
      {
        id: 2,
        title: "Add Site",
        link: "",
      },
    ],
  };
  function handleAddSiteClick() {
    setFormType(1);
    setSiteName("");
    setSiteArea("");
    setStates("");
    setCity("");
    setAdress("");
    getCity("");
    const zipCode = "";
    setZipCode("");
    setLocations("");
    setOwnerName("");
    setChecked(true);
  }

  const handleDisturbedChange = (event) => {
    const re = /^[0-9.\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      var count = (event.target.value.match(/\./g) || []).length;
      if (count > 1) {
        var minval = event.target.value.replace(/\.+$/, "");
      }

      setSiteArea(event.target.value);
      if (minval) {
        setSiteArea("");
      }
    }
  };

  const onChangeTimeZone = (e) => {
    setTimezone(e.target.value);
  };

  const mapBulkError = (data) => {
    let siteWithError = [];
    data.forEach((x) => {
      if (
        x.siteName?.trim() === undefined ||
        null ||
        x.stateId?.trim() === undefined ||
        null ||
        "" ||
        x.divisionId?.trim() === undefined ||
        null ||
        "" ||
        x.location?.trim() === undefined ||
        null ||
        "" ||
        x.zip?.trim().length >= 6 ||
        (x.zip && onlyContainsNumbers(x.zip) === false)
      ) {
        siteWithError.push(x);
        return;
      }
    });
    return siteWithError;
  };
  return (
    <div className="overidePersonal">
      <Grid container>
        <Grid lg={8} md={12} xs={12} pr={2}>
          <Grid container item lg={12} xs={12} sm={12} md={9}>
            <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
              <div className="heading_style">
                {formType === 2 ? "Edit Site" : "Add Site"}
              </div>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={4} textAlign="right">
              {!editMode && checkPrivileges([12, 108]) ? (
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={
                    isBulkUpload ? "Add Single Site" : "Add Multiple Sites"
                  }
                  varientContained={true}
                  onClick={() => setIsBulkUpload(!isBulkUpload)}
                ></Buttons>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <div>
              {isBulkUpload ? (
                <Grid container>
                  <Grid item lg={12} xs={12} sm={12} md={12} mt={4}>
                    <BulkUpload
                      handleFileChanges={handleFileChanges}
                      mapBulkError={mapBulkError}
                      paraText1={"Drop the files here ..."}
                      paraText2={"Drop the files here, "}
                      paraText3={"or Browse"}
                      templateUrl={SITE_TEMPLATE_URL}
                      templateText="Download Template"
                      gridColoumns={SITE_GRID_COLOUMNS}
                      isCustomColoumns={true}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container item md={12} xs={12} mt={4}>
                  <Grid item container spacing={2}>
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <InputField
                          isRequired={true}
                          type="text"
                          label="Site Name"
                          placeholder={"Add Site Name Here..."}
                          value={siteName}
                          onChange={(e) => onChangeSiteName(e)}
                        />
                      </FormControl>
                      {siteNameError && !siteName && (
                        <div className="errorMsg">
                          Please enter this required field
                        </div>
                      )}
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels
                          label={"Disturbed Acreage"}
                          isRequired={false}
                        />
                        <InputField
                          fullWidth={true}
                          disabled={false}
                          type="text"
                          placeholder={"Add disturbed acreage here..."}
                          value={siteArea}
                          onChange={(event, value) =>
                            handleDisturbedChange(event)
                          }
                        />
                      </FormControl>
                      {/* {siteAreaError && !siteArea && (
                        <div className="errorMsg">
                          Please enter this required field
                        </div>
                      )} */}
                    </Grid>
                  </Grid>
                  <Grid item container mt={1} spacing={2}>
                    <Grid item lg={6} xs={12} sm={12} md={4}>
                      <FormControl fullWidth>
                        <FormLabels label="Division" isRequired={true} />
                        <SelectMenu
                          placeholder="Please Select"
                          listData={divisions}
                          value={division}
                          onchangehandler={(e) => onChangeDivision(e)}
                        />
                      </FormControl>
                      {divisionError && !division && (
                        <div className="errorMsg">
                          Please enter this required field
                        </div>
                      )}
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <InputField
                          isRequired={false}
                          type="text"
                          label="Owner"
                          placeholder={"Add Owner Name Here..."}
                          value={ownerName}
                          onChange={(e) => onChangeOwnerName(e)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item container mt={1} spacing={2}>
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels label="State" isRequired={true} />
                        <SelectMenu
                          listData={state}
                          value={states}
                          onchangehandler={(e) => onChangeState(e)}
                          placeholder="Please Select"
                        />
                      </FormControl>
                      {statesError && !states && (
                        <div className="errorMsg">
                          Selection is required for this field
                        </div>
                      )}
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels label="City" isRequired={false} />
                        <SelectMenu
                          listData={city}
                          value={cities}
                          onchangehandler={(e) => onChangeCity(e)}
                          placeholder="Please Select"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container mt={1} spacing={2}>
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <LocationField
                          icon={true}
                          isRequired={true}
                          disabled={formType === 2}
                          type="text"
                          label="Location"
                          placeholder={"Add Location Here..."}
                          value={location}
                          onChangeLocations={(e) => setLocations(e)}
                          onChange={(e) => onChangeLocation(e)}
                        />
                      </FormControl>
                      {locationError && !location && (
                        <div className="errorMsg">
                          Selection is required for this field
                        </div>
                      )}
                    </Grid>

                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <InputField
                          isRequired={false}
                          type="text"
                          label="Address/Street"
                          placeholder={"Add Address/Street Here..."}
                          value={adress}
                          onChange={(e) => onChangeAdress(e)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container mt={1} spacing={2}>
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <NumberField
                          disabled={false}
                          isRequired={false}
                          type="text"
                          label={"Zip Code"}
                          maxLength="5"
                          value={zipCode}
                          placeholder={"Add Zip Code Here..."}
                          onChange={(value) =>
                            value.length <= 5 ? setZipCode(value) : zipCode
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels label="Timezone" isRequired={false} />
                        <SelectMenu
                          listData={TIMEZONE_OPTION}
                          value={timezone}
                          onchangehandler={(e) => onChangeTimeZone(e)}
                          placeholder="Please Select"
                        />
                      </FormControl>
                      {/* {timeZoneError && !timezone && (
                        <div className="errorMsg">
                          Selection is required for this field
                        </div>
                      )} */}
                    </Grid>
                  </Grid>
                  {checkPrivileges([12, 108]) && (
                    <Grid container mt={3}>
                      <Grid lg={5} xs={12} sm={6} md={4}>
                        <FormControl
                          component="fieldset"
                          variant="standard"
                          className="clientswitch"
                        >
                          <FormLabel
                            className="clientStatus"
                            component="status"
                          >
                            Status
                          </FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <ISwitch
                                  checked={checked}
                                  onChange={handleChange}
                                  name="status"
                                />
                              }
                              label={checked ? "Active" : "InActive"}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container mt={3} spacing={1}>
                    <Grid lg={2} xs={12} sm={4} md={4}>
                      <div className="">
                        <BackBtn dataBack={dataBack} />
                      </div>
                    </Grid>
                    <Grid item lg={4} xs={12} sm={12} md={4}></Grid>
                    <Grid lg={2} xs={12} sm={4} md={4} mt={1.5}>
                      <Buttons
                        varientText={true}
                        label="Cancel"
                        onClick={() => history(-1)}
                      />
                    </Grid>
                    <Grid lg={3} xs={12} sm={4} md={4} mt={1.5}>
                      <Buttons
                        varientContained={true}
                        label={editMode ? "Update" : "Save"}
                        onClick={() => addSites()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid lg={4} md={12} xs={12} pl={1}>
          <div className="toolBarBox">
            {formType === 2 && (
              <div className="otherDetail">
                <h3>Site Details</h3>
                <h2>{siteName}</h2>
              </div>
            )}
            {formType === 2 && (
              <div className="otherDetail">
                <h3>Client Name</h3>
                <h2>{customerName}</h2>
              </div>
            )}
            <ToolBar
              toolBarData={formType === 2 ? toolBarDataEditMode : toolBarData}
              handleClick={(e) => {
                if (e.id === 1) {
                  history(-1);
                }
                if (e.id === 2) {
                  editMode = false;
                  handleAddSiteClick();
                }
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddSite;
