import React, { useEffect, useState } from "react";
import Buttons from "../../../library/custom/button/Button";
import "./ticketsFeedbackDesk.scss";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Ticket from "../component/ticket/ticket";
import FilterList from "../common/filterList";
import CreateTicket from "../component/ticket/createTicket";
import * as supportAction from "../../../redux/actions/supportAction";
import Loader from "../../../library/common/Loader";
import AdminOpenTicket from "../component/ticket/adminOpenTicket";
import SuccessScreen from "../common/successSreen";
import FilterButton from "../../../library/custom/filterButton/filterButton";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_TICKET_DETAILS, CREATE_TICKET } from "../../../constant/routeContant";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TicketsFeedbackDesk = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const tab = location?.state?.tab;
  const defaultData = {
    priorityIds: "",
    search: "",
    satisfectionType: tab ? tab : tab === 0 ? 0 : "",
  }
  console.log(tab)
  const [ShowFullTicket, setShowFullTicket] = useState(false);
  const [showTicketForm, setShowTicketForm] = useState(false);
  const [showSucessScreen, setshowSucessScreen] = useState(false);

  const [getTicketsFeedbackDeskData, setgetTicketsFeedbackDeskData] = useState(defaultData);

  const tickets = useSelector(
    (state) => state.getTicketsFeedbackDesk?.data?.ticketList
  );
  const [ticketsState, setTicketsState] = useState(null);
  useEffect(() => {
    setTicketsState(tickets);
  }, [tickets]);
  useEffect(() => {
    dispatch(supportAction.getTicketsFeedbackDesk(getTicketsFeedbackDeskData));
  }, [getTicketsFeedbackDeskData]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [priorityState, setpriorityState] = useState({
    low: false,
    medium: false,
    high: false,
  });

  const handleChange = (event) => {
    const newPriorityState = {
      ...priorityState,
      [event.target.name]: event.target.checked,
    };

    setpriorityState(newPriorityState);
    const arr = [];
    if (newPriorityState.high) {
      arr.push(1);
    }
    if (newPriorityState.medium) {
      arr.push(2);
    }
    if (newPriorityState.low) {
      arr.push(3);
    }
    const priorityIds = arr.join(",");
    setgetTicketsFeedbackDeskData({
      ...getTicketsFeedbackDeskData,
      priorityIds: priorityIds,
    });
  };

  const [tabValue, setTabValue] = React.useState(
    tab === 2 ? 1 : tab === 1 ? 2 : tab === 0 ? 3 : 0
  );

  function handleTabsChange(e, newTabValue) {
    setTicketsState(null);
    setTabValue(newTabValue);

    if (newTabValue === 0) {
      setgetTicketsFeedbackDeskData({
        ...getTicketsFeedbackDeskData,
        satisfectionType: "",
      });
    }
    if (newTabValue === 1) {
      setgetTicketsFeedbackDeskData({
        ...getTicketsFeedbackDeskData,
        satisfectionType: 2,
      });
    }
    if (newTabValue === 2) {
      setgetTicketsFeedbackDeskData({
        ...getTicketsFeedbackDeskData,
        satisfectionType: 1,
      });
    }

    if (newTabValue === 3) {
      setgetTicketsFeedbackDeskData({
        ...getTicketsFeedbackDeskData,
        satisfectionType: 0,
      });
    }
  }
  const [status, setstatus] = useState("");

  const ticketDetails = useSelector(
    (state) => state.getTicketDetails?.data?.ticketDetails
  );
  const [ticketDetailsState, setTicketDetailsState] = useState(null);
  useEffect(() => {
    setTicketDetailsState(ticketDetails);
  }, [ticketDetails]);

  const [successScreens, setSuccessScreens] = useState(false);
  const createTicketData = useSelector((state) => state.createTicket.data);
  const createTicketStatus = useSelector((state) => state.createTicket.status);

  function handleSubmit(data) {
    setSuccessScreens(true);
    dispatch(supportAction.postCreateTicket(data));
  }

  const successScree = (id) => {
    return (
      <SuccessScreen
        id={id}
        label1="Ticket ID"
        label4="Support in"
        route="/support"
      ></SuccessScreen>
    );
  };
  function handleTicketDetails(params) {
    dispatch(supportAction.getTicketDetails(params));
  }
  return (
    <div className="overrideAdminTicketContainer">
      {createTicketStatus === "SUCCESS" &&
      createTicketData &&
      successScreens ? (
        successScree(createTicketData?.result?.ticketId)
      ) : (
        <div className="rightDiv">
          {ShowFullTicket && (
            <AdminOpenTicket
              // searchOption={searchOption && searchOption}
              showTicketForm={showTicketForm}
              setShowTicketForm={setShowTicketForm}
              setShowFullTicket={setShowFullTicket}
              // handleSubmit={handleSubmit}
              // handleTicketReply={handleTicketReply}
              getTicketApiData={getTicketsFeedbackDeskData}
              status={status}
              ticketsState={ticketsState}
              setTicketsState={setTicketsState}
              ticketDetailsState={ticketDetailsState}
              setTicketDetailsState={setTicketDetailsState}
              feedbackDesk={true}
              handleTicketDetails={handleTicketDetails}
            />
          )}
          {!ShowFullTicket && (
            <div className="containerTkt">
              {showTicketForm ? (
                <CreateTicket
                  onClick={handleSubmit}
                  setshowSucessScreen={setshowSucessScreen}
                  showSucessScreen={showSucessScreen}
                />
              ) : (
                <div className="mainContainer">
                  <div className="headerDiv">
                    <h1>Support Dashboard</h1>
                    <Buttons
                      startIcon={<AddIcon />}
                      primary={true}
                      label={"New Ticket"}
                      onClick={() => {navigate(CREATE_TICKET)}}
                    />
                  </div>

                  <Grid mt={2} className="menuDiv">
                    <Grid className="leftMenu">
                      <Box>
                        <Tabs
                          value={tabValue}
                          onChange={handleTabsChange}
                          aria-label="basic tabs example"
                          variant="scrollable"
                        >
                          <Tab
                            className="tabcustom"
                            icon={
                              ticketsState &&
                              getTicketsFeedbackDeskData.satisfectionType ===
                                "" && (
                                <div className="totalV">{tickets.length}</div>
                              )
                            }
                            iconPosition="end"
                            label="All Tickets"
                            {...a11yProps(0)}
                          />
                          <Tab
                            className="tabcustom"
                            icon={
                              ticketsState &&
                              getTicketsFeedbackDeskData.satisfectionType ===
                                2 && (
                                <div className="totalV">{tickets.length}</div>
                              )
                            }
                            iconPosition="end"
                            label="Neutral"
                            {...a11yProps(1)}
                          />
                          <Tab
                            className="tabcustom"
                            icon={
                              ticketsState &&
                              getTicketsFeedbackDeskData.satisfectionType ===
                                1 && (
                                <div className="totalV">{tickets.length}</div>
                              )
                            }
                            iconPosition="end"
                            label="Satisfied"
                            {...a11yProps(2)}
                          />
                          <Tab
                            className="tabcustom"
                            icon={
                              ticketsState &&
                              getTicketsFeedbackDeskData.satisfectionType ===
                                0 && (
                                <div className="totalV">{tickets.length}</div>
                              )
                            }
                            iconPosition="end"
                            label="Unsatisfied"
                            {...a11yProps(3)}
                          />
                        </Tabs>
                      </Box>
                    </Grid>
                    <Grid className="rightMenu">
                      <div className="searchBar">
                        <SearchBar
                          searchbarInput={getTicketsFeedbackDeskData.search}
                          handleSearch={(e) => {
                            setgetTicketsFeedbackDeskData({
                              ...getTicketsFeedbackDeskData,
                              search: e,
                            });
                          }}
                          placeholder="Search Text"
                        />
                      </div>
                      <div className="filter_btn">
                        <FilterButton handleClick={handleClick} />
                        <FilterList
                          id={id}
                          open={open}
                          handleClose={handleClose}
                          handleChange={handleChange}
                          anchorEl={anchorEl}
                          priorityState={priorityState}
                          isFeedback={true}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {ticketsState ? (
                    <div style={{ width: "100%" }}>
                      {tickets.map((ticket, index) => {
                        return (
                          <Grid
                            mt={2}
                            onClick={() => {
                              // dispatch(
                              //   supportAction.getTicketDetails(ticket.ticketId)
                              // );
                              // setShowFullTicket(true);
                              // setstatus(ticket.status);
                              navigate(ADMIN_TICKET_DETAILS, {
                                state: {
                                  ticketId: ticket.ticketId,
                                  status: ticket.status,
                                  getAdminDeskData: getTicketsFeedbackDeskData,
                                },
                              });
                            }}
                            className="ticketsContainer"
                            key={ticket.ticketId}
                          >
                            <Ticket
                              ShowFullTicket={ShowFullTicket}
                              ticketQuestion={ticket.title}
                              ticketAnswer={ticket.description}
                              priority={ticket.priority}
                              ticketId={ticket.ticketId}
                              category={ticket.category}
                              replyCount={ticket.replyCount}
                              createdDateTime={ticket.createdDateTime}
                              status={ticket.status}
                              createdBy={ticket.createByNameTicket}
                              createdById={ticket.createByIdTicket}
                            />
                          </Grid>
                        );
                      })}
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TicketsFeedbackDesk;
