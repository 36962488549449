import {
  getDropdownDataKeyValueType,
  getInspectionsType,
  getSupervisorByProjectType,
} from "../constants/adminCommonType";

export const getInspections = (data) => ({
  type: getInspectionsType.GET_INSPECTION_PENDING,
  data,
});

export const getInspectionstSuccess = (data) => ({
  type: getInspectionsType.GET_INSPECTION_SUCCESS,
  data,
});

export const getInspectionsFailure = (error) => ({
  type: getInspectionsType.GET_INSPECTION_FAILURE,
  error,
});

// getDropdownDataKeyValue
export const getDropdownDataKeyValue = (data) => ({
  type: getDropdownDataKeyValueType.GET_DROPDOWN_DATA_KEY_VALUE_PENDING,
  data,
});

export const getDropdownDataKeyValueSuccess = (data) => ({
  type: getDropdownDataKeyValueType.GET_DROPDOWN_DATA_KEY_VALUE_SUCCESS,
  data,
});

export const getDropdownDataKeyValueFailure = (error) => ({
  type: getDropdownDataKeyValueType.GET_DROPDOWN_DATA_KEY_VALUE_FAILURE,
  error,
});

export const getSupervisorByProject = (data) => ({
  type: getSupervisorByProjectType.GET_SUPERVISOR_BY_PROJECT_PENDING,
  data,
});

export const getSupervisorByProjectSuccess = (data) => ({
  type: getSupervisorByProjectType.GET_SUPERVISOR_BY_PROJECT_SUCCESS,
  data,
});

export const getSupervisorByProjectFailure = (error) => ({
  type: getSupervisorByProjectType.GET_SUPERVISOR_BY_PROJECT_FAILURE,
  error,
});
