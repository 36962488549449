import React, { useEffect, useState } from "react";
import ConfigurePermiteeType from "../components/configurePermiteeType";
import * as permitAction from "../../../../redux/storm-water/actions/permitAction";
import { useDispatch, useSelector } from "react-redux";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import Loader from "../../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { USER_ID } from "../../../../constant/constant";
function ConfigurePermiteeTypeContainer() {
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state.state);
  const addPermitteeType = useSelector((state) => state.addPermitType);
  const permitTypeList = useSelector((state) => state.permitTypeList?.data);
  const [isPermityTypeExistErrMsg, setisPermityTypeExistErrMsg] = useState("");
  const [isPermityTypeExistErrMsgUpdate, setisPermityTypeExistErrMsgUpdate] =
    useState("");
  const [open, setOpen] = useState(false);

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };
  const addPermitType = (data) => {
    setisPermityTypeExistErrMsgUpdate("");
    setisPermityTypeExistErrMsg("");
    setClearAddPermitType(true);
    dispatch(permitAction.addPermitType(data));
  };
  const updatePermitType = (data) => {
    setisPermityTypeExistErrMsgUpdate("");
    setisPermityTypeExistErrMsg("");
    setUpdatePermitType(true);
    dispatch(permitAction.updatePermitType(data));
  };
  const updateTypeOfPermit = useSelector((state) => state?.updatePermitType);
  const showLoader = useSelector(
    (state) =>
      state.state?.isLoading ||
      state.updatePermitType?.isLoading ||
      state?.addPermitType?.isLoading
  );
  const isLoading = useSelector((state) => state.permitTypeList.isLoading);
  const data = {
    search: "",
    page: 1,
  };

  const handlepermitTypeList = (data) => {
    dispatch(permitAction.getPermitTypeListPending(data));
  };
  useEffect(() => {
    getStateData();
    handlepermitTypeList(data);
  }, []);
  const [clearAddPermitType, setClearAddPermitType] = useState(false);
  const [clearUpdatePermitType, setUpdatePermitType] = useState(false);
  const initialState = {
    stateId: "",
    value: "",
    isDeleted: false,
    createdBy: parseInt(USER_ID()),
  };
  const [formValue, setFormValue] = useState(initialState);

  useEffect(() => {
    if (
      addPermitteeType?.status === "SUCCESS" &&
      addPermitteeType?.isLoading === false &&
      addPermitteeType?.data?.responseMessages?.responseCode === "HBNG001" &&
      clearAddPermitType
    ) {
      setFormValue({
        ...formValue,
        value: "",
        stateId: "",
      });
      toast("Permittee Type Name added Successfully");
      const data1 = {
        search: "",
        page: 1,
      };
      handlepermitTypeList(data1);
    }
    //responseMessage
    if (
      addPermitteeType?.status === "SUCCESS" &&
      addPermitteeType?.isLoading === false &&
      addPermitteeType?.data?.responseMessages?.responseCode === "HBNG012" &&
      clearAddPermitType
    ) {
      setisPermityTypeExistErrMsg(
        addPermitteeType?.data?.responseMessages?.responseMessage
      );
    }
  }, [addPermitteeType]);
  useEffect(() => {
    console.log("updateTypeOfPermit", updateTypeOfPermit);
    if (
      updateTypeOfPermit?.status === "SUCCESS" &&
      updateTypeOfPermit?.isLoading === false &&
      updateTypeOfPermit?.data?.responseMessages?.responseCode === "HBNG001" &&
      clearUpdatePermitType
    ) {
      toast("Permittee updated Successfully");
      const data1 = {
        search: "",
        page: 1,
      };
      handlepermitTypeList(data1);
      setOpen(false);
    }

    if (
      updateTypeOfPermit?.status === "SUCCESS" &&
      updateTypeOfPermit?.isLoading === false &&
      updateTypeOfPermit?.data?.responseMessages?.responseCode === "HBNG012" &&
      clearUpdatePermitType
    ) {
      setisPermityTypeExistErrMsgUpdate(
        updateTypeOfPermit?.data?.responseMessages?.responseMessage
      );
    }
  }, [updateTypeOfPermit]);
  return (
    <>
      {showLoader && <Loader />}
      {/* {showLoader ? (
        <Loader />
      ) : ( */}
      <div>
        <ConfigurePermiteeType
          addPermitType={addPermitType}
          state={stateData && stateData.data?.getState}
          permitTypeList={permitTypeList}
          updatePermitType={updatePermitType}
          handlepermitTypeList={handlepermitTypeList}
          isLoading={isLoading}
          formValue={formValue}
          setFormValue={setFormValue}
          isPermityTypeExistErrMsg={isPermityTypeExistErrMsg}
          setisPermityTypeExistErrMsg={setisPermityTypeExistErrMsg}
          setisPermityTypeExistErrMsgUpdat={setisPermityTypeExistErrMsgUpdate}
          isPermityTypeExistErrMsgUpdate={isPermityTypeExistErrMsgUpdate}
          setOpen={setOpen}
          open={open}
        />
      </div>
      {/* )} */}
      <ToastContainer />
    </>
  );
}

export default ConfigurePermiteeTypeContainer;
