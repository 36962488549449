import { getApiUrl } from "../config";
import { get } from "./axiosRequest";
import { USER_ID, ORG_ID } from "../constant/constant";

export const getUserLessonView = async (lessonId, courseId) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Lesson/GetLessonDetails?lessonId=${lessonId}&userId=${USER_ID() || localStorage.getItem("userId")}&courseId=${
    courseId ?? 0
  }&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
