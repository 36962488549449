import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import "./Image.scss";
import { getFileInfo } from "../../../utils/helper";
import commonImages from "../../../assets";

const File = ({
  files,
  handleCloseFile,
  imageUrl = "",
  deleteImageURL,
  isLoader,
}) => {
  const imageUrlConvertArray = imageUrl.length > 0 ? imageUrl?.split("|") : "";
  const uniqueImageUrls = [...new Set(imageUrlConvertArray)];

  return (
    <div className="overideImage">
      <div className="thumbContainer">
        {uniqueImageUrls &&
          uniqueImageUrls?.length > 0 &&
          uniqueImageUrls?.map((url, index) => {
            const fileNameSplit = url?.split("/");
            const fileName =
              fileNameSplit?.length > 0 && fileNameSplit.slice(-1).pop();
            const fileInfo = getFileInfo(url);

            return (
              <div className="imgBox" key={index}>
                {fileInfo?.isImage && (
                  <>
                    <div
                      className="removeIcon"
                      onClick={(i) => deleteImageURL(url, index)}
                    >
                      X
                    </div>
                    <img
                      src={
                        fileInfo?.fileExtension === "tif"
                          ? commonImages?.noimage
                          : url
                      }
                      className="dropImg"
                      alt={fileName}
                    />
                  </>
                )}

                {fileInfo?.isDocument && (
                  <>
                    <div
                      className="removeIcon"
                      onClick={(i) => deleteImageURL(url, index)}
                      key={index}
                    >
                      X
                    </div>
                    <img
                      className="dropImg"
                      src={commonImages?.docIcon}
                      alt={fileName}
                    />
                  </>
                )}
                {fileInfo?.isPdf && (
                  <>
                    <div
                      className="removeIcon"
                      onClick={(i) => deleteImageURL(url, index)}
                      key={index}
                    >
                      X
                    </div>
                    <img
                      className="dropImg"
                      src={commonImages?.pdfIcon}
                      alt={fileName}
                    />
                  </>
                )}
              </div>
            );
          })}
        {/* {isLoader && <p>Loading...</p>} */}
        {isLoader && (
          <Stack className="loaderDropBox" spacing={2} direction="row">
            <CircularProgress color="success" />
          </Stack>
        )}
      </div>
    </div>
  );
};

export default File;
