import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import commonImages from "../../../../assets";
import Typography from "@mui/material/Typography";
import "./lessonDetail.scss";
import Button from "../../../../library/custom/button/Button";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { USERLESSON, LESSON } from "../../../../constant/routeContant";
import Popover from "@mui/material/Popover";
import RadioGroup from "@mui/material/RadioGroup";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import {
  ORG_ID,
  API_STATUS,
  USER_ID,
  USER_TYPE,
} from "../../../../constant/constant";
import { useDispatch } from "react-redux";
import Buttons from "../../../../library/custom/button/Button";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import Checkbox from "../../../../library/checkbox/checkbox";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const LessonDetail = (props) => {
  const { viewOnClick } = props;
  const lessonItem = props?.viewData?.lessons;
  const isAdminLesson = props?.isAdminLesson;
  //Reassign  code ================== commit
  const getStatusCheckLessoncompleted = props?.getStatusCheckLessoncompleted;
  const setchecklessonstatus = props?.setchecklessonstatus;
  const checklessonstatus = props?.checklessonstatus;
  const CompleteLessonStatusResult = props?.CompleteLessonStatusResult;
  const [usersValue, setUsersValue] = useState();
  const [usersGroupValue, setUsersGroupValue] = useState();
  const setisSubmitAssign = props?.setisSubmitAssign;
  const isSubmitAssign = props?.isSubmitAssign;
  const [Reassignbtndisable, setReassignbtndisable] = useState(false);

  const dataBack = {
    title: "Back to Lesson",
    route: isAdminLesson ? LESSON : USERLESSON,
    islesson: false,
  };
  const autoCompGroup = useRef(null);
  const autoCompUser = useRef(null);
  const dispatch = useDispatch();
  const lessionid = lessonItem?.id;
  const [anchorEl, setAnchorEl] = useState(null);
  const errCompltemsg = props.errCompltemsg;
  const [userOrGroupId, setUserOrGroupId] = useState("");
  const [statuccheck, setStatuccheck] = useState("1");
  const [isSubmit, setIsSubmit] = useState(true);
  const onSearch = props.onSearch;
  const allUsers = props.allUsers;
  const quizStatus = props.quizStatus;
  const handleAllGroupSearch = props?.handleAllGroupSearch;
  const userGroups = props?.userGroups;
  const isLoading = props?.isLoading;
  const assignLessonStatus = props?.assignLessonStatus;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [anchorElAssign, setAnchorElAssign] = useState(null);
  const [isassignName, setIsassignName] = useState(false);
  const handleClickAssign = (event) => {
    setReassignbtndisable(false);
    setchecklessonstatus(false);
    setUsersValue({ key: "", value: "" });
    setUserOrGroupId("");
    setAnchorElAssign(event.currentTarget);
    setIsSubmit(true);
  };

  const handleCloseAssign = () => {
    setAnchorElAssign(null);
  };

  const openAssign = Boolean(anchorElAssign);
  const idAssign = open ? "simple-popover" : undefined;
  useEffect(() => {
    console.log("PROD DPL LESSION");
    if (assignLessonStatus === API_STATUS.SUCCESS) {
      setIsSubmit(true);
      setTimeout(() => {
        setAnchorElAssign(null);
      }, 2000);
    }
  }, [assignLessonStatus]);

  const onChangeUsers = (e) => {
    setchecklessonstatus(false);
    setIsSubmit(true);
    setUsersValue({ key: e?.key ?? "", value: e?.value ?? "" });
    setUsersGroupValue({ key: e?.key ?? "", value: e?.value ?? "" });
    if (e == null) {
      setUserOrGroupId("");
    } else {
      setUserOrGroupId(e.key);
      setIsassignName(false);
    }
  };
  const handlecheckbtn = (e, value) => {
    console.log("PROD DPL LESSION direct check");

    setStatuccheck(e.target.value);
    setUserOrGroupId("");
    setIsassignName(false);
    setchecklessonstatus(false);
    setUsersValue({ key: "", value: "" });
    setUsersGroupValue({ key: "", value: "" });
    setIsSubmit(true);
    setIsassignName(false);
  };

  function mappedData() {
    let errors = false;
    if (userOrGroupId?.length === 0) {
      setIsassignName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    let data;
    data = {
      userType: statuccheck,
      lessonId: lessionid,
      userOrGroupId: userOrGroupId,
      createdBy: USER_ID(),
      orgId: ORG_ID(),
      reAssign: false,
    };

    return data;
  }
  useEffect(() => {
    if (
      checklessonstatus === false &&
      props?.CompleteLessonStatusStatus === API_STATUS.SUCCESS &&
      props?.CompleteLessonStatusResult?.length === 0 &&
      isSubmitAssign
    ) {
      const data = mappedData();
      if (data) {
        data.reAssign = false;
      }

      dispatch(lmsAction.assignLesson(data));
    }
  }, [props?.CompleteLessonStatusResult, props?.CompleteLessonStatusStatus]);

  const handleClickAdd = (e) => {
    console.log("LESSION  CHANGES CHECK");
    setisSubmitAssign(true);
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(false);

      if (statuccheck === "0") {
        getStatusCheckLessoncompleted(data);
      } else if (statuccheck === "1") {
        dispatch(lmsAction.assignLesson(data));
      }
    }
  };
  const handleClickAddReassign = (e) => {
    setReassignbtndisable(true);
    let data;
    data = {
      userType: parseInt(statuccheck),
      lessonId: lessionid,
      userOrGroupId: userOrGroupId,
      createdBy: USER_ID(),
      orgId: ORG_ID(),
      reAssign: true,
    };
    dispatch(lmsAction.assignLesson(data));
  };
  const [checked, setChecked] = useState(false);

  const completeLesson = () => {
    const data = {
      userId: USER_ID(),
      lessonId: lessionid,
      orgId: ORG_ID(),
    };
    dispatch(lmsAction.completeLesson(data));
  };
  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };
  const cancelreassign = (e) => {
    setAnchorElAssign(null);
    setchecklessonstatus(false);
  };
  console.log("lesson reassign");
  return (
    <>
      <BackBtn dataBack={dataBack} />
      <Grid container className="lessonView">
        <Grid item xs={12}>
          <Grid className="lessonImage">
            <img
              key={lessonItem?.file?.id}
              src={
                lessonItem?.file?.path
                  ? lessonItem?.file?.path
                  : commonImages?.noimagePublish
              }
              alt=""
            />

            <div className="adminDetailBox">
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className="deletePop"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography className="deleteText">Delete Lesson</Typography>
              </Popover>
            </div>
          </Grid>
          <Grid container>
            <Grid lg={9} md={9} xs={12}>
              <Grid className="imageDetail">
                <Typography className="imageDetailContent">
                  <span>
                    <img src={commonImages?.category} alt="category" />
                  </span>
                  {lessonItem?.category}
                </Typography>
                {checkPrivileges([12, 11, 10]) ? (
                  <>
                    <Typography className="imageDetailContent">
                      <span>
                        <img src={commonImages?.group} alt="category" />
                      </span>
                      Assigned{" "}
                      <span className="count">
                        ({lessonItem?.totaleAssignedToUser})
                      </span>
                    </Typography>{" "}
                    <Typography className="imageDetailContent">
                      <span>
                        <img
                          src={commonImages?.assignmentturnedin}
                          alt="category"
                        />
                      </span>
                      Completed{" "}
                      <span className="count">
                        ({lessonItem?.totalCompletedByUser})
                      </span>
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                <Typography className="imageDetailContent">
                  <span>
                    <img src={commonImages?.alarm} alt="alarm" />
                  </span>
                  {`${lessonItem?.completionTimeHr}hr ${lessonItem?.completionTimeMin}min`}
                </Typography>
              </Grid>
            </Grid>
            <Grid md={3} xs={12}>
              {checkPrivileges([12, 11, 10]) &&
                lessonItem?.statusId === 1 &&
                isAdminLesson && (
                  <div className="assignBox">
                    <>
                      <Buttons
                        type="button"
                        name="btn"
                        label={"Assign"}
                        primaryassign={true}
                        onClick={handleClickAssign}
                      ></Buttons>

                      <Popover
                        id={idAssign}
                        open={openAssign}
                        anchorEl={anchorElAssign}
                        onClose={handleCloseAssign}
                        className="assignPopBox"
                        anchorOrigin={{
                          vertical: "right",
                          horizontal: "bottom",
                        }}
                        transformOrigin={{
                          vertical: "right",
                          horizontal: "right",
                        }}
                      >
                        <div className="assignPopDetail">
                          <div className="title">User Type</div>
                          <div className="innerauto">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              gap={5}
                              className="radioGroup"
                            >
                              <RadioButton
                                checked={statuccheck === "1" ? true : false}
                                value="1"
                                label="Group"
                                disabled={false}
                                onChange={(e, value) =>
                                  handlecheckbtn(e, value)
                                }
                              ></RadioButton>

                              <RadioButton
                                checked={statuccheck === "0" ? true : false}
                                value="0"
                                label="Individual"
                                disabled={false}
                                onChange={(e, value) =>
                                  handlecheckbtn(e, value)
                                }
                              ></RadioButton>
                            </RadioGroup>

                            {statuccheck === "1" ? (
                              <>
                                <div className="searchauto">
                                  <SearchAutoComplete
                                    optionsData={
                                      isLoading ? [] : userGroups ?? []
                                    }
                                    isKeyValuePair={true}
                                    typeOnchange={(event, value) =>
                                      handleAllGroupSearch(
                                        event.target.value,
                                        0,
                                        true
                                      )
                                    }
                                    onChange={(e, value) =>
                                      onChangeUsers(value)
                                    }
                                    noOptionsTextMsg="No group"
                                    isLoading={isLoading}
                                    autoCompClear={autoCompGroup}
                                  />
                                </div>
                                {isassignName ? (
                                  <div className="show-error">
                                    Please enter this required field
                                  </div>
                                ) : (
                                  ""
                                )}
                                <Grid
                                  container
                                  lg={12}
                                  mt={2}
                                  className="individualbtn"
                                >
                                  <Buttons
                                    variant="contained"
                                    type="button"
                                    name="btn"
                                    label={"Assign"}
                                    varientContained={true}
                                    onClick={(e) => handleClickAdd(e)}
                                  ></Buttons>
                                </Grid>
                                {props.assignmsg ? (
                                  <div className="success">
                                    Lesson Assign successfully
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <>
                                <SearchAutoComplete
                                  optionsData={
                                    props?.AlluserisLoading
                                      ? []
                                      : allUsers ?? []
                                  }
                                  isKeyValuePair={true}
                                  typeOnchange={(event, value) =>
                                    onSearch(
                                      event.target.value,
                                      USER_TYPE.NONPRIVILEGED
                                    )
                                  }
                                  onChange={(e, value) => onChangeUsers(value)}
                                  isLoading={props?.AlluserisLoading}
                                  autoCompClear={autoCompUser}
                                  value={usersValue ? usersValue : ""}
                                />
                                {isassignName ? (
                                  <div className="show-error">
                                    Please enter this required field
                                  </div>
                                ) : (
                                  ""
                                )}

                                {checklessonstatus === true &&
                                CompleteLessonStatusResult?.length > 0 ? (
                                  <div className="errorMsg">
                                    This lesson has been completed by the
                                    assigned user. Do you want to re-assign this
                                    lesson ?
                                  </div>
                                ) : (
                                  ""
                                )}

                                <Grid
                                  container
                                  lg={12}
                                  mt={2}
                                  className="individualbtn"
                                >
                                  {checklessonstatus === false && (
                                    <Buttons
                                      variant="contained"
                                      type="button"
                                      name="btn"
                                      label={"Assign"}
                                      varientContained={true}
                                      onClick={(e) => handleClickAdd(e)}
                                      disabled={isSubmit ? false : true}
                                    ></Buttons>
                                  )}
                                  {checklessonstatus && (
                                    <>
                                      {" "}
                                      <Buttons
                                        varientText={true}
                                        label="Cancel"
                                        onClick={(e) => cancelreassign(e)}
                                      />
                                      <Buttons
                                        variant="contained"
                                        type="button"
                                        name="btn"
                                        label={"Apply"}
                                        varientContained={true}
                                        onClick={(e) =>
                                          handleClickAddReassign(e)
                                        }
                                        disabled={
                                          Reassignbtndisable ? true : false
                                        }
                                      ></Buttons>
                                    </>
                                  )}
                                </Grid>
                                {props.assignmsg ? (
                                  <div className="success">
                                    Lesson Assign successfully
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                      </Popover>
                    </>
                  </div>
                )}
            </Grid>
          </Grid>

          <Grid>
            <Grid className="title">{lessonItem?.lesson}</Grid>
            <Grid className="otherDescration">
              <Grid className="subTitle">Description</Grid>
              <Grid className="desc">
                {" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: lessonItem?.description,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="lessonwithDetail">
          {lessonItem?.sections?.map((lessonSection, i) => {
            const document = lessonSection?.document?.split("/");
            const documentName =
              document?.length > 0 && document.slice(-1).pop();
            return (
              <Grid
                item
                xs={12}
                md={12}
                className="lessonwithDetailBox"
                key={i}
              >
                {lessonSection?.filesImg?.length > 0 && (
                  <Grid className="lessonImage">
                    {lessonSection?.filesImg?.map((coverImage) => {
                      return (
                        <img
                          key={coverImage?.id}
                          src={
                            coverImage
                              ? coverImage?.path
                              : commonImages?.noimagePublish
                          }
                          alt=""
                        />
                      );
                    })}
                  </Grid>
                )}
                <Grid className="otherDescration">
                  <Grid className="desc">
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lessonSection?.text,
                      }}
                    />
                  </Grid>
                </Grid>
                {lessonSection?.videoLink?.length > 0 && (
                  <Grid className="otherDescration">
                    <Grid className="subTitle">
                      {/* Safety Report Guidelines 101.mp4 */}
                    </Grid>
                    <Grid className="lessonImage">
                      <iframe
                        className="videoLink"
                        title={lessonSection?.videoLink}
                        src={lessonSection?.videoLink}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </Grid>
                  </Grid>
                )}

                {lessonSection?.filesDoc?.length > 0 && (
                  <Grid className="otherDescration">
                    <Grid className="subTitle">
                      {/* Safety Report Guidelines 101 */}
                    </Grid>
                    <Grid className="pdfAttached">
                      <span>
                        {lessonSection?.filesDoc.map((item, index) => {
                          return (
                            <a
                              href={item?.path}
                              target="_blank"
                              rel="noreferrer"
                              key={index}
                            >
                              <img src={commonImages?.attachment} alt="" />
                              Download PDF
                            </a>
                          );
                        })}
                      </span>
                      <span className="pdfAttachedName">{documentName}</span>
                    </Grid>
                  </Grid>
                )}

                <hr className="solid" />
              </Grid>
            );
          })}

          <Grid item xs={12} md={12} className="lessonwithDetailBox">
            <Grid container className="takeQuiz">
              {lessonItem?.quizId !== 0 && (
                <Button
                  varientContained={true}
                  label="Take Quiz"
                  disabled={
                    quizStatus || lessonItem?.quizId <= 0 ? true : false
                  }
                  onClick={() =>
                    viewOnClick(lessonItem?.quizId, lessonItem?.id)
                  }
                />
              )}

              {lessonItem?.quizId > 0 && !isAdminLesson ? (
                <div className="quizstartDiv">
                  {quizStatus ? (
                    <span className="quizstatuspass">Passed</span>
                  ) : quizStatus === false ? (
                    <span className="quizstatusfail">Failed</span>
                  ) : (
                    false
                  )}
                </div>
              ) : null}
            </Grid>

            {lessonItem?.quizId === 0 &&
            lessonItem?.isLessonCompeted === true ? (
              ""
            ) : lessonItem?.quizId === 0 && !isAdminLesson ? (
              <>
                <Grid className="checkingBox">
                  <Grid className="checkboxs">
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckedChange}
                      label={
                        "I confirm that I have successfully completed the lesson and taken the quiz."
                      }
                    />
                  </Grid>
                </Grid>
                <Grid className="Btncomplete">
                  <Button
                    varientContained={true}
                    onClick={completeLesson}
                    label="Complete"
                    disabled={checked ? false : true}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}

            {errCompltemsg ? (
              <div className="show-error">{props?.errCompltemsg}</div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LessonDetail;
