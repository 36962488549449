import React, { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../../library/common/Loader";
import {
  API_ADMIN_STATUS_STATUS,
  PAGE_SIZE,
  ORG_ID,
  LOGIN_ORG_ID,
  USER_ID,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import NORow from "../../../../library/custom/noRow/noRow";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import DialogBox from "../../../../library/common/DialogBox";
import FilterCommonStatusSelect from "../../shared/FilterCommonStatusSelect";
import { Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const EquipmentTable = ({
  equipmentList,
  getEquipmentList,
  isLoading,
  filterShow,
  handleDeleteOnClick,
  handleEditOnClick,
  isProfile = false,
  divisionDropdown,
  isDivisionDropdown = true,
}) => {
  const [incSearchText, setIncSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [openModal, setOpenModal] = useState(false);
  const [statusIds, setStatusIds] = useState();

  const [selectedRow, setSelectedRow] = useState("");

  const [division, setDivision] = useState(
    divisionDropdown ? divisionDropdown[0]?.key : 0
  );

  const [filter, setFilter] = useState({
    searchType: [],
  });

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
        setStatusIds(item?.key);
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const columns = [
    { field: "makeAndModel", headerName: "Make and Model", flex: 1.5 },
    { field: "categoryValue", headerName: "Category", flex: 2 },
    { field: "equipmentId", headerName: "Equipment ID", flex: 1.5 },
    { field: "divisionName", headerName: "Division", flex: 1 },
    { field: "serviceStartDate", headerName: "Start Date", flex: 1.3 },
    { field: "isActive", headerName: "Status", flex: 1 },

    {
      field: "icon",
      renderHeader: () =>
        filterShow ? (
          <SettingTable
            noOfRecords={equipmentList?.itemsCount ?? 0}
            numberOfItemsPerPage={pageSize}
            incidentLists={equipmentList?.equipmentlist ?? []}
            fileNameXL="equipmentList"
          />
        ) : (
          ""
        ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return filterShow ? (
          <TableRowAction
            rows={rows}
            deleted={checkPrivileges([52, 12])}
            edit={checkPrivileges([53, 52, 12])}
            editOnClick={(rows) => handleEditOnClick(rows)}
            deleteOnClick={() => deleteEquipment(rows?.row)}
            editLabel="Edit Equipment"
            deleteLabel="Delete Equipment"
          />
        ) : (
          ""
        );
      },
    },
  ];

  const deletehandle = () => {
    setOpenModal(false);
    handleDeleteOnClick(selectedRow.equipmentId);
  };
  const deleteEquipment = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const handleApply = (state) => {
    setAnchorEl(null);
    const data = {
      page: 1,
      pageSize: pageSize,
      search: incSearchText,
      statusIds: statusIds ? statusIds : "",
      divisionId: division === 1 ? 0 : division,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      userId: isProfile ? parseInt(USER_ID()) : 0,
    };
    getEquipmentList(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setIncSearchText(searchText);
    getEquipmentList({
      pageSize: pageSize,
      search: event.target.value,
      statusIds: statusIds ? statusIds : "",
      divisionId: division === 1 ? 0 : division,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      page: page,
      userId: isProfile ? parseInt(USER_ID()) : 0,
    });
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getEquipmentList({
      pageSize: pageSize,
      search: incSearchText,
      statusIds: statusIds ? statusIds : "",
      divisionId: division === 1 ? 0 : division,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      page: newPage + 1,
      userId: isProfile ? parseInt(USER_ID()) : 0,
    });
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    getEquipmentList({
      pageSize: newPazeSize,
      search: incSearchText,
      statusIds: statusIds ? statusIds : "",
      divisionId: division === 1 ? 0 : division,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      page: page,
      userId: isProfile ? parseInt(USER_ID()) : 0,
    });
  };
  const handleClear = () => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      prevStates.searchType = [];
      return prevStates;
    });
    setAnchorEl(null);
    getEquipmentList({
      page: 1,
      pageSize: pageSize,
      search: "",
      divisionId: division === 1 ? 0 : division,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      statusIds: "",
      userId: isProfile ? parseInt(USER_ID()) : 0,
    });
  };

  const onChangeDivision = (e) => {
    setDivision(e.target.value);
    setIncSearchText("");
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      statusIds: "",
      divisionId: e.target.value === 1 ? 0 : e.target.value,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      userId: isProfile ? parseInt(USER_ID()) : 0,
    };
    getEquipmentList(data);
    setPage(1);
  };
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to delete this Equipment?"}
              view
            />
          )}
        </div>

        <div className="grid_main_body divisionWithsearch">
          <Grid container>
            <Grid item lg={6} xs={12} sm={6} md={6} mr={2} pt={3}>
              <div className="filter_of_caselist">
                <div className="searchcaseid">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div>
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={incSearchText}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>
                {filterShow && (
                  <div className="filter_btn divisionWithFilter">
                    <button
                      aria-describedby={id}
                      variant="contained"
                      type="button"
                      name="btn"
                      onClick={(e) => handleClick(e)}
                    >
                      <FilterListIcon id="icn" /> Filters
                    </button>

                    <FilterCommonStatusSelect
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      handleApply={handleApply}
                      handleClear={handleClear}
                      filter={filter}
                      statusType={API_ADMIN_STATUS_STATUS}
                      onChangeType={onChangeType}
                      statusIds={statusIds}
                      filterSelect={false}
                      filterStatus={true}
                      resetBtn={true}
                    />
                  </div>
                )}
              </div>
            </Grid>
            {isDivisionDropdown && (
              <Grid item lg={4} xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <FormLabels label="Select Division" isRequired={false} />
                  <SelectMenu
                    disabledDefault={true}
                    listData={divisionDropdown}
                    value={division === 0 ? 1 : division}
                    onchangehandler={(e) => onChangeDivision(e)}
                    isgroupplaceholder={true}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.equipmentId}
                rows={equipmentList?.equipmentlist ?? []}
                rowCount={equipmentList?.itemsCount ?? 0}
                columns={columns}
                loading={isLoading}
                page={page}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EquipmentTable;
