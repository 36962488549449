import {
  createTicketType,
  deleteFAQType,
  getArticleCatSubcategorySagaType,
  getDashboardTicketsType,
  getTicketCategoryType,
  getTicketDetailsType,
  getTicketsAdminDeskType,
  getTicketsFeedbackDeskType,
  getTicketsType,
  postAddFAQType,
  postClosedTicketType,
  postUpdateSeverityTicketType,
  postUserAssignTicketType,
  postUserFeedbackOnTicketType,
  replyTicketType,
  updateFAQType,
} from "../constants/supportType";
import { getArticleType } from "../constants/supportType";
import { getArticlesBasedCatSubcategoryType } from "../constants/supportType";
import { getArticleDetailsType } from "../constants/supportType";
import { addLikeDislikeArticleType } from "../constants/supportType";

// getArticle ACTION
export const getArticle = (data) => ({
  type: getArticleType.GET_ARTICLE_PENDING,
  data,
});
export const getArticleSuccess = (data) => ({
  type: getArticleType.GET_ARTICLE_SUCCESS,
  data,
});

export const getArticleFailure = (error) => ({
  type: getArticleType.GET_ARTICLE_FAILURE,
  error,
});

export const getTicketCategory = (data) => ({
  type: getTicketCategoryType.GET_TICKET_CATEGORY_PENDING,
  data,
});
export const getTicketCategorySuccess = (data) => ({
  type: getTicketCategoryType.GET_TICKET_CATEGORY_SUCCESS,
  data,
});

export const getTicketCategoryFailure = (error) => ({
  type: getTicketCategoryType.GET_TICKET_CATEGORY_FAILURE,
  error,
});

// getArticleCatSubcategory ACTION
export const getArticleCatSubcategory = (data) => ({
  type: getArticleCatSubcategorySagaType.GET_ARTICLE_CAT_SUBCATEGORY_PENDING,
  data,
});

export const getArticleCatSubcategorySuccess = (data) => ({
  type: getArticleCatSubcategorySagaType.GET_ARTICLE_CAT_SUBCATEGORY_SUCCESS,
  data,
});

export const getArticleCatSubcategoryFailure = (error) => ({
  type: getArticleCatSubcategorySagaType.GET_ARTICLE_CAT_SUBCATEGORY_FAILURE,
  error,
});

// getArticlesBasedCatSubcategory ACTION
export const getArticlesBasedCatSubcategory = (data) => ({
  type: getArticlesBasedCatSubcategoryType.GET_ARTICLE_BASED_CAT_SUBCATEGORY_PENDING,
  data,
});
export const getArticlesBasedCatSubcategorySuccess = (data) => ({
  type: getArticlesBasedCatSubcategoryType.GET_ARTICLE_BASED_CAT_SUBCATEGORY_SUCCESS,
  data,
});

export const getArticlesBasedCatSubcategoryFailure = (error) => ({
  type: getArticlesBasedCatSubcategoryType.GET_ARTICLE_BASED_CAT_SUBCATEGORY_FAILURE,
  error,
});

// getArticleDetails ACTION
export const getArticleDetails = (data) => ({
  type: getArticleDetailsType.GET_ARTICLE_DETAILS_PENDING,
  data,
});

export const getArticleDetailsSuccess = (data) => ({
  type: getArticleDetailsType.GET_ARTICLE_DETAILS_SUCCESS,
  data,
});

export const getArticleDetailsFailure = (error) => ({
  type: getArticleDetailsType.GET_ARTICLE_DETAILS_FAILURE,
  error,
});

// postAddLikeDislikeArticle ACTION
export const addLikeDislikeArticle = (data) => ({
  type: addLikeDislikeArticleType.POST_ADD_LIKE_DISLIKE_ARTICLE_PENDING,
  data,
});

export const addLikeDislikeArticleSuccess = (data) => ({
  type: addLikeDislikeArticleType.POST_ADD_LIKE_DISLIKE_ARTICLE_SUCCESS,
  data,
});

export const addLikeDislikeArticleFailure = (data) => ({
  type: addLikeDislikeArticleType.POST_ADD_LIKE_DISLIKE_ARTICLE_FAILURE,
  data,
});

// getTickets ACTION
export const getTickets = (data) => ({
  type: getTicketsType.GET_TICKETS_PENDING,
  data,
});
export const getTicketsSuccess = (data) => ({
  type: getTicketsType.GET_TICKETS_SUCCESS,
  data,
});

export const getTicketsFailure = (error) => ({
  type: getTicketsType.GET_TICKETS_FAILURE,
  error,
});

// getTicketDetails ACTION
export const getTicketDetails = (data) => ({
  type: getTicketDetailsType.GET_TICKET_DETAILS_PENDING,
  data,
});
export const getTicketDetailsSuccess = (data) => ({
  type: getTicketDetailsType.GET_TICKET_DETAILS_SUCCESS,
  data,
});

export const getTicketDetailsFailure = (error) => ({
  type: getTicketDetailsType.GET_TICKET_DETAILS_FAILURE,
  error,
});

// postCreateTicket ACTION
export const postCreateTicket = (data) => ({
  type: createTicketType.POST_CREATE_TICKET_PENDING,
  data,
});

export const postCreateTicketSuccess = (data) => ({
  type: createTicketType.POST_CREATE_TICKET_SUCCESS,
  data,
});

export const postCreateTicketFailure = (data) => ({
  type: createTicketType.POST_CREATE_TICKET_FAILURE,
  data,
});

// postCreateTicket ACTION
export const replyTicket = (data) => ({
  type: replyTicketType.POST_REPLY_TICKET_PENDING,
  data,
});

export const replyTicketSuccess = (data) => ({
  type: replyTicketType.POST_REPLY_TICKET_SUCCESS,
  data,
});

export const replyTicketFailure = (data) => ({
  type: replyTicketType.POST_REPLY_TICKET_FAILURE,
  data,
});

// postUserFeedback ACTION
export const postUserFeedback = (data) => ({
  type: postUserFeedbackOnTicketType.POST_USER_FEEDBACK_ON_TICKET_PENDING,
  data,
});

export const postUserFeedbackSuccess = (data) => ({
  type: postUserFeedbackOnTicketType.POST_USER_FEEDBACK_ON_TICKET_SUCCESS,
  data,
});

export const postUserFeedbackFailure = (data) => ({
  type: postUserFeedbackOnTicketType.POST_USER_FEEDBACK_ON_TICKET_FAILURE,
  data,
});

// getDashboardTickets ACTION
export const getDashboardTickets = (data) => ({
  type: getDashboardTicketsType.GET_DASHBOARD_TICKETS_PENDING,
  data,
});
export const getDashboardTicketsSuccess = (data) => ({
  type: getDashboardTicketsType.GET_DASHBOARD_TICKETS_SUCCESS,
  data,
});

export const getDashboardTicketsFailure = (error) => ({
  type: getDashboardTicketsType.GET_DASHBOARD_TICKETS_FAILURE,
  error,
});

// getTicketsAdminDesk ACTION
export const getTicketsAdminDesk = (data) => ({
  type: getTicketsAdminDeskType.GET_TICKETS_ADMIN_DESK_PENDING,
  data,
});
export const getTicketsAdminDeskSuccess = (data) => ({
  type: getTicketsAdminDeskType.GET_TICKETS_ADMIN_DESK_SUCCESS,
  data,
});

export const getTicketsAdminDeskFailure = (error) => ({
  type: getTicketsAdminDeskType.GET_TICKETS_ADMIN_DESK_FAILURE,
  error,
});

// getTicketsFeedbackDesk ACTION
export const getTicketsFeedbackDesk = (data) => ({
  type: getTicketsFeedbackDeskType.GET_TICKETS_FEEDBACK_DESK_PENDING,
  data,
});
export const getTicketsFeedbackDeskSuccess = (data) => ({
  type: getTicketsFeedbackDeskType.GET_TICKETS_FEEDBACK_DESK_SUCCESS,
  data,
});

export const getTicketsFeedbackDeskFailure = (error) => ({
  type: getTicketsFeedbackDeskType.GET_TICKETS_FEEDBACK_DESK_FAILURE,
  error,
});

// ppostClosedTicket ACTION
export const postClosedTicket = (data) => ({
  type: postClosedTicketType.POST_CLOSED_TICKET_PENDING,
  data,
});

export const postClosedTicketSuccess = (data) => ({
  type: postClosedTicketType.POST_CLOSED_TICKET_SUCCESS,
  data,
});

export const postClosedTicketFailure = (data) => ({
  type: postClosedTicketType.POST_CLOSED_TICKET_FAILURE,
  data,
});

// postUserAssignTicket ACTION
export const postUserAssignTicket = (data) => ({
  type: postUserAssignTicketType.POST_USER_ASSIGN_TICKET_PENDING,
  data,
});

export const postUserAssignTicketSuccess = (data) => ({
  type: postUserAssignTicketType.POST_USER_ASSIGN_TICKET_SUCCESS,
  data,
});

export const postUserAssignTicketFailure = (data) => ({
  type: postUserAssignTicketType.POST_USER_ASSIGN_TICKET_FAILURE,
  data,
});

// postUpdateSeverityTicket ACTION
export const postUpdateSeverityTicket = (data) => ({
  type: postUpdateSeverityTicketType.POST_UPDATE_SEVERITY_TICKET_PENDING,
  data,
});

export const postUpdateSeverityTicketSuccess = (data) => ({
  type: postUpdateSeverityTicketType.POST_UPDATE_SEVERITY_TICKET_SUCCESS,
  data,
});

export const postUpdateSeverityTicketFailure = (data) => ({
  type: postUpdateSeverityTicketType.POST_UPDATE_SEVERITY_TICKET_FAILURE,
  data,
});

// postAddFAQ ACTION
export const postAddFAQ = (data) => ({
  type: postAddFAQType.POST_ADD_FAQ_PENDING,
  data,
});

export const postAddFAQSuccess = (data) => ({
  type: postAddFAQType.POST_ADD_FAQ_SUCCESS,
  data,
});

export const postAddFAQFailure = (data) => ({
  type: postAddFAQType.POST_ADD_FAQ_FAILURE,
  data,
});

// deleteFAQ ACTION
export const deleteFAQ = (data) => ({
  type: deleteFAQType.DELETE_FAQ_PENDING,
  data,
});

export const delteFAQSuccess = (data) => ({
  type: deleteFAQType.DELETE_FAQ_SUCCESS,
  data,
});

export const delteFAQFailure = (data) => ({
  type: deleteFAQType.DELETE_FAQ_FAILURE,
  data,
});

export const clearFAQ = () => ({
  type: deleteFAQType.CLEAR_FAQ,
  data: "",
});



export const updateFAQ = (payload) => ({
  type: updateFAQType.UPDATE_FAQ_PENDING,
  payload: payload,
});

export const updateFAQSuccess = (data) => ({
  type: updateFAQType.UPDATE_FAQ_SUCCESS,
  data,
});

export const updateFAQFailure = (error) => ({
  type: updateFAQType.UPDATE_FAQ_FAILED,
  error,
});