import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Parser from "html-react-parser";

const PersonalInjDetails = ({ addDetails }) => {
  return (
    <div className="commonOveride">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">Additional Details:</Typography>
            <Typography className="listView desNone">
              <div className="font-w400 overRideTextEditor">
                {Parser(addDetails.detail || "")}
              </div>
            </Typography>
          </Box>
          <Box className="commoninfoRight"></Box>
        </Box>
      </Box>
    </div>
  );
};

export default PersonalInjDetails;
