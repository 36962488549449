import React, { useState } from "react";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { FormControl, Grid } from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import "./jha.scss";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import MultiSelectBox from "./multiSelectBoxTemplate";
import { ASSET_TYPE_JHA } from "../../../../constant/inspectionConstant";
import MultiSelectMenu from "../../../../library/custom/selectMenu/MultiSelectBox";
const dataBack = {
  title: "Back to Templates",
  route: -1,
};
const JhaAddTemplate = ({
  formValue,
  setFormValue,
  categoriesList,
  categoriesName,
  setcategoriesName,
  catActArr,
  setcatActArr,
  submitTemplate,
  getJhaActivityList,
  editMode,
  setlastModifiedCatAct,
  lastModifiedCatAct,
  getJhaHazardsList,
  getControls,
  controlListData,
  controlsLoading,
}) => {
  const [isTemplateName, setIsTemplateName] = useState(false);
  const [isAssetTypeId, setIsAssetTypeId] = useState(false);
  // const [isCategories, setIsCategories] = useState(false)
  function submitData() {
    const data = [];
    catActArr.forEach((i) => {
      const activitiesId = [];
      i.selectedActivity.forEach((a) => {
        activitiesId.push(a.key);
      });
      data.push({
        categoryId: i.categoryId,
        activities: activitiesId,
      });
    });

    let errors = false;
    if (formValue?.templateName?.trim() === "") {
      setIsTemplateName(true);
      setFormValue({
        ...formValue,
        templateName: "",
      });
      errors = true;
    }
    if (!formValue.assetTypeId) {
      setIsAssetTypeId(true);
      errors = true;
    }
    if (data?.length <= 0) {
      setSelectCategory(true);
      setSelectCategoryMsg("Category is required");
      errors = true;
    }
    if (!errors)
      submitTemplate({
        tenplateName: formValue.templateName,
        assetTypeId: formValue.assetTypeId,
        categories: data,
        createdBy: formValue.createdBy,
      });
    else
      console.log({
        tenplateName: formValue.templateName,
        assetTypeId: formValue.assetTypeId,
        categories: data,
        createdBy: formValue.createdBy,
      });
  }

  const [categorySetValue, setCategorySetValue] = useState();
  const [categoryFindValues, setCategoryFindValues] = useState();
  const handleCategorySetChange = (e) => {
    const findCategoryValue = categoriesList.find(
      (item) => item.key === e.target.value
    );
    setCategoryFindValues(findCategoryValue);
    setCategorySetValue({
      categorySetValue: e.target.value,
    });
  };

  const [selectCategory, setSelectCategory] = useState(false);
  const [selectCategoryMsg, setSelectCategoryMsg] = useState(false);
  const handleOnclickCategory = () => {
    let errors = false;
    if (!categorySetValue) {
      setSelectCategory(true);
      setSelectCategoryMsg("Category is required");
      errors = true;
    }
    if (errors) {
      // setShowLoader(true);
      return;
    }
    const newCategoryes = [...catActArr];

    const index = newCategoryes?.findIndex(
      (obect) => obect.key === categoryFindValues?.key
    );
    if (index === -1) {
      // setShowLoader(false);
      setSelectCategory(false);
      newCategoryes.push({
        key: categoryFindValues?.key,
        categoriesName: categoryFindValues?.value,
        categoryId: categoryFindValues?.key,
        activities: [],
      });
      getJhaActivityList({
        categoryId: categoryFindValues?.key,
        search: "",
        isAll: 0,
        page: 1,
        pageSize: 1000,
      });
      setcatActArr(newCategoryes);
    } else {
      setSelectCategory(true);
      setSelectCategoryMsg("Category already added.");
    }
  };

  function selectAllActivity(list, categoryId) {
    let arr = [...list];

    arr.forEach((act) => {
      act.isSelected = true;
    });
    let updateCatArr = [...catActArr];

    updateCatArr.forEach((cat) => {
      if (cat.categoryId === categoryId) {
        cat.activities = arr;
      }
    });
    setcatActArr(updateCatArr);
  }
  function handleActivityCheck(singleActivity, activityList, categoryId) {
    activityList.forEach((act) => {
      if (act.activityId === singleActivity.activityId) {
        act.isSelected = !act.isSelected;
      }
    });

    let updateCatArr = [...catActArr];

    updateCatArr.forEach((cat) => {
      if (cat.categoryId === categoryId) {
        cat.activities = activityList;
      }
    });
    setcatActArr(updateCatArr);
  }
  function cancelCategory(params) {
    const newArr = catActArr.filter((i) => i.categoryId != params);
    setcatActArr(newArr);
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) getControls(panel);
  };
  return (
    <Grid className="overrideadmincategory">
      <Grid container className="controlNameBox">
        <Grid item md={8.5} xs={12}>
          <div className="controlTitile">
            {editMode ? "Edit" : "Create"} Hazard Analysis Template
          </div>
        </Grid>
      </Grid>

      <Grid item container mt={1.5} lg={12} spacing={1}>
        <Grid item lg={8} mt={2} className="Catoption">
          <InputField
            isRequired={true}
            disabled={false}
            type="text"
            label={"Hazard Analysis Template Name"}
            value={formValue.templateName}
            placeholder="Enter here"
            onChange={(e) =>
              setFormValue({
                ...formValue,
                templateName: e.target.value,
              })
            }
            isError={isTemplateName}
            errorMsg={isTemplateName ? "Template Name is Required" : ""}
          />
        </Grid>
      </Grid>

      <Grid item container mt={4} lg={12} spacing={1}>
        <Grid item lg={5} md={12} xs={12} mb={3}>
          <FormControl fullWidth>
            <FormLabels label="Select Asset Type" isRequired={true} />
            <SelectMenu
              placeholder="Please Select"
              listData={ASSET_TYPE_JHA}
              value={formValue.assetTypeId}
              onchangehandler={(e) =>
                setFormValue({
                  ...formValue,
                  assetTypeId: e.target.value,
                })
              }
              isError={isAssetTypeId}
              errorMsg={isAssetTypeId ? "Asset type is Required" : ""}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid container item>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <FormLabels label="Add Category" isRequired={true} />
              <SelectMenu
                placeholder="Please Select"
                listData={categoriesList ?? []}
                value={categorySetValue}
                onchangehandler={(e) => handleCategorySetChange(e)}
                // isError={isCategories}
                // errorMsg={isCategories ? "Category is required" : ""}
              />
              {/* {selectCategory && (
                    <div className="errorMsg">{selectCategoryMsg}</div>
                  )} */}
            </FormControl>
          </Grid>
          <Grid
            className="smallAddBtn"
            item
            lg={1.5}
            md={2}
            xs={3}
            ml={2}
            mt={4}
          >
            <Buttons
              aria-describedby="dd"
              variant="contained"
              type="button"
              name="btn"
              label={"Add"}
              id="btnAddEquipment"
              varientAddIconBlue={true}
              onClick={handleOnclickCategory}
            ></Buttons>
          </Grid>
        </Grid>

        <Grid md={12} item>
          {selectCategory && <p className="errorMsg">{selectCategoryMsg}</p>}
        </Grid>
      </Grid>
      <Grid item container mt={1.5} lg={12} spacing={1}>
        {catActArr.map((i) => (
          <Grid key={i.categoryId} mt={2} container>
            <MultiSelectBox
              btnLabel="Select All Activities"
              btnLabel2={"Remove Category"}
              label={i.categoriesName}
              list={i.activities}
              catActArr={catActArr}
              setcatActArr={setcatActArr}
              categoryId={i.categoryId}
              singleCategory={i}
              selectAll={selectAllActivity}
              cancel={cancelCategory}
              handleCheck={handleActivityCheck}
              selectedActivity={i.selectedActivity}
              setlastModifiedCatAct={setlastModifiedCatAct}
              getJhaHazardsList={getJhaHazardsList}
              getControls={getControls}
              handleChange={handleChange}
              expanded={expanded}
              setExpanded={setExpanded}
              controlListData={controlListData}
              controlsLoading={controlsLoading}
            />
          </Grid>
        ))}
      </Grid>

      <Grid item container lg={12} className="buttonsGrid" mt={3} mb={3}>
        <div>
          <BackBtn dataBack={dataBack} />
        </div>
        <Grid item lg={6} className="cancelSaveDiv">
          <Grid item lg={5}>
            <Buttons
              varientContained={true}
              label={"Save"}
              onClick={() => {
                submitData();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JhaAddTemplate;
