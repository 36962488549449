import { divisionsType } from "../constants/divisionsType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "divisions",
  isError: false,
  isLoading: false,
};

export const createDivisionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case divisionsType.POST_DIVISION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case divisionsType.POST_DIVISION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case divisionsType.POST_DIVISION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
      case divisionsType.CLEAR_DIVISION:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateDivisionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case divisionsType.UPDATE_DIVISION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case divisionsType.UPDATE_DIVISION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case divisionsType.UPDATE_DIVISION_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case divisionsType.CLEAR_DIVISION:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const getDivisionsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case divisionsType.GET_DIVISION_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case divisionsType.GET_DIVISION_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case divisionsType.GET_DIVISION_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const deleteDivisionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case divisionsType.DELETE_DIVISION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case divisionsType.DELETE_DIVISION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case divisionsType.DELETE_DIVISION_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const viewDivisionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case divisionsType.GET_DIVISION_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case divisionsType.GET_DIVISION_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case divisionsType.GET_DIVISION_DETAILS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
