import { toolBoxTalkType } from "../constants/toolBoxType";

export const createToolBoxTalk = (data) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_TALK_PENDING,
  data,
});

export const createToolBoxTalkSuccess = (data) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_TALK_SUCCESS,
  data,
});

export const createToolBoxTalkFailure = (error) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_TALK_FAILURE,
  error,
});

export const getToolBoxTalkList = (data) => ({
  type: toolBoxTalkType.GET_TOOL_BOX_TALK_LIST_PENDING,
  data,
});

export const getToolBoxTalkListSuccess = (data) => ({
  type: toolBoxTalkType.GET_TOOL_BOX_TALK_LIST_SUCCESS,
  data,
});

export const getToolBoxTalkListFailure = (error) => ({
  type: toolBoxTalkType.GET_TOOL_BOX_TALK_LIST_FAILURE,
  error,
});

export const getAdminToolBoxTalkList = (data) => ({
  type: toolBoxTalkType.GET_ADMINTOOL_BOX_TALK_LIST_PENDING,
  data,
});

export const getAdminToolBoxTalkListSuccess = (data) => ({
  type: toolBoxTalkType.GET_ADMINTOOL_BOX_TALK_LIST_SUCCESS,
  data,
});

export const getAdminToolBoxTalkListFailure = (error) => ({
  type: toolBoxTalkType.GET_ADMINTOOL_BOX_TALK_LIST_FAILURE,
  error,
});

export const getToolBoxTalkDetails = (data) => ({
  type: toolBoxTalkType.GET_TOOL_BOX_TALK_DETAILS_PENDING,
  payload: data,
});
export const getToolBoxTalkDetailsSuccess = (data) => ({
  type: toolBoxTalkType.GET_TOOL_BOX_TALK_DETAILS_SUCCESS,
  data,
});

export const getToolBoxTalkDetailsFailure = (error) => ({
  type: toolBoxTalkType.GET_TOOL_BOX_TALK_DETAILS_FAILED,
  error,
});

export const updateToolBoxTalkData = (payload) => ({
  type: toolBoxTalkType.UPDATE_TOOL_BOX_TALK_PENDING,
  payload: payload,
});

export const updateToolBoxTalkSuccess = (data) => ({
  type: toolBoxTalkType.UPDATE_TOOL_BOX_TALK_SUCCESS,
  data,
});

export const updateToolBoxTalkFailure = (error) => ({
  type: toolBoxTalkType.UPDATE_TOOL_BOX_TALK_FAILED,
  error,
});

export const deleteToolBoxTalk = (payload) => ({
  type: toolBoxTalkType.DELETE_TOOL_BOX_TALK_PENDING,
  payload: payload,
});

export const deleteToolBoxTalkSuccess = (payload) => ({
  type: toolBoxTalkType.DELETE_TOOL_BOX_TALK_SUCCESS,
  payload,
});

export const deleteToolBoxTalkFailure = (error) => ({
  type: toolBoxTalkType.DELETE_TOOL_BOX_TALK_FAILED,
  error,
});

export const clearToolBoxTalk = () => ({
  type: toolBoxTalkType.CLEAR_TOOLTALK,
  data: "",
});

export const clearScheduleDetailToolBoxTalk = () => ({
  type: toolBoxTalkType.CLEAR_SCHEDULETOOLTALK,
  data: "",
});

export const getToolBoxSafetyMeetingSearchList = (data) => ({
  type: toolBoxTalkType.GET_TOOL_BOX_SAFETY_MEETING_SEARCH_PENDING,
  data,
});

export const getToolBoxSafetyMeetingSearchListSuccess = (data) => ({
  type: toolBoxTalkType.GET_TOOL_BOX_SAFETY_MEETING_SEARCH_SUCCESS,
  data,
});

export const getToolBoxSafetyMeetingSearchListFailure = (error) => ({
  type: toolBoxTalkType.GET_TOOL_BOX_SAFETY_MEETING_SEARCH_FAILURE,
  error,
});

export const assignAdminToInstructorToolBox = (data) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_PENDING,
  data,
});

export const assignAdminToInstructorToolBoxSuccess = (data) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_SUCCESS,
  data,
});

export const assignAdminToInstructorToolBoxFailure = (error) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_FAILURE,
  error,
});

export const getScheduleSafetyMeetingDetails = (data) => ({
  type: toolBoxTalkType.GET_SCHEDULE_MEETING_DETAILS_PENDING,
  payload: data,
});
export const getScheduleSafetyMeetingDetailsSuccess = (data) => ({
  type: toolBoxTalkType.GET_SCHEDULE_MEETING_DETAILS_SUCCESS,
  data,
});

export const getScheduleSafetyMeetingDetailsFailure = (error) => ({
  type: toolBoxTalkType.GET_SCHEDULE_MEETING_DETAILS_FAILED,
  error,
});

//  Instructot to assign User
export const assignInstructorToUserToolBox = (data) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_USER_PENDING,
  data,
});

export const assignInstructorToUserToolBoxSuccess = (data) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_USER_SUCCESS,
  data,
});

export const assignInstructorToUserToolBoxFailure = (error) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_USER_FAILURE,
  error,
});

// Self Instructot to assign User
export const assignSelfInstructorToUserToolBox = (data) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_SELF_ASSIGN_TO_USER_PENDING,
  data,
});

export const assignSelfInstructorToUserToolBoxSuccess = (data) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_SELF_ASSIGN_TO_USER_SUCCESS,
  data,
});

export const assignSelfInstructorToUserToolBoxFailure = (error) => ({
  type: toolBoxTalkType.POST_TOOL_BOX_SELF_ASSIGN_TO_USER_FAILURE,
  error,
});

export const verifyAttendeeUser = (data) => ({
  type: toolBoxTalkType.POST_VERIFY_ATTENDEE_PENDING,
  data,
});

export const verifyAttendeeUserSuccess = (data) => ({
  type: toolBoxTalkType.POST_VERIFY_ATTENDEE_SUCCESS,
  data,
});

export const verifyAttendeeUserFailure = (error) => ({
  type: toolBoxTalkType.POST_VERIFY_ATTENDEE_FAILURE,
  error,
});

export const getVerifyAttendeeUserList = (data) => ({
  type: toolBoxTalkType.GET_VERIFY_ATTENDEE_LIST_PENDING,
  data,
});

export const getVerifyAttendeeUserListSuccess = (data) => ({
  type: toolBoxTalkType.GET_VERIFY_ATTENDEE_LIST_SUCCESS,
  data,
});

export const getVerifyAttendeeUserListFailure = (error) => ({
  type: toolBoxTalkType.GET_VERIFY_ATTENDEE_LIST_FAILURE,
  error,
});

export const deleteScheduleToolBoxTalk = (payload) => ({
  type: toolBoxTalkType.DELETE_SCHEDULE_TOOL_BOX_TALK_PENDING,
  payload: payload,
});

export const deleteScheduleToolBoxTalkSuccess = (payload) => ({
  type: toolBoxTalkType.DELETE_SCHEDULE_TOOL_BOX_TALK_SUCCESS,
  payload,
});

export const deleteScheduleToolBoxTalkFailure = (error) => ({
  type: toolBoxTalkType.DELETE_SCHEDULE_TOOL_BOX_TALK_FAILED,
  error,
});

export const UserMarkedCompletedToolBoxTalk = (data) => ({
  type: toolBoxTalkType.POST_USER_MARKED_TOOL_BOX_TALK_PENDING,
  data,
});

export const UserMarkedCompletedToolBoxTalkSuccess = (data) => ({
  type: toolBoxTalkType.POST_USER_MARKED_TOOL_BOX_TALK_SUCCESS,
  data,
});

export const UserMarkedCompletedToolBoxTalkFailure = (error) => ({
  type: toolBoxTalkType.POST_USER_MARKED_TOOL_BOX_TALK_FAILURE,
  error,
});

export const getExtraUserList = (data) => ({
  type: toolBoxTalkType.GET_EXTRA_USER_LIST_PENDING,
  data,
});

export const getExtraUserListSuccess = (data) => ({
  type: toolBoxTalkType.GET_EXTRA_USER_LIST_SUCCESS,
  data,
});

export const getExtraUserListFailure = (error) => ({
  type: toolBoxTalkType.GET_EXTRA_USER_LIST_FAILURE,
  error,
});

export const createExtraUser = (data) => ({
  type: toolBoxTalkType.POST_EXTRA_USER_PENDING,
  data,
});

export const createExtraUserSuccess = (data) => ({
  type: toolBoxTalkType.POST_EXTRA_USER_SUCCESS,
  data,
});

export const createExtraUserFailure = (error) => ({
  type: toolBoxTalkType.POST_EXTRA_USER_FAILURE,
  error,
});
export const clearCreateExtraUser = () => ({
  type: toolBoxTalkType.CLEAR_CREATE_USER_EXTRA,
});
export const clearUserDetailTalk = () => ({
  type: toolBoxTalkType.CLEAR_USERTOOLTALK,
  data: "",
});

export const getProjectSearchList = (data) => ({
  type: toolBoxTalkType.GET_PROJECT_SEARCH_LIST_PENDING,
  data,
});

export const getProjectSearchListSuccess = (data) => ({
  type: toolBoxTalkType.GET_PROJECT_SEARCH_LIST_SUCCESS,
  data,
});

export const getProjectSearchListFailure = (error) => ({
  type: toolBoxTalkType.GET_PROJECT_SEARCH_LIST_FAILURE,
  error,
});

export const searchAllUsersProjects = (
  searchText,
  searchType,
  searchProjectId,
  divisionId
) => ({
  type: toolBoxTalkType.GET_ALL_USER_SEARCH,
  searchText,
  searchType,
  searchProjectId,
  divisionId,
});

export const searchAllUsersProjectsSuccess = (data) => ({
  type: toolBoxTalkType.GET_ALL_USER_SEARCH_SUCCESS,
  data,
});

export const searchAllUsersProjectsFailure = (error) => ({
  type: toolBoxTalkType.GET_ALL_USER_SEARCH_FAILURE,
  error,
});

export const clearUserProjectWiseTalk = () => ({
  type: toolBoxTalkType.CLEAR_USERPROJECTWISETALK,
  data: "",
});
// scheduleUserCountStatus
export const scheduleUserCountStatusList = (data) => ({
  type: toolBoxTalkType.SCHEDULE_USERCOUNT_STATUS_LIST_PENDING,
  data,
});

export const scheduleUserCountStatusSuccess = (data) => ({
  type: toolBoxTalkType.SCHEDULE_USERCOUNT_STATUS_LIST_SUCCESS,
  data,
});

export const scheduleUserCountStatusFailure = (error) => ({
  type: toolBoxTalkType.SCHEDULE_USERCOUNT_STATUS_LIST_FAILURE,
  error,
});
// safety alert mail
export const getAlertmailSafetyMeeting = (data) => ({
  type: toolBoxTalkType.POST_SAFETY_MEETING_ALERT_PENDING,
  data,
});

export const getAlertmailSafetyMeetingSuccess = (data) => ({
  type: toolBoxTalkType.POST_SAFETY_MEETING_ALERT_SUCCESS,
  data,
});

export const getAlertmailSafetyMeetingFailure = (error) => ({
  type: toolBoxTalkType.POST_SAFETY_MEETING_ALERT_FAILURE,
  error,
});
