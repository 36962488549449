import { lmsReportType } from "../constants/lmsReportType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "lmsReport",
  isError: false,
  isLoading: false,
};

export const getLmsReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsReportType.GET_LMS_REPORT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsReportType.GET_LMS_REPORT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsReportType.GET_LMS_REPORT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getLmsReportCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsReportType.GET_LMS_REPORT_COURSE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsReportType.GET_LMS_REPORT_COURSE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsReportType.GET_LMS_REPORT_COURSE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getLmsReportLessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsReportType.GET_LMS_REPORT_LESSON_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsReportType.GET_LMS_REPORT_LESSON_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsReportType.GET_LMS_REPORT_LESSON_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
