import React, { useState } from "react";
import {
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";

function UserSearchInspectionList({
  getSwInspectionList,
  getSwInspectionListData,
  autoSearchDataLoading,
  UserDatail,
}) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      search: search ? search : "",
      inspectionTypeId: 0,
      projectId: 0,
      customerId: parseInt(ORG_ID()),
      frequencyId: "",
      statusId: 5,
      startDate: "",
      endDate: "",
      page: newPage + 1,
      pageSize: pageSize,
      userId: UserDatail?.userId,
    };
    getSwInspectionListData(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      search: search ? search : "",
      inspectionTypeId: 0,
      projectId: 0,
      customerId: parseInt(ORG_ID()),
      frequencyId: "",
      statusId: 5,
      startDate: "",
      endDate: "",
      page: page,
      pageSize: newPazeSize,
      userId: UserDatail?.userId,
    };
    getSwInspectionListData(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      search: event.target.value ? event.target.value : "",
      inspectionTypeId: 0,
      projectId: 0,
      customerId: parseInt(ORG_ID()),
      frequencyId: "",
      statusId: 5,
      startDate: "",
      endDate: "",
      page: 1,
      pageSize: pageSize,
      userId: UserDatail?.userId,
    };
    getSwInspectionListData(data);
  };

  const credcloumns = [
    { field: "reportId", headerName: " Report ID", flex: 2.8 },
    { field: "projectNumber", headerName: "Project Number", flex: 4 },
    { field: "projectName", headerName: "Project", flex: 4 },
    { field: "customer", headerName: "Client", flex: 4 },
    { field: "inspectionBy", headerName: "Inspector", flex: 3.5 },
    { field: "dateScheduled", headerName: "Date Scheduled", flex: 3.4 },
    { field: "openItemCount", headerName: "Opent Items", flex: 2.5 },
    { field: "inspectionType", headerName: "Inspection Type", flex: 2.5 },
    { field: "frequency", headerName: "Frequency", flex: 2.5 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={getSwInspectionList?.recordsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={getSwInspectionList?.inspectionReports ?? []}
          fileNameXL={"InspectionList"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];
  return (
    <div className="SwInspectionReportAnlalytics">
      <section className="grid_main_sec">
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search Inspection"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.reportId}
                rows={getSwInspectionList?.inspectionReports ?? []}
                rowCount={getSwInspectionList?.recordsCount ?? 0}
                columns={credcloumns}
                loading={autoSearchDataLoading}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UserSearchInspectionList;
