import { incidentType } from "../constants/incidentType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "incident",
  isError: false,
  isLoading: false,
};

export const incidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const incidentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_INCIDENT_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_INCIDENT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_INCIDENT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const deleteIncidentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.DELETE_INCIDENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.DELETE_INCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.DELETE_INCIDENT_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const actionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_ACTION_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_ACTION_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_ACTION_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const closeActionItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.CLOSE_ACTION_ITEM:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.CLOSE_ACTION_ITEM_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.CLOSE_ACTION_ITEM_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const casesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_CASES_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_CASES_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_CASES_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const viewCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.GET_CASE_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.GET_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.GET_CASE_DETAILS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const closeIncidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.CLOSE_INCIDENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.CLOSE_INCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case incidentType.CLOSE_INCIDENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};


export const deleteCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case incidentType.DELETE_CASE:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case incidentType.DELETE_CASE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.payload,
        isError: false,
        isLoading: false,
      };
    case incidentType.DELETE_CASE_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};