import { createSelector } from "@mui/x-data-grid/utils/createSelector";

export const selectOnboardUsersStatus = (state) =>
  state.getDropdownDataKeyValue?.data?.OnboardUsersStatus || [];

export const selectOnboardContractorStatus = createSelector(
  [selectOnboardUsersStatus],
  (onboardUsersStatus) => {
    if (!onboardUsersStatus) return null;

    const data = onboardUsersStatus?.map((x) => {
      if (x.value?.includes("User")) {
        const updatedSentence = x.value?.replace("User", "Contractor");
        x.value = updatedSentence;
      }
      return x;
    });

    return data || [];
  }
);
