import { CircularProgress, Grid } from "@mui/material";
import React from "react";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import SupportCard from "../../../library/custom/supportCard/supportCard";
import SupportAccordion from "../../../library/custom/accordion/supportAccordion";
import "./supportLandingPage.scss";
import { useNavigate } from "react-router-dom";
import Header from "../common/header";
import { checkPrivileges, usersAccess } from "../../../utils/rolesHelper";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import supportConnectIcon from "../../../assets/supportConnectIcon.svg";
import {
  ADMIN_FAQ,
  FAQ,
  RESOLUTION_TEAM_TICKET,
  TICKET,
  TICKET_DASHBOARD,
} from "../../../constant/routeContant";
import { InComponentLoader } from "../../../library/common/Loader";
const SupportLandingPage = (props) => {
  const navigate = useNavigate();
  const SUPPORT_LANDING_CARD_DETAILS = [
    {
      key: "1",
      category: "Tickets",
      details: "Raise and resolve your issues related to Sequence.",
      icon: <PlagiarismIcon className="icon" />,
    },
    {
      key: "2",
      category: "FAQs",
      details: "Frequently asked questions and common knowledge.",
      icon: <QuestionAnswerIcon className="icon" />,
    },
    {
      key: "3",
      category: "Connect",
      details: "Contact Sequence team for your queries and help.",
      icon: <img src={supportConnectIcon} alt="" />,
    },
  ];
  var ticketRoute;
  var faqRoute;
  if (checkPrivileges([7])) {
    ticketRoute = TICKET_DASHBOARD;
    faqRoute = ADMIN_FAQ;
  } else if (checkPrivileges([8])) {
    ticketRoute = RESOLUTION_TEAM_TICKET;
    faqRoute = FAQ;
  } else {
    ticketRoute = TICKET;
    faqRoute = FAQ;
  }
  return (
    <div className="overideSupportLandingPage">
      <div className="landingpageContainer">
        <Header
          value={props.searchbarInput}
          handleSearch={props.handleSearch}
        />
        <div className="cardsContainer">
          {SUPPORT_LANDING_CARD_DETAILS.map((details) => {
            return (
              <SupportCard
                icon={details.icon}
                key={details.key}
                category={details.category}
                details={details.details}
                submit={props.submit}
                onClick={() => {
                  details.key === "1"
                    ? navigate(ticketRoute)
                    : details.key === "2"
                    ? navigate(faqRoute)
                    : navigate("/connect");
                }}
              />
            );
          })}
        </div>
        {/* <h1 className="secondaryHeader">Getting Started</h1>
        <div className="tertiaryHeader">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          in nisi elit. Curabitur sit amet sapien
        </div> */}
        {props.articles ? (
          <Grid
            className="accordionDiv"
            item
            container
            mb={2}
            lg={10}
            mt={3}
            gap={3}
          >
            {props.articles.map((details) => {
              return (
                <SupportAccordion
                  key={details.articleId}
                  varientSupport={true}
                  accordionSummarys={details.title}
                  accordionDetails={details.description}
                />
              );
            })}
          </Grid>
        ) : (
          <InComponentLoader />
        )}
      </div>
    </div>
  );
};

export default SupportLandingPage;
