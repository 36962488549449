import React, { useEffect } from "react";
import ViewPermit from "../components/viewPermit";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import { SW_PERMIT_LIST } from "../../../../constant/routeContant";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../library/common/Loader";
import * as permitAction from "../../../../redux/storm-water/actions/permitAction";

export default function ViewPermitContainer() {
  const dispatch = useDispatch();
  const location = useLocation();

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };

  const permitDetailsisLoading = useSelector(
    (state) => state.permitDetails?.isLoading
  );
  const permitDetails = useSelector(
    (state) => state.permitDetails?.data?.result
  );
  const getPermitType = useSelector((state) => state.getPermitType?.data);

  const permitId = location?.state?.permitId;
  const projectid = location?.state?.projectid;
  const stateId = location?.state?.stateId;
  const projectName = location?.state?.projectName;
  const projectNumber = location?.state?.projectNumber;
  const Projectstate = location?.state?.state;
  const data1 = {
    stateId: stateId,
  };
  const handlePermitType = (data1) => {
    dispatch(permitAction.getPermitTypePending(data1));
  };
  useEffect(() => {
    if (permitId) {
      const data = {
        permitId: permitId,
      };
      dispatch(permitAction.permitDetailPending(data));
    }
  }, []);

  useEffect(() => {
    getStateData();
    handlePermitType(data1);
  }, []);
  const customerName = localStorage?.getItem("companyName");

  const dataBack = {
    title: "Back to Permits",
    route: SW_PERMIT_LIST,
    projectName: permitDetails?.projectName,
    projectNumber: permitDetails?.projectNumber,
    projectid: permitDetails?.projectId,
    customerName: customerName,
    stateId: permitDetails?.stateId,
  };

  return (
    <>
      {permitDetailsisLoading && <Loader />}

      <ViewPermit
        dataBack={dataBack}
        permitDetails={permitDetails}
        getPermitType={getPermitType}
        projectName={projectName}
        stateId={stateId}
      />
    </>
  );
}
