import React, { useEffect, useState } from "react";
import "./addlessons.scss";
import { FormControl, Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../../library/custom/textArea/TextArea";
import Button from "../../../../library/custom/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { formValidation } from "../../../../utils/incpectionHelper";
import Section from "../../../../library/custom/section/Section";
import AddIcon from "@mui/icons-material/Add";
import { CREATE_QUIZ } from "../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { LNG_ID, ORG_ID, HBNEXT_ORG_ID } from "../../../../constant/constant";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import Checkbox from "../../../../library/checkbox/checkbox";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import FormDropZone from "../../../../library/dropZone/FormDropZone";
const AddLessons = ({ categoryListData, onSubmit, lessonDetailData }) => {
  const location = useLocation();
  const { pathname } = location;
  const edit = pathname.split("/")[2] ?? null;
  const [errors, setErrors] = useState({});
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();

  const userId = localStorage.getItem("userId");
  const [addPhoto, setAddPhoto] = useState("");
  const [addPhotos, setAddPhotos] = useState(null);
  const [addDocs, setAddDocs] = useState("");
  const [checkBox, setcheckBox] = useState(
    edit && lessonDetailData ? null : false
  );

  const isShowClient =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && checkPrivileges([12]);
  const { isSuccess, editLessonData } = useSelector(
    (state) => state.updateLesson
  );
  const intialState = {
    id: Date.now() + Math.floor(Math.random() * 100),
    organisationId: ORG_ID(),
    createdBy: userId,
    createdByApp: 1,
    isDocAdded: false,
    lngId: LNG_ID,
    embededVideoLink: "",
    imgFiles: [],
    docFiles: [],
    isImageAdded: false,
    Text: "",
  };

  const [sectionList, setSectionList] = useState([intialState]);

  const formHandler = (event, action) => {
    // SEQ24R1-407 Global lesson update without select category TODO
    const iscategryAvilable = categoryListData?.categoryList?.filter((val) => {
      return val?.key === formValue?.categoryId;
    });

    if (iscategryAvilable.length === 0) {
      setFormValue({
        ...formValue,
        categoryId: "",
      });
    }

    const formData = {
      ...(edit && editLessonData && { lessonId: editLessonData?.id ?? "" }),
      organisationId: ORG_ID(),
      LngId: LNG_ID,
      // categoryId: formValue?.categoryId ?? null,
      categoryId: iscategryAvilable.length > 0 ? formValue?.categoryId : null, //TODO
      completionTimeHr: formValue?.completionTimeHr ?? 0,
      completionTimeMin: formValue?.completionTimeMin ?? null,
      title: formValue?.title ?? null,
      description: formValue?.description ?? null,
      createdBy: userId ? userId : "",
      createdByApp: null,
      //files: formValue?.Files.length > 0 ? formValue?.Files : images ?? "",
      files: images ?? [],
      //isGlobal: false,
      isGlobal: checkBox, // TODO
      DynamicFields: sectionList.length > 0 ? sectionList : [],
    };
    const { errorKey } = formValidation(
      {
        title: formValue.title,
        categoryId: formValue.categoryId,
        completionTimeMin: formValue.completionTimeMin,
      },
      action
    );
    setErrors(errorKey);
    let isError = false;
    if (!formValue.title) {
      isError = true;
    }
    //if (!formValue.categoryId) {
    if (!formValue.categoryId || iscategryAvilable.length === 0) {
      // TODO
      isError = true;
    }
    if (!formValue.completionTimeMin) {
      isError = true;
    }

    if (!isError) {
      setIsSubmit(true);

      onSubmit(formData);
    } else {
      setIsSubmit(false);
    }
  };

  const [formValue, setFormValue] = useState({
    organisationId: ORG_ID(),
    LngId: LNG_ID,
    createdBy: userId,
    categoryId: "",
    completionTimeHr: 0,
    completionTimeMin: "",
    title: "",
    description: "",
    Files: "",
    DynamicFields: "",
  });

  const handleMinsonChange = (e, value) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      const minval = e.target.value.replace(/^0+/, "");

      setFormValue({
        ...formValue,

        completionTimeMin: minval,
      });
    }
  };
  const handleTitleChange = (event, value) => {
    let fName = event.target.value;
    let finaltitile = fName.replace(/\s+/g, " ");
    setFormValue({
      ...formValue,

      title: finaltitile,
    });
  };

  const handleDescChange = (event, value) => {
    let Desc = event.target.value;
    setFormValue({
      ...formValue,

      description: Desc,
    });
  };

  useEffect(() => {
    if (checkBox) {
      dispatch(lmsAction.getCategoryList({ isGlobal: true }));
    } else if (checkBox === false) {
      dispatch(lmsAction.getCategoryList({ isGlobal: false }));
    }
  }, [checkBox]);

  const clearFormValue = React.useCallback(
    (editForm) => {
      let coverKeId = [];
      let coverImagePath;
      setAddPhoto(null);

      if (editForm?.file) {
        let coverImageId = editForm?.file?.id;
        coverImagePath = editForm?.file?.path;

        setAddPhoto(coverImagePath);
        coverKeId = [coverImageId];
      }
      setcheckBox(edit && editForm && editForm?.isGlobal);
      setFormValue({
        categoryId: (edit && editForm?.categoryId) ?? "",
        completionTimeHr: (edit && editForm?.completionTimeHr) ?? 0,
        completionTimeMin: (edit && editForm?.completionTimeMin) ?? "",
        description: (edit && editForm?.description) ?? "",
        title: (edit && editForm?.lesson) ?? "",
        organisationId: (edit && editForm?.organisationId) ?? "",
        LngId: (edit && editForm?.LngId) ?? "",
        Files: (edit && coverKeId) ?? "",
        Filesurl: (edit && coverImagePath) ?? "",
      });
      setImages(edit && coverKeId);
    },
    [edit, lessonDetailData]
  );

  useEffect(() => {
    if (isSuccess) {
      clearFormValue();
    }
  }, [isSuccess, clearFormValue]);

  useEffect(() => {
    setAddPhoto(null);
    //setAddPhoto("");
    setSectionList([intialState]);
  }, [edit]);

  useEffect(() => {
    if (edit) {
      clearFormValue(editLessonData);
      const newSectionList = [];

      editLessonData?.sections?.map((items) => {
        return newSectionList.push({
          id: items.sectionId,
          Text: items.text,
          embededVideoLink: items.videoLink,
          imgFiles: items?.filesImg[0]?.id ? [items?.filesImg[0]?.id] : [],
          imgFilesUrl: items?.filesImg[0]?.path || [],
          docFiles: items?.filesDoc[0]?.id ? [items?.filesDoc[0]?.id] : [],
          docFilesUrl: items?.filesDoc[0]?.path || [],
          sectionId: items.sectionId,
          organisationId: ORG_ID(),
          createdBy: userId,
          createdByApp: 1,
          isImageAdded: items?.filesImg[0]?.id ? true : false,
          isDocAdded: items?.filesDoc[0]?.id ? true : false,
          lngId: LNG_ID,
        });
      });

      setSectionList(newSectionList);
    } else {
      setSectionList([intialState]);
    }
  }, [edit, clearFormValue, editLessonData]);

  const handleOnclickSections = () => {
    //if (sectionList.length < 4) {
    const newSectionList = [...sectionList];
    newSectionList.push({
      id: Date.now() + Math.floor(Math.random() * 100),
      Text: "",
      organisationId: ORG_ID(),
      createdBy: userId,
      createdByApp: 1,
      isDocAdded: false,
      isImageAdded: false,
      lngId: 1,
      embededVideoLink: "",
      imgFiles: [],
      docFiles: [],
    });
    setSectionList(newSectionList);
    // }
  };

  // onchage function
  const handleOnChangeSectionForm = (current, data, type) => {
    let elementId = "";
    let value = "";
    if (type === "uploadPhoto" || type === "uploadDocs" || type === "Text") {
      value = current;
      elementId = type;
    } else {
      value = current.target.value;
      elementId = current.target.id;
    }
    let updatedSectionList = [...sectionList];
    updatedSectionList.forEach((sectionLists) => {
      if (sectionLists.id === data.id) {
        if (elementId === "Text") {
          sectionLists.Text = value;
        } else if (elementId === "embededVideoLink") {
          sectionLists.embededVideoLink = value;
        } else if (elementId === "uploadPhoto") {
          sectionLists.imgFiles = value;
        } else if (elementId === "uploadDocs") {
          sectionLists.docFiles = value;
        }
      }
    });
    setSectionList(updatedSectionList);
  };
  const [sectionImageData, setSectionImageData] = useState("");
  const [sectionData, setsectionData] = useState("");
  const sectionImageUpload = (files, id, single) => {
    setSectionImageData(id);
    setsectionData(single);
    setAddPhotos(files);
  };

  useEffect(() => {
    let updatedSectionList = [...sectionList];

    updatedSectionList.forEach((sectionLists) => {
      if (sectionLists.id === sectionData.id) {
        sectionLists.imgFiles = sectionImageData;
        sectionLists.imgFilesUrl = addPhotos;
        sectionLists.isImageAdded = true;
      }
    });
    setSectionList(updatedSectionList);
  }, [sectionImageData, addPhotos]);

  const [sectionFilesDatas, setSectionFilesDatas] = useState("");
  const [sectionDatas, setsectionDatas] = useState("");

  const sectionFileUploads = (files, id, single) => {
    setSectionFilesDatas(id);
    setsectionDatas(single);
    setAddDocs(files);
  };
  useEffect(() => {
    let updatedSectionList = [...sectionList];
    updatedSectionList.forEach((sectionListss) => {
      if (sectionListss.id === sectionDatas.id) {
        sectionListss.docFiles = sectionFilesDatas;
        sectionListss.isDocAdded = true;
        sectionListss.docFilesUrl = addDocs;
      }
    });
    setSectionList(updatedSectionList);
  }, [sectionFilesDatas, addDocs]);

  const handleDeleteImageURL = (urls) => {
    setImages([]);
    setAddPhoto("");
    setFormValue({
      ...formValue,
      Files: "",
      Filesurl: "",
    });
  };
  const handleDeleteImageURLs = (urls) => {
    let updatedSectionList = [...sectionList];
    updatedSectionList.forEach((sectionListss) => {
      if (sectionListss?.imgFilesUrl === urls) {
        sectionListss.imgFiles = [];
        sectionListss.imgFilesUrl = "";
        sectionListss.isImageAdded = false;
      }
    });
    setSectionList(updatedSectionList);
  };
  const handleDeleteUrlURLs = (urls) => {
    let updatedSectionList = [...sectionList];
    updatedSectionList.forEach((sectionListss) => {
      if (sectionListss?.docFilesUrl === urls) {
        sectionListss.docFiles = [];
        sectionListss.docFilesUrl = "";
        sectionListss.isDocAdded = false;
      }
    });
    setSectionList(updatedSectionList);
  };

  const cancelSectionList = (id) => {
    const removeSectionList = sectionList.filter(
      (section) => section.id !== id
    );
    setSectionList(removeSectionList);
  };

  const handleViewCreateQuiz = (lessonId, quizId) => {
    navigate(CREATE_QUIZ, {
      state: { lessonId: lessonId, quizId: quizId },
    });
  };

  return (
    <>
      <div className="overideLessons">
        <div className="formContainer">
          <div className="title">
            {edit ? (
              <div className="heading_style">
                <h1>Update Lesson</h1>
              </div>
            ) : (
              <div className="heading_style">
                <h1>Create Lesson</h1>
              </div>
            )}
          </div>
          <div className="addLessonBoxContainer">
            <div className="addlessonBox">
              <Grid>
                {isShowClient && ( //TODO
                  <Grid container>
                    <Grid item lg={6} xs={12} sm={12} md={4} mt={2}>
                      <div className="typeTxt">
                        <Checkbox
                          checked={checkBox}
                          onChange={() => {
                            setcheckBox(!checkBox);
                            setFormValue({
                              ...formValue,
                              categoryId: "",
                            });
                          }}
                          label={"Is Global"}
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
                <Grid item container mt={4} lg={12} spacing={1}>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabels label="Category" isRequired={true} />
                      <SelectMenu
                        listData={categoryListData?.categoryList}
                        value={formValue.categoryId}
                        isRequired={true}
                        placeholder="Select Category"
                        onchangehandler={(e) =>
                          setFormValue({
                            ...formValue,
                            categoryId: e.target.value,
                          })
                        }
                        isError={!!errors?.categoryId ?? false}
                        errorMsg={errors?.categoryId ?? ""}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabels
                        label={"Completion Time Minute"}
                        isRequired={true}
                      />

                      <InputField
                        fullWidth={true}
                        disabled={false}
                        type="text"
                        placeholder="Enter in Minutes"
                        value={formValue.completionTimeMin}
                        onChange={(event, value) =>
                          handleMinsonChange(event, value)
                        }
                        isError={!!errors?.completionTimeMin ?? false}
                        errorMsg={errors?.completionTimeMin ?? ""}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid lg={12} xs={12} sm={12} spacing={2} mt={3}>
                  <FormDropZone
                    label={"Add Lesson Cover Image"}
                    paraText1={"Drop the files here ..."}
                    paraText2={"Drop Images here, "}
                    paraText3={"or Browse"}
                    handleFileChange={(files, uploadfile) => {
                      setAddPhoto(files);
                      setImages(uploadfile);
                    }}
                    deleteImageURL={(url) => handleDeleteImageURL(url)}
                    isDynamics={true}
                    isRequired={true}
                    fileType={10}
                    isMuliple={false}
                    imageUrl={addPhoto}
                    id="addPhoto"
                    key="addPhoto"
                    isDoc={false}
                    isImage={true}
                    allFiles={false}
                  />
                </Grid>
              </Grid>
              <Grid mt={1.5} spacing={2} lg={12}>
                <FormControl fullWidth>
                  <InputField
                    isRequired={true}
                    fullWidth={true}
                    disabled={false}
                    type="text"
                    label="Title"
                    placeholder="Enter title text here..."
                    value={formValue.title}
                    onChange={(event, value) => handleTitleChange(event, value)}
                    isError={!!errors?.title ?? false}
                    errorMsg={errors?.title ?? ""}
                  />
                </FormControl>
              </Grid>

              <Grid mt={3} spacing={2} lg={12}>
                <FormLabels label={"Description"} />
                <TextArea
                  name={"issuefound"}
                  placeholder="Add lesson description text here..."
                  value={formValue.description}
                  onChange={(event, value) => handleDescChange(event, value)}
                />
              </Grid>
              {sectionList?.map((singleSection, index) => (
                <Section
                  key={index + 1}
                  keyNo={index + 1}
                  singleSection={singleSection}
                  handleOnChangeSectionForm={handleOnChangeSectionForm}
                  isErrorSectionName={singleSection.isErrorSectionName}
                  isErrorStatement={singleSection.isErrorStatement}
                  errorSectionNameMsg={singleSection.errorSectionNameMsg}
                  errorStatementMsg={singleSection.errorStatementMsg}
                  id={singleSection.id}
                  sectionImageUpload={(photo, id) =>
                    sectionImageUpload(photo, id, singleSection)
                  }
                  sectionFileUploads={(photo, id) =>
                    sectionFileUploads(photo, id, singleSection)
                  }
                  cancelSectionList={(id) => cancelSectionList(id)}
                  deleteImageURL={(url) => handleDeleteImageURLs(url)}
                  deleteUrlURL={(url) => handleDeleteUrlURLs(url)}
                />
              ))}
              <div className="addBox">
                <Button
                  variantOutlineSections={true}
                  disabled={sectionList.length === 4 ? true : false}
                  label=" Add New Section"
                  onClick={handleOnclickSections}
                />
              </div>

              <Grid container textAlign="left" alignItems="center">
                <Grid container mt={7}>
                  {edit === "edit" && (
                    <Grid container textAlign="left" alignItems="left">
                      <Grid md={3}>
                        <Button
                          startIcon={<AddIcon />}
                          varientContained={true}
                          label={
                            editLessonData?.quizId > 0
                              ? "Edit Quiz"
                              : "Create Quiz"
                          }
                          onClick={() =>
                            handleViewCreateQuiz(
                              editLessonData?.id,
                              editLessonData?.quizId
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid item mb={3} container mt={1} className="lessonBtn">
                    <Grid
                      container
                      textAlign="right"
                      alignItems="center"
                      className="addlessonButton"
                    >
                      <Grid item lg={4} mt={1} textAlign="center">
                        <Button
                          varientContained={true}
                          disabled={isSubmit ? true : false}
                          label={edit === "edit" ? "Update" : "Create Lesson"}
                          onClick={(e) =>
                            formHandler(
                              e,
                              edit === "edit" ? "update" : "submit"
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddLessons;
