import { roleType } from "../constants/roleType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "role",
  isError: false,
  isLoading: false,
};

export const creatRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case roleType.POST_ROLE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case roleType.POST_ROLE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case roleType.POST_ROLE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case roleType.CLEAR_ROLE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateRoleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case roleType.POST_UPDATE_ROLE_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case roleType.POST_UPDATE_ROLE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case roleType.POST_UPDATE_ROLE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case roleType.CLEAR_ROLE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getRoleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case roleType.GET_ROLE_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case roleType.GET_ROLE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case roleType.GET_ROLE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const creatAssignRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case roleType.POST_ASSIGN_ROLE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case roleType.POST_ASSIGN_ROLE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case roleType.POST_ASSIGN_ROLE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getRoleListDetsilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case roleType.GET_ROLE_LIST_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case roleType.GET_ROLE_LIST_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case roleType.GET_ROLE_LIST_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

      case roleType.CLEAR_ROLE:
        return {
          ...state,
          isError: true,
          isLoading: false,
          data: "",
        };

    default:
      return state;
  }
};

export const deleteRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case roleType.DELETE_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case roleType.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case roleType.DELETE_ROLE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getUserInRoleList = (state = initialState, action) => {
  switch (action.type) {
    case roleType.GET_USER_IN_ROLE_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case roleType.GET_USER_IN_ROLE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case roleType.GET_USER_IN_ROLE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};