import React, { useEffect } from "react";
import JhaReport from "../component/jhaReport";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { API_STATUS } from "../../../constant/constant";
import Loader from "../../../library/common/Loader";

function JhaReportContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const conductId = location?.state?.id;
  
  const getJhaConductDetailsData = useSelector(
    (state) => state?.getJhaConductDetailsData?.data
  );
  const showLoader = useSelector(
    (state) => state?.getJhaConductDetailsData?.isLoading
  );

  const isSuccess = useSelector(
    (state) => state.getJhaConductDetailsData.status === API_STATUS.SUCCESS
  );

  useEffect(() => {
    dispatch(jhaAction.getJhaConductDetails(conductId));
  }, [dispatch]);

  return (
    <>
      {showLoader && <Loader />}
      {isSuccess && (
        <JhaReport
          getJhaConductDetailsData={getJhaConductDetailsData}
          conductId={conductId}
        />
      )}
    </>
  );
}

export default JhaReportContainer;
