import { dashboardType } from "../constants/dashboardType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "DashBoard",
  isError: false,
  isLoading: false,
};

export const updateUserDashboardCartReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case dashboardType.UPDATE_USER_DASHBOARD_CART_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case dashboardType.UPDATE_USER_DASHBOARD_CART_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case dashboardType.UPDATE_USER_DASHBOARD_CART_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getDashboardCartListReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardType.GET_DASHBOARD_CART_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case dashboardType.GET_DASHBOARD_CART_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case dashboardType.GET_DASHBOARD_CART_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const getUserDashboardCartListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case dashboardType.GET_USER_DASHBOARD_CART_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case dashboardType.GET_USER_DASHBOARD_CART_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case dashboardType.GET_USER_DASHBOARD_CART_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
