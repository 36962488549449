import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./commonObservationReport.scss";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import {
  INSPECTION_REPORT,
  OPEN_ITEMS_LIST,
} from "../../../constant/routeContant";
import { useSelector } from "react-redux";
import { GET_COUNT_TYPE, LNG_ID } from "../../../constant/constant";
import parse from "html-react-parser";
import TextArea from "../../../library/custom/textArea/TextArea";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { useNavigate } from "react-router-dom";
import FormDropBox from "../../../library/custom/formDropBox/FormDropBox";
import ViewImages from "../../incidents/view-incident-report/common/viewImages/viewImages";
import { RemoveRedEye } from "@mui/icons-material";
import FormDropZone from "../../../library/dropZone/FormDropZone";
const ReprimandReportView = ({
  reprimandDetails,
  InspectionOpenItemDetail,
  origin,
  observationStatusLog,
  pageTitle,
  closeInspectionReprimand,
  closeReprimandReprimand,
  inspectionId,
  getCount,
  involvedPartyName,
  involvedPartyId
}) => {
  const [closesFilesData, setCloseFilesData] = useState("");
  const [closesFilesData1, setCloseFilesData1] = useState("");
  const [isCorrectiveInspection, setIsCorrecticeInspection] = useState(false);
  const [isCorrectiveReprimand, setIsCorrectiveReprimand] = useState(false);
  //inspection========

  const [correctiveReprimand, setCorrectiveReprimand] = useState("");
  const [correctiveInspection, setCorrectiveInspection] = useState("");
  const insInvolvedUserId =
    InspectionOpenItemDetail?.listInvolvedParty[0]?.userId;

  const insComment = InspectionOpenItemDetail?.listInvolvedParty[0]?.note;
  const insCloseMedia = InspectionOpenItemDetail?.listInvolvedParty[0]
    ?.closedFiles
    ? InspectionOpenItemDetail?.listInvolvedParty[0]?.closedFiles[0]?.filePath?.split(
        "|"
      )
    : "";

  const inspectionViolationCounts = useSelector(
    (state) => state.getInspectionViolationCounts
  );

  function insMapData() {
    let errors = false;
    if (correctiveInspection?.length === 0) {
      setIsCorrecticeInspection(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const inspectionReprimandData = {
      id: inspectionId,
      type: GET_COUNT_TYPE.REPRIMAND,
      involvedUserId: involvedPartyId,
      isEscalateToReprimand: false,
      files: [closesFilesData1?.fileId ? closesFilesData1?.fileId : 0],
      note: correctiveInspection,
      userId: 0,
    };
    return inspectionReprimandData;
  }
  const imageSize = {
    size: "small",
  };
  const closeInsReprimand = () => {
    const InsReprimandSubmitData = insMapData();
    if (!InsReprimandSubmitData) {
    } else {
      closeInspectionReprimand(InsReprimandSubmitData);
    }
  };
  const handleFileChange1 = (files, single) => {
    setCloseFilesData1({ fileId: single[0], filePath: files });
  };

  const handleDeleteImageURL1 = (url) => {
    setCloseFilesData1([]);
  };
  // reprimand==============================
  const reprimandUserId = reprimandDetails?.listInvolvedParty[0]?.userId;
  const reprimandInvolvedParty =
    reprimandDetails?.listInvolvedParty[0]?.involvedUserId;

  const reprimandCloseNote = reprimandDetails?.listInvolvedParty[0]?.note;
  const reprimandCloseMedia = reprimandDetails?.listInvolvedParty[0]
    ?.closedFiles
    ? reprimandDetails?.listInvolvedParty[0]?.closedFiles[0]?.filePath?.split(
        "|"
      )
    : "";

  function mappedData() {
    let errors = false;
    if (correctiveReprimand?.length === 0) {
      setIsCorrectiveReprimand(true);
      errors = true;
    }

    if (errors) {
      return false;
    }

    const closeReprimandData = {
      id: inspectionId,
      involvedUserId: involvedPartyId,
      files: [closesFilesData?.fileId ? closesFilesData?.fileId : 0],
      note: correctiveReprimand,
      userId: reprimandInvolvedParty,
    };
    return closeReprimandData;
  }

  const closeReprimand = () => {
    const ReprimandSubmitData = mappedData();
    if (!ReprimandSubmitData) {
    } else {
      closeReprimandReprimand(ReprimandSubmitData);
    }
  };

  const handleFileChange = (files, single) => {
    setCloseFilesData({ fileId: single[0], filePath: files });
  };

  const handleDeleteImageURL = (url) => {
    setCloseFilesData([]);
  };
  const data = reprimandDetails;
  const InsReCount = {
    id: insInvolvedUserId,
    type: GET_COUNT_TYPE?.REPRIMAND,
    lngId: LNG_ID,
  };
  const reprimandCount = {
    id: reprimandUserId,
    type: GET_COUNT_TYPE?.REPRIMAND,
    lngId: LNG_ID,
  };

  useEffect(() => {
    if (origin === "Reprimand") {
      getCount(reprimandCount);
    }
    if (origin === "Inspections" || origin === "Inspection") {
      getCount(InsReCount);
    }
  }, [insInvolvedUserId, reprimandUserId]);

  const openItemBackBtn = {
    title: "Back to Open Items",
    route: OPEN_ITEMS_LIST,
  };
  const navigate = useNavigate();
  const inspectionDetails = (id) => {
    navigate(INSPECTION_REPORT, {
      state: { reportId: InspectionOpenItemDetail?.inspectionId },
    });
  };

  return (
    <div className="observationOveride">
      <div className="commonOveride">
        <Box className="commoninfoWrap">
          <Box className="commoninfoBox">
            <Box className="commoninfoleft">
              <Typography className="titleViolation">Reprimand</Typography>
              {origin === "Reprimand" ? (
                <Typography className="reportid" mt={4}>
                  Reprimand ID: <span>{data?.inspectionOrReprimandId}</span>
                </Typography>
              ) : (
                <Typography
                  className="reportid"
                  mt={4}
                  onClick={() => inspectionDetails()}
                >
                  Inspection ID:{" "}
                  <span>{InspectionOpenItemDetail?.inspectionId}</span>
                  <span className="redirectPage">
                    {" "}
                    <RemoveRedEye />
                  </span>
                </Typography>
              )}

              {origin === "Inspections" || origin === "Inspection" ? (
                <Typography className="CaseId" mt={4}>
                  Reprimand ID:{" "}
                  <span>
                    {InspectionOpenItemDetail?.inspectionOrReprimandId}
                  </span>
                </Typography>
              ) : (
                ""
              )}
              {origin === "Reprimand" ? (
                <Box className="columns" mt={4}>
                  <Typography className="listView" style={{ display: "flex" }}>
                    Involved Party:
                    <span className="font-w400">
                      {involvedPartyName}
                    </span>
                  </Typography>
                  <Typography className="listView">
                    Corrective Party:
                    <span className="font-w400">
                      {data?.itemRespOrCorrecingPartyName}
                    </span>
                  </Typography>
                </Box>
              ) : (
                <Box className="columns" mt={4}>
                  <Typography className="listView" style={{ display: "flex" }}>
                    Involved Party:
                    <span className="font-w400">
                      {involvedPartyName}
                    </span>
                  </Typography>
                  <Typography className="listView">
                    Corrective Party:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.itemRespOrCorrecingPartyName}
                    </span>
                  </Typography>
                </Box>
              )}
              <Box className="columns">
                <Typography className="listView">
                  Date of Reprimand:
                  {origin === "Reprimand" ? (
                    <span className="font-w400">{data?.inspectionDate}</span>
                  ) : (
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.inspectionDate}
                    </span>
                  )}
                </Typography>
                <Typography className="listView">
                  Time of Reprimand:
                  {origin === "Reprimand" ? (
                    <span className="font-w400">{data?.inspectionTime}</span>
                  ) : (
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.inspectionTime}
                    </span>
                  )}
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  Location:
                  {origin === "Reprimand" ? (
                    <span className="font-w400">{data?.location}</span>
                  ) : (
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.location}
                    </span>
                  )}
                </Typography>
              </Box>
              {origin === "Reprimand" ? (
                ""
              ) : (
                <Box className="columns">
                  <Typography className="listView">
                    Asset Type:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.assetType === 1
                        ? "Project"
                        : InspectionOpenItemDetail?.assetType === 3
                        ? "Vehicle"
                        : InspectionOpenItemDetail?.assetType === 2
                        ? "Equipment"
                        : InspectionOpenItemDetail?.assetType === 4
                        ? "Custom Assets"
                        : ""}
                    </span>
                  </Typography>
                  <Typography className="listView">
                    {InspectionOpenItemDetail?.assetType === 1
                      ? "Project"
                      : InspectionOpenItemDetail?.assetType === 3
                      ? "Vehicle"
                      : InspectionOpenItemDetail?.assetType === 2
                      ? "Equipment"
                      : InspectionOpenItemDetail?.assetType === 4
                      ? "Custom Assets"
                      : ""}{" "}
                    :
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.assetName}
                    </span>
                  </Typography>
                </Box>
              )}

              <Box className="columns">
                {origin === "Reprimand" ? (
                  ""
                ) : (
                  <Typography className="listView">
                    Category:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.catName}
                    </span>
                  </Typography>
                )}

                {origin === "Reprimand" ? (
                  ""
                ) : (
                  <Typography className="listView">
                    Sub Category:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.subCatName}
                    </span>
                  </Typography>
                )}
              </Box>

              {origin === "Reprimand" ? (
                ""
              ) : (
                <Typography className="listView">
                  Question
                  {" " + InspectionOpenItemDetail?.questionSequenceNumber}:
                  <span className="blockView">
                    {parse(unescape(InspectionOpenItemDetail?.questionName))}
                  </span>{" "}
                </Typography>
              )}

              <Typography className="listView  ">
                Issue:
                {origin === "Reprimand" ? (
                  <span className="blockView">
                    {parse(unescape(data?.issue))}
                  </span>
                ) : (
                  <span className="blockView">
                    {parse(unescape(InspectionOpenItemDetail?.issue))}
                  </span>
                )}
              </Typography>

              <Typography className="listView ">
                Abadement Notes:
                {origin === "Reprimand" ? (
                  <span className="blockView">
                    {parse(unescape(data?.abatementNotes))}
                  </span>
                ) : (
                  <span className="blockView">
                    {parse(unescape(InspectionOpenItemDetail?.abatementNotes))}
                  </span>
                )}
              </Typography>

              <Typography className="listView">
                Action:
                {origin === "Reprimand" ? (
                  <span className="blockView">
                    {parse(unescape(data?.actionTaken))}
                  </span>
                ) : (
                  <span className="blockView">
                    {parse(unescape(InspectionOpenItemDetail?.actionTaken))}
                  </span>
                )}
              </Typography>
              {observationStatusLog === "Closed" && origin === "Reprimand" ? (
                <>
                  <Typography className="listView">
                    Comment:
                    <span className="blockView">{reprimandCloseNote}</span>
                  </Typography>
                  <Typography className="listView desNone">
                    Media:
                    <span className="blockView">
                      <ViewImages
                        image={reprimandCloseMedia}
                        imageSize={imageSize}
                      />
                    </span>
                  </Typography>
                </>
              ) : observationStatusLog === "Closed" &&
                (origin === "Inspection" || origin === "Inspections") ? (
                <>
                  <Typography className="listView">
                    Comment:
                    <span className="blockView">{insComment}</span>
                  </Typography>
                  <Typography className="listView desNone">
                    Media:
                    <span className="blockView">
                      <ViewImages image={insCloseMedia} imageSize={imageSize} />
                    </span>
                  </Typography>
                </>
              ) : observationStatusLog === "Open" &&
                (origin === "Inspection" ||
                  origin === "Inspections" ||
                  origin === "Reprimand") ? (
                ""
              ) : (
                ""
              )}
            </Box>

            <Box className="commoninfoRight">
              <Grid mt={4}>
                <Typography className="incstatus">Reprimand Status</Typography>
                {origin === "Reprimand" && data?.status === "Open" ? (
                  <Button
                    variant="contained"
                    className="statusbtnOpen cursonDefault"
                  >
                    {data?.status}
                  </Button>
                ) : origin === "Reprimand" && data?.status === "Close" ? (
                  <Button
                    variant="contained"
                    className="statusbtn cursonDefault"
                  >
                    {data?.status}
                  </Button>
                ) : observationStatusLog === "Open" ? (
                  <Button
                    variant="contained"
                    className="statusbtnOpen cursonDefault"
                  >
                    {observationStatusLog}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="statusbtn cursonDefault"
                  >
                    {observationStatusLog}
                  </Button>
                )}
              </Grid>
              <Grid mt={4}>
                <Typography className="incstatus">
                  Previous Reprimand
                </Typography>
                <Button
                  variant="contained"
                  className="reprimandstatusbtn"
                  style={{ marginLeft: "31px" }}
                >
                  {inspectionViolationCounts?.data?.result >= 1
                    ? inspectionViolationCounts?.data?.result
                    : 0}
                </Button>
              </Grid>
            </Box>
          </Box>
        </Box>

        {observationStatusLog === "Open" && origin === "Reprimand" ? (
          <Typography className="correctivemeasures desNone" mt={4}>
            <Grid item md={12} className="closedItemDropboxs">
              <FormLabels label="Comments" isRequired={true} />
              <TextArea
                placeholder={"Enter Here"}
                value={correctiveReprimand}
                onChange={(e) => {
                  if (
                    e.target.value === " " &&
                    correctiveReprimand.trim() === ""
                  )
                    setCorrectiveReprimand("");
                  else setCorrectiveReprimand(e.target.value);
                  setIsCorrectiveReprimand(false);
                }}
              />
              {isCorrectiveReprimand ? (
                <div className="newErrorMsg">
                  Please Select this required field
                </div>
              ) : (
                ""
              )}
              <FormDropZone
                label={""}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                handleFileChange={handleFileChange}
                fileType={10}
                imageUrl={closesFilesData?.filePath}
                deleteImageURL={(url) => handleDeleteImageURL(url)}
                id="addClosed"
                key="addClosed"
                isMultipleIcon={true}
                isImage={false}
                allImages={true}
              />
            </Grid>
          </Typography>
        ) : (origin === "Inspections" || origin === "Inspection") &&
          observationStatusLog === "Open" ? (
          <Typography className="correctivemeasures desNone" mt={4}>
            <Grid item md={12} className="closedItemDropboxs">
              <FormLabels label="Comments" isRequired={true} />
              <TextArea
                placeholder={"Enter Here"}
                value={correctiveInspection}
                onChange={(e) => {
                  if (
                    e.target.value === " " &&
                    correctiveInspection.trim() === ""
                  )
                    setCorrectiveInspection("");
                  else setCorrectiveInspection(e.target.value);
                  setIsCorrecticeInspection(false);
                }}
                isError={isCorrectiveInspection}
              />
              {isCorrectiveInspection ? (
                <div className="newErrorMsg">
                  Please Select this required field
                </div>
              ) : (
                ""
              )}
              <FormDropZone
                label={""}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                handleFileChange={handleFileChange1}
                fileType={10}
                imageUrl={closesFilesData1?.filePath}
                deleteImageURL={(url) => handleDeleteImageURL1(url)}
                id="addClosed"
                key="addClosed"
                isMultipleIcon={true}
                isImage={false}
                allImages={true}
              />
            </Grid>
          </Typography>
        ) : (
          ""
        )}

        <Box className="escalatereprimand">
          <Grid mt={4}>
            {pageTitle === "Item Management" ||
            origin === "Inspection" ||
            origin === "Inspections" ||
            origin === "Reprimand" ? (
              <BackBtn dataBack={openItemBackBtn} className="equipmentBack" />
            ) : (
              ""
            )}
          </Grid>
          <Grid mt={4} style={{ marginRight: "-240px" }}>
            {observationStatusLog === "Open" && origin === "Reprimand" ? (
              <Button
                varientContained={true}
                className="closereprimandbtn"
                label=" Close Item"
                onClick={closeReprimand}
              >
                Close Item
              </Button>
            ) : (origin === "Inspections" || origin === "Inspection") &&
              observationStatusLog === "Open" ? (
              <Button
                varientContained={true}
                className="closereprimandbtn"
                label=" Close Item"
                onClick={closeInsReprimand}
              >
                Close Item
              </Button>
            ) : observationStatusLog === "Closed" ? (
              ""
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default ReprimandReportView;
