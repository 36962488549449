import { SwCommonType } from "../types/swCommonType";
import { API_STATUS } from "../../../constant/constant";

const initialState = {
  flow: "GetReferenceData",
  isError: false,
  isLoading: false,
};

export const getSwReferenceDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwCommonType.GET_SW_REFERENCE_DATA_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwCommonType.GET_SW_REFERENCE_DATA_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwCommonType.GET_SW_REFERENCE_DATA_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getSwProjectSearchListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwCommonType.GET_PROJECT_SEARCH_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwCommonType.GET_PROJECT_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwCommonType.GET_PROJECT_SEARCH_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getSwContactSearchListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwCommonType.GET_CONTACT_SEARCH_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwCommonType.GET_CONTACT_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwCommonType.GET_CONTACT_SEARCH_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
