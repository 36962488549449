export const utilityDamageType = {
  POST_UTILITY_DAMAGE_PENDING:
    "[POST_UTILITY_DAMAGE_PENDING][REQUEST] Try To create Utility Damage",
  POST_UTILITY_DAMAGE_FAILURE:
    "[POST_UTILITY_DAMAGE_FAILURE][RESPONSE] Utility Damage Failed",
  POST_UTILITY_DAMAGE_SUCCESS:
    "[POST_UTILITY_DAMAGE_SUCCESS][RESPONSE] Utility Damage Successfull",
  PUT_UTILITY_DAMAGE_PENDING:
    "[PUT_UTILITY_DAMAGE_PENDING][REQUEST] Try To Update Utility Damage",
  PUT_UTILITY_DAMAGE_FAILURE:
    "[PUT_UTILITY_DAMAGE_FAILURE][RESPONSE] Updated Utility Damage Failed",
  PUT_UTILITY_DAMAGE_SUCCESS:
    "[PUT_UTILITY_DAMAGE_SUCCESS][RESPONSE] Update Utility Damage Successfull",
  GET_UTILITY_DAMAGE_PENDING:
    "[GET_UTILITY_DAMAGE_PENDING][REQUEST] Try To get Utility Damage",
  GET_UTILITY_DAMAGE_FAILURE:
    "[GET_UTILITY_DAMAGE_FAILURE][RESPONSE] Retrive Utility Damage Failed",
  GET_UTILITY_DAMAGE_SUCCESS:
    "[GET_UTILITY_DAMAGE_SUCCESS][RESPONSE] Retrive Utility Damage Successfull",
  CLEAR_UTILITY_DAMAGE: "[CLEAR_UTILITY_DAMAGE][REQUEST] Clear Utility Damage",
  UTILITY_DAMAGE_PAGE: "UtilityDamagePage",
};
