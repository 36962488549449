import React, { useEffect, useState } from "react";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import Buttons from "../../../../library/custom/button/Button";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import NormalGrid from "../../../../library/custom/gridTable/NormalGrid";
import { useNavigate } from "react-router-dom";
import { ADMIN_VIEW_PROCORE_PROJECT } from "../../../../constant/routeContant";
import { ORG_ID } from "../../../../constant/constant";

function ProcoreProjectList({
  getClientDetsilsData,
  isLoading,
  getSyncProcoreProjectsLists,
  setIsSuccess,
  syncProcoreProjectsListData,
}) {
  const navigate = useNavigate();
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  useEffect(() => {
    if (
      syncProcoreProjectsListData.status === "SUCCESS" &&
      syncProcoreProjectsListData?.data?.responseMessages?.responseCode ===
        "HBNG001"
    ) {
      setSelectionModel([]);
    }
  }, [syncProcoreProjectsListData]);

  const onClickSyncProcore = () => {
    let array = selectionModel;
    let integerValue = array[0];
    const data = {
      orgId: ORG_ID(),
      projectId: 0,
      procoreProjectId: integerValue,
    };
    getSyncProcoreProjectsLists(data);
    setIsSuccess(true);
  };
  const handleOnViewclick = (rows) => {
    navigate(ADMIN_VIEW_PROCORE_PROJECT, {
      state: { projectsDetails: rows.row, editMode: true },
    });
  };

  const credcloumns = [
    { field: "id", headerName: "Project ID", flex: 2.8 },
    { field: "name", headerName: "Project Name", flex: 4 },
    { field: "type_name", headerName: "Project Type", flex: 4 },
    { field: "status_name", headerName: "Status", flex: 4 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={
            getClientDetsilsData?.data?.listCompanyProject?.length ?? 0
          }
          numberOfItemsPerPage={100}
          incidentLists={getClientDetsilsData?.data?.listCompanyProject ?? []}
          fileNameXL={"ProcoreProjectsList"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={() => handleOnViewclick(rows)}
            view={true}
            viewLabel="View Project"
          />
        );
      },
    },
  ];
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">List of Projects in Procore</div>
          <div>
            <div className="filter_btn">
              <Buttons
                id="btnAddUsers"
                varientContained={true}
                label={"Sync Projects to SequenceXT"}
                onClick={onClickSyncProcore}
                disabled={selectionModel?.length == 0}
              ></Buttons>
            </div>
          </div>
        </div>
        <div className="grid_main_body">
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <NormalGrid
                getRowId={(r) => r.id}
                loading={isLoading}
                rowss={getClientDetsilsData?.data?.listCompanyProject ?? []}
                rowCount={
                  getClientDetsilsData?.data?.listCompanyProject?.length ?? 0
                }
                columnss={credcloumns}
                selectionModel={selectionModel}
                onSelectionModelChange={(e) => {
                  setSelectionModel(e);
                  const selectedIDs = new Set(e);
                  const selectedRows =
                    getClientDetsilsData?.data?.listCompanyProject?.filter(
                      (r) => selectedIDs.has(r.id)
                    );
                  setSelectedRows(selectedRows);
                }}
                disableMultipleSelection={true}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              ></NormalGrid>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProcoreProjectList;
