import React, { useEffect, useState } from "react";
import InspectionAuthority from "../components/InspectionAuthority";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as userAction from "../../../../redux/actions/userAction";
import { SW_INSPECTION_CONFIGURATION } from "../../../../constant/routeContant";
import { API_STATUS, PAGE_SIZE } from "../../../../constant/constant";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import * as swCommonAction from "../../../../redux/storm-water/actions/swCommonAction";
import Loader from "../../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";

function InspectionAuthorityContainer() {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const location = useLocation();

  const setupData = location?.state;

  const allUsers = useSelector((state) => state.allUsers);
  const InspectorListData = useSelector(
    (state) => state.getSwInspectorListData
  );

  const assignsSwInspectorData = useSelector(
    (state) => state.assignSwInspectorData
  );

  const deleteSwSecondaryInspectionDatas = useSelector(
    (state) => state.deleteSwSecondaryInspectionData
  );

  const deleteSwSecondaryInspectionDataStatus = useSelector(
    (state) => state.deleteSwSecondaryInspectionData.status
  );

  const assignSwInspectorDataStatus = useSelector(
    (state) => state.assignSwInspectorData.status
  );

  const getSwSecondaryInspectorListData = useSelector(
    (state) => state.getSwSecondaryInspectorListData
  );

  const isLoading =
    assignsSwInspectorData.isLoading ||
    deleteSwSecondaryInspectionDatas.isLoading;

  const [secondaryInspector, setSecondaryInspector] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);

  const searchSecondaryInspector = (searchText, type, divisionId) => {
    let filtered = secondaryInspector.filter((name) =>
      name.value.toLowerCase().includes(searchText.toLowerCase())
    );
    setselectedUsers(filtered);
  };

  const searchUsers = (
    searchText,
    type,
    divisionId,
    assetType,
    assetId,
    priviledgeId
  ) => {
    dispatch(
      userAction.searchAllUsers(
        searchText,
        type,
        divisionId,
        assetType,
        assetId,
        17
      )
    );
  };

  const refData = {
    flags: [4],
  };
  const getSwReference = useSelector((state) => state.getSwReference);
  const getSwReferenceData = (data) => {
    dispatch(swCommonAction.getSwReference(data));
  };

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    UserId: "",
    InspectionDayId: "",
    FrequencyId: "",
  };

  const SwInspectorList = (data) => {
    dispatch(swInspectionAction.getSwInspectorList(data));
  };

  const SwSecondaryInspectorList = (data) => {
    dispatch(swInspectionAction.getSwSecondaryInspectorList(data));
  };

  useEffect(() => {
    SwInspectorList(defaultData);
    getSwReferenceData(refData);
    setIsSuccess(false);
  }, []);

  useEffect(() => {
    if (
      assignSwInspectorDataStatus === API_STATUS.SUCCESS &&
      assignsSwInspectorData?.isLoading === false &&
      isSuccess &&
      assignsSwInspectorData?.data?.result === ""
    ) {
      setIsSuccess(false);
      toast("Inspector Assigned Successfully");
    }
  }, [assignSwInspectorDataStatus]);

  useEffect(() => {
    if (
      assignSwInspectorDataStatus === API_STATUS.SUCCESS &&
      assignsSwInspectorData?.isLoading === false &&
      isSuccess &&
      assignsSwInspectorData?.data?.result !== ""
    ) {
      setIsSuccess(false);
      toast(assignsSwInspectorData?.data?.result);
    }
  }, [assignSwInspectorDataStatus]);

  useEffect(() => {
    if (
      deleteSwSecondaryInspectionDataStatus === API_STATUS.SUCCESS &&
      deleteSwSecondaryInspectionDatas?.isLoading === false &&
      isSuccess
    ) {
      setIsSuccess(false);
      toast(" Secondary Inspector Deleted Successfully");
    }
  }, [deleteSwSecondaryInspectionDatas]);

  const assignSwInspectorDatas = (data) => {
    setIsSuccess(true);
    dispatch(swInspectionAction.assignSwInspector(data));
  };

  const deleteSecondaryInspectorList = (payload) => {
    setIsSuccess(true);
    dispatch(swInspectionAction.deleteSwSecondaryInspection(payload));
  };

  const dataBack = {
    title: "Back to Inspection Configuration",
    route: SW_INSPECTION_CONFIGURATION,
  };

  return (
    <>
      {isLoading && <Loader />}
      {/* {assignSwInspectorDataStatus === API_STATUS.SUCCESS &&
        assignsSwInspectorData?.data &&
        isSuccess &&
        toast("Inspector Assigned Successfully")} */}
      {/* {deleteSwSecondaryInspectionDataStatus === API_STATUS.SUCCESS &&
        deleteSwSecondaryInspectionDatas &&
        isSuccess &&
        toast(" Secondary Inspector Deleted Successfully")} */}
      <div>
        <ToastContainer />
      </div>
      <InspectionAuthority
        allUsers={allUsers?.data?.users}
        onSearch={searchUsers}
        isLoading={
          allUsers.isLoading ||
          InspectorListData.isLoading ||
          getSwSecondaryInspectorListData.isLoading
        }
        dataBack={dataBack}
        SwInspectorList={SwInspectorList}
        InspectorListData={InspectorListData}
        getSwReference={getSwReference?.data}
        assignSwInspectorDatas={assignSwInspectorDatas}
        SwSecondaryInspectorList={SwSecondaryInspectorList}
        getSwSecondaryInspectorListData={getSwSecondaryInspectorListData}
        deleteSecondaryInspectorList={deleteSecondaryInspectorList}
        secondaryInspector={secondaryInspector}
        setSecondaryInspector={setSecondaryInspector}
        selectedUsers={selectedUsers}
        searchSecondaryInspector={searchSecondaryInspector}
        setupData={setupData}
        assignsSwInspectorData={assignsSwInspectorData}
        assignSwInspectorDataStatus={assignSwInspectorDataStatus}
        deleteSwSecondaryInspectionDataStatus={
          deleteSwSecondaryInspectionDataStatus
        }
        deleteSwSecondaryInspectionDatas={deleteSwSecondaryInspectionDatas}
        setIsSuccess={setIsSuccess}
      />
    </>
  );
}

export default InspectionAuthorityContainer;
