import { signupType } from "../constants/signupType";

export const signup = (data) => ({
  type: signupType.POST_SIGNUP_PENDING,
  data,
});

export const signupSuccess = (data) => ({
  type: signupType.POST_SIGNUP_SUCCESS,
  data,
});

export const signupFailure = (error) => ({
  type: signupType.POST_SIGNUP_FAILURE,
  error,
});

export const clearErrors = (error = {}) => ({
  type: signupType.CLEAR_ERRORS,
  error,
});

//otp actions

export const otpsignup = (mobile, otp) => ({
  type: signupType.POST_OTPSIGNUP_PENDING,
  mobile,
  otp,
});

export const otpsignupSuccess = (data) => ({
  type: signupType.POST_OTPSIGNUP_SUCCESS,
  data,
});

export const otpsignupFailure = (error) => ({
  type: signupType.POST_OTPSIGNUP_FAILURE,
  error,
});

//Verify Mobile Actions
export const verifymobile = (data) => ({
  type: signupType.POST_VERIFYMOBILE_PENDING,
  data,
});

export const verifymobileSuccess = (data) => ({
  type: signupType.POST_VERIFYMOBILE_SUCCESS,
  data,
});

export const verifymobileFailure = (error) => ({
  type: signupType.POST_VERIFYMOBILE_FAILURE,
  error,
});

// update onboarding

export const updateOnboarding = (data) => ({
  type: signupType.PUT_ONBOARDING_PENDING,
  data,
});

export const updateOnboardingSuccess = (data) => ({
  type: signupType.PUT_ONBOARDING_SUCCESS,
  data,
});

export const updateOnboardingFailure = (error) => ({
  type: signupType.PUT_ONBOARDING_FAILURE,
  error,
});
