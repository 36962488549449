import React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CampaignIcon from "@mui/icons-material/Campaign";
import AssignmentIcon from "@mui/icons-material/Assignment";
import "./button.scss";
import commonImages from "../../../assets";

export default function Buttons({
  label,
  variantOutline,
  varientContained,
  varientText,
  onClick,
  varientTextGreen,
  disabled,
  primary,
  startIcon,
  type,
  redBg,
  id,
  variantOutlineSections,
  redButton,
  varientAddIconBlue,
  primaryalert,
  primaryassign,
  varientContainedExport,
  primaryalertAll,
  variantOutlineBorder = false,
  iconToggle = false,
  varientContainedGrey = false,
}) {
  return (
    <div className="overideBtn">
      {varientText && (
        <Button
          disabled={disabled}
          className="textButton"
          variant="text"
          onClick={onClick}
        >
          {label}
        </Button>
      )}
      {varientContained && (
        <Button
          id={id}
          disabled={disabled}
          className="containedButton"
          variant="contained"
          onClick={onClick}
          fullWidth={true}
          type={type}
        >
          {label}
        </Button>
      )}
      {variantOutline && (
        <Button
          disabled={disabled}
          variant="outlined"
          className="outlinedButton"
          onClick={onClick}
        >
          {label}
        </Button>
      )}
      {variantOutlineSections && (
        <Button
          disabled={disabled}
          variant="outlined"
          className="outlinedButton"
          onClick={onClick}
          startIcon={
            <AddIcon className={disabled ? "addIconGrey" : "addIconBtnsblue"} />
          }
        >
          {label}
        </Button>
      )}
      {varientTextGreen && (
        <Button
          variant="text"
          disabled={disabled}
          startIcon={
            <AddIcon className={disabled ? "addIconGrey" : "addIconBtn"} />
          }
          className="varientTextGreens"
          onClick={onClick}
        >
          {label}
        </Button>
      )}
      {primary && (
        <Button
          disabled={disabled}
          className={["primary_btn_style", redBg && "red_bg"].join(" ")}
          variant="text"
          onClick={onClick}
          startIcon={startIcon}
        >
          {label}
        </Button>
      )}
      {primaryalert && (
        <Button
          disabled={disabled}
          className={["primaryalert_btn_style", redBg && "red_bg"].join(" ")}
          variant="text"
          onClick={onClick}
          startIcon={<CampaignIcon className="addIconalertBtns" />}
        >
          {label}
        </Button>
      )}
      {primaryalertAll && (
        <Button
          disabled={disabled}
          className={["primaryalertAll_btn_style", redBg && "red_bg"].join(" ")}
          variant="text"
          onClick={onClick}
          startIcon={<CampaignIcon className="addIconalertBtns" />}
        >
          {label}
        </Button>
      )}
      {primaryassign && (
        <Button
          disabled={disabled}
          className={["primaryassign_btn_style", redBg && "red_bg"].join(" ")}
          variant="text"
          onClick={onClick}
          startIcon={<AssignmentIcon className="addIconalertBtns" />}
        >
          {label}
        </Button>
      )}
      {redButton && (
        <Button
          id={id}
          disabled={disabled}
          className="redBtn"
          variant="contained"
          onClick={onClick}
          fullWidth={true}
          type={type}
        >
          {label}
        </Button>
      )}
      {varientAddIconBlue && (
        <Button
          id={id}
          disabled={disabled}
          className="containedButton"
          variant="contained"
          onClick={onClick}
          startIcon={
            iconToggle ? (
              <RemoveIcon className="addIconBtns" />
            ) : (
              <AddIcon className="addIconBtns" />
            )
          }
          fullWidth={true}
          type={type}
        >
          {label}
        </Button>
      )}

      {varientContainedExport && (
        <Button
          id={id}
          disabled={disabled}
          className="containedButtonExport"
          variant="contained"
          onClick={onClick}
          fullWidth={true}
          type={type}
          startIcon={<img src={commonImages.downloadcategoryIcon} />}
        >
          {label}
        </Button>
      )}

      {variantOutlineBorder && (
        <Button
          disabled={disabled}
          variant="outlined"
          className="outlinedButton"
          onClick={onClick}
          startIcon={
            iconToggle ? (
              <RemoveIcon
                className={disabled ? "addIconGrey" : "addIconBtnsblue"}
              />
            ) : (
              <AddIcon
                className={disabled ? "addIconGrey" : "addIconBtnsblue"}
              />
            )
          }
        >
          {label}
        </Button>
      )}
      {varientContainedGrey && (
        <Button
          id={id}
          disabled={disabled}
          className="containedButtonGrey"
          variant="contained"
          onClick={onClick}
          fullWidth={true}
          type={type}
        >
          {label}
        </Button>
      )}
    </div>
  );
}
