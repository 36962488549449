import { getApiUrl } from "../config";
import { post, get, deletes } from "./axiosRequest";
import { ORG_ID } from "../constant/constant";

export const createToolBox = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Toolbox/CreateSaftyMeeting`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const getToolBoxTalkList = async ({ data }) => {
  const { pageNumber, pageSize, search, categoryIds, status, userId, tabId } =
    data;
  let url = `${
    getApiUrl().lmsApiUrl
  }/Toolbox/GetScheduleSaftyMeetingList?pageSize=${pageSize}`;
  if (pageNumber) {
    url += `&page=${pageNumber}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  if (categoryIds) {
    url += `&categoryIds=${categoryIds}`;
  }
  if (userId) {
    url += `&userId=${userId}`;
  }
  if (tabId) {
    url += `&tabId=${tabId}`;
  }

  if (status) {
    if (status === 2) {
      url += `&status=0`;
    } else {
      url += `&status=${status}`;
    }
  }
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getAdminToolBoxTalkList = async ({ data }) => {
  const { pageNumber, pageSize, search, status, userId, categoryIds } = data;

  let url = `${
    getApiUrl().lmsApiUrl
  }/Toolbox/GetAdminSaftyMeetingList?pageSize=${pageSize}`;
  if (pageNumber) {
    url += `&page=${pageNumber}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  if (categoryIds) {
    url += `&categoryIds=${categoryIds}`;
  }
  if (userId) {
    url += `&userId=${userId}`;
  }
  if (status) {
    if (status === 2) {
      url += `&status=0`;
    } else {
      url += `&status=${status}`;
    }
  }
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getToolBoxTalkDetails = async ({ payload }) => {
  let url = `${
    getApiUrl().lmsApiUrl
  }/Toolbox/GetSaftyMeetingDetails?toolboxTalkId=${
    payload?.toolboxId
  }&OrgId=${ORG_ID()}`;
  if (payload?.assignInstructorsUsersId != undefined) {
    url += `&assignInstructorsUsersId=${payload?.assignInstructorsUsersId}`;
  }

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateToolBoxTalkList = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Toolbox/UpdateSaftyMeeting`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const deleleToolBoxTalk = async ({ payload }) => {
  const url = `${getApiUrl().lmsApiUrl}/Toolbox/DeleteSaftyMeeting/${
    payload?.toolboxTalkId
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
      // data,
    });
  } catch (err) {
    return err;
  }
};

export const GetSaftyMeetingSearchList = async ({ data }) => {
  const { search } = data;

  let url = `${getApiUrl().lmsApiUrl}/Toolbox/GetSaftyMeetingSearch`;

  if (search) {
    url += `?search=${search}`;
  }
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getProjectSearchList = async ({ data }) => {
  const { divisionId } = data;
  const url = `${getApiUrl().adminApiUrl}/Project/GetProjectSearch?divisionId=${
    divisionId ? divisionId : 0
  }`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const assignAdminToinstructorToolBox = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Toolbox/AdminAssignInstructors`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const assignInstructorToUserToolBox = async (data) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Toolbox/AssignSaftyMeetingByInstructors`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const assignSelfInstructorToUserToolBox = async (data) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Toolbox/AssignSelfSaftyMeetingByInstructors`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getScheduleSafetyMeetingDetails = async ({ payload }) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Toolbox/GetScheduleSaftyMeetingDetails?scheduleId=${
    payload?.scheduleId
  }&scheduleDate=${payload.scheduleDate}&userId=${payload?.userId}&assignType=${
    payload?.assignType
  }&projectId=${payload?.projectId ? payload?.projectId : 0}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const verifyAttendee = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Toolbox/VeirfyAttendance`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const getVerifyAttendeeList = async ({ data }) => {
  const {
    scheduleId,
    userType,
    scheduleDate,
    search,
    page,
    pageSize,
    userId,
    projectId,
  } = data;

  let url = `${getApiUrl().lmsApiUrl}/Toolbox/GetVeirfyAttendanceList?`;

  if (scheduleId) {
    url += `scheduleId=${scheduleId}`;
  }
  if (projectId) {
    url += `&projectId=${projectId}`;
  }
  if (scheduleDate) {
    url += `&scheduleDate=${scheduleDate}`;
  }
  if (userType >= 0) {
    url += `&userType=${userType}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  if (page) {
    url += `&page=${page}`;
  }
  if (pageSize) {
    url += `&pageSize=${pageSize}`;
  }
  if (userId) {
    url += `&userId=${userId}`;
  }

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleleScheduleToolBoxTalk = async ({ payload }) => {
  const url = `${getApiUrl().lmsApiUrl}/Toolbox/DeleteScheduleSaftyMeeting/${
    payload?.scheduleId
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const userMarkedCompleteToolBox = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Toolbox/UserMarkedCompleted`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const createExtraUser = async (data) => {
  const url = `${getApiUrl().lmsApiUrl}/Toolbox/AddExtraUser`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getExtraUserList = async () => {
  const url = `${getApiUrl().lmsApiUrl}/Toolbox/GetExtraUser`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getAllUsersProject = async (
  serachText,
  type = 0,
  searchProjectId,
  divisionId = 0
) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Project/GetUserSearch?orgId=${ORG_ID()}&search=${
    serachText ? serachText?.trim().toLowerCase() : ""
  }&userType=${type}&divisionId=${divisionId}&projectId=${searchProjectId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const gettoolboxAssignedUserStatusList = async ({ data }) => {
  const { scheduleId, scheduleDate, userId, flag } = data;

  let url = `${
    getApiUrl().lmsApiUrl
  }/Toolbox/GetScheduleSaftyMeetingCountDetails?`;
  if (scheduleId) {
    url += `scheduleId=${scheduleId}`;
  }
  if (scheduleDate) {
    url += `&scheduleDate=${scheduleDate}`;
  }
  if (userId) {
    url += `&userId=${userId}`;
  }
  if (flag) {
    url += `&flag=${flag}`;
  }

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const sendSafetyMeetingAlertMail = async ({ data }) => {
  const url = `${getApiUrl().lmsApiUrl}/Toolbox/SendSafetyMeetingAlertEmail`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
