import { FormControl, Grid, RadioGroup } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Buttons from "../../../../library/custom/button/Button";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import TextArea from "../../../../library/custom/textArea/TextArea";
import { InputField } from "../../../../library/custom/textBox/InputField";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import {
  DIVISION_ID,
  ORG_ID,
  USER_ID,
  USER_TYPE,
  VEHICLE_GRID_COLOUMNS,
  VEHICLE_TEMPLATE_URL,
} from "../../../../constant/constant";
import { useState } from "react";
import { dateValiadtor, getFormatedDate } from "../../../../utils/helper";
import { ADMIN_VEHICLES } from "../../../../constant/routeContant";
import UsersChip from "../../../../library/custom/usersChip/UsersChip";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const AddVehicle = ({
  onSubmit,
  division,
  vehicleCategory,
  allUsers,
  handleAllUserSearch,
  isLoading,
  userGroups,
  editMode,
  vehicleDetailsData,
  vehicleId,
  handleAllGroupSearch,
  notuniqueVehicleList,
  ismultipleUpload,
  handleonfcs,
}) => {
  useEffect(() => {
    if (vehicleDetailsData) {
      setIndividualUsersIds(
        ...individualUsersIds,
        vehicleDetailsData?.assignToUser?.map((e) => e.key)
      );
      if (vehicleDetailsData?.assignToUser?.length > 0) {
        setIndividualUsersList(
          ...individualUsersList,
          vehicleDetailsData?.assignToUser?.map((e) => e)
        );
      }
      setuserGroupIds(
        ...userGroupIds,
        vehicleDetailsData?.assignToGroup?.map((e) => e.key)
      );
      if (vehicleDetailsData?.assignToGroup?.length > 0) {
        setUsersGroupList(
          ...userGroupList,
          vehicleDetailsData?.assignToGroup?.map((e) => e)
        );
      }
      setInspectorIds(
        ...inspectorIds,
        vehicleDetailsData?.assignToInspector?.map((e) => e.key)
      );
      if (vehicleDetailsData?.assignToInspector?.length > 0) {
        setInspectorList(
          ...inspectorList,
          vehicleDetailsData?.assignToInspector?.map((e) => e)
        );
      }
      setsupervisorId(
        ...supervisorId,
        vehicleDetailsData?.assignToSupervisor?.map((e) => e.key)
      );
      if (vehicleDetailsData?.assignToSupervisor?.length > 0) {
        setsupervisoList(
          ...supervisoList,
          vehicleDetailsData?.assignToSupervisor?.map((e) => e)
        );
      }

      setContractorGroupsIds(
        ...contractorGroupsIds,
        vehicleDetailsData?.assignToSubContractor?.map((e) => e.key)
      );
      if (vehicleDetailsData?.assignToSubContractor?.length > 0) {
        setContractorGroupsList(
          ...contractorGroupsList,
          vehicleDetailsData?.assignToSubContractor?.map((e) => e)
        );
      }
      setFormValue({
        ...formValue,
        isActive: vehicleDetailsData.isActive == 1 ? "1" : "2",
        categoryId: vehicleDetailsData.categoryId,
        divisionIds: vehicleDetailsData.divisionId,
        division: vehicleDetailsData.divisionId,
        insuranceCarrier: vehicleDetailsData.insuranceCarrier,
        insuranceExpDate:
          vehicleDetailsData.insuranceExpDate === "01-01-0001" || null
            ? null
            : vehicleDetailsData.insuranceExpDate,
        makeAndModel: vehicleDetailsData.makeAndModel,
        note: vehicleDetailsData.note,
        regNumber: vehicleDetailsData.regNumber,
        supervisorId: vehicleDetailsData.supervisorId,
        tagExpDate:
          vehicleDetailsData.tagExpDate === "01-01-0001" || null
            ? null
            : vehicleDetailsData.tagExpDate,
        vehicalStartDate:
          vehicleDetailsData.vehicalStartDate === "01-01-0001" || null
            ? null
            : vehicleDetailsData.vehicalStartDate,
        vehicalEndDate:
          vehicleDetailsData.vehicalEndDate === "01-01-0001" || null
            ? null
            : vehicleDetailsData.vehicalEndDate,
        vinNumber: vehicleDetailsData.vinNumber,
        gvwr: vehicleDetailsData.gvwr,
        year: vehicleDetailsData.year,
        unitNumber: vehicleDetailsData.unitNumber,
        subContractor: "",
      });
    }
  }, [vehicleDetailsData]);
  const [formValue, setFormValue] = useState({
    organisationId: 1,
    division:
      DIVISION_ID() == "null"
        ? ""
        : DIVISION_ID() == 0
        ? "undefined"
        : DIVISION_ID(),
    regNumber: "",
    makeAndModel: "",
    unitNumber: "",
    createdBy: parseInt(USER_ID()),
    categoryId: "",
    supervisor: "",
    supervisorId: "",
    assignedTo: "",
    assignedUserId: "",
    subContractor: "",
    vinNumber: "",
    tagNumber: "",
    tagExpDate: new Date(),
    insuranceCarrier: "",
    insuranceExpDate: new Date(),
    vehicalStartDate: new Date(),
    vehicalEndDate: null,
    note: "",
    divisionIds:
      DIVISION_ID() == "null"
        ? ""
        : DIVISION_ID() == 0
        ? "undefined"
        : DIVISION_ID(),
    vehicleCategory: "",
    isActive: "1",
    gvwr: "",
    year: "",
  });
  const dataBack = {
    title: "Back to Vehicles",
    route: ADMIN_VEHICLES,
  };
  const [isregNumber, setisregNumber] = useState(false);
  const [isMakeandModel, setisMakeandModel] = useState(false);
  const [isYear, setisYear] = useState(false);
  const [isCategoryId, setisCategoryId] = useState(false);
  const [isVinNumber, setisVinNumber] = useState(false);
  const [isInsauranceCarrier, setisInsauranceCarrier] = useState(false);
  const [isTagExpDate, setisTagExpDate] = useState(false);
  const [isTagExpDateErrMsg, setisTagExpDateErrMSg] = useState(false);
  const [isInsauranceExpDate, setisInsauranceExpDate] = useState(false);
  const [isInsauranceExpDateErrMsg, setisInsauranceExpDateErrMsg] =
    useState(false);
  const [isVehicleStartDate, setisVehicleStartDate] = useState(false);
  const [isVehicleStartDateErrMsg, setisVehicleStartDateErrMsg] =
    useState(false);
  const [isVehicleEndDate, setisVehicleEndDate] = useState(false);
  const [isVehicleEndDateErrMsg, setisVehicleEndDateErrMsg] = useState(false);
  const [isDivisionId, setisDivisionId] = useState(false);
  const [isYearErrMsg, setIsYearErrMsg] = useState(false);

  function mappedData() {
    let errors = false;
    if (formValue?.regNumber?.length === 0) {
      setisregNumber(true);
      errors = true;
    }
    if (formValue?.makeAndModel?.length === 0) {
      setisMakeandModel(true);
      errors = true;
    }

    if (formValue?.categoryId?.length === 0) {
      setisCategoryId(true);
      errors = true;
    }
    if (formValue?.vinNumber?.length === 0) {
      setisVinNumber(true);
      errors = true;
    }
    if (formValue?.insuranceCarrier?.length === 0) {
      setisInsauranceCarrier(true);
      errors = true;
    }
    if (formValue?.tagExpDate === null) {
      setisTagExpDate(true);
      errors = true;

      setisTagExpDateErrMSg("Tag Expiration Date is required");
    }
    if (formValue.tagExpDate != null) {
      const validDate = dateValiadtor(getFormatedDate(formValue.tagExpDate));
      if (validDate === false) {
        setisTagExpDate(true);
        errors = true;
        setisTagExpDateErrMSg("Invalid Date");
      }
    }
    if (formValue?.insuranceExpDate === null) {
      setisInsauranceExpDate(true);
      errors = true;
      setisInsauranceExpDateErrMsg("Insurance Expiration Date is required");
    }
    if (formValue.insuranceExpDate != null) {
      const validDate = dateValiadtor(
        getFormatedDate(formValue.insuranceExpDate)
      );
      if (validDate === false) {
        setisInsauranceExpDate(true);
        errors = true;
        setisInsauranceExpDateErrMsg("Invalid Date");
      }
    }
    if (formValue?.vehicalStartDate === null) {
      setisVehicleStartDate(true);
      errors = true;
      setisVehicleStartDateErrMsg("Start Date is required");
    }
    if (formValue.vehicalStartDate != null) {
      const validDate = dateValiadtor(
        getFormatedDate(formValue.vehicalStartDate)
      );
      if (validDate === false) {
        setisVehicleStartDate(true);
        errors = true;
        setisVehicleStartDateErrMsg("Invalid Date");
      }
    }
    // if (formValue?.vehicalEndDate?.length === 0) {
    //   setisVehicleEndDate(true);
    //   errors = true;
    // }
    if (formValue.vehicalEndDate != null) {
      const validDate = dateValiadtor(
        getFormatedDate(formValue.vehicalEndDate)
      );
      if (validDate === false) {
        setisVehicleEndDate(true);
        errors = true;
        setisVehicleEndDateErrMsg("Invalid Date");
      }
    }
    if (formValue?.divisionIds?.length === 0) {
      setisDivisionId(true);
      errors = true;
    }
    if (formValue.year != null) {
      const validDate = dateValiadtor(getFormatedDate(formValue.year));
      if (validDate === false) {
        setisYear(true);
        errors = true;
        setIsYearErrMsg("Please enter this required field");
      }
    }
    if (errors) {
      return false;
    }

    let data;
    if (editMode)
      data = {
        organisationId: ORG_ID(),
        regNumber: formValue.regNumber?.trim(),
        makeAndModel: formValue.makeAndModel,
        unitNumber: formValue.unitNumber,
        divisionId: formValue.divisionIds,
        categoryId: formValue.categoryId,
        assignToSupervisor: supervisorId,
        vinNumber: formValue.vinNumber,
        tagNumber: formValue.tagNumber,
        tagExpDate: getFormatedDate(formValue.tagExpDate),
        insuranceCarrier: formValue.insuranceCarrier,
        insuranceExpDate: getFormatedDate(formValue.insuranceExpDate),
        vehicalStartDate:
          formValue.vehicalStartDate === null
            ? null
            : getFormatedDate(formValue.vehicalStartDate),
        vehicalEndDate:
          formValue.vehicalEndDate === null
            ? null
            : getFormatedDate(formValue.vehicalEndDate),
        note: formValue.note,
        assignToUser: individualUsersIds,
        assignToGroup: userGroupIds,
        assignToInspector: inspectorIds,
        vehicalId: vehicleId,
        isActive: formValue.isActive == "1" ? true : false,
        gvwr: formValue.gvwr,
        year:
          formValue.year.length > 4
            ? formValue.year.toString().slice(11, 15)
            : formValue.year,
        createdBy: formValue.createdBy,
        createdByApp: 0,
        assignToSubContractor: contractorGroupsIds,
      };
    else
      data = {
        organisationId: ORG_ID(),
        regNumber: formValue.regNumber?.trim(),
        makeAndModel: formValue.makeAndModel,
        unitNumber: formValue.unitNumber,
        createdBy: formValue.createdBy,
        createdByApp: 0,
        categoryId: formValue.categoryId,
        assignToSupervisor: supervisorId,
        vinNumber: formValue.vinNumber,
        tagNumber: formValue.tagNumber,
        tagExpDate: getFormatedDate(formValue.tagExpDate),
        insuranceCarrier: formValue.insuranceCarrier,
        insuranceExpDate: getFormatedDate(formValue.insuranceExpDate),
        vehicalStartDate: getFormatedDate(formValue.vehicalStartDate),
        vehicalEndDate:
          formValue.vehicalEndDate === null
            ? null
            : getFormatedDate(formValue.vehicalEndDate),
        note: formValue.note,
        assignToUser: individualUsersIds,
        assignToGroup: userGroupIds,
        assignToInspector: inspectorIds,
        divisionId: formValue.divisionIds,
        gvwr: formValue.gvwr,
        year: formValue.year.toString().slice(11, 15),
        assignToSubContractor: contractorGroupsIds,
      };
    return data;
  }
  const submitData = () => {
    let isError = false;

    const data = mappedData();
    if (!data) {
    } else {
      editMode ? onSubmit(data) : onSubmit([data]);
    }
  };

  const autoCompSupervisor = useRef(null);
  const autoCompIns = useRef(null);
  const autoCompUser = useRef(null);
  const autoCompGroup = useRef(null);
  const autoCompContractor = useRef(null);

  const [supervisoList, setsupervisoList] = useState([]);
  const [userGroupList, setUsersGroupList] = useState([]);
  const [individualUsersList, setIndividualUsersList] = useState([]);
  const [inspectorList, setInspectorList] = useState([]);
  const [contractorGroupsList, setContractorGroupsList] = useState([]);

  const [supervisor, setsupervisor] = useState(1);
  const [individualUsers, setIndividualUsers] = useState(1);
  const [usersGroup, setUsersGroup] = useState(1);
  const [inspector, setInspector] = useState(1);
  const [contractorGroups, setContractorGroups] = useState(1);

  const [supervisorId, setsupervisorId] = useState([]);
  const [userGroupIds, setuserGroupIds] = useState([]);
  const [individualUsersIds, setIndividualUsersIds] = useState([]);
  const [inspectorIds, setInspectorIds] = useState([]);
  const [contractorGroupsIds, setContractorGroupsIds] = useState([]);

  const deleteIndividualUsersData = (id) => {
    const updateIndividualUsersList = individualUsersList.filter(
      (list) => list.key !== id
    );
    const updateindividualUsersIds = individualUsersIds.filter(
      (list) => list !== id
    );
    setIndividualUsersList(updateIndividualUsersList);
    setIndividualUsersIds(updateindividualUsersIds);
  };

  const deleteusersGroupData = (id) => {
    const updateUserGroupList = userGroupList.filter((list) => list.key !== id);
    const updateUserGroupIds = userGroupIds.filter((list) => list !== id);
    setUsersGroupList(updateUserGroupList);
    setuserGroupIds(updateUserGroupIds);
  };

  const deleteContractorGroupsData = (id) => {
    const updatecontractorGroupsList = contractorGroupsList.filter(
      (list) => list.key !== id
    );
    const updateContractorGroupsIds = contractorGroupsIds.filter(
      (list) => list !== id
    );
    setContractorGroupsList(updatecontractorGroupsList);
    setContractorGroupsIds(updateContractorGroupsIds);
  };
  const addIndiVidualUserData = () => {
    if (!individualUsers.key) return;
    const index = individualUsersList.findIndex(
      (object) => object.key === individualUsers.key
    );
    if (index === -1) {
      setIndividualUsersIds([...individualUsersIds, individualUsers.key]);
      setIndividualUsersList([...individualUsersList, individualUsers]);
    }
    const ele = autoCompUser.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };
  const addUserGroupData = () => {
    if (!usersGroup.key) return;
    const index = userGroupList.findIndex(
      (object) => object.key === usersGroup.key
    );
    if (index === -1) {
      setuserGroupIds([...userGroupIds, usersGroup.key]);
      setUsersGroupList([...userGroupList, usersGroup]);
    }
    const ele = autoCompGroup.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const deleteInspectorData = (id) => {
    const updateInspectorList = inspectorList.filter((list) => list.key !== id);
    const updateInspectorIds = inspectorIds.filter((list) => list !== id);
    setInspectorList(updateInspectorList);
    setInspectorIds(updateInspectorIds);
  };
  const deleteSupervisorData = (id) => {
    const updateSupervisorList = supervisoList.filter(
      (list) => list.key !== id
    );
    const updateSupervisorIds = supervisorId.filter((list) => list !== id);
    setsupervisoList(updateSupervisorList);
    setsupervisorId(updateSupervisorIds);
  };

  const addInspectorData = () => {
    if (!inspector.key) return;

    const index = inspectorList.findIndex(
      (object) => object.key === inspector.key
    );
    if (index === -1) {
      setInspectorIds([...inspectorIds, inspector.key]);
      setInspectorList([...inspectorList, inspector]);
    }
    const ele = autoCompIns.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const addSupervisorData = () => {
    if (!supervisor.key) return;

    const index = supervisoList.findIndex(
      (object) => object.key === supervisor.key
    );
    if (index === -1) {
      setsupervisorId([...supervisorId, supervisor.key]);
      setsupervisoList([...supervisoList, supervisor]);
    }
    const ele = autoCompSupervisor.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const addContractorGroupsData = () => {
    if (!contractorGroups.key) return;
    const index = contractorGroupsList.findIndex(
      (object) => object.key === contractorGroups.key
    );
    if (index === -1) {
      setContractorGroupsIds([...contractorGroupsIds, contractorGroups.key]);
      setContractorGroupsList([...contractorGroupsList, contractorGroups]);
    }
    const ele = autoCompContractor.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const [isBulkUpload, setIsBulkUpload] = useState(false);

  const handleFileChanges = (data) => {
    const newData = data.map((x) => {
      x.regNumber = x.licensePlateNumber;
      x.divisionId = parseInt(x.divisionId);
      x.categoryId = parseInt(x.categoryId);
      x.assignToSupervisor =
        x.assignToSupervisor === undefined || []
          ? []
          : x.assignToSupervisor.split(",");
      x.assignToUser =
        x.assignToUser === undefined ? [] : x.assignToUser.split(",");
      x.assignToGroup =
        x.assignToGroup === undefined ? [] : x.assignToGroup.split(",");
      x.assignToInspector =
        x.assignToInspector === undefined ? [] : x.assignToInspector.split(",");
      x.assignToSubContractor =
        x.assignToSubContractor === undefined
          ? []
          : x.assignToSubContractor.split(",");
      x.createdBy = parseInt(USER_ID());
      x.organisationId = parseInt(ORG_ID());
      x.vehicalEndDate = x.vehicalEndDate?.replace(/\//g, "-");
      x.vehicalStartDate = x.vehicalStartDate?.replace(/\//g, "-");
      x.tagExpDate = x.tagExpDate?.replace(/\//g, "-");
      x.insuranceExpDate = x.insuranceExpDate?.replace(/\//g, "-");
      return x;
    });
    onSubmit(newData);
  };

  const [isSupervisor, setisSupervisor] = useState(false);
  const [isInspector, setisInspector] = useState(false);
  const [isIndividualUser, setisIndividualUser] = useState(false);
  const [isUserGroup, setisUserGroup] = useState(false);
  const [isContractor, setisContractor] = useState(false);

  const [isSupervisorErrMsg, setisSupervisorErrMsg] = useState("");
  const [isInspectorErrMsg, setisInspectorErrMsg] = useState("");
  const [isIndividualUserErrMsg, setisIndividualUserErrMsg] = useState("");
  const [isUserGroupErrMsg, setisUserGroupErrMsg] = useState("");
  const [isContractorErrMsg, setisContractorErrMsg] = useState("");

  const mapBulkError = (data) => {
    let vehiclesWithError = [];
    data.forEach((x) => {
      const validtagExpDate = dateValiadtor(
        getFormatedDate(x.tagExpDate?.replace(/\//g, "-"))
      );
      const validInsuranceExpDate = dateValiadtor(
        getFormatedDate(x.insuranceExpDate?.replace(/\//g, "-"))
      );
      const validvehicalStartDate = dateValiadtor(
        getFormatedDate(x.vehicalStartDate?.replace(/\//g, "-"))
      );
      if (
        x.categoryId?.trim() === undefined ||
        null ||
        "" ||
        x.makeAndModel?.trim() === undefined ||
        null ||
        "" ||
        x.year?.trim().length !== 4 ||
        x.vinNumber?.trim() === undefined ||
        null ||
        "" ||
        x.licensePlateNumber?.trim() === undefined ||
        null ||
        "" ||
        !validtagExpDate ||
        x.insuranceCarrier?.trim() === undefined ||
        null ||
        "" ||
        !validInsuranceExpDate ||
        !validvehicalStartDate ||
        x.divisionId?.trim() === undefined ||
        null ||
        ""
      ) {
        vehiclesWithError.push(x);
        return;
      }
    });
    return vehiclesWithError;
  };
  return (
    <div className="overidePersonal">
      <Grid container item lg={9} xs={12} sm={12} md={9}>
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">
            {editMode ? "Edit Vehicle" : "Add Vehicle"}
          </div>
        </Grid>
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="right">
          {!editMode && checkPrivileges([78, 12]) ? (
            <Buttons
              aria-describedby="dd"
              variant="contained"
              type="button"
              name="btn"
              label={isBulkUpload ? "Single Vehicle" : "Multiple Vehicles"}
              varientContained={true}
              onClick={() => {
                setIsBulkUpload(!isBulkUpload);
                ismultipleUpload(!isBulkUpload);
              }}
            ></Buttons>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      {editMode && (
        <Grid item container mt={3} lg={9} spacing={2}>
          <Grid item lg={5} mt={3} xs={12} sm={12} md={4}>
            {checkPrivileges([78, 12]) && (
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="radioGroup"
                >
                  <RadioButton
                    checked={formValue.isActive === "1"}
                    value="1"
                    label="Active"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        isActive: e.target.value,
                      })
                    }
                  ></RadioButton>
                  <RadioButton
                    checked={formValue.isActive === "2"}
                    value="2"
                    label="Inactive"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        isActive: e.target.value,
                      })
                    }
                  ></RadioButton>
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
          <Grid item lg={7} xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                type="text"
                label={"Vehicle ID"}
                disabled={true}
                value={vehicleId}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      {isBulkUpload ? (
        <Grid container>
          <Grid item lg={9} xs={9} sm={9} md={9} mt={4}>
            <BulkUpload
              mapBulkError={mapBulkError}
              handleFileChanges={handleFileChanges}
              paraText1={"Drop the files here ..."}
              paraText2={"Drop the files here, "}
              paraText3={"or Browse"}
              templateUrl={VEHICLE_TEMPLATE_URL}
              templateText="Download Template"
              gridColoumns={VEHICLE_GRID_COLOUMNS}
              isCustomColoumns={true}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container item md={9} xs={9} className="addShadowBox">
          <Grid item container spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels label="Vehicle Category" isRequired={true} />
                <SelectMenu
                  listData={vehicleCategory ?? []}
                  value={formValue.categoryId}
                  onchangehandler={(e) =>
                    setFormValue({
                      ...formValue,
                      vehicleCategory: e.target.value,
                      categoryId: e.target.value,
                    })
                  }
                  isError={isCategoryId}
                  errorMsg={
                    isCategoryId ? "Selection is required for this field" : ""
                  }
                  placeholder="Please Select"
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <InputField
                type="text"
                label="Unit Number"
                value={formValue.unitNumber}
                onChange={(e) => {
                  if (
                    e.target.value === " " &&
                    formValue.unitNumber.trim() === ""
                  )
                    setFormValue({
                      ...formValue,
                      unitNumber: "",
                    });
                  else
                    setFormValue({
                      ...formValue,
                      unitNumber: e.target.value,
                    });
                }}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={2} mt={3}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <InputField
                type="text"
                isRequired={true}
                label="Vehicle Make & Model"
                value={formValue.makeAndModel}
                onChange={(e) => {
                  if (
                    e.target.value === " " &&
                    formValue.makeAndModel.trim() === ""
                  )
                    setFormValue({
                      ...formValue,
                      makeAndModel: "",
                    });
                  else if (e.target.value.length > 200) {
                    return;
                  } else
                    setFormValue({
                      ...formValue,
                      makeAndModel: e.target.value,
                    });
                }}
                isError={isMakeandModel}
                errorMsg={
                  isMakeandModel ? "Please enter this required field" : ""
                }
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth className="dateborderNone">
                <FormLabels label={"Year"} isRequired={true} />
                <DatePicker
                  views={["year"]}
                  value={formValue.year}
                  onChangeHandler={(e) => {
                    setFormValue({
                      ...formValue,
                      year: e,
                    });
                    setisYear(false);
                  }}
                  inputFormat="yyyy"
                  isError={isYear}
                  errorMsg={isYear ? isYearErrMsg : ""}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container spacing={2} mt={3}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <InputField
                type="number"
                // isRequired={true}
                label="GVWR"
                value={formValue.gvwr}
                onChange={(e) => {
                  if (e.target.value === " " && formValue.gvwr.trim() === "")
                    setFormValue({
                      ...formValue,
                      gvwr: "",
                    });
                  else
                    setFormValue({
                      ...formValue,
                      gvwr: e.target.value,
                    });
                }}
                // isError={isMakeandModel}
                // errorMsg={
                //   isMakeandModel ? "Please enter this required field" : ""
                // }
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputField
                  isRequired={true}
                  fullWidth={true}
                  type="text"
                  label={"VIN Number"}
                  value={formValue.vinNumber}
                  onChange={(e) => {
                    if (
                      e.target.value === " " &&
                      formValue.vinNumber.trim() === ""
                    )
                      setFormValue({
                        ...formValue,
                        vinNumber: "",
                      });
                    else if (e.target.value.length > 200) {
                      return;
                    } else
                      setFormValue({
                        ...formValue,
                        vinNumber: e.target.value,
                      });
                  }}
                  isError={isVinNumber}
                  errorMsg={
                    isVinNumber ? "Please enter this required field" : ""
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container mt={3} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputField
                  isRequired={true}
                  fullWidth={true}
                  type="text"
                  label={"Licence Plate Number"}
                  value={formValue.regNumber}
                  onChange={(e) => {
                    if (
                      e.target.value === " " &&
                      formValue.regNumber.trim() === ""
                    )
                      setFormValue({
                        ...formValue,
                        regNumber: "",
                      });
                    else if (e.target.value.length > 200) {
                      return;
                    } else
                      setFormValue({
                        ...formValue,
                        regNumber: e.target.value,
                      });
                  }}
                  isError={isregNumber}
                  errorMsg={
                    isregNumber ? "Please enter this required field" : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels label={"Tag Expiration Date"} isRequired={true} />
                <DatePicker
                  value={formValue.tagExpDate}
                  maxDateEnable
                  minDate={new Date()}
                  onChangeHandler={(e) => {
                    setFormValue({ ...formValue, tagExpDate: e });
                    setisTagExpDate(false);
                  }}
                  isError={isTagExpDate}
                  errorMsg={isTagExpDate ? isTagExpDateErrMsg : ""}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container mt={3} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputField
                  isRequired={true}
                  fullWidth={true}
                  type="text"
                  label={"Insurance Carrier"}
                  value={formValue.insuranceCarrier}
                  onChange={(e) => {
                    if (
                      e.target.value === " " &&
                      formValue.insuranceCarrier.trim() === ""
                    )
                      setFormValue({
                        ...formValue,
                        insuranceCarrier: "",
                      });
                    else if (e.target.value.length > 200) {
                      return;
                    } else
                      setFormValue({
                        ...formValue,
                        insuranceCarrier: e.target.value,
                      });
                  }}
                  isError={isInsauranceCarrier}
                  errorMsg={
                    isInsauranceCarrier
                      ? "Please enter this required field"
                      : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels
                  label={"Insurance Expiration Date"}
                  isRequired={true}
                />
                <DatePicker
                  value={formValue.insuranceExpDate}
                  maxDateEnable
                  minDate={new Date()}
                  onChangeHandler={(e) => {
                    const dateFrom = getFormatedDate(new Date());
                    const dateTo = getFormatedDate(e);

                    setFormValue({ ...formValue, insuranceExpDate: e });
                    setisInsauranceExpDate(false);
                  }}
                  isError={isInsauranceExpDate}
                  errorMsg={
                    isInsauranceExpDate ? isInsauranceExpDateErrMsg : ""
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container mt={3} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels label={"Start Date"} isRequired={true} />
                <DatePicker
                  value={formValue.vehicalStartDate}
                  onChangeHandler={(e) => {
                    setFormValue({ ...formValue, vehicalStartDate: e });
                    setisVehicleStartDate(false);
                  }}
                  isError={isVehicleStartDate}
                  errorMsg={isVehicleStartDate ? isVehicleStartDateErrMsg : ""}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels label={"Completion Date"} />
                <DatePicker
                  value={formValue.vehicalEndDate}
                  maxDateEnable={12 / 31 / 2030}
                  minDate={formValue.vehicalStartDate}
                  maxDate={12 / 31 / 2030}
                  onChangeHandler={(e) => {
                    const dateFrom = getFormatedDate(
                      formValue.vehicalStartDate
                    );
                    const dateTo = getFormatedDate(e);
                    // if (dateTo < dateFrom) {
                    //   return false;
                    // }
                    setFormValue({ ...formValue, vehicalEndDate: e });
                  }}
                  isError={isVehicleEndDate}
                  errorMsg={isVehicleEndDate ? isVehicleEndDateErrMsg : ""}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item lg={12} container mt={3} spacing={2}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels label="Division" isRequired={true} />
                <SelectMenu
                  listData={division ?? []}
                  // value={formValue.division}
                  value={
                    formValue.division === "undefined"
                      ? null
                      : formValue.division
                  }
                  onchangehandler={(e) => {
                    setFormValue({
                      ...formValue,
                      division: e.target.value,
                      divisionIds: e.target.value,
                    });
                    setisSupervisor(false);
                    setsupervisoList([]);
                    setsupervisorId([]);

                    setisInspector(false);
                    setInspectorIds([]);
                    setInspectorList([]);

                    setisIndividualUser(false);
                    setIndividualUsersIds([]);
                    setIndividualUsersList([]);

                    setisUserGroup(false);
                    setuserGroupIds([]);
                    setUsersGroupList([]);

                    setisContractor(false);
                    setContractorGroupsList([]);
                    setContractorGroupsIds([]);
                  }}
                  disabled={DIVISION_ID() > 0}
                  isError={isDivisionId}
                  errorMsg={
                    isDivisionId ? "Selection is required for this field" : ""
                  }
                />
              </FormControl>
            </Grid>
            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isSupervisor && (
                  <div className="errorMsg">{isSupervisorErrMsg}</div>
                )}
                <FormLabels label="Asset Supervisor" />
                <SearchAutoComplete
                  name="Asset Supervisor"
                  optionsData={isLoading ? [] : allUsers?.users ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.division === "undefined") {
                      setisSupervisor(true);
                      setisSupervisorErrMsg("Please select division first");
                      return;
                    } else {
                      setisSupervisor(false);
                      setisSupervisorErrMsg("");
                      handleAllUserSearch(
                        event,
                        USER_TYPE.DIVISION_EMPLOYEE,
                        formValue.division,
                        0,
                        0,
                        141
                      );
                    }
                  }}
                  isKeyValuePair={true}
                  value={formValue.supervisor}
                  onChange={(e, value) => setsupervisor(value)}
                  isLoading={isLoading}
                  autoCompClear={autoCompSupervisor}
                  handleFocus={handleonfcs && handleonfcs}
                />
              </Grid>

              {/* <Grid
                className="smallAddBtn"
                item
                lg={1.5}
                md={9}
                sm={12}
                xs={12}
                mt={4}
                ml={2}
              > */}
              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addSupervisorData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  userList={supervisoList}
                  handleDelete={(e) => deleteSupervisorData(e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item mt={3}>
            <Grid lg={6} md={6} sm={6} xs={12}>
              {isInspector && (
                <div className="errorMsg">{isInspectorErrMsg}</div>
              )}
              <FormLabels label="Assign Inspector" />
              <SearchAutoComplete
                name="Assign Inspector"
                optionsData={isLoading ? [] : allUsers?.users ?? []}
                typeOnchange={(event, value) => {
                  if (formValue.division === "undefined") {
                    setisInspector(true);
                    setisInspectorErrMsg("Please select division first");
                    return;
                  } else {
                    setisInspector(false);
                    setisInspectorErrMsg("");
                    handleAllUserSearch(
                      event,
                      USER_TYPE.DIVISION_EMPLOYEE,
                      formValue.division,
                      0,
                      0,
                      140
                    );
                  }
                }}
                isKeyValuePair={true}
                value={formValue.supervisor}
                onChange={(e, value) => setInspector(value)}
                isLoading={isLoading}
                autoCompClear={autoCompIns}
                handleFocus={handleonfcs && handleonfcs}
              />
            </Grid>

            <Grid
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="smallAddBtn"
              mt={3.5}
              pl={2}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                onClick={() => addInspectorData()}
                id="btnAddEquipment"
                varientAddIconBlue={true}
              ></Buttons>
            </Grid>
            <Grid mt={3} container gap={1}>
              <UsersChip
                userList={inspectorList}
                handleDelete={(e) => deleteInspectorData(e)}
              />
            </Grid>
          </Grid>

          <Grid container item mt={3}>
            <Grid lg={6} md={6} sm={6} xs={12}>
              {isIndividualUser && (
                <div className="errorMsg">{isIndividualUserErrMsg}</div>
              )}
              <FormLabels label="Add Individual Users" />
              <SearchAutoComplete
                name="Add Individual Users"
                optionsData={isLoading ? [] : allUsers?.users ?? []}
                typeOnchange={(event, value) => {
                  if (formValue.division === "undefined") {
                    setisIndividualUser(true);
                    setisIndividualUserErrMsg("Please select division first");
                    return;
                  } else {
                    setisIndividualUser(false);
                    setisIndividualUserErrMsg("");
                    handleAllUserSearch(
                      event,
                      USER_TYPE.NONPRIVILEGED,
                      formValue.division
                    );
                  }
                }}
                isKeyValuePair={true}
                value={formValue.supervisor}
                onChange={(e, value) => setIndividualUsers(value)}
                isLoading={isLoading}
                autoCompClear={autoCompUser}
                handleFocus={handleonfcs && handleonfcs}
              />
            </Grid>

            <Grid
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="smallAddBtn"
              mt={3.5}
              pl={2}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                onClick={() => addIndiVidualUserData()}
                id="btnAddEquipment"
                varientAddIconBlue={true}
              ></Buttons>
            </Grid>
            <Grid mt={3} container gap={1}>
              <UsersChip
                userList={individualUsersList}
                handleDelete={(e) => deleteIndividualUsersData(e)}
              />
            </Grid>
          </Grid>

          <Grid container item mt={3}>
            <Grid lg={6} md={6} sm={6} xs={12}>
              {isUserGroup && (
                <div className="errorMsg">{isUserGroupErrMsg}</div>
              )}
              <FormLabels label="Add User Group" />
              <SearchAutoComplete
                name="Add User Group"
                optionsData={isLoading ? [] : userGroups ?? []}
                typeOnchange={(event, value) => {
                  if (formValue.division === "undefined") {
                    setisUserGroup(true);
                    setisUserGroupErrMsg("Please select division first");
                    return;
                  } else {
                    setisUserGroup(false);
                    setisUserGroupErrMsg("");
                    handleAllGroupSearch(event, formValue.division);
                  }
                }}
                isKeyValuePair={true}
                isLoading={isLoading}
                value={formValue.supervisor}
                onChange={(e, value) => setUsersGroup(value)}
                noOptionsTextMsg="No group"
                autoCompClear={autoCompGroup}
              />
            </Grid>

            <Grid
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="smallAddBtn"
              mt={3.5}
              pl={2}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                onClick={() => addUserGroupData()}
                id="btnAddEquipment"
                varientAddIconBlue={true}
              ></Buttons>
            </Grid>
            <Grid mt={3} container gap={1}>
              <UsersChip
                isKeyValuePair={true}
                userList={userGroupList}
                handleDelete={(e) => deleteusersGroupData(e)}
              />
            </Grid>
          </Grid>

          <Grid container item mt={3}>
            <Grid lg={6} md={6} sm={6} xs={12}>
              {isContractor && (
                <div className="errorMsg">{isContractorErrMsg}</div>
              )}
              <FormLabels label="Add Subcontractors" />
              <SearchAutoComplete
                name="Add Subcontractors"
                optionsData={allUsers?.users ?? []}
                typeOnchange={(event, value = 1) => {
                  if (formValue.division === "undefined") {
                    setisContractor(true);
                    setisContractorErrMsg("Please select division first");
                    return;
                  } else {
                    setisContractor(false);
                    setisContractorErrMsg("");

                    handleAllUserSearch(
                      event,
                      USER_TYPE.DIVISION_CONTRACTOR,
                      formValue.division
                    );
                  }
                }}
                isKeyValuePair={true}
                value={formValue.subContractor}
                onChange={(e, value) => setContractorGroups(value)}
                autoCompClear={autoCompContractor}
                handleFocus={handleonfcs && handleonfcs}
              />
            </Grid>

            <Grid
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="smallAddBtn"
              mt={3.5}
              pl={2}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                onClick={() => addContractorGroupsData()}
                id="btnAddEquipment"
                varientAddIconBlue={true}
              ></Buttons>
            </Grid>
            <Grid mt={3} container gap={1}>
              <UsersChip
                isKeyValuePair={true}
                userList={contractorGroupsList}
                handleDelete={(e) => deleteContractorGroupsData(e)}
              />
            </Grid>
          </Grid>

          <Grid item container mt={2}>
            <Grid item lg={12} md={12} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Add Notes" />
                <TextArea
                  placeholder={"Enter Here"}
                  value={formValue.note}
                  onChange={(e) => {
                    setFormValue({
                      ...formValue,
                      note: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container lg={11} className="buttonsGrid" mt={3} mb={3}>
            <div>
              <BackBtn dataBack={dataBack} />
            </div>
            <Grid item lg={6} className="cancelSaveDiv">
              <Grid item lg={5}>
                <Buttons
                  varientContained={true}
                  label={editMode ? "Update" : "Save"}
                  onClick={() => {
                    submitData();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AddVehicle;
