import { projectType } from "../types/projectType";

export const createProject = (data) => ({
  type: projectType.POST_PROJECT_PENDING,
  data,
});

export const createProjectSuccess = (data) => ({
  type: projectType.POST_PROJECT_SUCCESS,
  data,
});

export const createProjectFailure = (error) => ({
  type: projectType.POST_PROJECT_FAILURE,
  error,
});

export const updateProject = (data) => ({
  type: projectType.PUT_PROJECT_PENDING,
  data,
});

export const updateProjectSuccess = (data) => ({
  type: projectType.PUT_PROJECT_SUCCESS,
  data,
});

export const updateProjectFailure = (error) => ({
  type: projectType.PUT_PROJECT_FAILURE,
  error,
});

export const getProjectDetails = (data) => ({
  type: projectType.GET_PROJECT_DETAILS_PENDING,
  data,
});

export const getProjectDetailsSuccess = (data) => ({
  type: projectType.GET_PROJECT_DETAILS_SUCCESS,
  data,
});

export const getProjectDetailsFailure = (error) => ({
  type: projectType.GET_PROJECT_DETAILS_FAILURE,
  error,
});

export const swProjectList = (data) => ({
  type: projectType.GET_SW_PROJECT_LIST_PENDING,
  data,
});

export const swProjectListSuccess = (data) => ({
  type: projectType.GET_SW_PROJECT_LIST_SUCCESS,
  data,
});

export const swProjectListFailure = (error) => ({
  type: projectType.GET_SW_PROJECT_LIST_FAILURE,
  error,
});

export const postAddContactInProject = (data) => ({
  type: projectType.POST_ADD_CONTACT_IN_PROJECT_PENDING,
  data,
});

export const postAddContactInProjectSuccess = (data) => ({
  type: projectType.POST_ADD_CONTACT_IN_PROJECT_SUCCESS,
  data,
});

export const postAddContactInProjectFailure = (error) => ({
  type: projectType.POST_ADD_CONTACT_IN_PROJECT_FAILURE,
  error,
});

export const getContactInProjectList = (data) => ({
  type: projectType.GET_CONTACT_IN_PROJECT_LIST_PENDING,
  data,
});

export const getContactInProjectListSuccess = (data) => ({
  type: projectType.GET_CONTACT_IN_PROJECT_LIST_SUCCESS,
  data,
});

export const getContactInProjectListFailure = (error) => ({
  type: projectType.GET_CONTACT_IN_PROJECT_LIST_FAILURE,
  error,
});

export const clearGetContactInProjectList = () => ({
  type: projectType.CLEAR_GET_CONTACT_IN_PROJECT_LIST,
  data: "",
});

export const updateProjectContactStaus = (data) => ({
  type: projectType.UPDATE_PROJECT_CONTACT_STATUS_PENDING,
  data,
});

export const updateProjectContactStausSuccess = (data) => ({
  type: projectType.UPDATE_PROJECT_CONTACT_STATUS_SUCCESS,
  data,
});

export const updateProjectContactStausFailure = (error) => ({
  type: projectType.UPDATE_PROJECT_CONTACT_STATUS_FAILURE,
  error,
});

// sample log create project sample
export const createProjectSample = (data) => ({
  type: projectType.POST_PROJECT_SAMPLE_PENDING,
  data,
});

export const createProjectSampleSuccess = (data) => ({
  type: projectType.POST_PROJECT_SAMPLE_SUCCESS,
  data,
});

export const createProjectSampleFailure = (error) => ({
  type: projectType.POST_PROJECT_SAMPLE_FAILURE,
  error,
});

export const swProjectSampleList = (data) => ({
  type: projectType.GET_SW_PROJECT_SAMPLE_LIST_PENDING,
  data,
});

export const swProjectSampleListSuccess = (data) => ({
  type: projectType.GET_SW_PROJECT_SAMPLE_LIST_SUCCESS,
  data,
});

export const swProjectSampleListFailure = (error) => ({
  type: projectType.GET_SW_PROJECT_SAMPLE_LIST_FAILURE,
  error,
});

export const editOneContactInProject = (data) => ({
  type: projectType.EDIT_ONE_CONTACT_IN_PROJECT_PENDING,
  data,
});

export const editOneContactInProjectSuccess = (data) => ({
  type: projectType.EDIT_ONE_CONTACT_IN_PROJECT_SUCCESS,
  data,
});

export const editOneContactInProjectFailure = (error) => ({
  type: projectType.EDIT_ONE_CONTACT_IN_PROJECT_FAILURE,
  error,
});

export const deleteContactInProject = (payload) => ({
  type: projectType.DELETE_CONTACT_IN_PROJECT_PENDING,
  payload: payload,
});

export const deleteContactInProjectSuccess = (payload) => ({
  type: projectType.DELETE_CONTACT_IN_PROJECT_SUCCESS,
  payload: payload,
});

export const deleteContactInProjectFailure = (payload) => ({
  type: projectType.DELETE_CONTACT_IN_PROJECT_FAILURE,
  payload: payload,
});

// rain fall DATA lIST
export const swGetRainfallDataList = (data) => ({
  type: projectType.SW_GET_RAINFALL_DATA_LIST_PENDING,
  data,
});

export const swGetRainfallDataListSuccess = (data) => ({
  type: projectType.SW_GET_RAINFALL_DATA_LIST_SUCCESS,
  data,
});

export const swGetRainfallDataListFailure = (error) => ({
  type: projectType.SW_GET_RAINFALL_DATA_LIST_FAILURE,
  error,
});
export const clearsWProject = () => ({
  type: projectType.CLEAR_SWPROJECT,
  data: "",
});
export const clearsWProjectUpdate = () => ({
  type: projectType.CLEAR_SWPROJECTEDIT,
  data: "",
});
export const deleteProject = (data) => ({
  type: projectType.SW_DELETE_PROJECT_PENDING,
  data,
});

export const deleteProjectSuccess = (data) => ({
  type: projectType.SW_DELETE_PROJECT_SUCCESS,
  data,
});

export const deleteProjectFailure = (data) => ({
  type: projectType.SW_DELETE_PROJECT_FAILURE,
  data,
});
