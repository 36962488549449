import * as assignAction from "../actions/assignAction ";
import { assignType } from "../constants/assignType";
import {
  getAssignList,
} from "../../services/assignService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

// export function* createAssignSaga(params) {
//   console.debug("createAssignSaga");
//   try {
//     const { data, status, error } = yield call(createAssign, params);
//     if (!data && status !== 200) throw apiError(error);
//     const assignPage = assignType.ASSIGN_PAGE;
//     yield put(
//       assignAction.createAssignSuccess({
//         assignPage,
//         data,
//       })
//     );
//   } catch (error) {
//     const data =
//       error && error.message
//         ? error
//         : { message: error || "Create Assign Failed" };
//     yield put(assignAction.createAssignFailure(data));
//   }
// }

// export function* updateAssignSaga(params) {
//   console.debug("updateAssignSaga");
//   try {
//     const { data, status, error } = yield call(updateAssign, params);
//     if (!data && status !== 200) throw apiError(error);
//     const assignPage = assignType.ASSIGN_PAGE;
//     yield put(
//       assignAction.updateAssignSuccess({
//         assignPage,
//         data,
//       })
//     );
//   } catch (error) {
//     const data =
//       error && error.message
//         ? error
//         : { message: error || "Update Assign Failed" };
//     yield put(assignAction.updateAssignFailure(data));
//   }
// }

export function* getAssignListSaga(params) {
  console.debug("getAssignListSaga");
  try {
    const { data, status, error } = yield call(getAssignList, params);
    if (!data && status !== 200) throw apiError(error);
    const assignPage = assignType.ASSIGN_PAGE;
    yield put(
      assignAction.getAssignListSuccess({
        assignPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Assign List Failed" };
    yield put(assignAction.getAssignListFailure(data));
  }
}

const myAssignSaga = [
  // takeLatest(assignType.POST_ASSIGN_PENDING, createAssignSaga),
  takeLatest(assignType.GET_ASSIGN_LIST_PENDING, getAssignListSaga),
  // takeLatest(assignType.PUT_ASSIGN_PENDING, updateAssignSaga),
];

export default myAssignSaga;
