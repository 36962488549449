export const newsType = {
  POST_NEWS_PENDING: "[NEWS][REQUEST] Create news",
  POST_NEWS_FAILURE: "[NEWS][RESPONSE] news Creation Failed",
  POST_NEWS_SUCCESS: "[NEWS][RESPONSE] news Created Successfull",
  NEWS_PAGE: "NewsPage",

  GET_LIST_NEWS_PENDING: "[NEWS][REQUEST] News List Page",
  GET_LIST_NEWS_SUCCESS: "[NEWS][RESPONSE] News List page  Successfull",
  GET_LIST_NEWS_FAILURE: "[NEWS][RESPONSE] News List page Failure",
  CLEAR_GETNEWS_NEWS: "[NEWS][RESPONSE] Clear List page Failure",
  NEWS_LIST_PAGE: "NewsListPage",

  GET_NEWS_DETAILS_PENDING: "[NEWS_DETAILS][REQUEST] News Detail List Page",
  GET_NEWS_DETAILS_SUCCESS:
    "[NEWS_DETAILS][RESPONSE] News Detail page  Successfull",
  GET_NEWS_DETAILS_FAILURE: "[NEWS_DETAILS][RESPONSE] News Detail page Failure",
  NEWS_DETAIL_PAGE: "NewsDetailPage",

  PUT_NEWS_PENDING: "[NEWS_UPDATE][REQUEST] News Detail Update List Page",
  PUT_NEWS_SUCCESS:
    "[NEWS_UPDATE][RESPONSE] News Detail Update page  Successfull",
  PUT_NEWS_FAILURE: "[NEWS_UPDATE][RESPONSE] News Detail  Updatepage Failure",
  NEWS_UPDATE_PAGE: "NewsUpdatePage",

  DELETE_NEWS_PENDING: "[DELETE_NEWS_PENDING][REQUEST] Delete News Page",
  DELETE_NEWS_SUCCESS:
    "[DELETE_NEWS_SUCCESS][RESPONSE] Delete News Page  Successfull",
  DELETE_NEWS_FAILURE:
    "[DELETE_NEWS_FAILURE][RESPONSE] Delete News Page Failure",
  DELETE_NEWS_PAGE: "DeleteNewsPage",
};
