import React, { useEffect } from "react";
import ViewSafetyLog from "../component/safetyLog/safetyLog";
import { useDispatch, useSelector } from "react-redux";
import * as lmsUserAction from "../../../redux/actions/lmsUserAction";
import * as userAction from "../../../redux/actions/userAction";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { useLocation } from "react-router-dom";

export default function SafetyLogContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigationObj = location?.state;

  const PAGE_SIZE_SIX = 6;

  const itemLogData = useSelector((state) => state.getItemList?.data);

  const allUsers = useSelector((state) => state.allUsers);
  const searchOption = useSelector((state) => state.users.data);

  function getAllUsers(searchText, type, divisionId) {
    dispatch(jhaAction.clearJha(searchText, type, divisionId));
    dispatch(userAction.searchAllUsers(searchText, type, divisionId));
  }

  const isGetItemLogLoading = useSelector(
    (state) => state.getItemList?.isLoading
  );
  const isLoading = allUsers?.isLoading;
  const coursesUserListData = useSelector((state) => state.getUserCoursesList);
  const isCoursesLoading = useSelector(
    (state) => state.getUserCoursesList?.isLoading
  );
  const lessionUserListData = useSelector((state) => state.getUserLessonsList);
  const jhaConductListData = useSelector(
    (state) => state.getSaftyLogJHAConductList
  );
  const getLessionUserListData = (data) => {
    dispatch(lmsUserAction.getUserLessonsList(data));
  };
  const isLessonLoading = useSelector(
    (state) => state.getUserLessonsList?.isLoading
  );

  const getItemLogKudosData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };

  const getItemLogCorrectiveData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };

  const getItemLogReprimandData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };

  const getCoursesUserListData = (data) => {
    dispatch(lmsUserAction.getUserCoursesList(data));
  };
  const getSaftyLogJHAConductList = (data) => {
    dispatch(jhaAction.getSaftyLogJHAConductList(data));
  };
  useEffect(() => {
    dispatch(lmsUserAction.clearCourseList(""));
    dispatch(lmsUserAction.clearLesssonList(""));
  }, []);

  return (
    <>
      <div className="dashbord_heading">
        <div className="insi-stitle">
          <p>Safety Log</p>
        </div>
      </div>
      <ViewSafetyLog
        getItemLogKudosData={getItemLogKudosData}
        getItemLogCorrectiveData={getItemLogCorrectiveData}
        getItemLogReprimandData={getItemLogReprimandData}
        coursesUserListData={coursesUserListData?.data}
        isCoursesLoading={isCoursesLoading}
        getCoursesUserListData={getCoursesUserListData}
        lessionUserListData={lessionUserListData.data}
        getLessionUserListData={getLessionUserListData}
        isLessonLoading={isLessonLoading}
        allUsers={allUsers && allUsers?.data}
        itemLogData={itemLogData}
        searchOption={searchOption?.users || []}
        onSearch={getAllUsers}
        isLoading={isLoading}
        isGetItemLogLoading={isGetItemLogLoading}
        PAGE_SIZE_SIX={PAGE_SIZE_SIX}
        getSaftyLogJHAConductList={getSaftyLogJHAConductList}
        jhaConductList={jhaConductListData}
        navigationObj={navigationObj}
      />
    </>
  );
}
