import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateToolbox from "../component/CreateToolbox/CreateToolbox";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { useLocation } from "react-router-dom";
//import * as lmsAction from "../../../redux/actions/lmsAction";
import SuccessScreencommon from "../../../library/custom/success/successScreen";
import { TOOL_BOX_LIST } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { API_STATUS, LNG_ID, ORG_ID } from "../../../constant/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";

const CreateToolboxContainer = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const editMode = location?.state?.editMode;
  const toolboxId = location?.state?.toolboxId;

  const createToolBoxTalk = useSelector((state) => state.createToolBoxTalk);
  const cid = createToolBoxTalk?.data?.result?.toolboxTalkId;
  const createToolBoxTalkIsloading = useSelector(
    (state) => state.createToolBoxTalk?.isLoading
  );
  const statuss = createToolBoxTalk?.status;

  const [successScreens, setSuccessScreens] = useState(false);
  //updateToolBoxTalk
  const updateToolBoxTalk = useSelector((state) => state.updateToolBoxTalk);
  const status = updateToolBoxTalk?.status;
  const isLoading = useSelector((state) => state.updateToolBoxTalk?.isLoading);

  const getDropdownDataKeyValue = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.toolboxCategories
  );
  const getToolBoxTalkViewDetail = useSelector(
    (state) => state?.getToolBoxTalkView
  );
  const updateIsloading = getToolBoxTalkViewDetail?.isLoading;
  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    lngId: LNG_ID,
    flags: [20],
  };
  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);
  useEffect(() => {
    setSuccessScreens(false);
  }, []);
  useEffect(() => {
    if (toolboxId) {
      dispatch(toolboxAction.getToolBoxTalkDetails(toolboxId));
    }
  }, []);

  const onSubmit = (data) => {
    setSuccessScreens(true);
    if (editMode) {
      dispatch(toolboxAction.updateToolBoxTalkData(data));
    } else dispatch(toolboxAction.createToolBoxTalk(data));
  };

  const successScree = (id, message, labelmsg) => {
    return (
      <SuccessScreencommon
        isUpdate={message}
        route={TOOL_BOX_LIST}
        id={id}
        label2={labelmsg}
        label1="Safety Meeting ID"
        label4="Safety Meeting in "
      ></SuccessScreencommon>
    );
  };
  return (
    <>
      {createToolBoxTalkIsloading && <Loader />}

      {isLoading && <Loader />}
      {statuss === API_STATUS.SUCCESS && !editMode && successScreens ? (
        successScree(cid, "Safety Meeting Created Successfully", "")
      ) : status === API_STATUS.SUCCESS &&
        editMode === true &&
        successScreens ? (
        successScree(
          toolboxId,
          "Safety Meeting Updated Successfully",
          "has been updated"
        )
      ) : (
        <CreateToolbox
          categoryListData={getDropdownDataKeyValue}
          onSubmit={onSubmit}
          editToolBoxData={editMode ? getToolBoxTalkViewDetail : ""}
          editMode={editMode}
          toolboxId={toolboxId}
        />
      )}
    </>
  );
};

export default CreateToolboxContainer;
