import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import ReportCredentialCount from "../component/reportCredentialCount";
import { useNavigate } from "react-router-dom";
import { CREDENTIAL_LOOKUP } from "../../../constant/routeContant";
import * as reportAction from "../../../redux/actions/reportAction";
import Loader from "../../../library/common/Loader";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";

function ReportCredentialCountConatiner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const PAGE_SIZES = 100;

  const NavigateToLookup = () => {
    navigate(CREDENTIAL_LOOKUP);
  };
  const getLookupCredentialReportData = useSelector(
    (state) => state.getLookupCredentialReportData
  );
  const allGroups = useSelector((state) => state.searchAllGroup);
  const isLoading =
    allGroups.isLoading || getLookupCredentialReportData.isLoading;

  const getLookupCredentialReports = (
    groupId,
    credentialTabId,
    startDate,
    endDate
  ) => {
    const defaultDatas = {
      page: 1,
      pageSize: PAGE_SIZES,
      groupId: groupId ? groupId : 0,
      orgId: ORG_ID(),
      tabId: credentialTabId,
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
    };
    dispatch(reportAction.getLookupCredentialReport(defaultDatas));
  };

  function handleAllGroupSearch(serachText, divisionId, alldivision) {
    dispatch(userAction.searchAllGroup(serachText, divisionId, alldivision));
  }

  const showLoader = useSelector(
    (state) =>
      state?.getCredentialReportData?.isLoading ||
      state?.getLookupCredentialReportData.isLoading
  );

  const CredentialReportData = useSelector(
    (state) => state.getCredentialReportData
  );

  const defaultData = {
    groupId: 0,
  };
  const getCredentialReports = (data) => {
    dispatch(reportAction.getCredentialReport(data));
  };
  useEffect(() => {
    getCredentialReports(defaultData);
  }, []);

  return (
    <>
      {showLoader && <Loader />}
      <ReportCredentialCount
        CredentialReportData={CredentialReportData}
        NavigateToLookup={NavigateToLookup}
        userGroups={allGroups?.data}
        getCredentialReports={getCredentialReports}
        getLookupCredentialReportData={getLookupCredentialReportData}
        getLookupCredentialReports={getLookupCredentialReports}
        PAGE_SIZES={PAGE_SIZES}
        handleAllGroupSearch={handleAllGroupSearch}
        isLoading={isLoading}
      />
    </>
  );
}

export default ReportCredentialCountConatiner;
