import { utilityDamageType } from "../constants/utilityDamageType";

export const utilityDamage = (data) => ({
  type: utilityDamageType.POST_UTILITY_DAMAGE_PENDING,
  data,
});

export const utilityDamageSuccess = (data) => ({
  type: utilityDamageType.POST_UTILITY_DAMAGE_SUCCESS,
  data,
});

export const utilityDamageFailure = (error) => ({
  type: utilityDamageType.POST_UTILITY_DAMAGE_FAILURE,
  error,
});

export const getUtilityDamageDetails = (caseid, incidentid) => ({
  type: utilityDamageType.GET_UTILITY_DAMAGE_PENDING,
  caseid,
  incidentid,
});

export const getUtilityDamageSuccess = (data) => ({
  type: utilityDamageType.GET_UTILITY_DAMAGE_SUCCESS,
  data,
});

export const getUtilityDamageFailure = (error) => ({
  type: utilityDamageType.GET_UTILITY_DAMAGE_FAILURE,
  error,
});

export const updateUtilityDamage = (data) => ({
  type: utilityDamageType.PUT_UTILITY_DAMAGE_PENDING,
  data,
});

export const updateUtilityDamageSuccess = (data) => ({
  type: utilityDamageType.PUT_UTILITY_DAMAGE_SUCCESS,
  data,
});

export const updateUtilityDamageFailure = (error) => ({
  type: utilityDamageType.PUT_UTILITY_DAMAGE_FAILURE,
  error,
});
export const clearUtilityDamageDetails = () => ({
  type: utilityDamageType.CLEAR_UTILITY_DAMAGE,
  data: {},
});
