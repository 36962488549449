import React from "react";
import { ADMIN_CONTRACTOR_CONFIGURATION_LIST, ADMIN_CONTRACTOR_LIST } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import ContractorManagementList from "../component/contractorManagement/contractorManagementList";

const ContractorManagementContainer = () => {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(ADMIN_CONTRACTOR_LIST);
    }
    else if (id === 2) {
      navigate(ADMIN_CONTRACTOR_CONFIGURATION_LIST);
    }
  };

  return (
    <>
      <div className="dashTitle"> Contractor Management</div>
      <ContractorManagementList onClick={onClickHandler} />
    </>
  );
};

export default ContractorManagementContainer;
