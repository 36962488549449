import { incpectionType } from "../constants/incpectionType";

export const getQuestionSet = (data) => ({
  type: incpectionType.GET_QUESTION_SET_PENDING,
  payload: data,
});

export const getReferenceData = () => ({
  type: incpectionType.GET_WEATHER_REF_PENDING,
});

export const getQuesDynamicField = (id) => ({
  type: incpectionType.GET_QUEST_DYNAMIC_FIELD_PENDING,
  payload: id,
});

export const revokeQuesDynamicField = () => ({
  type: incpectionType.REVOKE_DATA_UNMOUNT,
});

export const postInpectionData = (payload) => ({
  type: incpectionType.POST_INSPECTION_PENDING,
  payload: payload,
});

export const updateInpectionData = (payload) => ({
  type: incpectionType.UPDATE_INSPECTION_PENDING,
  payload: payload,
});

// inspetion view report
export const getInspectionRportList = (payload) => ({
  type: incpectionType.GET_INSPECTION_VIEW_LIST_PENDING,
  payload,
});

// inspectcion report item
export const deleteInspection = (payload) => ({
  type: incpectionType.DEELETE_INSPECTION_PENDING,
  payload,
});

export const deleteInspectionSuccess = (payload) => ({
  type: incpectionType.DEELETE_INSPECTION_SUCCESS,
  payload: payload,
});

export const deleteInspectionFailure = (payload) => ({
  type: incpectionType.DEELETE_INSPECTION_FAILED,
  payload: payload,
});

// get inspection details
export const getInspectionDetails = (id) => ({
  type: incpectionType.GET_INSPECTION_DETAILS_PENDING,
  payload: id,
});

// Inspection Configuration
export const adminAddUpdateSubcategory = (data) => ({
  type: incpectionType.POST_SUBCATEGORY_PENDING,
  data,
});
export const adminAddUpdateSubcategorySuccess = (data) => ({
  type: incpectionType.POST_SUBCATEGORY_SUCCESS,
  data,
});
export const adminAddUpdateSubcategoryFailure = (error) => ({
  type: incpectionType.POST_SUBCATEGORY_FAILURE,
  error,
});
export const clearAdminAddUpdateSubcategory = () => ({
  type: incpectionType.CLEAR_SUBCATEGORY,
  data: "",
});

export const adminAddUpdateCategory = (data) => ({
  type: incpectionType.POST_CATEGORY_PENDING,
  data,
});
export const adminAddUpdateCategorySuccess = (data) => ({
  type: incpectionType.POST_CATEGORY_SUCCESS,
  data,
});
export const adminAddUpdateCategoryFailure = (error) => ({
  type: incpectionType.POST_CATEGORY_FAILURE,
  error,
});
export const clearAdminAddUpdateCategory = () => ({
  type: incpectionType.CLEAR_CATEGORY,
  data: "",
});

export const addUpdateQuestions = (data) => ({
  type: incpectionType.POST_ADDUPDATEQUESTIONS_PENDING,
  data,
});
export const addUpdateQuestionsSuccess = (data) => ({
  type: incpectionType.POST_ADDUPDATEQUESTIONS_SUCCESS,
  data,
});
export const addUpdateQuestionsFailure = (error) => ({
  type: incpectionType.POST_ADDUPDATEQUESTIONS_FAILURE,
  error,
});
export const clearQuestions = () => ({
  type: incpectionType.CLEAR_QUESTIONS,
  data: "",
});

export const adminUpdateInspection = (data) => ({
  type: incpectionType.POST_ADMINUPDATEINSPECTION_PENDING,
  data,
});
export const adminUpdateInspectionSuccess = (data) => ({
  type: incpectionType.POST_ADMINUPDATEINSPECTION_SUCCESS,
  data,
});
export const adminUpdateInspectionFailure = (error) => ({
  type: incpectionType.POST_ADMINUPDATEINSPECTION_FAILURE,
  error,
});
export const clearAdminUpdateInspection = () => ({
  type: incpectionType.CLEAR_ADMINUPDATEINSPECTION,
  data: "",
});

export const adminAddUpdateInspection = (data) => ({
  type: incpectionType.POST_ADMINADDUPDATEINSPECTION_PENDING,
  data,
});
export const adminAddUpdateInspectionSuccess = (data) => ({
  type: incpectionType.POST_ADMINADDUPDATEINSPECTION_SUCCESS,
  data,
});
export const adminAddUpdateInspectionFailure = (error) => ({
  type: incpectionType.POST_ADMINADDUPDATEINSPECTION_FAILURE,
  error,
});
export const clearAdminAddUpdateInspection = () => ({
  type: incpectionType.CLEAR_ADMINADDUPDATEINSPECTION,
  data: "",
});

export const deleteQuestion = (payload) => ({
  type: incpectionType.DELETE_QUESTIONS,
  payload: payload,
});

export const deleteQuestionSuccess = (payload) => ({
  type: incpectionType.DELETE_QUESTIONS_SUCCESS,
  payload: payload,
});

export const deleteQuestionFailure = (payload) => ({
  type: incpectionType.DELETE_QUESTIONS_FAILED,
  payload: payload,
});

export const getCategoriesList = (data) => ({
  type: incpectionType.GET_CATEGORIES_LIST,
  data,
});

export const getCategoriesListSuccess = (data) => ({
  type: incpectionType.GET_CATEGORIES_LIST_SUCCESS,
  data,
});

export const getCategoriesListFailure = (error) => ({
  type: incpectionType.GET_CATEGORIES_LIST_FAILURE,
  error,
});

export const getSubCategoriesList = (data) => ({
  type: incpectionType.GET_SUB_CATEGORIES_LIST,
  data,
});

export const getSubCategoriesListSuccess = (data) => ({
  type: incpectionType.GET_SUB_CATEGORIES_LIST_SUCCESS,
  data,
});

export const getSubCategoriesListFailure = (error) => ({
  type: incpectionType.GET_SUB_CATEGORIES_LIST_FAILURE,
  error,
});

export const getQuestionsList = (data) => ({
  type: incpectionType.GET_QUESTIONS_LIST,
  data,
});

export const getQuestionsListSuccess = (data) => ({
  type: incpectionType.GET_QUESTIONS_LIST_SUCCESS,
  data,
});

export const getQuestionsListFailure = (error) => ({
  type: incpectionType.GET_QUESTIONS_LIST_FAILURE,
  error,
});

export const getQuestionSetList = (data) => ({
  type: incpectionType.GET_QUESTIONS_SET_LIST,
  data,
});

export const getQuestionSetListSuccess = (data) => ({
  type: incpectionType.GET_QUESTIONS_SET_LIST_SUCCESS,
  data,
});

export const getQuestionSetListFailure = (error) => ({
  type: incpectionType.GET_QUESTIONS_SET_LIST_FAILURE,
  error,
});

export const getAssetList = (data) => ({
  type: incpectionType.GET_ASSET_LIST,
  data,
});

export const getAssetListSuccess = (data) => ({
  type: incpectionType.GET_ASSET_LIST_SUCCESS,
  data,
});

export const getAssetListFailure = (error) => ({
  type: incpectionType.GET_ASSET_LIST_FAILURE,
  error,
});

export const cloneQuestionSet = (data) => ({
  type: incpectionType.CLONE_QUESTION_SET,
  data,
});

export const cloneQuestionSetSuccess = (data) => ({
  type: incpectionType.CLONE_QUESTION_SET_SUCCESS,
  data,
});

export const cloneQuestionSetFailure = (error) => ({
  type: incpectionType.CLONE_QUESTION_SET_FAILURE,
  error,
});

export const clearCloneQuestionSet = () => ({
  type: incpectionType.CLEAR_CLONE_QUESTION_SET,
  data: "",
});

export const qestSetDetailSet = (data) => ({
  type: incpectionType.QEST_SET_DETAIL,
  data,
});

export const qestSetDetailSetSuccess = (data) => ({
  type: incpectionType.QEST_SET_DETAIL_SUCCESS,
  data,
});

export const qestSetDetailSetFailure = (error) => ({
  type: incpectionType.QEST_SET_DETAIL_FAILURE,
  error,
});

export const getInspectionOpenItemDetail = (data) => ({
  type: incpectionType.GET_INSPECTION_OPENITEM_DETAIL,
  data,
});

export const getInspectionOpenItemDetailSuccess = (data) => ({
  type: incpectionType.GET_INSPECTION_OPENITEM_DETAIL_SUCCESS,
  data,
});

export const getInspectionOpenItemDetailFailure = (error) => ({
  type: incpectionType.GET_INSPECTION_OPENITEM_DETAIL_FAILURE,
  error,
});

export const CloseInspectionOpenItem = (data) => ({
  type: incpectionType.UPDATE_INSPECTION_OPENITEM,
  data,
});

export const CloseInspectionOpenItemSuccess = (data) => ({
  type: incpectionType.UPDATE_INSPECTION_OPENITEM_SUCCESS,
  data,
});

export const CloseInspectionOpenItemFailure = (error) => ({
  type: incpectionType.UPDATE_INSPECTION_OPENITEM_FAILED,
  error,
});

export const getInspectionViolationCounts = (data) => ({
  type: incpectionType.GET_INSPECTION_VIOLATION_COUNTS,
  data,
});

export const getInspectionViolationCountsSuccess = (data) => ({
  type: incpectionType.GET_INSPECTION_VIOLATION_COUNTS_SUCCESS,
  data,
});

export const getInspectionViolationCountsFailure = (error) => ({
  type: incpectionType.GET_INSPECTION_VIOLATION_COUNTS_FAILURE,
  error,
});

export const getItemList = (data) => ({
  type: incpectionType.GET_ITEM_LIST,
  data,
});

export const getItemListSuccess = (data) => ({
  type: incpectionType.GET_ITEM_LIST_SUCCESS,
  data,
});

export const getItemListFailure = (error) => ({
  type: incpectionType.GET_ITEM_LIST_FAILURE,
  error,
});

export const postReprimandToClose = (data) => ({
  type: incpectionType.POST_REPRIMAND_TO_CLOSE,
  data,
});
export const postReprimandToCloseSuccess = (data) => ({
  type: incpectionType.POST_REPRIMAND_TO_CLOSE_SUCCESS,
  data,
});
export const postReprimandToCloseFailure = (error) => ({
  type: incpectionType.POST_REPRIMAND_TO_CLOSE_FAILURE,
  error,
});

export const getInspectionAcknowledgement = (data) => ({
  type: incpectionType.GET_INSPECTION_ACKNOWLEDGEMENT,
  data,
});

export const getInspectionAcknowledgementSuccess = (data) => ({
  type: incpectionType.GET_INSPECTION_ACKNOWLEDGEMENT_SUCCESS,
  data,
});

export const getInspectionAcknowledgementFailure = (error) => ({
  type: incpectionType.GET_INSPECTION_ACKNOWLEDGEMENT_FAILURE,
  error,
});

export const updateInspectionAcknowledgement = (data) => ({
  type: incpectionType.POST_INSPECTION_ACKNOWLEDGEMENT_PENDING,
  data,
});
export const updateInspectionAcknowledgementSuccess = (data) => ({
  type: incpectionType.POST_INSPECTION_ACKNOWLEDGEMENT_SUCCESS,
  data,
});
export const updateInspectionAcknowledgementFailure = (error) => ({
  type: incpectionType.POST_INSPECTION_ACKNOWLEDGEMENT_FAILURE,
  error,
});

export const getExportItemList = (data) => ({
  type: incpectionType.GET_EXPORT_ITEM_LIST,
  data,
});

export const getExportItemListSuccess = (data) => ({
  type: incpectionType.GET_EXPORT_ITEM_LIST_SUCCESS,
  data,
});

export const getExportItemListFailure = (error) => ({
  type: incpectionType.GET_EXPORT_ITEM_LIST_FAILURE,
  error,
});

export const getInspectionReportAssetDetails = (data) => ({
  type: incpectionType.GET_INSPECTION_REPORT_ASSET_DETAILS,
  data,
});

export const getInspectionReportAssetDetailsSuccess = (data) => ({
  type: incpectionType.GET_INSPECTION_REPORT_ASSET_DETAILS_SUCCESS,
  data,
});

export const getInspectionReportAssetDetailsFailure = (error) => ({
  type: incpectionType.GET_INSPECTION_REPORT_ASSET_DETAILS_FAILURE,
  error,
});


export const getInspecionReport = (data) => ({
  type: incpectionType.GET_INSPECTION_REPORT,
  data,
});

export const getInspecionReportSuccess = (data) => ({
  type: incpectionType.GET_INSPECTION_REPORT_SUCCESS,
  data,
});

export const getInspecionReportFailure = (error) => ({
  type: incpectionType.GET_INSPECTION_REPORT_FAILURE,
  error,
});

export const getOpenItemPhotos = (data) => ({
  type: incpectionType.GET_OPEN_ITEM_PHOTOS,
  data,
});

export const getOpenItemPhotosSuccess = (data) => ({
  type: incpectionType.GET_OPEN_ITEM_PHOTOS_SUCCESS,
  data,
});

export const getOpenItemPhotosFailure = (error) => ({
  type: incpectionType.GET_OPEN_ITEM_PHOTOS_FAILURE,
  error,
});