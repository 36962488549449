import React, { useState } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import {
  ISwitch,
  LNG_ID,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import NORow from "../../../../library/custom/noRow/noRow";
import { INSPECTION_ADMIN } from "../../../../constant/routeContant";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const InspectionSetList = ({
  handleEditOnClick,
  addInspection,
  questionSetList,
  isLoading,
  onSubmit,
  onCloneSubmit,
}) => {
  const dataBack = {
    title: "Back to Inspections",
    route: INSPECTION_ADMIN,
  };

  const handleChange = (questionSetItem) => {
    const newCatList = [...questionSetList];
    newCatList.forEach((lists) => {
      if (lists.key == questionSetItem.key) {
        lists.questionSetId = questionSetItem.key;
        lists.value = questionSetItem.value;
        lists.imgUrl = null;
        lists.isDeleted = false;
        lists.isActive = !lists.isActive;
        lists.checked = !lists.checked;
        lists.createdBy = parseInt(USER_ID());
        lists.organisationId = parseInt(ORG_ID());
        lists.lngId = LNG_ID;
      }
    });
    const newListData = newCatList?.find((x) => x.checked);
    onSubmit(newListData);
  };

  const columns = [
    { field: "value", headerName: "Inspection Name", flex: 2 },
    { field: "dateOfLastUpdate", headerName: "Last Updated", flex: 1 },
    {
      flex: 1,
      field: "isActive",
      headerName: "Status",
      hide: !checkPrivileges([12, 4, 120]),
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox">
                <FormControlLabel
                  control={
                    <ISwitch
                      checked={rows?.row.isActive ? rows?.row.isActive : false}
                      onChange={() => handleChange(rows?.row)}
                      name="status"
                    />
                  }
                />

                <div>{rows?.row?.isActive ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 1,
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={questionSetList?.length ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={questionSetList ?? []}
          fileNameXL={"Inspections Set"}
        />
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <>
            {checkPrivileges([12, 120, 121, 4]) && (
              <ReportRowManipulte
                rows={rows}
                editOnClick={() =>
                  handleEditOnClick(rows?.row?.key, rows?.row?.assetTypeId)
                }
                cloneOnClick={() => handleCloneOnClick(rows?.row)}
                editInspectionOnClick={() =>
                  editInspectionNameOnClick(rows?.row)
                }
                edit
                editLabel="Edit Inspection"
                editInsectionLabel="Edit Inspection Name"
                editInspection
                clone
                cloneLabel="Clone Inspection"
              />
            )}
          </>
        );
      },
    },
  ];
  const [open, setOpen] = React.useState(false);
  const [editInsName, setInsName] = useState(false);

  const [modalData, setModalData] = useState();
  const [modalEditInsData, setModalEditInsData] = useState();

  const handleClose = () => setOpen(false);

  const intialCloneState = {
    inspecionClonetype: "",
  };
  const intialEditInsState = {
    inspecionNametype: "",
  };
  const [formValueClone, setFormValueClone] = useState(intialCloneState);
  const [formValueEditIns, setFormValueEditIns] = useState(intialEditInsState);
  const [isCloneInspeciontype, setIsCloneInspeciontype] = useState(false);
  const [isInspecionNametype, setIsInspecionNametype] = useState(false);
  const [errorsMsgCloneShowActive, seterrorsMsgCloneShowActive] =
    useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();

  const handleCloneOnClick = (modalData) => {
    setOpen(true);
    setInsName(false);
    setModalData(modalData);
    setFormValueClone({
      inspecionClonetype: "",
    });
  };

  const editInspectionNameOnClick = (modalEditInsData) => {
    setOpen(true);
    setInsName(true);
    setModalEditInsData(modalEditInsData);
    setFormValueEditIns({
      inspecionNametype: modalEditInsData?.value,
    });
  };

  const submitCloneData = () => {
    const index = questionSetList?.findIndex(
      (obect) =>
        obect.value?.toUpperCase() ===
        formValueClone?.inspecionClonetype?.toUpperCase()
    );

    if (formValueClone?.inspecionClonetype.trim() === "") {
      setFormValueClone({
        ...formValueClone,
        inspecionClonetype: "",
      });
      seterrorsMsgCloneShowActive(false);
      setIsCloneInspeciontype(true);
    } else if (index === -1) {
      setOpen(false);
      setFormValueClone({
        ...formValueClone,
        inspecionClonetype: "",
      });
      const dataClone = {
        cloneQuestionSetId: modalData?.key,
        newQuestionSet: formValueClone?.inspecionClonetype,
        createdBy: parseInt(USER_ID()),
        organisationId: ORG_ID(),
        lngId: LNG_ID,
      };
      onCloneSubmit(dataClone);
    } else {
      seterrorsMsgCloneShowActive(true);
      setErrorsMsgShow("Inspection Name already added");
    }
  };

  const submitEditInsData = () => {
    if (formValueEditIns?.inspecionNametype.trim() === "") {
      setFormValueEditIns({
        ...formValueEditIns,
        inspecionNametype: "",
      });
      setIsInspecionNametype(true);
    } else {
      setIsInspecionNametype(false);
      setOpen(false);
      setFormValueEditIns({
        ...formValueEditIns,
        inspecionNametype: "",
      });
      const dataEdit = {
        questionSetId: modalEditInsData?.key,
        value: formValueEditIns?.inspecionNametype,
        imgUrl: modalEditInsData?.imgUrl,
        isDeleted: modalEditInsData?.isDeleted,
        isActive: modalEditInsData?.isActive,
        assetTypeId: modalEditInsData?.assetTypeId,
        createdBy: parseInt(USER_ID()),
        organisationId: ORG_ID(),
        lngId: LNG_ID,
      };
      onSubmit(dataEdit);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modalContainer">
          {editInsName ? (
            <Box className="modalBox">
              <div className="modalTitle">Edit Inspection Name</div>
              <div className="modalInputBox">
                <InputField
                  type="text"
                  isRequired={true}
                  value={formValueEditIns?.inspecionNametype}
                  onChange={(e) =>
                    setFormValueEditIns({
                      ...formValueEditIns,
                      inspecionNametype: e.target.value,
                    })
                  }
                  label="Edit Inspection Name"
                  placeholder="Inspection Name Here..."
                  isError={isInspecionNametype}
                  errorMsg={
                    isInspecionNametype ? "Inspection Name is Required" : ""
                  }
                />
              </div>
              <Grid container className="modalFooterBox">
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientText={true}
                    label="Cancel"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientContained={true}
                    label={"Save"}
                    onClick={submitEditInsData}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box className="modalBox">
              <div className="modalTitle">Clone Inspection</div>
              <div className="modalInputBox">
                <InputField
                  type="text"
                  isRequired={true}
                  value={formValueClone?.inspecionClonetype}
                  onChange={(e) =>
                    setFormValueClone({
                      ...formValueClone,
                      inspecionClonetype: e.target.value,
                    })
                  }
                  label="Clone Inspection Name"
                  placeholder="Clone Inspection Name Here..."
                  isError={isCloneInspeciontype}
                  errorMsg={
                    isCloneInspeciontype
                      ? "Clone Inspection Name is Required"
                      : ""
                  }
                />
                {errorsMsgCloneShowActive && (
                  <div className="errorMsg">{errorsMsgShow}</div>
                )}
              </div>
              <Grid container className="modalFooterBox">
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientText={true}
                    label="Cancel"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientContained={true}
                    label={"Save"}
                    onClick={submitCloneData}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Modal>

      <Grid className="inspectionOverRide">
        <Grid container item md={12} xs={12} className="addShadowBox">
          <Grid container className="confBox">
            <Grid item md={8.5} xs={12}>
              <div className="confTitile">Configure Inspections</div>
            </Grid>
            <Grid item md={3.5} xs={12}>
              {checkPrivileges([12, 120, 121, 4]) && (
                <Buttons
                  id="btnAddCategory"
                  label={"Add Inspection"}
                  varientAddIconBlue={true}
                  onClick={() => addInspection()}
                />
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid container item md={12} xs={12}>
              <Grid item md={12} className="CategoriesBox" mt={3}>
                <div className="desc_box row_uniq">
                  <div className="table_style_ui inspectionSet">
                    <GridTable
                      getRowId={(r) => r.key}
                      rows={questionSetList ?? []}
                      rowCount={questionSetList?.length ?? 0}
                      columns={columns}
                      loading={isLoading}
                      checkboxSelection={false}
                      page={1}
                      pagination={false}
                      pageSize={PAGE_SIZE}
                      rowsPerPageOptions={[PAGE_SIZE]}
                      backIconButtonProps={{ disabled: false }}
                      disableSelectionOnClick
                      components={{
                        LoadingOverlay: TableLoader,
                        NoRowsOverlay: NORow,
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className="insBackBtn">
            <BackBtn dataBack={dataBack} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default InspectionSetList;
