import React, { useRef } from "react";
import { Grid, RadioGroup, FormControl } from "@mui/material";
import RadioButton from "../custom/radioButton/RadioButton";
import TextArea from "../custom/textArea/TextArea";
import FormLabels from "../custom/formLabel/FormLabels";
import SearchAutoComplete from "../../library/custom/searchAutoComplete/SearchAutoComplete";
import UsersChip from "../custom/usersChip/UsersChip";
import "./acceptableBox.scss";
import { InspectionInvolvedPartyOptionData } from "../../constant/inspectionConstant";
import { USER_TYPE } from "../../constant/constant";
import TextEditor from "../custom/textEditor/TextEditor";
import FormDropZone from "../dropZone/FormDropZone";

const AcceptableBoxDetails = ({
  handleOnChange,
  data,
  setImage,
  filesUrl,
  filess,
  deleteuser,
  labelValue,
  uniqueId,
  involdevPatyValue,
  employeeValue,
  subcontractorValue,
  deleteform,
  acceptablelength,
  deleteImageURLS,
  deleteSubContractor,
  handleAllUserSearch,
  isLoading,
  allUsers,
  assetId,
}) => {
  const autoCompInspector = useRef(null);

  const subcontractorValueCheck = subcontractorValue?.filter(
    (item) => item.value !== ""
  );
  const employeeValueCheck = employeeValue?.filter((item) => item.value !== "");

  return (
    <>
      <div className="notAcceptableBox">
        <Grid item md={12} xs={12} textAlign="left" mt={2}>
          {acceptablelength > 1 && (
            <div
              onClick={() => deleteform(uniqueId)}
              className="removeRepetBox"
            >
              X Remove Kudos
            </div>
          )}

          <FormLabels label="Add Comment" />
          <TextEditor
            value={labelValue ?? ""}
            placeholderText={"Add Comment Here..."}
            onChange={(e) => handleOnChange(e, uniqueId, "commentFeild")}
          />
          {labelValue?.length === 500 && (
            <p className={labelValue?.length === 500 ? "error" : "errornot"}>
              Character limit exceeded.
            </p>
          )}

          <div className="customMultiLable">
            <FormDropZone
              isMultipleIcon={true}
              paraText1={"Drop the files here ..."}
              paraText2={"Drop the files here, "}
              paraText3={"or Browse"}
              handleFileChange={(photo, fileDataId) =>
                setImage(photo, fileDataId, uniqueId)
              }
              fileType={10}
              imageUrl={filesUrl}
              deleteImageURL={(url, index) =>
                deleteImageURLS(url, index, uniqueId)
              }
              id="uploadMedia"
              key="uploadMedia"
              isMuliple={true}
              isDoc={false}
              isImage={true}
              allFiles={false}
            />
            
          </div>
        </Grid>

        <div className="container inVoledParty">
          <FormLabels label="Involved Party Type" labelPlacement="start" />
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            className="radioGroup"
          >
            {InspectionInvolvedPartyOptionData &&
              InspectionInvolvedPartyOptionData.map((item) => (
                <RadioButton
                  key={item.key}
                  checked={involdevPatyValue === item.value}
                  value={item.value}
                  label={item.label}
                  onChange={(e) =>
                    handleOnChange(e, uniqueId, "Involved Party Type")
                  }
                />
              ))}
          </RadioGroup>
        </div>

        {involdevPatyValue === "Employee" && (
          <>
            <Grid item container mt={3} spacing={2}>
              <Grid item lg={4} md={9} xs={12}>
                <FormControl fullWidth>
                  <FormLabels label="Add Involved Employee" />
                  <SearchAutoComplete
                    optionsData={isLoading ? [] : allUsers?.users ?? []}
                    value={employeeValue[0] ?? ""}
                    onChange={(event, value) =>
                      handleOnChange(value, uniqueId, "Add Involved Employee")
                    }
                    name="Add Involved Party"
                    typeOnchange={(event, value) =>
                      handleAllUserSearch(
                        event,
                        USER_TYPE.NONPRIVILEGED,
                        0,
                        0,
                        assetId,
                        0
                      )
                    }
                    isKeyValuePair={true}
                    isLoading={isLoading}
                    autoCompClear={autoCompInspector}
                  />
                </FormControl>
              </Grid>
              <Grid md={9} lg={9} mt={3} ml={2} xs={12}>
                <UsersChip
                  userList={employeeValueCheck}
                  handleDelete={(e) => deleteuser(e, uniqueId)}
                />
              </Grid>
            </Grid>
          </>
        )}

        {involdevPatyValue === "Sub-Contractor" && (
          <>
            <Grid item container mt={3} spacing={2}>
              <Grid item lg={4} md={9} xs={12}>
                <FormControl fullWidth>
                  <FormLabels label="Add Involved Company" />
                  <SearchAutoComplete
                    optionsData={isLoading ? [] : allUsers?.users ?? []}
                    value={subcontractorValue[0] ?? ""}
                    onChange={(event, value) =>
                      handleOnChange(value, uniqueId, "Add Involved Company")
                    }
                    name="Add Involved Party"
                    typeOnchange={(event, value) =>
                      handleAllUserSearch(
                        event,
                        USER_TYPE.CONTRACTOR,
                        0,
                        0,
                        assetId,
                        0
                      )
                    }
                    isKeyValuePair={true}
                    isLoading={isLoading}
                    autoCompClear={autoCompInspector}
                  />
                </FormControl>
              </Grid>
              <Grid md={9} lg={9} mt={3} ml={2} xs={12}>
                <UsersChip
                  userList={subcontractorValueCheck}
                  handleDelete={(e) => deleteSubContractor(e, uniqueId)}
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(AcceptableBoxDetails);
