import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import SearchIcon from "@mui/icons-material/Search";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { ORG_ID, PAGE_SIZE } from "../../../../constant/constant";
import DialogBox from "../../../../library/common/DialogBox";
import GridTable from "../../../../library/custom/gridTable/GridTable";

export default function IndividualUserGroupList({
  individualUserGroupData,
  getUserGroupListsData,
  loading,
  itemsCount,
  leaveGroup,
}) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const [openModal, setOpenModal] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [individualUserGroupList, setIndividualUserGroupList] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    userId: localStorage.getItem("userId"),
    orgId: ORG_ID(),
  });

  const deletehandle = () => {
    setOpenModal(false);
    leaveGroup(groupId);
  };


  const columns = [
    { field: "groupId", headerName: "GROUP ID", width: 300 },
    { field: "groupName", headerName: "GROUP NAME", width: 300 },
    { field: "joiningDate", headerName: "JOINING DATE", flex: 3 },
  ];

  const handleautoSearch = (event) => {
    let searchitm = "";
    if (event.target.value) {
      searchitm = event.target.value;
    }
    setSearch(searchitm);
    setIndividualUserGroupList({
      ...individualUserGroupList,
      searchitm: event.target.value,
    });
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: event.target.value,
      userId: localStorage.getItem("userId"),
      orgId: ORG_ID(),
    };
    getUserGroupListsData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getUserGroupListsData({
      ...individualUserGroupList,
      page: newPage + 1,
    });
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Users Groups</div>
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Leave"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to leave this User Group?"}
              view
            />
          )}
        </div>

        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div
              className="table_style_ui"
              
            >
              <GridTable
                getRowId={(r) => r.groupId}
                rows={individualUserGroupData ?? []}
                rowCount={itemsCount ?? 0}
                columns={columns}
                loading={loading}
                page={page}
                pagination
                pageSize={PAGE_SIZE}
                rowsPerPageOptions={[PAGE_SIZE]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
