import React, { useState, useEffect } from "react";
import RainFallData from "../components/rainFallData";
import * as swprojectAction from "../../../../redux/storm-water/actions/projectAction";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { ORG_ID } from "../../../../constant/constant";

function RainFallDataContainer() {
  const PAGE_SIZE_30 = 30;
  const dispatch = useDispatch();
  const location = useLocation();
  const ProjectId = location?.state?.projectId;
  const projectName = location?.state?.projectName;
  const projectNumber = location?.state?.projectNumber;
  const customerName = location?.state?.customerName;
  const stateId = location?.state?.stateId ?? location?.state?.stateid;
  const customerId = location?.state?.customerId ?? location?.state?.customerId;

  const swRainFallData = useSelector((state) => state?.swrainFallData);
  const swGetRainFallDataList = (data) => {
    dispatch(swprojectAction.swGetRainfallDataList(data));
  };

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE_30,
    rainfallType: 1,
    projectId: ProjectId,
    fromDate: "",
    toDate: "",
    search: "",
    OrgId: customerId ? customerId : parseInt(ORG_ID()),
  };
  useEffect(() => {
    swGetRainFallDataList(defaultData);
  }, []);
  return (
    <>
      <RainFallData
        swRainFallDatas={swRainFallData}
        swGetRainFallDataList={swGetRainFallDataList}
        projectName={projectName}
        projectNumber={projectNumber}
        customerName={customerName}
        projectId={ProjectId}
        stateId={stateId}
        customerId={customerId}
      />
    </>
  );
}

export default RainFallDataContainer;
