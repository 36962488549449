import React, { useEffect, useState } from "react";
import LmsHeader from "../component/lmsHeader/lmsHeader";
import {
  USERCOURSES,
  USERLESSON,
  DASHBOARD,
  COURSESDETAIL,
} from "../../../constant/routeContant";
import UserCoursesList from "../component/userCoursesList/userCoursesList";
import * as lmsAction from "../../../redux/actions/lmsAction";
import * as lmsUserAction from "../../../redux/actions/lmsUserAction";
import { useDispatch, useSelector } from "react-redux";
import { ORG_ID, USER_ID, API_STATUS } from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { useLocation } from "react-router-dom";
import Loader from "../../../library/common/Loader";
const UserCoursesContainer = () => {
  const dispatch = useDispatch();
  const PAGE_SIZE = 6;
  const coursesUserListData = useSelector((state) => state.getUserCoursesList);

  const categoryListData = useSelector((state) => state.getCategoryList?.data);
  //remove Tabs code Start====================
  const coursesStartData = useSelector((state) => state.startCourse);
  const [courseid, setcourseid] = useState(null);
  const location = useLocation();
  const iscourseNavigatePage = location?.state?.iscourseNavigatePage;
  const [isstart, setisstart] = useState(false);
  const showStartLoader = useSelector((state) => state.startCourse?.isLoading);

  useEffect(() => {
    if (
      coursesStartData?.status === API_STATUS.SUCCESS &&
      (iscourseNavigatePage || isstart)
    ) {
      navigate(COURSESDETAIL, {
        state: { courseId: courseid },
      });
    }
  }, [coursesStartData]);

  const defaultData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    search: "",
    catIds: "",
    statusId: 2,
    userId: USER_ID(),
    orgId: ORG_ID(),
  };
  const defaultCategoryData = {
    pageNumber: 1,
    isGlobal: false,
  };
  let navigate = useNavigate();

  const isLoading = useSelector((state) => state.getUserCoursesList?.isLoading);

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10, 9]);

    if (isAllowedDash === false) {
      navigate(DASHBOARD);
    }
    getCoursesUserListData(defaultData);
    getategoryListData(defaultCategoryData);
  }, []);

  const getCoursesUserListData = (data) => {
    dispatch(lmsUserAction.getUserCoursesList(data));
  };
  const getategoryListData = (data) => {
    dispatch(lmsAction.getCategoryList(data));
  };

  const dataHeader = {
    title: "My Training",
    menu: [
      {
        route: USERCOURSES,
        text: "Courses",
      },
      {
        route: USERLESSON,
        text: "Lessons",
      },
    ],
  };
  const postunEnrollCourse = (data) => {
    dispatch(lmsAction.unEnrollCourse(data));
  };
  return (
    <>
      {" "}
      {showStartLoader && <Loader />}
      <LmsHeader dataHeader={dataHeader} />
      <UserCoursesList
        coursesUserListData={coursesUserListData.data}
        getCoursesUserListData={getCoursesUserListData}
        categoryUserListData={categoryListData}
        isLoading={isLoading}
        isShow={true}
        setcourseid={setcourseid}
        courseid={courseid}
        setisstart={setisstart}
        isstart={isstart}
        postunEnrollCourse={postunEnrollCourse}
      />
    </>
  );
};

export default UserCoursesContainer;
