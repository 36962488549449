import React from "react";
import commonImages from "../../../assets";
import "./noRow.scss";

const NORow = () => {
  return (
    <>
      <div className="noRowBox">
        <div className="noRowImg">
          <img src={commonImages?.NoRow} alt="" />
        </div>
        <div className="noRowTitle">No Data</div>
      </div>
    </>
  );
};

export default NORow;
