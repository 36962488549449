import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AssignUserToolbox from "../component/assignToolBoxTalk/assignUserToolBoxTalk";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import * as userAction from "../../../redux/actions/userAction";
import { useLocation } from "react-router-dom";
import SuccessScreencommon from "../../../library/custom/success/successScreen";
import { TOOL_BOX_LIST } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { API_STATUS, USER_ID } from "../../../constant/constant";
const AssignUserToolboxContainer = () => {
  const dispatch = useDispatch();

  const assignInstructorToUserTool = useSelector(
    (state) => state.assignInstructorToUserTool
  );
  const AssignInstructorToUserIsloading = assignInstructorToUserTool?.isLoading;
  const statuss = assignInstructorToUserTool?.status;
  const cid = assignInstructorToUserTool?.data?.result?.scheduleId;

  const getScheduleMeetingDetailData = useSelector(
    (state) => state.getScheduleMeetingDetail
  );
  const isLoadingSchedueleDetail = getScheduleMeetingDetailData?.isLoading;

  const location = useLocation();
  const scheduleId = location?.state?.scheduleId;
  const assignedOnAdmin = location?.state?.assignedOnAdmin;
  const editMode = location?.state?.editMode;
  const isInstructor = location?.state?.isInstructor;
  const tabId = location?.state?.tabId;

  useEffect(() => {
    if (scheduleId) {
      const dataSchedule = {
        scheduleDate: assignedOnAdmin,
        scheduleId: scheduleId,
        userId: USER_ID(),
        assignType: 1, //
      };
      dispatch(toolboxAction.getScheduleSafetyMeetingDetails(dataSchedule));
    }
  }, []);

  const searchUsers = (
    searchText,
    searchType,
    divisionId,
    searchProjectId,
    priviledgeId
  ) => {
    dispatch(
      userAction.searchAllUsers(
        searchText,
        searchType,
        0,
        searchProjectId,
        1,
        priviledgeId
      )
    );
  };
  // function getAllUsers(searchText, type, divisionId, projectId, priviledgeId) {
  //   dispatch(userAction.searchAllUsers(searchText, type, "0", "0", "11"));
  // }

  function handleAllGroupSearch(serachText, divisionId, alldivision) {
    dispatch(userAction.searchAllGroup(serachText, divisionId, alldivision));
  }
  const allUsersProjectWise = useSelector(
    (state) => state.searchAllUserProjectWise
  );
  const allUsers = useSelector((state) => state.allUsers);
  const [successScreens, setSuccessScreens] = useState(false);
  const isAutoSearchDataLoading = allUsersProjectWise?.isLoading;
  const addAndUpdateAssign = async (data) => {
    setSuccessScreens(true);
    dispatch(toolboxAction.assignInstructorToUserToolBox(data));
  };
  const getProjectSearchList = useSelector(
    (state) => state.getProjectSearchList?.data?.projects
  );

  const getProjectSearch = (data) => {
    dispatch(toolboxAction.getProjectSearchList(data));
  };

  useEffect(() => {
    const defaultData = {};
    getProjectSearch(defaultData);
  }, []);
  const successScree = (id, message, labelmsg) => {
    return (
      <SuccessScreencommon
        isUpdate={message}
        route={TOOL_BOX_LIST}
        id={id}
        label2={labelmsg}
        label1="Safety Meeting Assign ID"
        label4="Safety Meeting Assign in "
      ></SuccessScreencommon>
    );
  };
  const allGroups = useSelector((state) => state.searchAllGroup);
  const isLoading = useSelector((state) => state.searchAllGroup?.isLoading);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const projectData = userInfo?.jobSite;

  return (
    <>
      {AssignInstructorToUserIsloading && <Loader />}
      {isLoadingSchedueleDetail && <Loader />}
      {statuss === API_STATUS.SUCCESS && successScreens ? (
        successScree(cid, "Safety Meeting Assign to Attendee Successfully", "")
      ) : (
        <AssignUserToolbox
          allUsers={allUsers && allUsers?.data}
          isLoading={isAutoSearchDataLoading}
          onSubmit={addAndUpdateAssign}
          projectData={getProjectSearchList}
          idSafety={scheduleId}
          editScheduleMeetingDetailData={getScheduleMeetingDetailData}
          handleAllGroupSearch={handleAllGroupSearch}
          userGroups={allGroups?.data?.groupList}
          handleAllUserSearch={searchUsers}
          editMode={editMode}
          isInstructor={isInstructor}
          tabId={tabId}
          //  getsafetydetailData={getsafetydetailData}
          assignedOnAdmin={assignedOnAdmin}
        />
      )}
    </>
  );
};

export default AssignUserToolboxContainer;
