import { call, put, takeLatest } from "redux-saga/effects";
import { getUserLessonView } from "../../services/userLessonViewService";
import { apiError } from "../../utils/helper";
import * as lmsUserAction from "../actions/lmsUserAction";
import { lmsUserType } from "../constants/lmsUserType";

export function* getUserLessonViewSaga({ lessonId,courseId }) {
  console.debug("getUserLessonView");
  try {
    const { data, status, error } = yield call(
      getUserLessonView,
      lessonId,
      courseId
    );
    if (!data && status !== 200) throw apiError(error);
    const GET_USER_LESSON_VIEW = lmsUserType.GET_USER_LESSON_VIEW;
    yield put(
      lmsUserAction.getUserLessonViewSuccess({
        GET_USER_LESSON_VIEW,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Personal Injury Report Failed" };
    yield put(lmsUserAction.getUserLessonViewFailure(data));
  }
}

const myGetUserLessonView = [
  takeLatest(lmsUserType.GET_USER_LESSON_VIEW, getUserLessonViewSaga),
];
export default myGetUserLessonView;
