import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react'
import "./Accordion.scss";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
const SupportAccordion = ({
    accordionSummarys,
    accordionDetails,
    length,
  }) => {

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = React.useCallback(() => {
      setExpanded((prevState) => !prevState);
    }, []);
  
  return (
    <Accordion
          style={{ width: "100%" }}
          mt={3}
          expanded={expanded}
          className="supportAccordion"
          onChange={() => handleChange()}
        >
          <AccordionSummary
            expandIcon={
              expanded ? (
                <RemoveOutlinedIcon />
              ) : (
                <AddOutlinedIcon />
              )
            }
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
            >
              {accordionSummarys}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "left",
                fontFamily: "lato",
                weight: 400,
                fontSize: "16px",
                color: "#999999",
              }}
            >
              {accordionDetails}
            </p>
          </AccordionDetails>
        </Accordion>
  )
}

export default SupportAccordion