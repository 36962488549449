import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import commonImages from "../../assets";
import ResetPasswordImage from "../../assets/reset-password.png";
import Button from "../../library/button/button";
import Footer from "../../library/footer/footer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./change-password.scss";
import Loader from "../../library/common/Loader";
import { changePassword } from "../../services/loginService";
import { logos } from "../../assets";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [pwdpopMsg, setPwdpopMsg] = useState(false);
  const location = useLocation();
  const mobile = location?.state?.mobile;
  const [checks, setChecks] = useState({
    capsLettercheck: false,
    numberCheck: false,
    pwdlength: false,
    smalllettercheck: false,
    specchar: false,
  });

  let defaultErrorStates = {
    password: true,
    confirmPassword: true,
    oldPassword: true,
  };

  /*
Dynamically update the
form whenever user inputs stuff
*/
  const untouchedForm = useRef(true);
  const isFormValid = useRef(false);
  const [errorList, setErrorList] = useState(defaultErrorStates);

  const style = {
    boxShadow: "rgb(204 204 204) 2px 2px 3px 3px",
    border: "2px rgb(238, 238, 238)",
    padding: "20px",
    marginTop: "-50px",
    marginRight: "-415px",
    position: "absolute",
    marginLeft: "450px",
  };

  const validateForm = () => {
    var capsClass = checks.capsLettercheck ? "valid" : "invalid";
    var numcheckClass = checks.numberCheck ? "valid" : "invalid";
    var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
    var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
    var specClass = checks.specchar ? "valid" : "invalid";
    var passwodval = false;
    if (
      capsClass === "valid" &&
      numcheckClass === "valid" &&
      pwdlenClass === "valid" &&
      sletterClass === "valid" &&
      specClass === "valid" &&
      password.trim().length !== 0
    ) {
      passwodval = true;
    }
    let newErrorList = {
      ...errorList,
      password: passwodval,
      confirmPassword: confirmPassword.trim() === password.trim(),
    };

    setErrorList({ ...newErrorList });

    /* This validation is not based on state */
    let temporaryValidation = true;
    for (const key in newErrorList) {
      temporaryValidation = temporaryValidation && newErrorList[key];
    }
    /*
Saving it in this ref as well for
when we don't want to revalidate the
whole thing
*/
    isFormValid.current = temporaryValidation;
    return temporaryValidation;
  };

  useEffect(() => {
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);
    } else {
      setPwdpopMsg(true);
    }
  }, [
    checks.capsLettercheck,
    checks.numberCheck,
    checks.pwdlength,
    checks.smalllettercheck,
    checks.specchar,
  ]);

  useEffect(() => {
    if (!mobile) {
      window.location.href = "/login";
    }
    if (untouchedForm.current) {
      return;
    }

    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, confirmPassword, mobile]);

  /* Next Button */
  const submitButton = async (event) => {
    event.preventDefault();
    setMsg("");
    untouchedForm.current = false;
    if (validateForm()) {
      setLoading(true);
      untouchedForm.current = true;
      try {
        setLoading(true);
        const result = await changePassword(password);
        if (result) {
          setMsg("Password Reset Successfully!");
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setErrorMsg("Password Reset Failed!");
      }
    }
  };

  const handleOnKeyUp = (e) => {
    const value = e.target.value.trim();
    const capsLettercheck = /[A-Z]/.test(value);
    const smalllettercheck = /[a-z]/.test(value);
    // const numberCheck = /[0-9]/.test(value);
    const numberCheck = /\d/.test(value);
    const pwdlength = value.length >= 8;
    const specchar = /[!@#$`~%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
    const whitespace = /\s/g.test(value);
    setChecks({
      capsLettercheck,
      numberCheck,
      pwdlength,
      smalllettercheck,
      specchar,
    });
    if (whitespace === true) {
      setPassword("");
    }
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);

      errorList.password = true;
    } else {
      setPwdpopMsg(true);
    }
  };
  var capsClass = checks.capsLettercheck ? "valid" : "invalid";
  var numcheckClass = checks.numberCheck ? "valid" : "invalid";
  var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
  var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
  var specClass = checks.specchar ? "valid" : "invalid";

  return (
    <div className="change-password">
      <div className="reset-password-container">
        {/* left */}
        <div className="reset-password-left">
          <div className="logoDark">
            <img className="logo" src={logos?.logoWhite} alt="logo" />
          </div>
          <div className="logoLight">
            <img className="logo" src={logos?.logo} alt="logo" />
          </div>
          <h2 className="reset-password-title">Change Password</h2>
          <form onSubmit={submitButton}>
            <div
              className={[
                "form-wrapper",
                errorList.confirmPassword ? null : "has-error",
              ].join(" ")}
            >
              <label>New Password</label>
              <div className="password-container">
                <input
                  className={[
                    errorList.confirmPassword
                      ? "input-control"
                      : "input-control border-red",
                  ]}
                  type="password"
                  placeholder="*******"
                  value={password}
                  maxLength="20"
                  onChange={(e) => setPassword(e.target.value.trim())}
                  onKeyUp={handleOnKeyUp}
                />

                {password && (
                  <div
                    style={{ display: pwdpopMsg === false ? "none" : "block" }}
                  >
                    <div style={style}>
                      <form>
                        <p style={{ fontWeight: "bold" }}>
                          All checkmarks must turn green.
                        </p>
                        <p className={pwdlenClass}>
                          <CheckCircleIcon className="checkbox" />
                          At least 8 characters
                        </p>
                        <p className={capsClass}>
                          <CheckCircleIcon className="checkbox" />
                          At least 1 uppercase letter
                        </p>
                        <p className={sletterClass}>
                          <CheckCircleIcon className="checkbox" />
                          At least 1 lowercase letter
                        </p>
                        <p className={specClass}>
                          <CheckCircleIcon className="checkbox" />
                          At least 1 special character
                        </p>
                        <p className={numcheckClass}>
                          <CheckCircleIcon className="checkbox" />
                          At least 1 number{" "}
                        </p>
                      </form>
                    </div>
                  </div>
                )}

                {errorList.password ? null : (
                  <div className="show-error">
                    Please enter your new password
                  </div>
                )}
              </div>
            </div>
            <div
              className={[
                "form-wrapper",
                errorList.confirmPassword ? null : "has-error",
              ].join(" ")}
            >
              <label>Confirm Password</label>
              <div className="password-container">
                <input
                  className={[
                    errorList.confirmPassword
                      ? "input-control"
                      : "input-control border-red",
                  ]}
                  type={isRevealPwd ? "text" : "password"}
                  placeholder="Enter Your Password"
                  value={confirmPassword}
                  maxLength="20"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <img
                  className="showHidePassword"
                  title={isRevealPwd ? "Hide password" : "show password"}
                  alt=""
                  src={
                    isRevealPwd
                      ? commonImages.visibilityoff
                      : commonImages.visibility
                  }
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />

                {errorList.confirmPassword ? null : (
                  <div className="show-error">Password do not match</div>
                )}
              </div>
            </div>
            <div className="login-button">
              {!loading ? (
                <Button
                  label="Change Password"
                  onButtonClickHandler={submitButton}
                />
              ) : (
                <Loader />
              )}
            </div>

            {msg && <p className="success">{msg}</p>}
            {errorMsg && <p className="show-error">{errorMsg}</p>}
          </form>
        </div>
        <div className="reset-password-right">
          <img src={ResetPasswordImage} alt="welcome" />
        </div>
      </div>
      <Footer fixed="fixed" />
    </div>
  );
};

export default ChangePassword;
