import { vehicalAccidentType } from "../constants/vehicalAccidentType";

export const vehicalAccident = (data) => ({
  type: vehicalAccidentType.POST_VEHICAL_ACCIDENT_PENDING,
  data,
});

export const vehicalAccidentSuccess = (data) => ({
  type: vehicalAccidentType.POST_VEHICAL_ACCIDENT_SUCCESS,
  data,
});

export const vehicalAccidentFailure = (error) => ({
  type: vehicalAccidentType.POST_VEHICAL_ACCIDENT_FAILURE,
  error,
});

export const getVehicalAccidentDetails = (caseid, incidentid) => ({
  type: vehicalAccidentType.GET_VEHICAL_ACCIDENT,
  caseid,
  incidentid,
});

export const getVehicalAccidentSuccess = (data) => ({
  type: vehicalAccidentType.GET_VEHICAL_ACCIDENT_SUCCESS,
  data,
});

export const getVehicalAccidentFailure = (error) => ({
  type: vehicalAccidentType.GET_VEHICAL_ACCIDENT_FAILURE,
  error,
});

export const updateVehicalAccident = (data) => ({
  type: vehicalAccidentType.PUT_VEHICAL_ACCIDENT_PENDING,
  data,
});

export const updateVehicalAccidentSuccess = (data) => ({
  type: vehicalAccidentType.PUT_VEHICAL_ACCIDENT_SUCCESS,
  data,
});

export const updateVehicalAccidentFailure = (error) => ({
  type: vehicalAccidentType.PUT_VEHICAL_ACCIDENT_FAILURE,
  error,
});

export const getRegNumber = (searchText) => ({
  type: vehicalAccidentType.GET_REG_NUMBER,
  searchText,
});

export const getRegNumberSuccess = (data) => ({
  type: vehicalAccidentType.GET_REG_NUMBER_SUCCESS,
  data,
});

export const getRegNumberFailure = (error) => ({
  type: vehicalAccidentType.GET_REG_NUMBER_FAILURE,
  error,
});

export const clearVehicleAccident = () => ({
  type: vehicalAccidentType.CLEAR_VEHICAL_ACCIDENT,
  data: {},
});
