import * as React from "react";
import "./GridTable.scss";
import { DataGrid } from "@mui/x-data-grid";

function GridTable({
  rows,
  columns,
  pageSize,
  rowsPerPageOptions,
  onPageChange,
  onClick,
  onRowClick,
  checkboxSelection,
  loading,
  components,
  disableSelectionOnClick,
  onSelectionModelChange,
  rowCount,
  getRowId,
  isRowSelectable,
  hideFooterPagination = false,
  selectionModel,
  RowClassName,
  disableColumnSelector = true,
  disableColumnFilter = true,
  disableColumnMenu = true,
  initialState = "",
  onColumnVisibilityModelChange = "",
  onPageSizeChange,
}) {
  return (
    <DataGrid
      className="overrideDataGrid"
      getRowId={getRowId}
      selectionModel={selectionModel}
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
      isRowSelectable={isRowSelectable}
      onPageChange={onPageChange}
      disableColumnSelector={disableColumnSelector}
      disableColumnFilter={disableColumnFilter}
      disableColumnMenu={disableColumnMenu}
      onCellClick={onClick}
      onRowClick={onRowClick}
      checkboxSelection={checkboxSelection}
      paginationMode="server"
      rowCount={rowCount}
      CanUserAddRows="False"
      IsReadOnly="True"
      onSelectionModelChange={onSelectionModelChange}
      disableSelectionOnClick={disableSelectionOnClick}
      components={components}
      loading={loading}
      hideFooterPagination={hideFooterPagination}
      getRowClassName={RowClassName}
      initialState={initialState}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
    />
  );
}

GridTable.propTypes = {};

export default GridTable;
