import { API_STATUS } from "../../constant/constant";
import { stolenPropertyType } from "../constants/stolenPropertyType";

const initialState = {
  flow: "stolenProperty",
  isError: false,
  isLoading: false,
};

export const stolenPropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case stolenPropertyType.POST_STOLEN_PROPERTY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case stolenPropertyType.POST_STOLEN_PROPERTY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case stolenPropertyType.POST_STOLEN_PROPERTY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case stolenPropertyType.CLEAR_STOLEN_PROPERTY:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getStolenPropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case stolenPropertyType.GET_STOLEN_PROPERTY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case stolenPropertyType.GET_STOLEN_PROPERTY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case stolenPropertyType.GET_STOLEN_PROPERTY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateStolenPropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case stolenPropertyType.PUT_STOLEN_PROPERTY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case stolenPropertyType.PUT_STOLEN_PROPERTY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case stolenPropertyType.PUT_STOLEN_PROPERTY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case stolenPropertyType.CLEAR_STOLEN_PROPERTY:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
