import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GridTable from "../../../library/custom/gridTable/GridTable";
import Buttons from "../../../library/custom/button/Button";
import SettingTable from "../../incidents/incidentList/SettingTable";

import { TableLoader } from "../../../library/common/Loader";
import {
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../constant/constant";
import ReportRowManipulte from "../../incidents/incidentList/ReportRowManipulte";
import NORow from "../../../library/custom/noRow/noRow";
import {
  MY_USER_ADD_CREDENTIAL,
  VIEW_CREDENTIAL,
} from "../../../constant/routeContant";

const CredentialsList = ({
  NavigateAddCredential,
  credentialListData,
  isLoading,
  getUserCredentials,
  isProfile = false,
}) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const handleEditOnclick = (rows) => {
    if (rows.row.isAssignedAdmin) {
      navigate(MY_USER_ADD_CREDENTIAL, {
        state: { credentialId: rows.row.id, configureMode: true },
      });
    } else {
      navigate(MY_USER_ADD_CREDENTIAL, {
        state: {
          credentialId: rows.row.id,
          editMode: true,
          configureMode: false,
        },
      });
    }
  };
  const handleConfigureOnClick = (rows) => {};

  const handleViewOnclick = (rows) => {
    navigate(VIEW_CREDENTIAL, {
      state: { credentialId: rows.row.id, editMode: true },
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(VIEW_CREDENTIAL, {
        state: { credentialId: rows.row.id, editMode: true },
      });
    }
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getUserCredentials({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      search: "",
      orgId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      userId: USER_ID(),
      credentialType: "",
      userType: 0,
    });
  };

  const columns = [
    { field: "id", headerName: "Credential ID", flex: 2.5 },
    { field: "credentialName", headerName: "Credential Name", flex: 3.5 },
    { field: "issuingBody", headerName: "Issuing Body", flex: 3.5 },
    { field: "credentialType", headerName: "Credential Type", flex: 3 },
    { field: "credentialNumber", headerName: "Credential Number", flex: 3.5 },
    { field: "expirationDate", headerName: "Expiration Date", flex: 3.5 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={credentialListData?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={credentialListData?.credentialData ?? []}
          fileNameXL={"Credential-list"}
        />
      ),
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={true}
            edit={true}
            editOnClick={() => handleEditOnclick(rows)}
            viewOnClick={() => handleViewOnclick(rows)}
            configureOnClick={() => handleConfigureOnClick(rows)}
            editLabel={
              rows?.row?.isAssignedAdmin
                ? "Configure Credential"
                : "Edit Credential"
            }
            configureLabel="Configure Credential"
            configure={false}
            download={false}
            viewLabel="View Credential"
            view={true}
          />
        );
      },
    },
  ];

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">My Credentials</div>
          <div>
            <div className="filter_btn">
              <Buttons
                variant="contained"
                type="button"
                name="btn"
                label={"Add Credential"}
                varientAddIconBlue={true}
                onClick={NavigateAddCredential}
              ></Buttons>
            </div>
          </div>
        </div>

        <div className="grid_main_body">
          <div className="desc_box row_uniq">
            <div
              className="table_style_ui"
              
            >
              <GridTable
                getRowId={(r) => r.id}
                columns={columns}
                rows={
                  credentialListData?.credentialData
                    ? credentialListData?.credentialData.map((x, index) => {
                        x.expirationDate = x.expirationDate
                          ? x.expirationDate
                          : "None";
                        x.credentialNumber = x.credentialNumber
                          ? x.credentialNumber
                          : "None";
                        return x;
                      })
                    : []
                }
                onClick={(rows) => onRowClick(rows)}
                rowCount={credentialListData?.noOfRecords ?? 0}
                loading={isLoading}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                rowsPerPageOptions={[PAGE_SIZE]}
                page={page}
                pagination
                pageSize={PAGE_SIZE}
                onPageChange={handlePagignation}
                checkboxSelection={false}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CredentialsList;
