import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import TabPanel from "../tabPanel/tabPanel";
import Popper from "@mui/material/Popper";
import LmsFilterList from "../../../../library/custom/lmsFilterList/lmsFilterList";
import TableCardView from "../../../../library/custom/tableCardView/tableCardView";
import { useNavigate } from "react-router-dom";
import { LESSONDETAIL } from "../../../../constant/routeContant";
import TableCard from "../../../../library/custom/tableCard/tableCard";
import { TableLoader } from "../../../../library/common/Loader";
import { ORG_ID, LOGIN_ORG_ID, USER_ID } from "../../../../constant/constant";
import NORow from "../../../../library/custom/noRow/noRow";
import { useDispatch } from "react-redux";
import * as lmsUserAction from "../../../../redux/actions/lmsUserAction";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import FilterButton from "../../../../library/custom/filterButton/filterButton";

const UserLessonsList = ({
  lessionUserListData,
  getLessionUserListData,
  categoryUserListData,
  isLoading,
  isShow,
  isProfile = false,
  isSafetyLog = false,
  usersId,
  islessonId,
  setislessonId,
  setisstart,
  postunEnrollLesson,
}) => {
  const [value, setValue] = useState(0);
  const [placement, setPlacement] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ searchType: [] });
  const PAGE_SIZE = 6;

  const handleautoSearch = (event) => {
    console.log("PROD DPL LESSION direct check");
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: searchText ? searchText : "",
      catIds: filter?.searchType[0]?.key ? filter?.searchType[0]?.key : "",
      statusId: value === 0 ? 2 : value === 2 ? "" : 1,
      userId: USER_ID(),
      orgId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    };
    getLessionUserListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getLessionUserListData({
      pageNumber: newPage + 1,
      pageSize: PAGE_SIZE,
      search: search ? search : "",
      catIds: filter?.searchType[0]?.key ? filter?.searchType[0]?.key : "",
      // statusId: value === 0 ? 2 : value === 3 ? "" : value - 1,

      statusId:
        !isProfile && isSafetyLog === false
          ? value === 0
            ? 2
            : value === 2
            ? ""
            : 1
          : "",
      userId: isSafetyLog ? usersId : USER_ID(),
      orgId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    setPage(1);
    const { searchType } = filter;

    getLessionUserListData({
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: search ? search : "",
      catIds: filter?.searchType[0]?.key ? filter?.searchType[0]?.key : "",
      statusId: newValue === 0 ? 2 : newValue === 2 ? "" : 1,
      userId: USER_ID(),
      orgId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleFilterClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleSearch = (data) => {
    setAnchorEl(null);
    getLessionUserListData(data);
    setOpen(false);
  };

  const id = open ? "simple-popper" : undefined;

  const navigate = useNavigate();
  console.log("userlesson  reduce tabs");
  const handleViewOnClick = (lessonId, status, statusId) => {
    setislessonId(lessonId);
    if (statusId === 3) {
      // for enroll
      const data = {
        createdBy: parseInt(USER_ID()),
        userType: 2,
        lessonId: lessonId,
        userOrGroupId:  parseInt(USER_ID()),
        orgId: parseInt(ORG_ID()),
      };
      dispatch(lmsAction.assignLesson(data));
    } else if (statusId === 2) {
      // for Start and inprogres
      setisstart(true);
      const data = {
        lessonId: lessonId,
        userId: parseInt(USER_ID()),
        orgId: parseInt(ORG_ID()),
      };
      dispatch(lmsUserAction.startLesson(data));
    } else {
      // inprogress
      navigate(LESSONDETAIL, {
        state: { lessonId: lessonId },
      });
    }

    if (statusId === 3) {
      // Enroll
      setTimeout(() => {
        const data1 = {
          pageNumber: 1,
          pageSize: PAGE_SIZE,
          search: "",
          catIds: "",
          statusId: value === 0 ? 2 : value === 2 ? "" : 1,
          userId: parseInt(USER_ID()),
          orgId: parseInt(ORG_ID()),
        };
        getLessionUserListData(data1);
      }, 2000);
    }
  };
  const viewUnEnrollOnClick = (lessonId, status, statusId) => {
    const DataUnEnrollLesson = {
      lessonId: lessonId,
      userId: parseInt(USER_ID()),
      modifiedBy: parseInt(USER_ID()),
    };

    postunEnrollLesson(DataUnEnrollLesson);

    setTimeout(() => {
      const data1 = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: "",
        catIds: "",
        statusId: value === 0 ? 2 : value === 2 ? "" : 1,
        userId: parseInt(USER_ID()),
        orgId: parseInt(ORG_ID()),
      };

      getLessionUserListData(data1);
    }, 2000);
  };
  const columns = [
    {
      field: "lessonId",
      width: "fit-content",
      cellClassName: "customCellTable custom",
      renderCell: (cellValues) => {
        const { id: lessonIDS } = cellValues;
        const lessonId=lessonIDS.split("|")?.[0];
        const status = cellValues?.row?.status;
        const statusId = cellValues?.row?.statusId;
        return (
          <>
            <TableCard
              viewOnClick={() => handleViewOnClick(lessonId, status, statusId)}
              rowData={cellValues?.row}
              viewEnrollOnClick={() =>
                   viewUnEnrollOnClick(lessonId, status, statusId)
                  }
            />
          </>
        );
      },
    },
  ];
  const noRecourdCount = lessionUserListData?.noOfRecords;

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };
  const handleClick = () => {
    console.log("");
  };
  return (
    <>
      <div className="lessonsList noIn">
        {isShow ? (
          <Grid container className="tabuserlmstab">
            <Grid lg={7} xs={12}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="scrollable auto tabs example"
                  //variant="scrollable"
                  //  scrollButtons="auto"
                >
                  <Tab
                    className="tabcustom"
                    icon={
                      value === 0 ? (
                        <div className="totalV">
                          {value === 0 ? noRecourdCount : ""}
                        </div>
                      ) : (
                        ""
                      )
                    }
                    iconPosition="end"
                    label="Assigned"
                  />

                  {/* <Tab
                    className="tabcustom"
                    icon={
                      value === 1 ? (
                        <div className="totalV">
                          {value === 1 ? noRecourdCount : ""}
                        </div>
                      ) : (
                        ""
                      )
                    }
                    iconPosition="end"
                    label="In progress"
                  /> */}
                  <Tab
                    className="tabcustom"
                    icon={
                      value === 1 ? (
                        <div className="totalV">
                          {value === 1 ? noRecourdCount : ""}
                        </div>
                      ) : (
                        ""
                      )
                    }
                    iconPosition="end"
                    label="Completed"
                  />
                  <Tab
                    className="tabcustom"
                    icon={
                      value === 2 ? (
                        <div className="totalV">
                          {value === 2 ? noRecourdCount : ""}
                        </div>
                      ) : (
                        ""
                      )
                    }
                    iconPosition="end"
                    label="All Lessons"
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid lg={5} xs={12}>
              <div className="rightFilterlms">
                <div className="searchfilter">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div className="searchfilterInput">
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={search}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>
                <div
                  className="filter_btn"
                  onClick={handleFilterClick("bottom-end")}
                >
                  <FilterButton label={"Filters"} handleClick={handleClick} />
                </div>
                <Popper
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  placement={placement}
                >
                  <LmsFilterList
                    filterType={categoryUserListData?.categoryList}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleSearch={handleSearch}
                    tabvalue={value}
                    inputsearch={search}
                    onChangeType={onChangeType}
                    filter={filter}
                    PAGE_SIZE={PAGE_SIZE}
                  />
                </Popper>
              </div>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid container>
          <TabPanel className="tabPanel" value={value} index={0}>
            <TableCardView
              columns={columns}
              rows={lessionUserListData?.lessons ?? []}
              rowCount={lessionUserListData?.noOfRecords ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={1}>
            <TableCardView
              columns={columns}
              rows={lessionUserListData?.lessons ?? []}
              rowCount={lessionUserListData?.noOfRecords ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
              options={{
                headerStyle: { position: "sticky", top: 0 },
                maxBodyHeight: 500,
              }}
            />
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={2}>
            <TableCardView
              columns={columns}
              rows={lessionUserListData?.lessons ?? []}
              rowCount={lessionUserListData?.noOfRecords ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={3}>
            <TableCardView
              columns={columns}
              rows={lessionUserListData?.lessons ?? []}
              rowCount={lessionUserListData?.noOfRecords ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[2]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </TabPanel>
        </Grid>
      </div>
    </>
  );
};

export default UserLessonsList;
