import React from "react";
import { useNavigate } from "react-router-dom";
import "./utilitydashboard.scss";
import Utilitydashboard from "./../component/utilityAdmin/utilityDashboard";
import {
  ADMIN_CATEGORY_CREDENTIALS,
  ADMIN_CATEGORY_INCIDENT,
  ADMIN_CATEGORY_LMS,
  ADMIN_CATEGORY_SUPPORT,
  ADMIN_CATEGORY_ASSETS,
  ADMIN_CATEGORY_CREDENTIALSS,
  ADMIN_CATEGORY_RESOURSE,
  SW_CONFIGURE_PERMITEE_TYPE,
  SW_GENERAL_PERMIT,
  ADMIN_ADD_FILES,
} from "../../../constant/routeContant";

const UtilityContainer = () => {
  const navigate = useNavigate();
  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(ADMIN_CATEGORY_INCIDENT);
    } else if (id === 2) {
      navigate(ADMIN_CATEGORY_LMS);
    } else if (id === 3) {
      navigate(ADMIN_CATEGORY_SUPPORT);
    } else if (id === 4) {
      navigate(ADMIN_CATEGORY_CREDENTIALS);
    } else if (id === 5) {
      navigate(ADMIN_CATEGORY_ASSETS);
    } else if (id === 6) {
      navigate(ADMIN_CATEGORY_CREDENTIALSS);
    } else if (id === 7) {
      navigate(ADMIN_CATEGORY_RESOURSE);
    } else if (id === 8) {
      navigate(ADMIN_ADD_FILES);
    } else if (id === 9) {
      navigate(SW_CONFIGURE_PERMITEE_TYPE);
    } else if (id === 10) {
      navigate(SW_GENERAL_PERMIT);
    }
  };
  return (
    <>
      <div className="dashTitle">Configuration Management</div>
      <Utilitydashboard onClick={onClickHandler} />
    </>
  );
};

export default UtilityContainer;
