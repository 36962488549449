import React, { useState } from "react";
import { Grid } from "@mui/material";
import TabPanel from "../../lms/component/tabPanel/tabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  SWUSERSEARCHBYROLETABS,
  USERSEARCHBYROLETABS,
} from "../../../constant/constant";
import EquipmentTable from "../component/equipments/equipmentTable";
import ProjectTable from "../component/project/projectTable";
import VehicleTable from "../component/vehicles/vehicleTable";
import AssetTable from "../component/assets/assetTable";
import UserGroupList from "../component/groups/userGroupList";
import { showRighSideMenu } from "../../../utils/rolesHelper";
import UserSearchInspectionList from "../component/userSearchByRole/userSearchInspectionList";
import IndividualUserGroupList from "../component/groups/individualUserGroupList";

const AllAssetsUserSearch = ({
  projectsListData,
  getProjectListData,
  isLoading,
  equipmentList,
  getEquipmentList,
  isEquipmentLoading,
  vehicles,
  getVehicleList,
  isVehicleLoading,
  assetList,
  isAssetLoading,
  getAssetListData,
  tabClick,
  isProfile,
  divisionId,
  GroupListData,
  noOfRecords,
  getuserGroupLists,
  loadingGroup,
  divisionDropdown,
  isDivisionDropdown,
  ContractorListData,
  getSwInspectionList,
  getSwInspectionListData,
  autoSearchDataLoading,
  UserDatail,
}) => {
  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }
  const showMenu = showRighSideMenu();

  console.log(ContractorListData, "ContractorListData");
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabClick(event?.target?.id);
  };
  return (
    <Grid container>
      {showMenu?.isSW && (
        <Grid item md={12}>
          <Tabs value={value} onChange={handleChange}>
            {SWUSERSEARCHBYROLETABS?.map((tabItem, i) => {
              return (
                <Tab
                  className="lessonList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        </Grid>
      )}
      {showMenu?.isSafety && (
        <Grid item md={12}>
          <Tabs value={value} onChange={handleChange}>
            {USERSEARCHBYROLETABS?.map((tabItem, i) => {
              return (
                <Tab
                  className="lessonList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        </Grid>
      )}
      {showMenu?.isSafety && (
        <Grid item md={12} mt={1} px={2}>
          <TabPanel value={value} index={0}>
            <ProjectTable
              projectsListData={isLoading ? [] : projectsListData}
              getProjectListData={getProjectListData}
              isProjectsLoading={isLoading}
              filterShow={false}
              isProfile={isProfile}
              divisionId={divisionId}
              divisionDropdown={divisionDropdown}
              isDivisionDropdown={isDivisionDropdown}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EquipmentTable
              equipmentList={equipmentList}
              getEquipmentList={getEquipmentList}
              isLoading={isEquipmentLoading}
              filterShow={false}
              isProfile={isProfile}
              divisionId={divisionId}
              divisionDropdown={divisionDropdown}
              isDivisionDropdown={isDivisionDropdown}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <VehicleTable
              vehicles={vehicles}
              getVehicleList={getVehicleList}
              isLoading={isVehicleLoading}
              filterShow={false}
              isProfile={isProfile}
              divisionId={divisionId}
              divisionDropdown={divisionDropdown}
              isDivisionDropdown={isDivisionDropdown}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AssetTable
              assetList={assetList}
              isAssetLoading={isAssetLoading}
              getAssetListData={getAssetListData}
              filterShow={false}
              isProfile={isProfile}
              divisionId={divisionId}
              divisionDropdown={divisionDropdown}
              isDivisionDropdown={isDivisionDropdown}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <IndividualUserGroupList
              loading={loadingGroup}
              getUserGroupListsData={getuserGroupLists}
              individualUserGroupData={loadingGroup ? [] : GroupListData}
              itemsCount={noOfRecords}
              isShow={false}
              isProfile={false}
            />
          </TabPanel>
        </Grid>
      )}
      {showMenu?.isSW && (
        <Grid item md={12} mt={3} px={2}>
          <TabPanel value={value} index={0}>
            <UserSearchInspectionList
              getSwInspectionList={getSwInspectionList}
              getSwInspectionListData={getSwInspectionListData}
              autoSearchDataLoading={autoSearchDataLoading}
              UserDatail={UserDatail}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <IndividualUserGroupList
              loading={loadingGroup}
              getUserGroupListsData={getuserGroupLists}
              individualUserGroupData={loadingGroup ? [] : GroupListData}
              itemsCount={noOfRecords}
              isShow={false}
              isProfile={false}
            />
          </TabPanel>
        </Grid>
      )}
    </Grid>
  );
};

export default AllAssetsUserSearch;
