import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import ViewcCasetable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { useNavigate } from "react-router-dom";
import { TableLoader } from "../../../../library/common/Loader";
import { PAGE_SIZE } from "../../../../constant/constant";
import { ADMIN_PERSONAL_CONTRACTOR } from "../../../../constant/routeContant";
import DialogBox from "../../../../library/common/DialogBox";
import NORow from "../../../../library/custom/noRow/noRow";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";

export default function PersonalContractorList({
  contractorListData,
  getContractorListData,
  loading,
  getuserLists,
  deleteList,
}) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const deletehandle = () => {
    setOpenModal(false);
    deleteList(selectedRow.id);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: searchText ? searchText : "",
      gradeId: "",
      contractorId: 0,
    };
    getContractorListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getuserLists({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      search: "",
    });
  };

  const handleViewOnClick = () => {};

  const handleOnclick = (rows) => {
    navigate(ADMIN_PERSONAL_CONTRACTOR, {
      state: { contractorPersonnelId:rows.row.id ,contractorId: rows.row.contractorId, editMode: true },
    });
  };
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteIncidents = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const columns = [
    { field: "personnelFirstName", headerName: "First Name", width: 200 },
    { field: "personnelLastName", headerName: "LastName", flex: 2 },
    { field: "personnelPhone", headerName: "Contact", flex: 2 },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={contractorListData?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={contractorListData?.contractorsPersonnelData ?? []}
          fileNameXL={"Contractor List"}
        />
      ),
      width: 170,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={() => handleViewOnClick()}
            editOnClick={() => handleOnclick(rows)}
            deleteOnClick={() => deleteIncidents(rows?.row)}
            edit={true}
            editLabel="Edit Personnel"
            download={false}
            viewLabel="View User"
            view={false}
            deleted={true}
            deleteLabel="Delete Personnel"
          />
        );
      },
    },
  ];

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Personnel Contractor</div>
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              view
              content={"Are you sure you want to delete this Personnel?"}
            />
          )}
          <div></div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div
              className="table_style_ui"
              
            >
              <ViewcCasetable
                getRowId={(r) => r.id}
                rows={contractorListData?.contractorsPersonnelData || []}
                rowCount={contractorListData?.noOfRecords}
                columns={columns}
                loading={loading}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={5}
                rowsPerPageOptions={[10, 20, 30]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
