import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import ViewImages from "../../../case/common/viewImages/viewImages";

export default function ViewPermit({
  permitDetails,
  getPermitType,
  projectName,
  dataBack,
  stateId,
}) {
  const imageSize = {
    size: "small",
  };
  const imageSizeSignature = {
    size: "small",
  };
  const [image, setImage] = useState([]);
  const [signatureImage, setSignatureImage] = useState([]);
  useEffect(() => {
    setImage(permitDetails?.documentUrl);
    setSignatureImage(permitDetails?.signatureUrl);
  }, [permitDetails]);
  useEffect(() => {
    console.log(image);
  }, [image]);

  var permidatatype = getPermitType?.permitType?.filter(
    (val) => val?.key == permitDetails?.permitteeTypeId
  );
  return (
    <>
      <div className="viewOverideClient">
        <BackBtn dataBack={dataBack} />
      </div>
      <div className="viewOverideContact">
        <div className="commonOveride">
          {/* <Typography className="titleDetail">{projectName}</Typography> */}
          <Box className="commoninfoWrap">
            <Box className="commoninfoBox">
              <Box className="commoninfoleft widthIncr">
                <Typography className="title">Details:</Typography>
                <Box className="columns">
                  <Typography className="listView">
                    Permittee ID :
                    <span className="font-w400">{permitDetails?.permitId}</span>
                  </Typography>
                  <Typography className="listView">
                    Permit Type :
                    <span className="font-w400">
                      {permidatatype && permidatatype[0]?.value}
                    </span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Permit Holder :
                    <span className="font-w400">
                      {permitDetails?.holderName}
                    </span>
                  </Typography>
                  <Typography className="listView">
                    Permit Number :
                    <span className="font-w400">
                      {permitDetails?.permitNumber}
                    </span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Project Name :
                    <span className="font-w400">{projectName}</span>
                  </Typography>
                  <Typography className="listView">
                    State:
                    <span className="font-w400"> {permitDetails?.state}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Approval Date :
                    <span className="font-w400">
                      {permitDetails?.approvalDate}
                    </span>
                  </Typography>
                  <Typography className="listView">
                    Project Permit Expiration Date:
                    <span className="font-w400">
                      {permitDetails?.expirationDate}
                    </span>
                  </Typography>
                </Box>
                <Box className="columns noWrapText">
                  <Typography className="listView">
                    General Permit Expiration Date:
                    <span className="font-w400">
                      {permitDetails?.sitePermitExpirationDate}
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <Typography className="listView ">
                    Permit :
                    <span className="blockView">
                      <ViewImages
                        image={image?.length > 0 ? [image] : []}
                        imageSize={imageSize}
                      />
                    </span>
                  </Typography>
                </Box>
                {stateId === 3 &&
                permidatatype &&
                (permidatatype[0]?.value === "primary" ||
                  permidatatype[0]?.value === "Primary") ? (
                  <Box>
                    <Typography className="listView ">
                      Signature :
                      <span className="blockView">
                        <ViewImages
                          image={
                            signatureImage?.length > 0 ? [signatureImage] : []
                          }
                          imageSize={imageSizeSignature}
                        />
                      </span>
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}
