export const personalInjuryIncidentReportType = {
  GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT_PENDING:
    "[GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT][REQUEST] GET PERSONAL INJURY INCIDENT REPORT COUNT Requested",
  GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT_FAILURE:
    "[GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT][RESPONSE] GET PERSONAL INJURY INCIDENT REPORT COUNT Failed",
  GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT_SUCCESS:
    "[GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT][RESPONSE] GET PERSONAL INJURY INCIDENT REPORT COUNT  Successfull",
  PERSONAL_INJURY_INCIDENT_REPORT_COUNT_PAGE:
    "PERSONAL INJURY INCIDENT REPORT COUNT",

  GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST_PENDING:
    "[GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST][REQUEST] GET PERSONAL INJURY INCIDENT REPORT LIST Requested",
  GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST_FAILURE:
    "[GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST][RESPONSE] GET PERSONAL INJURY INCIDENT REPORT LIST Failed",
  GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST_SUCCESS:
    "[GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST][RESPONSE] GET PERSONAL INJURY INCIDENT REPORT LIST  Successfull",
  PERSONAL_INJURY_INCIDENT_REPORT_LIST_PAGE:
    "PERSONAL INJURY INCIDENT REPORT LIST",
};
