export const lmsType = {
  GET_COURSES_LIST_PENDING:
    "[GET_COURSES_LIST][REQUEST] Get Courses list requested",
  GET_COURSES_LIST_FAILURE:
    "[GET_COURSES_LIST][RESPONSE] Get Courses list Failed",
  GET_COURSES_LIST_SUCCESS:
    "[GET_COURSES_LIST][RESPONSE] CGet Courses list Successfull",
  CASE_PAGE: "CoursesPage",

  GET_LESSONS_LIST_PENDING:
    "[GET_LESSONS_LIST][REQUEST] Get Lessons list requested",
  GET_LESSONS_LIST_FAILURE:
    "[GET_LESSONS_LIST][RESPONSE] Get Lessons list Failed",
  GET_LESSONS_LIST_SUCCESS:
    "[GET_LESSONS_LIST][RESPONSE] Get Lessons list Successfull",
  LESSONS_PAGE: "LessonsPage",

  GET_CATEGORY_LIST_PENDING:
    "[GET_CATEGORY_LIST][REQUEST] Get Category list requested",
  GET_CATEGORY_LIST_FAILURE:
    "[GET_CATEGORY_LIST][RESPONSE] Get Category list Failed",
  GET_CATEGORY_LIST_SUCCESS:
    "[GET_CATEGORY_LIST][RESPONSE] Get Category list Successfull",
  CATEGORY_PAGE: "CategoryPage",

  POST_COURSES_PENDING: "[COURSES][REQUEST] Create Courses",
  POST_COURSES_FAILURE: "[COURSES][RESPONSE] Courses Creation Failed",
  POST_COURSES_SUCCESS: "[COURSES][RESPONSE] Courses Created Successfull",
  CREATE_PAGE: "CreatePage",

  UPDATE_COURSES_PENDING: "UPDATE_COURSES_PENDING",
  UPDATE_COURSES_SUCCESS: "UPDATE_COURSES_SUCCESS",
  UPDATE_COURSES_FAILED: "UPDATE_COURSES_FAILED",
  UPDATE_PAGE: "UpdatePage",

  GET_LESSON_DROP_DOWN_LIST_PENDING:
    "[GET_LESSON_DROP_DOWN_LIST][REQUEST] Get Lessons list requested",
  GET_LESSON_DROP_DOWN_LIST_FAILURE:
    "[GET_LESSON_DROP_DOWN_LIST][RESPONSE] Get Lessons list Failed",
  GET_LESSON_DROP_DOWN_LIST_SUCCESS:
    "[GET_LESSON_DROP_DOWN_LIST][RESPONSE] Get Lessons list Successfull",
  LESSON_DROP_DOWN_PAGE: "LessonPage",

  GET_COURSE_DETAILS_PENDING: "GET_COURSE_DETAILS_PENDING",
  GET_COURSE_DETAILS_SUCCESS: "GET_COURSE_DETAILS_SUCCESS",
  GET_COURSE_DETAILS_FAILED: "GET_COURSE_DETAILS_FAILED",
  // unmounted revoke
  REVOKE_DATA_UNMOUNT: "REVOKE_DATA_UNMOUNT",

  DELETE_COURSE_PENDING: "DELETE_COURSE_PENDING",
  DELETE_COURSE_SUCCESS: "DELETE_COURSE_SUCCESS",
  DELETE_COURSE_FAILED: "DELETE_COURSE_FAILED",

  POST_LESSON_PENDING: "[LESSON][REQUEST] Create Lesson",
  POST_LESSON_FAILURE: "[LESSON][RESPONSE] Lesson Creation Failed",
  POST_LESSON_SUCCESS: "[LESSON][RESPONSE] Lesson Created Successfull",
  CREATE_LESSON_PAGE: "CreateLessonPage",

  GET_LESSON_DETAILS_PENDING: "GET_LESSON_DETAILS_PENDING",
  GET_LESSON_DETAILS_SUCCESS: "GET_LESSON_DETAILS_SUCCESS",
  GET_LESSON_DETAILS_FAILED: "GET_LESSON_DETAILS_FAILED",

  UPDATE_LESSON_PENDING: "UPDATE_LESSON_PENDING",
  UPDATE_LESSON_SUCCESS: "UPDATE_LESSON_SUCCESS",
  UPDATE_LESSON_FAILED: "UPDATE_LESSON_FAILED",

  DELETE_LESSON_PENDING: "DELETE_LESSON_PENDING",
  DELETE_LESSON_SUCCESS: "DELETE_LESSON_SUCCESS",
  DELETE_LESSON_FAILED: "DELETE_LESSON_FAILED",

  POST_PUBLISH_LESSON_PENDING: "[PUBLISH_LESSON][REQUEST] Publish Lesson",
  POST_PUBLISH_LESSON_FAILURE:
    "[PUBLISH_LESSON][RESPONSE] Publish Lesson Failed",
  POST_PUBLISH_LESSON_SUCCESS:
    "[PUBLISH_LESSON][RESPONSE] Publish Lesson Successfull",
  PUBLISH_LESSON_PAGE: "PUBLISH_LESSON",

  POST_PUBLISH_COURSES_PENDING: "[PUBLISH_COURSES][REQUEST] Publish Course",
  POST_PUBLISH_COURSES_FAILURE:
    "[PUBLISH_COURSES][RESPONSE] Publish Course Failed",
  POST_PUBLISH_COURSES_SUCCESS:
    "[PUBLISH_COURSES][RESPONSE] Publish Course Successfull",
  PUBLISH_COURSES_PAGE: "PUBLISH_COURSES",

  GET_COURSE_ASSIGN_LIST_PENDING:
    "[GET_COURSE_ASSIGN_LIST][REQUEST] Get Assign list requested",
  GET_COURSE_ASSIGN_LIST_FAILURE:
    "[GET_COURSE_ASSIGN_LIST][RESPONSE] Get Assign list Failed",
  GET_COURSE_ASSIGN_LIST_SUCCESS:
    "[GET_COURSE_ASSIGN_LIST][RESPONSE] CGet Assign list Successfull",
  ASSIGN_PAGE: "ASSIGN_PAGE",

  POST_ASSIGN_LESSON_PENDING: "[ASSIGN_LESSON][REQUEST] Assign Lesson",
  POST_ASSIGN_LESSON_FAILURE: "[ASSIGN_LESSON][RESPONSE] Lesson Assign Failed",
  POST_ASSIGN_LESSON_SUCCESS:
    "[ASSIGN_LESSON][RESPONSE] Lesson Assign Successfull",
  ASSIGN_LESSON_PAGE_POST: "AssignLessonPage",

  POST_ASSIGN_COURSE_PENDING: "[ASSIGN_COURSE][REQUEST] Assign Course",
  POST_ASSIGN_COURSE_FAILURE: "[ASSIGN_COURSE][RESPONSE] Course Assign Failed",
  POST_ASSIGN_COURSE_SUCCESS:
    "[ASSIGN_COURSE][RESPONSE] Course Assign Successfull",
  ASSIGN_COURSE_PAGE: "AssignCoursePage",

  GET_LESSON_ASSIGN_LIST_PENDING:
    "[GET_LESSON_ASSIGN_LIST][REQUEST] Get Assign lESSON list requested",
  GET_LESSON_ASSIGN_LIST_FAILURE:
    "[GET_LESSON_ASSIGN_LIST][RESPONSE] Get Assign lESSON list Failed",
  GET_LESSON_ASSIGN_LIST_SUCCESS:
    "[GET_LESSON_ASSIGN_LIST][RESPONSE] CGet Assign lESSONlist Successfull",
  ASSIGN_LESSON_PAGE: "ASSIGN_LESSON_PAGE_LIST",

  POST_COMPLETE_LESSON_PENDING: "[COMPLETE_LESSON][REQUEST] Complete Lesson",
  POST_COMPLETE_LESSON_FAILURE:
    "[COMPLETE_LESSON][RESPONSE] Complete Lesson  Failed",
  POST_COMPLETE_LESSON_SUCCESS:
    "[COMPLETE_LESSON][RESPONSE] Complete Lesson Successfull",
  COMPLETE_PAGE: "CompleteLessonPage",

  GET_LESSON_ASSIGN_ALERT_LIST_PENDING:
    "[GET_ALERT_LESSON_ASSIGN_LIST][REQUEST] Get Assign lESSON list requested",
  GET_LESSON_ASSIGN_ALERT_LIST_FAILURE:
    "[GET_ALERT_LESSON_ASSIGN_LIST][RESPONSE] Get Assign lESSON list Failed",
  GET_LESSON_ASSIGN_ALERT_LIST_SUCCESS:
    "[GET_ALERT_LESSON_ASSIGN_LIST][RESPONSE] CGet Assign lESSONlist Successfull",
  ASSIGN_LESSON_ALERT_PAGE: "ASSIGN_LESSON_ALERT_PAGE",

  GET_LESSONS_COMPLETED_STATUS_PENDING:
    "[GET_LESSONS_COMPLETED_STATUS_PENDING][REQUEST] Get Lessons COMPLETED Status requested",
  GET_LESSONS_COMPLETED_STATUS_FAILURE:
    "[GET_LESSONS_COMPLETED_STATUS_FAILURE][RESPONSE] Get Lessons COMPLETED Status Failed",
  GET_LESSONS_COMPLETED_STATUS_SUCCESS:
    "[GET_LESSONS_COMPLETED_STATUS_SUCCESS][RESPONSE] Get Lessons COMPLETED Status Successfull",
  LESSONS_COMPLETED_STATUS_PAGE: "LessonscOMPLETEDPage",
  CLEAR_ASSIGN_LESSON_STATUS: "CLEAR_ASSIGN_LESSON_STATUS",

  POST_LESSON_UNASSIGN_PENDING:
    "[LESSON_UNASSIGN][REQUEST] Create UnAssignLesson",
  POST_LESSON_UNASSIGN_FAILURE:
    "[LESSON_UNASSIGN][RESPONSE] Lesson UnAssignLesson Failed",
  POST_LESSON_UNASSIGN_SUCCESS:
    "[LESSON_UNASSIGN][RESPONSE] Lesson UnAssignLesson Successfull",
  CREATE_PAGE_LESSONUNASSIGN: "CreatePageUnassignLesson",

  POST_COURSE_UNASSIGN_PENDING:
    "[LESSON_UNASSIGN][REQUEST] Create UnAssignCourse",
  POST_COURSE_UNASSIGN_FAILURE:
    "[LESSON_UNASSIGN][RESPONSE] Lesson UnAssignCourse Failed",
  POST_COURSE_UNASSIGN_SUCCESS:
    "[LESSON_UNASSIGN][RESPONSE] Lesson UnAssignCourse Successfull",
  CREATE_PAGE_COURSEUNASSIGN: "CreatePageUnAssignCourse",

  GET_COURSES_COMPLETED_STATUS_PENDING:
    "[GET_COURSES_COMPLETED_STATUS_PENDING][REQUEST] Get Courses COMPLETED Status requested",
  GET_COURSES_COMPLETED_STATUS_FAILURE:
    "[GET_COURSES_COMPLETED_STATUS_FAILURE][RESPONSE] Get Courses COMPLETED Status Failed",
  GET_COURSES_COMPLETED_STATUS_SUCCESS:
    "[GET_COURSES_COMPLETED_STATUS_SUCCESS][RESPONSE] Get Courses COMPLETED Status Successfull",
  COURSES_COMPLETED_STATUS_PAGE: "CoursecOMPLETEDPage",
  CLEAR_ASSIGN_COURSE_STATUS: "CLEAR_ASSIGN_COURSE_STATUS",

  POST_LESSON_UNENROLL_PENDING:
    "[LESSON_UNENROLL][REQUEST] Create UnUnenrollLesson",
  POST_LESSON_UNENROLL_FAILURE:
    "[LESSON_UNENROLL][RESPONSE] Lesson UnEnrollLesson Failed",
  POST_LESSON_UNENROLL_SUCCESS:
    "[LESSON_UNENROLL][RESPONSE] Lesson UnEnrollLesson Successfull",
  CREATE_PAGE_LESSONUNENROLL: "CreatePageUnEnrollLesson",

  POST_COURSE_UNENROLL_PENDING:
    "[COURSE_UNENROLL][REQUEST] Create UnUnenrollCourse",
  POST_COURSE_UNENROLL_FAILURE:
    "[COURSE_UNENROLL][RESPONSE] Course UnEnrollCourse Failed",
  POST_COURSE_UNENROLL_SUCCESS:
    "[COURSE_UNENROLL][RESPONSE] Course UnEnrollCourse Successfull",
  CREATE_PAGE_COURSEUNENROLL: "CreatePageUnEnrollCourse",
};
