import { ORG_ID } from "../../constant/constant";
import { getApiUrl } from "../../config";
import { get, post } from "../axiosRequest";

export const organisationSummaryList = async ({ data }) => {
  const { FromDate, ToDate, Top, Page, PageSize } = data;

  const url = `${
    getApiUrl().adminSWApiUrl
  }Report/OrganizationSummaryReport?FromDate=${FromDate}&ToDate=${ToDate}&Top=${Top}&Page=${Page}&PageSize=${PageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const inspectorSummaryList = async ({ data }) => {
  const { CustomerId, FromDate, ToDate, Top, Page, PageSize } = data;

  const url = `${
    getApiUrl().adminSWApiUrl
  }Report/InspectorSummaryReport?CustomerId=${CustomerId}&FromDate=${FromDate}&ToDate=${ToDate}&Top=${Top}&Page=${Page}&PageSize=${PageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const dateWiseSummaryList = async ({ data }) => {
  const { CustomerId, FromDate, ToDate, Page, PageSize } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Report/InspectionDatewiseSummaryReport?CustomerId=${CustomerId}&FromDate=${FromDate}&ToDate=${ToDate}&Page=${Page}&PageSize=${PageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const perDayInspectorSummaryList = async ({ data }) => {
  const { CustomerId, FromDate, ToDate, Page, PageSize } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Report/InspectionPerDayPerInspectorReport?CustomerId=${CustomerId}&FromDate=${FromDate}&ToDate=${ToDate}&Page=${Page}&PageSize=${PageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const dateWiseInspectorSummaryList = async ({ data }) => {
  const { CustomerId, FromDate, ToDate, Page, PageSize } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Report/DayWiseInspectorSummaryReport?CustomerId=${CustomerId}&FromDate=${FromDate}&ToDate=${ToDate}&Page=${Page}&PageSize=${PageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const organisationMapList = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Report/MapReport`;
  try {
    return await post(url, null, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
