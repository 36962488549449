import React, { useEffect, useState } from "react";
import ProcoreProjectList from "../component/project/procoreProjectList";
import { useDispatch, useSelector } from "react-redux";
import * as projectAction from "../../../redux/actions/projectAction";
import { ORG_ID } from "../../../constant/constant";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../library/common/Loader";

function ProcoreProjectListContainer() {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);

  const getClientDetsilsData = useSelector(
    (state) => state?.procoreProjectsListData
  );
  const syncProcoreProjectsListData = useSelector(
    (state) => state?.syncProcoreProjectsListData
  );

  const isLoading = useSelector(
    (state) => state?.procoreProjectsListData?.isLoading
  );
  const showLoader = useSelector(
    (state) => state?.syncProcoreProjectsListData?.isLoading
  );
  const getProcoreProjectList = (data) => {
    dispatch(projectAction.getProcoreProjectsList(data));
  };

  const getSyncProcoreProjectsLists = (data) => {
    dispatch(projectAction.getSyncProcoreProjectsList(data));
  };

  useEffect(() => {
    if (
      syncProcoreProjectsListData.status === "SUCCESS" &&
      syncProcoreProjectsListData?.data?.responseMessages?.responseCode ===
        "HBNG001" &&
      isSuccess
    ) {
      toast("Project Maped Successfully");
    }
  }, [syncProcoreProjectsListData]);

  useEffect(() => {
    const defaultData = {
      orgId: ORG_ID(),
    };
    getProcoreProjectList(defaultData);
  }, [dispatch]);

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      {showLoader && <Loader />}
      <ProcoreProjectList
        getClientDetsilsData={getClientDetsilsData}
        isLoading={isLoading}
        getSyncProcoreProjectsLists={getSyncProcoreProjectsLists}
        setIsSuccess={setIsSuccess}
        syncProcoreProjectsListData={syncProcoreProjectsListData}
      />
    </>
  );
}

export default ProcoreProjectListContainer;
