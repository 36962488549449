export const caseType = {
  POST_CASE_PENDING: "[CASE][REQUEST] Create Case",
  POST_CASE_FAILURE: "[CASE][RESPONSE] Case Creation Failed",
  POST_CASE_SUCCESS: "[CASE][RESPONSE] Case Created Successfull",
  PUT_CASE_PENDING: "[CASE][REQUEST] Update Case",
  PUT_CASE_FAILURE: "[CASE][RESPONSE] Case Updation Failed",
  PUT_CASE_SUCCESS: "[CASE][RESPONSE] Case Updation Successfull",
  CLEAR_CASE: "[CLEAR_CASE][REQUEST] Clear CASE",
  GET_CASE_LIST_PENDING: "[GET_CASE_LIST][REQUEST] Get Case list requested",
  GET_CASE_LIST_FAILURE: "[GET_CASE_LIST][RESPONSE] Get Case list Failed",
  GET_CASE_LIST_SUCCESS: "[GET_CASE_LIST][RESPONSE] CGet Case list Successfull",
  CASE_PAGE: "CasePage",
};
