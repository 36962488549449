import * as projectAction from "../../storm-water/actions/projectAction";
import { projectType } from "../../storm-water/types/projectType";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../../../src/utils/helper";
import {
  createProject,
  getProjectDetail,
  updateProject,
  swProjectList,
  postAddContactInProjectService,
  getContactInProjectList,
  swReferenceData,
  updateProjectContactStatus,
  editOneContactInProjectService,
  deleteContactInProject,
  getRainfallData,
  deleteProject,
} from "../../../services/storm-water/projectService";

export function* createProjectSaga(params) {
  console.debug("createRoject");
  try {
    const { data, status, error } = yield call(createProject, params);
    if (!data && status !== 200) throw apiError(error);
    const projectPage = projectType.PROJECT_PAGE;
    yield put(
      projectAction.createProjectSuccess({
        projectPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Project  Creation Failed" };
    yield put(projectAction.createProjectFailure(data));
  }
}

export function* updateProjectSaga(params) {
  try {
    const { data, status, error } = yield call(updateProject, params);
    if (!data && status !== 200) throw apiError(error);
    const projectPage = projectType.PROJECT_PAGE;
    yield put(
      projectAction.updateProjectSuccess({
        projectPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Project Updated Successfull" };
    yield put(projectAction.updateProjectFailure(data));
  }
}

export function* getProjectDetailsSaga(datas) {
  try {
    const { data, status, error } = yield call(getProjectDetail, datas);
    if (!data && status !== 200) throw apiError(error);
    const projectPage = projectType.PROJECT_PAGE;
    yield put(
      projectAction.getProjectDetailsSuccess({
        projectPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Project Details Failed" };
    yield put(projectAction.getProjectDetailsFailure(data));
  }
}
export function* swprojectListSaga(params) {
  try {
    const { data, status, error } = yield call(swProjectList, params);
    if (!data && status !== 200) throw apiError(error);
    const swprojectPage = projectType.PROJECT_PAGE;
    yield put(
      projectAction.swProjectListSuccess({
        swprojectPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Project List Failed" };
    yield put(projectAction.swProjectListFailure(data));
  }
}

export function* addContactInProjectSaga(params) {
  try {
    const { data, status, error } = yield call(
      postAddContactInProjectService,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const swprojectPage = projectType.PROJECT_PAGE;
    yield put(
      projectAction.postAddContactInProjectSuccess({
        swprojectPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post add contact in project Failed" };
    yield put(projectAction.postAddContactInProjectFailure(data));
  }
}

export function* getContactInProjectListSaga(datas) {
  try {
    const { data, status, error } = yield call(getContactInProjectList, datas);
    if (!data && status !== 200) throw apiError(error);
    const projectPage = projectType.PROJECT_PAGE;
    yield put(
      projectAction.getContactInProjectListSuccess({
        projectPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Contact in Project List Failed" };
    yield put(projectAction.getContactInProjectListFailure(data));
  }
}

export function* updateProjectContactStatusSaga(params) {
  try {
    const { data, status, error } = yield call(
      updateProjectContactStatus,
      params
    );
    if (!data && status !== 200) throw apiError(error);

    yield put(
      projectAction.updateProjectContactStausSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Project Contact Status Successfull" };
    yield put(projectAction.updateProjectContactStausFailure(data));
  }
}

export function* editOneContactInProjectSaga(params) {
  try {
    const { data, status, error } = yield call(
      editOneContactInProjectService,
      params
    );
    if (!data && status !== 200) throw apiError(error);

    yield put(
      projectAction.editOneContactInProjectSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Edit one contact in project Failed" };
    yield put(projectAction.editOneContactInProjectFailure(data));
  }
}

function* deleteContactInProjectSaga(payload) {
  try {
    const { data, status } = yield call(deleteContactInProject, payload);
    yield put(
      projectAction.deleteContactInProjectSuccess({
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Delete Contact Failed" };
    yield put(projectAction.deleteContactInProjectFailure(data));
  }
}

export function* swRainFallDataListSaga(params) {
  try {
    const { data, status, error } = yield call(getRainfallData, params);
    if (!data && status !== 200) throw apiError(error);
    const swRainfallPage = projectType.RAINFALL_PAGE;
    yield put(
      projectAction.swGetRainfallDataListSuccess({
        swRainfallPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get RainfallData List Failed" };
    yield put(projectAction.swGetRainfallDataListFailure(data));
  }
}

function* deleteProjectSaga(payload) {
  try {
    const { data, status } = yield call(deleteProject, payload);

    yield put(
      projectAction.deleteProjectSuccess({
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Delete Project Failed" };
    yield put(projectAction.deleteProjectFailure(data));
  }
}

const myProjectSaga = [
  takeLatest(projectType.POST_PROJECT_PENDING, createProjectSaga),
  takeLatest(projectType.PUT_PROJECT_PENDING, updateProjectSaga),
  takeLatest(projectType.GET_PROJECT_DETAILS_PENDING, getProjectDetailsSaga),
  takeLatest(projectType.GET_SW_PROJECT_LIST_PENDING, swprojectListSaga),
  takeLatest(
    projectType.POST_ADD_CONTACT_IN_PROJECT_PENDING,
    addContactInProjectSaga
  ),
  takeLatest(
    projectType.GET_CONTACT_IN_PROJECT_LIST_PENDING,
    getContactInProjectListSaga
  ),
  takeLatest(
    projectType.UPDATE_PROJECT_CONTACT_STATUS_PENDING,
    updateProjectContactStatusSaga
  ),
  takeLatest(
    projectType.EDIT_ONE_CONTACT_IN_PROJECT_PENDING,
    editOneContactInProjectSaga
  ),
  takeLatest(
    projectType.DELETE_CONTACT_IN_PROJECT_PENDING,
    deleteContactInProjectSaga
  ),
  takeLatest(
    projectType.SW_GET_RAINFALL_DATA_LIST_PENDING,
    swRainFallDataListSaga
  ),
  takeLatest(projectType.SW_DELETE_PROJECT_PENDING, deleteProjectSaga),
];

export default myProjectSaga;
