import { SwInspectionType } from "../types/swInspectionType";

export const getSwQuestionSetList = (data) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_LIST_PENDING,
  data,
});

export const getSwQuestionSetListSuccess = (data) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_LIST_SUCCESS,
  data,
});

export const getSwQuestionSetListFailure = (error) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_LIST_FAILURE,
  error,
});

export const createSwQuestionSet = (data) => ({
  type: SwInspectionType.POST_SW_QUESTION_SET_PENDING,
  data,
});

export const createSwQuestionSetSuccess = (data) => ({
  type: SwInspectionType.POST_SW_QUESTION_SET_SUCCESS,
  data,
});

export const createSwQuestionSetFailure = (error) => ({
  type: SwInspectionType.POST_SW_QUESTION_SET_FAILURE,
  error,
});

export const updateSwQuestionSet = (data) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_PENDING,
  data,
});

export const updateSwQuestionSetSuccess = (data) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_SUCCESS,
  data,
});

export const updateSwQuestionSetFailure = (error) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_FAILURE,
  error,
});

export const getSwQuestionSetDetail = (id) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_DETAILS_PENDING,
  id,
});

export const getSwQuestionSetDetailSuccess = (data) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_DETAILS_SUCCESS,
  data,
});

export const getSwQuestionSetDetailFailure = (error) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_DETAILS_FAILED,
  error,
});

export const updateSwQuestionSetStatus = (data) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_STATUS_PENDING,
  data,
});

export const updateSwQuestionSetStatusSuccess = (data) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_STATUS_SUCCESS,
  data,
});

export const updateSwQuestionSetStatusFailure = (error) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_STATUS_FAILED,
  error,
});

export const clearSwQuestionSet = () => ({
  type: SwInspectionType.CLEAR_SW_QUESTION_SET,
  data: "",
});

export const editSwQuestionSet = (data) => ({
  type: SwInspectionType.EDIT_SW_QUESTION_SET_STATUS,
  data,
});

export const cloneSwQuestionSet = (data) => ({
  type: SwInspectionType.CLONE_SW_QUESTION_SET,
  data,
});

export const cloneSwQuestionSetSuccess = (data) => ({
  type: SwInspectionType.CLONE_SW_QUESTION_SET_SUCCESS,
  data,
});

export const cloneSwQuestionSetFailure = (error) => ({
  type: SwInspectionType.CLONE_SW_QUESTION_SET_FAILURE,
  error,
});

export const clearCloneSwQuestionSet = () => ({
  type: SwInspectionType.CLEAR_CLONE_SW_QUESTION_SET,
  data: "",
});

export const getQuestionSetOnProject = (data) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_ON_PROJECT_PENDING,
  data,
});

export const getQuestionSetOnProjectSuccess = (data) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_ON_PROJECT_SUCCESS,
  data,
});

export const getQuestionSetOnProjectFailure = (error) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_ON_PROJECT_FAILURE,
  error,
});

export const createSwQuestionSetProjectMap = (data) => ({
  type: SwInspectionType.POST_SW_QUESTION_SET_PROJECT_MAP_PENDING,
  data,
});

export const createSwQuestionSetProjectMapSuccess = (data) => ({
  type: SwInspectionType.POST_SW_QUESTION_SET_PROJECT_MAP_SUCCESS,
  data,
});

export const createsSwQuestionSetProjectMapFailure = (error) => ({
  type: SwInspectionType.POST_SW_QUESTION_SET_PROJECT_MAP_FAILURE,
  error,
});

export const updateSwQuestionSetProjectMap = (data) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_PENDING,
  data,
});

export const updateSwQuestionSetProjectMapSuccess = (data) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_SUCCESS,
  data,
});

export const updateSwQuestionSetProjectMapFailure = (error) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_FAILURE,
  error,
});
export const clearSwQuestionSetProjectMap = () => ({
  type: SwInspectionType.CLEAR_SW_QUESTION_SET_PROJECT_MAP,
  data: "",
});

export const updateStatusQuestionSetProjectMap = (data) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_PENDING,
  data,
});

export const updateStatusQuestionSetProjectMapSuccess = (data) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_SUCCESS,
  data,
});

export const updateStatusQuestionSetProjectMapFailure = (error) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_FAILURE,
  error,
});
export const clearUpdateStatusQuestionSetProjectMap = () => ({
  type: SwInspectionType.CLEAR_SW_QUESTION_SET_PROJECT_MAP_STATUS,
  data: "",
});

export const getSwQuestionSetProjectMapDetail = (id) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_PENDING,
  id,
});

export const getSwQuestionSetProjectMapDetailSuccess = (data) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_SUCCESS,
  data,
});

export const getSwQuestionSetProjectMapDetailFailure = (error) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_FAILURE,
  error,
});

export const getSwQuestionSetProjectMapList = (data) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_LIST_PENDING,
  data,
});

export const getSwQuestionSetProjectMapListSuccess = (data) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_LIST_SUCCESS,
  data,
});

export const getSwQuestionSetProjectMapListFailure = (error) => ({
  type: SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_LIST_FAILURE,
  error,
});

export const createSwCategory = (data) => ({
  type: SwInspectionType.POST_SW_CATEGORY_PENDING,
  data,
});

export const createSwCategorySuccess = (data) => ({
  type: SwInspectionType.POST_SW_CATEGORY_SUCCESS,
  data,
});

export const createSwCategoryFailure = (error) => ({
  type: SwInspectionType.POST_SW_CATEGORY_FAILURE,
  error,
});

export const updateSwCategory = (data) => ({
  type: SwInspectionType.UPDATE_SW_CATEGORY_PENDING,
  data,
});

export const updateSwCategorySuccess = (data) => ({
  type: SwInspectionType.UPDATE_SW_CATEGORY_SUCCESS,
  data,
});

export const updateSwCategoryFailure = (error) => ({
  type: SwInspectionType.UPDATE_SW_CATEGORY_FAILURE,
  error,
});

export const getSwCategoryList = (data) => ({
  type: SwInspectionType.GET_SW_CATEGORY_LIST_PENDING,
  data,
});

export const getSwCategoryListSuccess = (data) => ({
  type: SwInspectionType.GET_SW_CATEGORY_LIST_SUCCESS,
  data,
});

export const getSwCategoryListFailure = (error) => ({
  type: SwInspectionType.GET_SW_CATEGORY_LIST_FAILURE,
  error,
});

export const createSwSubCategory = (data) => ({
  type: SwInspectionType.POST_SW_SUB_CATEGORY_PENDING,
  data,
});

export const createSwSubCategorySuccess = (data) => ({
  type: SwInspectionType.POST_SW_SUB_CATEGORY_SUCCESS,
  data,
});

export const createSwSubCategoryFailure = (error) => ({
  type: SwInspectionType.POST_SW_SUB_CATEGORY_FAILURE,
  error,
});

export const updateSwSubCategory = (data) => ({
  type: SwInspectionType.UPDATE_SW_SUB_CATEGORY_PENDING,
  data,
});

export const updateSwSubCategorySuccess = (data) => ({
  type: SwInspectionType.UPDATE_SW_SUB_CATEGORY_SUCCESS,
  data,
});

export const updateSwSubCategoryFailure = (error) => ({
  type: SwInspectionType.UPDATE_SW_SUB_CATEGORY_FAILURE,
  error,
});

export const getSwSubCategoryList = (data) => ({
  type: SwInspectionType.GET_SW_SUB_CATEGORY_LIST_PENDING,
  data,
});

export const getSwSubCategoryListSuccess = (data) => ({
  type: SwInspectionType.GET_SW_SUB_CATEGORY_LIST_SUCCESS,
  data,
});

export const getSwSubCategoryListFailure = (error) => ({
  type: SwInspectionType.GET_SW_SUB_CATEGORY_LIST_FAILURE,
  error,
});

export const createSwQuestion = (data) => ({
  type: SwInspectionType.POST_SW_QUESTION_PENDING,
  data,
});

export const createSwQuestionSuccess = (data) => ({
  type: SwInspectionType.POST_SW_QUESTION_SUCCESS,
  data,
});

export const createSwQuestionFailure = (error) => ({
  type: SwInspectionType.POST_SW_QUESTION_FAILURE,
  error,
});

export const updateSwQuestion = (data) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_PENDING,
  data,
});

export const updateSwQuestionSuccess = (data) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_SUCCESS,
  data,
});

export const updateSwQuestionFailure = (error) => ({
  type: SwInspectionType.UPDATE_SW_QUESTION_FAILURE,
  error,
});

export const getSwQuestionList = (data) => ({
  type: SwInspectionType.GET_SW_QUESTION_LIST_PENDING,
  data,
});

export const getSwQuestionListSuccess = (data) => ({
  type: SwInspectionType.GET_SW_QUESTION_LIST_SUCCESS,
  data,
});

export const getSwQuestionListFailure = (error) => ({
  type: SwInspectionType.GET_SW_QUESTION_LIST_FAILURE,
  error,
});

export const createInspectionType = (data) => ({
  type: SwInspectionType.POST_INSPECTION_TYPE_PENDING,
  data,
});

export const createInspectionTypeSuccess = (data) => ({
  type: SwInspectionType.POST_INSPECTION_TYPE_SUCCESS,
  data,
});

export const createInspectionTypeFailure = (error) => ({
  type: SwInspectionType.POST_INSPECTION_TYPE_FAILURE,
  error,
});

export const updateInspectionType = (data) => ({
  type: SwInspectionType.UPDATE_INSPECTION_TYPE_PENDING,
  data,
});

export const updateInspectionTypeSuccess = (data) => ({
  type: SwInspectionType.UPDATE_INSPECTION_TYPE_SUCCESS,
  data,
});

export const updateInspectionTypeFailure = (error) => ({
  type: SwInspectionType.UPDATE_INSPECTION_TYPE_FAILURE,
  error,
});

export const getInspectionTypeList = (data) => ({
  type: SwInspectionType.GET_INSPECTION_TYPE_LIST_PENDING,
  data,
});

export const getInspectionTypeListSuccess = (data) => ({
  type: SwInspectionType.GET_INSPECTION_TYPE_LIST_SUCCESS,
  data,
});

export const getInspectionTypeListFailure = (error) => ({
  type: SwInspectionType.GET_INSPECTION_TYPE_LIST_FAILURE,
  error,
});

export const getInspectionTypeFieldsList = (data) => ({
  type: SwInspectionType.GET_INSPECTION_TYPE_FEILDS_LIST_PENDING,
  data,
});

export const getInspectionTypeFieldsListSuccess = (data) => ({
  type: SwInspectionType.GET_INSPECTION_TYPE_FEILDS_LIST_SUCCESS,
  data,
});

export const getInspectionTypeFieldsListFailure = (error) => ({
  type: SwInspectionType.GET_INSPECTION_TYPE_FEILDS_LIST_FAILURE,
  error,
});

export const getBindInspectionTypeFromStateList = (data) => ({
  type: SwInspectionType.GET_INSPECTION_TYPE_FROM_STATE_LIST_PENDING,
  data,
});

export const getBindInspectionTypeFromStateListSuccess = (data) => ({
  type: SwInspectionType.GET_INSPECTION_TYPE_FROM_STATE_LIST_SUCCESS,
  data,
});

export const getBindInspectionTypeFromStateListFailure = (error) => ({
  type: SwInspectionType.GET_INSPECTION_TYPE_FROM_STATE_LIST_FAILURE,
  error,
});

export const getInTyFeByInTyList = (data) => ({
  type: SwInspectionType.GET_IN_TY_FE_IN_TY_LIST_PENDING,
  data,
});

export const getInTyFeByInTyListSuccess = (data) => ({
  type: SwInspectionType.GET_IN_TY_FE_IN_TY_LIST_SUCCESS,
  data,
});

export const getInTyFeByInTyListFailure = (error) => ({
  type: SwInspectionType.GET_IN_TY_FE_IN_TY_LIST_FAILURE,
  error,
});

export const updateInspectionTypeFieldsMap = (data) => ({
  type: SwInspectionType.UPDATE_INSPECTION_TYPE_FIELDS_MAP_PENDING,
  data,
});

export const updateInspectionTypeFieldsMapSuccess = (data) => ({
  type: SwInspectionType.UPDATE_INSPECTION_TYPE_FIELDS_MAP_SUCCESS,
  data,
});

export const updateInspectionTypeFieldsMapFailure = (error) => ({
  type: SwInspectionType.UPDATE_INSPECTION_TYPE_FIELDS_MAP_FAILURE,
  error,
});

// User

export const getSwInspectionList = (data) => ({
  type: SwInspectionType.GET_SW_INSPECTION_LIST_PENDING,
  data,
});

export const getSwInspectionListSuccess = (data) => ({
  type: SwInspectionType.GET_SW_INSPECTION_LIST_SUCCESS,
  data,
});

export const getSwInspectionListFailure = (error) => ({
  type: SwInspectionType.GET_SW_INSPECTION_LIST_FAILURE,
  error,
});

export const createSwInspection = (data) => ({
  type: SwInspectionType.POST_SW_INSPECTION_PENDING,
  data,
});

export const createSwInspectionSuccess = (data) => ({
  type: SwInspectionType.POST_SW_INSPECTION_SUCCESS,
  data,
});

export const createSwInspectionFailure = (error) => ({
  type: SwInspectionType.POST_SW_INSPECTION_FAILURE,
  error,
});

export const updateSwInspection = (data) => ({
  type: SwInspectionType.UPDATE_SW_INSPECTION_PENDING,
  data,
});

export const updateSwInspectionSuccess = (data) => ({
  type: SwInspectionType.UPDATE_SW_INSPECTION_SUCCESS,
  data,
});

export const updateSwInspectionFailure = (error) => ({
  type: SwInspectionType.UPDATE_SW_INSPECTION_FAILURE,
  error,
});

export const clearSwInspection = () => ({
  type: SwInspectionType.CLEAR_SW_INSPECTION,
  data: "",
});

export const getSwInspectionDetail = (id) => ({
  type: SwInspectionType.GET_SW_INSPECTION_DETAILS_PENDING,
  id,
});

export const getSwInspectionDetailSuccess = (data) => ({
  type: SwInspectionType.GET_SW_INSPECTION_DETAILS_SUCCESS,
  data,
});

export const getSwInspectionDetailFailure = (error) => ({
  type: SwInspectionType.GET_SW_INSPECTION_DETAILS_FAILED,
  error,
});

export const deleteSwInspection = (payload) => ({
  type: SwInspectionType.DELETE_SW_INSPECTION_PENDING,
  payload: payload,
});

export const deleteSwInspectionSuccess = (payload) => ({
  type: SwInspectionType.DELETE_SW_INSPECTION_SUCCESS,
  payload: payload,
});

export const deleteSwInspectionFailure = (payload) => ({
  type: SwInspectionType.DELETE_SW_INSPECTION_FAILED,
  payload: payload,
});

// primary and secondry inspector

export const getSwInspectorList = (data) => ({
  type: SwInspectionType.GET_SW_INSPECTOR_LIST_PENDING,
  data,
});

export const getSwInspectorListSuccess = (data) => ({
  type: SwInspectionType.GET_SW_INSPECTOR_LIST_SUCCESS,
  data,
});

export const getSwInspectorListFailure = (error) => ({
  type: SwInspectionType.GET_SW_INSPECTOR_LIST_FAILURE,
  error,
});

export const assignSwInspector = (data) => ({
  type: SwInspectionType.POST_ASSIGN_INSPECTOR_PENDING,
  data,
});

export const assignSwInspectorSuccess = (data) => ({
  type: SwInspectionType.POST_ASSIGN_INSPECTOR_SUCCESS,
  data,
});

export const assignSwInspectorFailure = (error) => ({
  type: SwInspectionType.POST_ASSIGN_INSPECTOR_FAILURE,
  error,
});

export const getSwSecondaryInspectorList = (data) => ({
  type: SwInspectionType.GET_SW_SECONDARY_INSPECTOR_LIST_PENDING,
  data,
});

export const getSwSecondaryInspectorListSuccess = (data) => ({
  type: SwInspectionType.GET_SW_SECONDARY_INSPECTOR_LIST_SUCCESS,
  data,
});

export const getSwSecondaryInspectorListFailure = (error) => ({
  type: SwInspectionType.GET_SW_SECONDARY_INSPECTOR_LIST_FAILURE,
  error,
});

export const deleteSwSecondaryInspection = (payload) => ({
  type: SwInspectionType.DELETE_SW_SECONDARY_INSPECTOR_PENDING,
  payload: payload,
});

export const deleteSwSecondaryInspectionSuccess = (payload) => ({
  type: SwInspectionType.DELETE_SW_SECONDARY_INSPECTOR_SUCCESS,
  payload: payload,
});

export const deleteSwSecondaryInspectionFailure = (payload) => ({
  type: SwInspectionType.DELETE_SW_SECONDARY_INSPECTOR_FAILED,
  payload: payload,
});
//sw dashboard
export const getSwDashboardCardDetailsInspection = (data) => ({
  type: SwInspectionType.GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_PENDING,
  data,
});

export const getSwDashboardCardDetailsInspectionSuccess = (data) => ({
  type: SwInspectionType.GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_SUCCESS,
  data,
});

export const getSwDashboardCardDetailsInspectionFailure = (error) => ({
  type: SwInspectionType.GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_FAILURE,
  error,
});

export const createSwOpenItemDetail = (data) => ({
  type: SwInspectionType.POST_OPEN_ITEM_DETAIL_PENDING,
  data,
});

export const createSwOpenItemDetailSuccess = (data) => ({
  type: SwInspectionType.POST_OPEN_ITEM_DETAIL_SUCCESS,
  data,
});

export const createSwOpenItemDetailFailure = (error) => ({
  type: SwInspectionType.POST_OPEN_ITEM_DETAIL_FAILURE,
  error,
});

export const createSwInspectionCloseItem = (data) => ({
  type: SwInspectionType.POST_INSPECTION_CLOSE_ITEM_PENDING,
  data,
});

export const createSwInspectionCloseItemSuccess = (data) => ({
  type: SwInspectionType.POST_INSPECTION_CLOSE_ITEM_SUCCESS,
  data,
});

export const createSwInspectionCloseItemFailure = (error) => ({
  type: SwInspectionType.POST_INSPECTION_CLOSE_ITEM_FAILURE,
  error,
});

export const createSwInspectionItemComment = (data) => ({
  type: SwInspectionType.POST_INSPECTION_ITEM_COMMENT_PENDING,
  data,
});

export const createSwInspectionItemCommentSuccess = (data) => ({
  type: SwInspectionType.POST_INSPECTION_ITEM_COMMENT_SUCCESS,
  data,
});

export const createSwInspectionItemCommentFailure = (error) => ({
  type: SwInspectionType.POST_INSPECTION_ITEM_COMMENT_FAILURE,
  error,
});

// dahsboard

export const deferredSwInspection = (data) => ({
  type: SwInspectionType.POST_DEFERRED_INSPECTION_PENDING,
  data,
});

export const deferredSwInspectionSuccess = (data) => ({
  type: SwInspectionType.POST_DEFERRED_INSPECTION_SUCCESS,
  data,
});

export const deferredSwInspectionFailure = (error) => ({
  type: SwInspectionType.POST_DEFERRED_INSPECTION_FAILURE,
  error,
});

export const getSwOpenItemList = (data) => ({
  type: SwInspectionType.GET_SW_OPEN_ITEM_LIST_PENDING,
  data,
});

export const getSwOpenItemListSuccess = (data) => ({
  type: SwInspectionType.GET_SW_OPEN_ITEM_LIST_SUCCESS,
  data,
});

export const getSwOpenItemListFailure = (error) => ({
  type: SwInspectionType.GET_SW_OPEN_ITEM_LIST_FAILURE,
  error,
});

export const getExternalInspection = (data) => ({
  type: SwInspectionType.GET_EXTERNAL_INSPECTION_PENDING,
  data,
});

export const getExternalInspectionSuccess = (data) => ({
  type: SwInspectionType.GET_EXTERNAL_INSPECTION_SUCCESS,
  data,
});

export const getExternalInspectionFailure = (error) => ({
  type: SwInspectionType.GET_EXTERNAL_INSPECTION_FAILURE,
  error,
});

export const createConductExternalInspection = (data) => ({
  type: SwInspectionType.POST_CONDUCT_EXTERNAL_INSPECTION_PENDING,
  data,
});

export const createConductExternalInspectionSuccess = (data) => ({
  type: SwInspectionType.POST_CONDUCT_EXTERNAL_INSPECTION_SUCCESS,
  data,
});

export const createConductExternalInspectionFailure = (error) => ({
  type: SwInspectionType.POST_CONDUCT_EXTERNAL_INSPECTION_FAILURE,
  error,
});

export const getInspectionHeaderDetails = (data) => ({
  type: SwInspectionType.GET_INSPECTION_HEADER_DETAILS_PENDING,
  data,
});

export const getInspectionHeaderDetailsSuccess = (data) => ({
  type: SwInspectionType.GET_INSPECTION_HEADER_DETAILS_SUCCESS,
  data,
});

export const getInspectionHeaderDetailsFailure = (error) => ({
  type: SwInspectionType.GET_INSPECTION_HEADER_DETAILS_FAILURE,
  error,
});

export const getInspectionDailyReportDetails = (data) => ({
  type: SwInspectionType.GET_INSPECTION_DAILY_REPORT_DETAILS_PENDING,
  data,
});

export const getInspectionDailyReportDetailsSuccess = (data) => ({
  type: SwInspectionType.GET_INSPECTION_DAILY_REPORT_DETAILS_SUCCESS,
  data,
});

export const getInspectionDailyReportDetailsFailure = (error) => ({
  type: SwInspectionType.GET_INSPECTION_DAILY_REPORT_DETAILS_FAILURE,
  error,
});

export const updateInspectionDailyReport = (data) => ({
  type: SwInspectionType.POST_INSPECTION_DAILY_REPORT_PENDING,
  data,
});

export const updateInspectionDailyReportSuccess = (data) => ({
  type: SwInspectionType.POST_INSPECTION_DAILY_REPORT_SUCCESS,
  data,
});

export const updateInspectionDailyReportFailure = (error) => ({
  type: SwInspectionType.POST_INSPECTION_DAILY_REPORT_FAILURE,
  error,
});


export const getBulkInspectionDetails = (data) => ({
  type: SwInspectionType.POST_GET_BULK_INSPECTION_DETAILS_PENDING,
  data,
});

export const getBulkInspectionDetailsSuccess = (data) => ({
  type: SwInspectionType.POST_GET_BULK_INSPECTION_DETAILS_SUCCESS,
  data,
});

export const getBulkInspectionDetailsFailure = (error) => ({
  type: SwInspectionType.POST_GET_BULK_INSPECTION_DETAILS_FAILURE,
  error,
});


export const getBulkInspectionFiles = (data) => ({
  type: SwInspectionType.POST_GET_BULK_INSPECTION_FILES_PENDING,
  data,
});

export const getBulkInspectionFilesSuccess = (data) => ({
  type: SwInspectionType.POST_GET_BULK_INSPECTION_FILES_SUCCESS,
  data,
});

export const getBulkInspectionFilesFailure = (error) => ({
  type: SwInspectionType.POST_GET_BULK_INSPECTION_FILES_FAILURE,
  error,
});
export const getProjectContactsEmailDetails = (data) => ({
  type: SwInspectionType.POST_PROJECT_CONTACTS_EMAIL_DETAILS_PENDING,
  data,
});

export const getProjectContactsEmailDetailsSuccess = (data) => ({
  type: SwInspectionType.POST_PROJECT_CONTACTS_EMAIL_DETAILS_SUCCESS,
  data,
});

export const getProjectContactsEmailDetailsFailure = (error) => ({
  type: SwInspectionType.POST_PROJECT_CONTACTS_EMAIL_DETAILS_FAILURE,
  error,
});