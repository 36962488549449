import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GridTable from "..//..//..//library/custom/gridTable/GridTable";
import ReportRowManipulte from "../shared/ReportRowManipulte";
import SettingTable from "../shared/SettingTable";
import { Box } from "@mui/system";
import DialogIncidentBox from "../../../library/common/CloseDialogBox";
import AlertModalAction from "../shared/AlertModalAction";
import Buttons from "../../../library/custom/button/Button";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import Parser from "html-react-parser";

import {
  EDITCASE,
  NEAR_MISS,
  PERSONAL_INJURY,
  STOLEN_PROPERTY,
  UTILITY_DAMAGE,
  VEHICLE_ACCIDENT,
  VIEW_PERSONAL_INJURY,
} from "../../../constant/routeContant";
import { TableLoader } from "../../../library/common/Loader";
import NORow from "../../../library/custom/noRow/noRow";
import commonImages from "../../../assets";
import { PAGE_SIZE } from "../../../constant/constant";

const CaseIncidentList = (props) => {
  const { isFullAccess, isWriteAccess, isReadAccess } = props;
  const [reportIdOfChekbox, setReportIdOfChekbox] = useState([]);
  const caseDetail = props?.viewCaseData;
  const caseId = props?.viewCaseData?.caseId;
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState();
  const handleClose = () => setOpen(false);

  const handleOnclick = (rows) => {
    if (rows?.incidentId === 2) {
      navigations(VEHICLE_ACCIDENT, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 1) {
      navigations(PERSONAL_INJURY, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 4) {
      navigations(UTILITY_DAMAGE, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 3) {
      navigations(STOLEN_PROPERTY, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 5) {
      navigations(NEAR_MISS, rows?.reportId, rows?.caseId);
    }
  };

  const handleViewOnClick = (rows) => {
    if (rows?.incidentId === 2 || rows?.incidentId === 1 || rows?.incidentId === 4 || rows?.incidentId === 3 || rows?.incidentId === 5) {
      navigations(
        VIEW_PERSONAL_INJURY,
        rows?.reportId,
        rows?.caseId,
        rows?.incidentId
      );
    }
  };

  function navigations(navigationPath, incidentId, caseId, incidentType) {
    navigate(navigationPath, {
      state: {
        caseId: caseId,
        incidentId: incidentId,
        editMode: true,
        isDirect: true,
        incidentType: incidentType,
      },
    });
  }

  const columns = [
    { field: "reportId", headerName: "Report ID", flex: 1 },
    { field: "dateOfEntry", headerName: "Date Of Entry", flex: 1 },
    { field: "incidentType", headerName: "Incident Type", flex: 1 },
    { field: "enteredBy", headerName: "Entered By", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "icon",
      width: 70,
      renderHeader: () => (
        <SettingTable
          noOfRecords={props?.incidentList?.data?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={props?.incidentList?.data?.incident ?? []}
          fileNameXL={"Incident-List"}
        />
      ),

      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={(currentRow) => handleViewOnClick(currentRow)}
            editOnClick={(currentRow) => handleOnclick(currentRow)}
            deleteOnClick={() => deleteIncident(rows?.row)}
            edit={isFullAccess || isWriteAccess}
            view={isReadAccess || isFullAccess || isWriteAccess}
            deleted={isFullAccess}
            editLabel={"Edit Incident"}
            viewLabel={"View Incident"}
            deleteLabel={"Delete Incident"}
            CloseIncident={
              (rows?.row?.statusId !== "1" && rows?.row?.statusId !== "3"
                ? true
                : false) &&
              (isFullAccess || isWriteAccess)
            }
            CloseIncidentLabel={"Close Incident"}
            closeOnClick={() => closeIncidentHandler(rows?.row)}
          />
        );
      },
    },
  ];

  const [openIncidentModal, setOpenIncidentModal] = useState(false);
  const [selectedIncidentRow, setSelectedIncidentRow] = useState("");
  const [actionIds, setActionIds] = useState();
  const [closeDeleteMode, setCloseDeleteMode] = useState(1);

  const closehandle = () => {
    setOpenIncidentModal(false);
    props?.closeIncidents(selectedIncidentRow);
  };

  const closeIncidentHandler = (rows) => {
    setOpenIncidentModal(true);
    setSelectedIncidentRow(rows);
    setActionIds(rows);
    setCloseDeleteMode(1);
  };

  const deletehandle = () => {
    setOpenIncidentModal(false);
    props?.deleteIncidents(selectedIncidentRow);
  };

  const deleteIncident = (rows) => {
    setOpenIncidentModal(true);
    setSelectedIncidentRow(rows);
    setActionIds(rows);
    setCloseDeleteMode(2);
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      handleViewOnClick(rows?.row);
    }
  };

  const handleEdit = () => {
    navigate(EDITCASE, {
      state: {
        caseId: caseId,
        editMode: true,
        isEditModeThroughViewCase: true,
      },
    });
  };

  const handleCaseOnClick = (modalData) => {
    setOpen(true);
    setModalData(modalData);
  };
  const repotedTime = caseDetail?.timeOfCase?.split(":");

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="caseSummaryModal"
      >
        <Grid className="modalContainer">
          <Box className="modalBox">
            <div className="modalHeader">
              <div className="modalHeaderTitle">Past Summaries:</div>
              <div className="modalHeaderClose" onClick={handleClose}>
                X
              </div>
            </div>
            <div className="modalBody modalScroll">
              {modalData?.map((list, i) => (
                <div className="modalBodyList" key={i}>
                  <div className="modalBodyHeader">
                    <div className="modalBodyVersion">
                      Version {modalData?.length - i}
                    </div>
                    <div className="modalBodyDate">
                      Date of Entry: {list?.date}
                    </div>
                  </div>
                  <div className="modalBodyBody">
                    <div className="caseBodyTitle">Case Summary:</div>
                    <div className="caseBodyDescription overRideTextEditor">
                      {Parser(list?.value)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Box>
        </Grid>
      </Modal>

      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Cases</div>
          {openIncidentModal && (
            <DialogIncidentBox
              open={openIncidentModal}
              rows={[]}
              header={
                parseInt(closeDeleteMode) === 1
                  ? "Close Incident"
                  : "Delete Incident"
              }
              warningImg={parseInt(closeDeleteMode) === 2}
              closehandle={parseInt(closeDeleteMode) === 1
                ? closehandle
                : deletehandle}
              handleCloseModal={() => setOpenIncidentModal(false)}
              content={
                parseInt(closeDeleteMode) === 1?
                actionIds?.actionIds?.length > 0
                  ? `Following Action items ID: [${actionIds?.actionIds}] are still in open status, Do you still want to close this Incident?`
                  : "No Action Items Open status": actionIds?.actionIds?.length > 0 ? `Following Action items ID: [${actionIds?.actionIds}] are still in open status, Do you still want to Delete this Incident?`
                  : "No Action Items Open status"
              }
              view
            />
          )}
          {(isFullAccess || isWriteAccess) && (
            <div className="addCaseListBtn">
              <Box mr={3}>
                {caseDetail.status === "In Progress" ? (
                  <p className="inprogressStatus">In Progress</p>
                ) : caseDetail.status === "Open" ? (
                  <p className="openStatus">Open</p>
                ) : (
                  <p className="closeStatus">Closed</p>
                )}

                <Buttons
                  varientContained={true}
                  label={"Add Incident"}
                  startIcon={<AddIcon />}
                  onClick={() =>
                    navigate(PERSONAL_INJURY, {
                      state: {
                        caseId: caseId,
                        editMode: false,
                        isDirect: true,
                      },
                    })
                  }
                />
              </Box>
            </div>
          )}
        </div>
        <div className="grid_main_body">
          <div className="row_uniq section_box">
            <div className="caseDiv">
              <div className="caseidinc">
                Case ID : {caseDetail?.caseId}
                {isWriteAccess && isFullAccess && (
                  <span onClick={handleEdit} className="editBlueCase">
                    <img src={commonImages?.editBlue} alt="icon" />
                  </span>
                )}
              </div>
              <div className="detaildata">
                <div className="content_grid_ui">
                  <div>
                    <div className="prepared">Prepared by :</div>
                    <div className="preparedvalue">
                      {caseDetail?.createdByName}
                    </div>
                  </div>
                  <div className="locationTextContainer">
                    <div className="location">
                      <div className="locationTitle">Location :</div>{" "}
                      <div className="locationvalue">
                        {caseDetail?.location}
                      </div>
                    </div>
                  </div>



                  <div>
                    <div className="dateofincident">Date of case:</div>
                    <div className="dateofincidentvalue">
                      {caseDetail?.dateOfCase}
                    </div>
                  </div>
                  <div>
                    <div className="timeofincident">Time of case : </div>
                    <div className="timeofincidentvalue">
                      {repotedTime[0] + ":" + repotedTime[1]}
                    </div>
                  </div>
                  <div className="full_width">
                    <div className="dateofincident">Number of Incident : </div>
                    <div className="dateofincidentvalue">
                      {props?.incidentList?.data?.noOfRecords ?? 0}
                    </div>
                  </div>
                  <div className="full_width">
                    <div className="dateofincident">Establishment : </div>
                    <div className="dateofincidentvalue">
                      {caseDetail?.locationOfRecord}
                    </div>
                  </div>
                  
                </div>

                <div className="summer_of_case">
                  <div className="caseofsummry">Summary of Case : </div>
                  <div className="caseofsummryvalue overRideTextEditor">
                    {Parser(caseDetail?.description[0]?.value)}
                  </div>

                  <div className="btncaseofsummry">
                    <Buttons
                      variantOutline={true}
                      label={"View Past Summaries"}
                      onClick={() => handleCaseOnClick(caseDetail?.description)}
                    />
                  </div>
                </div>

                <div className="summer_of_case caseofsummryBox">
                  <div className="caseofsummry">Associated Incident:</div>
                  <div className="caseofsummryvalue caseofsummryFlex">
                    {caseDetail?.associatedIncident?.map((associatedItem) => {
                      return (
                        <div
                          key={associatedItem?.key}
                          className="caseofsummryText"
                        >
                          {associatedItem?.value}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="grid_main_sec">
        <div className="grid_main_header">
          <div className="grid_heading_style">List of Incidents</div>
        </div>
        <div className="grid_main_body">
          <div className="desc_box row_uniq">
            <div
              className="table_style_ui"
              style={{ height: 400, width: "100%" }}
            >
              <GridTable
                getRowId={(r) => r.reportId}
                rows={props?.incidentList?.data?.incident ?? []}
                columns={columns}
                pageSize={PAGE_SIZE}
                checkboxSelection={false}
                onClick={(rows) => onRowClick(rows)}
                rowCount={props?.incidentList?.data?.noOfRecords ?? 0}
                loading={props?.isListSuccess}
                pagination
                rowsPerPageOptions={[PAGE_SIZE]}
                onPageChange={(newPage) => props.pageSet(newPage + 1)}
                backIconButtonProps={{ disabled: false }}
                onSelectionModelChange={(reportId) =>
                  setReportIdOfChekbox(reportId)
                }
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>

      {reportIdOfChekbox.length > 0 && (
        <AlertModalAction
          reportIdOfChekbox={reportIdOfChekbox}
          incidentLists={props?.incidentList?.data?.incident ?? []}
          filename={"list-of-incident.csv"}
        />
      )}
    </>
  );
};

export default CaseIncidentList;
