import * as equipmentsAction from "../actions/equipmentsAction";
import { equipmentsType, deleteEquipmentType,getEquipmentDetailType,postEquipmentUpdateType } from "../constants/equipmentType";
import { getEquipmentList, 
  postCreateEquipmentService,
  deleteEquipmentService,
  getEquipmentFormDetails,
  updateEquipment,
} from "../../services/equipmentsService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* equipmentListSaga(params) {
  try {
    const { data, status, error } = yield call(getEquipmentList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = equipmentsType.EQUIMENTS_PAGE;
    yield put(
      equipmentsAction.getEquipmentListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Equipment List Failed" };
    yield put(equipmentsAction.getEquipmentListFailure(data));
  }
}

export function* createEquipmentSaga(params) {
  console.debug("createEquipmentSaga");
  try {
    const { data, status, error } = yield call(postCreateEquipmentService, params);
    if (!data && status !== 200) throw apiError(error);
    const page = equipmentsType.POST_CREATE_EQUIPMENT_SUCCESS;
    yield put(
      equipmentsAction.postCreateEquipmentSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Equipment " };
    yield put(equipmentsAction.postCreateEquipmentFailure(data));
  }
}

function* deleteEquipmentSaga(payload) {
  try {
    const { data, status, error } = yield call(deleteEquipmentService, payload);
    if (!data && status !== 200) throw apiError(error);
    const assetPage = deleteEquipmentType.DELETE_EQUIPMENT_PENDING;
    yield put(
      equipmentsAction.deleteEquipmentSuccess({
        assetPage,
        data,
        status
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Delete Equipment Failed" };
    yield put(equipmentsAction.deleteEquipmentFailure(data));
  }
}

export function* equipmentDetailSaga({equipmentId}) {
  console.debug("equipmentDetailSaga");
  try {
    const { data, status, error } = yield call(getEquipmentFormDetails, equipmentId);
    if (!data && status !== 200) throw apiError(error);
    const page = getEquipmentDetailType.EQUIPMENT_DETAIL_SUCCESS;
    yield put(
      equipmentsAction.equipmentDetailSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Equipment Detail Failed" };
    yield put(equipmentsAction.equipmentDetailFailure(data));
  }
}

export function* updateEquipmentsSaga(params) {
  console.debug("updateEquipmentsSaga");
  try {
    const { data, status, error } = yield call(updateEquipment, params);
    if (!data && status !== 200) throw apiError(error);
    const updatePage = postEquipmentUpdateType.UPDATE_PAGE;
    yield put(
      equipmentsAction.updateEquipmentsSuccess({
        updatePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Equipment updation Failed" };
    yield put(equipmentsAction.updateEquipmentsFailure(data));
  }
}


const myEquipmentSaga = [
  takeLatest(equipmentsType.GET_EQUIPMENT_LIST, equipmentListSaga),
  takeLatest(equipmentsType.POST_CREATE_EQUIPMENT,createEquipmentSaga),
  takeLatest(deleteEquipmentType.DELETE_EQUIPMENT_PENDING,deleteEquipmentSaga),
  takeLatest(getEquipmentDetailType.EQUIPMENT_DETAIL_PENDING,equipmentDetailSaga),
  takeLatest(postEquipmentUpdateType.UPDATE_EQUIPMENT_PENDING, updateEquipmentsSaga),
];

export default myEquipmentSaga;
