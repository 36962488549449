import React from "react";
import KudosObservationReport from "./kudosObservationReport";
import ViolationObservationReport from "./violationObservationReport";

const ViewObservationReport = ({
  observationReportData,
  observationType,
  closeViolation,
  observationID,
  observationStatus,
  inspectionViolationCounts,
}) => {
  return (
    <>
      {observationType && observationType === 1 && (
        <KudosObservationReport
          observationReportData={observationReportData}
          observationStatus={observationStatus}
        />
      )}

      {observationType && observationType === 2 && (
        <ViolationObservationReport
          observationReportData={observationReportData}
          closeViolation={closeViolation}
          observationID={observationID}
          observationStatus={observationStatus}
          observationType={observationType}
          inspectionViolationCounts={inspectionViolationCounts}
        />
      )}
    </>
  );
};

export default ViewObservationReport;
