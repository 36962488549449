import React, { useEffect } from "react";
import { REPORTS_CREDENTIAL_LIST } from "../../../constant/routeContant";
import ReportCredentialView from "../component/reportCredentialView";
import { useDispatch, useSelector } from "react-redux";
import * as reportAction from "../../../redux/actions/reportAction";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { useLocation } from "react-router-dom";
import Loader from "../../../library/common/Loader";

function ReportCredentialViewConatiner() {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location?.state?.id;
  const credentialTabId = location?.state?.tabId;

  console.log("1111", credentialTabId);

  const showLoader = useSelector(
    (state) => state?.getConfiguredCredentialReportData?.isLoading
  );

  const getConfiguredCredentialReportData = useSelector(
    (state) => state.getConfiguredCredentialReportData
  );

  const getConfiguredCredentialReports = (data) => {
    dispatch(reportAction.getConfiguredCredentialReport(data));
  };

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    userId: id,
    groupId: 0,
    orgId: ORG_ID(),
    tabId: credentialTabId,
    startDate: "",
    endDate: "",
  };
  useEffect(() => {
    getConfiguredCredentialReports(defaultData);
  }, []);

  const dataBack = {
    title: "Back to Credential Reports",
    route: REPORTS_CREDENTIAL_LIST,
  };
  return (
    <>
      {showLoader && <Loader />}
      <ReportCredentialView
        dataBack={dataBack}
        getConfiguredCredentialReportData={getConfiguredCredentialReportData}
        getConfiguredCredentialReports={getConfiguredCredentialReports}
        id={id}
        isLoading={getConfiguredCredentialReportData.isLoading}
        credentialTabId={credentialTabId}
      />
    </>
  );
}

export default ReportCredentialViewConatiner;
