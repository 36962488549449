import { quizType } from "../constants/quizType";


export const createQuiz = (data) => ({
  type: quizType.POST_QUIZ_PENDING,
  data,
});

export const createQuizSuccess = (data) => ({
  type: quizType.POST_QUIZ_SUCCESS,
  data,
});

export const createQuizFailure = (error) => ({
  type: quizType.POST_QUIZ_FAILURE,
  error,
});


export const getQuizList = (data) => ({
  
  type: quizType.GET_QUIZ_LIST_PENDING,
  data,
});

export const getQuizListSuccess = (data) => ({
  type: quizType.GET_QUIZ_LIST_SUCCESS,
  data,
});

export const getQuizListFailure = (error) => ({
  type: quizType.GET_QUIZ_LIST_FAILURE,
  error,
});


export const submitQuiz = (data) => ({
  type: quizType.POST_SUBMIT_QUIZ_PENDING,
  data,
});

export const submitQuizSuccess = (data) => ({
  type: quizType.POST_SUBMIT_QUIZ_SUCCESS,
  data,
});

export const submitQuizFailure = (error) => ({
  type: quizType.POST_SUBMIT_QUIZ_FAILURE,
  error,
});

export const getQuizDetails = (data) => ({
  type: quizType.GET_QUIZ_DETAILS,
  data,
});

export const getQuizDetailsSuccess = (data) => ({
  type: quizType.GET_QUIZ_DETAILS_SUCCESS,
  data,
});

export const getQuizDetailsFailure = (error) => ({
  type: quizType.GET_QUIZ_DETAILS_FAILURE,
  error,
});

export const updateQuizList = (payload) => ({
  type: quizType.POST_UPDATE_QUIZ_PENDING,
  payload,
});

export const updateQuizListSuccess = (data) => ({
  type: quizType.POST_UPDATE_QUIZ_SUCCESS,
  data,
});

export const updateQuizListFailure = (error) => ({
  type: quizType.POST_UPDATE_QUIZ_FAILURE,
  error,
});
