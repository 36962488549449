import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as contactsAction from "../../../redux/actions/contactsAction";
import Loader from "../../../library/common/Loader";
import { API_STATUS } from "../../../constant/constant";
import ViewContact from "../component/contacts/viewContact";
import { ADMIN_CONTACTS } from "../../../constant/routeContant";

export default function ContactViewContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const contactId = location?.state?.contactId;

  const viewContactData = useSelector((state) => state?.viewContacts?.data);

  const showLoader = useSelector((state) => state?.viewContacts?.isLoading);
  const isSuccess = useSelector(
    (state) => state.viewContacts.status === API_STATUS.SUCCESS
  );
  useEffect(() => {
    dispatch(contactsAction.getContactDetails(contactId));
  }, [dispatch]);

  const dataBack = {
    title: "Back to Contacts",
    route: ADMIN_CONTACTS,
  };
  return (
    <>
      {showLoader && <Loader />}
      {isSuccess && (
        <ViewContact dataBack={dataBack} viewContactData={viewContactData} />
      )}
    </>
  );
}
