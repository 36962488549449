import { call, put, takeLatest } from "redux-saga/effects";
import {
  addStolenProperty,
  getStolenProperty,
  updateStolenProperty,
} from "../../services/incidentService";
import { apiError } from "../../utils/helper";
import * as stolenPropertyAction from "../actions/stolenPropertyAction";
import { stolenPropertyType } from "../constants/stolenPropertyType";

export function* stolenPropertySaga(params) {
  console.debug("stolenPropertySaga");
  try {
    const { data, status, error } = yield call(addStolenProperty, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      stolenPropertyAction.stolenPropertySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Stolen Property Failed" };
    yield put(stolenPropertyAction.stolenPropertyFailure(data));
  }
}

export function* getStolenPropertySaga({ caseid, incidentid }) {
  console.debug("getStolenPropertySaga");
  try {
    const { data, status, error } = yield call(
      getStolenProperty,
      caseid,
      incidentid
    );
    if (!data && status !== 200) throw apiError(error);
    const stolenPropertyReportPage = stolenPropertyType.STOLEN_PROPERTY_PAGE;
    yield put(
      stolenPropertyAction.getStolenPropertySuccess({
        stolenPropertyReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Stolen Property Report Failed" };
    yield put(stolenPropertyAction.getStolenPropertyFailure(data));
  }
}

export function* updateStolenPropertySaga(params) {
  console.debug("updateVehicalAccidentSaga");
  try {
    const { data, status, error } = yield call(updateStolenProperty, params);
    if (!data && status !== 200) throw apiError(error);
    const stolenPropertyReportPage = stolenPropertyType.STOLEN_PROPERTY_PAGE;
    yield put(
      stolenPropertyAction.updateStolenPropertySuccess({
        stolenPropertyReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Stolen property updation Failed" };
    yield put(stolenPropertyAction.updateStolenPropertyFailure(data));
  }
}

const myStolenPropertySaga = [
  takeLatest(
    stolenPropertyType.POST_STOLEN_PROPERTY_PENDING,
    stolenPropertySaga
  ),
  takeLatest(
    stolenPropertyType.GET_STOLEN_PROPERTY_PENDING,
    getStolenPropertySaga
  ),
  takeLatest(
    stolenPropertyType.PUT_STOLEN_PROPERTY_PENDING,
    updateStolenPropertySaga
  ),
];

export default myStolenPropertySaga;
