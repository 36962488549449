import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TabPanel from "../../../lms/component/tabPanel/tabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { SAFETYLOGTABS, USER_TYPE } from "../../../../constant/constant";
import KudosList from "./kudosList";
import ViolationList from "./violationList";
import ReprimandLog from "./reprimandLog";
import Etraining from "../profile/etraining";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import {
  PAGE_SIZE,
  ORG_ID,
  USER_ID,
  GET_ITEM_LOG,
} from "../../../../constant/constant";
import JhaList from "./jhaList";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";

const ViewSafetyLog = ({
  getItemLogKudosData,
  getItemLogCorrectiveData,
  getItemLogReprimandData,
  coursesUserListData,
  isGetItemLogLoading,
  getCoursesUserListData,
  isCoursesLoading,
  lessionUserListData,
  getLessionUserListData,
  isLessonLoading,
  itemLogData,
  allUsers,
  searchOption,
  onSearch,
  isLoading,
  PAGE_SIZE_SIX,
  getSaftyLogJHAConductList,
  jhaConductList,
  navigationObj,
}) => {
  const [usersId, setUsersId] = useState("");
  const [users, setUsers] = useState("");
  useEffect(() => {
    if (navigationObj?.userSafetyLog === true) {
      setSafetyLogCheck("1");
      setUsers({
        key: navigationObj?.userId?.userId,
        value: navigationObj?.userId?.contractorName,
      });
      setUsersId(navigationObj?.userId?.userId);
    } else if (navigationObj?.userSafetyLog === false) {
      setSafetyLogCheck("0");
      setUsers({
        key: navigationObj?.userId?.userid,
        value: navigationObj?.userId?.userName,
      });
      setUsersId(navigationObj?.userId?.userid);
    }
  }, []);

  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }

  const [value, setValue] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [safetyLogCheck, setSafetyLogCheck] = useState("0");

  function handleSafetyCheckType(e) {
    setSafetyLogCheck(e);
    setUsers("");
    setValue("");
  }

  const [newLogData, setnewLogData] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    userId: "",
    organisationId: ORG_ID(),
  });

  const [kudosData, setkudosData] = useState({
    userId: parseInt(USER_ID()),
    search: "",
    itemTypes: GET_ITEM_LOG?.Kudos,
    statusIds: "",
    page: 1,
    pageSize: PAGE_SIZE,
    status: "",
    projectId: 0,
    divisionId: 0,
    OrgId: ORG_ID(),
    fromDate: "",
    toDate: "",
  });

  const [CorrectiveData, setCorrectiveData] = useState({
    userId: parseInt(USER_ID()),
    search: "",
    itemTypes: GET_ITEM_LOG.Corrective,
    statusIds: "",
    page: 1,
    pageSize: PAGE_SIZE,
    status: "",
    projectId: 0,
    divisionId: 0,
    OrgId: ORG_ID(),
    fromDate: "",
    toDate: "",
  });
  const [jhaData, setjhaData] = useState({
    userId: "",
    page: 1,
    pageSize: PAGE_SIZE,
  });

  const [ReprimandData, setReprimandData] = useState({
    userId: parseInt(USER_ID()),
    search: "",
    itemTypes: GET_ITEM_LOG.Reprimand,
    statusIds: "",
    page: 1,
    pageSize: PAGE_SIZE,
    status: "",
    projectId: 0,
    divisionId: 0,
    OrgId: ORG_ID(),
    fromDate: "",
    toDate: "",
  });

  const [etrainingNewLogData, setEtrainingNewLogData] = useState({
    pageNumber: 1,
    pageSize: PAGE_SIZE_SIX,
    search: "",
    catIds: "",
    statusId: "",
    userId: "",
    orgId: ORG_ID(),
  });

  useEffect(() => {
    if (!users) return;
    if (value == 0) {
      getLessionUserListData(etrainingNewLogData);
      getCoursesUserListData(etrainingNewLogData);
    } else if (value == 1) getItemLogKudosData(kudosData);
    else if (value == 2) getItemLogReprimandData(ReprimandData);
    else if (value == 3) getItemLogCorrectiveData(CorrectiveData);
    else if (value == 4) getSaftyLogJHAConductList(jhaData);
  }, [value]);

  const onChangeUsers = (id) => {
    setUsers(id);
    setUsersId(id?.key);
  };
  useEffect(() => {
    setnewLogData({
      ...newLogData,
      userId: usersId,
    });
    setEtrainingNewLogData({
      ...etrainingNewLogData,
      userId: usersId,
    });
    setkudosData({
      ...kudosData,
      userId: usersId,
    });
    setCorrectiveData({
      ...CorrectiveData,
      userId: usersId,
    });
    setReprimandData({
      ...ReprimandData,
      userId: usersId,
    });
    setjhaData({
      ...jhaData,
      userId: usersId,
    });
  }, [usersId]);

  return (
    <Grid container>
      <Grid item container mt={2} spacing={1} className="swproject">
        <Grid item lg={6} xs={12} sm={12} md={4}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={safetyLogCheck === "0"}
                value="0"
                label="Employee"
                onChange={(e) => handleSafetyCheckType(e.target.value)}
              ></RadioButton>

              <RadioButton
                checked={safetyLogCheck === "1"}
                value="1"
                label="Sub Contractor"
                onChange={(e) => handleSafetyCheckType(e.target.value)}
              ></RadioButton>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container mt={1}>
        {safetyLogCheck === "0" && (
          <Grid item container mt={1} spacing={2}>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormLabels label="Search User" />
              <SearchAutoComplete
                name="userSearch"
                placeholderText="Enter User Name"
                optionsData={isLoading ? [] : allUsers?.users ?? []}
                typeOnchange={(event, value) => {
                  setValue("");
                  onSearch(event.target.value, USER_TYPE.NONPRIVILEGED);
                }}
                isKeyValuePair={true}
                value={users}
                onChange={(event, value) => {
                  setValue("");
                  onChangeUsers(value);
                }}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        )}
        {safetyLogCheck === "1" && (
          <Grid item container mt={1} spacing={2}>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormLabels label="Search Sub Contractor" />
              <SearchAutoComplete
                name="userSearch"
                placeholderText="Enter Sub Contractor"
                optionsData={isLoading ? [] : allUsers?.users ?? []}
                typeOnchange={(event, value) => {
                  setValue("");
                  onSearch(event.target.value, USER_TYPE.DIVISION_CONTRACTOR);
                }}
                isKeyValuePair={true}
                value={users}
                onChange={(event, value) => {
                  setValue("");
                  onChangeUsers(value);
                }}
                isLoading={isLoading}
                noOptionsTextMsg="No Sub Contractor "
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item md={12}>
        <Grid container mt={2}>
          <Tabs value={value} onChange={handleChange}>
            {SAFETYLOGTABS?.map((tabItem, i) => {
              return (
                <Tab
                  className="lessonList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        </Grid>
      </Grid>
      <Grid item md={12} mt={3} px={2}>
        <TabPanel value={value} index={0}>
          <Etraining
            coursesUserListData={coursesUserListData}
            getCoursesUserListData={getCoursesUserListData}
            isLoading={isCoursesLoading}
            lessionUserListData={lessionUserListData}
            getLessionUserListData={getLessionUserListData}
            isLessonLoading={isLessonLoading}
            isProfile={false}
            usersId={usersId}
            isSafetyLog={true}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <KudosList
            itemListData={itemLogData}
            isGetItemLogLoading={isGetItemLogLoading}
            getItemLogKudosData={getItemLogKudosData}
            searchOption={searchOption}
            allUsers={allUsers}
            usersId={usersId}
            isSafetyLog={true}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ReprimandLog
            getItemLogReprimandData={getItemLogReprimandData}
            isGetItemLogLoading={isGetItemLogLoading}
            itemListData={itemLogData}
            usersId={usersId}
            isSafetyLog={true}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ViolationList
            getItemLogCorrectiveData={getItemLogCorrectiveData}
            itemListData={itemLogData}
            isGetItemLogLoading={isGetItemLogLoading}
            searchOption={searchOption}
            allUsers={allUsers}
            usersId={usersId}
            isSafetyLog={true}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <JhaList
            jhaConductList={jhaConductList}
            jhaData={jhaData}
            setjhaData={setjhaData}
            getSaftyLogJHAConductList={getSaftyLogJHAConductList}
            usersId={usersId}
            isSafetyLog={true}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default ViewSafetyLog;
