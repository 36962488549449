import React, { useState } from "react";
import { ORG_ID, PAGE_SIZE, USER_ID } from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import DialogBox from "../../../../library/common/DialogBox";

const DailyReportListContainer = ({
  isLoading,
  swInspectionList,
  count,
  viewOnClick,
  onRowClick,
  handleDeleteOnClick,
  getSwInspectionListData,
}) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteReport = (reportId) => {
    setOpenModal(true);
    setSelectedRow(reportId);
  };
  const deletehandle = () => {
    setOpenModal(false);
    handleDeleteOnClick(selectedRow);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      search: event.target.value,
      inspectionTypeId: 0,
      projectId: 0,
      customerId: parseInt(ORG_ID()),
      statusId: 0,
      startDate: "",
      endDate: "",
      page: page,
      pageSize: PAGE_SIZE,
      userId: parseInt(USER_ID()),
      isDailyReport: true,
    };
    getSwInspectionListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      search: search ? search : "",
      inspectionTypeId: 0,
      projectId: 0,
      customerId: parseInt(ORG_ID()),
      statusId: 0,
      startDate: "",
      endDate: "",
      pageSize: PAGE_SIZE,
      userId: parseInt(USER_ID()),
      isDailyReport: true,
    };
    getSwInspectionListData(data);
  };

  const columns = [
    {
      field: "reportId",
      headerName: "Report ID",
      flex: 1,
    },
    {
      field: "projectNumber",
      headerName: "Project Number",
      flex: 1,
    },
    {
      field: "project",
      headerName: "Project",
      flex: 1,
    },
    {
      field: "customer",
      headerName: "Client",
      flex: 1,
    },
    {
      field: "dateScheduled",
      headerName: "Date Scheduled",
      flex: 1,
      hide: false,
    },
    {
      field: "inspectionType",
      headerName: "Inspection Type",
      flex: 1,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      flex: 1,
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={count ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={swInspectionList ?? []}
          fileNameXL={"inspections"}
        />
      ),
      flex: 0.7,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            view
            viewOnClick={(row) => viewOnClick(row)}
            viewLabel={"View Report"}
            // deleted
            // deleteOnClick={(row) => deleteReport(row?.reportId)}
            // deleteLabel="Delete Report"
          />
        );
      },
    },
  ];
  return (
    <>
      <section className="grid_main_sec">
        {openModal && (
          <DialogBox
            open={openModal}
            rows={[]}
            header={"Confirm Deletion"}
            deletehandle={deletehandle}
            handleCloseModal={() => setOpenModal(false)}
            content={"Are you sure you want to delete this Report?"}
            view
          />
        )}
        <div className="grid_main_header two_grid">
          <Grid container item md={12} xs={12} className="">
            <Grid container className="confBox">
              <Grid item md={8} xs={12}>
                <div className="confTitile">My Daily Reports</div>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.reportId}
                rows={swInspectionList ?? []}
                rowCount={count ?? 0}
                columns={columns}
                checkboxSelection={false}
                loading={isLoading}
                page={1}
                pagination
                pageSize={PAGE_SIZE}
                rowsPerPageOptions={[10]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                onClick={(rows) => onRowClick(rows)}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DailyReportListContainer;
