import { categoryType } from "../constants/utilityCategoryType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "category",
  isError: false,
  isLoading: false,
};

export const createCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryType.POST_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.POST_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.POST_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const categoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryType.GET_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.GET_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const activeCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryType.POST_ACTIVE_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.POST_ACTIVE_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.POST_ACTIVE_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createSupportCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryType.POST_SUPPORT_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.POST_SUPPORT_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.POST_SUPPORT_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const categorySupportListReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryType.GET_SUPPORT_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.GET_SUPPORT_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.GET_SUPPORT_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const activeSupportCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryType.POST_ACTIVE_SUPPORT_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.POST_ACTIVE_SUPPORT_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.POST_ACTIVE_SUPPORT_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const categoryUtilityIncidentListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case categoryType.GET_INCIDENT_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.GET_INCIDENT_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.GET_INCIDENT_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createUtilityIncidentCategoryReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case categoryType.POST_INCIDENT_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.POST_INCIDENT_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.POST_INCIDENT_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const activeIncidentCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryType.POST_ACTIVE_INCIDENT_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.POST_ACTIVE_INCIDENT_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.POST_ACTIVE_INCIDENT_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

//Update category configuration
export const updateCategoryConfigationReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case categoryType.UPDATE_CATEGORY_CONFIGURATION:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.UPDATE_CATEGORY_CONFIGURATION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.UPDATE_CATEGORY_CONFIGURATION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const updateSupportCategoryConfigationReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case categoryType.UPDATE_SUPPORT_CATEGORY_CONFIGURATION:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.UPDATE_SUPPORT_CATEGORY_CONFIGURATION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.UPDATE_SUPPORT_CATEGORY_CONFIGURATION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const updateIncidentCategoryConfigationReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case categoryType.UPDATE_INCIDENT_CATEGORY_CONFIGURATION:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case categoryType.UPDATE_INCIDENT_CATEGORY_CONFIGURATION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case categoryType.UPDATE_INCIDENT_CATEGORY_CONFIGURATION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
