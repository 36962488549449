import React, { forwardRef } from "react";
import { CSVLink } from "react-csv";

const DownloadCsv = (props, ref) => {
  const {data, filename } = props;
  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        ref={ref}
        data={data}
        filename={filename}
      />
    </>
  );
};

export default forwardRef(DownloadCsv);
