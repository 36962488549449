import React, { useState } from "react";
import "../../../../src/feature/incidents/cases/addCaseList.scss";
import { Grid } from "@mui/material";
import GridTable from "../../../library/custom/gridTable/GridTable";
import SettingTable from "../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../library/common/Loader";
import NORow from "../../../library/custom/noRow/noRow";
import SearchIcon from "@mui/icons-material/Search";
import { GET_ITEM_LOG, ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { useEffect } from "react";
const ReportSafetylogList = ({
  PAGE_SIZES,
  itemLogData,
  isGetItemLogLoading,
  tabId,
  getItemLogData,
  startDate,
  endDate,
  project,
  divison,
  setIncSearchText,
  incSearchText,
}) => {
  const [page, setPage] = useState(1);

  let gridData =
    tabId === "1"
      ? itemLogData?.openLogsData
      : tabId === "2"
      ? itemLogData?.closeLogsData
      : itemLogData?.logsData;

  const [doPagi, setdoPagi] = useState(true);
  useEffect(() => {
    setdoPagi(true);
  }, [tabId]);

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    setdoPagi(false);
    console.log(newPage);
    const data = {
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      search: incSearchText,
      userId: 0,
      itemTypes:
        tabId === "3"
          ? GET_ITEM_LOG.Kudos
          : tabId === "4"
          ? GET_ITEM_LOG.Corrective
          : tabId === "5"
          ? GET_ITEM_LOG.Reprimand
          : tabId === "6"
          ? GET_ITEM_LOG.Action
          : GET_ITEM_LOG.All,
      organizationId: ORG_ID(),
      divisionId: divison,
      projectId: project,
      fromDate: startDate,
      toDate: endDate,
      status: "",
      statusIds: "",
    };
    getItemLogData(data);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "dateOfEntry", headerName: "Date of Entry", flex: 1 },
    { field: "jobsite", headerName: "Location/Jobsite", flex: 1 },
    { field: "itemType", headerName: "Item Type", flex: 1 },
    { field: "origin", headerName: "Origin", flex: 1 },
    { field: "addedBy", headerName: "Added by", flex: 1 },
    { field: "status", headerName: "Status", flex: 0.7 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={itemLogData?.result?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZES}
          incidentLists={itemLogData?.result?.logsData ?? []}
          fileNameXL="list-of-openItems"
        />
      ),
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];

  const handleautoSearch = (event) => {
    let search = "";
    if (event.target.value) {
      search = event.target.value;
    }
    setIncSearchText(search);
    const data = {
      page: page,
      pageSize: PAGE_SIZE,
      search: event.target.value,
      userId: 0,
      itemTypes:
        tabId === "3"
          ? GET_ITEM_LOG.Kudos
          : tabId === "4"
          ? GET_ITEM_LOG.Corrective
          : tabId === "5"
          ? GET_ITEM_LOG.Reprimand
          : tabId === "6"
          ? GET_ITEM_LOG.Action
          : GET_ITEM_LOG.All,
      organizationId: ORG_ID(),
      divisionId: divison,
      projectId: project,
      fromDate: startDate,
      toDate: endDate,
      status: "",
      statusIds: "",
    };
    getItemLogData(data);
  };
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Safety Data Reports</div>
        </div>
        <Grid mt={3}>
          <div className="grid_main_header two_grid">
            <div className="grid_heading_style">
              {tabId === "1"
                ? "Open Items"
                : tabId === "2"
                ? "Closed Items"
                : tabId === "3"
                ? "Kudos"
                : tabId === "4"
                ? "Corrective"
                : tabId === "5"
                ? "Reprimand"
                : tabId === "6"
                ? "Action"
                : "Total Items"}
            </div>
          </div>
          <Grid container mb={2} className="removeElement">
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={incSearchText}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
            </Grid>
          </Grid>
          <div className="grid_main_body">
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                {isGetItemLogLoading && doPagi ? (
                  ""
                ) : (
                  <GridTable
                    getRowId={(r) => r?.id + "|" + r?.itemType}
                    rows={gridData ?? []}
                    rowCount={
                      tabId === "1"
                        ? itemLogData?.openItemCount
                        : tabId === "2"
                        ? itemLogData?.closeItemCount
                        : itemLogData?.noOfRecords ?? 0
                    }
                    columns={columns}
                    // loading={isGetItemLogLoading}
                    page={page}
                    pagination
                    pageSize={PAGE_SIZE}
                    rowsPerPageOptions={[PAGE_SIZE]}
                    onPageChange={handlePagignation}
                    backIconButtonProps={{ disabled: false }}
                    // disableSelectionOnClick
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>
      </section>
    </>
  );
};

export default ReportSafetylogList;
