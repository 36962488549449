import React, { useEffect, useState } from "react";
import AddGeneralPermit from "../components/addGeneralPermit";
import { useDispatch, useSelector } from "react-redux";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import * as permitAction from "../../../../redux/storm-water/actions/permitAction";
import Loader from "../../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
function AddGeneralPermitContainer() {
  const dispatch = useDispatch();
  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };
  const location = useLocation();
  const stateId = location?.state?.stateId;
  const customer = location?.state?.customerName;
  const editMode = location?.state?.editMode;
  const data = {
    search: "",
    page: 1,
  };
  const generalPermitList = useSelector((state) => state.generalPermitList);
  const generalPermitUpdate = useSelector((state) => state.updateGeneralPermit);

  const handleGeneralPermitList = (data) => {
    dispatch(permitAction.generalPermitListPending(data));
  };
  const handleUpdateGeneralPermit = (data) => {
    setClearAddGeneralPermit(true);
    dispatch(permitAction.updateGeneralPermit(data));
  };
  useEffect(() => {
    getStateData();
    handleGeneralPermitList(data);
  }, []);
  const stateData = useSelector((state) => state.state);
  const showLoader = useSelector(
    (state) => state.state?.isLoading || state?.updateGeneralPermit?.isLoading
  );
  const isLoading = useSelector((state) => state.generalPermitList.isLoading);
  const [clearAddGeneralPermit, setClearAddGeneralPermit] = useState(false);
  useEffect(() => {
    if (
      generalPermitUpdate?.status === "SUCCESS" &&
      generalPermitUpdate?.isLoading === false &&
      generalPermitUpdate?.data?.responseMessages?.responseMessage ===
        "Request was successful" &&
      clearAddGeneralPermit
    ) {
      toast("General Permit updated Successfully");
      const data1 = {
        search: "",
        page: 1,
      };
      {
        generalPermitUpdate?.data?.responseMessages?.responseMessage ===
          "Request was successful" && handleGeneralPermitList(data1);
      }
    }
  }, [generalPermitUpdate]);

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <div>
          <AddGeneralPermit
            state={stateData && stateData.data?.getState}
            generalPermitList={generalPermitList?.data}
            handleGeneralPermitList={handleGeneralPermitList}
            handleUpdateGeneralPermit={handleUpdateGeneralPermit}
            generalPermitUpdate={generalPermitUpdate}
            stateId={stateId}
            editMode={editMode}
            isLoading={isLoading}
          />
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default AddGeneralPermitContainer;
