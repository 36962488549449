import React, { useEffect } from "react";
import JhaEditControl from "../component/jha/jhaEditControl";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { API_STATUS, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import { useDispatch } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { useState } from "react";
import Loader from "../../../library/common/Loader";
import SuccessScreen from "../../../library/custom/success/successScreen";

const JhaEditControlsContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const controlId = location.state.data.id;
  const controlTypeId = location.state.data.controlTypeId;
  const isActive = location.state.data.isActive;
  const [isSuccess, setisSuccess] = useState(false);

  useEffect(() => {
    setisSuccess(false);
    dispatch(jhaAction.clearJha());
    dispatch(
      jhaAction.getJhaControlTypeList({
        search: "",
        page: 1,
        pageSize: PAGE_SIZE,
      })
    );
    dispatch(jhaAction.getJhaControlDetails({ controlId: controlId }));
  }, [dispatch]);
  const [formValue, setformValue] = useState({
    controlsName: "",
    isGlobal: false,
    controlId: "",
    controlTypeId: "",
    isActive: false,
    modifiedBy: parseInt(USER_ID()),
  });
  // const [formValue, setformValue] = useState({
  //   controlsName: controlsName,
  //   isGlobal: false,
  //   controlId: controlId,
  //   controlTypeId: controlTypeId,
  //   isActive: isActive,
  //   modifiedBy: parseInt(USER_ID()),
  // });
  const controlTypeListData = useSelector(
    (state) => state.getJhaControlTypeList
  );
  const getJhaControlDetailsData = useSelector(
    (state) => state?.getJhaControlDetails
  );
  const updateJhaControlData = useSelector((state) => state?.updateJhaControl);
  useEffect(() => {
    setformValue({
      ...formValue,
      controlsName: getJhaControlDetailsData?.data?.controlsName,
      controlId: getJhaControlDetailsData?.data?.controlId,
      controlTypeId: controlTypeId,
      isActive: isActive,
    });
  }, [getJhaControlDetailsData]);

  function handleEdit(data) {
    setisSuccess(true);
    let newData = {
      controlsName: data.controlsName,
      isGlobal: false,
      controlId: data.controlId,
      controlTypeId: data.controlTypeId,
      isActive: data.isActive,
      modifiedBy: parseInt(USER_ID()),
    };
    dispatch(jhaAction.updateJhaControl(newData));
  }

  const successScreen = (id, message, message1) => {
    // dispatch(jhaAction.clearJha())
    return (
      <SuccessScreen
        id={id}
        isUpdate={"Control Updated Successfully"}
        route={-1}
        label1="Control ID"
        label4="Control in "
        label2={message1}
      ></SuccessScreen>
    );
  };

  const [controlTypeList, setcontrolTypeList] = useState([]);
  function mapControlTypeKeyValue() {
    let arr = [];
    controlTypeListData?.data?.result?.itemList.map((i) =>
      arr.push({
        key: i.id,
        value: i.controlTypeName,
      })
    );
    setcontrolTypeList(arr);
  }

  useEffect(() => {
    if (
      controlTypeListData.status === API_STATUS.SUCCESS &&
      controlTypeListData?.isLoading === false
      // clearalert
    ) {
      mapControlTypeKeyValue();
    }
  }, [controlTypeListData]);
  return (
    <div>
      {updateJhaControlData.status === "SUCCESS" &&
      isSuccess &&
      updateJhaControlData ? (
        successScreen(
          updateJhaControlData?.data.id,
          "Control Updated Successfully",
          "has been Updated"
        )
      ) : updateJhaControlData.isLoading ||
        getJhaControlDetailsData.isLoading ? (
        <Loader />
      ) : (
        <JhaEditControl
          formValue={formValue}
          handleEdit={handleEdit}
          setformValue={setformValue}
          prevControls={getJhaControlDetailsData?.data?.preDetails ?? []}
          controlTypeList={controlTypeList}
          controlTypeId={controlTypeId}
        />
      )}
    </div>
  );
};

export default JhaEditControlsContainer;
