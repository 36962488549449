import React, { useState } from "react";
import "./category.scss";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import {
  CATEGORY_ADMIN,
  CATEGORY_INCIDENT,
  CATEGORY_LMS,
  CATEGORY_SUPPORT,
  CATEGORY_ASSET,
  CATEGORY_CREDENTIALS,
  CATEGORY_RESOURSE,
} from "../../../../constant/categoryConstant";
import { Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Buttons from "../../../../library/custom/button/Button";
import CategoryData from "./categoryData";
import {
  ORG_ID,
  LNG_ID,
  USER_ID,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { ADMIN_CATEGORY_DASHBOARD } from "../../../../constant/routeContant";
import TextArea from "../../../../library/custom/textArea/TextArea";
import { CSVLink } from "react-csv";
import Checkbox from "../../../../library/checkbox/checkbox";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";

export default function CategoryAdmin(props) {
  const [flag, setFlag] = useState("");
  const data = props?.data;
  const title = props.title;
  const getUtilityCategory = props?.getUtilityCategory;
  const getSupportUtilityCategroy = props?.getSupportUtilityCategroy;
  const getUtilityIncidentCategory = props?.getUtilityIncidentCategory;
  const catselectid = props?.catselectid;
  const categoryPath = props?.categoryPath;
  const onSubmit = props?.onSubmit;
  const [categoryName, setCategoryName] = useState("");
  const [categoryDesc, setCategoryDesc] = useState("");
  const [categoryexist, setCategoryexist] = useState(false);
  const [errCateExitemsg, setErrCateExitemsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [checkBox, setcheckBox] = useState(false);

  const dataBack = {
    title: "Back to Modules",
    route: ADMIN_CATEGORY_DASHBOARD,
  };
  if (data) {
    var CsvActiveData = data?.filter((val) => val.statusId === 1);
  }
  const categoryHandler = (e) => {
    setCategoryName(e.target.value);
    setErrCateExitemsg("");
    setCategoryexist(false);
    setIsSubmit(false);
  };
  const categoryHandlerDesc = (e) => {
    setCategoryDesc(e.target.value);
    setIsSubmit(false);
  };
  const [isflag, setIsflag] = useState(false);

  const submitData = (e) => {
    let errors = false;
    if (flag === "") {
      setIsflag(true);
      errors = true;
    }
    if (categoryName.trim() === "") {
      setIsflag(true);
      errors = true;
    }

    const dataInc = {
      incRefId: flag,
      type: categoryName.trim(),
      createdBy: USER_ID(),
      orgId: ORG_ID(),
      isGlobal: checkBox,
    };

    const data1 = {
      flag: flag,
      categoryName: categoryName.trim(),
      createdBy: USER_ID(),
      organisationId: ORG_ID(),
      lngId: LNG_ID,
      isGlobal: checkBox,
      serviceType:parseInt(PRODUCT_ID())
    };
    if (catselectid === 1 && categoryPath === "support") {
      delete data1.serviceType; 
      data1.descrption = categoryDesc;
      data1.url = "";
    }
console.log('data',data);
    const isFound = Array.isArray(data) && data?.some((element) => {
      if (
        element?.value?.toUpperCase() === categoryName?.toUpperCase().trim()
      ) {
        return true;
      }

      return false;
    });

    if (isFound) {
      errors = true;

      setCategoryexist(true);
      setErrCateExitemsg("Category Already Exist");
    }

    if (errors === false && isFound === false) {
      if (categoryPath === "incident") {
        onSubmit(dataInc);
        setIsSubmit(true);
        setCategoryName("");
        setIsflag(false);
      } else {
        onSubmit(data1);
        setIsSubmit(true);
        setCategoryName("");
        setIsflag(false);
      }
    }
  };
  const selectedcategoryName = CATEGORY_ADMIN.find(
    (item) => item.key === catselectid
  );
  const selectedcategoryAssetName = CATEGORY_ASSET.find(
    (item) => item.key === catselectid
  );
  const catname = selectedcategoryName?.value?.replace(/ /g, "-")
    ? selectedcategoryName?.value?.replace(/ /g, "-")
    : selectedcategoryAssetName?.value?.replace(/ /g, "-");
  const headername = selectedcategoryName?.value
    ? selectedcategoryName?.value
    : selectedcategoryAssetName?.value;
  const headers = [
    { label: "ID", key: "key" },
    { label: headername, key: "value" },
  ];

  return (
    <>
      <div className="overrideadmincategory">
        <div className="createFaqh1">
          <BackBtn dataBack={dataBack} />
          <Grid item container mt={1} lg={12} spacing={1}>
            <Grid item lg={6} mt={1}>
              <h1>{title}</h1>
            </Grid>
          </Grid>
        </div>
        <Grid>
          <Grid item container mt={1.5} lg={12} spacing={1}>
            {categoryPath === "incident" ? (
              <Grid item lg={6} xs={12} mt={2}>
                <SelectMenu
                  listData={CATEGORY_INCIDENT}
                  value={flag}
                  placeholder="Select Category"
                  onchangehandler={(e) => {
                    setFlag(e.target.value);
                    getUtilityIncidentCategory(e);
                    setIsflag(false);
                    setErrCateExitemsg("");
                    setCategoryName("");
                    setIsSubmit(false);
                  }}
                  isError={isflag}
                  errorMsg={isflag ? "Category is Required" : ""}
                />
              </Grid>
            ) : (
              ""
            )}
            {categoryPath === "utility" ? (
              <>
                <Grid item lg={6} xs={12} mt={2}>
                  <SelectMenu
                    listData={CATEGORY_ADMIN}
                    value={flag}
                    placeholder="Select Category"
                    onchangehandler={(e) => {
                      setFlag(e.target.value);
                      getUtilityCategory(e);
                      setIsflag(false);
                      setErrCateExitemsg("");
                      setCategoryName("");
                      setIsSubmit(false);
                    }}
                    isError={isflag}
                    errorMsg={isflag ? "Category is Required" : ""}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}

            {categoryPath === "assets" ? (
              <>
                <Grid item lg={6} xs={12} mt={2}>
                  <SelectMenu
                    listData={CATEGORY_ASSET}
                    value={flag}
                    placeholder="Select Category"
                    onchangehandler={(e) => {
                      setFlag(e.target.value);
                      getUtilityCategory(e);
                      setIsflag(false);
                      setErrCateExitemsg("");
                      setCategoryexist(false);
                      setCategoryName("");
                      setIsSubmit(false);
                    }}
                    isError={isflag}
                    errorMsg={isflag ? "Category is Required" : ""}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}

            {categoryPath === "lms" ? (
              <Grid item lg={6} xs={12} mt={2}>
                <SelectMenu
                  listData={CATEGORY_LMS}
                  value={flag}
                  placeholder="Select Category"
                  onchangehandler={(e) => {
                    setFlag(e.target.value);
                    getUtilityCategory(e);
                    setIsflag(false);
                    setErrCateExitemsg("");
                    setCategoryexist(false);
                    setCategoryName("");
                    setIsSubmit(false);
                  }}
                  isError={isflag}
                  errorMsg={isflag ? "Category is Required" : ""}
                />
              </Grid>
            ) : (
              ""
            )}
            {categoryPath === "credentials" ? (
              <Grid item lg={6} xs={12} mt={2}>
                <SelectMenu
                  listData={CATEGORY_CREDENTIALS}
                  value={flag}
                  placeholder="Select Category"
                  onchangehandler={(e) => {
                    setFlag(e.target.value);
                    getUtilityCategory(e);
                    setIsflag(false);
                    setErrCateExitemsg("");
                    setCategoryexist(false);
                    setCategoryName("");
                    setIsSubmit(false);
                  }}
                  isError={isflag}
                  errorMsg={isflag ? "Category is Required" : ""}
                />
              </Grid>
            ) : (
              ""
            )}

            {categoryPath === "support" ? (
              <>
                <Grid item lg={6} xs={12} mt={2}>
                  <SelectMenu
                    listData={CATEGORY_SUPPORT}
                    value={flag}
                    placeholder="Select Category"
                    onchangehandler={(e) => {
                      setFlag(e.target.value);
                      getSupportUtilityCategroy(e);
                      setIsflag(false);
                      setErrCateExitemsg("");
                      setCategoryexist(false);
                      setCategoryName("");
                      setCategoryDesc("");
                      setIsSubmit(false);
                    }}
                    isError={isflag}
                    errorMsg={isflag ? "Category is Required" : ""}
                  />
                </Grid>
                {flag === 1 ? (
                  <Grid item lg={8} xs={12} mt={2}>
                    <TextArea
                      disabled={false}
                      isRequired={true}
                      type="text"
                      label={"Add Description"}
                      value={categoryDesc}
                      placeholder="Enter Description here..."
                      onChange={(e) => categoryHandlerDesc(e)}
                      isError={isflag}
                      errorMsg={
                        isflag ? "Category Description is Required" : ""
                      }
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {categoryPath === "resourse" ? (
              <>
                <Grid item lg={6} xs={12} mt={2}>
                  <SelectMenu
                    listData={CATEGORY_RESOURSE}
                    value={flag}
                    placeholder="Select Category"
                    onchangehandler={(e) => {
                      setFlag(e.target.value);
                      getUtilityCategory(e);
                      setIsflag(false);
                      setErrCateExitemsg("");
                      setCategoryexist(false);
                      setCategoryName("");
                      setIsSubmit(false);
                    }}
                    isError={isflag}
                    errorMsg={isflag ? "Category is Required" : ""}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>

          <Grid item container mt={1.5} lg={12} spacing={1}>
            <Grid item lg={6} mt={2} xs={12} className="Catoption">
              <InputField
                disabled={false}
                isRequired={true}
                type="text"
                label={"Add Options"}
                value={categoryName}
                placeholder="Add Options here..."
                onChange={(e) => categoryHandler(e)}
                isError={isflag}
                errorMsg={isflag ? "Category Name is Required" : ""}
              />
              {categoryexist ? (
                <div className="show-error">{errCateExitemsg}</div>
              ) : (
                ""
              )}
            </Grid>
            <Grid className="smallAddBtn" item mt={6} lg={1.1}>
              <Buttons
                disabled={isSubmit ? true : false}
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                onClick={(e) => submitData(e)}
                id="btnAddEquipment"
                varientAddIconBlue={true}
              ></Buttons>
            </Grid>
          </Grid>
          {checkPrivileges([12]) && parseInt(ORG_ID()) === HBNEXT_ORG_ID && (
            <Grid container m={1}>
              <Grid item lg={6} xs={12} sm={12} md={4} mt={1}>
                <div className="typeTxt">
                  <Checkbox
                    checked={checkBox}
                    onChange={() => setcheckBox(!checkBox)}
                    label={"Global Category"}
                  />
                </div>
              </Grid>
            </Grid>
          )}
          <Grid item container mt={3.5} lg={12} xs={12} spacing={1}>
            <Grid item lg={8} xs={12}>
              {catselectid ? (
                <CategoryData
                  categoryPath={categoryPath}
                  data={data}
                  catselectid={catselectid}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid item mt={6}>
            <div className="Button_style1">
              {catselectid === 5 ||
              catselectid === 6 ||
              catselectid === 7 ||
              catselectid === 9 ||
              catselectid === 10 ||
              (catselectid === 1 && categoryPath === "assets") ? (
                <CSVLink
                  data={CsvActiveData ? CsvActiveData : ""}
                  headers={headers}
                  filename={`${catname}.csv`}
                  className="btn btn-primary"
                  target="_blank"
                >
                  {CsvActiveData?.length > 0 ? (
                    <Buttons
                      label={"Export Data"}
                      disabled={catselectid ? false : true}
                      varientContainedExport={true}
                    />
                  ) : (
                    ""
                  )}
                </CSVLink>
              ) : (
                ""
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
