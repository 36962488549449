import React, { useEffect } from "react";
import "./Accordion.scss";
import FormLabels from "../formLabel/FormLabels";
import RadioButton from "../radioButton/RadioButton";
import Accordion from "@mui/material/Accordion";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import NotAcceptableBox from "../../notAcceptableBox/notAcceptableBox";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { questionTypeData } from "../../../constant/inspectionConstant";
import FormHelperText from "@mui/material/FormHelperText";
import {
  responseQuestionSet,
  getFormdata,
} from "../../../utils/incpectionHelper";
import { removeWordSpace } from "../../../utils/helper";
import AcceptableBox from "../../acceptableBox/acceptableBox";
function Accordions({
  accordionSummarys,
  accordionDetails,
  length,
  varientSupport,
  varientGeneral,
  skipQuestion,
  itemKeySub,
  qestSetState,
  setQestSetState,
  parentElementData,
  setImage,
  skip,
  filesId,
  filesUrl,
  edit,
  percentDone,
  subError,
  assetId = { assetId },
  assetTypeValue,
  userClear,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = React.useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, []);

  const radioButtonOnchangeHandler = React.useCallback(
    (e, key, keySub, typeItem) => {
      const { value } = typeItem;
      const keyParent = parentElementData?.itemKey;
      if (keyParent) {
        const dataForm = getFormdata(qestSetState, keyParent, keySub, key);
        const dataForms = { ...dataForm };
        if (dataForm[removeWordSpace(value)] === undefined) {
          dataForms[removeWordSpace(value)] = {};
        }
        dataForms.quesType = (typeItem && { ...typeItem }) ?? {};

        setQestSetState((prevState) =>
          responseQuestionSet(
            prevState,
            parentElementData,
            keySub,
            key,
            dataForms
          )
        );
      }
    },
    [parentElementData, qestSetState, setQestSetState]
  );

  const ischecked = (key, keySub) => {
    const keyParent = parentElementData?.itemKey;
    if (keyParent) {
      const dataForm = getFormdata(qestSetState, keyParent, keySub, key);
      const quesType = dataForm?.quesType ?? {};
      return quesType;
    } else {
      return {};
    }
  };

  const accordionExpandOnError = React.useCallback(() => {
    const { itemKey } = parentElementData;
    const questset =
      qestSetState && qestSetState.filter((item) => item.key === itemKey)[0];
    const questsetsub =
      questset &&
      questset.subcategories.filter((item) => item.key === itemKeySub)[0];
    const isError = questsetsub.questions.filter(
      (qs) => qs?.optionError !== undefined
    );
    const exp =
      isError.length > 0
        ? questsetsub?.skip?.IsSubCategory
          ? false
          : true
        : false;
    setExpanded((prevState) => (prevState ? prevState : exp));
  }, [qestSetState, parentElementData, itemKeySub]);

  React.useEffect(() => {
    if (skip?.IsSubCategory === true) {
      setExpanded(false);
    }
  }, [skip]);
  useEffect(() => {
    if (skip && edit === true) {
      if (skip?.IsSubCategory === true) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    }
  }, [skip, edit]);
  React.useEffect(() => {
    accordionExpandOnError();
  }, [accordionExpandOnError]);

  const isValidateError = (key) => {
    const { itemKey } = parentElementData;
    const questset =
      qestSetState && qestSetState.filter((item) => item.key === itemKey)[0];
    const questsetsub =
      questset &&
      questset.subcategories.filter((item) => item.key === itemKeySub)[0];
    const isError = questsetsub.questions.filter((qs) => qs?.key === key)[0];
    return isError?.optionError ?? "";
  };
  const completeds = accordionDetails?.filter(
    (items) => items?.isQuestionComplete
  );
  return (
    <div>
      {varientSupport && (
        <Accordion
          style={{ width: "100%" }}
          mt={3}
          expanded={expanded}
          onChange={() => handleChange()}
        >
          <AccordionSummary
            expandIcon={expanded ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "lato",
                weight: 700,
                fontSize: "22px",
                color: "#333333",
              }}
            >
              {accordionSummarys}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <p
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "left",
                fontFamily: "lato",
                weight: 400,
                fontSize: "16px",
                color: "#999999",
              }}
            >
              {accordionDetails}
            </p>
          </AccordionDetails>
        </Accordion>
      )}

      {varientGeneral && (
        <Accordion
          className={[
            "accordion_body",
            skip?.IsSubCategory && "disable_sub_accordian",
          ].join(" ")}
          expanded={expanded}
          onChange={() => (skip?.IsSubCategory ? "" : handleChange())}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{ flexDirection: "row-reverse" }}
          >
            <div className="accordian_header_content">
              <Typography>{accordionSummarys}</Typography>
              <div className="accordian_header_content_child">
                <button
                  onClick={(e) =>
                    skipQuestion &&
                    skipQuestion(
                      e,
                      accordionSummarys,
                      itemKeySub,
                      parentElementData
                    )
                  }
                >
                  {skip?.IsSubCategory ? "Undo" : "Skip"}
                </button>
                <Typography>
                  {completeds?.length}/{accordionDetails?.length} Inspected{" "}
                  {Math.trunc(percentDone)} (%)
                </Typography>
              </div>
            </div>
          </AccordionSummary>

          {accordionDetails?.map((data, index) => {
            return (
              <AccordionDetails key={data?.key}>
                <Grid container>
                  <FormControl error={true}>
                    <FormHelperText className="form_label_error">
                      {isValidateError(data?.key)}
                    </FormHelperText>
                    <div className="overideLables quesLable">
                      <div className="formLabel spaceLable">
                        {data?.index}
                        {". "}
                      </div>
                      <FormLabels label={data?.value} />
                    </div>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className="radioGroup"
                    >
                      {questionTypeData &&
                        questionTypeData.map((typeItem) => (
                          <RadioButton
                            key={typeItem.id}
                            checked={
                              ischecked(data?.key, itemKeySub)?.id ===
                              typeItem?.id
                            }
                            value={typeItem.value}
                            label={typeItem.label}
                            onChange={(e) =>
                              radioButtonOnchangeHandler(
                                e,
                                data?.key,
                                itemKeySub,
                                typeItem
                              )
                            }
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {ischecked(data?.key, itemKeySub)?.id === 0 && (
                  <>
                    <AcceptableBox
                      qestSetState={qestSetState}
                      setImage={setImage}
                      filesId={filesId}
                      filesUrl={filesUrl}
                      setQestSetState={setQestSetState}
                      parentElementData={parentElementData}
                      empType={{
                        key: data?.key,
                        keySub: itemKeySub,
                      }}
                      assetId={assetId}
                      assetTypeValue={assetTypeValue}
                      userClear={userClear}
                    />
                  </>
                )}
                {ischecked(data?.key, itemKeySub)?.id === 1 && (
                  <NotAcceptableBox
                    qestSetState={qestSetState}
                    setImage={setImage}
                    filesId={filesId}
                    filesUrl={filesUrl}
                    setQestSetState={setQestSetState}
                    parentElementData={parentElementData}
                    empType={{
                      key: data?.key,
                      keySub: itemKeySub,
                    }}
                    assetId={assetId}
                    assetTypeValue={assetTypeValue}
                    userClear={userClear}
                  />
                )}
              </AccordionDetails>
            );
          })}
        </Accordion>
      )}
      {subError && (
        <p className="error mb-1">Please enter this required field.</p>
      )}
    </div>
  );
}

export default React.memo(Accordions);
