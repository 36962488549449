import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FromToDatePicker from "../../../../library/custom/datePicker/DatePicker";
import { Card, CardContent, Box, CardActions } from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import Grid from "@mui/material/Grid";
import { getFormatedDate } from "../../../../utils/helper";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { ORG_ID, PAGE_SIZE, SITE_STATUS } from "../../../../constant/constant";
import commonImages from "../../../../assets";
import "./siteFilter.scss";

const SiteFilter = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleSearch,
  resetFilterAPI,
  issite,
  clients,
  isSiteDate = true,
}) => {
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    search: "",
    pageSize: PAGE_SIZE,
    page: 1,
    status: "",
    //customerId: ORG_ID(),
    customerId: ORG_ID(),
  });

  const resetFilter = () => {
    setFilter({
      startDate: "",
      endDate: "",
      search: "",
      pageSize: PAGE_SIZE,
      page: 1,
      status: "",
      customerId: ORG_ID(),
    });
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      customerId: ORG_ID(),
      startDate: "",
      endDate: "",
      search: "",
      status: "",
    };
    handleSearch(data);
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases filter_mui_1">
          <CardContent>
            <Grid container>
              <Grid item lg={12} md={7}>
                {isSiteDate && (
                  <Box className="" sx={{ pb: 2 }}>
                    <Typography className="filterLabel" gutterBottom variant="h5">
                      Time Period
                    </Typography>
                    <Box className="datePicker_row">
                      <div>
                        <Typography variant="subtitle1">From</Typography>
                        <FromToDatePicker
                          maxDateEnable={true}
                          value={filter.startDate}
                          onChangeHandler={(value) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              startDate: value,
                            }))
                          }
                        />
                      </div>
                      <div>
                        <Typography variant="subtitle1">To</Typography>
                        <FromToDatePicker
                          minDate={filter.startDate}
                          maxDateEnable={true}
                          value={filter.endDate}
                          onChangeHandler={(value) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              endDate: value,
                            }))
                          }
                        />
                      </div>
                    </Box>
                  </Box>
                )}
                {issite && (
                  <Box className="" sx={{ pb: 2 }}>
                    <Typography className="filterLabel" gutterBottom variant="h5">
                      Status
                    </Typography>
                    <Box>
                      <FormGroup>
                        {SITE_STATUS.map((filterItem, index) => (
                          <FormControlLabel
                            key={filterItem.Value}
                            control={
                              <img
                                className="filterListCheckbox"
                                checked={
                                  filter.status == filterItem.value
                                    ? true
                                    : false
                                }
                                alt=""
                                src={
                                  filter.status == filterItem.value
                                    ? commonImages.check
                                    : commonImages.noCheck
                                }
                                onClick={() =>
                                  setFilter((prevState) => ({
                                    ...prevState,
                                    status: filterItem.value,
                                  }))
                                }
                              />
                            }
                            label={filterItem.key}
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Buttons primary={true} label={"Reset"} onClick={resetFilter} />
            <Buttons
              primary={true}
              label={"Apply"}
              onClick={(e) => handleSearch(filter)}
            />
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default SiteFilter;
