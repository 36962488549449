import React from "react";

function ReportUtilities() {
  return (
    <div>
      <h1 style={{ fontSize: 40 }}>Coming Soon...</h1>
    </div>
  );
}

export default ReportUtilities;
