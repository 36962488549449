import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "./SwInspectionReports.scss";
import L from "leaflet";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import "./SwInspectionReports.scss";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchIcon from "@mui/icons-material/Search";
import Legend from "./Legend";
import redIcon from "../../../../assets/redIcon.svg";
import BlueIcon from "../../../../assets/BlueIcon.svg";
import redCloudIcon from "../../../../assets/redCloudIcon.svg";
import BlueCloudIcon from "../../../../assets/BlueCloudIcon.svg";


function ReportMap({ getOrganisationMapList }) {
  const [clientNameFilter, setClientNameFilter] = useState("");
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const [inspectorNameFilter, setInspectorNameFilter] = useState("");
  const [modifiedDataArray, setModifiedDataArray] = useState([]);
  const [map, setMap] = useState(null);

  const customIcon = ({ inspectorName, isRainfallAboveThreshold }) => {
    const isSingleInspector = inspectorName.split(",").length === 1;
    let iconUrl;

    if (isSingleInspector && !isRainfallAboveThreshold) {
      iconUrl = BlueIcon;
    } else if (isSingleInspector && isRainfallAboveThreshold) {
      iconUrl = BlueCloudIcon;
    } else if (!isSingleInspector && !isRainfallAboveThreshold) {
      iconUrl = redIcon;
    } else if (!isSingleInspector && isRainfallAboveThreshold) {
      iconUrl = redCloudIcon;
    }

    return L.icon({
      iconUrl,
      iconSize: [30, 50], 
      iconAnchor: [12, 41], 
      popupAnchor: [1, -34], 
    });
  };


  useEffect(() => {
    let modifiedDataArray = [];
    getOrganisationMapList?.data?.swMapReportList?.forEach((item) => {
      let geocode = item.latLong.split(",");
      let latitude = parseFloat(geocode[0]);
      let longitude = parseFloat(geocode[1]);
      if (!isNaN(latitude) && !isNaN(longitude)) {
        item.geocode = [latitude, longitude];
        delete item.latitude;
        delete item.longitude;
        modifiedDataArray.push(item);
      }
    });
    setModifiedDataArray(modifiedDataArray);
  }, [getOrganisationMapList]);

  const filteredDataArray = modifiedDataArray.filter((item) => {
    return (
      item.clientName
        .trim()
        .toLowerCase()
        .includes(clientNameFilter.trim().toLowerCase()) &&
      item.projectName
        .trim()
        .toLowerCase()
        .includes(projectNameFilter.trim().toLowerCase()) &&
      item.inspectorName
        .trim()
        .toLowerCase()
        .includes(inspectorNameFilter.trim().toLowerCase())
    );
  });

  return (
    <>
      <Grid item container mt={1} spacing={2} mb={2}>
        <Grid item lg={4} xs={12} sm={12} md={4}>
          <FormControl fullWidth>
            <FormLabels label={"Client Name"} isRequired={false} />
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search by Client Name"
                    type="text"
                    value={clientNameFilter}
                    className=""
                    onChange={(e) => setClientNameFilter(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </FormControl>
        </Grid>

        <Grid item lg={4} xs={12} sm={12} md={4}>
          <FormControl fullWidth>
            <FormLabels label={"Project Name"} isRequired={false} />
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search by Project Name"
                    type="text"
                    value={projectNameFilter}
                    className=""
                    onChange={(e) => setProjectNameFilter(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </FormControl>
        </Grid>

        <Grid item lg={4} xs={12} sm={12} md={4}>
          <FormControl fullWidth>
            <FormLabels label={"Inspector Name"} isRequired={false} />
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search by Inspector Name"
                    type="text"
                    value={inspectorNameFilter}
                    className=""
                    onChange={(e) => setInspectorNameFilter(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <div className="MapOverride">
        <MapContainer
          center={[33.7488, 84.3877]}
          zoom={1.5}
          maxZoom={18}
          scrollWheelZoom={true}
          ref={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tile.openstreetmap.de/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup chunkedLoading>
            {filteredDataArray.map((item, index) => (
              <Marker
                position={item?.geocode}
                icon={customIcon(item)}
                key={index}
              >
                <Popup>
                  <div className="popupMapstyleConatiner">
                    <h3>
                      <span className="popupMapstyle">Client Name :</span>{" "}
                      {item.clientName}
                    </h3>
                    <p>
                      {" "}
                      <span className="popupMapstyle">Project name :</span>
                      {item.projectName}
                    </p>
                    <p>
                      <span className="popupMapstyle">
                        Last Inspection Date :
                      </span>
                      {item.lastInspectionDate || "N/A"}
                    </p>
                    <p>
                      <span className="popupMapstyle">Inspector Name :</span>{" "}
                      {item.inspectorName || "N/A"}
                    </p>
                    <p>
                      <span className="popupMapstyle">Total Inspections :</span>{" "}
                      {item.totalInspections}
                    </p>
                    <p>
                      <span className="popupMapstyle">
                        Rainfall Threshold (Last day):
                      </span>{" "}
                      {item.rainfallThreshold}{" "}
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                          color: "#1f1f1f",
                        }}
                      >
                        inches
                      </span>
                    </p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
          <FullscreenControl position="topright" />
          <Legend map={map} />
        </MapContainer>
      </div>
    </>
  );
}

export default ReportMap;
