import * as credentialAction from "../actions/credentialAction";
import { credentialType } from "../constants/credentialType";

import {
  createCredential,
  updateCredential,
  getCredentialList,
  getCredentialDetails,
  getAssignUserList,
  getUserCredentialList,
  createUserCredential,
  getSearchCredential,
  assignCredential,
  getCredentialListByUser,
  getCredentialListByCredential,
  getCredentialViewDetails,
  updateUserCredential,
  createAdminCredential,
  deleteCredential,
} from "../../services/credentialService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* createCredentialSaga(params) {
  console.debug("createCaseSaga");
  try {
    const { data, status, error } = yield call(createCredential, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.createCredentialSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Credential Failed" };
    yield put(credentialAction.createCredentialFailure(data));
  }
}

export function* updateCredentialSaga(params) {
  console.debug("updateCredentialSaga");
  try {
    const { data, status, error } = yield call(updateCredential, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.updateCredentialSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Credential Failed" };
    yield put(credentialAction.updateCredentialFailure(data));
  }
}

export function* getCredentialListSaga(params) {
  console.debug("getCredentialListSaga");
  try {
    const { data, status, error } = yield call(getCredentialList, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.getCredentialListSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Credential List Failed" };
    yield put(credentialAction.getCredentialListFailure(data));
  }
}
export function* getCredentialDetailsSaga(datas) {
  console.debug("getCredentialDetailsSaga");
  try {
    const { data, status, error } = yield call(getCredentialDetails, datas);
    if (!data && status !== 200) throw apiError(error);
    const rolePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.getCredentialDetsilsSuccess({
        rolePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Role List Details Failed" };
    yield put(credentialAction.getCredentialDetailsFailure(data));
  }
}

export function* getAssignUserListSaga(params) {
  console.debug("getAssignUserListSaga");
  try {
    const { data, status, error } = yield call(getAssignUserList, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.getAssignUserListSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Credential List Failed" };
    yield put(credentialAction.getAssignUserListFailure(data));
  }
}

export function* getCredentialListByUsersSaga(params) {
  console.debug("getCredentialListByUsersSaga");
  try {
    const { data, status, error } = yield call(getCredentialListByUser, params);
    if (!data && status !== 200) throw apiError(error);
    const userPage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.getCredentialListByUsersSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Credential List Failed" };
    yield put(credentialAction.getAssignUserListFailure(data));
  }
}

export function* getUserCredentialListSaga(params) {
  console.debug("getUserCredentialListSaga");
  try {
    const { data, status, error } = yield call(getUserCredentialList, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.getUserCredentialListSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Credential List Failed" };
    yield put(credentialAction.getUserCredentialListFailure(data));
  }
}

export function* getCredentialListByCredentialsSaga(params) {
  console.debug("getCredentialListByCredentialsSaga");
  try {
    const { data, status, error } = yield call(
      getCredentialListByCredential,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.getCredentialListByCredentialsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Credential List Failed" };
    yield put(credentialAction.getUserCredentialListFailure(data));
  }
}

export function* createUserCredentialSaga(params) {
  console.debug("createUserCredentialSaga");
  try {
    const { data, status, error } = yield call(createUserCredential, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.createUserCredentialSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Credential Failed" };
    yield put(credentialAction.createUserCredentialFailure(data));
  }
}

export function* getSearchCredentialSaga(params) {
  console.debug("getSearchCredentialSaga");
  try {
    const {
      data: result,
      status,
      error,
    } = yield call(getSearchCredential, params);
    if (!result && status !== 200) throw apiError(error);
    const data = {
      responseMessages: {
        responseCode: "HBNG001",
      },
      result: typeof result === "string" ? JSON.parse(result) : result,
    };
    const casePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.getSearchCredentialSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Credential List Failed" };
    yield put(credentialAction.getSearchCredentialFailure(data));
  }
}
export function* assignCredentialSaga(params) {
  console.debug("assignCredentialSaga");
  try {
    const { data, status, error } = yield call(assignCredential, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.assignCredentialSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Assign Credential Failed" };
    yield put(credentialAction.assignCredentialFailure(data));
  }
}
export function* getCredentialViewDetailsSaga(datas) {
  console.debug("getCredentialViewDetailsSaga");
  try {
    const { data, status, error } = yield call(getCredentialViewDetails, datas);
    if (!data && status !== 200) throw apiError(error);
    const rolePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.getCredentialViewDetsilsSuccess({
        rolePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Credential Details Failed" };
    yield put(credentialAction.getCredentialViewDetailsFailure(data));
  }
}

export function* updateUserCredentialSaga(params) {
  console.debug("updateCredentialSaga");
  try {
    const { data, status, error } = yield call(updateUserCredential, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.updateUserCredentialSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Credential Failed" };
    yield put(credentialAction.updateUserCredentialFailure(data));
  }
}

export function* createAdminCredentialSaga(params) {
  console.debug("createAdminCredentialSaga");
  try {
    const { data, status, error } = yield call(createAdminCredential, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.createAdminCredentialSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Credential Failed" };
    yield put(credentialAction.createAdminCredentialFailure(data));
  }
}

function* deleteCredentialSaga(payload) {
  console.debug("deleteCredentialSaga");
  try {
    const { data, status, error } = yield call(deleteCredential, payload);
    if (!data && status !== 200) throw apiError(error);
    const credentialPage = credentialType.CREDENTIAL_PAGE;
    yield put(
      credentialAction.deleteCredentialSuccess({
        credentialPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Delete Credential list" };
    yield put(credentialAction.deleteCredentialFailure(data));
  }
}

const myCredentialSaga = [
  takeLatest(credentialType.POST_CREDENTIAL_PENDING, createCredentialSaga),
  takeLatest(credentialType.GET_CREDENTIAL_LIST_PENDING, getCredentialListSaga),
  takeLatest(credentialType.PUT_CREDENTIAL_PENDING, updateCredentialSaga),
  takeLatest(credentialType.GET_CREDENTIAL_DETAILS, getCredentialDetailsSaga),
  takeLatest(
    credentialType.GET_ASSIGN_USER_LIST_PENDING,
    getAssignUserListSaga
  ),
  takeLatest(
    credentialType.GET_USER_CREDENTIAL_LIST_PENDING,
    getUserCredentialListSaga
  ),
  takeLatest(
    credentialType.POST_USER_CREDENTIAL_PENDING,
    createUserCredentialSaga
  ),
  takeLatest(
    credentialType.GET_SEARCH_CREDENTIAL_PENDING,
    getSearchCredentialSaga
  ),
  takeLatest(
    credentialType.POST_ASSIGN_CREDENTIAL_PENDING,
    assignCredentialSaga
  ),
  takeLatest(
    credentialType.GET_CREDENTIAL_LIST_BY_USERS_PENDING,
    getCredentialListByUsersSaga
  ),
  takeLatest(
    credentialType.GET_CREDENTIAL_LIST_BY_CREDENTIALS_PENDING,
    getCredentialListByCredentialsSaga
  ),
  takeLatest(
    credentialType.GET_CREDENTIAL_VIEW_DETAILS_PENDING,
    getCredentialViewDetailsSaga
  ),
  takeLatest(
    credentialType.PUT_CREDENTIAL_USER_PENDING,
    updateUserCredentialSaga
  ),

  takeLatest(
    credentialType.POST_ADMIN_CREDENTIAL_PENDING,
    createAdminCredentialSaga
  ),

  takeLatest(credentialType.DELETE_CREDENTIAL, deleteCredentialSaga),
];

export default myCredentialSaga;
