import { reprimandType } from "../constants/reprimandType";

export const postReprimand = (data) => ({
    type: reprimandType.POST_REPRIMAND_PENDING,
    payload: data,
});

export const updateReprimand = (data) => ({
  type: reprimandType.UPDATE_REPRIMAND_PENDING,
  payload: data,
});

export const getReprimandList = (data) => ({
  type: reprimandType.GET_REPRIMAND_LIST_PENDING,
  payload: data,
});

//REVOKE_DATE_UNMOUNT
export const revokeDataUnmount = () => ({
  type: reprimandType.REVOKE_DATA_UNMOUNT
})

// GET REPRIMABND DETAILS
export const getReprimandDetails = (id) => ({
  type: reprimandType.GET_REPRIMAND_DETAILS_PENDING,
  payload:  id
})
// Get reprimand count
export const getReprimandCount = (data) => ({
  type: reprimandType.GET_REPRIMAND_COUNT_PENDING,
  data,
});

export const getReprimandCountSuccess = (data) => ({
  type: reprimandType.GET_REPRIMAND_COUNT_SUCCESS,
  data,
});

export const getReprimandCountFailure = (error) => ({
  type: reprimandType.GET_REPRIMAND_COUNT_FAILED,
  error,
});