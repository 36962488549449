import * as roleAction from "../actions/roleAction ";
import { roleType } from "../constants/roleType";
import {
  createRole,
  getRoleList,
  createAssignRole,
  getRoleListDetails,
  updateRoleList,
  deleteRole,
  getUserInRoleList
} from "../../services/roleService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* createRoleSaga(params) {
  console.debug("createCaseSaga");
  try {
    const { data, status, error } = yield call(createRole, params);
    if (!data && status !== 200) throw apiError(error);
    const rolePage = roleType.ROLE_PAGE;
    yield put(
      roleAction.createRoleSuccess({
        rolePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Role Failed" };
    yield put(roleAction.createRoleFailure(data));
  }
}

export function* updateRoleListSaga(params) {
  console.debug("updateRoleSaga");
  try {
    const { data, status, error } = yield call(updateRoleList, params);
    if (!data && status !== 200) throw apiError(error);
    const rolePage = roleType.ROLE_PAGE;
    yield put(
      roleAction.updateRoleListSuccess({
        rolePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Role List Failed" };
    yield put(roleAction.updateRoleListFailure(data));
  }
}

export function* getRoleListSaga(params) {
  console.debug("getRoleListSaga");
  try {
    const { data, status, error } = yield call(getRoleList, params);
    if (!data && status !== 200) throw apiError(error);
    const rolePage = roleType.ROLE_PAGE;
    yield put(
      roleAction.getRoleListSuccess({
        rolePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Role List Failed" };
    yield put(roleAction.getRoleListFailure(data));
  }
}

export function* createAssignRoleSaga(params) {
  console.debug("createAssignRoleSaga");
  try {
    const { data, status, error } = yield call(createAssignRole, params);
    if (!data && status !== 200) throw apiError(error);
    const rolePage = roleType.ROLE_PAGE;
    yield put(
      roleAction.createAssignRoleSuccess({
        rolePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Assign Role Failed" };
    yield put(roleAction.createAssignRoleFailure(data));
  }
}

export function* getRoleListDetailsSaga(datas) {
  console.debug("roleListDetailsSaga");
  try {
    const { data, status, error } = yield call(getRoleListDetails, datas);
    if (!data && status !== 200) throw apiError(error);
    const rolePage = roleType.ROLE_PAGE;
    yield put(
      roleAction.getRoleListDetsilsSuccess({
        rolePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Role List Details Failed" };
    yield put(roleAction.getRoleListDetailsFailure(data));
  }
}

function* deleteRoleSaga(payload) {
  console.debug("deleteRoleSaga");
  try {
    const { data, status, error } = yield call(deleteRole, payload);
    if (!data && status !== 200) throw apiError(error);
    const rolePage = roleType.ROLE_PAGE;
    yield put(
      roleAction.deleteRoleSuccess({
        rolePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Delete Role list" };
    yield put(roleAction.deleteRoleFailure(data));
  }
}

function* getUserInRoleListSaga(payload) {
  console.debug("getUserInRoleListSaga");
  try {
    const { data, status, error } = yield call(getUserInRoleList, payload);
    if (!data && status !== 200) throw apiError(error);
    const rolePage = roleType.ROLE_PAGE;
    yield put(
      roleAction.getUserInRoleListSuccess({
        rolePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "User In Role list" };
    yield put(roleAction.getUserInRoleListFailure(data));
  }
}




const myRoleSaga = [
  takeLatest(roleType.POST_ROLE_PENDING, createRoleSaga),
  takeLatest(roleType.GET_ROLE_LIST_PENDING, getRoleListSaga),
  takeLatest(roleType.POST_UPDATE_ROLE_LIST_PENDING, updateRoleListSaga),
  takeLatest(roleType.POST_ASSIGN_ROLE_PENDING, createAssignRoleSaga),
  takeLatest(roleType.GET_ROLE_LIST_DETAILS, getRoleListDetailsSaga),
  takeLatest(roleType.DELETE_ROLE, deleteRoleSaga),
  takeLatest(roleType.GET_USER_IN_ROLE_LIST, getUserInRoleListSaga),


];

export default myRoleSaga;
