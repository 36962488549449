import React from "react";
import { REPORTSMANAGEMENT } from "../../../constant/constant";
import Card from "../../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import { checkPrivileges } from "../../../utils/rolesHelper";

const ReportsManagementList = ({ onClick }) => {
  return (
    <Grid container className="CardListContaner">
      {checkPrivileges([12, 114, 115, 116]) && (
        <>
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={REPORTSMANAGEMENT[0]}
          />
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={REPORTSMANAGEMENT[1]}
          />
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={REPORTSMANAGEMENT[2]}
          />
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={REPORTSMANAGEMENT[3]}
          />
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={REPORTSMANAGEMENT[4]}
          />
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={REPORTSMANAGEMENT[5]}
          />
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={REPORTSMANAGEMENT[6]}
          />
        </>
      )}
    </Grid>
  );
};

export default ReportsManagementList;
