import { projectType } from "../../storm-water/types/projectType";
import { API_STATUS } from "../../../../src/constant/constant";

const initialState = {
  flow: "project",
  isError: false,
  isLoading: false,
};
export const createSwProjectSampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.POST_PROJECT_SAMPLE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.POST_PROJECT_SAMPLE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.POST_PROJECT_SAMPLE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const swprojectSampleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.GET_SW_PROJECT_SAMPLE_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.GET_SW_PROJECT_SAMPLE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.GET_SW_PROJECT_SAMPLE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
