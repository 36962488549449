import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ViewImages from "../viewImages/viewImages";

const PoliceReporting = ({ policeReporting }) => {
  const imageSize = {
    size: "small",
  };

  const image = [];
  const totalImg = policeReporting?.picturesOfRoadway?.map((i) =>
    image.push(i.value)
  );
  return (
    <div className="commonOveride">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">Police Reporting:</Typography>

            <Box className="columns">
              <Typography className="listView">
                Responding Police Department:
                <span className="font-w400">
                  {policeReporting?.police_department}
                </span>
              </Typography>
              <Typography className="listView">
                Police Case Number:
                <span className="font-w400">
                  {policeReporting?.police_case_number}
                </span>
              </Typography>
            </Box>
            <Typography className="listView">
              Number of Injuries Reported:
              <span className="font-w400">
                {policeReporting?.total_injuries}
              </span>
            </Typography>

            <Typography className="listView desNone">
              Pictures of Roadway
              <ViewImages image={image} imageSize={imageSize} />
            </Typography>
          </Box>
          <Box className="commoninfoRight"></Box>
        </Box>
      </Box>
    </div>
  );
};

export default PoliceReporting;
