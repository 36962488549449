export const permitType = {
  POST_ADD_PERMIT_PENDING:
    "[ADD_PERMIT_PENDING][REQUEST] Add Permit pending in storm Water",
  POST_ADD_PERMIT_SUCCESS:
    "[ADD_PERMIT_SUCCESS][REQUEST] Add Permit successfull in storm Water",
  POST_ADD_PERMIT_FAILURE:
    "[ADD_PERMIT_FAILURE][REQUEST] Add Permit failure in storm Water",

  PERMIT_PAGE: "PERMIT_PAGE",

  GET_PERMIT_DETAILS_PENDING:
    "[GET_PERMIT_DETAILS_PENDING][REQUEST] Get Permit details pending",
  GET_PERMIT_DETAILS_SUCCESS:
    "[ GET_PERMIT_DETAILS_SUCCESS][REQUEST] Get permit details success",
  GET_PERMIT_DETAILS_FAILURE:
    "[GET_PERMIT_DETAILS_FAILURE][REQUEST] Get permit details failure",

  GET_PERMIT_LIST_PENDING:
    "[GET_PERMIT_LIST_PENDING][REQUEST] Get Permit list pending",
  GET_PERMIT_LIST_SUCCESS:
    "[GET_PERMIT_LIST_SUCCESS][REQUEST] Get permit list success",
  GET_PERMIT_LIST_FAILURE:
    "[ GET_PERMIT_LIST_FAILURE][REQUEST] Get permit list failure",

  POST_UPDATE_PERMIT_PENDING:
    "[POST_UPDATE_PERMIT_PENDING][REQUEST] Update permit pending",
  POST_UPDATE_PERMIT_SUCCESS:
    "[POST_UPDATE_PERMIT_SUCCESS][REQUEST] Update permit success",
  POST_UPDATE_PERMIT_FAILURE:
    "[ POST_UPDATE_PERMIT_FAILURE][REQUEST] Update permit  failure",

  POST_ADD_PERMIT_TYPE_PENDING:
    "[POST_ADD_PERMIT_TYPE_PENDING][REQUEST] Add Permit Type pending in storm Water",
  POST_ADD_PERMIT_TYPE_SUCCESS:
    "[POST_ADD_PERMIT_TYPE_SUCCESS][REQUEST] Add Permit Type successfull in storm Water",
  POST_ADD_PERMIT_TYPE_FAILURE:
    "[ POST_ADD_PERMIT_TYPE_FAILURE][REQUEST] Add Permit Type failure in storm Water",

  GET_PERMIT_TYPE_LIST_PENDING:
    "[GET_PERMIT_TYPE_LIST_PENDING][REQUEST] Get Permit type list pending",
  GET_PERMIT_TYPE_LIST_SUCCESS:
    "[GET_PERMIT_TYPE_LIST_SUCCESS][REQUEST] Get permit type list success",
  GET_PERMIT_TYPE_LIST_FAILURE:
    "[GET_PERMIT_TYPE_LIST_FAILURE][REQUEST] Get permit type list failure",

  POST_UPDATE_PERMIT_TYPE_PENDING:
    "[POST_UPDATE_PERMIT_TYPE_PENDING][REQUEST] Update Permit Type pending in storm Water",
  POST_UPDATE_PERMIT_TYPE_SUCCESS:
    "[POST_UPDATE_PERMIT_TYPE_SUCCESS][REQUEST] Update Permit Type successfull in storm Water",
  POST_UPDATE_PERMIT_TYPE_FAILURE:
    "[POST_UPDATE_PERMIT_TYPE_FAILURE][REQUEST] Update Permit Type failure in storm Water",

  GET_GENERAL_PERMIT_LIST_PENDING:
    "[GET_GENERAL_PERMIT_LIST_PENDING][REQUEST] Get general Permit list pending",
  GET_GENERAL_PERMIT_LIST_SUCCESS:
    "[GET_GENERAL_PERMIT_LIST_SUCCESS][REQUEST] Get general permit list success",
  GET_GENERAL_PERMIT_LIST_FAILURE:
    "[GET_GENERAL_PERMIT_LIST_FAILURE][REQUEST] Get general permit list failure",

  POST_UPDATE_GENERAL_PERMIT_PENDING:
    "[POST_UPDATE_GENERAL_PERMIT_PENDING][REQUEST] Update General Permit pending in storm Water",
  POST_UPDATE_GENERAL_PERMIT_SUCCESS:
    "[POST_UPDATE_GENERAL_PERMIT_SUCCESS][REQUEST] Update General Permit successfull in storm Water",
  POST_UPDATE_GENERAL_PERMIT_FAILURE:
    "[POST_UPDATE_GENERAL_PERMIT_FAILURE][REQUEST] Update General Permit failure in storm Water",

  GET_PERMIT_TYPE_PENDING:
    "[GET_PERMIT_TYPE_PENDING][REQUEST] Get Permit Type pending",
  GET_PERMIT_TYPE_SUCCESS:
    "[GET_PERMIT_TYPE_SUCCESS][REQUEST] Get Permit Type success",
  GET_PERMIT_TYPE_FAILURE:
    "[GET_PERMIT_TYPE_FAILURE][REQUEST] Get Permit Type failure",
};
