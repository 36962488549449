import * as adminAction from "../actions/adminCommonAction";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";
import {
  getDropdownDataKeyValue,
  getInspections,
  getSupervisorByProject,
} from "../../services/adminCommonService";
import {
  getDropdownDataKeyValueType,
  getInspectionsType,
  getSupervisorByProjectType,
} from "../constants/adminCommonType";

export function* getInspectionsSaga(params) {
  try {
    const { data, status, error } = yield call(getInspections, params);
    if (!data && status !== 200) throw apiError(error);
    const page = getInspectionsType.CONTACT_PAGE;
    yield put(
      adminAction.getInspectionstSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspection Failed" };
    yield put(adminAction.getInspectionstSuccess(data));
  }
}

export function* getDropdownDataKeyValueSaga(params) {
  console.debug("contactListSaga");
  try {
    const { data, status, error } = yield call(getDropdownDataKeyValue, params);
    if (!data && status !== 200) throw apiError(error);
    const page = getInspectionsType.CONTACT_PAGE;
    yield put(
      adminAction.getDropdownDataKeyValueSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspection Failed" };
    yield put(adminAction.getDropdownDataKeyValueSuccess(data));
  }
}

export function* getSupervisorByProjectSaga(params) {
  console.debug("supervisorByProjectSaga");
  try {
    const { data, status, error } = yield call(getSupervisorByProject, params);
    if (!data && status !== 200) throw apiError(error);
    const page = getSupervisorByProjectType.SUPERVISOR_BY_PROJECT_PAGE;
    yield put(
      adminAction.getSupervisorByProjectSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Supervisor By Project Failed" };
    yield put(adminAction.getSupervisorByProjectFailure(data));
  }
}

const myAdminSaga = [
  takeLatest(getInspectionsType.GET_INSPECTION_PENDING, getInspectionsSaga),
  takeLatest(
    getDropdownDataKeyValueType.GET_DROPDOWN_DATA_KEY_VALUE_PENDING,
    getDropdownDataKeyValueSaga
  ),
  takeLatest(
    getSupervisorByProjectType.GET_SUPERVISOR_BY_PROJECT_PENDING,
    getSupervisorByProjectSaga
  ),
];

export default myAdminSaga;
