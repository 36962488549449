import React, { useState } from "react";
import ViewcCasetable from "../../../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../../../library/common/Loader";
import DialogBox from "../../../../library/common/DialogBox";
import NORow from "../../../../library/custom/noRow/noRow";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import "./contractor.scss";
import { ADMIN_PERSONAL_CONTRACTOR } from "../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";

import Buttons from "../../../../library/custom/button/Button";
import { PAGE_SIZE } from "../../../../constant/constant";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const ViewContractorDetails = ({
  addSubContractor,
  rows,
  personalContractorListData,
  loading,
  deleteList,
  compnayName,
  constractorId,
}) => {
  const navigate = useNavigate();
  const handleViewOnClick = () => {};

  const [openModal, setOpenModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState("");

  const deletehandle = () => {
    setOpenModal(false);
    deleteList(selectedRow.id);
  };

  const handleOnclick = (rows) => {
    navigate(ADMIN_PERSONAL_CONTRACTOR, {
      state: {
        contractorId: constractorId,
        editMode: true,
        companyName: compnayName,
        contractorPersonnelId: rows.row.id,
      },
    });
  };

  const deleteIncidents = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };
  const columns = [
    { field: "personnelFirstName", headerName: "First Name", width: 200 },
    { field: "personnelLastName", headerName: "Last Name", flex: 2 },
    { field: "personnelPhone", headerName: "Contact", flex: 2 },
    { field: "personnelEmail", headerName: "Email", flex: 2 },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={personalContractorListData?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={
            personalContractorListData?.contractorsPersonnelData ?? []
          }
          fileNameXL={"Contractor List"}
        />
      ),
      width: 170,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={() => handleViewOnClick()}
            editOnClick={() => handleOnclick(rows)}
            deleteOnClick={() => deleteIncidents(rows?.row)}
            edit={true}
            editLabel="Edit Personnel"
            download={false}
            viewLabel="View User"
            view={false}
            deleted={checkPrivileges([12, 43, 44, 145]) ? true : false}
            deleteLabel="Delete Personnel"
          />
        );
      },
    },
  ];
  return (
    <>
      {openModal && (
        <DialogBox
          open={openModal}
          rows={[]}
          deletehandle={deletehandle}
          handleCloseModal={() => setOpenModal(false)}
          view
        />
      )}
      <div className="topBlockBody">
        <div className="contractorName">
          <div className="name"> Contractor Name</div>
          <div className="head"> {rows?.contractorName}</div>
        </div>
        <div className="contractorName">
          <div className="name">Contractor Grade</div>
          <div className="head">{rows?.grade}</div>
        </div>
        {checkPrivileges([12, 43, 44, 145, 146]) && (
          <Buttons
            id="btnAddPersonal"
            varientAddIconBlue={true}
            label={"Add Personnel"}
            onClick={(e) => addSubContractor(e)}
          />
        )}
      </div>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Personnel List</div>
        </div>
      </section>
      <div className="desc_box row_uniq">
        <div className="table_style_ui">
          <ViewcCasetable
            getRowId={(r) => r.id}
            rows={personalContractorListData?.contractorsPersonnelData || []}
            rowCount={personalContractorListData?.noOfRecords}
            columns={columns}
            loading={loading}
            components={{
              LoadingOverlay: TableLoader,
              NoRowsOverlay: NORow,
            }}
            checkboxSelection={false}
            page="1"
            pagination
            pageSize={5}
            rowsPerPageOptions={[10, 20, 30]}
            backIconButtonProps={{ disabled: false }}
            disableSelectionOnClick
          />
        </div>
      </div>
    </>
  );
};

export default ViewContractorDetails;
