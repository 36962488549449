export const PERSONAL_INJURY = "/personal-injury";
export const VEHICLE_ACCIDENT = "/vehicle-accident";
export const UTILITY_DAMAGE = "/utility-damage";
export const STOLEN_PROPERTY = "/stolen-property";
export const NEAR_MISS = "/near-miss";

export const VIEW_PERSONAL_INJURY = "/view-case/view-incident";
export const VIEW_VEHICLE_ACCIDENT = "/view-case/vehicle-accident";
export const VIEW_UTILITY_DAMAGE = "/view-case/utility-damage";
export const VIEW_STOLEN_PROPERTY = "/view-case/stolen-property";
export const VIEW_NEAR_MISS = "/view-case/near-miss";

export const DASHBOARD = "/dashboard";
export const LOGIN = "/login";
export const ONBOARDING = "/onboarding";
export const SUPPORT = "/support";
export const REPRIMAND = "/reprimand";
export const REPRIMAND_LIST = "/reprimand/view";
export const VIEW_REPRIMAND_CONTAINER = "/reprimand/view/id";
export const INCIDENT_LIST = "/incident-list";
export const SWDASHBOARD = "/sw/dashboard";
export const LANDING_PAGE = "/landing/page";

// export const CASE_LIST = "/cases";

export const CASEINCIDENTS = "/cases/case-incidents";
export const CASES = "/cases";
export const ADDCASE = "/add-case";
export const EDITCASE = "/edit-case";

// inspection
export const ADD_INSPECTION = "/inspection/";
export const VIEW_INSPECTION_REPORT = "/inspection/view/report";
export const INSPECTION_REPORT = "/inspection/report";
export const INSPECTION_ADMIN = "/admin/inspection-configuration";
export const INSPECTION_CATEGORY = "/admin/inspection-category";
export const INSPECTION_SUB_CATEGORY = "/admin/inspection-subcategory";
export const INSPECTION_QUESTIONS = "/admin/inspection-questions";
export const INSPECTION_SET = "/admin/inspection-set";
export const INSPECTIONS = "/admin/inspections";
export const INSPECTIONS_ACKNOWLEDGMENT = "/admin/inspection-acknowledgment";

//LMS
export const CREATE_LESSON = "/lesson";
export const CREATE_COURSE = "/course";
export const LESSON = "/lesson/view";
export const COURSES = "/courses/view";
export const USERCOURSES = "/user-courses";
export const USERLESSON = "/user-lesson";
export const LESSONDETAIL = "/lesson/detail";
export const COURSESDETAIL = "/course/detail";
export const CREATE_QUIZ = "/create-quiz";
export const USER_QUIZ = "/user-quiz";
export const USER_ASSIGNED = "/user-course-list";
export const USER_ASSIGNED_LESSON = "/user-lesson-list";
export const TOOL_BOX_CREATE = "/create-safety-meeting";
export const TOOL_BOX_LIST = "/safety/meeting/list";
export const TOOL_BOX_DETAIL = "/safety/meeting/detail";
export const TOOL_BOX_ASSIGN = "/safety/meeting/assign";
export const TOOL_BOX_VERIFY_ATTENDEES = "/verify/attendees";
export const TOOL_BOX_ASSIGN_USER = "/safety/meeting/assign/user";
export const TOOL_BOX_USER = "/safety/meeting/user";
export const TOOL_BOX_USERCOUNT_ASSIGN = "/safety/meeting/user/status";

// observation
export const OBSERVATION = "/observation";
export const OPEN_ITEMS_LIST = "/openitemlist";
export const VIEW_OPEN_ITEMS_DETAILS = "/view/openitemdetails";
export const VIEW_OBSERVATION_DETAILS = "/viewobservationdetails";
export const VIEW_OBSERVATION_LISTS = "/view/observationlist";

export const VIEW_KUDOS = "/view-open-item/kudos";
export const VIEW_VIOLATION = "/view-open-item/violation";
export const VIEW_ACTION = "/view-open-item/action";
export const VIEW_REPRIMAND = "/view/reprimand";
export const KUDOS_LOG = "/kudosList";
export const VIOLATION_LOG = "/correctiveList";
export const REPRIMAND_LOG = "/reprimandList";
export const ACTION_LOG = "/actionList";

// admin
export const ADMIN_USER = "/admin/users";
export const ADD_ADMIN_USER = "/add/admin/users";
export const ADMIN_USER_CONFIGURATION = "/admin/user/configuration";
export const USER_CONFIGURATION_VIEW = "/admin/user/configuration/view";
export const ADMIN_NONPRIVILEGE_USER_LIST = "/admin/non-privilege/user/list";
export const ADD_ADMIN_NONPRIVILEGE_USER = "/admin/non-privilege/user";
export const USER_ONBOARD_EDIT = "/admin/user/onboard/edit";
export const ADMIN_GROUP = "/admin/groups";
export const ADD_ADMIN_GROUP = "/add/admin/group";
export const ADMIN_CONTACTS = "/admin/contacts";
export const ADMIN_PROFILE = "/admin/profile";
export const ADMIN_SAFETY_LOG = "/admin/safetylog";
export const ADMIN_RESOURCE = "/admin/resource";
export const ADMIN_ADD_RESOURCE = "/admin/add/resource";
export const ADMIN_VIEW_RESOURCE = "/admin/view/resource";
export const ADMIN_EDIT_RESOURCE = "/admin/edit/resource";
export const ADMIN_ADD_CONTACTS = "/admin/add/contacts";
export const ADMIN_VIEW_CONTACT = "/admin/view/contact";
export const ADMIN_EDIT_CONTACT = "/admin/edit/contact";
export const ADMIN_ASSETS = "/admin/assets";
export const ADMIN_VEHICLES = "/admin/vehicles";
export const ADMIN_EQUIPMENTS = "/admin/equipments";
export const ADMIN_ADD_EQUIPMENTS = "/admin/add/equipments";
export const ADMIN_EDIT_EQUIPMENT = "/admin/edit/equipment";
export const ADMIN_ADD_VEHICLES = "/admin/add/vehicles";
export const ADMIN_ADD_ASSETS = "/admin/add/assets";
export const ADMIN_DIVISION = "/admin/division";
export const ADMIN_ADD_DIVISION = "/admin/add/division";
export const ADMIN_VIEW_DIVISION = "/admin/view/division";
export const ADMIN_PROJECT = "/admin/project";
export const ADMIN_ADD_PROJECT = "/admin/add/project";
export const ADMIN_ASSIGN_ROLE = "/admin/assignrole";
export const ADMIN_ADD_NEW_ROLE = "/admin/add/newrole";
export const ADMIN_EDIT_DIVISION = "/admin/edit/division";
export const ADMIN_EDIT_NEW_ROLE = "/admin/edit/newrole";
export const ADMIN_CLIENT_LIST = "/admin/client";
export const ADMIN_ADD_CLIENT = "/admin/add/client";
export const ADMIN_VIEW_CLIENT = "/view/client";
export const ADMIN_USER_INFO = "/admin/userinfo";
export const ADMIN_USER_SEARCH = "/admin/user/search";
export const ADMIN_USER_SEARCH_BY_ROLE = "/admin/user/search/byrole";
export const ADMIN_USER_VIEW_BY_ROLE = "/admin/user/search/view";
export const ADMIN_PROCORE_USER_LIST = "/admin/user/procore/userslist";
export const ADMIN_PROCORE_PROJECT_LIST = "/admin/procore/project/list";
export const ADMIN_VIEW_PROCORE_PROJECT = "/admin/procore/project/view";
export const ADMIN_VIEW_USER = "/admin/user/view";

// Credential
export const CREDENTIAL_LOOKUP = "/credential/credentiallookup";
export const MY_CREDENTIALS = "/credential/mycredentials";
export const MY_USER_ADD_CREDENTIAL = "/credential/addcredential";
export const SETUP_CREDENTIAL = "/credential/setcredentials";
export const SETUP_ADD_CREDENTIAL = "/setupcredential/addcredentials";
export const VIEW_CREDENTIAL = "/credential/viewcredential";
export const ASSIGN_CREDENTIAL = "/credential/assigncredential";
export const ADMIN_CREDENTIAL = "/credential/credential";
export const ADMIN_ADD_CREDENTIAL = "/credential/add/credential";
export const VIEW_ADMIN_CREDENTIAL = "/credential/view/credential";
export const VIEW_LOOKUP_CREDENTIAL = "/credential/Lookup/view/credential";

export const ADMIN_CONTRACTOR_MANAGEMENT = "/admin/contractor/management";
export const ADMIN_CONTRACTOR_LIST = "/admin/contractor/list";
export const ADMIN_CONTRACTOR = "/admin/contractor/";
export const ADMIN_PERSONAL_CONTRACTOR_LIST = "/admin/personal/contractor/list";
export const ADMIN_PERSONAL_CONTRACTOR = "/admin/personal/contractor/";
export const ADMIN_CONTRACTOR_CONFIGURATION_LIST =
  "/admin/contractor/configuration/list";
export const ADMIN_CONTRACTOR_CONFIGURATION_VIEW =
  "/admin/contractor/configuration/view";
export const ADMIN_CONTRACTOR_CONFIGURATION_EDIT =
  "/admin/contractor/configuration/edit";

export const ADMIN_UTILITY = "/admin/category/dashboard/";
export const ADMIN_CATEGORY_CREDENTIALS = "/admin/category/utility/";
export const ADMIN_CATEGORY_INCIDENT = "/admin/category/incident/";
export const ADMIN_CATEGORY_DASHBOARD = "/admin/category/dashboard/";
export const ADMIN_CATEGORY_LMS = "/admin/category/lms/";
export const ADMIN_CATEGORY_SUPPORT = "/admin/category/support/";
export const ADMIN_CATEGORY_ASSETS = "/admin/category/assets/";
export const ADMIN_CATEGORY_CREDENTIALSS = "/admin/category/credentials/";
export const ADMIN_CATEGORY_RESOURSE = "/admin/category/resourse/";
export const ADMIN_ADD_FILES = "/admin/add/files";

export const ADMIN_COMPANY_MANAGEMENT = "/admin/companymanagement";
export const ADMIN_ASSET_MANAGEMENT = "/admin/assetmanagement";
export const ADMIN_CREDENTIAL_MANAGEMENT = "/admin/credential";
export const ADMIN_USER_MANAGEMENT = "/admin/usermanagement";

export const ADMIN_CONTRACTOR_DETAILS = "/admin/contractor/details";

//reports-analytics
export const REPORTS_ANALYTICS = "/reports-analytics";
export const SW_REPORTS_ANALYTICS = "/sw-reports-analytics";
export const STROM_WATER_ANALYTICS_REPORT_INSPECTION =
  "/sw/reports-analytics/inspection";
export const SW_SUMMARY_REPORT = "/sw-summary-report";
export const SW_MAP_VIEW_REPORT = "/sw-map-view-report";
export const SW_RAINFALL_REPORT = "/sw-rainfall-report";
// JHA
export const ADMIN_JHA_CONFIGURATION = "/admin/jha/configuration";
export const ADMIN_JHA_CATEGORY = "/admin/jha/category";
export const ADMIN_JHA_ACTIVITIES = "/admin/jha/activities";
export const ADMIN_JHA_HAZARDS = "/admin/jha/hazards";
export const ADMIN_JHA_SET_CONTROLS = "/admin/jha/set-controls";
export const ADMIN_JHA_EDIT_CONTROLS = "/admin/jha/edit-controls";
export const ADMIN_JHA_ADD_TEMPLATE = "/admin/jha/add-template";
export const ADMIN_JHA_LIST_TEMPLATE = "/admin/jha/list-template";
export const JHA_INSPECTION = "/jha-inspection";
export const CONDUCT_JHA = "/condut-jha";
export const CONDUCT_JHA_LIST = "/condut-jha/List";
export const ADMIN_JHA_LIST_SERVICE = "/admin/jha/list-service";
export const ADMIN_JHA_SETUP = "/admin/jha/setup";
// export const JHA_INSPECTION = "/jha-inspection";
// export const CONDUCT_JHA = "/condut-jha";
// export const CONDUCT_JHA_LIST = "/condut-jha/List";
export const CONDUCT_JHA_REPORT = "/jha/View-Report";

export const REPORTS_CREDENTIAL_COUNT = "/reports/credential/count";
export const REPORTS_CREDENTIAL_LIST = "/reports/credential/list";
export const REPORTS_CREDENTIAL_VIEW = "/reports/credential/view";
export const REPORTS_SAFETY_VIEW = "/reports/safety/view";
export const REPORTS_DATA = "/reports/data";
export const REPORTS_TRAINING_SESSIONS = "/reports/training/sessions";
export const REPORTS_UTILITIES = "/reports/utilities";
export const REPORTS_SAFETYLOG_LIST = "/reports/safetylog/list";
export const REPORTS_PERSONAL_INJURY = "/reports/personal-injury";
export const REPORTS_ANALYTICS_DASHBOARD = "/reports/reportsanalytics";
export const REPORTS_ANALYTICS_DASHBOARD_Detail = "/reports/reportsanalytics-detail";
export const REPORTS_ANALYTICS_DASHBOARD_ITEMS = "/reports/reportsanalytics-items";


// export const SUPPORT = "/ticket/dashboard";
export const TICKET_DASHBOARD = "/ticket/dashboard";
export const ADMIN_TICKET = "/admin/ticket";
export const ADMIN_FEEDBACK_TICKET = "/admin/feedback/ticket";
export const RESOLUTION_TEAM_TICKET = "/resolution-team/ticket";
export const TICKET = "/ticket";
export const USER_TICKET_DETAILS = "/user/ticket-details";
export const ADMIN_TICKET_DETAILS = "/admin/ticket-details";
export const CREATE_TICKET = "/create-ticket";
export const FAQ = "/faq";
export const ADMIN_FAQ = "/admin-faq";
export const CREATE_FAQ = "/create-faq";
export const FAQ_TOPIC = "/faq-topic";
export const FAQ_DETAIL = "/faq-detail";

// sw Inspection admin
export const SW_INSPECTION_CONFIGURATION = "/sw/inspection-configuration";
export const SETUP_INSPECTION = "/sw/setup-inspections";
export const SETUP_INSPECTION_LIST = "/sw/setup-inspections-list";
export const INSPECTION_TYPE = "/sw/inspections-type";
export const CONFIGURE_EDIT_INSPECTION = "/sw/edit-inspections";
export const LINK_QUESTION_INSPECTION = "/sw/link-question-inspections";
export const ITEM_FOUND_RESPONSES = "/sw/items-found-responses";
export const ADD_INSPECTION_FORM = "/sw/add-inspections-form";
export const INSPECTION_FORM_LIST = "/sw/inspections-form-list";
export const SW_CATEGORIES = "/sw/categories";
export const SW_SUB_CATEGORIES = "/sw/sub-categories";
export const INSPECTION_GENERATOR = "/sw/inspection-generator";
export const INSPECTION_AUTHORITY = "/sw/inspection-authority";
// sw Inspection user
export const SW_INSPECTION_LIST = "/sw/inspection-list";
export const SW_INSPECTION_VIEW = "/sw/inspection-report";
export const SW_INSPECTION_ADD = "/sw/inspection-add";
export const SW_INSPECTION_PROOF = "/sw/add-inspection-proof";
export const SW_INSPECTION_PROOF_VIEW = "/sw/view-inspection-proof";
export const SW_DAILY_REPORT = "/sw/daily-report";
export const SW_INSPECTION_DAILY_VIEW = "/sw/inspection-daily-report";

// strom water

export const ADD_CONTACT = "sw/admin/addContact";
export const ADMIN_SITE_PROJECTS_MANAGEMENT =
  "/sw/admin/site-prjects/management";
export const ADMIN_SITE_PROJECTS_LIST = "/sw/admin/site-projects/list";
export const ADMIN_PROJECTS_LIST = "/sw/admin/projects/list";
export const ADMIN_ADD_SITE = "/sw/admin/add/site";
export const ADMIN_ADD_STORM_WATERPROJECT = "/sw/admin/add/project";
export const ADMIN_VIEW_SITE = "/sw/admin/view/site";

export const SW_ADD_CONTACT = "sw/admin/addContact";
export const SW_CONTACT_LIST = "sw/admin/contactList";
export const SW_CONTACT_PROJECT = "/sw/admin/contact/project";
export const SW_ADD_PERMIT = "/sw/admin/addPermit";
export const SW_EDIT_PERMIT = "/sw/admin/editPermit";
export const SW_PERMIT_LIST = "/sw/admin/permitList";
export const SW_CONFIGURE_PERMITEE_TYPE = "/sw/admin/configure/permitteType";
export const SW_ADMIN_SAMPLINGLOG = "/sw/admin/samplinglog";
export const SW_ADMIN_SAMPLINGLOG_HISTORY = "/sw/admin/samplinglog/version";
export const SW_GENERAL_PERMIT = "/sw/admin/generalPermit";
export const SW_RAIN_FALL_DATA = "/sw/admin/rainfall/data";
export const SW_VIEW_PERMIT = "/sw/view/permit";

// sw open item
export const SW_OPEN_ITEMS = "/sw/openitem";
export const ADMIN_ADD_NEWS = "/admin/add/news";
export const ADMIN_LIST_NEWS = "/admin/list/news";
export const NEWSDETAIL = "/news/detail";
export const ACCESS_DENIED = "/access-denied";
