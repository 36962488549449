import React from "react";
import Card from "../../../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import { SWREPORTSMANAGEMENT } from "../../../../constant/constant";

function SwReportsManagementList({ onClick }) {
  return (
    <Grid container className="CardListContaner">
      <>
        <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={SWREPORTSMANAGEMENT[0]}
        />
        <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={SWREPORTSMANAGEMENT[1]}
        />
        <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={SWREPORTSMANAGEMENT[2]}
        />
        <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={SWREPORTSMANAGEMENT[3]}
        />
      </>
    </Grid>
  );
}

export default SwReportsManagementList;
