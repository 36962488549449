import React, { useEffect, useState } from "react";
import "../../../library/custom/success/successScreen.scss";
import commonImages from "../../../assets";
import { useNavigate } from "react-router-dom";
export default function SuccessScreen({
  id,
  isUpdate,
  label1,
  label2,
  label3,
  label4,
  isImg,
  isClass,
}) {
  const [seconds, setSeconds] = useState(5);
  const history = useNavigate();


  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      // window.location.reload();
      history(-1)
    }
  });

  return (
    <div
      className={isClass ? isClass + " successScreenBody" : "successScreenBody"}
    >
      <div className="iconSuccess">
        <img
          src={isImg ? isImg : commonImages.successCheck}
          className="succesImg"
          alt=""
        />
      </div>
      {isUpdate && <div className="heading">{isUpdate}</div>}
      <div className="report">{label1 ? label1 : "Report ID"}</div>
      <div className="CNU">{id}</div>
      <div className="generated">{label2 ? label2 : "has been generated"}</div>
      <div className="redirect">
        {label3 ? label3 : "You will be redirected to"} <br></br>
        {label4 ? label4 : " Home Screen in"} <span>{seconds} second</span>
      </div>
    </div>
  );
}
