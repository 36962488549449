import {
  assetsType,
  clearAssetType,
  deleteAssetType,
  getAssetDetailsType,
  postAddAssestType,
  updateAssetType,
} from "../constants/assetType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "Assets",
  isError: false,
  isLoading: false,
};

export const assetListReducer = (state = initialState, action) => {
  switch (action.type) {
    case assetsType.GET_ASSET_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case assetsType.GET_ASSET_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case assetsType.GET_ASSET_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postAddAssestReducer = (state = initialState, action) => {
  switch (action.type) {
    case postAddAssestType.POST_ADD_ASSET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case postAddAssestType.POST_ADD_ASSET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case postAddAssestType.POST_ADD_ASSET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case clearAssetType.CLEAR_ASSET:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const deleteAssetReducer = (state = initialState, action) => {
  switch (action.type) {
    case deleteAssetType.DELETE_ASSET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case deleteAssetType.DELETE_ASSET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case deleteAssetType.DELETE_ASSET_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case clearAssetType.CLEAR_ASSET:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getAssetDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getAssetDetailsType.GET_ASSET_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getAssetDetailsType.GET_ASSET_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getAssetDetailsType.GET_ASSET_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateAssetReducer = (state = initialState, action) => {
  switch (action.type) {
    case updateAssetType.UPDATE_ASSET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case updateAssetType.UPDATE_ASSET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case updateAssetType.UPDATE_ASSET_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case clearAssetType.CLEAR_ASSET:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const getAssetDynomoListReducer = (state = initialState, action) => {
  switch (action.type) {
    case assetsType.GET_ASSET_DYNOMO_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case assetsType.GET_ASSET_DYNOMO_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case assetsType.GET_ASSET_DYNOMO_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getUserAssetListReducer = (state = initialState, action) => {
  switch (action.type) {
    case assetsType.GET_USER_ASSET_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case assetsType.GET_USER_ASSET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case assetsType.GET_USER_ASSET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
