import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import "./Image.scss";
import { getFileInfo } from "../../../utils/helper";
import commonImages from "../../../assets";

const Thumbs = ({
  files,
  handleCloseFile,
  imageUrl = "",
  deleteImageURL,
  isLoader,
}) => {
  const imageUrlConvertArray =
    imageUrl && imageUrl.length > 0 ? imageUrl?.split("|") : "";

  const uniqueImageUrls = [...new Set(imageUrlConvertArray)];

  return (
    <div className="overideImage">
      <div className="thumbContainer">
        {uniqueImageUrls &&
          uniqueImageUrls?.length > 0 &&
          uniqueImageUrls?.map((url, index) => {
            const fileInfo = getFileInfo(url);

            return (
              <div className="imgBox" key={index}>
                <div
                  className="removeIcon"
                  onClick={(i) => deleteImageURL(url, index)}
                >
                  X
                </div>
                <img
                  src={
                    fileInfo?.fileExtension === "tif"
                      ? commonImages?.noimage
                      : url
                  }
                  alt="No Files"
                  className="img"
                />
                <div className="fileName"></div>
              </div>
            );
          })}
        {/* {isLoader && <p>Loading...</p>} */}
        {isLoader && (
          <Stack className="loaderDropBox" spacing={2} direction="row">
            <CircularProgress color="success" />
          </Stack>
        )}
      </div>
    </div>
  );
};

export default Thumbs;
