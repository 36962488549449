import { getApiUrl } from "../config";
import { LNG_ID, ORG_ID } from "../constant/constant";
import { deletes, get, post } from "./axiosRequest";

// getArticle SERVICE
export const getArticle = async ({ data }) => {
  const url = `${
    getApiUrl().supportApiUrl
  }/Support/GetArticles?organisationId=${ORG_ID()}&search=${data}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getTicketCategory = async ({ data }) => {
  const url = `${
    getApiUrl().supportApiUrl
  }/Ticket/GetTicketsCategory?orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// getArticleCatSubcategory SERVICE
export const getArticleCatSubcategory = async ({ data }) => {
  const url = `${
    getApiUrl().supportApiUrl
  }/Support/GetArticleCatSubcategory?organisationId=${ORG_ID()}&search=${
    data.search
  }&page=${data.page}&pageSize=${data.pageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// getArticlesBasedCatSubcategory Service
export const getArticlesBasedCatSubcategory = async ({ data }) => {
  const url = `${
    getApiUrl().supportApiUrl
  }/Support/GetArticlesBasedCatSubcategory?orgId=${ORG_ID()}&catSubcatId=${
    data.catSubcatId
  }&search=${data.search}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// getArticleDetails SERVICE
export const getArticleDetails = async ({ data }) => {
  const url = `${
    getApiUrl().supportApiUrl
  }/Support/GetArticleDetails?orgId=${ORG_ID()}&lngId=${LNG_ID}&catSubcatId=${
    data.catSubcatId
  }&articleId=${data.articleId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// Add Like Dislike Article SERVICE
export const addLikeDislikeArticle = async ({ data }) => {
  const url = `${getApiUrl().supportApiUrl}/Support/AddLikeDislikeArticle`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// getTickets SERVICE
export const getTickets = async ({ data }) => {
  const url = `${
    getApiUrl().supportApiUrl
  }/Ticket/GetTickets?orgId=${ORG_ID()}&userId=${data.userID}&priorityIds=${
    data.priorityID
  }&search=${data.searchbarInput}&ticketType=${data.ticketType}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// getTicketDetails SERVICE
export const getTicketDetails = async ({ data }) => {
  const url = `${
    getApiUrl().supportApiUrl
  }/Ticket/GetTicketDetails?orgId=${ORG_ID()}&ticketId=${data}&isFeedback=true`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// CREATE TICKET SERVICE
export const createTicket = async ({ data }) => {
  const url = `${getApiUrl().supportApiUrl}/Ticket/CreateTicket`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// REPLY TICKET SERVICE
export const replyTicket = async ({ data }) => {
  const url = `${getApiUrl().supportApiUrl}/Ticket/ReplyTicket`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// UserFeedbackOnTicket SERVICE
export const userFeedbackOnTicket = async ({ data }) => {
  const url = `${getApiUrl().supportApiUrl}/Ticket/UserFeedbackOnTicket`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// getDashboardTickets SERVICE
export const getDashboardTickets = async ({ data }) => {
  const url = `${
    getApiUrl().supportApiUrl
  }/Ticket/GetDashboardTicket?orgId=${ORG_ID()}&departmentID=0`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// getTicketsAdminDesk SERVICE
export const getTicketsAdminDesk = async ({ data }) => {
  const url = `${
    getApiUrl().supportApiUrl
  }/Ticket/GetTicketsAdminDesk?orgId=${ORG_ID()}&isAssigned=${
    data.isAssigned
  }&ticketTypes=${data.ticketTypes}&priorityIds=${data.priorityIds}&search=${
    data.search
  }`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// GetTicketsFeedbackDesk SERVICE
export const getTicketsFeedbackDesk = async ({ data }) => {
  const url = `${
    getApiUrl().supportApiUrl
  }/Ticket/GetTicketsFeedbackDesk?orgId=${ORG_ID()}&assignedTypes=&ticketTypes=&priorityIds=${
    data.priorityIds
  }&satisfectionType=${data.satisfectionType}&search=${data.search}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// PostClosedTicket SERVICE
export const postClosedTicket = async ({ data }) => {
  const url = `${getApiUrl().supportApiUrl}/Ticket/ClosedTicket`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// postUserAssignTicket SERVICE
export const postUserAssignTicket = async ({ data }) => {
  const url = `${getApiUrl().supportApiUrl}/Ticket/UserAssignTicket`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// postUpdateSeverityTicket SERVICE
export const postUpdateSeverityTicket = async ({ data }) => {
  const url = `${getApiUrl().supportApiUrl}/Ticket/UpdateSeverityTicket`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// postAddFAQ SERVICE
export const postAddFAQ = async ({ data }) => {
  const url = `${getApiUrl().supportApiUrl}/Support/AddFAQ`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteFaq = async (payload) => {
  const url = `${getApiUrl().supportApiUrl}/Support/DeleteFAQ/${
    payload?.articleId
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateFaq = async ({ payload }) => {
  const url = `${getApiUrl().supportApiUrl}/Support/UpdateFAQ`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
