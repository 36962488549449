import React, { useMemo, useRef } from "react";
import { Settings, FileDownload } from "@mui/icons-material";
import {
  IconButton,
  Menu,
  FormGroup,
} from "@mui/material";
import DownloadCsv from "../../download_file/DownloadCsv";
const SettingTable = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dowloadCsv = useRef();
  const { noOfRecords, numberOfItemsPerPage, incidentLists } = props;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const checkboxData = useMemo(() => {
    const arrRe = [];
    for (let i = 0; i < Math.ceil(noOfRecords / numberOfItemsPerPage); i++) {
      if (arrRe.slice(-1).length > 0) {
        arrRe.push(arrRe.slice(-1)[0] + 5);
      } else {
        arrRe.push(5);
      }
    }
    return arrRe;
  }, [noOfRecords, numberOfItemsPerPage]);

  return (
    <div>
      <IconButton
        onClick={incidentLists.length > 0 ? handleClick : undefined}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Settings />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="setting-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="setting_button_ui">
          {/* <FormLabel component="label">Result Per Page</FormLabel> */}
          <div className="result_perpage_setting">
            <FormGroup>
              {/* {checkboxData &&
                checkboxData.map((item) => (
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        onChange={() => checkedOonchange(item)}
                        checked={checked === item}
                        name="gilad"
                      />
                    }
                    label={item}
                  />
                ))} */}
            </FormGroup>
          </div>
          <div
            className="download_button"
            onClick={() => dowloadCsv.current.link.click()}
          >
            <FileDownload />
            <span>Download table data</span>
          </div>
        </div>
      </Menu>
      <DownloadCsv
        ref={dowloadCsv}
        data={incidentLists}
        filename={
          props?.fileNameXL ? props?.fileNameXL : "list-of-incident.csv"
        }
      />
    </div>
  );
};

export default SettingTable;
