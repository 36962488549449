import React from "react";
import { TextField } from "@mui/material";
import "./TimePicker.scss";

export default function TImePickers({
  onChangeTimeHandler,
  disabled,
  name,
  value,
  isError = false,
  errorMsg = "",
}) {
  return (
    <>
      <div className="overideTimePicker">
        <TextField
          id="time"
          type="time"
          fullWidth
          size="small"
          // defaultValue={value}
          value={value}
          disabled={disabled}
          onChange={(e) => onChangeTimeHandler(e)}
          className="formControlRoot"
          name={name}
          InputProps={{
            className: "inputRoot",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          error={isError}
          helperText={errorMsg}
        />
      </div>
    </>
  );
}
