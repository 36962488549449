import React from "react";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { FormControl, Grid } from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import "./jha.scss";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { useRef } from "react";
import { useState } from "react";
import TextEditor from "../../../../library/custom/textEditor/TextEditor";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
const dataBack = {
  title: "Back to Controls",
  route: -1,
};

const JhaEditControl = ({
  formValue,
  setformValue,
  handleEdit,
  prevControls,
  controlTypeList,
  controlTypeId,
}) => {
  const editor = useRef(null);

  const [isControlName, setisControlName] = useState(false);

  return (
    <div>
      <Grid className="overrideadmincategory">
        {/* <Grid container item md={12} xs={12} className="addShadowBox"> */}
        <Grid container className="controlNameBox">
          <Grid item md={8.5} xs={12}>
            <div className="controlTitile">Edit Controls</div>
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent={"space-between"}
          mt={1.5}
          lg={11.6}
        >
          <Grid item mt={3} lg={6} xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <FormLabels label="Select Control Type (optional)" />
              <SelectMenu
                listData={controlTypeList ?? []}
                value={formValue.controlTypeId}
                placeholder="Select Control"
                onchangehandler={(e) => {
                  setformValue({
                    ...formValue,
                    controlTypeId: e.target.value,
                  });
                }}
                disabled={controlTypeId != 0}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container mt={1} spacing={2}>
          <Grid item lg={12} xs={12} sm={12} md={8}>
            <FormControl fullWidth>
              <FormLabels label="Update Control" />

              <TextEditor
                value={formValue.controlsName ?? ""}
                placeholderText={"Enter Here..."}
                onChange={(e) => {
                  setformValue({
                    ...formValue,
                    controlsName: e,
                  });
                }}
              />
              {isControlName && (
                <div className="errorMsg">Control is required</div>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          mt={1}
          item
          container
          justifyContent={"right"}
          lg={12}
          className="cancelSaveDiv"
        >
          <Grid item lg={2.5}>
            <Buttons
              varientContained={true}
              label={"Update"}
              onClick={() => {
                handleEdit(formValue);
              }}
            />
          </Grid>
        </Grid>
        <Grid mt={2} item container justifyContent={"eft"} lg={11}>
          <Grid item lg={10}>
            <Grid container gap={"10px"} className="heading">
              <Grid item lg={9}>
                Previous Versions
              </Grid>
              <Grid item lg={2.5}>
                Date Updated
              </Grid>
            </Grid>
            {prevControls?.map((i, index) => (
              <Grid
                container
                gap={"10px"}
                className="richTextControl"
                key={index}
              >
                <Grid item lg={9}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: i?.controlsName,
                    }}
                    style={{ width: "100%" }}
                  />{" "}
                </Grid>
                <Grid item lg={2.5}>
                  {i?.createdDate}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item container lg={11} className="buttonsGrid" mt={3} mb={3}>
          <div>
            <BackBtn dataBack={dataBack} />
          </div>
          {/* <Grid item lg={6} className="cancelSaveDiv">
            <Grid item lg={5}>
              <Buttons
                varientContained={true}
                label={"Save"}
                onClick={() => {
                  console.log("");
                  //   submitData();
                }}
              />
            </Grid>
          </Grid> */}
        </Grid>
        {/* </Grid> */}
      </Grid>
    </div>
  );
};

export default JhaEditControl;
