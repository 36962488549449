import { personalInjuryIncidentReportType } from "../constants/personalInjuryIncidentReportType";

export const getPersonallInjuryIncidentReportCountReport = (data) => ({
  type: personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT_PENDING,
  data,
});

export const getPersonallInjuryIncidentReportCountSuccess = (data) => ({
  type: personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT_SUCCESS,
  data,
});

export const getPersonallInjuryIncidentReportCountFailure = (error) => ({
  type: personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT_FAILURE,
  error,
});

export const getPersonallInjuryIncidentReportListReport = (data) => ({
  type: personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST_PENDING,
  data,
});

export const getPersonallInjuryIncidentReportListSuccess = (data) => ({
  type: personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST_SUCCESS,
  data,
});

export const getPersonallInjuryIncidentReportListFailure = (error) => ({
  type: personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST_FAILURE,
  error,
});
