import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { InputField } from "../../../../library/custom/textBox/InputField";
import "./addCategory.scss";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import {
  LNG_ID,
  ORG_ID,
  USERID,
  ISwitch,
  PAGE_SIZE,
} from "../../../../constant/constant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { INSPECTION_ADMIN } from "../../../../constant/routeContant";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const AddCategory = ({
  onSubmit,
  getCategoriesList,
  getCategoriesListData,
  adminAddUpdateCategory,
  isLoading,
  recordsCount,
}) => {
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState();
  const handleClose = () => setOpen(false);
  const [addCategoryBox, setAddCategoryBox] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [categoryFilterData, setCategoryFilterData] = useState({});

  const addCategoryHandler = () => {
    setIsCategoryName(false);
    setAddCategoryBox(true);
  };

  const handleautoSearch = (event, state) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...categoryFilterData,
      page: 1,
      pageSize: PAGE_SIZE,
      searchText: searchText ? searchText : "",
      organisationId: ORG_ID(),
      lngId: LNG_ID,
      allowPaging: true,
    };
    setCategoryFilterData(data);
    getCategoriesListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getCategoriesListData({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      searchText: "",
      organisationId: ORG_ID(),
      lngId: LNG_ID,
      allowPaging: true,
      ...categoryFilterData,
    });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const intialState = {
    categoriesValue: "",
  };
  const [formValue, setFormValue] = useState(intialState);
  const [isCategoryName, setIsCategoryName] = useState(false);

  const handleChange = (categorieItem) => {
    const newCatList = [...getCategoriesList];
    newCatList.forEach((lists) => {
      if (lists.key == categorieItem.key) {
        lists.isActive = !lists.isActive;
        lists.checked = !lists.checked;
        lists.createdBy = parseInt(USERID);
      }
    });
    onSubmit({
      categories: newCatList,
      organisationId: ORG_ID(),
      lngId: LNG_ID,
    });
  };

  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();

  function mappedData() {
    let errors = false;
    if (formValue?.categoriesValue.trim() === "") {
      setFormValue({
        ...formValue,
        categoriesValue: "",
      });
      seterrorsMsgShowActive(false);
      setIsCategoryName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }

    const index = getCategoriesList?.findIndex(
      (obect) =>
        obect.value?.toUpperCase() === formValue?.categoriesValue?.toUpperCase()
    );
    if (index === -1) {
      seterrorsMsgShowActive(false);
      const categories = [
        {
          key: 0,
          value: formValue?.categoriesValue,
          isActive: true,
          createdBy: parseInt(USERID),
        },
      ];
      return { categories, organisationId: ORG_ID(), lngId: LNG_ID };
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Category Name already added");
    }
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const handleEditOnClick = (modalData) => {
    setAddCategoryBox(false);
    setOpen(true);
    setModalData(modalData);
    setFormValue({
      categoriesValue: modalData?.value,
    });
  };

  const submitUpdateData = () => {
    const index = getCategoriesList?.findIndex(
      (obect) =>
        obect.value?.toUpperCase() === formValue?.categoriesValue?.toUpperCase()
    );

    if (formValue?.categoriesValue.trim() === "") {
      setFormValue({
        ...formValue,
        categoriesValue: "",
      });
      seterrorsMsgShowActive(false);
      setIsCategoryName(true);
    } else if (index === -1) {
      setOpen(false);
      setFormValue({
        ...formValue,
        categoriesValue: "",
      });
      const categories = [
        {
          key: modalData?.key,
          value: formValue?.categoriesValue,
          isActive: modalData?.isActive,
          createdBy: parseInt(USERID),
        },
      ];
      onSubmit({ categories, organisationId: ORG_ID(), lngId: LNG_ID });
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Category Name already added");
    }
  };

  const dataBack = {
    title: "Back to Inspections",
    route: INSPECTION_ADMIN,
  };

  const [getCategoriesLists, setGetCategoriesLists] = useState([]);
  useEffect(() => {
    setGetCategoriesLists(getCategoriesList);
  }, [getCategoriesList]);
  useEffect(() => {
    if (
      adminAddUpdateCategory?.status === "SUCCESS" &&
      adminAddUpdateCategory?.isLoading === false
    ) {
      setIsSubmit(false);
      setFormValue({
        ...formValue,
        categoriesValue: "",
      });
      const data = {
        searchText: "",
        organisationId: ORG_ID(),
        lngId: LNG_ID,
        page: 1,
        pageSize: PAGE_SIZE,
        allowPaging: true,
      };
      getCategoriesListData(data);
    }
  }, [adminAddUpdateCategory]);

  const columns = [
    { field: "value", headerName: "Category Name", flex: 2 },
    { field: "dateOfLastUpdate", headerName: "Last Updated", flex: 1 },
    {
      flex: 1,
      field: "isActive",
      hide: !checkPrivileges([12, 4, 120]),
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox">
                <FormControlLabel
                  control={
                    <ISwitch
                      checked={rows?.row.isActive ? rows?.row.isActive : false}
                      onChange={() => handleChange(rows?.row)}
                      name="status"
                    />
                  }
                />
                <div>{rows?.row?.isActive ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 1,
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={recordsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={getCategoriesLists ?? []}
          fileNameXL={"categoryList"}
        />
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <>
            {checkPrivileges([12, 120, 121, 4]) && (
              <ReportRowManipulte
                rows={rows}
                editOnClick={() => handleEditOnClick(rows?.row)}
                edit
                editLabel="Edit Category"
              />
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <Grid className="inspectionOverRide">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid className="modalContainer">
            <Box className="modalBox">
              <div className="modalTitle">Edit Category</div>

              <div className="modalInputBox">
                <InputField
                  type="text"
                  isRequired={true}
                  value={formValue?.categoriesValue}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      categoriesValue: e.target.value,
                    })
                  }
                  // label="Create Category"
                  placeholder="Create Category Here..."
                  isError={isCategoryName}
                  errorMsg={isCategoryName ? "Category Name is Required" : ""}
                />
                {errorsMsgShowActive && (
                  <div className="errorMsg">{errorsMsgShow}</div>
                )}
              </div>
              <Grid container className="modalFooterBox">
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientText={true}
                    label="Cancel"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientContained={true}
                    label={"Save"}
                    onClick={submitUpdateData}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Modal>

        <Grid container item md={12} xs={12} className="addShadowBox">
          <Grid container className="confBox">
            <Grid item md={9} xs={12}>
              <div className="confTitile">Configure Categories</div>
            </Grid>
            <Grid item md={3} xs={12}>
              {checkPrivileges([12, 120, 121, 4]) && (
                <Buttons
                  id="btnAddCategory"
                  label={"Add Category"}
                  varientAddIconBlue={true}
                  onClick={() => addCategoryHandler()}
                />
              )}
            </Grid>
          </Grid>

          <Grid container md={12} xs={12} item>
            <Grid container item md={12} xs={12}>
              {addCategoryBox && (
                <Grid item container md={8} className="CategoriesBox">
                  <Grid item md={8} xs={8} mb={2}>
                    <InputField
                      type="text"
                      isRequired={true}
                      value={formValue?.categoriesValue}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          categoriesValue: e.target.value,
                        })
                      }
                      label="Create Category"
                      placeholder="Create Category Here..."
                      isError={isCategoryName}
                      errorMsg={
                        isCategoryName ? "Category Name is Required" : ""
                      }
                    />
                    {errorsMsgShowActive && (
                      <div className="errorMsg">{errorsMsgShow}</div>
                    )}
                  </Grid>
                  <Grid
                    className="smallAddBtn"
                    item
                    lg={1.5}
                    md={3}
                    xs={3}
                    ml={3}
                    mt={4}
                  >
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={"Create"}
                      id="btnAddEquipment"
                      varientAddIconBlue={true}
                      disabled={isSubmit ? true : false}
                      onClick={submitData}
                    ></Buttons>
                  </Grid>
                </Grid>
              )}

              <Grid item container md={12} className="CategoriesBox" mt={3}>
                <Grid item md={5} xs={8} mb={3}>
                  <div className="searchcaseid">
                    <label htmlFor="search">
                      <span>
                        <SearchIcon />
                      </span>
                      <div>
                        <input
                          id="search"
                          placeholder="Search"
                          type="text"
                          value={search}
                          className=""
                          onChange={handleautoSearch}
                          autoComplete="off"
                        />
                      </div>
                    </label>
                  </div>
                </Grid>
              </Grid>

              <Grid item md={12} className="CategoriesBox" mt={3}>
                <div className="desc_box row_uniq">
                  <div className="table_style_ui">
                    <GridTable
                      getRowId={(r) => r.key}
                      rows={getCategoriesLists ?? []}
                      rowCount={recordsCount ?? 0}
                      columns={columns}
                      loading={isLoading}
                      checkboxSelection={false}
                      page={page}
                      pagination
                      pageSize={PAGE_SIZE}
                      rowsPerPageOptions={[PAGE_SIZE]}
                      onPageChange={handlePagignation}
                      backIconButtonProps={{ disabled: false }}
                      disableSelectionOnClick
                      components={{
                        LoadingOverlay: TableLoader,
                        NoRowsOverlay: NORow,
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <div className="insBackBtn">
            <BackBtn dataBack={dataBack} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AddCategory;
