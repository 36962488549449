import React from "react";
import "./multiSelectBox.scss";
import { FormControlLabel, Grid } from "@mui/material";
import commonImages from "../../../../assets";

const MultiSelectBox = ({
  label,
  list,
  btnLabel,
  categoryId,
  singleCategory,
  selectAll,
  handleCheck,
  cancel,
  btnLabel2,
}) => {
  return (
    <div className="overrideMultiSelectBox">
      <Grid container className="boxContainer">
        <Grid
          container
          justifyContent={"space-between"}
          p={1.5}
          className="boxHeading"
        >
          <Grid lg={6}>
            <h1 className="label">{label}</h1>
          </Grid>

          <Grid
            item
            lg={6}
            //justifyContent={"right"}
            gap={"10px"}
            className="mutclass"
          >
            <div className="btnDiv" onClick={() => selectAll(list, categoryId)}>
              {btnLabel}
            </div>

            <div className="btnDiv" onClick={() => cancel(singleCategory)}>
              {btnLabel2}
            </div>
          </Grid>
        </Grid>
        {list?.map((i, index) => (
          <Grid container className="questionItem" key={index}>
            <FormControlLabel
              control={
                <img
                  className="filterCheckbox"
                  title={i?.isSelected ? "tickCheckbox" : "untickCheckbox"}
                  alt=""
                  src={
                    i?.isSelected ? commonImages.check : commonImages.noCheck
                  }
                  onClick={() => handleCheck(i, list, categoryId)}
                />
              }
              label={i.activityName}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MultiSelectBox;
