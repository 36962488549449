export const categoryType = {
  POST_CATEGORY_PENDING: "[CATEGORY][REQUEST] Create CATEGORY",
  POST_CATEGORY_FAILURE: "[CATEGORY][RESPONSE] CATEGORY Creation Failed",
  POST_CATEGORY_SUCCESS: "[CATEGORY][RESPONSE] CATEGORY Created Successfull",
  CREATE_PAGE: "CreatePageCategory",

  GET_CATEGORY_PENDING: "[CATEGORY][REQUEST] GET CATEGORY",
  GET_CATEGORY_FAILURE: "[CATEGORY][RESPONSE] CATEGORY GET Failed",
  GET_CATEGORY_SUCCESS: "[CATEGORY][RESPONSE] CATEGORY GET Successfull",
  LIST_PAGE: "ListPageCategory",

  POST_ACTIVE_CATEGORY_PENDING:
    "[ACTIVE_CATEGORY][REQUEST] Create Active CATEGORY",
  POST_ACTIVE_CATEGORY_FAILURE:
    "[ACTIVE_CATEGORY][RESPONSE] Active CATEGORY Creation Failed",
  POST_ACTIVE_CATEGORY_SUCCESS:
    "[ACTIVE_CATEGORY][RESPONSE] Active CATEGORY Created Successfull",
  ACTIVE_CREATE_PAGE: "ActivePageCategory",

  POST_SUPPORT_CATEGORY_PENDING:
    "[CATEGORY_SUPPORT][REQUEST] Create CATEGORY Support",
  POST_SUPPORT_CATEGORY_FAILURE:
    "[CATEGORY_SUPPORT][RESPONSE] CATEGORY  Creation Failed Support",
  POST_SUPPORT_CATEGORY_SUCCESS:
    "[CATEGORY_SUPPORT][RESPONSE] CATEGORY Support Created Successfull",
  CREATE_SUPPORT_PAGE: "CreateSupportPageCategory",

  GET_SUPPORT_CATEGORY_PENDING:
    "[CATEGORY_SUPPORT][REQUEST] GET Support CATEGORY",
  GET_SUPPORT_CATEGORY_FAILURE:
    "[CATEGORY_SUPPORT][RESPONSE] Support CATEGORY GET Failed",
  GET_SUPPORT_CATEGORY_SUCCESS:
    "[CATEGORY_SUPPORT][RESPONSE] Support CATEGORY GET Successfull",
  LIST_SUPPORT_PAGE: "ListPageCategory",

  POST_ACTIVE_SUPPORT_CATEGORY_PENDING:
    "[ACTIVE_SUPPORT_CATEGORY][REQUEST] Create Active Support CATEGORY",
  POST_ACTIVE_SUPPORT_CATEGORY_FAILURE:
    "[ACTIVE_SUPPORT_CATEGORY][RESPONSE] Active Support CATEGORY Creation Failed",
  POST_ACTIVE_SUPPORT_CATEGORY_SUCCESS:
    "[ACTIVE_SUPPORT_CATEGORY][RESPONSE] Active Support CATEGORY Created Successfull",
  ACTIVE_SUPPORT_CREATE_PAGE: "ActiveSupportPageCategory",

  POST_INCIDENT_CATEGORY_PENDING:
    "[CATEGORY_INCIDENT][REQUEST] Create CATEGORY Incident",
  POST_INCIDENT_CATEGORY_FAILURE:
    "[CATEGORY_INCIDENT][RESPONSE] CATEGORY  Creation Failed Incident",
  POST_INCIDENT_CATEGORY_SUCCESS:
    "[CATEGORY_INCIDENT][RESPONSE] CATEGORY Incident Created Successfull",
  CREATE_INCIDENT_PAGE: "CreateIncidentPageCategory",

  GET_INCIDENT_CATEGORY_PENDING:
    "[CATEGORY_INCIDENT][REQUEST] GET Incident CATEGORY",
  GET_INCIDENT_CATEGORY_FAILURE:
    "[CATEGORY_INCIDENT][RESPONSE] Incident CATEGORY GET Failed",
  GET_INCIDENT_CATEGORY_SUCCESS:
    "[CATEGORY_INCIDENT][RESPONSE] Incident CATEGORY GET Successfull",
  LIST_INCIDENT_PAGE: "ListPageIncidentCategory",

  POST_ACTIVE_INCIDENT_CATEGORY_PENDING:
    "[ACTIVE_INCIDENT_CATEGORY][REQUEST] Create Active Incident CATEGORY",
  POST_ACTIVE_INCIDENT_CATEGORY_FAILURE:
    "[ACTIVE_INCIDENT_CATEGORY][RESPONSE] Active Incident CATEGORY Creation Failed",
  POST_ACTIVE_INCIDENT_CATEGORY_SUCCESS:
    "[ACTIVE_INCIDENT_CATEGORY][RESPONSE] Active Incident CATEGORY Created Successfull",
  ACTIVE_INCIDENT_CREATE_PAGE: "ActiveIncidentPageCategory",

  UPDATE_CATEGORY_CONFIGURATION:
    "[UPDATE_CATEGORY_CONFIGURATION][REQUEST] Update CATEGORY configuration",
  UPDATE_CATEGORY_CONFIGURATION_FAILURE:
    "[UPDATE_CATEGORY_CONFIGURATION_FAILURE][RESPONSE] Update CATEGORY configuration Creation Failed",
  UPDATE_CATEGORY_CONFIGURATION_SUCCESS:
    "[UPDATE_CATEGORY_CONFIGURATION_SUCCESS][RESPONSE] Update CATEGORY configuration Successfull",
  UPDATE_CATEGORY_CONFIGURATION_PAGE: "UpdateCategoryConfigurationCategory",

  UPDATE_SUPPORT_CATEGORY_CONFIGURATION:
    "[UPDATE_SUPPORT_CATEGORY_CONFIGURATION][REQUEST] Update Support CATEGORY configuration",
  UPDATE_SUPPORT_CATEGORY_CONFIGURATION_FAILURE:
    "[UPDATE_SUPPORT_CATEGORY_CONFIGURATION_FAILURE][RESPONSE] Update Support CATEGORY configuration Creation Failed",
  UPDATE_SUPPORT_CATEGORY_CONFIGURATION_SUCCESS:
    "[UPDATE_SUPPORT_CATEGORY_CONFIGURATION_SUCCESS][RESPONSE] Update Support CATEGORY configuration Successfull",
  UPDATE_SUPPORT_CATEGORY_CONFIGURATION_PAGE:
    "UpdateSupportCategoryConfigurationCategory",

  UPDATE_INCIDENT_CATEGORY_CONFIGURATION:
    "[UPDATE_INCIDENT_CATEGORY_CONFIGURATION][REQUEST] Update Incident CATEGORY configuration",
  UPDATE_INCIDENT_CATEGORY_CONFIGURATION_FAILURE:
    "[UPDATE_INCIDENT_CATEGORY_CONFIGURATION_FAILURE][RESPONSE] Update Incident CATEGORY configuration Creation Failed",
  UPDATE_INCIDENT_CATEGORY_CONFIGURATION_SUCCESS:
    "[UPDATE_INCIDENT_CATEGORY_CONFIGURATION_SUCCESS][RESPONSE] Update Incident CATEGORY configuration Successfull",
  UPDATE_INCIDENT_CATEGORY_CONFIGURATION_PAGE:
    "UpdateincidentCategoryConfigurationCategory",
};
