import React from "react";
import TableData from "./tableData";
import { useLocation } from "react-router-dom";

const InspectionViewReport = () => {
  const location = useLocation();
  const detail = location?.state;
  return (
    <>
      <TableData detail={detail} />
    </>
  );
};

export default InspectionViewReport;
