import React, { useEffect } from "react";
import ParentAccordions from "../../library/custom/accordion/ParentAccordion";
import { useState } from "react";
const QuestionSet = ({
  qestSetData,
  skipQuestion,
  qestSetState,
  setQestSetState,
  setImage,
  filesId,
  filesUrl,
  inspEditDetails,
  edit,
  assetId,
  assetTypeValue,
  userClear
}) => {
  return (
    <>
      {qestSetState &&
        qestSetState.map((item) => (
          <ParentAccordions
            key={item.key}
            summary={item.value}
            itemKey={item.key}
            catError={item.catError}
            datas={item?.subcategories ?? []}
            skipQuestion={skipQuestion}
            qestSetState={qestSetState}
            setImage={setImage}
            filesId={filesId}
            filesUrl={filesUrl}
            setQestSetState={setQestSetState}
            mt={2}
            skip={item?.skip ?? {}}
            edit={edit}
            catogeryCompleted={item.catogeryCompleted}
            assetId={assetId}
            assetTypeValue={assetTypeValue}
            userClear={userClear}
          />
        ))}
    </>
  );
};

export default React.memo(QuestionSet);
