import React, { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../../library/common/Loader";
import {
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  USERID,
  USER_ID,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import Filter from "../../shared/Filter";
import { useDispatch } from "react-redux";
import * as assetsAction from "../../../../redux/actions/assetsAction";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import NORow from "../../../../library/custom/noRow/noRow";
import { useNavigate } from "react-router-dom";
import { ADMIN_ADD_ASSETS } from "../../../../constant/routeContant";
import DialogBox from "../../../../library/common/DialogBox";
import { Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const AssetTable = ({
  isAssetLoading,
  assetList,
  getAssetListData,
  filterShow,
  isProfile = false,
  divisionDropdown,
  isDivisionDropdown = true,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [division, setDivision] = useState(
    divisionDropdown ? divisionDropdown[0]?.key : 0
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [statusState, setStatusState] = useState({
    active: false,
    inActive: false,
  });
  const [statussState, setStatussState] = useState();

  function handleFilter(event) {
    const newStatusState = {
      [event.target.name]: event.target.checked,
    };
    setStatusState(newStatusState);
    const arr = [];
    if (newStatusState.active) {
      arr.push(1);
    }

    if (newStatusState.inActive) {
      arr.push(0);
    }

    const statusIds = arr.join(",");
    setStatussState(statusIds);
  }
  function handleApply() {
    const data = {
      page: 1,
      divisionId: division === 1 ? 0 : division,
      pageSize: pageSize,
      statusIds: statussState ? statussState : "",
      compnayName: "",
      search: "",
      userId: isProfile ? USERID : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    };
    dispatch(assetsAction.getAssetList(data));
    setAnchorEl(null);
  }
  const handleClear = () => {
    const newData = {
      page: 1,
      divisionId: division === 1 ? 0 : division,
      pageSize: pageSize,
      compnayName: "",
      search: "",
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      statusIds: "",
    };
    setSearch("");
    setStatusState({
      active: false,
      inActive: false,
    });
    dispatch(assetsAction.getAssetList(newData));
    setAnchorEl(null);
  };

  const handleEditOnclick = (rows) => {
    navigate(ADMIN_ADD_ASSETS, {
      state: { id: rows.row.id, editMode: true },
    });
  };
  const deletehandle = () => {
    setOpenModal(false);
    dispatch(assetsAction.deleteAsset(selectedRow));
  };

  const deleteAsset = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const columns = [
    { field: "assetName", headerName: "Asset Name", flex: 2 },
    { field: "id", headerName: "Asset ID", flex: 1 },
    { field: "divisionName", headerName: "Division", flex: 1.5 },
    { field: "startDate", headerName: "Start Date", flex: 1.5 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "icon",
      renderHeader: () =>
        filterShow ? (
          <SettingTable
            noOfRecords={assetList?.itemsCount ?? 0}
            numberOfItemsPerPage={pageSize}
            incidentLists={assetList?.assetData ?? []}
            fileNameXL="assetList"
          />
        ) : (
          ""
        ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return filterShow ? (
          <ReportRowManipulte
            rows={rows}
            deleteOnClick={() => {
              deleteAsset(rows.id);
            }}
            editOnClick={() => handleEditOnclick(rows)}
            deleted={checkPrivileges([37, 12])}
            edit={checkPrivileges([37, 38, 12])}
            deleteLabel="Delete Asset"
            editLabel="Edit Asset"
          />
        ) : (
          ""
        );
      },
    },
  ];

  const handleSearch = (data) => {
    setAnchorEl(null);
    getAssetListData(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      divisionId: division === 1 ? 0 : division,
      pageSize: pageSize,
      statusIds: statussState ? statussState : "",
      compnayName: "",
      search: event.target.value,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    };
    getAssetListData(data);
  };
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      divisionId: division === 1 ? 0 : division,
      pageSize: pageSize,
      statusIds: statussState ? statussState : "",
      compnayName: "",
      search: search,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    };
    getAssetListData(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      page: page,
      divisionId: division === 1 ? 0 : division,
      pageSize: newPazeSize,
      statusIds: statussState ? statussState : "",
      compnayName: "",
      search: search,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    };
    getAssetListData(data);
  };
  const onChangeDivision = (e) => {
    setDivision(e.target.value);
    setSearch("");
    const data = {
      page: 1,
      divisionId: e.target.value === 1 ? 0 : e.target.value,
      pageSize: pageSize,
      statusIds: "",
      compnayName: "",
      search: search,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    };
    getAssetListData(data);
    setPage(1);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to delete this Asset?"}
              view
            />
          )}
        </div>
        <div className="grid_main_body divisionWithsearch">
          <Grid container>
            <Grid item lg={6} xs={12} sm={6} md={6} mr={2} pt={3}>
              <div className="filter_of_caselist">
                <div className="searchcaseid">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div>
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={search}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>
                {filterShow && (
                  <div className="filter_btn divisionWithFilter">
                    <button
                      aria-describedby={id}
                      variant="contained"
                      type="button"
                      name="btn"
                      onClick={(e) => handleClick(e)}
                    >
                      <FilterListIcon id="icn" /> Filters
                    </button>
                    <Filter
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      handleSearch={handleSearch}
                      handleChange={handleFilter}
                      statusState={statusState}
                      handleApply={handleApply}
                      variantAsset={true}
                      handleClear={handleClear}
                    />
                  </div>
                )}
              </div>
            </Grid>
            {isDivisionDropdown && (
              <Grid item lg={4} xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <FormLabels label="Select Division" isRequired={false} />
                  <SelectMenu
                    disabledDefault={true}
                    listData={divisionDropdown}
                    value={division === 0 ? 1 : division}
                    onchangehandler={(e) => onChangeDivision(e)}
                    isgroupplaceholder={true}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.id}
                rows={assetList?.assetData ?? []}
                rowCount={assetList?.itemsCount ?? 0}
                columns={columns}
                loading={isAssetLoading}
                page={page}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AssetTable;
