import commonImages from "../assets/index";

const CarouselData = [
  {
    id: 1,
    title: "Personal Injury",
    img: commonImages.PersonalinjuryImg,
  },
  {
    id: 2,
    title: "Vehicle Accident",
    img: commonImages.VehicleImg,
  },
  {
    id: 3,
    title: "Stolen Property",
    img: commonImages.StolenImg,
  },
  {
    id: 4,
    title: "Utility Damage",
    img: commonImages.UtilitycaseImg,
  },
  {
    id: 5,
    title: "Near Miss",
    img: commonImages.nearMisses,
  },
];

export const IncidentCarouselData = [
  {
    id: 1,
    title: "Basic Inspection",
    img: commonImages.basicInspectionImg,
  },
  {
    id: 2,
    title: "Comprehensive Inspection",
    img: commonImages.comprehensiveInspectionImg,
  },
  {
    id: 3,
    title: "Mock OSHA",
    img: commonImages.mockOshaInspection,
  },
  {
    id: 4,
    title: "General Inspection",
    img: commonImages.generalInspection,
  },
];

export default CarouselData;
