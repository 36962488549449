import { getApiUrl } from "../config";
import { LNG_ID, ORG_ID, PAGE_SIZE } from "../constant/constant";
import { PRODUCT_ID } from "../constant/storm-water/constant";
import { get, post, deletes } from "./axiosRequest";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const product = userInfo?.product;

const projectstatus = product?.map((val) => {
  return val?.key;
});

export const getUserDetails = async (mobile) => {
  const url = `${getApiUrl().userApiUrl}/getuser?phoneNumber=${mobile}`;
  try {
    return await get(
      url,
      {
        headers: {
          Authorization: localStorage.getItem("id_token"),
          orgId: ORG_ID(),
        },
      },
      true
    );
  } catch (err) {
    return err;
  }
};

export const getUserLists = async ({ data }) => {
  const {
    page,
    pageSize,
    search,
    designation = "",
    divisionId = 0,
    IsProcoreUsers,
  } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/User/GetUserList?page=${page}&pageSize=${pageSize}&orgId=${ORG_ID()}&search=${search}&jobTitle=${designation}&divisionId=${divisionId}&productId=${
    PRODUCT_ID() ? PRODUCT_ID() : projectstatus[0]
  }&IsProcoreUsers=${IsProcoreUsers}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const searchUsers = async (
  searchType,
  serachText,
  productId = PRODUCT_ID()
) => {
  if (serachText?.trim()) {
    const url = `${
      getApiUrl().searchApiUrl
    }/v1/getusers?type=${searchType}&searchText=${
      serachText ? serachText?.trim()?.toLowerCase() : ""
    }&orgId=${ORG_ID()}&lngId=${LNG_ID}&productId=${productId}`;
    try {
      return await get(
        url,
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        },
        true
      );
    } catch (err) {
      return err;
    }
  }
};

export const addLists = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/User/AddUser`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getGroupList = async ({ data }) => {
  const { page, pageSize, search, divisionId = 0, allGroups } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Group/GetGroupList?orgId=${ORG_ID()}&search=${search}&page=${page}&pageSize=${pageSize}&divisionId=${divisionId}&allGroups=${allGroups}&productId=${PRODUCT_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addUserGroups = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/Group/AddGroup`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUserListDetails = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/User/GetUserDetails?orgId=${
    data.orgId
  }&userId=${data.userId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: data.orgId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateUserListDetails = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/User/UpdateUser`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteUserList = async (payload) => {
  const url = `${getApiUrl().adminApiUrl}/User/DeleteUser/${payload?.userId}`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getAllUsers = async (
  serachText,
  type = 0,
  divisionId = 0,
  assetId = 0,
  assetType = 0,
  priviledgeId = 0,
  productId = 0,
  orgId
) => {
  if (serachText?.trim()) {
    const url = `${getApiUrl().searchApiUrl}/v1/getusersearch?orgId=${
      orgId ? orgId : ORG_ID()
    }&search=${
      serachText ? serachText?.trim().toLowerCase() : ""
    }&userType=${type}&divisionId=${divisionId}&assetType=${assetType}&assetId=${assetId}&priviledgeId=${priviledgeId}&productId=${PRODUCT_ID()}`;

    try {
      return await get(
        url,
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        },
        true
      );
    } catch (err) {
      return err;
    }
  }
};

export const updateUserGroupService = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/Group/UpdateGroup`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteUserGroupService = async (payload) => {
  const url = `${getApiUrl().adminApiUrl}/Group/DeleteGroup/${payload?.userId}`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUserGroupDetailsService = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Group/GetGroupDetails?groupId=${data}&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUserGroupList = async ({ data }) => {
  const { page, search, userId, orgId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/User/GetUserGroupsList?orgId=${orgId}&userId=${userId}&search=${search}&page=${page}&pageSize=${PAGE_SIZE}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const leaveGroups = async (data) => {
  const { groupId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/User/RemoveUserFromGroup/${localStorage.getItem("userId")}/${groupId}`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const searchAllGroup = async (
  serachText,
  divisionId,
  alldivision,
  orgId
) => {
  if (serachText?.trim()) {
    const url = `${getApiUrl().searchApiUrl}/v1/searchgroup?orgId=${
      orgId ? orgId : ORG_ID()
    }&search=${serachText ? serachText?.trim() : ""}&divisionId=${
      divisionId ? divisionId : 0
    }&alldivision=${
      alldivision ? alldivision : false
    }&productId=${PRODUCT_ID()}`;
    try {
      return await get(
        url,
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        },
        true
      );
    } catch (err) {
      return err;
    }
  }
};

export const getDashboardCount = async (orgId, userId) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Dashboard/GetDashboardCount?orgId=${orgId}&userId=${userId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUserConfigurationLists = async ({ data }) => {
  const { page, pageSize, search, Status } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/User/GetOnboardedUsersList?Status=${Status}&Search=${search}&page=${page}&pageSize=${pageSize}&productId=${PRODUCT_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateUserConfigurationList = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/User/UpdateUserCognito`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUserConfigurationDetails = async (data) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/User/GetOnboardUserDetails?OnboardUserId=${data.data}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateUserOnboardDetails = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/User/UpdateOnboardUser`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateUserOnboardStatus = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/User/UserEnableDisable`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getNonPrivilegeList = async ({ data }) => {
  const { page, pageSize, search, titleId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/NonPrivilegedUser/GetUserList?search=${search}&page=${page}&pageSize=${pageSize}&titleId=${titleId}&productId=${PRODUCT_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteNonPrivilegeUserList = async (payload) => {
  const url = `${getApiUrl().adminApiUrl}/NonPrivilegedUser/DeleteUser/${
    payload.userId
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addNonPrivilegeUserList = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/NonPrivilegedUser/AddUser`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getNonPrivilegeUserDetails = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/NonPrivilegedUser/GetUserDetails?userId=${data.userId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateNonPrivilegeUserList = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/NonPrivilegedUser/UpdateUser`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const convertNonPrivilegeUserList = async (data) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/NonPrivilegedUser/ConvertInpriviledge`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const recreateUsers = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/User/RecreateUserIncognito`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getAssetOnUser = async ({ data }) => {
  const { assetType, userId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/User/GetAssetOnUser?assetType=${assetType}&userId=${userId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSupervisorOnAsset = async ({ data }) => {
  const { assetType, assetId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/User/GetSupervisorOnAsset?assetType=${assetType}&assetId=${assetId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const checkUserInfos = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/User/CheckUserInfo`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUserByRolrList = async ({ data }) => {
  const { page, pageSize, roleId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/User/UserSearchByRole?roleId=${roleId}&page=${page}&pageSize=${pageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSyncWithProcoreUserList = async ({ data }) => {
  const { orgId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Procore/SyncProcoreCompanyUser?orgId=${orgId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
