import * as siteAction from "../../storm-water/actions/siteAction";
import { siteType } from "../../storm-water/types/siteType";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../../../src/utils/helper";
import {
  site,
  getSiteDetsils,
  updateSite,
  siteList,
  getSiteSearchList,
  getSite,
} from "../../../services/storm-water/siteService";

export function* createClientSaga(params) {
  console.debug("createSiteSaga");
  try {
    const { data, status, error } = yield call(site, params);
    if (!data && status !== 200) throw apiError(error);
    const sitePage = siteType.SITE_PAGE;
    yield put(
      siteAction.siteSuccess({
        sitePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Site  Creation Failed" };
    yield put(siteAction.siteFailure(data));
  }
}

export function* updateSiteSaga(params) {
  console.debug("updateSiteSaga");
  try {
    const { data, status, error } = yield call(updateSite, params);
    if (!data && status !== 200) throw apiError(error);
    const sitePage = siteType.SITE_PAGE;
    yield put(
      siteAction.updateSiteSuccess({
        sitePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Site Created Successfull" };
    yield put(siteAction.updateSiteFailure(data));
  }
}

export function* getSiteDetailsSaga(datas) {
  console.debug("getSiteDetailsSaga");
  try {
    const { data, status, error } = yield call(getSiteDetsils, datas);
    if (!data && status !== 200) throw apiError(error);
    const sitePage = siteType.SITE_PAGE;
    yield put(
      siteAction.getSiteDetsilsSuccess({
        sitePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Site Details Failed" };
    yield put(siteAction.getSiteDetailsFailure(data));
  }
}

export function* siteListSaga(params) {
  try {
    const { data, status, error } = yield call(siteList, params);
    if (!data && status !== 200) throw apiError(error);
    const sitePage = siteType.SITE_PAGE;
    yield put(
      siteAction.siteListSuccess({
        sitePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Site List Failed" };
    yield put(siteAction.siteListFailure(data));
  }
}

export function* getSiteSearchSaga(params) {
  try {
    const { data, status, error } = yield call(getSiteSearchList, params);

    if (!data && status !== 200) throw apiError(error);
    const sitePage = siteType.SITE_PAGE;
    yield put(
      siteAction.getSiteAutoSearchListSuccess({
        sitePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Site Search List Failed" };
    yield put({
      type: siteType.GET_SW_SITE_AUTO_SEARCH_FAILURE,
      error: data,
    });
  }
}

export function* getSiteSaga(datas) {
  console.debug("getSiteSaga");
  try {
    const { data, status, error } = yield call(getSite, datas);
    if (!data && status !== 200) throw apiError(error);
    const sitePage = siteType.SITE_PAGE;
    yield put(
      siteAction.getSiteSuccess({
        sitePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Site Details Failed" };
    yield put(siteAction.getSiteFailure(data));
  }
}

const mySiteSaga = [
  takeLatest(siteType.POST_SITE_PENDING, createClientSaga),
  takeLatest(siteType.PUT_SITE_UPDATE_PENDING, updateSiteSaga),
  takeLatest(siteType.GET_SITE_DETAILS_PENDING, getSiteDetailsSaga),
  takeLatest(siteType.GET_SITE_LIST_PENDING, siteListSaga),
  takeLatest(siteType.GET_SW_SITE_AUTO_SEARCH_PENDING, getSiteSearchSaga),
  takeLatest(siteType.GET_SITE_PENDING, getSiteSaga),
];

export default mySiteSaga;
