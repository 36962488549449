import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClientList from "../component/client/clientList";
import * as clientAction from "../../../redux/actions/clientAction";
import { ADMIN_ADD_CLIENT, DASHBOARD } from "../../../constant/routeContant";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { LNG_ID, ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";

export default function ClientListContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateData = useSelector((state) => state.state);

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };

  useEffect(() => {
    if (!checkPrivileges([12, 67, 68, 69])) {
      navigate(DASHBOARD);
    }
    getStateData();
  }, []);

  const NavigateAddClientMethode = () => {
    navigate(ADMIN_ADD_CLIENT);
  };

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    industryTypeId: 0,
    stateId: 0,
    search: "",
  };
  const clientListData = useSelector((state) => state.getClientListData);

  const getClients = (data) => {
    dispatch(clientAction.getClientList(data));
  };

  useEffect(() => {
    getClients(defaultData);
  }, []);

  const industryType = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.industryTypes
  );
  const industryTypeStatus = useSelector(
    (state) => state.getDropdownDataKeyValue.isLoading
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: 19,
    lngId: LNG_ID,
    productId: PRODUCT_ID(),
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  return (
    <>
      <ClientList
        getClients={getClients}
        clientListsData={clientListData}
        NavigateAddClient={NavigateAddClientMethode}
        isLoading={
          clientListData.isLoading || stateData.isLoading || industryTypeStatus
        }
        industryType={industryType}
        state={stateData && stateData.data?.getState}
      />
    </>
  );
}
