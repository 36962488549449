import { jhaType } from "../constants/jhaType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "jha",
  isError: false,
  isLoading: false,
};

export const addJhaCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.POST_JHA_ADD_CATEGORY:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.POST_JHA_ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.POST_JHA_ADD_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaCategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_CATEGORY_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateJhaCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.UPDATE_JHA_CATEGORY:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.UPDATE_JHA_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.UPDATE_JHA_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const addJhaActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.POST_JHA_ADD_ACTIVITY:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.POST_JHA_ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.POST_JHA_ADD_ACTIVITY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaActivityListReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_ACTIVITY_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_ACTIVITY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateJhaActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.UPDATE_JHA_ACTIVITY:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.UPDATE_JHA_ACTIVITY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.UPDATE_JHA_ACTIVITY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const addJhaHazardReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.POST_JHA_ADD_HAZARD:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.POST_JHA_ADD_HAZARD_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.POST_JHA_ADD_HAZARD_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaHazardsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_HAZARDS_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_HAZARDS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_HAZARDS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateJhaHAzardReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.UPDATE_JHA_HAZARD:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.UPDATE_JHA_HAZARD_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.UPDATE_JHA_HAZARD_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaControlListReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_CONTROL_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_CONTROL_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_CONTROL_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const addJhaControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.POST_JHA_ADD_CONTROL:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.POST_JHA_ADD_CONTROL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.POST_JHA_ADD_CONTROL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateJhaControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.UPDATE_JHA_CONTROL:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.UPDATE_JHA_CONTROL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.UPDATE_JHA_CONTROL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getConductJhaListReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_CONDUCT_JHA_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_CONDUCT_JHA_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_CONDUCT_JHA_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateJhaTemplateStatusReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case jhaType.POST_JHA_UPDATE_TEMPLATE_STATUS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.POST_JHA_UPDATE_TEMPLATE_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.POST_JHA_UPDATE_TEMPLATE_STATUS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const addJhaTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.POST_JHA_ADD_TEMPLATE:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };

    case jhaType.POST_JHA_ADD_TEMPLATE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };

    case jhaType.POST_JHA_ADD_TEMPLATE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaTemplateListReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_TEMPLATE_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaControlTypeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_CONTROL_TYPE_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_CONTROL_TYPE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_CONTROL_TYPE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaControlDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_CONTROL_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_CONTROL_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_CONTROL_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const addJhaConductReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.POST_JHA_ADD_CONDUCT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.POST_JHA_ADD_CONDUCT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.POST_JHA_ADD_CONDUCT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaTempleteDetsilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_TEMPLETE_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_TEMPLETE_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_TEMPLETE_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateJhaTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.UPDATE_JHA_TEMPLATE:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.UPDATE_JHA_TEMPLATE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.UPDATE_JHA_TEMPLATE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaConductDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_CONDUCT_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_CONDUCT_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_CONDUCT_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const addJhaSetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.POST_JHA_ADD_SETUP:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.POST_JHA_ADD_SETUP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.POST_JHA_ADD_SETUP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaSetupListReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_SETUP_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_SETUP_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_SETUP_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const activeInactiveJHASetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.POST_JHA_ACTIVE_INACTIVE_SETUP:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.POST_JHA_ACTIVE_INACTIVE_SETUP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.POST_JHA_ACTIVE_INACTIVE_SETUP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJHAReferenceDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_REFERENCE_DATA:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_REFERENCE_DATA_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_REFERENCE_DATA_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaSetupDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_SETUP_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_SETUP_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_SETUP_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateJhaSetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.UPDATE_JHA_SETUP:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.UPDATE_JHA_SETUP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.UPDATE_JHA_SETUP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaSetupDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case jhaType.GET_JHA_SETUP_DASHBOARD:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_SETUP_DASHBOARD_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_SETUP_DASHBOARD_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getSaftyLogJHAConductListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case jhaType.GET_JHA_SAFETYLOG_CONDUCTLIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_SAFETYLOG_CONDUCTLIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_SAFETYLOG_CONDUCTLIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getJhaActiveTemplateListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case jhaType.GET_JHA_ACTIVE_TEMPLATE_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case jhaType.GET_JHA_ACTIVE_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case jhaType.GET_JHA_ACTIVE_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case jhaType.CLEAR_JHA:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
