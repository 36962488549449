import React, { useEffect, useState } from "react";
import "./LocationField.scss";
import { TextField } from "@mui/material";
import FormLabels from "../custom/formLabel/FormLabels";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import GoogleMaps from "../../feature/storm-water/admin/components/Mapbox";

function LocationField({
  fullWidth,
  value,
  disabled,
  type,
  label,
  placeholder,
  onChange,
  isRequired,
  maxLength,
  id,
  isError = false,
  errorMsg = "",
  name,
  Icon,
  onChangeLocations,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "3px solid #000",
  };

  const [open, setOpen] = useState(false);
  const [closeMap, setCloseMap] = useState(true);
  const handleOpen = (event) => {
    event.stopPropagation();
    if (disabled) {
      return false;
    } else {
      setOpen(true);
      setCloseMap(true);
    }
  };
  const handleClose = () => setOpen(false);

  const onCloseMap = () => {
    setCloseMap(false);
    setOpen(false);
  };

  return (
    <div className="overideInputField">
      <div className="input_field_box">
        {label && (
          <FormLabels isRequired={isRequired} label={label}></FormLabels>
        )}
        <TextField
          id={id ? id : "defaultId"}
          style={{ width: "100%", margin: 0 }}
          variant="standard"
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          type={type}
          value={value}
          className="formControlRoot"
          onChange={(e) => onChange(e)}
          fullWidth={fullWidth}
          size="small"
          InputProps={{
            className: "inputRoot",
            disableUnderline: true,
          }}
          error={isError}
          helperText={value ? "" : errorMsg}
          name={name}
        />
        <RoomOutlinedIcon onClick={handleOpen} className="location-icon" />
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <>
              {closeMap && (
                <>
                  <Box sx={style}>
                    <div onClick={onCloseMap} className="CloseMapstyle">
                      X
                    </div>
                    <GoogleMaps
                      onChageLocation={onChangeLocations}
                      onClose={handleClose}
                    />
                  </Box>
                </>
              )}
            </>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default LocationField;
