import { clientType } from "../constants/clientType";

export const getClientList = (data) => ({
  type: clientType.GET_CLIENT_LIST_PENDING,
  data,
});

export const getClientListSuccess = (data) => ({
  type: clientType.GET_CLIENT_LIST_SUCCESS,
  data,
});

export const getClientListFailure = (error) => ({
  type: clientType.GET_CLIENT_LIST_FAILURE,
  error,
});

export const createClient = (data) => ({
  type: clientType.POST_CLIENT_PENDING,
  data,
});

export const createClientSuccess = (data) => ({
  type: clientType.POST_CLIENT_SUCCESS,
  data,
});

export const createClientFailure = (error) => ({
  type: clientType.POST_CLIENT_FAILURE,
  error,
});

export const getClientDetsils = (data) => ({
  type: clientType.GET_CLIENT_DETAILS_PENDING,
  data,
});

export const getClientDetsilsSuccess = (data) => ({
  type: clientType.GET_CLIENT_DETAILS_SUCCESS,
  data,
});

export const getClientDetailsFailure = (error) => ({
  type: clientType.GET_CLIENT_DETAILS_FAILURE,
  error,
});

export const updateClient = (data) => ({
  type: clientType.PUT_CLIENT_PENDING,
  data,
});

export const updateClientSuccess = (data) => ({
  type: clientType.PUT_CLIENT_SUCCESS,
  data,
});

export const updateClientFailure = (error) => ({
  type: clientType.PUT_CLIENT_FAILURE,
  error,
});

export const deleteClient = (Organisationid) => ({
  type: clientType.DELETE_CLIENT_PENDING,
  Organisationid,
});

export const deleteClientSuccess = (data) => ({
  type: clientType.DELETE_CLIENT_SUCCESS,
  data,
});

export const deleteClientFailure = (error) => ({
  type: clientType.DELETE_CLIENT_FAILURE,
  error,
});

export const clearClient = () => ({
  type: clientType.CLEAR_CLIENT,
  data: "",
});

export const searchAllClients = (searchText) => ({
  type: clientType.GET_ALL_CLIENT,
  searchText,
});

export const searchAllClientsSuccess = (data) => ({
  type: clientType.GET_ALL_CLIENT_SUCCESS,
  data,
});

export const searchAllClientsFailure = (error) => ({
  type: clientType.GET_ALL_CLIENT_FAILURE,
  error,
});

export const syncWithProcore = (data) => ({
  type: clientType.GET_SYNC_WITH_PROCORE_CLIENT,
  data,
});

export const syncWithProcoreSuccess = (data) => ({
  type: clientType.GET_SYNC_WITH_PROCORE_SUCCESS,
  data,
});

export const syncWithProcoreFailure = (error) => ({
  type: clientType.GET_SYNC_WITH_PROCORE_FAILURE,
  error,
});

export const procoreCompanyList = (data) => ({
  type: clientType.GET_PROCORE_COMPANY_LIST,
  data,
});

export const procoreCompanyListSuccess = (data) => ({
  type: clientType.GET_PROCORE_COMPANY_LIST_SUCCESS,
  data,
});

export const procoreCompanyListFailure = (error) => ({
  type: clientType.GET_PROCORE_COMPANY_LIST_FAILURE,
  error,
});
