import React from "react";
import "./previousOpenItems.scss";
import { swInspection } from "../../../../../assets";
import FormLabels from "../../../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../../../library/custom/textArea/TextArea";
import { Grid } from "@mui/material";
import Buttons from "../../../../../library/custom/button/Button";
import DatePicker from "../../../../../library/custom/datePicker/DatePicker";
import parse from "html-react-parser";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../../library/custom/selectMenu/SelectMenu";

const PreviousOpenItems = ({
  previousItem,
  openItemHandler,
  getSwReference,
}) => {
  return (
    <>
      {Object.keys(previousItem)?.length > 0 && (
        <div className="prevOpenItemContainer">
          <div className="prvTitle">Previous Open Items</div>
          <div className="prevBox">
            <div className="prevBoxHeader">
              <div className="item">item</div>
              <div className="item">location</div>
              <div className="item"></div>
            </div>

            <div className="prevBoxBody">
              {Object.keys(previousItem).map((key) => {
                const item = previousItem[key];
                return (
                  <div className="prevBoxBodyList" key={key}>
                    <div className="prevBoxBodyListTop">
                      <div className="bodyListItem">
                        {item.item} {item.itemDetail ? ":" : ""}{" "}
                        <span className="itemDetailshow">
                          {parse(unescape(item.itemDetail))}
                        </span>
                      </div>
                      <div className="bodyListItem">
                        <div className="">{item.location}</div>
                        <div className="locationImg">
                          {item?.files?.map((file,index) => {
                            return <img key={index} src={file?.filePath} alt="" />;
                          })}
                        </div>
                      </div>
                      <div className="bodyListItem right">
                        {item.status === 2 && (
                          <>
                            <div
                              className="comment"
                              onClick={(e) =>
                                openItemHandler(
                                  item.questionId,
                                  item.locationId,
                                  "comments",
                                  e
                                )
                              }
                            >
                              <img src={swInspection?.comment} alt="" />
                            </div>
                            <div
                              className="checkcircleclosed open"
                              onClick={(e) =>
                                openItemHandler(
                                  item.questionId,
                                  item.locationId,
                                  "closeItem",
                                  e
                                )
                              }
                            >
                              Close Item
                            </div>
                          </>
                        )}
                        {item.status === 3 && (
                          <>
                            <div className="checkcircleclosed closed">
                              <img
                                src={swInspection?.checkcircleclosed}
                                alt=""
                              />{" "}
                              Item Closed
                            </div>
                          </>
                        )}
                      </div>
                      {item?.comment &&
                        item?.status === 2 &&
                        item?.comment?.map((items, index) => {
                          return (
                            <div className="allCommentBox" key={index}>
                              <div className="commentBody">
                                <div className="commentBodyList">
                                  <div className="commentBodyListItem paragraph left">
                                    <span>Comment: </span>
                                    {items.comment}
                                  </div>
                                  <div className="commentBodyListItem date right">
                                    {items.addedOn}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    {item.isCloseItem && (
                      <div className="closeItemBox">
                        <Grid container mb={2}>
                          <Grid item md={5} xs={12}>
                            <FormLabels
                              label={"Date Closed"}
                              isRequired={true}
                            />
                            <DatePicker
                              minDate
                              onChangeHandler={(e) =>
                                openItemHandler(
                                  item.questionId,
                                  item.locationId,
                                  "dateClosedText",
                                  e
                                )
                              }
                                disabled={true}
                              value={
                                item.dateClosedText ? item.dateClosedText : null
                              }
                              maxDateEnable={false}
                              //   isError={isInspectionStartDate}
                              //   errorMsg={isInspectionStartDate ? "Start date is required" : ""}
                            />
                            {item?.dateClosedError && (
                              <p className="error">Date Closed is Required. </p>
                            )}
                            <Grid md={12} xs={12} item mt={2}>
                              <FormControl fullWidth>
                                <FormLabels
                                  label="Corrective Action Taken"
                                  isRequired={false}
                                />
                                <SelectMenu
                                  placeholder="Please Select"
                                  listData={
                                    getSwReference?.ItemClosedActionList ?? []
                                  }
                                  value={item?.closedId ?? null}
                                  onchangehandler={(e) =>
                                    openItemHandler(
                                      item.questionId,
                                      item.locationId,
                                      "closedId",
                                      e.target.value
                                    )
                                  }
                                />
                              </FormControl>
                              {/* {item?.isClosedIdError && (
                                <div className="error">
                                  Please select Corrective Action Taken field.
                                </div>
                              )} */}
                            </Grid>
                          </Grid>
                        </Grid>

                        {item?.closedIdValue?.toLowerCase() === "other" && (
                          <>
                            <FormLabels
                              label="Other details"
                              isRequired={false}
                            />
                            <TextArea
                              placeholder={"Enter Here"}
                              name="freeText"
                              value={item?.actionTaken}
                              onChange={(e) =>
                                openItemHandler(
                                  item.questionId,
                                  item.locationId,
                                  "actionTakenText",
                                  e.target.value
                                )
                              }
                            />
                            {item?.actionTakenError && (
                              <p className="error">
                                Corrective Action Taken is Required.{" "}
                              </p>
                            )}
                          </>
                        )}
                        <Grid container mt={1} mb={1} className="buttonEnd">
                          <Grid item lg={3} xs={12} sm={6} md={6}>
                            <Buttons
                              varientText={true}
                              label="Cancel"
                              onClick={(e) =>
                                openItemHandler(
                                  item.questionId,
                                  item.locationId,
                                  "cancelCloseItem",
                                  e
                                )
                              }
                            />
                          </Grid>
                          <Grid item lg={3} xs={12} sm={6} md={6}>
                            <Buttons
                              varientContained={true}
                              label={"Save"}
                              onClick={(e) =>
                                openItemHandler(
                                  item.questionId,
                                  item.locationId,
                                  "closeItemSubmit",
                                  item.dateClosedText
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {item.isComment && (
                      <div className="commintingBox">
                        <FormLabels label="Comment" isRequired={true} />
                        <TextArea
                          placeholder={"Enter Here"}
                          name="freeText"
                          value={item?.commentText}
                          onChange={(e) =>
                            openItemHandler(
                              item.questionId,
                              item.locationId,
                              "commentText",
                              e.target.value
                            )
                          }
                        />
                        {item?.commentTextError && (
                          <p className="error">Comment is Required. </p>
                        )}
                        <Grid container mt={1} mb={1} className="buttonEnd">
                          <Grid item lg={3} xs={12} sm={6} md={6}>
                            <Buttons
                              varientText={true}
                              label="Cancel"
                              onClick={(e) =>
                                openItemHandler(
                                  item.questionId,
                                  item.locationId,
                                  "cancelComment",
                                  e
                                )
                              }
                            />
                          </Grid>
                          <Grid item lg={3} xs={12} sm={6} md={6}>
                            <Buttons
                              varientContained={true}
                              label={"Save"}
                              onClick={(e) =>
                                openItemHandler(
                                  item.questionId,
                                  item.locationId,
                                  "commentSubmit",
                                  item.commentText
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviousOpenItems;
