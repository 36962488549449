import * as caseAction from "../actions/caseAction";
import { caseType } from "../constants/caseType";
import {
  createCase,
  updateCase,
  getCaseList,
} from "../../services/caseService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* createCaseSaga(params) {
  console.debug("createCaseSaga");
  try {
    const { data, status, error } = yield call(createCase, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = caseType.CASE_PAGE;
    yield put(
      caseAction.createCaseSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Case Failed" };
    yield put(caseAction.createCaseFailure(data));
  }
}

export function* updateCaseSaga(params) {
  console.debug("updateCaseSaga");
  try {
    const { data, status, error } = yield call(updateCase, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = caseType.CASE_PAGE;
    yield put(
      caseAction.updateCaseSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Case Failed" };
    yield put(caseAction.updateCaseFailure(data));
  }
}

export function* getCaseListSaga(params) {
  console.debug("getCaseListSaga");
  try {
    const { data, status, error } = yield call(getCaseList, params);
    if (!data && status !== 200) throw apiError(error);
    const casePage = caseType.CASE_PAGE;
    yield put(
      caseAction.getCaseListSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Case List Failed" };
    yield put(caseAction.getCaseListFailure(data));
  }
}

const myCaseSaga = [
  takeLatest(caseType.POST_CASE_PENDING, createCaseSaga),
  takeLatest(caseType.GET_CASE_LIST_PENDING, getCaseListSaga),
  takeLatest(caseType.PUT_CASE_PENDING, updateCaseSaga),
];

export default myCaseSaga;
