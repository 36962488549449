import React, { useEffect } from "react";
import "./itemManagement.scss";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { TIMERANGE } from "../../../../constant/storm-water/constant";
import DonoughtChart from "../../../support/component/ticket/charts";
import commonImages from "../../../../assets";
import OpenItemList from "./openItemList";

const ItemManagement = (props) => {
  const noOpenItem = props?.openItemList?.totalOpenItems;
  useEffect(() => {
    TIMERANGE?.unshift({ key: 1, value: "All Open Items" });
  }, []);

  return (
    <>
      <Grid className="swItemManagement">
        <Grid className="title">Item Management</Grid>
        <Grid className="title" mt={5}>
          My Items
        </Grid>
        <Grid container mt={3}>
          <Grid md={4} xs={12} item>
            <FormControl fullWidth>
              <FormLabels label="Select Time Range" isRequired={false} />
              <SelectMenu
                placeholder="Please Select"
                listData={TIMERANGE ?? []}
                value={props?.timeRange ? props?.timeRange : null}
                onchangehandler={(e) => props?.handleTimeRangeSetChange(e)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={3}>
          <Grid md={4} xs={12} item>
            <Grid className="chartCard">
              <Grid className="assignedDiv1">
                <div className="heading">Total Open Items</div>
                <div className="count">
                  {props?.openItemList?.totalOpenItems}
                </div>
              </Grid>
              <div className="pieChart">
                {noOpenItem === 0 && (
                  <div className="noItemImg">
                    <div className="noItemImgTitle">
                      You have no Open Items!
                    </div>
                    <img src={commonImages?.Celebration} alt="" />
                  </div>
                )}

                <DonoughtChart
                  SwItemPieChart
                  itemListData={props?.isLoading ? [] : props?.openItemList}
                />
              </div>
            </Grid>
          </Grid>
          <Grid md={8} xs={12} item>
            <div className="smallCards">
              <div className="topSmallCards">
                <div
                  className={
                    props?.cartType === 1 ? "cardColumn active" : "cardColumn"
                  }
                  onClick={() => props?.getItemHander(1)}
                >
                  <div className="title">Total Open Items</div>
                  <div className="count">
                    {props?.isLoading ? 0 : props?.openItemList?.totalOpenItems}
                  </div>
                </div>
                <div
                  className={
                    props?.cartType === 2 ? "cardColumn active" : "cardColumn"
                  }
                  onClick={() => props?.getItemHander(2)}
                >
                  <div className="title">
                    <span>
                      <img src={commonImages?.KudosCount} alt="" />
                    </span>
                    Closed
                  </div>
                  <div className="count">
                    {props?.isLoading
                      ? 0
                      : props?.openItemList?.totalClosedItems}
                  </div>
                </div>
              </div>
              <div className="bottomSmallCards">
                <div
                  className={
                    props?.cartType === 3 ? "cardColumn active" : "cardColumn"
                  }
                  onClick={() => props?.getItemHander(3)}
                >
                  <div className="title">
                    <span>
                      <img src={commonImages?.ViolationCount} alt="" />
                    </span>
                    Open 1-4 Days
                  </div>
                  <div className="count">
                    {" "}
                    {props?.isLoading
                      ? 0
                      : props?.openItemList?.totalFourDaysOpen}
                  </div>
                </div>
                <div
                  className={
                    props?.cartType === 4 ? "cardColumn active" : "cardColumn"
                  }
                  onClick={() => props?.getItemHander(4)}
                >
                  <div className="title">
                    <span>
                      <img src={commonImages?.openError} alt="" />
                    </span>
                    Open 4-7 Days
                  </div>
                  <div className="count">
                    {" "}
                    {props?.isLoading
                      ? 0
                      : props?.openItemList?.totalSevenDaysOpen}
                  </div>
                </div>
                {props?.timeRange !== 7 && (
                  <div
                    className={
                      props?.cartType === 5 ? "cardColumn active" : "cardColumn"
                    }
                    onClick={() => props?.getItemHander(5)}
                  >
                    <div className="title">
                      <span>
                        <img src={commonImages?.ReprimandCount} alt="" />
                      </span>
                      Open &gt; 7 Days
                    </div>
                    <div className="count">
                      {props?.isLoading
                        ? 0
                        : props?.openItemList?.totalMoreThanSevenDaysOpen}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        <OpenItemList
          openItemListDetail={props?.openItemList}
          isLoading={props?.isLoading}
          cartType={props?.cartType}
          getOpenItemList={props?.getOpenItemList}
          todayDate={props?.todayDate}
          calculatedDate={props?.calculatedDate}
          setSelectionModel={props?.setSelectionModel}
          selectionModel={props?.selectionModel}
          disbled={props?.disbled}
          isComment={props?.isComment}
          commentFormValue={props?.commentFormValue}
          setCommentFormValue={props?.setCommentFormValue}
          submitCommentData={props?.submitCommentData}
          commentBox={props?.commentBox}
          closeBox={props?.closeBox}
          closeItemHandler={props?.closeItemHandler}
          commentHandler={props?.commentHandler}
          cancelComment={props?.cancelComment}
          cancelClose={props?.cancelClose}
          isDateClosed={props?.isDateClosed}
          isActionTaken={props?.isActionTaken}
          closeFormValue={props?.closeFormValue}
          setCloseFormValue={props?.setCloseFormValue}
          submitCloseData={props?.submitCloseData}
          setCartType={props?.setCartType}
          selectedRows={props?.selectedRows}
          handleSelectionModelChange={props?.handleSelectionModelChange}
          handleFileChange={props?.handleFileChange}
          closesFilesData={props?.closesFilesData}
          handleDeleteImageURL={props?.handleDeleteImageURL}
          isMedia={props?.isMedia}
          handleAllProjectSearch={props?.handleAllProjectSearch}
          isProjectLoading={props?.isProjectLoading}
          allProjects={props?.allProjects}
          timeRange={props?.timeRange}
          stateData={props?.stateData}
          getSwReference={props?.getSwReference}
          openItemHandler={props?.openItemHandler}
          isClosedIdError={props?.isClosedIdError}
          searchClient={props?.searchClient}
          searchAllClientsReducerData={props?.searchAllClientsReducerData}
          autoSearchDataLoading={props?.autoSearchDataLoading}
          setClients={props?.setClients}
          clients={props?.clients}
        />
      </Grid>
    </>
  );
};

export default ItemManagement;
