import * as newsAction from "../actions/newsAction";
import { newsType } from "../constants/newsType";
import {
  createNews,
  getNewsList,
  getNewsDetails,
  updateNews,
  deleteNews,
} from "../../services/newsService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* createNewsSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(createNews, payload);
    if (!data && status !== 200) throw apiError(error);
    const casePage = newsType.NEWS_PAGE;
    yield put(
      newsAction.postcreateNewsSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create News Failed" };
    yield put(newsAction.postcreateNewsFailure(data));
  }
}
export function* getNewsListSaga(params) {
  try {
    const { data, status, error } = yield call(getNewsList, params);

    if (!data && status !== 200) throw apiError(error);
    const newsPage = newsType.NEWS_LIST_PAGE;
    yield put(
      newsAction.getNewsListSuccess({
        newsPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get News List Failed" };
    yield put(newsAction.getNewsListFailure(data));
  }
}
export function* getNewsDetailSaga(datas) {
  console.debug("getNewsDetailSaga");
  try {
    const { data, status, error } = yield call(getNewsDetails, datas);
    if (!data && status !== 200) throw apiError(error);
    const newsPage = newsType.NEWS_DETAIL_PAGE;
    yield put(
      newsAction.getNewsDetailsSuccess({
        newsPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get News Details Failed" };
    yield put(newsAction.getNewsDetailsFailure(data));
  }
}
export function* updateNewsSaga(params) {
  try {
    const { data, status, error } = yield call(updateNews, params);
    if (!data && status !== 200) throw apiError(error);
    const newsPage = newsType.NEWS_UPDATE_PAGE;
    yield put(
      newsAction.updateNewsSuccess({
        newsPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update News Failed" };
    yield put(newsAction.updateNewsFailure(data));
  }
}
function* deleteNewsSaga(payload) {
  try {
    const { data, status } = yield call(deleteNews, payload);
    const newsPage = newsType.DELETE_NEWS_PAGE;

    yield put(
      newsAction.deleteNewsSuccess({
        newsPage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "delete News List Failed" };
    yield put(newsAction.deleteNewsFailure(data));
  }
}

const newsSaga = [
  takeLatest(newsType.POST_NEWS_PENDING, createNewsSaga),
  takeLatest(newsType.GET_LIST_NEWS_PENDING, getNewsListSaga),
  takeLatest(newsType.GET_NEWS_DETAILS_PENDING, getNewsDetailSaga),
  takeLatest(newsType.PUT_NEWS_PENDING, updateNewsSaga),
  takeLatest(newsType.DELETE_NEWS_PENDING, deleteNewsSaga),
];

export default newsSaga;
