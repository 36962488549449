import * as lmsReportAction from "../actions/lmsReportAction";
import { lmsReportType } from "../constants/lmsReportType";
import {
  getLmsReport,
  getLmsReportCourse,
  getLmsReportLesson,
} from "../../services/lmsReportService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* getLmsReportSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(getLmsReport, payload);
    if (!data && status !== 200) throw apiError(error);
    const lmsReportPage = lmsReportType.LMS_REPORT_PAGE;
    yield put(
      lmsReportAction.lmsReportSuccess({
        lmsReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Case Failed" };
    yield put(lmsReportAction.lmsReportFailure(data));
  }
}

export function* getLmsReportCourseSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(getLmsReportCourse, payload);
    if (!data && status !== 200) throw apiError(error);
    const lmsReportCoursePage = lmsReportType.LMS_REPORT_COURSE_PAGE;
    yield put(
      lmsReportAction.getLmsReportCourseSuccess({
        lmsReportCoursePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Lms Report Course Failed" };
    yield put(lmsReportAction.getLmsReportCourseFailure(data));
  }
}

export function* getLmsReportLessonSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(getLmsReportLesson, payload);
    if (!data && status !== 200) throw apiError(error);
    const lmsReportLessonPage = lmsReportType.LMS_REPORT_LESSON_PAGE;
    yield put(
      lmsReportAction.getLmsReportLessonSuccess({
        lmsReportLessonPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Lms Report Lesson Failed" };
    yield put(lmsReportAction.getLmsReportLessonFailure(data));
  }
}

const myReportSaga = [
  takeLatest(lmsReportType.GET_LMS_REPORT_PENDING, getLmsReportSaga),
  takeLatest(
    lmsReportType.GET_LMS_REPORT_COURSE_PENDING,
    getLmsReportCourseSaga
  ),
  takeLatest(
    lmsReportType.GET_LMS_REPORT_LESSON_PENDING,
    getLmsReportLessonSaga
  ),
];

export default myReportSaga;
