export const siteType = {
  POST_SITE_PENDING: "[SITE][REQUEST] Create Site",
  POST_SITE_FAILURE: "[SITE][RESPONSE] Site  Creation Failed",
  POST_SITE_SUCCESS: "[SITE][RESPONSE] Site Created Successfull",

  PUT_SITE_UPDATE_PENDING: "[SITE_UPDATE][REQUEST] Update Site",
  PUT_SITE_UPDATE_FAILURE: "[SITE_UPDATE][RESPONSE] Site Updation Failed",
  PUT_SITE_UPDATE_SUCCESS: "[SITE_UPDATE][RESPONSE] Site Updation Successfull",

  GET_SITE_DETAILS_PENDING: "[SITE_DETAILS][REQUEST] Get Site Details Data",
  GET_SITE_DETAILS_SUCCESS:
    "[SITE_DETAILS][RESPONSE] Get Site Details Data Successfull ",
  GET_SITE_DETAILS_FAILURE:
    "[SITE_DETAILS][RESPONSE] Get Site Details Data Failed ",

  GET_SITE_LIST_PENDING: "[GET_SITE_LIST][REQUEST] Get Site list requested",
  GET_SITE_LIST_FAILURE: "[GET_SITE_LIST][RESPONSE] Get Site list Failed",
  GET_SITE_LIST_SUCCESS: "[GET_SITE_LIST][RESPONSE] Get Site list Successfull",

  GET_SW_SITE_AUTO_SEARCH_PENDING:
    "[GET_SW_SITE_AUTO_SEARCH_PENDING][REQUEST] Get SW Site Auto Search requested",
  GET_SW_SITE_AUTO_SEARCH_FAILURE:
    "[GET_SW_SITE_AUTO_SEARCH_FAILURE][RESPONSE] Get SW Site Auto Search Failed",
  GET_SW_SITE_AUTO_SEARCH_SUCCESS:
    "[GET_SW_SITE_AUTO_SEARCH_SUCCESS][RESPONSE] Get SW Site Auto Search Successfull",
  SITE_PAGE: "SITE_PAGE",

  GET_SITE_PENDING: "[GET_SITE][REQUEST] Get Site Details Data",
  GET_SITE_SUCCESS: "[GET_SITE][RESPONSE] Get Site Details Data Successfull ",
  GET_SITE_FAILURE: "[GET_SITE][RESPONSE] Get Site Details Data Failed ",
  CLEAR_SWSITE: "[CLEAR_SWSITE][clear] Get Site Details Data clear",
};
