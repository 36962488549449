import React from "react";
import ContactList from "../components/contactList";
function ContactListContainer() {
  return (
    <div>
      <ContactList />
    </div>
  );
}

export default ContactListContainer;
