import { Grid } from "@mui/material";
import React from "react";
import "./errorPageStyle.scss";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SomethingWentWrong from "../../assets/somethingWentWrong.svg";


const ErrorPage = () => {
  const SAFETY_FACTS_ARRAY = [
    "Always make sure that you know how to use tools or machinery correctly.  If you are not comfortable with any equipment do not operate it until you have further training! Accidents can quickly happen when something is not a familiar item.  Common issues include using the wrong tool for a task or creating an unsafe environment while not correctly using heavy equipment.",
    "Working outside means watching out for harsh weather! Be mindful of extreme temperatures and in this current winter weather, keep your skin protected. ",
    "Long periods of time without proper breaks can lead to a decrease in focus or alertness.  This can possibly also cause a loss in productivity; make sure you are taking regular breaks within your work day.",
    "Talk to your manager if workplace stress is hindering your focus.  Workplace stress can include issues such as overly long hours, conflicts with coworkers and heavy workloads.  This often leads to distractions, rushing and lower productivity.",
    "Bad posture may not seem like a big problem but in the long run can have a huge effect on the employee as well as the company.  Make sure to practice good posture while sitting or lifting to avoid, sprains, strains, muscle tears or dislocations on your back or hips.",
    "Drugs or alcohol cause a huge difference in productivity and affect your motor skills and judgment.  This is a huge hazard for yourself and coworkers around you make sure to never take any drugs or alcohol prior to beginning your work day.",
    "It is important to always wear protective gear where it is required.  Personal protective equipment (PPE) includes items like hard hats, goggles, shoes and gloves that are vital to staying safe in certain work environments.  The reasons to wear this gear can vary from job to job; make sure to always wear everything that is necessary.",
    "Being aware of your surroundings means you may spot unsafe conditions in a work environment.  If you spot unsafe work areas make sure to make this known to a supervisor or corresponding personnel.  Marking items or machinery with appropriate tools can help bring possible hazards to attention."
  ];
  let randomIndex= Math.floor(Math.random()*(SAFETY_FACTS_ARRAY.length));
  
  return (
    <div className="overideErrorPage">
      <h1 className="header">Something went wrong</h1>
      <Grid className="imgDiv" item container lg={6}>
        <img src={SomethingWentWrong} alt="" />
      </Grid>
      <Grid className="iconGrid">
        <QuestionMarkIcon />
      </Grid>
      <Grid container item lg={3} className="factsGrid">
        <h1>Sequence Safety facts..</h1>
        <h2>
          {SAFETY_FACTS_ARRAY[randomIndex]}
        </h2>
      </Grid>
      {/* <Buttons label="Back/> */}
    </div>
  );
};

export default ErrorPage;
