import React from "react";
import Lms from "../component/lms";

const LmsContainer = () => {
  return (
    <>
      <Lms />
    </>
  );
};

export default LmsContainer;
