import { loginTypes } from "../constants/loginTypes";

export const login = (userName, password) => ({
  type: loginTypes.POST_LOGIN_PENDING,
  userName,
  password,
});

export const loginSuccess = (data) => ({
  type: loginTypes.POST_LOGIN_SUCCESS,
  data,
});

export const redirectUrlRequest = (pathName) => ({
  type: loginTypes.POST_REDIRECT_URL_PENDING,
  pathName,
});

export const setRiderctUrls = (pathName) => ({
  type: loginTypes.POST_REDIRECT_URL_PENDINGSUCCESS,
  pathName,
});

export const loginFailure = (error) => ({
  type: loginTypes.POST_LOGIN_FAILURE,
  error,
});

export const clearErrors = (error = {}) => ({
  type: loginTypes.CLEAR_ERRORS,
  error,
});

// set current user
export const setCurrentUserAction = (payload) => ({
  type: loginTypes.SET_CURRENT_USER,
  ...payload,
});

// logout user
export const logoOutUser = () => ({
  type: loginTypes.lOGIN_LOGOUT,
});
//

// push notification
export const postNotifiactionLogin = (data) => ({
  type: loginTypes.POST_NOTIFICATION_LOGIN_PENDING,
  data,
});

export const postNotifiactionLoginSuccess = (data) => ({
  type: loginTypes.POST_NOTIFICATION_LOGIN_SUCCESS,
  data,
});

export const postNotifiactionLoginFailure = (error) => ({
  type: loginTypes.POST_NOTIFICATION_LOGIN_FAILURE,
  error,
});

export const postNotifiactionLogout = (data) => ({
  type: loginTypes.POST_NOTIFICATION_LOGOUT_PENDING,
  data,
});

export const postNotifiactionLogoutSuccess = (data) => ({
  type: loginTypes.POST_NOTIFICATION_LOGOUT_SUCCESS,
  data,
});

export const postNotifiactionLogoutFailure = (error) => ({
  type: loginTypes.POST_NOTIFICATION_LOGOUT_FAILURE,
  error,
});

export const getNotifiactionList = (data) => ({
  type: loginTypes.GET_NOTIFICATION_LIST_PENDING,
  data,
});

export const getNotifiactionListSuccess = (data) => ({
  type: loginTypes.GET_NOTIFICATION_LIST_SUCCESS,
  data,
});

export const getNotifiactionListFailure = (error) => ({
  type: loginTypes.GET_NOTIFICATION_LIST_FAILURE,
  error,
});

export const updateNotifiactionList = (data) => ({
  type: loginTypes.POST_UPDATE_NOTIFICATION_LIST_PENDING,
  data,
});

export const updateNotifiactionListSuccess = (data) => ({
  type: loginTypes.POST_UPDATE_NOTIFICATION_LIST_SUCCESS,
  data,
});

export const updateNotifiactionListFailure = (error) => ({
  type: loginTypes.POST_UPDATE_NOTIFICATION_LIST_FAILURE,
  error,
});
