import React from "react";
import ReprimandList from "../component/reprimand/reprimandList";

const ReprimandListContiner = () => {
  return (
    <>
      <ReprimandList filterShow={true} />
    </>
  );
};

export default ReprimandListContiner;
