import * as personalInjuryIncidentReportAction from "../actions/personalInjuryIncidentReportAction";
import { personalInjuryIncidentReportType } from "../constants/personalInjuryIncidentReportType";
import {
  getPersonalInjuryIncidentReportCount,
  getPersonalInjuryIncidentReportList,
} from "../../services/personalInjuryIncidentReportService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* getPersonalInjuryIncidentReportCountSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(
      getPersonalInjuryIncidentReportCount,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const personalInjuryIncidentReportCountPage =
      personalInjuryIncidentReportType.PERSONAL_INJURY_INCIDENT_REPORT_COUNT_PAGE;
    yield put(
      personalInjuryIncidentReportAction.getPersonallInjuryIncidentReportCountSuccess(
        {
          personalInjuryIncidentReportCountPage,
          data,
        }
      )
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "PersonallInjury Incident Report Count Failed" };
    yield put(
      personalInjuryIncidentReportAction.getPersonallInjuryIncidentReportCountFailure(
        data
      )
    );
  }
}

export function* getPersonalInjuryIncidentReportListSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(
      getPersonalInjuryIncidentReportList,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const personalInjuryIncidentReportListPage =
      personalInjuryIncidentReportType.PERSONAL_INJURY_INCIDENT_REPORT_LIST_PAGE;
    yield put(
      personalInjuryIncidentReportAction.getPersonallInjuryIncidentReportListSuccess(
        {
          personalInjuryIncidentReportListPage,
          data,
        }
      )
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Personall Injury Incident Report List Failed" };
    yield put(
      personalInjuryIncidentReportAction.getPersonallInjuryIncidentReportListFailure(
        data
      )
    );
  }
}

const myIncidentReportSaga = [
  takeLatest(
    personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT_PENDING,
    getPersonalInjuryIncidentReportCountSaga
  ),
  takeLatest(
    personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST_PENDING,
    getPersonalInjuryIncidentReportListSaga
  ),
];

export default myIncidentReportSaga;
