import { lmsReportType } from "../constants/lmsReportType";

export const getLmsReport = (data) => ({
  type: lmsReportType.GET_LMS_REPORT_PENDING,
  data,
});

export const lmsReportSuccess = (data) => ({
  type: lmsReportType.GET_LMS_REPORT_SUCCESS,
  data,
});

export const lmsReportFailure = (error) => ({
  type: lmsReportType.GET_LMS_REPORT_FAILURE,
  error,
});

export const getLmsReportCourse = (data) => ({
  type: lmsReportType.GET_LMS_REPORT_COURSE_PENDING,
  data,
});

export const getLmsReportCourseSuccess = (data) => ({
  type: lmsReportType.GET_LMS_REPORT_COURSE_SUCCESS,
  data,
});

export const getLmsReportCourseFailure = (error) => ({
  type: lmsReportType.GET_LMS_REPORT_COURSE_FAILURE,
  error,
});

export const getLmsReportLesson = (data) => ({
  type: lmsReportType.GET_LMS_REPORT_LESSON_PENDING,
  data,
});

export const getLmsReportLessonSuccess = (data) => ({
  type: lmsReportType.GET_LMS_REPORT_LESSON_SUCCESS,
  data,
});

export const getLmsReportLessonFailure = (error) => ({
  type: lmsReportType.GET_LMS_REPORT_LESSON_FAILURE,
  error,
});
