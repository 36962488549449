import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ViewImages from "../viewImages/viewImages";
import parse from "html-react-parser";

const StolenMultipleProperty = ({ additionalData, otherField }) => {
  const imageSize = {
    size: "small",
  };

  return (
    <div className="commonOveride">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">Property details:</Typography>
            <div className="additionalListBox">
              <Box className="columns">
                <Typography className="listView">
                  Employee's Name:
                  <span className="font-w400">
                    {additionalData?.employeeName}
                  </span>
                </Typography>
                <Typography className="listView">
                  Project :
                  <span className="font-w400">{additionalData?.project}</span>
                </Typography>
              </Box>
              {additionalData &&
                additionalData?.stolenProperty?.map((item, i) => {
                  const image = [item?.files[0]?.value];
                  return (
                    <>
                      <div className="additionalList" key={i}>
                        <div className="subtitle">
                          Property details
                          {i + 1}
                        </div>

                        <Typography className="listView">
                          Description of Property:
                          <span className="font-w400 blockView overRideTextEditor">
                            {parse(unescape(item?.description))}
                          </span>
                        </Typography>

                        <Box className="columns">
                          <Typography className="listView">
                            Estimated Cost of Property:
                            <span className="font-w400">
                              {item?.estimatedCost}
                            </span>
                          </Typography>
                          <Typography className="listView">
                            Date of discovery:
                            <span className="font-w400">
                              {item?.dateOfDiscovery}
                            </span>
                          </Typography>
                        </Box>

                        <Typography className="listView columns">
                          <div className="columnsf">
                            Name of last known Person(s) in Custody of the
                            Stolen/Lost Property
                          </div>

                          <div className="columnsl font-w400">
                            {item?.lastKnownPerson}
                          </div>
                        </Typography>

                        <Typography className="listView columns">
                          <div className="columnsf">
                            Was the Property Stolen/Lost from a Locked or
                            Secured Location?
                          </div>

                          {item?.isPropertyStolen === true ? (
                            <div className="columnsl yesbtn">Yes</div>
                          ) : item?.isPropertyStolen === false ? (
                            <div className="columnsl nobtn">No</div>
                          ) : (
                            ""
                          )}
                        </Typography>
                        <Typography className="listView columns">
                          <div className="columnsf">
                            Has a Police Report Been Filed?
                          </div>

                          {item?.isPoliceReportFiled === true ? (
                            <div className="columnsl yesbtn">Yes</div>
                          ) : item?.isPoliceReportFiled === false ? (
                            <div className="columnsl nobtn">No</div>
                          ) : (
                            ""
                          )}
                        </Typography>
                        <Typography className="listView">
                          Last Know Location of Property/possession:
                          <span className="font-w400 overRideTextEditor">
                            {parse(unescape(additionalData?.propertyLocation))}
                          </span>
                        </Typography>

                        <Typography className="listView desNone">
                          Photo:
                          <ViewImages image={image} imageSize={imageSize} />
                        </Typography>
                      </div>
                    </>
                  );
                })}
            </div>
          </Box>
          <Box className="commoninfoRight"></Box>
        </Box>
      </Box>
    </div>
  );
};

export default StolenMultipleProperty;
