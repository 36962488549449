import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import { GET_ITEM_LOG, PAGE_SIZE_100 } from "../../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { REPORTS_ANALYTICS_DASHBOARD_ITEMS } from "../../../../constant/routeContant";
import {
  downloadExcel,
  downloadPDF,
  getAssetTypeValue,
  useInfiniteScroll,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";

const AllProject = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (props?.allAsset) {
      setData((prevData) => [...prevData, ...props.allAsset]);
    }
  }, [props?.allAsset]);

  const fetchMoreData = async (pageNumber) => {
    const { apiData, reportDetails } = props.drillDetail;
    const data = {
      assetType: apiData.assetType,
      customAssetType: apiData.customAssetType,
      startDate: apiData.startDate,
      endDate: apiData.endDate,
      divisionId: apiData.divisionId,
      assetId: apiData.assetId,
      assetCatId: apiData?.assetCatId,
      reportDetails: reportDetails,
      page: pageNumber,
      pageSize: PAGE_SIZE_100,
    };
    try {
      const response = await props.getInspectionReportAssetDetailsData(data);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    const moreData = await fetchMoreData(pageNumber);
    setPage(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };


  const handleOnClick = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
      state: {
        assetId: id,
        drillDetail: props.drillDetail,
        itemLog: GET_ITEM_LOG.Corrective,
        itemTypeValue: "Corrective",
      },
    });
  };

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  useEffect(() => {
    setData([]);
  }, []);

  const assetType = getAssetTypeValue(props.drillDetail?.apiData?.assetType);
  const columns = [
    {
      id: "assetId",
      label: `${assetType} ID`,
      sortable: true,
      isSorted: sortColumn === "assetId",
      sortDirection,
    },
    {
      id: "assetName",
      label: `${assetType} Name`,
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },
    {
      id: "count",
      label: "Number of Corrective Items",
      sortable: true,
      isSorted: sortColumn === "count",
      sortDirection,
    },
  ];

  const rows = data?.map((asset,index) => (
    <tr className="row" key={index}>
      <td>{asset.assetId}</td>
      <td>{asset.assetName}</td>
      <td>
        <span className="link" onClick={() => handleOnClick(asset?.assetId)}>
          {asset.count}
        </span>
      </td>
    </tr>
  ));

  const rowsPdf = data.map((asset) => [
    asset.assetId,
    asset.assetName,
    asset.count,
  ]);

  return (
    <>
      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">
            All {getAssetTypeValue(props.drillDetail?.apiData?.assetType)}{" "}
            (Corrective)
          </div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadPDF(
                        rowsPdf,
                        columns.map((col) => col.label),
                        "All-Project.pdf"
                      )
                    }
                  >
                    <img src={commonImages.pdfFill} alt="" />
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() => downloadExcel(data, "All-Project.xlsx")}
                  >
                    <img src={commonImages.excelDownload} alt="" />
                    <span>Download .xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="customTableWrapper">
        <Table
          columns={columns}
          rows={rows}
          tableRef={tableRef}
          onSort={handleSort}
        />
      </div>
    </>
  );
};

export default AllProject;
