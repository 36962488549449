import { all } from "redux-saga/effects";
import caseSaga from "./caseSaga";
import incidentSaga from "./incidentSaga";
import loginSaga from "./loginSaga";
import nearMissesSaga from "./nearMissesSaga";
import personalInjurySaga from "./personalInjurySaga";
import signupSaga from "./signupSaga";
import stateCitySaga from "./stateCitySaga";
import stolenPropertySaga from "./stolenPropertySaga";
import myFileUploadSaga from "./uploadFilesSaga";
import userSaga from "./userSaga";
import utilityDamageSaga from "./utilityDamageSaga";
import vehicalAccidentSaga from "./vehicalAccidentSaga";
import inspectionSaga from "./incpectionSaga";
import observationSaga from "./observationSaga";
import reprimandSaga from "./reprimandSaga";
import supportSaga from "./supportSaga";
import coursesSaga from "./coursesSaga";
import lessonsSaga from "./lessonsSaga";
import contactsSaga from "./contactsSaga";
import resourceSaga from "./resourceSaga";
import equipmentsSaga from "./equipmentsSaga";
import vehiclesSaga from "./vehiclesSaga";
import assetsSaga from "./assetsSaga";
import lessonUserSaga from "./lessonsUserSaga";
import coursesUserSaga from "./coursesUserSaga";
import getUserCourseViewSaga from "./userCourseViewSaga";
import getUserLessonViewSage from "./userLessonViewSaga";
import quizSaga from "./quizSaga";
import credentialSaga from "./credentialSaga";
import myAdminSaga from "./adminCommonSaga";
import divisionsSaga from "./divisionsSaga";
import projectSaga from "./projectSaga";
import myRoleSaga from "./roleSaga";
import assignSaga from "./assignSaga";
import mySafetyLogSaga from "./safetyLogSaga";
import contractorSaga from "./contractorSaga";
import utilityCategorySaga from "./utilityCategorySaga";
import myClientSaga from "./clientSaga";
import myCredentialReportSaga from "./reportSaga";

import toolBoxTalkSaga from "./toolBoxTalkSaga";
import toolBoxTalkAssignSaga from "./toolBoxTalkAssignSaga";
import myReportSaga from "./lmsReportSaga";
import myIncidentReportSaga from "./personalInjuryIncidentReportSaga";
import myDashboardSaga from "./dashboardSaga";
import mySwInspectionSaga from "../storm-water/saga/swInspectionSaga";
import mySiteSaga from "../storm-water/saga/siteSaga";
import myProjectSaga from "../storm-water/saga/projectSaga";
import myJhaSaga from "./jhaSaga";
import mySwCommonSaga from "../storm-water/saga/swCommonSaga";
import myPermitSaga from "../storm-water/saga/permitSaga";
import myProjectSampleSaga from "../storm-water/saga/swprojectSampleSaga";
import newsSaga from "./newsSaga";
import myReportsSaga from "../storm-water/saga/reportsSaga";
export default function* rootSaga() {
  yield all([
    ...loginSaga,
    ...signupSaga,
    ...incidentSaga,
    ...caseSaga,
    ...personalInjurySaga,
    ...userSaga,
    ...vehicalAccidentSaga,
    ...utilityDamageSaga,
    ...stateCitySaga,
    ...stolenPropertySaga,
    ...nearMissesSaga,
    ...myFileUploadSaga,
    ...inspectionSaga,
    ...observationSaga,
    ...reprimandSaga,
    ...supportSaga,
    ...coursesSaga,
    ...lessonsSaga,
    ...contactsSaga,
    ...resourceSaga,
    ...equipmentsSaga,
    ...vehiclesSaga,
    ...assetsSaga,
    ...lessonUserSaga,
    ...coursesUserSaga,
    ...getUserCourseViewSaga,
    ...getUserLessonViewSage,
    ...quizSaga,
    ...credentialSaga,
    ...myAdminSaga,
    ...divisionsSaga,
    ...projectSaga,
    ...myRoleSaga,
    ...assignSaga,
    ...mySafetyLogSaga,
    ...contractorSaga,
    ...utilityCategorySaga,
    ...myClientSaga,
    ...myCredentialReportSaga,
    ...toolBoxTalkSaga,
    ...toolBoxTalkAssignSaga,
    ...myReportSaga,
    ...myIncidentReportSaga,
    ...myDashboardSaga,
    ...mySwInspectionSaga,
    ...mySiteSaga,
    ...myProjectSaga,
    ...myJhaSaga,
    ...mySwCommonSaga,
    ...myPermitSaga,
    ...myProjectSampleSaga,
    ...newsSaga,
    ...myReportsSaga,
  ]);
}
