import { Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import ToolBar from "../../../../library/storm-water/toolBar";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import Buttons from "../../../../library/custom/button/Button";
import Button from "../../../../library/custom/button/Button";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { getCurrentDate, getFormatedDate } from "../../../../utils/helper";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import TextArea from "../../../../library/custom/textArea/TextArea";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { useState } from "react";
import {
  HBNEXT_ORG_ID,
  ISwitch,
  ORG_ID,
  ORG_NAME,
  USER_ID,
} from "../../../../constant/constant";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { WEEKDAYS } from "../../../../constant/storm-water/constant";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_ADD_STORM_WATERPROJECT,
  ADMIN_RESOURCE,
  INSPECTION_AUTHORITY,
  SETUP_INSPECTION_LIST,
  SW_ADMIN_SAMPLINGLOG,
  SW_CONTACT_PROJECT,
  SW_INSPECTION_LIST,
  SW_OPEN_ITEMS,
  SW_PERMIT_LIST,
  SW_RAIN_FALL_DATA,
} from "../../../../constant/routeContant";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const SetupInspection = ({
  onSubmit,
  getSwReference,
  editMode,
  getInspectionQuestionSetListData,
  inspectionQuestionSetList,
  handleAllUserSearch,
  isLoading,
  allUsers,
  allProjects,
  isProjectLoading,
  handleAllProjectSearch,
  mapId,
  editDetail,
  onUpdateSubmit,
  setupData,
  customer,
  projectCategorycheck,
  setProjectCategorycheck,
  isSubmit,
  setIsSubmit,
  selectProjectCategoryError,
  autoSearchDataLoading,
  getSwReferenceData,
  searchClient,
  searchAllClientsReducerData,
  setcustomerID,
  setcustomer,
  customerId,
  addSetup = false,
}) => {
  const navigate = useNavigate();
  const intialState = {
    note: "",
  };
  const [isProject, setIsProject] = useState(false);
  const [isInspectionType, setIsInspectionType] = useState(false);
  const [isPrimaryInspector, setIsPrimaryInspector] = useState(false);
  const [isInspectionForm, setIsInspectionForm] = useState(false);
  const [isInspectionStartDate, setIsInspectionStartDate] = useState(false);
  const [isDayOfInspection, setIsDayOfInspection] = useState(false);
  const [isDaysOfInspection, setIsDaysOfInspection] = useState(false);
  const [frequencyValue, setFrequencyValue] = useState();
  const [isFrequency, setIsFrequency] = useState(false);
  const [formValue, setFormValue] = useState(intialState);
  const [inspectionTypeValue, setInspectionTypeValue] = useState();
  const [primaryInspectorValue, setPrimaryInspectorValue] = useState();
  const [inspectionFormValue, setInspectionFormValue] = useState();
  const [inspectionStartDate, setInspectionStartDate] = useState(null);
  const [checked, setChecked] = useState(true);
  const [dayOfInspection, setDayOfInspection] = useState(null);
  const [individualUsersIds, setIndividualUsersIds] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [users, setUsers] = useState("");
  const [projects, setProjects] = useState("");
  const [primry, setPrimry] = useState([]);
  const [stateId, setStateId] = useState();
  const [days, setDay] = useState([]);
  const [selectedd, setselectedd] = useState([]);
  const [externalFormUrl, setExternalFormUrl] = useState("");
  const [islinkUrls, setIslinkUrls] = useState(false);
  const [islinkUrlsError, setLinkUrlsError] = useState(false);
  const autoComp = useRef(null);
  const [isGeorgiaState, setIsGeorgiaState] = useState(false);
  const [inspectionQuestionSetLists, setInspectionQuestionSetLists] = useState(
    []
  );
  const [insTypeList, setInsTypeList] = useState(null);
  const [projectCategoryError, setProjectCategoryError] = useState(false);

  useEffect(() => {
    if (getSwReference) {
      const newInsTypeList = getSwReference?.insTypeMstList?.filter(
        (item) => item.key !== 3
      );
      setInsTypeList(newInsTypeList);
    }
  }, [getSwReference]);

  useEffect(() => {
    if (inspectionQuestionSetList?.result) {
      setInspectionQuestionSetLists(inspectionQuestionSetList?.result);
      setIsGeorgiaState(inspectionQuestionSetList?.isGeorgiaState);
    }
  }, [inspectionQuestionSetList]);
  const [isErrorSamePrimary, setIsErrorSamePrimary] = useState(false);
  const addIndiVidualUserData = () => {
    const primaryInspector =
      parseInt(users?.key) === parseInt(primaryInspectorValue?.key);
    if (primaryInspector) {
      setIsErrorSamePrimary(true);
    } else {
      setIsErrorSamePrimary(false);
      const index = userList?.findIndex(
        (obect) => parseInt(obect.key) === parseInt(users.key)
      );
      if (index === -1 && users) {
        setUsers("");
        users.isPrimary = false;
        users.inspectorId = parseInt(users?.key);
        setUsersList([...userList, users]);
        setIndividualUsersIds([...individualUsersIds, users.key]);

        const ele = autoComp.current.getElementsByClassName(
          "MuiAutocomplete-clearIndicator"
        )[0];
        if (ele) ele.click();
      }
    }
  };
  const onChangeDay = (event) => {
    const {
      target: { value },
    } = event;

    if (value?.length === 1 && days.includes(value[0])) {
      setDay(days.filter((item) => item === value[0]));
    } else if (days.length < 2) {
      setDay(typeof value === "string" ? value.split(",") : value);
    }
  };

  const deleteUsers = (row) => {
    const updateList = userList.filter((list) => list.key !== row);
    setUsersList(updateList);
    setIndividualUsersIds(updateList?.map((ids) => ids.key));
  };

  const onChangeUsers = (value) => {
    setUsers(value);
  };

  const onChangeProjects = (value) => {
    setInspectionFormValue();
    setProjects(value);
    const data = {
      projectId: value?.key,
      orgId: clients
        ? clients
        : setupData?.customerId
        ? setupData?.customerId
        : ORG_ID(),
    };
    getInspectionQuestionSetListData(data);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleFrequencyChange = (e) => {
    setFrequencyValue(e.target.value);
    setInspectionFormValue(null);
  };
  const onchangeInspectionStartDatePicker = (e) => {
    setInspectionStartDate(e);
  };
  const handleInspectionTypeSetChange = (e) => {
    setInspectionTypeValue(e.target.value);
  };
  const [isErrorSameSecondary, setIsErrorSameSecondary] = useState(false);
  const handlePrimaryInspectorSetChange = (value) => {
    const exists = userList?.some(
      (item) => parseInt(item.key) === parseInt(value?.key)
    );
    if (exists) {
      setIsErrorSameSecondary(true);
    } else {
      setIsErrorSameSecondary(false);
      setPrimaryInspectorValue(value);
      setPrimry([
        {
          inspectorId: parseInt(value?.key),
          isPrimary: true,
        },
      ]);
    }
  };
  const handleInspectionFormSetChange = (e) => {
    setInspectionFormValue(e.target.value);
  };

  const handleDayOfInspectionSetChange = (e) => {
    setDayOfInspection(e.target.value);
  };
  const [checkBoxSelect, setCheckBoxSelect] = useState();
  const dataBack = {
    title: "Back to Inspection Service List",
    route: SETUP_INSPECTION_LIST,
  };

  function findValueMultipleSelect(arrrayofObject, selectedArray) {
    let valueArray = [];
    arrrayofObject.forEach((data) => {
      if (selectedArray.indexOf(data.value) >= 0) {
        valueArray.push(data.key);
      }
    });
    return valueArray;
  }
  function mappedData() {
    const selecteddays =
      frequencyValue === 5 && findValueMultipleSelect(WEEKDAYS, days);

    let errors = false;
    if (!projects) {
      setIsProject(true);
      errors = true;
    }
    if (!inspectionTypeValue) {
      setIsInspectionType(true);
      errors = true;
    }
    if (!primaryInspectorValue) {
      setIsPrimaryInspector(true);
      errors = true;
    }
    if (!isGeorgiaState && parseInt(projectCategorycheck) === 1) {
      if (!inspectionFormValue) {
        setIsInspectionForm(true);
        errors = true;
      }
    }
    if (isGeorgiaState && frequencyValue === 1) {
      setIsInspectionForm(false);
      errors = false;
    }
    if ((inspectionTypeValue === 1 || 2) && inspectionStartDate === null) {
      setIsInspectionStartDate(true);
      errors = true;
    }
    if (frequencyValue === 2) {
      if (inspectionTypeValue === 1 && dayOfInspection === null) {
        setIsDayOfInspection(true);
        errors = true;
      }
    }
    if (frequencyValue === 5) {
      if (inspectionTypeValue === 1 && days?.length !== 2) {
        setIsDaysOfInspection(true);
        errors = true;
      }
    }

    if (!projectCategorycheck) {
      setProjectCategoryError(true);
      errors = true;
    }

    if (parseInt(projectCategorycheck) === 2 && externalFormUrl) {
      //  if (externalFormUrl || externalFormUrl === "") {
      const res = externalFormUrl?.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (res === null) {
        setIslinkUrls(true);
        errors = true;
      } else {
        setIslinkUrls(false);
      }
    }
    //  }
    if (
      externalFormUrl === "" &&
      (parseInt(projectCategorycheck) === 2 || !projectCategorycheck)
    ) {
      setLinkUrlsError(true);
      errors = true;
    }

    if (!frequencyValue && inspectionTypeValue === 1) {
      setIsFrequency(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const data = {
      mapId: mapId ? mapId : 0,
      projectId: parseInt(projects?.key),
      questionSetId: parseInt(inspectionFormValue),
      inspectionType: parseInt(inspectionTypeValue),
      isExternal: parseInt(projectCategorycheck) === 1 ? false : true,
      externalFormUrl: externalFormUrl,
      inspectionStartDate:
        inspectionStartDate && getFormatedDate(inspectionStartDate),
      dayOfInspection:
        selecteddays[0] === 7
          ? 0
          : selecteddays[0]
          ? selecteddays[0]
          : dayOfInspection === 7
          ? 0
          : dayOfInspection === null
          ? null
          : dayOfInspection,
      dayOfInspection2: selecteddays[1] === 7 ? 0 : selecteddays[1],
      frequencyId: frequencyValue,
      note: formValue?.note,
      status: checked,
      isSameDayIns: checkBoxSelect,
      userId: parseInt(USER_ID()),
      listQsInspectorMap: [...primry, ...userList],
      orgId: setupData?.customerId
        ? setupData?.customerId
        : clients
        ? clients
        : ORG_ID(),
    };
    return data;
  }
  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      if (data?.mapId === 0) {
        onSubmit(data);
      } else {
        onUpdateSubmit(data);
      }
    }
  };

  useEffect(() => {
    if (editMode && editDetail) {
      setStateId(editDetail?.stateId);
      setInspectionStartDate(editDetail?.inspectionStartDate);
      setDayOfInspection(
        editDetail?.dayOfInspection === 0 ? 7 : editDetail?.dayOfInspection
      );
      setInspectionFormValue(editDetail?.questionSetId);
      setInspectionTypeValue(editDetail?.inspectionType);
      setCheckBoxSelect(editDetail?.isSameDayIns);
      setFrequencyValue(editDetail?.frequencyId);
      setProjectCategorycheck(editDetail?.isExternal ? "2" : "1");
      setExternalFormUrl(editDetail?.externalFormUrl);
      setDay([
        editDetail?.dayOfInspection === 1
          ? "Monday"
          : editDetail?.dayOfInspection === 2
          ? "Tuesday"
          : editDetail?.dayOfInspection === 3
          ? "Wednesday"
          : editDetail?.dayOfInspection === 4
          ? "Thursday"
          : editDetail?.dayOfInspection === 5
          ? "Friday"
          : editDetail?.dayOfInspection === 6
          ? "Saturday"
          : editDetail?.dayOfInspection === 0
          ? "Sunday"
          : "",
        editDetail?.dayOfInspection2 === 1
          ? "Monday"
          : editDetail?.dayOfInspection2 === 2
          ? "Tuesday"
          : editDetail?.dayOfInspection2 === 3
          ? "Wednesday"
          : editDetail?.dayOfInspection2 === 4
          ? "Thursday"
          : editDetail?.dayOfInspection2 === 5
          ? "Friday"
          : editDetail?.dayOfInspection2 === 6
          ? "Saturday"
          : editDetail?.dayOfInspection2 === 0
          ? "Sunday"
          : "",
      ]);
      setselectedd([
        editDetail?.dayOfInspection2 && editDetail?.dayOfInspection,
        editDetail?.dayOfInspection2,
      ]);
      setFormValue({
        ...formValue,
        note: editDetail?.note,
      });
      const filteredPrimaryData = editDetail?.inspectorDetails?.filter(
        (item) => item.isPrimary === true
      );
      const filteredSecondaryData = editDetail?.inspectorDetails?.filter(
        (item) => item.isPrimary === false
      );

      const updatedFilteredSecondaryData = filteredSecondaryData.map(
        (item) => ({
          ...item,
          inspectorId: item?.key,
        })
      );

      setIndividualUsersIds(filteredSecondaryData?.map((data) => data.key));
      setUsersList(updatedFilteredSecondaryData);

      setPrimaryInspectorValue({
        key: filteredPrimaryData[0]?.key,
        value: filteredPrimaryData[0]?.value,
      });
      setProjects({
        key: editDetail?.projectId,
        value: editDetail?.projectName,
      });
      setPrimry([
        {
          inspectorId: parseInt(filteredPrimaryData[0]?.key),
          isPrimary: true,
        },
      ]);
      setChecked(editDetail?.status);
      const data = {
        projectId: editDetail?.projectId,
        orgId: setupData?.customerId ? setupData?.customerId : ORG_ID(),
      };
      setchangesearchauto({ key: customerId ?? "", value: customer ?? "" });
      getInspectionQuestionSetListData(data);
    }
  }, [editMode, editDetail]);
  useEffect(() => {
    setchangesearchauto({ key: customerId ?? "", value: customer ?? "" });
  }, [customerId]);

  useEffect(() => {
    if (!projects) {
      setInspectionQuestionSetLists([]);
    }
  }, [projects]);

  const toolBarData = {
    title: "Inspection Tools",
    list: [
      {
        id: 9,
        title: "Edit Project",
        link: "",
        isshow: checkPrivileges([12, 111, 112, 113]),
      },
      {
        id: 1,
        title: "Project Contact",
        link: "",
        isshow: checkPrivileges([12, 132, 133, 134]),
      },
      {
        id: 2,
        title: "Inspection Service List",
        link: "",
        isshow: checkPrivileges([12, 99, 100, 101]),
      },
      {
        id: 11,
        title: "Setup Inspections",
        link: "",
        activeClass: "active",
        isshow: checkPrivileges([12, 99, 100]),
      },
      {
        id: 3,
        title: "Permits",
        link: "",
        isshow: checkPrivileges([12, 126, 127, 128]),
      },
      {
        id: 4,
        title: "Sampling Log",
        link: "",
        isshow: checkPrivileges([12, 129, 130, 131]),
      },
      {
        id: 5,
        title: "Open Items",
        link: "",
        isshow: checkPrivileges([12, 105, 106, 107]),
      },
      {
        id: 6,
        title: "Rainfall Data",
        link: "",
      },
      {
        id: 7,
        title: "Inspection Records",
        link: "",
        isshow: checkPrivileges([12, 102, 103, 104]),
      },

      {
        id: 8,
        title: "Inspection Authority",
        link: "",
        isshow: checkPrivileges([12, 96, 97, 98]),
      },
      {
        id: 12,
        title: "Project Resources",
        link: "",
        isshow: checkPrivileges([12, 73, 74, 75]),
      },
    ],
  };

  useEffect(() => {
    if (frequencyValue === 2) {
      setDay([]);
      setselectedd([]);
    }
  }, [frequencyValue]);

  useEffect(() => {
    if (setupData?.projectId) {
      setProjects({
        key: setupData?.projectId,
        value: setupData?.projectName,
      });

      const data = {
        projectId: setupData?.projectId,
        orgId: setupData?.customerId ? setupData?.customerId : ORG_ID(),
      };
      getInspectionQuestionSetListData(data);
    }
  }, [setupData]);

  const projectCheck = editMode || (setupData && projects);
  const customerName = customer ?? ORG_NAME();

  const handleProjectCatebtn = (e, value) => {
    setProjectCategorycheck(e.target.value);
  };

  useEffect(() => {
    if (projectCategorycheck) {
      // setProjects("");
    }
  }, [projectCategorycheck]);

  useEffect(() => {
    if (!editMode && getSwReference?.PlatformList?.length === 1) {
      setProjectCategorycheck(getSwReference?.PlatformList[0]?.key);
    }
  }, [!editMode, getSwReference]);

  const onChangeExternalFormUrl = (event) => {
    setLinkUrlsError(false);
    setIslinkUrls(false);
    setExternalFormUrl(event.target.value);
  };

  const [changesearchauto, setchangesearchauto] = useState({
    key: "",
    value: "",
  });
  const [clients, setClients] = useState();

  const typeOnchange = (event) => {
    searchClient(event.target.value.trim());
  };
  const onSelectSearchCustomer = (e, value, s) => {
    setchangesearchauto({ key: value?.key ?? "", value: value?.value ?? "" });
    setProjects("");
    setInspectionFormValue();
    setClients(value?.key);
    setcustomerID(value?.key);
    setcustomer(value?.value);
    // setcustomer(value?.value);
    const flagData = {
      flags: [3, 4, 10],
      orgId: value?.key,
    };
    getSwReferenceData(flagData);
  };
  return (
    <>
      <section className="grid_main_sec sw">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">
            {editMode ? "Edit Setup Inspection" : "Setup Inspection"}
          </div>
        </div>

        <Grid container>
          <Grid lg={8} md={12} xs={12} pr={2} item>
            {parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) &&
              checkPrivileges([12]) && (
                <Grid container item lg={6} xs={6} sm={6} md={6} mb={2}>
                  <FormControl fullWidth>
                    <FormLabels label="Client" isRequired={false} />
                    <SearchAutoComplete
                      name="Customer"
                      optionsData={searchAllClientsReducerData?.data ?? []}
                      typeOnchange={(event, value) => typeOnchange(event)}
                      isKeyValuePair={true}
                      value={changesearchauto ? changesearchauto : ""}
                      onChange={(event, value, key) =>
                        onSelectSearchCustomer(event, value, key)
                      }
                      isLoading={autoSearchDataLoading}
                      noOptionsTextMsg="No Client"
                      disabled={editMode || (customerId && !addSetup)}
                    />
                  </FormControl>
                </Grid>
              )}

            {getSwReference?.PlatformList?.length >= 2 && (
              <Grid
                item
                container
                mt={1}
                spacing={2}
                className="swproject"
                mb={2}
              >
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels
                      label={"Inspection Platform"}
                      isRequired={true}
                    />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className="radioGroup"
                    >
                      <RadioButton
                        checked={
                          parseInt(projectCategorycheck) === 1 ? true : false
                        }
                        value="1"
                        label="Internal"
                        onChange={(e, value) => handleProjectCatebtn(e, value)}
                        disabled={
                          !editMode &&
                          getSwReference?.PlatformList?.some(
                            (item) => item.key === 1
                          )
                            ? false
                            : true
                        }
                      ></RadioButton>

                      <RadioButton
                        checked={
                          parseInt(projectCategorycheck) === 2 ? true : false
                        }
                        value="2"
                        label="External"
                        onChange={(e, value) => handleProjectCatebtn(e, value)}
                        disabled={
                          !editMode &&
                          getSwReference?.PlatformList?.some(
                            (item) => item.key === 2
                          )
                            ? false
                            : true
                        }
                      ></RadioButton>
                    </RadioGroup>
                    {projectCategoryError && !projectCategorycheck && (
                      <p className="error">Please select project category </p>
                    )}
                    {selectProjectCategoryError && (
                      <p className="error">
                        Please select Project Category first
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid lg={6} xs={12} sm={12} md={6} item>
                <FormControl fullWidth>
                  <FormLabels label="Project" isRequired={true} />
                  <SearchAutoComplete
                    name="Add Individual Users"
                    optionsData={allProjects ?? []}
                    typeOnchange={(event, value) =>
                      handleAllProjectSearch(event, value, clients)
                    }
                    isKeyValuePair={true}
                    value={projects ?? null}
                    onChange={(e, value) => onChangeProjects(value)}
                    isLoading={isProjectLoading}
                    autoCompClear={autoComp}
                    noOptionsTextMsg={"No Project"}
                    isError={isProject}
                    errorMsg={isProject ? "Project is Required" : ""}
                    disabled={editMode}
                  />
                </FormControl>
              </Grid>
              <Grid lg={6} xs={12} sm={12} md={6} item>
                <FormControl fullWidth>
                  <FormLabels label="Inspection Type" isRequired={true} />
                  <SelectMenu
                    placeholder="Please Select"
                    listData={insTypeList ?? []}
                    value={inspectionTypeValue ? inspectionTypeValue : null}
                    onchangehandler={(e) => handleInspectionTypeSetChange(e)}
                    isError={isInspectionType}
                    errorMsg={
                      isInspectionType ? "Inspection type is required" : ""
                    }
                    disabled={editMode}
                  />
                </FormControl>
              </Grid>
              <Grid lg={6} xs={12} sm={12} md={6} item>
                <FormControl fullWidth>
                  <FormLabels label="Primary Inspector" isRequired={true} />
                  <SearchAutoComplete
                    name="Add Individual Users"
                    optionsData={allUsers?.users ?? []}
                    typeOnchange={(event, value) =>
                      handleAllUserSearch(event, value)
                    }
                    isKeyValuePair={true}
                    value={primaryInspectorValue}
                    onChange={(e, value) =>
                      handlePrimaryInspectorSetChange(value)
                    }
                    isLoading={isLoading}
                    autoCompClear={autoComp}
                    noOptionsTextMsg={"No User"}
                    isError={isPrimaryInspector}
                    errorMsg={
                      isPrimaryInspector ? "Primary inspector is required" : ""
                    }
                  />
                </FormControl>

                {isErrorSameSecondary && (
                  <div className="error">
                    Secondary Inspector cannot be Primary Inspector
                  </div>
                )}
              </Grid>

              {inspectionTypeValue === 1 && (
                <Grid lg={6} xs={12} sm={6} md={6} item>
                  <FormControl fullWidth>
                    <FormLabels label="Frequency" isRequired={true} />
                    <SelectMenu
                      disabled={editMode}
                      placeholder="Please Select"
                      listData={getSwReference?.insfrequencyList ?? []}
                      value={frequencyValue ? frequencyValue : null}
                      onchangehandler={(e) => handleFrequencyChange(e)}
                      isError={isFrequency}
                      errorMsg={isFrequency ? "Frequency is required" : ""}
                    />
                  </FormControl>
                </Grid>
              )}

              {parseInt(projectCategorycheck) === 1 ? (
                <>
                  {frequencyValue === 1 && isGeorgiaState ? (
                    ""
                  ) : (
                    <Grid lg={6} xs={12} sm={12} md={6} item>
                      <FormControl fullWidth>
                        <FormLabels label="Inspection Form" isRequired={true} />
                        <SelectMenu
                          placeholder="Please Select"
                          listData={inspectionQuestionSetLists ?? []}
                          value={
                            inspectionFormValue ? inspectionFormValue : null
                          }
                          onchangehandler={(e) =>
                            handleInspectionFormSetChange(e)
                          }
                          isError={isInspectionForm}
                          errorMsg={
                            isInspectionForm
                              ? "Inspection form is required"
                              : ""
                          }
                          disabled={editMode}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid lg={6} xs={12} sm={12} md={6} item>
                  <FormControl fullWidth>
                    <InputField
                      isRequired={true}
                      type="text"
                      label="Inspection Form URL"
                      placeholder={""}
                      value={externalFormUrl}
                      // onChange={(e) => setExternalFormUrl(e?.target?.value)}
                      onChange={(e) => onChangeExternalFormUrl(e)}
                    />
                  </FormControl>
                  {islinkUrls && (
                    <div className="error">Please add proper link</div>
                  )}
                  {islinkUrlsError && (
                    <div className="error">Add Link is Required</div>
                  )}
                </Grid>
              )}

              {inspectionTypeValue === 1 && (
                <>
                  {" "}
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormLabels
                      label={
                        frequencyValue === 1 && isGeorgiaState
                          ? "Start Date Of Inspection"
                          : "Date Of Inspection"
                      }
                      isRequired={true}
                    />
                    <DatePicker
                      minDate={getCurrentDate()}
                      onChangeHandler={(e) =>
                        onchangeInspectionStartDatePicker(e)
                      }
                      disabled={editMode ? true : false}
                      value={inspectionStartDate ? inspectionStartDate : null}
                      maxDateEnable
                      isError={isInspectionStartDate}
                      errorMsg={
                        isInspectionStartDate ? "Start date is required" : ""
                      }
                    />
                  </Grid>
                  {(frequencyValue === 2 || frequencyValue === 6) && (
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels
                          label="Day Of Inspection"
                          isRequired={true}
                        />
                        <SelectMenu
                          placeholder="Please Select"
                          listData={WEEKDAYS ?? []}
                          value={dayOfInspection ? dayOfInspection : null}
                          onchangehandler={(e) =>
                            handleDayOfInspectionSetChange(e)
                          }
                          isError={isDayOfInspection}
                          errorMsg={
                            isDayOfInspection
                              ? "Day of inspection is required"
                              : ""
                          }
                          disabled={checkBoxSelect}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {frequencyValue === 5 && (
                    <Grid item lg={6} xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabels
                          label="Day Of Inspection"
                          isRequired={true}
                        />
                        <MultiSelectBox
                          placeholder={"Please Select"}
                          listData={WEEKDAYS}
                          onChange={(e) => onChangeDay(e)}
                          value={days || []}
                          editMode={editMode}
                          addclientpage={true}
                          disabledValue={selectedd}
                        />
                      </FormControl>
                      {isDaysOfInspection && days?.length !== 2 && (
                        <p className="error">Day of inspection is required</p>
                      )}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            {inspectionTypeValue === 2 && (
              <Grid item lg={6} xs={12} sm={12} md={6} mt={2}>
                <FormLabels label={"Start Date"} isRequired={true} />
                <DatePicker
                  minDate={getCurrentDate()}
                  onChangeHandler={(e) => onchangeInspectionStartDatePicker(e)}
                  disabled={
                    editMode && editDetail?.inspectionStartDate !== null
                      ? true
                      : false
                  }
                  value={inspectionStartDate ? inspectionStartDate : null}
                  maxDateEnable
                  isError={isInspectionStartDate}
                  errorMsg={
                    isInspectionStartDate && !inspectionStartDate
                      ? "Start date is required"
                      : ""
                  }
                />
              </Grid>
            )}
            <Grid container spacing={2} mt={2}>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <FormLabels label="Add Notes" />
                <TextArea
                  value={formValue.note}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      note: e.target.value,
                    })
                  }
                  placeholder="Enter Here"
                />
              </Grid>
            </Grid>

            {/* <Grid item container mt={0} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormLabels label="Secondary Inspectors" isRequired={false} />
                <SearchAutoComplete
                  name="Add Individual Users"
                  optionsData={allUsers?.users ?? []}
                  typeOnchange={(event, value) =>
                    handleAllUserSearch(event, value)
                  }
                  isKeyValuePair={true}
                  value={users}
                  onChange={(e, value) => onChangeUsers(value)}
                  isLoading={isLoading}
                  autoCompClear={autoComp}
                />
                {isErrorSamePrimary && users && (
                  <p className="error">
                    Primary Inspector cannot be Secondary Inspector
                  </p>
                )}
              </Grid>
              <Grid
                className="smallAddBtn"
                item
                lg={1.6}
                xs={12}
                sm={12}
                mt={3}
                md={4}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addIndiVidualUserData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid pl={2} mt={3} mb={1} container gap={1}>
                <UsersChip
                  userList={userList}
                  handleDelete={(e) => deleteUsers(e)}
                />
              </Grid>
            </Grid> */}

            {checkPrivileges([12, 99]) && (
              <Grid container spacing={2}>
                <Grid ml={1} lg={5} xs={12} sm={6} md={6} item>
                  <FormControl
                    component="fieldset"
                    variant="standard"
                    className="contactswitch"
                  >
                    <FormLabels label="Status" />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <ISwitch
                            checked={checked}
                            onChange={handleChange}
                            name="status"
                          />
                        }
                        label={checked ? "Active" : "InActive"}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Grid container mt={10}>
              <Grid item lg={6} md={12} xs={12}>
                <div className="insBackBtn">
                  <BackBtn dataBack={dataBack} />
                </div>
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Grid container item lg={12} xs={12} className="divisionBtnBox">
                  <Grid lg={6} xs={12} sm={6} item>
                    <Buttons
                      onClick={() => navigate(SETUP_INSPECTION_LIST)}
                      varientText={true}
                      label="Cancel"
                    />
                  </Grid>
                  <Grid lg={6} xs={12} sm={6} item>
                    <Button
                      varientContained={true}
                      label={editMode ? "Update" : "Save"}
                      disabled={isSubmit ? true : false}
                      onClick={submitData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={4} md={12} xs={12} pl={1} item>
            <div className="toolBarBox">
              <div className="otherDetail">
                <h3>Projects Details</h3>
                <h2>{projects && projects?.value}</h2>
                <h2>{setupData && setupData?.projectNumber}</h2>
              </div>
              <div className="otherDetail">
                <h3>Client Name</h3>
                <h2>{customerName}</h2>
              </div>

              <ToolBar
                toolBarData={
                  editMode || setupData?.rightSideMode ? toolBarData : []
                }
                handleClick={(e) => {
                  if (e.id === 1) {
                    navigate(SW_CONTACT_PROJECT, {
                      state: {
                        projectName: editMode
                          ? projects?.value
                          : setupData?.projectName,
                        projectNumber: setupData?.projectNumber,
                        projectId: editMode
                          ? projects?.key
                          : setupData?.projectId,
                        customerName: editMode
                          ? customerName
                          : setupData?.customerName,
                        customerId: setupData?.customerId,
                        stateId: editMode ? stateId : setupData?.stateId,
                      },
                    });
                  }
                  if (e.id === 2) {
                    navigate(SETUP_INSPECTION_LIST, {
                      state: {
                        projectName: editMode
                          ? projects?.value
                          : setupData?.projectName,
                        projectNumber: setupData?.projectNumber,
                        projectId: editMode
                          ? projects?.key
                          : setupData?.projectId,
                        customerName: editMode
                          ? customerName
                          : setupData?.customerName,
                        customerId: setupData?.customerId,
                        stateId: editMode ? stateId : setupData?.stateId,
                      },
                    });
                  }
                  if (e.id === 3) {
                    navigate(SW_PERMIT_LIST, {
                      state: {
                        projectName: editMode
                          ? projects?.value
                          : setupData?.projectName,
                        projectNumber: setupData?.projectNumber,
                        projectid: editMode
                          ? projects?.key
                          : setupData?.projectId,
                        customerName: editMode
                          ? customerName
                          : setupData?.customerName,
                        customerId: setupData?.customerId,
                        stateId: editMode ? stateId : setupData?.stateId,
                      },
                    });
                  }
                  if (e.id === 4) {
                    navigate(SW_ADMIN_SAMPLINGLOG, {
                      state: {
                        projectName: editMode
                          ? projects?.value
                          : setupData?.projectName,
                        projectNumber: setupData?.projectNumber,
                        projectId: editMode
                          ? projects?.key
                          : setupData?.projectId,
                        customerName: editMode
                          ? customerName
                          : setupData?.customerName,
                        customerId: setupData?.customerId,
                        stateId: editMode ? stateId : setupData?.stateId,
                      },
                    });
                  }
                  if (e.id === 5) {
                    navigate(SW_OPEN_ITEMS, {
                      state: {
                        projectName: editMode
                          ? projects?.value
                          : setupData?.projectName,
                        projectId: editMode
                          ? projects?.key
                          : setupData?.projectId,
                        projectNumber: setupData?.projectNumber,
                        customerName: editMode
                          ? customerName
                          : setupData?.customerName,
                        customerId: setupData?.customerId,
                        stateId: editMode ? stateId : setupData?.stateId,
                      },
                    });
                  }
                  if (e.id === 6) {
                    navigate(SW_RAIN_FALL_DATA, {
                      state: {
                        projectName: editMode
                          ? projects?.value
                          : setupData?.projectName,
                        projectNumber: setupData?.projectNumber,
                        projectId: editMode
                          ? projects?.key
                          : setupData?.projectId,
                        customerName: editMode
                          ? customerName
                          : setupData?.customerName,
                        customerId: setupData?.customerId,
                        stateId: editMode ? stateId : setupData?.stateId,
                      },
                    });
                  }
                  if (e.id === 7) {
                    navigate(SW_INSPECTION_LIST, {
                      state: {
                        projectName: editMode
                          ? projects?.value
                          : setupData?.projectName,
                        projectId: editMode
                          ? projects?.key
                          : setupData?.projectId,
                        projectNumber: setupData?.projectNumber,
                        customerName: editMode
                          ? customerName
                          : setupData?.customerName,
                        customerId: setupData?.customerId,

                        stateId: editMode ? stateId : setupData?.stateId,
                      },
                    });
                  }
                  if (e.id === 8) {
                    navigate(INSPECTION_AUTHORITY, {
                      state: {
                        projectName: editMode
                          ? projects?.value
                          : setupData?.projectName,
                        projectId: editMode
                          ? projects?.key
                          : setupData?.projectId,
                        projectNumber: setupData?.projectNumber,
                        customerName: editMode
                          ? customerName
                          : setupData?.customerName,
                        customerId: setupData?.customerId,

                        mapId: editMode && editDetail ? editDetail?.mapId : 0,
                        secondaryInspectors: editMode && userList,
                        stateId: editMode ? stateId : setupData?.stateId,
                      },
                    });
                  }
                  if (e.id === 9) {
                    navigate(ADMIN_ADD_STORM_WATERPROJECT, {
                      state: {
                        editMode: true,
                        projectNumber: setupData?.projectNumber,
                        projectid: editMode
                          ? projects?.key
                          : setupData?.projectId,
                        stateId: editMode ? stateId : setupData?.stateId,
                        customerName: editMode
                          ? customerName
                          : setupData?.customerName,
                        customerId: setupData?.customerId,
                      },
                    });
                  }
                  if (e.id === 12) {
                    navigate(ADMIN_RESOURCE, {
                      state: {
                        projectName: editMode
                          ? projects?.value
                          : setupData?.projectName,
                        projectId: editMode
                          ? projects?.key
                          : setupData?.projectId,
                        projectNumber: setupData?.projectNumber,
                        customerName: editMode
                          ? customerName
                          : setupData?.customerName,
                        customerId: setupData?.customerId,
                        stateId: editMode ? stateId : setupData?.stateId,
                      },
                    });
                  } else return;
                }}
              />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default SetupInspection;
