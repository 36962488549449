import React from "react";
import {
  ADMIN_JHA_CATEGORY,
  ADMIN_JHA_ACTIVITIES,
  ADMIN_JHA_HAZARDS,
  ADMIN_JHA_LIST_TEMPLATE,
  ADMIN_JHA_LIST_SERVICE,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import JhaConfigurationManagement from "../component/jha/jhaConfigurationManagement";

const JhaConfigurationContainer = () => {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(ADMIN_JHA_CATEGORY);
    } else if (id === 2) {
      navigate(ADMIN_JHA_ACTIVITIES);
    } else if (id === 3) {
      navigate(ADMIN_JHA_HAZARDS);
    } else if (id === 4) {
      navigate(ADMIN_JHA_LIST_TEMPLATE);
    } else if (id === 5) {
      navigate(ADMIN_JHA_LIST_SERVICE);
    }
  };

  return (
    <>
      <div className="dashTitle">Hazard Analysis Configuration</div>
      <JhaConfigurationManagement onClick={onClickHandler} />
    </>
  );
};

export default JhaConfigurationContainer;
