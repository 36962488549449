import React, { useEffect } from "react";
import CredentialsList from "../component/credentialsList";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MY_USER_ADD_CREDENTIAL } from "../../../../src/constant/routeContant";
import * as credentialAction from "../../../redux/actions/credentialAction";
import { ORG_ID, PAGE_SIZE, USER_ID } from "../../../constant/constant";

const CredentialsListContainer = () => {
  const navigate = useNavigate();
  const defaultData = {
    userId: USER_ID(),
    page: 1,
    search: "",
    pageSize: PAGE_SIZE,
    orgId: ORG_ID(),
    credentialType: "",
    userType: 0,
  };

  const userCredentialListData = useSelector(
    (state) => state.getCredentialUserListData
  );
  const isLoading = userCredentialListData?.isLoading;
  const dispatch = useDispatch();

  const getUserCredentials = (data) => {
    dispatch(credentialAction.getUserCredentialList(data));
  };

  useEffect(() => {
    getUserCredentials(defaultData);
  }, []);

  const NavigateAddCredentialMethode = () => {
    navigate(MY_USER_ADD_CREDENTIAL);
  };

  return (
    <>
      <CredentialsList
        NavigateAddCredential={NavigateAddCredentialMethode}
        isShow={true}
        credentialListData={
          isLoading ? [] : userCredentialListData?.data?.result
        }
        isLoading={isLoading}
        getUserCredentials={getUserCredentials}
      />
    </>
  );
};
export default CredentialsListContainer;
