import React, { useEffect, useState } from "react";
import ViewNews from "../component/news/viewNews";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as newsAction from "../../../redux/actions/newsAction";
import Loader from "../../../library/common/Loader";
import { CoPresentOutlined } from "@mui/icons-material";

const ViewsNewsContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const NewsData = useSelector((state) => state.getNewsDetails);
  const newsId = location?.state?.newsId;
  const detailNewsIsloading = NewsData?.isLoading;
  useEffect(() => {
    dispatch(newsAction.getNewsDetails(newsId));
  }, [dispatch]);
  return (
    <>
      {detailNewsIsloading && <Loader />}
      <ViewNews NewsData={NewsData} />
    </>
  );
};

export default ViewsNewsContainer;
