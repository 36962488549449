import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";

import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables);

const BarChart = ({
  labels = [],
  datasets = [],
  options,
  height = "400px",
}) => {
  useEffect(() => {
    Chart.register(ChartDataLabels);
  }, []);

  return (
    <div style={{ width: "100%", height }}>
      <Bar options={options} data={{ labels, datasets }} />
    </div>
  );
};

export default BarChart;
