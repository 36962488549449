import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./viewIncidentReportHeader.scss";
import DownloadIcon from "@mui/icons-material/Download";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { convertUTCToLocal } from "../../../../utils/helper";

const ViewIncidentReportHeader = ({ headerData, handlePrint }) => {
  const conducted_On = new Date(headerData?.conductedon);
  const dateToTime = (date) =>
    date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

  const dateString = headerData?.conductedon;
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const localDate = new Date(dateString);
  const utcDate = new Date(localDate.getTime() + userOffset);

  const [incidentTime, setincidentTime] = useState("");
  useEffect(() => {
    if (dateString !== "" && dateString !== undefined && dateString !== null) {
      setincidentTime(convertUTCToLocal(dateString?.slice(0, 19) + "Z"));
    }
  }, [dateString]);

  return (
    <>
      <div className="ViewIncHeaderOver">
        <Box className="viewIncBox">
          <Box className="viewIncBoxWrap">
            <Box className="viewIncBoxLeft">
              <Typography className="caseId">
                Case ID:
                <span>{headerData?.caseId}</span>
              </Typography>
              <Typography className="reportid">
                Report ID: <span>{headerData?.report_id}</span>
              </Typography>
              <Typography className="reportedby">
                Reported by: <span>{headerData?.name}</span>
              </Typography>
              <Typography className="conductedon">
                Reported on:{" "}
                <span>
                  {`${conducted_On.getDate()}/${
                    conducted_On.getMonth() + 1
                  }/${conducted_On.getFullYear()}`}
                </span>
                <span>{`${incidentTime}`}</span>
              </Typography>
              {headerData?.project && (
                <Typography className="project">
                  Project: <span>{headerData?.project[1]}</span>{" "}
                </Typography>
              )}
            </Box>

            <Box textAlign={"center"} className="viewIncBoxRight">
              <Typography className="incstatus">Incident Status</Typography>
              {headerData?.status == 1 ? (
                <p className="inprogressStatus">Draft</p>
              ) : headerData?.status == 2 ? (
                <p className="inprogressStatus">Open</p>
              ) : headerData?.status == 3 ? (
                <p className="closeStatus">Closed</p>
              ) : (
                <></>
              )}
              {checkPrivileges([1, 2, 3, 12]) && (
                <Button
                  onClick={handlePrint}
                  variant="contained"
                  startIcon={<DownloadIcon className="downloadIcon" />}
                  className="downloadpdf"
                >
                  Download as PDF
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default ViewIncidentReportHeader;
