import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context";
import Button from "../../library/button/button";
import Footer from "../../library/footer/footer";
import ConstructionImage from "../../assets/construction.png";
import FacilityImage from "../../assets/facility.png";
import HospitalityImage from "../../assets/hospitality.png";
import ManufacturingImage from "../../assets/manufacturing.png";
import OtherImage from "../../assets/other.png";
import UtilityImage from "../../assets/utility.png";
import "./onboarding.scss";
import * as onboardingActions from "../../redux/actions/signupAction";
import TableLoader from "../../../src/library/common/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DASHBOARD } from "../../constant/routeContant";
import { API_STATUS } from "../../constant/constant";
import { logos } from "../../assets";

const Onboarding = () => {
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [industry, setIndustry] = useState(1);
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const user = useSelector((state) => state.signup?.storage);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onloadingData = useSelector((state) => state.updatedOnboarding);

  let defaultErrorStates = {
    companyName: true,
    jobTitle: true,
  };
  /*
Dynamically update the
form whenever user inputs stuff
*/
  const untouchedForm = useRef(true);
  const isFormValid = useRef(false);

  const [errorList, setErrorList] = useState(defaultErrorStates);

  /* Validate Form */
  const validateForm = () => {
    let newErrorList = {
      ...errorList,
      companyName: companyName.trim().length !== 0,
      jobTitle: jobTitle.trim().length !== 0,
    };
    setErrorList({ ...newErrorList });
    /* This validation is not based on state */
    let temporaryValidation = true;
    for (const key in newErrorList) {
      temporaryValidation = temporaryValidation && newErrorList[key];
    }
    /*
    Saving it in this ref as well for
    when we don't want to revalidate the
    whole thing
    */
    isFormValid.current = temporaryValidation;
    return temporaryValidation;
  };

  // const notify = () => toast("Data Successfully Submitted");

  useEffect(() => {
    if (untouchedForm.current) {
      return;
    }
    validateForm();
  }, [companyName, jobTitle]);
  /* Next Button */
  const submitButton = async (event) => {
    event.preventDefault();
    /*
      The form is no longer untouched,
      which means we can now do real-time verificaiton
      */

    untouchedForm.current = false;
    if (validateForm()) {
      untouchedForm.current = true;
      const userId = localStorage.getItem("userId");
      const data = {
        userId,
        companyName,
        jobTitle,
        industryType: industry,
        isPhoneNumberVerified: true,
      };
      dispatch(onboardingActions.updateOnboarding(data));
      //notify();
    }
  };

  useEffect(() => {
    const isOnboarded = localStorage.getItem("isOnboarded");
    if (isOnboarded === "true") {
      navigate(DASHBOARD);
    }
  }, []);

  const companyFilter = (event, setter) => {
    var comName = event.target.value;
    var compRmvWhiteSpace = comName.replace(/\s+/g, " ");
    setter(compRmvWhiteSpace.replace(/[^a-z0-9 ]/gi, ""));
  };
  const jobFilter = (event, setter) => {
    var jobName = event.target.value;
    var jobRmvWhiteSpace = jobName.replace(/\s+/g, " ");
    setter(jobRmvWhiteSpace.replace(/[^a-z0-9 ]/gi, ""));
  };
  return (
    <>
      <div
        style={{
          background: darkMode ? "#000" : "#fff",
        }}
        className="onboarding"
      >
        {onloadingData.status === API_STATUS.SUCCESS && navigate(DASHBOARD)}
        <div className="onboarding-container">
          <div className="onboarding-left">
            <div className="logos">
              <div className="logoDark">
                <img className="logo" src={logos?.logoWhite} alt="logo" />
              </div>
              <div className="logoLight">
                <img className="logo" src={logos?.logo} alt="logo" />
              </div>
            </div>
            <h1
              style={{
                color: darkMode ? "#fff" : "#000",
              }}
            >
              Welcome to Sequence,{user?.first_name}{" "}
            </h1>
            <form onSubmit={submitButton}>
              <div className="form-wrapper-row onboarding-form">
                <div className="form-wrapper">
                  <h1>What is your Company Name</h1>
                  <input
                    type="text"
                    placeholder="Ex - Supertech"
                    value={companyName}
                    id="compny-area"
                    onChange={(e) => companyFilter(e, setCompanyName)}
                  />
                  {errorList.companyName ? null : (
                    <div className="show-error">Company name is compulsory</div>
                  )}
                </div>
                <div className="form-wrapper form-wrapper-last">
                  <h1>Job Title</h1>
                  <input
                    type="text"
                    placeholder="Enter Job Title"
                    value={jobTitle}
                    id="job-area"
                    onChange={(e) => jobFilter(e, setJobTitle)}
                  />
                  {errorList.jobTitle ? null : (
                    <div className="show-error">Job Title is compulsory</div>
                  )}
                </div>
              </div>
              <div className="form-wrapper">
                <h2 className="onboarding-left-industry">
                  Select your industry type
                </h2>
              </div>
              <div className="onboarding-left-industry-wrap">
                <div
                  onClick={(event) => setIndustry(1)}
                  className={industry === 1 ? "card card-selected" : "card"}
                >
                  <div className="card-div">
                    <div className="cardImg">
                      <img src={ConstructionImage} alt="" />
                    </div>
                    <h1>Construction</h1>
                  </div>
                </div>
                <div
                  onClick={(event) => setIndustry(2)}
                  className={industry === 2 ? "card card-selected" : "card"}
                >
                  <div className="card-div">
                    <div className="cardImg">
                      <img src={HospitalityImage} alt="" />
                    </div>
                    <h1>Hospitality</h1>
                  </div>
                </div>
                <div
                  onClick={(event) => setIndustry(3)}
                  className={industry === 3 ? "card card-selected" : "card"}
                >
                  <div className="card-div">
                    <div className="cardImg">
                      <img src={FacilityImage} alt="" />
                    </div>
                    <h1>Facilities Management</h1>
                  </div>
                </div>
                <div
                  onClick={(event) => setIndustry(4)}
                  className={industry === 4 ? "card card-selected" : "card"}
                >
                  <div className="card-div">
                    <div className="cardImg">
                      <img src={UtilityImage} alt="" />
                    </div>
                    <h1>Utilities</h1>
                  </div>
                </div>
                <div
                  onClick={(event) => setIndustry(5)}
                  className={industry === 5 ? "card card-selected" : "card"}
                >
                  <div className="card-div">
                    <div className="cardImg">
                      <img src={ManufacturingImage} alt="" />
                    </div>
                    <h1>Manufacturing</h1>
                  </div>
                </div>
                <div
                  onClick={(event) => setIndustry(6)}
                  className={industry === 6 ? "card card-selected" : "card"}
                >
                  <div className="card-div">
                    <div className="cardImg">
                      <img src={OtherImage} alt="" />
                    </div>
                    <h1>Other</h1>
                  </div>
                </div>
              </div>
              <div className="onboarding-button">
                {onloadingData.isLoading ? (
                  <TableLoader />
                ) : (
                  <Button label="Submit" onButtonClickHandler={submitButton} />
                )}
              </div>
            </form>
          </div>
        </div>
        <Footer fixed="fixed" />
      </div>
      <ToastContainer />
    </>
  );
};

export default Onboarding;
