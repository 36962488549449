import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../../library/custom/button/Button";
import AddIcon from "@mui/icons-material/Add";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../../library/common/Loader";
import { PAGE_SIZE } from "../../../../constant/constant";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import NORow from "../../../../library/custom/noRow/noRow";
import DialogBox from "../../../../library/common/DialogBox";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const DivisionList = ({
  divisionsListData,
  getDivisionsListData,
  addDivision,
  isLoading,
  handleDeleteOnClick,
  handleEditOnClick,
  handleViewOnClick,
  onRowClick,
}) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: searchText ? searchText : "",
    };
    getDivisionsListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getDivisionsListData({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      search: "",
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteDivision = (divisionId) => {
    setOpenModal(true);
    setSelectedRow(divisionId);
  };
  const deletehandle = () => {
    setOpenModal(false);
    handleDeleteOnClick(selectedRow);
  };

  const columns = [
    { field: "divisionName", headerName: "Division Name", flex: 1 },
    { field: "stateName", headerName: "State", flex: 1 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={divisionsListData?.itemsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={divisionsListData?.getDivisionLists ?? []}
          fileNameXL={"divisionList"}
        />
      ),
      width: 170,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            edit={checkPrivileges([12, 49, 50])}
            view={checkPrivileges([12, 49, 50, 51])}
            viewLabel="View Division"
            editLabel="Edit Division"
            viewOnClick={(row) => handleViewOnClick(row?.divisionId)}
            editOnClick={(row) => handleEditOnClick(row?.divisionId)}
            deleteOnClick={(row) => deleteDivision(row?.divisionId)}
          />
        );
      },
    },
  ];

  return (
    <section className="grid_main_sec">
      {openModal && (
        <DialogBox
          open={openModal}
          rows={[]}
          header={"Confirm Deletion"}
          deletehandle={deletehandle}
          handleCloseModal={() => setOpenModal(false)}
          content={"Are you sure you want to delete this Division?"}
          view
        />
      )}
      <div className="grid_main_header two_grid">
        <div className="grid_heading_style">Divisions</div>
        <div>
          {checkPrivileges([12, 49, 50]) && (
            <Buttons
              id="btnAddDivision"
              label={"Add Division"}
              varientAddIconBlue={true}
              onClick={() => addDivision()}
            />
          )}
        </div>
      </div>
      <div className="grid_main_body">
        <div className="filter_of_caselist">
          <div className="searchcaseid">
            <label htmlFor="search">
              <span>
                <SearchIcon />
              </span>
              <div>
                <input
                  id="search"
                  placeholder="Search"
                  type="text"
                  value={search}
                  className=""
                  onChange={handleautoSearch}
                  autoComplete="off"
                />
              </div>
            </label>
          </div>
        </div>

        <div className="desc_box row_uniq">
          <div className="table_style_ui">
            <GridTable
              getRowId={(r) => r.divisionId}
              columns={columns}
              rows={divisionsListData?.getDivisionLists ?? []}
              rowCount={divisionsListData?.itemsCount ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              onClick={(rows) => onRowClick(rows)}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DivisionList;
