import React, { useState } from "react";
import { Grid } from "@mui/material";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { PAGE_SIZE, ROW_PER_PAGE_SIZE } from "../../../../constant/constant";
import { useNavigate } from "react-router-dom";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { ADMIN_USER_VIEW_BY_ROLE } from "../../../../constant/routeContant";

function UserSerchByRoleList({
  roles,
  getUserByRoleLists,
  usersByRoleListData,
  isLoading,
}) {
  const navigate = useNavigate();
  const [selectUserRole, setSelectUserRole] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const onChangeSelectUserRole = (e) => {
    setSelectUserRole(e.target.value);
    const data = {
      page: 1,
      pageSize: pageSize,
      roleId: e?.target?.value,
    };
    getUserByRoleLists(data);
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(ADMIN_USER_VIEW_BY_ROLE, {
        state: { UserDatails: rows?.row, editMode: true },
      });
    }
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: pageSize,
      roleId: selectUserRole ? selectUserRole : 0,
    };

    getUserByRoleLists(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      page: 1,
      pageSize: newPazeSize,
      roleId: selectUserRole ? selectUserRole : 0,
    };
    getUserByRoleLists(data);
  };
  const credcloumns = [
    { field: "userId", headerName: "User ID", flex: 2.8 },
    { field: "clientName", headerName: "Client Name", flex: 2.8 },
    { field: "userName", headerName: "User Name", flex: 4 },
    { field: "userRole", headerName: "User Role", flex: 4 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={usersByRoleListData?.data?.result?.totalCount ?? 0}
          numberOfItemsPerPage={pageSize}
          incidentLists={usersByRoleListData?.data?.result?.users ?? []}
          fileNameXL={"UserByRoleList"}
        />
      ),
    },
  ];
  return (
    <>
      <Grid container>
        <Grid item lg={6} xs={12} sm={12} md={6} mt={1} textAlign="left">
          <div className="heading_style">Search Users by Roles</div>
        </Grid>
      </Grid>
      <Grid container mt={2} spacing={3}>
        <Grid item lg={5} xs={12} sm={8} md={6}>
          <SelectMenu
            placeholder="Please Select"
            listData={roles}
            value={selectUserRole}
            onchangehandler={(e) => onChangeSelectUserRole(e)}
          />
        </Grid>
      </Grid>
      <Grid container mt={2} spacing={3}>
        <Grid item lg={11} xs={12} sm={8} md={6}>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) =>
                  r?.date +
                  "|" +
                  r?.userId +
                  "|" +
                  Date.now() +
                  Math.floor(Math.random() * 100)
                }
                rows={usersByRoleListData?.data?.result?.users ?? []}
                rowCount={usersByRoleListData?.data?.result?.totalCount ?? 0}
                columns={credcloumns}
                loading={isLoading}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={pageSize}
                onClick={(rows) => onRowClick(rows)}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default UserSerchByRoleList;
