import { call, put, takeLatest } from "redux-saga/effects";
import { getUserCourseView } from "../../services/userCourseViewService";
import { apiError } from "../../utils/helper";
import * as lmsUserAction from "../actions/lmsUserAction";
import { lmsUserType } from "../constants/lmsUserType";

export function* getUserCourseViewSaga({ userId, courseId }) {
  console.warn("getUserCourseView", userId, courseId );
  try {
    const { data, status, error } = yield call(
      getUserCourseView,
      userId,
      courseId
    );
    if (!data && status !== 200) throw apiError(error);
    const GET_USER_COURSE_VIEW = lmsUserType.GET_USER_COURSE_VIEW;
    yield put(
      lmsUserAction.getUserCoursesViewSuccess({
        GET_USER_COURSE_VIEW,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Personal Injury Report Failed" };
    yield put(lmsUserAction.getUserCoursesViewFailure(data));
  }
}

const myGetUserCourseView = [
  takeLatest(lmsUserType.GET_USER_COURSE_VIEW, getUserCourseViewSaga),
];
export default myGetUserCourseView;
