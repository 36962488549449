import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CreateTicket from "../component/ticket/createTicket";
import * as supportAction from "../../../redux/actions/supportAction";
import { ADMIN_TICKET } from "../../../constant/routeContant";
import SuccessScreen from "../common/successSreen";
import Loader from "../../../library/common/Loader";

const CreateTicketContainer = () => {
  const dispatch = useDispatch();
  const [showSucessScreen, setshowSucessScreen] = useState(false);
  const [successScreens, setSuccessScreens] = useState(false);
  const createTicketData = useSelector((state) => state.createTicket.data);
  const createTicketStatus = useSelector((state) => state.createTicket.status);

  function handleSubmit(data) {
    setSuccessScreens(true);
    dispatch(supportAction.postCreateTicket(data));
  }
  const isLoading = useSelector(
    (state) => state.createTicket.isLoading || state.getTicketCategory?.isLoading
  );
  const articleCatSubcategory = useSelector(
    (state) => state.getTicketCategory?.data?.ticketCategory
  );
  useEffect(() => {
    dispatch(supportAction.getTicketCategory(""));
  }, []);

  const successScree = (id) => {
    return (
      <SuccessScreen
        id={id}
        label1="Ticket ID"
        label4="Support in"
        route={ADMIN_TICKET}
      ></SuccessScreen>
    );
  };
  return (
    <div className="">
      {isLoading && <Loader />}
      {createTicketStatus === "SUCCESS" &&
      createTicketData &&
      successScreens ? (
        successScree(createTicketData?.result?.ticketId)
      ) : (
        <CreateTicket
          onClick={handleSubmit}
          setshowSucessScreen={setshowSucessScreen}
          showSucessScreen={showSucessScreen}
          articleCatSubcategory={articleCatSubcategory}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default CreateTicketContainer;
