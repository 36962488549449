import React, { useState } from "react";
import { FormControl, Grid, RadioGroup } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import Button from "../../../../library/custom/button/Button";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { ASSET_TYPE_JHA, ORG_ID, USER_ID } from "../../../../constant/constant";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import MultiSelectBox from "./multiSelectBox";
import Buttons from "../../../../library/custom/button/Button";
import { useEffect } from "react";
const JHASetUpAlt = ({
  templeteList,
  organisationDivision,
  getAssetList,
  catActArr,
  setcatActArr,
  submit,
  frequencyList,
  editMode,
  template,
  setTemplate,
  isTemplate,
  isAssetType,
  setisAssetType,
  setisTemplate,
  frequencies,
  setFrequencies,
  isFrequency,
  setisFrequency,
  supervisorRequiredTypeId,
  setsupervisorRequiredTypeId,
  assetType,
  setassetType,
  getTemplateListData,
}) => {
  const dataBack = {
    title: "Back to Hazard Analysis Service",
    route: -1,
  };

  const [categorySetValue, setCategorySetValue] = useState();
  const [categoryFindValues, setCategoryFindValues] = useState();
  const handleCategorySetChange = (e) => {
    const findCategoryValue = organisationDivision.find(
      (item) => item.key === e.target.value
    );
    setCategoryFindValues(findCategoryValue);
    setCategorySetValue({
      categorySetValue: e.target.value,
    });
  };

  const [selectCategory, setSelectCategory] = useState(false);
  const [selectCategoryMsg, setSelectCategoryMsg] = useState(false);
  const handleOnclickCategory = () => {
    let errors = false;
    if (!categorySetValue) {
      setSelectCategory(true);
      setSelectCategoryMsg("Division is required");
      errors = true;
    }
    if (errors) {
      // setShowLoader(true);
      return;
    }
    const newCategoryes = [...catActArr];

    const index = newCategoryes?.findIndex(
      (obect) => obect.key === categoryFindValues?.key
    );
    if (index === -1) {
      // setShowLoader(false);
      setSelectCategory(false);
      newCategoryes.push({
        key: categoryFindValues?.key,
        categoriesName: categoryFindValues?.value,
        categoryId: categoryFindValues?.key,
        activities: [],
      });
      getAssetList(template, {
        page: 1,
        divisionId: categoryFindValues?.key ?? 0,
        pageSize: 100,
        // statusIds: "",
        search: "",
        // userId: 0,
        organisationId: ORG_ID(),
      });
      // setCategorySetValue();
      setcatActArr(newCategoryes);
    } else {
      setSelectCategory(true);
      setSelectCategoryMsg("Division already added.");
    }
  };

  function selectAllActivity(list, categoryId) {
    let arr = [...list];

    arr.forEach((act) => {
      act.isSelected = true;
    });
    let updateCatArr = [...catActArr];

    updateCatArr.forEach((cat) => {
      if (cat.categoryId === categoryId) {
        cat.activities = arr;
      }
    });
    setcatActArr(updateCatArr);
  }
  function handleActivityCheck(singleActivity, activityList, categoryId) {
    activityList.forEach((act) => {
      if (act.activityId === singleActivity.activityId) {
        act.isSelected = !act.isSelected;
      }
    });

    let updateCatArr = [...catActArr];

    updateCatArr.forEach((cat) => {
      if (cat.categoryId === categoryId) {
        cat.activities = activityList;
      }
    });
    setcatActArr(updateCatArr);
  }
  function cancelCategory(params) {
    const newArr = catActArr.filter((i) => i.categoryId != params.categoryId);
    setcatActArr(newArr);
  }

  function submitData() {
    const data = [];
    catActArr.forEach((i) => {
      const activitiesId = [];
      i.activities.forEach((a) => {
        if (a.isSelected) activitiesId.push(a.activityId);
      });
      data.push({
        divisionId: i.categoryId,
        assetMappingKeys: activitiesId,
      });
    });

    let errors = false;

    if (!assetType) {
      setisAssetType(true);
      errors = true;
    }
    if (!template) {
      setisTemplate(true);
      errors = true;
    }
    if (!frequencies) {
      setisFrequency(true);
      errors = true;
    }
    if (data.length <= 0) {
      setSelectCategory(true);
      setSelectCategoryMsg("Division is required");
      errors = true;
    }
    if (!errors)
      submit({
        templateId: template,
        supervisorRequiredTypeId: parseInt(supervisorRequiredTypeId),
        divisionMappingKeys: data,
        createdBy: parseInt(USER_ID()),
        frequencyId: parseInt(frequencies),
      });
  }

  return (
    <>
      <div className="overidePersonal">
        <Grid container item lg={9} xs={12} sm={12} md={9}>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
            <div className="heading_style">
              {/* {editMode ? "Edit Project" : "Add Project"} */}
              Setup Hazard Analysis
            </div>
          </Grid>
        </Grid>

        <Grid item container mt={3} spacing={2} lg={12}>
          <Grid item lg={4} xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <FormLabels label="Asset Type" isRequired={true} />
              <SelectMenu
                // placeholder={"All Division"}
                placeholder="Please Select"
                listData={ASSET_TYPE_JHA}
                value={assetType}
                onchangehandler={(e) => {
                  setassetType(e.target.value);
                  setcatActArr([]);
                  setTemplate();
                }}
                isError={isAssetType}
                errorMsg={
                  isAssetType ? "Selection is required for this field" : ""
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container mt={0.8} spacing={2} lg={12}>
          <Grid item lg={8} xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <FormLabels label="Hazard Analysis Template" isRequired={true} />
              <SelectMenu
                // placeholder={"All Division"}
                placeholder="Please Select"
                listData={templeteList}
                value={template}
                onchangehandler={(e) => {
                  setTemplate(e.target.value);
                  setcatActArr([]);
                }}
                isError={isTemplate}
                errorMsg={
                  isTemplate ? "Selection is required for this field" : ""
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid container item>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Select Divisions" isRequired={true} />
                <SelectMenu
                  // placeholder={"All Division"}
                  placeholder="Please Select"
                  // isgroupplaceholder={true}
                  listData={organisationDivision ?? []}
                  value={categorySetValue}
                  onchangehandler={(e) => handleCategorySetChange(e)}
                  disabled={template === undefined || null}
                />
              </FormControl>
            </Grid>
            <Grid
              className="smallAddBtn"
              item
              lg={1.5}
              md={2}
              xs={3}
              ml={2}
              mt={3.8}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                id="btnAddEquipment"
                varientAddIconBlue={true}
                onClick={handleOnclickCategory}
              ></Buttons>
            </Grid>
          </Grid>

          <Grid md={12} item>
            {selectCategory && <p className="errorMsg">{selectCategoryMsg}</p>}
          </Grid>
        </Grid>

        <Grid item container mt={1.5} lg={12} spacing={1}>
          {catActArr.map((i) => (
            <Grid key={i.categoryId} mt={2} container>
              <MultiSelectBox
                btnLabel="Select All Assets"
                btnLabel2={"Remove Division"}
                label={i.categoriesName}
                list={i.activities}
                // catActArr={[]}
                // setcatActArr={[]}
                categoryId={i.categoryId}
                singleCategory={i}
                selectAll={selectAllActivity}
                cancel={cancelCategory}
                handleCheck={handleActivityCheck}
              />
            </Grid>
          ))}
        </Grid>

        <Grid item container mt={3} spacing={2}>
          <Grid item lg={8} mt={3} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels
                label="Supervisor Required to submit Hazard Analysis"
                isRequired={true}
              />
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radioGroup"
              >
                <RadioButton
                  checked={supervisorRequiredTypeId == "0"}
                  value="0"
                  label="All Supervisors"
                  onChange={(e) => setsupervisorRequiredTypeId(e.target.value)}
                ></RadioButton>
                <RadioButton
                  checked={supervisorRequiredTypeId == "1"}
                  value="1"
                  label="Any one supervisor"
                  onChange={(e) => setsupervisorRequiredTypeId(e.target.value)}
                ></RadioButton>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container mt={3} spacing={2} lg={12}>
          <Grid item lg={4} xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <FormLabels label="Select Frequency" isRequired={true} />
              <SelectMenu
                placeholder="Please Select"
                listData={frequencyList}
                value={frequencies}
                onchangehandler={(e) => {
                  setFrequencies(e.target.value);
                }}
                isError={isFrequency}
                errorMsg={
                  isFrequency ? "Selection is required for this field" : ""
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={7}>
          <Grid container item lg={12} gap={12} alignItems="center">
            <Grid item lg={4} textAlign="center" className="addcourseButton">
              <BackBtn dataBack={dataBack} />
            </Grid>
            <Grid item lg={6} textAlign="center" className="addcourseButton">
              <Button
                varientContained={true}
                label={editMode ? "Update" : "Save"}
                onClick={submitData}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default JHASetUpAlt;
