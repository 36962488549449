import { Grid, Typography } from "@mui/material";
import React from "react";
import "./cardList.scss";

const Card = ({ cardDetail, imgShow = false, iconShow = false, onClick }) => {
  return (
    <>
      <Grid item md={4} xs={12} key={cardDetail?.id}>
        <Grid className="CardListBox" onClick={() => onClick(cardDetail?.id)}>
          {iconShow && <Typography mt={0.5}>{cardDetail?.icon}</Typography>}
          {imgShow && (
            <Grid className="CardListImg">
              <img src={cardDetail?.image} alt="" />
            </Grid>
          )}
          <Grid className="cardListTitle">{cardDetail?.title}</Grid>
          <Grid className="cardListDesc">{cardDetail?.description}</Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Card;
