import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Buttons from "../custom/button/Button";
import { Add, Remove } from "@mui/icons-material";
import { getFormdata, responseQuestionSet } from "../../utils/incpectionHelper";
import AcceptableBoxDetails from "./acceptableBoxDetails";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../redux/actions/userAction";
import { checkPrivileges } from "../../utils/rolesHelper";

const AcceptableBox = ({
  qestSetState,
  setQestSetState,
  parentElementData,
  empType,
  setImage,
  filesId,
  filesUrl,
  assetId,
  assetTypeValue,
  userClear,
}) => {
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state.allUsers);
  function handleAllUserSearch(
    event,
    type,
    divisionId,
    assetType,
    assetId,
    priviledgeId
  ) {
    if (checkPrivileges([12, 139, 144]) ? assetId?.key : assetId) {
      dispatch(
        userAction.searchAllUsers(
          event.target.value,
          type,
          divisionId,
          checkPrivileges([12, 139, 144]) ? assetId?.key : assetId,
          assetTypeValue,
          priviledgeId
        )
      );
    }
  }
  const isAutoSearchDataLoading = allUsers.isLoading;

  const data = React.useMemo(() => {
    const keyParent = parentElementData?.itemKey;
    const { keySub, key } = empType;
    if (keyParent && key) {
      const dataForm = getFormdata(qestSetState, keyParent, keySub, key);
      const dataForms = { ...dataForm };
      return dataForms;
    }
    return qestSetState;
  }, [qestSetState, empType, parentElementData]);

  const handleOnChange = (event, id, fieldName) => {
    const acceptData = { ...data };
    const acceptable = [...acceptData?.acceptable];
    acceptable.forEach((acceptables) => {
      if (acceptables.id === id) {
        if (fieldName === "commentFeild") {
          // if (event.target.value.length <= 500) {
          acceptables.comment = event;
          // }
        }
        if (fieldName === "Involved Party Type") {
          acceptables.involdevPaty = event.target.value;
          acceptables.employee = [{ key: "", value: "" }];
          acceptables.subcontractor = [{ key: "", value: "" }];
        }
        if (fieldName === "Add Involved Employee") {
          if (!acceptables.employee?.some((item) => item.key === event?.key)) {
            acceptables.employee = [
              ...acceptables.employee,
              { key: event?.key ?? "", value: event?.value ?? "" },
            ];
          }
        }
        if (fieldName === "Add Involved Company") {
          if (
            !acceptables.subcontractor?.some((item) => item.key === event?.key)
          ) {
            acceptables.subcontractor = [
              ...acceptables.subcontractor,
              { key: event?.key ?? "", value: event?.value ?? "" },
            ];
          }
        }
      }
    });
    formHandler(acceptData);
  };

  const handleOnChangeCloseItem = (e, action) => {
    const acceptData = { ...data };
    const acceptable = { ...acceptData?.acceptable };
    acceptable.closeitem = !acceptable?.closeitem ?? false;
    acceptData.acceptable = acceptable;
    formHandler(acceptData);
  };

  const formHandler = (value) => {
    const keyParent = parentElementData?.itemKey;
    const { keySub, key } = empType;
    if (keyParent) {
      setQestSetState((prevState) =>
        responseQuestionSet(prevState, parentElementData, keySub, key, value)
      );
    }
  };

  const { iskudos, commentFeild } = data.acceptable;
  const { acceptable } = data;
  const onChangeButton = (value, empType, parentElementData) => {
    const acceptData = { ...data };
    acceptData.acceptable.push({
      id: Date.now() + Math.floor(Math.random() * 100),
      comment: "",
      media: [],
      filess: [],
      imgUrl: "",
      involdevPaty: "",
      employee: [],
      subcontractor: [],
    });
    formHandler(acceptData);
  };
  const deleteuser = (e, id) => {
    const acceptData = { ...data };
    const acceptable = [...acceptData?.acceptable];
    acceptable.forEach((acceptables) => {
      if (acceptables.id === id) {
        acceptables.employee = acceptables.employee.filter(
          (items) => items.key !== e
        );
      }
    });
    formHandler(acceptData);
  };
  const deleteSubContractor = (e, id) => {
    const acceptData = { ...data };
    const acceptable = [...acceptData?.acceptable];
    acceptable.forEach((acceptables) => {
      if (acceptables.id === id) {
        acceptables.subcontractor = acceptables.subcontractor.filter(
          (items) => items.key !== e
        );
      }
    });
    formHandler(acceptData);
  };

  const deleteform = (id) => {
    const acceptData = { ...data };
    const acceptable = [...acceptData?.acceptable];
    if (acceptable.length > 1) {
      acceptData.acceptable = acceptable.filter((items) => items.id !== id);
    }
    formHandler(acceptData);
  };
  const setImages = (photo, fileDataId, uniqueId) => {
    const acceptData = { ...data };
    const acceptable = [...acceptData?.acceptable];
    acceptable.forEach((acceptables) => {
      if (acceptables.id === uniqueId) {
        acceptables.media = fileDataId;
        acceptables.imgUrl = photo;
      }
    });
    formHandler(acceptData);
  };

  const handleDeleteImage = (urls, index, uniqueId) => {
    const acceptData = { ...data };
    const acceptable = [...acceptData?.acceptable];
    acceptable.forEach((acceptables) => {
      if (acceptables.id === uniqueId) {
        acceptables.imgUrl = acceptables.imgUrl
          .split("|")
          .filter((url) => url !== urls)
          .join("|");
        acceptables.media.splice(index, 1);
      }
    });
    formHandler(acceptData);
  };

  useEffect(() => {
    if (userClear) {
      const acceptData = { ...data };
      const acceptable = [...acceptData?.acceptable];
      acceptable.forEach((acceptables) => {
        acceptables.employee = [];
        acceptables.subcontractor = [];
      });
      formHandler(acceptData);
    }
  }, [userClear]);

  return (
    <>
      <div className="overideNotAc">
        <Box>
          {acceptable.map((acce, index) => {
            return (
              <AcceptableBoxDetails
                key={index}
                labelValue={acce.comment}
                involdevPatyValue={acce.involdevPaty}
                employeeValue={acce.employee}
                subcontractorValue={acce.subcontractor}
                uniqueId={acce.id}
                commentFeild={commentFeild}
                formHandler={formHandler}
                setImage={setImages}
                filesId={filesId}
                filesUrl={acce.imgUrl}
                filess={acce.filess}
                deleteform={(id) => deleteform(id)}
                data={data?.acceptable ?? {}}
                handleOnChange={(e, actionFeild, fieldName) =>
                  handleOnChange(e, actionFeild, fieldName)
                }
                deleteuser={(e, id) => deleteuser(e, id)}
                deleteSubContractor={(e, id) => deleteSubContractor(e, id)}
                handleOnChangeCloseItem={(e, action) =>
                  handleOnChangeCloseItem(e, action)
                }
                acceptablelength={acceptable.length}
                deleteImageURLS={handleDeleteImage}
                isLoading={isAutoSearchDataLoading}
                handleAllUserSearch={handleAllUserSearch}
                allUsers={allUsers && allUsers?.data}
                assetId={assetId}
              />
            );
          })}
          <Grid className="inputGrid" mt={1} mb={3} gap={2}>
            <Buttons
              primary={true}
              label={"Add Kudos"}
              startIcon={iskudos ? <Remove /> : <Add />}
              // redBg={true}
              disabled={acceptable.length === 4 ? true : false}
              onClick={(e) =>
                onChangeButton("kudos", empType, parentElementData)
              }
            />
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default React.memo(AcceptableBox);
