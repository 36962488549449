import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AssignToolbox from "../component/assignToolBoxTalk/assignToolBoxTalk";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { API_STATUS, USER_ID } from "../../../constant/constant";
import * as userAction from "../../../redux/actions/userAction";
import { useLocation } from "react-router-dom";
import SuccessScreencommon from "../../../library/custom/success/successScreen";
import { TOOL_BOX_LIST } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
//import { usersAccess } from "../../../utils/rolesHelper";

const AssignToolboxContainer = () => {
  const defaultData = {
    search: "",
  };
  const dispatch = useDispatch();
  const getToolSafetyMeetingSearchData = useSelector(
    (state) => state.getToolSafetyMeetingSearch?.data?.saftyMeetings
  );
  //assignAdminToInstructorTool
  const assignAdminToInstructorTool = useSelector(
    (state) => state.assignAdminToInstructorTool
  );
  const assignSelfInstructorToUserTool = useSelector(
    (state) => state.assignSelfInstructorToUserTool
  );

  const selfLoading = assignSelfInstructorToUserTool?.isLoading;
  const statusself = assignSelfInstructorToUserTool?.status;
  const getScheduleMeetingDetailData = useSelector(
    (state) => state.getScheduleMeetingDetail
  );
  // const getProjectSearchList = useSelector(
  //   (state) => state.getProjectSearchList?.data?.projects
  // );

  const isLoadingSchedueleDetail = getScheduleMeetingDetailData?.isLoading;
  const AssignAdminToInstructorIsloading =
    assignAdminToInstructorTool?.isLoading;
  const statuss = assignAdminToInstructorTool?.status;
  const cid = assignAdminToInstructorTool?.data?.result?.scheduleId;
  const location = useLocation();
  const toolboxid = location?.state?.toolboxid;
  const toolboxIdinstructor = location?.state?.toolboxId;
  const detailPageNavigate = location?.state?.detailPageNavigate;
  const id = location?.state?.id;
  const titleSafetyMeeting = location?.state?.titleSafetyMeeting;
  const toolboxTalkIdSelf = location?.state?.toolboxTalkIdSelf;
  const titleSafetyMeetingSelf = location?.state?.titleSafetyMeetingSelf;
  const assignInstructorsUsersId = location?.state?.assignInstructorsUsersId;
  const assignedOnSelf = location?.state?.assignedOnSelf;
  const scheduleIdseld = location?.state?.scheduleIdseld;
  const getSafetyMeetingSearch = (data) => {
    dispatch(toolboxAction.getToolBoxSafetyMeetingSearchList(data));
  };

  useEffect(() => {
    getSafetyMeetingSearch(defaultData);
  }, []);

  const [successScreens, setSuccessScreens] = useState(false);
  const allUsersProjectWise = useSelector(
    (state) => state.searchAllUserProjectWise
  );
  const allUsers = useSelector((state) => state.allUsers);
  const isAutoSearchDataLoading = allUsersProjectWise.isLoading;

  const addAndUpdateAssign = async (data) => {
    setSuccessScreens(true);

    if (detailPageNavigate) {
      dispatch(toolboxAction.assignSelfInstructorToUserToolBox(data));
    } else {
      dispatch(toolboxAction.assignAdminToInstructorToolBox(data));
    }
  };
  // const getProjectSearch = (data) => {
  //   dispatch(toolboxAction.getProjectSearchList(data));
  // };

  // useEffect(() => {
  //   getProjectSearch(defaultData);
  // }, []);

  const getScheduleSafetyMeetingDetails = (data) => {
    dispatch(toolboxAction.getScheduleSafetyMeetingDetails(data));
  };

  useEffect(() => {
    if (assignInstructorsUsersId) {
      const defaultData = {
        scheduleDate: assignedOnSelf,
        scheduleId: scheduleIdseld,
        // scheduleDate: "",
        userId: USER_ID(),
        assignType: 1,
      };
      getScheduleSafetyMeetingDetails(defaultData);
    }
  }, [assignInstructorsUsersId]);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isContractor = userInfo?.isContractor;
  function getAllUsers(searchText, type, divisionId, projectId, priviledgeId) {
    dispatch(userAction.searchAllUsers(searchText, type, "0", "0", 0, "91"));
  }

  const successScree = (id, message, labelmsg) => {
    return (
      <SuccessScreencommon
        isUpdate={message}
        route={TOOL_BOX_LIST}
        id={id}
        label2={labelmsg}
        label1="Safety Meeting Assign ID"
        label4="Safety Meeting Assign in "
      ></SuccessScreencommon>
    );
  };
  return (
    <>
      {AssignAdminToInstructorIsloading && <Loader />}
      {selfLoading && <Loader />}
      {isLoadingSchedueleDetail && <Loader />}
      {statuss === API_STATUS.SUCCESS && successScreens ? (
        successScree(cid, "Safety Meeting Assign  Successfully", "")
      ) : statusself === API_STATUS.SUCCESS &&
        successScreens &&
        assignSelfInstructorToUserTool ? (
        successScree(cid, "Safety Meeting Self Assign Successfully", "")
      ) : (
        <AssignToolbox
          getSafetyMeetingSearch={getSafetyMeetingSearch}
          SafetyMeetingSearchData={getToolSafetyMeetingSearchData}
          isLoading={isAutoSearchDataLoading}
          allUsers={allUsers && allUsers?.data}
          toolboxid={toolboxid}
          onSearch={getAllUsers}
          onSubmit={addAndUpdateAssign}
          // projectData={
          //   toolboxTalkIdSelf ? getProjectSearchList : getProjectSearchList
          // }
          idSafety={detailPageNavigate ? toolboxIdinstructor : id}
          titleSafetyMeeting={titleSafetyMeeting}
          editScheduleMeetingDetailData={getScheduleMeetingDetailData}
          detailPageNavigate={detailPageNavigate}
          toolboxIdinstructor={toolboxIdinstructor}
          toolboxTalkIdSelf={toolboxTalkIdSelf}
          titleSafetyMeetingSelf={titleSafetyMeetingSelf}
          isContractor={isContractor}
          assignInstructorsUsersId={assignInstructorsUsersId}
        />
      )}
    </>
  );
};

export default AssignToolboxContainer;
