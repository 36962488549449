import * as dashboardAction from "../actions/dashboardAction";
import { dashboardType } from "../constants/dashboardType";

import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

import {
  updateUserDashboardCart,
  userDashboardCartList,
  dashboardCartList,
} from "../../services/dashboardService";

export function* getUserDashboardCartListSaga(params) {
  try {
    const { data, status, error } = yield call(userDashboardCartList, params);
    if (!data && status !== 200) throw apiError(error);
    const userDashboardCartPage = dashboardType.USER_DASHBOARD_CART_LIST_PAGE;
    yield put(
      dashboardAction.getUserDashboardCartListSuccess({
        userDashboardCartPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get User Dashboard Cart List Failed" };
    yield put(dashboardAction.getUserDashboardCartListFailure(data));
  }
}

export function* getDashboardCartListSaga(params) {
  try {
    const { data, status, error } = yield call(dashboardCartList, params);
    if (!data && status !== 200) throw apiError(error);
    const dashboardCartPage = dashboardType.DASHBOARD_CART_LIST_PAGE;
    yield put(
      dashboardAction.getDashboardCartListSuccess({
        dashboardCartPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Dashboard Cart List Failed" };
    yield put(dashboardAction.getDashboardCartListFailure(data));
  }
}

export function* updateUserDashboardCartListSaga(param) {
  const payload = param.payload;
  try {
    const { data, status, error } = yield call(
      updateUserDashboardCart,
      payload
    );

    if (!data && status !== 200) throw apiError(error);
    const updateUserDashboardCartPage =
      dashboardType.UPDATE_USER_DASHBOARD_CART_PAGE;
    yield put(
      dashboardAction.updateUserDashboardCartSuccess({
        updateUserDashboardCartPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update User Dashboard Cart List Failed" };
    yield put(dashboardAction.updateUserDashboardCartFailure(data));
  }
}

const myDashboardSaga = [
  takeLatest(
    dashboardType.UPDATE_USER_DASHBOARD_CART_PENDING,
    updateUserDashboardCartListSaga
  ),
  takeLatest(
    dashboardType.GET_DASHBOARD_CART_LIST_PENDING,
    getDashboardCartListSaga
  ),
  takeLatest(
    dashboardType.GET_USER_DASHBOARD_CART_LIST_PENDING,
    getUserDashboardCartListSaga
  ),
];

export default myDashboardSaga;
