import React from "react";
import { COMPANYMANAGEMENT } from "../../../../constant/constant";
import Card from "../../../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const CompanyManagementList = ({ onClick }) => {
  return (
    <>
      <Grid container className="CardListContaner">
        {checkPrivileges([12, 49, 50, 51]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={COMPANYMANAGEMENT[0]}
          />
        )}
        {checkPrivileges([12, 40, 41, 42]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={COMPANYMANAGEMENT[1]}
          />
        )}
        {checkPrivileges([12, 73]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={COMPANYMANAGEMENT[2]}
          />
        )}
      </Grid>
    </>
  );
};

export default CompanyManagementList;
