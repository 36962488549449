import * as signupAction from "../actions/signupAction";
import { signupType } from "../constants/signupType";
import { API_STATUS } from "../../constant/constant";

import {
  signup,
  addOtpSignup,
  validateMobileSignup,
  verifymobile,
  userSignup,
  updateOnboarding,
  resendOtp,
} from "../../services/signupService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

const signupError = (errorObject) => {
  return errorObject.response ? errorObject.response : errorObject;
};

const handleError = (errorObject) => {
  return errorObject.code ? errorObject : errorObject;
};

export function* signupRequest({ data }) {
  const { mobile } = data;
  try {
    if (!data) throw signupError({});
    //handle the signup if user is onborded by admin
    const { status: mobileStatus, data: mobileResponse } = yield call(
      validateMobileSignup,
      mobile
    );
    if (
      mobileStatus === 200 &&
      mobileResponse &&
      mobileResponse.responseCode === "HBNG005"
    ) {
      throw handleError({
        code: mobileResponse.responseCode,
        message: "User Already Exists",
      });
    }

    const {
      user: { username, authenticationFlowType },
    } = yield call(signup, data);
    if (!username && authenticationFlowType !== "USER_SRP_AUTH")
      throw apiError("Signup Failed");

    const userData = {
      ...data,
      PhoneNumber: mobile,
      createdByApp: "1",
    };
    delete userData.password;
    delete userData.mobile;
    const {
      status,
      data: { userId },
    } = yield call(userSignup, userData);
    if (status !== 200 && !userId) throw apiError("Signup Failed");
    const signupPage = signupType.SIGNUP_PAGE;
    yield put(
      signupAction.signupSuccess({
        signupPage,
      })
    );
  } catch (error) {
    if (error && error.code === "UsernameExistsException") {
      try {
        const { status, data } = yield call(validateMobileSignup, mobile);
        if (status === 200 && data.responseCode === "HBNG004") {
          const response = yield call(resendOtp, mobile);
          if (!response) throw apiError("Signup Failed");
          const signupPage = signupType.SIGNUP_PAGE;
          yield put(
            signupAction.signupSuccess({
              signupPage,
            })
          );
        } else {
          throw apiError(error);
        }
        return;
      } catch (innerError) {
        const innerErrorData =
          innerError && innerError.message && innerError.code
            ? { code: innerError.code, message: innerError.message }
            : { message: innerError || "Signup Failed" };
        yield put(signupAction.signupFailure(innerErrorData));
        return;
      }
    }
    const errorData =
      error && error.message && error.code
        ? { code: error.code, message: error.message }
        : { message: error || "Signup Failed" };
    yield put(signupAction.signupFailure(errorData));
  }
}

export function* updateOnboardingSaga(params) {
  try {
    const { data, status, error } = yield call(updateOnboarding, params);
    if (!data && status !== 200) throw apiError(error);
    const onboardingPage = signupType.SIGNUPONBOARDING_PAGE;
    yield put(
      signupAction.updateOnboardingSuccess({
        onboardingPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Onbaording Failed" };
    yield put(signupAction.updateOnboardingFailure(data));
  }
}

// OTP call API
export function* signupOtpRequest({ mobile, otp }) {
  try {
    if (!mobile && !otp) throw apiError({});
    const data = yield call(addOtpSignup, mobile, otp);
    if (data !== API_STATUS.SUCCESS)
      throw apiError({ code: "Error", message: "Otp is incorrect" });
    const signupotpPage = signupType.SIGNUPOTP_PAGE;
    yield put(
      signupAction.otpsignupSuccess({
        signupotpPage,
        data,
      })
    );
  } catch (error) {
    const errorData =
      error && error.code
        ? { code: error.code, message: error.message }
        : { message: error || "Signup OTP Failed" };
    yield put(signupAction.otpsignupFailure(errorData));
  }
}

export function* verifyMobileRequest({ data }) {
  try {
    if (!data) throw signupError({});
    let {
      data: response,
      status,
      error,
    } = yield call(validateMobileSignup, data);
    if (!response && status !== 200) throw apiError(error);
    if (status === 200 && response && response.responseCode === "HBNG005") {
      const data_result = yield call(verifymobile, data);
      const signupPage = signupType.SIGNUP_PAGE;
      yield put(
        signupAction.verifymobileSuccess({
          signupPage,
          data_result,
        })
      );
    } else {
      const data = {
        code: response.responseCode,
        message: "User does not exist.",
      };
      yield put(signupAction.verifymobileFailure(data));
      return;
    }
  } catch (error) {
    const errorData =
      error && error.message && error.code
        ? { code: error.code, message: error.message }
        : { message: error || "Verification Failed" };
    yield put(signupAction.verifymobileFailure(errorData));
  }
}

const mySignupSaga = [
  takeLatest(signupType.POST_SIGNUP_PENDING, signupRequest),
  takeLatest(signupType.POST_OTPSIGNUP_PENDING, signupOtpRequest),
  takeLatest(signupType.POST_VERIFYMOBILE_PENDING, verifyMobileRequest),
  takeLatest(signupType.PUT_ONBOARDING_PENDING, updateOnboardingSaga),
];

export default mySignupSaga;
