import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "../common.scss";
import ViewImages from "../viewImages/viewImages";
import "./witnessReport.scss";

const WitnessRepot = ({ witnessData }) => {
  const imageSize = {
    size: "small",
  };
  return (
    <div className="commonOveride">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">Witness Details:</Typography>
            <div className="witnessBox">
              {witnessData?.map((list, i) => {
                const image = list?.files ? list?.files : "";
                const witness_image_result = image?.split("|");
                return (
                  <>
                    <div className="witnessList" key={i}>
                      <Typography className="listView">
                        Witness Detail {i + 1}:
                      </Typography>
                      <Box className="columns">
                        <Typography className="listView">
                          Name:
                          <span className="font-w400">{list?.witnessName}</span>
                        </Typography>
                        <Typography className="listView">
                          Employer's Name:
                          <span className="font-w400">
                            {list?.employerName}
                          </span>
                        </Typography>
                      </Box>
                      <Typography className="listView">
                        Job Title:
                        <span>{list?.jobTitle}</span>
                      </Typography>
                      <Typography className="listView">
                        Statement:
                        <span className="blockView">{list?.statement}</span>
                      </Typography>
                      <Typography className="listView desNone">
                        Photos:
                      </Typography>
                      <Typography className="listView desNone">
                        <ViewImages
                          image={witness_image_result}
                          imageSize={imageSize}
                        />
                      </Typography>
                    </div>
                  </>
                );
              })}
            </div>
          </Box>
          <Box className="commoninfoRight">
            <Box className="commonSignatureBox">
              {/* <p className="title">Signature:</p>
              <div className="box">
                <img src={""} alt="incident-type" />
              </div> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default WitnessRepot;
