import { Grid, Typography } from "@mui/material";
import React from "react";
import "./faqTopicStyle.scss";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./faqTopicStyle.scss";
import ViewImages from "../../../incidents/view-incident-report/common/viewImages/viewImages";
const FaqPreview = (props) => {
  const imageSize = {
    size: "large",
  };
  return (
    <div className="overrideDetailedFaq">
      <Grid className="detailedFaqContainer">
        <Grid mb={3} className="backBtnGrid">
          <button
            className="btn"
            onClick={() => {
              props.handleBack();
            }}
          >
            <ArrowBackIosNewIcon style={{ fontSize: "1rem" }} />
            Back
          </button>
        </Grid>
        <Grid mt={0.5} p={3} className="borderedGrid">
          <Grid className="detailsGrid">
            <Typography className="question">{props.title}</Typography>
            <Typography className="answer">
              {props.description.split("\n").map((str,ind) => (
                                  <p key={ind}>{str}</p>
                                ))}
            </Typography>
            {props.addPhoto && (
              <Grid>
                <div>
                  <ViewImages image={[props?.addPhoto]} imageSize={imageSize} />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FaqPreview;
