import "./dashboard.scss";
import { useEffect, useState, Suspense, lazy } from "react";
import {
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
  API_STATUS,
  SERVICE_TYPE,
} from "../../constant/constant";
import { useNavigate } from "react-router-dom";
import * as supportAction from "../../redux/actions/supportAction";
import * as getDashboardAction from "../../redux/actions/dashboardAction";
import * as jhaAction from "../../redux/actions/jhaAction";
import locaibrary from "../../assets/card/assignment.svg";
import customizeDashIcon from "../../assets/info.svg";
import {
  ADMIN_RESOURCE,
  CREDENTIAL_LOOKUP,
  OPEN_ITEMS_LIST,
  SWDASHBOARD,
  USERCOURSES,
  LANDING_PAGE,
  ADMIN_ADD_NEWS,
  ADMIN_LIST_NEWS,
} from "../../constant/routeContant";
import Loader, { InComponentLoader } from "../../library/common/Loader";
import DonoughtChart from "../support/component/ticket/charts";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../../library/custom/button/Button";
import JHADashboardTable from "./jhaDashboardTable";
import { PRODUCT_ID } from "../../constant/storm-water/constant";
import * as newsAction from "../../redux/actions/newsAction";
import { checkPrivileges } from "../../utils/rolesHelper";
const NewsList = lazy(() => import("../admin/component/news/listNews"));

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dashcount, setDashcount] = useState("");
  const [dashcountfinal, setDashcountfinal] = useState("");
  const [columns, setColumns] = useState("");
  const [finalobject, setFinalobject] = useState("");
  const [dashcountfinalcardIds, setDashcountfinalcardIds] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [showJhaDashboardTable, setShowJhaDashboardTable] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userInfoProduct = userInfo?.product;
  const data = userInfoProduct?.map((val) => val?.key);
  const getNewsList = useSelector((state) => state.getNewsList);
  const NewsData = getNewsList?.data?.news;
  useEffect(() => {
    if (PRODUCT_ID() == 2) {
      navigate(SWDASHBOARD);
    }
    if (SERVICE_TYPE() == null && data?.length > 1) {
      navigate(LANDING_PAGE);
    }
  }, []);

  useEffect(() => {
    const defaultData = {
      page: 1,
      pageSize: 2,
    };
    dispatch(newsAction.getNewsList(defaultData));
  }, []);

  const defaulydata1 = {
    userId: parseInt(USER_ID()),
    organizationId: ORG_ID(),
  };
  useEffect(() => {
    dispatch(supportAction.getDashboardTickets(""));
    dispatch(
      jhaAction.getJHASetupDashboard({
        page: 1,
        pageSize: PAGE_SIZE,
      })
    );
    dispatch(getDashboardAction.getDashboardCartList());
    dispatch(getDashboardAction.getUserDashboardCartList(defaulydata1));
  }, []);
  const getDashboardCartListStatus = useSelector(
    (state) => state.getDashboardCartList
  );
  const getJhaSetupDashboardData = useSelector(
    (state) => state.getJhaSetupDashboard
  );
  const UserDashboardCartList = useSelector(
    (state) => state.getUserDashboardCartList
  );
  const UserDashboardCartListIsloading = UserDashboardCartList?.isLoading;
  useEffect(() => {
    if (
      UserDashboardCartList.status == API_STATUS.SUCCESS &&
      UserDashboardCartList
    ) {
      var dataResult1 = UserDashboardCartList?.data?.result;
      dataResult1?.forEach((v) => (v.cartId += ""));
      var Useridsdash = dataResult1?.map((val) => {
        return val.cartId;
      });
      setDashcountfinalcardIds(Useridsdash);

      setDashcountfinal(dataResult1);
    }
  }, [UserDashboardCartList]);

  useEffect(() => {
    if (
      getDashboardCartListStatus.status == API_STATUS.SUCCESS &&
      UserDashboardCartList.status == API_STATUS.SUCCESS
    ) {
      let dataResult = getDashboardCartListStatus?.data?.result;
      dataResult?.forEach((v) => (v.cartId += ""));
      const final = dataResult?.filter(
        (val) => !dashcountfinalcardIds?.includes(val?.cartId)
      );
      setDashcount(final);
    }
  }, [getDashboardCartListStatus, dashcountfinalcardIds]);

  const dashboardTickets = useSelector(
    (state) => state.getDashboardTickets?.data?.result
  );
  const UpdateconfigDashboard = useSelector(
    (state) => state.updateUserDashboardCart
  );
  const isLoadingD = UpdateconfigDashboard?.isLoading;
  useEffect(() => {
    if (UpdateconfigDashboard?.status == API_STATUS.SUCCESS) {
      setIsVisible(false);
      dispatch(getDashboardAction.getUserDashboardCartList(defaulydata1));
    }
  }, [UpdateconfigDashboard]);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;

    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];

      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      destItems.map((key, index) => {
        return (key.cartSequenceId = index);
      });

      sourceItems.map((key, index) => {
        return (key.cartSequenceId = index);
      });
      setFinalobject(source.droppableId == "123" ? destItems : sourceItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      copiedItems.map((key, index) => {
        return (key.cartSequenceId = index);
      });
      setFinalobject(copiedItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  useEffect(() => {
    if (dashcount?.length > 0 || dashcountfinal?.length > 0) {
      let columnsFromBackend = {
        [111]: {
          name: "Final",
          items: dashcountfinal?.length > 0 ? dashcountfinal : [],
        },
        [123]: {
          name: "TODO",
          items: dashcount?.length > 0 && isVisible ? dashcount : [],
        },
      };
      setColumns(columnsFromBackend);
    }
  }, [dashcount, isVisible]);
  const handlesubmit = () => {
    finalobject?.map((val, index) => {
      return (val.cartId = parseInt(val.cartId));
    });
    const data = {
      userId: parseInt(USER_ID()),
      createdBy: parseInt(USER_ID()),
      createdByApp: 0,
      dashboardCart: finalobject,
    };

    updatedashboardconfig(data);
  };

  const updatedashboardconfig = async (data) => {
    dispatch(getDashboardAction.updateUserDashboardCart(data));
  };

  const custmizebtnFun = () => {
    if (isVisible) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const handleCardClick = (itemId) => {
    if (itemId === "1") {
      navigate(CREDENTIAL_LOOKUP);
    } else if (itemId === "2") {
      navigate(OPEN_ITEMS_LIST);
    } else if (itemId === "3") {
      navigate(USERCOURSES);
    } else {
      navigate(ADMIN_RESOURCE);
    }
  };
  const [isDashboard, setIsDashboard] = useState(false);
  useEffect(() => {
    if (isDashboard == true) {
      navigate(SWDASHBOARD);
    }
  }, [isDashboard]);
  const createNews = () => {
    navigate(ADMIN_ADD_NEWS);
  };
  const listNews = () => {
    navigate(ADMIN_LIST_NEWS);
  };

  return (
    <>
      {isLoading || UserDashboardCartListIsloading || isLoadingD ? (
        <Loader />
      ) : (
        <section className="grid_main_sec">
          <div className="grid_main_body">
            <div className="commingsoon">
              <div className="headingcustomige">
                <h1 className="heading" div>
                  My Favorites
                </h1>

                <div onClick={custmizebtnFun} className="customizedash">
                  <p>Customize your dashboard</p>
                  <img src={customizeDashIcon} alt="Customize Icon"></img>
                </div>
              </div>
              <>
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  {Object.entries(columns).map(([id, columns]) => {
                    return (
                      <div key={id}>
                        {columns.name == "TODO" && isVisible ? (
                          <>
                            <div className="hrcard"></div>
                            <div className="allcards">
                              <p>All Cards</p>
                            </div>
                          </>
                        ) : (
                          <div></div>
                        )}
                        <Droppable
                          type="group"
                          className="dashboardBody1"
                          droppableId={id}
                          key={id}
                        >
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="dashboardBody1"
                            >
                              {columns.name == "Final" && (
                                <Grid item container lg={2.8}>
                                  {!dashboardTickets ? (
                                    <InComponentLoader
                                      style={{
                                        position: "relative",
                                        top: "50%",
                                        left: "50%",
                                      }}
                                    />
                                  ) : (
                                    <div className="graphCardBody">
                                      <DonoughtChart
                                        DonutChart
                                        dashboardTickets={dashboardTickets}
                                      />
                                      <div className="assignedDiv">
                                        <h1>
                                          {dashboardTickets.unassignedTickets
                                            ? Math.round(
                                                ((dashboardTickets.totalTickets -
                                                  dashboardTickets.unassignedTickets) /
                                                  dashboardTickets.totalTickets) *
                                                  100
                                              )
                                            : 0}
                                          %
                                        </h1>
                                        <p>Assigned</p>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              )}

                              {columns.name == "Final" && (
                                <>
                                  {checkPrivileges([12, 123, 124]) && (
                                    <div
                                      className="dashboardCardBody"
                                      style={{
                                        padding: 28,
                                        margin: "0 0 0 0",
                                        minHeight: "90px",
                                      }}
                                      onClick={
                                        isVisible == false
                                          ? () =>
                                              setShowJhaDashboardTable(
                                                !showJhaDashboardTable
                                              )
                                          : ""
                                      }
                                    >
                                      <div className="icon">
                                        <img
                                          src={locaibrary}
                                          alt="Open Items"
                                        ></img>
                                      </div>

                                      <div className="textdash">
                                        Hazard Analysis Due
                                      </div>

                                      <div className="number2">
                                        {
                                          getJhaSetupDashboardData?.data
                                            ?.listCount
                                        }
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {columns.items.map((store, index) => (
                                <Draggable
                                  draggableId={store?.cartId.toString()}
                                  key={store.cartId}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      className="dashboardCardBody"
                                      style={{
                                        userSelect: "none",
                                        padding: 28,
                                        margin: "0 0 0 0",
                                        minHeight: "90px",

                                        ...provided.draggableProps.style,
                                      }}
                                      onClick={
                                        isVisible == false
                                          ? () => handleCardClick(store?.cartId)
                                          : ""
                                      }
                                    >
                                      <div className="icon">
                                        <img
                                          src={locaibrary}
                                          alt="Open Items"
                                        ></img>
                                      </div>

                                      <div className="textdash">
                                        {store.cartName}
                                      </div>
                                      <div className="number2">
                                        {store.count}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    );
                  })}
                </DragDropContext>
              </>
            </div>
          </div>
          {showJhaDashboardTable && (
            <JHADashboardTable
              itemsCount={getJhaSetupDashboardData?.data?.listCount ?? 0}
              itemList={getJhaSetupDashboardData?.data?.jhaDashboardList ?? []}
              isLoading={getJhaSetupDashboardData?.isLoading}
            />
          )}
          <>
            {" "}
            <div className="headingcustomigeCreateNews">
              <h1 className="heading1">Trending News</h1>
              {checkPrivileges([12, 87, 88]) && (
                <div onClick={createNews} className="customizedash">
                  <p>Create News/Announcement</p>
                  <img src={customizeDashIcon} alt="Customize Icon"></img>
                </div>
              )}
            </div>
            <Suspense fallback={<div>please wait.....</div>}>
              <NewsList NewsData={NewsData} />
            </Suspense>
            <Grid container item justifyContent="right" lg={12} mt={5}>
              <div className="seeall" onClick={listNews}>
                <p>See All</p>
              </div>
            </Grid>
          </>
          {/* )} */}

          <Grid item container lg={12}>
            <Grid lg={3} mt={6}>
              {isVisible && (
                <Button
                  varientContained={true}
                  disabled={isVisible ? false : true}
                  label={"save"}
                  className="cusmizebutton"
                  onClick={(e) => handlesubmit()}
                />
              )}
            </Grid>
          </Grid>
        </section>
      )}
    </>
  );
};

export default Dashboard;
