import React from "react";
import { useSelector } from "react-redux";

import ReportSafetylogList from "../component/reportSafetylogList";

function ReportSafetylogListContainer({
  tabId,
  getItemLogData,
  startDate,
  endDate,
  project,
  divison,
  setIncSearchText,
  incSearchText,
}) {
  const isGetItemLogLoading = useSelector(
    (state) => state.getItemList?.isLoading
  );
  const itemLogData = useSelector((state) => state.getItemList?.data?.result);
  return (
    <ReportSafetylogList
      itemLogData={itemLogData}
      isGetItemLogLoading={isGetItemLogLoading}
      tabId={tabId}
      getItemLogData={getItemLogData}
      startDate={startDate}
      endDate={endDate}
      project={project}
      divison={divison}
      incSearchText={incSearchText}
      setIncSearchText={setIncSearchText}
    />
  );
}

export default ReportSafetylogListContainer;
