import React from "react";
import "./addNewRoleForm.scss";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import NormalGrid from "../../../../library/custom/gridTable/NormalGrid";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import Buttons from "../../../../library/custom/button/Button";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../../../library/custom/textBox/InputField";
import TextArea from "../../../../library/custom/textArea/TextArea";
import { ORG_ID, SERVICE_TYPE } from "../../../../constant/constant";
import Checkbox from "../../../../library/checkbox/checkbox";
import { checkPrivileges } from "../../../../utils/rolesHelper";

export default function AddNewRoleForm({
  roleListData,
  isLoading,
  adddRoles,
  editMode,
  roleId,
  roleListDetsilsData,
}) {
  const [rolename, setRoleName] = useState("");
  const [selectedrow, setselectedRow] = useState([]);
  const [textValue, setTextValue] = useState("");
  const [roletitle, setTitleRole] = useState("");
  const [checkBox, setcheckBox] = useState(false);
  const [checkRoleBox, setCheckRoleBox] = useState(false);

  useEffect(() => {
    if (roleListDetsilsData?.data && editMode) {
      setRoleName(roleListDetsilsData?.data?.name);
      setselectedRow(roleListDetsilsData?.data?.privilegeId);
      setTextValue(roleListDetsilsData?.data?.description);
      setTitleRole(roleListDetsilsData?.data?.title);

      setroleId(roleListDetsilsData?.data?.roleId);
      setcheckBox(roleListDetsilsData?.data?.isGlobal);
      setCheckRoleBox(roleListDetsilsData?.data?.isClientDefaultRole);
      setSelectionModel(
        getDifference(
          roleListData?.roleData,
          roleListDetsilsData?.data?.privileges
        )
      );
    }
  }, [roleListDetsilsData?.data]);

  const history = useNavigate();

  const [isRoleNameValueEmpty, setIsRoleNameValueEmpty] = useState(false);
  const [roleIds, setroleId] = useState(0);
  const onSubmitData = (event) => {
    event?.stopPropagation();
    if (rolename == "") {
      setIsRoleNameValueEmpty(true);
      // setClientRoleExistError(true);
      return false;
    }
    setIsRoleNameValueEmpty(false);

    const sendData = {
      productId: [parseInt(SERVICE_TYPE())],
      title: roletitle,
      lngId: 1,
      privilegeIds: selectionModel,
      organisationId: ORG_ID(),
      createdBy: localStorage.getItem("userId"),
      createdByApp: 1,
      description: textValue,
      name: rolename,
      roleId: roleIds ? roleId : 0,
      isGlobal: checkBox ? true : false,
      isClientDefaultRole: checkRoleBox ? true : false,
    };

    adddRoles(sendData);
  };

  const credentialsColumns = [
    { field: "privilegeId", headerName: "Id", flex: 1 },
    { field: "name", headerName: "Privilege Name", width: 250 },
    { field: "title", headerName: "Title", width: 200 },
    { field: "description", headerName: "Description", flex: 5 },
  ];

  function getDifference(array1, array2) {
    if (array1 && array2) {
      let filterIds = [];
      array1.filter((object1) => {
        array2.some((object2) => {
          if (object1.privilegeId === object2.key) {
            filterIds.push(object2.key);
          }
        });
      });
      return filterIds;
    }
  }

  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  return (
    <>
      <div className="overidePersonal">
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">
            {editMode ? "Manage Roles" : "Add New Role"}
          </div>
        </Grid>
      </div>

      <Grid container item md={12} xs={12} className="addShadowBox">
        <Grid item container spacing={2}>
          <Grid item lg={6} xs={12} sm={12} md={6} mt={3}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                isRequired={true}
                disabled={false}
                type="text"
                label="Role Name"
                placeholder={"Add Role Name Text Here..."}
                value={rolename}
                onChange={(e) => setRoleName(e.target.value)}
              />
            </FormControl>
            {isRoleNameValueEmpty && rolename.length <= 0 ? (
              <p className="errorMsg">Please enter this required field</p>
            ) : (
              false
            )}
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={6} mt={3}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                isRequired={false}
                disabled={false}
                placeholder={"Add Role Title Text Here..."}
                type="text"
                label="Role Title"
                value={roletitle}
                onChange={(e) => setTitleRole(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        {checkPrivileges([12]) && (
          <Grid container>
            <Grid item lg={2} xs={12} sm={12} md={4} mt={2}>
              <div className="typeTxt">
                <Checkbox
                  checked={checkBox}
                  onChange={() => setcheckBox(!checkBox)}
                  label={"Global Role"}
                />
              </div>
            </Grid>
            <Grid item lg={2} xs={12} sm={12} md={4} mt={2}>
              <div className="typeTxt">
                <Checkbox
                  checked={checkRoleBox}
                  onChange={() => setCheckRoleBox(!checkRoleBox)}
                  label={"Client Role"}
                  // error={clientRoleExistError}
                />
              </div>
            </Grid>
          </Grid>
        )}

        <Grid item container mt={3} spacing={2}>
          <Grid item lg={12} md={12} xs={12} pl={2}>
            <FormControl fullWidth>
              <FormLabels label="Privilege Description" />
              <TextArea
                placeholder={"Add Privilege Description Text Here..."}
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container mt={3} spacing={2} pl={4} pr={2}>
          <FormControl fullWidth>
            <FormLabels label={"Select Access:"} isRequired={false} />
            <Grid lg={12}>
              <div className="desc_box row_uniq">
                <div
                  className="table_style_ui"
                  style={{ height: 400, width: "100%", paddingTop: 25 }}
                >
                  <NormalGrid
                    loading={isLoading}
                    getRowId={(r) => r.privilegeId}
                    rowss={roleListData?.roleData ?? []}
                    columnss={credentialsColumns}
                    rowCount={roleListData?.itemsCount}
                    selectionModel={selectionModel}
                    onSelectionModelChange={(e) => {
                      setSelectionModel(e);
                      const selectedIDs = new Set(e);
                      const selectedRows = roleListData?.roleData.filter((r) =>
                        selectedIDs.has(r.id)
                      );
                      setSelectedRows(selectedRows);
                    }}
                  ></NormalGrid>
                </div>
              </div>
            </Grid>
          </FormControl>
        </Grid>
        <Grid container spacing={3} pl={3} pr={3} className="addnewrolecls">
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left"></Grid>
          <Grid item lg={12} xs={12} sm={12} md={4} mt={1}>
            <div className="save_btn_with_back">
              <div className=""></div>
              <div>
                <div className="rightSideBtn">
                  <div
                    className="cancle_heading_style"
                    onClick={() => history(-1)}
                  >
                    Cancel
                  </div>
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={editMode ? "Update" : "Save"}
                    onClick={() => onSubmitData()}
                    varientContained={true}
                  ></Buttons>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
