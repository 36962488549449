import { useState, useEffect } from "react";

const useDebounce = (value, delay = 500) => {
  const [debounceValue, setDebounceValue] = useState(value);
  useEffect(() => {
    const timers = setTimeout(() => {
      setDebounceValue(value);
    }, delay);
    return () => {
      clearTimeout(timers);
    };
  }, [value, delay]);
  return debounceValue;
};

export default useDebounce;
