import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FromToDatePicker from "../../../library/custom/datePicker/DatePicker";
import { Card, CardContent, Box, CardActions } from "@mui/material";
import Buttons from "../../../library/custom/button/Button";
import Grid from "@mui/material/Grid";
import { getFormatedDate } from "../../../utils/helper";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import {
  INCIDENT_STATUS,
  INCIDENT_STATUS_Key_VALUE,
} from "../../../constant/constant";
import commonImages from "../../../assets";
import "./FilterIncidentList.scss";
const FilterIncidentList = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleSearch,
  filterType,
  resetFilterAPI,
  setAnchorEl
}) => {
  const [filter, setFilter] = useState({
    jobsite: "",
    startDate: "",
    endDate: "",
    searchType: "",
    incidentType: "",
    status: "",
  });

  const resetFilter = () => {
    setFilter({
      jobsite: "",
      startDate: null,
      endDate: null,
      searchType: "",
      incidentType: "",
      status: "",
    });
    setAnchorEl(null);
    resetFilterAPI();
  };

  console.log("filter", filter);

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases filter_mui_1">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Box className="" sx={{ pb: 2 }}>
                  <Typography className="filterLabel" gutterBottom variant="h5">
                    Incident Type
                  </Typography>
                </Box>
                <Box>
                  <FormGroup>
                    {filterType &&
                      filterType.map((item) => (
                        <FormControlLabel
                          key={item.id}
                          control={
                            <img
                              className="filterListCheckbox"
                              checked={
                                item.id == filter.incidentType ? true : false
                              }
                              alt=""
                              src={
                                item.id == filter.incidentType
                                  ? commonImages.check
                                  : commonImages.noCheck
                              }
                              onClick={() =>
                                setFilter((prevState) => ({
                                  ...prevState,
                                  incidentType: item.id,
                                }))
                              }
                            />
                          }
                          label={item?.title}
                        />
                      ))}
                  </FormGroup>
                </Box>
              </Grid>
              <Grid item md={7}>
                <Box className="" sx={{ pb: 2 }}>
                  <Typography className="filterLabel" gutterBottom variant="h5">
                    Time Period
                  </Typography>
                  <Box className="datePicker_row">
                    <div>
                      <Typography variant="subtitle1">From</Typography>
                      <FromToDatePicker
                        value={filter.startDate}
                        onChangeHandler={(value) =>
                          setFilter((prevState) => ({
                            ...prevState,
                            startDate: getFormatedDate(value),
                          }))
                        }
                      />
                    </div>
                    <div>
                      <Typography variant="subtitle1">To</Typography>
                      <FromToDatePicker
                        value={filter.endDate}
                        onChangeHandler={(value) =>
                          setFilter((prevState) => ({
                            ...prevState,
                            endDate: getFormatedDate(value),
                          }))
                        }
                      />
                    </div>
                  </Box>
                </Box>
                <Box className="" sx={{ pb: 2 }}>
                  <Typography className="filterLabel" gutterBottom variant="h5">
                    Status
                  </Typography>
                  <Box>
                    <FormGroup>
                      {INCIDENT_STATUS_Key_VALUE.map((filterItem, index) => (
                        <FormControlLabel
                          key={filterItem.Value}
                          control={
                            <img
                              className="filterListCheckbox"
                              checked={
                                filter.status == filterItem.value ? true : false
                              }
                              alt=""
                              src={
                                filter.status == filterItem.value
                                  ? commonImages.check
                                  : commonImages.noCheck
                              }
                              onClick={() =>
                                setFilter((prevState) => ({
                                  ...prevState,
                                  status: filterItem.value,
                                }))
                              }
                            />
                          }
                          label={filterItem.key}
                        />
                      ))}
                      {/* <FormControlLabel
                        key={INCIDENT_STATUS.SUBMITTED}
                        control={
                          <img
                            className="filterListCheckbox"
                            checked={
                              filter.status == INCIDENT_STATUS.SUBMITTED
                                ? true
                                : false
                            }
                            alt=""
                            src={
                              filter.status == INCIDENT_STATUS.SUBMITTED
                                ? commonImages.check
                                : commonImages.noCheck
                            }
                            onClick={() =>
                              setFilter((prevState) => ({
                                ...prevState,
                                status: INCIDENT_STATUS.SUBMITTED,
                              }))
                            }
                          />
                        }
                        label={INCIDENT_STATUS.SUBMITTED}
                      />
                      <FormControlLabel
                        key={INCIDENT_STATUS.CLOSED}
                        control={
                          <img
                            className="filterListCheckbox"
                            checked={
                              filter.status == INCIDENT_STATUS.CLOSED
                                ? true
                                : false
                            }
                            alt=""
                            src={
                              filter.status == INCIDENT_STATUS.CLOSED
                                ? commonImages.check
                                : commonImages.noCheck
                            }
                            onClick={() =>
                              setFilter((prevState) => ({
                                ...prevState,
                                status: INCIDENT_STATUS.CLOSED,
                              }))
                            }
                          />
                        }
                        label={INCIDENT_STATUS.CLOSED}
                      />
                      <FormControlLabel
                        key={INCIDENT_STATUS.DRAFT}
                        control={
                          <img
                            className="filterListCheckbox"
                            checked={
                              filter.status == INCIDENT_STATUS.DRAFT
                                ? true
                                : false
                            }
                            alt=""
                            src={
                              filter.status == INCIDENT_STATUS.DRAFT
                                ? commonImages.check
                                : commonImages.noCheck
                            }
                            onClick={() =>
                              setFilter((prevState) => ({
                                ...prevState,
                                status: INCIDENT_STATUS.DRAFT,
                              }))
                            }
                          />
                        }
                        label={INCIDENT_STATUS.DRAFT}
                      /> */}
                    </FormGroup>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Buttons primary={true} label={"Reset"} onClick={resetFilter} />
            <Buttons
              primary={true}
              label={"Apply"}
              onClick={(e) => handleSearch(e, filter)}
            />
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default FilterIncidentList;
