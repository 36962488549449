import React from "react";
import SetupSiteProjectsList from "../components/setupSiteProjectsList";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_SITE_PROJECTS_LIST,
  ADMIN_PROJECTS_LIST,
} from "../../../../constant/routeContant";
import { LOGIN_ORG_ID } from "../../../../constant/constant";

function SetupSiteProjectsListContainer() {
  const navigate = useNavigate();
  const title =
    LOGIN_ORG_ID() == 1 ? "Setup Sites and Projects" : "Setup Projects";

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(ADMIN_SITE_PROJECTS_LIST);
    }
    if (id === 2) {
      navigate(ADMIN_PROJECTS_LIST);
    }
  };

  return (
    <>
      <div className="dashTitle">{title}</div>
      <SetupSiteProjectsList onClick={onClickHandler} />
    </>
  );
}

export default SetupSiteProjectsListContainer;
