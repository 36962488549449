import React from "react";
import Sidebar from "../../../library/sidebar/sidebar";
import Navbar from "../../../library/navbar/navbar";
import ContactList from "../component/contacts/contactList";
import * as contactsAction from "../../../redux/actions/contactsAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS, PAGE_SIZE } from "../../../constant/constant";
import {
  ADMIN_ADD_CONTACTS,
  ADMIN_EDIT_CONTACT,
  ADMIN_VIEW_CONTACT,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";

export default function ContactsContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedServiceTypeData = localStorage?.getItem("serviceType");
  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    statusIds: "",
    compnayName: "",
    search: "",
    // productId: selectedServiceTypeData,
  };

  const contacts = useSelector((state) => state.contactList);
  const getContactListData = (data) => {
    dispatch(contactsAction.getContactList(data));
  };
  const isLoading = useSelector(
    (state) => state.contactList?.isLoading || state.deleteContacts?.isLoading
  );
  const deleteContacts = useSelector((state) => state.deleteContacts);

  useEffect(() => {
    getContactListData(defaultData);
  }, []);

  const addContact = () => {
    navigate(ADMIN_ADD_CONTACTS);
  };
  const handleDeleteOnClick = (contactId) => {
    dispatch(contactsAction.deleteContacts(contactId));
  };

  const handleEditOnClick = (contactId, statusId) => {
    navigate(ADMIN_EDIT_CONTACT, {
      state: { contactId: contactId, statusId, editMode: true },
    });
  };

  const handleViewOnClick = (contactId) => {
    navigate(ADMIN_VIEW_CONTACT, {
      state: { contactId: contactId },
    });
  };

  useEffect(() => {
    if (deleteContacts?.status === API_STATUS.SUCCESS) {
      getContactListData(defaultData);
    }
  }, [deleteContacts]);

  return (
    <>
      <ContactList
        contacts={isLoading ? [] : contacts?.data}
        getContactListData={getContactListData}
        isLoading={isLoading}
        addContact={addContact}
        handleDeleteOnClick={handleDeleteOnClick}
        handleViewOnClick={handleViewOnClick}
        handleEditOnClick={handleEditOnClick}
        selectedServiceTypeData={selectedServiceTypeData}
      />
    </>
  );
}
