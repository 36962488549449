import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";

import {
  INSPECTION_STATUS,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
  HBNEXT_ORG_ID,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import { getFormatedDate } from "../../../../utils/helper";
import CommentClose from "./commentClose";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";

const OpenItemList = (props) => {
  const adminView = checkPrivileges([12, 105]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [commentCloseBox, setCommentCloseBox] = useState(false);
  const [statusIds, setStatusIds] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const autoComp = useRef(null);
  const [projects, setProjects] = useState();
  const [projectId, setProjectId] = useState();
  const searchClient = props?.searchClient;
  const searchAllClientsReducerData = props?.searchAllClientsReducerData;
  const autoSearchDataLoading = props?.autoSearchDataLoading;

  const [changesearchauto, setchangesearchauto] = useState();
  const autoCompS = useRef(null);
  const isShowClient =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && checkPrivileges([12, 17]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const [filter, setFilter] = useState({
    searchType: [],
  });

  const columns = [
    {
      field: "itemId",
      headerName: "Item ID",
      // flex: 1,
    },
    {
      field: "projectNumber",
      headerName: "Project Number",
      // flex: 1,
    },
    {
      field: "projectName",
      headerName: "Project",
      // flex: 1,
    },
    {
      field: "itemFound",
      headerName: "Item Found",
      // flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      // flex: 1,
    },
    {
      field: "inspectionId",
      headerName: "Inspection Id",
      // flex: 1,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      renderCell: (rows) => {
        return (
          <>
            {getFormatedDate(rows?.row?.createdOn)}
          </>
        );
      },
      // flex: 1,
    },
    {
      field: "dateClosed",
      headerName: "Date Closed",
      // flex: 1,
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.dateClosedFormat? rows?.row?.dateClosedFormat :"N/A"}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.statusId === INSPECTION_STATUS?.Open
              ? "Open"
              : rows?.row?.statusId === INSPECTION_STATUS?.Closed
              ? "Closed"
              : ""}
          </>
        );
      },
      // flex: 1,
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={
            props?.cartType === 1 || parseInt(statusIds) === 1
              ? props?.openItemListDetail?.totalOpenItems
              : props?.cartType === 2 || parseInt(statusIds) === 2
              ? props?.openItemListDetail?.totalClosedItems
              : props?.cartType === 3
              ? props?.openItemListDetail?.totalFourDaysOpen
              : props?.cartType === 4
              ? props?.openItemListDetail?.totalSevenDaysOpen
              : props?.cartType === 5
              ? props?.openItemListDetail?.totalMoreThanSevenDaysOpen
              : ""
          }
          numberOfItemsPerPage={10}
          incidentLists={props?.openItemListDetail?.listDetails ?? []}
          fileNameXL={"openItemList"}
        />
      ),
      // flex: 0.7,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return <></>;
      },
    },
  ];

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      organisationId: props?.clients
        ? props?.clients
        : props?.stateData?.customerId
        ? props?.stateData?.customerId
        : parseInt(ORG_ID()),
      cartType: props?.cartType,
      search: search ? search : "",
      projectId: projectId ? projectId : 0,
      userId: adminView ? "" : parseInt(USER_ID()),
      fromDate:
        props?.calculatedDate !== ""
          ? getFormatedDate(props?.calculatedDate)
          : "",
      toDate: getFormatedDate(props?.todayDate),
      page: newPage + 1,
      pageSize: pageSize,
    };
    props?.getOpenItemList(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      organisationId: props?.clients
        ? props?.clients
        : props?.stateData?.customerId
        ? props?.stateData?.customerId
        : parseInt(ORG_ID()),
      cartType: props?.cartType,
      search: search ? search : "",
      projectId: projectId ? projectId : 0,
      userId: adminView ? "" : parseInt(USER_ID()),
      fromDate:
        props?.calculatedDate !== ""
          ? getFormatedDate(props?.calculatedDate)
          : "",
      toDate: getFormatedDate(props?.todayDate),
      page: page,
      pageSize: newPazeSize,
    };
    props?.getOpenItemList(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      organisationId: props?.clients
        ? props?.clients
        : props?.stateData?.customerId
        ? props?.stateData?.customerId
        : parseInt(ORG_ID()),
      cartType: props?.cartType,
      search: event.target.value,
      projectId: projectId ? projectId : 0,
      userId: adminView ? "" : parseInt(USER_ID()),
      fromDate: props?.calculatedDate
        ? getFormatedDate(props?.calculatedDate)
        : "",
      toDate: props?.todayDate ? getFormatedDate(props?.todayDate) : "",
      page: page,
      pageSize: pageSize,
    };
    props?.getOpenItemList(data);
  };
  useEffect(() => {
    if (props?.selectionModel?.length > 0) {
      setCommentCloseBox(true);
    } else {
      setCommentCloseBox(false);
    }
  }, [props?.selectionModel]);

  const handleApply = () => {
    const data = {
      organisationId: props?.stateData?.customerId
        ? props?.stateData?.customerId
        : parseInt(ORG_ID()),
      cartType: statusIds,
      search: "",
      projectId: projectId ? projectId : 0,
      userId: adminView ? "" : parseInt(USER_ID()),
      fromDate: getFormatedDate(props?.calculatedDate),
      toDate: getFormatedDate(props?.todayDate),
      page: 1,
      pageSize: pageSize,
    };
    props?.getOpenItemList(data);
    props?.setCartType(statusIds);
    handleClose();
  };

  const handleClear = () => {
    setStatusIds("");
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      prevStates.searchType = [];
      return prevStates;
    });
    const dataClear = {
      organisationId: props?.stateData?.customerId
        ? props?.stateData?.customerId
        : parseInt(ORG_ID()),
      cartType: props?.cartType,
      search: "",
      projectId: projectId ? projectId : 0,
      userId: adminView ? "" : parseInt(USER_ID()),
      fromDate: getFormatedDate(props?.calculatedDate),
      toDate: getFormatedDate(props?.todayDate),
      page: 1,
      pageSize: pageSize,
    };
    props?.getOpenItemList(dataClear);
    handleClose();
  };

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
        setStatusIds(item?.key);
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };

  const onChangeProjects = (value) => {
    setProjects(value);
    setProjectId(value?.key);
    const dataProjectBased = {
      organisationId: props?.clients
        ? props?.clients
        : props?.stateData?.customerId
        ? props?.stateData?.customerId
        : parseInt(ORG_ID()),
      cartType: props?.cartType,
      search: "",
      userId: adminView ? "" : parseInt(USER_ID()),
      fromDate: props?.calculatedDate
        ? getFormatedDate(props?.calculatedDate)
        : "",
      toDate: getFormatedDate(props?.todayDate),
      projectId: value?.key ? value?.key : 0,
      page: 1,
      pageSize: pageSize,
    };
    props?.getOpenItemList(dataProjectBased);
  };

  useEffect(() => {
    if (props?.stateData && props?.calculatedDate) {
      setProjects({
        key: props?.stateData?.projectId,
        value: props?.stateData?.projectName,
      });
      setProjectId(props?.stateData?.projectId);

      const data = {
        organisationId: props?.clients
          ? props?.clients
          : props?.stateData?.customerId
          ? props?.stateData?.customerId
          : parseInt(ORG_ID()),
        cartType: props?.cartType,
        search: "",
        userId: adminView ? "" : parseInt(USER_ID()),
        fromDate:
          props?.timeRange !== 1 ? getFormatedDate(props?.calculatedDate) : "",
        toDate: getFormatedDate(props?.todayDate),
        projectId: projectId
          ? projectId
          : props?.stateData?.projectId
          ? props?.stateData?.projectId
          : 0,
        page: 1,
        pageSize: pageSize,
      };
      props?.getOpenItemList(data);
    }
  }, [props?.stateData, props?.calculatedDate, props?.timeRange]);
  const typeOnchange = (event, value) => {
    if (event.target?.value?.trim()) {
      searchClient(event.target?.value?.trim());
    }
  };

  const onSelectSearchCustomer = (e, value, s) => {
    //setchangesearchauto({ key: value?.key ?? "", value: value?.value ?? "" });
    setchangesearchauto(value);
    props?.setClients(value?.key);

    const dataProjectBased = {
      organisationId: value?.key ? value?.key : parseInt(ORG_ID()),
      cartType: props?.cartType,
      search: "",
      userId: adminView ? "" : parseInt(USER_ID()),
      fromDate: props?.calculatedDate
        ? getFormatedDate(props?.calculatedDate)
        : "",
      toDate: getFormatedDate(props?.todayDate),
      projectId: projectId ? projectId : 0,
      page: 1,
      pageSize: pageSize,
    };
    props?.getOpenItemList(dataProjectBased);
  };
  return (
    <>
      <Grid container mt={8}>
        <Grid md={12} item>
          <div className="grid_main_body">
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search Open Item"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>

              {/*  todo*/}
              {/* <div className="filter_btn">
                <button
                  aria-describedby={id}
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClick(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>

                <FilterCommonStatusSelect
                  // id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  statusType={ITEM_STATUS}
                  handleApply={handleApply}
                  handleClear={handleClear}
                  filter={filter}
                  onChangeType={onChangeType}
                  statusIds={statusIds}
                  filterSelect={false}
                  filterStatus={true}
                  resetBtn={true}
                />
              </div> */}
            </div>
            {/* TODO className="openItemSw"  for cancel icon redirection class*/}
            <Grid container>
              {isShowClient && (
                <Grid item lg={5} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="Client" isRequired={false} />
                    <SearchAutoComplete
                      name="Customer"
                      optionsData={
                        autoSearchDataLoading
                          ? []
                          : searchAllClientsReducerData?.data ?? []
                      }
                      typeOnchange={(event, value) =>
                        typeOnchange(event, value)
                      }
                      isKeyValuePair={true}
                      value={changesearchauto}
                      onChange={(event, value, key) =>
                        onSelectSearchCustomer(event, value, key)
                      }
                      isLoading={autoSearchDataLoading}
                      autoCompClear={autoCompS}
                      noOptionsTextMsg="No Client"
                      placeholderText="Search by Client"
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item md={5} xs={12} mb={5} ml={1}>
                <FormControl fullWidth>
                  <FormLabels label="Project" isRequired={false} />
                  <SearchAutoComplete
                    name="Add Individual Users"
                    optionsData={props?.allProjects ?? []}
                    typeOnchange={(event, value) =>
                      props?.handleAllProjectSearch(event, value)
                    }
                    isKeyValuePair={true}
                    value={projects}
                    onChange={(e, value) => onChangeProjects(value)}
                    isLoading={props?.isProjectLoading}
                    autoCompClear={autoComp}
                    noOptionsTextMsg={"No Project"}
                    placeholderText="Search by Project"
                  />
                </FormControl>
              </Grid>
            </Grid>

            <div className="desc_box row_uniq">
              <div className="table_style_ui openItemTable">
                <GridTable
                  getRowId={(r) => r.itemId}
                  rows={props?.openItemListDetail?.listDetails ?? []}
                  rowCount={
                    props?.cartType === 1 || parseInt(statusIds) === 1
                      ? props?.openItemListDetail?.totalOpenItems
                      : props?.cartType === 2 || parseInt(statusIds) === 2
                      ? props?.openItemListDetail?.totalClosedItems
                      : props?.cartType === 3
                      ? props?.openItemListDetail?.totalFourDaysOpen
                      : props?.cartType === 4
                      ? props?.openItemListDetail?.totalSevenDaysOpen
                      : props?.cartType === 5
                      ? props?.openItemListDetail?.totalMoreThanSevenDaysOpen
                      : ""
                  }
                  columns={columns}
                  checkboxSelection
                  loading={props?.isLoading}
                  page={1}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  onSelectionModelChange={props?.handleSelectionModelChange}
                />
              </div>
            </div>
            {commentCloseBox && (
              <CommentClose
                disbled={props?.disbled}
                isComment={props?.isComment}
                commentFormValue={props?.commentFormValue}
                setCommentFormValue={props?.setCommentFormValue}
                submitCommentData={props?.submitCommentData}
                commentBox={props?.commentBox}
                closeBox={props?.closeBox}
                closeItemHandler={props?.closeItemHandler}
                commentHandler={props?.commentHandler}
                cancelComment={props?.cancelComment}
                cancelClose={props?.cancelClose}
                isDateClosed={props?.isDateClosed}
                isActionTaken={props?.isActionTaken}
                closeFormValue={props?.closeFormValue}
                setCloseFormValue={props?.setCloseFormValue}
                submitCloseData={props?.submitCloseData}
                selectedRows={props?.selectedRows}
                cartType={props?.cartType}
                handleFileChange={props?.handleFileChange}
                closesFilesData={props?.closesFilesData}
                handleDeleteImageURL={props?.handleDeleteImageURL}
                isMedia={props?.isMedia}
                getSwReference={props?.getSwReference}
                openItemHandler={props?.openItemHandler}
                isClosedIdError={props?.isClosedIdError}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default OpenItemList;
