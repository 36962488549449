import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterList from "./FilterList";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Grid from "@mui/material/Grid";
import FilterButton from "../../../../library/custom/filterButton/filterButton";

const ReportFilter = ({
  filterType,
  handleInputSearchByText,
  handleClose,
  anchorEl,
  handleInputSearchByTextState,
  handleInputSearch,
  searchByTextName,
  searchByText,
  handleSearchFilter,
  typeSearchLabel,
  isTypeSearchLabel,
  isStatus,
  statusLabel,
  sattusListData,
  statusOfList,
  handleClear,
  ...rest
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="filter_of_caselist">
        <Grid container spacing={2}>
          {searchByText && (
            <Grid item md={8}>
              <div>
                <InputField
                  type={"text"}
                  onChange={(e) => handleInputSearchByText(e)}
                  name={searchByTextName}
                  value={handleInputSearchByTextState ?? ""}
                  placeholder={searchByTextName}
                  disabled={false}
                  isError={false}
                  errorMsg={""}
                  isIcon={true}
                />
              </div>
            </Grid>
          )}

          <Grid item>
            <div className="filter_btn">
            <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClose(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              {/* <FilterButton
                label={"Filters"}
                handleClick={(e) => handleClose(e)}
              /> */}
              <FilterList
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleSearch={(event, state) =>
                  handleSearchFilter(event, state)
                }
                filterType={filterType ?? []}
                typeSearchLabel={typeSearchLabel}
                isTypeSearchLabel={isTypeSearchLabel}
                isStatus={isStatus}
                statusLabel={statusLabel}
                sattusListData={sattusListData}
                statusOfList={statusOfList}
                handleClear={handleClear}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ReportFilter;
