import React, { useEffect } from "react";
import SwInspectionReports from "../components/SwInspectionReports";
import { useDispatch } from "react-redux";
import * as clientAction from "../../../../redux/actions/clientAction";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useSelector } from "react-redux";
import {
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  PAGE_SIZE,
} from "../../../../constant/constant";
import * as swCommonAction from "../../../../redux/storm-water/actions/swCommonAction";

function SwInspectionReportsContainer() {
  const orgId = localStorage.getItem("orgId");
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state.allUsers);
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };
  const allClients = useSelector((state) => state.searchAllClientsReducerData);
  const isSearchClientLoading = allClients.isLoading;

  const getSwInspectionList = useSelector((state) => state.getSwInspectionList);
  const getSwInspectionListData = (data) => {
    dispatch(swInspectionAction.getSwInspectionList(data));
  };

  const getSwReference = useSelector((state) => state.getSwReference);
  const getSwReferenceData = (data) => {
    dispatch(swCommonAction.getSwReference(data));
  };

  const refData = {
    flags:[3, 4],
  };

  useEffect(() => {
    getSwReferenceData(refData);
  }, []);
  const defaultData = {
    search: "",
    inspectionTypeId: 0,
    frequencyId:"",
    projectId: 0,
    customerId: LOGIN_ORG_ID() === parseInt(HBNEXT_ORG_ID) ? 1 : orgId,
    statusId: 5,
    startDate: "",
    endDate: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: "",
  };

  useEffect(() => {
    getSwInspectionListData(defaultData);
  }, []);
  const autoSearchDataLoading =
    allUsers?.isLoading | getSwInspectionList?.isLoading;
  return (
    <>
      <SwInspectionReports
        autoSearchDataLoading={autoSearchDataLoading}
        allClients={allClients}
        searchClient={searchClient}
        allUsers={allUsers?.data?.users}
        getSwInspectionList={getSwInspectionList?.data}
        getSwInspectionListData={getSwInspectionListData}
        isSearchClientLoading={isSearchClientLoading}
        inspectionTypeValue={getSwReference?.data}
      />
    </>
  );
}

export default SwInspectionReportsContainer;
