import { useEffect, useRef, useState } from "react";
import validator from "validator";
import commonImages from "../../assets";
import WelcomeImage from "../../assets/welcome.png";
import Button from "../../library/button/button";
import Footer from "../../library/footer/footer";
import OtpBox from "../../library/otp-box/otp-box";
import Loader from "../../library/common/Loader";
import { maxLengthCheck } from "../../utils/helper";
import PasswordStrengthBar from "react-password-strength-bar";
import "./signup.scss";
import { useDispatch, useSelector } from "react-redux";
import * as signupActions from "../../redux/actions/signupAction";
import { COUNTRY_CODE } from "../../constant/constant";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { logos } from "../../assets";

const Signup = () => {
  const [mobileVerified, setMobileVerified] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileLength, setMobileLength] = useState("");
  const [toc, setToc] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();
  const [pwdpopMsg, setPwdpopMsg] = useState(false);
  const [pwdMsg, setPwdMsg] = useState("");

  const [checks, setChecks] = useState({
    capsLettercheck: false,
    numberCheck: false,
    pwdlength: false,
    smalllettercheck: false,
    specchar: false,
  });

  const goToLogin = () => {
    // dispatch(loginActions.clearErrors());
    setErrorMsg("");
    // navigate("/login", { replace: true });
    window.location.href = "/login";
  };
  const signupData = useSelector((state) => state.signup);
  /* Initially everything is invalid */
  let defaultErrorStates = {
    firstName: true,
    lastName: true,
    email: true,
    password: true,
    mobile: true,
    mobileLength: true,
    toc: true,
  };

  /*
Dynamically update the
form whenever user inputs stuff
*/
  const untouchedForm = useRef(true);
  const isFormValid = useRef(false);
  const [errorList, setErrorList] = useState(defaultErrorStates);

  const style = {
    boxShadow: "rgb(227 195 195) 3px 3px 20px",
    border: "2px rgb(238, 238, 238)",
    padding: "20px",
    marginTop: "-145px",
    marginRight: "-415px",
    position: "absolute",
    marginLeft: "232px",
    backgroundColor: "#fff",
    borderRadius: "4px",
  };

  const validateForm = () => {
    var capsClass = checks.capsLettercheck ? "valid" : "invalid";
    var numcheckClass = checks.numberCheck ? "valid" : "invalid";
    var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
    var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
    var specClass = checks.specchar ? "valid" : "invalid";
    var passwodval = false;
    if (
      capsClass === "valid" &&
      numcheckClass === "valid" &&
      pwdlenClass === "valid" &&
      sletterClass === "valid" &&
      specClass === "valid" &&
      password.trim().length !== 0
    ) {
      passwodval = true;
    }
    let newErrorList = {
      ...errorList,
      firstName: firstName.trim().length !== 0,
      lastName: lastName.trim().length !== 0,
      password: passwodval,
      email: validator.isEmail(email),
      mobile: mobile.trim().length !== 0,
      mobileLength: mobile.trim().length >= 10,
      toc: toc,
    };
    setErrorList({ ...newErrorList });
    /* This validation is not based on state */
    let temporaryValidation = true;
    for (const key in newErrorList) {
      temporaryValidation = temporaryValidation && newErrorList[key];
    }
    /*
Saving it in this ref as well for
when we don't want to revalidate the
whole thing
*/
    isFormValid.current = temporaryValidation;
    return temporaryValidation;
  };

  useEffect(() => {
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);
      setPwdMsg(true);
    } else {
      setPwdpopMsg(true);
    }
  }, [checks]);

  useEffect(() => {
    if (untouchedForm.current) {
      return;
    }
    validateForm();
  }, [firstName, lastName, password, email, mobile, mobileLength, toc]);

  useEffect(() => {
    if (
      signupData &&
      signupData.error &&
      signupData &&
      signupData.error.message
    ) {
      setErrorMsg(signupData.error.message);
    }
    if (signupData && signupData.mobileVerified === true) {
      setMobileVerified(true);
    }
  }, [signupData]);

  /* Next Button */
  const submitButton = async (event) => {
    event.preventDefault();

    /*
  The form is no longer untouched,
  which means we can now do real-time verificaiton
  */
    untouchedForm.current = false;
    if (validateForm()) {
      untouchedForm.current = true;
      var data = {
        password,
        firstName,
        lastName,
        email,
        mobile,
        isdCode: COUNTRY_CODE,
      };
      dispatch(signupActions.signup(data));
    }
  };

  const phoneFilter = (event, setter) => {
    setter(event.target.value.replace(/[^\d]+$/g, ""));
  };

  const FullNameFilter = (event, setter) => {
    let fName = event.target.value;
    let fNameWhiteSpace = fName.replace(/\s+/g, " ");
    setter(fNameWhiteSpace.replace(/[^a-z ]/gi, ""));
  };

  const handleOnKeyUp = (e) => {
    setPwdpopMsg(true);
    const value = e.target.value.trim();
    const capsLettercheck = /[A-Z]/.test(value);
    const smalllettercheck = /[a-z]/.test(value);
    const specchar = /[!@#$`~%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
    // const numberCheck = /[0-9]/.test(value);
    const numberCheck = /\d/.test(value);
    const pwdlength = value.length >= 8;
    const whitespace = /\s/g.test(value);
    //pwdpopMsg
    setChecks({
      capsLettercheck,
      numberCheck,
      pwdlength,
      smalllettercheck,
      specchar,
    });
    if (whitespace === true) {
      setPassword("");
    }

    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);
    } else {
      setPwdpopMsg(true);
    }
  };
  var capsClass = checks.capsLettercheck ? "valid" : "invalid";
  var numcheckClass = checks.numberCheck ? "valid" : "invalid";
  var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
  var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
  var specClass = checks.specchar ? "valid" : "invalid";
  const eFilter = (event, setter) => {
    if ([69, 187, 188, 189, 190].includes(event.keyCode)) {
      event.preventDefault();
      setter(event.target.value.replace(/[^\d]+$/g, ""));
    }
  };

  return (
    <>
      {mobileVerified === true ? (
        <OtpBox mobile={mobile} />
      ) : (
        <div className="signup">
          <div className="signup-container">
            {/* left */}
            <div className="signup-left">
              <div className="logoDark">
                <img className="logo" src={logos?.logoWhite} alt="logo" />
              </div>
              <div className="logoLight">
                <img className="logo" src={logos?.logo} alt="logo" />
              </div>
              <h1 className="signup-title">Create your Sequence Account</h1>
              <form onSubmit={submitButton}>
                <div className="form-wrapper-row">
                  <div
                    className={[
                      "form-wrapper",
                      errorList.firstName ? null : "has-error",
                    ].join(" ")}
                  >
                    <label>
                      First Name<b className="Mandatory-fields">*</b>
                    </label>
                    <input
                      className={[
                        errorList.firstName
                          ? "input-control"
                          : "input-control border-red",
                      ]}
                      type="text"
                      maxLength={20}
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => FullNameFilter(e, setFirstName)}
                    />
                    {errorList.firstName ? null : (
                      <div className="show-error">
                        This field cannot be left blank
                      </div>
                    )}
                  </div>
                  <div
                    className={[
                      "form-wrapper-last form-wrapper",
                      errorList.lastName ? null : "has-error",
                    ].join(" ")}
                  >
                    <label>
                      Last Name<b className="Mandatory-fields">*</b>{" "}
                    </label>
                    <input
                      className={[
                        errorList.lastName
                          ? "input-control"
                          : "input-control border-red",
                      ]}
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      maxLength={20}
                      onChange={(e) => FullNameFilter(e, setLastName)}
                    />
                    {errorList.lastName ? null : (
                      <div className="show-error">
                        This field cannot be left blank
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={[
                    "form-wrapper",
                    errorList.email ? null : "has-error",
                  ].join(" ")}
                >
                  <label>
                    Email<b className="Mandatory-fields">*</b>{" "}
                  </label>
                  <input
                    className={[
                      errorList.email
                        ? "input-control"
                        : "input-control border-red",
                    ]}
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    maxLength={100}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errorList.email ? null : (
                    <div className="show-error">
                      Please enter a valid Email address
                    </div>
                  )}
                </div>
                <div className="form-wrapper-row">
                  <div
                    className={[
                      "form-wrapper",
                      errorList.mobile ? null : "has-error",
                    ].join(" ")}
                  >
                    <label>
                      Mobile<b className="Mandatory-fields">*</b>
                    </label>
                    <div className="initial" data-placeholder={COUNTRY_CODE}>
                      <input
                        id="mobile"
                        className={[
                          errorList.mobile
                            ? "input-control"
                            : "input-control border-red",
                        ]}
                        type="mobile"
                        placeholder="123-123-1234"
                        value={mobile}
                        maxLength="10"
                        onInput={maxLengthCheck}
                        onChange={(e) => phoneFilter(e, setMobile)}
                        onKeyDown={(e) => eFilter(e, setMobile)}
                      />
                    </div>

                    {errorList.mobile ? null : (
                      <div className="show-error">
                        This field cannot be left blank
                      </div>
                    )}
                    {errorList.mobileLength ? null : (
                      <div className="show-error">
                        Please enter a valid mobile number
                      </div>
                    )}
                  </div>
                  <div
                    className={[
                      "form-wrapper-last form-wrapper",
                      errorList.password ? null : "has-error",
                    ].join(" ")}
                  >
                    <label>
                      Password (min 8 characters)
                      <b className="Mandatory-fields">*</b>
                    </label>
                    <div className="password-container">
                      <input
                        className={[
                          errorList.password
                            ? "input-control"
                            : "input-control border-red",
                        ]}
                        type={isRevealPwd ? "text" : "password"}
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value.trim())}
                        onKeyUp={handleOnKeyUp}
                        maxLength="20"
                      />
                      <img
                        className="showHidePassword"
                        title={isRevealPwd ? "Hide password" : "show password"}
                        alt=""
                        src={
                          isRevealPwd
                            ? commonImages.visibilityoff
                            : commonImages.visibility
                        }
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />

                      {password && (
                        <div className="passStr">
                          <PasswordStrengthBar
                            minLength={4}
                            password={password}
                            barColors={[
                              "#ddd",
                              "#ef4836",
                              "#f6b44d",
                              "#2b90ef",
                              "#25c281",
                            ]}
                          />
                        </div>
                      )}
                      {password && (
                        //css ide nd show
                        <div
                          style={{
                            display: pwdpopMsg === false ? "none" : "block",
                          }}
                        >
                          <div style={style}>
                            <form className="pwdbox">
                              <p className={pwdlenClass}>
                                <CheckCircleIcon className="checkbox" />
                                At least 8 characters
                              </p>

                              <p className={capsClass}>
                                <CheckCircleIcon className="checkbox" />
                                At least 1 uppercase letter
                              </p>

                              <p className={sletterClass}>
                                <CheckCircleIcon className="checkbox" />
                                At least 1 lowercase letter
                              </p>
                              <p className={specClass}>
                                <CheckCircleIcon className="checkbox" />
                                At least 1 special character
                              </p>

                              <p className={numcheckClass}>
                                <CheckCircleIcon className="checkbox" />
                                At least 1 number
                              </p>
                            </form>
                          </div>
                        </div>
                      )}

                      {errorList.password || pwdMsg === true ? null : (
                        <div className="show-error">
                          Password is missing or Invalid Password
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="signup-form-checkboxes-wrap">
                  <p className={[errorList.toc ? null : "has-error"].join(" ")}>
                    <input
                      className="styled-checkbox"
                      id="styled-checkbox-1"
                      type="checkbox"
                      checked={toc}
                      onChange={(e) => setToc(!toc)}
                    />
                    <label>
                      By signing up you agree to our{" "}
                      <a href="/terms-of-conditions" target="_blank">
                        <span className="terms">Terms of use </span>
                      </a>
                      and&nbsp;
                      <a href="/terms-of-conditions" target="_blank">
                        <span className="terms">Privacy Policy</span>
                      </a>
                    </label>
                    {errorList.toc ? null : (
                      <div className="show-error">
                        You need to check terms & conditions
                      </div>
                    )}
                  </p>
                </div>
                <div className="signup-button">
                  {signupData.isLoading ? (
                    <Loader />
                  ) : (
                    <Button
                      label="Sign Up"
                      onButtonClickHandler={submitButton}
                    />
                  )}
                </div>
                <p className="already-signup-txt">
                  Already a Sequence Member?
                  <b className="terms" onClick={goToLogin}>
                    Login
                  </b>
                </p>
                {errorMsg && <p className="show-error">{errorMsg}</p>}
              </form>
            </div>

            {/* right */}
            <div className="signup-right">
              <img className="welcome-img" src={WelcomeImage} alt="welcome" />
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};

export default Signup;
