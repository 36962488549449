import { accident } from "../assets/index";

const vehicleAccident = [
  {
    key: 1,
    value: "Dry",
    img: accident.bounding,
  },
  {
    key: 2,
    value: "Snowy",
    img: accident.acUnit,
  },
  {
    key: 3,
    value: "Windy",
    img: accident.air,
    // img: accident.bounding,
  },
  {
    key: 4,
    value: "Wet",
    img: accident.wet,
  },
  {
    key: 5,
    value: "Fog",
    img: accident.lensBlur,
  },
];

export default vehicleAccident;
