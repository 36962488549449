import { FormControl, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Buttons from "../../../../library/custom/button/Button";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import TextArea from "../../../../library/custom/textArea/TextArea";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { LNG_ID, ORG_ID } from "../../../../constant/constant";
import * as supportAction from "../../../../redux/actions/supportAction";
import "./faqTopicStyle.scss";
import FaqPreview from "./faqPreview";
import { useDispatch } from "react-redux";
import FormDropZone from "../../../../library/dropZone/FormDropZone";

const CreateFaq = (props) => {
  const dispatch = useDispatch();
  const [checkBox, setcheckBox] = useState(false);

  useEffect(() => {
    if (props?.articlesDetails && props?.editMode) {
      setformValue({
        ...formValue,
        categoryId: props.categoryID,
        title: props?.articlesDetails?.articleDetail?.title,
        description: props?.articlesDetails?.articleDetail?.description,
        fileIds: [props?.articlesDetails?.articleDetail?.imageId],
      });
      setAddPhoto(props?.articlesDetails?.articleDetail?.imageUrl);
      props?.articlesDetails?.articleDetail?.imageId === null
        ? setImages()
        : setImages([props?.articlesDetails?.articleDetail?.imageId]);
    }
  }, [props.articlesDetails]);

  const [formValue, setformValue] = useState({
    createdBy: localStorage.getItem("userId"),
    organisationId: ORG_ID(),
    lngId: LNG_ID,
    categoryId: "",
    title: "",
    description: "",
    fileIds: [],
    isGlobal: !checkBox,
    articleId: props.articleId,
  });

  const [images, setImages] = useState();
  useEffect(() => {
    setformValue({
      ...formValue,
      fileIds: images,
    });
  }, [images]);
  const [showPreview, setShowPreview] = useState(false);
  const [error, seterror] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCategory, setisCategory] = useState(false);
  const [isTitle, setisTitle] = useState(false);
  const [isDescription, setisDescription] = useState(false);

  function createNewFaq(formValue) {
    if (formValue.categoryId.length < 1) {
      setisCategory(true);
      seterror(true);
    }
    if (formValue.title.length < 1) {
      setisTitle(true);
      seterror(true);
    }
    if (formValue.description.length < 1) {
      setisDescription(true);
      seterror(true);
    }
    if (
      formValue.categoryId.length < 1 ||
      formValue.title.length < 1 ||
      formValue.description.length < 1
    ) {
      seterror(true);
    } else {
      seterror(false);
      setIsSubmit(true);
      if (props.editMode) dispatch(supportAction.updateFAQ(formValue));
      else dispatch(supportAction.postAddFAQ(formValue));
      props.setSuccessScreens(true);
    }
  }

  const [addPhoto, setAddPhoto] = useState("");

  const handleDeleteImageURL = (urls) => {
    setAddPhoto("");
    setImages();
  };
  return (
    <>
      {showPreview ? (
        <FaqPreview
          handleBack={() => setShowPreview(!setShowPreview)}
          title={formValue.title}
          description={formValue.description}
          category
          addPhoto={addPhoto}
        />
      ) : (
        <div className="overrideCreateFaq">
          <Grid container className="createFaqh1">
            <h1>Create FAQ</h1>
          </Grid>
          <Grid item container lg={9} spacing={2} mt={3}>
            <Grid item lg={6} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabels label="Select Category" isRequired={true} />
                <SelectMenu
                  placeholder={"Please Select"}
                  listData={props?.articleCatSubcategory}
                  value={formValue.categoryId}
                  onchangehandler={(e) => {
                    setformValue({
                      ...formValue,
                      categoryId: e.target.value,
                    });
                  }}
                  isKeyValue={false}
                  isError={isCategory}
                  errorMsg={
                    isCategory ? "Selection is required for this field" : ""
                  }
                />
              </FormControl>
            </Grid>

            {/* {adminAccess?.isFullAppAccess && (
              <Grid item lg={6} xs={12} sm={12} md={4} mt={4.5}>
                <div className="typeTxt">
                  <Checkbox
                    checked={checkBox}
                    onChange={() => setcheckBox(!checkBox)}
                    label={"Global FAQ"}
                  />
                </div>
              </Grid>
            )} */}
          </Grid>
          <Grid mt={2} item container lg={8} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputField
                isRequired={true}
                fullWidth={true}
                type="text"
                label="Title"
                value={formValue.title}
                placeholder="Enter title here"
                onChange={(e) => {
                  if (e.target.value === " " && formValue.title.trim() === "")
                    setformValue({
                      ...formValue,
                      title: "",
                    });
                  else setformValue({ ...formValue, title: e.target.value });
                }}
                isError={isTitle}
                errorMsg={isTitle ? "Please enter this required field" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item container mt={3}>
            <Grid item lg={8} md={12} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Add Text" isRequired={true} />
                <TextArea
                  placeholder={"Enter Here"}
                  value={formValue.description}
                  onChange={(e) => {
                    if (
                      e.target.value === " " &&
                      formValue.description.trim() === ""
                    )
                      setformValue({
                        ...formValue,
                        description: "",
                      });
                    else
                      setformValue({
                        ...formValue,
                        description: e.target.value,
                      });
                  }}
                  height300={true}
                  isError={isDescription}
                  errorMsg={
                    isDescription ? "Please enter this required field" : ""
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid lg={8} mb={2} mt={2}>
              <FormDropZone
                paraText1={"Drop the files here ..."}
                paraText2={"Drop Images here, "}
                paraText3={"or Browse"}
                handleFileChange={(files, uploadfiles) => {
                  setImages(uploadfiles);
                  setAddPhoto(files);
                }}
                deleteImageURL={(url) => handleDeleteImageURL(url)}
                isDynamics={false}
                isRequired={false}
                fileType={10}
                isMuliple={false}
                imageUrl={addPhoto}
                id="addPhoto"
                key="addPhoto"
                isDoc={false}
                isImage={true}
                allFiles={false}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            lg={8}
            mt={2}
            mb={4}
            justifyContent="flex-end"
          >
            <Grid item mb={2} lg={3} xs={12} sm={6} md={6} textAlign="center">
              <Buttons
                varientText={true}
                label="Preview"
                onClick={() => {
                  setShowPreview(true);
                }}
              />
            </Grid>
            <Grid item mb={2} lg={3} xs={12} sm={6} md={6} textAlign="right">
              <Buttons
                varientContained={true}
                label="Submit"
                onClick={() => createNewFaq(formValue)}
                // disabled={isSubmit ? true : false}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default CreateFaq;
