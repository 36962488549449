export const signupType = {
  POST_SIGNUP_PENDING: "[SIGNUP][REQUEST] Try To Register",
  POST_SIGNUP_FAILURE: "[SIGNUP][RESPONSE] Signup Failed",
  POST_SIGNUP_SUCCESS: "[SIGNUP][RESPONSE] Signup Successfull",
  CLEAR_ERRORS: "[SIGNUP][REQUEST] CLEAR_ERRORS",
  SIGNUP_PAGE: "SignupPage",

  POST_OTPSIGNUP_PENDING: "[SIGNUPOTP][REQUEST] Try To Register",
  POST_OTPSIGNUP_FAILURE: "[SIGNUPOTP][RESPONSE] SignupOtp Failed",
  POST_OTPSIGNUP_SUCCESS: "[SIGNUPOTP][RESPONSE] SignupOtp Successfull",

  POST_VERIFYMOBILE_PENDING: "[VERIFYMOBILE][REQUEST] Try To Verify Mobile",
  POST_VERIFYMOBILE_FAILURE: "[VERIFYMOBILE][RESPONSE] Verify Failed",
  POST_VERIFYMOBILE_SUCCESS: "[VERIFYMOBILE][RESPONSE] Verify Successfull",

  PUT_ONBOARDING_PENDING: "[ONBOARDING][REQUEST] Update Onboarding",
  PUT_ONBOARDING_FAILURE: "[ONBOARDING][RESPONSE] Onboarding Updation Failed",
  PUT_ONBOARDING_SUCCESS:
    "[ONBOARDING][RESPONSE] Onboarding Updation Successfull",
};
