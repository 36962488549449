import React, { useState, useEffect } from "react";
import Buttons from "../../../../library/custom/button/Button";
import AddIcon from "@mui/icons-material/Add";
import ReportFilter from "./reportFilter";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { COLUMNS_REPRIMINDS_VIEW } from "../../../../constant/inspectionConstant";
import { useDispatch, useSelector } from "react-redux";
import SettingTable from "../../../../library/custom/gridTable/SettingTable";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import DeleteAndDownloadAction from "../../../../library/custom/gridTable/deleteAndDownloadAction";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { useNavigate } from "react-router-dom";
import {
  REPRIMAND,
  VIEW_REPRIMAND_CONTAINER,
} from "../../../../constant/routeContant";
import { getReprimandList } from "../../../../redux/actions/reprimandAction";
import {
  InvolvedPartyOptionData,
  statusOfList,
} from "../../../../constant/inspectionConstant";
import { getFormatedDate } from "../../../../utils/helper";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const ReprimandList = ({ filterShow }) => {
  const dispatch = useDispatch();
  const { reprimandList, isLoading } = useSelector(
    (state) => state.reprimandView
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportIdOfChekbox, setReportIdOfChekbox] = useState([]);
  const [filter, setFilter] = useState({});
  const [searchText, setSearchText] = React.useState("");
  const navigate = useNavigate();

  const deleteItem = React.useCallback((item, action) => {
    if (action === "single") {
    } else {
    }
  }, []);
  function handleViewReprimand(row) {
    navigate(VIEW_REPRIMAND_CONTAINER, {
      state: {
        id: row?.reprimandDtlId,
        editMode: true,
        involvedPartyUserId: row?.involvedPartyUserId,
        status: row?.status,
      },
    });
  }

  const columns = React.useMemo(() => {
    const headers = [...COLUMNS_REPRIMINDS_VIEW];
    headers.push({
      field: "icon",
      renderHeader: () =>
        filterShow ? (
          <SettingTable
            noOfRecords={reprimandList?.recordsCount ?? 0}
            numberOfItemsPerPage={5}
            listItem={reprimandList?.insReprimandDetails ?? []}
            checked={pageSize}
            setchecked={setPageSize}
            filename={"Reprimand-Reports"}
          />
        ) : (
          ""
        ),

      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return filterShow ? (
          <TableRowAction
            rows={rows}
            usingComp={"Reprimand"}
            view={checkPrivileges([12, 81, 82, 83])}
            viewLabel={"View Reprimand"}
            deleteLabel={"Delete Reprimand"}
            viewOnClick={(row) => handleViewReprimand(row)}
          />
        ) : (
          ""
        );
      },
    });
    return headers;
  }, [reprimandList, pageSize, navigate]);

  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };

  useEffect(() => {
    const { statusType, searchType, startDate, endDate } = filter;
    dispatch(
      getReprimandList({
        searchText: searchText,
        status: (statusType && statusType[0]?.key) ?? undefined,
        empTypes: (searchType && searchType[0]?.key + 1) ?? undefined,
        page: page ?? undefined,
        fromdate: (startDate && getFormatedDate(startDate)) ?? undefined,
        todate: (endDate && getFormatedDate(endDate)) ?? undefined,
        pageSize: pageSize ?? undefined,
      })
    );
  }, [dispatch, searchText, filter, page, pageSize]);

  const handleSearchFilter = (event, state) => {
    setFilter(state);
    handleClose();
  };
  const handleClear = (event, state) => {
    setFilter([]);
    handleClose();
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      const { row } = rows;
      handleViewReprimand(row);
    }
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Reprimands</div>
          {checkPrivileges([12, 81, 82]) && (
            <div>
              {filterShow ? (
                <Buttons
                  primary={true}
                  label={"Add Reprimand"}
                  onClick={() => navigate(REPRIMAND)}
                  startIcon={<AddIcon />}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="grid_main_body">
          {filterShow ? (
            <ReportFilter
              filterType={[]}
              handleSearchFilter={handleSearchFilter}
              handleClose={handleClose}
              anchorEl={anchorEl}
              handleInputSearchByText={(e) => setSearchText(e.target.value)}
              handleInputSearchByTextState={searchText}
              searchByText={true}
              searchByTextName={"Search Reprimands"}
              typeSearchLabel={"Employment type"}
              isTypeSearchLabel={true}
              isStatus={true}
              statusLabel={"Status"}
              sattusListData={InvolvedPartyOptionData}
              statusOfList={statusOfList}
              handleClear={handleClear}
            />
          ) : (
            ""
          )}
        </div>

        <div className="desc_box row_uniq">
          <div className="table_style_ui">
            <GridTable
              getRowId={(r) => r.reprimandDtlId}
              rows={reprimandList?.insReprimandDetails ?? []}
              columns={columns ?? []}
              rowCount={reprimandList?.recordsCount ?? 0}
              onClick={(rows) => onRowClick(rows)}
              loading={isLoading}
              checkboxSelection={false}
              page={page}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[50]}
              onPageChange={(newPage) => setPage(newPage + 1)}
              backIconButtonProps={{ disabled: false }}
              onSelectionModelChange={(reportId) =>
                setReportIdOfChekbox(reportId)
              }
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              disableSelectionOnClick
            />
          </div>
        </div>
      </section>
      {reportIdOfChekbox.length > 0 && (
        <DeleteAndDownloadAction
          reportIdOfChekbox={reportIdOfChekbox}
          incidentLists={reprimandList?.insReprimandDetails ?? []}
          filename={"Reprimand-Reports"}
          deleteOnClick={() => deleteItem(null, "multiple")}
        />
      )}
    </>
  );
};

export default ReprimandList;
