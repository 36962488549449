import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import commonImages from "../../../assets";

import "./tableCard.scss";
import { Box } from "@mui/material";

const TableCard = (props) => {
  const { viewOnClick, rowData, viewEnrollOnClick } = props;
  // const courseDate = new Date(rowData?.courseDate);
  // const lessonDate = new Date(rowData?.lessonDate);
  return (
    <>
      {rowData?.lesson && (
        <div className="cardBoxOver">
          <Card className="cardBox">
            <div className="cardImgBox">
              <CardMedia
                component="img"
                className="cardImg"
                image={
                  rowData?.coverImage
                    ? rowData?.coverImage
                    : commonImages?.noimagePublish
                }
                alt="green iguana"
              />
              <Typography className="cardOverlap">
                <span>
                  <img src={commonImages?.locallibrary} alt="" />
                </span>
                Lesson
              </Typography>
            </div>
            <CardContent className="cardContentBox">
              <Typography className="cardTitle" gutterBottom>
                {rowData?.lesson.length > 55
                  ? rowData?.lesson.substring(0, 55) + "..."
                  : rowData?.lesson}
              </Typography>
              <Box className="centerContent">
                <Typography className="centerContentDetail">
                  <span>
                    <img src={commonImages?.category} alt="category" />
                  </span>

                  {rowData?.category.length > 65
                    ? rowData?.category.substring(0, 65) + "..."
                    : rowData?.category}
                </Typography>
                <Typography className="centerContentDetail">
                  {/* {rowData?.completedCount > 0 ? "completed times " : ""}
                  {rowData?.completedCount > 0 ? rowData?.completedCount : ""} */}
                  {/* <span>
                    <img src={commonImages?.alarm} alt="alarm" />
                  </span>
                  {`${
                    lessonDate.getMonth() + 1
                  }/${lessonDate.getDate()}/${lessonDate.getFullYear()}`} */}
                </Typography>
              </Box>
              <Box className="bottomContent">
                <Typography className="bottomContentDetail">
                  {rowData.isQuiz ? "Quiz" : ""}
                </Typography>

                <Typography className="bottomContentDetail">
                  {rowData?.completedCount > 0
                    ? "Completed Times: " + rowData?.completedCount
                    : ""}
                </Typography>
              </Box>
            </CardContent>

            <CardActions
              className={[
                rowData?.statusId === 3
                  ? "cardBtn enroll"
                  : rowData?.statusId === 0
                  ? "cardBtn inprogress"
                  : rowData?.statusId === 1
                  ? "cardBtn"
                  : rowData?.statusId === 2
                  ? "cardBtn start"
                  : "",
              ]}
              // onClick={() => viewOnClick(rowData?.id)}
            >
              <Button
                className={[
                  rowData?.statusId === 3
                    ? "cardButton enroll"
                    : rowData?.statusId === 0
                    ? "cardButton inprogress"
                    : rowData?.statusId === 1
                    ? "cardButton"
                    : rowData?.statusId === 2
                    ? "cardButton start"
                    : "",
                ]}
                size="small"
                onClick={() => viewOnClick(rowData?.id)}
              >
                {rowData?.status}
              </Button>
              {rowData?.isSelfAssigned && rowData?.statusId === 2 ? (
                <Button
                  className={[
                    rowData?.statusId === 3
                      ? "cardButton enroll"
                      : rowData?.statusId === 0
                      ? "cardButton inprogress"
                      : rowData?.statusId === 1
                      ? "cardButton"
                      : rowData?.statusId === 2
                      ? "cardButton unenroll"
                      : "",
                  ]}
                  size="small"
                  onClick={() => viewEnrollOnClick(rowData?.id)}
                >
                  {"UnEnroll"}
                </Button>
              ) : (
                ""
              )}
            </CardActions>
          </Card>
        </div>
      )}

      {rowData?.course && (
        <div className="cardBoxOver">
          <Card className="cardBox">
            <div className="cardImgBox">
              <CardMedia
                component="img"
                className="cardImg"
                image={
                  rowData?.coverImage
                    ? rowData?.coverImage
                    : commonImages?.noimagePublish
                }
                alt="green iguana"
              />
              <Typography className="cardOverlap">
                <span>
                  <img src={commonImages?.locallibrary} alt="" />
                </span>
                course
              </Typography>
            </div>
            <CardContent className="cardContentBox">
              <Typography className="cardTitle" gutterBottom>
                {rowData?.course.length > 55
                  ? rowData?.course.substring(0, 55) + "..."
                  : rowData?.course}
              </Typography>
              <Box className="centerContent">
                <Typography className="centerContentDetail">
                  <span>
                    <img src={commonImages?.category} alt="category" />
                  </span>

                  {rowData?.category.length > 65
                    ? rowData?.category.substring(0, 65) + "..."
                    : rowData?.category}
                </Typography>
                <Typography className="centerContentDetail">
                  {/* <span>
                    <img src={commonImages?.alarm} alt="alarm" />
                  </span>
                  {`${
                    courseDate.getMonth() + 1
                  }/${courseDate.getDate()}/${courseDate.getFullYear()}`} */}
                </Typography>
              </Box>
              <Box className="bottomContent">
                <Typography className="bottomContentDetail">
                  {rowData.isQuiz ? "Quiz" : ""}
                </Typography>

                <Typography className="bottomContentDetail">
                  {rowData?.courseCompletedCount > 0
                    ? "Completed Times: " + rowData?.courseCompletedCount
                    : ""}
                  {/* <span>
                    <img src={commonImages?.checkcircle} alt="alarm" />
                  </span>
                  {rowData?.status} */}
                </Typography>
              </Box>
            </CardContent>
            <CardActions
              className={[
                rowData?.statusId === 3
                  ? "cardBtn enroll"
                  : rowData?.statusId === 0
                  ? "cardBtn inprogress"
                  : rowData?.statusId === 1
                  ? "cardBtn "
                  : rowData?.statusId === 2
                  ? "cardBtn start "
                  : "",
              ]}
              //onClick={() => viewOnClick(rowData?.id)}
            >
              <Button
                className={[
                  rowData?.statusId === 3
                    ? "cardButton enroll"
                    : rowData?.statusId === 0
                    ? "cardButton inprogress"
                    : rowData?.statusId === 1
                    ? "cardButton"
                    : rowData?.statusId === 2
                    ? "cardButton start"
                    : "",
                ]}
                size="small"
                onClick={() => viewOnClick(rowData?.id)}
              >
                {rowData?.status}
              </Button>
              {rowData?.isSelfAssigned && rowData?.statusId === 2 ? (
                <Button
                  className={[
                    rowData?.statusId === 3
                      ? "cardButton enroll"
                      : rowData?.statusId === 0
                      ? "cardButton inprogress"
                      : rowData?.statusId === 1
                      ? "cardButton"
                      : rowData?.statusId === 2
                      ? "cardButton unenroll"
                      : "",
                  ]}
                  size="small"
                  onClick={() => viewEnrollOnClick(rowData?.id)}
                >
                  {"UnEnroll"}
                </Button>
              ) : (
                ""
              )}
            </CardActions>
          </Card>
        </div>
      )}
    </>
  );
};

export default TableCard;
