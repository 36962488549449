import * as swInspectionAction from "../actions/swInspectionAction";
import { SwInspectionType } from "../types/swInspectionType";

import {
  questionSetList,
  addQuestionSet,
  getQestSetDetail,
  updateQuestionSetStatus,
  addQuestionSetProjectMap,
  getQuestionSetProjectMapDetails,
  getQuestionSetProjectMapList,
  addCategory,
  updateCategory,
  getCategoryList,
  addSubCategory,
  updateSubCategory,
  getSubCategoryList,
  addQuestion,
  updateQuestion,
  getQuestionList,
  addInspectionType,
  updateInspectionType,
  getInspectionTypeList,
  getInspectionTypeFieldsList,
  getBindInspectionTypeFromStateList,
  getInTyFeByInTyList,
  updateInspectionTypeFieldsMap,
  updateQuestionSet,
  updateQuestionSetProjectMap,
  cloneSwQuestionSet,
  UpdateStatusQuestionSetProjectMap,
  getSwInspectionList,
  addSwInspection,
  getSwInspectionDetail,
  deleteSwInspection,
  updateSwInspection,
  getQuestionSetOnProject,
  getSwInspectorList,
  assignSwInspector,
  getSwSecondaryInspectorList,
  deleteSwInspector,
  swDashboardCardDetails,
  swInspectionCloseItem,
  swAddInspectionItemComment,
  swGetOpenItemDetails,
  diferredSwInspection,
  getOpenItemDashboardDetails,
  conductExternalInspection,
  getExternalInspection,
  getInspectionHeaderDetails,
  getInspectionDailyReportDetails,
  updateInspectionDailyReport,
  getBulkInspectionDetails,
  getBulkInspectionFiles,
  getProjectContactsEmailDetails,
} from "../../../services/storm-water/swInspectionService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../../utils/helper";

export function* questionSetListSaga(params) {
  console.debug("questionSetListSaga");
  try {
    const { data, status, error } = yield call(questionSetList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getSwQuestionSetListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get SW Question Set List Failed" };
    yield put(swInspectionAction.getSwQuestionSetListFailure(data));
  }
}

export function* addQuestionSetSaga(params) {
  console.debug("addQuestionSetSaga");
  try {
    const { data, status, error } = yield call(addQuestionSet, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.createSwQuestionSetSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "createSwQuestionSet Failed" };
    yield put(swInspectionAction.createSwQuestionSetFailure(data));
  }
}

export function* updateQuestionSetSaga(params) {
  console.debug("updateQuestionSetSaga");
  try {
    const { data, status, error } = yield call(updateQuestionSet, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.updateSwQuestionSetSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "updateSwQuestionSet Failed" };
    yield put(swInspectionAction.updateSwQuestionSetFailure(data));
  }
}

export function* getSwQuestionSetDetailSaga({ id }) {
  console.debug("getSwQuestionSetDetail");
  try {
    const { data, status, error } = yield call(getQestSetDetail, id);
    if (!data && status !== 200) throw apiError(error);
    const DetailPage = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    let counter = 1;
    const result = data?.questionSetCatDetails?.map((x) => {
      x.subcategories.forEach((y) => {
        y.questions
          .filter((z) => z.isSelected)
          .map((z) => {
            z.quesNo = counter;
            counter++;
            return z;
          });
        return y;
      });
      return x;
    });
    yield put(
      swInspectionAction.getSwQuestionSetDetailSuccess({
        DetailPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getSwQuestionSetDetail Failed" };
    yield put(swInspectionAction.getSwQuestionSetDetailFailure(data));
  }
}

export function* updateQuestionSetStatusSaga(params) {
  console.debug("updateQuestionSetStatusSaga");
  try {
    const { data, status, error } = yield call(updateQuestionSetStatus, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.updateSwQuestionSetStatusSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "updateSwQuestionSetStatus Failed" };
    yield put(swInspectionAction.updateSwQuestionSetStatusFailure(data));
  }
}

export function* cloneSwQuestionSetSaga(params) {
  console.debug("cloneSwQuestionSetSaga");
  try {
    const { data, status, error } = yield call(cloneSwQuestionSet, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.CLONE_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.cloneSwQuestionSetSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "clone Question Set Failed" };
    yield put(swInspectionAction.cloneSwQuestionSetFailure(data));
  }
}

export function* getQuestionSetOnProjectSaga(params) {
  console.debug("getQuestionSetOnProjectSaga");
  try {
    const { data, status, error } = yield call(getQuestionSetOnProject, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getQuestionSetOnProjectSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get SW Question Set On Project Failed" };
    yield put(swInspectionAction.getQuestionSetOnProjectFailure(data));
  }
}

export function* addQuestionSetProjectMapSaga(params) {
  console.debug("addQuestionSetProjectMapSaga");
  try {
    const { data, status, error } = yield call(
      addQuestionSetProjectMap,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.createSwQuestionSetProjectMapSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "createSwQuestionSetProjectMap Failed" };
    yield put(swInspectionAction.createsSwQuestionSetProjectMapFailure(data));
  }
}

export function* updateQuestionSetProjectMapSaga(params) {
  console.debug("updateQuestionSetProjectMapSaga");
  try {
    const { data, status, error } = yield call(
      updateQuestionSetProjectMap,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.updateSwQuestionSetProjectMapSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "updateSwQuestionSetProjectMap Failed" };
    yield put(swInspectionAction.updateSwQuestionSetProjectMapFailure(data));
  }
}

export function* updateStatusQuestionSetProjectMapSaga(params) {
  console.debug("updateStatusQuestionSetProjectMapSaga");
  try {
    const { data, status, error } = yield call(
      UpdateStatusQuestionSetProjectMap,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.updateStatusQuestionSetProjectMapSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "updateSwQuestionSetProjectMap Status Failed" };
    yield put(
      swInspectionAction.updateStatusQuestionSetProjectMapFailure(data)
    );
  }
}

export function* getQuestionSetProjectMapDetailsSaga({ id }) {
  console.debug("getQuestionSetProjectMapDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getQuestionSetProjectMapDetails,
      id
    );
    if (!data && status !== 200) throw apiError(error);
    const DetailPage = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getSwQuestionSetProjectMapDetailSuccess({
        DetailPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message: error || "GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS Failed",
          };
    yield put(swInspectionAction.getSwQuestionSetProjectMapDetailFailure(data));
  }
}

export function* getQuestionSetProjectMapListSaga(params) {
  console.debug("getQuestionSetProjectMapListSaga");
  try {
    const { data, status, error } = yield call(
      getQuestionSetProjectMapList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getSwQuestionSetProjectMapListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get SW Question Set List Failed" };
    yield put(swInspectionAction.getSwQuestionSetProjectMapListFailure(data));
  }
}

export function* addCategorySaga(params) {
  console.debug("addCategorySaga");
  try {
    const { data, status, error } = yield call(addCategory, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.createSwCategorySuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "createSwCategory Failed" };
    yield put(swInspectionAction.createSwCategoryFailure(data));
  }
}

export function* updateCategorySaga(params) {
  console.debug("updateCategorySaga");
  try {
    const { data, status, error } = yield call(updateCategory, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.updateSwCategorySuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "updateSwCategory Failed" };
    yield put(swInspectionAction.updateSwCategoryFailure(data));
  }
}

export function* getCategoryListSaga(params) {
  console.debug("getCategoryListSaga");
  try {
    const { data, status, error } = yield call(getCategoryList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getSwCategoryListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getSwCategoryList Failed" };
    yield put(swInspectionAction.getSwCategoryListFailure(data));
  }
}

export function* addSubCategorySaga(params) {
  console.debug("addSubCategorySaga");
  try {
    const { data, status, error } = yield call(addSubCategory, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.createSwSubCategorySuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "createSwSubCategory Failed" };
    yield put(swInspectionAction.createSwSubCategoryFailure(data));
  }
}

export function* updateSubCategorySaga(params) {
  console.debug("updateSubCategorySaga");
  try {
    const { data, status, error } = yield call(updateSubCategory, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.updateSwSubCategorySuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "updateSwSubCategory Failed" };
    yield put(swInspectionAction.updateSwSubCategoryFailure(data));
  }
}

export function* getSubCategoryListSaga(params) {
  console.debug("getCategoryListSaga");
  try {
    const { data, status, error } = yield call(getSubCategoryList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getSwSubCategoryListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getSwSubCategoryList Failed" };
    yield put(swInspectionAction.getSwSubCategoryListFailure(data));
  }
}

export function* addQuestionSaga(params) {
  console.debug("addQuestionSaga");
  try {
    const { data, status, error } = yield call(addQuestion, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.createSwQuestionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "createQuestion Failed" };
    yield put(swInspectionAction.createSwQuestionFailure(data));
  }
}

export function* updateQuestionSaga(params) {
  console.debug("updateQuestionSaga");
  try {
    const { data, status, error } = yield call(updateQuestion, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.updateSwQuestionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "updateSwQuestion Failed" };
    yield put(swInspectionAction.updateSwQuestionFailure(data));
  }
}

export function* getQuestionListSaga(params) {
  console.debug("getQuestionListSaga");
  try {
    const { data, status, error } = yield call(getQuestionList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getSwQuestionListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getSwQuestionList Failed" };
    yield put(swInspectionAction.getSwQuestionListFailure(data));
  }
}

export function* addInspectionTypeSaga(params) {
  console.debug("addInspectionTypeSaga");
  try {
    const { data, status, error } = yield call(addInspectionType, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.createInspectionTypeSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "create InspectionType Failed" };
    yield put(swInspectionAction.createInspectionTypeFailure(data));
  }
}

export function* updateInspectionTypeSaga(params) {
  console.debug("updateInspectionTypeSaga");
  try {
    const { data, status, error } = yield call(updateInspectionType, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.updateInspectionTypeSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "update InspectionType Failed" };
    yield put(swInspectionAction.updateInspectionTypeFailure(data));
  }
}

export function* getInspectionTypeListSaga(params) {
  console.debug("getInspectionTypeListSaga");
  try {
    const { data, status, error } = yield call(getInspectionTypeList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getInspectionTypeListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get InspectionType List Failed" };
    yield put(swInspectionAction.getInspectionTypeListFailure(data));
  }
}

export function* getInspectionTypeFieldsListSaga(params) {
  console.debug("getInspectionTypeFieldsListSaga");
  try {
    const { data, status, error } = yield call(
      getInspectionTypeFieldsList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getInspectionTypeFieldsListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get InspectionType Fields List Failed" };
    yield put(swInspectionAction.getInspectionTypeFieldsListFailure(data));
  }
}

export function* getBindInspectionTypeFromStateListSaga(params) {
  console.debug("getBindInspectionTypeFromStateListSaga");
  try {
    const { data, status, error } = yield call(
      getBindInspectionTypeFromStateList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getBindInspectionTypeFromStateListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message: error || "get Bind Inspection Type From State List Failed",
          };
    yield put(
      swInspectionAction.getBindInspectionTypeFromStateListFailure(data)
    );
  }
}

export function* getInTyFeByInTyListSaga(params) {
  console.debug("getInTyFeByInTyListSaga");
  try {
    const { data, status, error } = yield call(getInTyFeByInTyList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getInTyFeByInTyListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message:
              error ||
              "Get Inspecion Type Fields By Inspection Type List List Failed",
          };
    yield put(swInspectionAction.getInTyFeByInTyListFailure(data));
  }
}

export function* updateInspectionTypeFieldsMapSaga(params) {
  console.debug("updateInspectionTypeFieldsMapSaga");
  try {
    const { data, status, error } = yield call(
      updateInspectionTypeFieldsMap,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.updateInspectionTypeFieldsMapSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "update Inspection Type Fields Map Failed" };
    yield put(swInspectionAction.updateInspectionTypeFieldsMapFailure(data));
  }
}

// user

export function* swInspectionListSaga(params) {
  console.debug("swInspectionListSaga");
  try {
    const { data, status, error } = yield call(getSwInspectionList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.getSwInspectionListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get SW Inspection List Failed" };
    yield put(swInspectionAction.getSwInspectionListFailure(data));
  }
}

export function* addSwInspectionSaga(params) {
  console.debug("addSwInspectionSaga");
  try {
    const { data, status, error } = yield call(addSwInspection, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.createSwInspectionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "create SwInspection Failed" };
    yield put(swInspectionAction.createSwInspectionFailure(data));
  }
}

export function* updateSwInspectionSaga(params) {
  console.debug("updateSwInspectionSaga");
  try {
    const { data, status, error } = yield call(updateSwInspection, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.updateSwInspectionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "update SwInspection Failed" };
    yield put(swInspectionAction.updateSwInspectionFailure(data));
  }
}

export function* getSwInspectionDetailSaga({ id }) {
  console.debug("getSwInspectionDetailSaga");
  try {
    const { data, status, error } = yield call(getSwInspectionDetail, id);
    if (!data && status !== 200) throw apiError(error);
    const DetailPage = SwInspectionType.GET_SW_INSPECTION_PAGE;
    let counter = 1;
    const result = data?.categoriesView?.map((x) => {
      x.subcategoriesView.forEach((y) => {
        y.insQuesAnsReportView.map((z) => {
          z.quesNo = counter;
          counter++;
          return z;
        });
        return y;
      });
      return x;
    });
    yield put(
      swInspectionAction.getSwInspectionDetailSuccess({
        DetailPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get SwInspection Detail Failed" };
    yield put(swInspectionAction.getSwInspectionDetailFailure(data));
  }
}

export function* deleteSwInspectionSaga(payload) {
  try {
    const { data, status } = yield call(deleteSwInspection, payload);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;

    yield put(
      swInspectionAction.deleteSwInspectionSuccess({
        page,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "delete SwInspection Failed" };
    yield put(swInspectionAction.deleteSwInspectionFailure(data));
  }
}

// primary and secondry inspector
export function* swInspectorListSaga(params) {
  console.debug("swInspectorListSaga");
  try {
    const { data, status, error } = yield call(getSwInspectorList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.getSwInspectorListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get SW Inspector List Data Failed" };
    yield put(swInspectionAction.getSwInspectorListFailure(data));
  }
}

export function* assignSwInspectorSaga(params) {
  console.debug("assignSwInspectorSaga");
  try {
    const { data, status, error } = yield call(assignSwInspector, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.assignSwInspectorSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Assign Inspector Data Failed" };
    yield put(swInspectionAction.assignSwInspectorFailure(data));
  }
}

export function* getSwSecondaryInspectorListSaga(params) {
  console.debug("swInspectorListSaga");
  try {
    const { data, status, error } = yield call(
      getSwSecondaryInspectorList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.getSwSecondaryInspectorListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get SW Secondary Inspector List Data Failed" };
    yield put(swInspectionAction.getSwSecondaryInspectorListFailure(data));
  }
}

export function* deleteSwSecondaryInspectionSaga(payload) {
  try {
    const { data, status } = yield call(deleteSwInspector, payload);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;

    yield put(
      swInspectionAction.deleteSwSecondaryInspectionSuccess({
        page,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "delete SwInspector Failed" };
    yield put(swInspectionAction.deleteSwSecondaryInspectionFailure(data));
  }
}

export function* swDashboardCardDetailsListSaga(params) {
  console.debug("dashbordSaga");
  try {
    const { data, status, error } = yield call(swDashboardCardDetails, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getSwDashboardCardDetailsInspectionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get SW Dashboard card detail Failed" };
    yield put(
      swInspectionAction.getSwDashboardCardDetailsInspectionFailure(data)
    );
  }
}

export function* addSwOpenItemDetailSaga(params) {
  console.debug("addSwOpenItemDetailSaga");
  try {
    const { data, status, error } = yield call(swGetOpenItemDetails, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.createSwOpenItemDetailSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "create SwInspection Open Item Detail Failed" };
    yield put(swInspectionAction.createSwOpenItemDetailFailure(data));
  }
}

export function* addSwInspectionCloseItemSaga(params) {
  console.debug("addSwInspectionCloseItemSaga");
  try {
    const { data, status, error } = yield call(swInspectionCloseItem, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.createSwInspectionCloseItemSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "create SwInspection close Item Failed" };
    yield put(swInspectionAction.createSwInspectionCloseItemFailure(data));
  }
}

export function* addSwInspectionItemCommentSaga(params) {
  console.debug("addSwInspectionItemCommentSaga");
  try {
    const { data, status, error } = yield call(
      swAddInspectionItemComment,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.createSwInspectionItemCommentSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "create SwInspection Item  commentFailed" };
    yield put(swInspectionAction.createSwInspectionItemCommentFailure(data));
  }
}

export function* diferredSwInspectionSaga(params) {
  console.debug("diferredSwInspectionSaga");
  try {
    const { data, status, error } = yield call(diferredSwInspection, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.deferredSwInspectionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Defererer Inspection Data Failed" };
    yield put(swInspectionAction.deferredSwInspectionFailure(data));
  }
}

export function* swOpenItemListSaga(params) {
  console.debug("swOpenItemListSaga");
  try {
    const { data, status, error } = yield call(
      getOpenItemDashboardDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getSwOpenItemListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get SW Open Item List Failed" };
    yield put(swInspectionAction.getSwOpenItemListFailure(data));
  }
}

export function* conductExternalInspectionSaga(params) {
  console.debug("conductExternalInspectionSaga");
  try {
    const { data, status, error } = yield call(
      conductExternalInspection,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.createConductExternalInspectionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "create Conduct External Inspection Failed" };
    yield put(swInspectionAction.createConductExternalInspectionFailure(data));
  }
}

export function* externalInspectionSaga(params) {
  console.debug("externalInspectionSaga");
  try {
    const { data, status, error } = yield call(getExternalInspection, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.getExternalInspectionSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "create External Inspection Failed" };
    yield put(swInspectionAction.getExternalInspectionFailure(data));
  }
}

export function* getInspectionHeaderDetailsSaga(params) {
  console.debug("getInspectionHeaderDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getInspectionHeaderDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.getInspectionHeaderDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspection Header Details Failure" };
    yield put(swInspectionAction.getInspectionHeaderDetailsFailure(data));
  }
}

export function* getInspectionDailyReportDetailsSaga(params) {
  console.debug("getInspectionDailyReportDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getInspectionDailyReportDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.getInspectionDailyReportDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Inspection Daily Report Details Failure" };
    yield put(swInspectionAction.getInspectionDailyReportDetailsFailure(data));
  }
}

export function* updateInspectionDailyReportSaga(params) {
  console.debug("updateInspectionDailyReportSaga");
  try {
    const { data, status, error } = yield call(
      updateInspectionDailyReport,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.updateInspectionDailyReportSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "update Inspection Daily Report Failed" };
    yield put(swInspectionAction.updateInspectionDailyReportFailure(data));
  }
}

export function* getBulkInspectionDetailsSaga(params) {
  console.debug("getBulkInspectionDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getBulkInspectionDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.getBulkInspectionDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getBulkInspectionDetails Failed" };
    yield put(swInspectionAction.getBulkInspectionDetailsFailure(data));
  }
}

export function* getBulkInspectionFilesSaga(params) {
  console.debug("getBulkInspectionFilesSaga");
  try {
    const { data, status, error } = yield call(getBulkInspectionFiles, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_INSPECTION_PAGE;
    yield put(
      swInspectionAction.getBulkInspectionFilesSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getBulkInspectionFiles Failed" };
    yield put(swInspectionAction.getBulkInspectionFilesFailure(data));
  }
}

export function* getProjectContactsEmailDetailsSaga(params) {
  console.debug("getProjectContactsEmailDetailsSaga");
  try {
    const { data, status, error } = yield call(
      getProjectContactsEmailDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = SwInspectionType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swInspectionAction.getProjectContactsEmailDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getProjectContactsEmailDetails Failed" };
    yield put(swInspectionAction.getProjectContactsEmailDetailsFailure(data));
  }
}

const mySwInspectionSaga = [
  takeLatest(
    SwInspectionType.GET_SW_QUESTION_SET_LIST_PENDING,
    questionSetListSaga
  ),
  takeLatest(
    SwInspectionType.UPDATE_SW_QUESTION_SET_PENDING,
    updateQuestionSetSaga
  ),
  takeLatest(SwInspectionType.POST_SW_QUESTION_SET_PENDING, addQuestionSetSaga),
  takeLatest(
    SwInspectionType.GET_SW_QUESTION_SET_DETAILS_PENDING,
    getSwQuestionSetDetailSaga
  ),
  takeLatest(
    SwInspectionType.UPDATE_SW_QUESTION_SET_STATUS_PENDING,
    updateQuestionSetStatusSaga
  ),
  takeLatest(
    SwInspectionType.POST_SW_QUESTION_SET_PROJECT_MAP_PENDING,
    addQuestionSetProjectMapSaga
  ),
  takeLatest(
    SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_PENDING,
    updateQuestionSetProjectMapSaga
  ),
  takeLatest(
    SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_PENDING,
    updateStatusQuestionSetProjectMapSaga
  ),
  takeLatest(
    SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_PENDING,
    getQuestionSetProjectMapDetailsSaga
  ),
  takeLatest(
    SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_LIST_PENDING,
    getQuestionSetProjectMapListSaga
  ),
  takeLatest(SwInspectionType.POST_SW_CATEGORY_PENDING, addCategorySaga),
  takeLatest(SwInspectionType.UPDATE_SW_CATEGORY_PENDING, updateCategorySaga),
  takeLatest(
    SwInspectionType.GET_SW_CATEGORY_LIST_PENDING,
    getCategoryListSaga
  ),
  takeLatest(SwInspectionType.POST_SW_SUB_CATEGORY_PENDING, addSubCategorySaga),
  takeLatest(
    SwInspectionType.UPDATE_SW_SUB_CATEGORY_PENDING,
    updateSubCategorySaga
  ),
  takeLatest(
    SwInspectionType.GET_SW_SUB_CATEGORY_LIST_PENDING,
    getSubCategoryListSaga
  ),
  takeLatest(SwInspectionType.POST_SW_QUESTION_PENDING, addQuestionSaga),
  takeLatest(SwInspectionType.UPDATE_SW_QUESTION_PENDING, updateQuestionSaga),
  takeLatest(
    SwInspectionType.GET_SW_QUESTION_LIST_PENDING,
    getQuestionListSaga
  ),
  takeLatest(
    SwInspectionType.POST_INSPECTION_TYPE_PENDING,
    addInspectionTypeSaga
  ),
  takeLatest(
    SwInspectionType.UPDATE_INSPECTION_TYPE_PENDING,
    updateInspectionTypeSaga
  ),
  takeLatest(
    SwInspectionType.GET_INSPECTION_TYPE_LIST_PENDING,
    getInspectionTypeListSaga
  ),
  takeLatest(
    SwInspectionType.GET_INSPECTION_TYPE_FEILDS_LIST_PENDING,
    getInspectionTypeFieldsListSaga
  ),
  takeLatest(
    SwInspectionType.GET_INSPECTION_TYPE_FROM_STATE_LIST_PENDING,
    getBindInspectionTypeFromStateListSaga
  ),
  takeLatest(
    SwInspectionType.GET_IN_TY_FE_IN_TY_LIST_PENDING,
    getInTyFeByInTyListSaga
  ),
  takeLatest(
    SwInspectionType.UPDATE_INSPECTION_TYPE_FIELDS_MAP_PENDING,
    updateInspectionTypeFieldsMapSaga
  ),
  takeLatest(SwInspectionType.CLONE_SW_QUESTION_SET, cloneSwQuestionSetSaga),
  takeLatest(
    SwInspectionType.GET_SW_INSPECTION_LIST_PENDING,
    swInspectionListSaga
  ),
  takeLatest(
    SwInspectionType.UPDATE_SW_INSPECTION_PENDING,
    updateSwInspectionSaga
  ),
  takeLatest(SwInspectionType.POST_SW_INSPECTION_PENDING, addSwInspectionSaga),
  takeLatest(
    SwInspectionType.GET_SW_INSPECTION_DETAILS_PENDING,
    getSwInspectionDetailSaga
  ),
  takeLatest(
    SwInspectionType.DELETE_SW_INSPECTION_PENDING,
    deleteSwInspectionSaga
  ),
  takeLatest(
    SwInspectionType.GET_SW_QUESTION_SET_ON_PROJECT_PENDING,
    getQuestionSetOnProjectSaga
  ),
  takeLatest(
    SwInspectionType.GET_SW_INSPECTOR_LIST_PENDING,
    swInspectorListSaga
  ),
  takeLatest(
    SwInspectionType.POST_ASSIGN_INSPECTOR_PENDING,
    assignSwInspectorSaga
  ),
  takeLatest(
    SwInspectionType.GET_SW_SECONDARY_INSPECTOR_LIST_PENDING,
    getSwSecondaryInspectorListSaga
  ),
  takeLatest(
    SwInspectionType.DELETE_SW_SECONDARY_INSPECTOR_PENDING,
    deleteSwSecondaryInspectionSaga
  ),
  takeLatest(
    SwInspectionType.GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_PENDING,
    swDashboardCardDetailsListSaga
  ),
  takeLatest(
    SwInspectionType.POST_INSPECTION_CLOSE_ITEM_PENDING,
    addSwInspectionCloseItemSaga
  ),
  takeLatest(
    SwInspectionType.POST_INSPECTION_ITEM_COMMENT_PENDING,
    addSwInspectionItemCommentSaga
  ),
  takeLatest(
    SwInspectionType.POST_OPEN_ITEM_DETAIL_PENDING,
    addSwOpenItemDetailSaga
  ),
  takeLatest(
    SwInspectionType.POST_DEFERRED_INSPECTION_PENDING,
    diferredSwInspectionSaga
  ),
  takeLatest(
    SwInspectionType.GET_SW_OPEN_ITEM_LIST_PENDING,
    swOpenItemListSaga
  ),
  takeLatest(
    SwInspectionType.POST_CONDUCT_EXTERNAL_INSPECTION_PENDING,
    conductExternalInspectionSaga
  ),
  takeLatest(
    SwInspectionType.GET_EXTERNAL_INSPECTION_PENDING,
    externalInspectionSaga
  ),
  takeLatest(
    SwInspectionType.GET_INSPECTION_HEADER_DETAILS_PENDING,
    getInspectionHeaderDetailsSaga
  ),
  takeLatest(
    SwInspectionType.GET_INSPECTION_DAILY_REPORT_DETAILS_PENDING,
    getInspectionDailyReportDetailsSaga
  ),
  takeLatest(
    SwInspectionType.POST_INSPECTION_DAILY_REPORT_PENDING,
    updateInspectionDailyReportSaga
  ),
  takeLatest(
    SwInspectionType.POST_GET_BULK_INSPECTION_DETAILS_PENDING,
    getBulkInspectionDetailsSaga
  ),
  takeLatest(
    SwInspectionType.POST_GET_BULK_INSPECTION_FILES_PENDING,
    getBulkInspectionFilesSaga
  ),
  takeLatest(
    SwInspectionType.POST_PROJECT_CONTACTS_EMAIL_DETAILS_PENDING,
    getProjectContactsEmailDetailsSaga
  ),
];

export default mySwInspectionSaga;
