import React, { useEffect, useState } from "react";
import ViewClient from "../component/client/viewClient";
import { ADMIN_CLIENT_LIST } from "../../../constant/routeContant";
import * as clientAction from "../../../redux/actions/clientAction";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import SuccessScreen from "../../../library/custom/success/successScreen";

export default function ViewClientContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const Organisationid = location?.state?.Organisationid;
  const isProcoreConnected =
    location?.state?.OrganisationDetails?.isProcoreConnected;
  const procoreCompanyId =
    location?.state?.OrganisationDetails?.procoreCompanyId;
  const [isSuccess, setIsSuccess] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const getClientDetsilsData = useSelector(
    (state) => state?.getClientDetsilsData?.data
  );

  const getProcoreCompanyData = useSelector(
    (state) => state?.getProcoreCompanyData?.data
  );
  const getSyncWithProcoreData = useSelector(
    (state) => state?.getSyncWithProcoreData
  );

  const getClientDetsilsDatas = useSelector(
    (state) => state.getProcoreCompanyData
  );

  const getProcoreCompanyLists = (data) => {
    dispatch(clientAction.procoreCompanyList(data));
  };

  const getSynchWithProcore = (data) => {
    dispatch(clientAction.syncWithProcore(data));
    setIsConnected(data?.disconnectClient);
  };

  const showLoader = useSelector(
    (state) =>
      state?.getClientDetsilsData?.isLoading ||
      state?.getSyncWithProcoreData?.isLoading
  );

  useEffect(() => {
    if (
      getSyncWithProcoreData?.status === "SUCCESS" &&
      getSyncWithProcoreData?.isLoading === false &&
      getSyncWithProcoreData?.data?.result === "" &&
      getSyncWithProcoreData?.data?.responseMessages?.responseCode ===
        "HBNG002" &&
      isSuccess
    ) {
      toast(getSyncWithProcoreData?.data?.responseMessages?.responseMessage);
    }
  }, [getSyncWithProcoreData]);

  useEffect(() => {
    dispatch(clientAction.getClientDetsils(Organisationid));
  }, [dispatch]);

  const dataBack = {
    title: "Back to Clients",
    route: ADMIN_CLIENT_LIST,
  };
  useEffect(() => {
    setIsSuccess(false);
  }, []);
  const successScreen = (id, message) => {
    return (
      <SuccessScreen
        isUpdate={
          isConnected === false
            ? "Client Connected Successfully"
            : "Client Disconnected"
        }
        route={ADMIN_CLIENT_LIST}
        label1=" "
        label4=" "
        label2=" "
      ></SuccessScreen>
    );
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
        <>
          {showLoader && <Loader />}
          {getSyncWithProcoreData.status === "SUCCESS" &&
          getSyncWithProcoreData?.isLoading === false &&
          getSyncWithProcoreData?.data?.result === "" &&
          getSyncWithProcoreData?.data?.responseMessages?.responseCode ===
            "HBNG001" &&
          isSuccess ? (
            successScreen()
          ) : (
            <ViewClient
              dataBack={dataBack}
              getClientDetsilsData={getClientDetsilsData}
              Organisationid={Organisationid}
              getSynchWithProcore={getSynchWithProcore}
              isProcoreConnected={isProcoreConnected}
              setIsSuccess={setIsSuccess}
              getProcoreCompanyData={getProcoreCompanyData}
              isLoading={getClientDetsilsDatas?.isLoading}
              getProcoreCompanyLists={getProcoreCompanyLists}
              procoreCompanyId={procoreCompanyId}
            />
          )}
        </>
    </>
  );
}
