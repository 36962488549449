import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import Buttons from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../../feature/admin/shared/ReportRowManipulate";
import { ORG_ID, ORG_NAME, PAGE_SIZE } from "../../../../constant/constant";
import {
  ADMIN_PROJECTS_LIST,
  SETUP_INSPECTION,
  SETUP_INSPECTION_LIST,
  SW_ADMIN_SAMPLINGLOG,
  SW_CONTACT_PROJECT,
  SW_PERMIT_LIST,
  ADMIN_ADD_STORM_WATERPROJECT,
  SW_OPEN_ITEMS,
  SW_RAIN_FALL_DATA,
  SW_INSPECTION_LIST,
  INSPECTION_AUTHORITY,
  ADMIN_RESOURCE,
} from "../../../../constant/routeContant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import "../../../../feature/admin/component/contacts/viewContact.scss";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import OpenItemsFilter from "../../../open-Items/OpenItemsFilter";
import { Grid } from "@mui/material";
import ToolBar from "../../../../library/storm-water/toolBar";
import { useNavigate } from "react-router";
import { checkPrivileges } from "../../../../utils/rolesHelper";

export default function AddPermitList({
  addPermitNav,
  permitList,
  isLoading,
  handleEditOnClick,
  handlepermitList,
  projectName,
  projectNumber,
  projectid,
  getPermitType,
  stateId,
  handleViewOnClick,
  customer,
  customerId,
}) {
  const navigate = useNavigate();
  const columns = [
    {
      field: "documentUrl",
      headerName: "File Name",
      flex: 4,
      renderCell: (rows) => {
        console.log(rows?.row?.documentUrl, "nn");
        return (
          <>
            {rows?.row?.documentUrl ? (
              <Grid className="catListBox">
                <Grid className="closeIcon closeBtn">
                  <div title={rows?.row?.documentUrl?.slice(15)}>
                    {rows?.row?.documentUrl?.slice(15)}
                  </div>
                </Grid>
              </Grid>
            ) : rows?.row?.documentUrl === undefined ? (
              ""
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
    { field: "permitNumber", headerName: "Permit Number", flex: 1.6 },

    { field: "holderName", headerName: "Permit Holder", flex: 2 },
    {
      field: "approvalDate",
      headerName: "Approval Date",
      flex: 1.8,
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      flex: 1.9,
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={permitList?.itemsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={permitList?.permits ?? []}
          fileNameXL={"PermitList"}
        />
      ),
      flex: 1.3,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={false}
            editOnClick={(row) => handleEditOnClick(row)}
            viewOnClick={(row) => handleViewOnClick(row)}
            edit={checkPrivileges([12, 126, 127])}
            view={true}
            editLabel="Edit Project Permit"
            download={false}
            viewLabel={"View Project Permit"}
          />
        );
      },
    },
  ];
  const [allPermitListFilterData] = useState({
    search: "",
    expstartDate: "",
    expendDate: "",
    apvstartDate: "",
    apvendDate: "",
    permitNumber: "",
    permitHolder: "",
    projectId: projectid,
    page: 1,
    permitType: "",
  });

  const [permitFilterData, setPermitFilterData] = useState({});
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    handlepermitList({
      search: "",
      expstartDate: "",
      expendDate: "",
      apvstartDate: "",
      apvendDate: "",
      permitNumber: "",
      permitHolder: "",
      projectId: projectid,
      page: newPage + 1,
      permitType: "",
    });
  };

  const handleautoSearch = (event, state) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...permitFilterData,
      search: searchText ? searchText : "",
      expstartDate: "",
      expendDate: "",
      apvstartDate: "",
      apvendDate: "",
      permitNumber: "",
      permitHolder: "",
      projectId: projectid,
      page: 1,
      permitType: "",
    };
    setPermitFilterData(data);
    handlepermitList(data);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const resetData = () => {
    setAnchorEl(null);
    setPermitFilterData({});
    setPermitTypes("");

    const data = {
      ...permitFilterData,
      search: "",
      expstartDate: "",
      expendDate: "",
      apvstartDate: "",
      apvendDate: "",
      permitNumber: "",
      permitHolder: "",
      projectId: projectid,
      page: 1,
      permitType: "",
    };
    handlepermitList(data);
  };
  const handleSearchFilter = (event, state) => {
    setAnchorEl(null);
    setPermitFilterData(state);
    const data = {
      search: "",
      expstartDate: state.startDate1,
      expendDate: state.endDate1,
      apvstartDate: state.startDate,
      apvendDate: state.endDate,
      permitNumber: "",
      permitHolder: "",
      projectId: projectid,
      page: page,
      permitType: permitTypes ? permitTypes : 0,
    };
    handlepermitList(data);
  };

  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };
  const [permitTypes, setPermitTypes] = useState("");
  const permitTypeOnChanges = (e) => {
    setPermitTypes(e.target.value);
  };
  const customerName = customer ?? ORG_NAME();
  const toolBarData = {
    title: "Project Tools",
    list: [
      {
        id: 10,
        title: "Edit Project",
        link: "",
        isshow: checkPrivileges([12, 111, 112, 113]),
      },
      {
        id: 1,
        title: "Project Contact",
        link: "",
        isshow: checkPrivileges([12, 132, 133, 134]),
      },
      {
        id: 2,
        title: "Inspection Service List",
        link: "",
        isshow: checkPrivileges([12, 99, 100, 101]),
      },
      {
        id: 9,
        title: "Setup Inspections",
        link: "",
        isshow: checkPrivileges([12, 99, 100]),
      },
      {
        id: 11,
        title: "Permits",
        link: "",
        activeClass: "active",
        isshow: checkPrivileges([12, 126, 127, 128]),
      },

      // {
      //   id: 3,
      //   title: "Permits",
      //   link: "",
      // },

      {
        id: 4,
        title: "Sampling Log",
        link: "",
        isshow: checkPrivileges([12, 129, 130, 131]),
      },
      {
        id: 5,
        title: "Open Items",
        link: "",
        isshow: checkPrivileges([12, 105, 106, 107]),
      },
      {
        id: 6,
        title: "Rainfall Data",
        link: "",
        isshow: true,
      },
      {
        id: 7,
        title: "Inspection Records",
        link: "",
        isshow: checkPrivileges([12, 102, 103, 104]),
      },

      {
        id: 8,
        title: "Inspection Authority",
        link: "",
        isshow: checkPrivileges([12, 96, 97, 98]),
      },
      {
        id: 12,
        title: "Project Resources",
        link: "",
        isshow: checkPrivileges([12, 73, 74, 75]),
      },
    ],
  };
  const dataBack = {
    title: "Back to Edit Projects",
    route: ADMIN_ADD_STORM_WATERPROJECT,
    projectName: projectName,
    projectNumber: projectNumber,
    projectid: projectid,
    customerName: customerName,
    editMode: true,
  };
  return (
    <>
      <section className="grid_main_sec">
        <Grid container lg={12}>
          <Grid lg={8}>
            <Grid className="permitBox" lg={12} mb={2}>
              <Grid className="grid_main_header two_grid" lg={12}>
                <Grid lg={8} className="grid_heading_style" space={2}>
                  Project Permits
                </Grid>
                <Grid lg={4} className="addPermitBtn">
                  {checkPrivileges([12, 126, 127]) && (
                    <Grid lg={12}>
                      <Buttons
                        label={"Add Project Permit"}
                        id="btnAddEquipment"
                        varientAddIconBlue={true}
                        onClick={addPermitNav}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid lg={12} md={12} xs={12} pr={2} item>
              <div className="grid_main_body">
                <OpenItemsFilter
                  handleSearchFilter={handleSearchFilter}
                  handleClose={handleClose}
                  anchorEl={anchorEl}
                  incReport={search}
                  handleInputSearch={handleautoSearch}
                  resetFilterAPI={resetData}
                  placeholder="Search"
                  FromToDate
                  PermitFilter
                  firstLabel="Permittee Type"
                  firstLebelFilter={getPermitType?.permitType}
                  firstLebelValue={permitTypes}
                  firstLebelOnChanges={permitTypeOnChanges}
                />
                <div className=" permitlist_Grid desc_box row_uniq">
                  <div className="table_style_ui">
                    <GridTable
                      getRowId={(r) => r.permitId}
                      rows={permitList?.permits ?? []}
                      rowCount={permitList?.itemsCount ?? 0}
                      columns={columns}
                      checkboxSelection={false}
                      loading={isLoading}
                      page={1}
                      pagination
                      pageSize={PAGE_SIZE}
                      rowsPerPageOptions={[PAGE_SIZE]}
                      onPageChange={handlePagignation}
                      components={{
                        LoadingOverlay: TableLoader,
                        NoRowsOverlay: NORow,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <div className="">
              <BackBtn dataBack={dataBack} />
            </div>
          </Grid>

          <Grid lg={4} md={12} xs={12} pl={1} className="permitsTootBar ">
            <div className="toolBarBox">
              <div className="otherDetail">
                <h3>Projects Details</h3>
                <h2>{projectName}</h2>
                <h2>{projectNumber}</h2>
              </div>

              <div className="otherDetail">
                <h3>Client Name</h3>
                <h2>{customerName}</h2>
              </div>
              <ToolBar
                toolBarData={toolBarData}
                handleClick={(e) => {
                  if (e.id === 1) {
                    navigate(SW_CONTACT_PROJECT, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 2) {
                    navigate(SETUP_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 9) {
                    navigate(SETUP_INSPECTION, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                        rightSideMode: true,
                      },
                    });
                  }
                  if (e.id === 3) {
                    navigate(SW_PERMIT_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectid: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 4) {
                    navigate(SW_ADMIN_SAMPLINGLOG, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 5) {
                    navigate(SW_OPEN_ITEMS, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 6) {
                    navigate(SW_RAIN_FALL_DATA, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 7) {
                    navigate(SW_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 8) {
                    navigate(INSPECTION_AUTHORITY, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 10) {
                    navigate(ADMIN_ADD_STORM_WATERPROJECT, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        editMode: true,
                        projectid: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 12) {
                    navigate(ADMIN_RESOURCE, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectid,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  } else return;
                }}
              />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
