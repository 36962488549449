import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import "./DialogBox.scss";
import commonImages, { accident } from "../../assets";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}
export default function CustomizedIncidentDialogs(props) {
  return (
    <div className="dialogBoxOver">
      <BootstrapDialog
        onClose={props.handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="dialogBoxContainer"
      >
        {props?.warningImg && (
          <div className="inc_warning">
            <img src={accident?.warning} alt="warning" />
          </div>
        )}
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleCloseModal}
          className="dialog-title"
        >
          {props.header ? props?.header : "Close Incident"}
        </BootstrapDialogTitle>
        <DialogContent className="modalParaContent">
          <Typography gutterBottom className="modalPara">
            {props.content
              ? props?.content
              : "Are you sure you want to delete this"}
          </Typography>
        </DialogContent>
        <DialogActions className="dialogConfirm">
          <Button
            autoFocus
            onClick={props.handleCloseModal}
            className="deleteno"
          >
            No
          </Button>
          <Stack direction="row" spacing={2}>
            <Button
              className="deleteyes"
              variant="outlined"
              color="error"
              onClick={() => props.closehandle()}
            >
              Yes
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
