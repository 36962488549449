import { projectType, getProjectDetailsType } from "../constants/projectType";

export const postcreateProject = (data) => ({
  type: projectType.POST_CREATE_PROJECT,
  data,
});

export const postcreateProjectSuccess = (data) => ({
  type: projectType.POST_CREATE_PROJECT_SUCCESS,
  data,
});

export const postcreatesProjectFailure = (error) => ({
  type: projectType.POST_CREATE_PROJECT_FAILURE,
  error,
});

export const getProjectList = (data) => ({
  type: projectType.GET_PROJECT_LIST,
  data,
});

export const getProjectListSuccess = (data) => ({
  type: projectType.GET_PROJECT_LIST_SUCCESS,
  data,
});

export const getProjectListFailure = (error) => ({
  type: projectType.GET_PROJECT_LIST_FAILURE,
  error,
});

// deleteAsset action
export const deleteProject = (payload) => ({
  type: projectType.DELETE_PROJECT,
  payload: payload,
});

export const deleteProjectSuccess = (payload) => ({
  type: projectType.DELETE_PROJECT_SUCCESS,
  payload: payload,
});

export const deleteProjectFailure = (error) => ({
  type: projectType.DELETE_PROJECT_FAILURE,
  error,
});

export const getProjectDetails = (data) => ({
  type: getProjectDetailsType.GET_PROJECT_DETAILS,
  data,
});

export const getProjectDetailsSuccess = (data) => ({
  type: getProjectDetailsType.GET_PROJECT_DETAILS_SUCCESS,
  data,
});

export const getProjectDetailsFailure = (error) => ({
  type: getProjectDetailsType.GET_PROJECT_DETAILS_FAILURE,
  error,
});

export const postProjectUpdate = (data) => ({
  type: projectType.POST_PROJECT_UPDATE,
  data,
});

export const postProjectUpdateSuccess = (data) => ({
  type: projectType.POST_PROJECT_UPDATE_SUCCESS,
  data,
});

export const postProjectUpdateFailure = (error) => ({
  type: projectType.POST_PROJECT_UPDATE_FAILURE,
  error,
});

export const clearProject = () => ({
  type: projectType.CLEAR_PROJECT,
  data: "",
});

export const getProcoreProjectsList = (data) => ({
  type: projectType.GET_PROCORE_PROJECT_LIST,
  data,
});

export const getProcoreProjectsListSuccess = (data) => ({
  type: projectType.GET_PROCORE_PROJECT_LIST_SUCCESS,
  data,
});

export const getProcoreProjectsListFailure = (error) => ({
  type: projectType.GET_PROCORE_PROJECT_LIST_FAILURE,
  error,
});

export const getSyncProcoreProjectsList = (data) => ({
  type: projectType.GET_SYNC_PROCORE_PROJECT_LIST,
  data,
});

export const getSyncProcoreProjectsListSuccess = (data) => ({
  type: projectType.GET_SYNC_PROCORE_PROJECT_LIST_SUCCESS,
  data,
});

export const getSyncProcoreProjectsListFailure = (error) => ({
  type: projectType.GET_SYNC_PROCORE_PROJECT_LIST_FAILURE,
  error,
});
