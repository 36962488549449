import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const DailyReportView = ({
  dailyReportHeader,
  dailyReportInspectionDetails,
}) => {
  return (
    <>
      <div className="overReportHeader">
        <Grid container className="reportHeader  ">
          <Grid md={12} xs={12} className="reportHeaderLeft">
            <div className="reportTitle">
              Report ID:{dailyReportHeader?.logId}
            </div>
          </Grid>
          <Grid md={12} xs={12} className="reportHeaderLeft" mt={2}>
            <div className="reportTitle">
              NPDES & EROSION CONTROL INSPECTION REPORT DAILY LOG
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="basicOveride">
        <Box className="basicinfoWrap">
          <Box className="basicinfoBox">
            <Box className="basicinfoleft sw">
              <Box className="datetime dynmicFeild ">
                <Typography className="listView printListView">
                  Project Name:
                  <span>{dailyReportHeader?.projectName}</span>
                </Typography>

                <Typography className="listView list-right">
                  Project Location:
                  <span>{dailyReportHeader?.projectLocation}</span>
                </Typography>

                <Typography className="listView">
                  Month of Inspection:
                  <span>{dailyReportHeader?.monthOfInspection}</span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>

      <Grid container className="dailyReportView">
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <div className="viewReportIns table_white">
            <table>
              <tr className="tableHead">
                <th>Date</th>
                <th>Inspector</th>
                <th>Rain (Inches)</th>
                <th>Fuel Storage</th>
                <th>Construction Entrance</th>
              </tr>

              {dailyReportInspectionDetails?.map((item) => {
                return (
                  <tr key={item?.logId}>
                    <td>{item?.date}</td>
                    <td>{item?.inspectorName}</td>
                    <td>{item?.rainfall}</td>
                    <td>
                      {parseInt(item?.fuelStorage) === 1
                        ? "Acceptable"
                        : parseInt(item?.fuelStorage) === 2
                        ? "Not Acceptable"
                        : parseInt(item?.fuelStorage) === 3
                        ? "Not Applicable"
                        : ""}
                    </td>
                    <td>
                      {parseInt(item?.constructionEntrance) === 1
                        ? "Acceptable"
                        : parseInt(item?.constructionEntrance) === 2
                        ? "Not Acceptable"
                        : parseInt(item?.constructionEntrance) === 3
                        ? "Not Applicable"
                        : ""}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default DailyReportView;
