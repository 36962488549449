import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import "./searchBar.scss";
const SearchBar = ({ searchbarInput, handleSearch, placeholder }) => {
  return (
    <>
      <div className="searchcaseid">
        <label htmlFor="search">
          <span>
            <SearchIcon />
          </span>
          <div>
            <input
              placeholder={placeholder}
              value={searchbarInput}
              onChange={(e) => handleSearch(e.target.value)}
              id="search"
            />
          </div>
        </label>
      </div>
    </>
  );
};

export default SearchBar;
