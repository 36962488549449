import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_ADD_CREDENTIAL } from "../../../constant/routeContant";
import AdminCredential from "../component/adminCredentialList";
import { useDispatch, useSelector } from "react-redux";
import * as credentialAction from "../../../redux/actions/credentialAction";
import {
  LNG_ID,
  ORG_ID,
  PAGE_SIZE,
  API_STATUS,
} from "../../../constant/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";

function AdminCredentialListContainer() {
  const navigate = useNavigate();

  const defaultData = {
    userId: 0,
    page: 1,
    search: "",
    pageSize: PAGE_SIZE,
    orgId: ORG_ID(),
    credentialType: "",
    userType: 0,
  };

  const userCredentialListData = useSelector(
    (state) => state.getCredentialUserListData
  );
  const isLoading = userCredentialListData?.isLoading;
  const dispatch = useDispatch();

  const getUserCredentials = (data) => {
    console.log(data);
    dispatch(credentialAction.getUserCredentialList(data));
  };

  useEffect(() => {
    getUserCredentials(defaultData);
  }, []);

  const credentialTypes = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.credentialTypes
  );

  const deleteCredentialReducerData = useSelector(
    (state) => state.deleteCredentialReducerData.status
  );

  const deleteCredential = (credentialId) => {
    dispatch(credentialAction.deleteCredential(credentialId));
  };

  useEffect(() => {
    if (deleteCredentialReducerData === API_STATUS.SUCCESS) {
      getUserCredentials(defaultData);
    }
  }, [deleteCredentialReducerData]);

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: 8,
    lngId: LNG_ID,
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  const navigateAddCredential = () => {
    navigate(ADMIN_ADD_CREDENTIAL);
  };
  return (
    <AdminCredential
      navigateAddCredential={navigateAddCredential}
      credentialListData={isLoading ? [] : userCredentialListData?.data?.result}
      isLoading={isLoading}
      getUserCredentials={getUserCredentials}
      credentialTypes={credentialTypes}
      deleteCredentials={deleteCredential}
    />
  );
}

export default AdminCredentialListContainer;
