import React, { useState } from "react";
import "./SwInspectionReports.scss";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import {
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  ORG_NAME,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
  USER_TYPE,
} from "../../../../constant/constant";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import Cards from "../../../../feature/reports/component/Cards";
import { getDateWithDayPast, getFormatedDate } from "../../../../utils/helper";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import { useRef } from "react";
import { useEffect } from "react";
import { InputField } from "../../../../library/custom/textBox/InputField";
import * as userAction from "../../../../redux/actions/userAction";
import * as assetsAction from "../../../../redux/actions/assetsAction";
import { useSelector, useDispatch } from "react-redux";
import UserFilterList from "../../../admin/shared/UserFilterList ";
import FilterListIcon from "@mui/icons-material/FilterList";

function SwInspectionReports({
  autoSearchDataLoading,
  allClients,
  searchClient,
  allUsers,
  getSwInspectionList,
  getSwInspectionListData,
  isSearchClientLoading,
  inspectionTypeValue,
}) {
  const autoComp = useRef(null);
  const dispatch = useDispatch();
  const [clients, setClients] = useState("");
  const [usersValue, setUsersValue] = useState("");
  const [users, setUsers] = useState("");
  const [days, setDays] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [projects, setProjects] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isnepectionTypeId, setIsnepectionTypeId] = useState("");
  const [frequencyTypeId, setFrequencyTypeId] = useState("");
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const inspectionTypeIdOnChanges = (e) => {
    setIsnepectionTypeId(e.target.value);
  };

  const frequencyTypesOnChanges = (e) => {
    setFrequencyTypeId(e.target.value);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const handleSearch = (data) => {
    setAnchorEl(null);
    getSwInspectionListData(data);
  };

  const allProject = useSelector((state) => state.getAssetDynomoList);
  const isProjectLoading = allProject?.isLoading;
  function handleAllProjectSearch(event, value) {
    dispatch(
      assetsAction.getAssetDynomoList({
        orgId: clients?.key ? clients?.key : parseInt(ORG_ID()),
        searchText: event.target.value,
        type: 5,
      })
    );
  }

  useEffect(() => {
    if (parseInt(LOGIN_ORG_ID()) !== parseInt(HBNEXT_ORG_ID)) {
      setClients({
        key: ORG_ID(),
        value: ORG_NAME(),
      });
    }
  }, [LOGIN_ORG_ID(), ORG_NAME(), ORG_ID()]);
  useEffect(() => {
    if (ORG_ID() != LOGIN_ORG_ID()) {
      setClients({
        key: ORG_ID(),
        value: ORG_NAME(),
      });
    }
  }, []);
  const searchUsers = (
    searchText,
    type,
    divisionId,
    assetType,
    assetId,
    priviledgeId,
    productId,
    orgId = clients?.key
  ) => {
    dispatch(
      userAction.searchAllUsers(
        searchText,
        type,
        divisionId,
        assetType,
        assetId,
        17,
        productId,
        orgId
      )
    );
  };

  const handleApply = () => {
    setAnchorEl(null);
    const data = {
      search: search ? search : "",
      inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
      projectId: projects?.key ? projects?.key : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      statusId: 5,
      frequencyId: frequencyTypeId ? frequencyTypeId : "",
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(endDate) : "",
      page: 1,
      pageSize: pageSize,
      userId: users ? users : "",
    };
    getSwInspectionListData(data);
  };
  const handleClear = () => {
    setAnchorEl(null);
    setIsnepectionTypeId("");
    setFrequencyTypeId("");
    const data = {
      search: search ? search : "",
      inspectionTypeId: 0,
      projectId: projects?.key ? projects?.key : 0,
      frequencyId: "",
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      statusId: 5,
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(endDate) : "",
      page: 1,
      pageSize: pageSize,
      userId: users ? users : "",
    };
    getSwInspectionListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      search: search ? search : "",
      inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
      projectId: projects?.key ? projects?.key : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      frequencyId: frequencyTypeId ? frequencyTypeId : "",
      statusId: 5,
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(endDate) : "",
      page: newPage + 1,
      pageSize: pageSize,
      userId: users ? users : "",
    };
    getSwInspectionListData(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      search: search ? search : "",
      inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
      projectId: projects?.key ? projects?.key : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      frequencyId: frequencyTypeId ? frequencyTypeId : "",
      statusId: 5,
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(endDate) : "",
      page: page,
      pageSize: newPazeSize,
      userId: users ? users : "",
    };
    getSwInspectionListData(data);
  };

  const onChangeUsers = (value) => {
    setUsers(value?.key || "");
    setUsersValue({ key: value?.key ?? "", value: value?.value ?? "" });
    const data = {
      search: search ? search : "",
      inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
      projectId: projects?.key ? projects?.key : 0,
      customerId: clients ? clients?.key : parseInt(ORG_ID()),
      frequencyId: frequencyTypeId ? frequencyTypeId : "",
      statusId: 5,
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(endDate) : "",
      page: 1,
      pageSize: pageSize,
      userId: value?.key ? value?.key : "",
    };
    getSwInspectionListData(data);
  };
  const onChangeProjects = (value) => {
    setProjects(value);
    setUsersValue("");
    setUsers("");
    const data = {
      search: search ? search : "",
      inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
      frequencyId: frequencyTypeId ? frequencyTypeId : "",
      projectId: value?.key ? value?.key : 0,
      customerId: clients ? clients?.key : parseInt(ORG_ID()),
      statusId: 5,
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(endDate) : "",
      page: 1,
      pageSize: pageSize,
      userId: value === null ? "" : users ? users : "",
    };
    getSwInspectionListData(data);
  };

  const typeOnchange = (event, value) => {
    searchClient(event?.target?.value);
  };

  const onChangeClients = (value) => {
    console.log("value", value);
    setClients(value);
    setUsersValue("");
    setUsers("");
    setProjects("");
    const data = {
      search: search ? search : "",
      inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
      projectId: value === null ? 0 : projects?.key ? projects?.key : 0,
      customerId: value?.key ? value?.key : parseInt(ORG_ID()),
      frequencyId: frequencyTypeId ? frequencyTypeId : "",
      statusId: 5,
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(endDate) : "",
      page: 1,
      pageSize: pageSize,
      userId: value === null ? "" : users ? users : "",
    };
    getSwInspectionListData(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      search: event.target.value ? event.target.value : "",
      inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
      projectId: projects?.key ? projects?.key : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      frequencyId: frequencyTypeId ? frequencyTypeId : "",
      statusId: 5,
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(endDate) : "",
      page: 1,
      pageSize: pageSize,
      userId: users ? users : "",
    };
    getSwInspectionListData(data);
  };

  const cardOnClick = (value) => {
    setDays(value);
    if (days.key === value.key) {
      setDays("");
      const data = {
        search: search ? search : "",
        inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
        projectId: projects?.key ? projects?.key : 0,
        customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
        frequencyId: frequencyTypeId ? frequencyTypeId : "",
        statusId: 5,
        startDate: "",
        endDate: "",
        page: 1,
        pageSize: pageSize,
        userId: users ? users : "",
      };
      getSwInspectionListData(data);
    } else {
      if (value.key !== 12) {
        let dateObj = getDateWithDayPast(value.key);
        const data = {
          search: search ? search : "",
          inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
          projectId: projects?.key ? projects?.key : 0,
          customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
          frequencyId: frequencyTypeId ? frequencyTypeId : "",
          statusId: 5,
          startDate: dateObj.pastData ? dateObj.pastData : "",
          endDate: dateObj.getCurrentDate ? dateObj.getCurrentDate : "",
          page: 1,
          pageSize: pageSize,
          userId: users ? users : "",
        };
        getSwInspectionListData(data);
        setEndDate(dateObj.getCurrentDate);
        setStartDate(dateObj.pastData);
      }
      if (value.key === 13) {
        setStartDate("");
        setEndDate("");
        const data = {
          search: search ? search : "",
          inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
          projectId: projects?.key ? projects?.key : 0,
          customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
          frequencyId: frequencyTypeId ? frequencyTypeId : "",
          statusId: 5,
          startDate: "",
          endDate: "",
          page: 1,
          pageSize: pageSize,
          userId: users ? users : "",
        };
        getSwInspectionListData(data);
      }
    }
  };

  const onchangeStartdatePicker = (e) => {
    setStartDate(e);
  };

  useEffect(() => {
    if (startDate && endDate && days.key === 12) {
      const data = {
        search: search ? search : "",
        inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
        projectId: projects?.key ? projects?.key : 0,
        customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
        frequencyId: frequencyTypeId ? frequencyTypeId : "",
        statusId: 5,
        startDate: startDate ? getFormatedDate(startDate) : "",
        endDate: endDate ? getFormatedDate(endDate) : "",
        page: 1,
        pageSize: pageSize,
        userId: users ? users : "",
      };
      getSwInspectionListData(data);
    }
  }, [startDate, endDate]);

  const onchangeEnddatePicker = (e) => {
    setEndDate(e);
    const data = {
      search: search ? search : "",
      inspectionTypeId: isnepectionTypeId ? isnepectionTypeId : 0,
      projectId: projects?.key ? projects?.key : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      frequencyId: frequencyTypeId ? frequencyTypeId : "",
      statusId: 5,
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(e) : "",
      page: 1,
      pageSize: pageSize,
      userId: users ? users : "",
    };
    //  getSwInspectionListData(data);
  };

  const filterDays = [
    {
      key: 0,
      value: "Today",
    },
    {
      key: 7,
      value: "7 days",
    },
    {
      key: 14,
      value: "14 days",
    },
    {
      key: 30,
      value: "30 days",
    },
    {
      key: 12,
      value: "Custom",
    },
    {
      key: 13,
      value: "Reset",
    },
  ];

  const credcloumns = [
    { field: "reportId", headerName: " Report ID", flex: 2.8 },
    { field: "projectNumber", headerName: "Project Number", flex: 4 },
    { field: "projectName", headerName: "project", flex: 4 },
    { field: "customer", headerName: "Client", flex: 4 },
    { field: "inspectionBy", headerName: "Inspector", flex: 3.5 },
    { field: "dateScheduled", headerName: "Date Scheduled", flex: 3.4 },
    { field: "openItemCount", headerName: "Opent Items", flex: 2.5 },
    { field: "inspectionType", headerName: "Inspection Type", flex: 2.5 },
    { field: "frequency", headerName: "Frequency", flex: 2.5 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={getSwInspectionList?.recordsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={getSwInspectionList?.inspectionReports ?? []}
          fileNameXL={"InspectionList"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];
  return (
    <div className="SwInspectionReportAnlalytics">
      <div className="grid_heading_style">Inspection</div>
      <Grid item container spacing={3} mt={3}>
        <Grid item lg={5} xs={12} sm={12} md={6}>
          {ORG_ID() !== LOGIN_ORG_ID() ? (
            <FormControl fullWidth>
              <FormLabels label="Client" isRequired={false} />
              <InputField
                isRequired={false}
                type="text"
                placeholder={"Add Client Name Here..."}
                value={clients?.value}
                disabled={ORG_ID() !== 1}
              />
            </FormControl>
          ) : (
            <FormControl fullWidth>
              <FormLabels label="Select Client" isRequired={false} />
              <SearchAutoComplete
                name="orgName"
                placeholderText="Search Client"
                noOptionsTextMsg="No Client"
                optionsData={allClients?.data ?? []}
                typeOnchange={(event, value) => typeOnchange(event, value)}
                isKeyValuePair={true}
                value={clients}
                onChange={(event, value) => onChangeClients(value)}
                isLoading={isSearchClientLoading}
                autoCompClear={autoComp}
                disabled={parseInt(LOGIN_ORG_ID()) !== parseInt(HBNEXT_ORG_ID)}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item lg={5} xs={12} sm={12} md={6}>
          <FormControl fullWidth>
            <FormLabels label="Project" isRequired={false} />
            <SearchAutoComplete
              name="Add Individual Users"
              placeholderText="Search Project"
              optionsData={allProject?.data ?? []}
              typeOnchange={(event, value) =>
                handleAllProjectSearch(event, value)
              }
              isKeyValuePair={true}
              value={projects}
              onChange={(e, value) => onChangeProjects(value)}
              isLoading={isProjectLoading}
              autoCompClear={autoComp}
              noOptionsTextMsg={"No Project"}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container spacing={3} mt={0}>
        <Grid item lg={5} xs={12} sm={12} md={6}>
          <FormControl fullWidth>
            <FormLabels label="Select Inspector" isRequired={false} />
            <SearchAutoComplete
              name="selectInspector"
              placeholderText="Search Inspector"
              optionsData={allUsers ?? []}
              typeOnchange={(event, value) =>
                searchUsers(event.target.value, USER_TYPE.EMPLOYEE)
              }
              isKeyValuePair={true}
              value={usersValue ? usersValue : ""}
              onChange={(event, value) => onChangeUsers(value)}
              isLoading={autoSearchDataLoading}
              noOptionsTextMsg={"No Inspector"}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid item lg={8} xs={12} sm={12} md={8} mt={5}>
        <FormLabels label={"Select Time Period"} />
        <Cards
          staticCardData={true}
          cardData={filterDays}
          selectedVehicleAccident={days}
          cardClickHandler={cardOnClick}
        />
      </Grid>
      {days?.value === "Custom" ? (
        <>
          <Grid item container mt={1} spacing={1}>
            <Grid item lg={4} xs={12} sm={12}>
              <FormLabels label={"Custom Time Period"} isRequired={false} />
            </Grid>
          </Grid>

          <Grid item container spacing={2} mb={2}>
            <Grid item lg={3} xs={12} sm={12} md={4}>
              <FormLabels label={"From"} isRequired={false} />
              <DatePicker
                maxDateEnable={false}
                value={startDate}
                disabled={false}
                onChangeHandler={(e) => onchangeStartdatePicker(e)}
              />
            </Grid>
            <Grid item lg={3} xs={12} sm={12} md={4}>
              <FormLabels label={"To"} isRequired={false} />
              <DatePicker
                maxDateEnable={false}
                minDate={startDate}
                value={endDate}
                disabled={false}
                onChangeHandler={(e) => onchangeEnddatePicker(e)}
              />
            </Grid>
          </Grid>
        </>
      ) : null}
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Inspection Reports</div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search Inspection Report"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              <UserFilterList
                secondLabel="Inspection Type"
                firstLabel="Frequency"
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                secondLebelValue={isnepectionTypeId}
                firstLebelValue={frequencyTypeId}
                handleSearch={handleSearch}
                handleApply={handleApply}
                handleClear={handleClear}
                secondLebelFilter={inspectionTypeValue?.insTypeMstList}
                firstLebelFilter={inspectionTypeValue?.insfrequencyList}
                resetBtn={true}
                secondLebelOnChanges={inspectionTypeIdOnChanges}
                firstLebelOnChanges={frequencyTypesOnChanges}
                isShow={
                  isnepectionTypeId === 1 || (isnepectionTypeId === 3 && true)
                }
              />
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.reportId}
                rows={getSwInspectionList?.inspectionReports ?? []}
                rowCount={getSwInspectionList?.recordsCount ?? 0}
                columns={credcloumns}
                loading={autoSearchDataLoading}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SwInspectionReports;
