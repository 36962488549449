import React from "react";
import { TextField } from "@mui/material";
import FormLabels from "../formLabel/FormLabels";
import "./numberField.scss";

export function NumberField({
  fullWidth,
  value,
  disabled,
  type,
  label,
  placeholder,
  onChange,
  isRequired,
  maxLength,
  id,
  isError = false,
  errorMsg = "",
  name,
}) {
  const handleMinsonChange = (e, value) => {
    e.stopPropagation();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      onChange(e.target.value);
    } else {
      return false;
    }
  };
  return (
    <div className="overideInputField">
      <div className="input_field_box">
        {label && (
          <FormLabels isRequired={isRequired} label={label}></FormLabels>
        )}
        <TextField
          id={id ? id : "defaultId"}
          style={{ width: "100%", margin: 0 }}
          variant="standard"
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          type={type}
          value={value}
          className="formControlRoot"
          onChange={(e) => handleMinsonChange(e)}
          fullWidth={fullWidth}
          size="small"
          InputProps={{
            className: "inputRoot",
            disableUnderline: true,
          }}
          error={isError}
          helperText={value ? "" : errorMsg}
          name={name}
        />
      </div>
    </div>
  );
}
