export const stolenPropertyType = {
  POST_STOLEN_PROPERTY_PENDING:
    "[POST_STOLEN_PROPERTY_PENDING][REQUEST] Try To create stolen property",
  POST_STOLEN_PROPERTY_FAILURE:
    "[POST_STOLEN_PROPERTY_FAILURE][RESPONSE] Stolen property Failed",
  POST_STOLEN_PROPERTY_SUCCESS:
    "[POST_STOLEN_PROPERTY_SUCCESS][RESPONSE] Stolen property Successfull",

  PUT_STOLEN_PROPERTY_PENDING:
    "[PUT_STOLEN_PROPERTY_PENDING][REQUEST] Try To Update stolen property",
  PUT_STOLEN_PROPERTY_FAILURE:
    "[PUT_STOLEN_PROPERTY_FAILURE][RESPONSE] Stolen property Failed",
  PUT_STOLEN_PROPERTY_SUCCESS:
    "[PUT_STOLEN_PROPERTY_SUCCESS][RESPONSE] Stolen property Successfull",

  STOLEN_PROPERTY_PAGE: "StolenPropertyPage",
  GET_STOLEN_PROPERTY_PENDING: "[REQUEST] Get Stolen Property Report Data",
  GET_STOLEN_PROPERTY_SUCCESS:
    "[RESPONSE] Get Stolen Property Report Data Successfull",
  GET_STOLEN_PROPERTY_FAILURE:
    "[RESPONSE] Get Stolen Property Report DatFailed",

  CLEAR_STOLEN_PROPERTY: "[CLEAR_STOLEN_PROPERTY] Clear Stolen Property",
};
