import * as quizAction from "../actions/quizAction";
import { quizType } from "../constants/quizType";


import { call, put,takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

import {
  createQuiz,
  getQuizList,
  submitQuiz,
  getQuizDetails,
  updateQuizList
} from "../../services/quizService";

export function* createQuizSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(createQuiz, payload);
    if (!data && status !== 200) throw apiError(error);
    const casePage = quizType.SUBMIT_QUIZ;
    yield put(
      quizAction.createQuizSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Case Failed" };
    yield put(quizAction.createQuizFailure(data));
  }
}

export function* getQuizListSaga(params) {
  try {
    const { data, status, error } = yield call(getQuizList, params);
    if (!data && status !== 200) throw apiError(error);
    const quizPage = quizType.QUIZ_PAGE;
    yield put(
      quizAction.getQuizListSuccess({
        quizPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Course List Failed" };
    yield put(quizAction.getQuizListFailure(data));
  }
}

export function* submitQuizSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(submitQuiz, payload);
    if (!data && status !== 200) throw apiError(error);
    const submitQuizPage = quizType.SUBMIT_QUIZ;
    yield put(
      quizAction.submitQuizSuccess({
        submitQuizPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Submit Quiz Failed" };
    yield put(quizAction.submitQuizFailure(data));
  }
}
export function* getQuizDetailSaga(params) {
  console.debug("getQuizDetailSaga");
  try {
    const { data, status, error } = yield call(getQuizDetails, params);
    if (!data && status !== 200) throw apiError(error);
    const detailQuizPage = quizType.QUIZ_PAGE_DETAIL;
    yield put(
      quizAction.getQuizDetailsSuccess({
        detailQuizPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "get Resource details Failed" };
    yield put(quizAction.getQuizDetailsFailure(data));
  }
}
export function* updateQuizListSaga(param) {
  const payload = param.payload;
  try {
     const { data, status, error } = yield call(updateQuizList, payload);
  
    if (!data && status !== 200) throw apiError(error);
    const updateQuizPage = quizType.UPDATE_QUIZ;
    yield put(
      quizAction.updateQuizListSuccess({
        updateQuizPage,
        data,
        

      })
    );
  } catch (error) {
      const data =
      error && error.message
        ? error
        : { message: error || "update Quiz Failed" };
    yield put(quizAction.updateQuizListFailure(data));
  }
}

const myCaseSaga = [
  takeLatest(quizType.POST_QUIZ_PENDING, createQuizSaga),
  takeLatest(quizType.GET_QUIZ_LIST_PENDING, getQuizListSaga),
  takeLatest(quizType.POST_SUBMIT_QUIZ_PENDING, submitQuizSaga),
  takeLatest(quizType.GET_QUIZ_DETAILS, getQuizDetailSaga),
  takeLatest(quizType.POST_UPDATE_QUIZ_PENDING,updateQuizListSaga)
];

export default myCaseSaga;