import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./basicInformation.scss";
import Parser from "html-react-parser";
const BasicInformation = ({ basicInformationData }) => {
  return (
    <div className="basicOveride">
      <Box className="basicinfoWrap">
        <Box className="basicinfoBox">
          <Box className="basicinfoleft">
            <Typography className="title">Basic Information:</Typography>
            <Typography className="listView">
              Prepared by:
              <span className="focusedText">
                {basicInformationData?.prepared_name}
              </span>{" "}
            </Typography>
            <Typography className="listView">
              Location:
              <span className="focusedText">
                {basicInformationData?.location}
              </span>{" "}
            </Typography>
            <Box className="datetime">
              <Typography className="listView">
                {basicInformationData?.dateTitle}
                <span>{basicInformationData?.dateOfCase}</span>
              </Typography>
              <Typography className="listView">
                {basicInformationData?.timeTitle}
                <span>{basicInformationData?.timeOfCase}</span>
              </Typography>
            </Box>
            <Box className="datetime">
              {basicInformationData?.foreman_name && (
                <Typography className="listView">
                  Foreman Name:
                  <span>{basicInformationData?.foreman_name}</span>
                </Typography>
              )}
              {basicInformationData?.supervisor_name && (
                <Typography className="listView">
                  Supervisor Name:
                  <span>{basicInformationData?.supervisor_name[1]}</span>
                </Typography>
              )}
            </Box>
            {basicInformationData?.jobsite && (
              <Typography className="listView">
                Jobsite:
                <span>{basicInformationData?.jobsite[1]}</span>{" "}
              </Typography>
            )}
            {basicInformationData?.description && (
              <Typography className="listView desNone">
                Description of Case:{" "}
                <div className="overRideTextEditor">
                  {basicInformationData?.description.length > 0 &&
                    Parser(basicInformationData?.description[0].value || "")}
                </div>
              </Typography>
            )}
          </Box>
          <Box className="basicinfoRight">
            <Box className="basicInformationImgBox">
              <div className="basicInformationImg">
                <img
                  src={basicInformationData?.basicImgUrl}
                  alt="incident-type"
                />
              </div>
              <p className="basiimgname">{basicInformationData?.basicName}</p>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default BasicInformation;
