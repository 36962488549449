import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./basicInformation.scss";
import parse from "html-react-parser";

const BasicInformation = ({ basicInformationData }) => {
  return (
    <div className="basicOveride">
      <Box className="basicinfoWrap">
        <Box className="basicinfoBox">
          <Box className="basicinfoleft">
            <Typography className="title">Basic Information:</Typography>
            <Typography className="listView">
              Inspected by:
              <span className="focusedText">
                {basicInformationData?.inspected_By}
              </span>
            </Typography>
            <Box className="datetime">
              <Typography className="listView">
                Date of Inspection:
                <span>{basicInformationData?.dateOfIns}</span>
              </Typography>
              <Typography className="listView">
                Time of Inspection:
                <span>{basicInformationData?.timeOfIns}</span>
              </Typography>
            </Box>
            <Box className="datetime">
              <Typography className="listView">
                Location:
                <span>{basicInformationData?.location}</span>
              </Typography>
              <Typography className="listView">
                {basicInformationData?.assetType === 1
                  ? "Project:"
                  : basicInformationData?.assetType === 3
                  ? "Vehicle:"
                  : basicInformationData?.assetType === 2
                  ? "Equipment:"
                  : basicInformationData?.assetType === 4
                  ? "Custom asset:"
                  : ""}

                <span>{basicInformationData?.asset}</span>
              </Typography>
            </Box>

            <Typography className="listView">
              Weather at Time of Inspection:
              <span>{basicInformationData?.weatherTimeofIns}</span>
            </Typography>

            <Typography className="listView desNone">
              General Comments:
              <div className="overRideTextEditor">
                {parse(unescape(basicInformationData?.generalComment))}
              </div>
            </Typography>
          </Box>
          <Box className="basicinfoRight"></Box>
        </Box>
      </Box>
    </div>
  );
};

export default BasicInformation;
