import React, { useRef } from "react";
import { Grid, RadioGroup, FormControl } from "@mui/material";
import RadioButton from "../custom/radioButton/RadioButton";
import TextArea from "../custom/textArea/TextArea";
import FormLabels from "../custom/formLabel/FormLabels";
import UsersChip from "../../library/custom/usersChip/UsersChip";
import SearchAutoComplete from "../../library/custom/searchAutoComplete/SearchAutoComplete";
import "./NotAcceptable.scss";
import {
  InspectionInvolvedPartyOptionData,
  escalatetoReprimand,
  closeItem,
} from "../../constant/inspectionConstant";
import { USER_TYPE } from "../../constant/constant";
import TextEditor from "../custom/textEditor/TextEditor";
import FormDropZone from "../dropZone/FormDropZone";

const NotAcceptableBoxDetails = ({
  handleOnChange,
  data,
  uploadImg,
  loadingImg,
  setImage,
  filesId,
  filesUrl,
  deleteuser,
  deleteResponsibilityuser,
  commentValue,
  involdevPatyValue,
  employeeValue,
  subcontractorValue,
  uniqueId,
  escalatetoreprimandIsCheckedValue,
  escalatetoreprimandCommentValue,
  itemStatusIsCheckedValue,
  itemStatusCommentValue,
  itemStatusCommentUserValue,
  deleteform,
  notacceptablelength,
  deleteImageURLS,
  deleteSubContractor,
  itemCount,
  handleAllUserSearch,
  isLoading,
  allUsers,
  filess,
  assetId,
}) => {
  const autoCompInspector = useRef(null);

  const subcontractorValueCheck = subcontractorValue?.filter(
    (item) => item.value !== ""
  );
  const employeeValueCheck = employeeValue?.filter((item) => item.value !== "");
  return (
    <>
      <div className="notAcceptableBox">
        <Grid item md={12} xs={12} textAlign="left" mt={2}>
          {notacceptablelength > 1 && (
            <div
              onClick={() => deleteform(uniqueId)}
              className="removeRepetBox"
            >
              X Remove Items
            </div>
          )}
          <FormLabels label="Issue Found" />
          <TextEditor
            value={commentValue ?? ""}
            placeholderText={"Add Issue Found Here..."}
            onChange={(e) => handleOnChange(e, uniqueId, "commentFeild")}
          />
          {commentValue?.length === 500 && (
            <p className={commentValue?.length === 500 ? "error" : "errornot"}>
              Character limit exceeded.
            </p>
          )}

          <div className="customMultiLable">
            <FormDropZone
              isMultipleIcon={true}
              paraText1={"Drop the files here ..."}
              paraText2={"Drop the files here, "}
              paraText3={"or Browse"}
              handleFileChange={(photo, fileDataId) =>
                setImage(photo, fileDataId, uniqueId)
              }
              fileType={10}
              imageUrl={filesUrl}
              deleteImageURL={(url, index) =>
                deleteImageURLS(url, index, uniqueId)
              }
              id="uploadMedia"
              key="uploadMedia"
              isMuliple={true}
              isDoc={false}
              isImage={true}
              allFiles={false}
            />
          </div>
        </Grid>

        <div className="container inVoledParty">
          <FormLabels label="Involved Party Type" labelPlacement="start" />
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            className="radioGroup"
          >
            {InspectionInvolvedPartyOptionData &&
              InspectionInvolvedPartyOptionData.map((item) => (
                <RadioButton
                  key={item.key}
                  checked={involdevPatyValue === item.value}
                  value={item.value}
                  label={item.label}
                  onChange={(e) =>
                    handleOnChange(e, uniqueId, "Involved Party Type")
                  }
                />
              ))}
          </RadioGroup>
        </div>

        {involdevPatyValue === "Employee" && (
          <>
            <Grid item container mt={0} spacing={2}>
              <Grid item lg={4} md={9} xs={12}>
                <FormControl fullWidth>
                  <FormLabels label="Add Involved Employee" />

                  <SearchAutoComplete
                    optionsData={isLoading ? [] : allUsers?.users ?? []}
                    value={employeeValue[0] ?? ""}
                    onChange={(event, value) =>
                      handleOnChange(value, uniqueId, "Add Involved Employee")
                    }
                    name="Add Involved Party"
                    typeOnchange={(event, value) =>
                      handleAllUserSearch(
                        event,
                        USER_TYPE.NONPRIVILEGED,
                        0,
                        0,
                        assetId,
                        0
                      )
                    }
                    isKeyValuePair={true}
                    isLoading={isLoading}
                    autoCompClear={autoCompInspector}
                  />
                </FormControl>
              </Grid>
              <Grid md={9} lg={9} mt={3} ml={2} xs={12}>
                <div className="pastVioltionBox">
                  <UsersChip
                    userList={employeeValueCheck}
                    handleDelete={(e) => deleteuser(e, uniqueId)}
                    isCountChip={true}
                    isCount={itemCount}
                  />
                </div>
              </Grid>
            </Grid>
          </>
        )}

        {involdevPatyValue === "Sub-Contractor" && (
          <>
            <Grid item container mt={0} spacing={2}>
              <Grid item lg={4} md={9} xs={12}>
                <FormControl fullWidth>
                  <FormLabels label="Add Involved Company" />
                  <SearchAutoComplete
                    optionsData={isLoading ? [] : allUsers?.users ?? []}
                    value={subcontractorValue[0] ?? ""}
                    onChange={(event, value) =>
                      handleOnChange(value, uniqueId, "Add Involved Company")
                    }
                    name="Add Involved Party"
                    typeOnchange={(event, value) =>
                      handleAllUserSearch(
                        event,
                        USER_TYPE.CONTRACTOR,
                        0,
                        0,
                        assetId,
                        0
                      )
                    }
                    isKeyValuePair={true}
                    isLoading={isLoading}
                    autoCompClear={autoCompInspector}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid md={9} lg={12} mt={3} ml={2} xs={12}>
              <div className="pastVioltionBox">
                <UsersChip
                  userList={subcontractorValueCheck}
                  handleDelete={(e) => deleteSubContractor(e, uniqueId)}
                  isCountChip={true}
                  isCount={itemCount}
                />
              </div>
            </Grid>
          </>
        )}

        {/* ===============================Escalate to reprimand radio button ================== */}
        {(involdevPatyValue === "Sub-Contractor" ||
          involdevPatyValue === "Employee") && (
          <div className="notAcceptableBoxcontainer">
            <FormLabels label="Escalate to Reprimand" />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              {escalatetoReprimand &&
                escalatetoReprimand.map((item) => (
                  <RadioButton
                    key={item.key}
                    checked={escalatetoreprimandIsCheckedValue === item.value}
                    value={item.value}
                    label={item.label}
                    onChange={(e) =>
                      handleOnChange(e, uniqueId, "Escalate to Reprimand")
                    }
                  />
                ))}
            </RadioGroup>
          </div>
        )}

        {escalatetoreprimandIsCheckedValue == "Yes" && (
          <Grid item container>
            <Grid item lg={12} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Enter Action Taken" />
                <TextEditor
                  value={escalatetoreprimandCommentValue ?? ""}
                  placeholderText={"Enter Here..."}
                  onChange={(e) =>
                    handleOnChange(e, uniqueId, "Escalate to Reprimand comment")
                  }
                />
                {escalatetoreprimandCommentValue?.length === 500 && (
                  <p
                    className={
                      escalatetoreprimandCommentValue?.length === 500
                        ? "error"
                        : "errornot"
                    }
                  >
                    Character limit exceeded.
                  </p>
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid item container>
          <Grid item lg={7} className="inVoledParty">
            <FormLabels label="Item Status" />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              {closeItem &&
                closeItem.map((item) => (
                  <RadioButton
                    key={item.key}
                    checked={itemStatusIsCheckedValue === item.value}
                    value={item.value}
                    label={item.label}
                    onChange={(e) => handleOnChange(e, uniqueId, "Item Status")}
                  />
                ))}
            </RadioGroup>
          </Grid>
        </Grid>

        {itemStatusIsCheckedValue === "Closed" && (
          <Grid item container mt={2}>
            <Grid item lg={12} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Add Abatement Notes" />
                <TextEditor
                  value={itemStatusCommentValue ?? ""}
                  placeholderText={"Enter Here..."}
                  onChange={(e) =>
                    handleOnChange(e, uniqueId, "Item Status Comment")
                  }
                />
                {itemStatusCommentValue?.length === 500 && (
                  <p
                    className={
                      itemStatusCommentValue?.length === 500
                        ? "error"
                        : "errornot"
                    }
                  >
                    Character limit exceeded.
                  </p>
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}
        {itemStatusIsCheckedValue === "Open" && (
          <Grid item container mt={0} spacing={2}>
            <Grid item lg={4}>
              <FormControl fullWidth>
                <FormLabels
                  label="Assign Item Responsibility"
                  isRequired={true}
                />
                <SearchAutoComplete
                  optionsData={isLoading ? [] : allUsers?.users ?? []}
                  value={itemStatusCommentUserValue ?? ""}
                  onChange={(event, value) =>
                    handleOnChange(value, uniqueId, "items user")
                  }
                  name="Add Involved Party"
                  typeOnchange={(event, value) =>
                    handleAllUserSearch(
                      event,
                      USER_TYPE.DIVISION_EMPLOYEE,
                      0,
                      0,
                      assetId,
                      0
                    )
                  }
                  isKeyValuePair={true}
                  isLoading={isLoading}
                  autoCompClear={autoCompInspector}
                />
              </FormControl>
            </Grid>
            <Grid md={9} mt={3} ml={2} xs={12}>
              <UsersChip
                userList={
                  itemStatusCommentUserValue?.value && [
                    itemStatusCommentUserValue,
                  ]
                }
                handleDelete={(e) => deleteResponsibilityuser(e, uniqueId)}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default React.memo(NotAcceptableBoxDetails);
