import React, { useEffect, useState } from "react";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import AddQuestion from "../component/inspection/addQuestion";
import { ToastContainer, toast } from "react-toastify";
import { LNG_ID, ORG_ID, PAGE_SIZE } from "../../../constant/constant";

const InspectionQuestionContainer = () => {
  const dispatch = useDispatch();

  const defaultSubData = {
    searchText: "",
    organisationId: ORG_ID(),
    lngId: LNG_ID,
    pageSize: PAGE_SIZE,
    page: 1,
    allowPaging: false,
  };

  const getQuestionsList = useSelector((state) => state.getQuestionsList);
  const getQuestionsListData = (data) => {
    dispatch(incpectionAction.getQuestionsList(data));
  };

  const getSubCategoriesList = useSelector(
    (state) => state.getSubCategoriesList
  );
  const getSubCategoriesListData = (data) => {
    dispatch(incpectionAction.getSubCategoriesList(data));
  };

  const createUpdateQuestions = useSelector(
    (state) => state.addUpdateQuestions
  );

  const showLoader = useSelector(
    (state) =>
      state.getQuestionsList?.isLoading ||
      state.getSubCategoriesList?.isLoading ||
      state.addUpdateQuestions?.isLoading
  );

  const questionListStatus = useSelector(
    (state) => state.getQuestionsList?.isLoading
  );

  const [clearQuestion, setClearQuestion] = useState(false);

  const onSubmit = (data) => {
    setClearQuestion(true);
    dispatch(incpectionAction.addUpdateQuestions(data));
  };
  useEffect(() => {
    getSubCategoriesListData(defaultSubData);
  }, []);

  useEffect(() => {
    if (
      createUpdateQuestions?.status === "SUCCESS" &&
      createUpdateQuestions?.isLoading === false &&
      clearQuestion
    ) {
      toast("Questions Added successfully");
    }
  }, [createUpdateQuestions]);

  return (
    <>
      {showLoader && <Loader />}

      <AddQuestion
        getQuestionsList={
          questionListStatus ? [] : getQuestionsList?.data?.keyValues
        }
        getSubCategoriesList={getSubCategoriesList?.data?.keyValues.filter(
          (x) => x.isActive
        )}
        getQuestionsListData={getQuestionsListData}
        onSubmit={onSubmit}
        createUpdateQuestions={createUpdateQuestions}
        showLoader={questionListStatus}
      />
      <ToastContainer />
    </>
  );
};

export default InspectionQuestionContainer;
