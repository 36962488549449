import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "../../../../library/button/button";
import "./otherIncidents.scss";

const OtherIncidents = ({ incData }) => {
  return (
    <>
      {incData && (
        <div className="otherIncOver">
          <Box>
            <Typography variant="h1" className="incetitle">
              Other Incidents within this Case
            </Typography>
            <Box className="incbtnlist">
              {incData.map((list) => (
                <Button
                  key={list?.id}
                  varientContained={true}
                  label={list?.value}
                />
              ))}
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default OtherIncidents;
