import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import "./contractor.scss";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { NumberField } from "../../../../library/custom/numberField/numberField";

import TextArea from "../../../../library/custom/textArea/TextArea";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  COUNTRY_CODE,
  TEXT_FIELD_ERROR_MSG,
  DROP_DOWN_FIELD_ERROR_MSG,
  USER_ID,
} from "../../../../constant/constant";

const ContractorConfigurationEdit = ({
  divisions,
  editMode,
  contractorGrades,
  contractorTypes,
  stateData,
  addContractor,
  contractorId,
  viewContractors,
}) => {
  function findValueMultipleSelect(arrrayofObject, selectedArray) {
    let valueArray = [];
    arrrayofObject?.forEach((data) => {
      if (selectedArray.indexOf(data.value) >= 0) {
        valueArray.push(data.key);
      }
    });
    return valueArray;
  }

  const [divisionIds, setDivisionIds] = useState([]);
  const divisionsData = findValueMultipleSelect(divisions, divisionIds);

  function initiateState(editModeObject) {
    const keyDynamic = editMode ? "modifiedBy" : "createdBy";
    const divisionId = editMode ? "divisionIds" : "divisionId";
    const intialState = {
      contractorTypeId: editModeObject ? editModeObject?.contractorTypeId : "",
      stateId: editModeObject ? editModeObject?.stateId : "",
      contractorName: editModeObject ? editModeObject?.contractorName : "",
      gradeId: editModeObject ? editModeObject?.gradeId : "",
      pocFirstName: editModeObject ? editModeObject?.pocFirstName : "",
      pocLastName: editModeObject ? editModeObject?.pocLastName : "",
      pocEmail: editModeObject ? editModeObject?.pocEmail : "",
      isdCode: COUNTRY_CODE,
      pocPhone: editModeObject ? editModeObject?.pocPhone : "",
      pocCity: editModeObject ? editModeObject?.pocCity : "",
      pocZipCode: editModeObject ? editModeObject?.pocZipCode : "",
      pocNotes: editModeObject ? editModeObject?.pocNotes : "",
      status: editModeObject ? editModeObject?.status : "",
      statusText: editModeObject ? editModeObject?.statusText : "",
      createdOn: editModeObject ? editModeObject?.createdOn : "",
      updatedOn: editModeObject ? editModeObject?.updatedOn : "",
      modifiedbyuser: parseInt(USER_ID()),
      isProvided: true,
      contractorId: editModeObject ? editModeObject?.contractorId : "",
    };
    return intialState;
  }
  useEffect(() => {
    const divisionId = editMode ? "divisionIds" : "divisionId";
    setFormValue({
      ...formValue,
      [divisionId]: divisionsData,
    });
  }, [divisionIds]);

  const [formValue, setFormValue] = useState(initiateState());

  const onChangeMultipleDivision = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);

    setDivisionIds(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    let valueArray = [];
    divisions?.forEach((data) => {
      let newarr = viewContractors?.data?.result?.divisionIds?.forEach((i) => {
        if (i === data.key) valueArray.push(data.value);
      });
      console.log(valueArray);
      if (divisionIds.indexOf(data) >= 0) {
        valueArray.push(data.value);
      }
    });
    setDivisionIds(valueArray);
  }, [viewContractors, divisions]);

  useEffect(() => {
    if (viewContractors?.data) {
      setFormValue(initiateState(viewContractors?.data));
    }
  }, [viewContractors]);

  const history = useNavigate();
  const [contractoNameError, setContractoNameError] = useState(false);
  const [contractoTypeError, setContractoTypeError] = useState(false);
  const [contractoGradeError, setContractoGradeError] = useState(false);
  const [contractoFnameError, setContractoFnameError] = useState(false);
  const [contractoLnameError, setContractoLnameError] = useState(false);
  const [contractoEmailError, setContractoEmailError] = useState(false);
  const [contractoPhoneError, setContractoPhoneError] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [divisionsError, setDivisionsError] = useState(false);
  const [stateIdError, setStateIdError] = useState(false);
  const [pocCityError, setPocCityError] = useState(false);
  const [pocZipCodeError, setPocZipCodeError] = useState(false);

  const onClickAddUser = (e) => {
    let isError = false;
    if (!formValue.contractorName) {
      setContractoNameError(true);
      isError = true;
    }
    if (!formValue.contractorTypeId) {
      setContractoTypeError(true);
      isError = true;
    }
    if (!formValue.gradeId) {
      setContractoGradeError(true);
      isError = true;
    }
    if (editMode) {
      if (formValue.divisionIds.length == 0) {
        setDivisionsError(true);
        isError = true;
      }
    }
    if (!formValue.pocFirstName) {
      setContractoFnameError(true);
      isError = true;
    }
    if (!formValue.pocLastName) {
      setContractoLnameError(true);
      isError = true;
    }
    if (!formValue.pocEmail) {
      setContractoEmailError(true);
      isError = true;
    }
    if (!formValue.pocPhone) {
      setContractoPhoneError(true);
      isError = true;
    }
    if (!formValue.stateId) {
      setStateIdError(true);
      isError = true;
    }
    if (!formValue.pocCity) {
      setPocCityError(true);
      isError = true;
    }
    if (!formValue.pocZipCode) {
      setPocZipCodeError(true);
      isError = true;
    }

    if (!isError) {
      const sendData = formValue;
      addContractor(editMode ? formValue : sendData);
    }
  };
  return (
    <Grid container mt={4}>
      <Grid item lg={12} xs={12} sm={12} md={4}>
        <Grid container item lg={9} xs={12} sm={12} md={9}>
          <Grid item lg={6} xs={12} sm={12} md={6} textAlign="left">
            <div className="heading_style">Edit Sub-Contractor</div>
          </Grid>
        </Grid>
        <div>
          <Grid container item md={9} xs={9} className="addShadowBox">
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"Sub-Contractor Name"}
                    value={formValue.contractorName}
                    placeholder="Sub-Contractor Name"
                    onChange={(e) => {
                      setContractoNameError(false);
                      setFormValue({
                        ...formValue,
                        contractorName: e.target.value,
                      });
                    }}
                    isError={contractoNameError}
                    errorMsg={
                      contractoNameError && !formValue.contractorName
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <FormLabels label="Sub-Contractor Type" isRequired={true} />
                  <SelectMenu
                    placeholder={"Please Select"}
                    listData={contractorTypes}
                    value={formValue.contractorTypeId}
                    onchangehandler={(e) => {
                      setContractoTypeError(false);
                      setFormValue({
                        ...formValue,
                        contractorTypeId: e.target.value,
                      });
                    }}
                    isError={contractoTypeError}
                    errorMsg={
                      contractoTypeError && !formValue.contractorTypeId
                        ? DROP_DOWN_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container mt={2} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <FormLabels label="Grade" isRequired={true} />
                  <SelectMenu
                    placeholder={"Please Select"}
                    listData={contractorGrades}
                    value={formValue.gradeId}
                    onchangehandler={(e) => {
                      setContractoGradeError(false);
                      setFormValue({
                        ...formValue,
                        gradeId: e.target.value,
                      });
                    }}
                    isError={contractoGradeError}
                    errorMsg={
                      contractoGradeError && !formValue.gradeId
                        ? DROP_DOWN_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <FormLabels label="Division" isRequired={true} />
                  <MultiSelectBox
                    placeholder={"Please Select"}
                    listData={divisions}
                    onChange={(e) => onChangeMultipleDivision(e)}
                    value={divisionIds ?? []}
                    isError={divisionsError}
                    errorMessage={
                      divisionsError && formValue.divisionIds?.length == 0
                        ? DROP_DOWN_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <h1 className="pocHeading">Point of Contact Details</h1>

            <Grid container mt={1} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"First Name"}
                    value={formValue.pocFirstName}
                    placeholder="Enter first name"
                    onChange={(e) => {
                      setContractoFnameError(false);
                      setFormValue({
                        ...formValue,
                        pocFirstName: e.target.value,
                      });
                    }}
                    isError={contractoFnameError}
                    errorMsg={
                      contractoFnameError && !formValue.pocFirstName
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"Last Name"}
                    value={formValue.pocLastName}
                    placeholder="Enter last name"
                    onChange={(e) => {
                      setContractoLnameError(false);
                      setFormValue({
                        ...formValue,
                        pocLastName: e.target.value,
                      });
                    }}
                    isError={contractoLnameError}
                    errorMsg={
                      contractoLnameError && !formValue.pocLastName
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container mt={2} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"Email"}
                    value={formValue.pocEmail}
                    placeholder="Enter Email"
                    onChange={(e) => {
                      setContractoEmailError(false);
                      setFormValue({
                        ...formValue,
                        pocEmail: e.target.value,
                      });
                    }}
                    isError={contractoEmailError}
                    errorMsg={
                      contractoEmailError && !formValue.pocEmail
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <NumberField
                    type="text"
                    disabled={false}
                    isRequired={true}
                    label="Phone Number"
                    maxLength="10"
                    value={formValue.pocPhone}
                    placeholder={`Enter Phone number without ${COUNTRY_CODE}`}
                    onChange={(value) =>
                      value.length <= 10
                        ? setFormValue({
                            ...formValue,
                            pocPhone: value,
                          })
                        : formValue.pocPhone
                    }
                    isError={contractoPhoneError}
                    errorMsg={
                      contractoPhoneError && !formValue.pocEmail
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container mt={0} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="State" isRequired={true} />
                  <SelectMenu
                    placeholder={"Please Select"}
                    listData={stateData}
                    value={formValue.stateId}
                    onchangehandler={(e) =>
                      setFormValue({
                        ...formValue,
                        stateId: e.target.value,
                      })
                    }
                    isError={stateIdError}
                    errorMsg={
                      stateIdError && !formValue.stateId
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"City"}
                    maxLength="10"
                    value={formValue.pocCity}
                    placeholder={`City`}
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        pocCity: e.target.value,
                      })
                    }
                    isError={pocCityError}
                    errorMsg={
                      pocCityError && !formValue.pocCity
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container mt={0} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <NumberField
                    type="text"
                    isRequired={true}
                    label="Zip Code"
                    placeholder="Add Zip Code Here..."
                    maxLength="5"
                    value={formValue.pocZipCode}
                    onChange={(e) =>
                      e.length <= 5
                        ? setFormValue({
                            ...formValue,
                            pocZipCode: e,
                          })
                        : formValue.pocZipCode
                    }
                    isError={pocZipCodeError}
                    errorMsg={
                      pocZipCodeError && !formValue.pocZipCode
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container mt={0} spacing={3}>
              <Grid item lg={12} xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <FormLabels label="Add Notes" />
                  <TextArea
                    placeholder={"Enter Here"}
                    value={formValue.pocNotes}
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        pocNotes: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container mb={10} mt={1} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left"></Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <div className="adduser_btn">
                  <div
                    className="cancle_heading_style"
                    onClick={() => history(-1)}
                  >
                    Cancel
                  </div>
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Update Contractor"}
                    varientContained={true}
                    onClick={(e) => onClickAddUser(e)}
                  ></Buttons>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
export default ContractorConfigurationEdit;
