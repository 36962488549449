import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FromToDatePicker from "../../../library/custom/datePicker/DatePicker";
import {
  Card,
  CardContent,
  Box,
  CardActions,
  FormControl,
} from "@mui/material";
import Buttons from "../../../library/custom/button/Button";
import { OPEN_CASE_LIST_FILTER } from "../../../constant/constant";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
const FilterList = ({
  id,
  open,
  anchorEl,
  handleClose,
  dateOfCaseFrom,
  dateOfCaseHandlerFrom,
  dateOfCaseTo,
  dateOfCaseHandlerTO,
  handleSearch,
  resetFilter,
  onChangeSelectUserType,
  caseStatus,
}) => {
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases">
          <CardContent>
            <Box className="" sx={{ pb: 2 }}>
              {/* <Typography gutterBottom variant="h5">
                Time Period
              </Typography> */}
                    <FormLabels label="Time Period" isRequired={false} />

              <Box className="datePicker_row">
                <div>
                  <Typography variant="subtitle1">From</Typography>
                  <FromToDatePicker
                    value={dateOfCaseFrom}
                    onChangeHandler={dateOfCaseHandlerFrom}
                  />
                </div>
                <div>
                  <Typography variant="subtitle1">To</Typography>
                  <FromToDatePicker
                    value={dateOfCaseTo}
                    onChangeHandler={dateOfCaseHandlerTO}
                  />
                </div>
                <div>
                  <FormControl fullWidth>
                    <FormLabels label="Case Status" isRequired={false} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={OPEN_CASE_LIST_FILTER}
                      value={caseStatus}
                      onchangehandler={(e) => onChangeSelectUserType(e)}
                    />
                  </FormControl>
                </div>
              </Box>
            </Box>
          </CardContent>
          <CardActions>
            <Buttons
              primary={true}
              label={"Reset"}
              onClick={(e) => resetFilter(e)}
            />
            <Buttons
              primary={true}
              label={"Apply"}
              onClick={(e) => handleSearch(e)}
            />
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default FilterList;
