import { newsType } from "../constants/newsType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "news",
  isError: false,
  isLoading: false,
};

export const newsAddReducer = (state = initialState, action) => {
  switch (action.type) {
    case newsType.POST_NEWS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case newsType.POST_NEWS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case newsType.POST_NEWS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const getNewsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case newsType.GET_LIST_NEWS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case newsType.GET_LIST_NEWS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case newsType.GET_LIST_NEWS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case newsType.CLEAR_GETNEWS_NEWS:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
export const getNewsDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case newsType.GET_NEWS_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case newsType.GET_NEWS_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case newsType.GET_NEWS_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
export const updateNewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case newsType.PUT_NEWS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case newsType.PUT_NEWS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case newsType.PUT_NEWS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case newsType.CLEAR_NEWS:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
export const deleteNewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case newsType.DELETE_NEWS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case newsType.DELETE_NEWS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case newsType.DELETE_NEWS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
