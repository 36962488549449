import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./filterButtonStyle.scss";
const FilterButton = ({ id = "btn", label = "Filter", handleClick }) => {
  return (
    <div className="overrideFilterButton">
      <button
        aria-describedby={id}
        variant="contained"
        type="button"
        name="btn"
        onClick={(e) => handleClick(e)}
        className="filterbtns"      >
        <FilterListIcon id="icn" />
        {label}
      </button>
    </div>
  );
};

export default FilterButton;
