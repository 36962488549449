import { personalInjuryIncidentReportType } from "../constants/personalInjuryIncidentReportType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "personalInjuryIncidentReport",
  isError: false,
  isLoading: false,
};

export const getPersonalInjuryIncidentReportCountReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_COUNT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getPersonalInjuryIncidentReportListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case personalInjuryIncidentReportType.GET_PERSONAL_INJURY_INCIDENT_REPORT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
