import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SearchIcon from "@mui/icons-material/Search";
import TabPanel from "../tabPanel/tabPanel";
import Popper from "@mui/material/Popper";
import LmsFilterList from "../../../../library/custom/lmsFilterList/lmsFilterList";
import TableCardView from "../../../../library/custom/tableCardView/tableCardView";
import TableCard from "../../../../library/custom/tableCard/tableCard";
import { useNavigate } from "react-router-dom";
import { COURSESDETAIL } from "../../../../constant/routeContant";
import { ORG_ID, LOGIN_ORG_ID, USER_ID } from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { useDispatch } from "react-redux";
import * as lmsUserAction from "../../../../redux/actions/lmsUserAction";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import "./usercourselist.scss";
import FilterButton from "../../../../library/custom/filterButton/filterButton";
const UserCoursesList = ({
  coursesUserListData,
  getCoursesUserListData,
  categoryUserListData,
  isLoading,
  isShow,
  isProfile = false,
  isSafetyLog = false,
  usersId,
  setcourseid,
  courseid,
  setisstart,
  isstart,
  postunEnrollCourse
}) => {
  const [value, setValue] = useState(0);
  const [placement, setPlacement] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ searchType: [] });
  const PAGE_SIZE = 6;

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: searchText ? searchText : "",
      catIds: filter?.searchType[0]?.key ? filter?.searchType[0]?.key : "",
      statusId: value === 0 ? 2 : value === 2 ? "" : 1,
      userId: USER_ID(),
      orgId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    };
    getCoursesUserListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getCoursesUserListData({
      pageNumber: newPage + 1,
      pageSize: PAGE_SIZE,
      search: "",
      catIds: filter?.searchType[0]?.key ? filter?.searchType[0]?.key : "",
      statusId:
        !isProfile && isSafetyLog === false
          ? value === 0
            ? 2
            : value === 2
            ? ""
            : 1
          : "",
      userId: isSafetyLog ? usersId : USER_ID(),
      orgId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getCoursesUserListData({
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: "",
      catIds: filter?.searchType[0]?.key ? filter?.searchType[0]?.key : "",
      statusId: newValue === 0 ? 2 : newValue === 2 ? "" : 1,
      userId: USER_ID(),
      orgId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleFilterClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const id = open ? "simple-popper" : undefined;

  const navigate = useNavigate();

  const handleViewOnClick = (courseId, status, statusId) => {
    setcourseid(courseId);
    if (statusId === 3) {
      const data = {
        createdBy: parseInt(USER_ID()),
        userType: 2,
        courseId: courseId,
        userOrGroupId: parseInt(USER_ID()),
        orgId: parseInt(ORG_ID()),
      };

      dispatch(lmsAction.assignCourse(data));
    } else if (statusId === 2) {
      setisstart(true);
      const data = {
        courseId: courseId,
        userId: parseInt(USER_ID()),
        orgId: parseInt(ORG_ID()),
      };

      dispatch(lmsUserAction.startCourse(data));
    } else {
      navigate(COURSESDETAIL, {
        state: { courseId: courseId },
      });
    }

    setTimeout(() => {
      const data1 = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: "",
        catIds: "",
        statusId: value === 0 ? 2 : value === 2 ? "" : 1,
        userId: parseInt(USER_ID()),
        orgId: isProfile ? LOGIN_ORG_ID() : parseInt(ORG_ID()),
      };
      getCoursesUserListData(data1);
    }, 2000);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const handleSearch = (data) => {
    setAnchorEl(null);
    getCoursesUserListData(data);
    setOpen(false);
  };
  const viewUnEnrollOnClick = (courseId, status, statusId) => {
    const DataUnEnrollCourse = {
      courseId: courseId,
      userId: parseInt(USER_ID()),
      modifiedBy: parseInt(USER_ID()),
    };

    postunEnrollCourse(DataUnEnrollCourse);

    setTimeout(() => {
      const data1 = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: "",
        catIds: "",
        statusId: value === 0 ? 2 : value === 2 ? "" : 1,
        userId: parseInt(USER_ID()),
        orgId: isProfile ? LOGIN_ORG_ID() : parseInt(ORG_ID()),
      };
      getCoursesUserListData(data1);
    }, 2000);
  };
  const columns = [
    {
      field: "courseId",
      width: "fit-content",
      cellClassName: "customCellTable custom",
      renderCell: (cellValues) => {
       const { id: courseIDS } = cellValues;
        const courseId=courseIDS.split("|")?.[0];
        const status = cellValues?.row?.status;
        const statusId = cellValues?.row?.statusId;
        return (
          <>
            <TableCard
              viewOnClick={() => handleViewOnClick(courseId, status, statusId)}
              rowData={cellValues?.row}
              viewEnrollOnClick={() =>
                viewUnEnrollOnClick(courseId, status, statusId)
              }
            />
          </>
        );
      },
    },
  ];
  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };
  const noRecourdCount = coursesUserListData?.noOfRecords;
  console.log("usercourse reduce tabs");
  return (
    <>
      <div className="lessonsList noIn">
        {isShow ? (
          <Grid container className="tabuserlmstab">
            <Grid lg={7} xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                // variant="scrollable"
                // scrollButtons="auto"
              >
                <Tab
                  className="tabcustom"
                  icon={
                    value === 0 ? (
                      <div className="totalV">
                        {value === 0 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="Assigned"
                />
                {/* <Tab
                  className="tabcustom"
                  icon={
                    value === 1 ? (
                      <div className="totalV">
                        {value === 1 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="In progress"
                /> */}
                <Tab
                  className="tabcustom"
                  icon={
                    value === 1 ? (
                      <div className="totalV">
                        {value === 1 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="Completed"
                />

                <Tab
                  className="tabcustom"
                  label="All Courses"
                  icon={
                    value === 2 ? (
                      <div className="totalV">
                        {value === 2 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                />
              </Tabs>
            </Grid>
            <Grid lg={5} xs={12}>
              <div className="rightFilterlms">
                <div className="searchfilter">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div className="searchfilterInput">
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={search}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>
                <div
                  className="filter_btn"
                  onClick={handleFilterClick("bottom-end")}
                >
                  <FilterButton label={"Filters"} />
                </div>
                <Popper
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  placement={placement}
                >
                  <LmsFilterList
                    filterType={categoryUserListData?.categoryList}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleSearch={handleSearch}
                    tabvalue={value}
                    inputsearch={search}
                    onChangeType={onChangeType}
                    filter={filter}
                    PAGE_SIZE={PAGE_SIZE}
                  />
                </Popper>
              </div>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid container>
          <TabPanel className="tabPanel" value={value} index={0}>
            <TableCardView
              columns={columns}
              rows={coursesUserListData?.courses ?? []}
              rowCount={coursesUserListData?.noOfRecords ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={1}>
            <TableCardView
              columns={columns}
              rows={coursesUserListData?.courses ?? []}
              rowCount={coursesUserListData?.noOfRecords ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={2}>
            <TableCardView
              columns={columns}
              rows={coursesUserListData?.courses ?? []}
              rowCount={coursesUserListData?.noOfRecords ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={3}>
            <TableCardView
              columns={columns}
              rows={coursesUserListData?.courses ?? []}
              rowCount={coursesUserListData?.noOfRecords ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </TabPanel>
        </Grid>
      </div>
    </>
  );
};

export default UserCoursesList;
