import React, { useEffect, useState } from "react";
import "./incidentReportList.scss";
import _ from "lodash";
import FilterListIcon from "@mui/icons-material/FilterList";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import HSelectMenu from "..//..//..//library/custom/selectMenu/SelectMenu";
import HDatePicker from "..//..//..//library/custom/datePicker/DatePicker";
import GridTable from "..//..//..//library/custom/gridTable/GridTable";
import IncidentmultiselectSearch from "..//..//..//library/custom/selectMenu/MultiSelectBox";
import { getApiUrl } from "../../../config";

export default function IncidentReportList({ createCase }) {
  const [incidentLists, setincidentLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    setLoading(true);
    const url = `${getApiUrl().incidentApiUrl}/GetIncidentList?pageNumber=1`;
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => setincidentLists(resp.incident))
      .catch();
  }, []);
  const columns = [
    { field: "caseId", headerName: "Case ID", width: 130 },
    { field: "reportId", headerName: "Report ID", width: 150 },
    { field: "dateOfEntry", headerName: "Date Of Entry", width: 150 },

    {
      field: "incidentType",
      headerName: "Incident Type",
      type: "text",
      width: 150,
    },
    {
      field: "enteredBy",
      headerName: "Entered By",
      type: "text",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      type: "text",
      width: 150,
    },
    {
      field: "icon",
      headerName: <img src="settings.png" alt="" />,
      width: 100,
    },
  ];

  const handlepagechange = (e) => {};
  const handleSearch = (event) => {
    let searchitm = 1;
    if (event.target.value) {
      searchitm = event.target.value;
    }
    setSearch(searchitm);
    const urlsearch = `${
      getApiUrl().incidentApiUrl
    }/GetIncidentList?pageNumber=${searchitm}`;

    fetch(urlsearch)
      .then((resp) => resp.json())
      .then((resp) => setincidentLists(resp.incident))
      .catch();
  };
  const locationonchangehandler = (e) => {};

  return (
    <>
      <div className=" mt-5">
        <div className="row">
          <div className="viewcasetitile">View Incident Reports</div>
          <div>
            <button
              className="btncaseadd"
              onClick={(e) => {
                e.preventDefault();
                createCase();
              }}
            >
              + Create New Case
            </button>
          </div>
        </div>
        <div>
          <button
            aria-describedby={id}
            variant="contained"
            type="button"
            name="btn"
            className="toggle-button"
            onClick={(e) => {
              {
                handleClick(e);
              }
            }}
          >
            <FilterListIcon id="icn" /> Filters{" "}
          </button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 5, m: 1, mb: 1 }}>
              <div className="caseviewpopconner">
                <div className="location12">INCIDENT TYPE</div>
                <span id="hselect">
                  <IncidentmultiselectSearch />
                  {/* <HSelectMenu onchangehandler={locationonchangehandler} /> */}
                </span>
              </div>
              <div id="tmprd">
                TIME PERIOD
                <br />
                from
                <span className="hdate">
                  {" "}
                  <HDatePicker />
                </span>
              </div>
              TO
              <HDatePicker />
              STATUS
              <HSelectMenu onchangehandler={locationonchangehandler} />
              <button
                type="search"
                name="search"
                id="btnseacrh1"
                onClick={(e) => {
                  {
                    handleSearch(e);
                  }
                }}
              >
                Apply
              </button>
            </Typography>
          </Popover>
        </div>

        <div className="container1">
          <div className="searchcaseid">
            <label htmlFor="search">
              <input
                id="search"
                type="text"
                className="inputsearchcase"
                onChange={handleSearch}
                autoComplete="off"
              />
            </label>
          </div>
          <div>
            <GridTable
              rows={incidentLists}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onPageChange={handlepagechange}
              checkboxSelection={false}
              disableColumnSelector
              disableColumnFilter
              disableColumnMenu
            />
          </div>
        </div>
      </div>
    </>
  );
}
