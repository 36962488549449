import React, { useEffect, useState } from "react";
import QuestionConfiguration from "../components/questionConfiguration";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE } from "../../../../constant/constant";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../library/common/Loader";

const QuestionConfigurationContainer = () => {
  const dispatch = useDispatch();
  const defaultData = {
    search: "",
    page: 1,
    pageSize: 1000,
  };

  const getSubCategoriesList = useSelector(
    (state) => state.getSwSubCategoryList
  );
  const getSubCategoriesListData = (data) => {
    dispatch(swInspectionAction.getSwSubCategoryList(data));
  };

  const getQuestionList = useSelector((state) => state.getSwQuestionList);
  const getQuestionListData = (data) => {
    dispatch(swInspectionAction.getSwQuestionList(data));
  };
  const questionListStatus = useSelector(
    (state) => state.getSwQuestionList?.isLoading
  );

  const createSwQuestion = useSelector((state) => state.createSwQuestion);
  const updateSwQuestion = useSelector((state) => state.updateSwQuestion);

  const showLoader = useSelector(
    (state) =>
      state.createSwQuestion?.isLoading ||
      state.getSwQuestionList?.isLoading ||
      state.getSwSubCategoryList?.isLoading ||
      state.updateSwQuestion?.isLoading
  );

  const [clearQuestion, setClearQuestion] = useState(false);

  const onSubmit = (data) => {
    setClearQuestion(true);
    dispatch(swInspectionAction.createSwQuestion(data));
  };
  useEffect(() => {
    if (
      createSwQuestion?.status === "SUCCESS" &&
      createSwQuestion?.isLoading === false &&
      clearQuestion
    ) {
      toast("Question Added successfully");
    }
  }, [createSwQuestion]);
  useEffect(() => {
    getSubCategoriesListData(defaultData);
  }, []);

  const [clearQuestionOption, setClearQuestionOption] = useState(false);
  const onUpdateSubmit = (data) => {
    setClearQuestionOption(true);
    dispatch(swInspectionAction.updateSwQuestion(data));
  };
  useEffect(() => {
    if (
      updateSwQuestion?.status === "SUCCESS" &&
      updateSwQuestion?.isLoading === false &&
      clearQuestionOption
    ) {
      toast("Question Update successfully");
    }
  }, [updateSwQuestion]);

  return (
    <>
      {showLoader && <Loader />}

      <QuestionConfiguration
        getQuestionList={questionListStatus?[]:getQuestionList}
        onSubmit={onSubmit}
        getSubCategoriesList={getSubCategoriesList?.data?.result?.categoryList?.filter(
          (x) => x.status
        ) }
        createSwQuestion={createSwQuestion}
        getQuestionListData={getQuestionListData}
        onUpdateSubmit={onUpdateSubmit}
      />
      <ToastContainer />

    </>
  );
};

export default QuestionConfigurationContainer;
