import {
  Edit,
  FileDownload,
  MoreVert,
  RemoveRedEye,
  Grading,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, Menu } from "@mui/material";
import React from "react";
import DownloadCsv from "../../download_file/DownloadCsv";
import commonImages from "../../../assets";

export default function ReportRowManipulte(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dowloadCsv = React.useRef();
  const {
    rows: { row },
    viewOnClick,
    editOnClick,
    deleteOnClick,
    edit,
    download,
    view,
    deleted,
    editLabel = "Edit Incident",
    deleteLabel = "Delete Incident",
    downloadLable = "Download Report",
    viewLabel = "View Incident",
    openLink,
    openLinkLabel = "Link",
    openLinkUrl,
    configure = false,
    configureLabel,
    configureOnClick,
    safetyLog = false,
    safetyLogOnClick,
    safetyLogLabel = "Safety Log",
    addIncidentLabel = "Add Incident",
    addIncidentClick,
    addIncident = false,
    CloseIncident = false,
    CloseIncidentLabel,
    closeOnClick,
  } = props;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const statusClosed = row?.status === "Closed" ? true : false;
  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="setting-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="setting_button_ui">
          <div className="dropdownList_ui">
            <ul>
              {view && (
                <li onClick={() => viewOnClick(row)}>
                  <>
                    <RemoveRedEye /> <span>{viewLabel}</span>
                  </>
                </li>
              )}
              {openLink && (
                <li>
                  <div
                    onClick={() => {
                      window.open(openLinkUrl, "_blank");
                    }}
                    className="downloadresourse"
                  >
                    <FileDownload /> <span>{openLinkLabel}</span>
                  </div>
                </li>
              )}

              {edit && (
                <li
                  // className={statusClosed ? "disabledBtnLi" : null}
                  onClick={() => editOnClick(row)}
                >
                  <Edit /> <span>{editLabel}</span>
                </li>
              )}
              {safetyLog && (
                <li onClick={() => safetyLogOnClick(row)}>
                  <Grading /> <span>{safetyLogLabel}</span>
                </li>
              )}
              {configure && (
                <li onClick={() => configureOnClick(row)}>
                  <Edit /> <span>{configureLabel}</span>
                </li>
              )}
              {download && (
                <li onClick={() => dowloadCsv.current.link.click()}>
                  <FileDownload /> <span>{downloadLable}</span>
                </li>
              )}
              {addIncident && (
                <li
                  className={statusClosed ? "disabledBtnLi" : null}
                  onClick={() => addIncidentClick(row)}
                >
                  <AddIcon /> <span>{addIncidentLabel}</span>
                </li>
              )}
              {deleted && (
                <li
                  // className={statusClosed ? "disabledBtnLi" : null}
                  onClick={() => deleteOnClick(row)}
                >
                  <img
                    src={commonImages.deleteblueIcon}
                    className="deleteImg"
                    alt=""
                  />

                  <span>{deleteLabel}</span>
                </li>
              )}
              {CloseIncident && (
                <li onClick={() => closeOnClick(row)}>
                  <img
                    src={commonImages.closeIncident}
                    width="20px"
                    height="25px"
                    className="closeIncident"
                    alt=""
                  />
                  <span> {CloseIncidentLabel}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Menu>
      <DownloadCsv
        ref={dowloadCsv}
        data={[row]}
        filename={"list-of-incident.csv"}
      />
    </>
  );
}
