import * as uploadFilesAction from "../actions/uploadFilesAction";
import { uploadFilesType } from "../constants/uploadFilesType";
import { createFileUpload } from "../../services/fileUploadService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";
export function* createUploadFilesSaga(params) {
  console.debug("createFileUploadSaga");
  try {
    const { data, status, error } = yield call(createFileUpload, params);
    if (!data && status !== 200) throw apiError(error);
    const uploadFilePage = uploadFilesType.UPLOAD_FILE_PAGE;
    yield put(
      uploadFilesAction.createFileUploadSuccess({
        uploadFilePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "File upload fail Failed" };
    yield put(uploadFilesAction.createFileUploadFailure(data));
  }
}

const myFileUploadSaga = [
  takeLatest(uploadFilesType.POST_UPLOAD_FILE_PENDING, createUploadFilesSaga),
];

export default myFileUploadSaga;
