import React from "react";
import ReportsManagementList from "../reportsManagement/reportsManagementList";
import { useNavigate } from "react-router-dom";
import {
  REPORTS_CREDENTIAL_COUNT,
  REPORTS_DATA,
  REPORTS_SAFETY_VIEW,
  REPORTS_TRAINING_SESSIONS,
  REPORTS_UTILITIES,
  REPORTS_PERSONAL_INJURY,
  REPORTS_ANALYTICS_DASHBOARD,
} from "../../../constant/routeContant";

const UserManagementContainer = () => {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(REPORTS_CREDENTIAL_COUNT);
    } else if (id === 2) {
      navigate(REPORTS_SAFETY_VIEW);
    } else if (id === 3) {
      navigate(REPORTS_DATA);
    } else if (id === 4) {
      navigate(REPORTS_TRAINING_SESSIONS);
    } else if (id === 5) {
      navigate(REPORTS_UTILITIES);
    } else if (id === 6) {
      navigate(REPORTS_PERSONAL_INJURY);
    }
    else if (id === 7) {
      navigate(REPORTS_ANALYTICS_DASHBOARD);
    }
  };

  return (
    <>
      <div className="dashTitle">Reports & Analytics</div>
      <ReportsManagementList onClick={onClickHandler} />
    </>
  );
};

export default UserManagementContainer;
