import { Grid } from "@mui/material";
import React from "react";
import { SETUPSITEMANAGEMENT } from "../../../../constant/storm-water/constant";
import Card from "../../../../library/custom/cardList/card";
import { HBNEXT_ORG_ID, LOGIN_ORG_ID } from "../../../../constant/constant";
import { checkPrivileges } from "../../../../utils/rolesHelper";

function SetupSiteProjectsList({ onClick }) {
  return (
    <>
      <Grid container className="CardListContaner">
        {checkPrivileges([12, 108]) && (
          <>
            {LOGIN_ORG_ID() === HBNEXT_ORG_ID && (
              <Card
                iconShow={false}
                onClick={onClick}
                imgShow={true}
                cardDetail={SETUPSITEMANAGEMENT[0]}
              />
            )}

            <Card
              iconShow={false}
              onClick={onClick}
              imgShow={true}
              cardDetail={SETUPSITEMANAGEMENT[1]}
            />
          </>
        )}
      </Grid>
    </>
  );
}

export default SetupSiteProjectsList;
