import { incidentType } from "../constants/incidentType";

export const getIncidentData = () => ({
  type: incidentType.GET_INCIDENT,
});

export const getIncidentDataSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_SUCCESS,
  data,
});

export const getIncidentDataFailure = (error) => ({
  type: incidentType.GET_INCIDENT_FAILURE,
  error,
});

// delete incidents

export const deleteIncidents = (payload) => ({
  type: incidentType.DELETE_INCIDENT,
  payload: payload,
});

export const deleteIncidentsSuccess = (payload) => ({
  type: incidentType.DELETE_INCIDENT_SUCCESS,
  payload: payload,
});

export const deleteIncidentsFailure = (payload) => ({
  type: incidentType.DELETE_INCIDENT_FAILED,
  payload: payload,
});

export const getIncidentList = (data) => ({
  type: incidentType.GET_INCIDENT_LIST,
  data,
});

export const getIncidentListSuccess = (data) => ({
  type: incidentType.GET_INCIDENT_LIST_SUCCESS,
  data,
});

export const getIncidentListFailure = (error) => ({
  type: incidentType.GET_INCIDENT_LIST_FAILURE,
  error,
});

export const getActionList = (data) => ({
  type: incidentType.GET_ACTION_LIST,
  data,
});

export const getActionListSuccess = (data) => ({
  type: incidentType.GET_ACTION_LIST_SUCCESS,
  data,
});

export const getActionListFailure = (error) => ({
  type: incidentType.GET_ACTION_LIST_FAILURE,
  error,
});

export const closeActionItem = (data) => ({
  type: incidentType.CLOSE_ACTION_ITEM,
  data,
});

export const closeActionItemSuccess = (data) => ({
  type: incidentType.CLOSE_ACTION_ITEM_SUCCESS,
  data,
});

export const closeActionItemFailure = (error) => ({
  type: incidentType.CLOSE_ACTION_ITEM_FAILURE,
  error,
});

export const getCasesList = (data) => ({
  type: incidentType.GET_CASES_LIST,
  data,
});

export const getCasesListSuccess = (data) => ({
  type: incidentType.GET_CASES_LIST_SUCCESS,
  data,
});

export const getCasesListFailure = (error) => ({
  type: incidentType.GET_CASES_LIST_FAILURE,
  error,
});

export const getCaseDetails = (caseId) => ({
  type: incidentType.GET_CASE_DETAILS_PENDING,
  caseId,
});

export const getCaseDetailsSuccess = (data) => ({
  type: incidentType.GET_CASE_DETAILS_SUCCESS,
  data,
});

export const getCaseDetailsFailure = (error) => ({
  type: incidentType.GET_CASE_DETAILS_FAILED,
  error,
});

export const closeIncident = (data) => ({
  type: incidentType.CLOSE_INCIDENT,
  data,
});

export const closeIncidentSuccess = (data) => ({
  type: incidentType.CLOSE_INCIDENT_SUCCESS,
  data,
});

export const closeIncidentFailure = (error) => ({
  type: incidentType.CLOSE_INCIDENT_FAILURE,
  error,
});


export const deleteCaseList = (payload) => ({
  type: incidentType.DELETE_CASE,
  payload: payload,
});

export const deleteCaseSuccess = (payload) => ({
  type: incidentType.DELETE_CASE_SUCCESS,
  payload: payload,
});

export const deleteCaseFailure = (payload) => ({
  type: incidentType.DELETE_CASE_FAILED,
  payload: payload,
});
