import React, { useState } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import { ISwitch, PAGE_SIZE, USER_ID } from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import NORow from "../../../../library/custom/noRow/noRow";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { useDispatch } from "react-redux";
import TextArea from "../../../../library/custom/textArea/TextArea";
import { useNavigate } from "react-router-dom";
import * as jhaAction from "../../../../redux/actions/jhaAction";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const JhaHazards = ({
  handleEditOnClick,
  isLoading,
  formValue,
  setFormValue,
  catId,
  setCatId,
  categoriesList,
  activityList,
  addJhaHazard,
  hazardsList,
  handleStatusChange,
  handleSetControl,
  getJhaHazardsList,
  itemsCount,
  setlistState,
  setlistCountState,
}) => {
  const navigate = useNavigate();
  const dataBack = {
    title: "Back to Hazard Analysis Configuration",
    route: -1,
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseRerportRow = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const columns = [
    { field: "hazardsName", headerName: "Hazards", flex: 3 },
    { field: "modifiedDate", headerName: "Last Updated", flex: 1 },
    {
      flex: 1,
      field: "isActive",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox">
                {checkPrivileges([58, 12]) && (
                  <FormControlLabel
                    control={
                      <ISwitch
                        checked={
                          rows?.row.isActive ? rows?.row.isActive : false
                        }
                        onChange={() => handleChange(rows?.row)}
                        name="status"
                      />
                    }
                  />
                )}
                <div>{rows?.row?.isActive ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 0.5,
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={hazardsList?.length ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={hazardsList ?? []}
          fileNameXL={" hazards"}
        />
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            editOnClick={() => {
              setOpen(true);
              seteditFormValue({
                ...editFormValue,
                hazardsId: rows?.row?.id,
                hazardsName: rows?.row?.hazardsName,
                isActive: rows?.row?.isActive,
              });
            }}
            setOnClick={() => handleSetControl(rows.row)}
            edit={checkPrivileges([58, 59, 12])}
            editLabel="Edit Hazard"
            set
            setLabel="Set Controls"
            handleClose={handleCloseRerportRow}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        );
      },
    },
  ];
  const [open, setOpen] = React.useState(false);
  const [isAlreadyExists, setIsAlreadyExists] = useState(false);
  const [isEditAlreadyExists, setIsEditAlreadyExists] = useState(false);
  const [isExistsErrorMsg, setisExistsErrorMsg] = useState("");
  const [isEditExistsErrorMsg, setisEditExistsErrorMsg] = useState("");
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const [isActId, setisActId] = useState(false);
  const [isCatId, setisCatId] = useState(false);
  const [isHazardName, setisHazardName] = useState(false);
  const [isEditHazardName, setisEditHazardName] = useState(false);
  const [page, setpage] = useState(1);
  const handleChange = (hazardsSetItem) => {
    const newCatList = [...hazardsList];
    newCatList.forEach((lists) => {
      if (lists.id === hazardsSetItem.id) {
        lists.isGlobal = false;
        lists.hazardsId = lists.id;
        lists.isActive = !lists.isActive;
        lists.listsmodifiedBy = parseInt(USER_ID());
      }
    });
    handleStatusChange(hazardsSetItem);
  };

  // add
  function submitData() {
    let error = false;
    if (formValue.activityId === "") {
      setisActId(true);
      error = true;
    }
    if (catId === "") {
      setisCatId(true);
      error = true;
    }
    if (formValue?.hazardsName?.trim() === "") {
      setisHazardName(true);
      setFormValue({
        ...formValue,
        hazardsName: "",
      });
      error = true;
    }
    const index = hazardsList?.findIndex(
      (object) =>
        object.hazardsName.toUpperCase() ===
        formValue?.hazardsName.toUpperCase()
    );
    if (index >= 0) {
      setIsAlreadyExists(true);
      setisExistsErrorMsg("Hazard Already Exists");
      error = true;
      return;
    }
    if (!error) {
      addJhaHazard(formValue);
      setFormValue({
        ...formValue,
        hazardsName: "",
      });
    }
  }

  // pagination

  const handlePagignation = (newPage) => {
    setpage(newPage + 1);
    const data = {
      activityId: formValue.activityId,
      search: "",
      page: newPage + 1,
      pageSize: PAGE_SIZE,
    };
    getJhaHazardsList(data);
  };
  // edit
  const [editFormValue, seteditFormValue] = useState({
    hazardsName: formValue.hazardsName,
    isGlobal: false,
    hazardsId: 0,
    isActive: undefined,
    modifiedBy: parseInt(USER_ID()),
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modalContainer">
          <Box className="modalBox">
            {/* <div className="modalTitle">Edit Hazard Name</div> */}
            {/* <div className="modalInputBox"> */}
            <TextArea
              type="text"
              isRequired={true}
              value={editFormValue.hazardsName}
              onChange={(e) => {
                setisEditHazardName(false);
                setIsEditAlreadyExists(false);
                if (
                  e.target.value === " " &&
                  formValue.hazardsName.trim() === ""
                )
                  setFormValue({
                    ...formValue,
                    hazardsName: "",
                  });
                else
                  seteditFormValue({
                    ...editFormValue,
                    hazardsName: e.target.value,
                  });
              }}
              label="Edit Hazard Name"
              placeholder="Hazard Name Here..."
              isError={isEditHazardName}
              errorMsg={isEditHazardName ? "Hazard Name is Required" : ""}
            />
            <Grid container className="modalFooterBox">
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientText={true}
                  label="Cancel"
                  onClick={handleClose}
                />
              </Grid>
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientContained={true}
                  label={"Save"}
                  onClick={() => {
                    let error = false;
                    if (editFormValue?.hazardsName?.trim() === "") {
                      setisEditHazardName(true);
                      error = true;
                    }
                    const index = hazardsList?.findIndex(
                      (object) =>
                        object.hazardsName.toUpperCase() ===
                        editFormValue?.hazardsName.toUpperCase()
                    );
                    if (index >= 0) {
                      setIsEditAlreadyExists(true);
                      setisEditExistsErrorMsg("Hazard Already Exists");
                      error = true;
                      return;
                    }
                    if (!error) {
                      handleEditOnClick(editFormValue);
                      handleClose();
                    }
                  }}
                />
              </Grid>

              <Grid md={12} item>
                {isEditAlreadyExists && (
                  <p className="errorMsg">{isEditExistsErrorMsg}</p>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Modal>

      <Grid className="inspectionOverRide">
        {/* <Grid container item md={12} xs={12} className="addShadowBox"> */}
        <Grid container className="confBox">
          <Grid item md={8.5} xs={12}>
            <div className="confTitile">Hazards</div>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item lg={4} md={12} xs={12} mb={3}>
            <FormControl fullWidth>
              <FormLabels label="Select Category" isRequired={true} />
              <SelectMenu
                placeholder="Please Select"
                listData={categoriesList ?? []}
                value={catId}
                onchangehandler={(e) => {
                  setCatId(e.target.value);
                  setlistState([]);
                  setlistCountState(0);
                }}
                isError={isCatId}
                errorMsg={isCatId ? "Category is Required" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item lg={4} md={12} xs={12} mb={3}>
            <FormControl fullWidth>
              <FormLabels label="Select Activity" isRequired={true} />
              <SelectMenu
                placeholder="Please Select"
                listData={activityList ?? []}
                value={formValue.activityId}
                onchangehandler={(e) =>
                  setFormValue({
                    ...formValue,
                    activityId: e.target.value,
                  })
                }
                isError={isActId}
                errorMsg={isActId ? "Activity is Required" : ""}
              />
            </FormControl>
          </Grid>
        </Grid>
        {checkPrivileges([58, 59, 12]) && (
          <Grid item container mt={1.5} lg={12} spacing={1}>
            <Grid item lg={8} mt={2} className="Catoption">
              <InputField
                isRequired={true}
                disabled={false}
                type="text"
                label={"Hazards"}
                value={formValue.hazardsName}
                placeholder="Enter here"
                onChange={(e) => {
                  setisHazardName(false);
                  if (
                    e.target.value === " " &&
                    formValue.hazardsName.trim() === ""
                  )
                    setFormValue({
                      ...formValue,
                      hazardsName: "",
                    });
                  else
                    setFormValue({
                      ...formValue,
                      hazardsName: e.target.value,
                      isHazardName: false,
                    });

                  setisExistsErrorMsg("");
                }}
                isError={isHazardName}
                errorMsg={isHazardName ? "Hazard Name is Required" : ""}
              />
            </Grid>
            <Grid className="smallAddBtn" item mt={6} lg={1.1}>
              <Buttons
                // disabled={isSubmit ? true : false}
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                onClick={(e) => submitData()}
                id="btnAddEquipment"
                varientAddIconBlue={true}
              ></Buttons>
            </Grid>
            <Grid md={12} item>
              {isAlreadyExists && (
                <p className="errorMsg">{isExistsErrorMsg}</p>
              )}
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid container item md={12} xs={12}>
            <Grid item md={12} className="" mt={3}>
              <div className="desc_box row_uniq">
                <div className="table_style_ui">
                  <GridTable
                    getRowId={(r) => r.id}
                    rows={hazardsList ?? []}
                    rowCount={itemsCount ?? 0}
                    columns={columns}
                    loading={isLoading}
                    checkboxSelection={false}
                    page={page}
                    pagination={true}
                    pageSize={PAGE_SIZE}
                    rowsPerPageOptions={[PAGE_SIZE]}
                    onPageChange={handlePagignation}
                    backIconButtonProps={{ disabled: false }}
                    disableSelectionOnClick
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div onClick={() => dispatch(jhaAction.clearJha())}>
          <BackBtn dataBack={dataBack} />
        </div>
      </Grid>
    </>
  );
};

export default JhaHazards;
