import { ORG_ID, PAGE_SIZE } from "../../constant/constant";
import { getApiUrl } from "../../config";
import { get, post } from "../axiosRequest";

export const addPermit = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Permit/AddPermit`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getPermitDetails = async ({ data }) => {
  const { permitId } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Permit/GetPermitDetail?permitId=${permitId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getPermitList = async ({ data }) => {
  const {
    search,
    expstartDate,
    expendDate,
    apvstartDate,
    apvendDate,
    permitNumber,
    permitHolder,
    projectId,
    page,
    permitType,
  } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Permit/GetPermitList?search=${search}&expstartDate=${expstartDate}&expendDate=${expendDate}&apvstartDate=${apvstartDate}&apvendDate=${apvendDate}&permitNumber=${permitNumber}&permitHolder=${permitHolder}&projectId=${projectId}&page=${page}&pageSize=${PAGE_SIZE}&permitType=${permitType}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updatePermit = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Permit/UpdatePermit`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addPermitType = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Permit/AddPermitType`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getPermitTypeList = async ({ data }) => {
  const { search, page } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Permit/GetPermitTypeList?search=${search}&page=${page}&pageSize=${PAGE_SIZE}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updatePermitType = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Permit/UpdatePermitType`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getGeneralPermitList = async ({ data }) => {
  const { search, page } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Permit/GetGeneralPermitList?page=${page}&pageSize=${PAGE_SIZE}&search=${search}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateGeneralPermitType = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Permit/UpdateGeneralPermit`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getPermitType = async ({ data }) => {
  const { stateId } = data;
  const url = `${getApiUrl().adminSWApiUrl}Permit/GetPermitType?stateId=${
    stateId ? stateId : ""
  }`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
