import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { get } from "./axiosRequest";

export const getState = async () => {
  const url = `${getApiUrl().userApiUrl}/getstate`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCity = async (stateId) => {
  const url = `${getApiUrl().userApiUrl}/getcity?stateid=${
    stateId ? stateId : ""
  }`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
