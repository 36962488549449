export const CATEGORY_ADMIN = [
  {
    key: 5,
    value: "Job Title",
  },
  {
    key: 6,
    value: "Vehicle Type",
  },
  {
    key: 7,
    value: "Equipment Type",
  },
  {
    key: 10,
    value: "Contractor Grade",
  },
  {
    key: 9,
    value: "Contractor Type",
  },
];
export const CATEGORY_INCIDENT = [
  {
    key: 1,
    value: "Treatment Type",
  },
  {
    key: 3,
    value: "Type of Injury",
  },
  {
    key: 4,
    value: "Body Part",
  },

  {
    key: 5,
    value: "Illness Type ",
  },
  {
    key: 2,
    value: "Personal Injury Contributing Factor",
  },
  {
    key: 7,
    value: "Vehicle Damage Contributing Factor",
  },
  {
    key: 9,
    value: "Utility Property Damaged",
  },

  {
    key: 6,
    value: "Case Classify",
  },

  {
    key: 8,
    value: "Traffic At Time Of Incident",
  },

  {
    key: 10,
    value: "Type of Utility",
  },

  {
    key: 11,
    value: "Type of Utility Line Damage",
  },

  {
    key: 12,
    value: "Were Utility Lines",
  },
  {
    key: 13,
    value: "Establishment",
  },
];
export const CATEGORY_SUPPORT = [
  {
    key: 1,
    value: "Faq",
  },
  {
    key: 13,
    value: "Ticket",
  },
];

export const CATEGORY_LMS = [
  {
    key: 4,
    value: "LMS",
  },
  {
    key: 12,
    value: "Safety Meetings",
  },
];
export const CATEGORY_RESOURSE = [
  {
    key: 3,
    value: "Resources",
  },
];
export const CATEGORY_ASSET = [
  {
    key: 1,
    value: "Asset Type",
  },
];

export const CATEGORY_CREDENTIALS = [
  {
    key: 8,
    value: "Issue Body",
  },
];
