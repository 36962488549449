import { getApiUrl } from "../config";
import { post } from "./axiosRequest";
import { ORG_ID } from "../constant/constant";

export const getLmsReport = async (data) => {
  const url = `${getApiUrl().reportApiUrl}/LmsReport/LmsReport`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getLmsReportCourse = async (data) => {
  const url = `${getApiUrl().reportApiUrl}/LmsReport/GetCourse`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getLmsReportLesson = async (data) => {
  const url = `${getApiUrl().reportApiUrl}/LmsReport/GetLesson`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
