import { API_STATUS } from "../../constant/constant";
import {
  getDropdownDataKeyValueType,
  getInspectionsType,
  getSupervisorByProjectType,
} from "../constants/adminCommonType";

const initialState = {
  flow: "Admin",
  isError: false,
  isLoading: false,
};

export const getInspectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getInspectionsType.GET_INSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getInspectionsType.GET_INSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getInspectionsType.GET_INSPECTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
// getDropdownDataKeyValue
export const getDropdownDataKeyValueReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getDropdownDataKeyValueType.GET_DROPDOWN_DATA_KEY_VALUE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getDropdownDataKeyValueType.GET_DROPDOWN_DATA_KEY_VALUE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getDropdownDataKeyValueType.GET_DROPDOWN_DATA_KEY_VALUE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getSupervisorByProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case getSupervisorByProjectType.GET_SUPERVISOR_BY_PROJECT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getSupervisorByProjectType.GET_SUPERVISOR_BY_PROJECT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getSupervisorByProjectType.GET_SUPERVISOR_BY_PROJECT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
