export const perosnalInjuryType = {
  POST_PERSONAL_INJURY_PENDING:
    "[PERSONAL_INJURY][REQUEST] Try To carete Personal Injury",
  POST_PERSONAL_INJURY_FAILURE:
    "[PERSONAL_INJURY][RESPONSE] Personal Injury Failed",
  POST_PERSONAL_INJURY_SUCCESS:
    "[PERSONAL_INJURY][RESPONSE] Personal Injury Successfull",
  PERSONAL_INJURY_PAGE: "PersonalInjuryPage",
  GET_PERSONAL_REPORT: "[REQUEST] Get Personal Report Data",
  GET_PERSONAL_REPORT_SUCCESS:
    "[RESPONSE] Get Personal Report Data Successfull",
  GET_PERSONAL_REPORT_FAILURE: "[RESPONSE] Get Personal report Data Failed",
  PERSONAL_REPORT_PAGE: "PERSONALREPORTPage",
  PUT_PERSONAL_INJURY_PENDING:
    "[PERSONAL_INJURY][REQUEST] Try To Update Personal Injury",
  PUT_PERSONAL_INJURY_FAILURE:
    "[PERSONAL_INJURY][RESPONSE] Personal Injury Updated Failed",
  PUT_PERSONAL_INJURY_SUCCESS:
    "[PERSONAL_INJURY][RESPONSE] Personal Injury Updated Successfull",
  CLEAR_PERSONAL_INJURY:
    "[CLEAR_PERSONAL_INJURY][REQUEST] Clear Personal Injury",
  EDIT_PERSONAL_INJURY: "[EDIT_PERSONAL_INJURY][REQUEST] Edit Personal Injury",
};
