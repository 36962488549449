import { reportsType } from "../../storm-water/types/reportsType";
import { API_STATUS } from "../../../../src/constant/constant";

const initialState = {
  flow: "reports",
  isError: false,
  isLoading: false,
};

export const organisationSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportsType.GET_ORGANISATION_SUMMARY_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case reportsType.GET_ORGANISATION_SUMMARY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case reportsType.GET_ORGANISATION_SUMMARY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const inspectorSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportsType.GET_INSPECTOR_SUMMARY_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case reportsType.GET_INSPECTOR_SUMMARY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case reportsType.GET_INSPECTOR_SUMMARY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const dateWiseSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportsType.GET_DATEWISE_SUMMARY_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case reportsType.GET_DATEWISE_SUMMARY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case reportsType.GET_DATEWISE_SUMMARY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const perDayInspectorSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportsType.GET_PER_DAY_INSPECTOR_SUMMARY_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case reportsType.GET_PER_DAY_INSPECTOR_SUMMARY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case reportsType.GET_PER_DAY_INSPECTOR_SUMMARY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const dateWiseInspectorSummaryReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case reportsType.GET_DATEWISE_INSPECTOR_SUMMARY_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case reportsType.GET_DATEWISE_INSPECTOR_SUMMARY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case reportsType.GET_DATEWISE_INSPECTOR_SUMMARY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const organisationMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportsType.GET_ORGANISATION_MAP_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case reportsType.GET_ORGANISATION_MAP_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case reportsType.GET_ORGANISATION_MAP_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
