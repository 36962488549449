import {
  createTicketType,
  deleteFAQType,
  getArticleCatSubcategorySagaType,
  getDashboardTicketsType,
  getTicketCategoryType,
  getTicketDetailsType,
  getTicketsAdminDeskType,
  getTicketsFeedbackDeskType,
  getTicketsType,
  postAddFAQType,
  postClosedTicketType,
  postUpdateSeverityTicketType,
  postUserAssignTicketType,
  postUserFeedbackOnTicketType,
  replyTicketType,
  updateFAQType,
} from "../constants/supportType";
import { getArticleType } from "../constants/supportType";
import { getArticlesBasedCatSubcategoryType } from "../constants/supportType";
import { getArticleDetailsType } from "../constants/supportType";
import { addLikeDislikeArticleType } from "../constants/supportType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "faq",
  isError: false,
  isLoading: false,
};

// articleCatSubcategoryReducer REDUCER
export const articleCatSubcategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case getArticleCatSubcategorySagaType.GET_ARTICLE_CAT_SUBCATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getArticleCatSubcategorySagaType.GET_ARTICLE_CAT_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getArticleCatSubcategorySagaType.GET_ARTICLE_CAT_SUBCATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getTicketCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case getTicketCategoryType.GET_TICKET_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getTicketCategoryType.GET_TICKET_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getTicketCategoryType.GET_TICKET_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// articleReducer REDUCER
export const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case getArticleType.GET_ARTICLE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getArticleType.GET_ARTICLE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getArticleType.GET_ARTICLE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// articlesBasedCatSubcategoryReducer REDUCER
export const articlesBasedCatSubcategoryReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getArticlesBasedCatSubcategoryType.GET_ARTICLE_BASED_CAT_SUBCATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getArticlesBasedCatSubcategoryType.GET_ARTICLE_BASED_CAT_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getArticlesBasedCatSubcategoryType.GET_ARTICLE_BASED_CAT_SUBCATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// articlesDetailsReducer REDUCER
export const articlesDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getArticleDetailsType.GET_ARTICLE_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getArticleDetailsType.GET_ARTICLE_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getArticleDetailsType.GET_ARTICLE_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// addLikeDislikeArticleReducer REDUCER
export const addLikeDislikeArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case addLikeDislikeArticleType.POST_ADD_LIKE_DISLIKE_ARTICLE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case addLikeDislikeArticleType.POST_ADD_LIKE_DISLIKE_ARTICLE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case addLikeDislikeArticleType.POST_ADD_LIKE_DISLIKE_ARTICLE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// getTicketsReducer REDUCER
export const getTicketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getTicketsType.GET_TICKETS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getTicketsType.GET_TICKETS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getTicketsType.GET_TICKETS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// getTicketDetailsReducer REDUCER
export const getTicketDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getTicketDetailsType.GET_TICKET_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getTicketDetailsType.GET_TICKET_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getTicketDetailsType.GET_TICKET_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// createTicketReducer REDUCER
export const createTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case createTicketType.POST_CREATE_TICKET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case createTicketType.POST_CREATE_TICKET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case createTicketType.POST_CREATE_TICKET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// replyTicketReducer REDUCER
export const replyTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case replyTicketType.POST_REPLY_TICKET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case replyTicketType.POST_REPLY_TICKET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case replyTicketType.POST_REPLY_TICKET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// postUserFeedbackReducer REDUCER
export const postUserFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case postUserFeedbackOnTicketType.POST_USER_FEEDBACK_ON_TICKET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case postUserFeedbackOnTicketType.POST_USER_FEEDBACK_ON_TICKET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case postUserFeedbackOnTicketType.POST_USER_FEEDBACK_ON_TICKET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// getDashboardTicketsReducer REDUCER
export const getDashboardTicketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getDashboardTicketsType.GET_DASHBOARD_TICKETS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getDashboardTicketsType.GET_DASHBOARD_TICKETS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getDashboardTicketsType.GET_DASHBOARD_TICKETS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// getDashboardTicketsReducer REDUCER
export const getTicketsAdminDeskReducer = (state = initialState, action) => {
  switch (action.type) {
    case getTicketsAdminDeskType.GET_TICKETS_ADMIN_DESK_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getTicketsAdminDeskType.GET_TICKETS_ADMIN_DESK_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getTicketsAdminDeskType.GET_TICKETS_ADMIN_DESK_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// getTicketsFeedbackDeskReducer REDUCER
export const getTicketsFeedbackDeskReducer = (state = initialState, action) => {
  switch (action.type) {
    case getTicketsFeedbackDeskType.GET_TICKETS_FEEDBACK_DESK_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getTicketsFeedbackDeskType.GET_TICKETS_FEEDBACK_DESK_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getTicketsFeedbackDeskType.GET_TICKETS_FEEDBACK_DESK_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// postClosedTicketReducer REDUCER
export const postClosedTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case postClosedTicketType.POST_CLOSED_TICKET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case postClosedTicketType.POST_CLOSED_TICKET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case postClosedTicketType.POST_CLOSED_TICKET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// postUserAssignTicketReducer REDUCER
export const postUserAssignTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case postUserAssignTicketType.POST_USER_ASSIGN_TICKET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case postUserAssignTicketType.POST_USER_ASSIGN_TICKET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case postUserAssignTicketType.POST_USER_ASSIGN_TICKET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// postUpdateSeverityTicketReducer REDUCER
export const postUpdateSeverityTicketReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case postUpdateSeverityTicketType.POST_UPDATE_SEVERITY_TICKET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case postUpdateSeverityTicketType.POST_UPDATE_SEVERITY_TICKET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case postUpdateSeverityTicketType.POST_UPDATE_SEVERITY_TICKET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// postUpdateSeverityTicketReducer REDUCER
export const postAddFAQReducer = (state = initialState, action) => {
  switch (action.type) {
    case postAddFAQType.POST_ADD_FAQ_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case postAddFAQType.POST_ADD_FAQ_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case postAddFAQType.POST_ADD_FAQ_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};


// deleteReducer REDUCER
export const deleteFAQReducer = (state = initialState, action) => {
  switch (action.type) {
    case deleteFAQType.DELETE_FAQ_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case deleteFAQType.DELETE_FAQ_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case deleteFAQType.DELETE_FAQ_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
      case deleteFAQType.CLEAR_FAQ:
      return {
        ...state,
        isError: false,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};




export const updateFAQReducer = (state = initialState, action) => {
  switch (action.type) {
    case updateFAQType.UPDATE_FAQ_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
      case updateFAQType.UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
      case updateFAQType.UPDATE_FAQ_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
      // case clearFAQType.CLEAR_FAQ:
      //   return {
      //     ...state,
      //     isError: true,
      //     isLoading: false,
      //     data: "",
      //   };

    default:
      return state;
  }
};