import { lmsType } from "../constants/lmsType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "courses",
  isError: false,
  isLoading: false,
  editCourseData: {},
};

export const createCoursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_COURSES_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_COURSES_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_COURSES_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.UPDATE_COURSES_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.UPDATE_COURSES_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.UPDATE_COURSES_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case lmsType.REVOKE_DATA_UNMOUNT:
      return {
        ...state,
        isSuccess: false,
        isError: false,
        isLoading: false,
        editCourseData: {},
        msg: "",
        error: "",
        successData: {},
      };
    case lmsType.GET_COURSE_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case lmsType.GET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        editCourseData: action.payload,
      };
    case lmsType.GET_COURSE_DETAILS_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const getCoursesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.GET_COURSES_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.GET_COURSES_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.GET_COURSES_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    // case lmsType.GET_CATEGORY_LIST_PENDING:
    //   return {
    //     ...state,
    //     status: API_STATUS.PENDING,
    //     isError: false,
    //     isLoading: true,
    //   };
    // case lmsType.GET_CATEGORY_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     status: API_STATUS.SUCCESS,
    //     ...action.data,
    //     isError: false,
    //     isLoading: false,
    //   };
    // case lmsType.GET_CATEGORY_LIST_FAILURE:
    //   return {
    //     ...state,
    //     status: API_STATUS.FAILURE,
    //     isError: true,
    //     isLoading: false,
    //     error: { ...action.error },
    //   };

    default:
      return state;
  }
};

export const getCategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.GET_CATEGORY_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.GET_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getlessonsDropDownListReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.GET_LESSON_DROP_DOWN_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.GET_LESSON_DROP_DOWN_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.GET_LESSON_DROP_DOWN_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const deleteCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.DELETE_COURSE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.DELETE_COURSE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.DELETE_COURSE_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const publishCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_PUBLISH_COURSES_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_PUBLISH_COURSES_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_PUBLISH_COURSES_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getAssignCourseUserList = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.GET_COURSE_ASSIGN_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.GET_COURSE_ASSIGN_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.GET_COURSE_ASSIGN_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const assignCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_ASSIGN_COURSE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_ASSIGN_COURSE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_ASSIGN_COURSE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// Unassign course reducer
export const unAssignCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_COURSE_UNASSIGN_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_COURSE_UNASSIGN_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_COURSE_UNASSIGN_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getCompletedCourseStatuscheckReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case lmsType.GET_COURSES_COMPLETED_STATUS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.GET_COURSES_COMPLETED_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.GET_COURSES_COMPLETED_STATUS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case lmsType.CLEAR_ASSIGN_COURSE_STATUS:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const unEnrollCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_COURSE_UNENROLL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_COURSE_UNENROLL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_COURSE_UNENROLL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
