import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function LineCharts({dashboardTickets}) {
  
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" 
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart"
    }
  }
};

const labels = ["Average Response Time, Average First Response Time"];

const data = {
  labels,
  datasets: [
    {
      label: "Average response time",
      data: [dashboardTickets?.graphData?.averageResponseTimeMints],
      backgroundColor: "#1B436E"
    },
    {
      label: "Average first response time",
      data: [dashboardTickets?.graphData?.averageFirstResponseTimeMints],
      backgroundColor: "#195BE1"
    }
  ]
};
  return <Bar options={options} data={data} />;
}
