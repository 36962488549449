import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterList from "./FilterIncidentList";
import { InputField } from "../../../library/custom/textBox/InputField";
import Grid from "@mui/material/Grid";

const IncidentReportFilter = ({
  filterType,
  handleSearchFilter,
  handleClose,
  anchorEl,
  incReport,
  handleInputSearch,
  resetFilterAPI,
  setAnchorEl,
  ...rest
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="filter_of_caselist">
        <Grid container spacing={2}>
          <Grid item md={6.7}>
            <div>
              <InputField
                type={"text"}
                onChange={(e) => handleInputSearch(e)}
                name={"incidentReport"}
                value={incReport ?? ""}
                placeholder={"Search Incident Report"}
                disabled={false}
                isError={false}
                errorMsg={""}
                isIcon={true}
              />
            </div>
          </Grid>
          <Grid item>
            <div className="filter_btn">
              <button
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClose(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              {/* <FilterButton
                label={"Filters"}
                handleClick={(e) => handleClose(e)}
              /> */}
              <FilterList
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                resetFilterAPI={resetFilterAPI}
                handleSearch={(event, state) =>
                  handleSearchFilter(event, state)
                }
                filterType={filterType ?? []}
                setAnchorEl={setAnchorEl}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default IncidentReportFilter;
