import { Grid } from "@mui/material";
import React, { useState, useRef } from "react";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Buttons from "../../../../library/custom/button/Button";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { ORG_ID, USERID } from "../../../../constant/constant";
import Button from "../../../../library/custom/button/Button";
import { ADMIN_DIVISION } from "../../../../constant/routeContant";
import "./createDivision.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UsersChip from "../../../../library/custom/usersChip/UsersChip";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";

const CreateDivision = ({
  onSubmit,
  state,
  viewDivisionsData,
  editMode,
  divisionId,
  allUsers,
  handleAllUserSearch,
  isUserLoading,
  divisionExistError,
  setDivisionExistError,
}) => {
  const navigate = useNavigate();
  // ==================  Todo====================
  const [serviceType, setServiceType] = useState([]);
  const [serviceTypeError, setServiceTypeError] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const serviceTypes = userInfo?.product;

  useEffect(() => {
    if (serviceTypes?.length === 1) {
      setServiceType(serviceTypes[0].key);
    }
  }, [serviceTypes]);
  const selectedServiceTypeData = localStorage?.getItem("serviceType");

  const intialState = {
    divisionId: 0,
    clientId: "",
    divisionName: "",
    stateId: "",
    adminIds: [],
    createdBy: USERID,
  };

  const dataBack = {
    title: "Back to Divisions",
    route: ADMIN_DIVISION,
  };

  const [formValue, setFormValue] = useState(intialState);
  const [isSubmit, setIsSubmit] = useState(false);
  const [individualUsersIds, setIndividualUsersIds] = useState([]);
  const autoComp = useRef(null);

  const addIndiVidualUserData = () => {
    const index = userList?.findIndex((obect) => obect.key === users.key);
    if (index === -1 && users) {
      setUsers("");
      setUsersList([...userList, users]);
      const ele = autoComp.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const handleStateChange = (e) => {
    setFormValue({
      ...formValue,
      stateId: e.target.value,
    });
  };

  const [isDivisionName, setIsDivisionName] = useState(false);
  const [isStateId, setIsStateId] = useState(false);
  const findValueMultipleSelectServiveType = (
    arrrayofObject,
    selectedArray
  ) => {
    if (serviceTypes?.length > 1) {
      let valueArray = [];
      arrrayofObject.forEach((data) => {
        if (selectedArray?.indexOf(data.value) >= 0) {
          valueArray.push(data.key);
        }
      });
      return valueArray;
    }
  };
  function mappedData() {
    const selectedServiceType = findValueMultipleSelectServiveType(
      serviceTypes,
      serviceType
    );
    let errors = false;
    if (formValue?.stateId?.length === 0) {
      setIsStateId(true);
      errors = true;
    }
    if (formValue?.divisionName.trim() === "") {
      setFormValue({
        ...formValue,
        divisionName: "",
      });
      setIsDivisionName(true);
      errors = true;
    }
    if (!serviceType || serviceType?.length === 0) {
      setServiceTypeError(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    const userIds = userList.map((daata) => daata.key);
    const data = {
      organisationId: parseInt(ORG_ID()),
      divisionId: divisionId ? divisionId : formValue?.divisionId,
      divisionName: formValue?.divisionName,
      clientId: formValue?.clientId,
      stateId: formValue?.stateId,
      adminIds: userIds,
      createdBy: parseInt(USERID),
      modifiedBy: parseInt(USERID),
      productIds: selectedServiceType ? selectedServiceType : [serviceType],
    };
    return { data };
  }
  const submitData = () => {
    const data = mappedData();

    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const [userList, setUsersList] = useState([]);
  const [users, setUsers] = useState("");

  const deleteUsers = (row) => {
    const updateList = userList.filter((list) => list.key !== row);
    setUsersList(updateList);
  };

  const onChangeUsers = (value) => {
    setUsers(value);
  };

  useEffect(() => {
    if (viewDivisionsData) {
      setIndividualUsersIds(
        ...individualUsersIds,
        viewDivisionsData?.adminData?.map((data) => data.key)
      );

      setUsersList(viewDivisionsData?.adminData);

      setFormValue({
        divisionName: viewDivisionsData?.divisionName,
        stateId: viewDivisionsData?.stateId,
        clientId: viewDivisionsData?.clientId,
      });
      setServiceType(
        viewDivisionsData?.productIds?.map((item) => item.value) ||
          viewDivisionsData?.product?.map((item) => item.value)
      );
    }
  }, [viewDivisionsData]);
  const onChangeServiceType = (event) => {
    setServiceTypeError(false);
    const {
      target: { value },
    } = event;
    setServiceType(typeof value === "string" ? value.split(",") : value);
  };
  useEffect(() => {
    if (selectedServiceTypeData) {
      const newListData = serviceTypes?.find(
        (x) => x.key == selectedServiceTypeData
      );

      setServiceType(
        typeof newListData?.value === "string"
          ? newListData?.value.split(",")
          : newListData?.value
      );
    }
  }, [selectedServiceTypeData]);

  useEffect(() => {
    if (divisionExistError) {
      setIsSubmit(false);
    }
  }, [divisionExistError]);

  return (
    <div className="overideDivision">
      <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
        <div className="heading_style">
          {editMode ? "Edit Division" : "Add Division"}
        </div>
      </Grid>
      <Grid item container mt={3} spacing={2}>
        <Grid item lg={4} xs={12} sm={12} md={4}>
          <InputField
            type="text"
            label="Division Name"
            placeholder="Add Division Name Here.."
            value={formValue.divisionName}
            isRequired
            onChange={(e) => {
              setFormValue({ ...formValue, divisionName: e.target.value });
              setDivisionExistError(false);
            }}
            isError={isDivisionName}
            errorMsg={isDivisionName ? "Division Name is Required" : ""}
          />
          {divisionExistError && (
            <div className="errorMsg">Division name already exists</div>
          )}
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4}>
          <FormControl fullWidth>
            <FormLabels isRequired={true} label="State" />
            <SelectMenu
              listData={state}
              value={formValue.stateId}
              onchangehandler={(e) => handleStateChange(e)}
              isError={isStateId}
              errorMsg={isStateId ? "State is Required" : ""}
              placeholder="Please Select"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container mt={3} spacing={2}>
        {serviceTypes.length > 1 && (
          <Grid item lg={4} xs={12} sm={12} md={6} pl={2}>
            <FormControl fullWidth>
              <FormLabels label="Service Type" isRequired={true} />

              <MultiSelectBox
                placeholder={"Please Select"}
                listData={serviceTypes}
                onChange={(e) => onChangeServiceType(e)}
                value={serviceType || []}
                isError={serviceTypeError}
                errorMessage="Selection is required for this field"
                disableSelect={true}
              />
            </FormControl>
          </Grid>
        )}
        {serviceTypes.length === 1 && (
          <Grid item lg={4} xs={12} sm={12} md={6} pl={2}>
            <FormControl fullWidth>
              <FormLabels label="Service Type" isRequired={true} />
              {serviceTypes?.map((value, index) => {
                return (
                  <div className="serviveTypeStyle" key={value?.key}>
                    {" "}
                    {value?.value}
                  </div>
                );
              })}
            </FormControl>
          </Grid>
        )}
        {(serviceType == 2 || selectedServiceTypeData == 2) && (
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <InputField
              type="text"
              label="Client Id"
              placeholder="Add Client Id Here.."
              value={formValue.clientId}
              isRequired={false}
              onChange={(e) =>
                setFormValue({ ...formValue, clientId: e.target.value })
              }
            />
          </Grid>
        )}
      </Grid>

      {editMode && (
        <Grid container item mt={3}>
          <Grid lg={4} md={6} sm={9} xs={12}>
            <FormLabels label="Division Admin" />
            <SearchAutoComplete
              name="Add Individual Users"
              optionsData={isUserLoading ? [] : allUsers?.users ?? []}
              typeOnchange={(event, value) => handleAllUserSearch(event, value)}
              isKeyValuePair={true}
              value={users}
              onChange={(e, value) => onChangeUsers(value)}
              isLoading={isUserLoading}
              autoCompClear={autoComp}
            />
          </Grid>

          <Grid
            className="smallAddBtn"
            lg={1.5}
            md={9}
            sm={12}
            xs={12}
            mt={4}
            ml={2}
          >
            <Buttons
              aria-describedby="dd"
              variant="contained"
              type="button"
              name="btn"
              label={"Add"}
              onClick={() => addIndiVidualUserData()}
              id="btnAddEquipment"
              varientAddIconBlue={true}
            ></Buttons>
          </Grid>
          <Grid mt={3} container gap={1}>
            <UsersChip
              userList={userList}
              handleDelete={(e) => deleteUsers(e)}
            />
          </Grid>
        </Grid>
      )}

      <Grid container item xs={12} mt={20}>
        <Grid item lg={4} xs={12} md={4} sm={4}>
          <BackBtn dataBack={dataBack} />
        </Grid>

        <Grid
          container
          item
          lg={8}
          xs={12}
          md={8}
          sm={8}
          className="divisionBtnBox"
        >
          <Grid lg={4} xs={12} sm={4} md={4}>
            <Buttons
              onClick={() => navigate(dataBack?.route)}
              varientText={true}
              label="Cancel"
            />
          </Grid>

          <Grid lg={4} xs={12} sm={4} md={4}>
            <Button
              varientContained={true}
              label={editMode ? "Update" : "Save"}
              disabled={isSubmit ? true : false}
              onClick={submitData}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateDivision;
