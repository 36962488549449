import React, { useEffect, useState, useRef } from "react";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import TextArea from "../../../../library/custom/textArea/TextArea";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import Button from "../../../../library/custom/button/Button";
import { ADMIN_EQUIPMENTS } from "../../../../constant/routeContant";
import { dateValiadtor, getFormatedDate } from "../../../../utils/helper";
import {
  ORG_ID,
  USERID,
  DIVISION_ID,
  USER_TYPE,
  EQUIPMENT_GRID_COLOUMNS,
  EQUIPMENT_TEMPLATE_URL,
} from "../../../../constant/constant";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import UsersChip from "../../../../library/custom/usersChip/UsersChip";
import "./equipment.scss";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import Buttons from "../../../../library/custom/button/Button";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const AddEquipment = ({
  onSubmit,
  division,
  equipmentCategory,
  equipmentData,
  editMode,
  userGroups,
  allUsers,
  isLoading,
  handleAllUserSearch,
  equipmentId,
  handleAllGroupSearch,
  ismultipleUpload,
  handleonfcs,
}) => {
  const initialState = {
    equipmentCategories: "",
    assignedUserId: "0",
    makeAndModel: "",
    serialNumber: "",
    serviceStartDate: null,
    serviceEndDate: null,
    note: "",
    createdBy: parseInt(USERID),
    divisionIds:
      DIVISION_ID() == "null"
        ? ""
        : DIVISION_ID() == 0
        ? "undefined"
        : DIVISION_ID(),
    divisionName:
      DIVISION_ID() == "null"
        ? ""
        : DIVISION_ID() == 0
        ? "undefined"
        : DIVISION_ID(),
    eqId: 1,
    assetSupervisor: "",
    userGroups: "",
    supervisorId: 0,
    individualUsers: "",
    assignToUser: [],
    supervisor: "",
    assignToInspector: [],
    startDate: new Date(),
    organisationId: ORG_ID(),
    year: "",
    subContractor: "",
  };
  const dataBack = {
    title: "Back to Equipment",
    route: ADMIN_EQUIPMENTS,
  };
  const [formValue, setFormValue] = useState(initialState);
  const [individualUsersList, setIndividualUsersList] = useState([]);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [individualUsers, setIndividualUsers] = useState(1);
  const [individualUsersIds, setIndividualUsersIds] = useState([]);
  const [usersGroup, setUsersGroup] = useState(1);
  const [userGroupList, setUsersGroupList] = useState([]);
  const [userGroupIds, setuserGroupIds] = useState([]);
  const [inspector, setInspector] = useState(1);
  const [inspectorList, setInspectorList] = useState([]);
  const [inspectorIds, setInspectorIds] = useState([]);
  const [contractorGroupsList, setContractorGroupsList] = useState([]);
  const [contractorGroups, setContractorGroups] = useState(1);
  const [contractorGroupsIds, setContractorGroupsIds] = useState([]);

  const autoComp = useRef(null);
  const autoComp1 = useRef(null);
  const autoComp2 = useRef(null);
  const autoCompSupervisor = useRef(null);
  const autoCompContractor = useRef(null);

  const deleteIndividualUsersData = (id) => {
    const updateIndividualUsersList = individualUsersList.filter(
      (list) => list.key !== id
    );
    const updateindividualUsersIds = individualUsersIds.filter(
      (list) => list !== id
    );
    setIndividualUsersList(updateIndividualUsersList);
    setIndividualUsersIds(updateindividualUsersIds);
  };

  const deleteusersGroupData = (id) => {
    const updateUserGroupList = userGroupList.filter((list) => list.key !== id);
    const updateUserGroupIds = userGroupIds.filter((list) => list !== id);
    setUsersGroupList(updateUserGroupList);
    setuserGroupIds(updateUserGroupIds);
  };

  const addUserGroupData = () => {
    if (!usersGroup.key) return;
    const index = userGroupList.findIndex(
      (object) => object.key === usersGroup.key
    );
    if (index === -1) {
      setuserGroupIds([...userGroupIds, usersGroup.key]);
      setUsersGroupList([...userGroupList, usersGroup]);
      const ele = autoComp.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const deleteContractorGroupsData = (id) => {
    const updatecontractorGroupsList = contractorGroupsList.filter(
      (list) => list.key !== id
    );
    const updateContractorGroupsIds = contractorGroupsIds.filter(
      (list) => list !== id
    );
    setContractorGroupsList(updatecontractorGroupsList);
    setContractorGroupsIds(updateContractorGroupsIds);
  };

  const addIndiVidualUserData = () => {
    if (!individualUsers.key) return;
    const index = individualUsersList.findIndex(
      (object) => object.key === individualUsers.key
    );
    if (index === -1) {
      setIndividualUsersIds([...individualUsersIds, individualUsers.key]);
      setIndividualUsersList([...individualUsersList, individualUsers]);
      const ele = autoComp2.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const addContractorGroupsData = () => {
    if (!contractorGroups.key) return;
    const index = contractorGroupsList.findIndex(
      (object) => object.key === contractorGroups.key
    );
    if (index === -1) {
      setContractorGroupsIds([...contractorGroupsIds, contractorGroups.key]);
      setContractorGroupsList([...contractorGroupsList, contractorGroups]);
    }
    const ele = autoCompContractor.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  function mappedData() {
    const data = {
      organisationId: ORG_ID(),
      equipmentCategory: formValue?.equipmentCategories,
      assignToSupervisor: supervisorId,
      assignToSubcontractor: contractorGroupsIds,
      makeAndModel: formValue?.makeAndModel,
      serialNumber: formValue?.serialNumber?.trim(),
      serviceStartDate:
        formValue.serviceStartDate === null
          ? null
          : getFormatedDate(formValue?.serviceStartDate),
      serviceEndDate:
        formValue.serviceEndDate === null
          ? null
          : getFormatedDate(formValue?.serviceEndDate),
      note: formValue?.note,
      divisionId: formValue?.divisionName,
      assignToUser: individualUsersIds,
      assignToGroup: userGroupIds,
      equipmentId: equipmentId,
      isActive: formValue.isActive === "1" ? true : false,
      createdBy: parseInt(localStorage.getItem("userId")),
      createdByApp: 0,
      assignToInspector: inspectorIds,
      year: formValue.year,
      subContractor: "",
    };
    let errors = false;

    if (formValue?.equipmentCategories?.length === 0) {
      setIsEquipmentCategory(true);
      errors = true;
    }

    if (formValue?.makeAndModel?.trim() === "") {
      setFormValue({
        ...formValue,
        makeAndModel: "",
      });
      setIsMakeAndModel(true);
      errors = true;
    }

    if (formValue?.divisionName?.length === 0) {
      setIsDivision(true);
      errors = true;
    }

    if (formValue?.serialNumber?.trim() === "") {
      setFormValue({
        ...formValue,
        serialNumber: "",
      });
      setIsEquipmentSerialNo(true);
      errors = true;
    }

    if (formValue.serviceStartDate != null) {
      const validDate = dateValiadtor(
        getFormatedDate(formValue.serviceStartDate)
      );
      if (validDate === false) {
        setIsServiceStartDate(true);
        errors = true;
        setIsServiceStartDateErrMsg("Invalid Date");
      }
    }
    if (formValue.serviceEndDate != null) {
      const validDate = dateValiadtor(
        getFormatedDate(formValue.serviceEndDate)
      );
      if (validDate === false) {
        setIsServiceEndDate(true);
        errors = true;
        setIsServiceEndDateErrMsg("Invalid Date");
      }
    }
    if (formValue.year != null) {
      const validDate = dateValiadtor(getFormatedDate(formValue.year));
      if (validDate === false) {
        setIsYear(true);
        errors = true;
        setIsYearErrMsg("Please enter this required field");
      }
    }

    if (errors) {
      return false;
    }
    return data;
  }

  useEffect(() => {
    if (equipmentData) {
      setFormValue({
        supervisorId: equipmentData?.supervisorUserId,
        year: equipmentData?.year,
        createdBy: equipmentData?.createdBy,
        divisionName: equipmentData?.divisionId,
        equipmentCategories: equipmentData?.equipmentCategory,
        makeAndModel: equipmentData?.makeAndModel,
        note: equipmentData?.note,
        serialNumber: equipmentData?.serialNumber,
        serviceEndDate:
          equipmentData?.serviceEndDate === null
            ? null
            : equipmentData?.serviceEndDate,
        serviceStartDate:
          equipmentData?.serviceStartDate === null
            ? null
            : equipmentData?.serviceStartDate,
        isActive: equipmentData?.isActive === 1 ? "1" : "0",
        equipmentID: equipmentData?.equipmentId,
        supervisorUserName: equipmentData?.supervisorUserName,
      });
      setIndividualUsersIds(
        ...individualUsersIds,
        equipmentData?.assignToUser?.map((e) => e.key)
      );

      if (equipmentData?.assignToUser?.length > 0) {
        setIndividualUsersList(
          ...individualUsersList,
          equipmentData?.assignToUser?.map((e) => e)
        );
      }

      setuserGroupIds(
        ...userGroupIds,
        equipmentData?.assignToGroup?.map((e) => e.key)
      );
      if (equipmentData?.assignToGroup?.length > 0) {
        setUsersGroupList(
          ...userGroupList,
          equipmentData?.assignToGroup?.map((e) => e)
        );
      }

      setInspectorIds(
        ...inspectorIds,
        equipmentData?.assignToInspector?.map((e) => e.key)
      );
      if (equipmentData?.assignToInspector?.length > 0) {
        setInspectorList(
          ...inspectorList,
          equipmentData?.assignToInspector?.map((e) => e)
        );
      }

      setsupervisorId(equipmentData?.assignToSupervisor?.map((e) => e.key));
      if (equipmentData?.assignToSupervisor?.length > 0) {
        setsupervisoList(
          ...supervisoList,
          equipmentData?.assignToSupervisor?.map((e) => e)
        );
      }

      setContractorGroupsIds(
        ...contractorGroupsIds,
        equipmentData?.assignToSubcontractor?.map((e) => e.key)
      );
      if (equipmentData?.assignToSubcontractor?.length > 0) {
        setContractorGroupsList(
          ...contractorGroupsList,
          equipmentData?.assignToSubcontractor?.map((e) => e)
        );
      }
    }
  }, [equipmentData]);

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      editMode ? onSubmit(data) : onSubmit([data]);
    }
  };

  const [isEquipmentCategory, setIsEquipmentCategory] = useState(false);
  const [isMakeAndModel, setIsMakeAndModel] = useState(false);
  const [isDivision, setIsDivision] = useState(false);
  const [isEquipmentSerialNo, setIsEquipmentSerialNo] = useState(false);
  const [isServiceStartDate, setIsServiceStartDate] = useState(false);
  const [isServiceStartDateErrMsg, setIsServiceStartDateErrMsg] =
    useState(false);
  const [isServiceEndDate, setIsServiceEndDate] = useState(false);
  const [isServiceEndDateErrMsg, setIsServiceEndDateErrMsg] = useState(false);
  const [isYear, setIsYear] = useState(false);
  const [isYearErrMsg, setIsYearErrMsg] = useState(false);
  const [supervisorId, setsupervisorId] = useState([]);
  const [supervisoList, setsupervisoList] = useState([]);
  const [supervisor, setsupervisor] = useState(1);

  const deleteInspectorData = (id) => {
    const updateInspectorList = inspectorList.filter((list) => list.key !== id);
    const updateInspectorIds = inspectorIds.filter((list) => list !== id);
    setInspectorList(updateInspectorList);
    setInspectorIds(updateInspectorIds);
  };

  const deleteSupervisorData = (id) => {
    const updateSupervisorList = supervisoList.filter(
      (list) => list.key !== id
    );
    const updateSupervisorIds = supervisorId.filter((list) => list !== id);
    setsupervisoList(updateSupervisorList);
    setsupervisorId(updateSupervisorIds);
  };

  const addInspectorData = () => {
    if (!inspector.key) return;
    const index = inspectorList.findIndex(
      (object) => object.key === inspector.key
    );
    if (index === -1) {
      setInspectorIds([...inspectorIds, inspector.key]);
      setInspectorList([...inspectorList, inspector]);
      const ele = autoComp1.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const addSupervisorData = () => {
    if (!supervisor.key) return;
    const index = supervisoList.findIndex(
      (object) => object.key === supervisor.key
    );
    if (index === -1) {
      setsupervisorId([...supervisorId, supervisor.key]);
      setsupervisoList([...supervisoList, supervisor]);
      const ele = autoCompSupervisor.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const handleFileChanges = (data) => {
    const newData = data.map((x) => {
      x.organisationId = parseInt(ORG_ID());
      x.createdBy = parseInt(USERID);
      x.assignToSupervisor =
        x.assignToSupervisor === undefined
          ? []
          : x.assignToSupervisor.split(",");
      x.divisionId = parseInt(x.divisionId);
      x.assignToUser =
        x.assignToUser === undefined ? [] : x.assignToUser.split(",");
      x.assignToGroup =
        x.assignToGroup === undefined ? [] : x.assignToGroup.split(",");
      x.assignToInspector =
        x.assignToInspector === undefined || []
          ? []
          : x.assignToInspector.split(",");
      x.assignToSubcontractor =
        x.assignToSubcontractor === undefined
          ? []
          : x.assignToSubcontractor.split(",");
      x.equipmentCategory = parseInt(x.equipmentCategory);
      x.makeAndModel = x?.makeAndModel;
      x.note = x?.note;
      x.serviceStartDate = x?.serviceStartDate
        ? x?.serviceStartDate?.replace(/\//g, "-")
        : null;
      x.serviceEndDate = x?.serviceEndDate
        ? x?.serviceEndDate?.replace(/\//g, "-")
        : null;
      return x;
    });
    onSubmit(newData);
  };

  const [isSupervisor, setisSupervisor] = useState(false);
  const [isInspector, setisInspector] = useState(false);
  const [isIndividualUser, setisIndividualUser] = useState(false);
  const [isUserGroup, setisUserGroup] = useState(false);
  const [isContractor, setisContractor] = useState(false);

  const [isSupervisorErrMsg, setisSupervisorErrMsg] = useState("");
  const [isInspectorErrMsg, setisInspectorErrMsg] = useState("");
  const [isIndividualUserErrMsg, setisIndividualUserErrMsg] = useState("");
  const [isUserGroupErrMsg, setisUserGroupErrMsg] = useState("");
  const [isContractorErrMsg, setisContractorErrMsg] = useState("");

  const [assetSupervisorValue, setAssetSupervisorValue] = useState("");
  const onSelectAssetSupervisor = (value) => {
    setAssetSupervisorValue({
      key: value?.key ?? "",
      value: value?.value ?? "",
    });
    setsupervisor(value);
  };

  const mapBulkError = (data) => {
    let equipmentsWithError = [];
    data.forEach((x) => {
      if (
        x.equipmentCategory?.trim() === undefined ||
        null ||
        "" ||
        x.makeAndModel?.trim() === undefined ||
        null ||
        "" ||
        x.year?.trim().length !== 4 ||
        x.divisionId?.trim() === undefined ||
        null ||
        "" ||
        x.serialNumber?.trim() === undefined ||
        null ||
        ""
      ) {
        equipmentsWithError.push(x);
        return;
      }
    });
    return equipmentsWithError;
  };
  return (
    <>
      <div className="overidePersonal">
        <Grid container item lg={9} xs={12} sm={12} md={9}>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
            <div className="heading_style">
              {editMode ? "Edit Equipment" : "Add Equipment"}
            </div>
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="right">
            {checkPrivileges([52, 12]) && !editMode ? (
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={isBulkUpload ? "Single Equipment" : "Multiple Equipment"}
                varientContained={true}
                onClick={() => {
                  setIsBulkUpload(!isBulkUpload);
                  ismultipleUpload(!isBulkUpload);
                }}
              ></Buttons>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} mt={3} xs={12} sm={12} md={4}>
            {checkPrivileges([53, 12]) && editMode && (
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="radioGroup"
                >
                  <RadioButton
                    checked={formValue.isActive == 1}
                    value="1"
                    label="Active"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        isActive: e.target.value,
                      })
                    }
                  ></RadioButton>
                  <RadioButton
                    checked={formValue.isActive == 0}
                    value="0"
                    label="Inactive"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        isActive: e.target.value,
                      })
                    }
                  ></RadioButton>
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
        </Grid>
        {isBulkUpload ? (
          <Grid container>
            <Grid item lg={9} xs={9} sm={9} md={9} mt={4}>
              <BulkUpload
                mapBulkError={mapBulkError}
                handleFileChanges={handleFileChanges}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                templateUrl={EQUIPMENT_TEMPLATE_URL}
                templateText="Download Template"
                gridColoumns={EQUIPMENT_GRID_COLOUMNS}
                isCustomColoumns={true}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container item md={9} xs={9} className="addShadowBox">
            <Grid item container spacing={2} lg={12}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Equipment Category" isRequired={true} />
                  <SelectMenu
                    placeholder="Please Select"
                    listData={equipmentCategory}
                    value={formValue.equipmentCategories}
                    onchangehandler={(e) => {
                      setFormValue({
                        ...formValue,
                        equipmentCategories: e.target.value,
                      });
                      setisSupervisor(false);
                      setisInspector(false);
                      setisIndividualUser(false);
                      setisUserGroup(false);
                    }}
                    isError={isEquipmentCategory}
                    errorMsg={
                      isEquipmentCategory
                        ? "Equipment Category is Required"
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <InputField
                  fullWidth={true}
                  type="text"
                  label="Equipment Make & Model"
                  value={formValue.makeAndModel}
                  isRequired={true}
                  onChange={(e) => {
                    if (e.target.value.length > 200) {
                      return;
                    } else
                      setFormValue({
                        ...formValue,
                        makeAndModel: e.target.value,
                      });
                  }}
                  placeholder="Add Equipment Make & Model Here.."
                  isError={isMakeAndModel}
                  errorMsg={
                    isMakeAndModel ? "Equipment  Make & Model is Required" : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} lg={12} mt={3}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth className="dateborderNone">
                  <FormLabels label={"Equipment Year"} isRequired={true} />
                  <DatePicker
                    views={["year"]}
                    value={formValue.year}
                    onChangeHandler={(e) => {
                      setFormValue({
                        ...formValue,
                        year: e,
                      });
                      setIsYear(false);
                    }}
                    inputFormat="yyyy"
                    isError={isYear}
                    errorMsg={isYear ? isYearErrMsg : ""}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Division" isRequired={true} />
                  <SelectMenu
                    placeholder="Please Select"
                    listData={division}
                    //value={formValue.divisionName}
                    value={
                      formValue.divisionName === "undefined"
                        ? null
                        : formValue.divisionName
                    }
                    onchangehandler={(e) => {
                      setFormValue({
                        ...formValue,
                        divisionName: e.target.value,
                        divisionIds: e.target.value,
                      });

                      setisIndividualUser(false);
                      setIndividualUsersIds([]);
                      setIndividualUsersList([]);

                      setisUserGroup(false);
                      setuserGroupIds([]);
                      setUsersGroupList([]);

                      setisInspector(false);
                      setInspectorIds([]);
                      setInspectorList([]);

                      setisSupervisor(false);
                      setsupervisorId([]);
                      setsupervisoList([]);

                      setisContractor(false);
                      setContractorGroupsList([]);
                      setContractorGroupsIds([]);
                    }}
                    disabled={DIVISION_ID() > 0}
                    isError={isDivision}
                    errorMsg={
                      isDivision ? "Selection is required for this field" : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isSupervisor && (
                  <div className="errorMsg">{isSupervisorErrMsg}</div>
                )}
                <FormLabels label="Asset Supervisor" />
                <SearchAutoComplete
                  name="Asset Supervisor"
                  optionsData={isLoading ? [] : allUsers?.users ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.divisionName === "undefined") {
                      setisSupervisor(true);
                      setisSupervisorErrMsg("Please select division first");
                      return;
                    } else {
                      setisSupervisor(false);
                      setisSupervisorErrMsg("");
                      handleAllUserSearch(
                        event,
                        USER_TYPE.DIVISION_EMPLOYEE,
                        formValue.divisionName,
                        0,
                        0,
                        141
                      );
                    }
                  }}
                  isKeyValuePair={true}
                  value={assetSupervisorValue}
                  onChange={(e, value) => onSelectAssetSupervisor(value)}
                  isLoading={isLoading}
                  autoCompClear={autoCompSupervisor}
                  handleFocus={handleonfcs && handleonfcs}
                />
              </Grid>

              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Button
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addSupervisorData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Button>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  userList={supervisoList}
                  handleDelete={(e) => deleteSupervisorData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isInspector && (
                  <div className="errorMsg">{isInspectorErrMsg}</div>
                )}
                <FormLabels label="Assign Inspector" />
                <SearchAutoComplete
                  name="Assign Inspector"
                  optionsData={isLoading ? [] : allUsers?.users ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.divisionName === "undefined") {
                      setisInspector(true);
                      setisInspectorErrMsg("Please select division first");
                      return;
                    } else {
                      console.log(formValue.divisionName);
                      setisInspector(false);
                      setisInspectorErrMsg("");
                      handleAllUserSearch(
                        event,
                        USER_TYPE.DIVISION_EMPLOYEE,
                        formValue.divisionName,
                        0,
                        0,
                        140
                      );
                    }
                  }}
                  isKeyValuePair={true}
                  value={formValue.supervisor}
                  onChange={(e, value) => setInspector(value)}
                  isLoading={isLoading}
                  autoCompClear={autoComp1}
                  handleFocus={handleonfcs && handleonfcs}
                />
              </Grid>

              <Grid
                className="smallAddBtn"
                lg={6}
                md={6}
                sm={6}
                xs={12}
                mt={3.5}
                pl={2}
              >
                <Button
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addInspectorData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Button>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  userList={inspectorList}
                  handleDelete={(e) => deleteInspectorData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isIndividualUser && (
                  <div className="errorMsg">{isIndividualUserErrMsg}</div>
                )}
                <FormLabels label="Add Individual Users" />
                <SearchAutoComplete
                  name="Add Individual Users"
                  optionsData={isLoading ? [] : allUsers?.users ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.divisionName === "undefined") {
                      setisIndividualUser(true);
                      setisIndividualUserErrMsg("Please select division first");
                      return;
                    } else {
                      setisIndividualUser(false);
                      setisIndividualUserErrMsg("");
                      handleAllUserSearch(
                        event,
                        USER_TYPE.NONPRIVILEGED,
                        formValue.divisionName
                      );
                    }
                  }}
                  isKeyValuePair={true}
                  value={formValue.supervisor}
                  onChange={(e, value) => setIndividualUsers(value)}
                  isLoading={isLoading}
                  autoCompClear={autoComp2}
                  handleFocus={handleonfcs && handleonfcs}
                />
              </Grid>
              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Button
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addIndiVidualUserData()}
                  varientAddIconBlue={true}
                  id="btnAddEquipment"
                ></Button>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  userList={individualUsersList ?? []}
                  handleDelete={(e) => deleteIndividualUsersData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isUserGroup && (
                  <div className="errorMsg">{isUserGroupErrMsg}</div>
                )}
                <FormLabels label="Add User Group" />
                <SearchAutoComplete
                  name="Add User Group"
                  optionsData={isLoading ? [] : userGroups ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.divisionName === "undefined") {
                      setisUserGroup(true);
                      setisUserGroupErrMsg("Please select division first");
                      return;
                    } else {
                      setisUserGroup(false);
                      setisUserGroupErrMsg("");

                      handleAllGroupSearch(event, formValue.divisionName);
                    }
                  }}
                  isLoading={isLoading}
                  isKeyValuePair={true}
                  value={formValue.userGroups}
                  onChange={(e, value) => setUsersGroup(value)}
                  autoCompClear={autoComp}
                />
              </Grid>

              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Button
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addUserGroupData()}
                  varientAddIconBlue={true}
                  id="btnAddEquipment"
                ></Button>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  isKeyValuePair={true}
                  userList={userGroupList}
                  handleDelete={(e) => deleteusersGroupData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                {isContractor && (
                  <div className="errorMsg">{isContractorErrMsg}</div>
                )}
                <FormLabels label="Add Subcontractors" />
                <SearchAutoComplete
                  name="Add Subcontractors"
                  optionsData={allUsers?.users ?? []}
                  typeOnchange={(event, value = 1) => {
                    if (formValue.divisionName === "undefined") {
                      setisContractor(true);
                      setisContractorErrMsg("Please select division first");
                      return;
                    } else {
                      setisContractor(false);
                      setisContractorErrMsg("");

                      handleAllUserSearch(
                        event,
                        USER_TYPE.DIVISION_CONTRACTOR,
                        formValue.divisionName
                      );
                    }
                  }}
                  isKeyValuePair={true}
                  value={formValue.subContractor}
                  onChange={(e, value) => setContractorGroups(value)}
                  autoCompClear={autoCompContractor}
                  handleFocus={handleonfcs && handleonfcs}
                />
              </Grid>

              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addContractorGroupsData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  isKeyValuePair={true}
                  userList={contractorGroupsList}
                  handleDelete={(e) => deleteContractorGroupsData(e)}
                />
              </Grid>
            </Grid>

            <Grid item container mt={3} spacing={2}>
              {editMode ? (
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <InputField
                      isRequired={true}
                      fullWidth={true}
                      disabled={true}
                      type="text"
                      label="Equipment ID"
                      value={formValue.equipmentID}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          equipmentID: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}

              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <InputField
                    isRequired={true}
                    fullWidth={true}
                    disabled={false}
                    type="text"
                    label="Equipment Serial Number"
                    value={formValue.serialNumber}
                    onChange={(e) => {
                      if (e.target.value.length > 200) {
                        return;
                      } else
                        setFormValue({
                          ...formValue,
                          serialNumber: e.target.value,
                        });
                    }}
                    placeholder="Add Equipment Serial Number Here.."
                    isError={isEquipmentSerialNo}
                    errorMsg={
                      isEquipmentSerialNo
                        ? "Equipment Serial Number is Required"
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              item
              className="addWitnessContainerGrid "
              container
              mt={3}
              spacing={2}
              lg={12}
            >
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label={"Start Date"} />
                  <DatePicker
                    value={formValue.serviceStartDate}
                    onChangeHandler={(e) => {
                      setFormValue({
                        ...formValue,
                        serviceStartDate: e,
                      });
                      setIsServiceStartDate(false);
                    }}
                    isError={isServiceStartDate}
                    errorMsg={
                      isServiceStartDate ? isServiceStartDateErrMsg : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label={"Completion Date"} />
                  <DatePicker
                    value={formValue.serviceEndDate}
                    maxDateEnable
                    minDate={formValue.serviceStartDate}
                    onChangeHandler={(e) => {
                      const dateFrom = getFormatedDate(
                        formValue.serviceStartDate
                      );
                      const dateTo = getFormatedDate(e);
                      setFormValue({ ...formValue, serviceEndDate: e });
                      setIsServiceEndDate(false);
                    }}
                    isError={isServiceEndDate}
                    errorMsg={isServiceEndDate ? isServiceEndDateErrMsg : ""}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container mt={3}>
              <Grid item lg={12} xs={12} sm={12} md={8}>
                <FormLabels label=" Add Notes" />
                <TextArea
                  placeholder="Add Notes Here.."
                  value={formValue.note}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      note: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid item container lg={11} className="buttonsGrid" mt={3} mb={3}>
              <div>
                <BackBtn dataBack={dataBack} />
              </div>
              <Grid item lg={6} className="cancelSaveDiv">
                <Grid item lg={5}>
                  <Button
                    varientContained={true}
                    label={editMode ? "Update" : "Save"}
                    onClick={submitData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default AddEquipment;
