import { FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import React, { useState } from "react";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import DatePickers from "../../../../library/custom/datePicker/DatePicker";
import { useNavigate } from "react-router-dom";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { ADMIN_CATEGORY_DASHBOARD } from "../../../../constant/routeContant";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import {
  HBNEXT_ORG_ID,
  ISwitch,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import ReportRowManipulte from "../../../admin/shared/ReportRowManipulate";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import SearchIcon from "@mui/icons-material/Search";
import { dateValiadtor, getFormatedDate } from "../../../../utils/helper";
import Button from "../../../../library/custom/button/Button";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import { checkPrivileges } from "../../../../utils/rolesHelper";

function AddGeneralPermit({
  state,
  generalPermitList,
  handleGeneralPermitList,
  handleUpdateGeneralPermit,
  editMode,
  stateId,
  isLoading,
}) {
  const [open, setOpen] = useState(false);
  const [isStartDate, setisStartDate] = useState(false);
  const [modalData, setModalData] = useState();
  const [search, setSearch] = useState();
  const [isStateError, setIsStateError] = useState(false);
  const [generalPermitDateErr, setGeneralPermitDateErr] = useState(false);
  const initialState = {
    stateId: 0,
    startDate: null,
  };
  const navigate = useNavigate();
  const editInitialState = {
    stateId: 0,
    startDate: null,
  };
  const [formValue, setFormValue] = useState(initialState);
  const [editFormValue, setEditFormValue] = useState(editInitialState);
  const [editStartDateErr, setEditStartDateErr] = useState(false);
  const [editStartDateVaildErr, setEditStartDateValidErr] = useState(false);

  const [page, setPage] = useState(1);
  console.log(editStartDateErr, "editStartDateErr");
  const [addGeneralPermitBox, setAddGeneralPermitBox] = useState(false);
  const [permitFilterData, setPermitFilterData] = useState({});
  const dataBack = {
    title: "Back to Configuration Management",
    route: ADMIN_CATEGORY_DASHBOARD,
  };
  const handleClose = () => {
    setOpen(false);
    setFormValue({ ...formValue, stateId: "", startDate: null });
    setEditStartDateErr(false);
    setEditStartDateValidErr(false);
  };

  function mappedData() {
    let errors = false;
    if (formValue.stateId === 0) {
      setIsStateError(true);
      errors = true;
    }
    if (formValue?.startDate === null) {
      setisStartDate(true);
      errors = true;
    }
    if (formValue?.startDate) {
      const validDate = dateValiadtor(getFormatedDate(formValue?.startDate));
      if (validDate === false) {
        setGeneralPermitDateErr(true);
        errors = true;
      } else if (validDate === "Valid date") {
        setGeneralPermitDateErr(false);
      }
    }
    if (errors) {
      return false;
    }
    const data = {
      stateId: formValue.stateId,
      genPermitExpDate:
        formValue.startDate === null
          ? null
          : getFormatedDate(formValue.startDate),
    };

    return data;
  }

  function mappedData1() {
    let errors = false;
    console.log(editFormValue?.startDate, "editFormValue?.startDate");
    if (editFormValue?.startDate === null) {
      setEditStartDateErr(true);
      errors = true;
    } else {
      setEditStartDateErr(false);
    }
    if (editFormValue?.startDate) {
      const validDate = dateValiadtor(
        getFormatedDate(editFormValue?.startDate)
      );
      if (validDate === false) {
        setEditStartDateValidErr(true);
        errors = true;
      } else if (validDate === "Valid date") {
        setEditStartDateValidErr(false);
      }
    }
    if (errors) {
      return false;
    }
    const editData = {
      stateId: modalData?.stateId,
      genPermitExpDate:
        editFormValue?.startDate === null
          ? null
          : getFormatedDate(editFormValue?.startDate),
    };

    return editData;
  }
  const editSubmitData = () => {
    const editData = mappedData1();
    console.log(editData, "edit datta");
    if (!editData) {
    } else {
      handleUpdateGeneralPermit(editData);
    }
  };

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      handleUpdateGeneralPermit(data);
      setFormValue({
        ...formValue,
        startDate: null,
        stateId: "",
      });
    }
  };
  const generalPermitLists = generalPermitList?.generalpermit;

  const handleChangeGlobal = (item) => {
    const newTypeList = [...generalPermitLists];
    newTypeList.forEach((lists) => {
      if (lists.stateId === item.stateId) {
        lists.isGlobal = !lists.isGlobal;
        lists.checked = !lists.checked;
        handleUpdateGeneralPermit({
          stateId: lists?.stateId,
          genPermitExpDate: lists?.expirationDate,
          isGlobal: lists?.isGlobal,
        });
      }
    });
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...permitFilterData,
      search: searchText ? searchText : "",
      page: 1,
    };
    setPermitFilterData(data);
    handleGeneralPermitList(data);
  };
  const columns = [
    { field: "state", headerName: "State", flex: 4 },
    {
      field: "expirationDate",
      headerName: "General Permit Expiration Date",
      flex: 3,
    },
    {
      flex: 2,
      field: "isGlobal",
      headerName: "Global",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox swins">
                {checkPrivileges([12, 93]) &&
                  parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
                    <FormControlLabel
                      control={
                        <ISwitch
                          checked={rows?.row.isGlobal ? true : false}
                          onChange={() => handleChangeGlobal(rows?.row)}
                          name="status"
                        />
                      }
                    />
                  )}

                <div>{rows?.row?.isGlobal === true ? "Global" : "N/A"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={generalPermitList?.itemsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={generalPermitList?.permits ?? []}
          fileNameXL={"generalPermitList"}
        />
      ),
      flex: 0.6,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={true}
            editOnClick={() => handleEditOnClick(rows?.row)}
            edit={true}
            editLabel="Edit Expiration Date"
            download={false}
          />
        );
      },
    },
  ];

  const handleEditOnClick = (modalData, rows) => {
    setAddGeneralPermitBox(!addGeneralPermitBox);
    setOpen(true, {
      state: { stateId: rows?.row?.stateId, editMode: true },
    });
    setModalData(modalData);

    setEditFormValue({
      startDate: modalData?.expirationDate,
    });
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    handleGeneralPermitList({
      search: "",
      page: newPage + 1,
    });
  };

  return (
    <>
      <div className="overridePersonal">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid className="modalContainer">
            <Box className="modalBox">
              <div className="modalTitle">Edit General Permit</div>
              <div className="modalInputBox">
                <Grid item lg={5} md={8} xs={8} mb={2}>
                  <FormControl fullWidth>
                    <FormLabels
                      label={"General Permit Expiration Date"}
                      isRequired={true}
                    />
                    <DatePickers
                      maxDateEnable={true}
                      value={editFormValue?.startDate}
                      disabled={false}
                      onChangeHandler={(e) => {
                        setEditFormValue({
                          ...editFormValue,
                          startDate: e,
                        });
                        setEditStartDateErr(false);
                        setEditStartDateValidErr(false);
                      }}
                      isError={editStartDateErr}
                      errorMsg={
                        editStartDateErr || editFormValue?.startDate === !null
                          ? "Field is Required"
                          : ""
                      }
                    />
                  </FormControl>
                  {editStartDateVaildErr &&
                    editFormValue?.startDate != null && (
                      <div className="errorMsg">Invalid Date</div>
                    )}
                </Grid>
              </div>
              <Grid container className="modalFooterBox">
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Button
                    varientText={true}
                    label="Cancel"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Button
                    varientContained={true}
                    label={"Save"}
                    onClick={editSubmitData}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Modal>
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">General Permits</div>
        </Grid>
        {checkPrivileges([12, 93]) &&
          parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
            <>
              <Grid item container mt={4} spacing={2}>
                <Grid item lg={5} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="Select State" isRequired={true} />
                    <SelectMenu
                      listData={state}
                      value={formValue.stateId}
                      placeholder="Please Select"
                      onchangehandler={(e) =>
                        setFormValue({
                          ...formValue,
                          stateId: e.target.value,
                        })
                      }
                      isError={isStateError}
                      errorMsg={isStateError ? "Field is Required" : ""}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item mt={3} lg={5} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels
                      label={"General Permit Expiration Date"}
                      isRequired={true}
                    />
                    <DatePickers
                      value={formValue.startDate}
                      maxDateEnable={true}
                      disabled={false}
                      onChangeHandler={(e) => {
                        setFormValue({
                          ...formValue,
                          startDate: e,
                        });
                        setisStartDate(false);
                        setGeneralPermitDateErr(false);
                      }}
                      isError={isStartDate}
                      errorMsg={isStartDate ? "Field is Required" : ""}
                    />
                  </FormControl>
                  {generalPermitDateErr && formValue?.startDate != null && (
                    <div className="errorMsg">Invalid Date</div>
                  )}
                </Grid>
              </Grid>
            </>
          )}
      </div>
      <section className="grid_main_sec">
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.stateId}
                rows={generalPermitList?.generalpermit ?? []}
                rowCount={generalPermitList?.itemsCount ?? 0}
                columns={columns}
                checkboxSelection={false}
                page={1}
                loading={isLoading}
                pagination
                pageSize={PAGE_SIZE}
                onPageChange={handlePagignation}
                rowsPerPageOptions={[PAGE_SIZE]}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <Grid item container lg={11} className="buttonsGrid" mt={3} mb={3}>
        <div className="">
          <BackBtn dataBack={dataBack} />
        </div>
        <Grid
          item
          lg={6}
          className="cancelSaveDiv"
          style={{ marginRight: "-70px" }}
        >
          {checkPrivileges([12, 93]) &&
            parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
              <>
                <Grid item lg={5}>
                  <Button
                    varientText={true}
                    label="Cancel"
                    onClick={() => navigate(-1)}
                  />
                </Grid>
                <Grid item lg={5}>
                  <Button
                    varientContained={true}
                    label={"Save"}
                    onClick={submitData}
                  />
                </Grid>
              </>
            )}
        </Grid>
      </Grid>
    </>
  );
}

export default AddGeneralPermit;
