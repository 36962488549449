import { API_STATUS } from "../../constant/constant";
import { vehicalAccidentType } from "../constants/vehicalAccidentType";

const initialState = {
  flow: "vehicleAccident",
  isError: false,
  isLoading: false,
};

export const vehicleAccidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case vehicalAccidentType.POST_VEHICAL_ACCIDENT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case vehicalAccidentType.POST_VEHICAL_ACCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case vehicalAccidentType.POST_VEHICAL_ACCIDENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case vehicalAccidentType.CLEAR_VEHICAL_ACCIDENT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const viewVehicleAccidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case vehicalAccidentType.GET_VEHICAL_ACCIDENT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case vehicalAccidentType.GET_VEHICAL_ACCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case vehicalAccidentType.GET_VEHICAL_ACCIDENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateVehicleAccidentReducer = (state = initialState, action) => {
  switch (action.type) {
    case vehicalAccidentType.PUT_VEHICAL_ACCIDENT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case vehicalAccidentType.PUT_VEHICAL_ACCIDENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case vehicalAccidentType.PUT_VEHICAL_ACCIDENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case vehicalAccidentType.CLEAR_VEHICAL_ACCIDENT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getRegNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case vehicalAccidentType.GET_REG_NUMBER:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case vehicalAccidentType.GET_REG_NUMBER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case vehicalAccidentType.GET_REG_NUMBER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
