export const nearMissesType = {
  POST_NEAR_MISSES_PENDING:
    "[POST_NEAR_MISSES_PENDING][REQUEST] Try To create Near Misses",
  POST_NEAR_MISSES_FAILURE:
    "[POST_NEAR_MISSES_FAILURE][RESPONSE] Near Misses Failed",
  POST_NEAR_MISSES_SUCCESS:
    "[POST_NEAR_MISSES_SUCCESS][RESPONSE] Near Misses Successfull",

  PUT_NEAR_MISSES_PENDING:
    "[PUT_NEAR_MISSES_PENDING][REQUEST] Try To update Near Misses",
  PUT_NEAR_MISSES_FAILURE:
    "[PUT_NEAR_MISSES_FAILURE][RESPONSE] Near Misses Failed",
  PUT_NEAR_MISSES_SUCCESS:
    "[PUT_NEAR_MISSES_SUCCESS][RESPONSE] Near Misses Successfull",

  NEAR_MISSES_PAGE: "NearMissesPage",

  GET_NEAR_MISSES_PENDING: "[REQUEST] Get Near Misses Report Data",
  GET_NEAR_MISSES_SUCCESS: "[RESPONSE] Get Near Misses Report Data Successfull",
  GET_NEAR_MISSES_FAILURE: "[RESPONSE] Get Near Misses Report Data Failed",
  CLEAR_NEAR_MISSES: "[CLEAR_NEAR_MISSES] Clear Near Misses",
};
