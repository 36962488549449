import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./commonObservationReport.scss";
import ViewImages from "../../incidents/view-incident-report/common/viewImages/viewImages";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import {
  VIEW_OBSERVATION_LISTS,
  OPEN_ITEMS_LIST,
  INSPECTION_REPORT,
} from "../../../constant/routeContant";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { RemoveRedEye } from "@mui/icons-material";
const KudosObservationReport = ({
  observationReportData,
  InspectionOpenItemDetail,
  pageTitle,
  origin,
  observationStatusLog,
  involvedPartyName,
  involvedPartyId,
  backRouteText,
  backRoute,
  addedBy,
}) => {
  const navigate = useNavigate();
  const imageSize = {
    size: "small",
  };

  const inspectionDetails = (id) => {
    navigate(INSPECTION_REPORT, {
      state: { reportId: InspectionOpenItemDetail?.inspectionId },
    });
  };

  const observationBackBtn = {
    title: "Back to Observation",
    route: VIEW_OBSERVATION_LISTS,
  };

  const openItemBackBtn = {
    title: backRouteText ? backRouteText : "Back to Open Items",
    route: backRoute ? backRoute : OPEN_ITEMS_LIST,
  };
  const image = observationReportData?.files
    ? observationReportData?.files[0]?.filePath?.split("|")
    : "";
  const data = observationReportData?.insObservationDetails;
  return (
    <div className="observationOveride">
      <div className="commonOveride">
        <Box className="commoninfoWrap">
          <Box className="commoninfoBox">
            <Box className="commoninfoleft">
              <Typography className="titleKudos">Kudos</Typography>
              {origin === "Inspections" ? (
                <Typography
                  className="reportid"
                  mt={4}
                  onClick={() => inspectionDetails()}
                >
                  Inspection ID:{" "}
                  <span>{InspectionOpenItemDetail?.inspectionId}</span>
                  <span className="redirectPage">
                    {" "}
                    <RemoveRedEye />
                  </span>
                </Typography>
              ) : (
                <Typography className="reportid" mt={4}>
                  Observation ID: <span>{data?.insObservationId}</span>
                </Typography>
              )}

              {origin === "Inspections" ? (
                <Typography className="CaseId" mt={4}>
                  Kudos ID:{" "}
                  <span>
                    {InspectionOpenItemDetail?.inspectionOrReprimandId}
                  </span>
                </Typography>
              ) : (
                ""
              )}

              <Box className="columns" mt={4}>
                <Typography
                  className="listView"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  Appreciated Party:
                  {origin === "Inspections" ? (
                    <span className="font-w400">
                      {/* {InspectionOpenItemDetail?.listInvolvedParty?.map(
                        (user) => (
                          <div key={user?.key}>{user.userName}</div>
                        )
                      )} */}
                      {involvedPartyName}
                    </span>
                  ) : (
                    <span className="font-w400">
                      {data?.observedEmployeName}
                    </span>
                  )}{" "}
                </Typography>
              </Box>
              <Box className="columns">
                {origin === "Inspections" ? (
                  <Typography className="listView">
                    Date of Inspection:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.inspectionDate}
                    </span>
                  </Typography>
                ) : (
                  <Typography className="listView">
                    Date of Observation:
                    <span className="font-w400">{data?.observationDate}</span>
                  </Typography>
                )}
                {origin === "Inspections" ? (
                  <Typography className="listView">
                    Time of Inspection:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.inspectionTime}
                    </span>
                  </Typography>
                ) : (
                  <Typography className="listView">
                    Time of Observation:
                    <span className="font-w400">{data?.observationTime}</span>
                  </Typography>
                )}
              </Box>
              <Box className="columns">
                {origin === "Inspections" || origin === "Inspection" ? (
                  <>
                    <Typography className="listView">
                      Location:
                      <span className="font-w400">
                        {InspectionOpenItemDetail?.location}
                      </span>
                    </Typography>
                    {addedBy && (
                      <Typography className="listView">
                        Inspector Name:
                        <span className="font-w400">{addedBy}</span>
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography className="listView">
                    Location:
                    <span className="font-w400">{data?.location}</span>
                  </Typography>
                )}
              </Box>
              {origin === "Inspections" ? (
                <Box className="columns">
                  <Typography className="listView">
                    Asset Type:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.assetType === 1
                        ? "Project"
                        : InspectionOpenItemDetail?.assetType === 3
                        ? "Vehicle"
                        : InspectionOpenItemDetail?.assetType === 2
                        ? "Equipment"
                        : InspectionOpenItemDetail?.assetType.id === 4
                        ? "Custom Assets"
                        : ""}
                    </span>
                  </Typography>
                  <Typography className="listView">
                    {InspectionOpenItemDetail?.assetType === 1
                      ? "Project"
                      : InspectionOpenItemDetail?.assetType === 3
                      ? "Vehicle"
                      : InspectionOpenItemDetail?.assetType === 2
                      ? "Equipment"
                      : InspectionOpenItemDetail?.assetType.id === 4
                      ? "Custom Assets"
                      : ""}
                    :
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.assetName}
                    </span>
                  </Typography>
                </Box>
              ) : (
                <Box className="columns">
                  <Typography className="listView">
                    Project:
                    <span className="font-w400">{data?.jobSiteName}</span>
                  </Typography>
                </Box>
              )}

              <Box className="columns">
                {origin === "Inspections" ? (
                  <Typography className="listView">
                    Category:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.catName}
                    </span>
                  </Typography>
                ) : (
                  ""
                )}

                {origin === "Inspections" ? (
                  <Typography className="listView">
                    Sub Category:
                    <span className="font-w400">
                      {InspectionOpenItemDetail?.subCatName}
                    </span>
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              {origin === "Inspections" ? (
                <Typography className="listView ">
                  Question
                  {" " + InspectionOpenItemDetail?.questionSequenceNumber}:
                  <span className="blockView">
                    {parse(unescape(InspectionOpenItemDetail?.questionName))}
                  </span>{" "}
                </Typography>
              ) : (
                ""
              )}

              {origin === "Inspections" ? (
                <Typography className="listView ">
                  Inspection Details:
                  <span className="blockView">
                    {parse(unescape(InspectionOpenItemDetail?.comment))}
                  </span>{" "}
                </Typography>
              ) : (
                <Typography className="listView ">
                  Observation Details:
                  <span className="blockView">
                    {parse(unescape(data?.observedDetails))}
                  </span>{" "}
                </Typography>
              )}
              {origin === "Inspections" ? (
                <Typography className="listView ">
                  Media:
                  <span className="blockView">
                    {InspectionOpenItemDetail?.correctiveFiles?.map((list) => {
                      return (
                        <ViewImages
                          key={list?.key}
                          singleImagesid={list?.fileId}
                          singleImagespath={list?.filePath}
                          imageSize={imageSize}
                          singleImage={true}
                          multiImage={false}
                        />
                      );
                    })}
                  </span>
                </Typography>
              ) : (
                <Typography className="listView ">
                  Media:
                  <span className="blockView">
                    <ViewImages image={image} imageSize={imageSize} />
                  </span>
                </Typography>
              )}
            </Box>

            <Box className="commoninfoRight">
              {origin === "Inspections" ? (
                <Grid mt={4}>
                  <Typography className="incstatus">
                    Inspection Status
                  </Typography>
                  <Button
                    variant="contained"
                    className="statusbtn cursonDefault"
                  >
                    {observationStatusLog}
                  </Button>
                </Grid>
              ) : (
                <Grid mt={4}>
                  <Typography className="incstatus">
                    Observation Status
                  </Typography>
                  <Button
                    variant="contained"
                    className="statusbtn cursonDefault"
                  >
                    {data?.statusText}
                  </Button>
                </Grid>
              )}
            </Box>
          </Box>
        </Box>

        <Box className="escalatereprimand">
          <Grid mt={4}>
            {pageTitle === "Item Management" || origin === "Inspections" ? (
              <BackBtn dataBack={openItemBackBtn} className="equipmentBack" />
            ) : origin === "Inspections" || origin === "Observations" ? (
              <BackBtn dataBack={openItemBackBtn} className="equipmentBack" />
            ) : (
              <BackBtn
                dataBack={observationBackBtn}
                className="equipmentBack"
              />
            )}
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default KudosObservationReport;
