import { roleType } from "../constants/roleType";

export const createRole = (data) => ({
  type: roleType.POST_ROLE_PENDING,
  data,
});

export const createRoleSuccess = (data) => ({
  type: roleType.POST_ROLE_SUCCESS,
  data,
});

export const createRoleFailure = (error) => ({
  type: roleType.POST_ROLE_FAILURE,
  error,
});

export const updateRoleList = (data) => ({
  type: roleType.POST_UPDATE_ROLE_LIST_PENDING,
  data,
});

export const updateRoleListSuccess = (data) => ({
  type: roleType.POST_UPDATE_ROLE_LIST_SUCCESS,
  data,
});

export const updateRoleListFailure = (error) => ({
  type: roleType.POST_UPDATE_ROLE_LIST_FAILURE,
  error,
});

export const getRoleList = (data) => ({
  type: roleType.GET_ROLE_LIST_PENDING,
  data,
});

export const getRoleListSuccess = (data) => ({
  type: roleType.GET_ROLE_LIST_SUCCESS,
  data,
});

export const getRoleListFailure = (error) => ({
  type: roleType.GET_ROLE_LIST_FAILURE,
  error,
});

export const createAssignRole = (data) => ({
  type: roleType.POST_ASSIGN_ROLE_PENDING,
  data,
});

export const createAssignRoleSuccess = (data) => ({
  type: roleType.POST_ASSIGN_ROLE_SUCCESS,
  data,
});

export const createAssignRoleFailure = (data) => ({
  type: roleType.POST_ASSIGN_ROLE_FAILURE,
  data,
});

export const getRoleListDetsils = (data) => ({
  type: roleType.GET_ROLE_LIST_DETAILS,
  data,
});

export const getRoleListDetsilsSuccess = (data) => ({
  type: roleType.GET_ROLE_LIST_DETAILS_SUCCESS,
  data,
});

export const getRoleListDetailsFailure = (error) => ({
  type: roleType.GET_ROLE_LIST_DETAILS_FAILURE,
  error,
});

export const deleteRole = (roleId) => ({
  type: roleType.DELETE_ROLE,
  roleId,
});

export const deleteRoleSuccess = (data) => ({
  type: roleType.DELETE_ROLE_SUCCESS,
  data,
});

export const deleteRoleFailure = (error) => ({
  type: roleType.DELETE_ROLE_FAILURE,
  error,
});

export const clearRole = () => ({
  type: roleType.CLEAR_ROLE,
  data: {},
});

export const getUserInRoleList = (userId) => ({
  type: roleType.GET_USER_IN_ROLE_LIST,
  userId,
});

export const getUserInRoleListSuccess = (data) => ({
  type: roleType.GET_USER_IN_ROLE_LIST_SUCCESS,
  data,
});

export const getUserInRoleListFailure = (error) => ({
  type: roleType.GET_USER_IN_ROLE_LIST_FAILURE,
  error,
});
