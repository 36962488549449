import React, { useEffect } from "react";
import ConductJhaList from "../component/conductJhaList";
import { PAGE_SIZE } from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { CONDUCT_JHA } from "../../../constant/routeContant";

function ConductJhaListContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const conductJhaListData = useSelector(
    (state) => state.getConductJhaListData
  );

  const NavigateAddConductjha = () => {
    navigate(CONDUCT_JHA);
  };
  const defaultData = {
    page:1,
    pageSize: PAGE_SIZE,
    search: "",
    endDate: "",
    startDate: "",
    userId: 0,
    assetType:0,
  };
  const getConductJhaList = (data) => {
    dispatch(jhaAction.getConductJhaList(data));
  };

  useEffect(() => {
    getConductJhaList(defaultData);
  }, []);
  
  return (
  <ConductJhaList 
  getConductJhaList={getConductJhaList}
  conductJhaListData={conductJhaListData}
  NavigateAddConductjha={NavigateAddConductjha}
  isLoading={conductJhaListData.isLoading}
  />
  );
}

export default ConductJhaListContainer;
