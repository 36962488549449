import { stolenPropertyType } from "../constants/stolenPropertyType";

export const stolenProperty = (data) => ({
  type: stolenPropertyType.POST_STOLEN_PROPERTY_PENDING,
  data,
});

export const stolenPropertySuccess = (data) => ({
  type: stolenPropertyType.POST_STOLEN_PROPERTY_SUCCESS,
  data,
});

export const stolenPropertyFailure = (error) => ({
  type: stolenPropertyType.POST_STOLEN_PROPERTY_FAILURE,
  error,
});

export const getStolenProperty = (caseid, incidentid) => ({
  type: stolenPropertyType.GET_STOLEN_PROPERTY_PENDING,
  caseid,
  incidentid,
});

export const getStolenPropertySuccess = (data) => ({
  type: stolenPropertyType.GET_STOLEN_PROPERTY_SUCCESS,
  data,
});

export const getStolenPropertyFailure = (error) => ({
  type: stolenPropertyType.GET_STOLEN_PROPERTY_FAILURE,
  error,
});

export const updateStolenProperty = (data) => ({
  type: stolenPropertyType.PUT_STOLEN_PROPERTY_PENDING,
  data,
});

export const updateStolenPropertySuccess = (data) => ({
  type: stolenPropertyType.PUT_STOLEN_PROPERTY_SUCCESS,
  data,
});

export const updateStolenPropertyFailure = (error) => ({
  type: stolenPropertyType.PUT_STOLEN_PROPERTY_FAILURE,
  error,
});
export const clearStolenProperty = () => ({
  type: stolenPropertyType.CLEAR_STOLEN_PROPERTY,
  data: {},
});
