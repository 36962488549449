import * as toolBoxAction from "../actions/toolBoxAction";
import { toolBoxTalkType } from "../constants/toolBoxType";
import {
  assignAdminToinstructorToolBox,
  assignInstructorToUserToolBox,
  assignSelfInstructorToUserToolBox,
  getScheduleSafetyMeetingDetails,
  verifyAttendee,
  getVerifyAttendeeList,
  deleleScheduleToolBoxTalk,
  userMarkedCompleteToolBox,
  createExtraUser,
  getExtraUserList,
} from "../../services/toolBoxTalkService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* assignAdminToInstToolBoxSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(
      assignAdminToinstructorToolBox,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const casePage = toolBoxTalkType.CREATE_TOOL_ASSIGNPAGE;
    yield put(
      toolBoxAction.assignAdminToInstructorToolBoxSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message: error || "Assign Admin to Instructor Tool Box Talk Failed",
          };
    yield put(toolBoxAction.assignAdminToInstructorToolBoxFailure(data));
  }
}

export function* assignInstructorToUserToolBoxSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(
      assignInstructorToUserToolBox,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const casePage = toolBoxTalkType.CREATE_TOOL_ASSIGNUSERPAGE;
    yield put(
      toolBoxAction.assignInstructorToUserToolBoxSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message: error || "Assign  Instructor To User Tool Box Talk Failed",
          };
    yield put(toolBoxAction.assignInstructorToUserToolBoxFailure(data));
  }
}

export function* assignSelfInstructorToUserToolBoxSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(
      assignSelfInstructorToUserToolBox,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const casePage = toolBoxTalkType.CREATE_TOOL_SELFASSIGNUSERPAGE;
    yield put(
      toolBoxAction.assignSelfInstructorToUserToolBoxSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message:
              error || "Assign  Self Instructor To User Tool Box Talk Failed",
          };
    yield put(toolBoxAction.assignSelfInstructorToUserToolBoxFailure(data));
  }
}
export function* getScheduleMeetingDetailSaga(payload) {
  try {
    const { data, status, error } = yield call(
      getScheduleSafetyMeetingDetails,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    yield put({
      type: toolBoxTalkType.GET_SCHEDULE_MEETING_DETAILS_SUCCESS,
      data: data,
    });
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Schedule Meeting Detail Failed" };
    yield put({
      type: toolBoxTalkType.GET_SCHEDULE_MEETING_DETAILS_FAILED,
      error: data,
    });
  }
}

export function* verifyAttendeeSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(verifyAttendee, payload);
    if (!data && status !== 200) throw apiError(error);
    const casePage = toolBoxTalkType.CREATE_VERIFY_PAGE;
    yield put(
      toolBoxAction.verifyAttendeeUserSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message: error || "Verify attendee Failed",
          };
    yield put(toolBoxAction.verifyAttendeeUserFailure(data));
  }
}
export function* getVerifyAttendeeListSaga(params) {
  try {
    const { data, status, error } = yield call(getVerifyAttendeeList, params);

    if (!data && status !== 200) throw apiError(error);
    const toolboxpagePage = toolBoxTalkType.VERIFY_LIST_PAGE;
    yield put(
      toolBoxAction.getVerifyAttendeeUserListSuccess({
        toolboxpagePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get verrify   Failed" };
    yield put(toolBoxAction.getVerifyAttendeeUserListFailure(data));
  }
}

function* deleteScheduleToolBoxTalkSaga(payload) {
  try {
    const { data, status } = yield call(deleleScheduleToolBoxTalk, payload);
    const deletePage = toolBoxTalkType.TOOLBOXTALK_SCHEDULE_DELETE_PAGE;

    yield put(
      toolBoxAction.deleteScheduleToolBoxTalkSuccess({
        deletePage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Delete Schedule Tool Box Failed" };
    yield put(toolBoxAction.deleteScheduleToolBoxTalkFailure(data));
  }
}

export function* userMarkedCompleteToolBoxSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(
      userMarkedCompleteToolBox,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const casePage = toolBoxTalkType.CREATE_USER_MARKEDPAGE;
    yield put(
      toolBoxAction.UserMarkedCompletedToolBoxTalkSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message: error || "User marked complted Tool Box Talk Failed",
          };
    yield put(toolBoxAction.UserMarkedCompletedToolBoxTalkFailure(data));
  }
}

export function* getExtraUserListSaga(params) {
  try {
    const { data, status, error } = yield call(getExtraUserList, params);

    if (!data && status !== 200) throw apiError(error);
    const getExtraUserPage = toolBoxTalkType.GET_EXTRA_USER_PAGE;
    yield put(
      toolBoxAction.getExtraUserListSuccess({
        getExtraUserPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get extra User Failed" };
    yield put(toolBoxAction.getExtraUserListFailure(data));
  }
}

export function* createExtraUserSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(createExtraUser, payload);
    if (!data && status !== 200) throw apiError(error);
    const casePage = toolBoxTalkType.CREATE_EXTRA_USER_PAGE;
    yield put(
      toolBoxAction.createExtraUserSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create extra User Failed" };
    yield put(toolBoxAction.createExtraUserFailure(data));
  }
}

const myToolBoxTalkSaga = [
  takeLatest(
    toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_PENDING,
    assignAdminToInstToolBoxSaga
  ),
  takeLatest(
    toolBoxTalkType.GET_SCHEDULE_MEETING_DETAILS_PENDING,
    getScheduleMeetingDetailSaga
  ),
  takeLatest(
    toolBoxTalkType.POST_TOOL_BOX_ASSIGN_TO_USER_PENDING,
    assignInstructorToUserToolBoxSaga
  ),
  takeLatest(
    toolBoxTalkType.POST_TOOL_BOX_SELF_ASSIGN_TO_USER_PENDING,
    assignSelfInstructorToUserToolBoxSaga
  ),
  takeLatest(toolBoxTalkType.POST_VERIFY_ATTENDEE_PENDING, verifyAttendeeSaga),
  takeLatest(
    toolBoxTalkType.GET_VERIFY_ATTENDEE_LIST_PENDING,
    getVerifyAttendeeListSaga
  ),
  takeLatest(
    toolBoxTalkType.DELETE_SCHEDULE_TOOL_BOX_TALK_PENDING,
    deleteScheduleToolBoxTalkSaga
  ),
  takeLatest(
    toolBoxTalkType.POST_USER_MARKED_TOOL_BOX_TALK_PENDING,
    userMarkedCompleteToolBoxSaga
  ),
  takeLatest(toolBoxTalkType.GET_EXTRA_USER_LIST_PENDING, getExtraUserListSaga),
  takeLatest(toolBoxTalkType.POST_EXTRA_USER_PENDING, createExtraUserSaga),
];
export default myToolBoxTalkSaga;
