import * as categoryAction from "../actions/utilityCategoryAction";
import { categoryType } from "../constants/utilityCategoryType";
import {
  addcategory,
  getCategoryLists,
  activeCategory,
  getSupportCategoryLists,
  addSupportcategory,
  activeSupportCategory,
  getUtilityIncidentCategoryLists,
  addIncidentcategory,
  activeIncidentCategory,
  updateCategoryConfigurationCategory,
  updateSupportCategoryConfigurationCategory,
  updateIncidentCategoryConfigurationCategory,
} from "../../services/utilityCategoryService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* createCategorySaga(param) {
  const payload = param;

  try {
    const { data, status, error } = yield call(addcategory, payload);
    if (!data && status !== 200) throw apiError(error);
    const categoryPage = categoryType.CREATE_PAGE;

    yield put(
      categoryAction.utilityCategorySuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Category Failed" };
    yield put(categoryAction.utilityCategoryFailure(data));
  }
}

export function* getCategorySaga(paramsData) {
  try {
    const { data, status, error } = yield call(getCategoryLists, paramsData);
    if (!data && status !== 200) throw apiError(error);

    const categoryPage = categoryType.LIST_PAGE;
    yield put(
      categoryAction.getUtilityCategoryValueSuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Category List Failed" };
    yield put(categoryAction.getUtilityCategoryValueFailure(data));
  }
}

export function* activeCategorySaga(param) {
  const payload = param;

  try {
    const { data, status, error } = yield call(activeCategory, payload);
    if (!data && status !== 200) throw apiError(error);
    const categoryPage = categoryType.CREATE_PAGE;

    yield put(
      categoryAction.utilityActiveCategorySuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Category Failed" };
    yield put(categoryAction.utilityActiveCategoryFailure(data));
  }
}

export function* createSupporCategorySaga(param) {
  const payload = param;

  try {
    const { data, status, error } = yield call(addSupportcategory, payload);
    if (!data && status !== 200) throw apiError(error);
    const categoryPage = categoryType.CREATE_SUPPORT_PAGE;

    yield put(
      categoryAction.utilitySupportCategorySuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Support Category Failed" };
    yield put(categoryAction.utilitySupportCategoryFailure(data));
  }
}

export function* getSupportCategorySaga(paramsData) {
  try {
    const { data, status, error } = yield call(
      getSupportCategoryLists,
      paramsData
    );
    if (!data && status !== 200) throw apiError(error);

    const categoryPage = categoryType.LIST_SUPPORT_PAGE;
    yield put(
      categoryAction.getUtilitySupportCategoryValueSuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Support Category List Failed" };
    yield put(categoryAction.getUtilitySupportCategoryValueFailure(data));
  }
}

export function* activeSupportCategorySaga(param) {
  const payload = param;
  try {
    const { data, status, error } = yield call(activeSupportCategory, payload);
    if (!data && status !== 200) throw apiError(error);
    const categoryPage = categoryType.ACTIVE_SUPPORT_CREATE_PAGE;

    yield put(
      categoryAction.utilitySupportActiveCategorySuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Active Support Category Failed" };
    yield put(categoryAction.utilitySupportActiveCategoryFailure(data));
  }
}

export function* getUtilityIncidentCategorySaga(paramsData) {
  try {
    const { data, status, error } = yield call(
      getUtilityIncidentCategoryLists,
      paramsData
    );
    if (!data && status !== 200) throw apiError(error);

    const categoryPage = categoryType.LIST_INCIDENT_PAGE;
    yield put(
      categoryAction.getUtilityIncidentValueSuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Category List Failed" };
    yield put(categoryAction.getUtilityIncidentValueFailure(data));
  }
}

//createIncidentCategorySaga
export function* createIncidentCategorySaga(param) {
  const payload = param;

  try {
    const { data, status, error } = yield call(addIncidentcategory, payload);
    if (!data && status !== 200) throw apiError(error);
    const categoryPage = categoryType.CREATE_INCIDENT_PAGE;

    yield put(
      categoryAction.utilityIncidentCategorySuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Incident Category Failed" };
    yield put(categoryAction.utilityIncidentCategoryFailure(data));
  }
}

export function* activeIncidentCategorySaga(param) {
  const payload = param;
  console.log("payload===========", payload);
  try {
    const { data, status, error } = yield call(activeIncidentCategory, payload);
    if (!data && status !== 200) throw apiError(error);
    const categoryPage = categoryType.ACTIVE_INCIDENT_CREATE_PAGE;

    yield put(
      categoryAction.utilityIncidentActiveCategorySuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Active Inactive Category Failed" };
    yield put(categoryAction.utilityIncidentActiveCategoryFailure(data));
  }
}
//updateConfigurationCategorySaga
export function* updateConfigurationCategorySaga(param) {
  const payload = param;
  console.log("payload===========", payload);
  try {
    const { data, status, error } = yield call(
      updateCategoryConfigurationCategory,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const categoryPage = categoryType.UPDATE_CATEGORY_CONFIGURATION_PAGE;

    yield put(
      categoryAction.updateCategoryConfigurationSuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update category Configurion Category Failed" };
    yield put(categoryAction.updateCategoryConfigurationFailure(data));
  }
}
//updateSupportCategoryConfigurationCategory
export function* updateSupportConfigurationCategorySaga(param) {
  const payload = param;
  console.log("payload===========", payload);
  try {
    const { data, status, error } = yield call(
      updateSupportCategoryConfigurationCategory,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const categoryPage =
      categoryType.UPDATE_SUPPORT_CATEGORY_CONFIGURATION_PAGE;

    yield put(
      categoryAction.updateSupportCategoryConfigurationSuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update category Configurion Category Failed" };
    yield put(categoryAction.updateSupportCategoryConfigurationFailure(data));
  }
}
export function* updateIncidentConfigurationCategorySaga(param) {
  const payload = param;
  console.log("payload===========", payload);
  try {
    const { data, status, error } = yield call(
      updateIncidentCategoryConfigurationCategory,
      payload
    );
    if (!data && status !== 200) throw apiError(error);
    const categoryPage =
      categoryType.UPDATE_INCIDENT_CATEGORY_CONFIGURATION_PAGE;

    yield put(
      categoryAction.updateIncidentCategoryConfigurationSuccess({
        categoryPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message:
              error || "Update Incident category Configurion Category Failed",
          };
    yield put(categoryAction.updateIncidentCategoryConfigurationFailure(data));
  }
}
const myCaseSaga = [
  takeLatest(categoryType.POST_CATEGORY_PENDING, createCategorySaga),
  takeLatest(categoryType.GET_CATEGORY_PENDING, getCategorySaga),
  takeLatest(categoryType.POST_ACTIVE_CATEGORY_PENDING, activeCategorySaga),
  takeLatest(
    categoryType.POST_SUPPORT_CATEGORY_PENDING,
    createSupporCategorySaga
  ),
  takeLatest(categoryType.GET_SUPPORT_CATEGORY_PENDING, getSupportCategorySaga),
  takeLatest(
    categoryType.POST_ACTIVE_SUPPORT_CATEGORY_PENDING,
    activeSupportCategorySaga
  ),
  takeLatest(
    categoryType.GET_INCIDENT_CATEGORY_PENDING,
    getUtilityIncidentCategorySaga
  ),
  takeLatest(
    categoryType.POST_INCIDENT_CATEGORY_PENDING,
    createIncidentCategorySaga
  ),
  takeLatest(
    categoryType.POST_ACTIVE_INCIDENT_CATEGORY_PENDING,
    activeIncidentCategorySaga
  ),
  takeLatest(
    categoryType.UPDATE_CATEGORY_CONFIGURATION,
    updateConfigurationCategorySaga
  ),
  takeLatest(
    categoryType.UPDATE_SUPPORT_CATEGORY_CONFIGURATION,
    updateSupportConfigurationCategorySaga
  ),
  takeLatest(
    categoryType.UPDATE_INCIDENT_CATEGORY_CONFIGURATION,
    updateIncidentConfigurationCategorySaga
  ),
];

export default myCaseSaga;
