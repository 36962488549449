import * as reportAction from "../actions/reportAction";
import { reportType } from "../constants/reportType";
import {
  getConfiguredCredentialReport,
  getCredentialReport,
  getLookupCredentialReport,
} from "../../services/reportService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* getCredentialReportSaga(params) {
  try {
    const { data, status, error } = yield call(getCredentialReport, params);
    if (!data && status !== 200) throw apiError(error);
    const reportPage = reportType.REPORT_PAGE;
    yield put(
      reportAction.getCredentialReportSuccess({
        reportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Credential Report Failed" };
    yield put(reportAction.getCredentialReportFailure(data));
  }
}

export function* getLookupCredentialReportSaga(params) {
  try {
    const { data, status, error } = yield call(
      getLookupCredentialReport,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const reportPage = reportType.REPORT_PAGE;
    yield put(
      reportAction.getLookupCredentialReportSuccess({
        reportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Lookup Credential Report Failed" };
    yield put(reportAction.getLookupCredentialReportFailure(data));
  }
}

export function* getConfiguredCredentialReportSaga(params) {
  try {
    const { data, status, error } = yield call(
      getConfiguredCredentialReport,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const reportPage = reportType.REPORT_PAGE;
    yield put(
      reportAction.getConfiguredCredentialReportSuccess({
        reportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Configure Credential Report Failed" };
    yield put(reportAction.getConfiguredCredentialReportFailure(data));
  }
}

const myCredentialReportSaga = [
  takeLatest(reportType.GET_CREDENTIAL_REPORT_PENDING, getCredentialReportSaga),
  takeLatest(
    reportType.GET_LOOKUP_CREDENTIAL_REPORT_PENDING,
    getLookupCredentialReportSaga
  ),
  takeLatest(
    reportType.GET_CONFIGURED_CREDENTIAL_REPORT_PENDING,
    getConfiguredCredentialReportSaga
  ),
];

export default myCredentialReportSaga;
