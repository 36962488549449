import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import "./selectMenu.scss";
import FormHelperText from "@mui/material/FormHelperText";
import commonImages from "../../../assets";
import { OutlinedInput } from "@mui/material";
import selectIcon from "./selectmenuIcon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectMenu({
  listData,
  onChange,
  value,
  name,
  isError = false,
  errorMessage,
  placeholder,
  disabledDefault = true,
  editMode = false,
  disabledValue,
  addclientpage = false,
  disableSelect = false,
}) {
  return (
    <div className="overideSelectMenu">
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        onChange={onChange}
        size="small"
        displayEmpty={true}
        MenuProps={MenuProps}
        disabled={disableSelect}
        renderValue={(value) =>
          value?.length ? (
            Array.isArray(value) ? (
              value.join(", ")
            ) : (
              value
            )
          ) : (
            <div className="placeholder">Please Select</div>
          )
        }
        className="selectBox"
        name={name}
        IconComponent={selectIcon}
        //   IconComponent={() => (
        //     <ExpandMoreOutlinedIcon size="large" className="expIcon" />
        //   )}
        //
      >
        {listData &&
          listData?.map((name) => (
            <MenuItem
              key={name.key}
              value={name.value}
              className="selectutilityitems"
              disabled={
                editMode && addclientpage
                  ? disabledValue?.includes(name?.value)
                  : false
              }
            >
              <img
                // style={{marginRight:"5px"}}
                className="multiCheck"
                title={
                  value?.indexOf(name.value) > -1
                    ? "tickCheckbox"
                    : "untickCheckbox"
                }
                alt=""
                src={
                  value?.indexOf(name.value) > -1
                    ? commonImages.check
                    : commonImages.noCheck
                }
              />
              <ListItemText primary={name.value} />
            </MenuItem>
          ))}
      </Select>
      {isError && value.length === 0 && (
        <FormHelperText className="errorText">
          {errorMessage ? errorMessage : "Required"}
        </FormHelperText>
      )}
    </div>
  );
}
