import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const ViewInspectionProof = ({
  inspectionDetail,
  handlePrint,
  inspectionRowDetail,
}) => {
  return (
    <>
      <div className="overReportHeader">
        <Grid container className="reportHeader ">
          <Grid md={6} xs={12} className="reportHeaderLeft">
            <div className="reportTitle">
              Report ID: {inspectionRowDetail?.reportId}
            </div>
          </Grid>
          <Grid md={6} xs={12} className="reportHeaderLeft right">
            <div className="reportTitle right">
              {inspectionRowDetail?.inspectionType}
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="basicOveride">
        <Box className="basicinfoWrap">
          <Box className="basicinfoBox">
            <Box className="basicinfoleft sw">
              <div className="info-report">
                <Typography className="title">Basic Information:</Typography>
                <Typography className="title report-id">
                  Report ID:
                  <span className="title report-id">
                    {" "}
                    {inspectionRowDetail?.reportId}
                  </span>
                </Typography>
              </div>

              <Box className="datetime dynmicFeild">
                {inspectionRowDetail?.project && (
                  <Typography className="listView list-right">
                    Project:
                    <span>{inspectionRowDetail?.project}</span>
                  </Typography>
                )}

                {inspectionRowDetail?.projectNumber && (
                  <Typography className="listView">
                    Project Number:
                    <span>{inspectionRowDetail?.projectNumber}</span>
                  </Typography>
                )}
                {inspectionRowDetail?.customer && (
                  <Typography className="listView">
                    Client:
                    <span>{inspectionRowDetail?.customer}</span>
                  </Typography>
                )}

                {inspectionRowDetail?.dateReported && (
                  <Typography className="listView">
                    Date of Repoted:
                    <span>{inspectionRowDetail?.dateReported}</span>
                  </Typography>
                )}
              </Box>

              <Box className="datetime">
                <Typography className="listView">
                  Frequency:
                  <span>{inspectionRowDetail?.frequency}</span>
                </Typography>
                <Typography className="listView">
                  Inspection Type:
                  <span>{inspectionRowDetail?.inspectionType}</span>
                </Typography>
              </Box>
            </Box>
            <Box className="basicinfoRight sw"></Box>
          </Box>
        </Box>
      </div>

      <Grid container>
        <Grid md={10} xs={12}>
          <div className="viewProof">
            <div className="viewProofImages">
              {inspectionDetail?.files?.map((file) => {
                return (
                  <div className="viewProofImage" key={file?.fileId}>
                    <img src={file?.filePath} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid md={10} xs={12}>
          <div className="bottomBox">
            <p className="mendetryText">
              "I certify under penalty of law that this report and all
              attachments were prepared under my direction or supervision in
              accordance with a system designed to assure that certified
              personnel properly gather and evaluate the information submitted.
              Based on my inquiry of the person or persons who manage the
              system, or those persons directly responsible for gathering the
              information, the information submitted is, to the best of my
              knowledge and belief, true, accurate, and complete. I am aware
              that there are significant penalties for submitting false
              information, including the possibility of fine and imprisonment
              for knowing violations."
            </p>

            <div className="reportSignatureImg">
              <img src={inspectionDetail?.signature} alt="" />
            </div>
            <div>
              <Button
                onClick={handlePrint}
                variant="contained"
                startIcon={<DownloadIcon className="downloadIcon" />}
                className="downloadpdf sw"
              >
                Download as PDF
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewInspectionProof;
