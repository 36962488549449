import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

function ViewUser({ getUserListDetsilsData }) {
  const location = useLocation();
  const userDetails = location?.state?.userDetails;
  const data = getUserListDetsilsData;

  return (
    <>
      <div className="viewOverideContact">
        <div className="commonOveride">
          <Box className="commoninfoWrap">
            <Box className="commoninfoBox">
              <Box className="commoninfoleft">
                <Typography className="title"> User Details:</Typography>
                <Box className="columns">
                  <Typography className="listView">
                    First Name:
                    <span className="font-w400">{data?.firstname}</span>
                  </Typography>
                  <Typography className="listView">
                    Last Name:
                    <span className="font-w400">{data?.lastname}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Job Title:
                    <span className="font-w400">{userDetails?.jobtitle}</span>
                  </Typography>
                  <Typography className="listView">
                    Division:
                    {userDetails?.divisions?.map((item) => (
                      <span className="font-w400">{item?.value}</span>
                    ))}
                  </Typography>
                </Box>

                <Box className="columns">
                  <Typography className="listView">
                    Role:
                    {data?.safetyRoles?.map((item) => (
                      <span className="font-w400">{item?.value}</span>
                    ))}
                  </Typography>
                  <Typography className="listView">
                    Phone:
                    <span className="font-w400">{data?.phonenumber}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Email:
                    <span className="font-w400">{data?.email}</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}

export default ViewUser;
