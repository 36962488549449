export const dashboardType = {
  UPDATE_USER_DASHBOARD_CART_PENDING:
    "[UPDATE_USER_DASHBOARD_CART_PENDING][REQUEST] UpdateUserDashboardCart Requested",
  UPDATE_USER_DASHBOARD_CART_FAILURE:
    "[UPDATE_USER_DASHBOARD_CART_FAILURE][RESPONSE] UpdateUserDashboardCart Failed",
  UPDATE_USER_DASHBOARD_CART_SUCCESS:
    "[UPDATE_USER_DASHBOARD_CART_SUCCESS][RESPONSE] UpdateUserDashboardCart Successfull",
  UPDATE_USER_DASHBOARD_CART_PAGE: "UpdateUserDashboardCartPage",

  GET_DASHBOARD_CART_LIST_PENDING:
    "[GET_DASHBOARD_CART_LIST_PENDING][REQUEST] Get DASHBOARD CART LIST Requested",
  GET_DASHBOARD_CART_LIST_FAILURE:
    "[GET_DASHBOARD_CART_LIST_FAILURE][RESPONSE] DASHBOARD CART LIST Failed",
  GET_DASHBOARD_CART_LIST_SUCCESS:
    "[GET_DASHBOARD_CART_LIST_SUCCESS][RESPONSE] GET DASHBOARD CART LIST Successfull",
  DASHBOARD_CART_LIST_PAGE: "DASHBOARD CART LIST Page",

  GET_USER_DASHBOARD_CART_LIST_PENDING:
    "[GET_USER_DASHBOARD_CART_LIST_PENDING][REQUEST] Get User DASHBOARD CART LIST Requested",
  GET_USER_DASHBOARD_CART_LIST_FAILURE:
    "[GET_USER_DASHBOARD_CART_LIST_FAILURE][RESPONSE] Get User DASHBOARD CART LIST Failed",
  GET_USER_DASHBOARD_CART_LIST_SUCCESS:
    "[GET_USER_DASHBOARD_CART_LIST_SUCCESS][RESPONSE] GET User DASHBOARD CART LIST Successfull",
  USER_DASHBOARD_CART_LIST_PAGE: "USER DASHBOARD CART LIST Page",
};
