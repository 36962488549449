import React, { useEffect, useState } from "react";
import ToolBoxTalkDetail from "../component/toolBoxTalkDetail/toolBoxTalkDetail";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { useNavigate } from "react-router-dom";
import { API_STATUS, MODULES_NAME, USER_ID } from "../../../constant/constant";
import { usersAccess } from "../../../utils/rolesHelper";
import SuccessScreen from "../../../library/custom/success/successScreen";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { TOOL_BOX_LIST } from "../../../constant/routeContant";

const ToolBoxTalkDetailContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const toolboxId = location?.state?.toolboxTalkId;
  const scheduleId = location?.state?.scheduleId;
  const assignedOn = location?.state?.assignedOn;
  const assignedOnAdmin = location?.state?.assignedOnAdmin;
  const toolboxtalkuyserlistPage = location?.state?.toolboxtalkuyserlist;
  const Tabvalue = location?.state?.tabvalue;
  const assignInstructorsUsersId = location?.state?.assignInstructorsUsersId;
  const navigate = useNavigate();
  const access = usersAccess(MODULES_NAME.lms);
  const showLoader = useSelector((state) => state.getToolBoxTalkView.isLoading);
  const getToolBoxTalkDetail = useSelector((state) => state.getToolBoxTalkView);

  const deleteToolBoxTalkData = useSelector((state) => state.deleteToolBoxTalk);
  const userMarkedCompletedToolBox = useSelector(
    (state) => state.userMarkedCompletedToolBox
  );
  const userMarkedStatus = userMarkedCompletedToolBox?.status;
  const userMarkedIsloading = userMarkedCompletedToolBox?.isLoading;

  useEffect(() => {
    if (deleteToolBoxTalkData?.status === API_STATUS.SUCCESS) {
      dispatch(toolboxAction.clearToolBoxTalk());
      navigate(TOOL_BOX_LIST, {
        state: { toolboxId: toolboxId },
      });
    }
  }, [deleteToolBoxTalkData]);

  useEffect(() => {
    const data = {
      assignInstructorsUsersId: assignInstructorsUsersId,
      toolboxId: toolboxId,
    };
    dispatch(toolboxAction.getToolBoxTalkDetails(data));
  }, []);

  useEffect(() => {
    const dataSchedule = {
      scheduleDate: assignedOnAdmin || "",
      scheduleId: scheduleId ? scheduleId : "",
      //scheduleDate: "",
      userId: USER_ID(),
      assignType: "",
    };
    dispatch(toolboxAction.getScheduleSafetyMeetingDetails(dataSchedule));
  }, []);
  const getScheduleMeetingDetailData = useSelector(
    (state) => state.getScheduleMeetingDetail
  );
  const instructorUserContractors = getScheduleMeetingDetailData?.users;

  let found = instructorUserContractors?.some((el) => el.key == USER_ID());
  if (found == undefined) {
    found = false;
  }
  const successScree = (id, message) => {
    return (
      <SuccessScreen
        isUpdate={message}
        route="/safety/meeting/user"
        id={id}
        label2="Congrats! You have successfully completed the Safety Meeting"
        label1="Safety ID"
        label4="Safety in "
      ></SuccessScreen>
    );
  };
  const deleteToolBoxTalks = (toolboxId) => {
    dispatch(toolboxAction.deleteToolBoxTalk(toolboxId));
  };

  const userMarkedToolBoxTalks = (data) => {
    dispatch(toolboxAction.UserMarkedCompletedToolBoxTalk(data));
  };

  console.log("access--------------------", access);
  return (
    <>
      {showLoader && <Loader />}
      {userMarkedIsloading && <Loader />}
      {userMarkedStatus == API_STATUS.SUCCESS ? (
        successScree(toolboxId, "Safety Meeting Completed Successfully")
      ) : (
        <ToolBoxTalkDetail
          access={access}
          getToolBoxTalkDetail={getToolBoxTalkDetail}
          toolboxId={toolboxId}
          deleteToolBoxTalks={deleteToolBoxTalks}
          scheduleId={scheduleId}
          isfound={found}
          instructorUserContractors={getScheduleMeetingDetailData}
          userMarkedToolBoxTalks={userMarkedToolBoxTalks}
          assignedOn={assignedOn}
          assignedOnAdmin={assignedOnAdmin}
          assignInstructorsUsersId={assignInstructorsUsersId}
          toolboxtalkuyserlistPage={toolboxtalkuyserlistPage}
          Tabvalue={Tabvalue}
        />
      )}
    </>
  );
};

export default ToolBoxTalkDetailContainer;
