import React from "react";
import { InputField } from "../../../../library/custom/textBox/InputField";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import AllAssetsUserSearch from "../../container/allAssetsUserSearch";

function ViewUserByRole({
  UserDatails,
  projectsListData,
  getProjectListData,
  isLoading,
  equipmentList,
  getEquipmentList,
  isEquipmentLoading,
  vehicleList,
  getVehicleList,
  isVehicleLoading,
  assetList,
  isAssetLoading,
  getAssetListData,
  tabClick,
  divisionId,
  loading,
  GroupListData,
  noOfRecords,
  getuserGroupLists,
  loadingGroup,
  autoSearchDataLoading,
  getSwInspectionList,
  getSwInspectionListData,
  UserDatail,
}) {
  return (
    <>
      <Grid container>
        <Grid item lg={6} xs={12} sm={12} md={6} mt={1} textAlign="left">
          <div className="heading_style">Search Users by Roles</div>
        </Grid>
      </Grid>
      <Grid container mt={2} spacing={3}>
        <Grid item lg={4} xs={12} sm={8} md={6}>
          <FormControl fullWidth>
            <InputField
              type="text"
              label="User Name"
              value={UserDatails?.userName + " " + UserDatails?.lastName}
              disabled={true}
            />
          </FormControl>
        </Grid>
        <Grid item lg={4} xs={12} sm={8} md={6}>
          <FormControl fullWidth>
            <InputField
              type="text"
              label="Client Name"
              value={UserDatails?.clientName}
              disabled={true}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container mt={2} spacing={3}>
        <Grid item lg={4} xs={12} sm={8} md={6}>
          <FormControl fullWidth>
            <InputField
              type="text"
              label="Role Name"
              value={UserDatails?.userRole}
              disabled={true}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container mt={2}>
        <AllAssetsUserSearch
          projectsListData={projectsListData}
          getProjectListData={getProjectListData}
          isLoading={isLoading}
          equipmentList={equipmentList}
          getEquipmentList={getEquipmentList}
          isEquipmentLoading={isEquipmentLoading}
          vehicles={vehicleList}
          getVehicleList={getVehicleList}
          isVehicleLoading={isVehicleLoading}
          assetList={assetList}
          isAssetLoading={isAssetLoading}
          getAssetListData={getAssetListData}
          tabClick={tabClick}
          divisionId={divisionId}
          loading={loading}
          GroupListData={GroupListData}
          noOfRecords={noOfRecords}
          getuserGroupLists={getuserGroupLists}
          loadingGroup={loadingGroup}
          isDivisionDropdown={false}
          autoSearchDataLoading={autoSearchDataLoading}
          getSwInspectionList={getSwInspectionList}
          getSwInspectionListData={getSwInspectionListData}
          UserDatail={UserDatail}
        />
      </Grid>
    </>
  );
}

export default ViewUserByRole;
