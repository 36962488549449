import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import "./toolBoxTalkList.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../tabPanel/tabPanel";
import { useSelector, useDispatch } from "react-redux";
import {
  COLUMNS_ADMIN_TOOLBOXTALK_VIEW,
  COLUMNS_TOOLBOXTALKADMIN_VIEW,
} from "../../courseConstant";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";

import {
  TOOL_BOX_ASSIGN,
  TOOL_BOX_DETAIL,
  TOOL_BOX_VERIFY_ATTENDEES,
  TOOL_BOX_ASSIGN_USER,
  TOOL_BOX_USERCOUNT_ASSIGN,
} from "../../../../constant/routeContant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { PAGE_SIZE, ORG_ID, USER_ID } from "../../../../constant/constant";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import DialogBox from "../../../../library/common/DialogBox";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import * as toolboxAction from "../../../../redux/actions/toolBoxAction";
import { useLocation } from "react-router-dom";
import FilterCommonStatusSelect from "../../../admin/shared/FilterCommonStatusSelect";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const ToolBoxTalkAdminList = (props) => {
  const location = useLocation();
  const backTabId = location?.state?.backTabId;
  const [page, setPage] = useState(1);
  const [value, setValue] = React.useState(backTabId ? backTabId : 0);
  const dispatch = useDispatch();
  const ToolBoxTalkListAdminData = props?.ToolBoxTalkListData;
  const getToolBoxTalkListAdmin = props?.getToolBoxTalkListAdmin;
  const deleteAdminToolBoxTalks = props?.deleteAdminToolBoxTalks;
  const getToolBoxTalkList = props?.getToolBoxTalkList;
  const ToolBoxTalkListDataSchedule = props?.ToolBoxTalkListDataSchedule;

  const handleChange = (event, newValue) => {
    if (value === 0) {
      setPage(1);
      setValue(newValue);
      const { searchType } = filter;
      const data = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: "",
        catIds: "",
        userId: USER_ID(),
        tabId: "0",
      };
      getToolBoxTalkList(data);
    } else {
      setPage(1);
      setValue(newValue);
      const { searchType } = filter;
      const data = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: "",
        catIds: "",
        userId: "",
      };
      getToolBoxTalkListAdmin(data);
    }
  };
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { isLoading } = useSelector((state) => state.getToolBoxTalkList);
  const categoryListData = props?.categoryListData;
  const noRecourdCount = ToolBoxTalkListAdminData?.itemsCount;
  const noRecourdScheduleCount = ToolBoxTalkListDataSchedule?.itemsCount;

  const handleOnCellClick = (params) => {
    let toolboxTalkId = params?.id;
    let fieldName = params?.field;
    let scheduleId = params?.row?.id;
    let finalid;
    if (fieldName === "titleSafetyMeeting") {
      finalid = "true";
    } else {
      finalid = "false";
    }

    if (fieldName === "titleSafetyMeeting") {
      dispatch(toolboxAction.clearToolBoxTalk());
      navigate(TOOL_BOX_DETAIL, {
        state: {
          fieldId: finalid,
          toolboxTalkId: toolboxTalkId,
          scheduleId: scheduleId,
        },
      });
    }
  };

  const handleOnCellClickSchedule = (params) => {
    let toolboxTalkId = params?.row?.toolboxTalkId;
    let fieldName = params?.field;
    let scheduleId = params?.row?.id;
    let assignedOnAdmin = params?.row?.assignedOn;
    let title = params?.row?.titleSafetyMeeting;
    let finalid;
    if (
      fieldName === "titleSafetyMeeting" ||
      fieldName === "totaleAssignedTo" ||
      fieldName === "totalUserCompetedBy"
    ) {
      finalid = "true";
    } else {
      finalid = "false";
    }

    if (fieldName === "titleSafetyMeeting") {
      dispatch(toolboxAction.clearToolBoxTalk());
      navigate(TOOL_BOX_DETAIL, {
        state: {
          fieldId: finalid,
          toolboxTalkId: toolboxTalkId,
          scheduleId: scheduleId,
          tabvalue: value,
        },
      });
    }
    if (fieldName === "totaleAssignedTo") {
      navigate(TOOL_BOX_USERCOUNT_ASSIGN, {
        state: {
          fieldId: finalid,
          flag: "0",
          scheduleId: scheduleId,
          scheduleDate: assignedOnAdmin,
          title: title,
          tabvalue: value,
        },
      });
    }
    if (fieldName === "totalUserCompetedBy") {
      navigate(TOOL_BOX_USERCOUNT_ASSIGN, {
        state: {
          fieldId: finalid,
          flag: "1",
          scheduleId: scheduleId,
          scheduleDate: assignedOnAdmin,
          title: title,
          tabvalue: value,
        },
      });
    }
    if (fieldName === "totalUserNotCompetedBy") {
      navigate(TOOL_BOX_USERCOUNT_ASSIGN, {
        state: {
          fieldId: finalid,
          flag: "2",
          scheduleId: scheduleId,
          scheduleDate: assignedOnAdmin,
          title: title,
          tabvalue: value,
        },
      });
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteItem = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const deletehandle = () => {
    setOpenModal(false);

    const data = {
      toolboxTalkId: selectedRow?.id,
    };
    deleteAdminToolBoxTalks(data);
  };
  const deletehandleSchedule = () => {
    setOpenModal(false);
    const data = {
      scheduleId: selectedRow?.id,
      scheduleDate: selectedRow?.assignedOn,
    };

    props?.deleteScheduleToolBoxTalk(data);
  };
  const columns = React.useMemo(() => {
    const headers = [...COLUMNS_ADMIN_TOOLBOXTALK_VIEW];
    headers.push({
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={noRecourdCount ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={ToolBoxTalkListAdminData?.saftyMeetingList ?? []}
          fileNameXL={"SafetyMeetings"}
        />
      ),
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            usingComp={"Meeting"}
            ScheduleMeetings
            deleted={rows.row.isPublished ? "" : "deleted"}
            ScheduleAttendeeLabel={"Schedule Meeting"}
            deleteLabel={"Delete Meeting"}
            scheduleMeetingOnclick={() => handleScheduleMeetingOnclick(rows)}
            deleteOnClick={() =>
              checkPrivileges([12, 90]) ? deleteItem(rows?.row) : ""
            }
          />
        );
      },
    });
    return headers;
  }, [ToolBoxTalkListAdminData?.saftyMeetingList, PAGE_SIZE, navigate]);
  const handleClickAssign = (data) => {
    navigate(TOOL_BOX_ASSIGN_USER, {
      state: {
        tabId: value,
        scheduleId: data?.row?.id,
        assignedOnAdmin: data?.row?.assignedOn,
      },
    });
  };
  const handleViewAttendeeOnClick = (row) => {
    const assignedOn = row?.assignedOn;
    const toolboxTalkId = row?.toolboxTalkId;
    const scheduleId = row?.id;

    navigate(TOOL_BOX_VERIFY_ATTENDEES, {
      state: {
        assignedOn: assignedOn,
        toolboxTalkId: toolboxTalkId,
        scheduleId: scheduleId,
      },
    });
  };
  const columnsSchedule = React.useMemo(() => {
    const headers = [...COLUMNS_TOOLBOXTALKADMIN_VIEW];

    headers.push({
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={noRecourdScheduleCount ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={ToolBoxTalkListDataSchedule?.saftyMeetingList ?? []}
          fileNameXL={"SafetyMeetings"}
        />
      ),
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            usingComp={"Meeting"}
            VerifyAttendees
            viewAttendeeLabel={"Verify Attendees"}
            deleted={rows.row.isPublished ? "" : "deleted"}
            deleteLabel={"Delete Meeting"}
            viewAttendee
            AssignAttendees
            assignLabel={"Assign Attendees"}
            AssignAttendeeOnClick={() => handleClickAssign(rows)}
            viewOnAttendeeClick={(row) => handleViewAttendeeOnClick(row)}
            deleteOnClick={() =>
              checkPrivileges([12, 90]) ? deleteItem(rows?.row) : ""
            }
          />
        );
      },
    });
    return headers;
  }, [ToolBoxTalkListDataSchedule?.saftyMeetingList, PAGE_SIZE, navigate]);

  const handleScheduleMeetingOnclick = (rows) => {
    const toolboxid = rows?.id;
    const id = rows?.row?.id;
    const titleSafetyMeeting = rows?.row?.titleSafetyMeeting;

    navigate(TOOL_BOX_ASSIGN, {
      state: {
        id: id,
        toolboxid: toolboxid,
        titleSafetyMeeting: titleSafetyMeeting,
        editMode: true,
      },
    });
  };

  const handlePagignation = (newPage) => {
    if (value == 0) {
      setPage(newPage + 1);
      getToolBoxTalkListAdmin({
        pageNumber: newPage + 1,
        pageSize: PAGE_SIZE,
        search: search,
        userId: "",
        organisationId: ORG_ID(),
      });
    } else {
      setPage(newPage + 1);
      getToolBoxTalkList({
        pageNumber: newPage + 1,
        pageSize: PAGE_SIZE,
        search: search,
        userId: "",
        organisationId: ORG_ID(),
      });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const handleSearch = (data1) => {
    setAnchorEl(null);
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: search,

      userId: "",
      organisationId: ORG_ID(),
      categoryIds: filter?.searchType[0]?.key,
    };

    if (value == 1) {
      getToolBoxTalkList(data);
    } else {
      getToolBoxTalkListAdmin(data);
    }
  };

  const [filter, setFilter] = useState({ searchType: [] });

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };
  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: searchText ? searchText : "",
      catIds: filter?.searchType[0]?.key ? filter?.searchType[0]?.key : "",
      userId: "",
    };
    if (value == 1) {
      getToolBoxTalkList(data);
    } else {
      getToolBoxTalkListAdmin(data);
    }
  };
  ToolBoxTalkListDataSchedule?.saftyMeetingList.map((Dta, index) => {
    return (Dta["safetyid"] = (page - 1) * 5 + index + 1);
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClear = () => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      prevStates.searchType = [];
      return prevStates;
    });

    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: "",
      userId: "",
      organisationId: ORG_ID(),
      categoryIds: "",
    };

    if (value == 1) {
      getToolBoxTalkList(data);
    } else {
      getToolBoxTalkListAdmin(data);
    }
  };
  return (
    <>
      <div className="toolboxList">
        <Grid container>
          <Grid lg={6} xs={12}>
            {openModal && value === 0 && (
              <DialogBox
                open={openModal}
                rows={[]}
                header={"Confirm Deletion"}
                deletehandle={deletehandle}
                handleCloseModal={() => setOpenModal(false)}
                content={"Are you sure you want to delete this Safety Meeting?"}
                view
              />
            )}

            {openModal && value === 1 && (
              <DialogBox
                open={openModal}
                rows={[]}
                header={"Confirm Deletion"}
                deletehandle={deletehandleSchedule}
                handleCloseModal={() => setOpenModal(false)}
                content={
                  "Are you sure you want to delete this Schedule Safety Meeting?"
                }
                view
              />
            )}
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tabcustom"
                  icon={
                    value === 0 ? (
                      <div className="totalV">
                        {value === 0 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="All Safety Meetings"
                />
                <Tab
                  className="tabcustom"
                  icon={
                    value === 1 ? (
                      <div className="totalV">
                        {value === 1 ? noRecourdScheduleCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="Scheduled Meetings"
                />
              </Tabs>
            </Box>
          </Grid>
          <Grid lg={6} xs={12} mt={1}>
            <div className="filtertoolbox">
              <div className="filter_of_caselisttoolBox">
                <div className="searchcaseid">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div>
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={search}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>
                <div className="filter_btn">
                  <button
                    aria-describedby={id}
                    variant="contained"
                    type="button"
                    name="btn"
                    onClick={(e) => handleClick(e)}
                  >
                    <FilterListIcon id="icn" /> Filters
                  </button>

                  <FilterCommonStatusSelect
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleApply={handleSearch}
                    handleClear={handleClear}
                    filter={filter}
                    statusType={categoryListData ?? []}
                    onChangeType={onChangeType}
                    //  statusIds={statusIds}
                    filterSelect={false}
                    filterStatus={true}
                    resetBtn={true}
                    label="Category"
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <TabPanel className="tabPanel" value={value} index={1}>
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.safetyid}
                  rows={ToolBoxTalkListDataSchedule?.saftyMeetingList ?? []}
                  rowCount={ToolBoxTalkListDataSchedule?.itemsCount ?? 0}
                  columns={columnsSchedule}
                  //loading={isLoading}
                  loading={props?.showLoaderSchedule}
                  page={page}
                  pagination
                  pageSize={PAGE_SIZE}
                  rowsPerPageOptions={[PAGE_SIZE]}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  onClick={handleOnCellClickSchedule}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={0}>
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.id}
                  rows={ToolBoxTalkListAdminData?.saftyMeetingList ?? []}
                  rowCount={ToolBoxTalkListAdminData?.itemsCount ?? 0}
                  columns={columns}
                  loading={props?.showLoader}
                  page={page}
                  pagination
                  pageSize={PAGE_SIZE}
                  rowsPerPageOptions={[PAGE_SIZE]}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  onClick={handleOnCellClick}
                />
              </div>
            </div>
          </TabPanel>
        </Grid>
      </div>
    </>
  );
};

export default ToolBoxTalkAdminList;
