import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Popover,
} from "@mui/material";
import React from "react";
import Buttons from "../../../library/custom/button/Button";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import "./FilterCommonStatusSelect.scss"

const Filter = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleSearch,
  handleChange,
  handleApply,
  statusState,
  variantAsset,
  handleClear
}) => {
  return (
    <div className="overridePopover" style={{ marginTop: "10px" }}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {variantAsset && (
          <Card className="filterlist_of_cases">
            <CardContent>
              <Box className="checkboxStyleOverrides" sx={{ pb: 2 }} >
                <FormControl component="fieldset" variant="standard">
                  <FormLabels label="Status" isRequired={false} />
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={statusState.active}
                          onChange={handleChange}
                          name="active"
                        />
                      }
                      label="Active"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={statusState.inActive}
                          onChange={handleChange}
                          name="inActive"
                        />
                      }
                      label="Inactive"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </CardContent>
            <CardActions>
            <Buttons
                primary={true}
                label={"Reset"}
                onClick={(e) => handleClear(e)}
                size="small"
              />
              <Buttons
                primary={true}
                label={"Apply"}
                onClick={(e) => handleApply(e)}
                size="small"
              />
            </CardActions>
          </Card>
        )}
      </Popover>
    </div>
  );
};

export default Filter;
