import React, { useEffect, useState } from "react";
import AddAdminCredential from "../component/addAdminCredential";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import * as credentialAction from "../../../redux/actions/credentialAction";
import { useLocation } from "react-router-dom";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { ADMIN_CREDENTIAL } from "../../../constant/routeContant";
import { API_STATUS } from "../../../constant/constant";

function AddAdminCredentialContainer() {
  const [successScreens, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const allUsers = useSelector((state) => state.allUsers);
  const editMode = location?.state?.editMode;
  const credentialId = location?.state?.credentialId;

  const addUserCredential = (data) => {
    setIsSuccess(true);
    if (editMode) {
      dispatch(credentialAction.updateUserCredential(data));
    } else {
      dispatch(credentialAction.createAdminCredential(data));
    }
  };
  const getCredentialViewDetsilsData = useSelector(
    (state) => state.getCredentialViewDetsilsData
  );

  const credentialListData = useSelector(
    (state) => state.getSearchCredentialData
  );

  const showLoader = useSelector(
    (state) =>
      state.createAdminCredentialData.isLoading ||
      state.updateUserCredentialData.isLoading ||
      state.getCredentialViewDetsilsData.isLoading
  );

  const updateUserCredentialData = useSelector(
    (state) => state.updateUserCredentialData
  );
  const updateUserCredentialDataStatus = useSelector(
    (state) => state.updateUserCredentialData.status
  );

  const createAdminCredentialData = useSelector(
    (state) => state.createAdminCredentialData
  );

  const createCredentialDataStatus = useSelector(
    (state) => state.createAdminCredentialData.status
  );

  const autoSearchDataLoading =
    allUsers.isLoading || credentialListData.isLoading;

  const getCredentials = (data) => {
    dispatch(credentialAction.getSearchCredentialList(data));
  };

  const searchUsers = (searchText, type, divisionId) => {
    dispatch(userAction.searchAllUsers(searchText, type, divisionId));
  };
  const searchOption = useSelector((state) => state.users.data);

  const getCredentialViewDetsils = () => {
    dispatch(credentialAction.getCredentialViewDetsils(credentialId));
  };

  useEffect(() => {
    if (editMode && credentialId) {
      getCredentialViewDetsils();
    }
  }, []);

  useEffect(() => {
    setIsSuccess(false);
  }, []);
  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={" "}
        isUpdate={
          editMode
            ? "Credential Updated Successfully"
            : " Credential  Added Successfully"
        }
        route={ADMIN_CREDENTIAL}
        label1=" "
        label2=" "
        label4="Credential List"
      ></SuccessScreen>
    );
  };

  return (
    <>
      {showLoader && <Loader />}
      {createCredentialDataStatus === API_STATUS.SUCCESS &&
      createAdminCredentialData?.data &&
      successScreens ? (
        successScreen(createAdminCredentialData?.data?.id)
      ) : updateUserCredentialDataStatus === API_STATUS.SUCCESS &&
        updateUserCredentialData?.data &&
        successScreens ? (
        successScreen(updateUserCredentialData?.data?.id)
      ) : (
        <AddAdminCredential
          allUsers={allUsers?.data?.users}
          searchOption={searchOption?.users || []}
          onSearch={searchUsers}
          isLoading={autoSearchDataLoading}
          credentialListData={credentialListData ?? []}
          getCredentials={getCredentials}
          addUserCredentials={addUserCredential}
          editMode={editMode}
          credentialNameIdss={credentialId}
          getCredentialViewDetsilsData={getCredentialViewDetsilsData}
        />
      )}
    </>
  );
}

export default AddAdminCredentialContainer;
