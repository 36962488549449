import React, { useEffect, useState } from "react";
import Buttons from "../../../library/custom/button/Button";
import AddIcon from "@mui/icons-material/Add";
import ReportFilter from "./reportFilter";
import GridTable from "../../../library/custom/gridTable/GridTable";
import { columnsViewReport } from "../../../constant/inspectionConstant";
import { useSelector, useDispatch } from "react-redux";
import {
  getInspectionRportList,
  deleteInspection,
} from "../../../redux/actions/incpectionAction";
import SettingTable from "../../../library/custom/gridTable/SettingTable";
import TableRowAction from "../../../library/custom/gridTable/TableRowAction";
import DeleteAndDownloadAction from "../../../library/custom/gridTable/deleteAndDownloadAction";
import { getFormatedDate } from "../../../utils/helper";
import { TableLoader } from "../../../library/common/Loader";
import { useNavigate } from "react-router-dom";
import { INSPECTION_REPORT } from "../../../constant/routeContant";
import DialogBox from "../../../library/common/DialogBox";

import {
  API_STATUS,
  LNG_ID,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
  USER_ID,
} from "../../../constant/constant";
import NORow from "../../../library/custom/noRow/noRow";
import { getQuestionSet } from "../../../redux/actions/incpectionAction";
import { checkPrivileges } from "../../../utils/rolesHelper";

const TableData = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { inpsViewList, isLoading } = useSelector(
    (state) => state.inspViewReportList
  );
  const { quesSet } = useSelector((state) => state.inspection);
  const deleteIns = useSelector((state) => state.inspectionDelete);
  const deleteInsLoading = useSelector(
    (state) => state.inspectionDelete?.isLoading
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportIdOfChekbox, setReportIdOfChekbox] = useState([]);
  const [filter, setFilter] = useState({});
  const [inspReport, setInspReport] = useState("");
  const [assetTypeId, setAssetTypeId] = useState();
  const [assetId, setAssetId] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [userId, setUserId] = useState();
  const Inspcheck = checkPrivileges([12, 139, 144]);
  const toDateHandler = (value) => {
    setToDate(value);
  };
  const fromDateHandler = (value) => {
    setFromDate(value);
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deletehandle = () => {
    setOpenModal(false);
    const data = {
      InspectionId: selectedRow?.reportId,
      organisationId: parseInt(ORG_ID()),
    };
    dispatch(deleteInspection(data));
  };

  const deleteIncpection = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };
  const deleteItem = React.useCallback(
    (item, action) => {
      if (action === "single") {
        dispatch(deleteInspection(item?.reportId));
      } else {
      }
    },
    [dispatch]
  );

  const handleViewOnClick = (reportId) => {
    navigate(INSPECTION_REPORT, {
      state: { reportId: reportId },
    });
  };

  const columns = React.useMemo(() => {
    const headers = [...columnsViewReport];
    headers.push({
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={inpsViewList?.recordsCount}
          listItem={inpsViewList?.inspectionReports ?? []}
          filename={"Inspection-Reports"}
          numberOfItemsPerPage={PAGE_SIZE}
        />
      ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        const { id: reportId } = rows;
        return (
          <TableRowAction
            rows={rows}
            actionData={[
              "View Details",
              "Edit Incident",
              "Download Report",
              "Delete Incident",
            ]}
            usingComp={"Inspection"}
            view={checkPrivileges([12, 120, 121, 122, 4, 6, 5])}
            deleted={checkPrivileges([12, 120, 121, 4])}
            edit={checkPrivileges([12, 120, 121, 4, 5])}
            editLabel={"Edit Inspection"}
            viewLabel={"View Inspection"}
            deleteLabel={"Delete Inspection"}
            viewOnClick={() => handleViewOnClick(reportId)}
            editOnClick={() =>
              navigate(
                `/inspection/${rows?.row?.inspectionTypeId}/edit/${rows?.row?.reportId}`,
                {
                  state: {
                    assetTypeValue: rows?.row?.assetType,
                    statusId: rows?.row?.statusId,
                  },
                }
              )
            }
            deleteOnClick={() => deleteIncpection(rows?.row)}
          />
        );
      },
    });
    return headers;
  }, [inpsViewList, pageSize, deleteItem, navigate]);

  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };

  const onRowClick = (row) => {
    if (row?.value) {
      navigate(INSPECTION_REPORT, {
        state: { reportId: row?.id },
      });
    }
  };

  useEffect(() => {
    const { searchType } = filter;
    dispatch(
      getInspectionRportList({
        compnayId: undefined,
        divisionId: undefined,
        searchInspection: inspReport,
        startDate: fromDate && getFormatedDate(fromDate),
        endDate: toDate && getFormatedDate(toDate),
        status: status && status,
        assetId: assetId && assetId,
        inspectionTypes: searchType,
        pageSize: pageSize ?? 10,
        page: page ?? 1,
        organisationId: ORG_ID(),
        assetTypeId: assetTypeId,
        lngId: LNG_ID,
        userId: userId ? userId : Inspcheck ? 0 : USER_ID(),
      })
    );
    handleClose();
  }, [dispatch, assetTypeId, fromDate, toDate, inspReport, pageSize]);

  const typeOnChanges = (e) => {
    setFilter({ ...filter, searchType: e.target.value });
  };

  const typeOnAssetChanges = (e) => {
    setAssetTypeId(e.target.value);
  };

  const handleSearchFilter = (event, state) => {
    setFilter(state);
  };
  const handleClearFilter = (event, state) => {
    dispatch(
      getInspectionRportList({
        compnayId: undefined,
        divisionId: undefined,
        searchInspection: inspReport,
        startDate: null,
        endDate: null,
        inspectionTypes: "",
        pageSize: pageSize ?? 10,
        page: page ?? 1,
        assetTypeId: "",
        organisationId: ORG_ID(),
        lngId: LNG_ID,
        userId: Inspcheck ? 0 : USER_ID(),
      })
    );
    setFilter({ ...filter, searchType: "" });
    setAssetTypeId();
    setFromDate(null);
    setToDate(null);
    handleClose();
  };

  useEffect(() => {
    const data = {
      SubClassId: "",
      organisationId: ORG_ID(),
      lngId: LNG_ID,
      assetTypeId: assetTypeId,
      isAdmin: false,
    };
    dispatch(getQuestionSet({ data }));
  }, [dispatch, assetTypeId]);

  const handleApply = () => {
    const { searchType } = filter;
    dispatch(
      getInspectionRportList({
        compnayId: undefined,
        divisionId: undefined,
        searchInspection: inspReport,
        startDate: fromDate && getFormatedDate(fromDate),
        endDate: toDate && getFormatedDate(toDate),
        status: status && status,
        assetId: assetId && assetId,
        inspectionTypes: searchType,
        pageSize: pageSize ?? 10,
        page: page ?? 1,
        organisationId: ORG_ID(),
        assetTypeId: assetTypeId,
        lngId: LNG_ID,
        userId: userId ? userId : Inspcheck ? 0 : USER_ID(),
      })
    );
    handleClose();
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const { searchType } = filter;
    dispatch(
      getInspectionRportList({
        compnayId: undefined,
        divisionId: undefined,
        searchInspection: inspReport,
        startDate: fromDate && getFormatedDate(fromDate),
        endDate: toDate && getFormatedDate(toDate),
        status: status && status,
        assetId: assetId && assetId,
        inspectionTypes: searchType,
        pageSize: pageSize ?? 10,
        page: newPage + 1 ?? 1,
        organisationId: ORG_ID(),
        assetTypeId: assetTypeId,
        lngId: LNG_ID,
        userId: userId ? userId : Inspcheck ? 0 : USER_ID(),
      })
    );
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const { searchType } = filter;
    dispatch(
      getInspectionRportList({
        compnayId: undefined,
        divisionId: undefined,
        searchInspection: inspReport,
        startDate: fromDate && getFormatedDate(fromDate),
        endDate: toDate && getFormatedDate(toDate),
        status: status && status,
        assetId: assetId && assetId,
        inspectionTypes: searchType,
        pageSize: newPazeSize ?? 10,
        page: page ?? 1,
        organisationId: ORG_ID(),
        assetTypeId: assetTypeId,
        lngId: LNG_ID,
        userId: userId ? userId : Inspcheck ? 0 : USER_ID(),
      })
    );
  };

  useEffect(() => {
    if (deleteIns?.status === API_STATUS.SUCCESS) {
      dispatch(
        getInspectionRportList({
          compnayId: undefined,
          divisionId: undefined,
          searchInspection: inspReport,
          startDate: fromDate && getFormatedDate(fromDate),
          endDate: toDate && getFormatedDate(toDate),
          status: status && status,
          assetId: assetId && assetId,
          inspectionTypes: "",
          pageSize: pageSize ?? 10,
          page: page ?? 1,
          assetTypeId: assetTypeId,
          organisationId: ORG_ID(),
          lngId: LNG_ID,
          userId: userId ? userId : Inspcheck ? 0 : USER_ID(),
        })
      );
    }
  }, [deleteIns]);

  useEffect(() => {
    if (props?.detail) {
      setAssetTypeId(props?.detail?.assetType);
      setFromDate(props?.detail?.fromDate);
      setToDate(props?.detail?.toDate);
      setStatus(props?.detail?.status);
      setAssetId(props?.detail?.assetId);
      setUserId(props?.detail?.userId);
    }
  }, [props?.detail]);
  return (
    <>
      <section className="grid_main_sec">
        {openModal && (
          <DialogBox
            open={openModal}
            rows={[]}
            header={"Confirm Deletion"}
            deletehandle={deletehandle}
            handleCloseModal={() => setOpenModal(false)}
            content={"Are you sure you want to delete this Inspection?"}
            view
          />
        )}
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Inspection Reports</div>
          <div>
            {checkPrivileges([12, 120, 121, 4, 5]) && (
              <Buttons
                primary={true}
                label={"Create New Report"}
                onClick={() => navigate("/inspection/")}
                startIcon={<AddIcon />}
              />
            )}
          </div>
        </div>
        <div className="grid_main_body">
          <ReportFilter
            filterType={quesSet ?? []}
            handleSearchFilter={handleSearchFilter}
            handleClearFilter={handleClearFilter}
            handleClose={handleClose}
            anchorEl={anchorEl}
            inspReport={inspReport}
            setInspReport={setInspReport}
            typeOnChanges={typeOnChanges}
            typeVaue={filter.searchType}
            handleApply={handleApply}
            typeOnAssetChanges={typeOnAssetChanges}
            assetTypeId={assetTypeId ?? ""}
            toDateHandler={toDateHandler}
            toDate={toDate}
            fromDate={fromDate}
            fromDateHandler={fromDateHandler}
          />
        </div>

        <div className="desc_box row_uniq">
          <div className="table_style_ui">
            <GridTable
              getRowId={(r) => r.reportId}
              rows={
                isLoading || deleteInsLoading
                  ? []
                  : inpsViewList?.inspectionReports ?? []
              }
              rowCount={inpsViewList?.recordsCount ?? 0}
              columns={columns}
              checkboxSelection={false}
              page={page}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={ROW_PER_PAGE_SIZE}
              onPageSizeChange={handlePageSizeChange}
              onPageChange={(newPage) => handlePagignation(newPage)}
              loading={isLoading || deleteInsLoading}
              backIconButtonProps={{ disabled: false }}
              onClick={(rows) => onRowClick(rows)}
              onSelectionModelChange={(reportId) =>
                setReportIdOfChekbox(reportId)
              }
              disableSelectionOnClick
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
            />
          </div>
        </div>
      </section>
      {reportIdOfChekbox.length > 0 && (
        <DeleteAndDownloadAction
          reportIdOfChekbox={reportIdOfChekbox}
          incidentLists={inpsViewList?.inspectionReports ?? []}
          filename={"Inspection-Reports"}
          deleteOnClick={() => deleteItem(null, "multiple")}
        />
      )}
    </>
  );
};

export default React.memo(TableData);
