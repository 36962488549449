import { Grid } from "@mui/material";
import React from "react";
import "./ticketStyle.scss";
import DescriptionIcon from "@mui/icons-material/Description";
import ForumIcon from "@mui/icons-material/Forum";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";

const Ticket = (props) => {
  return (
    <div className="overrideTicket">
      <Grid className="ticketContainer">
        <Grid className="idCatDiv">
          <Grid className="id">
            <span
              className={
                props.status === 1 ? "iconClosed" : `icon${props.priority}`
              }
            >
              <DescriptionIcon />
            </span>
            Ticket Id - {props.ticketId}
          </Grid>
          {props.createdBy
          &&
          <Grid className="id">
           Created By: {props.createdBy}
          </Grid>
          }
          
          <Grid className="catTimeDiv">
            <Grid className="cat">
              <CategoryTwoToneIcon className="cat" />
              {props.category}
            </Grid>
            <Grid className="cat">
              {props.createdDateTime.slice(0, 10)}
            </Grid>
          </Grid>
        </Grid>
        <Grid mt={2} className="question">
          {props.ticketQuestion}
        </Grid>
        <Grid mt={2} className="answer">
          {props.ticketAnswer}
        </Grid>
        <Grid mt={3} mb={1} className="priority-msg">
          <Grid
            className={
              props.status === 1
                ? "priorityClosed"
                : `priority${props.priority}`
            }
          >
            {props.status === 1 ? "Closed" : props.priority}
          </Grid>
          {props.assignedUserName && (
            <Grid className="id">
              Assigned To: {props.assignedUserName}
            </Grid>
          )}
          <Grid className="id">
            {props.status !== 1 && props.replyCount === 0
              ? "Open"
              : props.status !== 1 && props.replyCount > 0
              ? " In Progress"
              : ""}
          </Grid>
          <Grid className="msg">
            <ForumIcon />
            {props.replyCount}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Ticket;
