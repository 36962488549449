import React, { useEffect } from "react";
import UserSerchByRoleList from "../component/userSearchByRole/userSerchByRoleList";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { useDispatch, useSelector } from "react-redux";
import { LNG_ID, ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import * as userAction from "../../../redux/actions/userAction";

function UserSearchByRoleContainer() {
  const dispatch = useDispatch();
  const usersByRoleListData = useSelector((state) => state.userByRoleListData);
  const isLoading = usersByRoleListData?.isLoading;

  const getUserByRoleLists = (data) => {
    dispatch(userAction.getUserByRoleList(data));
  };

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      roleId: 0,
    };
    getUserByRoleLists(data);
  }, []);

  const roles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.roles
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: 5,
    lngId: LNG_ID,
    productId: PRODUCT_ID(),
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);
  return (
    <UserSerchByRoleList
      roles={roles}
      getUserByRoleLists={getUserByRoleLists}
      usersByRoleListData={usersByRoleListData}
      isLoading={isLoading}
    />
  );
}

export default UserSearchByRoleContainer;
