import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import { GET_ITEM_LOG } from "../../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { REPORTS_ANALYTICS_DASHBOARD_ITEMS } from "../../../../constant/routeContant";
import {
  calculatePercentageChange,
  downloadExcel,
  downloadPDF,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";

const TreandingSubCategories = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const tableRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (props?.trendData) {
      setData((prevData) => [...prevData, ...props?.trendData?.trendPassData]);
    }
  }, [props?.trendData]);

  const columns = [
    "Trending Sub Categories",
    "Previous Period",
    "Corrective - Current Period",
    "Trends",
    "Previous Period",
    "Kudos - Current Period",
    "Trends",
  ];
  const totals = data.reduce(
    (acc, trend) => {
      acc[0] += trend.correctivePreviousCount || 0;
      acc[1] += trend.correctiveTargetCount || 0;
      acc[2] += trend.kudosPreviousCount || 0;
      acc[3] += trend.kudosTargetCount || 0;
      return acc;
    },
    [0, 0, 0, 0]
  );

  const percentageChangeCorrective = calculatePercentageChange(
    totals[1],
    totals[0]
  );
  const percentageChangeKudos = calculatePercentageChange(totals[3], totals[2]);

  const rows = [
    ...data?.map((trend) => [
      trend.subCategoryName,
      trend.correctivePreviousCount,
      trend.correctiveTargetCount,
      calculatePercentageChange(
        trend.correctiveTargetCount,
        trend.correctivePreviousCount
      ),
      trend.kudosPreviousCount,
      trend.kudosTargetCount,
      calculatePercentageChange(
        trend.kudosTargetCount,
        trend.kudosPreviousCount
      ),
    ]),
    [
      "Total",
      totals[0],
      totals[1],
      percentageChangeCorrective !== null ? percentageChangeCorrective : "-",
      totals[2],
      totals[3],
      percentageChangeKudos !== null ? percentageChangeKudos : "-",
    ],
  ];

  const handleOnClick = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
      state: {
        subCategoryId: id,
        drillDetail: props.drillDetail,
        itemLog: GET_ITEM_LOG.Corrective,
        itemTypeValue: "Corrective",
      },
    });
  };

  const handleOnClickKudos = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
      state: {
        subCategoryId: id,
        drillDetail: props.drillDetail,
        itemLog: GET_ITEM_LOG.Kudos,
        itemTypeValue: "Kudos",
      },
    });
  };

  const handleOnPreClick = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
      state: {
        subCategoryId: id,
        drillDetail: props.trendData,
        itemLog: GET_ITEM_LOG.Corrective,
        itemTypeValue: "Corrective",
        previous: "previous",
      },
    });
  };

  const handleOnClickPreKudos = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_ITEMS, {
      state: {
        subCategoryId: id,
        drillDetail: props.trendData,
        itemLog: GET_ITEM_LOG.Kudos,
        itemTypeValue: "Kudos",
        previous: "previous",
      },
    });
  };

  const renderRows = () => {
    const dataRows = data?.map((trend) => (
      <tr className="row customRowColor" key={trend.subCategoryId}>
        <td>{trend.subCategoryName}</td>
        <td className="currectiveBg">
          <span
            className="link"
            onClick={() => handleOnPreClick(trend?.categoryId)}
          >
            {trend.correctivePreviousCount}
          </span>
        </td>
        <td className="currectiveBg">
          <span
            className="link"
            onClick={() => handleOnClick(trend?.subCategoryId)}
          >
            {trend.correctiveTargetCount}
          </span>
        </td>
        <td className="currectiveBg">
          <span
            className={`persantage ${
              calculatePercentageChange(
                trend.correctiveTargetCount,
                trend.correctivePreviousCount
              ) < 0
                ? "minus"
                : "plus"
            }`}
          >
            {`${calculatePercentageChange(
              trend.correctiveTargetCount,
              trend.correctivePreviousCount
            )}`}
          </span>
        </td>
        <td className="kudosBg">
          <span
            className="link"
            onClick={() => handleOnClickPreKudos(trend?.categoryId)}
          >
            {trend.kudosPreviousCount}
          </span>
        </td>
        <td className="kudosBg">
          <span
            className="link"
            onClick={() => handleOnClickKudos(trend?.categoryId)}
          >
            {trend.kudosTargetCount}
          </span>
        </td>
        <td className="kudosBg">
          <span
            className={`persantage ${
              calculatePercentageChange(
                trend.kudosTargetCount,
                trend.kudosPreviousCount
              ) < 0
                ? "minus"
                : "plus"
            }`}
          >
            {`${calculatePercentageChange(
              trend.kudosTargetCount,
              trend.kudosPreviousCount
            )}`}
          </span>
        </td>
      </tr>
    ));

    const totalsRow = (
      <tr className="row customRowColor totalsRow">
        <td>Total</td>
        <td className="currectiveBg">{totals[0]}</td>
        <td className="currectiveBg">{totals[1]}</td>
        <td className="currectiveBg">
          <span
            className={`persantage ${
              percentageChangeCorrective < 0 ? "minus" : "plus"
            }`}
          >
            {percentageChangeCorrective !== null
              ? `${percentageChangeCorrective}`
              : "-"}
          </span>
        </td>
        <td className="kudosBg">{totals[2]}</td>
        <td className="kudosBg">{totals[3]}</td>
        <td className="kudosBg">
          <span
            className={`persantage ${
              percentageChangeKudos < 0 ? "minus" : "plus"
            }`}
          >
            {percentageChangeKudos !== null ? `${percentageChangeKudos}` : "-"}
          </span>
        </td>
      </tr>
    );

    return (
      <>
        {dataRows}
        {totalsRow}
      </>
    );
  };

  return (
    <>
      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">Trending Sub Categories</div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadPDF(rows, columns, "Trending-Sub-Categories.pdf")
                    }
                  >
                    <img src={commonImages.pdfFill} alt={""} />
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadExcel(rows, "Trending-Sub-Categories.xlsx")
                    }
                  >
                    <img src={commonImages.excelDownload} alt={""} />
                    <span>Download .xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="customTableWrapper">
        <Table
          columns={() => (
            <tr className="column">
              <th>Sub Category Name</th>
              <th className="currectiveBg">Previous Period</th>
              <th className="currectiveBg">
                Corrective - Current Period
              </th>
              <th className="currectiveBg">Trends</th>
              <th className="kudosBg">Previous Period</th>
              <th className="kudosBg">Kudos - Current Period</th>
              <th className="kudosBg">Trends</th>
            </tr>
          )}
          rows={renderRows()}
          tableRef={tableRef}
          className={"autoHeight"}
        />
      </div>
    </>
  );
};

export default TreandingSubCategories;
