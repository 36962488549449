import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "../common.scss";
import InjuredPart from "./injuredPart";
import ViewImages from "../viewImages/viewImages";
import Parser from "html-react-parser";
const InjureDetails = ({ injuryData }) => {
  const imageSize = {
    size: "small",
  };

  return (
    <div className="commonOveride personalInj">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">Injury Details:</Typography>
            <Typography className="listView">
              Description of Injury:
              <div className="blockView overRideTextEditor">
                {Parser(injuryData?.description_injury || "")}
              </div>
            </Typography>
            <Typography className="listView columns">
              <span>Contributing Factors:</span>
              <div className="breakValue">
                <span>
                  {injuryData?.contributing_factor?.map((i) => i).join(", ")}
                </span>
              </div>
            </Typography>
            <Typography className="listView columns">
              <span>Type of Injury: </span>{" "}
              <div className="breakValue">
                <span>{injuryData?.injury_type?.map((i) => i).join(", ")}</span>
              </div>
            </Typography>
            <Typography className="listView columns">
              <span>Body Part Injured:</span>
              <div className="breakValue">
                <span>{injuryData?.body_part?.map((i) => i).join(", ")}</span>
              </div>
            </Typography>
            <Typography className="listView columns">
              <div className="columnsf">
                Was the Injury Treated with Basic First Aid
              </div>
              <div className="columnsl">
                <div
                  className={
                    injuryData?.basic_injury === "Yes"
                      ? "mb-2 yesbtn"
                      : "mb-2 nobtn"
                  }
                >
                  {injuryData?.basic_injury}
                </div>
                {injuryData?.basic_injury === "No" && (
                  <span>{injuryData?.basic_injury_value}</span>
                )}
              </div>
            </Typography>
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <div className="columnsf">Was this Injury Recordable?</div>
                {injuryData?.injury_recordable.length > 0 && (
                  <div
                    className={
                      injuryData?.injury_recordable === "Yes"
                        ? "columnsl yesbtn"
                        : "columnsl nobtn"
                    }
                  >
                    {injuryData?.injury_recordable}
                  </div>
                )}{" "}
              </Typography>
            )}
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView">
                Summary of Work Related Injuries and Illnesses:
                <span></span>{" "}
              </Typography>
            )}

            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <div className="columnsf">Case Number:</div>
                <div className="columnsl">
                  {injuryData?.injury_recordable === "Yes" && (
                    <span>{injuryData?.case_number}</span>
                  )}
                </div>{" "}
              </Typography>
            )}
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <div className="columnsf">Employee Name:</div>
                <div className="columnsl">
                  <span>{injuryData?.employee_name}</span>
                </div>
              </Typography>
            )}
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <div className="columnsf">Job Title:</div>
                <div className="columnsl">
                  <span>{injuryData?.job_title}</span>
                </div>
              </Typography>
            )}
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <div className="columnsf">Date of Injury / Illness:</div>
                <div className="columnsl">
                  <span>{injuryData?.start_date}</span>
                </div>
              </Typography>
            )}
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <div className="columnsf">Where did the event occur?</div>
                <div className="columnsl">
                  <span>{injuryData?.location}</span>
                </div>
              </Typography>
            )}
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <span>Body Part Injured:</span>
                <div className="breakValue">
                  <span>{injuryData?.body_part?.map((i) => i).join(", ")}</span>
                </div>
              </Typography>
            )}
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <div className="columnsf">
                  Object or Substance that Directly Injured or Made the Person
                  Ill:
                </div>
                <div className="columnsl">
                  <span>{injuryData?.objectOrSubstance}</span>
                </div>
              </Typography>
            )}
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <div className="columnsf">Classify the Case:</div>
                <div className="columnsl">
                  <span>{injuryData?.classify_case}</span>
                </div>
              </Typography>
            )}
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <div className="columnsf">How Many Days?</div>
                <div className="columnsl">
                  <span>{injuryData?.classifyHowManyDays}</span>
                </div>
              </Typography>
            )}
            {injuryData?.employment_type !== 1 && (
              <Typography className="listView columns">
                <div className="columnsf">Type of Illness:</div>
                <div className="columnsl">
                  <span>{injuryData?.typeOfIllnessValue}</span>
                </div>
              </Typography>
            )}
            <Box>
              <Typography className="listView desNone">Photos:</Typography>
              <ViewImages
                image={injuryData?.accident_image}
                imageSize={imageSize}
              />
            </Box>
          </Box>
          <Box className="commoninfoRight">
            <InjuredPart bodyPartInj={injuryData?.body_part} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default InjureDetails;
