import { API_STATUS } from "../../constant/constant";
import { utilityDamageType } from "../constants/utilityDamageType";

const initialState = {
  flow: "utilityDamage",
  isError: false,
  isLoading: false,
};

export const utilityDamageReducer = (state = initialState, action) => {
  switch (action.type) {
    case utilityDamageType.POST_UTILITY_DAMAGE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case utilityDamageType.POST_UTILITY_DAMAGE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case utilityDamageType.POST_UTILITY_DAMAGE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case utilityDamageType.CLEAR_UTILITY_DAMAGE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const viewUtilityDamageReducer = (state = initialState, action) => {
  switch (action.type) {
    case utilityDamageType.GET_UTILITY_DAMAGE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case utilityDamageType.GET_UTILITY_DAMAGE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case utilityDamageType.GET_UTILITY_DAMAGE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateUtilityDamageReducer = (state = initialState, action) => {
  switch (action.type) {
    case utilityDamageType.PUT_UTILITY_DAMAGE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case utilityDamageType.PUT_UTILITY_DAMAGE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case utilityDamageType.PUT_UTILITY_DAMAGE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case utilityDamageType.CLEAR_UTILITY_DAMAGE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
