import React from "react";
import Buttons from "../../../../library/custom/button/Button";
import AddIcon from "@mui/icons-material/Add";
import "./siteProjectHeader.scss";
import { useNavigate, useLocation } from "react-router-dom";

const SiteProjectHeader = (props) => {
  const dataHeader = props?.dataHeader;
  const swTeamtabclickFun = props?.swTeamtabclickFun;
  const isteam = props?.isteam;
  const isteamval = props?.isteamval;
  const navigate = useNavigate();
  let url = useLocation();

  return (
    <>
      <div className="headerWrapper">
        {dataHeader?.title && (
          <>
            <div className="headerTitle">{dataHeader.title}</div>
          </>
        )}
        <div className="headerBox">
          <div className="headerLeft">
            <ul>
              {dataHeader?.menu.map((list, i) => {
                return list?.isshow ? (
                  <li
                    className={
                      dataHeader?.isdashboard && list?.id == isteamval
                        ? "active"
                        : url.pathname === list.route
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      dataHeader?.isdashboard
                        ? swTeamtabclickFun(list?.id)
                        : navigate(list.route)
                    }
                    key={i}
                  >
                    {list.text}
                  </li>
                ) : (
                  ""
                );
              })}
            </ul>
          </div>
          <div className="headerRight">
            {dataHeader?.btnText && (
              <Buttons
                //primary={true}
                varientAddIconBlue
                label={dataHeader.btnText}
                startIcon={<AddIcon />}
                onClick={() => navigate(dataHeader.btnLink)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteProjectHeader;
