import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterList from "./FilterList";
import { InputField } from "../../../library/custom/textBox/InputField";
import Grid from "@mui/material/Grid";
import FilterButton from "../../../library/custom/filterButton/filterButton";

const ReportFilter = ({
  filterType,
  handleClearFilter,
  handleSearchFilter,
  handleClose,
  anchorEl,
  inspReport,
  setInspReport,
  typeOnChanges,
  typeVaue,
  handleApply,
  typeOnAssetChanges,
  assetTypeId,
  toDateHandler,
  toDate,
  fromDate,
  fromDateHandler,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="filter_of_caselist">
        <Grid container spacing={2}>
          <Grid item md={8}>
            <div>
              <InputField
                type={"text"}
                onChange={(e) => setInspReport(e.target.value)}
                name={"inspreport"}
                value={inspReport ?? ""}
                placeholder={"Search inpection report"}
                disabled={false}
                isError={false}
                errorMsg={""}
                isIcon={true}
              />
            </div>
          </Grid>
          <Grid item>
            <div className="filter_btn">
              <button
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClose(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              <FilterList
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleSearch={(event, state) =>
                  handleSearchFilter(event, state)
                }
                handleClear={(event, state) => handleClearFilter(event, state)}
                filterType={filterType ?? []}
                typeOnChanges={typeOnChanges}
                typeVaue={typeVaue}
                handleApply={handleApply}
                typeOnAssetChanges={typeOnAssetChanges}
                assetTypeId={assetTypeId}
                toDateHandler={toDateHandler}
                toDate={toDate}
                fromDate={fromDate}
                fromDateHandler={fromDateHandler}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ReportFilter;
