import React from "react";
import ViewImages from "../viewImages/viewImages";
import "./appendix.scss";

const Appendix = (appendixImg) => {
  const imageSize = {
    size: "large",
  };
  return (
    <div className="appendixOver">
      <div className="appendixBox">
        <div className="appendixtitle">Appendix:</div>
        <ViewImages image={appendixImg?.appendixImg} imageSize={imageSize} />
      </div>
    </div>
  );
};

export default Appendix;
