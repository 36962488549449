import React, { useEffect, useState } from "react";
import InspectionFormsList from "../components/InspectionFormsList";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import { USER_ID } from "../../../../constant/constant";
import { ADD_INSPECTION_FORM } from "../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../library/common/Loader";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";

const InspectionFormsListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [stateId, setStateId] = useState();
  const defaultData = {
    userId: 0,
    search: "",
    status: "",
    page: 1,
    pageSize: 10,
  };
  const stateData = useSelector((state) => state.state);

  const cloneSwQuestionSet = useSelector((state) => state.cloneSwQuestionSet);

  const updateQuestionSetStatus = useSelector(
    (state) => state.updateSwQuestionSetStatus
  );
  const inspectionQuestionSetList = useSelector(
    (state) => state.swQuestionSetList
  );
  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };
  const getInspectionQuestionSetListData = (data) => {
    dispatch(swInspectionAction.getSwQuestionSetList(data));
  };

  const [clearUpdateQuestionSetStatus, setClearUpdateQuestionSetStatus] =
    useState(false);
  const updateQuestionSetStatusData = (data) => {
    setClearUpdateQuestionSetStatus(true);
    dispatch(swInspectionAction.updateSwQuestionSetStatus(data));
  };
  const isLoading = useSelector((state) => state.swQuestionSetList?.isLoading);
  const showLoader = useSelector(
    (state) =>
      state.updateSwQuestionSetStatus?.isLoading ||
      state.cloneSwQuestionSet?.isLoading ||
      state.state?.isLoading
  );
  useEffect(() => {
    getInspectionQuestionSetListData(defaultData);
    dispatch(swInspectionAction.clearCloneSwQuestionSet());
  }, []);

  const handleEditOnClick = (row) => {
    navigate(ADD_INSPECTION_FORM, {
      state: {
        row: row,
        editMode: true,
      },
    });
  };

  useEffect(() => {
    if (
      updateQuestionSetStatus?.status === "SUCCESS" &&
      updateQuestionSetStatus?.isLoading === false &&
      clearUpdateQuestionSetStatus
    ) {
      toast("Question Set Status Updated successfully");
    }
  }, [updateQuestionSetStatus]);

  const [clearClone, setClearClone] = useState(false);

  const onCloneSubmit = (data) => {
    setClearClone(true);
    dispatch(swInspectionAction.cloneSwQuestionSet(data));
  };

  useEffect(() => {
    if (
      cloneSwQuestionSet?.status === "SUCCESS" &&
      cloneSwQuestionSet?.isLoading === false &&
      clearClone
    ) {
      toast("Clone Inspection Form successfully");
      setStateId();
      setIsSubmit(false);
    }
  }, [cloneSwQuestionSet]);
  useEffect(() => {
    getStateData();
  }, []);

  return (
    <>
      {showLoader && <Loader />}

      <InspectionFormsList
        isLoading={isLoading}
        handleEditOnClick={handleEditOnClick}
        getInspectionQuestionSetListData={getInspectionQuestionSetListData}
        count={inspectionQuestionSetList?.data?.count}
        inspectionQuestionSetList={
          isLoading
            ? []
            : inspectionQuestionSetList?.data?.listQuestionSetDetails
        }
        updateQuestionSetStatusData={updateQuestionSetStatusData}
        onCloneSubmit={onCloneSubmit}
        updateQuestionSetStatus={updateQuestionSetStatus}
        cloneSwQuestionSet={cloneSwQuestionSet}
        state={stateData && stateData.data?.getState}
        isSubmit={isSubmit}
        setIsSubmit={setIsSubmit}
        stateId={stateId}
        setStateId={setStateId}
      />
      <ToastContainer />
    </>
  );
};

export default InspectionFormsListContainer;
