import React, { useState } from "react";
import GridTable from "../../library/custom/gridTable/GridTable";
import {
  PAGE_SIZE,
  LNG_ID,
  ORG_ID,
  USER_ID,
  GET_ITEM_LOG,
  ROW_PER_PAGE_SIZE,
} from "../../constant/constant";
import NORow from "../../library/custom/noRow/noRow";
import { TableLoader } from "../../library/common/Loader";
import SettingTable from "../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../admin/shared/ReportRowManipulate";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterList from "./FilterIncidentList";
import { checkPrivileges } from "../../utils/rolesHelper";
import {
  setColumnVisibilityModel1,
  columnVisibilityModel,
} from "../../utils/helper";

const ActionList = ({
  userId,
  itemListData,
  getItemLogActionData,
  handleViewOnClickAction,
  isGetItemLogLoading,
  searchShow,
  filterButtonShow = false,
  getItemLogAllData,
  statusIds,
  setStatusIds,
}) => {
  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [incSearchText, setIncSearchText] = useState("");
  const intialstate = {
    userId: userId,
    search: "",
    pageSize: pageSize,
    page: 1,
    projectId: 0,
    divisionId: 0,
    itemTypes: GET_ITEM_LOG.Action,
    statusIds: statusIds ? statusIds : "",
    fromDate: "",
    toDate: "",
    status: "",
  };
  const [allOpenitemFilterData, setAllOpenItemFilterData] =
    useState(intialstate);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleautoSearch = (event) => {
    let search = "";
    if (event.target.value) {
      search = event.target.value;
    }
    setIncSearchText(search);
    getItemLogActionData({
      ...allOpenitemFilterData,
      search: event.target.value,
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.9,
      sortable: true,
      hideable: false,
      filterable: false,
    },
    {
      field: "origin",
      headerName: "Origin",
      flex: 1,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "itemType",
      headerName: "Item Type",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.assetType === 0
              ? "NA"
              : rows?.row?.assetType === 1
              ? "Project"
              : rows?.row?.assetType === 3
              ? "Vehicle"
              : rows?.row?.assetType === 2
              ? "Equipment"
              : "Custom asset"}
          </>
        );
      },
      type: "text",
      flex: 1.2,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "jobsite",
      headerName: "Jobsite",
      flex: 1.5,
      sortable: true,
      filterable: false,
    },
    {
      field: "inspectionTitle",
      headerName: "Inspection Title",
      flex: 1.5,
      minWidth: 100,
      sortable: true,
      filterable: false,
    },

    {
      field: "category",
      headerName: "Category",
      flex: 1.5,
      minWidth: 110,
      sortable: true,
      filterable: false,
    },
    {
      field: "subCategory",
      headerName: "Sub-Category",
      flex: 1.5,
      minWidth: 110,
      sortable: true,
      filterable: false,
    },
    {
      field: "questions",
      headerName: "Questions",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "location",
      headerName: "Jobsite",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "involvedPartyName",
      headerName: "Involved Party",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },
    {
      field: "assignedToName",
      headerName: "Corrective Party",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },

    {
      field: "addedBy",
      headerName: "Added by",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },
    {
      field: "dateOfEntry",
      headerName: "Date of Entry",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={itemListData?.result?.totalnoOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={itemListData?.result?.logsData ?? []}
          fileNameXL={"action-list"}
        />
      ),
      flex: 1,
      minWidth: 60,
      sortable: false,
      disableClickEventBubbling: true,

      filterable: false,
      disableColumnMenu: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={(row) =>
              handleViewOnClickAction(
                row?.id,
                row?.itemTypeId,
                row?.rootId,
                row?.subRootId,
                row?.assignedPartyId,
                row?.status,
                row?.origin,
                row?.itemType,
                row?.addedById,
                row?.involvedPartyName,
                row?.involvedPartyId
              )
            }
            view={checkPrivileges([117, 118, 119, 12])}
            viewLabel="View Action"
          />
        );
      },
    },
  ];

  const onRowClick = (rows) => {
    if (rows.value && checkPrivileges([117, 118, 119, 12])) {
      const { row } = rows;
      console.log(rows, "row");

      handleViewOnClickAction(
        row?.id,
        row?.itemTypeId,
        row?.rootId,
        row?.subRootId,
        row?.assignedPartyId,
        row?.status,
        row?.origin,
        row?.itemType,
        row?.addedById,
        row?.involvedPartyName,
        row?.involvedPartyId
      );
    }
  };
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getItemLogActionData({
      page: newPage + 1,
      pageSize: pageSize,
      search: "",
      userId: userId,
      itemTypes: GET_ITEM_LOG.Action,
      organizationId: ORG_ID(),
      projectId: 0,
      divisionId: 0,
      statusIds: statusIds,
      fromDate: "",
      toDate: "",
      status: "",
    });
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    getItemLogActionData({
      page: page,
      pageSize: newPazeSize,
      search: "",
      userId: userId,
      itemTypes: GET_ITEM_LOG.Action,
      organizationId: ORG_ID(),
      projectId: 0,
      divisionId: 0,
      statusIds: statusIds,
      fromDate: "",
      toDate: "",
      status: "",
    });
  };

  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };
  const resetData = () => {
    setAnchorEl(null);
    setStatusIds(2);
    getItemLogAllData({
      ...intialstate,
      statusIds: 2,
      status: 2,
      page: 1,
    });
  };

  const handleSearchFilter = (event, state) => {
    setAnchorEl(null);
    setAllOpenItemFilterData(state);
    setStatusIds(state.status);
    const data = {
      userId: userId,
      search: incSearchText,
      page: page,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.Action,
      statusIds: state.status,
      status: state.status,
      fromDate: state.startDate,
      toDate: state.endDate,
    };
    getItemLogActionData(data);
  };
  // const [columnVisibilityModel, setColumnVisibilityModel] = useState({
  //   category: false,
  //   subCategory: false,
  //   questions: false,
  //   location: false,
  //   addedBy: false,
  //   inspectionTitle: false,
  //   assignedToName: false,
  // });
  // const setColumnVisibilityModel1 = (newModel) => {
  //   localStorage.setItem("columnVisibilityAction", JSON.stringify(newModel));
  // };
  //var retrievedObject = localStorage.getItem("columnVisibilityAction");
  var retrievedObjectdata = JSON.parse(
    localStorage.getItem("columnVisibilityAction")
  );
  return (
    <div className="grid_main_body">
      <div className="desc_box row_uniq">
        {searchShow ? (
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={incSearchText}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
            {/* <div className="filter_btn">
            <button
              aria-describedby={id}
              variant="contained"
              type="button"
              name="btn"
              onClick={""}
            >
              <FilterListIcon id="icn" /> Filters
            </button>
          </div> */}
            {filterButtonShow && (
              <div className="filter_btn">
                <button
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClose(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>
                <FilterList
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  resetFilterAPI={resetData}
                  handleSearch={(event, state) =>
                    handleSearchFilter(event, state)
                  }
                  filterType={[]}
                  openItemFilter={true}
                  itemTypes={GET_ITEM_LOG.Action}
                  bothRecords
                />
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="table_style_ui">
          <GridTable
            getRowId={(r) =>
              r?.id +
              "|" +
              r?.itemType +
              "|" +
              Date.now() +
              Math.floor(Math.random() * 100)
            }
            rows={itemListData?.result?.logsData ?? []}
            onClick={(rows) => onRowClick(rows)}
            rowCount={itemListData?.result?.totalnoOfRecords ?? 0}
            columns={columns}
            loading={isGetItemLogLoading}
            page={page}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={ROW_PER_PAGE_SIZE}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePagignation}
            backIconButtonProps={{ disabled: false }}
            disableSelectionOnClick
            components={{
              LoadingOverlay: TableLoader,
              NoRowsOverlay: NORow,
            }}
            disableColumnMenu={false}
            disableColumnSelector={false}
            disableColumnFilter={false}
            // initialState={{
            //   columns: {
            //     columnVisibilityModel: columnVisibilityModel,
            //   },
            // }}
            initialState={{
              columns: {
                columnVisibilityModel: retrievedObjectdata
                  ? retrievedObjectdata
                  : columnVisibilityModel,
              },
            }}
            // onColumnVisibilityModelChange={(newModel) =>
            //   setColumnVisibilityModel1(newModel)
            // }
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel1(newModel, "columnVisibilityAction")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ActionList;
