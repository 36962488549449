import React from "react";
import CredentialManagementList from "../component/credentialManagement/credentialManagementList";

import {
  SETUP_CREDENTIAL,
  ASSIGN_CREDENTIAL,
  CREDENTIAL_LOOKUP,
  MY_CREDENTIALS,
  ADMIN_CREDENTIAL,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";

const CredentialManagementContainer = () => {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(SETUP_CREDENTIAL);
    } else if (id === 2) {
      navigate(ASSIGN_CREDENTIAL);
    } else if (id === 3) {
      navigate(MY_CREDENTIALS);
    } else if (id === 4) {
      navigate(CREDENTIAL_LOOKUP);
    } else if (id === 5) {
      navigate(ADMIN_CREDENTIAL);
    }
  };

  return (
    <>
      <div className="dashTitle">Credential Management</div>
      <CredentialManagementList onClick={onClickHandler} />
    </>
  );
};

export default CredentialManagementContainer;
