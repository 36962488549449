import { SwInspectionType } from "../types/swInspectionType";
import { API_STATUS } from "../../../constant/constant";

const initialState = {
  flow: "SwInspection",
  isError: false,
  isLoading: false,
};

export const swQuestionSetListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_QUESTION_SET_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_QUESTION_SET_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_QUESTION_SET_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createSwQuestionSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.POST_SW_QUESTION_SET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_SW_QUESTION_SET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_SW_QUESTION_SET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_SW_QUESTION_SET:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateSwQuestionSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.UPDATE_SW_QUESTION_SET_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.UPDATE_SW_QUESTION_SET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.UPDATE_SW_QUESTION_SET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_SW_QUESTION_SET:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getSwQuestionSetDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_QUESTION_SET_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_QUESTION_SET_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_QUESTION_SET_DETAILS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateSwQuestionSetStatusReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.UPDATE_SW_QUESTION_SET_STATUS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.UPDATE_SW_QUESTION_SET_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.UPDATE_SW_QUESTION_SET_STATUS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const cloneSwQuestionSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.CLONE_SW_QUESTION_SET:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.CLONE_SW_QUESTION_SET_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.CLONE_SW_QUESTION_SET_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_CLONE_SW_QUESTION_SET:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getQuestionSetOnProjectReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_QUESTION_SET_ON_PROJECT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_QUESTION_SET_ON_PROJECT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_QUESTION_SET_ON_PROJECT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createSwQuestionSetProjectMapReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.POST_SW_QUESTION_SET_PROJECT_MAP_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_SW_QUESTION_SET_PROJECT_MAP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_SW_QUESTION_SET_PROJECT_MAP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_SW_QUESTION_SET_PROJECT_MAP:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
export const updateSwQuestionSetProjectMapReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_SW_QUESTION_SET_PROJECT_MAP:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateStatusQuestionSetProjectMapReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_SW_QUESTION_SET_PROJECT_MAP_STATUS:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getSwQuestionSetProjectMapDetailReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getSwQuestionSetProjectMapListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_QUESTION_SET_PROJECT_MAP_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createSwCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.POST_SW_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_SW_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_SW_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateSwCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.UPDATE_SW_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.UPDATE_SW_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.UPDATE_SW_CATEGORY_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getSwCategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_CATEGORY_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createSwSubCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.POST_SW_SUB_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_SW_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_SW_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateSwSubCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.UPDATE_SW_SUB_CATEGORY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.UPDATE_SW_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.UPDATE_SW_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getSwSubCategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_SUB_CATEGORY_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_SUB_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_SUB_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createSwQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.POST_SW_QUESTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_SW_QUESTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_SW_QUESTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateSwQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.UPDATE_SW_QUESTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.UPDATE_SW_QUESTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.UPDATE_SW_QUESTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getSwQuestionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_QUESTION_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_QUESTION_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createInspectionTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.POST_INSPECTION_TYPE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_INSPECTION_TYPE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_INSPECTION_TYPE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateInspectionTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.UPDATE_INSPECTION_TYPE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.UPDATE_INSPECTION_TYPE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.UPDATE_INSPECTION_TYPE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getInspectionTypeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_INSPECTION_TYPE_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_INSPECTION_TYPE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_INSPECTION_TYPE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getInspectionTypeFieldsListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.GET_INSPECTION_TYPE_FEILDS_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_INSPECTION_TYPE_FEILDS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_INSPECTION_TYPE_FEILDS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getBindInspectionTypeFromStateListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.GET_INSPECTION_TYPE_FROM_STATE_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_INSPECTION_TYPE_FROM_STATE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_INSPECTION_TYPE_FROM_STATE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getInTyFeByInTyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_IN_TY_FE_IN_TY_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_IN_TY_FE_IN_TY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_IN_TY_FE_IN_TY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateInspectionTypeFieldsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.UPDATE_INSPECTION_TYPE_FIELDS_MAP_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.UPDATE_INSPECTION_TYPE_FIELDS_MAP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.UPDATE_INSPECTION_TYPE_FIELDS_MAP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

// User

export const getSwInspectionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_INSPECTION_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_INSPECTION_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_INSPECTION_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createSwInspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.POST_SW_INSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_SW_INSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_SW_INSPECTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_SW_INSPECTION:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateSwInspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.UPDATE_SW_INSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.UPDATE_SW_INSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.UPDATE_SW_INSPECTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_SW_INSPECTION:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getSwInspectionDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_INSPECTION_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_INSPECTION_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_INSPECTION_DETAILS_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const deleteSwInspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.DELETE_SW_INSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.DELETE_SW_INSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.DELETE_SW_INSPECTION_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

// primary and secondry inspector

export const getSwInspectorListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_INSPECTOR_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_INSPECTOR_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_INSPECTOR_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const assignSwInspectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.POST_ASSIGN_INSPECTOR_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_ASSIGN_INSPECTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_ASSIGN_INSPECTOR_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getSwSecondaryInspectorListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_SECONDARY_INSPECTOR_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_SECONDARY_INSPECTOR_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_SECONDARY_INSPECTOR_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const deleteSwSecondaryInspectionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.DELETE_SW_SECONDARY_INSPECTOR_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.DELETE_SW_SECONDARY_INSPECTOR_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.DELETE_SW_SECONDARY_INSPECTOR_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

//SW Dasboard card details

export const swDashboardcardDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createSwOpenItemDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.POST_OPEN_ITEM_DETAIL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_OPEN_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_OPEN_ITEM_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_SW_OPEN_ITEM_DETAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
export const createSwInspectionCloseItemReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.POST_INSPECTION_CLOSE_ITEM_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_INSPECTION_CLOSE_ITEM_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_INSPECTION_CLOSE_ITEM_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_SW_INSPECTION_CLOSE_ITEM:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const createSwInspectionItemCommentReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.POST_INSPECTION_ITEM_COMMENT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_INSPECTION_ITEM_COMMENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_INSPECTION_ITEM_COMMENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_SW_INSPECTION_ITEM_COMMENT:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const deferredSwInspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.POST_DEFERRED_INSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_DEFERRED_INSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_DEFERRED_INSPECTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const swOpenItemListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_SW_OPEN_ITEM_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_SW_OPEN_ITEM_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_SW_OPEN_ITEM_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getExternalInspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.GET_EXTERNAL_INSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_EXTERNAL_INSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_EXTERNAL_INSPECTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const conductExternalInspectionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.POST_CONDUCT_EXTERNAL_INSPECTION_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_CONDUCT_EXTERNAL_INSPECTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_CONDUCT_EXTERNAL_INSPECTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getInspectionHeaderDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.GET_INSPECTION_HEADER_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_INSPECTION_HEADER_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_INSPECTION_HEADER_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getInspectionDailyReportDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.GET_INSPECTION_DAILY_REPORT_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.GET_INSPECTION_DAILY_REPORT_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.GET_INSPECTION_DAILY_REPORT_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateInspectionDailyReportReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.POST_INSPECTION_DAILY_REPORT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_INSPECTION_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_INSPECTION_DAILY_REPORT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getBulkInspectionDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.POST_GET_BULK_INSPECTION_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_GET_BULK_INSPECTION_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_GET_BULK_INSPECTION_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getBulkInspectionFilesReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SwInspectionType.POST_GET_BULK_INSPECTION_FILES_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_GET_BULK_INSPECTION_FILES_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_GET_BULK_INSPECTION_FILES_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getProjectContactsEmailDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SwInspectionType.POST_PROJECT_CONTACTS_EMAIL_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case SwInspectionType.POST_PROJECT_CONTACTS_EMAIL_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case SwInspectionType.POST_PROJECT_CONTACTS_EMAIL_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case SwInspectionType.CLEAR_PROJECT_CONTACTS_EMAIL_DETAILS:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};