import React, { useEffect } from "react";
import ToolBoxTalkUserList from "../component/toolBoxTalkList/toolBoxTalkUserList";
import * as lmsAction from "../../../redux/actions/lmsAction";
import { useDispatch, useSelector } from "react-redux";
import LmsHeader from "../component/lmsHeader/lmsHeader";
import { DASHBOARD, TOOL_BOX_CREATE } from "../../../constant/routeContant";
import { usersAccess } from "../../../utils/rolesHelper";
import {
  MODULES_NAME,
  PAGE_SIZE,
  ORG_ID,
  LNG_ID,
  USER_ID,
} from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
const ToolboxTalkUserContainer = () => {
  const dispatch = useDispatch();
  const access = usersAccess(MODULES_NAME.lms);
  let navigate = useNavigate();
  const adminAccess = usersAccess(MODULES_NAME.admin);
  const lmsAccess = usersAccess(MODULES_NAME.lms);

  const isAllowed =
    lmsAccess.isFullAccess ||
    lmsAccess.isWriteAccess ||
    lmsAccess.isReadAccess ||
    adminAccess.isFullAppAccess;

  const getToolBoxTalkListResult = useSelector(
    (state) => state.getToolBoxTalkList?.data
  );

  const getDropdownDataKeyValue = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.toolboxCategories
  );
  // const dataHeader = {
  //   title: "Safety Meetings",
  //   btnText: "Create Safety Meeting",
  //   btnLink: TOOL_BOX_CREATE,
  //   menu: [],
  // };
  const defaultData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    search: "",
    catIds: "",
    userId: USER_ID(),
    status: "",
    tabId: 1,
  };

  useEffect(() => {
    // if (!isAllowed) {
    //   navigate(DASHBOARD);
    // }

    getToolBoxTalkListData(defaultData);
  }, []);
  const getToolBoxTalkListData = (data) => {
    dispatch(toolboxAction.clearUserDetailTalk(data));
    dispatch(toolboxAction.getToolBoxTalkList(data));
  };

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    lngId: LNG_ID,
    flags: [20],
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);
  return (
    <>
      {/* {lmsAccess.isFullAccess &&
      lmsAccess.isWriteAccess &&
      lmsAccess.isReadAccess ? (
        <LmsHeader dataHeader={dataHeader} />
      ) : (
        ""
      )} */}

      <ToolBoxTalkUserList
        access={access}
        categoryListData={getDropdownDataKeyValue}
        getToolBoxTalkListResult={getToolBoxTalkListResult}
        getToolBoxTalkListData={getToolBoxTalkListData}
      />
    </>
  );
};

export default ToolboxTalkUserContainer;
