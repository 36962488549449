export const projectType = {
  POST_PROJECT_PENDING: "[PROJECT][REQUEST] Create Project storm Water",
  POST_PROJECT_FAILURE:
    "[PROJECT][RESPONSE] Project  Creation storm Water Failed",
  POST_PROJECT_SUCCESS: "[PROJECT][RESPONSE] Project Created Successfull",
  PUT_PROJECT_PENDING: "[PROJECT][REQUEST] Update Project",
  PUT_PROJECT_FAILURE: "[PROJECT][RESPONSE] Project Updation Failed",
  PUT_PROJECT_SUCCESS: "[PROJECT][RESPONSE] Project Updation Successfull",
  GET_PROJECT_DETAILS_PENDING:
    "[PROJECT_DETAILS][REQUEST] Get Project Details Data",
  GET_PROJECT_DETAILS_SUCCESS:
    "[PROJECT_DETAILS][RESPONSE] Get Project Details Data Successfull ",
  GET_PROJECT_DETAILS_FAILURE:
    "[PROJECT_DETAILS][RESPONSE] Get Project Details Data Failed ",

  GET_SW_PROJECT_LIST_PENDING:
    "[GET_SW_PROJECT_LIST_PENDING][REQUEST] Get Project list requested",
  GET_SW_PROJECT_LIST_FAILURE:
    "[GET_SW_PROJECT_LIST_FAILURE][RESPONSE] Get Project list Failed",
  GET_SW_PROJECT_LIST_SUCCESS:
    "[GET_SW_PROJECT_LIST_SUCCESS][RESPONSE] Get Project list Successfull",

  PROJECT_PAGE: "PROJECT_PAGE",

  POST_ADD_CONTACT_IN_PROJECT_PENDING:
    "[  POST_ADD_CONTACT_IN_PROJECT_PENDING][REQUEST] Post add contact in project requested",
  POST_ADD_CONTACT_IN_PROJECT_SUCCESS:
    "[  POST_ADD_CONTACT_IN_PROJECT_SUCCESS][RESPONSE] Post add contact in project Successfull",
  POST_ADD_CONTACT_IN_PROJECT_FAILURE:
    "[  POST_ADD_CONTACT_IN_PROJECT_FAILURE][RESPONSE] Post add contact in project Failed",

  GET_CONTACT_IN_PROJECT_LIST_PENDING:
    "[GET_CONTACT_IN_PROJECT_LIST_PENDING][REQUEST] Get contact in Project list requested",
  GET_CONTACT_IN_PROJECT_LIST_SUCCESS:
    "[ GET_CONTACT_IN_PROJECT_LIST_SUCCESS][RESPONSE] Get contact in Project list  Successfull",
  GET_CONTACT_IN_PROJECT_LIST_FAILURE:
    "[ GET_CONTACT_IN_PROJECT_LIST_FAILURE][RESPONSE] Get contact in Project list  Failed",
  CLEAR_GET_CONTACT_IN_PROJECT_LIST:
    "[ CLEAR_GET_CONTACT_IN_PROJECT_LIST][UNMOUNT] Get contact in Project list  Unmount",

  UPDATE_PROJECT_CONTACT_STATUS_PENDING:
    "[UPDATE_PROJECT_CONTACT_STATUS_PENDING][REQUEST] Update Project Contact Status requested",
  UPDATE_PROJECT_CONTACT_STATUS_SUCCESS:
    "[UPDATE_PROJECT_CONTACT_STATUS_SUCCESS][RESPONSE] Update Project Contact Status  Successfull",
  UPDATE_PROJECT_CONTACT_STATUS_FAILURE:
    "[UPDATE_PROJECT_CONTACT_STATUS_FAILURE][RESPONSE] Update Project Contact Status  Failed",

  POST_PROJECT_SAMPLE_PENDING:
    "[PROJECT_SAMPLE][REQUEST] Create Project Sample storm Water",
  POST_PROJECT_SAMPLE_FAILURE:
    "[PROJECT_SAMPLE][RESPONSE] Project Sample Creation storm Water Failed",
  POST_PROJECT_SAMPLE_SUCCESS:
    "[PROJECT_SAMPLE][RESPONSE] Project Sample Created Successfull",

  GET_SW_PROJECT_SAMPLE_LIST_PENDING:
    "[GET_SW_PROJECT_SAMPLE_LIST_PENDING][REQUEST] Get Project Sample list requested",
  GET_SW_PROJECT_SAMPLE_LIST_FAILURE:
    "[GET_SW_PROJECT_SAMPLE_LIST_FAILURE][RESPONSE] Get Project Sample list Failed",
  GET_SW_PROJECT_SAMPLE_LIST_SUCCESS:
    "[GET_SW_PROJECT_SAMPLE_LIST_SUCCESS][RESPONSE] Get Project Sample list Successfull",

  EDIT_ONE_CONTACT_IN_PROJECT_PENDING:
    "[EDIT_ONE_CONTACT_IN_PROJECT_PENDING][REQUEST] Edit One Project In Contact storm Water",
  EDIT_ONE_CONTACT_IN_PROJECT_SUCCESS:
    "[EDIT_ONE_CONTACT_IN_PROJECT_SUCCESS][RESPONSE] Edit One Project In Contact Successfull",
  EDIT_ONE_CONTACT_IN_PROJECT_FAILURE:
    "[EDIT_ONE_CONTACT_IN_PROJECT_FAILURE][RESPONSE] Edit One Project In Contact Failed",

  DELETE_CONTACT_IN_PROJECT_PENDING:
    "[DELETE_CONTACT_IN_PROJECT_PENDING][REQUEST] Delete Contact In Project requested",
  DELETE_CONTACT_IN_PROJECT_SUCCESS:
    "[DELETE_CONTACT_IN_PROJECT_SUCCESS][RESPONSE] Delete Contact In Project Successfull",
  DELETE_CONTACT_IN_PROJECT_FAILURE:
    "[DELETE_CONTACT_IN_PROJECT_FAILURE][RESPONSE] Delete Contact In Project Failed",

  SW_GET_RAINFALL_DATA_LIST_PENDING:
    "[SW_GET_RAINFALL_DATA_LIST_PENDING][REQUEST] Get Rain fall  list requested",
  SW_GET_RAINFALL_DATA_LIST_FAILURE:
    "[SW_GET_RAINFALL_DATA_LIST_FAILURE][RESPONSE] Get Rain fall list Failed",
  SW_GET_RAINFALL_DATA_LIST_SUCCESS:
    "[SW_GET_RAINFALL_DATA_LIST_SUCCESS][RESPONSE] Get Rain fall list Successfull",

  RAINFALL_PAGE: "RAINFALL_PAGE",
  CLEAR_SWPROJECT: "CLEAR_SWPROJECT_DATA",
  CLEAR_SWPROJECTEDIT: "CLEAR_SWPROJECT_DATA_EDIT",

  SW_DELETE_PROJECT_PENDING:
    "[SW_DELETE_PROJECT_PENDING][REQUEST] Delete Project requested",
  SW_DELETE_PROJECT_SUCCESS:
    "[SW_DELETE_PROJECT_SUCCESS][RESPONSE] Delete Project Successfull",
  SW_DELETE_PROJECT_FAILURE:
    "[SW_DELETE_PROJECT_FAILURE][RESPONSE] Delete Project Failed",
};
