import React, { useEffect } from "react";
import AddPermitList from "../components/addPermitList";
import * as permitAction from "../../../../redux/storm-water/actions/permitAction";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SW_ADD_PERMIT,
  SW_EDIT_PERMIT,
  SW_VIEW_PERMIT,
} from "../../../../constant/routeContant";
import { useDispatch, useSelector } from "react-redux";

function AddPermitListContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const projectName = location?.state?.projectName;
  const projectNumber = location?.state?.projectNumber;
  const projectid = location?.state?.projectid ?? location?.state?.projectId;

  const stateId = location?.state?.stateId ?? location?.state?.stateid;
  const customer = location?.state?.customerName;
  const customerId = location?.state?.customerId;

  const addPermitNav = () => {
    navigate(SW_ADD_PERMIT, {
      state: {
        projectid: projectid,
        stateId: stateId,
        projectName: projectName,
        projectNumber: projectNumber,
        customerId: customerId,
      },
    });
  };

  const handlepermitList = (data) => {
    dispatch(permitAction.permitListPending(data));
  };

  const permitList = useSelector((state) => state.permitList);
  useEffect(() => {
    const data = {
      search: "",
      expstartDate: "",
      expendDate: "",
      apvstartDate: "",
      apvendDate: "",
      permitNumber: "",
      permitHolder: "",
      projectId: projectid,
      page: 1,
      permitType: "",
    };
    dispatch(permitAction.permitListPending(data));
    handlePermitType(data1);
  }, []);

  const handleEditOnClick = (rows) => {
    navigate(SW_EDIT_PERMIT, {
      state: {
        permitId: rows.permitId,
        editMode: true,
        projectid: projectid,
        projectName: projectName,
        projectNumber: projectNumber,
        stateId: stateId,
      },
    });
  };
  const handleViewOnClick = (rows) => {
    navigate(SW_VIEW_PERMIT, {
      state: {
        permitId: rows.permitId,
        editMode: true,
        projectid: projectid,
        projectName: projectName,
        projectNumber: projectNumber,
        stateId: stateId,
      },
    });
  };
  const isLoading = useSelector((state) => state.permitList?.isLoading);
  const data1 = {
    stateId: stateId,
  };
  const handlePermitType = (data1) => {
    dispatch(permitAction.getPermitTypePending(data1));
  };
  const getPermitType = useSelector((state) => state?.getPermitType?.data);
  return (
    <div>
      <AddPermitList
        addPermitNav={addPermitNav}
        permitList={isLoading ? [] : permitList?.data}
        isLoading={isLoading}
        handleEditOnClick={handleEditOnClick}
        handleViewOnClick={handleViewOnClick}
        handlepermitList={handlepermitList}
        projectName={projectName}
        projectNumber={projectNumber}
        projectid={projectid}
        getPermitType={getPermitType}
        stateId={stateId}
        customer={customer}
        customerId={customerId}
      />
    </div>
  );
}

export default AddPermitListContainer;
