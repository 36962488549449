import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { get, post, deletes } from "./axiosRequest";
import { PRODUCT_ID } from "../constant/storm-water/constant";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const product = userInfo?.product;
const projectstatus = product?.map((val) => {
  return val?.key;
});
export const getContactList = async ({ data }) => {
  const { pageSize, page, statusIds, compnayName, search } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Contact/GetContactList?organisationId=${ORG_ID()}&page=${page}&pageSize=${pageSize}&search=${search}&statusIds=${statusIds}&compnayName=${compnayName}&productId=${
    PRODUCT_ID() ? PRODUCT_ID() : projectstatus[0]
  }`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const createContact = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Contact/AddContact`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateContact = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/Contact/UpdateContact`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteContacts = async (payload) => {
  const url = `${getApiUrl().adminApiUrl}/Contact/DeleteContact/${
    payload?.payload
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getContactsReport = async (contactId) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Contact/GetContactDetails?contactId=${contactId}&orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const convertNonPrivilegeContactList = async (data) => {
  console.log("data", data);
  const url = `${getApiUrl().adminApiUrl}/Contact/ConvertInpriviledge`;
  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
