import { Card, CardActionArea, CardContent, Grid } from "@mui/material";
import React from "react";
import "./supportCardStyle.scss";
const SupportCard = ({ icon, category, details, onClick, details2 = "" }) => {
  return (
    <div className="cardOverride">
      <Card className="card">
        <CardActionArea className="cardContent" onClick={onClick}>
          <CardContent>
            <Grid mt={0.5}>{icon}</Grid>
            <Grid className="category" mt={0.5}>
              {category}
            </Grid>
            <Grid className="details" mt={3}>
              {details}
            </Grid>
            <Grid className="details2" mt={3}>
              {details2}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default SupportCard;
