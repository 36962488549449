import { Chip, Grid } from "@mui/material";
import "./UsersChip.scss";
import React, { useState } from "react";

const DeletableChip = ({
  userList,
  handleDelete,
  isCountChip = false,
  isCount,
  isiconDel = true,
}) => {
  const [showAll, setshowAll] = useState(false);
  const itemsToRender = showAll ? userList : userList?.slice(0, 2);

  return (
    <Grid container gap={2} className="overrideDeletableChip">
      {itemsToRender &&
        itemsToRender.length > 0 &&
        itemsToRender?.map((e, i) => {
          return (
            <div className="countChip" key={i}>
              <Chip
                className="chip"
                key={e.key}
                label={e.value}
                onDelete={isiconDel ? () => handleDelete(e.key) : false}
              />
              {isCountChip && (
                <div className="pastVioltion">
                  Previous Corrective: {isCount[i]?.count}
                </div>
              )}
            </div>
          );
        })}
      {userList?.length > 2 && (
        <Chip
          className="chip"
          // key={e.key}
          label={showAll ? "Show Less" : `+ ${userList?.length - 2} more`}
          onClick={() => setshowAll(!showAll)}
          // onDelete={() => handleDelete(e.key)}
        />
      )}
    </Grid>
  );
};

export default DeletableChip;
