import * as jhaAction from "../actions/jhaAction";
import { jhaType } from "../constants/jhaType";

import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";
import {
  addJhaActivity,
  addJhaCategory,
  addJhaControl,
  addJhaHazard,
  getConductJhalList,
  addJhaTemplate,
  getJhaActivtyList,
  getJhaCategoryList,
  getJhaControlDetails,
  getJhaControlList,
  getJhaControlTypeList,
  getJhaHazardsList,
  getJhaTemplateList,
  updateJhaActivity,
  updateJhaCategory,
  updateJhaControl,
  updateJhaHazard,
  updateJhaTemplateStatus,
  addJhaConduct,
  getJhaTempleteDetsils,
  getJhaConductDetails,
  updateJhaTemplate,
  addJHASetup,
  getJhaSetupList,
  activeInactiveJHASetup,
  getJHAReferenceData,
  getJHASetupDetail,
  updateJHASetup,
  getJHASetupDashboard,
  getSaftyLogJHAConductList,
  getJhaActiveTemplateList,
} from "../../services/jhaService";

export function* addJhaCategorySaga(params) {
  console.debug("addJhaCategroySaga");
  try {
    const { data, status, error } = yield call(addJhaCategory, params);
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.addJhaCategorySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Category Failed" };
    yield put(jhaAction.addJhaCategoryFailure(data));
  }
}

export function* jhaCategoryListSaga(params) {
  console.debug("categoryListSaga");
  try {
    const { data, status, error } = yield call(getJhaCategoryList, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaCategoryListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha Category List Failed" };
    yield put(jhaAction.getJhaCategoryListFailure(data));
  }
}

export function* updateJhaCategorySaga(params) {
  try {
    const { data, status, error } = yield call(updateJhaCategory, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.updateJhaCategorySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Category Failed" };
    yield put(jhaAction.updateJhaCategoryFailure(data));
  }
}

export function* addJhaActivitySaga(params) {
  console.debug("addJhaActivitySaga");
  try {
    const { data, status, error } = yield call(addJhaActivity, params);
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.addJhaActivitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Activity Failed" };
    yield put(jhaAction.addJhaActivityFailure(data));
  }
}

export function* jhaActivityListSaga(params) {
  console.debug("activtyListSaga");
  try {
    const { data, status, error } = yield call(getJhaActivtyList, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaActivityListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha Activity List Failed" };
    yield put(jhaAction.getJhaActivityListFailure(data));
  }
}

export function* updateJhaActivitySaga(params) {
  try {
    const { data, status, error } = yield call(updateJhaActivity, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.updateJhaActivitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Activity Failed" };
    yield put(jhaAction.updateJhaActivityFailure(data));
  }
}

export function* addJhaHazardSaga(params) {
  console.debug("addJhaHazardSaga");
  try {
    const { data, status, error } = yield call(addJhaHazard, params);
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.addJhaHazardSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Hazard Failed" };
    yield put(jhaAction.addJhaHazardFailure(data));
  }
}

export function* jhaHazardsListSaga(params) {
  console.debug("hazardsListSaga");
  try {
    const { data, status, error } = yield call(getJhaHazardsList, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaHazardsListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha Hazards List Failed" };
    yield put(jhaAction.getJhaHazardsListFailure(data));
  }
}

export function* updateJhaHazardSaga(params) {
  try {
    const { data, status, error } = yield call(updateJhaHazard, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.updateJhaHazardSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Hazard Failed" };
    yield put(jhaAction.updateJhaHazardFailure(data));
  }
}

export function* jhaControlListSaga(params) {
  console.debug("ControlListSaga");
  try {
    const { data, status, error } = yield call(getJhaControlList, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaControlListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha Control List Failed" };
    yield put(jhaAction.getJhaControlListFailure(data));
  }
}

export function* addJhaControlSaga(params) {
  console.debug("addJhaControlSaga");
  try {
    const { data, status, error } = yield call(addJhaControl, params);
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.addJhaControlSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Control Failed" };
    yield put(jhaAction.addJhaControlFailure(data));
  }
}

export function* updateJhaControlSaga(params) {
  try {
    const { data, status, error } = yield call(updateJhaControl, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.updateJhaControlSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Control Failed" };
    yield put(jhaAction.updateJhaControlFailure(data));
  }
}

export function* conductJhaListSaga(params) {
  console.debug("ConductJhaListSaga");
  try {
    const { data, status, error } = yield call(getConductJhalList, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getConductJhaListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Conduct Jha List Failed" };
    yield put(jhaAction.getConductJhaListFailure(data));
  }
}

export function* addJhaTemplateSaga(params) {
  console.debug("addJhaTemplateSaga");
  try {
    const { data, status, error } = yield call(addJhaTemplate, params);
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.addJhaTemplateSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Template Failed" };
    yield put(jhaAction.addJhaTemplateFailure(data));
  }
}

export function* jhaTemplateListSaga(params) {
  console.debug("TemplateListSaga");
  try {
    const { data, status, error } = yield call(getJhaTemplateList, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaTemplateListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha Template List Failed" };
    yield put(jhaAction.getJhaTemplateListFailure(data));
  }
}

export function* updateJhaTemplateStatusSaga(params) {
  console.debug("updateJhaTemplateStatusSaga");
  try {
    const { data, status, error } = yield call(updateJhaTemplateStatus, params);
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.updateJhaTemplateStatusSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Template Failed" };
    yield put(jhaAction.updateJhaTemplateStatusFailure(data));
  }
}

export function* jhaControlTypeListSaga(params) {
  console.debug("ControlTypeListSaga");
  try {
    const { data, status, error } = yield call(getJhaControlTypeList, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaControlTypeListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha ControlType List Failed" };
    yield put(jhaAction.getJhaControlTypeListFailure(data));
  }
}

export function* jhaControlDetailsSaga(params) {
  console.debug("ControlDetailsSaga");
  try {
    const { data, status, error } = yield call(getJhaControlDetails, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaControlDetailsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha ControlType List Failed" };
    yield put(jhaAction.getJhaControlDetailsFailure(data));
  }
}

export function* addJhaConductSaga(params) {
  console.debug("addJhaConductSaga");
  try {
    const { data, status, error } = yield call(addJhaConduct, params);
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.addJhaConductSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Jha Conduct Failed" };
    yield put(jhaAction.addJhaConductFailure(data));
  }
}

export function* getJhaTempleteDetailsSaga(datas) {
  console.debug("getJhaTempleteDetailsSaga");
  try {
    const { data, status, error } = yield call(getJhaTempleteDetsils, datas);
    if (!data && status !== 200) throw apiError(error);
    const jhaPage = jhaType.JHA_PAGE;
    yield put(
      jhaAction.getJhaTempeleteDetsilsSuccess({
        jhaPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha Templete Details Data Failed" };
    yield put(jhaAction.getJhaTempeleteDetailsFailure(data));
  }
}

export function* updateJhaTemplateSaga(params) {
  try {
    const { data, status, error } = yield call(updateJhaTemplate, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.updateJhaTemplateSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Template Failed" };
    yield put(jhaAction.updateJhaTemplateFailure(data));
  }
}

export function* jhaConductDetailsSaga(params) {
  console.debug("ConductDetailsSaga");
  try {
    const { data, status, error } = yield call(getJhaConductDetails, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaConductDetailsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha Conduct Details List Data Failed" };
    yield put(jhaAction.getJhaConductDetailsFailure(data));
  }
}

export function* addJhaSetupSaga(params) {
  console.debug("addJhaSetupSaga");
  try {
    const { data, status, error } = yield call(addJHASetup, params);
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.addJhaSetupSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Add Setup Failed" };
    yield put(jhaAction.addJhaSetupFailure(data));
  }
}

export function* jhaSetupListSaga(params) {
  console.debug("setupListSaga");
  try {
    const { data, status, error } = yield call(getJhaSetupList, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaSetupListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha Setup List Failed" };
    yield put(jhaAction.getJhaSetupListFailure(data));
  }
}

export function* activeInactiveJHASetupSaga(params) {
  console.debug("activeInactiveJHASetupSaga");
  try {
    const { data, status, error } = yield call(activeInactiveJHASetup, params);
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.activeInactiveJHASetupSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "activeInactiveJHASetup Failed" };
    yield put(jhaAction.activeInactiveJHASetupFailure(data));
  }
}

export function* getJHAReferenceDataSaga(params) {
  console.debug("getJHAReferenceData");
  try {
    const { data, status, error } = yield call(getJHAReferenceData, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJHAReferenceDataSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha getJHAReferenceData Failed" };
    yield put(jhaAction.getJHAReferenceDataFailure(data));
  }
}

export function* jhaSetupDetailsSaga(params) {
  console.debug("SetupDetailsSaga");
  try {
    const { data, status, error } = yield call(getJHASetupDetail, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaSetupDetailsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha Setup Details List Data Failed" };
    yield put(jhaAction.getJhaSetupDetailsFailure(data));
  }
}

export function* updateJhaSetupSaga(params) {
  try {
    const { data, status, error } = yield call(updateJHASetup, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.updateJhaSetupSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Setup Failed" };
    yield put(jhaAction.updateJhaSetupFailure(data));
  }
}

export function* getJHASetupDashboardSaga(params) {
  console.debug("getJHASetupDashboard");
  try {
    const { data, status, error } = yield call(getJHASetupDashboard, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJHASetupDashboardSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getJHASetupDashboard Data Failed" };
    yield put(jhaAction.getJHASetupDashboardFailure(data));
  }
}

export function* getSaftyLogJHAConductListSaga(params) {
  console.debug("getSaftyLogJHAConductList");
  try {
    const { data, status, error } = yield call(
      getSaftyLogJHAConductList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getSaftyLogJHAConductListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getSaftyLogJHAConductList Data Failed" };
    yield put(jhaAction.getSaftyLogJHAConductListFailure(data));
  }
}

export function* jhaActiveTemplateListSaga(params) {
  console.debug("ActiveTemplateListSaga");
  try {
    const { data, status, error } = yield call(
      getJhaActiveTemplateList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      jhaAction.getJhaActiveTemplateListSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Jha Active Template List Failed" };
    yield put(jhaAction.getJhaActiveTemplateListFailure(data));
  }
}

const myJhaSaga = [
  takeLatest(jhaType.POST_JHA_ADD_CATEGORY, addJhaCategorySaga),
  takeLatest(jhaType.GET_JHA_CATEGORY_LIST, jhaCategoryListSaga),
  takeLatest(jhaType.UPDATE_JHA_CATEGORY, updateJhaCategorySaga),
  takeLatest(jhaType.POST_JHA_ADD_ACTIVITY, addJhaActivitySaga),
  takeLatest(jhaType.GET_JHA_ACTIVITY_LIST, jhaActivityListSaga),
  takeLatest(jhaType.UPDATE_JHA_ACTIVITY, updateJhaActivitySaga),
  takeLatest(jhaType.POST_JHA_ADD_HAZARD, addJhaHazardSaga),
  takeLatest(jhaType.GET_JHA_HAZARDS_LIST, jhaHazardsListSaga),
  takeLatest(jhaType.UPDATE_JHA_HAZARD, updateJhaHazardSaga),
  takeLatest(jhaType.GET_JHA_CONTROL_LIST, jhaControlListSaga),
  takeLatest(jhaType.POST_JHA_ADD_CONTROL, addJhaControlSaga),
  takeLatest(jhaType.UPDATE_JHA_CONTROL, updateJhaControlSaga),
  takeLatest(jhaType.GET_CONDUCT_JHA_LIST, conductJhaListSaga),
  takeLatest(jhaType.POST_JHA_ADD_TEMPLATE, addJhaTemplateSaga),
  takeLatest(jhaType.GET_JHA_TEMPLATE_LIST, jhaTemplateListSaga),
  takeLatest(
    jhaType.POST_JHA_UPDATE_TEMPLATE_STATUS,
    updateJhaTemplateStatusSaga
  ),
  takeLatest(jhaType.POST_JHA_ADD_CONDUCT, addJhaConductSaga),
  takeLatest(
    jhaType.GET_JHA_TEMPLETE_DETAILS_PENDING,
    getJhaTempleteDetailsSaga
  ),
  takeLatest(jhaType.GET_JHA_CONTROL_TYPE_LIST, jhaControlTypeListSaga),
  takeLatest(jhaType.GET_JHA_CONTROL_DETAILS, jhaControlDetailsSaga),
  takeLatest(jhaType.GET_JHA_CONDUCT_DETAILS, jhaConductDetailsSaga),
  takeLatest(jhaType.UPDATE_JHA_TEMPLATE, updateJhaTemplateSaga),
  takeLatest(jhaType.POST_JHA_ADD_SETUP, addJhaSetupSaga),
  takeLatest(jhaType.GET_JHA_SETUP_LIST, jhaSetupListSaga),
  takeLatest(
    jhaType.POST_JHA_ACTIVE_INACTIVE_SETUP,
    activeInactiveJHASetupSaga
  ),
  takeLatest(jhaType.GET_JHA_REFERENCE_DATA, getJHAReferenceDataSaga),
  takeLatest(jhaType.GET_JHA_SETUP_DETAILS, jhaSetupDetailsSaga),
  takeLatest(jhaType.UPDATE_JHA_SETUP, updateJhaSetupSaga),
  takeLatest(jhaType.GET_JHA_SETUP_DASHBOARD, getJHASetupDashboardSaga),
  takeLatest(
    jhaType.GET_JHA_SAFETYLOG_CONDUCTLIST,
    getSaftyLogJHAConductListSaga
  ),
  takeLatest(jhaType.GET_JHA_ACTIVE_TEMPLATE_LIST, jhaActiveTemplateListSaga),
];

export default myJhaSaga;
