import React, { useState, useEffect } from "react";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Buttons from "../../../../library/custom/button/Button";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { ISwitch, USER_ID } from "../../../../constant/constant";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import {
  INSPECTION_GENERATOR,
  LINK_QUESTION_INSPECTION,
  SW_CATEGORIES,
  SW_SUB_CATEGORIES,
} from "../../../../constant/routeContant";
import ToolBar from "../../../../library/storm-water/toolBar";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const ItemsFoundResponses = ({
  getSubCategoriesList,
  getQuestionListData,
  getQuestionList,
  isLoading,
  onSubmit,
  updateSwQuestion,
  questionListStatus,
}) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalData, setModalData] = useState();
  const handleCloseModal = () => setOpenModal(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const intialState = {
    value: "",
    subCategoriesValue: "",
    questionValue: [],
  };
  const modalIntialState = {
    value: "",
  };
  const [modalFormValue, setModalFormValue] = useState(modalIntialState);
  const [formValue, setFormValue] = useState(intialState);
  const [questionName, setIsQuestionName] = useState(false);
  const [isQuestionOptionName, setIsQuestionOptionName] = useState(false);
  const [subCategoriesName, setSubCategoriesName] = useState(false);
  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [questionOption, setQuestionOption] = useState("");
  const [questionDetail, setQuestionDetail] = useState();
  const [isOptionName, setIsOptionName] = useState(false);
  const [modalErrorsMsgShowActive, setModalErrorsMsgShowActive] =
    useState(false);
  const [modalErrorsMsgShow, setModalErrorsMsgShow] = useState();

  const handleSubCategoriesChange = (e) => {
    setFormValue({
      ...formValue,
      subCategoriesValue: e.target.value,
    });
    const data = {
      subcatId: [e.target.value],
    };
    getQuestionListData(data);
  };

  const handleQuestionChange = (e) => {
    setFormValue({
      ...formValue,
      questionValue: e.target.value,
    });
  };

  function mappedData() {
    let errors = false;
    if (formValue?.questionValue?.length === 0) {
      setIsQuestionName(true);
      errors = true;
    }
    if (formValue?.subCategoriesValue?.length === 0) {
      seterrorsMsgShowActive(false);
      setSubCategoriesName(true);
      errors = true;
    }
    if (questionOption?.trim() === "") {
      setQuestionOption("");
      setIsQuestionOptionName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    const index = questionDetail?.options?.findIndex(
      (object) => object.value.toUpperCase() === questionOption.toUpperCase()
    );
    if (index === -1) {
      seterrorsMsgShowActive(false);
      setErrorsMsgShow("");
      const questionList = [
        {
          key: questionDetail?.key,
          value: questionDetail?.value,
          status: questionDetail?.status,
          isTextAnswerType: questionDetail?.isTextAnswerType,
          options: [
            {
              key: 0,
              value: questionOption,
              status: true,
            },
          ],
        },
      ];
      return {
        questionList,
        userId: parseInt(USER_ID()),
      };
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Question Option already added");
    }
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  function mappedModalData() {
    let errors = false;
    if (modalFormValue?.value.trim() === "") {
      setModalFormValue({
        ...modalFormValue,
        value: "",
      });
      setModalErrorsMsgShowActive(false);
      setIsOptionName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }

    const index = questionDetail?.options?.findIndex(
      (obect) =>
        obect.value?.toUpperCase() === modalFormValue?.value?.toUpperCase()
    );
    if (index === -1) {
      setModalErrorsMsgShowActive(false);
      const questionList = [
        {
          key: questionDetail?.key,
          value: questionDetail?.value,
          status: questionDetail?.status,
          isTextAnswerType: questionDetail?.isTextAnswerType,
          options: [
            {
              key: modalData?.key,
              value: modalFormValue?.value,
              status: modalData?.status,
            },
          ],
        },
      ];
      return {
        questionList,
        userId: parseInt(USER_ID()),
      };
    } else {
      setModalErrorsMsgShowActive(true);
      setModalErrorsMsgShow("Option Name already added");
    }
  }

  const submitModalData = () => {
    const data = mappedModalData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  useEffect(() => {
    if (
      updateSwQuestion?.status === "SUCCESS" &&
      updateSwQuestion?.isLoading === false
    ) {
      setIsSubmit(false);
      setQuestionOption("");
      const data = {
        subcatId: [formValue?.subCategoriesValue],
      };
      getQuestionListData(data);
      setOpenModal(false);
      setAnchorEl(false);
      setIsQuestionOptionName(false);
    }
  }, [updateSwQuestion]);

  useEffect(() => {
    if (formValue.questionValue) {
      const questionDetail = getQuestionList?.find(
        (item) => item.key === formValue.questionValue
      );
      setQuestionDetail(questionDetail);
    }
  }, [formValue.questionValue]);

  useEffect(() => {
    if (questionListStatus === false) {
      const questionDetail = getQuestionList?.find(
        (item) => item.key === formValue.questionValue
      );
      setQuestionDetail(questionDetail);
    }
  }, [questionListStatus]);

  const handleEditOnClick = (modalData) => {
    setOpenModal(true);
    setModalData(modalData);
    setModalFormValue({
      value: modalData?.value,
    });
  };

  const handleChange = (item) => {
    const newOptionList = [...questionDetail?.options];
    newOptionList.forEach((lists) => {
      if (lists.key == item.key) {
        lists.status = !lists.status;
        lists.checked = !lists.checked;
        const questionList = [
          {
            key: questionDetail?.key,
            value: questionDetail?.value,
            status: questionDetail?.status,
            isTextAnswerType: questionDetail?.isTextAnswerType,
            options: [
              {
                key: item?.key,
                value: item?.value,
                status: item?.status,
              },
            ],
          },
        ];
        onSubmit({
          questionList,
          userId: parseInt(USER_ID()),
        });
      }
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dataBack = {
    title: "Back to Inspection Configuration",
    route: -1,
  };

  const columns = [
    {
      field: "value",
      headerName: "Item Found Responses",
      flex: 4,
    },

    {
      flex: 1.5,
      field: "status",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox swins">
                {checkPrivileges([12, 93]) && (
                  <FormControlLabel
                    control={
                      <ISwitch
                        checked={rows?.row?.status ? rows?.row?.status : false}
                        onChange={() => handleChange(rows?.row)}
                        name="status"
                      />
                    }
                  />
                )}
                <div>{rows?.row?.status ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={questionDetail?.options?.length ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={questionDetail?.options ?? []}
          fileNameXL={"resourcesList"}
        />
      ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            edit={checkPrivileges([12, 93, 94])}
            editOnClick={() => handleEditOnClick(rows?.row)}
            editLabel="Edit Item Found Response"
          />
        );
      },
    },
  ];
  const toolBarData = {
    title: "Inspection Form Configuration",
    list: [
      {
        id: 1,
        title: "Inspection Header",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 2,
        title: "Category Configuration",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 3,
        title: "Sub Category Configuration",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 4,
        title: "Question Configuration",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 5,
        title: "Item Found Responses",
        link: "",
        activeClass: "active",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
    ],
  };
  return (
    <>
      <section className="grid_main_sec">
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid className="modalContainer">
            <Box className="modalBox">
              <div className="modalTitle">Edit Option</div>

              <div className="modalInputBox">
                <InputField
                  type="text"
                  isRequired={true}
                  value={modalFormValue?.value}
                  onChange={(e) =>
                    setModalFormValue({
                      ...modalFormValue,
                      value: e.target.value,
                    })
                  }
                  label="Edit Item Found Response"
                  placeholder="Option Here..."
                  isError={isOptionName}
                  errorMsg={isOptionName ? "Option is Required" : ""}
                />
                {modalErrorsMsgShowActive && (
                  <div className="errorMsg">{modalErrorsMsgShow}</div>
                )}
              </div>
              <Grid container className="modalFooterBox">
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientText={true}
                    label="Cancel"
                    onClick={handleCloseModal}
                  />
                </Grid>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientContained={true}
                    label={"Save"}
                    onClick={submitModalData}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Modal>

        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Items Found Responses</div>
        </div>

        <Grid container>
          <Grid item md={8} xs={12}>
            <Grid container mt={4}>
              <Grid md={6} lg={6} xs={12} item>
                <FormControl fullWidth>
                  <FormControl fullWidth>
                    <FormLabels label="Select Sub Category" isRequired={true} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={getSubCategoriesList ?? []}
                      value={formValue.subCategoriesValue}
                      onchangehandler={(e) => handleSubCategoriesChange(e)}
                      isError={subCategoriesName}
                      errorMsg={
                        subCategoriesName ? "Sub Category is Required" : ""
                      }
                    />
                  </FormControl>
                </FormControl>
              </Grid>

              <Grid md={10} lg={10} xs={12} mt={3} item>
                <FormControl fullWidth>
                  <FormControl fullWidth>
                    <FormLabels label="Questions" isRequired={true} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={getQuestionList ?? []}
                      value={formValue.questionValue}
                      onchangehandler={(e) => handleQuestionChange(e)}
                      isError={questionName}
                      errorMsg={questionName ? "Question is Required" : ""}
                    />
                  </FormControl>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={3} mb={5}>
              <Grid md={6} lg={6} xs={8} item>
                <InputField
                  type="text"
                  isRequired={true}
                  label="Item Found Response"
                  placeholder="Enter here"
                  value={questionOption}
                  onChange={(e) => setQuestionOption(e.target.value)}
                  isError={isQuestionOptionName}
                  errorMsg={isQuestionOptionName ? "Response is Required" : ""}
                />
                {errorsMsgShowActive && (
                  <p className="error">{errorsMsgShow}</p>
                )}
              </Grid>
              <Grid
                className="smallAddBtn"
                item
                lg={1.5}
                md={3}
                xs={3}
                ml={3}
                mt={4}
              >
                {checkPrivileges([12, 93, 94]) && (
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Create"}
                    id="btnAddEquipment"
                    varientAddIconBlue={true}
                    disabled={isSubmit ? true : false}
                    onClick={submitData}
                  ></Buttons>
                )}
              </Grid>
            </Grid>

            <div className="grid_main_body">
              <div className="desc_box row_uniq">
                <div className="table_style_ui">
                  <GridTable
                    getRowId={(r) => r.key}
                    rows={questionDetail?.options ?? []}
                    rowCount={questionDetail?.options?.length ?? 0}
                    columns={columns}
                    checkboxSelection={false}
                    loading={isLoading}
                    page={1}
                    pagination={false}
                    pageSize={questionDetail?.options?.length}
                    rowsPerPageOptions={[10]}
                    // onPageChange={handlePagignation}
                    backIconButtonProps={{ disabled: false }}
                    disableSelectionOnClick
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                    hideFooterPagination={true}
                  />
                </div>
                <div className="insBackBtn">
                  <BackBtn dataBack={dataBack} />
                </div>
              </div>
            </div>
          </Grid>

          <Grid lg={4} md={12} xs={12} item className="inspectionTool">
            <div className="toolBarBox">
              <ToolBar
                toolBarData={toolBarData}
                handleClick={(e) => {
                  if (e.id === 1) {
                    navigate(INSPECTION_GENERATOR);
                  }
                  if (e.id === 2) {
                    navigate(SW_CATEGORIES);
                  }
                  if (e.id === 3) {
                    navigate(SW_SUB_CATEGORIES);
                  }
                  if (e.id === 4) {
                    navigate(LINK_QUESTION_INSPECTION);
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default ItemsFoundResponses;
