import { assignType } from "../constants/assignType";

export const createAssign = (data) => ({
  type: assignType.POST_ASSIGN_PENDING,
  data,
});

export const createAssignSuccess = (data) => ({
  type: assignType.POST_ASSIGN_SUCCESS,
  data,
});

export const createAssignFailure = (error) => ({
  type: assignType.POST_ASSIGN_FAILURE,
  error,
});

export const updateAssign = (data) => ({
  type: assignType.PUT_ASSIGN_PENDING,
  data,
});

export const updateAssignuccess = (data) => ({
  type: assignType.PUT_ASSIGN_SUCCESS,
  data,
});

export const updateAssignFailure = (error) => ({
  type: assignType.PUT_ASSIGN_FAILURE,
  error,
});

export const getAssignList = (data) => ({
  type: assignType.GET_ASSIGN_LIST_PENDING,
  data,
});

export const getAssignListSuccess = (data) => ({
  type: assignType.GET_ASSIGN_LIST_SUCCESS,
  data,
});

export const getAssignListFailure = (error) => ({
  type: assignType.GET_ASSIGN_LIST_FAILURE,
  error,
});
