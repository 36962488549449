import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./viewContact.scss";
import BackBtn from "../../../../library/custom/backBtn/backBtn";

const ViewContact = ({ viewContactData, dataBack }) => {
  const data = viewContactData?.result;

  return (
    <div className="viewOverideContact">
      <BackBtn dataBack={dataBack} />
      <div className="commonOveride">
        <Box>
          <Typography className="titleDetail">
            {data?.firstName} {data?.lastName}
          </Typography>
        </Box>
        <Box className="commoninfoWrap">
          <Box className="commoninfoBox">
            <Box className="commoninfoleft">
              <Typography className="title">Personnel information:</Typography>

              <Box className="columns">
                <Typography className="listView">
                  Company Name:
                  <span className="focusedText">{data?.companyName}</span>
                </Typography>
                <Typography className="listView">
                  Job Title:
                  <span className="focusedText">{data?.designation}</span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  Phone Number:
                  <span className="font-w400">{data?.phone}</span>
                </Typography>
                <Typography className="listView">
                  Email ID:
                  <span className="font-w400">{data?.email}</span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  Address1:
                  <span className="font-w400">{data?.address1}</span>
                </Typography>
                <Typography className="listView">
                  Address2:
                  <span className="font-w400">{data?.address2}</span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  State:
                  <span className="font-w400">{data?.stateName}</span>
                </Typography>
                <Typography className="listView">
                  City:
                  <span className="font-w400">{data?.cityName}</span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  Office Number:
                  <span className="font-w400">{data?.officePhone}</span>
                </Typography>
                <Typography className="listView">
                  Zip Code:
                  <span className="font-w400">{data?.zipCode}</span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView ">
                  Service Type:
                  {data?.product?.map((item) => (
                    <span className="font-w400" key={item?.key}>
                      {item?.value}
                    </span>
                  ))}
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView desNone">
                  Notes:
                  <span className="font-w400">{data?.notes}</span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default ViewContact;
