import Box from "@mui/material/Box";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import Loader from "../../../library/common/Loader";
import * as viewPersonalInjuryAction from "../../../redux/actions/perosnalInjuryAction";
import * as viewVehicleAccidentAction from "../../../redux/actions/vehicalAccidentAction";
import * as viewStolenPropertyAction from "../../../redux/actions/stolenPropertyAction";
import * as viewNearMissAction from "../../../redux/actions/nearMissesAction";
import * as viewUtilityDamageAction from "../../../redux/actions/utilityDamageAction";
import { getFormatedDate } from "../../../utils/helper";
import "../../../scss/pages/viewIncident.scss";
import PersonalImg from "../../../constant/carouselConstant";
import ViewIncidentReportHeader from "../common/viewIncidentReportHeader/viewIncidentReportHeader";
import OtherIncidents from "../common/otherIncidents/otherIncidents";
import BasicInformation from "../common/basicInformation/basicInformation";
import PersonnalInformation from "../common/personalInjury/personnalInformation";
import InjuryDetails from "../common/personalInjury/injuryDetails";
import WitnessReport from "../common/witnessReport/witnessReport";
import ViewAction from "../common/viewAction/viewAction";
import Appendix from "../common/appendix/appendix";
import PersonalInjDetails from "../common/personalInjury/addDetail";
import AccidentDetails from "../common/vehicleAccident/accidentDetails";
import PoliceReporting from "../common/vehicleAccident/policeReporting";
import AdditionalVehicleDetails from "../common/vehicleAccident/additionalVehicleDetails";
import OtherStolenStatus from "../common/stolenProperty/stolenStatus";
import StolenMultipleProperty from "../common/stolenProperty/stolenMultipleProperty";
import MultipleEmployee from "../common/nearMiss/multipleEmployee";
import UtilityMultipleDamage from "../common/utilityDamage/utilityMultipleDamage";
import UtilityDamageDetail from "../common/utilityDamage/utilityDamageDetail";
import vehicleAccident from "../../../constant/accidentConstant";
import { Grid } from "@mui/material";
import { LOGIN_ORG_ID, ORG_ID } from "../../../constant/constant";

const ViewIncidentReportContainer = () => {
  const showLoader = useSelector(
    (state) =>
      state.getPersonalReport.isLoading ||
      state.getAccidentReport.isLoading ||
      state.getLossPropertyReport.isLoading ||
      state.getNearMissReport.isLoading ||
      state.getUtilityDamageReport.isLoading
  );

  const location = useLocation();
  const caseID = location?.state?.caseId;
  const incidentID = location?.state?.incidentId;
  const incidentType = location?.state?.incidentType;
  const dispatch = useDispatch();
  const personalReportData = useSelector((state) =>
    incidentType === 1
      ? state?.getPersonalReport?.data
      : incidentType === 2
      ? state?.getAccidentReport?.data
      : incidentType === 3
      ? state.getLossPropertyReport.data
      : incidentType === 4
      ? state.getUtilityDamageReport.data
      : state.getNearMissReport.data
  );

  const associatedIncidentData =
    personalReportData?.incCase?.associatedIncident;
  const propertyStatus =
    personalReportData?.wasProperty === true ? "Stolen" : "Lost";
  const headerData = {
    caseId: personalReportData?.incCase?.caseId,
    report_id: incidentID,
    name: personalReportData?.personalInjury?.createdByName,
    conductedon:
      personalReportData?.personalInjury?.createdDatetime ??
      personalReportData?.createdDatetime,
    status: personalReportData?.status,
  };
  const basicInformationData = {
    prepared_name: personalReportData?.incCase?.createdByName,
    dateTitle: "Date of Case:",
    timeTitle: "Time of Case:",
    dateOfCase: personalReportData?.incCase?.dateOfCase,
    timeOfCase: personalReportData?.incCase?.timeOfCase,
    description: personalReportData?.incCase?.description,
    location: personalReportData?.incCase?.location,
    basicImgUrl:
      incidentType === 1
        ? PersonalImg[0]?.img
        : incidentType === 2
        ? PersonalImg[1]?.img
        : incidentType === 3
        ? PersonalImg[2]?.img
        : incidentType === 4
        ? PersonalImg[3]?.img
        : PersonalImg[0]?.img,
    basicName:
      incidentType === 1
        ? PersonalImg[0]?.title
        : incidentType === 2
        ? PersonalImg[1]?.title
        : incidentType === 3
        ? PersonalImg[2]?.title
        : incidentType === 4
        ? PersonalImg[3]?.title
        : PersonalImg[4]?.title,
  };

  const accidentDetails = {
    accident_location:
      personalReportData?.vehicleAccident?.intersectionRoad +
      (personalReportData?.vehicleAccident?.intersectionRoad?.length > 0
        ? ", "
        : "") +
      personalReportData?.vehicleAccident?.roadway +
      (personalReportData?.vehicleAccident?.roadway?.length > 0 ? ", " : "") +
      (personalReportData?.vehicleAccident?.state !== null
        ? personalReportData?.vehicleAccident?.state
        : "") +
      (personalReportData?.vehicleAccident?.state?.length > 0 ? ", " : "") +
      (personalReportData?.vehicleAccident?.city !== null
        ? personalReportData?.vehicleAccident?.city
        : ""),
    speed_limit: personalReportData?.vehicleAccident?.postedSpeedLimit,
    weather: vehicleAccident.filter(
      (i) => i.key === personalReportData?.vehicleAccident?.weatherTypeId
    ),
    traffic: personalReportData?.vehicleAccident?.trafficType,
    contributing_factor:
      personalReportData?.vehicleAccident?.contributingFactorsList?.map(
        (i) => i.value
      ),
    description: personalReportData?.vehicleAccident?.description,
  };

  const policeReporting = {
    police_department: personalReportData?.vehicleAccident?.respondingPolice,
    police_case_number: personalReportData?.vehicleAccident?.policeCaseNumber,
    total_injuries: personalReportData?.vehicleAccident?.numberOfInjuries,
    picturesOfRoadway: personalReportData?.vehicleAccident?.files,
  };

  const vehicleDetailsData = {
    driver_name: personalReportData?.vehicleDetails?.nameOfDriver,
    reg_no: personalReportData?.vehicleDetails?.vehicleId,
    unit_number: personalReportData?.vehicleDetails?.numberOfInjuries,
    modal: "",
    occupants: personalReportData?.vehicleDetails?.numberOfOccupants,
    vehicle_towed: personalReportData?.vehicleDetails?.isVehicleTowed,
    ticket_issued: personalReportData?.vehicleDetails?.isTicketIssuedToDriver,
    under_influence: personalReportData?.vehicleDetails?.isInfluenceOfDrugs,
  };

  const personalData = {
    employment_type: personalReportData?.personalInjury?.employmentType,
    company_name: personalReportData?.personalInjury?.companyName,
    employee_name: personalReportData?.personalInjury?.employee,
    supervisor_name: personalReportData?.personalInjury?.supervisor,
    job_title: personalReportData?.personalInjury?.jobTitle,
    start_date: getFormatedDate(personalReportData?.personalInjury?.startDate),
    job_site: personalReportData?.personalInjury?.jobSite,
    location: personalReportData?.personalInjury?.incidentLocation,
  };

  const appendixImg = () => {
    // if (personalReportData?.incCase?.modifiedDatetime) {\
    const injImg = [];
    const injTotalImg = personalReportData?.personalInjury?.files?.map((i) =>
      injImg.push(i.value)
    );
    const roadwayImg = [];
    const roadwayTotalImg = personalReportData?.vehicleAccident?.files?.map(
      (i) => roadwayImg.push(i.value)
    );

    const propertyImg = [];
    const propertyTotalImg = personalReportData?.stolenProperty?.map((item) =>
      item?.files?.map((i) => propertyImg.push(i.value))
    );
    const additionalVehicleImg = [];
    const additionalVehicleTotalImg =
      personalReportData?.vehicleAccident?.vehicles?.map((item) =>
        item?.files?.map((i) => additionalVehicleImg.push(i.value))
      );
    const totalWitnessImg = [];
    const witnessImg = personalReportData?.witness?.map((x) => x.files);
    const witTotalImg = witnessImg?.forEach((itemValue) => {
      const itemValueImg = itemValue?.split("|");
      totalWitnessImg?.push(itemValueImg);
    });
    const witneshPushImg = [];
    const allWitness = totalWitnessImg.forEach((imgSprat) => {
      const allimgSprat = imgSprat?.forEach((itemWitness) => {
        const allWitnessValue = itemWitness;
        witneshPushImg.push(allWitnessValue);
      });
    });

    const totalActionImg = [];
    const actionImg = personalReportData?.actions?.map((x) =>
      x?.files ? x?.files[0]?.filePath : ""
    );
    const acTotalImg = actionImg?.forEach((itemValue, i) => {
      const actionImgValue = itemValue;
      totalActionImg?.push(actionImgValue);
    });
    const totalImg = [
      ...injImg,
      ...witneshPushImg,
      ...totalActionImg,
      ...roadwayImg,
      ...additionalVehicleImg,
      ...propertyImg,
    ];
    return totalImg;
    // }
  };

  const injuryData = {
    employment_type: personalReportData?.personalInjury?.employmentType,
    description_injury: personalReportData?.personalInjury?.descriptionOfInjury,
    contributing_factor:
      personalReportData?.personalInjury?.contributingFactorsList.map(
        (i) => i.value
      ),
    injury_type: personalReportData?.personalInjury?.typeOfInjuryList.map(
      (i) => i.value
    ),
    body_part: personalReportData?.personalInjury?.bodyPartInjuredList.map(
      (i) => i.value
    ),
    basic_injury:
      personalReportData?.personalInjury?.isTreatedByFirstAid === true
        ? "Yes"
        : "No",
    basic_injury_value: personalReportData?.personalInjury?.treatedTypeValue,
    injury_recordable:
      personalReportData?.personalInjury?.wasRecordableInOshaId == 0
        ? "Yes"
        : personalReportData?.personalInjury?.wasRecordableInOshaId == 1
        ? "No"
        : personalReportData?.personalInjury?.wasRecordableInOshaId == 2
        ? "Not Sure"
        : "",
    case_number: incidentID,
    objectOrSubstance: personalReportData?.osha301?.objectOrSubstance,
    employee_name: personalReportData?.personalInjury?.employee,
    job_title: personalReportData?.personalInjury?.jobTitle,
    // data_injury: injuryDate,
    // injury_location: eventLocation,
    // machine: injuryObject,
    classify_case: personalReportData?.osha301?.classifyValue,
    typeOfIllnessValue: personalReportData?.osha301?.typeOfIllnessValue,
    classifyHowManyDays: personalReportData?.osha301?.classifyHowManyDays,
    // total_days: injuryDays,
    start_date: personalReportData?.incCase?.dateOfCase,
    illness_type: personalReportData?.osha301?.typeOfIllness,
    accident_image: personalReportData?.personalInjury?.files?.map(
      (i) => i.value
    ),
    location: personalReportData?.personalInjury?.incidentLocation,
  };
  const addDetails = {
    detail:
      incidentType === 1
        ? personalReportData?.personalInjury?.additionalDetails
        : incidentType === 2
        ? personalReportData?.vehicleAccident?.additionalDetails
        : personalReportData?.additionalDetails,
  };

  useEffect(() => {
    incidentType === 1
      ? dispatch(
          viewPersonalInjuryAction.getPersonalReportDetails(caseID, incidentID)
        )
      : incidentType === 2
      ? dispatch(
          viewVehicleAccidentAction.getVehicalAccidentDetails(
            caseID,
            incidentID
          )
        )
      : incidentType === 3
      ? dispatch(viewStolenPropertyAction.getStolenProperty(caseID, incidentID))
      : incidentType === 4
      ? dispatch(
          viewUtilityDamageAction.getUtilityDamageDetails(caseID, incidentID)
        )
      : incidentType === 5
      ? dispatch(viewNearMissAction.getNearMisses(caseID, incidentID))
      : console.log(incidentType);
  }, [dispatch]);

  // React PDF
  const componentRef = useRef(null);
  const [pdfloader, setPdfLoader] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Incident Report",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
    onBeforeGetContent: () => setPdfLoader(true),
    onAfterPrint: () => setPdfLoader(false),
    onPrintError: () => setPdfLoader(false),
  });
  const isClient = ORG_ID() !== LOGIN_ORG_ID();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clientLogo = localStorage.getItem("orgLogo");
  const clientName = localStorage.getItem("orgName");
  const logoCheck = isClient ? clientLogo : userInfo?.logo;

  return (
    <>
      {pdfloader && <Loader />}

      {showLoader ? (
        <Loader />
      ) : (
        <div className="overideView">
          <Box ref={componentRef} className="viewBox">
            <div className="incedentTitle repotPdfHeading">
              {" "}
              View Incident Report
            </div>
            <Grid item md={12} className="clientPdfDetail">
              <Grid className="clientName">
                {isClient ? clientName : userInfo?.clientName}
              </Grid>
              <Grid className="clientLogo">
                {logoCheck && <img src={logoCheck} alt="client Log" />}
              </Grid>
            </Grid>
            <ViewIncidentReportHeader
              handlePrint={handlePrint}
              headerData={headerData}
            />
            <OtherIncidents incData={associatedIncidentData} />
            {incidentType === 3 && (
              <OtherStolenStatus stolenStatusData={propertyStatus} />
            )}

            <BasicInformation basicInformationData={basicInformationData} />
            {incidentType === 4 && (
              <UtilityMultipleDamage additionalData={personalReportData} />
            )}
            {incidentType === 5 && (
              <MultipleEmployee additionalData={personalReportData?.employes} />
            )}
            {incidentType === 3 && (
              <StolenMultipleProperty additionalData={personalReportData} />
            )}
            {incidentType === 1 && (
              <PersonnalInformation personalData={personalData} />
            )}
            {incidentType === 1 && <InjuryDetails injuryData={injuryData} />}

            {incidentType === 2 && (
              <AccidentDetails accidentDetails={accidentDetails} />
            )}
            {incidentType === 2 && (
              <PoliceReporting policeReporting={policeReporting} />
            )}
            {(incidentType === 1 ||
              incidentType === 2 ||
              incidentType === 5) && (
              <WitnessReport witnessData={personalReportData?.witness} />
            )}
            {incidentType === 2 && (
              <AdditionalVehicleDetails
                additionalData={personalReportData?.vehicleAccident}
              />
            )}
            <ViewAction items={personalReportData?.actions} />
            {incidentType === 4 && (
              <UtilityDamageDetail addDetails={personalReportData} />
            )}
            {(incidentType === 1 ||
              incidentType === 2 ||
              incidentType === 3 ||
              incidentType === 5) && (
              <PersonalInjDetails addDetails={addDetails} />
            )}
            <Appendix appendixImg={appendixImg()} />
          </Box>
        </div>
      )}
    </>
  );
};
export default ViewIncidentReportContainer;
