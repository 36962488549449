import { loginTypes } from "../constants/loginTypes";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "login",
  userSuccess: false,
  userInfo: {},
  isError: false,
  isLoading: false,
  isOnboarded: false,
  error: {},
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginTypes.POST_LOGIN_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
        error: {},
      };
    case loginTypes.POST_LOGIN_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
        flow: "login",
      };
    case loginTypes.POST_LOGIN_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        userSuccess: false,
        isError: true,
        isLoading: false,
        error: { ...action.error },
        userInfo: {},
      };
    case loginTypes.POST_REDIRECT_URL_PENDINGSUCCESS:
      return {
        ...state,
        url: action.pathName,
      };

    case loginTypes.CLEAR_ERRORS:
      return {
        ...state,
        returnToLogin: false,
        error: { ...action.error },
        isError: false,
        userSuccess: false,
        isLoading: false,
      };
    case loginTypes.LOGOUT_SESSION:
      return {
        ...state,
        loginPage: action.loginPage,
      };
    default:
      return state;
  }
};

//push notification
export const NotifiactionLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginTypes.POST_NOTIFICATION_LOGIN_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case loginTypes.POST_NOTIFICATION_LOGIN_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case loginTypes.POST_NOTIFICATION_LOGIN_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const NotifiactionLogoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginTypes.POST_NOTIFICATION_LOGOUT_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case loginTypes.POST_NOTIFICATION_LOGOUT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case loginTypes.POST_NOTIFICATION_LOGOUT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const NotifiactionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginTypes.GET_NOTIFICATION_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case loginTypes.GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case loginTypes.GET_NOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const updateNotifiactionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginTypes.POST_UPDATE_NOTIFICATION_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case loginTypes.POST_UPDATE_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case loginTypes.POST_UPDATE_NOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
