import { reportType } from "../constants/reportType";

export const getCredentialReport = (data) => ({
  type: reportType.GET_CREDENTIAL_REPORT_PENDING,
  data,
});

export const getCredentialReportSuccess = (data) => ({
  type: reportType.GET_CREDENTIAL_REPORT_SUCCESS,
  data,
});

export const getCredentialReportFailure = (error) => ({
  type: reportType.GET_CREDENTIAL_REPORT_FAILURE,
  error,
});

export const getLookupCredentialReport = (data) => ({
  type: reportType.GET_LOOKUP_CREDENTIAL_REPORT_PENDING,
  data,
});

export const getLookupCredentialReportSuccess = (data) => ({
  type: reportType.GET_LOOKUP_CREDENTIAL_REPORT_SUCCESS,
  data,
});

export const getLookupCredentialReportFailure = (error) => ({
  type: reportType.GET_LOOKUP_CREDENTIAL_REPORT_FAILURE,
  error,
});

export const getConfiguredCredentialReport = (data) => ({
  type: reportType.GET_CONFIGURED_CREDENTIAL_REPORT_PENDING,
  data,
});

export const getConfiguredCredentialReportSuccess = (data) => ({
  type: reportType.GET_CONFIGURED_CREDENTIAL_REPORT_SUCCESS,
  data,
});

export const getConfiguredCredentialReportFailure = (error) => ({
  type: reportType.GET_CONFIGURED_CREDENTIAL_REPORT_FAILURE,
  error,
});
