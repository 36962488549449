export const reportsType = {
  GET_ORGANISATION_SUMMARY_LIST_PENDING:
    "[GET_ORGANISATION_SUMMARY][REQUEST] Get Organisation Summary list requested",
  GET_ORGANISATION_SUMMARY_LIST_SUCCESS:
    "[GET_ORGANISATION_SUMMARY][RESPONSE] Get Organisation Summary list Successfull",
  GET_ORGANISATION_SUMMARY_LIST_FAILURE:
    "[GET_ORGANISATION_SUMMARY][RESPONSE] Get Organisation Summary list Failed",

  GET_INSPECTOR_SUMMARY_LIST_PENDING:
    "[GET_INSPECTOR_SUMMARY][REQUEST] Get Inspector Summary list requested",
  GET_INSPECTOR_SUMMARY_LIST_SUCCESS:
    "[GET_INSPECTOR_SUMMARY][RESPONSE] Get Inspector Summary list Successfull",
  GET_INSPECTOR_SUMMARY_LIST_FAILURE:
    "[GET_INSPECTOR_SUMMARY][RESPONSE] Get Inspector Summary list Failed",

  GET_DATEWISE_SUMMARY_LIST_PENDING:
    "[GET_DATEWISE_SUMMARY][REQUEST] Get Date wise Summary list requested",
  GET_DATEWISE_SUMMARY_LIST_SUCCESS:
    "[GET_DATEWISE_SUMMARY][RESPONSE] Get Date wise Summary list Successfull",
  GET_DATEWISE_SUMMARY_LIST_FAILURE:
    "[GET_DATEWISE_SUMMARY][RESPONSE] Get Date wise Summary list Failed",

  GET_PER_DAY_INSPECTOR_SUMMARY_LIST_PENDING:
    "[GET_PER_DAY_INSPECTOR_SUMMARY][REQUEST] Get Per Day Inspector Summary list requested",
  GET_PER_DAY_INSPECTOR_SUMMARY_LIST_SUCCESS:
    "[GET_PER_DAY_INSPECTOR_SUMMARY][RESPONSE] Get Per Day Inspector Summary list Successfull",
  GET_PER_DAY_INSPECTOR_SUMMARY_LIST_FAILURE:
    "[GET_PER_DAY_INSPECTOR_SUMMARY][RESPONSE] Get Per Day Inspector Summary list Failed",

  GET_DATEWISE_INSPECTOR_SUMMARY_LIST_PENDING:
    "[GET_DATEWISE_INSPECTOR_SUMMARY][REQUEST] Get Date wise Inspector Summary list requested",
  GET_DATEWISE_INSPECTOR_SUMMARY_LIST_SUCCESS:
    "[GET_DATEWISE_INSPECTOR_SUMMARY][RESPONSE] Get Date wise Inspector Summary list Successfull",
  GET_DATEWISE_INSPECTOR_SUMMARY_LIST_FAILURE:
    "[GET_DATEWISE_INSPECTOR_SUMMARY][RESPONSE] Get Date wise Inspector Summary list Failed",
  GET_ORGANISATION_MAP_LIST_PENDING:
    "[GET_ORGANISATION_MAP][REQUEST] Get Organisation Map list requested",
  GET_ORGANISATION_MAP_LIST_SUCCESS:
    "[GET_ORGANISATION_MAP][RESPONSE] Get Organisation Map list Successfull",
  GET_ORGANISATION_MAP_LIST_FAILURE:
    "[GET_ORGANISATION_MAP][RESPONSE] Get Organisation Map list Failed",

  REPORTS_PAGE: "SITE_PAGE",
};
