import React from "react";
import CompanyManagementList from "../component/companyManagement/companyManagementList";

import {
  ADMIN_DIVISION,
  ADMIN_CONTACTS,
  ADMIN_RESOURCE,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";

const CompanyManagementContainer = () => {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(ADMIN_DIVISION);
    } else if (id === 2) {
      navigate(ADMIN_CONTACTS);
    } else if (id === 3) {
      navigate(ADMIN_RESOURCE);
    }
  };

  return (
    <>
      <div className="dashTitle">Company Management</div>
      <CompanyManagementList onClick={onClickHandler} />
    </>
  );
};

export default CompanyManagementContainer;
