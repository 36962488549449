import { Grid, IconButton, Menu } from "@mui/material";
import React from "react";
import "./cardBox.scss";
import {
  Edit,
  FileDownload,
  MoreVert,
  RemoveRedEye,
} from "@mui/icons-material";
import commonImages from "../../../assets";

import TableRowAction from "../gridTable/TableRowAction";
import ReportRowManipulte from "../../../feature/admin/shared/ReportRowManipulate";
const CardBox = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const statusClosed = row?.status === "Closed" ? true : false;
  return (
    <div className="overRideCardbox">
      <Grid mb={4} className="header">
        <Grid container className="content">
          <Grid container fullWidth>
            <Grid item lg={11}>
              <h1>{props.category}</h1>
            </Grid>
            {props.popupButton && (
              <Grid item>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="setting-menu"
                  open={open}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <div className="setting_button_ui">
                    <div className="dropdownList_ui">
                      <ul>
                        <li onClick={() => props.editOnClick()}>
                          <Edit /> <span>{props.editLabel}</span>
                        </li>

                        <li
                          className={null}
                          onClick={() => props.deleteOnClick()}
                        >
                          <img
                            src={commonImages.deleteblueIcon}
                            className="deleteImg"
                            alt=""
                          />
                          <span> {props.deleteLabel}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Menu>
              </Grid>
            )}
          </Grid>
          <p>{props.details}</p>
        </Grid>
      </Grid>
    </div>
  );
};

export default CardBox;
