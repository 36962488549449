import { FormControlLabel, FormGroup, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Buttons from "../../../../library/custom/button/Button";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import {
  HBNEXT_ORG_ID,
  ISwitch,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import ReportRowManipulte from "../../../admin/shared/ReportRowManipulate";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import SearchIcon from "@mui/icons-material/Search";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { ADMIN_CATEGORY_DASHBOARD } from "../../../../constant/routeContant";
import AddIcon from "@mui/icons-material/Add";
import { checkPrivileges } from "../../../../utils/rolesHelper";

function ConfigurePermiteeType({
  addPermitType,
  state,
  permitTypeList,
  handlepermitTypeList,
  updatePermitType,
  isLoading,
  formValue,
  setFormValue,
  isPermityTypeExistErrMsg,
  setisPermityTypeExistErrMsg,
  setisPermityTypeExistErrMsgUpdat,
  isPermityTypeExistErrMsgUpdate,
  setOpen,
  open,
}) {
  const [isErrorValue, setIsErrorValue] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [isValue, setIsValue] = useState(false);
  const [isState, setIsState] = useState(false);
  //const [open, setOpen] = useState(false);

  const [addPermitteeBox, setAddPermitteBox] = useState(false);
  const [page, setPage] = useState(1);
  const handleClose = () => {
    setOpen(false);
    setFormValue({
      ...formValue,
      value: "",
      stateId: "",
    });
  };
  const [modalData, setModalData] = useState();
  const dataBack = {
    title: "Back to Configuration Management",
    route: ADMIN_CATEGORY_DASHBOARD,
  };
  // const initialState = {
  //   stateId: "",
  //   value: "",
  //   isDeleted: false,
  //   createdBy: parseInt(USER_ID()),
  // };
  const editInitialState = {
    stateId: "",
    value: "",
    isDeleted: false,
    createdBy: parseInt(USER_ID()),
  };
  //const [formValue, setFormValue] = useState(initialState);
  const [editFormValue, setEditFormValue] = useState(editInitialState);
  function mappedData() {
    let errors = false;
    if (formValue?.value.trim() === "") {
      setFormValue({
        ...formValue,
        value: "",
      });
      setIsValue(true);
      errors = true;
    }

    if (formValue?.stateId?.length === 0) {
      setIsState(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const data = {
      stateId: formValue.stateId,
      value: formValue.value.replace(/\s+/g, " ").trim(),
      isDeleted: false,
      createdBy: parseInt(USER_ID()),
    };

    return data;
  }

  const createPermittee = () => {
    const data = mappedData();
    console.log(data);
    if (!data) {
    } else {
      addPermitType(data);
      // setFormValue({
      //   ...formValue,
      //   value: "",
      //   stateId: "",
      // });
    }
  };

  function mappedData1() {
    let errors = false;

    if (editFormValue?.value.trim() === "") {
      setEditFormValue({
        ...editFormValue,
        value: "",
      });
      setIsErrorValue(true);
      errors = true;
    }

    if (editFormValue?.stateId?.length === 0) {
      setIsErrorState(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    const editData = {
      stateId: editFormValue?.stateId,
      value: editFormValue?.value.replace(/\s+/g, " ").trim(),
      isDeleted: false,
      createdBy: parseInt(USER_ID()),
      permiTypeId: modalData?.permitTypeId,
      isActive: modalData?.isActive,
      modifiedBy: parseInt(USER_ID()),
    };

    return editData;
  }
  const editPermitType = () => {
    const editData = mappedData1();
    console.log(editData);
    if (!editData) {
    } else {
      updatePermitType(editData);
    }
  };
  const handleEditOnClick = (modalData) => {
    setisPermityTypeExistErrMsgUpdat("");
    setAddPermitteBox(!addPermitteeBox);
    setOpen(true);
    setModalData(modalData);
    setEditFormValue({
      value: modalData?.value,
      stateId: modalData?.stateId,
    });
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    handlepermitTypeList({
      search: search ? search : "",
      page: newPage + 1,
    });
  };
  const getPermitList = permitTypeList?.permits;
  const [permitTypeFilterData, setPermitTypeFilterData] = useState({});
  const [search, setSearch] = useState();
  const columns = [
    { field: "value", headerName: "Permittee Type Name", flex: 3 },
    { field: "state", headerName: "State", flex: 3 },

    {
      flex: 2,
      field: "lastName",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox swins">
                <FormControlLabel
                  control={
                    <ISwitch
                      checked={rows?.row.isActive ? true : false}
                      onChange={() => handleChange(rows?.row)}
                      name="status"
                    />
                  }
                />

                <div>
                  {rows?.row?.isActive === true ? "Active" : "Inactive"}
                </div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 2,
      field: "isGlobal",
      headerName: "Global",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox swins">
                {checkPrivileges([12, 93]) &&
                  parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
                    <FormControlLabel
                      control={
                        <ISwitch
                          checked={rows?.row.isGlobal ? true : false}
                          onChange={() => handleChangeGlobal(rows?.row)}
                          name="status"
                        />
                      }
                    />
                  )}

                <div>{rows?.row?.isGlobal === true ? "Global" : "N/A"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={permitTypeList?.itemsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={permitTypeList?.permits ?? []}
          fileNameXL={"permitTypeList"}
        />
      ),
      flex: 2,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={true}
            editOnClick={() => handleEditOnClick(rows?.row)}
            edit={true}
            editLabel="Edit Permittee Type"
            download={false}
          />
        );
      },
    },
  ];

  const handleautoSearch = (event, state) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...permitTypeFilterData,
      search: searchText ? searchText : "",
      page: 1,
    };
    setPermitTypeFilterData(data);
    handlepermitTypeList(data);
  };

  const handleChange = (item) => {
    const newTypeList = [...getPermitList];
    newTypeList.forEach((lists) => {
      if (lists.permitTypeId === item.permitTypeId) {
        lists.isActive = !lists.isActive;
        lists.checked = !lists.checked;

        updatePermitType({
          stateId: lists?.stateId,
          value: lists?.value,
          isDeleted: false,
          createdBy: parseInt(USER_ID()),
          permiTypeId: lists?.permitTypeId,
          isActive: lists?.isActive,
          modifiedBy: parseInt(USER_ID()),
        });
      }
    });
  };

  const handleChangeGlobal = (item) => {
    const newTypeList = [...getPermitList];
    newTypeList.forEach((lists) => {
      if (lists.permitTypeId === item.permitTypeId) {
        lists.isGlobal = !lists.isGlobal;
        lists.checked = !lists.checked;

        updatePermitType({
          stateId: lists?.stateId,
          value: lists?.value,
          isDeleted: false,
          createdBy: parseInt(USER_ID()),
          permiTypeId: lists?.permitTypeId,
          isActive: lists?.isActive,
          isGlobal: lists?.isGlobal,
          modifiedBy: parseInt(USER_ID()),
        });
      }
    });
  };

  return (
    <>
      <div className="overidePersonal">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid className="modalContainer">
            <Box className="modalBox">
              <div className="modalTitle">Edit Permittee Type</div>
              <div className="modalInputBox">
                <Grid container item lg={5} md={8} xs={8} mb={2}>
                  <InputField
                    type="text"
                    value={editFormValue.value}
                    isRequired={true}
                    onChange={(e) =>
                      setEditFormValue(
                        {
                          ...editFormValue,
                          value: e.target.value,
                        },
                        setisPermityTypeExistErrMsgUpdat("")
                      )
                    }
                    label="Permittee Type Name"
                    placeholder="Add Permittee Type Name..."
                    isError={isErrorValue}
                    errorMsg={
                      isErrorValue ? "Permittee Type Name is Required" : ""
                    }
                  />

                  {isPermityTypeExistErrMsgUpdate && (
                    <div className="errorMsg">
                      {isPermityTypeExistErrMsgUpdate}
                    </div>
                  )}
                </Grid>

                <Grid item container mt={2} lg={5} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="State" isRequired={true} />

                    <SelectMenu
                      listData={state}
                      value={editFormValue?.stateId}
                      placeholder="Please Select"
                      onchangehandler={(e) =>
                        setEditFormValue({
                          ...editFormValue,
                          stateId: e.target.value,
                        })
                      }
                      isError={isErrorState}
                      errorMsg={isErrorState ? "State is Required" : ""}
                    />
                  </FormControl>
                </Grid>
              </div>
              <Grid container className="modalFooterBox">
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientText={true}
                    label="Cancel"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientContained={true}
                    label={"Save"}
                    onClick={editPermitType}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Modal>
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">Configure Permittee Types</div>
        </Grid>
        <Grid
          item
          container
          md={8}
          className="CategoriesBox"
          style={{ display: "flex", alignItems: "inline" }}
          mt={5}
        >
          <Grid item lg={5} md={8} xs={8} mb={2}>
            <InputField
              type="text"
              value={formValue.value}
              onChange={(e) =>
                setFormValue(
                  {
                    ...formValue,
                    value: e.target.value,
                  },
                  setIsValue(false),
                  setisPermityTypeExistErrMsg("")
                )
              }
              isRequired={true}
              label="Permittee Type Name"
              placeholder="Add Permittee Type Name..."
              isError={isValue}
              errorMsg={isValue ? "Permittee Type Name is Required" : ""}
            />
            {isPermityTypeExistErrMsg && (
              <div className="errorMsg">{isPermityTypeExistErrMsg}</div>
            )}
          </Grid>
          <Grid item lg={5} xs={12} sm={12} md={6} ml={2}>
            <FormControl fullWidth>
              <FormLabels label="State" isRequired={true} />

              <SelectMenu
                listData={state}
                value={formValue.stateId}
                placeholder="Please Select"
                onchangehandler={(e) =>
                  setFormValue(
                    {
                      ...formValue,
                      stateId: e.target.value,
                    },
                    setIsState(false)
                  )
                }
                isError={isState}
                errorMsg={isState ? "State is Required" : ""}
              />
            </FormControl>
          </Grid>
          <Grid className="smallAddBtn" item lg={1} md={3} xs={3} ml={3} mt={3}>
            <Buttons
              varientAddIconBlue
              startIcon={<AddIcon />}
              label={"Create"}
              onClick={createPermittee}
            ></Buttons>
          </Grid>
        </Grid>
      </div>
      <section className="grid_main_sec">
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.permitTypeId}
                rows={permitTypeList?.permits ?? []}
                rowCount={permitTypeList?.itemsCount ?? 0}
                columns={columns}
                checkboxSelection={false}
                page={1}
                pagination
                loading={isLoading}
                pageSize={PAGE_SIZE}
                onPageChange={handlePagignation}
                rowsPerPageOptions={[PAGE_SIZE]}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="">
        <BackBtn dataBack={dataBack} />
      </div>
    </>
  );
}

export default ConfigurePermiteeType;
