import React from "react";
import "./imageModal.scss";
import { modalImg } from "../../../assets";
import { getFileExtension } from "../../../utils/helper";
import { IMAGEEXT } from "../../../constant/constant";
const ImageModal = ({
  clickedImg,
  setClickedImg,
  handleRotationRight,
  handleRotationLeft,
}) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
    }
  };

  const fileExtension = getFileExtension(clickedImg);
  const imgCheck =
    fileExtension === IMAGEEXT.png ||
    fileExtension === IMAGEEXT.jpg ||
    fileExtension === IMAGEEXT.jpeg ||
    fileExtension === IMAGEEXT.gif ||
    fileExtension === IMAGEEXT.svg;

  return (
    <>
      {imgCheck && (
        <div className="overlay dismiss" onClick={handleClick}>
          <div className="mainImgBox">
            <img className="mainImg" src={clickedImg} alt={clickedImg} />
          </div>
          <span>
            <img
              className="dismiss"
              onClick={handleClick}
              src={modalImg?.modalclose}
            />
          </span>
          <div onClick={handleRotationLeft} className="overlay-arrows_left">
            <img src={modalImg?.arrowbackleft} />
          </div>
          <div onClick={handleRotationRight} className="overlay-arrows_right">
            <img src={modalImg?.arrowbackright} />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageModal;
