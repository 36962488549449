import React, { useEffect } from "react";
import ViewUserByRole from "../component/userSearchByRole/viewUserByRole";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as contractorAction from "../../../redux/actions/contractorAction";
import * as projectAction from "../../../redux/actions/projectAction";
import * as equipmentsAction from "../../../redux/actions/equipmentsAction";
import * as vehiclesAction from "../../../redux/actions/vehiclesAction";
import * as assetsAction from "../../../redux/actions/assetsAction";
import * as userAction from "../../../redux/actions/userAction";
import { showRighSideMenu } from "../../../utils/rolesHelper";
import * as swInspectionAction from "../../../redux/storm-water/actions/swInspectionAction";

function ViewUserByRoleContainer() {
  const location = useLocation();
  const dispatch = useDispatch();
  const getSwInspectionList = useSelector((state) => state.getSwInspectionList);
  const autoSearchDataLoading = getSwInspectionList?.isLoading;
  const divisionIdGet = location?.state?.divisionId;
  const UserDatails = location?.state?.UserDatails;
  const divisionId = divisionIdGet;
  const showMenu = showRighSideMenu();

  const defaultProjectData = {
    organisationId: ORG_ID(),
    divisionId: divisionId,
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: UserDatails?.userId,
  };

  const defaultData = {
    search: "",
    inspectionTypeId: 0,
    frequencyId: "",
    projectId: 0,
    customerId: ORG_ID(),
    statusId: 5,
    startDate: "",
    endDate: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: UserDatails?.userId,
  };

  const projectsListData = useSelector((state) => state.projectList);
  const isLoading = useSelector((state) => state.projectList?.isLoading);
  const getProjectListData = (data) => {
    dispatch(projectAction.getProjectList(data));
  };

  const defaultEquipmentData = {
    organisationId: ORG_ID(),
    divisionId: divisionId,
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    statusIds: "",
    userId: UserDatails?.userId,
  };
  const equipmentList = useSelector((state) => state.equipmentList);
  const isEquipmentLoading = useSelector(
    (state) => state.equipmentList?.isLoading
  );

  const getEquipmentList = (data) => {
    dispatch(equipmentsAction.getEquipmentList(data));
  };

  const getContractorListData = (data) => {
    dispatch(contractorAction.getContractorList(data));
  };

  const defaultVehicleData = {
    organisationId: ORG_ID(),
    divisionId: divisionId,
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    userId: UserDatails?.userId,
  };

  const vehicleList = useSelector((state) => state.vehicleList);
  const isVehicleLoading = useSelector((state) => state.vehicleList?.isLoading);
  const getVehicleList = (data) => {
    dispatch(vehiclesAction.getVehicleList(data));
  };

  const defaultAssetData = {
    organisationId: ORG_ID(),
    divisionId: divisionId,
    page: 1,
    pageSize: PAGE_SIZE,
    statusIds: "",
    search: "",
    userId: UserDatails?.userId,
  };
  const assetList = useSelector((state) => state.assetList);
  const isAssetLoading = useSelector((state) => state.assetList?.isLoading);
  const getAssetListData = (data) => {
    dispatch(assetsAction.getAssetList(data));
  };

  const GroupListData = useSelector((state) => state.individualUserGroupData);

  const getuserGroupLists = (data) => {
    dispatch(userAction.getUserGroupListsData(data));
  };

  const defaultGroupData = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    userId: UserDatails?.userId,
    orgId: ORG_ID(),
  };

  const getSwInspectionListData = (data) => {
    dispatch(swInspectionAction.getSwInspectionList(data));
  };

  const tabClick = (id) => {
    if (id === "5") {
      getuserGroupLists(defaultGroupData);
    } else if (id === "4") {
      getAssetListData(defaultAssetData);
    } else if (id === "3") {
      getVehicleList(defaultVehicleData);
    } else if (id === "2") {
      getEquipmentList(defaultEquipmentData);
    } else if (id === "1" && showMenu?.isSafety) {
      getProjectListData(defaultProjectData);
    } else if (id === "1" && showMenu?.isSW) {
      getSwInspectionListData(defaultData);
    }
  };

  useEffect(() => {
    if (showMenu?.isSafety) {
      getProjectListData(defaultProjectData);
    } else {
      getSwInspectionListData(defaultData);
    }
  }, [dispatch]);

  return (
    <ViewUserByRole
      UserDatails={UserDatails}
      projectsListData={projectsListData?.data}
      getProjectListData={getProjectListData}
      isLoading={isLoading}
      equipmentList={equipmentList?.data}
      getEquipmentList={getEquipmentList}
      isEquipmentLoading={isEquipmentLoading}
      vehicleList={vehicleList?.data}
      getVehicleList={getVehicleList}
      isVehicleLoading={isVehicleLoading}
      assetList={assetList?.data}
      isAssetLoading={isAssetLoading}
      getAssetListData={getAssetListData}
      tabClick={tabClick}
      divisionId={divisionId}
      GroupListData={GroupListData?.data?.userInroup}
      noOfRecords={GroupListData?.data?.itemsCount}
      getuserGroupLists={getuserGroupLists}
      loadingGroup={GroupListData?.isLoading}
      getContractorListData={getContractorListData}
      getSwInspectionList={getSwInspectionList?.data}
      getSwInspectionListData={getSwInspectionListData}
      autoSearchDataLoading={autoSearchDataLoading}
      UserDatail={UserDatails}
    />
  );
}

export default ViewUserByRoleContainer;
