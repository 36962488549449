import { quizType } from "../constants/quizType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "Quiz",
  isError: false,
  isLoading: false,
};

export const createQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case quizType.POST_QUIZ_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case quizType.POST_QUIZ_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case quizType.POST_QUIZ_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getQuizListReducer = (state = initialState, action) => {
  switch (action.type) {
    case quizType.GET_QUIZ_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case quizType.GET_QUIZ_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case quizType.GET_QUIZ_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const submitQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case quizType.POST_SUBMIT_QUIZ_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case quizType.POST_SUBMIT_QUIZ_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case quizType.POST_SUBMIT_QUIZ_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getQuizDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case quizType.GET_QUIZ_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case quizType.GET_QUIZ_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case quizType.GET_QUIZ_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const updateQuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case quizType.POST_UPDATE_QUIZ_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case quizType.POST_UPDATE_QUIZ_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case quizType.POST_UPDATE_QUIZ_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
   
    default:
      return state;
  }
};