import { assignType } from "../constants/assignType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "assign",
  isError: false,
  isLoading: false,
};

export const creatAssignReducer = (state = initialState, action) => {
  switch (action.type) {
    case assignType.POST_ASSIGN_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case assignType.POST_ASSIGN_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case assignType.POST_ASSIGN_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateAssignReducer = (state = initialState, action) => {
  switch (action.type) {
    case assignType.PUT_ASSIGN_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case assignType.PUT_ASSIGN_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case assignType.PUT_ASSIGN_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getAssignListReducer = (state = initialState, action) => {
  switch (action.type) {
    case assignType.GET_ASSIGN_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case assignType.GET_ASSIGN_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case assignType.GET_ASSIGN_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
