import { credentialType } from "../constants/credentialType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "credential",
  isError: false,
  isLoading: false,
};

export const createCredentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.POST_CREDENTIAL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.POST_CREDENTIAL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.POST_CREDENTIAL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case credentialType.CLEAR_CREDENTIAL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateCredentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.PUT_CREDENTIAL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.PUT_CREDENTIAL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.PUT_CREDENTIAL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case credentialType.CLEAR_CREDENTIAL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getCredentialListReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.GET_CREDENTIAL_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.GET_CREDENTIAL_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.GET_CREDENTIAL_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getCredentialDetsilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.GET_CREDENTIAL_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.GET_CREDENTIAL_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.GET_CREDENTIAL_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case credentialType.CLEAR_CREDENTIAL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const getAssignUserListReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.GET_ASSIGN_USER_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.GET_ASSIGN_USER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.GET_ASSIGN_USER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getCredentialListByCredentialsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case credentialType.GET_CREDENTIAL_LIST_BY_CREDENTIALS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.GET_CREDENTIAL_LIST_BY_CREDENTIALS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.GET_CREDENTIAL_LIST_BY_CREDENTIALS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const assignCredentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.POST_ASSIGN_CREDENTIAL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.POST_ASSIGN_CREDENTIAL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.POST_ASSIGN_CREDENTIAL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createUserCredentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.POST_USER_CREDENTIAL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.POST_USER_CREDENTIAL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.POST_USER_CREDENTIAL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case credentialType.CLEAR_CREDENTIAL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getSearchCredentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.GET_SEARCH_CREDENTIAL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.GET_SEARCH_CREDENTIAL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.GET_SEARCH_CREDENTIAL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getCredentialListByUsersReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case credentialType.GET_CREDENTIAL_LIST_BY_USERS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.GET_CREDENTIAL_LIST_BY_USERS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.GET_CREDENTIAL_LIST_BY_USERS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getCredentialUserListReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.GET_USER_CREDENTIAL_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.GET_USER_CREDENTIAL_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.GET_USER_CREDENTIAL_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getCredentialViewDetsilsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case credentialType.GET_CREDENTIAL_VIEW_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.GET_CREDENTIAL_VIEW_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.GET_CREDENTIAL_VIEW_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case credentialType.CLEAR_CREDENTIAL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updateUserCredentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.PUT_CREDENTIAL_USER_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.PUT_CREDENTIAL_USER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.PUT_CREDENTIAL_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case credentialType.CLEAR_CREDENTIAL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const createAdminCredentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.POST_ADMIN_CREDENTIAL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.POST_ADMIN_CREDENTIAL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.POST_ADMIN_CREDENTIAL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case credentialType.CLEAR_CREDENTIAL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const deleteCredentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case credentialType.DELETE_CREDENTIAL:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case credentialType.DELETE_CREDENTIAL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case credentialType.DELETE_CREDENTIAL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
