export const roleType = {
  POST_ROLE_PENDING: "[ROLE][REQUEST] Create Role",
  POST_ROLE_FAILURE: "[ROLE][RESPONSE] Role Creation Failed",
  POST_ROLE_SUCCESS: "[ROLE][RESPONSE] Role Created Successfull",
  POST_UPDATE_ROLE_LIST_PENDING: "[REQUEST] Update Role List Data",
  POST_UPDATE_ROLE_LIST_SUCCESS: "[RESPONSE] Update Role List Data Successfull",
  POST_UPDATE_ROLE_LIST_FAILURE: "[RESPONSE] Update Role List Data Failed",

  GET_ROLE_LIST_PENDING: "[GET_ROLE_LIST][REQUEST] Get Role list requested",
  GET_ROLE_LIST_FAILURE: "[GET_ROLE_LIST][RESPONSE] Get Role list Failed",
  GET_ROLE_LIST_SUCCESS: "[GET_ROLE_LIST][RESPONSE] CGet Role list Successfull",

  POST_ASSIGN_ROLE_PENDING: "[ASSIGNROLE][REQUEST] Create Assign Role",
  POST_ASSIGN_ROLE_FAILURE:
    "[ASSIGNROLE][RESPONSE] Assign Role Creation Failed",
  POST_ASSIGN_ROLE_SUCCESS:
    "[ASSIGNROLE][RESPONSE] Assign Role Created Successfull",

  GET_ROLE_LIST_DETAILS:
    "[ROLE_LIST_DETAILS][REQUEST] Get Role List Details Data",
  GET_ROLE_LIST_DETAILS_SUCCESS:
    "[ROLE_LIST_DETAILS][RESPONSE] Get Role List Details Data Failed",
  GET_ROLE_LIST_DETAILS_FAILURE:
    "[ROLE_LIST_DETAILS][RESPONSE] Get Role List Details Data Successfull",

  DELETE_ROLE: "[REQUEST] Delete Role",
  DELETE_ROLE_SUCCESS: "[RESPONSE] Delete Role Successfull",
  DELETE_ROLE_FAILURE: "[RESPONSE] Delete Role Failed",

  CLEAR_ROLE: "[CLEAR_ROLE][REQUEST] Clear Role",
  GET_USER_IN_ROLE_LIST:"[GET_USER_IN_ROLE_LIST][REQUEST] Get User In Role List",
  GET_USER_IN_ROLE_LIST_FAILURE:"[GET_USER_IN_ROLE_LIST][RESPONSE] Get User In Role List Failed",
  GET_USER_IN_ROLE_LIST_SUCCESS:"[GET_USER_IN_ROLE_LIST][RESPONSE] Get User In Role List Successfull",



  ROLE_PAGE: "RolePage",
};
