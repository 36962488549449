import { getApiUrl } from "../../config";
import { ORG_ID } from "../../constant/constant";
import { PRODUCT_ID } from "../../constant/storm-water/constant";
import { get } from "../axiosRequest";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const product = userInfo?.product;
const projectstatus = product?.map((val) => {
  return val?.key;
});
export const getSwReferenceData = async ({ data }) => {
  const { flags, orgId } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Common/GetReferenceData?flags=${flags}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getProjectSearchList = async ({ data }) => {
  const { orgId, search, userId } = data;
  const url = `${
    getApiUrl().searchApiUrl
  }/v1/getprojectlist?orgId=${orgId}&search=${
    search ? search.trim() : ""
  }&userId=${userId}`;

  try {
    return await get(
      url,
      {
        headers: {
          Authorization: localStorage.getItem("id_token"),
        },
      },
      true
    );
  } catch (err) {
    return err;
  }
};

export const getContactSearchList = async ({ data }) => {
  const { orgId, search } = data;
  const url = `${
    getApiUrl().contactSearchListUrl
  }/getcontactlist?search=${search?.trim()}&orgId=${orgId}&productId=${
    PRODUCT_ID() ? PRODUCT_ID() : projectstatus[0]
  }`;

  try {
    return await get(
      url,
      {
        headers: {
          Authorization: localStorage.getItem("id_token"),
        },
      },
      true
    );
  } catch (err) {
    return err;
  }
};
