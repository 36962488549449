import React, { useEffect, useState } from "react";
import OpenItemList from "./openItemList";
import { useDispatch, useSelector } from "react-redux";
import * as incpectionAction from "../../redux/actions/incpectionAction";
import { USER_ID, GET_ITEM_LOG, PAGE_SIZE } from "../../constant/constant";
import { VIEW_OPEN_ITEMS_DETAILS } from "../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import Loader from "../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { checkPrivileges } from "../../utils/rolesHelper";

const OpenItemListContainer = ({ title = "Item Management" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);

  const itemListData = useSelector((state) => state.getItemList?.data);

  const userId = checkPrivileges([12, 117]) ? 0 : parseInt(USER_ID());
  const [statusIds, setStatusIds] = useState(2);
  const defaultData = {
    userId: userId,
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
    projectId: 0,
    divisionId: 0,
    itemTypes: GET_ITEM_LOG.All,
    statusIds: statusIds,
    fromDate: "",
    toDate: "",
    status: "",
  };

  const getItemLogAllData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };

  const getItemLogKudosData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };

  const getItemLogCorrectiveData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };
  const getItemLogReprimandData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };
  const getItemLogActionData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };

  const getExportItemListData = (data) => {
    setIsSuccess(true);
    dispatch(incpectionAction.getExportItemList(data));
  };

  const handleTabClick = (id) => {
    setActiveTab(id);

    if (id === 1) {
      setStatusIds(2);
      defaultData.itemTypes = GET_ITEM_LOG.Corrective;
      getItemLogCorrectiveData({ ...defaultData, statusIds: 2 });
    }
    if (id === 2) {
      setStatusIds(2);
      defaultData.itemTypes = GET_ITEM_LOG.Kudos;
      getItemLogKudosData({ ...defaultData, statusIds: 2 });
    }
    if (id === 3) {
      setStatusIds(2);
      defaultData.itemTypes = GET_ITEM_LOG.Reprimand;
      getItemLogReprimandData({ ...defaultData, statusIds: 2 });
    }
    if (id === 4) {
      setStatusIds(2);
      defaultData.itemTypes = GET_ITEM_LOG.Action;
      getItemLogActionData({ ...defaultData, statusIds: 2 });
    }
  };

  useEffect(() => {
    if (activeTab === 0) {
      getItemLogAllData(defaultData);
    }
  }, [activeTab]);
  const handleViewOnClickAction = (
    id,
    itemTypeId,
    rootId,
    subRootId,
    assignedPartyId,
    status,
    origin,
    itemType,
    addedById,
    involvedPartyName,
    involvedPartyId
  ) => {
    navigate(VIEW_OPEN_ITEMS_DETAILS, {
      state: {
        itemTypeId: itemTypeId,
        id: id,
        rootId: rootId,
        subRootId: subRootId,
        assignedPartyId: assignedPartyId,
        status: status,
        origin: origin,
        itemType: itemType,
        addedById: addedById,
        involvedPartyName:involvedPartyName,
        involvedPartyId:involvedPartyId
      },
    });
  };

  const handleViewOnClickKudos = (
    id,
    itemType,
    title,
    origin,
    status,
    addedById,
    involvedPartyName,
    involvedPartyId
  ) => {
    navigate(VIEW_OPEN_ITEMS_DETAILS, {
      state: {
        id: id,
        itemType: itemType,
        title: title,
        origin: origin,
        status: status,
        addedById: addedById,
        involvedPartyName: involvedPartyName,
        involvedPartyId: involvedPartyId,
      },
    });
  };

  const handleViewOnClick = (
    id,
    itemType,
    title,
    origin,
    status,
    itemTypeId,
    rootId,
    subRootId,
    assignedPartyId,
    addedById,
    assignedTo,
    involvedPartyName,
    involvedPartyId
  ) => {
    navigate(VIEW_OPEN_ITEMS_DETAILS, {
      state: {
        id: id,
        itemType: itemType,
        title: title,
        origin: origin,
        status: status,
        itemTypeId: itemTypeId,
        rootId: rootId,
        subRootId: subRootId,
        assignedPartyId: assignedPartyId,
        addedById: addedById,
        assignedTo: assignedTo,
        involvedPartyName:involvedPartyName,
        involvedPartyId:involvedPartyId
      },
    });
  };

  const handleViewOnClickReprimand = (
    id,
    origin,
    itemType,
    status,
    addedById,
    involvedPartyName,
    involvedPartyId
  ) => {
    navigate(VIEW_OPEN_ITEMS_DETAILS, {
      state: {
        id: id,
        origin: origin,
        itemType: itemType,
        status: status,
        addedById: addedById,
        involvedPartyName:involvedPartyName,
        involvedPartyId:involvedPartyId
      },
    });
  };

  const handleViewOnClickViolation = (
    id,
    itemType,
    title,
    status,
    origin,
    addedById,
    assignedTo,
    involvedPartyName,
    involvedPartyId
  ) => {
    navigate(VIEW_OPEN_ITEMS_DETAILS, {
      state: {
        id: id,
        itemType: itemType,
        title: title,
        status: status,
        origin: origin,
        addedById: addedById,
        assignedTo: assignedTo,
        involvedPartyName:involvedPartyName,
        involvedPartyId:involvedPartyId
      },
    });
  };

  const isGetItemLogLoading = useSelector(
    (state) => state.getItemList?.isLoading
  );

  const getExportItemListDatass = useSelector(
    (state) => state.getExportItemListDatas
  );
  const isLoading = getExportItemListDatass?.isLoading;

  useEffect(() => {
    if (
      getExportItemListDatass?.data?.responseMessages?.responseMessage ==
      "Request was successful"
    ) {
      setIsSuccess(false);
      toast("Export Details has been sent to registered mail ID");
      setTimeout(() => {
        window.location.reload();
      }, 3800);
    }
  }, [getExportItemListDatass]);

  const openItemIntialCount = {
    correctivecount: "",
    reprimandscount: "",
    kudoscount: "",
    actionscount: "",
    openItemCountt: "",
  };
  const [openItemCount, setOpenItemCount] = useState(openItemIntialCount);

  useEffect(() => {
    if (activeTab === 0 && itemListData) {
      setOpenItemCount({
        ...openItemCount,
        correctivecount: itemListData?.result?.noOfViolation,
        reprimandscount: itemListData?.result?.noOfReprimand,
        kudoscount: itemListData?.result?.noOfKudos,
        actionscount: itemListData?.result?.noOfAction,
        totalopenitems: itemListData?.result?.openItemCount,
        data: itemListData,
      });
    }
  }, [activeTab, itemListData]);
  const showLoader = useSelector(
    (state) => state.getExportItemListDatas?.isLoading || isGetItemLogLoading
  );
  return (
    <>
      {isLoading && <Loader />}
      <div className="openItemoverride">
        <div className="insi-stitle">
          <p>{title}</p>
        </div>
      </div>
      <div>
        <ToastContainer />
      </div>
      <OpenItemList
        userId={userId}
        itemListData={isGetItemLogLoading ? [] : itemListData}
        isGetItemLogLoading={isGetItemLogLoading}
        getItemLogKudosData={getItemLogKudosData}
        getItemLogCorrectiveData={getItemLogCorrectiveData}
        getItemLogReprimandData={getItemLogReprimandData}
        getItemLogActionData={getItemLogActionData}
        handleViewOnClickAction={handleViewOnClickAction}
        handleViewOnClickKudos={handleViewOnClickKudos}
        handleViewOnClickReprimand={handleViewOnClickReprimand}
        handleViewOnClickViolation={handleViewOnClickViolation}
        title={title}
        filterShow
        handleTabClick={handleTabClick}
        activeTab={activeTab}
        getItemLogAllData={getItemLogAllData}
        handleViewOnClick={handleViewOnClick}
        statusIds={statusIds}
        setStatusIds={setStatusIds}
        getExportItemListData={getExportItemListData}
        getExportItemListDatass={getExportItemListDatass}
        openItemCount={openItemCount}
      />
    </>
  );
};

export default OpenItemListContainer;
