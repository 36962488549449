import React from "react";
import Buttons from "../button/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import "./lmsFilterList.scss";
import commonImages from "../../../assets";
import { ORG_ID, USER_ID } from "../../../constant/constant";

const LmsFilterList = ({
  filterType,
  handleSearch,
  tabvalue,
  inputsearch,
  onChangeType,
  filter,
  PAGE_SIZE,
}) => {
  const handleApply = (e) => {
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: inputsearch ? inputsearch : "",
      catIds: filter?.searchType[0]?.key ? filter?.searchType[0]?.key : "",
      statusId:
        parseInt(tabvalue) === 0 ? 2 : parseInt(tabvalue) === 2 ? "" : 1,
      userId: USER_ID(),
      orgId: ORG_ID(),
      organisationId: ORG_ID(),
      status: tabvalue ? tabvalue : "",
    };
    handleSearch(data);
  };
  return (
    <>
      <Box className="filterResult">
        <div className="filterTitle">Category</div>
        <div className="checkboxWrap">
          <FormGroup className="labelList">
            {filterType &&
              filterType.map((item) => (
                <FormControlLabel
                  key={item.key}
                  control={
                    <img
                      className="filterCheckbox"
                      title={
                        filter.searchType[0]?.key === item.key
                          ? "tickCheckbox"
                          : "untickCheckbox"
                      }
                      alt=""
                      src={
                        filter.searchType[0]?.key === item.key
                          ? commonImages.check
                          : commonImages.noCheck
                      }
                      onClick={() => onChangeType(item)}
                    />
                  }
                  label={item?.value}
                  className="checkboxText"
                />
              ))}
          </FormGroup>
        </div>
        <div className="filterBottom">
          <Buttons
            primary={true}
            label={"Apply"}
            onClick={(e) => handleApply(e)}
          />
        </div>
      </Box>
    </>
  );
};

export default LmsFilterList;
