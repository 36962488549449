import { getApiUrl } from "../config";
import { post, get, deletes } from "./axiosRequest";
import { ORG_ID } from "../constant/constant";
import { PRODUCT_ID } from "../constant/storm-water/constant";
export const createNews = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/News/AddNews`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const getNewsList = async ({ data }) => {
  const { page, pageSize } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/News/GetNewsList?page=${page}&pageSize=${pageSize}&productId=${PRODUCT_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const getNewsDetails = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/News/GetNewsDetails?Newsid=${data}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const updateNews = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/News/UpdateNews`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteNews = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/News/DeleteNews/${payload?.newsId}`;

  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
