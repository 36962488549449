import {
  clearVehicleType,
  deleteVehicleType,
  getVehicleDetailsType,
  postAddVehicleType,
  updateVehicleType,
  vehiclesType,
} from "../constants/vehicleType";

export const getVehicleList = (data) => ({
  type: vehiclesType.GET_VEHICLE_LIST,
  data,
});

export const getVehicleListSuccess = (data) => ({
  type: vehiclesType.GET_VEHICLE_LIST_SUCCESS,
  data,
});

export const getVehicleListlFailure = (error) => ({
  type: vehiclesType.GET_VEHICLE_LIST_FAILURE,
  error,
});

// postAddVehicle Action
export const postAddVehicle = (data) => ({
  type: postAddVehicleType.POST_ADD_VEHICLE_PENDING,
  data,
});

export const postAddVehicleSuccess = (data) => ({
  type: postAddVehicleType.POST_ADD_VEHICLE_SUCCESS,
  data,
});

export const postAddVehicleFailure = (data) => ({
  type: postAddVehicleType.POST_ADD_VEHICLE_FAILURE,
  data,
});

// deleteVehicle action
export const deleteVehicle = (payload) => ({
  type: deleteVehicleType.DELETE_VEHICLE_PENDING,
  payload: payload,
});

export const deleteVehicleSuccess = (payload) => ({
  type: deleteVehicleType.DELETE_VEHICLE_SUCCESS,
  payload: payload,
});

export const deleteVehicleFailure = (error) => ({
  type: deleteVehicleType.DELETE_VEHICLE_FAILED,
  error,
});

export const getVehicleDetails = (data) => ({
  type: getVehicleDetailsType.GET_VEHICLE_DETAILS,
  data,
});

export const getVehicleDetailsSuccess = (data) => ({
  type: getVehicleDetailsType.GET_VEHICLE_DETAILS_SUCCESS,
  data,
});

export const getVehicleDetailsFailure = (error) => ({
  type: getVehicleDetailsType.GET_VEHICLE_DETAILS_FAILURE,
  error,
});


export const updateVehicle = (payload) => ({
  type: updateVehicleType.UPDATE_VEHICLE_PENDING,
  payload: payload,
});

export const updateVehicleSuccess = (data) => ({
  type: updateVehicleType.UPDATE_VEHICLE_SUCCESS,
  data,
});

export const updateVehicleFailure = (error) => ({
  type: updateVehicleType.UPDATE_VEHICLE_FAILED,
  error,
});

export const clearVehicle = () => ({
  type: clearVehicleType.CLEAR_VEHICLE,
  data: "",
});