import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import "./userForm.scss";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getFormatedDate } from "../../../../utils/helper";
import {
  COUNTRY_CODE,
  NON_PRIVILEGE_USERS_TEMPLATE_URL,
  USER_ID,
} from "../../../../constant/constant";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import Checkbox from "../../../../library/checkbox/checkbox";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const AddNonPrivilegeUser = ({
  adduserLists,
  divisions,
  jobTitles,
  editMode,
  userId,
  getNonPrivilegeUserListDetsilsData,
  roles,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitleName] = useState(null);
  const [date, setDate] = useState(null);
  const [division, setDivision] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [divisionError, setDivisionError] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [checkBox, setcheckBox] = useState(true);
  const [selectUserRole, setSelectUserRole] = useState("");
  const [checkBoxPrivilege, setcheckBoxPrivilege] = useState(false);
  const [emailverify, setEmailverify] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneverify, setPhoneverify] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [selectUserRoleError, setSelectUserRoleError] = useState(false);

  // code start
  const [serviceType, setServiceType] = useState([]);
  const [serviceTypeError, setServiceTypeError] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const serviceTypes = userInfo?.product;

  useEffect(() => {
    if (serviceTypes?.length === 1) {
      setServiceType(serviceTypes[0].key);
    }
  }, [serviceTypes]);

  useEffect(() => {
    const companyNames = localStorage.getItem("companyName");
    const orgNames = localStorage.getItem("orgName");
    if (orgNames) {
      setCompanyName(orgNames);
    } else {
      setCompanyName(companyNames);
    }
  }, []);

  useEffect(() => {
    if (getNonPrivilegeUserListDetsilsData) {
      setFirstName(getNonPrivilegeUserListDetsilsData?.firstname);
      setLastName(getNonPrivilegeUserListDetsilsData?.lastname);
      setDivision(getNonPrivilegeUserListDetsilsData?.divisionId);
      setEmail(getNonPrivilegeUserListDetsilsData?.email);
      const phone = getNonPrivilegeUserListDetsilsData?.phonenumber;
      setPhone(
        phone && phone > 10
          ? phone.substring(phone.length - 10, phone.length)
          : phone
      );
      setDate(getNonPrivilegeUserListDetsilsData?.startDate);
      setJobTitleName(getNonPrivilegeUserListDetsilsData?.jobTitleId);
      setcheckBox(!getNonPrivilegeUserListDetsilsData?.startDate);
      setServiceType(
        getNonPrivilegeUserListDetsilsData?.product?.map((item) => item.value)
      );
    }
  }, [getNonPrivilegeUserListDetsilsData]);

  const history = useNavigate();

  const firstNameHandler = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameHandler = (e) => {
    setLastName(e.target.value);
  };

  const onChangeJobTitle = (e) => {
    setJobTitleName(e.target.value);
  };

  const onchangedatePicker = (e) => {
    setDate(e);
  };

  const onChangeDivision = (e) => {
    setDivision(e.target.value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    const isValidEmail = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailverify(false);
    } else {
      setEmailverify(true);
    }

    setEmail(e.target.value);
    if (e.target.value == "") {
      setEmailverify(false);
    }
  };

  const onChangeSelectUserRole = (e) => {
    setSelectUserRole(e.target.value);
  };

  const findValueMultipleSelectServiveType = (
    arrrayofObject,
    selectedArray
  ) => {
    if (serviceTypes?.length > 1) {
      let valueArray = [];
      arrrayofObject.forEach((data) => {
        if (selectedArray?.indexOf(data.value) >= 0) {
          valueArray.push(data.key);
        }
      });
      return valueArray;
    }
  };
  const userIds = localStorage.getItem("userId");

  const onClickAddUser = (e) => {
    const selectedServiceType = findValueMultipleSelectServiveType(
      serviceTypes,
      serviceType
    );
    let isFieldEmpty = false;
    if (!firstName) {
      setFirstNameError(true);
      isFieldEmpty = true;
    }
    if (!lastName) {
      setLastNameError(true);
      isFieldEmpty = true;
    }
    if (editMode && checkBoxPrivilege && !email) {
      setEmailError(true);
      isFieldEmpty = true;
    }
    if (emailverify == true) {
      isFieldEmpty = true;
    }
    if (editMode && checkBoxPrivilege && !phone) {
      setPhoneError(true);
      isFieldEmpty = true;
    }
    if (phone) {
      if (phone?.length != 10) {
        setPhoneverify(true);
        isFieldEmpty = true;
      }
    }
    if (phone?.length == 0) {
      setPhoneverify(false);
    }
    if (!division) {
      setDivisionError(true);
      isFieldEmpty = true;
    }
    if (!serviceType || serviceType?.length === 0) {
      setServiceTypeError(true);
      isFieldEmpty = true;
    }
    if (editMode && checkBoxPrivilege && selectUserRole?.length === 0) {
      setSelectUserRoleError(true);
      isFieldEmpty = true;
    }
    if (!isFieldEmpty) {
      let findData = {};
      if (editMode) {
        findData = roles.find((role) => role.key === selectUserRole);
      }
      let sendData = {
        users: [
          {
            firstname: firstName,
            lastname: lastName,
            divisionId: division,
            jobTitleId: jobTitle,
            startDate: checkBox ? null : getFormatedDate(date),
            email: email,
            phonenumber: phone,
          },
        ],
        productIds: selectedServiceType ? selectedServiceType : [serviceType],
        createdbyuser: parseInt(USER_ID()),
        createdbyapp: "0",
      };
      let sendDataEdit = {
        firstname: firstName,
        lastname: lastName,
        divisionId: division,
        jobTitleId: jobTitle,
        startDate: checkBox ? null : getFormatedDate(date),
        email: email,
        phonenumber: phone,
        userid: +userId,
        modifiedbyuser: parseInt(USER_ID()),
        modifiedbyapp: "0",
        productIds: selectedServiceType ? selectedServiceType : [serviceType],
      };

      let sendConvertData = {
        userid: +userId,
        email: email,
        phonenumber: phone,
        isdCode: COUNTRY_CODE,
        roleId: findData?.key,
      };
      if (checkBoxPrivilege) {
        adduserLists(sendConvertData, checkBoxPrivilege);
      } else {
        adduserLists(editMode ? sendDataEdit : sendData, checkBoxPrivilege);
      }
    }
  };

  useEffect(() => {
    if (!phone) {
      setPhoneverify(false);
    }
  }, [phone]);

  const handleFileChanges = (data) => {
    const selectedServiceType = findValueMultipleSelectServiveType(
      serviceTypes,
      serviceType
    );
    const users = data.map((x, index) => {
      x.firstname = x?.firstname;
      x.lastname = x?.lastname;
      x.email = x.email === undefined ? "" : x.email;
      x.phonenumber = x.phonenumber === undefined ? "" : x.phonenumber;
      x.startDate = x.startDate === undefined ? null : x.startDate;
      x.divisionId = parseInt(x.divisionId);
      x.jobTitleId =
        parseInt(x.jobTitleId) === undefined ? null : parseInt(x.jobTitleId);

      delete x.id;
      return x;
    });
    const usersData = {
      users: users,
      createdbyuser: parseInt(USER_ID()),
      createdbyapp: "0",
      productIds: selectedServiceType ? selectedServiceType : [serviceType],
    };
    adduserLists(usersData);
  };

  const onChangeServiceType = (event) => {
    setServiceTypeError(false);
    const {
      target: { value },
    } = event;
    setServiceType(typeof value === "string" ? value.split(",") : value);
  };
  const selectedServiceTypeData = localStorage?.getItem("serviceType");

  useEffect(() => {
    if (selectedServiceTypeData) {
      const newListData = serviceTypes?.find(
        (x) => x.key == selectedServiceTypeData
      );

      setServiceType(
        typeof newListData?.value === "string"
          ? newListData?.value.split(",")
          : newListData?.value
      );
    }
  }, [selectedServiceTypeData]);
  
  const mapBulkError = (data) => {
    let usersWithError = [];
    data.forEach((x) => {
      // const validDate = dateValiadtor(
      //   getFormatedDate(x.startDate?.replace(/\//g, "-"))
      // );
      if (
        x.firstname?.trim() === undefined ||
        null ||
        "" ||
        x.lastname?.trim() === undefined ||
        null ||
        "" ||
        x.divisionId?.trim() === undefined ||
        null ||
        "" 
      ) {
        usersWithError.push(x);
        return;
      }
    });
    return usersWithError;
  };
  return (
    <Grid container mt={4}>
      <Grid item lg={9} xs={12} sm={12} md={9}>
        <Grid container>
          <Grid item lg={6} xs={12} sm={12} md={6} textAlign="left">
            <div className="heading_style">
              {isBulkUpload ? "Add Multi User" : " Add Non Privileged User"}
            </div>
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={6} textAlign="right">
            {checkPrivileges([12, 64]) && (
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={isBulkUpload ? "Single Users" : "Bulk Upload"}
                varientContained={true}
                onClick={() => setIsBulkUpload(!isBulkUpload)}
              ></Buttons>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <div>
          {isBulkUpload ? (
            <Grid item lg={9} xs={9} sm={9} md={9} mt={4}>
              <BulkUpload
                handleFileChanges={handleFileChanges}
                mapBulkError={mapBulkError}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                templateUrl={NON_PRIVILEGE_USERS_TEMPLATE_URL}
                templateText="Download Template"
                pocFirstNameKey="firstname"
                pocLastNameKey="lastname"
                pocPhoneKey="phonenumber"
                pocFirstNamelabel="FIRST NAME"
                pocLastNamelabel="LAST NAME"
                pocPhonelabel="PHONE"
              />
            </Grid>
          ) : (
            <Grid container item md={9} xs={9} className="addShadowBox">
              <Grid container mt={2} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={checkBoxPrivilege}
                      isRequired={true}
                      type="text"
                      label={"First Name"}
                      value={firstName}
                      placeholder="Enter first name"
                      onChange={(e) => firstNameHandler(e)}
                    />
                  </FormControl>
                  {firstNameError && !firstName && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={checkBoxPrivilege}
                      isRequired={true}
                      type="text"
                      label={"Last Name"}
                      value={lastName}
                      placeholder="Enter last name"
                      onChange={(e) => lastNameHandler(e)}
                    />
                  </FormControl>
                  {lastNameError && !lastName && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid container mt={1} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <InputField
                      disabled={false}
                      isRequired={checkBoxPrivilege}
                      type="text"
                      label={"Email"}
                      value={email}
                      placeholder="Enter Email"
                      onChange={(e) => onChangeEmail(e)}
                    />
                  </FormControl>
                  {emailError && !email && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                  {emailverify && (
                    <div className="errorMsg">
                      Please enter a valid Email address
                    </div>
                  )}
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth={true}>
                    <NumberField
                      disabled={false}
                      isRequired={checkBoxPrivilege}
                      type="text"
                      label={"Phone"}
                      maxLength="10"
                      value={phone}
                      placeholder={`Enter Phone number without ${COUNTRY_CODE}`}
                      onChange={(value) =>
                        value.length <= 10 ? setPhone(value) : phone
                      }
                    />
                  </FormControl>
                  {phoneError && !phone && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                  {phoneverify && phone?.length != 10 && (
                    <div className="errorMsg">
                      Please enter 10 digit valid Phone Number
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid item container mt={0} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Company" />
                    <InputField
                      disabled={true}
                      isRequired={true}
                      type="text"
                      value={companyName}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Job Title" isRequired={false} />
                    <SelectMenu
                      placeholder="Please Select"
                      disabled={checkBoxPrivilege}
                      listData={jobTitles}
                      value={jobTitle}
                      onchangehandler={(e) => onChangeJobTitle(e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container mt={0} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label={"Hire Date"} isRequired={false} />
                    <DatePicker
                      value={date}
                      disabled={checkBox || checkBoxPrivilege}
                      onChangeHandler={(e) => onchangedatePicker(e)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Division" isRequired={true} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={divisions}
                      value={division}
                      disabled={checkBoxPrivilege}
                      onchangehandler={(e) => onChangeDivision(e)}
                    />
                  </FormControl>
                  {divisionError && !division && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item container mt={0} spacing={1}>
                <Grid item lg={5} xs={12} sm={12} md={4}>
                  <div className="typeTxt">
                    <Checkbox
                      disabled={checkBoxPrivilege}
                      checked={checkBox}
                      onChange={() => setcheckBox(!checkBox)}
                      label={"Did Not Provide"}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container mt={4} spacing={3}>
                {serviceTypes.length > 1 && (
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabels label="Service Type" isRequired={true} />
                      <MultiSelectBox
                        disableSelect={true}
                        placeholder={"Please Select"}
                        listData={serviceTypes}
                        onChange={(e) => onChangeServiceType(e)}
                        value={serviceType || []}
                        isError={serviceTypeError}
                        errorMessage="Selection is required for this field"
                      />
                    </FormControl>
                  </Grid>
                )}
                {serviceTypes.length === 1 && (
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabels label="Service Type" isRequired={true} />
                      {serviceTypes?.map((value, index) => {
                        return (
                          <div className="serviveTypeStyle" key={value?.key}>
                            {" "}
                            {value?.value}
                          </div>
                        );
                      })}
                    </FormControl>
                  </Grid>
                )}

                {editMode && checkBoxPrivilege && (
                  <Grid item lg={6} xs={12} sm={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabels
                        label={"Select User Role"}
                        isRequired={true}
                      />
                      <SelectMenu
                        placeholder="Please Select"
                        listData={roles}
                        value={selectUserRole}
                        onchangehandler={(e) => onChangeSelectUserRole(e)}
                      />
                    </FormControl>
                    {selectUserRoleError && selectUserRole?.length === 0 && (
                      <div className="errorMsg">
                        Please enter this required field
                      </div>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid container mb={10} mt={1} spacing={3}>
                {editMode && (
                  <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
                    <Grid item lg={5} xs={12} sm={12} md={4}>
                      <div className="typeTxt">
                        <Checkbox
                          checked={checkBoxPrivilege}
                          onChange={() =>
                            setcheckBoxPrivilege(!checkBoxPrivilege)
                          }
                          label={"Convert to Privileged User"}
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
                {!editMode && (
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sm={12}
                    md={4}
                    textAlign="left"
                  ></Grid>
                )}
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <div className="adduser_btn">
                    <div
                      className="cancle_heading_style"
                      onClick={() => history(-1)}
                    >
                      Cancel
                    </div>
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={editMode ? "Update User" : "Add User"}
                      varientContained={true}
                      onClick={(e) => onClickAddUser(e)}
                    ></Buttons>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default AddNonPrivilegeUser;
