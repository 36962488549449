import React, { useState } from "react";
import GridTable from "../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import SettingTable from "../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../library/common/Loader";
import { PAGE_SIZE } from "../../../constant/constant";
import AddIcon from "@mui/icons-material/Add";
import NORow from "../../../library/custom/noRow/noRow";
import ReportRowManipulte from "../../admin/shared/ReportRowManipulate";
import Buttons from "../../../library/custom/button/Button";
import { checkPrivileges } from "../../../utils/rolesHelper";

const ObservationList = ({
  observationList,
  getObservationListData,
  isLoading,
  handleViewOnClick,
  addObservation,
}) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [observationFilterData, setObservationFilterData] = useState({
    searchText: search,
    page: 1,
    pageSize: PAGE_SIZE,
    location: "",
    Status: "",
    ItemType: "",
  });

  const columns = [
    { field: "insObservationId", headerName: "ID", flex: 1 },
    { field: "observationDate", headerName: "Date of Entry", flex: 1.5 },
    { field: "location", headerName: "Location/Jobsite", flex: 1.5 },
    { field: "observedTypeValue", headerName: "Item Type", flex: 1.5 },
    { field: "observedByName", headerName: "Added by", flex: 1.5 },
    { field: "statusText", headerName: "Status", flex: 1 },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={observationList?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={observationList?.observationData ?? []}
          fileNameXL={"observation List"}
        />
      ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={(row) =>
              handleViewOnClick(
                row?.insObservationId,
                row?.observedType,
                row?.observedEmployee,
                row?.status
              )
            }
            view={checkPrivileges([12, 84, 85, 86])}
            viewLabel="View Observation"
          />
        );
      },
    },
  ];

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getObservationListData({
      ...observationFilterData,
      page: newPage + 1,
    });
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);

    setObservationFilterData({
      ...observationFilterData,
      searchText: event.target.value,
    });
    const data = {
      ...observationFilterData,
      searchText: event.target.value,
      page: 1,
      pageSize: PAGE_SIZE,
      location: "",
      Status: "",
      ItemType: "",
    };
    getObservationListData(data);
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      const { row } = rows;
      handleViewOnClick(
        row?.insObservationId,
        row?.observedType,
        row?.observedEmployee,
        row?.status
      );
    }
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Observations</div>
          <div>
            {checkPrivileges([12, 84, 85]) && (
              <Buttons
                varientContained={true}
                label={" + Add Observation"}
                startIcon={<AddIcon />}
                onClick={() => addObservation()}
              />
            )}
          </div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                    data-testid="search-input"
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.insObservationId}
                rows={observationList?.observationData ?? []}
                rowCount={observationList?.noOfRecords ?? 0}
                onClick={(rows) => onRowClick(rows)}
                columns={columns}
                loading={isLoading}
                page={page}
                pagination
                pageSize={PAGE_SIZE}
                rowsPerPageOptions={[PAGE_SIZE]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ObservationList;
