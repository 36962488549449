import React from "react";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { ISwitch } from "../../../../constant/constant";
import "./inspectionAcknowledgement.scss";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../../library/custom/textArea/TextArea";
import Buttons from "../../../../library/custom/button/Button";
import { useNavigate } from "react-router-dom";
import { INSPECTION_ADMIN } from "../../../../constant/routeContant";

const InspectionAcknowledgment = (props) => {
  const navigate = useNavigate();
  const dataBack = {
    title: "Set Inspection Acknowledgement",
    route: INSPECTION_ADMIN,
  };

  const dataBackFooter = {
    title: "Back to Inspection Configuration",
    route: INSPECTION_ADMIN,
  };

  return (
    <>
      <Grid className="inspectionOverRide inspectionAcknowledgement">
        <h2 className="inspectionAcknowledgementHeading">
          Set Inspection Acknowledgement
        </h2>

        <Grid container>
          <Grid lg={5} xs={12} sm={6} md={6}>
            <FormControl
              component="fieldset"
              variant="standard"
              className="contactswitch"
            >
              <FormLabel component="status">
                Set Inspection Acknowledgment Status
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <ISwitch
                      checked={props?.checked}
                      onChange={props?.handleChange}
                      name="status"
                    />
                  }
                  label={props?.checked ? "Active" : "InActive"}
                />
              </FormGroup>
            </FormControl>
          </Grid>

          {props?.checked && (
            <Grid item container mt={3}>
              <Grid item lg={8} md={8} xs={12}>
                <FormControl fullWidth>
                  <FormLabels label="Set Acknowledgment Message" />
                  <TextArea
                    placeholder={"Enter Here"}
                    value={props?.acknowledgmentMessage}
                    onChange={(e) => {
                      props?.setAcknowledgmentMessage(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid container mt={13}>
          <Grid md={8} xs={12}>
            <div className="insBackBtn">
              <BackBtn dataBack={dataBackFooter} />
            </div>
          </Grid>
          <Grid item lg={2} xs={12} sm={3} md={3}>
            <Buttons
              varientText={true}
              label="Cancel"
              onClick={() => navigate(-1)}
            />
          </Grid>
          <Grid item lg={2} xs={12} sm={3} md={3}>
            <Buttons
              varientContained={true}
              label={"Save"}
              onClick={props?.onSubmitData}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InspectionAcknowledgment;
