import {
  Edit,
  FileDownload,
  MoreVert,
  RemoveRedEye,
} from "@mui/icons-material";
import { IconButton, Menu } from "@mui/material";
import React from "react";
import DownloadCsv from "../../../download_file/DownloadCsv";
import commonImages from "../../../../assets";

export default function JHAReportRowManipulte(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dowloadCsv = React.useRef();
  const {
    rows: { row },
    // anchorEl,
    // setAnchorEl,
    // handleClose,
    viewOnClick,
    editOnClick,
    setOnClick,
    deleteOnClick,
    cloneOnClick,
    editInspectionOnClick,
    edit,
    set,
    download,
    view,
    deleted,
    credentialLists = false,
    clone = false,
    editInspection = false,
  } = props;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="setting-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="setting_button_ui">
          <div className="dropdownList_ui">
            <ul>
              {view && (
                <li onClick={() => viewOnClick(row)}>
                  {credentialLists ? (
                    <>
                      <Edit />
                      <span>Edit Credential</span>
                    </>
                  ) : (
                    <>
                      <RemoveRedEye />{" "}
                      <span>
                        {props?.viewLabel ? props?.viewLabel : "View Detail"}
                      </span>
                    </>
                  )}
                </li>
              )}

              {edit && (
                <li onClick={() => editOnClick(row)}>
                  <Edit /> <span>{props.editLabel}</span>
                </li>
              )}
              {set && (
                <li onClick={() => setOnClick(row)}>
                  <img
                    src={commonImages.setControlIcon}
                    className="deleteImg"
                    alt=""
                  />{" "}
                  <span>{props.setLabel}</span>
                </li>
              )}
              {download && (
                <li onClick={() => dowloadCsv.current.link.click()}>
                  <FileDownload /> <span>{props.downloadLabel}</span>
                </li>
              )}
              {deleted && (
                <li onClick={() => deleteOnClick(row)}>
                  <img
                    src={commonImages.deleteblueIcon}
                    className="deleteImg"
                    alt=""
                  />
                  <span>{props.deleteLabel}</span>
                </li>
              )}
              {editInspection && (
                <li onClick={() => editInspectionOnClick(row)}>
                  <Edit /> <span>{props.editInsectionLabel}</span>
                </li>
              )}
              {clone && (
                <li onClick={() => cloneOnClick(row)}>
                  <img
                    src={commonImages.contentcopy}
                    className="deleteImg"
                    alt=""
                  />
                  <span>{props?.cloneLabel}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Menu>
      <DownloadCsv
        ref={dowloadCsv}
        data={[row]}
        filename={"list-of-incident.csv"}
      />
    </>
  );
}
