import * as supportAction from "../actions/supportAction";
import {
  createTicketType,
  deleteFAQType,
  getArticleCatSubcategorySagaType,
  getDashboardTicketsType,
  getTicketCategoryType,
  getTicketDetailsType,
  getTicketsAdminDeskType,
  getTicketsFeedbackDeskType,
  getTicketsType,
  postAddFAQType,
  postClosedTicketType,
  postUpdateSeverityTicketType,
  postUserAssignTicketType,
  postUserFeedbackOnTicketType,
  replyTicketType,
  updateFAQType,
} from "../constants/supportType";
import { getArticleType } from "../constants/supportType";
import { getArticlesBasedCatSubcategoryType } from "../constants/supportType";
import { getArticleDetailsType } from "../constants/supportType";
import { addLikeDislikeArticleType } from "../constants/supportType";
import {
  deleteFaq,
  getArticleCatSubcategory,
  getDashboardTickets,
  getTicketCategory,
  getTicketDetails,
  getTickets,
  getTicketsAdminDesk,
  getTicketsFeedbackDesk,
  postAddFAQ,
  postClosedTicket,
  postUpdateSeverityTicket,
  postUserAssignTicket,
  replyTicket,
  updateFaq,
  userFeedbackOnTicket,
} from "../../services/supportService";
import { getArticlesBasedCatSubcategory } from "../../services/supportService";
import { getArticle } from "../../services/supportService";
import { getArticleDetails } from "../../services/supportService";
import { addLikeDislikeArticle } from "../../services/supportService";
import { createTicket } from "../../services/supportService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

// getArticleSaga SAGA
export function* getArticleSaga(searchText) {
  try {
    const { data, status, error } = yield call(getArticle, searchText);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.getArticleSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get artical Failed" };
    yield put(supportAction.getArticleFailure(data));
  }
}

export function* getTicketCategorySaga(searchText) {
  try {
    const { data, status, error } = yield call(getTicketCategory, searchText);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.getTicketCategorySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Ticket Category Failed" };
    yield put(supportAction.getTicketCategoryFailure(data));
  }
}

// getArticleCatSubcategorySaga SAGA
export function* getArticleCatSubcategorySaga(searchText) {
  try {
    const { data, status, error } = yield call(
      getArticleCatSubcategory,
      searchText
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.getArticleCatSubcategorySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get artical Failed" };
    yield put(supportAction.getArticleCatSubcategoryFailure(data));
  }
}

// getArticlesBasedCatSubcategorySaga SAGA
export function* getArticlesBasedCatSubcategorySaga(searchText) {
  try {
    const { data, status, error } = yield call(
      getArticlesBasedCatSubcategory,
      searchText
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.getArticlesBasedCatSubcategorySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get artical Failed" };
    yield put(supportAction.getArticlesBasedCatSubcategoryFailure(data));
  }
}

// getArticleDetailsSaga SAGA
export function* getArticlesDetailsSaga(searchText) {
  try {
    const { data, status, error } = yield call(getArticleDetails, searchText);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.getArticleDetailsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get artical Failed" };
    yield put(supportAction.getArticleDetailsFailure(data));
  }
}

// addLikeDislikeArticleSaga SAGA
export function* addLikeDislikeArticleSaga(params) {
  try {
    const { data, status, error } = yield call(addLikeDislikeArticle, params);
    if (!data && status !== 200) throw apiError(error);
    const addLikeDislikeArticlePage =
      addLikeDislikeArticleType.addLikeDislikeArticle;
    yield put(
      supportAction.addLikeDislikeArticleSuccess({
        addLikeDislikeArticlePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Observation Failed" };
    yield put(supportAction.addLikeDislikeArticleFailure(data));
  }
}

// getTicketsSaga SAGA
export function* getTicketsSaga(searchText) {
  try {
    const { data, status, error } = yield call(getTickets, searchText);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.getTicketsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Ticket Failed" };
    yield put(supportAction.getTicketsFailure(data));
  }
}

// getTicketDetailsSaga SAGA
export function* getTicketDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(getTicketDetails, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.getTicketDetailsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Ticket details Failed" };
    yield put(supportAction.getTicketDetailsFailure(data));
  }
}

// createTicketSaga SAGA
export function* createTicketSaga(params) {
  try {
    const { data, status, error } = yield call(createTicket, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.postCreateTicketSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Ticket Failed" };
    yield put(supportAction.postCreateTicketFailure(data));
  }
}

// replyTicketSaga SAGA
export function* replyTicketSaga(params) {
  try {
    const { data, status, error } = yield call(replyTicket, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.replyTicketSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Ticket Failed" };
    yield put(supportAction.replyTicketFailure(data));
  }
}

// UserFeedbackOnTicketSaga SAGA
export function* userFeedbackOnTicketSaga(params) {
  try {
    const { data, status, error } = yield call(userFeedbackOnTicket, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.postUserFeedbackSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Add Ticket Failed" };
    yield put(supportAction.postUserFeedbackFailure(data));
  }
}

// getDashboardTicketsSaga SAGA
export function* getDashboardTicketsSaga(searchText) {
  try {
    const { data, status, error } = yield call(getDashboardTickets, searchText);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.getDashboardTicketsSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Dashboard Ticket Failed" };
    yield put(supportAction.getDashboardTicketsFailure(data));
  }
}

// getTicketsAdminDeskSaga SAGA
export function* getTicketsAdminDeskSaga(searchText) {
  try {
    const { data, status, error } = yield call(getTicketsAdminDesk, searchText);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.getTicketsAdminDeskSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get AdminDesk Ticket Failed" };
    yield put(supportAction.getTicketsAdminDeskFailure(data));
  }
}

// getTicketsFeedbackDeskSaga SAGA
export function* getTicketsFeedbackDeskSaga(searchText) {
  try {
    const { data, status, error } = yield call(
      getTicketsFeedbackDesk,
      searchText
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.getTicketsFeedbackDeskSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get AdminDesk Ticket Failed" };
    yield put(supportAction.getTicketsFeedbackDeskFailure(data));
  }
}

// postClosedTicketSaga SAGA
export function* postClosedTicketSaga(params) {
  try {
    const { data, status, error } = yield call(postClosedTicket, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.postClosedTicketSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Closed Ticket Failed" };
    yield put(supportAction.postClosedTicketFailure(data));
  }
}

// postUserAssignTicketSaga SAGA
export function* postUserAssignTicketSaga(params) {
  try {
    const { data, status, error } = yield call(postUserAssignTicket, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.postUserAssignTicketSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post User Assign Ticket Failed" };
    yield put(supportAction.postUserAssignTicketFailure(data));
  }
}

// postUpdateSeverityTicketSaga SAGA
export function* postUpdateSeverityTicketSaga(params) {
  try {
    const { data, status, error } = yield call(
      postUpdateSeverityTicket,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.postUpdateSeverityTicketSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post User Assign Ticket Failed" };
    yield put(supportAction.postUpdateSeverityTicketFailure(data));
  }
}

// postAddFAQtSaga SAGA
export function* postAddFAQSaga(params) {
  try {
    const { data, status, error } = yield call(postAddFAQ, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.postAddFAQSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Add FAQ Failed" };
    yield put(supportAction.postAddFAQFailure(data));
  }
}

// deleteFAQtSaga SAGA
export function* deleteFAQSaga(params) {
  try {
    const { data, status, error } = yield call(deleteFaq, params.data);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.delteFAQSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Add FAQ Failed" };
    yield put(supportAction.delteFAQFailure(data));
  }
}

export function* updateFAQSaga(params) {
  try {
    const { data, status, error } = yield call(updateFaq, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      supportAction.updateFAQSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "FAQ updation Failed" };
    yield put(supportAction.updateFAQFailure(data));
  }
}

const mySupportSaga = [
  takeLatest(getArticleType.GET_ARTICLE_PENDING, getArticleSaga),
  takeLatest(
    getTicketCategoryType.GET_TICKET_CATEGORY_PENDING,
    getTicketCategorySaga
  ),
  takeLatest(
    getArticleCatSubcategorySagaType.GET_ARTICLE_CAT_SUBCATEGORY_PENDING,
    getArticleCatSubcategorySaga
  ),
  takeLatest(
    getArticlesBasedCatSubcategoryType.GET_ARTICLE_BASED_CAT_SUBCATEGORY_PENDING,
    getArticlesBasedCatSubcategorySaga
  ),
  takeLatest(
    getArticleDetailsType.GET_ARTICLE_DETAILS_PENDING,
    getArticlesDetailsSaga
  ),
  takeLatest(
    addLikeDislikeArticleType.POST_ADD_LIKE_DISLIKE_ARTICLE_PENDING,
    addLikeDislikeArticleSaga
  ),
  takeLatest(getTicketsType.GET_TICKETS_PENDING, getTicketsSaga),
  takeLatest(
    getTicketDetailsType.GET_TICKET_DETAILS_PENDING,
    getTicketDetailsSaga
  ),
  takeLatest(createTicketType.POST_CREATE_TICKET_PENDING, createTicketSaga),
  takeLatest(replyTicketType.POST_REPLY_TICKET_PENDING, replyTicketSaga),
  takeLatest(
    postUserFeedbackOnTicketType.POST_USER_FEEDBACK_ON_TICKET_PENDING,
    userFeedbackOnTicketSaga
  ),
  takeLatest(
    getDashboardTicketsType.GET_DASHBOARD_TICKETS_PENDING,
    getDashboardTicketsSaga
  ),
  takeLatest(
    getTicketsAdminDeskType.GET_TICKETS_ADMIN_DESK_PENDING,
    getTicketsAdminDeskSaga
  ),
  takeLatest(
    getTicketsFeedbackDeskType.GET_TICKETS_FEEDBACK_DESK_PENDING,
    getTicketsFeedbackDeskSaga
  ),
  takeLatest(
    postClosedTicketType.POST_CLOSED_TICKET_PENDING,
    postClosedTicketSaga
  ),
  takeLatest(
    postUserAssignTicketType.POST_USER_ASSIGN_TICKET_PENDING,
    postUserAssignTicketSaga
  ),
  takeLatest(
    postUpdateSeverityTicketType.POST_UPDATE_SEVERITY_TICKET_PENDING,
    postUpdateSeverityTicketSaga
  ),
  takeLatest(postAddFAQType.POST_ADD_FAQ_PENDING, postAddFAQSaga),
  takeLatest(deleteFAQType.DELETE_FAQ_PENDING, deleteFAQSaga),
  takeLatest(updateFAQType.UPDATE_FAQ_PENDING, updateFAQSaga),
];
export default mySupportSaga;
