import React, { useEffect, useRef, useState } from "react";
import { FormControl, Grid, RadioGroup } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { InputField } from "../../../../library/custom/textBox/InputField";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Button from "../../../../library/custom/button/Button";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { ADMIN_PROJECT } from "../../../../constant/routeContant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import {
  DIVISION_ID,
  LNG_ID,
  ORG_ID,
  PROJECT_GRID_COLOUMNS,
  PROJECT_TEMPLATE_URL,
  USERID,
  USER_TYPE,
} from "../../../../constant/constant";
import Buttons from "../../../../library/custom/button/Button";
import UsersChip from "../../../../library/custom/usersChip/UsersChip";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import { dateValiadtor, getFormatedDate } from "../../../../utils/helper";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const AddProject = ({
  onSubmit,
  division,
  userGroups,
  contractorsGroups,
  handleAllUserSearch,
  allUsers,
  isLoading,
  projectDetailsData,
  editMode,
  projectId,
  handleAllGroupSearch,
  handleonfcs,
}) => {
  const [isBulkUpload, setIsBulkUpload] = useState(false);

  useEffect(() => {
    if (projectDetailsData) {
      setindividualIds(
        ...individualIds,
        projectDetailsData?.assignToUser?.map((e) => e.key)
      );
      if (projectDetailsData?.assignToUser?.length > 0) {
        setUsersList(
          ...userList,
          projectDetailsData?.assignToUser?.map((e) => e)
        );
      }

      setuserGroupIds(
        ...userGroupIds,
        projectDetailsData?.assignToGroup?.map((e) => e.key)
      );
      if (projectDetailsData?.assignToGroup?.length > 0) {
        setUsersGroupList(
          ...userList,
          projectDetailsData?.assignToGroup?.map((e) => e)
        );
      }

      setContractorGroupsIds(
        ...contractorGroupsIds,
        projectDetailsData?.assignToSubContracter?.map((e) => e.key)
      );
      if (projectDetailsData?.assignToSubContracter?.length > 0) {
        setContractorGroupsList(
          ...contractorGroupsList,
          projectDetailsData?.assignToSubContracter?.map((e) => e)
        );
      }

      setInspectorIds(
        ...inspectorIds,
        projectDetailsData?.assignToInspector?.map((e) => e.key)
      );
      if (projectDetailsData?.assignToInspector?.length > 0) {
        setInspectorList(
          ...inspectorList,
          projectDetailsData?.assignToInspector?.map((e) => e)
        );
      }
      setsupervisorId(
        ...supervisorId,
        projectDetailsData?.assignToSupervisor?.map((e) => e.key)
      );
      if (projectDetailsData?.assignToSupervisor?.length > 0) {
        setsupervisoList(
          ...supervisoList,
          projectDetailsData?.assignToSupervisor?.map((e) => e)
        );
      }
      setFormValue({
        ...formValue,
        isActive: projectDetailsData.statusId == 1 ? "1" : "2",
        projectId: projectDetailsData.projectId,
        projectName: projectDetailsData.projectName,
        location: projectDetailsData.location,
        startDate: projectDetailsData.startDate,
        completionDate: projectDetailsData.completionDate,
        divisionIds: projectDetailsData.divisionId,
        createdBy: parseInt(USERID),
        organisationId: projectDetailsData.organisationId,
        division: projectDetailsData.divisionId,
        assetSupervisor: projectDetailsData.supervisorId,
        supervisorId: projectDetailsData.supervisorId,
        subContractor: "",
      });
    }
  }, [projectDetailsData]);

  const initialState = {
    projectName: "",
    location: "",
    startDate: new Date(),
    completionDate: null,
    divisionIds:
      DIVISION_ID() == "null"
        ? ""
        : DIVISION_ID() == 0
        ? "undefined"
        : DIVISION_ID(),
    createdBy: parseInt(USERID),
    organisationId: ORG_ID(),
    division:
      DIVISION_ID() == "null"
        ? ""
        : DIVISION_ID() == 0
        ? "undefined"
        : DIVISION_ID(),
    assetSupervisor: "",
    userGroups: "",
    supervisorId: "",
    subContractor: "",
    individualUsers: "",
  };
  const [formValue, setFormValue] = useState(initialState);
  const dataBack = {
    title: "Back to Projects",
    route: ADMIN_PROJECT,
  };
  const [isProjectName, setisProjectName] = useState(false);
  const [isDivisionId, setisDivisionId] = useState(false);
  const [isLoacation, setisLoacation] = useState(false);
  const [isStartDate, setisStartDate] = useState(false);
  const [isCompletionDate, setisCompletionDate] = useState(false);
  const [completitionDateErrMsg, setcompletitionDateErrMsg] = useState("");
  const [startDateErrMsg, setStartDateErrMsg] = useState("");

  function mappedData() {
    let errors = false;

    if (formValue?.projectName?.length === 0) {
      setisProjectName(true);
      errors = true;
    }
    console.log(formValue.divisionIds, formValue.division);
    if (formValue?.divisionIds.length === 0) {
      setisDivisionId(true);
      errors = true;
    }
    if (formValue?.division === "undefined") {
      setisDivisionId(true);
      errors = true;
    }
    if (formValue?.location?.length === 0) {
      setisLoacation(true);
      errors = true;
    }
    if (formValue?.startDate === null) {
      setisStartDate(true);
      errors = true;
      setStartDateErrMsg("Start Date is required");
    }

    if (formValue.startDate != null) {
      const validDate = dateValiadtor(getFormatedDate(formValue.startDate));
      if (validDate === false) {
        setisStartDate(true);
        errors = true;
        setStartDateErrMsg("Invalid Date");
      }
    }
    // if (formValue?.completionDate === null) {
    //   setisCompletionDate(true);
    //   errors = true;
    //   setcompletitionDateErrMsg("Completion Date is required");
    // }
    if (formValue.completionDate != null) {
      const validDate = dateValiadtor(
        getFormatedDate(formValue.completionDate)
      );
      if (validDate === false) {
        setisCompletionDate(true);
        errors = true;
        setcompletitionDateErrMsg("Invalid Date");
      }
    }
    if (errors) {
      return false;
    }

    let data;
    if (editMode)
      data = {
        projectName: formValue.projectName,
        divisionId: formValue.division,
        location: formValue.location,
        supervisorIds: supervisorId,
        individualUserIds: individualIds,
        userGroupIds: userGroupIds,
        inspectorUserIds: inspectorIds,
        subcontractorIds: contractorGroupsIds,
        projectId: projectId,
        modifiedBy: formValue.createdBy,
        isActive: formValue.isActive == "1" ? true : false,
        startDate: getFormatedDate(formValue.startDate),
        completionDate: formValue?.completionDate
          ? getFormatedDate(formValue.completionDate)
          : null,
      };
    else
      data = {
        projectName: formValue.projectName,
        divisionId: formValue.divisionIds,
        location: formValue.location,
        supervisorIds: supervisorId,
        individualUserIds: individualIds,
        userGroupIds: userGroupIds,
        inspectorUserIds: inspectorIds,
        subcontractorIds: contractorGroupsIds,
        startDate: getFormatedDate(formValue?.startDate),
        completionDate: formValue?.completionDate
          ? getFormatedDate(formValue?.completionDate)
          : null,
        createdBy: formValue.createdBy,
        organisationId: formValue.organisationId,
      };

    return data;
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      console.log(data.startDate);
      editMode ? onSubmit(data) : onSubmit([data]);
    }
  };

  const autoCompSupervisor = useRef(null);
  const autoCompUser = useRef(null);
  const autoCompGroup = useRef(null);
  const autoCompContractor = useRef(null);
  const autoCompInspector = useRef(null);

  const [supervisoList, setsupervisoList] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [userGroupList, setUsersGroupList] = useState([]);
  const [contractorGroupsList, setContractorGroupsList] = useState([]);
  const [inspectorList, setInspectorList] = useState([]);
  const [supervisor, setsupervisor] = useState(1);
  const [users, setUsers] = useState(1);
  const [usersGroup, setUsersGroup] = useState(1);
  const [contractorGroups, setContractorGroups] = useState(1);
  const [inspector, setInspector] = useState(1);
  const [supervisorId, setsupervisorId] = useState([]);
  const [individualIds, setindividualIds] = useState([]);
  const [userGroupIds, setuserGroupIds] = useState([]);
  const [contractorGroupsIds, setContractorGroupsIds] = useState([]);
  const [inspectorIds, setInspectorIds] = useState([]);

  const deleteData = (id) => {
    const updateList = userList.filter((list) => list.key !== id);
    const updateAssignIds = individualIds.filter((list) => list !== id);
    setUsersList(updateList);
    setindividualIds(updateAssignIds);
  };
  const deleteusersGroupData = (id) => {
    const updateUserGroupList = userGroupList.filter((list) => list.key !== id);
    const updateUserGroupIds = userGroupIds.filter((list) => list !== id);
    setUsersGroupList(updateUserGroupList);
    setuserGroupIds(updateUserGroupIds);
  };

  const deleteContractorGroupsData = (id) => {
    const updatecontractorGroupsList = contractorGroupsList.filter(
      (list) => list.key !== id
    );
    const updateContractorGroupsIds = contractorGroupsIds.filter(
      (list) => list !== id
    );
    setContractorGroupsList(updatecontractorGroupsList);
    setContractorGroupsIds(updateContractorGroupsIds);
  };

  const deleteInspectorData = (id) => {
    const updateInspectorList = inspectorList.filter((list) => list.key !== id);
    const updateInspectorIds = inspectorIds.filter((list) => list !== id);
    setInspectorList(updateInspectorList);
    setInspectorIds(updateInspectorIds);
  };

  const deleteSupervisorData = (id) => {
    const updateSupervisorList = supervisoList.filter(
      (list) => list.key !== id
    );
    const updateSupervisorIds = supervisorId.filter((list) => list !== id);
    setsupervisoList(updateSupervisorList);
    setsupervisorId(updateSupervisorIds);
  };
  const addUserData = () => {
    if (!users.key) return;
    const index = userList.findIndex((object) => object.key === users.key);
    if (index === -1) {
      setindividualIds([...individualIds, users.key]);
      setUsersList([...userList, users]);
    }
    const ele = autoCompUser.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const addUserGroupData = () => {
    if (!usersGroup.key) return;
    const index = userGroupList.findIndex(
      (object) => object.key === usersGroup.key
    );
    if (index === -1) {
      setuserGroupIds([...userGroupIds, usersGroup.key]);
      setUsersGroupList([...userGroupList, usersGroup]);
    }
    const ele = autoCompGroup.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const addContractorGroupsData = () => {
    if (!contractorGroups.key) return;
    const index = contractorGroupsList.findIndex(
      (object) => object.key === contractorGroups.key
    );
    if (index === -1) {
      setContractorGroupsIds([...contractorGroupsIds, contractorGroups.key]);
      setContractorGroupsList([...contractorGroupsList, contractorGroups]);
    }
    const ele = autoCompContractor.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const addInspectorData = () => {
    if (!inspector.key) return;

    const index = inspectorList.findIndex(
      (object) => object.key === inspector.key
    );
    if (index === -1) {
      setInspectorIds([...inspectorIds, inspector.key]);
      setInspectorList([...inspectorList, inspector]);
    }
    const ele = autoCompInspector.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const addSupervisorData = () => {
    if (!supervisor.key) return;

    const index = supervisoList.findIndex(
      (object) => object.key === supervisor.key
    );
    if (index === -1) {
      setsupervisorId([...supervisorId, supervisor.key]);
      setsupervisoList([...supervisoList, supervisor]);
    }
    const ele = autoCompSupervisor.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };
  const handleFileChanges = (data) => {
    const projects = data.map((x, index) => {
      x.projectName = x?.projectName;
      x.divisionId = parseInt(x.divisionId);
      x.location = x.location;
      x.supervisorIds =
        x.supervisorIds === undefined ? [] : x.supervisorIds.split(",");
      x.individualUserIds =
        x.individualUserIds === undefined ? [] : x.individualUserIds.split(",");
      x.userGroupIds =
        x.userGroupIds === undefined ? [] : x.userGroupIds.split(",");
      x.inspectorUserIds =
        x.inspectorUserIds === undefined ? [] : x.inspectorUserIds.split(",");
      x.subcontractorIds =
        x.subcontractorIds === undefined ? [] : x.subcontractorIds.split(",");
      x.startDate = x.startDate?.replace(/\//g, "-");
      x.completionDate = x.completionDate?.replace(/\//g, "-");
      x.createdBy = parseInt(USERID);
      x.organisationId = parseInt(ORG_ID());
      x.lngId = parseInt(LNG_ID);
      x.status = true;
      return x;
    });
    const projectsData = {
      projects,
    };
    onSubmit(projectsData.projects);
  };
  const [isSupervisor, setisSupervisor] = useState(false);
  const [isInspector, setisInspector] = useState(false);
  const [isIndividualUser, setisIndividualUser] = useState(false);
  const [isUserGroup, setisUserGroup] = useState(false);
  const [isContractor, setisContractor] = useState(false);
  const [isSupervisorErrMsg, setisSupervisorErrMsg] = useState("");
  const [isInspectorErrMsg, setisInspectorErrMsg] = useState("");
  const [isIndividualUserErrMsg, setisIndividualUserErrMsg] = useState("");
  const [isUserGroupErrMsg, setisUserGroupErrMsg] = useState("");
  const [isContractorErrMsg, setisContractorErrMsg] = useState("");

  const mapBulkError = (data) => {
    let projectsWithError = [];
    data.forEach((x) => {
      const validDate = dateValiadtor(
        getFormatedDate(x.startDate?.replace(/\//g, "-"))
      );
      if (
        x.projectName?.trim() === undefined ||
        null ||
        "" ||
        x.divisionId?.trim() === undefined ||
        null ||
        "" ||
        x.location?.trim() === undefined ||
        null ||
        "" ||
        !validDate
      ) {
        projectsWithError.push(x);
        return;
      }
    });
    return projectsWithError;
  };
  return (
    <>
      <div className="overidePersonal">
        <Grid container item lg={9} xs={12} sm={12} md={9}>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
            <div className="heading_style">
              {editMode ? "Edit Project" : "Add Project"}
            </div>
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="right">
            {checkPrivileges([70, 12]) && !editMode ? (
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={isBulkUpload ? "Single Project" : "Multiple Projects"}
                varientContained={true}
                onClick={() => setIsBulkUpload(!isBulkUpload)}
              ></Buttons>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        {editMode && (
          <Grid item container mt={3} spacing={2}>
            <Grid item lg={4} mt={3} xs={12} sm={12} md={4}>
              {checkPrivileges([70, 12]) && (
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="radioGroup"
                  >
                    <RadioButton
                      checked={formValue.isActive === "1"}
                      value="1"
                      label="Active"
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          isActive: e.target.value,
                        })
                      }
                    ></RadioButton>
                    <RadioButton
                      checked={formValue.isActive === "2"}
                      value="2"
                      label="Inactive"
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          isActive: e.target.value,
                        })
                      }
                    ></RadioButton>
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
            <Grid item lg={5} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  type="text"
                  label={"Project ID"}
                  disabled={true}
                  value={projectId}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        {isBulkUpload ? (
          <Grid container>
            <Grid item lg={9} xs={9} sm={9} md={9} mt={4}>
              <BulkUpload
                mapBulkError={mapBulkError}
                handleFileChanges={handleFileChanges}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                templateUrl={PROJECT_TEMPLATE_URL}
                templateText="Download Template"
                gridColoumns={PROJECT_GRID_COLOUMNS}
                isCustomColoumns={true}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container item md={9} xs={9} className="addShadowBox">
            <Grid container lg={12}>
              <Grid lg={6} xs={12} sm={12} md={6} fullWidth>
                <InputField
                  isRequired={true}
                  fullWidth={true}
                  disabled={false}
                  type="text"
                  label="Project Name"
                  value={formValue.projectName}
                  onChange={(e) => {
                    if (
                      e.target.value === " " &&
                      formValue.projectName.trim() === ""
                    )
                      setFormValue({
                        ...formValue,
                        projectName: "",
                      });
                    else if (e.target.value.length > 200) {
                      return;
                    } else
                      setFormValue({
                        ...formValue,
                        projectName: e.target.value,
                      });
                  }}
                  isError={isProjectName}
                  errorMsg={
                    isProjectName ? "Please enter this required field" : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid item container mt={3} spacing={2} lg={12}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Division" isRequired={true} />
                  <SelectMenu
                    placeholder="Please Select"
                    listData={division}
                    //  value={formValue.division}
                    value={
                      formValue.division === "undefined"
                        ? null
                        : formValue.division
                    }
                    onchangehandler={(e) => {
                      setFormValue({
                        ...formValue,
                        division: e.target.value,
                        divisionIds: e.target.value,
                      });
                      //   setisDivisionId(true);
                      setisIndividualUser(false);
                      setUsersList([]);
                      setindividualIds([]);

                      setisUserGroup(false);
                      setuserGroupIds([]);
                      setUsersGroupList([]);

                      setisContractor(false);
                      setContractorGroupsList([]);
                      setContractorGroupsIds([]);

                      setisSupervisor(false);
                      setsupervisoList([]);
                      setsupervisorId([]);

                      setisInspector(false); // inspector
                      setInspectorList([]);
                      setInspectorIds([]);
                    }}
                    disabled={DIVISION_ID() > 0}
                    // isError={isDivisionId}
                    // errorMsg={
                    //   isDivisionId ? "Selection is required for this field" : ""
                    // }
                  />
                </FormControl>
                {isDivisionId && (
                  <div className="errorMsg">
                    Selection is required for this field
                  </div>
                )}
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <InputField
                  fullWidth={true}
                  type="text"
                  label="Location"
                  value={formValue.location}
                  placeholder="Location"
                  isRequired={true}
                  onChange={(e) => {
                    if (
                      e.target.value === " " &&
                      formValue.location.trim() === ""
                    )
                      setFormValue({
                        ...formValue,
                        location: "",
                      });
                    else
                      setFormValue({
                        ...formValue,
                        location: e.target.value,
                      });
                  }}
                  isError={isLoacation}
                  errorMsg={
                    isLoacation ? "Please enter this required field" : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isSupervisor && (
                  <div className="errorMsg">{isSupervisorErrMsg}</div>
                )}
                <FormLabels label="Asset Supervisor" />
                <SearchAutoComplete
                  name="Asset Supervisor"
                  optionsData={isLoading ? [] : allUsers?.users ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.division === "undefined") {
                      setisSupervisor(true);
                      setisSupervisorErrMsg("Please select division first");
                      return;
                    } else {
                      // console.log(formValue.division)
                      setisSupervisor(false);
                      setisSupervisorErrMsg("");
                      handleAllUserSearch(
                        event,
                        USER_TYPE.DIVISION_EMPLOYEE,
                        formValue.division,
                        0,
                        0,
                        141
                      );
                    }
                  }}
                  handleFocus={handleonfcs && handleonfcs}
                  isKeyValuePair={true}
                  value={formValue.assetSupervisor}
                  onChange={(e, value) => setsupervisor(value)}
                  isLoading={isLoading}
                  autoCompClear={autoCompSupervisor}
                  // isError={isSupervisor}
                  // errorMsg={isSupervisor && isSupervisorErrMsg}
                />
              </Grid>
              {/* <Grid
                className="smallAddBtn"
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                mt={4}
                ml={2}
              > */}
              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addSupervisorData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  userList={supervisoList}
                  handleDelete={(e) => deleteSupervisorData(e)}
                />
              </Grid>
            </Grid>
            {/* </Grid> */}

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isInspector && (
                  <div className="errorMsg">{isInspectorErrMsg}</div>
                )}
                <FormLabels label="Assign Inspector" />
                <SearchAutoComplete
                  name="Assign Inspector"
                  optionsData={isLoading ? [] : allUsers?.users ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.division === "undefined") {
                      setisInspector(true);
                      setisInspectorErrMsg("Please select division first");
                      return;
                    } else {
                      setisInspector(false);
                      setisInspectorErrMsg("");
                      handleAllUserSearch(
                        event,
                        USER_TYPE.DIVISION_EMPLOYEE,
                        formValue.division,
                        0,
                        0,
                        140
                      );
                    }
                  }}
                  handleFocus={handleonfcs && handleonfcs}
                  isKeyValuePair={true}
                  value={formValue.supervisor}
                  onChange={(e, value) => setInspector(value)}
                  isLoading={isLoading}
                  autoCompClear={autoCompInspector}
                />
              </Grid>

              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addInspectorData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  userList={inspectorList}
                  handleDelete={(e) => deleteInspectorData(e)}
                />
              </Grid>
            </Grid>

            <Grid
              item
              className="addWitnessContainerGrid "
              container
              mt={3}
              spacing={2}
              lg={12}
            >
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label={"Start Date"} isRequired={true} />
                  <DatePicker
                    value={formValue.startDate}
                    onChangeHandler={(e) => {
                      setFormValue({
                        ...formValue,
                        startDate: e,
                      });
                      setisStartDate(false);
                    }}
                    isError={isStartDate}
                    errorMsg={isStartDate ? startDateErrMsg : ""}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label={"Completion Date"} isRequired={false} />
                  <DatePicker
                    value={formValue.completionDate}
                    maxDateEnable
                    minDate={formValue.startDate}
                    onChangeHandler={(e) => {
                      setFormValue({
                        ...formValue,
                        completionDate: e,
                      });
                      setisCompletionDate(false);
                    }}
                    isError={isCompletionDate}
                    errorMsg={isCompletionDate ? completitionDateErrMsg : ""}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isIndividualUser && (
                  <div className="errorMsg">{isIndividualUserErrMsg}</div>
                )}
                <FormLabels label="Add Individual Users" />
                <SearchAutoComplete
                  name="Add Individual Users"
                  optionsData={isLoading ? [] : allUsers?.users ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.division === "undefined") {
                      setisIndividualUser(true);
                      setisIndividualUserErrMsg("Please select division first");
                      return;
                    } else {
                      setisIndividualUser(false);
                      setisIndividualUserErrMsg("");
                      handleAllUserSearch(
                        event,
                        USER_TYPE.NONPRIVILEGED,
                        formValue.division
                      );
                    }
                  }}
                  handleFocus={handleonfcs && handleonfcs}
                  isKeyValuePair={true}
                  value={formValue.supervisor}
                  onChange={(e, value) => setUsers(value)}
                  isLoading={isLoading}
                  autoCompClear={autoCompUser}
                />
              </Grid>

              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addUserData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  userList={userList}
                  handleDelete={(e) => deleteData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isUserGroup && (
                  <div className="errorMsg">{isUserGroupErrMsg}</div>
                )}
                <FormLabels label="Add User Group" />
                <SearchAutoComplete
                  name="Add User Group"
                  optionsData={isLoading ? [] : userGroups ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.division === "undefined") {
                      setisUserGroup(true);
                      setisUserGroupErrMsg("Please select division first");
                      return;
                    } else {
                      setisUserGroup(false);
                      setisUserGroupErrMsg("");

                      handleAllGroupSearch(event, formValue.division);
                    }
                  }}
                  isLoading={isLoading}
                  isKeyValuePair={true}
                  value={formValue.userGroups}
                  onChange={(e, value) => setUsersGroup(value)}
                  noOptionsTextMsg="No group"
                  autoCompClear={autoCompGroup}
                />
              </Grid>

              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addUserGroupData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  isKeyValuePair={true}
                  userList={userGroupList}
                  handleDelete={(e) => deleteusersGroupData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isContractor && (
                  <div className="errorMsg">{isContractorErrMsg}</div>
                )}
                <FormLabels label="Add Subcontractors" />
                <SearchAutoComplete
                  name="Add Subcontractors"
                  optionsData={allUsers?.users ?? []}
                  typeOnchange={(event, value = 1) => {
                    if (formValue.division === "undefined") {
                      setisContractor(true);
                      setisContractorErrMsg("Please select division first");
                      return;
                    } else {
                      setisContractor(false);
                      setisContractorErrMsg("");

                      handleAllUserSearch(
                        event,
                        USER_TYPE.DIVISION_CONTRACTOR,
                        formValue.division
                      );
                    }
                  }}
                  handleFocus={handleonfcs && handleonfcs}
                  isKeyValuePair={true}
                  value={formValue.subContractor}
                  onChange={(e, value) => setContractorGroups(value)}
                  autoCompClear={autoCompContractor}
                />
              </Grid>

              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addContractorGroupsData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  isKeyValuePair={true}
                  userList={contractorGroupsList}
                  handleDelete={(e) => deleteContractorGroupsData(e)}
                />
              </Grid>
            </Grid>

            <Grid container mt={7}>
              <Grid container item lg={12} gap={12} alignItems="center">
                <Grid
                  item
                  lg={4}
                  textAlign="center"
                  className="addcourseButton"
                >
                  <BackBtn dataBack={dataBack} />
                </Grid>
                <Grid
                  item
                  lg={6}
                  textAlign="center"
                  className="addcourseButton"
                >
                  <Button
                    varientContained={true}
                    label={editMode ? "Update" : "Submit"}
                    onClick={submitData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default AddProject;
