import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextArea from "../../../library/custom/textArea/TextArea";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import ViewImages from "../../incidents/view-incident-report/common/viewImages/viewImages";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import {
  INSPECTION_REPORT,
  OPEN_ITEMS_LIST,
  VIEW_PERSONAL_INJURY,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { ASSETMANAGEMENT, GET_COUNT_TYPE } from "../../../constant/constant";
import parse from "html-react-parser";
import { RemoveRedEye } from "@mui/icons-material";
import FormDropZone from "../../../library/dropZone/FormDropZone";

const PersonalInjuryActionReport = ({
  personalReportData,
  actionReportId,
  closeAction,
  actionStatus,
  origin,
  InspectionOpenItemDetail,
  closeInspectionAction,
  addedById,
  actionIncidentId,
  actionCaseId,
  actionItemTypeId,
  involvedPartyId,
}) => {
  const imageSize = {
    size: "small",
  };

  const data = personalReportData?.actions?.filter(
    (x) => x.actionId === actionReportId
  );
  const [closesFilesData, setCloseFilesData] = useState("");
  const [closesFilesData1, setCloseFilesData1] = useState("");
  const [isNote, setIsNote] = useState(false);
  const [isCorrective, setIsCorrectiveMeasure] = useState(false);
  const InspectionData = InspectionOpenItemDetail;
  const navigate = useNavigate();
  const involvedUserId = InspectionOpenItemDetail?.listInvolvedParty[0]?.userId;

  const dataBack = {
    title: "Back to Open Item",
    route: OPEN_ITEMS_LIST,
  };

  const [formValue, setFormValue] = useState({
    // actionId: 0,
    note: "",
    correctiveMeasure: "",
  });

  function mappedData() {
    let errors = false;

    if (formValue.note?.length === 0) {
      setIsNote(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const closeData = {
      actionId: actionReportId,
      note: formValue.note,
      files: [closesFilesData1?.fileId ? closesFilesData1?.fileId : 0],
    };
    return closeData;
  }
  function mapData() {
    let errors = false;

    if (formValue.correctiveMeasure?.length === 0) {
      setIsCorrectiveMeasure(true);
      errors = true;
    }

    if (errors) {
      return false;
    }

    const closeActionData = {
      id: actionReportId,
      type: GET_COUNT_TYPE.ACTION,
      involvedUserId: involvedPartyId,
      isEscalateToReprimand: false,
      note: formValue.correctiveMeasure,
      userId: involvedUserId,
      files: [closesFilesData?.fileId ? closesFilesData?.fileId : 0],
    };
    return closeActionData;
  }
  const closeInspection = () => {
    const InspectionSubmitData = mapData();
    if (!InspectionSubmitData) {
    } else {
      closeInspectionAction(InspectionSubmitData);
    }
  };
  const caseData = personalReportData?.incCase;
  const caseDetail = {
    dateOfIncident: caseData?.dateOfCase,
    timeOfIncident: caseData?.timeOfCase,
    location: caseData?.location,
    caseId: caseData?.caseId,
  };

  const IncReportId = personalReportData?.incidentId;

  const submitData = () => {
    const submitData = mappedData();
    if (!submitData) {
    } else {
      closeAction(submitData);
    }
  };

  const handleFileChange = (files, single) => {
    setCloseFilesData({ fileId: single[0], filePath: files });
  };

  const handleDeleteImageURL = () => {
    setCloseFilesData([]);
  };

  const handleFileChange1 = (files, single) => {
    setCloseFilesData1({ fileId: single[0], filePath: files });
  };

  const handleDeleteImageURL1 = () => {
    setCloseFilesData1([]);
  };

  const correctiveMedia = personalReportData?.actions[0]?.closedFiles[0];
  const media = correctiveMedia ? correctiveMedia?.filePath?.split("|") : "";
  const inspectionDetails = (id) => {
    navigate(INSPECTION_REPORT, {
      state: { reportId: InspectionData?.inspectionId },
    });
  };

  const incidentDetailDetails = (id) => {
    navigate(VIEW_PERSONAL_INJURY, {
      state: {
        incidentId: IncReportId,
        caseId: actionIncidentId,
        incidentType: actionItemTypeId,
      },
    });
  };

  const inspectionClosedFiles = InspectionData?.closedFiles
    ? InspectionData?.closedFiles[0]?.filePath?.split("|")
    : "null";

  return (
    <div className="observationOveride">
      <div className="commonOveride">
        {origin === "Inspection" ? (
          <Box className="commoninfoWrap">
            <Box className="commoninfoBox">
              <Box className="commoninfoleft">
                <Typography className="titleAction">Action Assigned</Typography>
                <Typography
                  className="reportid"
                  mt={4}
                  onClick={() => inspectionDetails()}
                >
                  Inspection ID: <span>{InspectionData?.inspectionId}</span>
                  <span className="redirectPage">
                    <RemoveRedEye />
                  </span>
                </Typography>
                <Typography className="CaseId" mt={4}>
                  Action ID:{" "}
                  <span>{InspectionData?.inspectionOrReprimandId}</span>
                </Typography>

                <Box className="columns" mt={4}>
                  <Typography className="listView">
                    Corrective Party:
                    <span className="font-w400">
                      {InspectionData?.itemRespOrCorrecingPartyName}
                    </span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Date of Inspection:
                    <span className="font-w400">
                      {InspectionData?.inspectionDate}
                    </span>
                  </Typography>
                  <Typography className="listView">
                    Time of Inspection:
                    <span className="font-w400">
                      {InspectionData?.inspectionTime}
                    </span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Asset Type:
                    <span className="font-w400">
                      {InspectionData?.assetType === 1
                        ? "Project"
                        : InspectionData?.assetType === 3
                        ? "Vehicle"
                        : InspectionData?.assetType === 2
                        ? "Equipment"
                        : InspectionData?.assetType === 4
                        ? "Custom Assets"
                        : ""}
                    </span>
                  </Typography>
                  <Typography className="listView">
                    {InspectionData?.assetType === 1
                      ? "Project"
                      : InspectionData?.assetType === 3
                      ? "Vehicle"
                      : InspectionData?.assetType === 2
                      ? "Equipment"
                      : InspectionData?.assetType === 4
                      ? "Custom Assets"
                      : ""}{" "}
                    :
                    <span className="font-w400">
                      {InspectionData?.assetName}
                    </span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Location:
                    <span className="font-w400">
                      {InspectionData?.location}
                    </span>
                  </Typography>
                </Box>

                <Box className="columns">
                  <Typography className="listView">
                    Category:
                    <span className="font-w400">{InspectionData?.catName}</span>
                  </Typography>

                  <Typography className="listView">
                    Sub Category:
                    <span className="font-w400">
                      {InspectionData?.subCatName}
                    </span>
                  </Typography>
                </Box>

                <Typography className="listView ">
                  Question {" " + InspectionData?.questionSequenceNumber}:
                  <span className="blockView">
                    {parse(unescape(InspectionData?.questionName))}
                  </span>{" "}
                </Typography>

                <Typography className="listView ">
                  Action Assigned:
                  <span className="blockView">
                    {parse(unescape(InspectionData?.issue))}
                  </span>
                </Typography>
                <Typography className="listView ">
                  Media:
                  <span className="blockView">
                    {InspectionOpenItemDetail?.correctiveFiles?.map(
                      (list, index) => {
                        return (
                          <ViewImages
                            singleImagesid={list?.fileId}
                            singleImagespath={list?.filePath}
                            imageSize={imageSize}
                            singleImage={true}
                            multiImage={false}
                            key={index}
                          />
                        );
                      }
                    )}
                  </span>
                </Typography>
                {actionStatus === "Closed" ? (
                  <>
                    <Typography className="listView ">
                      Comments:
                      <span className="blockView">
                        {InspectionData?.abatementNotes}
                      </span>
                    </Typography>
                    <Typography className="listView ">
                      Media:
                      <span className="blockView">
                        <ViewImages
                          image={inspectionClosedFiles}
                          imageSize={imageSize}
                        />
                      </span>
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Box>

              <Box className="commoninfoRight">
                <Grid mt={4}>
                  <Typography className="incstatus">Action Status</Typography>
                  {actionStatus === "Closed" ? (
                    <Button
                      variant="contained"
                      className="statusbtn cursonDefault"
                    >
                      {actionStatus}
                    </Button>
                  ) : actionStatus === "Open" ? (
                    <Button
                      variant="contained"
                      className="statusbtnOpen cursonDefault"
                    >
                      {actionStatus}
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="commoninfoWrap">
            {data?.map((list) => {
              const image = list?.files
                ? list?.files[0]?.filePath?.split("|")
                : "";
              return (
                <>
                  <Box className="commoninfoBox">
                    <Box className="commoninfoleft">
                      <Typography className="CaseID" mt={4}>
                        Case ID:{" "}
                        <span className="caseNo">{caseDetail?.caseId}</span>
                      </Typography>
                      <Typography
                        className="reportid"
                        mt={4}
                        onClick={() => incidentDetailDetails()}
                      >
                        Report ID: <span>{IncReportId}</span>
                        <span className="redirectPage">
                          <RemoveRedEye />
                        </span>
                      </Typography>
                      <Box className="columns" mt={4}>
                        <Typography className="listView">
                          Corrective Party:
                          <span className="font-w400">
                            {list?.assignedPartyName}
                          </span>
                        </Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView">
                          Date of Incident:
                          <span className="font-w400">
                            {caseDetail?.dateOfIncident}
                          </span>
                        </Typography>
                        <Typography className="listView">
                          Time of Incident:
                          <span className="font-w400">
                            {caseDetail?.timeOfIncident}
                          </span>
                        </Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView">
                          Location:
                          <span className="font-w400">
                            {caseDetail?.location}
                          </span>
                        </Typography>
                      </Box>

                      <Typography className="listView ">
                        Action Assigned:
                        <span className="blockView">
                          {parse(unescape(list?.description))}
                        </span>
                      </Typography>
                      <Typography className="listView ">
                        Media:
                        <span className="blockView">
                          <ViewImages image={image} imageSize={imageSize} />
                        </span>
                      </Typography>
                      {actionStatus === "Closed" && origin === "Incidents" ? (
                        <>
                          <Typography className="listView ">
                            Comments:
                            <span className="blockView">{list?.closeNote}</span>
                          </Typography>
                          <Typography className="listView ">
                            Media:
                            <span className="blockView">
                              <ViewImages image={media} imageSize={imageSize} />
                            </span>
                          </Typography>
                        </>
                      ) : (
                        ""
                      )}
                    </Box>

                    <Box className="commoninfoRight">
                      <Grid mt={4}>
                        <Typography className="incstatus">
                          Action Status
                        </Typography>
                        {actionStatus === "Closed" ? (
                          <Button
                            variant="contained"
                            className="statusbtn cursonDefault"
                          >
                            {actionStatus}
                          </Button>
                        ) : actionStatus === "Open" ? (
                          <Button
                            variant="contained"
                            className="statusbtnOpen cursonDefault"
                          >
                            {actionStatus}
                          </Button>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
        )}

        {actionStatus === "Closed" ? (
          ""
        ) : origin === "Inspection" && actionStatus === "Open" ? (
          <Typography className="correctivemeasures desNone" mt={4}>
            <Grid item md={12} className="closedItemDropboxs">
              <FormLabels label="Comments" isRequired={true} />
              <TextArea
                placeholder={"Enter Here"}
                value={formValue.correctiveMeasure}
                onChange={(e) => {
                  if (
                    e.target.value === " " &&
                    formValue.correctiveMeasure.trim() === ""
                  )
                    setFormValue({
                      ...formValue,
                      correctiveMeasure: "",
                    });
                  else
                    setFormValue({
                      ...formValue,
                      correctiveMeasure: e.target.value,
                    });
                  setIsCorrectiveMeasure(false);
                }}
                isError={isCorrective}
              />
              {isCorrective ? (
                <div className="newErrorMsg">
                  Please Select this required field
                </div>
              ) : (
                ""
              )}
              <FormDropZone
                label={""}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                handleFileChange={handleFileChange}
                fileType={10}
                imageUrl={closesFilesData?.filePath}
                deleteImageURL={(url) => handleDeleteImageURL()}
                id="addClosed"
                key="addClosed"
                isImage={false}
                isMultipleIcon={true}
                allImages={true}
              />
            </Grid>
          </Typography>
        ) : (
          <Typography className="correctivemeasures desNone" mt={4}>
            <Grid item md={12} className="closedItemDropboxs">
              <FormLabels label="Comments" isRequired={true} />
              <TextArea
                placeholder={"Enter Here"}
                value={formValue?.note}
                onChange={(e) => {
                  if (e.target.value === " " && formValue.note.trim() === "")
                    setFormValue({
                      ...formValue,
                      note: "",
                    });
                  else
                    setFormValue({
                      ...formValue,
                      note: e.target.value,
                    });
                  setIsNote(false);
                }}
                isError={isNote}
              />
              {isNote ? (
                <div className="newErrorMsg">
                  Please Select this required field
                </div>
              ) : (
                ""
              )}
              <FormDropZone
                label={""}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                handleFileChange={handleFileChange1}
                fileType={10}
                imageUrl={closesFilesData1?.filePath}
                deleteImageURL={(url) => handleDeleteImageURL1()}
                id="addClosed"
                key="addClosed"
                isImage={false}
                isMultipleIcon={true}
                allImages={true}
              />
            </Grid>
          </Typography>
        )}
        <Box className="escalatereprimand">
          <Grid mt={4}>
            <BackBtn dataBack={dataBack} className="equipmentBack" />
          </Grid>
          <Grid mt={4} style={{ marginRight: "-240px" }}>
            {actionStatus === "Closed" ? (
              ""
            ) : origin === "Inspection" ? (
              <Button
                varientContained={true}
                className="closereprimandbtn"
                onClick={closeInspection}
              >
                Close Item
              </Button>
            ) : (
              <Button
                varientContained={true}
                className="closereprimandbtn"
                onClick={submitData}
              >
                Close Item
              </Button>
            )}
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default PersonalInjuryActionReport;
