import { API_STATUS } from "../../constant/constant";
import { perosnalInjuryType } from "../constants/perosnalInjuryType";

const initialState = {
  flow: "personalInjury",
  isError: false,
  isLoading: false,
};

export const perosnalInjuryReducer = (state = initialState, action) => {
  switch (action.type) {
    case perosnalInjuryType.POST_PERSONAL_INJURY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case perosnalInjuryType.POST_PERSONAL_INJURY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case perosnalInjuryType.POST_PERSONAL_INJURY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case perosnalInjuryType.CLEAR_PERSONAL_INJURY:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const updatePersonalInjuryReducer = (state = initialState, action) => {
  switch (action.type) {
    case perosnalInjuryType.PUT_PERSONAL_INJURY_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case perosnalInjuryType.PUT_PERSONAL_INJURY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case perosnalInjuryType.PUT_PERSONAL_INJURY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    case perosnalInjuryType.CLEAR_PERSONAL_INJURY:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const viewPersonalInjuryReducer = (state = initialState, action) => {
  switch (action.type) {
    case perosnalInjuryType.GET_PERSONAL_REPORT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case perosnalInjuryType.GET_PERSONAL_REPORT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case perosnalInjuryType.GET_PERSONAL_REPORT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
