import React, { useEffect, useState } from "react";
import * as credentialAction from "../../../redux/actions/credentialAction";
import { useDispatch, useSelector } from "react-redux";
import AddUserCredentials from "../component/addUserCredentials";
import { useLocation } from "react-router-dom";
import SuccessScreen from "../../../library/custom/success/successScreen";
import Loader from "../../../library/common/Loader";
import { MY_CREDENTIALS } from "../../../constant/routeContant";
import { API_STATUS } from "../../../constant/constant";

export default function AddCredentialsContainer() {
  const [successScreens, setIsSuccess] = useState(false);
  const getCredentialViewDetsilsData = useSelector(
    (state) => state.getCredentialViewDetsilsData
  );
  const location = useLocation();
  const editMode = location?.state?.editMode;
  const configureMode = location?.state?.configureMode;
  const credentialId = location?.state?.credentialId;

  const dispatch = useDispatch();

  const addUserCredential = (data) => {
    setIsSuccess(true);
    if (editMode || configureMode) {
      dispatch(credentialAction.updateUserCredential(data));
    } else {
      dispatch(credentialAction.createUserCredential(data));
    }
  };

  const showLoader = useSelector(
    (state) =>
      state.createUserCredentialData.isLoading ||
      state.updateUserCredentialData.isLoading ||
      state.getCredentialViewDetsilsData.isLoading
  );

  const updateUserCredentialData = useSelector(
    (state) => state.updateUserCredentialData
  );
  const updateUserCredentialDataStatus = useSelector(
    (state) => state.updateUserCredentialData.status
  );

  const createUserCredentialData = useSelector(
    (state) => state.createUserCredentialData
  );
  const createCredentialDataStatus = useSelector(
    (state) => state.createUserCredentialData.status
  );

  const credentialListData = useSelector(
    (state) => state.getSearchCredentialData
  );

  const isLoading = credentialListData.isLoading;

  const getCredentials = (data) => {
    dispatch(credentialAction.getSearchCredentialList(data));
  };

  const getCredentialLists = () => {
    dispatch(credentialAction.getUserCredentialList());
  };

  useEffect(() => {
    dispatch(credentialAction.clearCredential());
    getCredentialLists();
  }, []);

  const getCredentialViewDetsils = () => {
    dispatch(credentialAction.getCredentialViewDetsils(credentialId));
  };

  useEffect(() => {
    if (editMode || (configureMode && credentialId)) {
      getCredentialViewDetsils();
    }
  }, []);

  useEffect(() => {
    setIsSuccess(false);
  }, []);
  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          configureMode
            ? "Credential configured Successfully"
            : editMode
            ? "Credential Updated Successfully"
            : "Credential Added Successfully"
        }
        route={MY_CREDENTIALS}
        label1="Credential ID"
        label2=""
        label4="Credential in "
      ></SuccessScreen>
    );
  };
  return (
    <>
      {showLoader && <Loader />}
      {createCredentialDataStatus === API_STATUS.SUCCESS &&
      createUserCredentialData?.data &&
      successScreens ? (
        successScreen(createUserCredentialData?.data?.id)
      ) : updateUserCredentialDataStatus === API_STATUS.SUCCESS &&
        updateUserCredentialData?.data &&
        successScreens ? (
        successScreen(updateUserCredentialData?.data?.id)
      ) : (
        <AddUserCredentials
          addUserCredentials={addUserCredential}
          editMode={editMode}
          getCredentials={getCredentials}
          credentialListData={credentialListData ?? []}
          credentialNameIdss={credentialId}
          configureMode={configureMode}
          getCredentialViewDetsilsData={getCredentialViewDetsilsData}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
