import React, { useState, useEffect } from "react";
import InspectionsType from "../components/inspectionsType";
import { CONFIGURE_EDIT_INSPECTION } from "../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import Loader from "../../../../library/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { PAGE_SIZE, USER_ID } from "../../../../constant/constant";

const InspectionsTypeContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [clearInspectionsType, setClearInspectionsType] = useState(false);
  const [clearUpdateInspectionsType, setClearUpdateInspectionsType] =
    useState(false);

  const defaultData = {
    search: "",
    stateId: 0,
    userId: parseInt(USER_ID()),
    isActive:"",
    page: 1,
    pageSize: PAGE_SIZE,
  };

  const getInspectionTypeList = useSelector(
    (state) => state.getInspectionTypeList
  );

  const getInspectionTypeListData = (data) => {
    dispatch(swInspectionAction.getInspectionTypeList(data));
  };
  const createInspectionsType = useSelector(
    (state) => state.createInspectionType
  );
  const updateInspectionType = useSelector(
    (state) => state.updateInspectionType
  );

  const stateData = useSelector((state) => state.state);

  const isLoading = useSelector((state) => state.getInspectionTypeList?.isLoading);

  const showLoader = useSelector(
    (state) =>
      state.createInspectionType?.isLoading ||
      state.updateInspectionType?.isLoading ||
      state.state?.isLoading 
  );

  const updateInspectionTypeData = (data) => {
    setClearUpdateInspectionsType(true);
    dispatch(swInspectionAction.updateInspectionType(data));
  };

  const onSubmit = (data) => {
    setClearInspectionsType(true);
    dispatch(swInspectionAction.createInspectionType(data));
  };

  useEffect(() => {
    if (
      createInspectionsType?.status === "SUCCESS" &&
      createInspectionsType?.isLoading === false &&
      clearInspectionsType
    ) {
      toast("InspectionsType Created successfully");
    }
  }, [createInspectionsType]);

  useEffect(() => {
    if (
      updateInspectionType?.status === "SUCCESS" &&
      updateInspectionType?.isLoading === false &&
      clearUpdateInspectionsType
    ) {
      toast("InspectionType Updated successfully");
    }
  }, [updateInspectionType]);

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };

  useEffect(() => {
    getInspectionTypeListData(defaultData);
    getStateData();
  }, []);

  const handleEditOnClick = (row) => {
    navigate(CONFIGURE_EDIT_INSPECTION, {
      state: {
        row: row,
        editMode: true,
      },
    });
  };
  return (
    <>
      {showLoader && <Loader />}
      <InspectionsType
        handleEditOnClick={handleEditOnClick}
        onSubmit={onSubmit}
        updateInspectionTypeData={updateInspectionTypeData}
        createInspectionsType={createInspectionsType}
        updateInspectionType={updateInspectionType}
        state={stateData && stateData.data?.getState}
        count={getInspectionTypeList?.data?.value?.count}
        inspectionTypeList={isLoading ? [] : getInspectionTypeList?.data?.value?.listInspectionTypeDetails}
        getInspectionTypeListData={getInspectionTypeListData}
        isLoading={isLoading}
      />
      <ToastContainer />
    </>
  );
};

export default InspectionsTypeContainer;
