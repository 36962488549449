import React, { useEffect } from "react";
import Items from "../component/items";
import { useDispatch, useSelector } from "react-redux";
import { GET_ITEM_LOG, PAGE_SIZE, PAGE_SIZE_100 } from "../../../../constant/constant";
import * as incpectionAction from "../../../../redux/actions/incpectionAction";
import { useLocation } from "react-router-dom";
import Loader from "../../../../library/common/Loader";
const ItemsContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const propsData = location?.state;

  const defaultData = {
    userId: propsData?.userId ?? 0,
    search: "",
    page: 1,
    pageSize: PAGE_SIZE_100,
    projectId: propsData?.assetId ?? "",
    divisionId: 0,
    itemTypes: propsData?.itemLog ? propsData?.itemLog : GET_ITEM_LOG.All,
    statusIds: "",
    fromDate:
      propsData?.previous === "previous"
        ? propsData?.drillDetail?.apiData?.previousStartDate
        : propsData?.drillDetail?.apiData?.startDate,
    toDate:
      propsData?.previous === "previous"
        ? propsData?.drillDetail?.apiData?.previousEndDate
        : propsData?.drillDetail?.apiData?.endDate,
    status: "",
    assetType: propsData?.drillDetail?.apiData?.assetType,
    reportDetails: propsData?.drillDetail?.reportDetails,
    categoryId: propsData?.categoryId ?? "",
    subCategoryId: propsData?.subCategoryId ?? "",
    assetCatId: propsData?.drillDetail?.apiData?.assetCatId ?? "",
  };

  const itemListData = useSelector((state) => state.getItemList?.data);
  const getItemLogAllData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };

  const getOpenItemPhotos = useSelector((state) => state.openItemPhotos?.data);
  const getOpenItemPhotosData = (data) => {
    dispatch(incpectionAction.getOpenItemPhotos(data));
  };

  const isLoading = useSelector(
    (state) => state.getItemList?.isLoading || state.openItemPhotos?.isLoading
  );

  useEffect(() => {
    getItemLogAllData(defaultData);
  }, []);

  return (
    <>
      {isLoading && <Loader />}

      <Items
        userId={propsData?.userId ?? 0}
        propsData={propsData}
        getItemLogAllData={getItemLogAllData}
        items={itemListData?.result?.logsData}
        getOpenItemPhotosData={getOpenItemPhotosData}
        getOpenItemPhotos={getOpenItemPhotos}
      />
    </>
  );
};

export default ItemsContainer;
