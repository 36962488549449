import React, { useEffect } from "react";
import * as credentialAction from "../../../redux/actions/credentialAction";
import CredentialLookup from "../component/credentialLookup";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import { DASHBOARD } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";

export default function CredentialLookupContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAllowed = checkPrivileges([12, 13, 47, 48]);
  const credentialsByUsers = useSelector(
    (state) => state.getCredentialListByUserData?.data
  );
  const credentialsByCredentialsData = useSelector(
    (state) => state.getCredentialListByCredentialsData?.data
  );

  const allUsers = useSelector((state) => state.allUsers);

  const searchUsers = (searchType, searchText) => {
    dispatch(userAction.searchUsers(searchType, searchText));
  };
  function handleAllUserSearch(event, value) {
    dispatch(userAction.searchAllUsers(event.target.value));
  }

  const credentialListData = useSelector(
    (state) => state.getSearchCredentialData
  );
  const isLoading = allUsers.isLoading || credentialListData.isLoading;
  const searchOption = useSelector((state) => state.users.data);
  const getCredentials = (data) => {
    dispatch(credentialAction.getSearchCredentialList(data));
  };

  const getCredListByUser = (data) => {
    dispatch(credentialAction.getCredentialListByUsers(data));
  };

  const getCredListByCredential = (dataCred) => {
    dispatch(credentialAction.getCredentialListByCredentials(dataCred));
  };
  const getAllUsers = (searchText, type, divisionId) => {
    dispatch(userAction.searchAllUsers(searchText, type, divisionId));
  };
  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }
    searchUsers("", "");
  }, []);

  const isUserLoading = useSelector(
    (state) => state.getCredentialListByUserData?.isLoading
  );
  const isCredLoading = useSelector(
    (state) => state.getCredentialListByCredentialsData?.isLoading
  );

  return (
    <>
      <CredentialLookup
        credentialsByUsers={credentialsByUsers}
        credentialsByCredentialsData={credentialsByCredentialsData}
        handleAllUserSearch={handleAllUserSearch}
        allUsers={allUsers && allUsers?.data}
        searchOption={searchOption?.users || []}
        getCredentials={getCredentials}
        credentialListData={credentialListData ?? []}
        isUserLoading={isUserLoading}
        isCredLoading={isCredLoading}
        getCredListByUser={getCredListByUser}
        getCredListByCredential={getCredListByCredential}
        isLoading={isLoading}
        onSearch={getAllUsers}
      />
    </>
  );
}
