import * as lessonsUserAction from "../actions/lmsUserAction";
import { lmsUserType } from "../constants/lmsUserType";
import {
    getUserLessonsList,startLessons
} from "../../services/lessonsService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* getUserLessonsListSaga(params) {
  console.debug("getUserLessonsListSaga");
  try {
    const { data, status, error } = yield call(getUserLessonsList, params);
    if (!data && status !== 200) throw apiError(error);
    const lessonsUserPage = lmsUserType.LESSONS_USER__PAGE;
    yield put(
        lessonsUserAction.getUserLessonsListSuccess({
        lessonsUserPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Lesson List Failed" };
    yield put(lessonsUserAction.getUserLessonsListFailure(data));
  }
}
export function* startLessonSaga(param) {
  const payload = param.data;
  try {
    const { data, status, error } = yield call(startLessons, payload);
    if (!data && status !== 200) throw apiError(error);
    const startLesson = lmsUserType.START_LESSON_PAGE;
    yield put(
      lessonsUserAction.startLessonSuccess({
        startLesson,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Start Course Failed" };
    yield put(lessonsUserAction.startCourseFailure(data));
  }
}

const myUserLessonsSaga = [
  takeLatest(lmsUserType.GET_USER_LESSONS_LIST_PENDING, getUserLessonsListSaga),
  takeLatest(lmsUserType.POST_START_LESSON_PENDING, startLessonSaga),

];

export default myUserLessonsSaga;
