import { Grid } from "@mui/material";
import React, { useState } from "react";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import {
  ORG_ID,
  ORG_NAME,
  PAGE_SIZE,
  USERID,
  USER_ID,
} from "../../../../constant/constant";
import ReportRowManipulte from "../../../admin/shared/ReportRowManipulate";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import DatePickers from "../../../../library/custom/datePicker/DatePicker";
import Checkbox from "../../../../library/checkbox/checkbox";
import Buttons from "../../../../library/custom/button/Button";
import { useNavigate } from "react-router-dom";
import "./samplinglog.scss";
import ToolBar from "../../../../library/storm-water/toolBar";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { dateValiadtor, getFormatedDate } from "../../../../utils/helper";
import {
  SETUP_INSPECTION,
  SETUP_INSPECTION_LIST,
  SW_ADMIN_SAMPLINGLOG_HISTORY,
  SW_CONTACT_PROJECT,
  SW_OPEN_ITEMS,
  SW_PERMIT_LIST,
  ADMIN_PROJECTS_LIST,
  SW_RAIN_FALL_DATA,
  SW_INSPECTION_LIST,
  INSPECTION_AUTHORITY,
  ADMIN_ADD_STORM_WATERPROJECT,
  ADMIN_RESOURCE,
} from "../../../../constant/routeContant";
import commonImages from "../../../../assets/index";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import FormDropZone from "../../../../library/dropZone/FormDropZone";

function SamplingLogs({
  ProjectSampleResult,
  addAndUpdateSample,
  getProjectSampleListData,
  isprojectsampleLoading,
  projectId,
  projectNumber,
  projectName,
  stateId,
  customer,
  customerId,
}) {
  const history = useNavigate();
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    sampleDate: null,
    isAddedInGeos: false,
    report: "",
    createdBy: parseInt(USER_ID()),
  });
  const [checkBox, setcheckBox] = useState(false);
  const [dateErrMsg, setDateErrMsg] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [dropboxError, setDropboxError] = useState(false);
  const [photos, setPhotos] = useState("");
  const [copyreport, setCopyreport] = useState("");
  const [iscancel, setIscancel] = useState(false);

  const handleDeleteImageURL = (urls) => {
    const previousImg = photos.split("|");
    setPhotos(previousImg.filter((url) => url !== urls).join("|"));
  };

  const addsampleReport = () => {
    let isFieldEmpty = false;
    if (!formValue?.sampleDate) {
      setStartDateError(true);
      isFieldEmpty = true;
    }
    if (!photos) {
      isFieldEmpty = true;
      setDropboxError(true);
    }
    if (formValue?.sampleDate) {
      const validDate = dateValiadtor(getFormatedDate(formValue?.sampleDate));
      if (validDate === false) {
        setDateErrMsg(true);
        isFieldEmpty = true;
      } else if (validDate === "Valid date") {
        setDateErrMsg(false);
      }
    }
    if (!isFieldEmpty) {
      const formdata = {
        sampleDate: getFormatedDate(formValue?.sampleDate),
        isAddedInGeos: checkBox,
        report: photos,
        createdBy: parseInt(USER_ID()),
        projectId: projectId,
      };

      addAndUpdateSample(formdata);

      setFormValue("");
      setPhotos("");
      setFormValue({
        ...formValue,
        sampleDate: null,
      });
      setPhotos("");
      setIscancel(true);
      setcheckBox(false);
      setStartDateError(false);
      setDropboxError(false);
    }
  };
  const handleOnViewSampleclick = (rows) => {
    navigate(SW_ADMIN_SAMPLINGLOG_HISTORY, {
      state: {
        projectId: projectId,
        projectName: projectName,
        projectNumber: projectNumber,
        stateId: stateId,
      },
    });
  };

  const [showCopyMsg, setshowCopyMsg] = useState();
  const copyReportUrl = (id, val) => {
    let sampleid = id?.sampleId;
    setshowCopyMsg(sampleid);
    setCopyreport(val);
    navigator.clipboard.writeText(val);
    setTimeout(function () {
      setshowCopyMsg();
    }, 1000);
  };
  const columns = [
    { field: "sampleDate", headerName: "Sample Date", flex: 1.5 },
    { field: "createdByName", headerName: "Added By", flex: 1.2 },
    {
      field: "report",
      headerName: "Report",
      flex: 3.3,
      renderCell: (cellValues) => {
        let cellval = cellValues;

        return (
          <>
            <div className="nameBox12">
              <div className="nameImg12">
                <p>
                  {cellval?.row?.report ? (
                    <div className="checkreport">
                      <div>
                        {showCopyMsg === cellval?.row?.sampleId && (
                          <p className="custom-tooltip">copied!</p>
                        )}
                      </div>
                      <div className="checkreport1">
                        {cellval?.row?.report}{" "}
                      </div>
                      <div className="checkreport1sample">
                        <img
                          src={commonImages?.contentcopy}
                          onClick={(e) =>
                            copyReportUrl(cellval?.row, cellval?.row?.report)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: "isAddedInGeos",
      headerName: "GEOS",
      flex: 1,
      renderCell: (cellValues) => {
        let cellval = cellValues;

        return (
          <>
            <div className="nameBox1">
              <div className="nameImg1">
                <p>
                  {cellval?.row?.isAddedInGeos ? (
                    <div className="checksample">
                      <img src={commonImages?.checksampleResport} />
                    </div>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            </div>
          </>
        );
      },
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={"ProjectSampleResult?.itemsCount ?? 0"}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={"ProjectSampleResult?.sampleLog ?? []"}
          fileNameXL={"ProjectSampleLogList"}
        />
      ),
      flex: 0.9,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={false}
            viewOnClick={() => handleOnViewSampleclick(rows)}
            view={true}
            viewLabel="View Log History"
            download={false}
          />
        );
      },
    },
  ];

  const customerName = customer ?? ORG_NAME();
  const toolBarData = {
    title: "Project Tools",
    list: [
      {
        id: 10,
        title: "Edit Project",
        link: "",
        isshow: checkPrivileges([12, 111, 112, 113]),
      },
      {
        id: 1,
        title: "Project Contact",
        link: "",
        isshow: checkPrivileges([12, 132, 133, 134]),
      },
      {
        id: 2,
        title: "Inspection Service List",
        link: "",
        isshow: checkPrivileges([12, 99, 100, 101]),
      },
      {
        id: 9,
        title: "Setup Inspections",
        link: "",
        isshow: checkPrivileges([12, 99, 100]),
      },
      {
        id: 3,
        title: "Permits",
        link: "",
        isshow: checkPrivileges([12, 126, 127, 128]),
      },
      {
        id: 11,
        title: "Sampling Log",
        link: "",
        activeClass: "active",
        isshow: checkPrivileges([12, 129, 130, 131]),
      },

      {
        id: 5,
        title: "Open Items",
        link: "",
        isshow: checkPrivileges([12, 105, 106, 107]),
      },
      {
        id: 6,
        title: "Rainfall Data",
        link: "",
        isshow: true,
      },
      {
        id: 7,
        title: "Inspection Records",
        link: "",
        isshow: checkPrivileges([12, 102, 103, 104]),
      },

      {
        id: 8,
        title: "Inspection Authority",
        link: "",
        isshow: checkPrivileges([12, 96, 97, 98]),
      },
      {
        id: 12,
        title: "Project Resources",
        link: "",
        isshow: checkPrivileges([12, 73, 74, 75]),
      },
    ],
  };

  const [page, setPage] = useState(1);

  const onchangeSampleDate = (e) => {
    setFormValue({
      ...formValue,
      sampleDate: e,
    });
    setDateErrMsg(false);
  };
  const handleFileChange = (photos) => {
    setPhotos(photos);
  };
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);

    getProjectSampleListData({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      projectId: projectId,
    });
  };

  const resetform = () => {
    handleDeleteImageURL(photos);

    setFormValue({
      ...formValue,
      sampleDate: null,
    });
    setPhotos("");
    setIscancel(true);
    setcheckBox(false);
    setTimeout(() => {
      setIscancel(false);
      setcheckBox(false);
    }, 1);
  };

  const dataBack = {
    title: "Back to Edit Projects",
    route: ADMIN_ADD_STORM_WATERPROJECT,
    projectName: projectName,
    projectNumber: projectNumber,
    projectid: projectId,
    customerName: customerName,
    editMode: true,
  };
  return (
    <>
      <section className="grid_main_sec sw">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Sampling Log</div>
        </div>
        <Grid container>
          <Grid lg={8} md={12} xs={12} pr={2} item>
            {checkPrivileges([12, 129, 130]) && (
              <Grid
                item
                container
                lg={12}
                md={8}
                className="CategoriesBox"
                mt={5}
              >
                <Grid item lg={6} md={8} mb={2} xs={12}>
                  <FormLabels label={"Sample Date"} isRequired={true} />
                  <DatePickers
                    maxDateEnable={false}
                    disabled={false}
                    value={formValue?.sampleDate}
                    onChangeHandler={(e) => onchangeSampleDate(e)}
                  />
                  {!formValue?.sampleDate && startDateError && (
                    <div className="errorMsg">Please select required field</div>
                  )}
                  {dateErrMsg && formValue?.sampleDate != null && (
                    <div className="errorMsg">Invalid Date</div>
                  )}
                </Grid>

                <Grid
                  item
                  lg={5}
                  xs={12}
                  sm={12}
                  md={6}
                  ml={2}
                  mt={5}
                  className="samplecheckbox"
                >
                  <Checkbox
                    label={"Added to GEOS"}
                    onChange={() => setcheckBox(!checkBox)}
                    checked={checkBox}
                  />
                </Grid>
              </Grid>
            )}

            {checkPrivileges([12, 129, 130]) && (
              <Grid mt={5} spacing={2} lg={12} xs={12}>
                <FormLabels label={"Add Report"} isRequired={true} />
                <>
                  <FormDropZone
                    paraText1={"Drop the files here ..."}
                    paraText2={"Drop Images here, "}
                    paraText3={"or Browse"}
                    handleFileChange={handleFileChange}
                    deleteImageURL={(url) => handleDeleteImageURL(url)}
                    fileType={10}
                    isMuliple={false}
                    imageUrl={photos}
                    id="addPhoto"
                    key="addPhoto"
                    isDoc={true}
                    isImage={false}
                    allFiles={true}
                  />
                  <p>
                    Supported file types: doc, docx, ppt, pptx, xls, xlsx,
                    images, or pdf
                  </p>
                  {!photos && dropboxError && (
                    <div className="errorMsg">Please select required field</div>
                  )}
                </>
              </Grid>
            )}

            <Grid lg={12} xs={12} md={12} mt={2}>
              {checkPrivileges([12, 129, 130]) && (
                <>
                  <Grid
                    container
                    item
                    lg={12}
                    xs={12}
                    textAlign="right"
                    alignItems="center"
                    className="sampleLogBox"
                  >
                    <Grid lg={3} xs={12} sm={6}>
                      <Buttons
                        varientText={true}
                        label="Cancel"
                        onClick={() => resetform()}
                      />
                    </Grid>

                    <Grid lg={4} xs={12} sm={6} md={4}>
                      <Buttons
                        varientContained={true}
                        label={"Add Sample"}
                        onClick={addsampleReport}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container lg={12} xs={12}>
              <Grid item lg={12} mt={2} xs={12}>
                <section className="grid_main_sec">
                  <div className="grid_main_body">
                    <div className="desc_box row_uniq">
                      <div className="table_style_ui">
                        <GridTable
                          getRowId={(r) => r.sampleId}
                          rows={ProjectSampleResult?.sampleLog ?? []}
                          rowCount={ProjectSampleResult?.itemsCount ?? 0}
                          columns={columns}
                          loading={isprojectsampleLoading}
                          page={page}
                          pagination
                          pageSize={PAGE_SIZE}
                          rowsPerPageOptions={[PAGE_SIZE]}
                          onPageChange={handlePagignation}
                          backIconButtonProps={{ disabled: false }}
                          disableSelectionOnClick
                          components={{
                            LoadingOverlay: TableLoader,
                            NoRowsOverlay: NORow,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </Grid>
            </Grid>
          </Grid>

          <Grid lg={4} md={12} xs={12} pl={1} item>
            <div className="toolBarBox">
              <div className="otherDetail">
                <h3>Projects Details</h3>
                <h2>{projectName}</h2>
                <h2>{projectNumber}</h2>
              </div>

              <div className="otherDetail">
                <h3>Client Name</h3>
                <h2>{customerName}</h2>
              </div>

              <ToolBar
                toolBarData={toolBarData}
                handleClick={(e) => {
                  if (e.id === 1) {
                    navigate(SW_CONTACT_PROJECT, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 2) {
                    navigate(SETUP_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 9) {
                    navigate(SETUP_INSPECTION, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                        rightSideMode: true,
                      },
                    });
                  }
                  if (e.id === 3) {
                    navigate(SW_PERMIT_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectid: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 5) {
                    navigate(SW_OPEN_ITEMS, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 6) {
                    navigate(SW_RAIN_FALL_DATA, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 7) {
                    navigate(SW_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 8) {
                    navigate(INSPECTION_AUTHORITY);
                  }
                  if (e.id === 10) {
                    navigate(ADMIN_ADD_STORM_WATERPROJECT, {
                      state: {
                        editMode: true,
                        projectid: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                      },
                    });
                  }
                  if (e.id === 12) {
                    navigate(ADMIN_RESOURCE, {
                      state: {
                        projectName: projectName,
                        projectNumber: projectNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: customerId
                          ? customerId
                          : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  } else return;
                }}
              />
            </div>
          </Grid>
        </Grid>
      </section>
      <div className="insBackBtn1">
        <BackBtn dataBack={dataBack} />
      </div>
    </>
  );
}

export default SamplingLogs;
