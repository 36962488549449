import React, { useState } from "react";
import JHACategoryConfiguration from "../component/jha/jhaCategoryConfiguration";
import { useDispatch } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { API_STATUS, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../library/common/Loader";

const JhaCategoryConfigurationContainer = () => {
  const dispatch = useDispatch();
  const [shwoToast, setshwoToast] = useState(false);

  // add Jha Category
  const addJhaCategoryData = useSelector((state) => state?.addJhaCategory);
  function addJhaCategory(data) {
    setshwoToast(true);
    dispatch(jhaAction.addJhaCategory(data));
  }
  useEffect(() => {
    if (
      addJhaCategoryData.status == API_STATUS.SUCCESS &&
      addJhaCategoryData?.isLoading == false &&
      shwoToast
      // clearalert
    ) {
      toast("Category Added successfully");
      setshwoToast(false);
      getCategoriesListData(defaultData);
    }
  }, [addJhaCategoryData]);

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
  };

  // get JHa Cat List
  const categoriesListData = useSelector((state) => state.jhaCategoryList);
  const getCategoriesListData = (data) => {
    dispatch(jhaAction.getJhaCategoryList(data));
  };
  const isLoading = useSelector(
    (state) =>
      state.jhaCategoryList?.isLoading ||
      state.addJhaCategoryData?.isLoading ||
      state.updateJhaCategory?.isLoading
  );
  const addCategoryLoading = useSelector(
    (state) =>
      state.addJhaCategoryData?.isLoading || state.updateJhaCategory?.isLoading
  );

  useEffect(() => {
    dispatch(jhaAction.clearJha());
    getCategoriesListData(defaultData);
  }, []);

  // edit
  const updateJhaCategoryData = useSelector(
    (state) => state?.updateJhaCategory
  );

  function handleEditOnClick(data) { 
    setshwoToast(true);
    dispatch(jhaAction.updateJhaCategory(data));
  }
  function handleStatusChange(data) {
    let newData = {
      categoryName: data.categoryName,
      isGlobal: false,
      categoryId: data.id,
      isActive: data.isActive,
      listsmodifiedBy: parseInt(USER_ID()),
    };
    setshwoToast(true);
    dispatch(jhaAction.updateJhaCategory(newData));
  }
  useEffect(() => {
    if (
      updateJhaCategoryData.status === API_STATUS.SUCCESS &&
      updateJhaCategoryData?.isLoading === false && 
      shwoToast
      // clearalert
    ) {
      toast("Category Updated successfully"); 
      setshwoToast(false);
   
      getCategoriesListData(defaultData);
    }
  }, [updateJhaCategoryData]);

  const showLoader = useSelector(
    (state) =>
      state.addJhaCategoryData?.isLoading || state.updateJhaCategory?.isLoading
  );

  return (
    <div>
      {showLoader && <Loader />}

      <ToastContainer />
      <JHACategoryConfiguration
        addJhaCategory={addJhaCategory}
        categoriesList={categoriesListData?.data?.result?.itemList ?? []}
        itemsCount={categoriesListData?.data?.result?.itemsCount ?? []}
        isLoading={isLoading}
        getCategoriesListData={getCategoriesListData}
        addCategoryLoading={addCategoryLoading}
        handleEditOnClick={handleEditOnClick}
        handleStatusChange={handleStatusChange}
      />
    </div>
  );
};

export default JhaCategoryConfigurationContainer;
