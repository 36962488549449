import * as projectAction from "../../storm-water/actions/projectAction";
import { projectType } from "../../storm-water/types/projectType";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../../../src/utils/helper";
import {
  createProjectSampleLog,
  getprojectSample,
} from "../../../services/storm-water/projectService";

export function* createProjectSampleSaga(params) {
  console.debug("createRoject");
  try {
    const { data, status, error } = yield call(createProjectSampleLog, params);
    if (!data && status !== 200) throw apiError(error);
    const projectPage = projectType.PROJECT_PAGE;
    yield put(
      projectAction.createProjectSampleSuccess({
        projectPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Project Sample Creation Failed" };
    yield put(projectAction.createProjectSampleFailure(data));
  }
}

export function* getprojectSampleSaga(params) {
  try {
    console.log("hello params", params);
    const { data, status, error } = yield call(getprojectSample, params);
    if (!data && status !== 200) throw apiError(error);
    const swprojectPage = projectType.PROJECT_PAGE;
    yield put(
      projectAction.swProjectSampleListSuccess({
        swprojectPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Project Sample List Failed" };
    yield put(projectAction.swProjectSampleListFailure(data));
  }
}

const myProjectSampleSaga = [
  takeLatest(projectType.POST_PROJECT_SAMPLE_PENDING, createProjectSampleSaga),
  takeLatest(
    projectType.GET_SW_PROJECT_SAMPLE_LIST_PENDING,
    getprojectSampleSaga
  ),
];

export default myProjectSampleSaga;
