import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import commonImages from "../../../../assets";
import Typography from "@mui/material/Typography";
import "./toolBoxTalkDetail.scss";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import {
  TOOL_BOX_LIST,
  TOOL_BOX_ASSIGN,
  TOOL_BOX_CREATE,
  TOOL_BOX_ASSIGN_USER,
  TOOL_BOX_USER,
} from "../../../../constant/routeContant";
import Popover from "@mui/material/Popover";

import Buttons from "../../../../library/custom/button/Button";
import { useNavigate } from "react-router-dom";
import DialogBox from "../../../../library/common/DialogBox";
import { getFormatedDate } from "../../../../utils/helper";
import { USER_ID } from "../../../../constant/constant";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const ToolBoxTalkDetail = (props) => {
  const ToolBoxDetail = props?.getToolBoxTalkDetail;
  const scheduleId = props?.scheduleId;
  const toolboxtalkuyserlistPage = props?.toolboxtalkuyserlistPage;
  const Tabvalue = props?.Tabvalue;
  const dataBack = {
    title: "Back to Safety Meeting",
    route: toolboxtalkuyserlistPage ? TOOL_BOX_USER : TOOL_BOX_LIST,
    backTabId: Tabvalue,
  };
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isClient = userInfo?.isClient;
  const isContractor = userInfo?.isContractor;
  const contractorProfileShow = isContractor === true && isClient === false;
  const userMarkedToolBoxTalks = props?.userMarkedToolBoxTalks;
  const toolboxId = props?.toolboxId;
  const instructorUserContractors = props?.instructorUserContractors;
  const assignInstructorsUsersId = props?.assignInstructorsUsersId;
  const navigate = useNavigate();
  const isAssignfound = props?.isfound;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const assignedOn = props?.assignedOn;
  const assignedOnAdmin = props?.assignedOnAdmin;
  const deleteItem = (rows) => {
    setOpenModal(true);
  };

  const deletehandle = () => {
    setOpenModal(false);
    const data = {
      toolboxTalkId: toolboxId,
    };
    props?.deleteToolBoxTalks(data);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const completeTraining = () => {
    const data = {
      userId: parseInt(USER_ID()),
      scheduleDate: assignedOn,
      scheduleId: scheduleId,
    };
    userMarkedToolBoxTalks(data);
  };

  const handleClickAssign = (scheduleId) => {
    navigate(TOOL_BOX_ASSIGN_USER, {
      state: {
        scheduleId: scheduleId,
        assignedOnAdmin: assignedOnAdmin,
        isInstructor: true,
      },
    });
  };
  // const handleClickSelfAssign = (scheduleId) => {
  //   navigate(TOOL_BOX_ASSIGN, {
  //     state: {
  //       scheduleId: scheduleId,
  //       detailPageNavigate: true,
  //       toolboxId: toolboxId,
  //     },
  //   });
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const filepath = ToolBoxDetail?.file?.path;
  const edittoolbox = (toolboxids) => {
    navigate(TOOL_BOX_CREATE, {
      state: { toolboxId: toolboxids, editMode: true },
    });
  };
  return (
    <>
      <BackBtn dataBack={dataBack} />
      <Grid container className="toolboxtalkView">
        <Grid item xs={12}>
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to delete this ToolBox Talk?"}
              view
            />
          )}
          <Grid className="lessonImage">
            <div className="adminDetailBox">
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className="deletePop"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography className="deleteText">Delete Meeting</Typography>
              </Popover>
            </div>
          </Grid>
          <Grid container>
            <Grid md={9}>
              <Grid className="imageDetail">
                <Typography className="imageDetailContent">
                  <span>
                    <img src={commonImages?.category} alt="category" />
                  </span>
                  {ToolBoxDetail?.categoryName}
                </Typography>
                {instructorUserContractors?.id ? (
                  <Typography className="imageDetailContent">
                    <span>
                      <img src={commonImages?.alarm} alt="alarm" />
                    </span>
                    {getFormatedDate(
                      instructorUserContractors?.scheduleTalkStartDate
                    )}
                  </Typography>
                ) : (
                  ""
                )}
                {instructorUserContractors?.id &&
                instructorUserContractors?.scheduleTalkStartDate !=
                  instructorUserContractors?.scheduleTalkEndDate ? (
                  <Typography className="imageDetailContent">
                    <span>
                      <img src={commonImages?.alarm} alt="alarm" />
                    </span>
                    {getFormatedDate(
                      instructorUserContractors?.scheduleTalkEndDate
                    )}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            {checkPrivileges([12, 90]) && (
              <Grid md={3}>
                <Grid className="imageDetail">
                  <Typography className="imageDetailContent">
                    <div
                      className="edittoolbox"
                      onClick={() => edittoolbox(toolboxId)}
                    >
                      <span>
                        <img src={commonImages?.editicon} alt="alarm" />
                      </span>
                      Edit Content
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {/* {checkPrivileges([12, 91]) && assignInstructorsUsersId && (
              <Grid md={3}>
                <Grid className="imageDetail">
                  <Buttons
                    type="button"
                    name="btn"
                    label={"Assign Users"}
                    varientContained={true}
                    onClick={() => handleClickAssign(scheduleId)}
                  ></Buttons>
                </Grid>
              </Grid>
            )} */}
          </Grid>

          <Grid>
            <Grid className="title">{ToolBoxDetail?.titleSafetyMeeting}</Grid>
            <Grid className="otherDescration">
              <Grid className="subTitle">Description</Grid>
              <Grid className="desc">
                {" "}
                <p>{ToolBoxDetail?.descriptionSafetyMeeting}</p>
              </Grid>

              <Grid className="subTitle"></Grid>
              <Grid className="desc">
                {" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: ToolBoxDetail?.textSafetyMeeting,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {ToolBoxDetail?.file?.id && (
          <Grid container className="lessonwithDetail">
            <Grid item xs={12} md={12} className="lessonwithDetailBox">
              <Grid className="otherDescration">
                <Grid className="subTitle"></Grid>
                <Grid className="pdfAttached">
                  <span>
                    {" "}
                    <a href={filepath} target="_blank" rel="noreferrer">
                      <img src={commonImages?.attachment} alt="" />
                      Download PDF/Image
                    </a>{" "}
                  </span>
                </Grid>
              </Grid>

              <hr className="solid" />
            </Grid>
          </Grid>
        )}
        {checkPrivileges([12, 90, 91, 92]) || contractorProfileShow ? (
          <>
            <Grid className="Btncomplete">
              <Buttons
                varientContained={true}
                onClick={completeTraining}
                TODO
                USER
                COMPALTE
                TRANNING
                label="Complete Training"
                disabled={ToolBoxDetail?.isUserMarkedCompleted ? true : false}
              />
            </Grid>
            {ToolBoxDetail?.isUserMarkedCompleted ? (
              <span className="trngComp">Training successfully completed</span>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default ToolBoxTalkDetail;
