import React, { useEffect, useState } from "react";
import AttendeeesToolbox from "../component/toolBoxTalkAttendees/toolBoxTalkAttendees";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import SuccessScreen from "../../../library/custom/success/successScreen";

import { PAGE_SIZE } from "../../../constant/constant";
import { TOOL_BOX_LIST } from "../../../constant/routeContant";
const ToolboxtalkAttendeesContainer = () => {
  const [successScreens, setSuccessScreens] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();
  const tabId = location?.state?.tabId;
  const assignedOn = location?.state?.assignedOn;
  const scheduleId = location?.state?.scheduleId;
  const getVerifyAttendeeListData = useSelector(
    (state) => state.getVerifyAttendeeList?.data
  );

  const getScheduleMeetingDetailData = useSelector(
    (state) => state.getScheduleMeetingDetail
  );
  const isLoading = getScheduleMeetingDetailData?.isLoading;

  const isTableLoading = useSelector(
    (state) => state.getVerifyAttendeeList?.isLoading
  );

  const defaultData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    search: "",
    catIds: "",
    userId: 0,
    status: "",
    scheduleId: scheduleId,
    scheduleDate: assignedOn,
    userType: 0,
  };
  useEffect(() => {
    const data = {
      scheduleId: scheduleId,
      scheduleDate: assignedOn,
      userId: "",
      assignType: 1,
    };
    dispatch(toolboxAction.getScheduleSafetyMeetingDetails(data));
  }, []);

  const getverrifyListData = (data) => {
    dispatch(toolboxAction.getVerifyAttendeeUserList(data));
  };

  const getProjectSearchList = useSelector(
    (state) => state.getProjectSearchList?.data?.projects
  );
  const getProjectSearch = (data) => {
    dispatch(toolboxAction.getProjectSearchList(data));
  };

  useEffect(() => {
    getProjectSearch(defaultData);
  }, []);

  const [clearExtraUser, setClearExtraUser] = useState(false);

  const addExtraUser = useSelector((state) => state.createExtraUser);
  const showAddLoader = useSelector(
    (state) => state.createExtraUser?.isLoading
  );
  const verifyAttendee = useSelector((state) => state.verifyAttendee);
  const showVerifyAttendeeLoader = useSelector(
    (state) => state.verifyAttendee?.isLoading
  );

  const onExtraUserSubmit = (data) => {
    setClearExtraUser(true);
    dispatch(toolboxAction.createExtraUser(data));
  };

  const onVeirfyAttendanceSubmit = (data) => {
    setSuccessScreens(true);
    dispatch(toolboxAction.verifyAttendeeUser(data));
  };

  useEffect(() => {
    if (
      addExtraUser?.status === "SUCCESS" &&
      addExtraUser?.isLoading === false &&
      clearExtraUser
    ) {
      toast("Add Extra User successfully");
    }
  }, [addExtraUser]);

  const successScreen = (id, message) => {
    return (
      <SuccessScreen
        isUpdate={message}
        route={TOOL_BOX_LIST}
        label1=" "
        label2=" "
      ></SuccessScreen>
    );
  };

  return (
    <>
      {
        // isLoading ||
        (showAddLoader || showVerifyAttendeeLoader) && <Loader />
      }
      {verifyAttendee.status === "SUCCESS" &&
      verifyAttendee.data &&
      successScreens &&
      verifyAttendee?.data?.responseMessages?.responseCode == "HBNG001" ? (
        successScreen(
          verifyAttendee?.data?.id,
          "Attendance Submitted Successfully"
        )
      ) : (
        <AttendeeesToolbox
          verifyAttendeeListData={getVerifyAttendeeListData?.attendances}
          verifyAttendeeFiles={getVerifyAttendeeListData?.files}
          getScheduleMeetingDetailData={getScheduleMeetingDetailData}
          projectData={getProjectSearchList}
          getverrifyListData={getverrifyListData}
          assignedOn={assignedOn}
          defaultData={defaultData}
          onExtraUserSubmit={onExtraUserSubmit}
          addExtraUser={addExtraUser}
          onVeirfyAttendanceSubmit={onVeirfyAttendanceSubmit}
          isTableLoading={isTableLoading}
          tabId={tabId}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default ToolboxtalkAttendeesContainer;
