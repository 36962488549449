import { categoryType } from "../constants/utilityCategoryType";

export const utilityCategory = (data) => ({
  type: categoryType.POST_CATEGORY_PENDING,
  data,
});

export const utilityCategorySuccess = (data) => ({
  type: categoryType.POST_CATEGORY_SUCCESS,
  data,
});

export const utilityCategoryFailure = (error) => ({
  type: categoryType.POST_CATEGORY_FAILURE,
  error,
});

export const getUtilityCategoryValue = (data) => ({
  type: categoryType.GET_CATEGORY_PENDING,
  data,
});

export const getUtilityCategoryValueSuccess = (data) => ({
  type: categoryType.GET_CATEGORY_SUCCESS,
  data,
});

export const getUtilityCategoryValueFailure = (error) => ({
  type: categoryType.GET_CATEGORY_FAILURE,
  error,
});

export const utilityActiveCategory = (data) => ({
  type: categoryType.POST_ACTIVE_CATEGORY_PENDING,
  data,
});

export const utilityActiveCategorySuccess = (data) => ({
  type: categoryType.POST_ACTIVE_CATEGORY_SUCCESS,
  data,
});

export const utilityActiveCategoryFailure = (error) => ({
  type: categoryType.POST_ACTIVE_CATEGORY_FAILURE,
  error,
});

// support list add active inctive=========================
export const getUtilitySupportCategoryValue = (data) => ({
  type: categoryType.GET_SUPPORT_CATEGORY_PENDING,
  data,
});

export const getUtilitySupportCategoryValueSuccess = (data) => ({
  type: categoryType.GET_SUPPORT_CATEGORY_SUCCESS,
  data,
});

export const getUtilitySupportCategoryValueFailure = (error) => ({
  type: categoryType.GET_SUPPORT_CATEGORY_FAILURE,
  error,
});

export const utilitySupportCategory = (data) => ({
  type: categoryType.POST_SUPPORT_CATEGORY_PENDING,
  data,
});

export const utilitySupportCategorySuccess = (data) => ({
  type: categoryType.POST_SUPPORT_CATEGORY_SUCCESS,
  data,
});

export const utilitySupportCategoryFailure = (error) => ({
  type: categoryType.POST_SUPPORT_CATEGORY_FAILURE,
  error,
});

export const utilitySupportActiveCategory = (data) => ({
  type: categoryType.POST_ACTIVE_SUPPORT_CATEGORY_PENDING,
  data,
});

export const utilitySupportActiveCategorySuccess = (data) => ({
  type: categoryType.POST_ACTIVE_SUPPORT_CATEGORY_SUCCESS,
  data,
});

export const utilitySupportActiveCategoryFailure = (error) => ({
  type: categoryType.POST_ACTIVE_SUPPORT_CATEGORY_FAILURE,
  error,
});

//incident

export const utilityIncidentCategory = (data) => ({
  type: categoryType.POST_INCIDENT_CATEGORY_PENDING,
  data,
});

export const utilityIncidentCategorySuccess = (data) => ({
  type: categoryType.POST_INCIDENT_CATEGORY_SUCCESS,
  data,
});

export const utilityIncidentCategoryFailure = (error) => ({
  type: categoryType.POST_INCIDENT_CATEGORY_FAILURE,
  error,
});

export const getUtilityIncidentCategoryValue = (data) => ({
  type: categoryType.GET_INCIDENT_CATEGORY_PENDING,
  data,
});

export const getUtilityIncidentValueSuccess = (data) => ({
  type: categoryType.GET_INCIDENT_CATEGORY_SUCCESS,
  data,
});

export const getUtilityIncidentValueFailure = (error) => ({
  type: categoryType.GET_INCIDENT_CATEGORY_FAILURE,
  error,
});

export const utilityIncidentActiveCategory = (data) => ({
  type: categoryType.POST_ACTIVE_INCIDENT_CATEGORY_PENDING,
  data,
});

export const utilityIncidentActiveCategorySuccess = (data) => ({
  type: categoryType.POST_ACTIVE_INCIDENT_CATEGORY_SUCCESS,
  data,
});

export const utilityIncidentActiveCategoryFailure = (error) => ({
  type: categoryType.POST_ACTIVE_INCIDENT_CATEGORY_FAILURE,
  error,
});
// update category configuration

export const updateCategoryConfiguration = (payload) => ({
  type: categoryType.UPDATE_CATEGORY_CONFIGURATION,
  payload,
});

export const updateCategoryConfigurationSuccess = (data) => ({
  type: categoryType.UPDATE_CATEGORY_CONFIGURATION_SUCCESS,
  data,
});

export const updateCategoryConfigurationFailure = (error) => ({
  type: categoryType.UPDATE_CATEGORY_CONFIGURATION_FAILURE,
  error,
});

export const updateSupportCategoryConfiguration = (payload) => ({
  type: categoryType.UPDATE_SUPPORT_CATEGORY_CONFIGURATION,
  payload,
});

export const updateSupportCategoryConfigurationSuccess = (data) => ({
  type: categoryType.UPDATE_SUPPORT_CATEGORY_CONFIGURATION_SUCCESS,
  data,
});

export const updateSupportCategoryConfigurationFailure = (error) => ({
  type: categoryType.UPDATE_SUPPORT_CATEGORY_CONFIGURATION_FAILURE,
  error,
});

export const updateIncidentCategoryConfiguration = (payload) => ({
  type: categoryType.UPDATE_INCIDENT_CATEGORY_CONFIGURATION,
  payload,
});

export const updateIncidentCategoryConfigurationSuccess = (data) => ({
  type: categoryType.UPDATE_INCIDENT_CATEGORY_CONFIGURATION_SUCCESS,
  data,
});

export const updateIncidentCategoryConfigurationFailure = (error) => ({
  type: categoryType.UPDATE_INCIDENT_CATEGORY_CONFIGURATION_FAILURE,
  error,
});
