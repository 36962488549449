import {
  Edit,
  FileDownload,
  MoreVert,
  RemoveRedEye,
  Grading,
  Add,
  Email,
} from "@mui/icons-material";
import { IconButton, Menu } from "@mui/material";
import React from "react";
import DownloadCsv from "../../download_file/DownloadCsv";
import commonImages, { inspection } from "../../../assets";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

export default function ReportRowManipulte(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dowloadCsv = React.useRef();
  const {
    rows: { row },
    viewOnClick,
    editOnClick,
    addForm,
    deleteOnClick,
    edit,
    download,
    view,
    deleted,
    addBtn,
    addBtnLabel = "Add",
    editLabel = "Edit Incident",
    deleteLabel = "Delete Incident",
    downloadLable = "Download Report",
    viewLabel = "View Incident",
    manageLabel = "Manage Inspection Authority",
    openLink,
    openLinkLabel = "Link",
    openLinkUrl,
    configure = false,
    configureLabel,
    configureOnClick,
    safetyLog = false,
    safetyLogOnClick,
    safetyLogLabel = "Safety Log",
    clone = false,
    cloneOnClick,
    manage = false,
    manageOnClick,
    sampleLog = false,
    sampleLogOnClick,
    sampleLogLabel = "Add Sample",
    missed = false,
    missedOnClick,
    missedLabel,
    conductExternal = false,
    conductExternalOnClick,
    conductExternalLabel,
    addProof = false,
    addProofOnClick,
    addProofLabel,
    resendEmailOnClick,
    resendEmailLable,
    resendEmail = false,
    sendEmailOnClick,
    sendEmailLable,
    sendEmail = false,
    defer = false,
    deferOnClick,
    deferLabel,
    unAssignLesson = false,
    unAssignLessonOnclick,
    unAssignLessonLabel,
  } = props;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (row?.status === 0 || row?.status === 3) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="setting-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="setting_button_ui">
          <div className="dropdownList_ui">
            <ul>
              {view && (
                <li onClick={() => viewOnClick(row)}>
                  <>
                    <RemoveRedEye /> <span>{viewLabel}</span>
                  </>
                </li>
              )}

              {openLink && (
                <li>
                  <div
                    onClick={() => {
                      window.open(openLinkUrl, "_blank");
                    }}
                    className="downloadresourse"
                  >
                    <FileDownload /> <span>{openLinkLabel}</span>
                  </div>
                </li>
              )}

              {edit && (
                <li onClick={() => editOnClick(row)}>
                  <Edit /> <span>{editLabel}</span>
                </li>
              )}
              {safetyLog && (
                <li onClick={() => safetyLogOnClick(row)}>
                  <Grading /> <span>{safetyLogLabel}</span>
                </li>
              )}

              {configure && (
                <li onClick={() => configureOnClick(row)}>
                  <Edit /> <span>{configureLabel}</span>
                </li>
              )}
              {download && (
                <li onClick={() => dowloadCsv.current.link.click()}>
                  <FileDownload /> <span>{downloadLable}</span>
                </li>
              )}
              {resendEmail && (
                <li onClick={() => resendEmailOnClick(row)}>
                  <RestartAltIcon /> <span>{resendEmailLable}</span>
                </li>
              )}
              {addBtn && (
                <li onClick={() => addForm(row)}>
                  <Add /> <span>{addBtnLabel}</span>
                </li>
              )}
              {sampleLog && (
                <li onClick={() => sampleLogOnClick(row)}>
                  <Add /> <span>{sampleLogLabel}</span>
                </li>
              )}
              {missed && (
                <li onClick={() => missedOnClick(row)}>
                  <>
                    <img
                      src={commonImages.missedIcon}
                      className="deleteImg"
                      alt=""
                    />{" "}
                    <span>{missedLabel}</span>
                  </>
                </li>
              )}
              {defer && (
                <li onClick={() => deferOnClick(row)}>
                  <>
                    <img
                      src={commonImages.deferIcon}
                      className="deleteImg"
                      alt=""
                    />{" "}
                    <span>{deferLabel}</span>
                  </>
                </li>
              )}
              {deleted && (
                <li onClick={() => deleteOnClick(row)}>
                  <img
                    src={commonImages.deleteblueIcon}
                    className="deleteImg"
                    alt=""
                  />

                  <span>{deleteLabel}</span>
                </li>
              )}
              {sendEmail && (
                <li onClick={() => sendEmailOnClick(row)}>
                  <Email />
                  <span>{sendEmailLable}</span>
                </li>
              )}
              {manage && (
                <li onClick={() => manageOnClick(row)}>
                  <img
                    src={commonImages.ManageIcon}
                    className="deleteImg"
                    alt=""
                  />

                  <span>{manageLabel}</span>
                </li>
              )}
              {clone && (
                <li onClick={() => cloneOnClick(row)}>
                  <img
                    src={commonImages.contentcopy}
                    className="deleteImg"
                    alt=""
                  />
                  <span>{props?.cloneLabel}</span>
                </li>
              )}

              {conductExternal && (
                <li onClick={() => conductExternalOnClick(row)}>
                  <img
                    src={inspection?.conductExternal}
                    className="conductExternal"
                    alt=""
                  />
                  <span>{conductExternalLabel}</span>
                </li>
              )}

              {addProof && (
                <li onClick={() => addProofOnClick(row)}>
                  <img
                    src={inspection?.conductExternal}
                    className="conductExternal"
                    alt=""
                  />
                  <span>{addProofLabel}</span>
                </li>
              )}
              {unAssignLesson && (
                <li onClick={() => unAssignLessonOnclick(row)}>
                  <AssignmentReturnIcon />
                  <span>{unAssignLessonLabel}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Menu>
      <DownloadCsv
        ref={dowloadCsv}
        data={[row ? row : []]}
        filename={"list-of-incident.csv"}
      />
    </>
  );
}
