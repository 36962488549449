import { useEffect } from "react";
import L from "leaflet";
import "./Legend.scss";

function Legend({ map }) {
  console.log(map);
  useEffect(() => {
    if (map) {
      const legend = L.control({ position: "bottomright" });

      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");
        div.innerHTML =
          "<h4>Projects</h4>" +
          "<span  class='bullet'style='color: #2596be;'>&#9632;</span>Single Inspector<br>" +
          "<span  class='bullet'style='color: #ce3d2d;'>&#9632;</span>Multiple Inspectors<br>";
        return div;
      };

      legend.addTo(map);
    }
  }, [map]);
  return null;
}

export default Legend;
