import { dashboardType } from "../constants/dashboardType";

export const updateUserDashboardCart = (payload) => ({
  type: dashboardType.UPDATE_USER_DASHBOARD_CART_PENDING,
  payload,
});

export const updateUserDashboardCartSuccess = (data) => ({
  type: dashboardType.UPDATE_USER_DASHBOARD_CART_SUCCESS,
  data,
});

export const updateUserDashboardCartFailure = (error) => ({
  type: dashboardType.UPDATE_USER_DASHBOARD_CART_FAILURE,
  error,
});

export const getDashboardCartList = (data) => ({
  type: dashboardType.GET_DASHBOARD_CART_LIST_PENDING,
  data,
});

export const getDashboardCartListSuccess = (data) => ({
  type: dashboardType.GET_DASHBOARD_CART_LIST_SUCCESS,
  data,
});

export const getDashboardCartListFailure = (error) => ({
  type: dashboardType.GET_DASHBOARD_CART_LIST_FAILURE,
  error,
});

export const getUserDashboardCartList = (data) => ({
  type: dashboardType.GET_USER_DASHBOARD_CART_LIST_PENDING,
  data,
});

export const getUserDashboardCartListSuccess = (data) => ({
  type: dashboardType.GET_USER_DASHBOARD_CART_LIST_SUCCESS,
  data,
});

export const getUserDashboardCartListFailure = (error) => ({
  type: dashboardType.GET_USER_DASHBOARD_CART_LIST_FAILURE,
  error,
});
