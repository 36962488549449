import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./NormalGrid.scss";
export default function NormalGrid({
  rowss,
  columnss,
  onSelectionModelChange,
  selectionModel,
  rowCount,
  getRowId,
  loading,
  onRowClick,
  isRowSelectable,
  components,
  onCellClick,
  checkboxSelection = true,
  disableMultipleSelection = false,
}) {
  const handleSelectionModelChange = (newSelection) => {
    if (disableMultipleSelection && newSelection.length > 1) {
      const latestSelection = newSelection.slice(-1);
      onSelectionModelChange(latestSelection, rowss);
    } else {
      onSelectionModelChange(newSelection, rowss);
    }
  };
  return (
    <div className="normalTableBox">
      <DataGrid
        className="overrideDataGrid"
        getRowId={getRowId}
        checkboxSelection={checkboxSelection}
        rowCount={rowCount}
        rows={rowss}
        columns={columnss}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelection) =>
          handleSelectionModelChange(newSelection)
        }
        loading={loading}
        onRowClick={onRowClick}
        isRowSelectable={isRowSelectable}
        components={components}
        onCellClick={onCellClick}
      />
    </div>
  );
}
