import React, { useEffect, useState } from "react";
import ViewProcoreProject from "../component/project/viewProcoreProject";
import { ADMIN_PROCORE_PROJECT_LIST } from "../../../constant/routeContant";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import {
  API_STATUS,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
} from "../../../constant/constant";
import * as projectAction from "../../../redux/actions/projectAction";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";

function ViewProcoreProjectContainer() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);

  const dataBack = {
    title: "Back to Procore Project List",
    route: ADMIN_PROCORE_PROJECT_LIST,
  };
  const projectsListData = useSelector((state) => state.projectList);
  const syncProcoreProjectsListData = useSelector(
    (state) => state?.syncProcoreProjectsListData
  );
  const showLoader = useSelector(
    (state) => state?.syncProcoreProjectsListData?.isLoading
  );
  const getSyncProcoreProjectsLists = (data) => {
    dispatch(projectAction.getSyncProcoreProjectsList(data));
  };
  const projectListDelete = useSelector((state) => state.deleteProject.status);
  useEffect(() => {
    if (projectListDelete === "SUCCESS") getProjectListData(defaultData);
  }, [projectListDelete]);
  const getProjectListData = (data) => {
    dispatch(projectAction.getProjectList(data));
  };

  const isLoading = useSelector(
    (state) => state.deleteProject.isLoading || state.projectList.isLoading
  );
  const clientDivision = useSelector((state) => state.getDropdownDataKeyValue);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const divisions = userInfo?.divisions?.filter(
    (i) => i?.serviceType == PRODUCT_ID()
  );
  const isClient = userInfo?.isClient;
  const [clientDivisions, setClientDivisions] = useState();
  const valueCheck = clientDivisions || divisions;
  const clientSwitch = isClient || ORG_ID() !== LOGIN_ORG_ID();
  useEffect(() => {
    if (clientSwitch) {
      if (clientDivision?.status === API_STATUS.SUCCESS) {
        const divisions = clientDivision?.data?.divisons && [
          ...clientDivision?.data?.divisons,
        ];
        divisions?.unshift({ key: 1, value: "All Division" });
        setClientDivisions(divisions);
      }
    }
  }, [clientDivision, clientSwitch]);

  const defaultData = {
    organisationId: ORG_ID(),
    divisionId: clientSwitch && valueCheck ? 0 : divisions[0]?.key,
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: 0,
  };

  useEffect(() => {
    dispatch(projectAction.clearProject());
  }, []);

  function deleteProject(data) {
    dispatch(projectAction.deleteProject(data));
  }

  useEffect(() => {
    if (syncProcoreProjectsListData.status === "SUCCESS" && isSuccess) {
      toast("Project Synced Successfully");
    }
  }, [syncProcoreProjectsListData]);
  return (
    <>
      <div>
        <ToastContainer />
      </div>
      {showLoader && <Loader />}
      <ViewProcoreProject
        dataBack={dataBack}
        location={location}
        projectsListData={isLoading ? [] : projectsListData?.data}
        getProjectListData={getProjectListData}
        isLoading={isLoading}
        deleteProject={deleteProject}
        isProjectsLoading={isLoading}
        divisionDropdown={
          clientSwitch && valueCheck ? clientDivisions : divisions
        }
        checkbox={true}
        onRowClick={true}
        onSelectionModelChange={true}
        selectionModel={true}
        getSyncProcoreProjectsLists={getSyncProcoreProjectsLists}
        syncProcoreProjectsListData={syncProcoreProjectsListData}
        clientSwitch={clientSwitch}
        valueCheck={valueCheck}
        divisions={divisions}
        setIsSuccess={setIsSuccess}
        isSuccess={isSuccess}
      />
    </>
  );
}

export default ViewProcoreProjectContainer;
