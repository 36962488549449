import { caseType } from "../constants/caseType";

export const createCase = (data) => ({
  type: caseType.POST_CASE_PENDING,
  data,
});

export const createCaseSuccess = (data) => ({
  type: caseType.POST_CASE_SUCCESS,
  data,
});

export const createCaseFailure = (error) => ({
  type: caseType.POST_CASE_FAILURE,
  error,
});

export const updateCase = (data) => ({
  type: caseType.PUT_CASE_PENDING,
  data,
});

export const updateCaseSuccess = (data) => ({
  type: caseType.PUT_CASE_SUCCESS,
  data,
});

export const updateCaseFailure = (error) => ({
  type: caseType.PUT_CASE_FAILURE,
  error,
});

export const clearCase = () => ({
  type: caseType.CLEAR_CASE,
  data: "",
});

export const getCaseList = (data) => ({
  type: caseType.GET_CASE_LIST_PENDING,
  data,
});

export const getCaseListSuccess = (data) => ({
  type: caseType.GET_CASE_LIST_SUCCESS,
  data,
});

export const getCaseListFailure = (error) => ({
  type: caseType.GET_CASE_LIST_FAILURE,
  error,
});
