import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import App from "./App";
import { ThemeProvider } from "./context";
import { ErrorBoundary } from "./ErrorBoundary ";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import I18N from "./i18n";
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {})
    .catch((error) => {});
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider>
    <Provider store={store}>
      <ErrorBoundary>
        <I18N>
          <App />
        </I18N>
      </ErrorBoundary>
    </Provider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
