import {
  getProjectSearchList,
  getSwReferenceData,
  getContactSearchList,
} from "../../../services/storm-water/swCommonService";
import * as swCommonAction from "../actions/swCommonAction";
import { SwCommonType } from "../types/swCommonType";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../../utils/helper";

export function* getSwReferenceDataSaga(params) {
  console.debug("getSwReferenceDataSaga");
  try {
    const { data, status, error } = yield call(getSwReferenceData, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwCommonType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swCommonAction.getSwReferenceSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get SW Question Set List Failed" };
    yield put(swCommonAction.getSwReferenceFailure(data));
  }
}

export function* getProjectSearchListSaga(params) {
  console.debug("getProjectSearchListSaga");
  try {
    const { data, status, error } = yield call(getProjectSearchList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwCommonType.GET_SW_QUESTION_SET_PAGE;
    yield put(
      swCommonAction.getProjectSearchListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get ProjectSearchList Failed" };
    yield put(swCommonAction.getProjectSearchListFailure(data));
  }
}

export function* getContactSearchListSaga(params) {
  console.debug("getContactSearchListSaga");
  try {
    const { data, status, error } = yield call(getContactSearchList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = SwCommonType.GET_CONTACT_SEARCH_LIST_PAGE;
    yield put(
      swCommonAction.getContactSearchListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get ContactSearchList Failed" };
    yield put(swCommonAction.getContactSearchListFailure(data));
  }
}
const mySwCommonSaga = [
  takeLatest(
    SwCommonType.GET_SW_REFERENCE_DATA_PENDING,
    getSwReferenceDataSaga
  ),
  takeLatest(
    SwCommonType.GET_PROJECT_SEARCH_LIST_PENDING,
    getProjectSearchListSaga
  ),
  takeLatest(
    SwCommonType.GET_CONTACT_SEARCH_LIST_PENDING,
    getContactSearchListSaga
  ),
];

export default mySwCommonSaga;
