import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const UtilityMultipleDamage = ({ additionalData }) => {
  return (
    <div className="commonOveride">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">Utility Damage Details:</Typography>
            <Box className="columns">
              <Typography className="listView">
                Foreman Name:
                <span className="font-w400">
                  {additionalData?.utilityDamagedPrm?.employee}
                </span>
              </Typography>
              <Typography className="listView">
                Supervisor Name:
                <span className="font-w400">
                  {additionalData?.utilityDamagedPrm?.supervisor}
                </span>
              </Typography>
            </Box>
            <Box className="columns">
              <Typography className="listView">
                Jobsite/Project name:
                <span className="font-w400">
                  {additionalData?.utilityDamagedPrm?.jobSite}
                </span>
              </Typography>
            </Box>
            <div className="additionalListBox">
              {additionalData?.utilityDamagedPrm?.utilityPrm?.map((item, i) => {
                return (
                  <>
                    <div className="additionalList" key={i}>
                      <div className="subtitle">Utility Damage {i + 1}</div>
                      <Box className="columns">
                        <Typography className="listView">
                          Utility Property Damaged:
                          <span className="font-w400">
                            {item?.utilityProperty}
                          </span>
                        </Typography>
                        <Typography className="listView">
                          Type of Utility:
                          <span className="font-w400">
                            {item.typeOfUtility.map((i) => i.value).join(", ")}
                          </span>
                        </Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView">
                          Type of Utility Line Damage:
                          <span className="font-w400">
                            {item.udUtilityDamage
                              .map((i) => i.value)
                              .join(", ")}
                          </span>
                        </Typography>
                        <Typography className="listView">
                          Type of Machinery:
                          <span className="font-w400">
                            {item?.typeOfMachinery}
                          </span>
                        </Typography>
                      </Box>
                      <Typography className="listView">
                        Location:
                        <span className="font-w400">{item?.addLocation}</span>
                      </Typography>
                      <Box className="columns">
                        <Typography className="listView">
                          Location Details:
                          <span className="font-w400">
                            {item?.locationDetail}
                          </span>
                        </Typography>
                        <Typography className="listView">
                          Locate Ticket #:
                          <span className="font-w400">
                            {item?.locateTicket}
                          </span>
                        </Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView">
                          Validity From:
                          <span className="font-w400">{item.validFrom}</span>
                        </Typography>
                        <Typography className="listView">
                          Validity To:
                          <span className="font-w400">{item?.validTo}</span>
                        </Typography>
                      </Box>
                      <Box className="columns">
                        <Typography className="listView desNone">
                          Damage Ticket #:
                          <span className="font-w400">
                            {item?.damageTicket}
                          </span>
                        </Typography>
                        <Typography className="listView desNone">
                          Were Utility Lines:
                          <span className="font-w400">
                            {item.udUtilityLine.map((i) => i.value).join(", ")}
                          </span>
                        </Typography>
                      </Box>
                    </div>
                  </>
                );
              })}
            </div>
          </Box>
          <Box className="commoninfoRight"></Box>
        </Box>
      </Box>
    </div>
  );
};

export default UtilityMultipleDamage;
