import { CognitoJwtVerifier } from "aws-jwt-verify";
import { poolData } from "../UserPool";

export const awsverify = async ({ accessToken, tokenType }) => {
  const verifier = CognitoJwtVerifier.create({
    userPoolId: poolData.UserPoolId,
    tokenUse: tokenType,
    clientId: poolData.ClientId,
  });
  try {
    const payload = await verifier.verify(accessToken);
    return payload;
  } catch (error) {
    return "Token not valid!";
  }
};
