import React, { useState } from "react";
import JhaAddTemplate from "../component/jha/jhaAddTemplate";
import { API_STATUS, USER_ID } from "../../../constant/constant";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { useEffect } from "react";
import Loader from "../../../library/common/Loader";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { useLocation } from "react-router-dom";

const JhaAddTemplateContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isSuccess, setisSuccess] = useState(false);
  const editMode = location?.state?.editMode;
  const [lastModifiedCatAct, setlastModifiedCatAct] = useState({
    cat: undefined,
    act: undefined,
  });
  useEffect(() => {
    setisSuccess(false);
    dispatch(jhaAction.clearJha());
  }, []);

  useEffect(() => {
    if (editMode)
      dispatch(jhaAction.getJhaTempeleteDetsils(location?.state?.templateId));
  }, []);
  const getJhaTempleteDetsilsData = useSelector(
    (state) => state?.getJhaTempleteDetsilsData
  );

  const hazardsListData = useSelector((state) => state.getJhaHazardsList);
  let arr = [];
  useEffect(() => {
    if (
      editMode &&
      getJhaTempleteDetsilsData?.data &&
      getJhaTempleteDetsilsData?.status === "SUCCESS"
    ) {
      setFormValue({
        ...formValue,
        templateName: getJhaTempleteDetsilsData?.data?.templateName,
        assetTypeId: getJhaTempleteDetsilsData?.data?.assetTypeId,
        isCategories: false,
      });

      getJhaTempleteDetsilsData?.data?.categories?.forEach((cat) => {
        let actArr = [];
        cat.activitiesTemplate.forEach((act) => {
          let hazArr = [];
          act?.hazards.forEach((h) => {
            hazArr.push({
              hazardsName: h.value,
              id: h.key,
            });
          });
          actArr.push({
            activityId: act.key,
            activityName: act.value,
            key: act.key,
            value: act.value,
            isSelected: true,
            categoryId: cat.key,
            hazards: hazArr,
          });
        });
        arr.push({
          categoryId: cat.key,
          categoriesName: cat.value,
          // activities: actArr,
          selectedActivity: actArr,
        });
      });
      setcatActArr(arr);
    }
  }, [getJhaTempleteDetsilsData]);

  const [formValue, setFormValue] = useState({
    templateName: "",
    isTemplateName: false,
    assetTypeId: null,
    isAssetTypeId: false,
    categories: [],
    isCategories: false,
    createdBy: parseInt(USER_ID()),
  });
  const [categoriesName, setcategoriesName] = useState([]);
  const [catActArr, setcatActArr] = useState([]);
  // const [actApiData, setactApiData] = useState();
  const categoriesListData = useSelector((state) => state.jhaCategoryList);
  const activitiesListData = useSelector((state) => state.getJhaActivityList);
  
  const controlListData = useSelector((state) => state.getJhaControlList);
  const controlsLoading = useSelector((state) => state.getJhaControlList.isLoading);
  const getCategoriesListData = (data) => {
    dispatch(jhaAction.getJhaCategoryList(data));
  };

  const getJhaHazardsList = (data) => {
    dispatch(jhaAction.getJhaHazardsList(data));
  };
  const getControls = (data) => {
    dispatch(
      jhaAction.getJhaControlList({
        pageSize: 10000,
        page: 1,
        search: "",
        hazardId: data,
      })
    );
  };

  useEffect(() => {
    getCategoriesListData({ search: "", isAll: 0, page: 1, pageSize: 1000 });
  }, []);
  const [categoriesList, setcategoriesList] = useState([]);
  //  categoriesList = mapCategoriesKeyValue();
  function mapCategoriesKeyValue() {
    let arr = [];
    categoriesListData?.data?.result?.itemList.forEach((i) =>
      arr.push({
        key: i.id,
        value: i.categoryName,
      })
    );
    setcategoriesList(arr);
  }
  useEffect(() => {
    if (
      categoriesListData.status === API_STATUS.SUCCESS &&
      categoriesListData?.isLoading === false
      // clearalert
    ) {
      mapCategoriesKeyValue();
    }
  }, [categoriesListData]);

  const getJhaActivityList = (data) => {
    if (data !== undefined) dispatch(jhaAction.getJhaActivityList(data));
  };

  // useEffect(() => {
  //   getJhaActivityList(actApiData);
  // }, [actApiData]);
  useEffect(() => {
    if (catActArr.length > 0) {
      let arr = [...catActArr];
      const activityDaata = [];
      // arr[arr.length - 1].activities =
      activitiesListData?.data?.result?.itemList.forEach((act) => {
        activityDaata.push({
          categoryId: arr[arr.length - 1].categoryId,
          activityId: act.id,
          isSelected: false,
          activityName: act.activityName,
        });
      });
      arr[arr.length - 1].activities = activityDaata;

      setcatActArr(arr);
    }
  }, [activitiesListData]);

  useEffect(() => {
    if (!hazardsListData?.isLoading && hazardsListData.status === "SUCCESS") {
      let arr = [...catActArr];
      arr.forEach((i) => {
        if (i.categoryId === lastModifiedCatAct.cat) {
          i?.selectedActivity?.forEach((j) => {
            if (lastModifiedCatAct.act === j.key) {
              j.hazards = hazardsListData?.data?.result?.itemList;
            }
          });
        }
      });
      setcatActArr(arr);
    }
  }, [hazardsListData]);

  const addJhaTemplateData = useSelector((state) => state?.addJhaTemplate);
  const updateJhaTemplateData = useSelector(
    (state) => state?.updateJhaTemplate
  );
  const successScreen = (id, message, message1) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          editMode
            ? "Template Updated Successfully"
            : "Template Added Successfully"
        }
        route={-1}
        label1="Template ID"
        label4="Templates in "
        label2={message1}
      ></SuccessScreen>
    );
  };

  function submitTemplate(data) {
    dispatch(jhaAction.clearJha(data));
    setisSuccess(true);
    if (editMode)
      dispatch(
        jhaAction.updateJhaTemplate({
          tenplateName: data.tenplateName,
          assetTypeId: data.assetTypeId,
          templateId: location?.state?.templateId,
          categories: data.categories,
          createdBy: data.createdBy,
        })
      );
    else dispatch(jhaAction.addJhaTemplate(data));
  }
  const showLoader =
    activitiesListData.isLoading ||
    categoriesListData?.isLoading ||
    getJhaTempleteDetsilsData.isLoading;
  return (
    <div>
      {showLoader && <Loader />}
      {addJhaTemplateData.status === "SUCCESS" &&
      isSuccess &&
      addJhaTemplateData ? (
        successScreen(
          addJhaTemplateData?.data.id,
          "Template Added Successfully",
          "has been generated"
        )
      ) : updateJhaTemplateData.status === "SUCCESS" &&
        isSuccess &&
        updateJhaTemplateData ? (
        successScreen(
          updateJhaTemplateData?.data.id,
          "Template Updated Successfully",
          "has been updated"
        )
      ) : addJhaTemplateData.isLoading || updateJhaTemplateData.isLoading ? (
        <Loader />
      ) : (
        <JhaAddTemplate
          categoriesName={categoriesName}
          setcategoriesName={setcategoriesName}
          formValue={formValue}
          setFormValue={setFormValue}
          categoriesList={categoriesList}
          // findCatId={findCatId}
          catActArr={catActArr}
          setcatActArr={setcatActArr}
          submitTemplate={submitTemplate}
          getJhaActivityList={getJhaActivityList}
          editMode={editMode}
          setlastModifiedCatAct={setlastModifiedCatAct}
          lastModifiedCatAct={lastModifiedCatAct}
          getJhaHazardsList={getJhaHazardsList}
          getControls={getControls}
          controlListData={controlListData?.data?.result?.itemList ?? []}
          controlsLoading={controlsLoading}
        />
      )}
    </div>
  );
};

export default JhaAddTemplateContainer;
