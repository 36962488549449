import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import { logoOutUser } from "../../redux/actions/loginActions";
import "./navbar.scss";
import {
  ADMIN_PROFILE,
  DASHBOARD,
  LANDING_PAGE,
} from "../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import SearchAutoComplete from "../custom/searchAutoComplete/SearchAutoComplete";
import * as clientAction from "../../redux/actions/clientAction";
import { checkPrivileges } from "../../utils/rolesHelper";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid } from "@mui/material";
import { logos } from "../../assets";
import BellIcon from "../../assets/BellIcon.svg";
import _ from "lodash";
import NotificationList from "../../feature/admin/shared/NotificationList";
import * as loginActions from "../../redux/actions/loginActions";
import { messaging } from "../../../src/firebase";
import { getToken } from "firebase/messaging";
import Loader from "../common/Loader";
import { vapidKey, SERVICE_TYPE } from "../../constant/constant";
import ReactGA from "react-ga4";
const pages = [];
const ResponsiveAppBar = ({ handleDrawerToggle, islanding = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchAllClientsReducerData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchDataLoading = searchAllClientsReducerData.isLoading;
  const handleCloseNavMenu = () => {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clients, setClients] = useState();
  const serviceType = SERVICE_TYPE();
  const Profile_image = localStorage.getItem("profileImage")
    ? localStorage.getItem("profileImage")
    : "";
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userInfoProduct = userInfo?.product;
  const data = userInfoProduct?.map((val) => val?.key);
  const onChangeClients = (org) => {
    localStorage.setItem("orgId", org.key);
    localStorage.setItem("divisionId", 0);
    localStorage.setItem("orgName", org.value);
    localStorage.setItem("orgLogo", org.logoUrl);
    localStorage.setItem("ProcoreCompanyId", org.ProcoreCompanyId);
    localStorage.setItem("IsProcoreConnected", org.IsProcoreConnected);
    window.location.href = DASHBOARD;
    setClients(org);
  };

  const loginOrgID = localStorage.getItem("loginOrganizationId");
  const loginOrganizationName = localStorage.getItem("loginOrganizationName");
  const SwitchedClientOrgID = localStorage.getItem("orgId");
  const ProcoreId = localStorage.getItem("ProcoreCompanyId");
  const IsProcoreConnected = localStorage.getItem("IsProcoreConnected");

  const onMyOrgChange = () => {
    localStorage.setItem("orgId", loginOrgID);
    localStorage.setItem("orgName", loginOrganizationName);
    localStorage.setItem("companyName", loginOrganizationName);
    localStorage.removeItem("ProcoreCompanyId", ProcoreId);
    localStorage.removeItem("IsProcoreConnected", IsProcoreConnected);
    window.location.href = DASHBOARD;
    setAnchorEl(null);
  };

  const typeOnchange = (event) => {
    const searchText = event.target?.value;
    if (!_.isEmpty(searchText)) {
      searchClient(searchText.trim());
    }
  };

  const getHeaderText = () => {
    const loginOrganizationId = localStorage.getItem("loginOrganizationId");
    const orgId = localStorage.getItem("orgId");
    const headerText =
      loginOrganizationId === orgId
        ? localStorage.getItem("name")
        : localStorage.getItem("orgName");
    return "Welcome, " + headerText;
  };

  const [anchorElNotfication, NotficationsetAnchorEl] = useState(null);
  const [fcmTokens, setFcmToken] = useState("");

  const NotificationOClick = (event) => {
    NotficationsetAnchorEl(event.currentTarget);
  };

  const handleCloseOnNOtification = () => {
    NotficationsetAnchorEl(null);
    window.close();
  };
  const opens = Boolean(anchorElNotfication);
  const idd = opens ? "simple-popover" : undefined;
  const userId = localStorage.getItem("userId");
  const NotifiactionListDatas = useSelector(
    (state) => state.NotifiactionListData
  );
  const loginData = useSelector((state) => state.login);
  const NotifiactionLogoutDatas = useSelector(
    (state) => state.NotifiactionLogoutData
  );

  const notificationCount =
    NotifiactionListDatas?.data?.unreadCount > 100
      ? "99+"
      : NotifiactionListDatas?.data?.unreadCount;

  const getNotifiactionListData = (data) => {
    dispatch(loginActions.getNotifiactionList(data));
  };

  const UpdateNotifiactionListData = (data) => {
    dispatch(loginActions.updateNotifiactionList(data));
  };

  useEffect(() => {
    const defaultData = {
      page: 1,
      pageSize: 1000,
      status: "",
      userId: userId,
      search: "",
      toDate: "",
      fromDate: "",
    };
    getNotifiactionListData(defaultData);
  }, []);

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: vapidKey,
      });
      setFcmToken(token);
    } else if (permission === "denied") {
      return null;
    }
  }

  const postNotifiactionLogOutData = (data) => {
    dispatch(loginActions.postNotifiactionLogout(data));
  };

  useEffect(() => {
    if (loginData.status === "SUCCESS") {
      requestPermission();
    }
  }, []);

  const logoutClickHandler = () => {
    const defaultData = {
      userId: userId,
      fcmToken: fcmTokens,
    };
    postNotifiactionLogOutData(defaultData);
  };

  useEffect(() => {
    if (
      NotifiactionLogoutDatas?.status === "SUCCESS" &&
      NotifiactionLogoutDatas?.isLoading === false
    ) {
      dispatch(logoOutUser());
    }
  }, [NotifiactionLogoutDatas]);

  const pathName = window.location.pathname;
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathName, title: pathName });
  }, [pathName]);
  return (
    <div className="header-over">
      {NotifiactionLogoutDatas.isLoading && <Loader />}
      <AppBar className="topHeader header-box" position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Grid
              container
              className={
                islanding
                  ? "topHeaderBox islanding-top-headerBox"
                  : "topHeaderBox"
              }
            >
              <div item xs={2} sm={2} md={2} className="menuIconMobile">
                <MenuIcon onClick={handleDrawerToggle} />
              </div>
              {islanding && (
                // <div className="logo">
                <Grid item lg={2.5} md={3} xs={2}>
                  <img
                    src={logos?.logo}
                    width="180px"
                    className="light-logo logoResponsive"
                    alt="no logo"
                  />
                  <img
                    src={logos?.logoWhite}
                    width="180px"
                    className="dark-logo "
                    alt="no logo"
                  />
                </Grid>
                // </div>
              )}

              <Grid
                item
                md={islanding ? 5 : 7}
                xs={8}
                lg={islanding ? 5 : 7}
                className="leftBox WelcomeUserText"
              >
                <h1 className="welcomeUser ">{getHeaderText()}</h1>
              </Grid>
              <Grid
                item
                md={islanding ? 4.5 : 3}
                xs={2}
                lg={islanding ? 4.5 : 5}
                container
                className="rightBox profieImages"
              >
                <Grid>
                  {pages.map((page) => (
                    <Button
                      className="pages-title"
                      key={page}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {page}
                    </Button>
                  ))}
                </Grid>
                <Grid item md={8} xs={8} sm={8} className="searchAutoMobile">
                  {checkPrivileges([12, 15]) && !islanding && (
                    <SearchAutoComplete
                      name="orgName"
                      placeholderText="Search organization"
                      noOptionsTextMsg="No organization"
                      optionsData={
                        autoSearchDataLoading
                          ? []
                          : searchAllClientsReducerData?.data ?? []
                      }
                      typeOnchange={(event, value) =>
                        typeOnchange(event, value)
                      }
                      isKeyValuePair={true}
                      value={clients}
                      onChange={(event, value) => onChangeClients(value)}
                      isLoading={autoSearchDataLoading}
                    />
                  )}
                </Grid>
                {serviceType === "1" && (
                  <Grid
                    item
                    lg={1.5}
                    md={2}
                    xs={2}
                    sm={2}
                    mt={1}
                    className="NotificationStyles"
                  >
                    {!islanding && (
                      <div className="notification-badge">
                        <img
                          src={BellIcon}
                          alt="Notification Icon"
                          onClick={NotificationOClick}
                        />
                        {notificationCount > 0 && (
                          <span className="badge">{notificationCount}</span>
                        )}
                      </div>
                    )}
                    <NotificationList
                      id={idd}
                      open={opens}
                      anchorEl={anchorElNotfication}
                      handleClose={handleCloseOnNOtification}
                      NotifiactionListDatas={NotifiactionListDatas?.data}
                      UpdateNotifiactionListData={UpdateNotifiactionListData}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  md={2}
                  xs={2}
                  sm={2}
                  iconRightBox
                  className="ProfileImageIcon"
                >
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={handleClick}
                    className="Profile_Btn"
                  >
                    <Avatar alt="profile image" src={Profile_image} />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className="profileDropdown"
                  >
                    {loginOrgID === SwitchedClientOrgID ? (
                      ""
                    ) : (
                      <MenuItem onClick={onMyOrgChange}>
                        My Organization
                      </MenuItem>
                    )}

                    {!islanding && (
                      <MenuItem
                        onClick={() => {
                          navigate(ADMIN_PROFILE);
                          setAnchorEl(null);
                        }}
                      >
                        Profile
                      </MenuItem>
                    )}
                    {!islanding && data?.length > 1 && (
                      <MenuItem
                        onClick={() => {
                          navigate(LANDING_PAGE);
                          setAnchorEl(null);
                        }}
                      >
                        Service Type
                      </MenuItem>
                    )}
                    <MenuItem onClick={logoutClickHandler}>Logout</MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
export default ResponsiveAppBar;
