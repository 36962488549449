import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PAGE_SIZE,
  MODULES_NAME,
  API_STATUS,
  USER_ID,
} from "../../../constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { usersAccess } from "../../../utils/rolesHelper";
import { DASHBOARD } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { useState } from "react";
import ToolBoxAssignedUserStatus from "../component/toolBoxAssignUserStatus/toolBoxAssignUserStatus";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ToolboxScheduleUserStatusContainer = () => {
  const location = useLocation();
  const fieldid = location?.state?.fieldId;
  const scheduleId = location?.state?.scheduleId;
  const scheduleDate = location?.state?.scheduleDate;
  const flag = location?.state?.flag;
  const titleSafetyMeeting = location?.state?.title;
  const Tabvalue = location?.state?.tabvalue;
  const getScheduleAssignUserStatusData = useSelector(
    (state) => state.getAssignTookBoxUser
  );

  const isLoading = getScheduleAssignUserStatusData?.isLoading;
  const dispatch = useDispatch();

  const defaultData = {
    scheduleId: scheduleId,
    flag: flag,
    scheduleDate: scheduleDate,
    userId: USER_ID(),
  };
  let navigate = useNavigate();
  const adminAccess = usersAccess(MODULES_NAME.admin);
  const lmsAccess = usersAccess(MODULES_NAME.lms);

  const isAllowed =
    lmsAccess.isFullAccess ||
    lmsAccess.isWriteAccess ||
    adminAccess.isFullAppAccess;
  useEffect(() => {
    // if (!isAllowed) {
    //   navigate(DASHBOARD);
    // }
    scheduleUserCountStatusList(defaultData);
  }, []);
  const scheduleUserCountStatusList = async (data) => {
    await dispatch(toolboxAction.scheduleUserCountStatusList(data));
  };
  const [clearAlert, setClearAlert] = useState(false);
  const addSubmitAlert = async (data) => {
    setClearAlert(true);
    dispatch(toolboxAction.getAlertmailSafetyMeeting(data));
  };
  const postSafetyMeetingAlertData = useSelector(
    (state) => state?.postSafetyMeetingAlert
  );
  const isloadingalert = postSafetyMeetingAlertData?.isLoading;
  useEffect(() => {
    if (
      postSafetyMeetingAlertData.status == API_STATUS.SUCCESS &&
      postSafetyMeetingAlertData?.isLoading === false &&
      clearAlert
    ) {
      toast("Mail Sending successfully");
    }
  }, [postSafetyMeetingAlertData]);
  return (
    <>
      {isLoading && <Loader />}
      {isloadingalert && <Loader />}

      <ToolBoxAssignedUserStatus
        getScheduleAssignUserStatusData={
          getScheduleAssignUserStatusData?.data?.users
        }
        fieldid={fieldid}
        flag={flag}
        titleSafetyMeeting={titleSafetyMeeting}
        Tabvalue={Tabvalue}
        onSubmit={addSubmitAlert}
        scheduleId={scheduleId}
      />
      <ToastContainer />
    </>
  );
};

export default ToolboxScheduleUserStatusContainer;
