import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import "./toolBoxTalkList.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../tabPanel/tabPanel";
import { useSelector, useDispatch } from "react-redux";
import { COLUMNS_USER_TOOLBOXTALK_VIEW } from "../../courseConstant";
import { useNavigate } from "react-router-dom";
import {
  TOOL_BOX_DETAIL,
  TOOL_BOX_VERIFY_ATTENDEES,
} from "../../../../constant/routeContant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { PAGE_SIZE, ORG_ID, USER_ID } from "../../../../constant/constant";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import Popper from "@mui/material/Popper";
import LmsFilterList from "../../../../library/custom/lmsFilterList/lmsFilterList";
import FilterButton from "../../../../library/custom/filterButton/filterButton";
import SettingTable from "../../../incidents/incidentList/SettingTable";

const ToolBoxTalkUserList = (props) => {
  const [page, setPage] = useState(1);
  const [value, setValue] = React.useState(0);
  const [status, setStatus] = useState(0);
  const [placement, setPlacement] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const getToolBoxTalkListResult = props?.getToolBoxTalkListResult;
  const getToolBoxTalkListData = props?.getToolBoxTalkListData;
  const categoryListData = props?.categoryListData;
  const noRecourdCount = getToolBoxTalkListResult?.itemsCount;
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.getToolBoxTalkList);
  const isSafetyLog = props?.isSafetyLog === false;
  const usersId = props?.usersId;
  const handleChange = (event, newValue) => {
    setPage(1);
    setValue(newValue);
    setStatus(newValue);
    const { searchType } = filter;
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: "",
      catIds: "",
      userId: value === 1 ? 0 : USER_ID(),
      status: "",
    };

    getToolBoxTalkListData(data);
  };

  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };

  const handleOnCellClick = (params) => {
    let toolboxTalkId = params?.row?.toolboxTalkId;
    let assignInstructorsUsersId = params?.row?.assignInstructorsUsersId;
    let fieldName = params?.field;
    let scheduleId = params?.row?.id;
    let assignedOn = params?.row?.assignedOn;

    let finalid;
    if (fieldName === "titleSafetyMeeting") {
      finalid = "true";
    } else {
      finalid = "false";
    }

    if (fieldName === "titleSafetyMeeting") {
      navigate(TOOL_BOX_DETAIL, {
        state: {
          fieldId: finalid,
          toolboxTalkId: toolboxTalkId,
          scheduleId: scheduleId,
          assignedOn: assignedOn,
          assignInstructorsUsersId: assignInstructorsUsersId,
          toolboxtalkuyserlist: true,
        },
      });
    }
  };

  const columns = React.useMemo(() => {
    const headers = [...COLUMNS_USER_TOOLBOXTALK_VIEW];
    headers.push({
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={noRecourdCount ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={getToolBoxTalkListResult?.saftyMeetingList ?? []}
          fileNameXL={"SafetyMeetings"}
        />
      ),
      width: 170,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {},
    });
    return headers;
  }, [getToolBoxTalkListResult?.saftyMeetingList, PAGE_SIZE, navigate]);

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getToolBoxTalkListData({
      pageNumber: newPage + 1,
      pageSize: PAGE_SIZE,
      search: search,
      status: value,
      userId: isSafetyLog ? usersId : USER_ID(),

      organisationId: ORG_ID(),
    });
  };

  const handleSearch = (data1) => {
    setAnchorEl(null);

    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: search,
      status: value,
      userId: USER_ID(),
      organisationId: ORG_ID(),
      categoryIds: filter?.searchType[0]?.key,
    };

    getToolBoxTalkListData(data);
    setOpen(false);
  };
  const handleFilterClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const id = open ? "simple-popper" : undefined;
  const [filter, setFilter] = useState({ searchType: [] });
  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: searchText ? searchText : "",
      catIds: filter?.searchType[0]?.key ? filter?.searchType[0]?.key : "",
      userId: USER_ID(),
      tabId: 1,
    };

    getToolBoxTalkListData(data);
  };
  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };
  getToolBoxTalkListResult?.saftyMeetingList?.map((Dta, index) => {
    return (Dta["safetyid"] = (page - 1) * 5 + index + 1);
  });
  return (
    <>
      <div className="toolboxList">
        <Grid container>
          <Grid lg={7} xs={12}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tabcustom"
                  icon={
                    value === 0 ? (
                      <div className="totalV">
                        {value === 0 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="Safety Meetings"
                />
              </Tabs>
            </Box>
          </Grid>
          <Grid lg={5} xs={12}>
            <div className="rightFilter">
              <div className="searchfilter">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div className="searchfilterInput">
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
              <div
                className="filter_btn"
                onClick={handleFilterClick("bottom-end")}
              >
                <FilterButton label={"Filters"} />
              </div>
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
              >
                <LmsFilterList
                  filterType={categoryListData ?? []}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  handleSearch={handleSearch}
                  tabvalue={value}
                  inputsearch={search}
                  onChangeType={onChangeType}
                  filter={filter}
                  PAGE_SIZE={PAGE_SIZE}
                />
              </Popper>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <TabPanel className="tabPanel" value={value} index={0}>
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.safetyid}
                  rows={getToolBoxTalkListResult?.saftyMeetingList ?? []}
                  rowCount={getToolBoxTalkListResult?.itemsCount ?? 0}
                  columns={columns}
                  loading={isLoading}
                  page={page}
                  pagination
                  pageSize={PAGE_SIZE}
                  rowsPerPageOptions={[PAGE_SIZE]}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  onClick={handleOnCellClick}
                />
              </div>
            </div>
          </TabPanel>
        </Grid>
      </div>
    </>
  );
};

export default ToolBoxTalkUserList;
