import {
  ADD_INSPECTION_FORM,
  SW_INSPECTION_CONFIGURATION,
} from "../../../../constant/routeContant";
import React, { useState, useEffect } from "react";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import Buttons from "../../../../library/custom/button/Button";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { Grid } from "@mui/material";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import FilterCommonStatusSelect from "../../../admin/shared/FilterCommonStatusSelect";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";

import {
  HBNEXT_ORG_ID,
  ISwitch,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import "./configureInspections.scss";
import { STATUS } from "../../../../constant/storm-water/constant";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const InspectionFormsList = ({
  getInspectionQuestionSetListData,
  inspectionQuestionSetList,
  handleEditOnClick,
  isLoading,
  count,
  updateQuestionSetStatusData,
  onCloneSubmit,
  updateQuestionSetStatus,
  cloneSwQuestionSet,
  state,
  isSubmit,
  setIsSubmit,
  stateId,
  setStateId
}) => {
  const navigate = useNavigate();

  const intialCloneState = {
    inspecionClonetype: "",
  };

  const [formValueClone, setFormValueClone] = useState(intialCloneState);
  const [modalData, setModalData] = useState();
  const [cloneOpen, setCloneOpen] = React.useState(false);
  const [isCloneInspeciontype, setIsCloneInspeciontype] = useState(false);
  const [errorsMsgCloneShowActive, seterrorsMsgCloneShowActive] =
    useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();

  const [stateNameError, setStateNameError] = useState(false);

  const onChangeState = (event) => {
    setStateNameError(false);
    setStateId(event.target.value);
  };

  const handleCloneClose = () => setCloneOpen(false);

  const handleCloneOnClick = (modalData) => {
    setCloneOpen(true);
    setModalData(modalData);
    setFormValueClone({
      inspecionClonetype: "",
    });
  };

  const submitCloneData = () => {
    const index = inspectionQuestionSetList?.findIndex(
      (obect) =>
        obect.value?.toUpperCase() ===
        formValueClone?.inspecionClonetype?.toUpperCase()
    );

    if (formValueClone?.inspecionClonetype.trim() === "") {
      setFormValueClone({
        ...formValueClone,
        inspecionClonetype: "",
      });
      seterrorsMsgCloneShowActive(false);
      setIsCloneInspeciontype(true);
    } else if (!stateId || stateId === 0) {
      setStateNameError(true);
    } else if (index === -1) {
      setIsSubmit(true);
      setCloneOpen(false);
      setFormValueClone({
        ...formValueClone,
        inspecionClonetype: "",
      });
      const dataClone = {
        cloneQuestionSetId: modalData?.key,
        newQuestionSet: formValueClone?.inspecionClonetype,
        createdBy: parseInt(USER_ID()),
        stateId: stateId,
      };
      onCloneSubmit(dataClone);
    } else {
      seterrorsMsgCloneShowActive(true);
      setErrorsMsgShow("Inspection Form Name already added");
    }
  };

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [statusIds, setStatusIds] = useState();
  const [filter, setFilter] = useState({
    searchType: [],
  });

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      search: search ? search : "",
      userId: 0,
      status: statusIds === 1 ? true : statusIds === 2 ? false : "",
      pageSize: PAGE_SIZE,
    };
    getInspectionQuestionSetListData(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      search: event.target.value,
      userId: 0,
      status: statusIds === 1 ? true : statusIds === 2 ? false : "",
      page: page,
      pageSize: PAGE_SIZE,
    };
    getInspectionQuestionSetListData(data);
  };

  const handleChange = (item) => {
    const newTypeList = [...inspectionQuestionSetList];
    newTypeList.forEach((lists) => {
      if (lists.key == item.key) {
        lists.status = !lists.status;
        lists.checked = !lists.checked;
        updateQuestionSetStatusData({
          questionSetId: lists?.key,
          status: lists?.status,
          isGlobal: lists?.isGlobal,
          userId: parseInt(USER_ID()),
        });
      }
    });
  };

  const handleGlobalChange = (item) => {
    const newTypeList = [...inspectionQuestionSetList];
    newTypeList.forEach((lists) => {
      if (lists.key == item.key) {
        lists.isGlobal = !lists.isGlobal;
        updateQuestionSetStatusData({
          questionSetId: lists?.key,
          status: lists?.status,
          isGlobal: lists?.isGlobal,
          userId: parseInt(USER_ID()),
        });
      }
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dataBack = {
    title: "Back to Inspection Configuration",
    route: SW_INSPECTION_CONFIGURATION,
  };
  const handleApply = () => {
    const data = {
      search: search ? search : "",
      userId: 0,
      status: statusIds === 1 ? true : statusIds === 2 ? false : "",
      page: page,
      pageSize: PAGE_SIZE,
    };
    getInspectionQuestionSetListData(data);
    handleClose();
  };

  const handleClear = () => {
    setSearch("");
    setStatusIds("");
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      prevStates.searchType = [];
      return prevStates;
    });
    const dataClear = {
      userId: 0,
      search: "",
      status: "",
      page: 1,
      pageSize: 10,
    };
    getInspectionQuestionSetListData(dataClear);
    handleClose();
  };

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
        setStatusIds(item?.key);
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };

  const addInspectionForm = () => {
    navigate(ADD_INSPECTION_FORM);
  };

  const columns = [
    {
      field: "value",
      headerName: "Inspection Form",
      flex: 2,
    },
    {
      field: "stateName",
      headerName: "State",
      flex: 1.5,
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox swins">
                {checkPrivileges([12, 93]) && (
                  <FormControlLabel
                    control={
                      <ISwitch
                        checked={rows?.row.status ? true : false}
                        onChange={() => handleChange(rows?.row)}
                        name="status"
                      />
                    }
                  />
                )}

                <div>{rows?.row?.status ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },

    {
      flex: 1,
      field: "isGlobal",
      headerName: "Global",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox swins">
                {checkPrivileges([12, 93]) &&
                  parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
                    <FormControlLabel
                      control={
                        <ISwitch
                          checked={rows?.row.isGlobal ? true : false}
                          onChange={() => handleGlobalChange(rows?.row)}
                          name="status"
                        />
                      }
                    />
                  )}

                <div>{rows?.row?.isGlobal ? "Global" : "N/A"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={count ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={inspectionQuestionSetList ?? []}
          fileNameXL={"inspections Type"}
        />
      ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            edit={
              (checkPrivileges([12, 93, 94]) &&
                parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)) ||
              !rows?.row?.isGlobal
            }
            clone={checkPrivileges([12, 93, 94])}
            editOnClick={(row) => handleEditOnClick(row)}
            editLabel="Edit Inspection Form"
            cloneOnClick={() => handleCloneOnClick(rows?.row)}
            cloneLabel="Clone Inspection Form"
          />
        );
      },
    },
  ];
  useEffect(() => {
    if (
      updateQuestionSetStatus?.status === "SUCCESS" &&
      updateQuestionSetStatus?.isLoading === false
    ) {
      const data = {
        page: page,
        search: search ? search : "",
        userId: 0,
        status: statusIds === 1 ? true : statusIds === 2 ? false : "",
        pageSize: PAGE_SIZE,
      };
      getInspectionQuestionSetListData(data);
    }
  }, [updateQuestionSetStatus]);

  useEffect(() => {
    if (
      cloneSwQuestionSet?.status === "SUCCESS" &&
      cloneSwQuestionSet?.isLoading === false
    ) {
      const data = {
        page: page,
        search: search ? search : "",
        userId: 0,
        status: statusIds === 1 ? true : statusIds === 2 ? false : "",
        pageSize: PAGE_SIZE,
      };
      getInspectionQuestionSetListData(data);
    }
  }, [cloneSwQuestionSet]);

  return (
    <>
      <Modal
        open={cloneOpen}
        onClose={handleCloneClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modalContainer">
          <Box className="modalBox">
            <div className="modalTitle">Clone Inspection Form</div>
            <Grid className="modalInputBox p-0" mt={2} mb={1}>
              <InputField
                type="text"
                isRequired={true}
                value={formValueClone?.inspecionClonetype}
                onChange={(e) =>
                  setFormValueClone({
                    ...formValueClone,
                    inspecionClonetype: e.target.value,
                  })
                }
                label="Clone Inspection Form Name"
                placeholder="Clone Inspection Form Name Here..."
                isError={isCloneInspeciontype}
                errorMsg={
                  isCloneInspeciontype
                    ? "Clone Inspection Form Name is Required"
                    : ""
                }
              />
              {errorsMsgCloneShowActive && (
                <Grid className="errorMsg" mb={1}>
                  {errorsMsgShow}
                </Grid>
              )}
            </Grid>
            <Grid className="modalInputBox p-0" mt={3} mb={4} p={0}>
              <div className="overideInputField">
                <FormControl fullWidth>
                  <FormLabels label="State" isRequired={true} />
                  <SelectMenu
                    listData={state}
                    value={stateId}
                    onchangehandler={(e) => onChangeState(e)}
                    placeholder="Please Select"
                  />
                  {stateNameError && (
                    <Grid className="errorMsg" mb={1}>
                      Selection is required for this field
                    </Grid>
                  )}
                </FormControl>
              </div>
            </Grid>
            <Grid container className="modalFooterBox">
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientText={true}
                  label="Cancel"
                  onClick={handleCloneClose}
                />
              </Grid>
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientContained={true}
                  label={"Save"}
                  disabled={isSubmit ? true : false}
                  onClick={submitCloneData}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Modal>

      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <Grid container item md={12} xs={12} className="">
            <Grid container className="confBox">
              <Grid item md={8} xs={12}>
                <div className="confTitile">Configure Inspection Forms</div>
              </Grid>
              <Grid item md={4} xs={12}>
                {checkPrivileges([12, 93, 94]) && (
                  <Buttons
                    id="btnAddCategory"
                    label={"Add Inspection Form"}
                    varientAddIconBlue={true}
                    onClick={() => addInspectionForm()}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>

              <FilterCommonStatusSelect
                // id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                statusType={STATUS}
                handleApply={handleApply}
                handleClear={handleClear}
                filter={filter}
                onChangeType={onChangeType}
                statusIds={statusIds}
                filterSelect={false}
                filterStatus={true}
                resetBtn={true}
              />
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.key}
                rows={inspectionQuestionSetList ?? []}
                rowCount={count ?? 0}
                columns={columns}
                checkboxSelection={false}
                loading={isLoading}
                page={1}
                pagination
                pageSize={PAGE_SIZE}
                rowsPerPageOptions={[10]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
            <div className="insBackBtn">
              <BackBtn dataBack={dataBack} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InspectionFormsList;
