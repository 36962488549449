import React from "react";
import ReportAnalytics from "../component/reportAnalytics";

const ReportAnalyticsTabContainer = () => {
  
  return (
    <>
      <ReportAnalytics />
    </>
  );
};

export default ReportAnalyticsTabContainer;
