import { lmsUserType } from "../constants/lmsUserType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "lessons",
  isError: false,
  isLoading: false,
};

export const getUserLessonsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsUserType.GET_USER_LESSONS_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsUserType.GET_USER_LESSONS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsUserType.GET_USER_LESSONS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case lmsUserType.CLEAR_LESSONLIST:
      return {
        ...state,
        status: "clearLesson",
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getUserCategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsUserType.GET_USER_CATEGORY_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsUserType.GET_USER_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsUserType.GET_USER_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getUserLessonViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsUserType.GET_USER_LESSON_VIEW:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsUserType.GET_USER_LESSON_VIEW_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsUserType.GET_USER_LESSON_VIEW_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const startLessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsUserType.POST_START_LESSON_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsUserType.POST_START_LESSON_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsUserType.POST_START_LESSON_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
