import React, { useState, useEffect } from "react";
import ImageModal from "../../../../../library/custom/imageModal/imageModal";
import "./viewImages.scss";
import { compressImage } from "../../../../../utils/helper";
import Loader from "../../../../../library/common/Loader";

const ViewImages = ({
  imageSize,
  image,
  singleImage = false,
  singleImagespath,
  multiImage = true,
  singleImagesid,
  showName = true,
}) => {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [compressedImages, setCompressedImages] = useState([]);
  const [originalImages, setOriginalImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = async (imageVal, i) => {
    try {
      setCurrentIndex(i);
      setClickedImg(imageVal);
    } catch (error) {
      console.error("Error handling click:", error);
    }
  };

  const handleRotationRight = () => {
    if (compressedImages.length === 0 || currentIndex === null) return;

    const newIndex = (currentIndex + 1) % compressedImages.length;
    setCurrentIndex(newIndex);
    setClickedImg(originalImages[newIndex]);
  };

  const handleRotationLeft = () => {
    if (compressedImages.length === 0 || currentIndex === null) return;

    const newIndex =
      (currentIndex - 1 + compressedImages.length) % compressedImages.length;
    setCurrentIndex(newIndex);
    setClickedImg(originalImages[newIndex]);
  };

  useEffect(() => {
    const compressAllImages = async () => {
      try {
        setLoading(true);
        const compressed = await Promise.all(
          image.map(async (img) => await compressImage(img))
        );

        const validCompressedImages = compressed.filter((url) => url !== null);
        setCompressedImages(validCompressedImages);
        setOriginalImages(image);
        setLoading(false);
      } catch (error) {
        console.error("Error compressing images:", error);
        setLoading(false);
      }
    };

    if (multiImage && image) {
      compressAllImages();
    }
  }, [image, multiImage]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : multiImage && compressedImages.length > 0 ? (
        <div className="viewImageBox">
          <div
            className={
              imageSize?.size === "small" ? "viewList small" : "viewList large"
            }
          >
            {compressedImages.map((imageVal, i) => {
              const imageNameS = image[i]?.split("/");
              const imageName =
                imageNameS?.length > 0 && imageNameS.slice(-1).pop();
              return (
                <div className="listItem" key={i}>
                  {imageVal && (
                    <>
                      <img
                        onClick={() => handleClick(image[i], i)}
                        src={imageVal}
                        alt={imageName}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                      {showName && <div className="imageName">{imageName}</div>}
                    </>
                  )}
                </div>
              );
            })}

            {clickedImg && (
              <ImageModal
                clickedImg={clickedImg}
                handleRotationRight={handleRotationRight}
                setClickedImg={setClickedImg}
                handleRotationLeft={handleRotationLeft}
              />
            )}
          </div>
        </div>
      ) : null}
      {singleImage && (
        <div className="viewImageBox">
          <div
            className={
              imageSize?.size === "small" ? "viewList small" : "viewList large"
            }
          >
            <div className="listItem">
              <img src={singleImagespath} alt="" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewImages;
