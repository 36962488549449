import React from "react";
import { CONTRACTORMANAGEMENT } from "../../../../constant/constant";
import Card from "../../../../library/custom/cardList/card";
import { Grid } from "@mui/material";

const ContractorManagementList = ({ onClick }) => {
  return (
    <>
      <Grid container className="CardListContaner">
        <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={CONTRACTORMANAGEMENT[0]}
        />

        <Card
          iconShow={false}
          onClick={onClick}
          imgShow={true}
          cardDetail={CONTRACTORMANAGEMENT[1]}
        />
      </Grid>
    </>
  );
};

export default ContractorManagementList;
