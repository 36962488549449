import "./selectMenu.scss";
const SelectIcon = () => {
  return (
    <svg className="selecticonCls" width="50" height="50" viewBox="0 0 45 45">
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#979797"
        strokeWidth="2"
        d="M17 20l5.467 5.467L27.934 20"
      />
    </svg>
  );
};

export default SelectIcon;
