import "./button.scss"

const Button = (props) => (
  <button
    onClick={props.onButtonClickHandler}
    type={props.type}
    className={["button", props.className].join(" ")}
  >
    {props.label}
  </button>

)
Button.defaultProps = {
  className: "",
  type: "button",
  label: "New Button",
  onButtonClickHandler: () => {},
}
export default Button
