import React, { useState } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import {
  ASSET_TYPE_JHA,
  ISwitch,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import NORow from "../../../../library/custom/noRow/noRow";
import SearchIcon from "@mui/icons-material/Search";
import * as jhaAction from "../../../../redux/actions/jhaAction";
import { useDispatch } from "react-redux";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./jha.scss";
import ServiceeFilterList from "./serviceListFilter";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const JHAServiceList = ({
  setupJHA,
  isLoading,
  jhaServiceList,
  itemsCount,
  getJhaSetupList,
  handleStatusChange,
  handleEditOnClick,
  setFormValue,
  formValue,
  frequencyList,
}) => {
  const dataBack = {
    title: "Back to Hazard Analysis Configuration",
    route: -1,
  };

  const dispatch = useDispatch();

  const handleChange = (templateSetItem) => {
    const newCatList = [...jhaServiceList];
    newCatList.forEach((lists) => {
      if (lists.id === templateSetItem.id) {
        lists.statusId = lists.statusId === 1 ? 0 : 1;
        lists.listsmodifiedBy = parseInt(USER_ID());
      }
    });
    handleStatusChange(templateSetItem);
  };

  const columns = [
    { field: "templateName", headerName: "Template Name", flex: 2 },
    { field: "assetName", headerName: "Asset Name", flex: 1 },
    { field: "assetType", headerName: "Asset Type", flex: 1 },
    { field: "frequency", headerName: "Frequency", flex: 1 },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox">
                {checkPrivileges([61, 12]) && (
                  <FormControlLabel
                    control={
                      <ISwitch
                        checked={rows?.row.statusId === 1 ? true : false}
                        onChange={() => handleChange(rows?.row)}
                        name="status"
                      />
                    }
                  />
                )}
                <div>{rows?.row?.statusId === 1 ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 0.5,
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={[]}
          fileNameXL={"Setup List"}
        />
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            editOnClick={() => {
              handleEditOnClick(rows.row);
            }}
            edit={checkPrivileges([61, 62, 12])}
            editLabel="Edit Hazard Analysis Setup"
          />
        );
      },
    },
  ];

  // const [search, setsearch] = useState("");
  const [page, setpage] = useState(1);
  function handleautoSearch(params) {
    // setsearch(params);
    const data = {
      userId: formValue.userId,
      assetType: formValue.assetType,
      frequencyId: formValue.frequencyId,
      statusId: formValue.statusId,
      search: params,
      page: formValue.page,
      pageSize: PAGE_SIZE,
    };
    setFormValue({
      ...formValue,
      search: params,
    });
    getJhaSetupList(data);
  }

  // pagination
  const handlePagignation = (newPage) => {
    setpage(newPage + 1);
    const data = {
      userId: formValue.userId,
      assetType: formValue.assetType,
      frequencyId: formValue.frequencyId,
      statusId: formValue.statusId,
      search: formValue.search,
      page: newPage + 1,
      pageSize: PAGE_SIZE,
    };
    setFormValue({
      ...formValue,
      page: newPage + 1,
    });
    getJhaSetupList(data);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <section className="grid_main_sec">
      <div className="grid_main_header two_grid">
        <div className="grid_heading_style">Hazard Analysis Service List</div>
        <div>
          {checkPrivileges([61, 62, 12]) && (
            <Buttons
              id="btnAddContact"
              label={"Setup Hazard Analysis"}
              varientAddIconBlue={true}
              onClick={() => setupJHA()}
            />
          )}
        </div>
      </div>

      <Grid item container md={12} className="CategoriesBox" mt={3}></Grid>
      <div className="filter_of_caselist">
        <div className="searchcaseid">
          <label htmlFor="Search JHA">
            <span>
              <SearchIcon />
            </span>
            <div>
              <input
                id="search"
                placeholder="Search"
                type="text"
                value={formValue.search}
                className=""
                onChange={(e) => handleautoSearch(e.target.value)}
                autoComplete="off"
              />
            </div>
          </label>
        </div>
        <div className="filter_btn">
          <button
            aria-describedby={id}
            variant="contained"
            type="button"
            name="btn"
            onClick={(e) => handleClick(e)}
          >
            <FilterListIcon id="icn" /> Filters
          </button>

          <ServiceeFilterList
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleApply={() => {
              getJhaSetupList(formValue);
              handleClose();
            }}
            firstLabel="Asset Type"
            secondLabel="Frequency"
            firstLebelFilter={ASSET_TYPE_JHA}
            secondLebelFilter={frequencyList}
            firstLebelValue={formValue.assetType}
            secondLebelValue={formValue.frequencyId}
            firstLebelOnChanges={(e) =>
              setFormValue({
                ...formValue,
                assetType: e.target.value,
              })
            }
            secondLebelOnChanges={(e) =>
              setFormValue({
                ...formValue,
                frequencyId: e.target.value,
              })
            }
            handleClear={() => {
              setFormValue({
                ...formValue,
                assetType: "",
                frequencyId: "",
                statusId: "",
                search: "",
                page: 1,
              });

              getJhaSetupList({
                ...formValue,
                assetType: "",
                frequencyId: "",
                statusId: "",
                search: "",
                page: 1,
              });
              handleClose();
            }}
          />
        </div>
      </div>

      <Grid container>
        <Grid container item md={12} xs={12}>
          <Grid item md={12} className="" mt={3}>
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.id}
                  rows={jhaServiceList ?? []}
                  rowCount={itemsCount ?? 0}
                  columns={columns}
                  loading={isLoading}
                  page={1}
                  pagination
                  pageSize={PAGE_SIZE}
                  rowsPerPageOptions={[PAGE_SIZE]}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div onClick={() => dispatch(jhaAction.clearJha())}>
        <BackBtn dataBack={dataBack} />
      </div>
    </section>
  );
};

export default JHAServiceList;
