import { siteType } from "../../storm-water/types/siteType";
import { API_STATUS } from "../../../../src/constant/constant";

const initialState = {
  flow: "site",
  isError: false,
  isLoading: false,
};

export const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case siteType.POST_SITE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case siteType.POST_SITE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case siteType.POST_SITE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const updateSiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case siteType.PUT_SITE_UPDATE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case siteType.PUT_SITE_UPDATE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case siteType.PUT_SITE_UPDATE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getSiteDetsilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case siteType.GET_SITE_DETAILS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case siteType.GET_SITE_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case siteType.GET_SITE_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case siteType.CLEAR_SWSITE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };

    default:
      return state;
  }
};

export const siteListReducer = (state = initialState, action) => {
  switch (action.type) {
    case siteType.GET_SITE_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case siteType.GET_SITE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case siteType.GET_SITE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const siteListAutoSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case siteType.GET_SW_SITE_AUTO_SEARCH_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case siteType.GET_SW_SITE_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case siteType.GET_SW_SITE_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const getSiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case siteType.GET_SITE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case siteType.GET_SITE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case siteType.GET_SITE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
