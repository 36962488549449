import React, { useEffect } from "react";
import DailyReportList from "../components/dailyReportList";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useLocation } from "react-router-dom";
import { SW_INSPECTION_VIEW } from "../../../../constant/routeContant";
import {
  API_STATUS,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";

const DailyReportListContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const defaultData = {
    search: "",
    inspectionTypeId: 0,
    projectId: 0,
    customerId: parseInt(ORG_ID()),
    statusId: 0,
    startDate: "",
    endDate: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: parseInt(USER_ID()),
    isDailyReport: true,
  };

  const getSwInspectionList = useSelector((state) => state.getSwInspectionList);
  const getSwInspectionListData = (data) => {
    dispatch(swInspectionAction.getSwInspectionList(data));
  };

  const deleteReport = useSelector((state) => state.deleteSwInspection);
  const isLoading = useSelector(
    (state) =>
      state.getSwInspectionList?.isLoading ||
      state.deleteSwInspection?.isLoading
  );

  const viewOnClick = (row) => {
    navigate(SW_INSPECTION_VIEW, {
      state: {
        inspectionId: row?.reportId,
      },
    });
  };

  const onRowClick = (row) => {
    if (row?.value) {
      navigate(SW_INSPECTION_VIEW, {
        state: {
          inspectionId: row?.id,
        },
      });
    }
  };

  const handleDeleteOnClick = (reportId) => {
    dispatch(swInspectionAction.deleteSwInspection(reportId));
  };

  useEffect(() => {
    if (deleteReport?.status === API_STATUS.SUCCESS) {
      getSwInspectionListData(defaultData);
    }
  }, [deleteReport]);

  useEffect(() => {
    getSwInspectionListData(defaultData);
  }, []);

  return (
    <>
      <DailyReportList
        isLoading={isLoading}
        swInspectionList={
          isLoading ? [] : getSwInspectionList?.data?.inspectionReports
        }
        viewOnClick={viewOnClick}
        onRowClick={onRowClick}
        handleDeleteOnClick={handleDeleteOnClick}
        count={getSwInspectionList?.data?.recordsCount}
        getSwInspectionListData={getSwInspectionListData}
      />
    </>
  );
};

export default DailyReportListContainer;
