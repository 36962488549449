import { useEffect, useRef, useState } from "react";
import VerifyMobileImage from "../../assets/verify-mobile-image.png";
import Button from "../../library/button/button";
import Footer from "../../library/footer/footer";
import { maxLengthCheck } from "../../utils/helper";
import { API_STATUS, COUNTRY_CODE } from "../../constant/constant";
import * as resetsignup from "../../redux/actions/signupAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./verify-mobile.scss";
import Loader from "../../library/common/Loader";
import { logos } from "../../assets";

const VerifyMobile = () => {
  const [mobile, setMobile] = useState("");
  const [mobileLength, setMobileLength] = useState("");
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const signupData = useSelector((state) => state.verifyMobile);
  const navigate = useNavigate();

  const errmsg = useSelector((state) => state.verifyMobile.error);
  const [errorMsg, setErrorMsg] = useState("");
  const showLoader = useSelector((state) => state.verifyMobile.isLoading);

  /* Initially everything is invalid */
  let defaultErrorStates = {
    mobile: true,
    mobileLength: true,
  };

  /*
Dynamically update the
form whenever user inputs stuff
*/
  const untouchedForm = useRef(true);
  const isFormValid = useRef(false);

  const [errorList, setErrorList] = useState(defaultErrorStates);

  /* Validate Form */
  const validateForm = () => {
    let newErrorList = {
      ...errorList,
      // mobile: mobile.trim().length !== 0,
      mobileLength: mobile.trim().length >= 10,
    };

    setErrorList({ ...newErrorList });

    /* This validation is not based on state */
    let temporaryValidation = true;
    for (const key in newErrorList) {
      temporaryValidation = temporaryValidation && newErrorList[key];
    }
    /*
Saving it in this ref as well for
  when we don't want to revalidate the
  whole thing
  */
    isFormValid.current = temporaryValidation;
    return temporaryValidation;
  };

  useEffect(() => {
    if (untouchedForm.current) {
      return;
    }
    validateForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobile, mobileLength]);

  useEffect(() => {
    if (errmsg && errmsg.message) {
      setErrorMsg(errmsg.message);
    }
  }, [errmsg]);

  useEffect(() => {
    if (signupData?.error?.code === "LimitExceededException") {
      let newErrorList = {
        ...errorList,
        limitexeeds: true,
      };

      setErrorList({ ...newErrorList });
    } else {
      if (
        signupData?.data_result &&
        mobile &&
        signupData?.status === API_STATUS.SUCCESS
      ) {
        navigate("/reset-password", {
          state: {
            mobile: mobile,
          },
        });
      }
    }
  }, [signupData]);

  /* Next Button */
  const submitButton = async (event) => {
    event.preventDefault();

    /*
  The form is no longer untouched,
  which means we can now do real-time verificaiton
  */
    untouchedForm.current = false;
    if (validateForm()) {
      untouchedForm.current = true;
      dispatch(resetsignup.verifymobile(mobile));
    }
  };

  const phoneFilter = (event, setter) => {
    setErrorMsg("");
    setter(event.target.value.replace(/[^\d]+$/g, ""));
  };
  const eFilter = (event, setter) => {
    if ([69, 187, 188, 189, 190].includes(event.keyCode)) {
      event.preventDefault();
      setter(event.target.value.replace(/[^\d]+$/g, ""));
    }
  };
  return (
    <div className="verify-mobile">
      <div className="verify-mobile-container">
        {/* left */}
        <div className="verify-mobile-left">
          <div className="logoDark">
            <img className="logo" src={logos?.logoWhite} alt="logo" />
          </div>
          <div className="logoLight">
            <img className="logo" src={logos?.logo} alt="logo" />
          </div>
          <h1 className="title">Reset Password </h1>
          <p>
            Please enter your registered mobile number to reset your password
          </p>
          <form onSubmit={submitButton}>
            <div
              className={[
                "form-wrapper",
                errorList.mobile ? null : "has-error",
              ].join(" ")}
            >
              <h1>Mobile Number</h1>
              <div className="initial" data-placeholder={COUNTRY_CODE}>
                <input
                  className={[
                    errorList.mobile
                      ? "input-control"
                      : "input-control border-red",
                  ]}
                  type="mobile"
                  placeholder=""
                  value={mobile}
                  maxLength="10"
                  onInput={maxLengthCheck}
                  onChange={(e) => phoneFilter(e, setMobile)}
                  onKeyDown={(e) => eFilter(e, setMobile)}
                />
              </div>
              {errorList.mobile ? null : (
                <div className="show-error">
                  Please enter your registered mobile number
                </div>
              )}
              {errorList.limitexeeds && errorList.limitexeeds === true && (
                <div className="show-error">
                  Attempt limit exceeded, please try after some time
                </div>
              )}
              {errorMsg ? <div className="show-error">{errorMsg}</div> : <></>}

              {errorList.mobileLength ? null : (
                <div className="show-error">
                  Please enter a valid mobile number
                </div>
              )}
            </div>
            <div className="verify-mobile-button">
              {showLoader ? (
                <Loader />
              ) : (
                <Button
                  label="Send Reset Password Code"
                  onButtonClickHandler={submitButton}
                />
              )}
            </div>
          </form>
        </div>
        <div className="verify-mobile-right">
          <img src={VerifyMobileImage} alt="welcome" />
        </div>
      </div>
      <Footer fixed="fixed" />
    </div>
  );
};

export default VerifyMobile;
