import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";

import { post, get } from "./axiosRequest";
export const createCase = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrl}/createcase`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateCase = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrl}/updatecase`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCaseList = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrl}/getcaselist?orgId=${ORG_ID()}`;
  try {
    data.orgId = ORG_ID();
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
