import React from "react";
import { Grid, Box } from "@mui/material";
import { InputField } from "../../../library/custom/textBox/InputField";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import DatePicker from "../../../library/custom/datePicker/DatePicker";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import { NumberField } from "../../../library/custom/numberField/numberField";
import "../styles/property.scss";
import Buttons from "../../../library/custom/button/Button";
import intl from "react-intl-universal";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
import FormDropZone from "../../../library/dropZone/FormDropZoneIncident";
export default function Property(props) {
  const {
    keyNo,
    estimatedCost,
    propertyDescription,
    dateOfDiscovery,
    stolenlostproperty,
    propertyType,
    policeReport,
    propertyOnChanges,
    isErrorPropertyDescription,
    isPropertyDescriptionMsg,
    cancelPropertyList,
    files,
    id,
    deleteImageURL,
    stolePropertyUpload,
    filess,
    dateOfDiscoveryError,
  } = props;

  return (
    <>
      <div className="overideProperty">
        <div className="propertyBox">
          <h1 className="title">{`Property ${keyNo}`}</h1>
          <Grid item container>
            <Grid item lg={10} xs={12} sm={12} md={10}>
              <FormControl fullWidth>
                <FormLabels
                  label={intl.get(
                    "incident.stolenProperty.descriptionOfProperty"
                  )}
                  isRequired={true}
                />
                <TextEditor
                  placeholder={"Add Description of Property Text Here..."}
                  id="propertyDescription"
                  value={propertyDescription}
                  onChange={(e) => propertyOnChanges(e, "propertyDescription")}
                  isError={isErrorPropertyDescription}
                  errorMsg={isPropertyDescriptionMsg}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container mt={2} spacing={3}>
            <Grid item lg={5} xs={12} sm={12} md={5}>
              <NumberField
                type="text"
                isRequired={false}
                label={intl.get(
                  "incident.stolenProperty.estimateCostOfProperty"
                )}
                placeholder="Add Estimated Cost of Property Number Here.."
                id="estimatedCost"
                value={estimatedCost}
                onChange={(e) => propertyOnChanges(e, "estimatedCost")}
              />
            </Grid>

            <Grid item lg={5} xs={12} sm={12} md={5}>
              <FormLabels
                label={intl.get("incident.stolenProperty.dateOfdiscovery")}
                isRequired={true}
              />
              <DatePicker
                id="dateOfDiscovery"
                value={dateOfDiscovery}
                onChangeHandler={(e) => propertyOnChanges(e, "datePicker")}
                isError={dateOfDiscoveryError}
                errorMsg="Please select this required field"
              />
            </Grid>
          </Grid>

          <Grid item container mt={2} spacing={3}>
            <Grid item lg={5} xs={12} sm={12} md={5}>
              <FormControl fullWidth>
                <FormLabels
                  label={intl.get(
                    "incident.stolenProperty.wasPropertyLostFromLockedPlace"
                  )}
                />
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="radioGroup"
                >
                  <RadioButton
                    checked={propertyType === true}
                    value={true}
                    label="Yes"
                    id="propertyTypeYes"
                    onChange={(e) => propertyOnChanges(e)}
                  ></RadioButton>
                  <RadioButton
                    checked={propertyType === false}
                    value={false}
                    label="No"
                    id="propertyTypeNo"
                    onChange={(e) => propertyOnChanges(e)}
                  ></RadioButton>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item lg={5} xs={12} sm={12} md={5}>
              <InputField
                fullWidth={true}
                type="text"
                label={intl.get("incident.stolenProperty.nameOfLastPerson")}
                placeholder="Add Name of last known Person(s) in 
                Custody of the Stolen/Lost Property Text Here..."
                id="stolenlostproperty"
                value={stolenlostproperty}
                onChange={(e) => propertyOnChanges(e)}
              />
            </Grid>
          </Grid>

          <Grid item container mt={2} spacing={3}>
            <Grid item lg={5} xs={12} sm={12} md={5}>
              <FormControl fullWidth>
                <FormLabels
                  label={intl.get(
                    "incident.stolenProperty.hasPoliceReportFilled"
                  )}
                />
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="radioGroup"
                >
                  <RadioButton
                    checked={policeReport === true}
                    value={true}
                    label="Yes"
                    id="policeReportYes"
                    onChange={(e) => propertyOnChanges(e)}
                  ></RadioButton>
                  <RadioButton
                    checked={policeReport === false}
                    value={false}
                    label="No"
                    id="policeReportNo"
                    onChange={(e) => propertyOnChanges(e)}
                  ></RadioButton>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid lg={10} xs={12} sm={12} md={10} mt={2}>
              <FormDropZone
                key={id}
                label={intl.get("incident.stolenProperty.addPhotos")}
                id="uploadMedia"
                paraText1={"Drop the files here ..."}
                paraText2={"Drop Files here, "}
                paraText3={"or Browse"}
                deleteImageURL={deleteImageURL}
                handleFileChange={(photo, fileIds) =>
                  stolePropertyUpload(photo, fileIds)
                }
                filess={filess}
                imageUrl={files}
                fileType={10}
                isDoc={true}
                isImage={false}
                allFiles={true}
                isDynamics={true}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid item container mt={3}>
        <Grid lg={8} xs={12} sm={12} md={12}>
          <Box mb={2} textAlign="right">
            <Buttons
              variantOutline={true}
              onClick={() => cancelPropertyList(id)}
              label={intl.get("incident.stolenProperty.cancel")}
            ></Buttons>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
