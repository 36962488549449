import React from "react";
import "./injuredPart.scss";


const InjuredPart = ({bodyPartInj}) => {

    return (        
        <>
            <div className="bodyInjured">
                
<svg width="146" height="405" viewBox="0 0 146 405" fill="none" xmlns="http://www.w3.org/2000/svg">

<path d="M72.8015 213.749C72.3956 214.005 72.2603 214.391 72.2603 214.648C72.5309 214.648 72.6662 214.648 72.9369 214.648C72.9369 214.391 72.9368 214.134 72.8015 213.749Z" className="unselect" />

<path d="M30.5813 129.117C31.6639 129.117 32.8817 129.117 33.9643 129.117C33.6936 127.062 33.2877 125.008 33.017 123.081C32.2051 125.008 31.3932 127.062 30.5813 129.117Z" className="unselect" />

<path d="M112.315 121.926C112.044 121.926 111.774 121.926 111.503 121.926C111.368 124.366 111.097 126.677 110.962 129.117C112.315 129.117 113.668 129.117 114.886 129.117C114.21 127.191 113.533 125.393 112.992 123.467C112.721 122.953 112.586 122.439 112.315 121.926Z" className="unselect" />

<path d="M73.4794 220.812C74.968 238.663 76.3212 256.642 77.945 274.493C87.688 274.493 97.431 274.493 106.633 274.493C107.174 269.741 108.121 264.99 109.204 260.238C112.587 245.341 115.158 230.058 114.481 214.776C110.286 214.776 92.1535 214.776 73.0735 214.776C73.0735 216.702 73.3441 218.757 73.4794 220.812Z" className="unselect" />

<path id="right_leg" d="M81.3271 301.59C78.7561 312.378 77.5382 323.294 79.162 334.338C81.1918 348.08 80.9212 361.821 79.8386 375.562C84.4395 375.562 89.0403 375.562 93.6412 375.562C94.1825 372.095 95.4004 368.756 96.6182 365.417C99.7306 356.684 102.978 347.951 105.549 339.09C108.526 328.559 110.15 317.9 108.797 306.984C108.526 305.058 108.12 303.131 107.579 301.333C99.1893 301.333 90.2582 301.333 81.3271 301.333C81.4624 301.333 81.4624 301.462 81.3271 301.59Z" className={bodyPartInj?.includes("Leg")? 'selected': 'unselect'} />

<path id="right_knee" d="M78.2135 277.832C78.4842 281.428 79.4314 285.023 80.6493 288.619C82.0025 292.857 82.4084 296.967 81.4612 301.205C90.3923 301.205 99.3234 301.205 107.713 301.205C107.443 300.306 107.307 299.407 107.172 298.636C106.631 295.683 105.819 292.857 105.683 289.904C105.548 284.767 105.954 279.63 106.631 274.621C97.4289 274.621 87.8212 274.621 77.9429 274.621C77.9429 275.648 78.0782 276.804 78.2135 277.832Z" className={bodyPartInj?.includes("Knee")? 'selected': 'unselect'} />

<path id="left_leg" d="M64.9527 344.227C65.0881 340.631 65.6293 337.035 66.1706 333.439C67.6591 322.652 66.4412 312.121 63.8702 301.719C63.8702 301.462 63.7349 301.333 63.7349 301.076C54.8038 301.076 45.8727 301.076 37.3475 301.076C37.2122 301.847 36.9416 302.618 36.8063 303.26C34.7765 313.79 36.265 324.193 38.4301 334.595C40.7305 345.126 44.2488 355.4 48.4437 365.288C49.7969 368.627 51.0148 371.966 51.5561 375.434C56.0216 375.434 60.6225 375.434 65.0881 375.434C64.4115 365.16 64.8174 354.629 64.9527 344.227Z" className={bodyPartInj?.includes("Leg")? 'selected': 'unselect'} />

<path id="right_toe" d="M79.7027 377.745C79.432 379.158 76.5903 395.982 76.5903 399.064C76.5903 402.146 78.0788 404.072 80.6499 404.072C83.3563 404.072 106.361 404.072 106.361 404.072C106.361 404.072 108.661 403.302 107.173 399.449C105.684 395.725 95.3998 388.79 95.1291 385.579C94.8585 382.369 93.2346 378.131 93.2346 378.131C93.2346 377.232 93.37 376.461 93.5053 375.562C89.0397 375.562 84.4389 375.562 79.7027 375.562C79.838 376.333 79.7027 377.103 79.7027 377.745Z" className={bodyPartInj?.includes("Toe")? 'selected': 'unselect'} />

<path id="right_finger" d="M131.394 196.796C131.8 197.824 131.8 198.851 131.8 199.879C131.259 200.264 129.5 201.933 128.959 204.502C128.282 207.456 129.094 212.593 126.929 214.776C126.929 214.776 127.606 216.702 129.771 215.418C132.071 214.134 132.612 210.281 134.642 210.281C136.672 210.152 137.89 211.95 138.972 215.932C139.514 217.986 139.514 219.913 139.243 221.454C136.943 222.61 133.289 224.664 132.612 226.719C132.612 226.719 132.612 227.747 133.56 227.49C134.236 227.233 136.807 226.334 138.16 225.82C137.349 227.875 133.56 230.7 132.883 233.012C132.206 235.323 134.777 234.039 136.401 233.012C138.025 231.984 141.138 228.26 141.138 228.26C141.138 228.26 143.844 223.765 144.52 220.555C145.197 217.344 145.468 205.786 145.468 205.786L145.332 200.007H145.468C145.468 199.493 145.468 199.108 145.468 198.594C145.468 197.952 145.468 197.31 145.468 196.54C140.732 196.54 135.86 196.54 131.394 196.54C131.394 196.54 131.394 196.668 131.394 196.796Z" className={bodyPartInj?.includes("Finger")? 'selected': 'unselect'} />

<path id="left_toe" d="M52.0987 378.131C51.9634 378.131 50.3396 382.369 50.0689 385.579C49.7983 388.79 39.514 395.725 38.0255 399.449C36.537 403.173 38.8374 404.072 38.8374 404.072C38.8374 404.072 61.977 404.072 64.5481 404.072C67.1192 404.072 68.6077 402.146 68.6077 399.064C68.6077 395.982 65.4954 377.745 65.4954 377.745C65.36 376.975 65.36 376.333 65.36 375.562C60.7592 375.562 56.2936 375.562 51.8281 375.562C51.9634 376.333 51.9634 377.232 52.0987 378.131Z" className={bodyPartInj?.includes("Toe")? 'selected': 'unselect'} />

<path d="M67.5245 273.08C69.1483 253.688 70.6368 234.296 72.2607 214.904V214.776C56.4283 214.776 40.0546 214.776 30.8529 214.776C30.1763 230.058 33.018 245.084 36.1303 260.109C37.0776 264.99 38.0248 269.741 38.5661 274.621C47.9031 274.621 57.6462 274.621 67.3892 274.621C67.5245 274.108 67.5245 273.594 67.5245 273.08Z" className="unselect" />

<path id="left_knee" d="M64.9528 288.362C66.9826 283.868 67.1179 279.244 67.5238 274.493C57.7808 274.493 48.0378 274.493 38.7008 274.493C39.5127 280.4 39.7833 286.179 39.5127 292.215C39.3774 295.169 38.2948 298.123 37.4829 301.205C46.008 301.205 54.9391 301.205 63.8702 301.205C62.923 296.71 62.923 292.472 64.9528 288.362Z" className={bodyPartInj?.includes("Knee")? 'selected': 'unselect'} />

<path id="eye" d="M86.6051 47.9533C87.6876 46.0269 88.7702 44.229 89.7174 42.1742C90.5293 40.3763 91.3412 38.5783 91.8825 36.7804C97.2953 35.8814 98.5132 27.277 95.9421 24.837C94.4536 23.5528 93.2357 23.8096 92.4238 24.4517C92.5591 24.0665 92.6944 23.5528 92.8298 23.0391C81.3276 23.0391 66.3071 23.0391 53.5871 23.0391C53.7224 23.5528 53.8577 23.938 53.993 24.3233C53.1811 23.8096 52.0986 23.6812 50.7454 24.837C48.1743 27.1486 49.3922 35.6246 54.6696 36.652C55.6169 39.3489 56.5641 42.1742 57.6467 44.8711C58.0526 46.0269 58.9999 46.9259 59.9471 47.9533C68.8782 47.9533 78.3506 47.9533 86.7404 47.9533C86.6051 47.9533 86.6051 47.9533 86.6051 47.9533Z" className={bodyPartInj?.includes("Eye")? 'selected': 'unselect'} />

<path id="torso" d="M129.906 84.169C126.659 74.7941 120.705 68.116 110.015 66.3181C107.714 65.9328 105.549 64.777 103.519 63.6212C102.031 62.7222 100.542 61.8233 99.0536 60.9243C85.3863 60.9243 62.5173 60.9243 46.5496 60.9243C45.7377 61.438 45.0611 61.8233 44.2492 62.337C40.5956 64.5202 36.942 66.0613 32.747 66.9602C25.7104 68.5013 20.9742 72.9961 17.7266 79.0321C15.5615 83.1416 14.3436 87.508 13.667 91.8744C48.9854 91.8744 98.5123 91.8744 131.936 91.8744C131.395 89.3059 130.854 86.7375 129.906 84.169Z" className={bodyPartInj?.includes("Torso")? 'selected': 'unselect'} />

<path id="Neck" d="M90.3927 55.9159C87.6863 54.3748 86.7391 52.0632 85.9272 49.4947C85.7919 49.1094 86.1978 48.5957 86.4685 48.082C78.0787 48.082 68.6063 48.082 59.6752 48.082C59.9458 48.3389 60.2165 48.7241 60.3518 48.981C59.5399 52.5769 57.3748 55.1453 53.7211 56.8148C51.1501 58.0991 48.7143 59.5117 46.2786 60.9244C62.3816 60.9244 85.1153 60.9244 98.7825 60.9244C96.0761 59.2549 93.3698 57.457 90.3927 55.9159Z" className={bodyPartInj?.includes("Neck")? 'selected': 'unselect'} />

<path id="back" d="M34.7758 181.257C33.8285 189.091 33.0166 197.053 31.9341 204.887C31.3928 208.226 31.1221 211.437 30.9868 214.776C40.1885 214.776 56.4269 214.776 72.3946 214.776C72.3946 214.519 72.5299 214.134 72.9359 213.877C72.9359 214.134 72.9359 214.519 73.0712 214.776C92.1512 214.776 110.419 214.776 114.479 214.776C114.479 213.62 114.344 212.593 114.344 211.437C113.532 201.291 111.908 191.146 110.419 181.129C96.2108 181.129 54.9384 181.129 34.9111 181.129C34.7758 181.129 34.7758 181.257 34.7758 181.257Z" className={bodyPartInj?.includes("Back")? 'selected': 'unselect'} />

<path id="right_arm" d="M143.71 165.204C143.304 159.168 141.951 153.261 139.921 147.482C132.208 147.482 124.63 147.482 119.488 147.482C119.623 152.747 119.894 158.141 121.112 163.278C123.953 174.451 127.742 185.495 131.261 196.54C135.726 196.54 140.598 196.54 145.334 196.54C145.334 195.512 145.199 194.485 145.199 193.458C144.657 183.954 144.251 174.579 143.71 165.204Z" className={bodyPartInj?.includes("Arm")? 'selected': 'unselect'} />

<path d="M138.973 145.042C138.702 144.143 138.432 143.244 138.432 142.345C138.838 137.85 138.702 133.484 138.297 129.246C131.26 129.246 123.411 129.246 114.886 129.246C116.104 132.713 117.322 136.052 118.54 139.519C119.352 141.703 119.352 143.757 119.487 146.069C119.487 146.583 119.487 147.097 119.487 147.482C124.629 147.482 132.207 147.482 139.92 147.482C139.65 146.583 139.379 145.812 138.973 145.042Z" className="unselect" />

<path id="torso" d="M133.29 107.799C132.749 106.515 132.613 105.102 132.613 103.689C132.613 99.7081 132.343 95.727 131.802 91.8743C98.3776 91.8743 48.8507 91.8743 13.5323 91.8743C12.991 95.0849 12.8557 98.2954 12.585 101.634C12.4497 104.331 12.0438 107.028 11.2318 109.597C9.06672 116.018 7.57821 122.439 6.90161 129.117C14.0735 129.117 22.0574 129.117 30.4472 129.117C31.2591 127.062 32.071 125.008 33.0183 123.081C33.2889 125.136 33.6949 127.191 33.9655 129.117C58.729 129.117 86.8755 129.117 110.962 129.117C111.098 126.677 111.368 124.365 111.504 121.925C111.774 121.925 112.045 121.925 112.316 121.925C112.586 122.439 112.721 122.953 112.992 123.466C113.669 125.264 114.345 127.191 114.887 129.117C123.412 129.117 131.396 129.117 138.297 129.117C137.62 121.925 135.861 114.862 133.29 107.799Z" className={bodyPartInj?.includes("Torso")? 'selected': 'unselect'} />

<path id="Head" d="M91.8826 9.81164C89.3115 2.87677 79.8391 -0.462249 71.9906 0.0514458C64.0067 0.56514 62.2476 3.39046 62.2476 3.39046C62.2476 3.39046 58.3233 3.26204 56.1582 6.08736C54.1284 8.91268 52.7752 11.3527 52.3693 15.976C52.2339 18.2876 52.7752 20.9845 53.4518 23.1677C66.1719 23.1677 81.1923 23.1677 92.6945 23.1677C93.5064 20.0855 94.183 15.7191 91.8826 9.81164Z" className={bodyPartInj?.includes("Head")? 'selected': 'unselect'} />

<path id="left_finger" d="M0.13532 200.007C0.13532 200.135 0.13532 200.264 0.13532 200.392L0 206.3C0 206.3 0.13532 217.858 0.947237 221.068C1.75915 224.279 4.33023 228.774 4.33023 228.774C4.33023 228.774 7.44257 232.498 9.06641 233.526C10.6902 234.553 13.2613 235.837 12.5847 233.526C11.9081 231.342 8.11917 228.389 7.30726 226.334C8.66045 226.848 11.3668 227.747 11.9081 228.003C12.8554 228.389 12.8554 227.233 12.8554 227.233C12.1788 225.178 8.52513 223.123 6.2247 221.967C5.95406 220.298 5.95406 218.372 6.49534 216.445C7.57789 212.464 8.79577 210.666 10.8256 210.795C12.8554 210.923 13.3966 214.647 15.6971 216.06C17.9975 217.344 18.5388 215.418 18.5388 215.418C16.3737 213.363 17.1856 208.226 16.509 205.144C15.8324 202.19 13.532 200.392 13.532 200.392C13.532 199.236 13.6673 198.081 14.0732 197.053C14.0732 196.925 14.2086 196.668 14.2086 196.54C9.87833 196.54 5.00682 196.54 0.13532 196.54C0 197.567 0.13532 198.851 0.13532 200.007Z" className={bodyPartInj?.includes("Finger")? 'selected': 'unselect'} />

<path id="back" d="M34.7782 136.052C35.4548 144.656 36.4021 153.261 36.5374 161.865C36.5374 168.286 35.4548 174.708 34.7782 181C54.9408 181 96.078 181 110.287 181C109.881 178.303 109.61 175.735 109.339 173.038C108.663 167.387 108.663 161.737 108.933 156.086C109.475 147.097 110.287 137.978 110.963 128.989C86.8763 128.989 58.7298 128.989 33.9663 128.989C34.3723 131.429 34.6429 133.74 34.7782 136.052Z" className={bodyPartInj?.includes("Back")? 'selected': 'unselect'} />

<path id="left_arm" d="M1.21788 176.249C1.3532 182.413 0.405959 188.577 0 194.87C0 195.384 0 196.026 0 196.54C4.8715 196.54 9.87833 196.54 14.0732 196.54C18.5388 183.954 23.0043 171.497 25.0341 158.398C25.5754 154.802 25.4401 151.206 25.4401 147.482C21.5158 147.482 13.8026 147.482 5.27746 147.482C2.30043 156.728 1.08256 166.36 1.21788 176.249Z" className={bodyPartInj?.includes("Arm")? 'selected': 'unselect'} />

<path d="M6.76757 142.345C6.90289 143.115 6.63225 144.014 6.22629 144.785C5.95565 145.684 5.68501 146.454 5.27905 147.353C13.8042 147.353 21.5174 147.353 25.4417 147.353C25.4417 146.198 25.4417 144.913 25.4417 143.757C25.4417 143.244 25.4417 142.73 25.577 142.345C27.2008 137.978 28.8247 133.484 30.4485 129.117C22.0587 129.117 14.0748 129.117 6.90289 129.117C6.49693 133.484 6.36161 137.85 6.76757 142.345Z" className="unselect" />

</svg>

            </div>
        </>
    )
}

export default InjuredPart;