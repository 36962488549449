import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import Buttons from "../../../library/custom/button/Button";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { Grid } from "@mui/material";
import Cards from "./Cards";
import { CSVLink } from "react-csv";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import {
  FILTERDAYS,
  LNG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  USER_ID,
} from "../../../constant/constant";
import DatePicker from "../../../library/custom/datePicker/DatePicker";

import {
  getCurrentDate,
  getDateWithDayPast,
  getFormatedDate,
} from "../../../utils/helper";
import DonoughtChart from "../../support/component/ticket/charts";
import { Settings } from "@mui/icons-material";

function ReportTrainingSessions({
  getLmsReportData,
  lmsReportData,
  lmsReportLesson,
  lmsReportCourse,
  getLmsReportLessonData,
  getLmsReportCourseData,
}) {
  const componentRef = useRef(null);
  const [days, setDays] = useState({ key: 30, value: "30 days" });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cardSection, setCardSection] = useState(1);
  const [lessonTab, setTabLesson] = useState();
  const [courseTab, setCourseTab] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lessonList, setLessonList] = useState();
  const [courseList, setCourseList] = useState();

  useEffect(() => {
    if (lmsReportLesson) {
      setLessonList(lmsReportLesson);
    }
  }, [lmsReportLesson]);
  useEffect(() => {
    if (lmsReportCourse) {
      setCourseList(lmsReportCourse);
    }
  }, [lmsReportCourse]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleChartClick = (id) => {
    setCardSection(id);
    setCourseList();
    setLessonList();
    setTabLesson();
    setCourseTab();
  };
  const cardOnClick = (value) => {
    let dateObj = getDateWithDayPast(value.key);
    if (days.key === value.key) {
      const defaultData = {
        isAssignedSelected: true,
        isCompletedSelected: true,
        isNotCompletedSelected: true,
        isInProgressSelected: true,
        isOntimeSelected: true,
        isNotOntimeSelected: true,
        fromDate: dateObj.pastData,
        toDate: dateObj.getCurrentDate,
        // userId: parseInt(USER_ID()),
        orgId: parseInt(ORG_ID()),
        lngId: parseInt(LNG_ID),
      };
      getLmsReportData(defaultData);
      setDays({ key: 30, value: "30 days" });
      setStartDate(dateObj.pastData);
      setEndDate(dateObj.getCurrentDate);
    } else {
      if (value.key !== 12) {
        let dateObj = getDateWithDayPast(value.key);
        const defaultData = {
          isAssignedSelected: true,
          isCompletedSelected: true,
          isNotCompletedSelected: true,
          isInProgressSelected: true,
          isOntimeSelected: true,
          isNotOntimeSelected: true,
          fromDate: dateObj.pastData,
          toDate: dateObj.getCurrentDate,
          // userId: parseInt(USER_ID()),
          orgId: parseInt(ORG_ID()),
          lngId: parseInt(LNG_ID),
        };
        getLmsReportData(defaultData);
        setStartDate(dateObj.pastData);
        setEndDate(dateObj.getCurrentDate);
      }
      if (value.key === 13) {
        let dateObj = getDateWithDayPast(30);
        const defaultData = {
          isAssignedSelected: true,
          isCompletedSelected: true,
          isNotCompletedSelected: true,
          isInProgressSelected: true,
          isOntimeSelected: true,
          isNotOntimeSelected: true,
          fromDate: dateObj.pastData,
          toDate: dateObj.getCurrentDate,
          // userId: parseInt(USER_ID()),
          orgId: parseInt(ORG_ID()),
          lngId: parseInt(LNG_ID),
        };
        getLmsReportData(defaultData);
        setStartDate(dateObj.pastData);
        setEndDate(dateObj.getCurrentDate);
      }

      setDays(days.key === 13 ? { key: 30, value: "30 days" } : value);
    }
  };

  const onchangeEnddatePicker = (e) => {
    const defaultData = {
      isAssignedSelected: true,
      isCompletedSelected: true,
      isNotCompletedSelected: true,
      isInProgressSelected: true,
      isOntimeSelected: true,
      isNotOntimeSelected: true,
      fromDate: getFormatedDate(
        startDate === "" ? getCurrentDate() : startDate
      ),
      toDate: getFormatedDate(e),
      // userId: parseInt(USER_ID()),
      orgId: parseInt(ORG_ID()),
      lngId: parseInt(LNG_ID),
    };
    setEndDate(e);
    getLmsReportData(defaultData);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Credential Report",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
  });

  useEffect(() => {
    let dateObj = getDateWithDayPast(30);
    const defaultData = {
      isAssignedSelected: true,
      isCompletedSelected: true,
      isNotCompletedSelected: true,
      isInProgressSelected: true,
      isOntimeSelected: true,
      isNotOntimeSelected: true,
      fromDate: dateObj.pastData,
      toDate: dateObj.getCurrentDate,
      // userId: parseInt(USER_ID()),
      orgId: parseInt(ORG_ID()),
      lngId: parseInt(LNG_ID),
    };
    getLmsReportData(defaultData);
    setStartDate(dateObj.pastData);
    setEndDate(dateObj.getCurrentDate);
  }, []);

  const lessonChart = {
    Completed: lmsReportData?.totalLessonCompleted,
    InProgress: lmsReportData?.totalLessonInProgress,
    InCompleted: lmsReportData?.totalLessonNotCompleted,
  };
  const courseChart = {
    Completed: lmsReportData?.totalCourseCompleted,
    InProgress: lmsReportData?.totalCourseInProgress,
    InCompleted: lmsReportData?.totalCourseNotCompleted,
  };

  const getCourse = (coursesId) => {
    setCourseTab(coursesId);
    setTabLesson();
    const defaultData = {
      isAssignedSelected: coursesId === 1 ? true : false,
      isCompletedSelected: coursesId === 2 ? true : false,
      isNotCompletedSelected: coursesId === 3 ? true : false,
      isInProgressSelected: coursesId === 4 ? true : false,
      isOntimeSelected: coursesId === 5 ? true : false,
      isNotOntimeSelected: coursesId === 6 ? true : false,
      fromDate: startDate,
      toDate: endDate,
      // userId: parseInt(USER_ID()),
      orgId: parseInt(ORG_ID()),
      lngId: parseInt(LNG_ID),
    };
    getLmsReportCourseData(defaultData);
  };

  const getLesson = (lessonId) => {
    setTabLesson(lessonId);
    setCourseTab();
    const defaultData = {
      isAssignedSelected: lessonId === 1 ? true : false,
      isCompletedSelected: lessonId === 2 ? true : false,
      isNotCompletedSelected: lessonId === 3 ? true : false,
      isInProgressSelected: lessonId === 4 ? true : false,
      isOntimeSelected: lessonId === 5 ? true : false,
      isNotOntimeSelected: lessonId === 6 ? true : false,
      fromDate: startDate,
      toDate: endDate,
      // userId: parseInt(USER_ID()),
      orgId: parseInt(ORG_ID()),
      lngId: parseInt(LNG_ID),
    };
    getLmsReportLessonData(defaultData);
  };

  useEffect(() => {
    if (lessonTab) {
      getLesson(lessonTab);
    }
    if (courseTab) {
      getCourse(courseTab);
    }
  }, [days]);

  const isClient = ORG_ID() !== LOGIN_ORG_ID();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clientLogo = localStorage.getItem("orgLogo");
  const clientName = localStorage.getItem("orgName");
  const logoCheck = isClient ? clientLogo : userInfo?.logo;

  return (
    <>
      <div ref={componentRef} className="lmsReport innerReportAndAnalyticsPdf">
        <section className="grid_main_sec">
          <Grid container className="grid_main_header two_grid">
            <Grid item className="grid_heading_style repotPdfHeading" lg={7}>
              Learning Management System Reports
            </Grid>
            <Grid item md={12} className="clientPdfDetail">
              <Grid className="clientName">
                {isClient ? clientName : userInfo?.clientName}
              </Grid>
              <Grid className="clientLogo">
                {logoCheck && <img src={logoCheck} alt="client Log" />}
              </Grid>
            </Grid>
            <Grid item lg={5} md={6} className="filter_btn">
              <Grid>
                <Buttons
                  primary={true}
                  id="btnAddClient"
                  label={"Download Report"}
                  onClick={handlePrint}
                ></Buttons>
              </Grid>
            </Grid>
          </Grid>
        </section>

        <Grid container mt={2} item lg={12} xs={12} sm={12} md={8} className="removeElement">
          <Grid item lg={12}>
            <FormLabels label={"Select Time Period"} />
          </Grid>
          <Cards
            staticCardData={true}
            cardData={FILTERDAYS}
            selectedVehicleAccident={days}
            cardClickHandler={cardOnClick}
          />
        </Grid>
        {days?.value === "Custom" ? (
          <>
            <Grid item container mt={1} spacing={1} className="removeElement">
              <Grid item lg={4} xs={12} sm={12}>
                <FormLabels label={"Custom Time Period"} isRequired={false} />
              </Grid>
            </Grid>

            <Grid item container spacing={2} mb={2} className="removeElement">
              <Grid item lg={3} xs={12} sm={12} md={4}>
                <FormLabels label={"From"} isRequired={false} />
                <DatePicker
                  maxDateEnable={false}
                  value={startDate === "" ? getCurrentDate() : startDate}
                  disabled={false}
                  onChangeHandler={(e) => setStartDate(e)}
                />
              </Grid>
              <Grid item lg={3} xs={12} sm={12} md={4}>
                <FormLabels label={"To"} isRequired={false} />

                <DatePicker
                  maxDateEnable={false}
                  minDate={startDate}
                  value={endDate === "" ? getCurrentDate() : endDate}
                  disabled={false}
                  onChangeHandler={(e) => onchangeEnddatePicker(e)}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        <Grid container lg={12} className="reportChart">
          <Grid lg={5} item>
            <Grid item container lg={12}>
              <div
                className={
                  cardSection === 1
                    ? "pieGraphCardBody active"
                    : "pieGraphCardBody"
                }
                onClick={() => handleChartClick(1)}
              >
                <div className="assignedDiv1">
                  <p className="heading">Total Lessons</p>
                  <h1 className="addedNumber">{lmsReportData?.totalLesson}</h1>
                </div>
                <DonoughtChart PieChartReport lmsReportData={lessonChart} />
              </div>
            </Grid>
          </Grid>

          <Grid lg={5} item>
            <Grid item container lg={12}>
              <div
                className={
                  cardSection === 2
                    ? "pieGraphCardBody active"
                    : "pieGraphCardBody"
                }
                onClick={() => handleChartClick(2)}
              >
                <div className="assignedDiv1">
                  <p className="heading">Total Courses</p>
                  <h1 className="addedNumber">{lmsReportData?.totalCourse}</h1>
                </div>
                <DonoughtChart PieChartReport lmsReportData={courseChart} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        {cardSection === 1 && (
          <>
            <div className="reportBody">
              <div
                className={
                  lessonTab === 1 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getLesson(1)}
              >
                <div className="text">Total Lessons Assigned</div>
                <div className="number">
                  {lmsReportData?.totalLessonAssigned}
                </div>
              </div>
              <div
                className={
                  lessonTab === 2 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getLesson(2)}
              >
                <div className="text">Completed</div>
                <div className="number">
                  {lmsReportData?.totalLessonCompleted}
                </div>
              </div>
              <div
                className={
                  lessonTab === 3 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getLesson(3)}
              >
                <div className="text">Not Completed</div>
                <div className="number">
                  {lmsReportData?.totalLessonNotCompleted}
                </div>
              </div>
              <div
                className={
                  lessonTab === 4 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getLesson(4)}
              >
                <div className="text">In Progress</div>

                <div className="number">
                  {lmsReportData?.totalLessonInProgress}
                </div>
              </div>
              <div
                className={
                  lessonTab === 5 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getLesson(5)}
              >
                <div className="text">On Time</div>
                <div className="number">{lmsReportData?.totalLessonOnTime}</div>
              </div>
              <div
                className={
                  lessonTab === 6 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getLesson(6)}
              >
                <div className="text">Not On Time</div>
                <div className="number">
                  {lmsReportData?.totalLessonNotOnTime}
                </div>
              </div>
            </div>
            {lessonList?.lessons?.length > 0 && (
              <div className="reportTableRes">
                <table className="reportTable">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>lesson Name</th>
                      <th>Category</th>
                      <th>Assigned to</th>
                      <th>Completed By</th>
                      <th>not Completed By</th>
                      <th>
                        <Settings
                          onClick={handleClick}
                          className="settingIconReport"
                        />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography
                            sx={{ py: 1, px: 2 }}
                            className="downloadList"
                          >
                            <CSVLink
                              filename={"lmsReportLessons"}
                              data={lessonList?.lessons ?? []}
                            >
                              Download List
                            </CSVLink>
                          </Typography>
                        </Popover>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lessonList?.lessons?.map((item) => {
                      return (
                        <tr key={item?.lessonId}>
                          <td>{item?.lessonId}</td>
                          <td>{item?.lessonName}</td>
                          <td>{item?.category}</td>
                          <td>{item?.totalAssignedTo}</td>
                          <td>{item?.totalCompletedBy}</td>
                          <td>{item?.totalNotCompletedBy}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}

        {cardSection === 2 && (
          <>
            <div className="reportBody">
              <div
                className={
                  courseTab === 1 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getCourse(1)}
              >
                <div className="text">Total Courses Assigned</div>
                <div className="number">
                  {lmsReportData?.totalCourseAssigned}
                </div>
              </div>
              <div
                className={
                  courseTab === 2 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getCourse(2)}
              >
                <div className="text">Completed</div>
                <div className="number">
                  {lmsReportData?.totalCourseCompleted}
                </div>
              </div>
              <div
                className={
                  courseTab === 3 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getCourse(3)}
              >
                <div className="text">Not Completed</div>
                <div className="number">
                  {lmsReportData?.totalCourseNotCompleted}
                </div>
              </div>
              <div
                className={
                  courseTab === 4 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getCourse(4)}
              >
                <div className="text">In Progress</div>
                <div className="number">
                  {lmsReportData?.totalCourseInProgress}
                </div>
              </div>
              <div
                className={
                  courseTab === 5 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getCourse(5)}
              >
                <div className="text">On Time</div>
                <div className="number">{lmsReportData?.totalCourseOnTime}</div>
              </div>
              <div
                className={
                  courseTab === 6 ? "reportCardBody active" : "reportCardBody"
                }
                onClick={() => getCourse(6)}
              >
                <div className="text">Not On Time</div>
                <div className="number">
                  {lmsReportData?.totalCourseNotOnTime}
                </div>
              </div>
            </div>

            {courseList?.courses?.length > 0 && (
              <div className="reportTableRes">
                <table className="reportTable">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Course Name</th>
                      <th>Category</th>
                      <th>Assigned to</th>
                      <th>Completed By</th>
                      <th>not Completed By</th>
                      <th>
                        <Settings
                          onClick={handleClick}
                          className="settingIconReport"
                        />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography
                            sx={{ py: 1, px: 2 }}
                            className="downloadList"
                          >
                            <CSVLink
                              filename={"lmsReportCourse"}
                              data={courseList?.courses ?? []}
                            >
                              Download List
                            </CSVLink>
                          </Typography>
                        </Popover>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {courseList?.courses?.map((item) => {
                      return (
                        <tr key={item?.courseId}>
                          <td>{item?.courseId}</td>
                          <td>{item?.courseName}</td>
                          <td>{item?.category}</td>
                          <td>{item?.totalAssignedTo}</td>
                          <td>{item?.totalCompletedBy}</td>
                          <td>{item?.totalNotCompletedBy}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ReportTrainingSessions;
