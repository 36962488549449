import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../../feature/admin/shared/ReportRowManipulate";
import { PAGE_SIZE } from "../../../../constant/constant";
import { SW_ADD_CONTACT } from "../../../../constant/routeContant";
import FilterCommonStatusSelect from "../../../../feature/admin/shared/FilterCommonStatusSelect";
import "../../../../feature/admin/component/contacts/viewContact.scss";

export default function ContactList() {
  const columns = [
    { field: "id", headerName: "Company", flex: 2 },
    { field: "firstName", headerName: "Name", flex: 2 },
    { field: "lastName", headerName: "Contact Number", flex: 2 },
    {
      field: "age",
      headerName: "Email ID",
      flex: 2,
    },
    {
      field: "fullName",
      headerName: "Status",
      flex: 2,
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={"clientListsData?.data?.result?.noOfRecords ?? 0"}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={
            "clientListsData?.data?.result?.organisationData ?? []"
          }
          fileNameXL={"ClientList"}
        />
      ),
      flex: 2,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={true}
            editOnClick={"() => handleOnEditclick(rows)"}
            viewOnClick={"() => handleOnViewclick(rows)"}
            edit={true}
            editLabel="Edit Client"
            download={false}
            view={true}
            viewLabel="View Client"
          />
        );
      },
    },
  ];
  const rows = [{ id: 1, lastName: "Snow", firstName: "Company", age: 35 }];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Contacts</div>
          <div>
            <Buttons
              id="btnAddContact"
              label={"Add Contact"}
              varientAddIconBlue={true}
              onClick={SW_ADD_CONTACT}
            />
          </div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search Sites"
                    type="text"
                    value={"search"}
                    className=""
                    onChange={"handleautoSearch"}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>

            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                // onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              <FilterCommonStatusSelect />
            </div>
          </div>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                // getRowId={"rows"}
                rows={rows}
                rowCount={"contacts?.result?.noOfRecords ?? 0"}
                columns={columns}
                checkboxSelection={false}
                page={"page"}
                pagination
                pageSize={PAGE_SIZE}
                rowsPerPageOptions={[PAGE_SIZE]}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
