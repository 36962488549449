import React from "react";
import "./faqTopicStyle.scss";
import { Grid } from "@mui/material";
import CardBox from "../../../../library/custom/cardBox/cardBox";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../../library/common/Loader";
import * as supportAction from "../../../../redux/actions/supportAction";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { FAQ_DETAIL } from "../../../../constant/routeContant";
import Header from "../../common/header";
const FaqTopic = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const articleCatSubcatId = location?.state?.articleCatSubcatId;
  const title = location?.state?.title;
  const description = location?.state?.description;
  const [searchbarInput, setSearchbarInput] = useState("");
  useEffect(() => {
    dispatch(
      supportAction.getArticlesBasedCatSubcategory({
        catSubcatId: articleCatSubcatId,
        search: "",
      })
    );
  }, []);
  const articlesBasedCatSubcategory = useSelector(
    (state) => state.getArticlesBasedCatSubcategory?.data?.articles
  );
  const showLoader = useSelector(
    (state) => state.getArticlesBasedCatSubcategory?.isLoading
  );
  function handleSearch(search) {
    const newApiData = {
      catSubcatId: articleCatSubcatId,
      search: search,
    };
    setSearchbarInput(search);
    dispatch(supportAction.getArticlesBasedCatSubcategory(newApiData));
  }
  return (
    <>
    {
      showLoader && <Loader />
    }
      {articlesBasedCatSubcategory ? (
        <div className="overrideFaqTopic">
          <Header
            value={searchbarInput}
            handleSearch={(search) => {
              handleSearch(search);
            }}
          />
          <Grid className="container">
            <Grid mb={3} className="topGrid">
              <button
                className="btn"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIosNewIcon style={{ fontSize: "1rem" }} />
                Back
              </button>
            </Grid>
            <CardBox
              className="cardBox"
              category={title}
              details={description}
            />
            {articlesBasedCatSubcategory.map((item, index) => {
              return (
                <Grid
                  key={item.articleId}
                  onClick={() => {
                    navigate(FAQ_DETAIL, {
                      state: {
                        catSubcatId: articleCatSubcatId,
                        articleId: item.articleId,
                        title: title,
                        description: description,
                      },
                    });
                  }}
                  mt={2}
                  className="faqQuestions"
                >
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default FaqTopic;
