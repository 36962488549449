import React, { useState } from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../../library/custom/textArea/TextArea";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import {
  PASSPERCENTAGETYPE,
  LNG_ID,
  ORG_ID,
} from "../../../../constant/constant";
import Button from "../../../../library/custom/button/Button";
import AddNewQuestion from "../../../../library/custom/addNewQuestion/addNewQuestion";
import "./createQuiz.scss";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { element } from "prop-types";

const CreateQuiz = ({ onSubmit, QuizDetail, quizId }) => {
  const location = useLocation();
  const lessonId = location?.state?.lessonId;
  const detaildata = QuizDetail;
  const intialState = {
    organisationId: ORG_ID(),
    lngId: LNG_ID,
    lessonId: lessonId,
    createdBy: localStorage.getItem("userId"),
    title: "",
    description: "",
    passPercentage: "",
    questions: "",
    quizId: "",
  };

  const [formValue, setFormValue] = useState(intialState);
  useEffect(() => {
    if (detaildata && quizId > 0) {
      setFormValue({
        title: detaildata?.quizDetail?.title,
        description: detaildata?.quizDetail?.description,
        passPercentage: detaildata?.quizDetail?.passPercentage,
        lessonId: lessonId,
        lngId: LNG_ID,
        createdBy: localStorage.getItem("userId"),
        quizId: quizId ? quizId : "",
      });
    }
  }, [detaildata, quizId]);

  useEffect(() => {
    const newQuestionList = [];

    if (detaildata) {
      detaildata?.questionList?.map((items) => {
        return newQuestionList.push({
          id: Date.now() + Math.floor(Math.random() * 100),
          question: items?.question,
          answers: items?.answers,
          files: items?.files[0]?.value ? items?.files[0]?.value : [],
          fileIds: items?.files[0]?.key ? [items?.files[0]?.key] : [],
          isFileAdded: items?.files[0]?.key ? true : false,
        });
      });

      setQuestionss(newQuestionList);
    }
  }, [detaildata]);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleOnclickQuestion = () => {
    if (questionss.length < 10) {
      const newQuestions = [...questionss];
      newQuestions.push({
        id: Date.now() + Math.floor(Math.random() * 100),
        question: "",
        isFileAdded: false,
        fileIds: [],
        anasError: false,
        answers: [
          {
            id: Date.now() + Math.floor(Math.random() * 100),
            answer: "",
            isCorrected: false,
            isShowDefault: false,
          },
          {
            id: Date.now() + Math.floor(Math.random() * 100),
            answer: "",
            isCorrected: false,
            isShowDefault: false,
          },
        ],
      });
      setQuestionss(newQuestions);
    }
  };

  const handleOnChangeQuestionForm = (
    current,
    data,
    singleAns,
    type,
    isEdit = false
  ) => {
    let elementId = "";
    let value = "";
    if (type === "uploadMedia") {
      value = current;
      elementId = type;
    } else {
      value = current.target.value;
      elementId = current.target.id;
    }
    let updatedQuestions = [...questionss];

    updatedQuestions.forEach((questionss, index) => {
      if (questionss.id === data.id) {
        if (elementId === "question") {
          questionss.question = value;
        } else if (elementId === "uploadMedia") {
          questionss.files = value;
        }
      }

      if (singleAns) {
        const andlength = questionss.answers.length;

        questionss.answers.forEach((answer, index) => {
          if (elementId === "answer") {
            if (answer?.answerId && singleAns?.answerId) {
              if (answer.answerId === singleAns.answerId) {
                answer.answer = value;
              }
            } else {
              if (answer.id === singleAns.id) {
                answer.answer = value;
              }
            }
          }
        });
      }
    });
    setQuestionss(updatedQuestions);
  };

  const cancelQuestion = (sinlgeQues) => {
    const removeQuestion = questionss.filter(
      (question) => question.id !== sinlgeQues.id
    );
    setQuestionss(removeQuestion);
  };

  const handleDeleteImageURL = (singleQuestion) => {
    let updatedQuestions = [...questionss];
    updatedQuestions.forEach((questionsss) => {
      if (questionsss.id === singleQuestion.id) {
        questionsss.files = "";
        questionsss.fileIds = [];
        questionsss.isFileAdded = false;
      }
    });
    setQuestionss(updatedQuestions);
  };

  const [files, setFiles] = useState("");
  const [fileIds, setFileIds] = useState([]);
  const [singlQues, setSinglQues] = useState("");

  const questionFileUpload = (files, fileDataId, single) => {
    setFiles(files);
    setFileIds(fileDataId);
    setSinglQues(single);
  };
  useEffect(() => {
    let updatedQuestions = [...questionss];
    updatedQuestions.forEach((questionsss) => {
      if (questionsss.id === singlQues.id) {
        questionsss.files = files;
        questionsss.fileIds = fileIds;
        questionsss.isFileAdded = true;
      }
    });
    setQuestionss(updatedQuestions);
  }, [files]);
  useEffect(() => {
    setFormValue({
      ...formValue,
      passPercentage: PASSPERCENTAGETYPE[4]?.key,
    });
  }, []);
  const dataBack = {
    title: "Back to Lesson",
    route: -1,
  };
  const [isTitle, setIsTitle] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isPassPercentage, setIsPassPercentage] = useState(false);
  const [isquizans, setIsquizans] = useState(false);

  function mappedData() {
    let errors = false;
    let updatedQuestions = [...questionss];
    if (updatedQuestions.length > 0) {
      updatedQuestions?.forEach((data) => {
        if (!data.question) {
          data.isErrorQuestion = true;
          data.errorQuestionMsg = "Please enter this required field.";
          errors = true;
        }
        const adad = data.answers.indexOf();
        const index = data.answers
          .map((object) => object.isCorrected)
          .indexOf(true);
        if (index < 0) {
          data.anasError = true;
          errors = true;
        } else {
          data.anasError = false;
          errors = false;
        }

        const indexArr = data.answers.map((object) => object.answer.trim());
        const resultNonEmptyAns = indexArr.includes("");
        if (resultNonEmptyAns === true) {
          setIsquizans(true);
          errors = true;
        }
      });
    }
    setQuestionss(updatedQuestions);
    if (formValue?.description?.length === 0) {
      setIsDescription(true);
      errors = true;
    }
    if (formValue?.title?.length === 0) {
      setIsTitle(true);
      errors = true;
    }
    if (!formValue?.passPercentage) {
      setIsPassPercentage(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const data = {
      organisationId: formValue?.organisationId
        ? formValue?.organisationId
        : localStorage.getItem("orgId"),
      lngId: formValue?.lngId,
      lessonId: formValue?.lessonId,
      createdBy: formValue?.createdBy,
      title: formValue?.title,
      description: formValue?.description,
      passPercentage: formValue?.passPercentage,
      questions: questionss,
      quizId: quizId,
    };
    return { data };
  }
  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const [questionss, setQuestionss] = useState([
    {
      id: Date.now() + Math.floor(Math.random() * 100),
      question: "",
      isFileAdded: false,
      fileIds: [],
      anasError: false,

      answers: [
        {
          id: Date.now() + Math.floor(Math.random() * 100),
          answer: "",
          isCorrected: false,
          isShowDefault: false,
        },
        {
          id: Date.now() + Math.floor(Math.random() * 100),
          answer: "",
          isCorrected: false,
          isShowDefault: false,
        },
      ],
    },
  ]);
  const addAnswers = (singleAns) => {
    let updatedQuestions = [...questionss];
    updatedQuestions.forEach((sinlgeQues) => {
      if (sinlgeQues.id === singleAns.id) {
        sinlgeQues.answers.push({
          id: Date.now() + Math.floor(Math.random() * 100),
          answer: "",
          isCorrected: false,
          isShowDefault: true,
        });
      }
      setQuestionss(updatedQuestions);
    });
  };

  const cancelAnswers = (singQue, signAws) => {
    const cloneList = [...questionss];
    cloneList.forEach((des) => {
      if (singQue.id === des.id) {
        let filterAns = des.answers.filter((te) => te.id !== signAws.id);
        des.answers = filterAns;
      }
    });
    setQuestionss(cloneList);
  };

  const correctAnswer = (singQue, signAws) => {
    const cloneList = [...questionss];

    cloneList.forEach((des, index) => {
      if (singQue.id === des.id) {
        des.answers.forEach((ansCorrect) => {
          if (ansCorrect?.answerId && signAws?.answerId) {
            if (ansCorrect?.answerId === signAws?.answerId) {
              ansCorrect.isCorrected = true;
            } else {
              ansCorrect.isCorrected = false;
            }
          } else {
            if (ansCorrect?.id === signAws?.id) {
              ansCorrect.isCorrected = true;
            } else {
              ansCorrect.isCorrected = false;
            }
          }
        });
      }
    });
    setQuestionss(cloneList);
  };
  return (
    <>
      <div className="overCreateQuiz">
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                isRequired={true}
                type="text"
                label="Title"
                value={formValue.title}
                placeholder="Add Title Text Here…"
                onChange={(e) =>
                  setFormValue({ ...formValue, title: e.target.value })
                }
                isError={isTitle}
                errorMsg={isTitle ? "Title is Required" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} mt={3}>
            <FormControl fullWidth>
              <FormLabels label="Description" isRequired={true} />
              <TextArea
                placeholder={"Add Description Here..."}
                value={formValue?.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    description: e.target.value,
                  })
                }
                isError={isDescription}
                errorMsg={isDescription ? "Description is Required" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item mt={3} lg={5} xs={12} sm={12} md={5}>
            <FormControl fullWidth>
              <FormLabels label="Pass Percentage" />
              <SelectMenu
                listData={PASSPERCENTAGETYPE}
                value={formValue.passPercentage}
                onchangehandler={(e) =>
                  setFormValue({
                    ...formValue,
                    passPercentage: e.target.value,
                  })
                }
                isError={isPassPercentage}
                errorMsg={isPassPercentage ? "Pass Percentage is Required" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item container mt={3} xs={12}>
            <Grid item xs={12}>
              {questionss?.map((singleQuestion, index) => (
                <AddNewQuestion
                  key={index}
                  singleQuestion={singleQuestion}
                  handleOnChangeQuestionForm={handleOnChangeQuestionForm}
                  id={singleQuestion.id}
                  cancelQuestion={(id) => cancelQuestion(id)}
                  imageUrl={singleQuestion.files}
                  deleteImageURL={(url) => handleDeleteImageURL(singleQuestion)}
                  questionFileUpload={(photo, fileDataId, singleQuestions) =>
                    questionFileUpload(photo, fileDataId, singleQuestions)
                  }
                  addAnswers={addAnswers}
                  cancelAnswers={cancelAnswers}
                  correctAnswer={correctAnswer}
                  isErrorQuestion={singleQuestion.isErrorQuestion}
                  errorQuestionMsg={singleQuestion.errorQuestionMsg}
                  isquizans={isquizans}
                />
              ))}
            </Grid>
            <Grid item xs={12} mt={2}>
              <Button
                varientTextGreen={true}
                label="Add New Question"
                disabled={questionss.length === 10 ? true : false}
                onClick={handleOnclickQuestion}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} mt={2} className="quizBtnContainer">
            <Grid item lg={4} xs={12} className="quizBack">
              <BackBtn dataBack={dataBack} />
            </Grid>
            <Grid item lg={8} xs={12} className="quizSubmit">
              <Button
                varientContained={true}
                label={"Publish"}
                disabled={isSubmit ? true : false}
                onClick={submitData}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CreateQuiz;
