import { useNavigate } from "react-router-dom";
import IncidentReportList from "../../incidents/incidentReportList/incidentReportList";
import { PERSONAL_INJURY } from "../../../constant/routeContant";
const IncidentReportListContainer = () => {
  const navigate = useNavigate();
  const createCase = () => {
    navigate(PERSONAL_INJURY);
  };

  return (
    <>
      <IncidentReportList createCase={createCase} />
    </>
  );
};

export default IncidentReportListContainer;
