import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CreateFaq from "../component/faq/createFaq";
import * as supportAction from "../../../redux/actions/supportAction";
import SuccessScreen from "../../../library/custom/success/successScreen";
import Loader from "../../../library/common/Loader";
import { useLocation } from "react-router-dom";
import { ADMIN_FAQ } from "../../../constant/routeContant";

const CreateFaqContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const articleId = location?.state?.articleId;
  const categoryID = location?.state?.categoryID;
  const editMode = location?.state?.editMode;
  useEffect(() => {
    dispatch(
      supportAction.getArticleCatSubcategory({
        search: "",
        page: 1,
        pageSize: 100,
      })
    );
  }, []);

  useEffect(() => {
    setSuccessScreens(false);
    if (articleId) {
      dispatch(
        supportAction.getArticleDetails({
          catSubcatId: categoryID,
          articleId: articleId,
        })
      );
    }
  }, []);
  const [successScreens, setSuccessScreens] = useState(false);
  const createTicketData = useSelector((state) => state.postAddFAQ?.data);
  const createTicketStatus = useSelector((state) => state.postAddFAQ?.status);
  const updateFaq = useSelector((state) => state.updateFAQ);
  const articleCatSubcategory = useSelector(
    (state) => state.articlesCatSub?.data?.articleCatSubcat
  );

  const articlesDetails = useSelector((state) => state.getArticleDetails?.data);
  const showLoader = useSelector(
    (state) =>
      state.articlesCatSub?.isLoading ||
      state.postAddFAQ?.isLoading ||
      state.getArticleDetails?.isLoading ||
      state.updateFAQ?.isLoading
  );

  const successScree = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          editMode ? "Faq Updated Successfully" : "Faq Created Successfully"
        }
        label1={"FAQ ID"}
        label2={editMode ? "" : "has been generated"}
        label4="Support in"
        route={-1}
      ></SuccessScreen>
    );
  };
  return (
    <div>
      {showLoader && <Loader />}
      {createTicketStatus === "SUCCESS" &&
      createTicketData &&
      successScreens ? (
        successScree(createTicketData?.result?.articleId)
      ) : updateFaq.status === "SUCCESS" && successScreens ? (
        successScree(articleId)
      ) : (
        <CreateFaq
          articleCatSubcategory={
            articleCatSubcategory ? articleCatSubcategory : []
          }
          setSuccessScreens={setSuccessScreens}
          editMode={editMode}
          articlesDetails={articlesDetails}
          categoryID={categoryID}
          articleId={articleId}
        />
      )}
    </div>
  );
};

export default CreateFaqContainer;
