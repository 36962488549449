import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Buttons from "../../../../library/custom/button/Button";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import { COUNTRY_CODE } from "../../../../constant/constant";

const UserInfo = ({ userInfo, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [emailVerify, setEmailVerify] = useState("");
  const [phone, setPhone] = useState("");
  const [isSubmit, setIsSubmit] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    const isValidEmail = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailVerify(false);
    } else {
      setEmailVerify(true);
    }
    setEmail(e.target.value);
    if (e.target.value === "") {
      setEmailVerify(false);
    }
  };
  const onChangePhone = (value) => {
    setPhone(value.length <= 10 ? value : phone);
    const phonePattern = /^\d{10}$/;
    if (value.length <= 10) {
      setPhoneError(!phonePattern.test(value));
    } else if (value.length === 0) {
      setPhoneError(false);
    } else {
      setPhoneError(false);
    }
  };

  function mappedData() {
    let errors = false;
    if (phone) {
      if (phoneError) {
        setPhoneError(true);
        errors = true;
      }
    } else {
      setPhoneError(false);
    }
    if (errors) {
      return false;
    }
    const data = {
      phonenumber: phone,
      email: email,
    };
    return data;
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      onSubmit(data);
    }
  };

  useEffect(() => {
    if ((email || phone) && !emailVerify) {
      setIsSubmit(false);
    } else {
      setIsSubmit(true);
    }
  }, [email, phone]);

  useEffect(() => {
    if (!email && !phone) {
      const data = {
        phonenumber: "",
        email: "",
      };
      onSubmit(data);
    }
  }, [email, phone]);

  const renderClientName = (clientName) => {
    if (Array.isArray(clientName)) {
      return clientName.map((client, index) => (
        <div key={index}>
          {typeof client === "object" ? client.fullName : client}
        </div>
      ));
    }
    return clientName;
  };
  return (
    <>
      <Grid item container spacing={2} md={12} className="CategoriesBox">
        <Grid item md={4} xs={12} mb={2}>
          <InputField
            type="text"
            isRequired={false}
            value={email}
            onChange={(e) => onChangeEmail(e)}
            label="Enter Email ID"
            placeholder="Enter Email Here..."
            isError={false}
            errorMsg={false}
          />
          {emailVerify && (
            <div className="errorMsg">Please enter a valid Email address</div>
          )}
        </Grid>
        <Grid item md={4} xs={12} mb={2}>
          <NumberField
            // disabled={editMode ? true : false}
            isRequired={false}
            type="text"
            label={"Phone Number"}
            maxLength="10"
            value={phone}
            placeholder={`Enter Phone number without ${COUNTRY_CODE}`}
            onChange={(value) => onChangePhone(value)}
          />
          {phoneError && (
            <p className="error">Please enter valid phone number</p>
          )}
        </Grid>

        <Grid className="smallAddBtn" item lg={1.5} md={3} xs={3} ml={3} mt={4}>
          <Buttons
            varientContained={true}
            label={"Verify"}
            onClick={submitData}
            disabled={isSubmit ? true : false}
          />
        </Grid>
      </Grid>

      <Grid container className="dailyReportView">
        <Grid item lg={12} xs={12} sm={12} md={12} spacing={2} mt={2}>
          <div className="viewReportIns table_white tableCheckUser">
            <table>
              <tr className="tableHead">
                <th>User Name</th>
                <th>User Id</th>
                <th>Client Name</th>
                <th>Date of Onboarded</th>
                <th>Email ID</th>
                <th>Phone Number</th>
                <th>User Type</th>
              </tr>

              {userInfo?.result?.length > 0 ? (
                userInfo?.result?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.userName}</td>
                      <td>{item?.userId}</td>
                      <td>{renderClientName(item?.clientName)}</td>
                      <td>{item?.dateOfOnboarded}</td>
                      <td>{item?.email}</td>
                      <td>{item?.mobile}</td>
                      <td>{item?.userType}</td>
                    </tr>
                  );
                })
              ) : (
                <>
                  {userInfo?.result === "" && (
                    <tr>
                      <td colSpan={6}>
                        <div className="checkUserInfoMessage">
                          {userInfo?.responseMessages?.responseMessage}
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </table>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default UserInfo;
