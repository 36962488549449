import { stateCityType } from "../constants/stateCityType";

export const getState = () => ({
  type: stateCityType.GET_STATE,
});

export const getStateSuccess = (data) => ({
  type: stateCityType.GET_STATE_SUCCESS,
  data,
});

export const getStateFailure = (error) => ({
  type: stateCityType.GET_STATE_FAILURE,
  error,
});

export const getCity = (stateId) => ({
  type: stateCityType.GET_CITY,
  stateId,
});

export const getCitySuccess = (data) => ({
  type: stateCityType.GET_CITY_SUCCESS,
  data,
});

export const getCityFailure = (error) => ({
  type: stateCityType.GET_CITY_FAILURE,
  error,
});
