import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { PRODUCT_ID } from "../constant/storm-water/constant";

import { post, get } from "./axiosRequest";
export const createAssign = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Assign/AddAssign`;
  console.log("createAssign" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateRole = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/updateassign`;
  console.log("updateAssign" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getAssignList = async (data) => {
  const { search } = data.data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Role/GetRoleList?search=${search}&productId=${PRODUCT_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
