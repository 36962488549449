import { stateCityType } from "../constants/stateCityType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "stateCity",
  isError: false,
  isLoading: false,
};

export const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case stateCityType.GET_STATE:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case stateCityType.GET_STATE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case stateCityType.GET_STATE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const cityReducer = (state = initialState, action) => {
  switch (action.type) {
    case stateCityType.GET_CITY:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case stateCityType.GET_CITY_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case stateCityType.GET_CITY_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
