import * as vehiclesAction from "../actions/vehiclesAction";
import {
  deleteVehicleType,
  getVehicleDetailsType,
  postAddVehicleType,
  updateVehicleType,
  vehiclesType,
} from "../constants/vehicleType";
import {
  deleteVehicle,
  getVehicleDetails,
  getVehicleList,
  postAddVehicle,
  updateVehicle,
} from "../../services/vehiclesService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* vehicleListSaga(params) {
  try {
    const { data, status, error } = yield call(getVehicleList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = vehiclesType.VEHICLE_PAGE;
    yield put(
      vehiclesAction.getVehicleListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Vehicle List Failed" };
    yield put(vehiclesAction.getVehicleListlFailure(data));
  }
}

export function* postAddVehicleSaga(params) {
  try {
    const { data, status, error } = yield call(postAddVehicle, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      vehiclesAction.postAddVehicleSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post Closed Ticket Failed" };
    yield put(vehiclesAction.postAddVehicleFailure(data));
  }
}

function* deleteVehicleSaga(payload) {
  try {
    const { data, status, error } = yield call(deleteVehicle, payload);
    if (!data && status !== 200) throw apiError(error);
    const vehiclePage = deleteVehicleType.Vehicle_Page;
    yield put(
      vehiclesAction.deleteVehicleSuccess({
        vehiclePage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Delete Vehicle Failed" };
    yield put(vehiclesAction.deleteVehicleFailure(data));
  }
}


export function* getVehicleDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(getVehicleDetails, params);
    if (!data && status !== 200) throw apiError(error);
    const page = getVehicleDetailsType.VEHICLE_DETAILS_PAGE;
    yield put(
      vehiclesAction.getVehicleDetailsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Vehicle Details Failed" };
    yield put(vehiclesAction.getVehicleDetailsSuccess(data));
  }
}

export function* updateAssetSaga(params) {
  try {
    const { data, status, error } = yield call(updateVehicle, params);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      vehiclesAction.updateVehicleSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Vehicle updation Failed" };
    yield put(vehiclesAction.updateVehicleFailure(data));
  }
}

const myVehicleSaga = [
  takeLatest(vehiclesType.GET_VEHICLE_LIST, vehicleListSaga),
  takeLatest(postAddVehicleType.POST_ADD_VEHICLE_PENDING, postAddVehicleSaga),
  takeLatest(deleteVehicleType.DELETE_VEHICLE_PENDING, deleteVehicleSaga),
  takeLatest(getVehicleDetailsType.GET_VEHICLE_DETAILS, getVehicleDetailsSaga),
  takeLatest(updateVehicleType.UPDATE_VEHICLE_PENDING, updateAssetSaga),
];

export default myVehicleSaga;
