import { FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Buttons from "../../../../library/custom/button/Button";
import { TableLoader } from "../../../../library/common/Loader";
import { toast } from "react-toastify";
import NORow from "../../../../library/custom/noRow/noRow";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import {
  API_STATUS,
  ISwitch,
  ORG_ID,
  ORG_NAME,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import ReportRowManipulte from "../../../admin/shared/ReportRowManipulate";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import DialogBox from "../../../../library/common/DialogBox";
import ToolBar from "../../../../library/storm-water/toolBar";
import { useNavigate } from "react-router";
import {
  ADMIN_ADD_CONTACTS,
  ADMIN_ADD_STORM_WATERPROJECT,
  ADMIN_RESOURCE,
  INSPECTION_AUTHORITY,
  SETUP_INSPECTION,
  SETUP_INSPECTION_LIST,
  SW_ADMIN_SAMPLINGLOG,
  SW_INSPECTION_LIST,
  SW_OPEN_ITEMS,
  SW_PERMIT_LIST,
  SW_RAIN_FALL_DATA,
} from "../../../../constant/routeContant";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { convertUTCtoLocalTimeZone } from "../../../../utils/helper";

function AddContactProject({
  deleteContact,
  contactSearch,
  isContactLoading,
  handleAllContactSearch,
  getProjectContactsListAction,
  getAllProjectContactsList,
  addContactInProjectAction,
  addContactInProject,
  isLoading,
  handleUpdateContactInProject,
  updateContactInProject,
  editContactInProject,
  handleDeleteContact,
  projectData,
  stateId,
  clearUpdateContact,
  clearAddContact,
  customerId,
  customer,
}) {
  const navigate = useNavigate();
  const autoComp1 = useRef(null);
  const customerName = customer ?? ORG_NAME();
  const [isContact, setIsContact] = useState(false);
  const [contacts, setContacts] = useState("");
  const [page, setPage] = useState(1);
  const [projects, setProjects] = useState("");
  const [projectId, setProjectId] = useState();
  const [selectedRow, setSelectedRow] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [contactAlreadyExist, setContactAlreadyExist] = useState(false);
  const autoComp = useRef(null);

  useEffect(() => {
    if (projectData?.projectId || projectData?.projectid) {
      setProjects({
        key: projectData?.projectId ?? projectData?.projectid,
        value: projectData?.projectName,
      });
      setProjectId(projectData?.projectId ?? projectData?.projectid);
      const data = {
        projectId: projectData?.projectId ?? projectData?.projectid,
        userId: 0,
        page: page,
        pageSize: PAGE_SIZE,
      };

      getProjectContactsListAction(data);
    }
  }, [projectData]);

  const [getContactInProjectLists, setGetContactInProjectLists] = useState([]);
  function projectContactList() {
    const data1 = {
      projectId: projectId,
      userId: 0,
      page: 1,
      pageSize: PAGE_SIZE,
    };
    {
      getProjectContactsListAction(data1);
    }
  }

  useEffect(() => {
    if (
      updateContactInProject?.status === "SUCCESS" &&
      updateContactInProject?.isLoading === false &&
      clearUpdateContact
    ) {
      toast("Contact Updated successfully");
      projectContactList();
    }
  }, [updateContactInProject]);

  useEffect(() => {
    if (
      editContactInProject?.status === "SUCCESS" &&
      editContactInProject?.isLoading === false
    ) {
      toast("Contact Updated successfully");
      projectContactList();
    }
  }, [editContactInProject]);

  useEffect(() => {
    if (
      addContactInProject?.status === "SUCCESS" &&
      addContactInProject?.isLoading === false &&
      addContactInProject?.data?.responseMessages?.responseCode === "HBNG001" &&
      clearAddContact
    ) {
      toast("Contact Added successfully");
      projectContactList();
    }
    if (
      addContactInProject?.data?.responseMessages?.responseCode === "HBNG009"
    ) {
      setContactAlreadyExist(true);
    } else {
      setContactAlreadyExist(false);
    }
  }, [addContactInProject]);

  const getProjectContactsList = getAllProjectContactsList?.contactList;
  const handleChange = (item) => {
    const newTypeList = [...getProjectContactsList];
    newTypeList.forEach((lists) => {
      if (lists.id === item.id) {
        lists.isActive = !lists.isActive;
        lists.checked = !lists.checked;
        handleUpdateContactInProject([
          {
            id: lists?.id,
            modifiedBy: USER_ID(),
            isActive: lists?.isActive,
          },
        ]);
      }
    });
  };

  function mappedData() {
    const data = {
      projectId: projects?.key,
      contactId: contacts?.key,
      createdBy: USER_ID(),
    };
    let errors = false;
    if (!projects || projects == null) {
      errors = true;
    }
    if (!contacts || contacts === null || contacts === "") {
      setIsContact(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    return data;
  }

  useEffect(() => {
    if (!projects) {
      setIsContact(false);
    }
  }, [projects]);

  const addContactInList = () => {
    const data = mappedData();
    if (!data) {
    } else {
      addContactInProjectAction(data);
      const ele = autoComp1.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  useEffect(() => {
    if (deleteContact?.status === API_STATUS.SUCCESS) {
      const data1 = {
        projectId: projectId,
        userId: 0,
        page: 1,
        pageSize: PAGE_SIZE,
      };
      getProjectContactsListAction(data1);
    }
  }, [deleteContact]);

  const columns = [
    { field: "contactName", headerName: "Contact Name", flex: 1 },
    {
      flex: 1,
      field: "phoneNumber",
      headerName: "Phone Number",
      renderCell: (rows) => {
        return (
          <div title={rows?.row?.phoneNumber}>
            {rows?.row?.phoneNumber ? rows?.row?.phoneNumber : "N/A"}
          </div>
        );
      },
    },
    {
      flex: 1.6,
      field: "email",
      headerName: "Email ID",
      renderCell: (rows) => {
        return (
          <div title={rows?.row?.email}>
            {rows?.row?.email ? rows?.row?.email : "N/A"}
          </div>
        );
      },
    },
    {
      flex: 1.2,
      field: "createDate",
      headerName: "Date Added",
      renderCell: (rows) => {
        return (
          <div
            title={
              rows?.row?.createDate
                ? convertUTCtoLocalTimeZone(rows?.row?.createDate)
                : "N/A"
            }
          >
            {rows?.row?.createDate
              ? convertUTCtoLocalTimeZone(rows?.row?.createDate)
              : "N/A"}
          </div>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Receive Email",
      flex: 1,
      sortable: false,
      renderCell: (rows) => {
        return (
          <FormGroup className="isActiveBox swins">
            <FormControlLabel
              control={
                <ISwitch
                  checked={rows?.row?.isActive ? true : false}
                  onChange={() => handleChange(rows?.row)}
                  name="status"
                />
              }
            />
            <div>{rows?.row?.isActive === true ? "Active" : "Inactive"}</div>
          </FormGroup>
        );
      },
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={getProjectContactsList?.itemsCount ?? []}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={getProjectContactsList ?? []}
          fileNameXL={"ProjectList"}
        />
      ),
      flex: 0.8,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={true}
            deleteOnClick={() => removeContact(rows?.row, "single")}
            deleteLabel="Remove Contact"
            download={false}
            deleted={checkPrivileges([12, 132])}
          />
        );
      },
    },
  ];

  const addContact = () => {
    navigate(ADMIN_ADD_CONTACTS);
  };

  const deletehandle = () => {
    setOpenModal(false);
    const data = {
      id: selectedRow?.id,
      modifiedBy: USER_ID(),
    };
    handleDeleteContact(data);
  };

  const removeContact = (rows, modal) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      projectId: projectId,
      userId: 0,
      pageSize: PAGE_SIZE,
    };
    getProjectContactsListAction(data);
  };

  useEffect(() => {
    if (projects && getAllProjectContactsList) {
      setGetContactInProjectLists(getAllProjectContactsList);
    } else {
      setGetContactInProjectLists([]);
    }
  }, [projects, getAllProjectContactsList]);

  const toolBarDataEditMode = {
    title: "Project Tools",
    list: [
      {
        id: 1,
        title: "Edit Project",
        link: "",
        isshow: checkPrivileges([12, 111, 112, 113]),
      },
      {
        id: 11,
        title: "Project Contact",
        link: "",
        activeClass: "active",
        isshow: checkPrivileges([12, 132, 133, 134]),
      },
      {
        id: 2,
        title: "Inspection Service List",
        link: "",
        isshow: checkPrivileges([12, 99, 100, 101]),
      },
      {
        id: 9,
        title: "Setup Inspections",
        link: "",
        isshow: checkPrivileges([12, 99, 100]),
      },

      {
        id: 3,
        title: "Permits",
        link: "",
        isshow: checkPrivileges([12, 126, 127, 128]),
      },
      {
        id: 4,
        title: "Sampling Log",
        link: "",
        isshow: checkPrivileges([12, 129, 130, 131]),
      },
      {
        id: 5,
        title: "Open Items",
        link: "",
        isshow: checkPrivileges([12, 105, 106, 107]),
      },
      {
        id: 6,
        title: "Rainfall Data",
        link: "",
        isshow: true,
      },
      {
        id: 7,
        title: "Inspection Records",
        link: "",
        isshow: checkPrivileges([12, 102, 103, 104]),
      },

      {
        id: 8,
        title: "Inspection Authority",
        link: "",
        isshow: checkPrivileges([12, 96, 97, 98]),
      },
      {
        id: 12,
        title: "Project Resources",
        link: "",
        isshow: checkPrivileges([12, 73, 74, 75]),
      },
    ],
  };

  const dataBack = {
    title: "Back to Edit Projects",
    route: ADMIN_ADD_STORM_WATERPROJECT,
    editMode: true,
    projectName: projectData?.projectName,
    projectNumber: projectData?.projectNumber,
    projectid: projectId,
    customerName: customerName,
    customerId: customerId ? customerId : parseInt(ORG_ID()),
    stateId: stateId,
  };
  return (
    <>
      <div className="overidePersonal">
        {openModal && (
          <DialogBox
            open={openModal}
            rows={[]}
            header={"Confirm Deletion"}
            deletehandle={deletehandle}
            handleCloseModal={() => setOpenModal(false)}
            content={"Are you sure you want to delete this Contact?"}
            view
          />
        )}
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">Add Contact to Projects</div>
        </Grid>
      </div>
      <Grid container>
        <Grid lg={8} md={12} xs={12} pr={2}>
          <Grid container item md={12} xs={12} mt={4}>
            <Grid container item lg={12} mt={2} xs={12} sm={12} md={12}>
              <Grid lg={6} xs={12} sm={12} md={6} item>
                <FormControl fullWidth>
                  <FormLabels label="Project" />
                </FormControl>
                <SearchAutoComplete
                  name="Add Individual Users"
                  isKeyValuePair={true}
                  value={projects}
                  autoCompClear={autoComp}
                  noOptionsTextMsg={"No Project"}
                  disabled={true}
                />
              </Grid>
              <Grid lg={5} mt={3} ml={3}>
                <Buttons
                  id="btnAddContact"
                  label={"Create New Contact"}
                  varientAddIconBlue={true}
                  onClick={() => addContact()}
                />
              </Grid>
            </Grid>
            <Grid container item lg={12} mt={3}>
              <Grid item lg={6} md={6} sm={9} xs={12}>
                <FormControl fullWidth>
                  <FormLabels label="Project Contacts" isRequired={true} />
                  <SearchAutoComplete
                    name="Add Individual Users"
                    optionsData={contactSearch ?? []}
                    typeOnchange={(event) => handleAllContactSearch(event)}
                    isKeyValuePair={true}
                    value={contacts ? contacts : ""}
                    onChange={(e, value) => {
                      setContacts(value);
                      setIsContact(false);
                      setContactAlreadyExist(false);
                    }}
                    isLoading={isContactLoading}
                    autoCompClear={autoComp1}
                    noOptionsTextMsg={"No Contact"}
                    disabled={!projects ? true : false}
                    isError={isContact}
                    errorMsg={
                      isContact ? "Selection is required for this field" : ""
                    }
                  />
                  {contactAlreadyExist && (
                    <div className="errorMsg">
                      Contact already added to project
                    </div>
                  )}
                </FormControl>
              </Grid>

              {checkPrivileges([12, 132, 133]) && (
                <Grid
                  item
                  lg={4}
                  md={6}
                  sm={9}
                  xs={12}
                  mt={3}
                  ml={3}
                  className="addContactProject"
                >
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label="Add Contacts"
                    id="btnAddEquipment"
                    varientAddIconBlue={true}
                    onClick={addContactInList}
                    disabled={!projects ? true : false}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <section className="grid_main_sec">
            <div className="grid_main_body">
              <div className="desc_box row_uniq">
                <div className="table_style_ui">
                  <GridTable
                    getRowId={(r) => r?.id}
                    rows={getContactInProjectLists?.contactList ?? []}
                    rowCount={getContactInProjectLists?.itemsCount ?? 0}
                    columns={columns}
                    checkboxSelection={false}
                    page={1}
                    isLoading={isLoading}
                    pagination
                    pageSize={PAGE_SIZE}
                    rowsPerPageOptions={[PAGE_SIZE]}
                    onPageChange={handlePagignation}
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </Grid>

        <Grid lg={4} md={12} xs={12} pl={1} className="permitsTootBar">
          <div className="toolBarBox">
            <div className="otherDetail">
              <h3>Project Details</h3>
              <h2>{projects?.value}</h2>
              <h2>{projectData?.projectNumber}</h2>
            </div>
            <div className="otherDetail">
              <h3>Client Name</h3>
              <h2>{customerName}</h2>
            </div>

            <ToolBar
              toolBarData={toolBarDataEditMode}
              handleClick={(e) => {
                if (e.id === 1) {
                  navigate(ADMIN_ADD_STORM_WATERPROJECT, {
                    state: {
                      editMode: true,
                      projectName: projectData?.projectName,
                      projectNumber: projectData?.projectNumber,
                      projectid: projectId,
                      customerName: customerName,
                      customerId: customerId ? customerId : parseInt(ORG_ID()),
                      stateId: stateId,
                    },
                  });
                }
                if (e.id === 2) {
                  navigate(SETUP_INSPECTION_LIST, {
                    state: {
                      projectName: projectData?.projectName,
                      projectNumber: projectData?.projectNumber,
                      projectId: projectId,
                      customerName: customerName,
                      customerId: customerId ? customerId : parseInt(ORG_ID()),
                      stateId: stateId,
                    },
                  });
                }
                if (e.id === 9) {
                  navigate(SETUP_INSPECTION, {
                    state: {
                      projectName: projectData?.projectName,
                      projectNumber: projectData?.projectNumber,
                      projectId: projectId,
                      customerName: customerName,
                      customerId: customerId ? customerId : parseInt(ORG_ID()),
                      stateId: stateId,
                      rightSideMode: true,
                    },
                  });
                }
                if (e.id === 3) {
                  navigate(SW_PERMIT_LIST, {
                    state: {
                      projectName: projectData?.projectName,
                      projectNumber: projectData?.projectNumber,
                      projectId: projectId ?? projectData?.projectId,
                      customerName: customerName,
                      customerId: customerId ? customerId : parseInt(ORG_ID()),
                      stateId: stateId,
                    },
                  });
                }
                if (e.id === 4) {
                  navigate(SW_ADMIN_SAMPLINGLOG, {
                    state: {
                      projectName: projectData?.projectName,
                      projectNumber: projectData?.projectNumber,
                      projectId: projectData?.projectId,
                      customerName: customerName,
                      customerId: customerId ? customerId : parseInt(ORG_ID()),
                      stateId: stateId,
                    },
                  });
                }
                if (e.id === 5) {
                  navigate(SW_OPEN_ITEMS, {
                    state: {
                      projectName: projectData?.projectName,
                      projectNumber: projectData?.projectNumber,
                      projectId: projectData?.projectId,
                      customerName: customerName,
                      customerId: customerId ? customerId : parseInt(ORG_ID()),
                      stateId: stateId,
                    },
                  });
                }
                if (e.id === 6) {
                  navigate(SW_RAIN_FALL_DATA, {
                    state: {
                      projectName: projectData?.projectName,
                      projectNumber: projectData?.projectNumber,
                      projectId: projectData?.projectId,
                      customerName: customerName,
                      customerId: customerId ? customerId : parseInt(ORG_ID()),
                      stateId: stateId,
                    },
                  });
                }
                if (e.id === 7) {
                  navigate(SW_INSPECTION_LIST, {
                    state: {
                      projectName: projectData?.projectName,
                      projectNumber: projectData?.projectNumber,
                      projectId: projectData?.projectId,
                      customerName: customerName,
                      customerId: customerId ? customerId : parseInt(ORG_ID()),
                      stateId: stateId,
                    },
                  });
                }
                if (e.id === 8) {
                  navigate(INSPECTION_AUTHORITY);
                }
                if (e.id === 12) {
                  navigate(ADMIN_RESOURCE, {
                    state: {
                      projectName: projectData?.projectName,
                      projectNumber: projectData?.projectNumber,
                      projectId: projectData?.projectId,
                      customerName: customerName,
                      customerId: customerId ? customerId : parseInt(ORG_ID()),
                      stateId: stateId,
                    },
                  });
                } else return;
              }}
            />
          </div>
        </Grid>

        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="swprojectback">
            <BackBtn dataBack={dataBack} />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default AddContactProject;
