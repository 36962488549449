import React, { useEffect, useState } from "react";
import EditInspectionsType from "../components/editInspectionsType";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import Loader from "../../../../library/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { INSPECTION_TYPE } from "../../../../constant/routeContant";

const EditInspectionsTypeContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const detail = location?.state?.row;
  const navigate = useNavigate();

  console.log("detail", detail);

  const [clearUpdateInspectionsType, setClearUpdateInspectionsType] =
    useState(false);
  const updateInspectionType = useSelector(
    (state) => state.updateInspectionType
  );

  const stateData = useSelector((state) => state.state);

  const showLoader = useSelector(
    (state) => state.updateInspectionType?.isLoading || state.state?.isLoading
  );

  const updateInspectionTypeData = (data) => {
    setClearUpdateInspectionsType(true);
    dispatch(swInspectionAction.updateInspectionType(data));
  };

  useEffect(() => {
    if (
      updateInspectionType?.status === "SUCCESS" &&
      updateInspectionType?.isLoading === false &&
      clearUpdateInspectionsType
    ) {
      toast("InspectionType Updated successfully");
      setTimeout(function () {
        navigate(INSPECTION_TYPE);
      }, 5000);
    }
  }, [updateInspectionType]);

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };

  useEffect(() => {
    getStateData();
  }, []);

  return (
    <>
      {showLoader && <Loader />}
      <EditInspectionsType
        detail={detail}
        updateInspectionTypeData={updateInspectionTypeData}
        state={stateData && stateData.data?.getState}
      />
      <ToastContainer />
    </>
  );
};

export default EditInspectionsTypeContainer;
