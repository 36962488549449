import { getApiUrl } from "../config";
import { get, post, deletes } from "./axiosRequest";
import { ORG_ID, LNG_ID, PAGE_SIZE, USERID } from "../constant/constant";

export const getIncidentReferenceData = async () => {
  const url = `${
    getApiUrl().incidentApiUrl
  }/getreferencedata?orgId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addPersonalInjury = async ({ data }) => {
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/PersonalInjury/AddPersonalInjury`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updatePersonalInjury = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/updatepersonalinjury`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/PersonalInjury/UpdatePersonalInjury`;
  // try {

  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getPersonalInjury = async (caseid, incidentid) => {
  // const url = `${
  //   getApiUrl().incidentApiUrl
  // }/getpersonalInjury?orgId=${ORG_ID()}&caseid=${caseid}&incidentid=${incidentid}`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/PersonalInjury/GetPersonalInjury?orgId=${ORG_ID()}&caseId=${caseid}&incidentId=${incidentid}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addVehicale = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/addvehicleaccident`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/VehicleAccident/AddVehicleAccident`;

  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getVehicleAccidentReport = async (caseid, incidentid) => {
  // const url = `${
  //   getApiUrl().incidentApiUrl
  // }/getvehicleaccident?orgId=${ORG_ID()}&caseid=${caseid}&incidentid=${incidentid}`;

  const url = `${
    getApiUrl().incidentApiUrlNew
  }/VehicleAccident/GetVehicleAccident?orgId=${ORG_ID()}&caseId=${caseid}&incidentId=${incidentid}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateVehicleAccident = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/updatevehicleaccident`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/VehicleAccident/UpdateVehicleAccident`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addUtilityDamage = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrlNew}/UtilityDamage/AddUtilityDamage`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUtilityDamageReport = async (caseid, incidentid) => {
  // const url = `${
  //   getApiUrl().incidentApiUrl
  // }/getUtilitydamage?orgId=${ORG_ID()}&caseid=${caseid}&incidentid=${incidentid}`;

  const url = `${
    getApiUrl().incidentApiUrlNew
  }/UtilityDamage/GetUtilityDamage?orgId=${ORG_ID()}&caseId=${caseid}&incidentId=${incidentid}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateUtilityDamage = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/updateutilitydamage`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/UtilityDamage/UpdateUtilityDamage`;

  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addNearMisses = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrlNew}/NearMiss/AddNearMiss`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getNearMisses = async (caseid, incidentid) => {
  // const url = `${
  //   getApiUrl().incidentApiUrl
  // }/getnearmiss?orgId=${ORG_ID()}&caseid=${caseid}&incidentid=${incidentid}`;

  const url = `${
    getApiUrl().incidentApiUrlNew
  }/NearMiss/GetNearMiss?orgId=${ORG_ID()}&caseId=${caseid}&incidentId=${incidentid}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateNearMisses = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/updatenearmiss`;

  const url = `${getApiUrl().incidentApiUrlNew}/NearMiss/UpdateNearMiss`;

  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addStolenProperty = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/addstolenproperty`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/StolenProperty/AddStolenProperty`;
  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getStolenProperty = async (caseid, incidentid) => {
  // const url = `${
  //   getApiUrl().incidentApiUrl
  // }/getstolenproperty?orgId=${ORG_ID()}&caseid=${caseid}&incidentid=${incidentid}`;

  const url = `${
    getApiUrl().incidentApiUrlNew
  }/StolenProperty/GetStolenProperty?orgId=${ORG_ID()}&caseId=${caseid}&incidentId=${incidentid}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateStolenProperty = async ({ data }) => {
  // const url = `${getApiUrl().incidentApiUrl}/updatestolenproperty`;
  const url = `${
    getApiUrl().incidentApiUrlNew
  }/StolenProperty/UpdateStolenProperty`;

  try {
    data.orgId = ORG_ID();
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getIncidentList = async ({ data }) => {
  const {
    pageNumber,
    searchText,
    startDate,
    endDate,
    caseId,
    incidentType,
    status,
  } = data;
  const pageNumbers = !!pageNumber ? `pageNumber=${pageNumber}&` : "";
  const searchTexts = !!searchText ? `searchText=${searchText}&` : "";

  const startDate6 = !!startDate ? `fromdate=${startDate}&` : "";
  const endDate7 = !!endDate ? `todate=${endDate}&` : "";
  const caseIds = !!caseId ? `caseId=${caseId}&` : "";
  const incidentTypes = !!incidentType ? `incidentType=${incidentType}&` : "";
  const statuss = !!status ? `status=${status}&` : "";
  let url = `${
    getApiUrl().incidentApiUrlNew
  }/IncidentType/GetIncidentList?${pageNumbers}orgId=${ORG_ID()}&${searchTexts}${startDate6}${endDate7}${caseIds}${incidentTypes}${statuss}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getRegNumber = async (searchText) => {
  const url = `${getApiUrl().searchApiUrl}/v1/getvehicle?regNumber=${
    searchText ? searchText?.trim() : ""
  }&orgId=${ORG_ID()}`;
  try {
    return await get(
      url,
      {
        headers: {
          Authorization: localStorage.getItem("id_token"),
        },
      },
      true
    );
  } catch (err) {
    return err;
  }
};

export const deleteIncident = async ({ payload }) => {
  const url = `${getApiUrl().incidentApiUrl}/deleteincident/${
    payload ? payload : ""
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getActionsList = async ({ data }) => {
  const { page, search } = data;

  let url = `${
    getApiUrl().incidentApiUrl
  }/GetOpenActionList?organizationId=${ORG_ID()}&lngId=${LNG_ID}&userId=${USERID}&search=${search}&page=${page}&pageSize=${PAGE_SIZE}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// post update observation
export const closeActionService = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrl}/CloseAction`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCasesList = async ({ data }) => {
  const { page, search, date_from, date_to, statusIds = "" } = data;

  let url = `${
    getApiUrl().incidentApiUrl
  }/getcaselist?pageNumber=${page}&pageSize=${PAGE_SIZE}&statusIds=${statusIds}&date_from=${date_from}&date_to=${date_to}&orgId=${ORG_ID()}&searchText=${search}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCaseReport = async (caseId) => {
  const url = `${
    getApiUrl().incidentApiUrl
  }/getcase?orgId=${ORG_ID()}&CaseId=${caseId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    });
  } catch (err) {
    return err;
  }
};

export const closeIncident = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrl}/CloseIncident`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteCase = async ({ payload }) => {
  console.log("delete case", payload);
  const url = `${getApiUrl().incidentApiUrl}/deleteCase/${payload}`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
