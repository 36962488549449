import React, { useState } from "react";
import "./multiSelectBox.scss";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
} from "@mui/material";
import commonImages from "../../../../assets";
import { useEffect } from "react";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
// import SelectMenu from "../../../../library/custom/selectMenu/MultiSelectBox";
import Buttons from "../../../../library/custom/button/Button";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PAGE_SIZE } from "../../../../constant/constant";
import parse from "html-react-parser";

const MultiSelectBox = ({
  label,
  list,
  btnLabel,
  catActArr,
  setcatActArr,
  activityId,
  singleactivity,
  selectAll,
  handleCheck,
  cancel,
  btnLabel2,
  selectedActivity,
  categoryId,
  setlastModifiedCatAct,
  getJhaHazardsList,
  handleChange,
  expanded,
  controlListData,
  controlsLoading,
}) => {
  const [actArr, setactArr] = useState([]);
  useEffect(() => {
    let arr = [];
    list?.map((i) =>
      arr.push({
        key: i.activityId,
        value: i.activityName,
      })
    );
    setactArr(arr);
  }, [list]);

  const [activtySetValue, setactivtySetValue] = useState();
  const [activityFindValues, setactivityFindValues] = useState();
  const handleactivitySetChange = (e) => {
    const findactivityValue = actArr.find(
      (item) => item.key === e.target.value
    );
    setactivityFindValues(findactivityValue);
    setactivtySetValue({
      activtySetValue: e.target.value,
    });
  };

  const [selectactivity, setSelectactivity] = useState(false);
  const [selectactivityList, setSelectactivityList] = useState(
    selectedActivity ?? []
  );
  const [selectactivityMsg, setSelectactivityMsg] = useState(false);
  const handleOnclickactivity = () => {
    setlastModifiedCatAct({ cat: categoryId, act: activityFindValues?.key });
    let errors = false;
    if (!activtySetValue) {
      setSelectactivity(true);
      setSelectactivityMsg("activity is required");
      errors = true;
    }
    if (errors) {
      // setShowLoader(true);
      return;
    }
    const newactivityes = [...catActArr];

    console.log(selectactivityList);
    console.log(activityFindValues);
    const index = selectactivityList?.findIndex(
      (obect) => obect.key === activityFindValues?.key
    );
    if (index === -1) {
      setSelectactivity(false);
      let arr = [...selectactivityList];
      arr.push({
        key: activityFindValues?.key,
        value: activityFindValues?.value,
        hazards: [],
      });
      getJhaHazardsList({
        activityId: activityFindValues?.key,
        search: "",
        page: 1,
        pageSize: 10000,
      });
      setSelectactivityList(arr);
    } else {
      setSelectactivity(true);
      setSelectactivityMsg("Activity already added.");
    }
  };
  useEffect(() => {
    // if (selectactivityList?.length > 0) {
    let arr = [...catActArr];
    arr.forEach((i) => {
      if (i.categoryId === categoryId) {
        i.selectedActivity = selectactivityList;
      }
    });
    setcatActArr(arr);
    // }
  }, [selectactivityList]);
  useEffect(() => {
    console.log(catActArr);
  }, [catActArr]);

  function cancelActivity(params) {
    let arr = [...catActArr];
    let newSelectAct = [];
    arr.forEach((cat) => {
      if (cat?.categoryId === categoryId) {
        newSelectAct = cat?.selectedActivity?.filter((i) => i.key !== params);
        cat.selectedActivity = newSelectAct;
        setSelectactivityList(newSelectAct);
      }
    });
    setcatActArr(arr);
  }

  return (
    <div className="overrideMultiSelectBox">
      <Grid p={2} container className="boxContainer">
        <Grid
          container
          justifyContent={"space-between"}
          p={1.5}
          className="boxHeading"
        >
          <h1 className="label">{label}</h1>
          <Grid container item lg={6} justifyContent={"right"} gap={"10px"}>
            {/* <div className="btnDiv" onClick={() => selectAll(list, activityId)}>
              {btnLabel}
            </div> */}

            <div className="btnDiv" onClick={() => cancel(categoryId)}>
              {btnLabel2}
            </div>
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid container item>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Add activity" isRequired={true} />
                <SelectMenu
                  placeholder="Please Select"
                  listData={actArr ?? []}
                  value={activtySetValue}
                  onchangehandler={(e) => handleactivitySetChange(e)}
                />
              </FormControl>
            </Grid>
            <Grid
              className="smallAddBtn"
              item
              lg={1.5}
              md={2}
              xs={3}
              ml={2}
              mt={4}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                id="btnAddEquipment"
                varientAddIconBlue={true}
                onClick={handleOnclickactivity}
              ></Buttons>
            </Grid>
          </Grid>

          <Grid md={12} item>
            {selectactivity && <p className="errorMsg">{selectactivityMsg}</p>}
          </Grid>
        </Grid>
        {selectedActivity?.map((activity, index) => (
          <Grid container mt={2} key={index}>
            <Accordion
              style={{ width: "100%" }}
              className="jhAccordianChild"
              // key={activity?.key}
            >
              <AccordionSummary
                className="jhChildAccordian"
                p={0}
                expandIcon={<ExpandMoreIcon />}
              >
                <Grid
                  container
                  justifyContent={"space-between"}
                  p={1.5}
                  className="actBoxHeading"
                >
                  <h1 className="label">{activity.value}</h1>
                  <Grid
                    container
                    item
                    lg={6}
                    justifyContent={"right"}
                    gap={"10px"}
                  >
                    <div
                      className="btnDiv"
                      onClick={() => cancelActivity(activity.key)}
                    >
                      {"Remove Activity"}
                    </div>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                style={{ borderTop: "1px solid #dde2e5" }}
                className="accordinDetails"
              >
                {activity?.hazards?.map((hazard, index) => {
                  return (
                    <Grid container mt={2} key={index}>
                      <Accordion
                        style={{ width: "100%" }}
                        className="jhAccordianChild"
                        // key={activity?.key}
                        expanded={expanded === hazard.id}
                        onChange={handleChange(hazard.id)}
                      >
                        <AccordionSummary
                          className="jhChildAccordian"
                          p={0}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          {`Hazard : ${hazard?.hazardsName}`}
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ borderTop: "1px solid #dde2e5" }}
                          className="accordinDetails"
                        >
                          {controlListData?.map((i, index) => (
                            <Grid
                              pl={2}
                              pr={2}
                              style={{ background: "#fff" }}
                              key={index}
                            >
                              {controlsLoading ? (
                                <Skeleton />
                              ) : (
                                <Grid
                                  container
                                  className="controleName"
                                  key={i?.key}
                                >
                                  <Grid item className="controleStyle">
                                    Control {index + 1}-{" "}
                                  </Grid>
                                  <Grid item className="controleMainDiv">
                                    {parse(unescape(i?.controlsName))}
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MultiSelectBox;
