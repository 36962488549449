import React, { useEffect } from "react";
import AssignedUserList from "../component/assignedLessonUser/assignedLessonUser";
import * as lmsAction from "../../../redux/actions/lmsAction";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE, API_STATUS } from "../../../constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

const AsignComplUserLessonContainer = () => {
  const location = useLocation();
  const fieldid = location?.state?.fieldId;
  const lessonId = location?.state?.lessonId;
  const lessonname = location?.state?.lessonname;
  const dispatch = useDispatch();
  const [isdisable, setisdisable] = useState(false);
  const getAssignLessonUserListData = useSelector(
    (state) => state?.getAssignLessonUserList
  );

  // alert mail  for lesson assign
  const [isunAssignbtn, setisunAssignbtn] = useState(false);
  const lessonUserAssignAlertData = useSelector(
    (state) => state?.getAssignAlertUserListReducer
  );
  const assignUserloading = useSelector(
    (state) => state?.getAssignAlertUserListReducer?.isLoading
  );
  const unAssignLessonReducerData = useSelector(
    (state) => state?.unAssignLesson
  );

  let navigate = useNavigate();

  const defaultData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    lessonId: lessonId,
    isCompleted: fieldid,
  };

  useEffect(() => {
    if (
      unAssignLessonReducerData.status === API_STATUS.SUCCESS &&
      isunAssignbtn
    ) {
      getLessonAssignUserListData(defaultData);
      // setisdisable(false);
    }
  }, [unAssignLessonReducerData]);

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10]);

    if (isAllowedDash === false) {
      navigate(DASHBOARD);
    }
    getLessonAssignUserListData(defaultData);
  }, []);
  const [clearAlert, setClearAlert] = useState(false);

  const getLessonAssignUserListData = async (data) => {
    await dispatch(lmsAction.getAssignedLessonList(data));
  };

  const addSubmitAlert = async (data) => {
    setClearAlert(true);
    dispatch(lmsAction.getAlertmailAssigncourseLesson(data));
  };

  useEffect(() => {
    if (
      lessonUserAssignAlertData.status == API_STATUS.SUCCESS &&
      lessonUserAssignAlertData?.isLoading === false &&
      clearAlert
    ) {
      toast("Mail Sending successfully");
    }
  }, [lessonUserAssignAlertData]);

  // unAssign Lesson Api call
  const postunAssignLesson = async (data) => {
    // setClearAlert(true);
    setisdisable(true);
    dispatch(lmsAction.unAssignLesson(data));
  };

  return (
    <>
      {assignUserloading && <Loader />}

      <AssignedUserList
        getLessonAssignUserListData={getLessonAssignUserListData}
        lessonUserAssignListData={getAssignLessonUserListData?.dataresult}
        isLoading={getAssignLessonUserListData?.isLoading}
        lessonname={lessonname ?? ""}
        lessonId={lessonId}
        fieldid={fieldid}
        onSubmit={addSubmitAlert}
        postunAssignLesson={postunAssignLesson}
        setisunAssignbtn={setisunAssignbtn}
        setisdisable={setisdisable}
        isdisable={isdisable}
      />
      <ToastContainer />
    </>
  );
};

export default AsignComplUserLessonContainer;
