import React from "react";
import InspectionList from "../component/inspection/inspectionList";
import {
  INSPECTIONS,
  INSPECTIONS_ACKNOWLEDGMENT,
  INSPECTION_CATEGORY,
  INSPECTION_QUESTIONS,
  INSPECTION_SET,
  INSPECTION_SUB_CATEGORY,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";

const InspectionConfigContainer = () => {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(INSPECTION_CATEGORY);
    } else if (id === 2) {
      navigate(INSPECTION_SUB_CATEGORY);
    } else if (id === 3) {
      navigate(INSPECTION_QUESTIONS);
    } else if (id === 4) {
      navigate(INSPECTIONS);
    } else if (id === 5) {
      navigate(INSPECTIONS_ACKNOWLEDGMENT);
    }
  };

  return (
    <>
      <div className="dashTitle">Inspection Management</div>
      <InspectionList onClick={onClickHandler} />
    </>
  );
};

export default InspectionConfigContainer;
