import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "../common.scss";

const PersonnalInformation = ({ personalData }) => {
  return (
    <div className="commonOveride">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">Personnel information:</Typography>
            <Box className="columns">
              <Typography className="listView">
                Employment Type:
                <span className="focusedText">
                  {personalData?.employment_type == 3
                    ? "Employee"
                    : personalData?.employment_type == 1
                    ? "Contractor"
                    : ""}
                </span>
              </Typography>
            </Box>

            <Typography className="listView">
              Company Name:
              <span className="focusedText">
                {personalData?.company_name}
              </span>{" "}
            </Typography>
            <Box className="columns">
              <Typography className="listView">
                Employee's Name:
                <span className="focusedText">
                  {personalData?.employee_name}
                </span>
              </Typography>
              {personalData?.supervisor_name && (
                <Typography className="listView">
                  Supervisor:
                  <span className="font-w400">
                    {personalData?.supervisor_name}
                  </span>
                </Typography>
              )}
            </Box>
            <Box className="columns">
              <Typography className="listView">
                Job Title:
                <span className="font-w400">{personalData?.job_title}</span>
              </Typography>
              <Typography className="listView">
                Start Date:
                <span className="font-w400">{personalData?.start_date}</span>
              </Typography>
            </Box>

            <Box className="columns">
              {personalData?.job_site && (
                <Typography className="listView desNone">
                  Jobsite:
                  <span className="font-w400">{personalData?.job_site}</span>
                </Typography>
              )}
              <Typography className="listView desNone">
                Where did the case occur:
                <span className="font-w400">{personalData?.location}</span>
              </Typography>
            </Box>
          </Box>
          <Box className="commoninfoRight"></Box>
        </Box>
      </Box>
    </div>
  );
};

export default PersonnalInformation;
