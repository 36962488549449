import { API_STATUS } from "../../constant/constant";
import { nearMissesType } from "../constants/nearMissesType";

const initialState = {
  flow: "nearMisses",
  isError: false,
  isLoading: false,
};

export const nearMissesReducer = (state = initialState, action) => {
  switch (action.type) {
    case nearMissesType.POST_NEAR_MISSES_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case nearMissesType.POST_NEAR_MISSES_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case nearMissesType.POST_NEAR_MISSES_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case nearMissesType.CLEAR_NEAR_MISSES:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getNearMissesReducer = (state = initialState, action) => {
  switch (action.type) {
    case nearMissesType.GET_NEAR_MISSES_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case nearMissesType.GET_NEAR_MISSES_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case nearMissesType.GET_NEAR_MISSES_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateNearMissesReducer = (state = initialState, action) => {
  switch (action.type) {
    case nearMissesType.PUT_NEAR_MISSES_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case nearMissesType.PUT_NEAR_MISSES_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case nearMissesType.PUT_NEAR_MISSES_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case nearMissesType.CLEAR_NEAR_MISSES:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};
