import { permitType } from "../types/permitType";

export const addPermit = (data) => ({
  type: permitType.POST_ADD_PERMIT_PENDING,
  data,
});

export const addPermitSuccess = (data) => ({
  type: permitType.POST_ADD_PERMIT_SUCCESS,
  data,
});

export const addPermitFailure = (error) => ({
  type: permitType.POST_ADD_PERMIT_FAILURE,
  error,
});
//permit details
export const permitDetailPending = (data) => ({
  type: permitType.GET_PERMIT_DETAILS_PENDING,
  data,
});

export const permitDetailSuccess = (data) => ({
  type: permitType.GET_PERMIT_DETAILS_SUCCESS,
  data,
});

export const permitDetailFailure = (error) => ({
  type: permitType.GET_PERMIT_DETAILS_FAILURE,
  error,
});

export const permitListPending = (data) => ({
  type: permitType.GET_PERMIT_LIST_PENDING,
  data,
});

export const permitListSuccess = (data) => ({
  type: permitType.GET_PERMIT_LIST_SUCCESS,
  data,
});

export const permitListFailure = (error) => ({
  type: permitType.GET_PERMIT_LIST_FAILURE,
  error,
});

export const PostUpdatePermitPending = (data) => ({
  type: permitType.POST_UPDATE_PERMIT_PENDING,
  data,
});

export const PostUpdatePermitSuccess = (data) => ({
  type: permitType.POST_UPDATE_PERMIT_SUCCESS,
  data,
});

export const PostUpdatePermitFailure = (error) => ({
  type: permitType.POST_UPDATE_PERMIT_FAILURE,
  error,
});

export const addPermitType = (data) => ({
  type: permitType.POST_ADD_PERMIT_TYPE_PENDING,
  data,
});

export const addPermitTypeSuccess = (data) => ({
  type: permitType.POST_ADD_PERMIT_TYPE_SUCCESS,
  data,
});

export const addPermitTypeFailure = (error) => ({
  type: permitType.POST_ADD_PERMIT_TYPE_FAILURE,
  error,
});

export const getPermitTypeListPending = (data) => ({
  type: permitType.GET_PERMIT_TYPE_LIST_PENDING,
  data,
});

export const getPermitTypeListSuccess = (data) => ({
  type: permitType.GET_PERMIT_TYPE_LIST_SUCCESS,
  data,
});

export const getPermitTypeListFailure = (error) => ({
  type: permitType.GET_PERMIT_TYPE_LIST_FAILURE,
  error,
});

export const updatePermitType = (data) => ({
  type: permitType.POST_UPDATE_PERMIT_TYPE_PENDING,
  data,
});

export const updatePermitTypeSuccess = (data) => ({
  type: permitType.POST_UPDATE_PERMIT_TYPE_SUCCESS,
  data,
});

export const updatePermitTypeFailure = (error) => ({
  type: permitType.POST_UPDATE_PERMIT_TYPE_FAILURE,
  error,
});

export const generalPermitListPending = (data) => ({
  type: permitType.GET_GENERAL_PERMIT_LIST_PENDING,
  data,
});

export const generalPermitListSuccess = (data) => ({
  type: permitType.GET_GENERAL_PERMIT_LIST_SUCCESS,
  data,
});

export const generalPermitListFailure = (error) => ({
  type: permitType.GET_GENERAL_PERMIT_LIST_FAILURE,
  error,
});

export const updateGeneralPermit = (data) => ({
  type: permitType.POST_UPDATE_GENERAL_PERMIT_PENDING,
  data,
});

export const updateGeneralPermitSuccess = (data) => ({
  type: permitType.POST_UPDATE_GENERAL_PERMIT_SUCCESS,
  data,
});

export const updateGeneralPermitFailure = (error) => ({
  type: permitType.POST_UPDATE_GENERAL_PERMIT_FAILURE,
  error,
});

export const getPermitTypePending = (data) => ({
  type: permitType.GET_PERMIT_TYPE_PENDING,
  data,
});

export const getPermitTypeSuccess = (data) => ({
  type: permitType.GET_PERMIT_TYPE_SUCCESS,
  data,
});

export const getPermitTypeFailure = (error) => ({
  type: permitType.GET_PERMIT_TYPE_FAILURE,
  error,
});
