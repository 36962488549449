import AddincidentIdList from "./incidentListId";
import * as incidentAction from "../../../redux/actions/incidentAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_STATUS } from "../../../constant/constant";
import Loader from "../../../library/common/Loader";
import { checkPrivileges } from "../../../utils/rolesHelper";

const IncidentListIdContainer = () => {
  const dispatch = useDispatch();
  let { incidentId } = useParams();
  const [page, setPage] = useState(1);

  const pageSet = (pageNumber) => {
    setPage(pageNumber);
  };
  const deleteIncidents = (deleteIncedent) => {
    dispatch(incidentAction.deleteIncidents(deleteIncedent.reportId));
    // getIncidentListData();
  };
  const incidentListDelete = useSelector(
    (state) => state.deleteIncidentsData.status
  );

  const incidentList = useSelector((state) => state.incidentList);

  const getIncidentListData = (data) => {
    dispatch(
      incidentAction.getIncidentList({ pageNumber: 1, caseId: incidentId })
    );
  };

  const isSuccess = useSelector(
    (state) => state.incidentList.status === API_STATUS.SUCCESS
  );
  useEffect(() => {
    if (incidentListDelete === "SUCCESS") {
      getIncidentListData(0);
    } else {
      getIncidentListData(incidentId);
    }
  }, [incidentListDelete]);
  useEffect(() => {
    getIncidentListData(incidentId);
  }, []);
  return (
    <>
      {isSuccess ? (
        <AddincidentIdList
          pageSet={pageSet}
          incidentList={incidentList}
          deleteIncidents={deleteIncidents}
          isFullAccess={checkPrivileges([1, 12])}
          isWriteAccess={checkPrivileges([2, 12])}
          isReadAccess={checkPrivileges([3, 12])}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default IncidentListIdContainer;
