import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { get, post, deletes } from "./axiosRequest";

export const getCredentialReport = async ({ data }) => {
  const { groupId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Credential/GeCredentialReports?orgId=${ORG_ID()}&groupId=${groupId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getLookupCredentialReport = async ({ data }) => {
  const { tabId, startDate, endDate, page, pageSize, groupId } = data;

  const url = `${
    getApiUrl().adminApiUrl
  }/Credential/GetConfigureCredentialListReports?orgId=${ORG_ID()}&tabId=${tabId}&startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}&groupId=${groupId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getConfiguredCredentialReport = async ({ data }) => {
  const { tabId, startDate, endDate, page, pageSize, groupId, userId } = data;

  const url = `${
    getApiUrl().adminApiUrl
  }/Credential/GetConfigureCredentialListReports?orgId=${ORG_ID()}&userId=${userId}&tabId=${tabId}&startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}&groupId=${groupId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
