import * as stateCityAction from "../actions/stateCityAction";
import { stateCityType } from "../constants/stateCityType";
import { getState, getCity } from "../../services/stateCityService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* getStateSaga() {
  console.debug("getStateSaga");
  try {
    const { data, status, error } = yield call(getState);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      stateCityAction.getStateSuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Get State Failed" };
    yield put(stateCityAction.getStateFailure(data));
  }
}

export function* getCitySaga({ stateId }) {
  console.debug("getCitySaga");
  try {
    const { data, status, error } = yield call(getCity, stateId);
    if (!data && status !== 200) throw apiError(error);
    yield put(
      stateCityAction.getCitySuccess({
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Search City Failed" };
    yield put(stateCityAction.getCityFailure(data));
  }
}

const myStateCitySaga = [
  takeLatest(stateCityType.GET_STATE, getStateSaga),
  takeLatest(stateCityType.GET_CITY, getCitySaga),
];
export default myStateCitySaga;
