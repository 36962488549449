import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./viewCredential.scss";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import ViewImages from "../../incidents/view-incident-report/common/viewImages/viewImages";

export default function ViewLookupCredential({ dataBack, viewCredentialData }) {
  const data = viewCredentialData?.result;
  const imageSize = {
    size: "small",
  };
  const [image, setImage] = useState([]);
  useEffect(() => {
    setImage(
      data?.imageUrls?.length > 0 ? data?.imageUrls?.[0].split("|") : null
    );
  }, [data]);
  return (
    <div className="viewOverideContact">
      <BackBtn dataBack={dataBack} />
      <div className="commonOveride">
        <Box>
          <Typography className="titleDetail">
            {data?.firstName} {data?.lastName}
          </Typography>
        </Box>
        <Box className="commoninfoWrap">
          <Box className="commoninfoBox">
            <Box className="commoninfoleft">
              <Typography className="title">Credential information:</Typography>

              <Box className="columns">
                <Typography className="listView">
                  User Name:
                  <span className="font-w400">{data?.userName}</span>
                </Typography>
                <Typography className="listView">
                  User Id:
                  <span className="font-w400">{data?.userId}</span>
                </Typography>
              </Box>

              <Box className="columns">
                <Typography className="listView">
                  Credential Name:
                  <span className="font-w400">{data?.credentialName}</span>
                </Typography>
                <Typography className="listView">
                  Issuing Body:
                  <span className="font-w400">{data?.issuingBody}</span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  Credential Type:
                  <span className="font-w400">{data?.credentialType}</span>
                </Typography>
                <Typography className="listView">
                  <span className="font-w400">{data?.email}</span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  Credential Number:
                  <span className="font-w400">{data?.credentialNumber}</span>
                </Typography>
                <Typography className="listView">
                  {/* Address2: */}
                  <span className="font-w400">{data?.address2}</span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  Passing Date:
                  <span className="font-w400">{data?.passingDate}</span>
                </Typography>
                <Typography className="listView">
                  Class ID:
                  <span className="font-w400">{data?.classId}</span>
                </Typography>
              </Box>
              <Box className="columns">
                <Typography className="listView">
                  Expiration Date:
                  <span className="font-w400">{data?.expirationDate}</span>
                </Typography>
                <Typography className="listView">
                  <span className="font-w400">{data?.zipCode}</span>
                </Typography>
              </Box>

              <Box>
                <Typography className="listView ">
                  Media:
                  {image !== null && image[0]?.includes("pdf") ? (
                    <a href={image[0]} target="_blank">
                      Open PDF
                    </a>
                  ) : (
                    <span className="blockView">
                      <ViewImages image={image} imageSize={imageSize} />
                    </span>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}
