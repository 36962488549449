import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UtilityDamageForm from "../components/utilityDamageForm";
import Carousel from "../../../library/custom/carouselBox/Carousel";
import CarouselData from "../../../constant/carouselConstant";
import * as incidentAction from "../../../redux/actions/incidentAction";
import * as utilityDamageAction from "../../../redux/actions/utilityDamageAction";
import * as userAction from "../../../redux/actions/userAction";
import * as stateCityAction from "../../../redux/actions/stateCityAction";
import Loader from "../../../library/common/Loader";
import {
  API_STATUS,
  INCIDENT_REPORT_ADDED,
  INCIDENT_REPORT_UPDATE,
  MODULES_NAME,
} from "../../../constant/constant";
import {
  PERSONAL_INJURY,
  VEHICLE_ACCIDENT,
  UTILITY_DAMAGE,
  STOLEN_PROPERTY,
  NEAR_MISS,
  DASHBOARD,
} from "../../../constant/routeContant";
import SuccessScreen from "../../incidents/add-incident/component/successScreen";

import { useLocation, useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { validateFileSize } from "../../../utils/helper";
import { fileUpload } from "../../../services/fileUploadService";
export default function UtilityDamageContainer() {
  const location = useLocation();
  const existingCaseId = location?.state?.caseId;
  const incidentId = location?.state?.incidentId;
  const editMode = location?.state?.editMode;
  const [successScreens, setSuccessScreens] = useState(false);
  const isAllowed = checkPrivileges([1, 2, 3, 12]);
  const isLoading = useSelector((state) => state.users.isLoading);
  let navigate = useNavigate();
  const disptch = useDispatch();
  const incident = useSelector((state) => state.incident.data);
  const status = useSelector((state) => state.addUtilityDamage.status);
  const addUtilityDamageData = useSelector(
    (state) => state.addUtilityDamage.data
  );

  const updatedUtilityDamageStatus = useSelector(
    (state) => state.updatedUtilityDamageData.status
  );
  const updatedUtilityDamageData = useSelector(
    (state) => state.updatedUtilityDamageData.data
  );

  const utilityDamageData = useSelector(
    (state) => state.getUtilityDamageReport.data
  );

  const utilityDamageDataStatus = useSelector(
    (state) => state.getUtilityDamageReport
  );
  const searchOption = useSelector((state) => state.users.data);
  const searchOptionStatus = useSelector((state) => state.users);

  const allUsers = useSelector((state) => state.allUsers.data);

  const assetOnUserList = useSelector((state) => state.getAssetOnUser.data);
  const supervisorOnAssetList = useSelector(
    (state) => state.getSupervisorOnAsset.data
  );


  const searchAllUsers = (searchType, searchText, projectId = 0) => {
    disptch(
      userAction.searchAllUsers(searchText, searchType, 0, projectId, 1, 0)
    );
  };

  const isSuccess = useSelector(
    (state) =>
      state.incident.status === API_STATUS.SUCCESS &&
      (existingCaseId
        ? existingCaseId
        : state.createdCase.status === API_STATUS.SUCCESS)
  );
  const showLoader = useSelector(
    (state) =>
      state.addUtilityDamage.isLoading ||
      state.incident.isLoading ||
      state.createdCase.isLoading ||
      state.updatedUtilityDamageData.isLoading ||
      state.getUtilityDamageReport.isLoading ||
      state.fileUpload.isLoading
  );

  const [dataId, setDataId] = useState("");
  const addAndUpdateUtilityDamages = async (data) => {
    setDataId(data.incidentId);
    setSuccessScreens(true);

    if (data.incidentId === 0) {
      disptch(utilityDamageAction.utilityDamage(data));
    } else {
      disptch(utilityDamageAction.updateUtilityDamage(data));
    }
  };

  const getIncidentData = () => {
    disptch(incidentAction.getIncidentData());
  };

  const searchUsers = (searchType, searchText) => {
    disptch(userAction.searchUsers(searchType, searchText));
  };

  const getUtilityDamageDetails = (caseId, incidentId) => {
    disptch(utilityDamageAction.getUtilityDamageDetails(caseId, incidentId));
  };

  const [fileError, setFileError] = useState(null);
  const [countError, setCountError] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [fileUploadData, setFileUploadData] = useState();

  const uploadFile = async (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileError(validation.errorMessage);
      setCountError(countError + 1);
      return;
    }
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadData(files);
      })
      .catch((error) => {
        setCountError(countError + 1);
        setFileError("Error while uploading files.");
      });
  };

  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }
    disptch(utilityDamageAction.clearUtilityDamageDetails());

    if (existingCaseId && incidentId) {
      getUtilityDamageDetails(existingCaseId, incidentId);
    }
    getIncidentData();
  }, []);

  const [carouselValue, setcarouselValue] = useState({
    id: 4,
    title: "Utility Damage",
  });

  const handleOnChangeMethod = (value) => {
    if (!editMode) {
      setcarouselValue(value);
      if (value.id === 1) {
        navigate(PERSONAL_INJURY, {
          state: { caseId: existingCaseId, isDirect: true },
        });
      } else if (value.id === 2) {
        navigate(VEHICLE_ACCIDENT, { state: { caseId: existingCaseId } });
      } else if (value.id === 4) {
        navigate(UTILITY_DAMAGE, { state: { caseId: existingCaseId } });
      } else if (value.id === 3) {
        navigate(STOLEN_PROPERTY, { state: { caseId: existingCaseId } });
      } else if (value.id === 5) {
        navigate(NEAR_MISS, { state: { caseId: existingCaseId } });
      }
    }
    if (value.id === 2) {
      disptch(stateCityAction.getState());
    }
  };

  const successScree = (id) => {
    return (
      <SuccessScreen
        id={id}
        caseId={existingCaseId}
        isUpdate={dataId === 0 ? INCIDENT_REPORT_ADDED : INCIDENT_REPORT_UPDATE}
      ></SuccessScreen>
    );
  };

  // useEffect(() => {
  //   if (utilityDamageDataStatus.status === API_STATUS.SUCCESS) {
  //     const empName = utilityDamageDataStatus?.data?.employee;
  //     searchUsers("", empName?.split(" ")[0]);
  //   }
  // }, [utilityDamageDataStatus]);

  
  function getAssetOnUser(params) {
    console.log(params);
    const data = {
      assetType: 1,
      userId: params.userId,
    };
    disptch(userAction.getAssetOnUser(data));
  }
  function getSupervisorOnAsset(params) {
    const data = {
      assetType: 1,
      assetId: params,
    };
    disptch(userAction.getSupervisorOnAsset(data));
  }
  return (
    <>
      {status === API_STATUS.SUCCESS &&
      addUtilityDamageData &&
      successScreens ? (
        successScree(addUtilityDamageData?.incidentId)
      ) : updatedUtilityDamageStatus === API_STATUS.SUCCESS &&
        updatedUtilityDamageData &&
        successScreens ? (
        successScree(incidentId)
      ) : (
        <div className="dashboard-slider">
          <div>
            <div className="dashbord_heading">
              <div className="insi-stitle">
                <p>Select Incident Type</p>
                <div className="caseId">Case ID: EVE- {existingCaseId}</div>
              </div>
            </div>

            <Carousel
              sliderItems={CarouselData}
              activeSlide={carouselValue}
              handleOnChange={handleOnChangeMethod}
              edit={editMode ? true : false}
            />
          </div>
          <div className="loader-wrapper">{(showLoader || isLoader) && <Loader />}</div>
          <div>
            {isSuccess && (
              <UtilityDamageForm
                incident={incident}
                caseId={existingCaseId}
                incidentId={incidentId ? incidentId : 0}
                searchOption={searchOption}
                utilityDamageData={incidentId ? utilityDamageData : ""}
                onSubmit={addAndUpdateUtilityDamages}
                uploadFile={uploadFile}
                fileUploadData={fileUploadData}
                editMode={editMode}
                searchUsers={searchUsers}
                isLoading={isLoading}
                searchAllUsers={searchAllUsers}
                allUsers={allUsers}
                searchOptionStatus={searchOptionStatus}
                utilityDamageDataStatus={utilityDamageDataStatus}
                getAssetOnUser={getAssetOnUser}
                assetOnUserList={assetOnUserList?.jobSite ?? []}
                getSupervisorOnAsset={getSupervisorOnAsset}
                supervisorOnAssetList={supervisorOnAssetList?.result ?? []}
                fileError={fileError}
                countError={countError}
            />
            )}
          </div>
        </div>
      )}
    </>
  );
}
