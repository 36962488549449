import { reportsType } from "../types/reportsType";

export const getOrganisationSummaryList = (data) => ({
  type: reportsType.GET_ORGANISATION_SUMMARY_LIST_PENDING,
  data,
});

export const getOrganisationSummaryListSuccess = (data) => ({
  type: reportsType.GET_ORGANISATION_SUMMARY_LIST_SUCCESS,
  data,
});

export const getOrganisationSummaryListFailure = (error) => ({
  type: reportsType.GET_ORGANISATION_SUMMARY_LIST_FAILURE,
  error,
});

export const getInspectorSummaryList = (data) => ({
  type: reportsType.GET_INSPECTOR_SUMMARY_LIST_PENDING,
  data,
});

export const getInspectorSummaryListSuccess = (data) => ({
  type: reportsType.GET_INSPECTOR_SUMMARY_LIST_SUCCESS,
  data,
});

export const getInspectorSummaryListFailure = (error) => ({
  type: reportsType.GET_INSPECTOR_SUMMARY_LIST_FAILURE,
  error,
});

export const getDateWiseSummaryList = (data) => ({
  type: reportsType.GET_DATEWISE_SUMMARY_LIST_PENDING,
  data,
});

export const getDateWiseSummaryListSuccess = (data) => ({
  type: reportsType.GET_DATEWISE_SUMMARY_LIST_SUCCESS,
  data,
});

export const getDateWiseSummaryListFailure = (error) => ({
  type: reportsType.GET_DATEWISE_SUMMARY_LIST_FAILURE,
  error,
});

export const getPerDayInspectorSummaryList = (data) => ({
  type: reportsType.GET_PER_DAY_INSPECTOR_SUMMARY_LIST_PENDING,
  data,
});

export const getPerDayInspectorSummaryListSuccess = (data) => ({
  type: reportsType.GET_PER_DAY_INSPECTOR_SUMMARY_LIST_SUCCESS,
  data,
});

export const getPerDayInspectorSummaryListFailure = (error) => ({
  type: reportsType.GET_PER_DAY_INSPECTOR_SUMMARY_LIST_FAILURE,
  error,
});

export const getDateWiseInspectorSummaryList = (data) => ({
  type: reportsType.GET_DATEWISE_INSPECTOR_SUMMARY_LIST_PENDING,
  data,
});

export const getDateWiseInspectorSummaryListSuccess = (data) => ({
  type: reportsType.GET_DATEWISE_INSPECTOR_SUMMARY_LIST_SUCCESS,
  data,
});

export const getDateWiseInspectorSummaryListFailure = (error) => ({
  type: reportsType.GET_DATEWISE_INSPECTOR_SUMMARY_LIST_FAILURE,
  error,
});

export const getOrganisationMapList = (data) => ({
  type: reportsType.GET_ORGANISATION_MAP_LIST_PENDING,
  data,
});

export const getOrganisationMapListSuccess = (data) => ({
  type: reportsType.GET_ORGANISATION_MAP_LIST_SUCCESS,
  data,
});

export const getOrganisationMapListFailure = (error) => ({
  type: reportsType.GET_ORGANISATION_MAP_LIST_FAILURE,
  error,
});
