import React, { useEffect, useState, useRef } from "react";
import AddInspectionProof from "../components/addInspectionProof";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SuccessScreen from "../../../../library/custom/success/successScreen";
import { SW_INSPECTION_LIST } from "../../../../constant/routeContant";
import Loader from "../../../../library/common/Loader";
import * as uploadFilesAction from "../../../../redux/actions/uploadFilesAction";

const AddInspectionProofContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const inspectionId = location?.state?.inspectionId;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [successScreens, setSuccessScreens] = useState(false);
  const hiddenAttendeeSignatureInput = useRef([]);
  const [imageCheck, setImageCheck] = useState(false);
  const addProof = useSelector((state) => state.conductExternalInspection);
  const fileUploadData = useSelector((state) => state.fileUpload.data);
  const isSignatureProfile = userInfo?.signatureUrl;
  const [signatureError, setSignatureError] = useState(false);
  const [checkBoxError, setCheckBoxError] = useState(false);
  const [filess, setFiless] = useState([]);
  const [filessIds, setFilessIds] = useState([]);
  const [isFiles, setIsFiles] = useState(false);
  const [checkBox, setcheckBox] = useState(false);

  const showLoader = useSelector(
    (state) =>
      state.conductExternalInspection?.isLoading || state.fileUpload?.isLoading
  );

  const onSubmit = (data) => {
    setSuccessScreens(true);
    dispatch(swInspectionAction.createConductExternalInspection(data));
  };

  const [isContractorProfileSignature, setIsContractorProfileSignature] =
    useState(false);
  const [selectedContractorSignature, setSelectedContractorSignature] =
    useState("");

  const uploadContractorSignatureFile = (data) => {
    setIsContractorProfileSignature(true);
    dispatch(uploadFilesAction.createFileUpload(data));
  };

  const contractorsignatureUpload = (event) => {
    const fileUploaded = event.target.files[0];
    handleContractorSignatureUploadMethod(fileUploaded);
  };
  const handleContractorSignatureUploadMethod = (data) => {
    const formData = new FormData();
    formData.append("fileName", data);
    uploadContractorSignatureFile(formData);
  };

  useEffect(() => {
    if (isContractorProfileSignature) {
      if (fileUploadData && imageCheck) {
        setSelectedContractorSignature(fileUploadData?.files[0]?.filePath);
      }
    }
  }, [fileUploadData, imageCheck]);

  useEffect(() => {
    if (isSignatureProfile) {
      setSelectedContractorSignature(isSignatureProfile);
    }
  }, [isSignatureProfile]);

  const handleFileChange = (photos, photosids) => {
    setFilessIds(photosids);
    setFiless(photos);
  };

  const handleDeleteImageURL = (urls, index) => {
    const previousImg = filess?.split("|");
    setFiless(previousImg.filter((url) => url !== urls).join("|"));
    filessIds?.splice(index, 1);
  };

  const successScreen = (id, successMsg, label1, label4, label2) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={successMsg}
        route={SW_INSPECTION_LIST}
        label1={label1}
        label4={label4}
        label2={label2}
      ></SuccessScreen>
    );
  };
  const filePaths = filess?.length > 0 && filess?.split("|");
  const transformedFiles = filessIds?.map((fileId, index) => {
    return {
      fileId: fileId,
      filePath: filePaths[index],
      subrootId: 0,
    };
  });
  function mappedData() {
    let errors = false;

    if (!checkBox) {
      setCheckBoxError(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const data = {
      inspectionId: inspectionId,
      files: transformedFiles,
      signature: selectedContractorSignature,
    };
    return data;
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      onSubmit(data);
    }
  };

  return (
    <>
      {showLoader && <Loader />}

      {addProof.status === "SUCCESS" &&
      addProof.data &&
      addProof &&
      successScreens ? (
        successScreen(
          addProof?.data?.inspectionId,
          "Inspection Successfully",
          "Inspection Id",
          "Inspection List in",
          " "
        )
      ) : (
        <AddInspectionProof
          selectedContractorSignature={selectedContractorSignature}
          contractorsignatureUpload={contractorsignatureUpload}
          signatureError={signatureError}
          isSignatureProfile={isSignatureProfile}
          hiddenAttendeeSignatureInput={hiddenAttendeeSignatureInput}
          setImageCheck={setImageCheck}
          handleFileChange={(photo, filedId) =>
            handleFileChange(photo, filedId)
          }
          deleteImageURL={handleDeleteImageURL}
          filess={filess}
          submitData={submitData}
          isFiles={isFiles}
          checkBox={checkBox}
          setcheckBox={setcheckBox}
          checkBoxError={checkBoxError}
        />
      )}
    </>
  );
};

export default AddInspectionProofContainer;
