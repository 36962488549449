import { getApiUrl } from "../config";
import { post } from "./axiosRequest";
import { ORG_ID } from "../constant/constant";

export const getPersonalInjuryIncidentReportCount = async (data) => {
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/GetPiIncidentReportCount`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const getPersonalInjuryIncidentReportList = async (data) => {
  const url = `${
    getApiUrl().reportApiUrl
  }/IncidentReport/GetPiIncidentReportList`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
