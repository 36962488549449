import React from "react";
import Inspections from "../component/inspections";
import { useDispatch, useSelector } from "react-redux";
import * as incpectionAction from "../../../../redux/actions/incpectionAction";
import Loader from "../../../../library/common/Loader";
import { useLocation } from "react-router-dom";
import * as adminAction from "../../../../redux/actions/adminCommonAction";
import { LOGIN_ORG_ID, ORG_ID } from "../../../../constant/constant";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import * as assetsAction from "../../../../redux/actions/assetsAction";

const InspectionsContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const backDetail = location?.state;
  const getinspecionReport = useSelector((state) => state.inspecionReport);
  const getInspecionReportData = (data) => {
    dispatch(incpectionAction.getInspecionReport(data));
  };
  const isLoading = useSelector((state) => state.inspecionReport?.isLoading);

  const assetOption = useSelector((state) => state.getAssetDynomoList.data);
  const isAssetLoading = useSelector(
    (state) => state.getAssetDynomoList.isLoading
  );
  const searchAsset = (searchText, type, assetCatId, divisionId) => {
    dispatch(
      assetsAction.getAssetDynomoList({
        searchText,
        type,
        assetCatId,
        divisionId,
      })
    );
  };

  const organisationDivision = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const vehicleCategories = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.vehicleCategories
  );
  const equipmentCategories = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.equipmentCategories
  );
  const customAssetCategories = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.assetTypes
  );
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const getDivisionData = (data) => {
    dispatch(adminAction.getDropdownDataKeyValue(data));
  };

  const divisionList = userInfo.isClient
    ? organisationDivision
    : LOGIN_ORG_ID() === ORG_ID()
    ? userInfo.divisions?.filter((i) => i?.serviceType == PRODUCT_ID())
    : organisationDivision;
    
  const addDefaultCategory = (categories, defaultCategory) => {
    if (!Array.isArray(categories)) {
      categories = [];
    }
    return [{ key: "AllCategory", value: defaultCategory }, ...categories];
  };

  const enhancedVehicleCategories = addDefaultCategory(
    vehicleCategories,
    "All Vehicle"
  );
  const enhancedEquipmentCategories = addDefaultCategory(
    equipmentCategories,
    "All Equipment"
  );
  const enhancedCustomAssetCategories = addDefaultCategory(
    customAssetCategories,
    "All Custom Asset"
  );
  return (
    <>
      {isLoading && <Loader />}
      <Inspections
        divisions={divisionList}
        vehicleCategories={enhancedVehicleCategories}
        equipmentCategories={enhancedEquipmentCategories}
        customAssetCategories={enhancedCustomAssetCategories}
        inspecionReport={getinspecionReport?.data}
        getInspecionReportData={getInspecionReportData}
        backDetail={backDetail}
        isAssetLoading={isAssetLoading}
        assetOption={assetOption}
        searchAsset={searchAsset}
        getDivisionData={getDivisionData}
      />
    </>
  );
};

export default InspectionsContainer;