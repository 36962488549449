import React, { useState, useEffect } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import CreateAction from "../../../library/custom/createAction/CreateAction";
import Property from "./property";
import Button from "../../../library/custom/button/Button";
import { getFormatedDate } from "../../../utils/helper";
import {
  API_STATUS,
  GET_USER_TYPE,
  INCIDENT_STATUS,
} from "../../../constant/constant";
import intl from "react-intl-universal";
import useDebounce from "../../../utils/useDebounce";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
const StolenPropertyForm = ({
  incident,
  searchOption,
  caseData,
  onSubmit,
  lossPropertyData,
  incidentId,
  caseId,
  editMode,
  uploadFile,
  fileUploadData,
  searchUsers,
  isLoading,
  lossPropertyDataStatus,
  searchAllUsers,
  allUsers,
  getAssetOnUser,
  assetOnUserList,
  fileError,
  countError,
}) => {
  const { associatedIncidentType } = incident;
  const todatDate = new Date();
  const userId = localStorage.getItem("userId");
  const [type, setType] = useState(false);
  const [associatedIncidentTypes, setAssociatedIncidentTypes] = useState(
    associatedIncidentType
  );

  const intialState = {
    propertyType: "0",
    employeeName: "",
    jobSiteProjectName: null,
    lastknowLocation: "",
    employeeSignature: "",
    dateOfCase: todatDate,
    addDetails: "",
    timeOfCase: todatDate.getHours() + ":" + todatDate.getMinutes(),
    type: false,
    caseDescription: "",
    location: "",
    userId: "",
  };
  const [createdBy, setCreatedBy] = useState();
  const [searchtext, setSeachText] = useState("");
  const debounceAs = useDebounce(searchtext, 500);
  useEffect(() => {
    if (debounceAs) {
      searchUsers(GET_USER_TYPE.PREVNONPREVUSER, debounceAs);
    }
  }, [debounceAs]);
  const [disableEmpName, setDisableEmpName] = useState(false);
  useEffect(() => {
    if (lossPropertyData) {
      const {
        stolenProperty,
        actions,
        createdByName,
        propertyLocation,
        employeeName,
        wasProperty,
        userId,
        projectId,
        additionalDetails,
        project,
      } = lossPropertyData;
      setCreatedBy(createdByName);
      setDisableEmpName(true);
      if (lossPropertyDataStatus.status === API_STATUS.SUCCESS) {
        const newPropertyList = [];
        stolenProperty.forEach((items, index) => {
          newPropertyList.push({
            propertyId: items?.propertyId,
            propertyDescription: items?.description,
            componentId: items.componentId,
            estimatedCost: items.estimatedCost,
            dateOfDiscovery: items.dateOfDiscovery,
            propertyType: items.isPropertyStolen,
            id: index,
            stolenlostproperty: items.lastKnownPerson,
            policeReport: items.isPoliceReportFiled,
            filess: items?.files.map((file) => {
              return {
                fileUrl: file.value,
                id: file.key,
              };
            }),
            fileIds: items?.files.map((item) => item.key),
            files: items?.files.map((item) => item.value).join("|"),
          });
        });
        setPropertyList(newPropertyList);
        if (actions.length) {
          const actionList = [];
          actions.forEach((items, index) => {
            actionList.push({
              isFileAdded: items.isFileAdded,
              files: items.files,
              description: items.description,
              assignedPartyName: items.assignedPartyName,
              assignedPartyId: items.assignedPartyId,
              id: items.actionId,
              fileId: items?.files?.map((files) => files.fileId),
            });
          });

          setActionlist(actionList);
        }

        intialState.lastknowLocation = propertyLocation;
        intialState.propertyType = wasProperty ? "0" : "1";
        intialState.employeeName = employeeName;
        intialState.jobSiteProjectName = projectId;
        intialState.userId = userId;
        intialState.addDetails = additionalDetails;
        setFormValue(intialState);
        setJobSiteData([{ key: projectId, value: project }]);
        setEmployeeNameValue({
          userId: userId,
          firstName: employeeName,
        });
        if (associatedIncidentTypes) {
          setType(true);
        }
      }
    }
  }, [lossPropertyDataStatus]);

  const [actionList, setActionlist] = useState([
    {
      assignedPartyName: "",
      file: [],
      isFileAdded: false,
      selectedFileName: "",
      description: "",
      id: Date.now() + Math.floor(Math.random() * 100),
      isCompleted: false,
      isError: false,
      isFileError: false,
    },
  ]);

  const handleChangeCreateActionDesc = (value, data) => {
    let updatedActionList = actionList;
    updatedActionList.forEach((singleValue) => {
      if (singleValue.id === data.id) {
        singleValue[`description`] = value;
      }
    });
    setActionlist(updatedActionList);
  };

  useEffect(() => {
    if (fileUploadData) {
      let updatedActionList = [...actionList];
      updatedActionList.forEach((actionLists) => {
        if (actionLists.id === selectedData.id) {
          actionLists.file = selectedFileUploaded;
          actionLists.isFileAdded = true;
          actionLists.fileId = [fileUploadData?.files[0].id];
          actionLists.selectedFileName = selectedFileName;
          actionLists.isFileError = false;
        }
      });
      setActionlist(updatedActionList);
    }
  }, [fileUploadData]);

  useEffect(() => {
    if (countError) {
      let updatedActionList = [...actionList];
      updatedActionList.forEach((actionLists) => {
        if (actionLists.id === selectedData.id) {
          actionLists.isFileError = true;
        }
      });
      setActionlist(updatedActionList);
    }
  }, [countError]);

  const handleOnclickAddAction = () => {
    if (actionList.length < 4) {
      const newActionList = [...actionList];
      newActionList.push({
        assignedPartyName: "",
        file: null,
        selectedFileName: "",
        description: "",
        id: actionList.length + 1,
        isCompleted: false,
        isError: false,
        isFileError: false,
      });
      setActionlist(newActionList);
    }
  };
  const [jobSiteData, setJobSiteData] = useState([]);

  const [employeeNameValue, setEmployeeNameValue] = useState();

  const handleEmployeeName = (e, value) => {
    setEmployeeNameValue({
      userId: value?.userId ?? "",
      firstName: value?.firstName ?? "",
      lastName: value?.lastName ?? "",
    });
    setFormValue({
      ...formValue,
      employeeName: `${value?.firstName} ${value?.lastName}`,
      userId: value?.userId,
    });
    setJobSiteData(value?.jobSite);
  };

  const handleCreateActionCancelCta = (data) => {
    const newActionList = actionList.filter(
      (singleAction) => data.id !== singleAction.id
    );
    setActionlist(newActionList);
  };
  const removeActionImage = (singleAction) => {
    const actionListClone = [...actionList];
    actionListClone.forEach((Actionlist) => {
      if (Actionlist?.id === singleAction?.id) {
        Actionlist.fileId = null;
        Actionlist.file = [];
        Actionlist.isFileAdded = false;
        Actionlist.selectedFileName = "";
        Actionlist.isFileError = false;
      }
    });
    setActionlist(actionListClone);
  };

  const [selectedData, setSelectedData] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileUploaded, setSelectedFileUploaded] = useState([]);

  const handleUploadFileMethod = (fileUploaded, fileName, data) => {
    setSelectedData(data);
    setSelectedFileName(fileName);
    setSelectedFileUploaded(fileUploaded);
    uploadFile(fileUploaded);
  };

  const handleDeletePropertyImage = (urls, singleWitness) => {
    const cloneWitness = [...propertyList];
    cloneWitness.forEach((singleWitn) => {
      if (singleWitn.id === singleWitness.id) {
        singleWitn.files = singleWitn.files
          .split("|")
          .filter((url) => url !== urls)
          .join("|");
      }
    });
    setPropertyList(cloneWitness);
  };

  const [formValue, setFormValue] = useState(intialState);

  const handleAddAssigneeCta = (event, users, data) => {
    let updatedActionList = actionList;
    updatedActionList.forEach((singleValue) => {
      if (singleValue.id === data.id) {
        singleValue[`assignedPartyName`] = users?.value || {};
        singleValue[`assignedPartyId`] = users?.key || 0;
        singleValue.isError = false;
        singleValue.isFileError = false;
      }
    });
    setActionlist(updatedActionList);
  };
  const [propertyList, setPropertyList] = useState([
    {
      estimatedCost: "",
      propertyDescription: "",
      dateOfDiscovery: new Date(),
      dateOfDiscoveryError: false,
      stolenlostproperty: "",
      propertyType: null,
      policeReport: null,
      id: Date.now() + Math.floor(Math.random() * 100),
      isErrorPropertyDescription: false,
      isPropertyDescriptionMsg: "",
      fileIds: [],
      files: "",
      propertyId: 0,
      filess: [],
    },
  ]);
  const handleOnclickAddProperty = () => {
    if (propertyList.length < 4) {
      const newPropertyList = [...propertyList];
      newPropertyList.push({
        estimatedCost: "",
        propertyDescription: "",
        dateOfDiscovery: new Date(),
        dateOfDiscoveryError: false,
        stolenlostproperty: "",
        propertyType: null,
        policeReport: null,
        id: Date.now() + Math.floor(Math.random() * 100),
        isErrorPropertyDescription: false,
        isPropertyDescriptionMsg: "",
        fileIds: [],
        files: "",
        propertyId: 0,
        filess: [],
      });
      setPropertyList(newPropertyList);
    }
  };

  const [filesProperty, setFilesProperty] = useState("");
  const [propertyData, setPropertyData] = useState("");
  const [propertyDataFiledIds, setPropertyDataFiledIds] = useState("");

  const stolePropertyUpload = (files, fileIds, single) => {
    setFilesProperty(files);
    setPropertyData(single);
    setPropertyDataFiledIds(fileIds);
  };
  useEffect(() => {
    let updatedWitnessList = [...propertyList];
    updatedWitnessList.forEach((witnessLists) => {
      if (witnessLists.id === propertyData.id) {
        witnessLists.fileIds = propertyDataFiledIds;
        witnessLists.files = filesProperty;
      }
    });
    setPropertyList(updatedWitnessList);
  }, [filesProperty]);

  const propertyOnChanges = (e, data, values) => {
    const elementId = e?.target?.id;
    const value = e?.target?.value;
    let updatedPropertyList = [...propertyList];
    updatedPropertyList.forEach((propertyLists) => {
      if (propertyLists.id === data.id) {
        if (values === "datePicker") {
          propertyLists.dateOfDiscoveryError = false;
          propertyLists.dateOfDiscovery = e ? getFormatedDate(e) : "";
        }
        if (values === "uploadFile") {
          propertyLists.files = e;
        }
        if (values === "estimatedCost") {
          if (e <= 0) return;
          propertyLists.estimatedCost = e;
        } else if (values === "propertyDescription") {
          propertyLists.propertyDescription = e;
        } else if (elementId === "stolenlostproperty") {
          propertyLists.stolenlostproperty = value;
        } else if (
          elementId === "propertyTypeYes" ||
          elementId === "propertyTypeNo"
        ) {
          propertyLists.propertyType = JSON.parse(value);
        } else if (
          elementId === "policeReportYes" ||
          elementId === "policeReportNo"
        ) {
          propertyLists.policeReport = JSON.parse(value);
        }
      }
    });
    setPropertyList(updatedPropertyList);
  };

  const cancelPropertyList = (id) => {
    const removePropertyList = propertyList.filter(
      (property) => property.id !== id
    );
    setPropertyList(removePropertyList);
  };
  const [isEmployeeName, setIsEmployeeName] = useState(false);
  const [isJobSiteProjectName, setIsJobSiteProjectName] = useState(false);

  function mappedData(isSaveAsDraft) {
    let errors = false;
    if (!isSaveAsDraft) {
      if (!formValue.employeeName) {
        setIsEmployeeName(true);
        errors = true;
      }
      if (!formValue.jobSiteProjectName) {
        setIsJobSiteProjectName(true);
        errors = true;
      }
      let updatedPropertyList = [...propertyList];
      updatedPropertyList?.forEach((data) => {
        if (data.propertyDescription?.trim() === "") {
          data.propertyDescription = "";
          data.isErrorPropertyDescription = true;
          data.isPropertyDescriptionMsg = "Please enter this required field.";
          errors = true;
        }
        if (!data.dateOfDiscovery) {
          data.dateOfDiscovery = "";
          data.dateOfDiscoveryError = true;
          errors = true;
        }
      });
      setPropertyList(updatedPropertyList);

      let cloneActionList = [...actionList];
      cloneActionList.forEach((items) => {
        if (items.description || items.isFileAdded) {
          if (!items.assignedPartyId) {
            items.isError = true;
            errors = true;
          } else {
            items.isError = false;
          }
        }
      });

      if (errors) {
        window.scrollTo({
          top: 1050,
          left: 0,
          behavior: "smooth",
        });
        return false;
      }
    }

    const associatedIncidentTypeData = [];
    associatedIncidentTypes.forEach((data) => {
      if (data.isSelected === true) {
        associatedIncidentTypeData.push(data.key);
      }
    });

    const actionListData = [];
    actionList.forEach((data) => {
      actionListData.push({
        assignedPartyId: data.assignedPartyId,
        isFileAdded: data.isFileAdded,
        files: data.fileId,
        description: data.description,
      });
    });
    const filterActionData = actionListData?.filter(
      (item) => item?.assignedPartyId !== undefined
    );
    const propertyListData = [];
    propertyList.forEach((data) => {
      propertyListData.push({
        componentId: editMode ? data.componentId : "0",
        description: data?.propertyDescription,
        estimatedCost: data?.estimatedCost,
        dateOfDiscovery: data?.dateOfDiscovery,
        isPropertyStolen: data?.propertyType,
        lastKnownPerson: data?.stolenlostproperty,
        isPoliceReportFiled: data?.policeReport,
        fileIds: data.fileIds,
        propertyId: data.propertyId,
      });
    });
    const data = {
      caseId: caseId === 0 ? caseData.caseId : caseId,
      incidentId: incidentId,
      status: INCIDENT_STATUS.SUBMITTED,
      createdBy: parseInt(userId),
      createdByName: "string",
      userId: parseInt(formValue.userId),
      projectId: formValue.jobSiteProjectName,
      propertyLocation: formValue.lastknowLocation,
      additionalDetails: formValue.addDetails,
      wasProperty: formValue.propertyType == "0" ? true : false,
      stolenProperty: propertyListData,
      actions: filterActionData?.length > 0 ? filterActionData : [],
      orgId: 0,
    };
    return { stolenProperty: data };
  }
  const [isSubmit, setIsSubmit] = useState(false);
  const submitData = () => {
    const data = mappedData(false);
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const saveData = () => {
    const data = mappedData(true);
    data.stolenProperty.status = INCIDENT_STATUS.DRAFT;
    setIsSubmit(true);
    onSubmit(data);
  };

  useEffect(() => {
    if (assetOnUserList) {
      setJobSiteData(assetOnUserList);
      setDisableEmpName(false);
    }
  }, [assetOnUserList]);

  const searchUsersByProject = (type, text) => {
    searchAllUsers(type, text);
  };
  useEffect(() => {
    if (
      employeeNameValue !== undefined &&
      employeeNameValue !== null &&
      employeeNameValue !== 0 &&
      employeeNameValue !== "" &&
      employeeNameValue.userId !== ""
    ) {
      getAssetOnUser(employeeNameValue);
    }
  }, [employeeNameValue]);
  return (
    <>
      <div className="overidePersonal">
        <Grid container mt={3}>
          <FormControl>
            <FormLabels label="Was the Property " />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={formValue.propertyType === "0"}
                value="0"
                label="Stolen"
                onChange={(e) =>
                  setFormValue({ ...formValue, propertyType: e.target.value })
                }
              ></RadioButton>
              <RadioButton
                checked={formValue.propertyType === "1"}
                value="1"
                label="Lost"
                onChange={(e) =>
                  setFormValue({ ...formValue, propertyType: e.target.value })
                }
              ></RadioButton>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <>
              <FormLabels label="Employee's Name" isRequired={true} />
              <SearchAutoComplete
                optionsData={isLoading ? [] : searchOption?.users ?? []}
                isUserDetails={true}
                value={employeeNameValue}
                isLoading={isLoading}
                typeOnchange={(event) => {
                  setSeachText(event.target.value);
                }}
                onChange={(event, value) => handleEmployeeName(event, value)}
                isError={isEmployeeName}
                errorMsg={
                  isEmployeeName && !formValue.employeeName
                    ? "Selection is required for this field ."
                    : ""
                }
              />
            </>
            {/* )} */}
          </Grid>

          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels
                label={intl.get("incident.stolenProperty.jobsiteName")}
                isRequired={true}
              />
              <SelectMenu
                listData={assetOnUserList}
                value={formValue.jobSiteProjectName}
                disabled={disableEmpName}
                onchangehandler={(e) =>
                  setFormValue({
                    ...formValue,
                    jobSiteProjectName: e.target.value,
                  })
                }
                isError={isJobSiteProjectName}
                errorMsg={
                  isJobSiteProjectName && !formValue.jobSiteProjectName
                    ? "Please enter this required field."
                    : ""
                }
                placeholder={"Please Select"}
              />
            </FormControl>
          </Grid>
        </Grid>

        {propertyList.map((singleProperty, index) => (
          <Grid item container mt={3} key={index}>
            <Property
              estimatedCost={singleProperty.estimatedCost}
              propertyDescription={singleProperty.propertyDescription}
              dateOfDiscovery={singleProperty.dateOfDiscovery}
              stolenlostproperty={singleProperty.stolenlostproperty}
              propertyType={singleProperty.propertyType}
              policeReport={singleProperty.policeReport}
              filess={singleProperty.filess}
              dateOfDiscoveryError={singleProperty.dateOfDiscoveryError}
              propertyOnChanges={(e, value) =>
                propertyOnChanges(e, singleProperty, value)
              }
              keyNo={index + 1}
              isErrorPropertyDescription={
                singleProperty.isErrorPropertyDescription
              }
              isPropertyDescriptionMsg={singleProperty.isPropertyDescriptionMsg}
              id={singleProperty.id}
              files={singleProperty.files}
              cancelPropertyList={(id) => cancelPropertyList(id)}
              deleteImageURL={(url) =>
                handleDeletePropertyImage(url, singleProperty)
              }
              stolePropertyUpload={(photo, filedId) =>
                stolePropertyUpload(photo, filedId, singleProperty)
              }
            />
          </Grid>
        ))}

        <div className="addBox">
          <Button
            varientTextGreen={true}
            disabled={propertyList.length === 4 ? true : false}
            label={intl.get("incident.stolenProperty.addProperty")}
            onClick={handleOnclickAddProperty}
          />
        </div>

        <Grid container mt={3}>
          <Grid item lg={8} xs={12} sm={12} md={8}>
            <FormLabels
              label={intl.get(
                "incident.stolenProperty.lastKnowLocationOfProperty"
              )}
            />
            <TextEditor
              placeholder={
                "Add Last known Location of Property/Possession Text Here.."
              }
              value={formValue.lastknowLocation}
              onChange={(value) =>
                setFormValue({ ...formValue, lastknowLocation: value })
              }
            />
          </Grid>
        </Grid>

        {actionList.map((singleAction, index) => (
          <CreateAction
            key={index}
            singleAction={singleAction}
            handleChangeActionDescription={handleChangeCreateActionDesc}
            handleAddAssigneeCta={handleAddAssigneeCta}
            handleUploadFile={handleUploadFileMethod}
            handleCancelCta={handleCreateActionCancelCta}
            incidentId={lossPropertyData?.incidentId}
            optionsData={allUsers?.users ?? []}
            searchUsers={searchUsersByProject}
            isLoading={isLoading}
            isError={singleAction.isError}
            removeActionImage={removeActionImage}
            fileError={fileError}
          />
        ))}

        <div className="addBox">
          <Button
            varientTextGreen={true}
            disabled={actionList.length === 4 ? true : false}
            label={intl.get("incident.case.addAction")}
            onClick={handleOnclickAddAction}
          />
        </div>

        <Grid container mt={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <FormControl fullWidth>
              <FormLabels
                label={intl.get("incident.case.addAdditinalDetails")}
              />
              <TextEditor
                placeholder={"Add Additional Details Text Here..."}
                value={formValue.addDetails}
                onChange={(value) => {
                  setFormValue({ ...formValue, addDetails: value });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={3} spacing={10} alignItems="center">
          <Grid lg={4} xs={12} sm={6} md={4} textAlign="center">
            <Button
              disabled={isSubmit ? true : false}
              varientText={true}
              label={intl.get("incident.case.saveAsDraft")}
              onClick={saveData}
            />
          </Grid>
          <Grid lg={4} xs={12} sm={6} md={4} textAlign="right">
            <Button
              disabled={isSubmit ? true : false}
              varientContained={true}
              label={intl.get("incident.case.submit")}
              onClick={submitData}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default StolenPropertyForm;
