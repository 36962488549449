import { SwCommonType } from "../types/swCommonType";

export const getSwReference = (data) => ({
  type: SwCommonType.GET_SW_REFERENCE_DATA_PENDING,
  data,
});

export const getSwReferenceSuccess = (data) => ({
  type: SwCommonType.GET_SW_REFERENCE_DATA_SUCCESS,
  data,
});

export const getSwReferenceFailure = (error) => ({
  type: SwCommonType.GET_SW_REFERENCE_DATA_FAILURE,
  error,
});

export const getProjectSearchList = (data) => ({
  type: SwCommonType.GET_PROJECT_SEARCH_LIST_PENDING,
  data,
});

export const getProjectSearchListSuccess = (data) => ({
  type: SwCommonType.GET_PROJECT_SEARCH_LIST_SUCCESS,
  data,
});

export const getProjectSearchListFailure = (error) => ({
  type: SwCommonType.GET_PROJECT_SEARCH_LIST_FAILURE,
  error,
});

export const getContactSearchList = (data) => ({
  type: SwCommonType.GET_CONTACT_SEARCH_LIST_PENDING,
  data,
});

export const getContactSearchListSuccess = (data) => ({
  type: SwCommonType.GET_CONTACT_SEARCH_LIST_SUCCESS,
  data,
});

export const getContactSearchListFailure = (error) => ({
  type: SwCommonType.GET_CONTACT_SEARCH_LIST_FAILURE,
  error,
});
