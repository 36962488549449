import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import TextArea from "../../../../library/custom/textArea/TextArea";
import { Grid } from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import { getCurrentDate } from "../../../../utils/helper";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormDropZone from "../../../../library/dropZone/FormDropZone";

const CommentClose = (props) => {
  return (
    <>
      {props?.cartType !== 2 && props?.cartType !== undefined && (
        <div className="commentCloseWrapper">
          <div
            className={
              props?.commentBox ? "commentClose active" : "commentClose"
            }
            onClick={() => props?.commentHandler()}
            disbled={props?.disbled ? "disbled" : ""}
          >
            {props?.commentBox ? (
              <RemoveIcon className="addIconBtns" />
            ) : (
              <AddIcon className="addIconBtns" />
            )}{" "}
            Add Comment
          </div>
          <div
            className={props?.closeBox ? "commentClose active" : "commentClose"}
            onClick={() => props?.closeItemHandler()}
          >
            {props?.closeBox ? (
              <RemoveIcon className="addIconBtns" />
            ) : (
              <AddIcon className="addIconBtns" />
            )}{" "}
            Close Item
          </div>
        </div>
      )}

      <div>
        {props?.selectedRows?.map((item) => {
          return item?.comment?.map((items, index) => {
            return (
              <div className="allCommentWrapper" key={index}>
                <div className="allCommentList">
                  <div className="detail">
                    <span>Item Id:</span>
                    {item?.itemId}
                  </div>
                  <div className="detail">
                    <span>Comment:</span>
                    {items?.comment}
                  </div>
                  <div className="detail">
                    <span>Date of Comment:</span>
                    {items?.addedOn}
                  </div>
                  <div className="detail">
                    <span>Commented by:</span>
                    {items?.addedBy}
                  </div>
                </div>
              </div>
            );
          });
        })}
      </div>

      <div>
        {props?.selectedRows?.map((item, index) => {
          return (
            item?.dateClosedFormat && (
              <div className="allCommentWrapper" key={index}>
                <div className="allCommentList">
                  <div className="detail">
                    <span>Item Id:</span>
                    {item?.itemId}
                  </div>
                  <div className="detail">
                    <span>Date Closed:</span>
                    {item?.dateClosedFormat}
                  </div>
                  {item?.closedAction && (
                    <div className="detail">
                      <span>Corrective Action Taken:</span>
                      {item?.closedAction}
                    </div>
                  )}

                  {item?.correctiveActionTaken && (
                    <div className="detail">
                      <span>Other Details:</span>
                      {item?.correctiveActionTaken}
                    </div>
                  )}

                  {item?.closedFiles?.length > 0 && (
                    <div className="detail">
                      <span>Media:</span>
                      {item?.closedFiles?.map((file) => {
                        return (
                          <div className="closedImg" key={file?.fileId}>
                            <img src={file?.filePath} alt="" />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>

      <div className="bothFormBox">
        {!props?.disbled &&
          props?.commentBox &&
          props?.cartType !== 2 &&
          props?.cartType !== undefined && (
            <div className="commintingBox">
              <FormLabels label="Comment" isRequired={true} />
              <TextArea
                placeholder={"Enter Here"}
                name="freeText"
                value={props?.commentFormValue?.comment}
                onChange={(e) =>
                  props?.setCommentFormValue({
                    ...props?.commentFormValue,
                    comment: e.target.value,
                  })
                }
              />
              {props?.isComment && !props?.commentFormValue?.comment && (
                <p className="error">Comment is Required. </p>
              )}
              <Grid container mt={1} mb={1} className="buttonEnd">
                <Grid item lg={3} xs={12} sm={6} md={6}>
                  <Buttons
                    varientText={true}
                    label="Cancel"
                    onClick={props?.cancelComment}
                  />
                </Grid>
                <Grid item lg={3} xs={12} sm={6} md={6}>
                  <Buttons
                    varientContained={true}
                    label={"Save"}
                    onClick={props?.submitCommentData}
                  />
                </Grid>
              </Grid>
            </div>
          )}

        {props?.closeBox &&
          props?.cartType !== 2 &&
          props?.cartType !== undefined && (
            <div className="closeItemBox">
              <Grid container mb={2}>
                <Grid item md={5} xs={12}>
                  <FormLabels label={"Date Closed"} isRequired={true} />
                  <DatePicker
                    minDate
                    onChangeHandler={(e) =>
                      props?.setCloseFormValue({
                        ...props?.closeFormValue,
                        dateClosed: e,
                      })
                    }
                    //   disabled={editMode ? true : false}
                    value={
                      props?.closeFormValue?.dateClosed
                        ? props?.closeFormValue?.dateClosed
                        : null
                    }
                    maxDateEnable={false}
                    isError={
                      props?.isDateClosed && !props?.closeFormValue?.dateClosed
                    }
                    errorMsg={
                      props?.isDateClosed && !props?.closeFormValue?.dateClosed
                        ? "Start date is required"
                        : ""
                    }
                  />

                  <Grid md={12} xs={12} item mt={2}>
                    <FormControl fullWidth>
                      <FormLabels
                        label="Corrective Action Taken"
                        isRequired={false}
                      />
                      <SelectMenu
                        placeholder="Please Select"
                        listData={
                          props?.getSwReference?.ItemClosedActionList ?? []
                        }
                        value={props?.closeFormValue?.closedId ?? null}
                        onchangehandler={(e) =>
                          props?.openItemHandler(e.target.value)
                        }
                      />
                    </FormControl>
                    {/* {props?.isClosedIdError &&
                      !props?.closeFormValue?.closedId && (
                        <div className="error">
                          Please select Corrective Action Taken field.
                        </div>
                      )} */}
                  </Grid>
                </Grid>
              </Grid>

              {props?.closeFormValue?.closedIdValue?.toLowerCase() ===
                "other" && (
                <>
                  <Grid md={12} item className="closedItemDropbox">
                    <FormLabels label="Other details" isRequired={false} />
                    <TextArea
                      placeholder={"Enter Here"}
                      name="freeText"
                      value={props?.closeFormValue?.actionTaken}
                      onChange={(e) =>
                        props?.setCloseFormValue({
                          ...props?.closeFormValue,
                          actionTaken: e.target.value,
                        })
                      }
                    />
                    {props?.isMedia && (
                      <FormDropZone
                        label={""}
                        paraText1={"Drop the files here ..."}
                        paraText2={"Drop the files here, "}
                        paraText3={"or Browse"}
                        handleFileChange={props?.handleFileChange}
                        fileType={10}
                        imageUrl={props?.closesFilesData?.filePath}
                        deleteImageURL={(url) =>
                          props?.handleDeleteImageURL(url)
                        }
                        id="addClosed"
                        key="addClosed"
                        isMuliple={false}
                        isDoc={true}
                        isImage={false}
                        allFiles={true}
                        isMultipleIcon={true}
                      />
                    )}
                  </Grid>
                  {props?.isActionTaken &&
                    !props?.closeFormValue?.actionTaken && (
                      <p className="error">
                        Corrective Action Taken is Required.{" "}
                      </p>
                    )}
                </>
              )}

              <Grid container mt={1} mb={1} className="buttonEnd">
                <Grid item lg={3} xs={12} sm={6} md={6}>
                  <Buttons
                    varientText={true}
                    label="Cancel"
                    onClick={props?.cancelClose}
                  />
                </Grid>
                <Grid item lg={3} xs={12} sm={6} md={6}>
                  <Buttons
                    varientContained={true}
                    label={"Save"}
                    onClick={props?.submitCloseData}
                  />
                </Grid>
              </Grid>
            </div>
          )}
      </div>
    </>
  );
};

export default CommentClose;
