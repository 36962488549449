import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import RadioGroup from "@mui/material/RadioGroup";
import Buttons from "../../../../library/custom/button/Button";
import { API_STATUS, USER_ID } from "../../../../constant/constant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { TOOL_BOX_LIST } from "../../../../constant/routeContant";
import "./toolBoxTalkAttendees.scss";
import { useNavigate } from "react-router-dom";
import FormDropBox from "../../../../library/custom/formDropBox/FormDropBox";
import { InputField } from "../../../../library/custom/textBox/InputField";
import NormalGrid from "../../../../library/custom/gridTable/NormalGrid";
import commonImages from "../../../../assets";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ViewImages from "../../../incidents/view-incident-report/common/viewImages/viewImages";
import FormDropZone from "../../../../library/dropZone/FormDropZone";
export default function AttendeesToolBoxTalk({
  verifyAttendeeListData,
  getScheduleMeetingDetailData,
  projectData,
  getverrifyListData,
  assignedOn,
  onExtraUserSubmit,
  addExtraUser,
  onVeirfyAttendanceSubmit,
  isTableLoading,
  verifyAttendeeFiles,
  tabId,
}) {
  const intialState = {
    userType: 0,
    individualUserIds: [],
  };

  const [schedulemeetignId, setSchedulemeetignId] = useState();
  const [assetId, setAssetId] = useState(null);
  const [titleSafetyMeeting, setTitleSafetyMeeting] = useState();
  const [preExtraUser, setPreExtraUser] = useState([]);
  const [tableUserIds, setTableUserIds] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [formValue, setFormValue] = useState(intialState);
  const [groupUser, setGroupUser] = React.useState([]);
  const [groupId, setGroupId] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [finalGroupData, setFinalGroupDraa] = useState([]);
  const [currentGroupData, setCurrentGroupData] = useState([]);

  useEffect(() => {
    if (getScheduleMeetingDetailData?.status === API_STATUS.SUCCESS) {
      setSchedulemeetignId(getScheduleMeetingDetailData?.id);
      setAssetId(getScheduleMeetingDetailData?.projectId);
      setTitleSafetyMeeting(getScheduleMeetingDetailData?.titleSafetyMeeting);
      setPreExtraUser(getScheduleMeetingDetailData?.extranalUsers);
    }
  }, [getScheduleMeetingDetailData]);

  useEffect(() => {
    const userId = groupUser?.map((val) => val.userId);

    setSelectionModel(userId);
  }, [groupUser]);

  useEffect(() => {
    let tempGroupUserData = finalGroupData;

    var userGroupObj = tempGroupUserData?.findIndex(
      (rowObj) => rowObj.groupId == groupId
    );
    if (userGroupObj > -1) {
      tempGroupUserData?.splice(userGroupObj, 1);
    }

    if (groupUser.length > 0 && groupId) {
      let tempobj = {
        groupId: groupId,
        userIds: groupUser?.map((val) => val.userId),
      };

      tempGroupUserData.push(tempobj);
    }

    setFinalGroupDraa(tempGroupUserData);
  }, [groupUser, groupId]);

  useEffect(() => {
    if (verifyAttendeeListData && formValue?.userType === 0) {
      const userIds = verifyAttendeeListData?.users?.filter(
        (userId) => userId.attendanceStatus === true
      );
      if (userIds?.length > 0) {
        setTableUserIds(userIds.map((x) => x.userId));
      }
    }

    if (verifyAttendeeListData && formValue?.userType === 2) {
      const userIds = verifyAttendeeListData?.contractors?.filter(
        (userId) => userId.attendanceStatus === true
      );
      if (userIds?.length > 0) {
        setTableUserIds(userIds.map((x) => x.userId));
      }
    }

    if (verifyAttendeeListData && formValue?.userType === 1) {
      const userIds = verifyAttendeeListData?.groups?.filter(
        (userId) => userId.attendanceStatus === true
      );
      if (userIds?.length > 0) {
        setTableUserIds(userIds.map((x) => x.groupId));
      }
    }
  }, [verifyAttendeeListData, formValue?.userType]);

  const history = useNavigate();

  const dataBack = {
    title: "Back to Safety Meeting",
    route: TOOL_BOX_LIST,
    backTabId: tabId,
  };

  useEffect(() => {
    if (assetId) {
      getverrifyListData({
        scheduleId: schedulemeetignId,
        scheduleDate: assignedOn,
        userType: formValue.userType ? formValue.userType : 0,
        page: 1,
        search: "",
        pageSize: 10,
        userId: USER_ID(),
        projectId: assetId,
      });
    }
  }, [formValue.userType, schedulemeetignId, assignedOn, assetId]);

  const [media, setmedia] = useState();
  const [imgUrl, setImgUrl] = useState();
  const [viewImages, setViewImages] = useState([]);
  const imageSize = {
    size: "medium",
  };
  const setImages = (photo, fileDataId) => {
    setmedia(fileDataId);
    setImgUrl(photo);
  };
  useEffect(() => {
    setViewImages(imgUrl?.split("|"));
  }, [imgUrl]);

  const handleDeleteImageURL = (urls, index) => {
    media.splice(index, 1);
    setmedia(media);
    const previousImg = imgUrl.split("|");
    setImgUrl(previousImg.filter((url) => url !== urls).join("|"));
  };

  const intialExtraUserState = {
    userName: "",
  };
  const [isExtraUserSubmit, setIsExtraUserSubmit] = useState(false);
  const [formExtraUserValue, setFormExtraUserValue] =
    useState(intialExtraUserState);
  const [isExtraUserName, setIsExtraUserName] = useState(false);
  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();

  const handleChange = (item) => {
    const removeUser = preExtraUser?.filter((x) => x.key !== item.key);
    setPreExtraUser(removeUser);
  };

  function mappedExtraUserData() {
    let errors = false;
    if (formExtraUserValue?.userName.trim() === "") {
      setFormExtraUserValue({
        ...formExtraUserValue,
        userName: "",
      });
      seterrorsMsgShowActive(false);
      setIsExtraUserName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    return {
      userName: formExtraUserValue?.userName,
      createdBy: parseInt(USER_ID()),
    };
  }

  const submitExtraUserData = () => {
    const data = mappedExtraUserData();
    if (!data) {
    } else {
      setIsExtraUserSubmit(true);
      onExtraUserSubmit(data);
    }
  };
  const [isVeirfyAttendance, setIsVeirfyAttendance] = useState();

  const groupuserData = groupUser?.map((val) => val.userId);
  function mappedVeirfyAttendanceData() {
    let errors = false;
    if (errors) {
      return false;
    }
    const userExtra = preExtraUser?.map((x) => x.key);

    let sendData;
    if (formValue.userType == 1) {
      sendData = {
        scheduleId: schedulemeetignId,
        scheduleDate: assignedOn,
        userType: formValue.userType,
        groupWiseUserIds: finalGroupData,
        extranalUserIds: userExtra,
        verifiedBy: parseInt(USER_ID()),
        fileIds: media,
        projectId: assetId,
      };
    } else {
      sendData = {
        scheduleId: schedulemeetignId,
        scheduleDate: assignedOn,
        userType: formValue.userType,
        userTypeIds: tableUserIds,
        extranalUserIds: userExtra,
        verifiedBy: parseInt(USER_ID()),
        fileIds: media,
        projectId: assetId,
      };
    }

    return sendData;
  }

  const submitVeirfyAttendanceData = () => {
    const data = mappedVeirfyAttendanceData();
    if (!data) {
    } else {
      setIsVeirfyAttendance(true);
      onVeirfyAttendanceSubmit(data);
    }
  };

  useEffect(() => {
    if (
      addExtraUser?.status === "SUCCESS" &&
      addExtraUser?.isLoading === false
    ) {
      setFormExtraUserValue({
        ...formExtraUserValue,
        userName: "",
      });
      setIsExtraUserSubmit(false);
    }

    if (addExtraUser?.status === "SUCCESS") {
      const updateExtraUsers = [...preExtraUser];
      updateExtraUsers?.push({
        key: addExtraUser?.data?.result?.userId,
        value: `${addExtraUser?.data?.result?.fristName} ${addExtraUser?.data?.result?.lastName}`,
      });
      setPreExtraUser(updateExtraUsers);
    }
  }, [addExtraUser]);

  const columns = [
    { field: "userName", headerName: "Name", width: 200 },
    { field: "jobTitle", headerName: "Job Title", flex: 1 },
    {
      field: "attendanceStatus",
      headerName: "Status",
      flex: 1,
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.attendanceStatus === true ? (
              <img src={commonImages?.AttendanceVerifiedBtn} />
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  const columnsPopup = [
    { field: "userName", headerName: "Name", width: 100 },
    { field: "jobTitle", headerName: "Job Title", flex: 1 },
    { field: "attendanceStatus", headerName: "Status", flex: 1 },
  ];
  const columnsContractor = [
    { field: "userName", headerName: "Name", width: 200 },
    { field: "jobTitle", headerName: "Job Title", flex: 2 },
    {
      field: "attendanceStatus",
      headerName: "Status",
      flex: 2,
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.attendanceStatus === true ? (
              <img src={commonImages?.AttendanceVerifiedBtn} />
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];
  const columnsGroup = [
    { field: "groupName", headerName: "Group Name", width: 200 },
    {
      field: "userCount",
      headerName: "Total Users",
      flex: 2,
      cellClassName: "assigned-to--cell-Group",
    },
    {
      field: "userAttendanceCount",
      headerName: "Verified Users",
      flex: 2,
    },

    {
      field: "attendanceStatus",
      headerName: "Status",
      flex: 2,
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.attendanceStatus === true ? (
              <img src={commonImages?.AttendanceVerifiedBtn} />
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  const onRowClick = (e) => {
    if (e.field == "userCount") {
      setCurrentGroupData(e?.row?.users);
      setGroupId(e?.id);
      setOpen(true);
      if (e?.row?.attendanceStatus === true) {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    if (verifyAttendeeFiles) {
      const imageUrl = verifyAttendeeFiles?.map((item) => item.value).join("|");
      const imageId = verifyAttendeeFiles?.map((item) => item.key);
      setmedia(imageId);
      setImgUrl(imageUrl);
    }
  }, [verifyAttendeeFiles]);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const onChangeIssuingBody = (event) => {
    setAssetId(event.target.value);
  };
  return (
    <div className="overidePersonal">
      <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
        <div className="heading_style">Verify Attendees</div>
      </Grid>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Totals Users</DialogTitle>
        <div className="diologattendee">
          <DialogContent lg={6} xs={12} sm={6} md={4}>
            <div className="desc_box row_uniq1">
              <div className="table_style_ui">
                <NormalGrid
                  getRowId={(r) => r.userId}
                  rowss={currentGroupData ?? []}
                  columnss={columnsPopup}
                  selectionModel={selectionModel}
                  onSelectionModelChange={(e) => {
                    //  setSelectionModel(e);
                    const selectedIDs = new Set(e);
                    const selectedRows = currentGroupData?.filter((r) =>
                      selectedIDs.has(r.userId)
                    );
                    setSelectedRows(selectedRows);
                    setGroupUser(selectedRows);
                  }}
                  isRowSelectable={(rows) =>
                    rows.row.attendanceStatus === false
                  }
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                ></NormalGrid>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>

      <Grid item container mt={3} spacing={2} pl={4}>
        <Grid lg={12} className="addShadowBox">
          <Grid container mt={2}>
            <Grid item lg={5} xs={12} sm={12} md={4} mt={1} pl={2}>
              <FormControl fullWidth>
                <FormLabels label="Select Project(Asset)" isRequired={true} />
                <SelectMenu
                  placeholder={"Please Select"}
                  listData={projectData}
                  value={assetId ?? ""}
                  disabled={assetId > 0 ? true : false}
                  onchangehandler={(e) => onChangeIssuingBody(e)}
                />
              </FormControl>
            </Grid>

            <Grid item lg={5} xs={12} sm={12} md={4} mt={1} pl={2}>
              <FormControl fullWidth>
                <FormLabels
                  label={"Search Safety Meeting"}
                  isRequired={false}
                />

                <InputField
                  fullWidth={true}
                  disabled={true}
                  type="text"
                  value={titleSafetyMeeting}
                  placeholder="Enter name"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container>
            <Grid container mt={4}>
              <Grid item lg={6} xs={12} sm={12} md={4} pl={2}>
                <FormLabels label="Select User Type" isRequired={false} />
                <RadioGroup row name="radio-user-type" className="radioGroup">
                  <RadioButton
                    checked={formValue.userType === 0}
                    value={0}
                    label="Individual"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        userType: parseInt(e.target.value),
                      })
                    }
                  ></RadioButton>

                  <RadioButton
                    checked={formValue.userType === 2}
                    value={2}
                    label="Contractor"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        userType: parseInt(e.target.value),
                      })
                    }
                  ></RadioButton>
                  <RadioButton
                    checked={formValue.userType === 1}
                    value={1}
                    label="Group"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        userType: parseInt(e.target.value),
                      })
                    }
                  ></RadioButton>
                </RadioGroup>
              </Grid>
            </Grid>

            <Grid container mt={3} sx={12} className="verrifyattendee">
              <Grid lg={12}>
                <div className="desc_box row_uniq">
                  <div className="table_style_ui">
                    {formValue.userType === 0 && (
                      <NormalGrid
                        loading={isTableLoading}
                        getRowId={(r) => r.userId}
                        rowss={verifyAttendeeListData?.users ?? []}
                        columnss={columns}
                        rowCount={verifyAttendeeListData?.users?.itemsCount}
                        selectionModel={tableUserIds}
                        onSelectionModelChange={(e) => {
                          setTableUserIds(e);
                          const selectedIDs = new Set(e);
                          const selectedRows =
                            verifyAttendeeListData?.users.filter((r) =>
                              selectedIDs.has(r.userId)
                            );
                          setSelectedRows(selectedRows);
                        }}
                        onRowClick={onRowClick}
                        isRowSelectable={(rows) =>
                          rows.row.attendanceStatus === false
                        }
                        components={{
                          LoadingOverlay: TableLoader,
                          NoRowsOverlay: NORow,
                        }}
                      ></NormalGrid>
                    )}

                    {formValue.userType === 1 && (
                      <NormalGrid
                        loading={isTableLoading}
                        getRowId={(r) => r.groupId}
                        rowss={verifyAttendeeListData?.groups ?? []}
                        columnss={columnsGroup}
                        rowCount={verifyAttendeeListData?.groups?.itemsCount}
                        selectionModel={tableUserIds}
                        checkboxSelection={false}
                        onSelectionModelChange={(e) => {
                          setTableUserIds(e);
                          const selectedIDs = new Set(e);
                          const selectedRows =
                            verifyAttendeeListData?.users.filter((r) =>
                              selectedIDs.has(r.userId)
                            );
                          setSelectedRows(selectedRows);
                        }}
                        //onRowClick={onRowClick}
                        isRowSelectable={(rows) =>
                          rows.row.attendanceStatus === false
                        }
                        components={{
                          LoadingOverlay: TableLoader,
                          NoRowsOverlay: NORow,
                        }}
                        //  onClick={(row) => handleClickOpen(row)}
                        onCellClick={onRowClick}
                      ></NormalGrid>
                    )}

                    {formValue.userType === 2 && (
                      <NormalGrid
                        loading={isTableLoading}
                        getRowId={(r) => r.userId}
                        rowss={verifyAttendeeListData?.contractors ?? []}
                        columnss={columnsContractor}
                        rowCount={
                          verifyAttendeeListData?.contractors?.itemsCount
                        }
                        selectionModel={tableUserIds}
                        onSelectionModelChange={(e) => {
                          setTableUserIds(e);
                          const selectedIDs = new Set(e);
                          const selectedRows =
                            verifyAttendeeListData?.users.filter((r) =>
                              selectedIDs.has(r.userId)
                            );
                          setSelectedRows(selectedRows);
                        }}
                        onRowClick={onRowClick}
                        isRowSelectable={(rows) =>
                          rows.row.attendanceStatus === false
                        }
                        components={{
                          LoadingOverlay: TableLoader,
                          NoRowsOverlay: NORow,
                        }}
                      ></NormalGrid>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid mt={3} spacing={2} lg={12}>
              <FormDropZone
                label={"Add Proof of Attendance"}
                paraText1={"Drop the file here ..."}
                paraText2={"Drop Document here, "}
                paraText3={"or Browse"}
                handleFileChange={(photo, fileDataId) =>
                  setImages(photo, fileDataId)
                }
                isDynamics={true}
                fileType={10}
                imageUrl={imgUrl}
                deleteImageURL={(url, index) =>
                  handleDeleteImageURL(url, index)
                }
                id="addPhoto"
                key="addPhoto"
                allFiles={true}
                isMuliple={false}
                isDoc={false}
                isImage={false}
              />
            </Grid>

            <Grid mt={3} spacing={2} lg={2}>
              <div>
                {imgUrl && (
                  <a href={imgUrl} target="_blank" alt="">
                    <Buttons varientContained={true} label={"View File"} />
                  </a>
                )}
              </div>
            </Grid>
            {/* {formValue.userType === 0 && ( */}
            <Grid container mt={3}>
              <Grid item container md={6} xs={12}>
                <Grid item md={8} xs={8} mb={2}>
                  <InputField
                    type="text"
                    // isRequired={true}
                    value={formExtraUserValue?.userName}
                    onChange={(e) =>
                      setFormExtraUserValue({
                        ...formExtraUserValue,
                        userName: e.target.value,
                      })
                    }
                    label="Additional Attendees"
                    placeholder="Enter User Name Here..."
                    isError={isExtraUserName}
                    errorMsg={isExtraUserName ? "User Name is Required" : ""}
                  />
                  {errorsMsgShowActive && (
                    <div className="errorMsg">{errorsMsgShow}</div>
                  )}
                </Grid>

                <Grid
                  className="smallAddBtn"
                  item
                  lg={1.5}
                  md={3}
                  xs={3}
                  ml={3}
                  mt={4}
                >
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Create"}
                    id="btnAddEquipment"
                    varientAddIconBlue={true}
                    disabled={isExtraUserSubmit ? true : false}
                    onClick={submitExtraUserData}
                  ></Buttons>
                </Grid>
              </Grid>
              <Grid
                container
                item
                md={12}
                className="inspectionOverRide toolBoxOverRide"
              >
                <Grid item md={6} xs={12} className="CategoriesBox" mt={0}>
                  <Grid className="questionBox mainQuestion" mt={0}>
                    {preExtraUser &&
                      preExtraUser?.map((item) => {
                        return (
                          <Grid className="catListBox" key={item?.key}>
                            <Grid className="catListItem">{item?.value}</Grid>
                            <Grid
                              onClick={() => handleChange(item)}
                              className="closeIcon"
                            >
                              X
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container mb={5} mt={1} spacing={2} pl={2} pr={2}>
              <Grid item lg={4} xs={12} sm={12} md={4} textAlign="left"></Grid>
              <Grid item lg={12} xs={12} sm={12} md={4}>
                <div className="save_btn_with_back">
                  <div className="">
                    <BackBtn dataBack={dataBack} />
                  </div>

                  <div>
                    <div className="rightSideBtn">
                      <div
                        className="cancle_heading_style"
                        onClick={() => history(-1)}
                      >
                        Cancel
                      </div>
                      <Buttons
                        aria-describedby="dd"
                        variant="contained"
                        type="button"
                        name="btn"
                        label={"Save"}
                        disabled={isVeirfyAttendance ? true : false}
                        onClick={submitVeirfyAttendanceData}
                        varientContained={true}
                      ></Buttons>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
