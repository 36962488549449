import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import intl from "react-intl-universal";
import { GET_USER_TYPE } from "../../../../constant/constant";
import useDebounce from "../../../../utils/useDebounce";
import TextEditor from "../../../../library/custom/textEditor/TextEditor";
export default function Employee(props) {
  const {
    employeeOnChange,
    employeeName,
    disableEmpName,
    supervisor,
    jobTitle,
    startData,
    jobSiteProjectName,
    contributingFactors,
    descriptionOfIncident,
    equipmentInspected,
    JHACompleted,
    associatedJHA,
    employeesTrained,
    similarCases,
    vehicleListLength,
    nameOfDriveData,
    handleEmployeeName,
    searchOption,
    jobSiteData,
    supervisorData,
    isErrorEmployeeName,
    errorEmployeeNameMsg,
    isErrorJobSiteProjectName,
    errorJobSiteProjectNameMsg,
    isErrorDescriptionOfIncident,
    isavoidedinthefuture,
    isavoidedinthefutureMsg,
    errorDescriptionOfIncidentMsg,

    cancelEmployee,
    incidentId,
    keyNo,
    searchUsers,
    isLoading,
    editSerch,
    supervisorError,
    jobSiteProjectNameError,
    editMode
  } = props;
  const [searchtext, setSeachText] = useState("");
  const debounceAs = useDebounce(searchtext, 500);
  useEffect(() => {
    if (debounceAs) {
      searchUsers(GET_USER_TYPE.PREVNONPREVUSER, debounceAs);
    }
  }, [debounceAs]);

  return (
    <>
      <div
        style={{
          // border: "1px solid #DFDFDF",
          maxWidth: "750px",
          padding: "20px",
          // borderRadius: "10px",
          marginBottom: "30px",
          marginTop: "20px",
        }}
      >
        <Grid item container>
          <Grid item lg={12} xs={12} sm={12} md={12} textAlign="left" mb={2}>
            {/* <h1>Employee {keyNo}</h1> */}
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            {/* {employeeName && incidentId ? (
              <InputField
                fullWidth={true}
                disabled={true}
                type="text"
                label={"Employee"}
                value={employeeName}
                placeholder="Employee's Name"
              />
            ) : ( */}
            <>
              <FormLabels label="Employee's Name" isRequired={true} />

              {/* <p onClick={() => editSerch()}> Edit</p> */}
              <SearchAutoComplete
                optionsData={isLoading ? [] : searchOption?.users ?? []}
                isUserDetails={true}
                value={employeeName}
                isLoading={isLoading}
                // disabled={disableEmpName}
                typeOnchange={(event) => {
                  setSeachText(event.target.value);
                }}
                // typeOnchange={(event) =>
                //   searchUsers(GET_USER_TYPE.PREVNONPREVUSER, event.target.value)
                // }
                onChange={(event, value) => handleEmployeeName(event, value)}
                isError={isErrorEmployeeName}
                errorMsg={errorEmployeeNameMsg}
              />
              {/* {incidentId ? (
                <p style={{ textAlign: "center" }}>{employeeName}</p>
              ) : null} */}
            </>
            {/* )} */}
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <InputField
              id="jobTitle"
              fullWidth={true}
              disabled={true}
              type="text"
              label={intl.get("incident.nearMisses.jobTitle")}
              value={jobTitle}
              onChange={(e) => employeeOnChange(e)}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={1}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              isRequired={true}
              label={intl.get("incident.nearMisses.jobsiteName")}
            />
            <SelectMenu
              placeholder={"Please Select"}
              name="projectName"
              listData={jobSiteData}
              isError={jobSiteProjectNameError}
              disabled={editMode ? true : disableEmpName}
              value={jobSiteProjectName}
              onchangehandler={(e) => employeeOnChange(e)}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              isRequired={true}
              label={intl.get("incident.nearMisses.supervisor")}
            />
            <SelectMenu
              placeholder={"Please Select"}
              name="supervisor"
              listData={supervisorData}
              disabled={editMode ? true : disableEmpName}
              isError={supervisorError}
              value={supervisor}
              onchangehandler={(e) => employeeOnChange(e)}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={1}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <InputField
              id="jobTitle"
              fullWidth={true}
              disabled={true}
              type="text"
              label={intl.get("incident.nearMisses.startDate")}
              value={startData}
              isError={isErrorJobSiteProjectName}
              errorMsg={errorJobSiteProjectNameMsg}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get("incident.nearMisses.contributingFactor")}
            />
            <MultiSelectBox
              placeholder={"Please Select"}
              listData={nameOfDriveData?.filter((list) => list.isActive)}
              onChange={(e) => employeeOnChange(e)}
              value={contributingFactors}
              name="contributingFactors"
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} mt={1}>
          <Grid item lg={12} xs={12} sm={12} md={10}>
            <FormLabels
              label={intl.get("incident.nearMisses.descOfncident")}
              isRequired={true}
            />
            <TextEditor
              id="descriptionOfIncident"
              placeholder={"Add description of incident text here..."}
              value={descriptionOfIncident}
              onChange={(e, ref) => employeeOnChange(e, ref)}
              isError={isErrorDescriptionOfIncident}
              errorMsg={errorDescriptionOfIncidentMsg}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} mt={1}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get(
                "incident.nearMisses.wasEquipmentInspectedBefore"
              )}
              isRequired={false}
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={equipmentInspected === true}
                value={true}
                label="Yes"
                id="equipmentInspectedYes"
                onChange={(e) => employeeOnChange(e)}
              ></RadioButton>
              <RadioButton
                id="equipmentInspectedNo"
                checked={equipmentInspected === false}
                value={false}
                label="No"
                onChange={(e) => employeeOnChange(e)}
              ></RadioButton>
            </RadioGroup>
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get("incident.nearMisses.wasJhaCompleted")}
              isRequired={false}
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={JHACompleted === true}
                id="JHACompletedYes"
                value={true}
                label="Yes"
                onChange={(e) => employeeOnChange(e)}
              ></RadioButton>
              <RadioButton
                checked={JHACompleted === false}
                value={false}
                id="JHACompletedNo"
                label="No"
                onChange={(e) => employeeOnChange(e)}
              ></RadioButton>
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid container item spacing={3} mt={1}>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get("incident.nearMisses.allAssociatedIdentified")}
              isRequired={false}
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={associatedJHA === true}
                value={true}
                label="Yes"
                id="associatedJHAYes"
                onChange={(e) => employeeOnChange(e)}
              ></RadioButton>
              <RadioButton
                id="associatedJHANo"
                checked={associatedJHA === false}
                value={false}
                label="No"
                onChange={(e) => employeeOnChange(e)}
              ></RadioButton>
            </RadioGroup>
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={5}>
            <FormLabels
              label={intl.get("incident.nearMisses.wereEmployeesTrained")}
              isRequired={false}
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={employeesTrained === true}
                id="employeesTrainedYes"
                value={true}
                label="Yes"
                onChange={(e) => employeeOnChange(e)}
              ></RadioButton>
              <RadioButton
                checked={employeesTrained === false}
                value={false}
                id="employeesTrainedNo"
                label="No"
                onChange={(e) => employeeOnChange(e)}
              ></RadioButton>
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid item container mt={3}>
          <Grid item lg={12} xs={12} sm={12} md={10}>
            <FormLabels
              label={intl.get("incident.nearMisses.howSimilarCaseAvoided")}
              isRequired={true}
            />
            <TextEditor
              id="similarCases"
              placeholder={
                "Add how can similar cases be avoided in the future here..."
              }
              value={similarCases}
              onChange={(e, ref) => employeeOnChange(e, ref)}
              isError={isavoidedinthefuture}
              errorMsg={isavoidedinthefutureMsg}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
