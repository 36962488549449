import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Buttons from "../../../../library/custom/button/Button";
import { API_STATUS, USER_TYPE } from "../../../../constant/constant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { TOOL_BOX_LIST } from "../../../../constant/routeContant";
import UsersChip from "../../../../library/custom/usersChip/UsersChip";
import { InputField } from "../../../../library/custom/textBox/InputField";
import "./assignToolBoxTalk.scss";

export default function AssignUserToolBoxTalk({
  allUsers,
  isLoading,
  onSubmit,
  projectData,
  idSafety,
  editScheduleMeetingDetailData,
  handleAllGroupSearch,
  userGroups,
  handleAllUserSearch,
  editMode,
  isInstructor,
  tabId,
}) {
  const [searchSafetyMeetingName, setSearchSafetyMeetingName] = useState([]);

  const autoCompUser = useRef(null);
  const autoCompContractor = useRef(null);
  const autoCompGroup = useRef(null);

  const [users, setUsers] = useState(1);
  const [userList, setUsersList] = useState([]);
  const [individualIds, setindividualIds] = useState([]);

  const [contractorGroups, setContractorGroups] = useState(1);
  const [contractorGroupsList, setContractorGroupsList] = useState([]);
  const [contractorGroupsIds, setContractorGroupsIds] = useState([]);

  const [usersGroup, setUsersGroup] = useState(1);
  const [userGroupIds, setuserGroupIds] = useState([]);
  const [userGroupList, setUsersGroupList] = useState([]);
  const [isEnableProject, setIsEnableProject] = useState(false);

  useEffect(() => {
    if (editScheduleMeetingDetailData?.status == API_STATUS.SUCCESS) {
      setSearchSafetyMeetingId(editScheduleMeetingDetailData?.toolboxTalkId);
      setSearchSafetyMeetingName(
        editScheduleMeetingDetailData?.titleSafetyMeeting
      );
      setindividualIds(editScheduleMeetingDetailData?.users?.map((e) => e.key));
      if (editScheduleMeetingDetailData?.users?.length > 0) {
        setUsersList(editScheduleMeetingDetailData?.users);
      }

      setuserGroupIds(editScheduleMeetingDetailData?.groups?.map((e) => e.key));
      if (editScheduleMeetingDetailData?.groups?.length > 0) {
        setUsersGroupList(editScheduleMeetingDetailData?.groups);
      }

      setContractorGroupsIds(
        editScheduleMeetingDetailData?.contractors?.map((e) => e.key)
      );
      if (editScheduleMeetingDetailData?.contractors?.length > 0) {
        setContractorGroupsList(editScheduleMeetingDetailData?.contractors);
      }
      setProjectName(editScheduleMeetingDetailData?.projectId);
      setIsEnableProject(
        editScheduleMeetingDetailData?.projectId > 0 ? true : false
      );
    }
  }, [editScheduleMeetingDetailData]);

  const [searchSafetyMeetingId, setSearchSafetyMeetingId] = useState("");
  const [searchSafetyMeetingIdError, setSearchSafetyMeetingIdError] =
    useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectNameError, setProjectNameError] = useState(false);

  useState(false);

  const dataBack = {
    title: "Back to Safety Meeting",
    route: TOOL_BOX_LIST,
    backTabId: isInstructor ? 1 : tabId,
  };

  const onChangeProjectname = (event) => {
    setProjectName(event.target.value);
  };

  function mappedData() {
    let errors = false;

    if (projectName === null) {
      errors = true;
      setProjectNameError(true);
    }
    if (errors) {
      return false;
    }
    const data = {
      scheduleId: idSafety,
      assignType: isInstructor ? 1 : 0,
      userIds: individualIds,
      contractorIds: contractorGroupsIds,
      groupIds: userGroupIds,
      createdBy: parseInt(localStorage.getItem("userId")),
      projectId: projectName,
    };
    return data;
  }

  const onSubmitData = () => {
    const data = mappedData();

    if (!data) {
    } else {
      editMode ? onSubmit(data) : onSubmit(data);
    }
  };

  const addUserData = () => {
    if (!users.key) return;
    const index = userList.findIndex((object) => object.key === users.key);
    if (index === -1) {
      setindividualIds([...individualIds, users.key]);
      setUsersList([...userList, users]);
    }
    const ele = autoCompUser.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  // Add Users

  const deleteData = (id) => {
    const updateList = userList.filter((list) => list.key !== id);
    const updateAssignIds = individualIds.filter((list) => list !== id);
    setUsersList(updateList);
    setindividualIds(updateAssignIds);
  };

  // Add subContractors

  const addContractorGroupsData = () => {
    if (!contractorGroups.key) return;
    const index = contractorGroupsList.findIndex(
      (object) => object.key === contractorGroups.key
    );
    if (index === -1) {
      setContractorGroupsIds([...contractorGroupsIds, contractorGroups.key]);
      setContractorGroupsList([...contractorGroupsList, contractorGroups]);
    }
    const ele = autoCompContractor.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  // delete subContractors

  const deleteContractorGroupsData = (id) => {
    const updatecontractorGroupsList = contractorGroupsList.filter(
      (list) => list.key !== id
    );
    const updateContractorGroupsIds = contractorGroupsIds.filter(
      (list) => list !== id
    );
    setContractorGroupsList(updatecontractorGroupsList);
    setContractorGroupsIds(updateContractorGroupsIds);
  };

  // Add groups

  const addUserGroupData = () => {
    if (!usersGroup.key) return;
    const index = userGroupList.findIndex(
      (object) => object.key === usersGroup.key
    );
    if (index === -1) {
      setuserGroupIds([...userGroupIds, usersGroup.key]);
      setUsersGroupList([...userGroupList, usersGroup]);
    }
    const ele = autoCompGroup.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  // delete groups
  const deleteusersGroupData = (id) => {
    const updateUserGroupList = userGroupList.filter((list) => list.key !== id);
    const updateUserGroupIds = userGroupIds.filter((list) => list !== id);
    setUsersGroupList(updateUserGroupList);
    setuserGroupIds(updateUserGroupIds);
  };
  const typeOnchange = (event, value, USER_TYPE) => {
    if (projectName > 0) {
      const previllageidInstructor = USER_TYPE == 1 ? 0 : "0";
      handleAllUserSearch(
        event.target.value,
        USER_TYPE,
        0,
        projectName,
        previllageidInstructor
      );
    }
  };
  const [usersValue, setUsersValue] = useState("");
  const onSelectUsers = (value) => {
    setUsersValue({ key: value?.key ?? "", value: value?.value ?? "" });
    setUsers(value);
  };
  const [groupsValue, setGroupsValue] = useState("");
  const onSelectGroups = (value) => {
    setGroupsValue({ key: value?.key ?? "", value: value?.value ?? "" });
    setUsersGroup(value);
  };

  const [subcontractorsValue, setSubcontractorsValue] = useState("");
  const onSelectSubcontractors = (value) => {
    setSubcontractorsValue({
      key: value?.key ?? "",
      value: value?.value ?? "",
    });
    setContractorGroups(value);
  };

  return (
    <div className="overidePersonal">
      <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
        <div className="heading_style">Assign Attendees</div>
      </Grid>

      <Grid item container mt={3} spacing={2} pl={4}>
        <Grid lg={12} className="addShadowBox">
          <Grid item container>
            <Grid item lg={5} xs={12} sm={12} md={4} mt={3} pl={2}>
              <FormControl fullWidth>
                {idSafety > 0 && (
                  <InputField
                    fullWidth={true}
                    disabled={true}
                    type="text"
                    label="Search Safety Meeting"
                    value={searchSafetyMeetingName}
                    placeholder="Enter name"
                  />
                )}
              </FormControl>
              {searchSafetyMeetingIdError && !searchSafetyMeetingId && (
                <div className="errorMsg">Please enter this required field</div>
              )}
            </Grid>
            <Grid item lg={5} xs={12} sm={12} md={4} mt={3} pl={2}>
              <FormControl fullWidth>
                <FormLabels label="Select Project(Asset)" isRequired={true} />
                <SelectMenu
                  placeholder={"Please Select"}
                  disabled={isEnableProject ? true : false}
                  listData={projectData}
                  value={projectName}
                  onchangehandler={(e) => onChangeProjectname(e)}
                />
                {projectNameError && !projectName && (
                  <div className="errorMsg">
                    Selection is required for this field
                  </div>
                )}
              </FormControl>
            </Grid>

            <Grid container item mt={3}>
              <Grid item lg={4} md={6} sm={9} xs={12} pl={2}>
                <FormLabels label="Assign to Users" />
                <SearchAutoComplete
                  name="Assign to Users"
                  optionsData={allUsers?.users ?? []}
                  typeOnchange={(event, value) =>
                    typeOnchange(event, value, USER_TYPE.EMPLOYEE)
                  }
                  isKeyValuePair={true}
                  value={usersValue}
                  onChange={(e, value) => onSelectUsers(value)}
                  isLoading={isLoading}
                  autoCompClear={autoCompUser}
                />
              </Grid>

              <Grid
                className="smallAddBtn"
                item
                lg={1.5}
                md={9}
                sm={12}
                xs={12}
                mt={4}
                ml={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addUserData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1} pl={2}>
                <UsersChip
                  userList={userList}
                  handleDelete={(e) => deleteData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid item lg={4} md={6} sm={9} xs={12} pl={2}>
                <FormLabels label="Assign to Subcontractors" />
                <SearchAutoComplete
                  name="Assign to Subcontractors"
                  optionsData={allUsers?.users ?? []}
                  typeOnchange={(event, value) =>
                    typeOnchange(event, value, USER_TYPE.CONTRACTOR)
                  }
                  isKeyValuePair={true}
                  value={subcontractorsValue}
                  onChange={(e, value) => onSelectSubcontractors(value)}
                  autoCompClear={autoCompContractor}
                />
              </Grid>

              <Grid
                className="smallAddBtn"
                item
                lg={1.5}
                md={9}
                sm={12}
                xs={12}
                mt={4}
                ml={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addContractorGroupsData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1} pl={2}>
                <UsersChip
                  isKeyValuePair={true}
                  userList={contractorGroupsList}
                  handleDelete={(e) => deleteContractorGroupsData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid item lg={4} md={6} sm={9} xs={12} pl={2}>
                <FormLabels label="Assign to Groups" />
                <SearchAutoComplete
                  name="Add User Group"
                  optionsData={isLoading ? [] : userGroups ?? []}
                  typeOnchange={(event, value) =>
                    handleAllGroupSearch(event.target.value, 0, true)
                  }
                  isLoading={isLoading}
                  isKeyValuePair={true}
                  value={groupsValue}
                  onChange={(e, value) => onSelectGroups(value)}
                  noOptionsTextMsg="No group"
                  autoCompClear={autoCompGroup}
                />
              </Grid>

              <Grid
                className="smallAddBtn"
                item
                lg={1.5}
                md={9}
                sm={12}
                xs={12}
                mt={4}
                ml={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addUserGroupData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1} pl={2}>
                <UsersChip
                  isKeyValuePair={true}
                  userList={userGroupList}
                  handleDelete={(e) => deleteusersGroupData(e)}
                />
              </Grid>
            </Grid>
            <Grid container mb={5} mt={1} spacing={2} pl={2} pr={2}>
              <Grid item lg={4} xs={12} sm={12} md={4} textAlign="left"></Grid>
              <Grid item lg={12} xs={12} sm={12} md={4}>
                <div className="save_btn_with_back">
                  <div className="">
                    <BackBtn dataBack={dataBack} />
                  </div>
                  <div>
                    <div className="rightSideBtn">
                      <Buttons
                        aria-describedby="dd"
                        variant="contained"
                        type="button"
                        name="btn"
                        label={"Assign"}
                        onClick={() => onSubmitData()}
                        varientContained={true}
                      ></Buttons>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
