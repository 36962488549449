import { call, put, takeLatest } from "redux-saga/effects";
import {
  addUtilityDamage,
  getUtilityDamageReport,
  updateUtilityDamage,
} from "../../services/incidentService";
import { apiError } from "../../utils/helper";
import * as utilityDamageAction from "../actions/utilityDamageAction";
import { utilityDamageType } from "../constants/utilityDamageType";

export function* utilityDamageSaga(params) {
  console.debug("utilityDamageSaga");
  try {
    const { data, status, error } = yield call(addUtilityDamage, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = utilityDamageType.UTILITY_DAMAGE_PAGE;
    yield put(
      utilityDamageAction.utilityDamageSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Utility Damage Failed" };
    yield put(utilityDamageAction.utilityDamageFailure(data));
  }
}

export function* getutilityDamageSaga({ caseid, incidentid }) {
  console.debug("getUtilityDamageReport");
  try {
    const { data, status, error } = yield call(
      getUtilityDamageReport,
      caseid,
      incidentid
    );
    if (!data && status !== 200) throw apiError(error);
    const utilityDamageReportPage = utilityDamageType.UTILITY_DAMAGE_PAGE;
    yield put(
      utilityDamageAction.getUtilityDamageSuccess({
        utilityDamageReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Utility Damage Report Failed" };
    yield put(utilityDamageAction.getUtilityDamageFailure(data));
  }
}

export function* updateutilityDamageSaga(params) {
  console.debug("updatePersonalInjurySaga");
  try {
    const { data, status, error } = yield call(updateUtilityDamage, params);
    if (!data && status !== 200) throw apiError(error);
    const utilityPage = utilityDamageType.UTILITY_DAMAGE_PAGE;
    yield put(
      utilityDamageAction.updateUtilityDamageSuccess({
        utilityPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Utility damage updation Failed" };
    yield put(utilityDamageAction.updateUtilityDamageFailure(data));
  }
}

const myUtilityDamageSaga = [
  takeLatest(utilityDamageType.POST_UTILITY_DAMAGE_PENDING, utilityDamageSaga),
  takeLatest(
    utilityDamageType.GET_UTILITY_DAMAGE_PENDING,
    getutilityDamageSaga
  ),
  takeLatest(
    utilityDamageType.PUT_UTILITY_DAMAGE_PENDING,
    updateutilityDamageSaga
  ),
];

export default myUtilityDamageSaga;
