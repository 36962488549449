export const SwCommonType = {
  GET_SW_REFERENCE_DATA_PAGE: "SWGetReferenceData",
  GET_SW_REFERENCE_DATA_PENDING:
    "[GET_SW_REFERENCE_DATA_PENDING] Get ReferenceData Data",
  GET_SW_REFERENCE_DATA_SUCCESS:
    "[GET_SW_REFERENCE_DATA_SUCCESS] Get ReferenceData Data Successfull",
  GET_SW_REFERENCE_DATA_FAILURE:
    "[GET_SW_REFERENCE_DATA_FAILURE] Get ReferenceData Data Failed",

  GET_PROJECT_SEARCH_LIST_PAGE: "getProjectSearchList",
  GET_PROJECT_SEARCH_LIST_PENDING:
    "[GET_PROJECT_SEARCH_LIST_PENDING] Get ProjectSearchList Data",
  GET_PROJECT_SEARCH_LIST_SUCCESS:
    "[GET_PROJECT_SEARCH_LIST_SUCCESS] Get ProjectSearchList Data Successfull",
  GET_PROJECT_SEARCH_LIST_FAILURE:
    "[GET_PROJECT_SEARCH_LIST_FAILURE] Get ProjectSearchList Data Failed",

  GET_CONTACT_SEARCH_LIST_PAGE: "getContactSearchList",
  GET_CONTACT_SEARCH_LIST_PENDING:
    "[GET_CONTACT_SEARCH_LIST_PENDING] Get ContactSearchList Data",
  GET_CONTACT_SEARCH_LIST_SUCCESS:
    "[GET_CONTACT_SEARCH_LIST_SUCCESS] Get ContactSearchList Data Successfull",
  GET_CONTACT_SEARCH_LIST_FAILURE:
    "[GET_CONTACT_SEARCH_LIST_FAILURE] Get ContactSearchList Data Failed",
};
