import React, { useRef, useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import { InputField } from "../../../../../library/custom/textBox/InputField";
import FormLabels from "../../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import SelectMenu from "../../../../../library/custom/selectMenu/SelectMenu";
import {
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  ORG_NAME,
} from "../../../../../constant/constant";
import Checkbox from "../../../../../library/checkbox/checkbox";

const SwIndividualCommonForm = ({
  handleAllProjectSearch,
  allProjects,
  isProjectLoading,
  onChangeProjects,
  projects,
  getSwReference,
  inspectionQuestionSetList,
  inspectionTypeId,
  handleInspectionTypeSetChange,
  handleQuestionSetChange,
  questionSetId,
  allClients,
  clients,
  searchClient,
  onChangeClients,
  isSearchClientLoading,
  firstClientError,
  userInfo,
  frequencyValue,
  handleFrequencyChange,
  isGeorgiaState,
  alsoSubmitRoutineInspection,
  handleChangeDefault,
  InspectionType
}) => {
  const autoComp = useRef(null);
  const [frequency, setFrequency] = useState();

  useEffect(() => {
    if (isGeorgiaState && getSwReference?.insfrequencyList) {
      const newFrequency = getSwReference?.insfrequencyList?.filter(
        (item) => item.key !== 1
      );
      setFrequency(newFrequency);
    } else {
      setFrequency(getSwReference?.insfrequencyList);
    }
  }, [isGeorgiaState, getSwReference?.insfrequencyList]);
  return (
    <>
      <Grid container className="formController" mt={5}>
        <Grid container item md={8} xs={12} spacing={2}>
          <Grid item md={6} xs={12}>
            {ORG_ID() !== LOGIN_ORG_ID() ? (
              <FormControl fullWidth>
                <FormLabels label="Client" isRequired={false} />
                <InputField
                  isRequired={false}
                  type="text"
                  placeholder={"Add Client Name Here..."}
                  value={clients?.value}
                  disabled={ORG_ID() !== 1}
                />
              </FormControl>
            ) : (
              <FormControl fullWidth>
                <FormLabels label="Client Name" isRequired={true} />
                <SearchAutoComplete
                  name="Add Individual Users"
                  optionsData={allClients ?? []}
                  typeOnchange={(event, value) => searchClient(event, value)}
                  isKeyValuePair={true}
                  value={clients}
                  onChange={(e, value) => onChangeClients(value)}
                  isLoading={isSearchClientLoading}
                  autoCompClear={autoComp}
                  noOptionsTextMsg={"No Client Name"}
                  // isError={isClient}
                  // errorMsg={isClient ? "Client is Required" : ""}
                  disabled={
                    parseInt(LOGIN_ORG_ID()) !== parseInt(HBNEXT_ORG_ID)
                  }
                />
              </FormControl>
            )}
            {firstClientError && !clients && (
              <p className="error">First select client</p>
            )}
          </Grid>
          {clients && (
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Project" isRequired={true} />
                <SearchAutoComplete
                  name="Add Individual Users"
                  optionsData={allProjects ?? []}
                  typeOnchange={(event, value) =>
                    handleAllProjectSearch(event, value)
                  }
                  isKeyValuePair={true}
                  value={projects}
                  onChange={(e, value) => onChangeProjects(value)}
                  isLoading={isProjectLoading}
                  autoCompClear={autoComp}
                  noOptionsTextMsg={"No Project"}
                  // isError={isProject}
                  // errorMsg={isProject ? "Project is Required" : ""}
                />
              </FormControl>
            </Grid>
          )}

          {projects && (
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Inspection Type" isRequired={true} />
                <SelectMenu
                  placeholder="Please Select"
                  listData={InspectionType ?? []}
                  value={inspectionTypeId ? inspectionTypeId : null}
                  onchangehandler={(e) => handleInspectionTypeSetChange(e)}
                  // isError={isInspectionType}
                  // errorMsg={
                  //   isInspectionType ? "Inspection type is required" : ""
                  // }
                />
              </FormControl>
              
            </Grid>
          )}

          {(inspectionTypeId === 1 || inspectionTypeId === 3) && (
            <Grid lg={6} xs={12} sm={6} md={6} item>
              <FormControl fullWidth>
                <FormLabels label="Frequency" />
                <SelectMenu
                  placeholder="Please Select"
                  listData={frequency ?? []}
                  value={frequencyValue ? frequencyValue : null}
                  onchangehandler={(e) => handleFrequencyChange(e)}
                  // isError={isFrequency}
                  // errorMsg={isFrequency ? "Frequency is required" : ""}
                />
              </FormControl>
            </Grid>
          )}
          {inspectionTypeId && (
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Inspection Name" isRequired={true} />
                <SelectMenu
                  placeholder="Inspection Name"
                  listData={inspectionQuestionSetList}
                  value={questionSetId ? questionSetId : null}
                  onchangehandler={(e) => handleQuestionSetChange(e)}
                  // isError={isInspectionType}
                  // errorMsg={
                  //   isInspectionType ? "Inspection Name is required" : ""
                  // }
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SwIndividualCommonForm;
