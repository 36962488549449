import { safetyLogType } from "../constants/safetyLogType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "divisions",
  isError: false,
  isLoading: false,
};

export const getKudosLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case safetyLogType.GET_KUDOS_LOG_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case safetyLogType.GET_KUDOS_LOG_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case safetyLogType.GET_KUDOS_LOG_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getReprimandLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case safetyLogType.GET_REPRIMAND_LOG_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case safetyLogType.GET_REPRIMAND_LOG_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case safetyLogType.GET_REPRIMAND_LOG_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getViolationLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case safetyLogType.GET_VIOLATION_LOG_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case safetyLogType.GET_VIOLATION_LOG_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case safetyLogType.GET_VIOLATION_LOG_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
