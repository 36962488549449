import React, { useState, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import {
  ORG_ID,
  PAGE_SIZE,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import {
  ADMIN_ADD_STORM_WATERPROJECT,
  SW_ADD_PERMIT,
  SW_ADMIN_SAMPLINGLOG,
} from "../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import SiteFilter from "./siteFilter";
import { getFormatedDate } from "../../../../utils/helper";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import DialogBox from "../../../../library/common/DialogBox";

export default function SwProjectList({
  state,
  swProjectLists,
  swProjectListData,
  isLoading,
  searchClient,
  autoSearchDataLoading,
  searchAllClientsReducerData,
  onSearchSite,
  searchSitedata,
  divisionId,
  deleteSWProject,
}) {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clients, setClients] = useState();
  const [siteName, setSiteName] = useState("");
  const [searchdata, setSearchdata] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [projectName, setProjectName] = useState("");
  const autoCompGroup = useRef(null);

  const [swProjectListApiData, setSwProjectListApiData] = useState({
    organisationId: ORG_ID(),
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    startDate: "",
    endDate: "",
    siteId: "",
    customerId: clients
      ? clients
      : parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)
      ? 0
      : ORG_ID(),
    status: "",
    divisionId: divisionId ?? "",
  });

  const autoCompSupervisor = useRef(null);
  const [changesearchautoClient, setChangesearchautoClient] = useState({
    key: "",
    value: "",
  });
  const onSelectSearchCustomer = (e, value, s) => {
    setChangesearchautoClient({
      key: value?.key ?? "",
      value: value?.value ?? "",
    });
    setClients(value?.key || 0);
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      customerId: value?.key || 0,
      startDate: searchdata?.startDate || "",
      endDate: searchdata?.endDate || "",
      status: searchdata?.status || "",
      search: "",
      divisionId: divisionId ?? "",
    };
    swProjectLists(data);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const deletehandle = () => {
    setOpenModal(false);
    deleteSWProject(selectedRow.projectId);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    setSwProjectListApiData({
      ...swProjectListApiData,
      page: newPage + 1,
      siteId: siteName,
    });
    const data = {
      ...swProjectListApiData,
      customerId: clients
        ? clients
        : parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)
        ? 0
        : ORG_ID(),
      page: newPage + 1,
      startDate: searchdata?.startDate || "",
      endDate: searchdata?.endDate || "",
      status: searchdata?.status || "",
      search: search ? search : "",
      divisionId: divisionId ?? "",
    };

    swProjectLists(data);
  };

  const deleteProject = (rows, name) => {
    setOpenModal(true);
    setSelectedRow(rows);
    setProjectName(name);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...swProjectListApiData,
      search: searchText ? searchText : "",
      customerId: clients
        ? clients
        : parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)
        ? 0
        : ORG_ID(),
      startDate: searchdata?.startDate || "",
      endDate: searchdata?.endDate || "",
      status: searchdata?.status || "",
      divisionId: divisionId ?? "",
    };
    setSwProjectListApiData({
      ...swProjectListApiData,
      search: searchText ? searchText : "",
    });
    swProjectLists(data);
  };
  const handleOnEditclick = (rows) => {
    navigate(ADMIN_ADD_STORM_WATERPROJECT, {
      state: {
        projectid: rows.row.projectId,
        customerId: rows.row.customerId,
        customerName: rows.row.customer,
        editMode: true,
        stateId: rows.row.stateId,
        clients: rows?.row?.customerId,
      },
    });
  };
  const handleOnAddclick = (rows) => {
    navigate(SW_ADD_PERMIT, {
      state: {
        projectid: rows.row.projectId,
        stateId: rows.row.stateId,
        projectNumber: rows.row.projectNumber,
        projectName: rows.row.name,
        state: rows.row.state,
      },
    });
  };
  const handleOnViewSampleclick = (rows) => {
    navigate(SW_ADMIN_SAMPLINGLOG, {
      state: {
        projectId: rows?.row?.projectId,
        stateId: rows.row.stateId,
        projectNumber: rows.row.projectNumber,
        projectName: rows.row.name,
        state: rows.row.state,
      },
    });
  };
  const swProjectcolumns = [
    { field: "projectId", headerName: "Project ID", flex: 1 },
    { field: "customer", headerName: "Client", flex: 1 },
    {
      field: "site",
      headerName: "Site",
      flex: 1.3,
      renderCell: (rows) => {
        return <div>{rows?.row?.site ? rows?.row?.site : "N/A"}</div>;
      },
    },
    { field: "name", headerName: "Project Name", flex: 1.3 },
    { field: "city", headerName: "City", flex: 1 },

    { field: "state", headerName: "State", flex: 1 },
    { field: "startDate", headerName: "Start Date", flex: 1.2 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (rows) => {
        return <div>{rows?.row?.isActive ? "Active" : "InActive"}</div>;
      },
      flex: 0.8,
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={swProjectListData?.data?.itemsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={swProjectListData?.data?.projects ?? []}
          fileNameXL={"ProjectList"}
        />
      ),
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={true}
            editOnClick={() => handleOnEditclick(rows)}
            deleteOnClick={() => deleteProject(rows?.row, rows?.row?.name)}
            addForm={() => handleOnAddclick(rows)}
            edit={checkPrivileges([12, 111, 112, 113])}
            editLabel="Manage Project"
            download={false}
            addBtn={checkPrivileges([12, 126, 127])}
            addBtnLabel="Add Project Permit"
            sampleLog={
              rows?.row?.isSamplingRequired == true &&
              checkPrivileges([12, 129, 130, 131])
                ? true
                : false
            }
            sampleLogOnClick={() => handleOnViewSampleclick(rows)}
            deleteLabel="Delete Project"
            deleted={checkPrivileges([12])}
          />
        );
      },
    },
  ];

  const handleSearchs = (data) => {
    setAnchorEl(null);
    let filterdata = data;
    filterdata["startDate"] = filterdata?.startDate
      ? getFormatedDate(filterdata?.startDate)
      : "";
    filterdata["endDate"] = filterdata?.endDate
      ? getFormatedDate(filterdata?.endDate)
      : "";

    const newdata = {
      ...filterdata,
      customerId: clients
        ? clients
        : parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)
        ? 0
        : ORG_ID(),
      siteId: siteName,
      search: search ? search : "",
    };
    setSearchdata(newdata);
    swProjectLists(newdata);
  };
  const typeOnchange = (event) => {
    if (event.target?.value?.trim()) {
      searchClient(event.target?.value?.trim());
    }
  };
  const handleAllSearchSearch = (event) => {
    if (event?.target?.value?.trim()) {
      const defaultdata = {
        search: event?.target?.value?.trim(),
        customerId: clients ? clients : null,
      };
      onSearchSite(defaultdata);
    }
  };
  const [changesearchauto, setchangesearchauto] = useState({
    key: "",
    value: "",
  });
  const onSelectSearchSite = (e, value, s) => {
    setchangesearchauto({ key: value?.key ?? "", value: value?.value ?? "" });

    setSiteName(value?.key || null);
    const data1 = {
      organisationId: ORG_ID(),
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      startDate: "",
      endDate: "",
      siteId: value?.key || null,
      customerId: clients
        ? clients
        : parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)
        ? 0
        : ORG_ID(),
      divisionId: divisionId ?? "",
    };
    swProjectLists(data1);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_body">
          <Grid item container mt={2} mb={3}>
            {parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) &&
              checkPrivileges([12, 17]) && (
                <Grid item lg={4} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="Client" isRequired={false} />
                    <SearchAutoComplete
                      name="Customer"
                      optionsData={
                        autoSearchDataLoading
                          ? []
                          : searchAllClientsReducerData?.data ?? []
                      }
                      typeOnchange={(event, value) => typeOnchange(event)}
                      isKeyValuePair={true}
                      value={
                        changesearchautoClient ? changesearchautoClient : ""
                      }
                      onChange={(event, value, key) =>
                        onSelectSearchCustomer(event, value, key)
                      }
                      isLoading={autoSearchDataLoading}
                      noOptionsTextMsg="No Client"
                      autoCompClear={autoCompGroup}
                    />
                  </FormControl>
                </Grid>
              )}
            {parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) &&
              checkPrivileges([12, 17]) && (
                <Grid
                  item
                  lg={4}
                  xs={12}
                  sm={12}
                  md={6}
                  ml={parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) ? 3 : 0}
                >
                  <FormControl fullWidth>
                    <FormLabels label="Site" isRequired={false} />
                    <SearchAutoComplete
                      name="Site"
                      isKeyValuePair={true}
                      typeOnchange={(event, value) =>
                        handleAllSearchSearch(event)
                      }
                      optionsData={searchSitedata}
                      isLoading={isLoading}
                      noOptionsTextMsg="No Site"
                      onChange={(event, value, key) =>
                        onSelectSearchSite(event, value, key)
                      }
                      value={changesearchauto ? changesearchauto : ""}
                      autoCompClear={autoCompSupervisor}
                    />
                  </FormControl>
                </Grid>
              )}
          </Grid>
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={`Are you sure you want to delete this Project ${projectName}  ?`}
              view
            />
          )}
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search Projects, City, Project No."
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>

            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              <SiteFilter
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleSearch={handleSearchs}
                handleClose={handleClose}
                issite={true}
                clients={clients}
                search={search}
              />
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.projectId}
                rows={swProjectListData?.data?.projects ?? []}
                rowCount={swProjectListData?.data?.itemsCount ?? 0}
                columns={swProjectcolumns}
                loading={isLoading}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={PAGE_SIZE}
                rowsPerPageOptions={[PAGE_SIZE]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
