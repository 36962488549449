import React from "react";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import "./headerStyle.scss";
const Header = ({ searchbarInput, handleSearch }) => {
  return (
    <div className="overrideHeader">
      <div className="header">
        <h1 className="heading">Hi! How can we help you?</h1>
      </div>
      <div className="searchBar">
        <SearchBar
          searchbarInput={searchbarInput}
          handleSearch={handleSearch}
          placeholder="Search"
        />
      </div>
    </div>
  );
};

export default Header;
