import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import { TableLoader } from "../../../../library/common/Loader";
import { PAGE_SIZE } from "../../../../constant/constant";
import { TOOL_BOX_LIST } from "../../../../constant/routeContant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import NORow from "../../../../library/custom/noRow/noRow";
import Button from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import NormalGrid from "../../../../library/custom/gridTable/NormalGrid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import TextEditor from "../../../../library/custom/textEditor/TextEditor";
export default function ToolBoxAssignUserStatus({
  fieldid,
  onSubmit,
  getScheduleAssignUserStatusData,
  isLoading,
  flag,
  titleSafetyMeeting,
  Tabvalue,
  scheduleId,
  coursesUserAssignListData,
}) {
  let titleUserStatus;
  if (flag === "0") {
    titleUserStatus = "ASSIGN USERS" + " " + titleSafetyMeeting;
  } else if (flag === "1") {
    titleUserStatus = "COMPLETED USERS" + " " + titleSafetyMeeting;
  } else {
    titleUserStatus = "NOT COMPLETED USERS" + " " + titleSafetyMeeting;
  }

  const [selectedUserId, setselectedUserId] = useState([]);
  const [customMessage, setCustomMessage] = useState(
    "This is a reminder that you have a Safety Meeting which is incomplete."
  );
  const [open, setOpen] = React.useState(false);
  const [editInsName, setInsName] = useState(false);

  const dataBack = {
    title: "Back to Safety Meetings",
    route: TOOL_BOX_LIST,
    backTabId: Tabvalue,
  };
  const handleclickSafetyAlert = (userId) => {
    setOpen(true);
    setInsName(true);
    const useridArr = [];
    useridArr.push(userId);
    setselectedUserId(useridArr);
  };
  const handleAlertMail = () => {
    setOpen(true);
    setInsName(true);
    setselectedUserId([]);

    const data = {
      userIds: selectedUserId,
      scheduleId: scheduleId,
      dynamicText: customMessage,
    };

    onSubmit(data);
    setOpen(false);
    setCustomMessage(
      "This is a reminder that you have a Safety Meeting which is incomplete."
    );
  };

  const handleclickSafetyAlertAll = (userId) => {
    setOpen(true);
    setInsName(true);
    setselectedUserId([]);
  };
  const handleAlertAll = (userId) => {
    const UsersIds = getScheduleAssignUserStatusData?.map((val) => {
      return val?.userId;
    });
    const data = {
      userIds: UsersIds,
      scheduleId: scheduleId,
      dynamicText: customMessage,
    };

    onSubmit(data);
    setOpen(false);
    setCustomMessage(
      "This is a reminder that you have a Safety Meeting which is incomplete."
    );
  };

  const columns_assign = [
    { field: "userId", headerName: "USER ID", width: 100, flex: 1 },

    { field: "userName", headerName: "User Name", flex: 1 },
    {
      field: "jobTitle",
      headerName: "Job Title",
      type: "text",
      flex: 1,
    },
    {
      field: "isExtranal",
      headerName: "Is External",
      type: "text",
      flex: 1,
      renderCell: (cellValues) => {
        return <>{cellValues.row.isExtranal == false ? "No" : "Yes"}</>;
      },
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={coursesUserAssignListData?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={getScheduleAssignUserStatusData ?? []}
          fileNameXL={
            fieldid === "false"
              ? "UserNotCompleted"
              : fieldid === "true" && flag === "0"
              ? "UserAssigned"
              : "UserCompleted"
          }
        />
      ),
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        if (fieldid === "false") {
          return (
            <div className="alertbtn">
              <Button
                primaryalert={true}
                onClick={(row) => handleclickSafetyAlert(cellValues?.id)}
                label="Alert"
              />
            </div>
          );
        }
      },
    },
  ];

  const handleClose = () => {
    setCustomMessage(
      "This is a reminder that you have training that is incomplete."
    );
    setOpen(false);
    setselectedUserId([]);
  };
  const onchangeCustomMessage = (e) => {
    setCustomMessage(e);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modalContainer">
          {editInsName && (
            <Box className="modalBox">
              <div className="modalTitle">Send Custom Message</div>
              {/* <div className="modalInputBox"> */}
              <Grid container mt={2} lg={12} className="lesson-alert-model">
                <TextEditor
                  value={customMessage}
                  placeholderText={"Enter Here..."}
                  onChange={(e) => {
                    onchangeCustomMessage(e);
                  }}
                />
              </Grid>

              {/* </div> */}
              <Grid container className="modalFooterBox" mt={2}>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Button
                    varientText={true}
                    label="Cancel"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item lg={3} xs={12} sm={3} md={3}>
                  <Button
                    redButton={true}
                    label={"Send Alert"}
                    onClick={
                      selectedUserId.length > 0
                        ? handleAlertMail
                        : handleAlertAll
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Modal>
      <BackBtn dataBack={dataBack} />
      <div className="assignDivtitle">
        <div className="assigntitle">
          <div className="lessonnmeT">{titleUserStatus}</div>
        </div>
        {fieldid == "false" && (
          <div className="allertall">
            {getScheduleAssignUserStatusData?.length > 1 && (
              <Button
                primaryalertAll={true}
                onClick={(row) => handleclickSafetyAlertAll()}
                label="Alert All"
              />
            )}
          </div>
        )}
      </div>

      <div className="tableData">
        <NormalGrid
          loading={isLoading}
          getRowId={(r) => r.userId}
          rowss={
            getScheduleAssignUserStatusData
              ? getScheduleAssignUserStatusData
              : []
          }
          columnss={columns_assign}
          rowCount={getScheduleAssignUserStatusData?.length}
          components={{
            LoadingOverlay: TableLoader,
            NoRowsOverlay: NORow,
          }}
          checkboxSelection={false}
        ></NormalGrid>
      </div>
    </>
  );
}
