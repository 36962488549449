import React from "react";
import JhaListTemplate from "../component/jha/jhaListTemplate";
import { useNavigate } from "react-router-dom";
import { ADMIN_JHA_ADD_TEMPLATE } from "../../../constant/routeContant";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { API_STATUS, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const JhaListTemplateContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // add
  function addTemplate() {
    navigate(ADMIN_JHA_ADD_TEMPLATE);
  }

  // get JHa Template List
  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    assetType: 0,
  };

  const templateListData = useSelector((state) => state.getJhaTemplateList);
  const getTemplateListData = (data) => {
    dispatch(jhaAction.getJhaTemplateList(data));
  };

  useEffect(() => {
    getTemplateListData(defaultData);
  }, []);

  const isLoading = useSelector((state) => state.getJhaTemplateList?.isLoading || state?.updateJhaTemplateStatus?.isLoading);
  
  
  const updateJhaTemplateStatusData = useSelector((state) => state?.updateJhaTemplateStatus);
  function handleStatusChange(data) {
    let newData = {
      templateId: data.id,
      isActive: data.isActive,

      modifiedBy: parseInt(USER_ID()),
    };
    dispatch(jhaAction.updateJhaTemplateStatus(newData));
  }
  useEffect(() => {
    if (
      updateJhaTemplateStatusData.status === API_STATUS.SUCCESS &&
      updateJhaTemplateStatusData?.isLoading === false
      // clearalert
    ) {
      toast("Status Updated successfully");
      getTemplateListData(defaultData);
    }
  }, [updateJhaTemplateStatusData]);
  function handleEditOnClick(data){
    
    navigate(ADMIN_JHA_ADD_TEMPLATE, {
      state: {
        templateId:data.id,
        editMode:true
      },
    });
  }

  return (
    <div>
    <ToastContainer />
      <JhaListTemplate
        addTemplate={addTemplate}
        templateList={templateListData?.data?.result?.itemList ?? []}
        itemsCount={templateListData?.data?.result?.itemsCount ?? []}
        isLoading={isLoading}
        getTemplateListData={getTemplateListData}
        handleStatusChange={handleStatusChange}
        handleEditOnClick={handleEditOnClick}
      />
    </div>
  );
};

export default JhaListTemplateContainer;
