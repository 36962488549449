import React, { useEffect, useState, useRef } from "react";
import ViewReport from "../components/viewReport";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../../../library/common/Loader";
import { Grid } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import {
  API_STATUS,
  LOGIN_ORG_ID,
  ORG_ID,
} from "../../../../constant/constant";
import "./viewReport.scss";

const ViewReportContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const inspectionId = location?.state?.inspectionId;
  const [createdOnDate,setCreatedOnDate] = useState();
  const getSwInspectionDetail = useSelector(
    (state) => state.getSwInspectionDetail
  );
  const getSwInspectionDetailData = (data) => {
    dispatch(swInspectionAction.getSwInspectionDetail(data));
  };
  const getInTyFeByInTyList = useSelector((state) => state.getInTyFeByInTyList);
  const getInTyFeByInTyListData = (data) => {
    dispatch(swInspectionAction.getInTyFeByInTyList(data));
  };
  const showLoader = useSelector(
    (state) =>
      state.getSwInspectionDetail?.isLoading ||
      state.getInTyFeByInTyList?.isLoading
  );

  useEffect(() => {
    if (inspectionId) {
      const data = {
        InspectionId: inspectionId,
      };
      getSwInspectionDetailData(data);
    }
  }, [inspectionId]);

  useEffect(() => {
    if (getSwInspectionDetail?.status === API_STATUS?.SUCCESS) {
      const data = {
        orgId: ORG_ID(),
        inspectionTypeId: getSwInspectionDetail?.data?.inspectionTypeId
          ? getSwInspectionDetail?.data?.inspectionTypeId
          : "",
        stateId: getSwInspectionDetail?.data?.stateId
          ? getSwInspectionDetail?.data?.stateId
          : "",
        projectId: getSwInspectionDetail?.data?.projectId
          ? getSwInspectionDetail?.data?.projectId
          : "",
      };
      getInTyFeByInTyListData(data);

      setCreatedOnDate(getSwInspectionDetail?.data?.createdOn);
    }
  }, [getSwInspectionDetail]);

  const [pdfloader, setPdfLoader] = useState(false);
  const dateOfInspection = getSwInspectionDetail?.data?.dateOfInspection;
  const inspectedProjectName = getSwInspectionDetail?.data?.projectName;

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${dateOfInspection} ${inspectedProjectName}`,
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
    onBeforeGetContent: () => setPdfLoader(true),
    onAfterPrint: () => setPdfLoader(false),
    onPrintError: () => setPdfLoader(false),
  });
  const isClient = ORG_ID() !== LOGIN_ORG_ID();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clientLogo = localStorage.getItem("orgLogo");
  const clientName = localStorage.getItem("orgName");
  const logoCheck = isClient ? clientLogo : userInfo?.logo;

  return (
    <>
      {showLoader && <Loader />}
      {pdfloader && <Loader />}

      <Grid ref={componentRef} className="pdfOveride InspectionContainer">
        <Grid container>
          <Grid item md={12}>
            <div className="title repotPdfHeading">View Inspection Report</div>
          </Grid>
          <Grid item md={12} className="clientPdfDetail">
            <Grid className="clientName">
              {isClient ? clientName : userInfo?.clientName}
            </Grid>
            <Grid className="clientLogo">
              {logoCheck && <img src={logoCheck} alt="client Log" />}
            </Grid>
          </Grid>
        </Grid>

        <ViewReport
          handlePrint={handlePrint}
          inspectionDetail={showLoader ? [] : getSwInspectionDetail?.data}
          getInTyFeByInTyList={
            getInTyFeByInTyList?.data?.listInspectionTypeFields
          }
          inspectionId={inspectionId}
          createdOnDate={createdOnDate}
        />
      </Grid>
    </>
  );
};

export default ViewReportContainer;
