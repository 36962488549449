import React from "react";
import { Grid, FormControl } from "@mui/material";
import ViewImages from "../../../../../incidents/view-incident-report/common/viewImages/viewImages";
import parse from "html-react-parser";
import commonImages from "../../../../../../assets";

const CategoriesReportView = ({
  viewDetail,
  inspectionAcknowledgementData,
}) => {
  const viewData = viewDetail?.categoriesView;
  const viewSkipData = viewDetail?.skippedReportView;
  const imageSize = {
    size: "small",
  };

  return (
    <>
      {viewData?.map((item, i) => {
        return (
          <>
            {item?.skipped === true ? (
              <>
                <div className="overSkipView" key={item?.key}>
                  <div className="skipView categorySkip">
                    <div className="skipViewLeft">
                      <div className="skipViewTitle">{item?.value}</div>
                    </div>
                    <div className="skipViewRight">
                      <div className="skipViewDesc">Skipped</div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Grid container className="categoriesView">
                <Grid key={i} item md={12} className="categoriesTitle">
                  <Grid>{item?.value}</Grid>
                </Grid>
                {item?.subcategoriesView?.map((subItem, i) => {
                  return (
                    <>
                      {subItem?.skipped === true ? (
                        <>
                          <div className="overSkipView" key={subItem?.key}>
                            <div className="skipView subCategorySkip">
                              <div className="skipViewLeft">
                                <div className="skipViewTitle">
                                  {subItem?.value}
                                </div>
                              </div>
                              <div className="skipViewRight">
                                <div className="skipViewDesc">Skipped</div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <Grid
                            key={i}
                            item
                            md={12}
                            className="subCategoriesTitle"
                          >
                            <Grid>{subItem?.value}</Grid>
                          </Grid>
                          <div className="subCateViewBox">
                            {subItem?.insQuesAnsReportView?.map(
                              (inQuestion, i) => {
                                return (
                                  <>
                                    <Grid
                                      key={i}
                                      item
                                      md={12}
                                      className="subCateView"
                                    >
                                      <div className="commonInsOveride">
                                        <Grid className="commoninfoWrap">
                                          <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            className="listViewBox"
                                          >
                                            <div className="listDetailBox">
                                              <div className="listView">
                                                <div className="questions">
                                                  <div className="dFlex">
                                                    <span>
                                                      {inQuestion?.sequenceId}
                                                      {"."}{" "}
                                                      {inQuestion?.question}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="questionitem">
                                                  <div>
                                                    {inQuestion?.answerMstId ===
                                                      1 && (
                                                      <div className="acceptableBtn commonBtn">
                                                        Acceptable
                                                      </div>
                                                    )}
                                                    {inQuestion?.answerMstId ===
                                                      2 && (
                                                      <div className="notAcceptableBtn commonBtn">
                                                        Not Acceptable
                                                      </div>
                                                    )}
                                                    {inQuestion?.answerMstId ===
                                                      3 && (
                                                      <div className="notApplicableBtn commonBtn">
                                                        Not Applicable
                                                      </div>
                                                    )}
                                                    {inQuestion?.answerMstId ===
                                                      null && <></>}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="listDetailBox seprateSection">
                                              {inQuestion?.reprimands?.map(
                                                (repItem) => {
                                                  const image =
                                                    repItem?.correctiveFiles
                                                      ? repItem?.correctiveFiles?.map(
                                                          (x) => x.filePath
                                                        )
                                                      : "";
                                                  const involvedName =
                                                    repItem?.involved_Ids
                                                      ? repItem?.involved_Ids?.map(
                                                          (x) => `${x.value} , `
                                                        )
                                                      : "";
                                                  return (
                                                    <>
                                                      {inQuestion?.answerMstId ===
                                                        1 && (
                                                        <div className="borderSeprateSection">
                                                          <Grid
                                                            container
                                                            className="listView"
                                                          >
                                                            <Grid
                                                              item
                                                              md={12}
                                                              className="dFlex"
                                                            >
                                                              <span className="minH">
                                                                Comments:
                                                              </span>
                                                              <div className="overRideTextEditor">
                                                                {parse(
                                                                  unescape(
                                                                    repItem?.comment
                                                                  )
                                                                )}
                                                              </div>
                                                            </Grid>
                                                          </Grid>
                                                          {repItem
                                                            ?.correctiveFiles
                                                            ?.length !== 0 && (
                                                            <Grid
                                                              container
                                                              className="listView"
                                                            >
                                                              <Grid
                                                                item
                                                                md={12}
                                                              >
                                                                <ViewImages
                                                                  image={image}
                                                                  imageSize={
                                                                    imageSize
                                                                  }
                                                                />
                                                              </Grid>
                                                            </Grid>
                                                          )}
                                                          <Grid
                                                            container
                                                            className="listView"
                                                          >
                                                            <Grid
                                                              item
                                                              md={12}
                                                              className="dFlex"
                                                            >
                                                              <span className="minH">
                                                                Involved Party
                                                                Type:
                                                              </span>
                                                              {repItem?.employee_type ===
                                                              0
                                                                ? "Employee"
                                                                : repItem?.employee_type ===
                                                                  1
                                                                ? "Sub-Contractor"
                                                                : repItem?.employee_type ===
                                                                  2
                                                                ? "N/A"
                                                                : ""}
                                                            </Grid>
                                                          </Grid>

                                                          {repItem?.employee_type ===
                                                            0 && (
                                                            <Grid
                                                              container
                                                              className="listView"
                                                            >
                                                              <Grid
                                                                item
                                                                md={12}
                                                                className="dFlex"
                                                              >
                                                                <span className="minH">
                                                                  Involved
                                                                  Employee:
                                                                </span>

                                                                {repItem?.involved_Ids &&
                                                                  repItem?.involved_Ids?.map(
                                                                    (x) =>
                                                                      `${x.value} , `
                                                                  )}
                                                              </Grid>
                                                            </Grid>
                                                          )}

                                                          {repItem?.employee_type ===
                                                            1 && (
                                                            <Grid
                                                              container
                                                              className="listView"
                                                            >
                                                              <Grid
                                                                item
                                                                md={12}
                                                                className="dFlex"
                                                              >
                                                                <span className="minH">
                                                                  Involved
                                                                  Sub-Contractor:
                                                                </span>
                                                                {repItem?.involved_Ids &&
                                                                  repItem?.involved_Ids?.map(
                                                                    (x) =>
                                                                      `${x.value} , `
                                                                  )}
                                                              </Grid>
                                                            </Grid>
                                                          )}
                                                          {repItem?.employee_type ===
                                                            2 && ""}
                                                        </div>
                                                      )}
                                                      {inQuestion?.answerMstId ===
                                                        2 && (
                                                        <div className="borderSeprateSection">
                                                          <Grid
                                                            container
                                                            className="listView"
                                                          >
                                                            <Grid
                                                              item
                                                              md={12}
                                                              className="dFlex"
                                                            >
                                                              <span className="minH">
                                                                Item Found:
                                                              </span>
                                                              <div className="overRideTextEditor">
                                                                {" "}
                                                                {parse(
                                                                  unescape(
                                                                    repItem?.corrective_issue_dtl
                                                                  )
                                                                )}
                                                              </div>
                                                            </Grid>
                                                          </Grid>
                                                          {repItem
                                                            ?.correctiveFiles
                                                            ?.length !== 0 && (
                                                            <Grid
                                                              container
                                                              className="listView"
                                                            >
                                                              <Grid
                                                                item
                                                                md={12}
                                                              >
                                                                <ViewImages
                                                                  image={image}
                                                                  imageSize={
                                                                    imageSize
                                                                  }
                                                                />
                                                              </Grid>
                                                            </Grid>
                                                          )}

                                                          <Grid
                                                            container
                                                            className="listView"
                                                          >
                                                            <Grid
                                                              item
                                                              md={8}
                                                              className="dFlex"
                                                            >
                                                              <span className="minH">
                                                                Involved Party
                                                                Type:
                                                              </span>
                                                              {repItem?.employee_type ===
                                                              0
                                                                ? "Employee"
                                                                : repItem?.employee_type ===
                                                                  1
                                                                ? "Sub-Contractor"
                                                                : repItem?.employee_type ===
                                                                  2
                                                                ? "N/A"
                                                                : ""}
                                                            </Grid>
                                                            <Grid item md={4}>
                                                              {!(
                                                                repItem?.employee_type ===
                                                                2
                                                              ) && (
                                                                <span
                                                                  className={
                                                                    repItem?.employee_type ===
                                                                      0 ||
                                                                    repItem?.employee_type ===
                                                                      1 ||
                                                                    repItem?.employee_type ===
                                                                      2
                                                                      ? "commonAcBtn yesBtn"
                                                                      : "commonAcBtn noBtn"
                                                                  }
                                                                >
                                                                  {repItem?.employee_type ===
                                                                    0 ||
                                                                  repItem?.employee_type ===
                                                                    1 ||
                                                                  repItem?.employee_type ===
                                                                    2
                                                                    ? "Yes"
                                                                    : "No"}
                                                                </span>
                                                              )}
                                                            </Grid>
                                                          </Grid>

                                                          {repItem &&
                                                            repItem?.involved_Ids &&
                                                            repItem?.involved_Ids?.map(
                                                              (x, index) => (
                                                                <>
                                                                  <Grid
                                                                    container
                                                                    className="listView"
                                                                    key={index}
                                                                  >
                                                                    <Grid
                                                                      item
                                                                      md={8}
                                                                      className="dFlex"
                                                                    >
                                                                      <span className="minH">
                                                                        {repItem?.employee_type ===
                                                                        0
                                                                          ? "Involved Employee:"
                                                                          : repItem?.employee_type ===
                                                                            1
                                                                          ? "Involved Sub-Contractor:"
                                                                          : repItem?.employee_type ===
                                                                            2
                                                                          ? ""
                                                                          : ""}
                                                                      </span>
                                                                      {x.value}
                                                                    </Grid>
                                                                    {repItem?.employee_type !==
                                                                      2 && (
                                                                      <Grid
                                                                        item
                                                                        md={4}
                                                                        container
                                                                      >
                                                                        <span className="privies">
                                                                          Past
                                                                          Corrective:
                                                                        </span>
                                                                        <span className="commonZBtn noBtn">
                                                                          {
                                                                            x?.count
                                                                          }
                                                                        </span>
                                                                      </Grid>
                                                                    )}
                                                                  </Grid>
                                                                </>
                                                              )
                                                            )}

                                                          {repItem?.employee_type ===
                                                            2 && ""}

                                                          {(repItem?.employee_type ===
                                                            0 ||
                                                            repItem?.employee_type ===
                                                              1) && (
                                                            <Grid
                                                              container
                                                              className="listView"
                                                            >
                                                              <Grid
                                                                item
                                                                md={8}
                                                                sm={8}
                                                                xs={8}
                                                              >
                                                                <span>
                                                                  Escalate to
                                                                  Reprimand:
                                                                </span>
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                md={4}
                                                                sm={4}
                                                                xs={4}
                                                              >
                                                                <span
                                                                  className={
                                                                    repItem?.escalate_reprimand ===
                                                                    true
                                                                      ? "commonAcBtn yesBtn"
                                                                      : "commonAcBtn noBtn"
                                                                  }
                                                                >
                                                                  {repItem?.escalate_reprimand ===
                                                                  true
                                                                    ? "Yes"
                                                                    : "No"}
                                                                </span>
                                                              </Grid>
                                                            </Grid>
                                                          )}

                                                          {repItem?.escalate_reprimand && (
                                                            <Grid
                                                              container
                                                              className="listView"
                                                            >
                                                              <Grid
                                                                item
                                                                md={12}
                                                                className="dFlex"
                                                              >
                                                                <span className="minH">
                                                                  Action Taken:
                                                                </span>

                                                                <div className="overRideTextEditor">
                                                                  {" "}
                                                                  {parse(
                                                                    unescape(
                                                                      repItem?.action_taken_dtl
                                                                    )
                                                                  )}
                                                                </div>
                                                              </Grid>
                                                            </Grid>
                                                          )}

                                                          <Grid
                                                            container
                                                            className="listView"
                                                          >
                                                            <Grid
                                                              item
                                                              md={12}
                                                              className="dFlex"
                                                            >
                                                              <span className="minH">
                                                                Item Status:
                                                              </span>
                                                              {
                                                                repItem?.item_status
                                                              }
                                                            </Grid>
                                                          </Grid>
                                                          {repItem?.item_status ===
                                                            "Closed" && (
                                                            <Grid
                                                              container
                                                              className="listView"
                                                            >
                                                              <Grid
                                                                item
                                                                md={12}
                                                                className="dFlex"
                                                              >
                                                                <span className="minH">
                                                                  Abatement
                                                                  Notes:
                                                                </span>

                                                                <div className="overRideTextEditor">
                                                                  {" "}
                                                                  {parse(
                                                                    unescape(
                                                                      repItem?.abatement_notes
                                                                    )
                                                                  )}
                                                                </div>
                                                              </Grid>
                                                            </Grid>
                                                          )}
                                                          {repItem?.item_status ===
                                                            "Open" && (
                                                            <Grid
                                                              container
                                                              className="listView"
                                                            >
                                                              <Grid
                                                                item
                                                                md={12}
                                                                className="dFlex"
                                                              >
                                                                <span className="minH">
                                                                  Responsible
                                                                  Party:
                                                                </span>
                                                                {
                                                                  repItem?.item_responsibility_name
                                                                }
                                                              </Grid>
                                                            </Grid>
                                                          )}
                                                        </div>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
              </Grid>
            )}
          </>
        );
      })}

      {viewDetail?.status_Id === 5 &&
        inspectionAcknowledgementData?.data?.result[0]?.status && (
          <Grid item container spacing={2} mb={3} mt={3}>
            <Grid item lg={10} textAlign="left">
              <FormControl fullWidth>
                <div className="typeTxt">
                  {/* <img
                  className="checkImage"
                  title={"tickCheckbox"}
                  alt=""
                  src={commonImages.check}
                /> */}
                  <span className="associatedIncidentLabel ml-1 blackText">
                    {inspectionAcknowledgementData?.data?.result[0]?.value}
                  </span>
                </div>
              </FormControl>
            </Grid>
          </Grid>
        )}
    </>
  );
};

export default CategoriesReportView;
