export const SwInspectionType = {
  GET_SW_QUESTION_SET_PAGE: "GetSwQuestionSetList",
  GET_SW_QUESTION_SET_LIST_PENDING:
    "[GET_SW_QUESTION_SET_LIST_PENDING] Get Sw Question Set List Data",
  GET_SW_QUESTION_SET_LIST_SUCCESS:
    "[GET_SW_QUESTION_SET_LIST_SUCCESS] Get SW Question Set List Data Successfull",
  GET_SW_QUESTION_SET_LIST_FAILURE:
    "[GET_SW_QUESTION_SET_LIST_FAILURE] Get SW Question Set List Data Failed",

  POST_SW_QUESTION_SET_PENDING:
    "[POST_SW_QUESTION_SET_PENDING] Try to create Sw Update Question Set Data",
  POST_SW_QUESTION_SET_SUCCESS:
    "[POST_SW_QUESTION_SET_SUCCESS] Create create SW Question Set Successfull",
  POST_SW_QUESTION_SET_FAILURE:
    "[POST_SW_QUESTION_SET_FAILURE] Create Sw Question Set Data Failed",

  UPDATE_SW_QUESTION_SET_PENDING:
    "[UPDATE_SW_QUESTION_SET_PENDING] Try to update Sw Update Question Set Data",
  UPDATE_SW_QUESTION_SET_SUCCESS:
    "[UPDATE_SW_QUESTION_SET_SUCCESS]  Update SW Question Set Successfull",
  UPDATE_SW_QUESTION_SET_FAILURE:
    "[UPDATE_SW_QUESTION_SET_FAILURE] Sw Question Set Data Failed",

  GET_SW_QUESTION_SET_DETAILS_PENDING:
    "[GET_SW_QUESTION_SET_DETAILS_PENDING] Get Sw Question Set Detail Data",
  GET_SW_QUESTION_SET_DETAILS_SUCCESS:
    "[GET_SW_QUESTION_SET_DETAILS_SUCCESS] Get SW Question Set Detail Data Successfull",
  GET_SW_QUESTION_SET_DETAILS_FAILED:
    "[GET_SW_QUESTION_SET_DETAILS_FAILED] Get SW Question Set Detail Data Failed",
  CLEAR_SW_QUESTION_SET:
    "[CLEAR_SW_QUESTION_SET][REQUEST] CLEAR_Sw_QUESTION_SET",

  UPDATE_SW_QUESTION_SET_STATUS_PENDING:
    "UPDATE_SW_QUESTION_SET_STATUS_PENDING",
  UPDATE_SW_QUESTION_SET_STATUS_SUCCESS:
    "UPDATE_SW_QUESTION_SET_STATUS_SUCCESS",
  UPDATE_SW_QUESTION_SET_STATUS_FAILED: "UPDATE_SW_QUESTION_SET_STATUS_FAILED",
  UPDATE_SW_QUESTION_SET_STATUS_PAGE: "SW_QUESTION_SET_STATUS UpdatePage",
  CLEAR_SW_QUESTION_SET_STATUS:
    "[CLEAR_Sw_QUESTION_SET_STATUS][REQUEST] CLEAR_Sw_QUESTION_SET_STATUS",
  EDIT_SW_QUESTION_SET_STATUS:
    "[EDIT_Sw_QUESTION_SET_STATUS][REQUEST]  EDIT_Sw_QUESTION_SET_STATUS",

  CLONE_SW_QUESTION_SET_PAGE: "CLONE_SW_QUESTION_SET_PAGE Page",
  CLONE_SW_QUESTION_SET: "[CLONE_SW_QUESTION_SET] Clone Question Set Data",
  CLONE_SW_QUESTION_SET_SUCCESS:
    "[CLONE_SW_QUESTION_SET_SUCCESS] Clone Question Set Successfull",
  CLONE_SW_QUESTION_SET_FAILURE:
    "[CLONE_SW_QUESTION_SET_FAILURE] Clone Question Set Data Failed",
  CLEAR_CLONE_SW_QUESTION_SET:
    "[CLEAR_CLONE_SW_QUESTION_SET][REQUEST] Clear Clone Question Set",

  GET_SW_QUESTION_SET_ON_PROJECT_PENDING:
    "[GET_SW_QUESTION_SET_ON_PROJECT_PENDING] Get Sw Question Set on Project List Data",
  GET_SW_QUESTION_SET_ON_PROJECT_SUCCESS:
    "[GET_SW_QUESTION_SET_ON_PROJECT_SUCCESS] Get SW Question Set on Project List Data Successfull",
  GET_SW_QUESTION_SET_ON_PROJECT_FAILURE:
    "[GET_SW_QUESTION_SET_ON_PROJECT_FAILURE] Get SW Question Set on Project List Data Failed",

  POST_SW_QUESTION_SET_PROJECT_MAP_PENDING:
    "[POST_SW_QUESTION_SET_PROJECT_MAP_PENDING] Try to create Update Sw Question Set Project Map Data",
  POST_SW_QUESTION_SET_PROJECT_MAP_SUCCESS:
    "[POST_Sw_QUESTION_SET_PROJECT_MAP_SUCCESS] Create Update SW Question Set Project Map Successfull",
  POST_SW_QUESTION_SET_PROJECT_MAP_FAILURE:
    "[POST_SW_QUESTION_SET_PROJECT_MAP_FAILURE] Create Update Sw Question Set Project Map Data Failed",

  UPDATE_SW_QUESTION_SET_PROJECT_MAP_PENDING:
    "[UPDATE_SW_QUESTION_SET_PROJECT_MAP_PENDING] Try to Update Sw Question Set Project Map Data",
  UPDATE_SW_QUESTION_SET_PROJECT_MAP_SUCCESS:
    "[UPDATE_Sw_QUESTION_SET_PROJECT_MAP_SUCCESS] Update SW Question Set Project Map Successfull",
  UPDATE_SW_QUESTION_SET_PROJECT_MAP_FAILURE:
    "[UPDATE_SW_QUESTION_SET_PROJECT_MAP_FAILURE] Update Sw Question Set Project Map Data Failed",
  CLEAR_SW_QUESTION_SET_PROJECT_MAP:
    "[CLEAR_SW_QUESTION_SET_PROJECT_MAP][REQUEST] CLEAR_Sw_QUESTION_SET_PROJECT_MAP",

  UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_PENDING:
    "[UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_PENDING] Try to Update Sw Question Set Project Status Map Data",
  UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_SUCCESS:
    "[UPDATE_Sw_QUESTION_SET_PROJECT_MAP_STATUS_SUCCESS] Update SW Question Set Project Map Status Successfull",
  UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_FAILURE:
    "[UPDATE_SW_QUESTION_SET_PROJECT_MAP_STATUS_FAILURE] Update Sw Question Set Project Map Status Data Failed",
  CLEAR_SW_QUESTION_SET_PROJECT_MAP_STATUS:
    "[CLEAR_SW_QUESTION_SET_PROJECT_MAP_STATUS][REQUEST] CLEAR_Sw_QUESTION_SET_PROJECT_MAP Status",

  GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_PENDING:
    "[GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_PENDING] Try to create Update Sw Question Set Project Map Data",
  GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_SUCCESS:
    "[GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_SUCCESS] Create Update Question Set Project Map Successfull",
  GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_FAILURE:
    "[GET_SW_QUESTION_SET_PROJECT_MAP_DETAILS_FAILURE]  Create Update Sw Question Set Project Map Data Failed",

  GET_SW_QUESTION_SET_PROJECT_MAP_LIST_PENDING:
    "[GET_SW_QUESTION_SET_PROJECT_MAP_LIST_PENDING] Get Question Sw Set Project Map List Data",
  GET_SW_QUESTION_SET_PROJECT_MAP_LIST_SUCCESS:
    "[GET_SW_QUESTION_SET_PROJECT_MAP_LIST_SUCCESS] Get Sw Question Set Project Map List Data Successfull",
  GET_SW_QUESTION_SET_PROJECT_MAP_LIST_FAILURE:
    "[GET_SW_QUESTION_SET_PROJECT_MAP_LIST_FAILURE] Get sw Question Set Project Map List Data Failed",

  POST_SW_CATEGORY_PENDING:
    "[POST_SW_CATEGORY_PENDING] Try to Sw create Category Data",
  POST_SW_CATEGORY_SUCCESS:
    "[POST_SW_CATEGORY_SUCCESS] Create Sw Category Successfull",
  POST_SW_CATEGORY_FAILURE:
    "[POST_SW_CATEGORY_FAILURE] Create Sw Category Data Failed",

  UPDATE_SW_CATEGORY_PENDING:
    "[UPDATE_SW_CATEGORY_PENDING] Try to Update Sw Category Data",
  UPDATE_SW_CATEGORY_SUCCESS:
    "[UPDATE_SW_CATEGORY_SUCCESS] Update Sw Category Successfull",
  UPDATE_SW_CATEGORY_FAILURE:
    "[UPDATE_SW_CATEGORY_FAILURE] Update Sw Category Data Failed",

  GET_SW_CATEGORY_LIST_PENDING:
    "[GET_SW_CATEGORY_LIST_PENDING] Get Sw Category List Data",
  GET_SW_CATEGORY_LIST_SUCCESS:
    "[GET_SW_CATEGORY_LIST_SUCCESS] Get Sw Category Data Successfull",
  GET_SW_CATEGORY_LIST_FAILURE:
    "[GET_SW_CATEGORY_LIST_FAILURE] Get Sw Category Data Failed",

  POST_SW_SUB_CATEGORY_PENDING:
    "[POST_SW_SUB_CATEGORY_PENDING] Try to Sw create Sub Category Data",
  POST_SW_SUB_CATEGORY_SUCCESS:
    "[POST_SW_SUB_CATEGORY_SUCCESS] Create Sw sub Category Successfull",
  POST_SW_SUB_CATEGORY_FAILURE:
    "[POST_SW_SUB_CATEGORY_FAILURE] Create Sw Sub Category Data Failed",

  UPDATE_SW_SUB_CATEGORY_PENDING:
    "[UPDATE_SW_SUB_CATEGORY_PENDING] Try to Update Sw Sub Category Data",
  UPDATE_SW_SUB_CATEGORY_SUCCESS:
    "[UPDATE_SW_SUB_CATEGORY_SUCCESS] Update Sw Category Successfull",
  UPDATE_SW_SUB_CATEGORY_FAILURE:
    "[UPDATE_SW_SUB_CATEGORY_FAILURE] Update Sw Sub Category Data Failed",

  GET_SW_SUB_CATEGORY_LIST_PENDING:
    "[GET_SW_SUB_CATEGORY_LIST_PENDING] Get Sw Sub Category List Data",
  GET_SW_SUB_CATEGORY_LIST_SUCCESS:
    "[GET_SW_SUB_CATEGORY_LIST_SUCCESS] Get Sw Sub Category Data Successfull",
  GET_SW_SUB_CATEGORY_LIST_FAILURE:
    "[GET_SW_SUB_CATEGORY_LIST_FAILURE] Get Sw Sub Category Data Failed",

  POST_SW_QUESTION_PENDING:
    "[POST_SW_QUESTION_PENDING] Try to Sw create Question Data",
  POST_SW_QUESTION_SUCCESS:
    "[POST_SW_QUESTION_SUCCESS] Create Sw Question Successfull",
  POST_SW_QUESTION_FAILURE:
    "[POST_SW_QUESTION_FAILURE] Create Sw Question Data Failed",

  UPDATE_SW_QUESTION_PENDING:
    "[UPDATE_SW_QUESTION_PENDING] Try to Update Sw Question Data",
  UPDATE_SW_QUESTION_SUCCESS:
    "[UPDATE_SW_QUESTION_SUCCESS] Update Sw Category Successfull",
  UPDATE_SW_QUESTION_FAILURE:
    "[UPDATE_SW_QUESTION_FAILURE] Update Sw Question Data Failed",

  GET_SW_QUESTION_LIST_PENDING:
    "[GET_SW_QUESTION_LIST_PENDING] Get Sw Question List Data",
  GET_SW_QUESTION_LIST_SUCCESS:
    "[GET_SW_QUESTION_LIST_SUCCESS] Get Sw Question Data Successfull",
  GET_SW_QUESTION_LIST_FAILURE:
    "[GET_SW_QUESTION_LIST_FAILURE] Get Sw Question Data Failed",

  POST_INSPECTION_TYPE_PENDING:
    "[POST_INSPECTION_TYPE_PENDING] Try to create InspectionType Data",
  POST_INSPECTION_TYPE_SUCCESS:
    "[POST_INSPECTION_TYPE_SUCCESS] Create InspectionType Successfull",
  POST_INSPECTION_TYPE_FAILURE:
    "[POST_INSPECTION_TYPE_FAILURE] Create InspectionType Data Failed",

  UPDATE_INSPECTION_TYPE_PENDING:
    "[UPDATE_INSPECTION_TYPE_PENDING] Try to Update InspectionType Data",
  UPDATE_INSPECTION_TYPE_SUCCESS:
    "[UPDATE_INSPECTION_TYPE_SUCCESS] Update InspectionType Successfull",
  UPDATE_INSPECTION_TYPE_FAILURE:
    "[UPDATE_INSPECTION_TYPE_FAILURE] Update InspectionType Data Failed",

  GET_INSPECTION_TYPE_LIST_PENDING:
    "[GET_INSPECTION_TYPE_LIST_PENDING] Get InspectionType List Data",
  GET_INSPECTION_TYPE_LIST_SUCCESS:
    "[GET_INSPECTION_TYPE_LIST_SUCCESS] Get InspectionType Data Successfull",
  GET_INSPECTION_TYPE_LIST_FAILURE:
    "[GET_INSPECTION_TYPE_LIST_FAILURE] Get InspectionType Data Failed",

  GET_INSPECTION_TYPE_FEILDS_LIST_PENDING:
    "[GET_INSPECTION_TYPE_FEILDS_LIST_PENDING] Get InspectionType Fields List Data",
  GET_INSPECTION_TYPE_FEILDS_LIST_SUCCESS:
    "[GET_INSPECTION_TYPE_FEILDS_LIST_SUCCESS] Get InspectionType Fields Data Successfull",
  GET_INSPECTION_TYPE_FEILDS_LIST_FAILURE:
    "[GET_INSPECTION_TYPE_FEILDS_LIST_FAILURE] Get InspectionType Fields Data Failed",

  GET_INSPECTION_TYPE_FROM_STATE_LIST_PENDING:
    "[GET_INSPECTION_TYPE_FROM_STATE_LIST_PENDING] Get InspectionType from state List Data",
  GET_INSPECTION_TYPE_FROM_STATE_LIST_SUCCESS:
    "[GET_INSPECTION_TYPE_FROM_STATE_LIST_SUCCESS] Get InspectionType From State List Data Successfull",
  GET_INSPECTION_TYPE_FROM_STATE_LIST_FAILURE:
    "[GET_INSPECTION_TYPE_FROM_STATE_LIST_FAILURE] Get InspectionType From State List Data Failed",

  GET_IN_TY_FE_IN_TY_LIST_PENDING:
    "[GET_IN_TY_FE_IN_TY_LIST_PENDING] GetInspecionTypeFieldsByInspectionType List Data",
  GET_IN_TY_FE_IN_TY_LIST_SUCCESS:
    "[GET_IN_TY_FE_IN_TY_LIST_SUCCESS] GetInspecionTypeFieldsByInspectionType List Data Successfull",
  GET_IN_TY_FE_IN_TY_LIST_FAILURE:
    "[GET_IN_TY_FE_IN_TY_LIST_FAILURE] GetInspecionTypeFieldsByInspectionType List Data Failed",

  UPDATE_INSPECTION_TYPE_FIELDS_MAP_PENDING:
    "[UPDATE_INSPECTION_TYPE_FIELDS_MAP_PENDING] Try to Update InspectionType Fields Map Data",
  UPDATE_INSPECTION_TYPE_FIELDS_MAP_SUCCESS:
    "[UPDATE_INSPECTION_TYPE_FIELDS_MAP_SUCCESS] Update InspectionType Fields Map Successfull",
  UPDATE_INSPECTION_TYPE_FIELDS_MAP_FAILURE:
    "[UPDATE_INSPECTION_TYPE_FIELDS_MAP_FAILURE] Update InspectionType Fields Map Failed",

  // USER

  GET_SW_INSPECTION_PAGE: "SW Inspection List",
  GET_SW_INSPECTION_LIST_PENDING:
    "[GET_SW_INSPECTION_LIST_PENDING] Get Sw Inspection List Data",
  GET_SW_INSPECTION_LIST_SUCCESS:
    "[GET_SW_INSPECTION_LIST_SUCCESS] Get SW Inspection List Data Successfull",
  GET_SW_INSPECTION_LIST_FAILURE:
    "[GET_SW_INSPECTION_LIST_FAILURE] Get SW Inspection List Data Failed",

  POST_SW_INSPECTION_PENDING:
    "[POST_SW_INSPECTION_PENDING] Try to create sw Inspection Data",
  POST_SW_INSPECTION_SUCCESS:
    "[POST_SW_INSPECTION_SUCCESS] Create sw Inspection Successfull",
  POST_SW_INSPECTION_FAILURE:
    "[POST_SW_INSPECTION_LIST_FAILURE] Create sw Inspection Data Failed",
  CLEAR_SW_INSPECTION: "[CLEAR_SW_INSPECTION][REQUEST] CLEAR_SW_INSPECTION",

  UPDATE_SW_INSPECTION_PENDING:
    "[UPDATE_SW_INSPECTION_PENDING] Try to update sw Inspection Data",
  UPDATE_SW_INSPECTION_SUCCESS:
    "[UPDATE_SW_INSPECTION_SUCCESS] update sw Inspection Successfull",
  UPDATE_SW_INSPECTION_FAILURE:
    "[UPDATE_SW_INSPECTION_FAILURE] update sw Inspection Data Failed",

  GET_SW_INSPECTION_DETAILS_PENDING:
    "[GET_SW_INSPECTION_DETAILS_PENDING] Get Sw Inspection Detail Data",
  GET_SW_INSPECTION_DETAILS_SUCCESS:
    "[GET_SW_INSPECTION_DETAILS_SUCCESS] Get SW Inspection Detail Data Successfull",
  GET_SW_INSPECTION_DETAILS_FAILED:
    "[GET_SW_INSPECTION_DETAILS_FAILED] Get SW Inspection Detail Data Failed",
  CLEAR_SW_INSPECTION_DETAILS:
    "[CLEAR_SW_INSPECTION_DETAILS][REQUEST] CLEAR_SW_INSPECTION_DETAILS",

  DELETE_SW_INSPECTION_PENDING: "DELETE_SW_INSPECTION",
  DELETE_SW_INSPECTION_SUCCESS: "DELETE_SW_INSPECTION_SUCCESS",
  DELETE_SW_INSPECTION_FAILED: "DELETE_SW_INSPECTION_FAILED",

  // primary and secondry inspector

  GET_SW_INSPECTOR_LIST_PENDING:
    "[GET_SW_INSPECTOR_LIST_PENDING] Get Sw Inspector List Data",
  GET_SW_INSPECTOR_LIST_SUCCESS:
    "[GET_SW_INSPECTOR_LIST_SUCCESS] Get SW Inspector List Data Successfull",
  GET_SW_INSPECTOR_LIST_FAILURE:
    "[GET_SW_INSPECTOR_LIST_FAILURE] Get SW Inspector List Data Failed",

  POST_ASSIGN_INSPECTOR_PENDING:
    "[POST_ASSIGN_INSPECTOR_PENDING] Try to Assign Inspector ",
  POST_ASSIGN_INSPECTOR_SUCCESS:
    "[POST_ASSIGN_INSPECTOR_SUCCESS] Assign Inspector Data Successfull",
  POST_ASSIGN_INSPECTOR_FAILURE:
    "[POST_ASSIGN_INSPECTOR_FAILURE]  Assign Inspector Data Failed",

  GET_SW_SECONDARY_INSPECTOR_LIST_PENDING:
    "[GET_SW_SECONDARY_INSPECTOR_LIST_PENDING] Get Sw  Secondary Inspector List Data",
  GET_SW_SECONDARY_INSPECTOR_LIST_SUCCESS:
    "[GET_SW_SECONDARY_INSPECTOR_LIST_SUCCESS] Get SW Secondary Inspector List Data Successfull",
  GET_SW_SECONDARY_INSPECTOR_LIST_FAILURE:
    "[GET_SW_SECONDARY_INSPECTOR_LIST_FAILURE] Get SW Secondary Inspector List Data Failed",

  DELETE_SW_SECONDARY_INSPECTOR_PENDING: "DELETE_SW_SECONDARY_INSPECTOR",
  DELETE_SW_SECONDARY_INSPECTOR_SUCCESS:
    "DELETE_SW_SECONDARY_INSPECTOR_SUCCESS",
  DELETE_SW_SECONDARY_INSPECTOR_FAILED: "DELETE_SW_SECONDARY_INSPECTOR_FAILED",
  //sw dashboard
  GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_PENDING:
    "[GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_PENDING] Get Sw dASHBOARD Set List Data",
  GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_SUCCESS:
    "[GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_SUCCESS] Get SW dASHBOARD Set List Data Successfull",
  GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_FAILURE:
    "[GET_SW_DASHBOARD_CARD_DETAILS_INSPECTION_FAILURE] Get SW dASHBOARD Set List Data Failed",

  POST_OPEN_ITEM_DETAIL_PENDING:
    "[POST_OPEN_ITEM_DETAIL_PENDING] Try to Inspection  Open item Detail pending",
  POST_OPEN_ITEM_DETAIL_SUCCESS:
    "[POST_OPEN_ITEM_DETAIL_SUCCESS]  Inspection  Open item Detail Successfull",
  POST_OPEN_ITEM_DETAIL_FAILURE:
    "[POST_OPEN_ITEM_DETAIL_FAILURE]  Inspection Open item Detail Data Failed",
  CLEAR_SW_OPEN_ITEM_DETAIL:
    "[CLEAR_SW_OPEN_ITEM_DETAIL][REQUEST] CLEAR_SW_OPEN_ITEM_DETAIL",

  POST_INSPECTION_CLOSE_ITEM_PENDING:
    "[POST_INSPECTION_CLOSE_ITEM_PENDING] Try to Inspection close item pending",
  POST_INSPECTION_CLOSE_ITEM_SUCCESS:
    "[POST_INSPECTION_CLOSE_ITEM_SUCCESS]  Inspection close item Successfull",
  POST_INSPECTION_CLOSE_ITEM_FAILURE:
    "[POST_INSPECTION_CLOSE_ITEM_FAILURE]  Inspection close item Data Failed",
  CLEAR_SW_INSPECTION_CLOSE_ITEM:
    "[CLEAR_SW_INSPECTION_CLOSE_ITEM][REQUEST] CLEAR_SW_INSPECTION_CLOSE_ITEM",

  POST_INSPECTION_ITEM_COMMENT_PENDING:
    "[POST_INSPECTION_ITEM_COMMENT_PENDING] Try to Inspection item comment pending",
  POST_INSPECTION_ITEM_COMMENT_SUCCESS:
    "[POST_INSPECTION_ITEM_COMMENT_SUCCESS]  Inspection item comment Successfull",
  POST_INSPECTION_ITEM_COMMENT_FAILURE:
    "[POST_INSPECTION_ITEM_COMMENT_FAILURE] Inspection item comment Data Failed",
  CLEAR_SW_INSPECTION_ITEM_COMMENT:
    "[CLEAR_SW_INSPECTION_ITEM_COMMENT][REQUEST] CLEAR_SW_INSPECTION_ITEM_COMMENT",

  POST_DEFERRED_INSPECTION_PENDING:
    "[POST_DEFERRED_INSPECTION_PENDING] Try to Deferred and missed Inspection ",
  POST_DEFERRED_INSPECTION_SUCCESS:
    "[POST_DEFERRED_INSPECTION_SUCCESS] Deferred and missed Data Successfull",
  POST_DEFERRED_INSPECTION_FAILURE:
    "[POST_DEFERRED_INSPECTION_FAILURE]  Deferred and missed Data Failed",

  GET_SW_OPEN_ITEM_LIST_PENDING:
    "[GET_SW_OPEN_ITEM_LIST_PENDING] Get Sw Open Item List Data",
  GET_SW_OPEN_ITEM_LIST_SUCCESS:
    "[GET_SW_OPEN_ITEM_LIST_SUCCESS] Get Sw Open Item List Data Successfull",
  GET_SW_OPEN_ITEM_LIST_FAILURE:
    "[GET_SW_OPEN_ITEM_LIST_FAILURE] Get Sw Open Item List Data Failed",

  POST_CONDUCT_EXTERNAL_INSPECTION_PENDING:
    "[POST_CONDUCT_EXTERNAL_INSPECTION_PENDING] Try to create Conduct External Inspection Data",
  POST_CONDUCT_EXTERNAL_INSPECTION_SUCCESS:
    "[POST_CONDUCT_EXTERNAL_INSPECTION_SUCCESS] Create create Conduct External Inspection Successfull",
  POST_CONDUCT_EXTERNAL_INSPECTION_FAILURE:
    "[POST_CONDUCT_EXTERNAL_INSPECTION_FAILURE] Create Conduct External Inspection Data Failed",

  GET_EXTERNAL_INSPECTION_PENDING:
    "[GET_EXTERNAL_INSPECTION_PENDING] Get External Inspection Data",
  GET_EXTERNAL_INSPECTION_SUCCESS:
    "[GET_EXTERNAL_INSPECTION_SUCCESS] Get External Inspection Data Successfull",
  GET_EXTERNAL_INSPECTION_FAILURE:
    "[GET_EXTERNAL_INSPECTION_FAILURE] Get External Inspection Data Failed",

  GET_INSPECTION_HEADER_DETAILS_PENDING:
    "[GET_INSPECTION_HEADER_DETAILS_PENDING] Get Inspection Header Details Data",
  GET_INSPECTION_HEADER_DETAILS_SUCCESS:
    "[GET_INSPECTION_HEADER_DETAILS_SUCCESS] Get Inspection Header Details Successfull",
  GET_INSPECTION_HEADER_DETAILS_FAILURE:
    "[GET_INSPECTION_HEADER_DETAILS_FAILURE] Get Inspection Header Details Failed",

  GET_INSPECTION_DAILY_REPORT_DETAILS_PENDING:
    "[GET_INSPECTION_DAILY_REPORT_DETAILS_PENDING] Get Inspection Daily Report Details Data",
  GET_INSPECTION_DAILY_REPORT_DETAILS_SUCCESS:
    "[GET_INSPECTION_DAILY_REPORT_DETAILS_SUCCESS] Get Inspection Daily Report Details Successfull",
  GET_INSPECTION_DAILY_REPORT_DETAILS_FAILURE:
    "[GET_INSPECTION_DAILY_REPORT_DETAILS_FAILURE] Get Inspection Daily Report Details Failed",

  POST_INSPECTION_DAILY_REPORT_PENDING:
    "[POST_INSPECTION_DAILY_REPORT_PENDING] Try to create Inspection Daily Report Data",
  POST_INSPECTION_DAILY_REPORT_SUCCESS:
    "[POST_INSPECTION_DAILY_REPORT_SUCCESS] Create Inspection Daily Report Successfull",
  POST_INSPECTION_DAILY_REPORT_FAILURE:
    "[POST_INSPECTION_DAILY_REPORT_FAILURE] Create Inspection Daily Report Data Failed",

  POST_GET_BULK_INSPECTION_DETAILS_PENDING:
    "[POST_GET_BULK_INSPECTION_DETAILS_PENDING] Try to get bulk Inspection detail Data",
  POST_GET_BULK_INSPECTION_DETAILS_SUCCESS:
    "[POST_GET_BULK_INSPECTION_DETAILS_SUCCESS] get bulk Inspection detail Successfull",
  POST_GET_BULK_INSPECTION_DETAILS_FAILURE:
    "[POST_GET_BULK_INSPECTION_DETAILS_FAILURE] get bulk Inspection detail Failed",

  POST_GET_BULK_INSPECTION_FILES_PENDING:
    "[POST_GET_BULK_INSPECTION_FILES_PENDING] Try to get bulk Inspection file Data",
  POST_GET_BULK_INSPECTION_FILES_SUCCESS:
    "[POST_GET_BULK_INSPECTION_FILES_SUCCESS] get bulk Inspection file Successfull",
  POST_GET_BULK_INSPECTION_FILES_FAILURE:
    "[POST_GET_BULK_INSPECTION_FILES_FAILURE] get bulk Inspection file Failed",
  POST_PROJECT_CONTACTS_EMAIL_DETAILS_PENDING:
    "[POST_PROJECT_CONTACTS_EMAIL_DETAILS_PENDING] Try to Get Project Contacts Email Details Data",
  POST_PROJECT_CONTACTS_EMAIL_DETAILS_SUCCESS:
    "[POST_PROJECT_CONTACTS_EMAIL_DETAILS_SUCCESS] Create Project Contacts Email Details Successfull",
  POST_PROJECT_CONTACTS_EMAIL_DETAILS_FAILURE:
    "[POST_PROJECT_CONTACTS_EMAIL_DETAILS_FAILURE] Create Project Contacts Email Details Failed",
    CLEAR_PROJECT_CONTACTS_EMAIL_DETAILS:
    "[CLEAR_PROJECT_CONTACTS_EMAIL_DETAILS][REQUEST] CLEAR_PROJECT_CONTACTS_EMAIL_DETAILS",
};
