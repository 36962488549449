import { uploadFilesType } from "../constants/uploadFilesType";

export const createFileUpload = (data) => ({
  type: uploadFilesType.POST_UPLOAD_FILE_PENDING,
  data,
});

export const createFileUploadSuccess = (data) => ({
  type: uploadFilesType.POST_UPLOAD_FILE_SUCCESS,
  data,
});

export const createFileUploadFailure = (error) => ({
  type: uploadFilesType.POST_UPLOAD_FILE_FAILURE,
  error,
});
