import React, { useEffect } from "react";
import SetupCredentialList from "../../../feature/credential/component/setupCredentialList";
import {
  SETUP_ADD_CREDENTIAL,
  DASHBOARD,
} from "../../../../src/constant/routeContant";
import { useNavigate } from "react-router-dom";
import * as credentialAction from "../../../redux/actions/credentialAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { useDispatch, useSelector } from "react-redux";
import { LNG_ID, ORG_ID, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";

export default function SetupCredentialListContainer() {
  const navigate = useNavigate();
  const isAllowed = checkPrivileges([12, 13]);

  const NavigateAddCredentialsMethode = () => {
    navigate(SETUP_ADD_CREDENTIAL);
  };

  const defaultData = {
    search: "",
    page: 1,
    organisationId: ORG_ID(),
    pageSize: PAGE_SIZE,
    credentialTypeId: "",
    userId: USER_ID(),
  };
  const credentialListData = useSelector(
    (state) => state.getCredentialListData
  );

  const dispatch = useDispatch();

  const getCredentials = (data) => {
    dispatch(credentialAction.getCredentialList(data));
  };

  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }
    getCredentials(defaultData);
  }, []);

  const credentialTypes = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.credentialTypes
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: 8,
    lngId: LNG_ID,
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  return (
    <>
      <SetupCredentialList
        NavigateAddCredentials={NavigateAddCredentialsMethode}
        credentialListData={credentialListData?.data?.result}
        getCredentials={getCredentials}
        isLoading={credentialListData.isLoading}
        credentialTypes={credentialTypes}
      />
    </>
  );
}
