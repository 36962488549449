import { resourceType } from "../constants/resourceType";

export const getResourceList = (data) => ({
  type: resourceType.GET_RESOURCE_LIST,
  data,
});

export const getResourceListSuccess = (data) => ({
  type: resourceType.GET_RESOURCE_LIST_SUCCESS,
  data,
});

export const getResourceListFailure = (error) => ({
  type: resourceType.GET_RESOURCE_LIST_FAILURE,
  error,
});

export const createResource = (data) => ({
  type: resourceType.POST_RESOURCE_PENDING,
  data,
});

export const createResourceSuccess = (data) => ({
  type: resourceType.POST_RESOURCE_SUCCESS,
  data,
});

export const createResourceFailure = (error) => ({
  type: resourceType.POST_RESOURCE_FAILURE,
  error,
});

export const updateResource = (data) => ({
  type: resourceType.POST_UPDATE_RESOURCE,
  data,
});

export const updateResourceSuccess = (data) => ({
  type: resourceType.POST_UPDATE_RESOURCE_SUCCESS,
  data,
});

export const updateResourceFailure = (error) => ({
  type: resourceType.POST_UPDATE_RESOURCE_FAILURE,
  error,
});

export const getResourceDetails = (data) => ({
  type: resourceType.GET_RESOURCE_DETAILS,
  data,
});

export const getResourceDetailsSuccess = (data) => ({
  type: resourceType.GET_RESOURCE_DETAILS_SUCCESS,
  data,
});

export const getResourceDetailsFailure = (error) => ({
  type: resourceType.GET_RESOURCE_DETAILS_FAILURE,
  error,
});


export const deleteResource = (payload) => ({
  type: resourceType.DELETE_RESOURCE_PENDING,
  payload: payload,
});

export const deleteResourceSuccess = (payload) => ({
  type: resourceType.DELETE_RESOURCE_SUCCESS,
  payload: payload,
});

export const deleteResourceFailure = (payload) => ({
  type: resourceType.DELETE_RESOURCE_FAILED,
  payload: payload,
});

export const clearResource = () => ({
  type: resourceType.CLEAR_RESOURCE,
  data: "",
});

export const editResource = (data) => ({
  type: resourceType.EDIT_RESOURCE,
  data,
});