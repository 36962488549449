import { call, put, takeLatest } from "redux-saga/effects";
import {
  addPersonalInjury,
  getPersonalInjury,
  updatePersonalInjury,
} from "../../services/incidentService";
import { apiError } from "../../utils/helper";
import * as perosnalInjuryAction from "../actions/perosnalInjuryAction";
import { perosnalInjuryType } from "../constants/perosnalInjuryType";

export function* personalInjurySaga(params) {
  console.debug("personalInjurySaga");
  try {
    const { data, status, error } = yield call(addPersonalInjury, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = perosnalInjuryType.PERSONAL_INJURY_PAGE;
    yield put(
      perosnalInjuryAction.perosnalInjurySuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Personal Injury Failed" };
    yield put(perosnalInjuryAction.perosnalInjuryFailure(data));
  }
}

export function* updatePersonalInjurySaga(params) {
  console.debug("updatePersonalInjurySaga");
  try {
    const { data, status, error } = yield call(updatePersonalInjury, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = perosnalInjuryType.PERSONAL_INJURY_PAGE;
    yield put(
      perosnalInjuryAction.updatePerosnalInjurySuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Personal Injury updation Failed" };
    yield put(perosnalInjuryAction.updatePerosnalInjuryFailure(data));
  }
}

export function* getPersonalInjurySaga({ caseid, incidentid }) {
  console.debug("getPersonalInjuryReport");
  try {
    const { data, status, error } = yield call(
      getPersonalInjury,
      caseid,
      incidentid
    );
    if (!data && status !== 200) throw apiError(error);
    const personalInjuryReportPage = perosnalInjuryType.PERSONAL_REPORT_PAGE;
    yield put(
      perosnalInjuryAction.getPersonalReportDetailsSuccess({
        personalInjuryReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Personal Injury Report Failed" };
    yield put(perosnalInjuryAction.getPersonalReportDetailsFailure(data));
  }
}

const myPersonalInjurySaga = [
  takeLatest(
    perosnalInjuryType.POST_PERSONAL_INJURY_PENDING,
    personalInjurySaga
  ),
  takeLatest(
    perosnalInjuryType.PUT_PERSONAL_INJURY_PENDING,
    updatePersonalInjurySaga
  ),
  takeLatest(perosnalInjuryType.GET_PERSONAL_REPORT, getPersonalInjurySaga),
];

export default myPersonalInjurySaga;
