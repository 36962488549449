import React from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import { LANGUAGES } from "./constant/constant";
const locales = {
  [LANGUAGES.US]: require("./locales/en-US.json"),
  [LANGUAGES.MX]: require("./locales/es-MX.json"),
};

class I18N extends React.Component {
  state = {
    initDone: false,
  };

  static childContextTypes = {
    i18n: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.i18n = {
      setLanguage: this.setLanguage,
    };
  }

  getChildContext() {
    return {
      i18n: this.i18n,
    };
  }

  componentDidMount() {
    const userLang = navigator.language || navigator.userLanguage;
    this.setLanguage(
      userLang === "mx" || userLang === "es-MX" ? LANGUAGES.MX : LANGUAGES.US
    );
  }

  //

  // modify this to make an axios call to a language set in the future
  // grab on demand locales

  setLanguage = (language) => {
    intl
      .init({
        currentLocale: language, // TODO: determine locale here
        locales,
      })
      .then(() => {
        this.setState({ initDone: true });
      }).catch((error) => {
      });
  };

  render() {
    return this.state.initDone && this.props.children;
  }
}

export default I18N;
