import React from "react";
import SupportCard from "../../../library/custom/supportCard/supportCard";
import "./adminFaqContainer.scss";
import { Grid } from "@mui/material";
import { NoteAdd, Plagiarism } from "@mui/icons-material";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { useNavigate } from "react-router-dom";
import { CREATE_FAQ, FAQ } from "../../../constant/routeContant";
const AdminFaqcontainer = () => {
  const navigate = useNavigate();

  return (
    <div className="overrideAdminFaqPage">
      {checkPrivileges([7,12]) ? (
        <div className="faqMainContainer">
          <div className="rightDiv">
            <div className="faqcontainer">
              <Grid className="cardsContainer" gap={3}>
                <SupportCard
                  varientFaq={true}
                  icon={<Plagiarism className="faqIcon" />}
                  category={"Browse FAQs"}
                  details={"Browse through FAQs to help yourself"}
                  onClick={() => navigate(FAQ)}
                />
                <SupportCard
                  varientFaq={true}
                  icon={<NoteAdd className="faqIcon" />}
                  category={"Add FAQs"}
                  details={"Create new FAQs"}
                  onClick={() => navigate(CREATE_FAQ)}
                />
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <h1>Access Denied</h1>
      )}
    </div>
  );
};

export default AdminFaqcontainer;
