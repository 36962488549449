import React, { useEffect, useState } from "react";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { ORG_ID, PAGE_SIZE, USER_ID } from "../../../../constant/constant";
import SetupInspection from "../components/setupInspection";
import * as swCommonAction from "../../../../redux/storm-water/actions/swCommonAction";
import * as userAction from "../../../../redux/actions/userAction";
import SuccessScreen from "../../../../library/custom/success/successScreen";
import { SETUP_INSPECTION_LIST } from "../../../../constant/routeContant";
import { useLocation } from "react-router-dom";
import * as assetsAction from "../../../../redux/actions/assetsAction";

import * as projectAction from "../../../../redux/storm-water/actions/projectAction";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import * as clientAction from "../../../../redux/actions/clientAction";
const SetupInspectionContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const mapId = location?.state?.row;
  const editMode = location?.state?.editMode;
  const addSetup = location?.state?.addSetup;
  // const customer = location?.state?.customerName;
  // const customerId = location?.state?.customerId ?? location?.state?.customerId;

  const [customer, setcustomer] = useState(location?.state?.customerName);
  const [customerId, setcustomerID] = useState(location?.state?.customerId);
  const projectCategorycheckId =
    location?.state?.projectCategorycheck ??
    location?.state?.projectCategorycheck;
  const [projectCategorycheck, setProjectCategorycheck] = useState("");
  const [clearCategory, setClearCategory] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectProjectCategoryError, setSelectProjectCategoryError] =
    useState();
  function handleAllUserSearch(event, value) {
    dispatch(
      userAction.searchAllUsers(
        event.target.value,
        0,
        "0",
        "0",
        0,
        "17",
        "",
        customerId
      )
    );
  }
  const allUsers = useSelector((state) => state.allUsers);
  const isUserLoading = allUsers?.isLoading;

  const refData = {
    flags: [3, 4, 10],
    orgId: customerId,
  };
  const getSwReference = useSelector((state) => state.getSwReference);
  const getSwReferenceData = (data) => {
    dispatch(swCommonAction.getSwReference(data));
  };
  const createSwQuestionSetProjectMap = useSelector(
    (state) => state.createSwQuestionSetProjectMap
  );
  const updateSwQuestionSetProjectMap = useSelector(
    (state) => state.updateSwQuestionSetProjectMap
  );
  const [clearSwQuestionSetProjectMap, setClearSwQuestionSetProjectMap] =
    useState(false);
  const showLoader = useSelector(
    (state) =>
      state.createSwQuestionSetProjectMap?.isLoading ||
      state.updateSwQuestionSetProjectMap?.isLoading ||
      state.getSwQuestionSetProjectMapDetail?.isLoading ||
      state.getQuestionSetOnProject?.isLoading ||
      state.getSwReference?.isLoading
  );
  const [successScreens, setSuccessScreens] = useState(false);

  const onSubmit = (data) => {
    setClearCategory(true);
    setSuccessScreens(true);
    setClearSwQuestionSetProjectMap(true);
    dispatch(swInspectionAction.createSwQuestionSetProjectMap(data));
  };

  const onUpdateSubmit = (data) => {
    setSuccessScreens(true);
    setClearSwQuestionSetProjectMap(true);
    dispatch(swInspectionAction.updateSwQuestionSetProjectMap(data));
  };

  const getSwQuestionSetProjectMapDetail = useSelector(
    (state) => state.getSwQuestionSetProjectMapDetail
  );
  const getSwQuestionSetProjectMapDetailData = (data) => {
    dispatch(swInspectionAction.getSwQuestionSetProjectMapDetail(data));
  };

  const inspectionQuestionSetList = useSelector(
    (state) => state.getQuestionSetOnProject
  );

  const isLoading = useSelector((state) => state.swQuestionSetList?.isLoading);

  const getInspectionQuestionSetListData = (data) => {
    dispatch(swInspectionAction.getQuestionSetOnProject(data));
  };

  function handleAllProjectSearch(event, value, orgId) {
    console.log(orgId);
    if (!projectCategorycheck) {
      setSelectProjectCategoryError(true);
    } else {
      setSelectProjectCategoryError(false);
      dispatch(
        assetsAction.getAssetDynomoList({
          searchText: event.target.value,
          type: 5,
          orgId: orgId,
        })
      );
    }
  }
  const allProject = useSelector((state) => state.getAssetDynomoList);
  const isProjectLoading = allProject?.isLoading;

  useEffect(() => {
    dispatch(swInspectionAction.clearSwQuestionSetProjectMap());
    getSwReferenceData(refData);
  }, [customerId]);

  const successScreen = (id, successMsg, label1, label4, label2) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={successMsg}
        route={SETUP_INSPECTION_LIST}
        inspectionData={
          customerId
            ? {
                customerId: customerId,
                customerName: customer,
                projectId: null,
                projectName: null,
              }
            : location?.state
        }
        label1={label1}
        label4={label4}
        label2={label2}
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    if (mapId && editMode) {
      const data = {
        mapId: parseInt(mapId),
        userId: parseInt(USER_ID()),
      };
      getSwQuestionSetProjectMapDetailData({
        data,
        orgId: location?.state?.customerId,
      });
    }
  }, [mapId && editMode]);

  useEffect(() => {
    if (
      createSwQuestionSetProjectMap?.status === "SUCCESS" &&
      createSwQuestionSetProjectMap?.isLoading === false &&
      createSwQuestionSetProjectMap?.data?.mapId === 0 &&
      clearCategory
    ) {
      toast(
        createSwQuestionSetProjectMap?.data?.responseMessages?.responseMessage
      );
      setIsSubmit(false);
    }
  }, [createSwQuestionSetProjectMap]);
  useEffect(() => {
    if (projectCategorycheckId) {
      setProjectCategorycheck(projectCategorycheckId);
    }
  }, [projectCategorycheckId]);

  const searchAllClientsReducerData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchDataLoading = searchAllClientsReducerData.isLoading;
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };
  return (
    <>
      {showLoader && <Loader />}
      {createSwQuestionSetProjectMap.status === "SUCCESS" &&
      createSwQuestionSetProjectMap.data &&
      createSwQuestionSetProjectMap?.data?.mapId !== 0 &&
      clearSwQuestionSetProjectMap &&
      successScreens ? (
        successScreen(
          createSwQuestionSetProjectMap?.data?.mapId,
          "Project Mapped Successfully",
          "Map Id",
          "Inspection Service List in",
          " "
        )
      ) : updateSwQuestionSetProjectMap.status === "SUCCESS" &&
        updateSwQuestionSetProjectMap.data &&
        clearSwQuestionSetProjectMap &&
        successScreens ? (
        successScreen(
          updateSwQuestionSetProjectMap?.data?.mapId,
          "Project Mapped Updated Successfully",
          " ",
          "Inspection Service List in",
          "has been Updated"
        )
      ) : (
        <>
          <SetupInspection
            onSubmit={onSubmit}
            getSwReference={getSwReference?.data}
            getInspectionQuestionSetListData={getInspectionQuestionSetListData}
            inspectionQuestionSetList={
              isLoading ? [] : inspectionQuestionSetList?.data
            }
            handleAllUserSearch={handleAllUserSearch}
            isLoading={isUserLoading}
            allUsers={allUsers?.data}
            handleAllProjectSearch={handleAllProjectSearch}
            isProjectLoading={isProjectLoading}
            allProjects={allProject?.data}
            editMode={editMode}
            mapId={mapId}
            editDetail={getSwQuestionSetProjectMapDetail?.data}
            onUpdateSubmit={onUpdateSubmit}
            setupData={location?.state}
            customer={customer}
            projectCategorycheck={projectCategorycheck}
            setProjectCategorycheck={setProjectCategorycheck}
            customerId={customerId}
            isSubmit={isSubmit}
            setIsSubmit={setIsSubmit}
            selectProjectCategoryError={selectProjectCategoryError}
            searchClient={searchClient}
            autoSearchDataLoading={autoSearchDataLoading}
            searchAllClientsReducerData={searchAllClientsReducerData}
            getSwReferenceData={getSwReferenceData}
            setcustomerID={setcustomerID}
            setcustomer={setcustomer}
            addSetup={addSetup}
          />
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default SetupInspectionContainer;
