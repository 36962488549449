import {
  clearVehicleType,
  deleteVehicleType,
  getVehicleDetailsType,
  postAddVehicleType,
  updateVehicleType,
  vehiclesType,
} from "../constants/vehicleType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "Vehicles",
  isError: false,
  isLoading: false,
};

export const vehicleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case vehiclesType.GET_VEHICLE_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case vehiclesType.GET_VEHICLE_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case vehiclesType.GET_VEHICLE_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const postAddVehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case postAddVehicleType.POST_ADD_VEHICLE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case postAddVehicleType.POST_ADD_VEHICLE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case postAddVehicleType.POST_ADD_VEHICLE_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
      case clearVehicleType.CLEAR_VEHICLE:
        return {
          ...state,
          isError: true,
          isLoading: false,
          data: "",
        };
    default:
      return state;
  }
};

export const deleteVehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case deleteVehicleType.DELETE_VEHICLE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case deleteVehicleType.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case deleteVehicleType.DELETE_VEHICLE_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
      case clearVehicleType.CLEAR_VEHICLE:
        return {
          ...state,
          isError: true,
          isLoading: false,
          data: "",
        };
    default:
      return state;
  }
};


export const getVehicleDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getVehicleDetailsType.GET_VEHICLE_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getVehicleDetailsType.GET_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getVehicleDetailsType.GET_VEHICLE_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};



export const updateVehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case updateVehicleType.UPDATE_VEHICLE_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
      case updateVehicleType.UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
      case updateVehicleType.UPDATE_VEHICLE_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
      case clearVehicleType.CLEAR_VEHICLE:
        return {
          ...state,
          isError: true,
          isLoading: false,
          data: "",
        };

    default:
      return state;
  }
};