import * as contactsAction from "../actions/contactsAction";
import { contactType } from "../constants/contactType";
import {
  getContactList,
  createContact,
  deleteContacts,
  getContactsReport,
  updateContact,
  convertNonPrivilegeContactList,
} from "../../services/contactsService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* contactListSaga(params) {
  console.debug("contactListSaga");
  try {
    const { data, status, error } = yield call(getContactList, params);
    if (!data && status !== 200) throw apiError(error);
    const page = contactType.CONTACT_PAGE;
    yield put(
      contactsAction.getContactListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Contact List Failed" };
    yield put(contactsAction.getContactListFailure(data));
  }
}

export function* createContactSaga(params) {
  console.debug("createContactSaga");
  try {
    const { data, status, error } = yield call(createContact, params);
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    const page = contactType.CONTACT_PAGE;
    yield put(
      contactsAction.createContactSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Contact Failed" };
    yield put(contactsAction.createsContactFailure(data));
  }
}

function* deleteContactsSaga(payload) {
  try {
    const { data, status } = yield call(deleteContacts, payload);
    const casePage = contactType.CONTACT_PAGE;

    yield put(
      contactsAction.deleteContactsSuccess({
        casePage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get CONTACT List Failed" };
    yield put(contactsAction.deleteContactsFailure(data));
  }
}

export function* contactsReport({ contactId }) {
  console.debug("contactsReport");
  try {
    const { data, status, error } = yield call(getContactsReport, contactId);
    if (!data && status !== 200) throw apiError(error);
    const ContactsReportPage = contactType.CONTACT_PAGE;
    yield put(
      contactsAction.getContactDetailsSuccess({
        ContactsReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get contact Report Failed" };
    yield put(contactsAction.getContactDetailsFailure(data));
  }
}

export function* updateContactSaga(params) {
  console.debug("updateContactSaga");
  try {
    const { data, status, error } = yield call(updateContact, params);
    if (!data && status !== 200) throw apiError(error);
    const updatePage = contactType.UPDATE_PAGE;
    yield put(
      contactsAction.updateContactSuccess({
        updatePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "contact updation Failed" };
    yield put(contactsAction.updateContactFailure(data));
  }
}

export function* convertNonPrivelegeContactListSaga(params) {
  console.debug("convertNonPrivelegeContactListSaga");
  try {
    const { data, status, error } = yield call(
      convertNonPrivilegeContactList,
      params
    );
    console.log("datadata", data);
    if (!data && status !== 200) throw apiError(error);
    const page = contactType.CONTACT_PAGE;
    yield put(
      contactsAction.convertNonPrivilegeContactListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message: error || "Convert Non Privelege Contact List Data Failed",
          };
    yield put(contactsAction.convertNonPrivilegeContactListFailure(data));
  }
}

const myContactsSaga = [
  takeLatest(contactType.GET_CONTACTS_LIST, contactListSaga),
  takeLatest(contactType.POST_CONTACTS, createContactSaga),
  takeLatest(contactType.UPDATE_CONTACT_PENDING, updateContactSaga),
  takeLatest(contactType.DELETE_CONTACT_PENDING, deleteContactsSaga),
  takeLatest(contactType.GET_CONTACT_DETAILS_PENDING, contactsReport),
  takeLatest(
    contactType.CONVERT_NON_PRIVILEGE_CONTACT_LIST,
    convertNonPrivelegeContactListSaga
  ),
];

export default myContactsSaga;
