import { lmsUserType } from "../constants/lmsUserType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "courses",
  isError: false,
  isLoading: false,
};

export const getUserCoursesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsUserType.GET_USER_COURSES_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsUserType.GET_USER_COURSES_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsUserType.GET_USER_COURSES_LIST_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case lmsUserType.CLEAR_COURSELIST:
      return {
        ...state,
        status: "clearCourse",
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

export const getUserCourseViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsUserType.GET_USER_COURSE_VIEW:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsUserType.GET_USER_COURSE_VIEW_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsUserType.GET_USER_COURSE_VIEW_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const startCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsUserType.POST_START_COURSES_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsUserType.POST_START_COURSES_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsUserType.POST_START_COURSES_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
