import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import commonImages from "../../assets";
import WelcomeImage from "../../assets/welcome.png";
import Button from "../../library/button/button";
import Footer from "../../library/footer/footer";
import Loader from "../../library/common/Loader";
import { maxLengthCheck } from "../../utils/helper";
import "./login.scss";
import * as loginActions from "../../redux/actions/loginActions";
import { COUNTRY_CODE } from "../../constant/constant";
import { logos } from "../../assets";
import intl from "react-intl-universal";

const Login = () => {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const loginData = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToForgetPassword = () => {
    window.location.href = "/verify-mobile";
  };

  const goToSignUp = () => {
    window.location.href = "/signup";
  };

  const login = () => {
    dispatch(loginActions.login(mobile, password, mobile));
  };

  /* Initially everything is invalid */
  let defaultErrorStates = {
    mobile: true,
    mobileLength: true,
    password: true,
  };

  /*
Dynamically update the
form whenever user inputs stuff
*/
  const untouchedForm = useRef(true);
  const isFormValid = useRef(false);

  const [errorList, setErrorList] = useState(defaultErrorStates);

  /* Validate Form */
  const validateForm = () => {
    let newErrorList = {
      ...errorList,
      password: password.trim().length !== 0,
      mobile: mobile.trim().length !== 0,
      mobileLength: mobile.trim().length >= 10,
    };

    setErrorList({ ...newErrorList });

    /* This validation is not based on state */
    let temporaryValidation = true;
    for (const key in newErrorList) {
      temporaryValidation = temporaryValidation && newErrorList[key];
    }
    /*
Saving it in this ref as well for
  when we don't want to revalidate the
  whole thing
  */
    isFormValid.current = temporaryValidation;
    return temporaryValidation;
  };

  useEffect(() => {
    if (untouchedForm.current) {
      return;
    }
    validateForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, mobile]);

  useEffect(() => {
    if (
      loginData &&
      loginData.error &&
      loginData.error.code === "NEW_PASSWORD_REQUIRED"
    ) {
      navigate("/change-password", { state: { mobile } });
      return;
    }
    if (loginData && loginData.error && loginData.error.message) {
      setErrorMsg(loginData.error.message);
    }
  }, [loginData]);

  /* Next Button */
  const submitButton = async (event) => {
    // event.preventDefault();

    /*
  The form is no longer untouched,
  which means we can now do real-time verificaiton
  */
    untouchedForm.current = false;

    if (validateForm()) {
      untouchedForm.current = true;
      login();
    }
  };

  const phoneFilter = (event, setter) => {
    setter(event.target.value.replace(/[^\d]+$/g, ""));
  };
  const eFilter = (event, setter) => {
    if ([69, 187, 188, 189, 190].includes(event.keyCode)) {
      event.preventDefault();
      setter(event.target.value.replace(/[^\d]+$/g, ""));
    }
  };
  function submitOnEnterPress(e) {
    if (e.key === "Enter" && !loginData.isLoading) {
      submitButton();
    }
  }
  return (
    <>
      <div className="login">
        <div className="login-container">
          <div className="login-left">
            <div className="logoDark">
              <img className="logo" src={logos?.logoWhite} alt="logo" />
            </div>
            <div className="logoLight">
              <img className="logo" src={logos?.logo} alt="logo" />
            </div>
            <h1 className="logo-title">{intl.get("login.title")}</h1>
            <form onSubmit={() => submitButton}>
              <div
                className={[
                  "form-wrapper",
                  errorList.mobile ? null : "has-error",
                ].join(" ")}
              >
                <label className="label">{intl.get("login.mobile")}</label>
                <div className="initial" data-placeholder={COUNTRY_CODE}>
                  <input
                    className={[
                      errorList.mobile
                        ? "input-control"
                        : "input-control border-red",
                    ]}
                    id="mobile"
                    type="mobile"
                    placeholder={intl.get("login.mobilePlaceholder")}
                    value={mobile}
                    maxLength="10"
                    onInput={maxLengthCheck}
                    onChange={(e) => phoneFilter(e, setMobile)}
                    onKeyDown={(e) => {
                      eFilter(e, setMobile);
                      submitOnEnterPress(e);
                    }}
                  />
                </div>

                {errorList.mobile ? null : (
                  <div className="show-error">
                    {intl.get("login.mobileErrorMessage")}
                  </div>
                )}
                {errorList.mobileLength ? null : (
                  <div className="show-error">
                    {intl.get("login.mobileValidMessage")}
                  </div>
                )}
              </div>
              <div
                className={[
                  "form-wrapper",
                  errorList.password ? null : "has-error",
                ].join(" ")}
              >
                <label className="label">{intl.get("login.password")}</label>
                <div className="password-container">
                  <input
                    className={[
                      errorList.password
                        ? "input-control"
                        : "input-control border-red",
                    ]}
                    type={isRevealPwd ? "text" : "password"}
                    placeholder={intl.get("login.passwordPlaceholder")}
                    value={password}
                    maxLength="20"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => submitOnEnterPress(e)}
                  />
                  <img
                    className="showHidePassword"
                    title={
                      isRevealPwd
                        ? intl.get("login.hidePassword")
                        : intl.get("login.showPassword")
                    }
                    alt=""
                    src={
                      isRevealPwd
                        ? commonImages.visibilityoff
                        : commonImages.visibility
                    }
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                  {errorList.password ? null : (
                    <div className="show-error">
                      {intl.get("login.passwordErrorMessage")}
                    </div>
                  )}
                </div>
              </div>
              <h2 onClick={goToForgetPassword} className="forgot-password">
                {intl.get("login.forgetPassword")}
              </h2>
              <div className="login-button" style={{ width: "60% !important" }}>
                {loginData.isLoading ? (
                  <Loader />
                ) : (
                  <Button
                    label={intl.get("login.loginbutton")}
                    onButtonClickHandler={submitButton}
                  />
                )}
              </div>
              <p className="signup-txt">
                {intl.get("login.notSequenceuser")}&nbsp;
                <span onClick={goToSignUp}>
                  {intl.get("signUp.signUp")}&nbsp;
                </span>
                {intl.get("login.nowText")}
              </p>
              {errorMsg && <p className="show-error">{errorMsg}</p>}
            </form>
          </div>
          <div className="login-right">
            <img src={WelcomeImage} alt="welcome" />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Login;
