import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./tableCardView.scss";

const TableCardView = (props) => {
  return (
    <>
      <div className="overTableCard">
        <DataGrid
         // getRowId={(r) => r.id}
          getRowId={(r) => r.id +
            "|" +
            Date.now() +
            Math.floor(Math.random() * 100)}

          rowHeight={"fit-content"}
          rows={props?.rows}
          columns={props.columns}
          className="tableCard"
          getRowClassName={() => `customRowCard`}
          components={props?.components}
          loading={props?.loading}
          rowCount={props?.rowCount}
          rowsPerPageOptions={props?.rowsPerPageOptions}
          paginationMode="server"
          pageSize={props?.pageSize}
          onPageChange={props?.onPageChange}
          disableSelectionOnClick={true}
        />
      </div>
    </>
  );
};

export default TableCardView;
