import React, { useState } from "react";
import { Grid } from "@mui/material";
import FormDropZone from "../../../../library/dropZone/FormDropZone";

function AddFiles() {
  const [photos, setPhotos] = useState("");

  const handleFileChange = (photos) => {
    setPhotos(photos);
  };

  const handleDeleteImageURL = (urls) => {
    const previousImg = photos.split("|");
    setPhotos(previousImg.filter((url) => url !== urls).join("|"));
  };

  return (
    <div className="overidePersonal">
      <Grid container item lg={9} xs={12} sm={12} md={9}>
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">Add Files</div>
        </Grid>
      </Grid>

      <Grid item container mt={1} spacing={2}>
        <Grid item lg={12} xs={12} sm={12} md={8}>
          <>
            <FormDropZone
              paraText1={"Drop the files here ..."}
              paraText2={"Drop Files here, "}
              paraText3={"or Browse"}
              handleFileChange={handleFileChange}
              deleteImageURL={(url) => handleDeleteImageURL(url)}
              isDynamics={true}
              isRequired={true}
              fileType={10}
              isMuliple={true}
              imageUrl={photos}
              id="addPhoto"
              key="addPhoto"
              isDoc={false}
              isImage={true}
              allFiles={true}
              isTableView={true}
            />
          </>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddFiles;
