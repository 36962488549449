import React, { useEffect, useState } from "react";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiKeySelectBox";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import {
  LNG_ID,
  ORG_ID,
  ISwitch,
  USER_ID,
  QUESTION_TEMPLATE_URL,
  QUESTION_GRID_COLOUMNS,
} from "../../../../constant/constant";
import { INSPECTION_ADMIN } from "../../../../constant/routeContant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useNavigate } from "react-router-dom";
import NORow from "../../../../library/custom/noRow/noRow";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const AddQuestion = ({
  onSubmit,
  getQuestionsList,
  getSubCategoriesList,
  getQuestionsListData,
  createUpdateQuestions,
  questionStatus,
}) => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [getQuestionsLists, setGetQuestionsLists] = useState([]);
  console.log("getQuestionsLists", getQuestionsLists);
  const intialState = {
    questionValue: "",
    subCategoriesValue: "",
  };
  const [formValue, setFormValue] = useState(intialState);
  const [isQuestionName, setIsQuestionName] = useState(false);
  const [subCategoriesName, setSubCategoriesName] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [subCatId, setSubCatId] = useState();
  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();

  const handleChange = (questionItem) => {
    const newQuestionList = [...getQuestionsLists];
    newQuestionList.forEach((lists) => {
      if (lists.key == questionItem.key) {
        lists.isActive = !lists.isActive;
        lists.checked = !lists.checked;
        lists.questionId = questionItem.key;
        lists.createdBy = parseInt(USER_ID());
      }
    });
    setGetQuestionsLists(newQuestionList);
  };

  function mappedData() {
    let errors = false;
    if (formValue?.questionValue.trim() === "") {
      setFormValue({
        ...formValue,
        questionValue: "",
      });
      seterrorsMsgShowActive(false);
      setIsQuestionName(true);
      errors = true;
    }
    if (formValue?.subCategoriesValue?.length === 0) {
      seterrorsMsgShowActive(false);
      setSubCategoriesName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }
    const index = getQuestionsLists?.findIndex(
      (object) =>
        object.value.toUpperCase() === formValue?.questionValue.toUpperCase()
    );
    if (index === -1) {
      seterrorsMsgShowActive(false);
      const questions = [
        {
          questionId: 0,
          subcategoryId: parseInt(
            formValue?.subCategoriesValue?.map((x) => x.key).toString()
          ),
          question: formValue?.questionValue,
          isActive: true,
          createdBy: parseInt(USER_ID()),
        },
      ];
      return { questions, organisationId: parseInt(ORG_ID()), lngId: LNG_ID };
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Question already added");
    }
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const submitUpdateData = () => {
    const questions = [];
    getQuestionsLists.forEach((data) => {
      if (data.checked) {
        questions.push(data);
      }
    });
    onSubmit({ questions, organisationId: parseInt(ORG_ID()), lngId: LNG_ID });
  };

  const handleFileChanges = (data) => {
    const subIdsFilter = data?.map((x) => parseInt(x.subcategoryId));
    setSubCatId([...new Set(subIdsFilter)]);

    const questions = data.map((x) => {
      x.questionId = 0;
      x.subcategoryId = parseInt(x.subcategoryId);
      x.isActive = true;
      x.createdBy = parseInt(USER_ID());
      return x;
    });
    onSubmit({ questions, organisationId: parseInt(ORG_ID()), lngId: LNG_ID });
  };

  const handleSubCategoriesChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];
    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.key === item.key) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.key !== item.key);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setFormValue({
      ...formValue,
      subCategoriesValue: duplicateRemoved,
    });
  };

  useEffect(() => {
    if (formValue?.subCategoriesValue) {
      const subIds = formValue?.subCategoriesValue?.map((x) => x.key);
      setSubCatId(subIds);
      const data = {
        subCategories: subIds,
        searchText: "",
        organisationId: parseInt(ORG_ID()),
        lngId: LNG_ID,
      };
      getQuestionsListData(data);
    }
  }, [formValue?.subCategoriesValue]);

  const dataBack = {
    title: "Questions Configuration",
    route: INSPECTION_ADMIN,
  };

  useEffect(() => {
    setGetQuestionsLists(getQuestionsList);
  }, [getQuestionsList]);

  useEffect(() => {
    if (
      createUpdateQuestions?.status === "SUCCESS" &&
      createUpdateQuestions?.isLoading === false
    ) {
      setIsSubmit(false);
      setIsQuestionName(false);
      setFormValue({
        ...formValue,
        questionValue: "",
      });
      const data = {
        subCategories: subCatId ? subCatId : [0],
        searchText: "",
        organisationId: parseInt(ORG_ID()),
        lngId: LNG_ID,
      };
      getQuestionsListData(data);
      setIsBulkUpload(false);
    }
  }, [createUpdateQuestions]);

  return (
    <>
      <Grid className="inspectionOverRide">
        <BackBtn dataBack={dataBack} />

        <Grid container item md={12} xs={12} className="addShadowBox">
          <Grid container mt={1}>
            <Grid container item md={12} xs={12}>
              <Grid container item md={12} mb={3}>
                <Grid item lg={6} xs={12} mb={3}>
                  <FormControl fullWidth>
                    <FormLabels label="Select Sub Category" isRequired={true} />
                    <MultiSelectBox
                      listData={getSubCategoriesList ?? []}
                      value={formValue.subCategoriesValue || []}
                      onChange={handleSubCategoriesChange}
                      isError={subCategoriesName}
                      isIdShow={true}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  sm={12}
                  md={4}
                  mt={3}
                  textAlign="right"
                >
                  {checkPrivileges([12, 120, 4]) && (
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={
                        isBulkUpload ? "Single Question" : "Multiple Question"
                      }
                      varientContained={true}
                      onClick={() => setIsBulkUpload(!isBulkUpload)}
                    ></Buttons>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  {isBulkUpload ? (
                    <>
                      <BulkUpload
                        handleFileChanges={handleFileChanges}
                        paraText1={"Drop the files here ..."}
                        paraText2={"Drop the files here, "}
                        paraText3={"or Browse"}
                        templateUrl={QUESTION_TEMPLATE_URL}
                        templateText="Download Template"
                        gridColoumns={QUESTION_GRID_COLOUMNS}
                        isCustomColoumns={true}
                      />
                    </>
                  ) : (
                    <>
                      {checkPrivileges([12, 120, 121, 4]) && (
                        <>
                          <Grid className="addQuestionBox">
                            <Grid className="addQuestionInput" lg={10}>
                              <InputField
                                type="text"
                                isRequired={true}
                                value={formValue?.questionValue}
                                label={false}
                                placeholder="Add Question Text Here..."
                                onChange={(e) =>
                                  setFormValue({
                                    ...formValue,
                                    questionValue: e.target.value,
                                  })
                                }
                                isError={isQuestionName}
                                errorMsg={
                                  isQuestionName ? "Question is Required" : ""
                                }
                              />
                            </Grid>

                            <Grid className="smallAddBtn" lg={1.3}>
                              <Buttons
                                aria-describedby="dd"
                                variant="contained"
                                type="button"
                                name="btn"
                                label={"Add"}
                                id="btnAddEquipment"
                                varientAddIconBlue={true}
                                disabled={isSubmit ? true : false}
                                onClick={submitData}
                              ></Buttons>
                            </Grid>
                          </Grid>
                          <Grid md={12} item>
                            {errorsMsgShowActive && (
                              <p className="errorMsg">{errorsMsgShow}</p>
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item md={12} className="CategoriesBox" mt={3}>
                <Grid className="title">Questions</Grid>
                <Grid className="questionBox mainQuestion" mt={3}>
                  {getQuestionsLists &&
                    getQuestionsLists.map((questionItem) => {
                      return (
                        <Grid className="catListBox" key={questionItem?.key}>
                          <Grid className="catListItem">
                            {questionItem?.value}
                          </Grid>
                          {checkPrivileges([12, 4, 120]) && (
                            <Grid className="closeIcon">
                              <FormControl
                                component="fieldset"
                                variant="standard"
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <ISwitch
                                        checked={
                                          questionItem.isActive
                                            ? questionItem.isActive
                                            : false
                                        }
                                        onChange={() =>
                                          handleChange(questionItem)
                                        }
                                        name="status"
                                      />
                                    }
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}
                </Grid>
                {getQuestionsLists && getQuestionsLists.length === 0 && (
                  <NORow />
                )}
              </Grid>
            </Grid>
          </Grid>
          {getQuestionsLists && getQuestionsLists.length !== 0 && (
            <Grid container mt={10}>
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientText={true}
                  label="Cancel"
                  onClick={() => navigate(-1)}
                />
              </Grid>
              <Grid item lg={2} xs={12} sm={3} md={3}>
                {checkPrivileges([12, 120, 121, 4]) && (
                  <Buttons
                    varientContained={true}
                    label={"Save"}
                    onClick={submitUpdateData}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AddQuestion;
