import React from "react";
import { JHA_CONFIGURATION_MANGEMENT } from "../../../../constant/constant";
import Card from "../../../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const JhaConfigurationManagement = ({ onClick }) => {
  return (
    <>
      <Grid container className="CardListContaner">
        {checkPrivileges([12, 58, 59, 60]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={JHA_CONFIGURATION_MANGEMENT[0]}
          />
        )}
        {checkPrivileges([12, 58, 59, 60]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={JHA_CONFIGURATION_MANGEMENT[1]}
          />
        )}
        {checkPrivileges([12, 58, 59, 60]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={JHA_CONFIGURATION_MANGEMENT[2]}
          />
        )}
        {checkPrivileges([12, 58, 59, 60]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={JHA_CONFIGURATION_MANGEMENT[3]}
          />
        )}
        {checkPrivileges([12, 61, 62, 63]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={JHA_CONFIGURATION_MANGEMENT[4]}
          />
        )}
      </Grid>
    </>
  );
};

export default JhaConfigurationManagement;
